import * as _ from "lodash-es";
import * as CSV from "comma-separated-values/csv";
import * as FPP from "formatter-plus-plus";

let FormatterPlusPlus = FPP.FormatterPP;
let formatterPlusPlus = new FormatterPlusPlus();
exploreCardService.$inject = ['utils', 'toastNotification', 'c', 'analyticsService'];

const cardIdentifier = (card, suffix = '') => {
  return `${card.sourceType}_${card.sourceId}_${card.column.display_name}_${suffix}`;
}
export function exploreCardService(utils, toastNotification, c, analyticsService) {
    return {
        copyToClipboard: copyToClipboard,
        exportAsCSV: exportAsCSV,
        cardIdentifier: cardIdentifier,

    };
    function copyToClipboard(card, data) {
        let clipboardString = '', valueHeader = _getValueHeader(card);
        clipboardString += `${card.column.display_name}\t${valueHeader}`;
        data.forEach(function (item) {
            clipboardString += `\n${card.column.type == c.text ? item.value : item.formatted }\t${card.selectedAggregation?.format?.scientific ? formatterPlusPlus.humanize(item.agg) : item.agg}`;
        });
        utils.copyToClipboard(utils.sanitizeData(clipboardString));
        let message = `Copied ${data.length} item${data.length > 1 ? 's' : ''} to clipboard`;
        toastNotification.success(message);
        analyticsService.userEventTrack(c.userEvents.exploreCards.copyValueToClipboard, {eventOrigin: 'dataview.exploreMenu' });
    }
    function exportAsCSV(card, data, exportFileNamePrepend=null) {
        let valueHeader = _getValueHeader(card);
        data = data.map(function (item) {
            return {
                [card.column.display_name]: String(card.column.type == c.text ? item.value : item.formatted),
                [valueHeader]: card.selectedAggregation?.format?.scientific ? formatterPlusPlus.humanize(item.agg) : item.agg
            }
        });
        let csvContent = CSV.encode(data, {header: true});
        let fileName = exportFileNamePrepend ? `${exportFileNamePrepend}_${card.column.display_name}.csv` : `${card.column.display_name}.csv`;
        let result = utils.downloadStringDataAsFile(csvContent, fileName, 'text/csv;encoding:utf-8');
        !!result ? toastNotification.success('Downloaded CSV successfully') : toastNotification.error('Error while downloading CSV');
        analyticsService.userEventTrack(c.userEvents.exploreCards.exportListAsCSV, {eventOrigin: 'dataview.exploreMenu' });
    }
    function _getValueHeader(card) {
        let valueHeader = card.selectedAggregation.aggregation,
            targetDisplayName = _.get(_.find(card.metadata, ['internal_name', card.selectedAggregation.target]), 'display_name');
        if (valueHeader != "COUNT" && targetDisplayName) {
            valueHeader += `(${targetDisplayName})`;
        }
        return valueHeader
    }

}
