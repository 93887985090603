<template>
  <div class="mm-data-library-dataset-views">
    <mm-divider class="m-b-3" />

    <div class="actions d-flex p-l-2">
      <mm-spacer />
      <div v-if="computedShowDataSync" class="d-flex">
        <restrictions-wrapper validate-storage-limit>
          <mm-button
            :label="$t('data_library.views.set_sync_on')"
            icon="sync"
            objective="tertiary"
            size="small"
            :disabled="!computedIsAnyViewSelected"
            :loading="isSetDataSyncOnLoading"
            @click="toggleViewsDataSync(true)"
          />
        </restrictions-wrapper>
        <restrictions-wrapper validate-storage-limit>
          <mm-button
            class="m-l-4"
            :label="$t('data_library.views.set_sync_off')"
            icon="sync_disabled"
            objective="tertiary"
            size="small"
            :disabled="!computedIsAnyViewSelected"
            :loading="isSetDataSyncOffLoading"
            @click="toggleViewsDataSync(false)"
          />
        </restrictions-wrapper>
        <mm-divider style="height: auto" class="m-x-3" vertical />
      </div>
      <mm-button
        class="delete-btn m-r-2"
        :label="$t('data_library.views.delete_views')"
        icon="delete"
        objective="tertiary"
        size="small"
        :disabled="!computedIsAnyViewSelected || computedAreAllViewsSelected"
        :loading="isDeleteSelectedLoading"
        @click="confirmDeleteSelectedViews"
      />
    </div>

    <mm-divider class="m-y-3" />

    <div class="views-table">
      <mm-row no-gutters class="mm-text--caption-bold align-items-center overflow-kit">
        <mm-col>
          <div class="d-flex select-all" style="place-items: center">
            <mm-checkbox
              v-model="computedAreAllViewsSelected"
              class="m-l-1"
              :indeterminate="computedIsAnyViewSelected"
              :label="$t('data_library.views.view_name')"
            />
          </div>
        </mm-col>
        <mm-col cols="1">
          <mm-tooltip wrapper-class="overflow-ellipsis max-width-100" :label="$t('data_library.views.pipeline_tasks')">
            <span class="mm-text--caption-bold"> {{ $t('data_library.views.pipeline_tasks') }} </span>
          </mm-tooltip>
        </mm-col>
        <mm-col cols="2">
          <mm-tooltip wrapper-class="overflow-ellipsis max-width-100" :label="$t('data_library.views.last_update')">
            <span class="mm-text--caption-bold"> {{ $t('data_library.views.last_update') }} </span>
          </mm-tooltip>
        </mm-col>
        <mm-col cols="2">
          <mm-tooltip wrapper-class="overflow-ellipsis max-width-100" :label="$tc('global.dictionary.row', 2)">
            <span class="mm-text--caption-bold"> {{ $tc('global.dictionary.row', 2) }} </span>
          </mm-tooltip>
        </mm-col>
        <mm-col cols="2" class="p-l-2">
          <mm-tooltip wrapper-class="overflow-ellipsis max-width-100" :label="$tc('global.dictionary.column', 2)">
            <span class="mm-text--caption-bold"> {{ $tc('global.dictionary.column', 2) }} </span>
          </mm-tooltip>
        </mm-col>
        <mm-col v-if="computedShowDataSync" cols="1" class="col-center">
          <mm-tooltip :label="$t('data_library.views.ds_tooltip')">
            <span class="mm-text--caption-bold d-flex" style="text-align: center">
              {{ $t('data_library.views.data_sync') }}
            </span>
          </mm-tooltip>
        </mm-col>
        <mm-col cols="1" />
      </mm-row>

      <mm-divider class="m-t-3" />

      <div class="views-list overflow-kit">
        <div v-for="view in computedViews" :key="view.viewId">
          <mm-row no-gutters class="mm-text--body-regular align-items-center p-y-3">
            <mm-col class="overflow-ellipsis">
              <div class="d-flex overflow-hidden align-items-center">
                <mm-checkbox v-model="viewsSelection[view.viewId]" class="m-l-1" />
                <mm-tooltip
                  v-if="view.properties.pipelineStatus == RESOURCE_PIPELINE_STATUS.ERROR"
                  :label="$t('data_library.views.error_tooltip')"
                >
                  <mm-icon name="error" color="dest800" />
                </mm-tooltip>
                <mm-tooltip :label="view.properties.name" wrapper-class="overflow-ellipsis max-width-100 p-a-2">
                  <mm-link :label="view.properties.name" @click="openView(view)" />
                </mm-tooltip>
              </div>
            </mm-col>
            <mm-col cols="1">
              <mm-tooltip
                wrapper-class="overflow-ellipsis max-width-100"
                :label="view.properties.tasksQuantity.toLocaleString()"
              >
                <span> {{ view.properties.tasksQuantity.toLocaleString() }}</span>
              </mm-tooltip>
            </mm-col>
            <mm-col cols="2">
              <mm-tooltip
                wrapper-class="overflow-ellipsis max-width-100"
                :label="moment.utc(view.properties.updatedAt).local().format('lll')"
              >
                <span> {{ getFromNowDate(view.properties.updatedAt) }}</span>
              </mm-tooltip>
            </mm-col>
            <mm-col cols="2">
              <mm-tooltip
                wrapper-class="overflow-ellipsis max-width-100"
                :label="view.properties.rowCount.toLocaleString()"
              >
                <span> {{ view.properties.rowCount.toLocaleString() }}</span>
              </mm-tooltip>
            </mm-col>
            <mm-col cols="2" class="p-l-2">
              <mm-tooltip
                wrapper-class="overflow-ellipsis max-width-100"
                :label="view.properties.columnCount.toLocaleString()"
              >
                <span> {{ view.properties.columnCount.toLocaleString() }}</span>
              </mm-tooltip>
            </mm-col>
            <mm-col v-if="computedShowDataSync" cols="1" class="col-center">
              <mm-icon
                :name="view.properties.dataSync ? 'check' : 'sync_disabled'"
                @click="$userEvents.save(USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.DATA_SYNC_ICON_CLICK)"
              />
              <mm-tooltip
                v-if="view.properties.dataUpdatePending"
                :label="$t('data_library.views.inconsistent_tooltip')"
                position="left"
                :visible="computedShowDataflowStatus"
              >
                <mm-icon
                  :class="{ 'cursor-pointer': computedShowDataflowStatus }"
                  name="warning_dark"
                  color="warn800"
                  @click="toggleDataflowStatusModal(view.viewId)"
                />
              </mm-tooltip>
            </mm-col>
            <mm-col style="text-align: center" cols="1">
              <keep-alive>
                <mm-menu
                  :key="`${view.viewId}_${menuKey}`"
                  :close-on-content-click="false"
                  @input="$userEvents.save(USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.OPEN_MENU)"
                >
                  <template #activator="{ on }">
                    <mm-button
                      class="m-r-2"
                      :key="`view_options_button_${menuKey}`"
                      icon="menu_3_dots_horizontal"
                      objective="tertiary"
                      size="medium"
                      v-on="on"
                    />
                  </template>
                  <template #content>
                    <view-options :view="view" @delete="deselectDeletedView" @optionExecuted="menuKey++" />
                  </template>
                </mm-menu>
              </keep-alive>
            </mm-col>
          </mm-row>

          <mm-divider />
        </div>
      </div>
    </div>

    <resource-modal-delete
      v-if="selectedViewsToDelete.length"
      v-model="isDeleteModalVisible"
      :resources="selectedViewsToDelete"
      @submit="deleteSelectedViews"
    />

    <mm-modal v-model="showDataflowStatusModal" :title="$t('dataflow_status.title')" closable>
      <dataflow-status :is-visible="showDataflowStatusModal" :highlighted-view-id="highlightedViewId" />
    </mm-modal>
  </div>
</template>

<script>
// Filters
import { getFromNowDate } from '@/utils/filters'

// Dependencies
import moment from 'moment'

// Constants
import {
  RESOURCE_PIPELINE_STATUS,
  RESOURCE_STATUS,
  REQUESTS_STATUS,
  SPLITIO_FEATURE_FLAGS,
  USER_EVENTS,
  getOldAppUrl
} from '@/constants'

// API
import viewApi from '@/modules/view/api/view.api'

// Components
import ViewOptions from '@/modules/view/components/view-options/view-options'
import ResourceModalDelete from '@/modules/resources/components/resource-modal-delete/resource-modal-delete.vue'
import DataflowStatus from '@/components/dataflow-status/dataflow-status'
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

// Plugins
import { featuresVisibility } from '@/plugins/splitio'

export default {
  name: 'data-library-dataset-views',
  components: { ViewOptions, ResourceModalDelete, DataflowStatus, RestrictionsWrapper },
  props: {
    datasetId: {
      type: [String, Number],
      required: true
    }
  },
  data: () => ({
    viewsSelection: {},
    isDeleteSelectedLoading: false,
    isSetDataSyncOnLoading: false,
    isSetDataSyncOffLoading: false,
    isDeleteModalVisible: false,
    selectedViewsToDelete: [],
    showDataflowStatusModal: false,
    highlightedViewId: null,
    menuKey: 0,
    moment
  }),
  computed: {
    computedViews() {
      return Object.values(this.$store.state.resources.resourcesMap)
        .filter((r) => r.viewId && r.properties.dataset.id == this.datasetId)
        .filter((view) => view.status != RESOURCE_STATUS.DELETED)
        .sort((a, b) => moment(b.properties.updatedAt) - moment(a.properties.updatedAt))
    },
    computedIsAnyViewSelected() {
      return Object.values(this.viewsSelection).some((value) => value)
    },
    computedAreAllViewsSelected: {
      get() {
        const viewsSelectionValues = Object.values(this.viewsSelection)
        return viewsSelectionValues.length == this.computedViews.length && viewsSelectionValues.every((value) => value)
      },
      set(isSelected) {
        this.viewsSelection = this.computedViews
          .map((view) => view.viewId)
          .reduce((o, k) => ({ ...o, [k]: isSelected }), {})
      }
    },
    computedShowDataSync() {
      return featuresVisibility[SPLITIO_FEATURE_FLAGS.DATA_SYNC]
    },
    computedShowDataflowStatus() {
      return featuresVisibility[SPLITIO_FEATURE_FLAGS.DATAFLOW_STATUS]
    }
  },
  created() {
    this.RESOURCE_PIPELINE_STATUS = RESOURCE_PIPELINE_STATUS
    this.USER_EVENTS = USER_EVENTS
  },
  watch: {
    datasetId() {
      this.computedAreAllViewsSelected = false
      this.viewsSelection = {}
    }
  },
  methods: {
    openView(view) {
      this.$userEvents.save(USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.OPEN_VIEW, { id: view.viewId })
      // TODO: remove when angular code is gone
      const redirectUrl = `${getOldAppUrl()}/#/workspaces/${this.$store.state.workspaceId}/projects/${
        this.$store.state.projectId
      }/dataviews/${view.viewId}`
      window.open(redirectUrl, `_mammoth_ds_${this.datasetId}`)
    },
    confirmDeleteSelectedViews() {
      this.selectedViewsToDelete = Object.keys(this.viewsSelection)
        .filter((viewId) => this.viewsSelection[viewId])
        .map((viewId) => this.$store.getters['resources/getViewByViewId'](viewId))

      this.isDeleteModalVisible = true
    },
    async deleteSelectedViews() {
      this.isDeleteSelectedLoading = true
      this.$userEvents.save(USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.DELETE_VIEWS, {
        ids: this.selectedViewsToDelete.map((v) => v.viewId)
      })

      for await (const view of this.selectedViewsToDelete) await this.deleteView(view)

      this.selectedViewsToDelete = []
      this.isDeleteSelectedLoading = false
    },
    async deleteView(view) {
      const removeResponse = await viewApi.remove(view.viewId)

      if (removeResponse.status != REQUESTS_STATUS.SUCCESS)
        this.$toast.show({
          content: this.$t('navbar.tab.menu.delete.error', { viewName: view.properties.name }),
          status: 'error'
        })
      else this.deselectDeletedView(view)
    },
    deselectDeletedView(view) {
      this.viewsSelection[view.viewId] = false
      delete this.viewsSelection[view.viewId]
      view.status = RESOURCE_STATUS.DELETED
    },
    async toggleViewsDataSync(status) {
      if (status) this.isSetDataSyncOnLoading = true
      else this.isSetDataSyncOffLoading = true

      this.$userEvents.save(USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.TOGGLE_DATA_SYNC, { ids: viewsIds, status })

      const viewsIds = Object.keys(this.viewsSelection).filter((viewId) => this.viewsSelection[viewId])
      await viewApi.toggleViewsDataSyncStatus(viewsIds, status)
      this.$toast.show({ content: this.$t('view.dataflow_settings.data_sync_options.success'), status: 'success' })

      if (status) this.isSetDataSyncOnLoading = false
      else this.isSetDataSyncOffLoading = false
    },
    toggleDataflowStatusModal(viewId) {
      this.$userEvents.save(USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.WARNING_ICON_CLICK, { viewId })
      this.highlightedViewId = viewId
      if (this.computedShowDataflowStatus) this.showDataflowStatusModal = true
    },
    getFromNowDate
  }
}
</script>

<style lang="scss" scoped>
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';

.mm-data-library-dataset-views {
  .delete-btn:not(.mm-button--disabled) {
    color: var(--mm-color--dest700) !important;

    ::v-deep svg {
      --mm-icon--color: var(--mm-color--dest700) !important;
      --mm-icon--color-hover: var(--mm-color--dest700) !important;
    }
  }

  .select-all {
    ::v-deep .mm-checkbox--label {
      @extend .mm-text--caption-bold;
    }
  }

  .views-list {
    overflow-x: hidden !important;
    max-height: 250px;
  }

  .col-center {
    justify-content: center;
    display: flex;
  }

  .actions {
    flex-flow: wrap;
  }
}
</style>
