export const RESOURCE_TYPES = {
  DATASET: 'dataset',
  FILE: 'file',
  FOLDER: 'folder',
  REPORT: 'report',
  VIEW: 'view',
  WEBHOOK: 'webhook'
}

export const RESOURCE_STATUS = {
  ACTION_NEEDED: 'action_needed',
  DELETED: 'deleted',
  ERROR: 'error',
  HAS_UNSTRUCTURED_DATA: 'has_unstructured_data',
  NEED_ACTION: 'need_action',
  OUTDATED: 'outdated',
  PROCESSING: 'processing',
  READY: 'ready'
}

export const RESOURCE_DRAFT_STATUS = {
  CLEAN: 'clean',
  DIRTY: 'dirty',
  OFF: 'off'
}

export const RESOURCE_PIPELINE_STATUS = {
  ERROR: 'error',
  READY: 'ready',
  RUNNING: 'running',
  SUBMITTED: 'submitted'
}

export const RESOURCE_DEPENDENCIES = {
  BRANCH_OUT: 'BRANCH_OUT',
  BRANCH_OUT_TO_PROJECT: 'BRANCH_OUT_TO_PROJECT',
  CROSSTAB: 'CROSSTAB',
  EXPORT: 'EXPORT',
  JOIN: 'JOIN',
  LIVE_LINK: 's3',
  LOOKUP: 'LOOKUP',
  PUBLISH: 'publishdb',
  VIEW: 'DATAVIEW'
}

export const IN_OUT_RESOURCE_DEPENDENCIES = [
  'BRANCH_OUT',
  'BRANCH_OUT_TO_PROJECT',
  'CROSSTAB',
  'EXPORT',
  'JOIN',
  'LIVE_LINK',
  'LOOKUP',
  'PUBLISH'
].map((k) => RESOURCE_DEPENDENCIES[k])

export const RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP = { publishdb: 'publish', s3: 'live_link' }

export const DATAFLOW_STATUS_NON_SYNC_TASKS_RESOURCE_DEPENDENCIES = {
  in: ['BRANCH_OUT', 'CROSSTAB'].map((k) => RESOURCE_DEPENDENCIES[k]),
  out: ['BRANCH_OUT_TO_PROJECT', 'JOIN', 'LOOKUP', 'VIEW', 'EXPORT', 'LIVE_LINK', 'PUBLISH'].map(
    (k) => RESOURCE_DEPENDENCIES[k]
  )
}

export const DATASET_NAME_MAX_LENGTH = 300

export const FOLDER_NAME_MAX_LENGTH = 100
