
export var validationConstants = {
  messages: {
    onlyAlphaNum: 'Please use only alphanumerics, underscore or spaces',
    required: 'Cannot be empty',
    notStartsWithAlpha: 'Should start with an alphabet',
    maxlength: 'Should be less than 300 characters long',
    valDuplicateCol: 'Another column with the given name already exists',
    valDate: 'Please enter a valid date in <em>YYYY-MM-DD HH:mm:ss</em> format',
    valInteger: 'Should be an integer',
    valNumber: 'Should be a number',
    valEmail: 'Please enter a valid Email',
    valReservedColName: 'This is a reserved column name, please enter a different one',

    // specific ones
    valAtLeastOneDateComponent: 'Please specify at least one non-zero value',

    //metric related
    valDuplicateMetricName: 'Metric name is not unique'
  }
};

