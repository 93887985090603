import * as angular from "angular";
import {ActionServiceFactory} from "./actionService.factory";
import {ActionFactory} from "./Action.factory";
import {dataviewConfig} from "../dataview.config";
import {actionController} from "./action.controller";
import {mysqlActionManagerFactory} from "./mysql.manager";
import {s3ActionManagerFactory} from "./s3upload.manager";
import {postgresqlActionManagerFactory} from "./postgresql.manager";
import {mssqlActionManagerFactory} from "./mssql.manager";
import {publishDBManagerFactory} from "./publishdb.manager";
import {elasticsearchActionManagerFactory} from "./elasticsearch.manager";
import {emailActionManagerFactory} from "./email.manager";
import {powerbiActionManagerFactory} from "./powerbi.manager";
import {internalDataSetFactory} from "./saveasdataset.manager";
import {ActionSequenceSelectController, actionSequenceSelect} from "./action.sequence.select";
import {viewUtilsModule} from "../viewUtils/index";
import {redshiftActionManagerFactory} from './redshift.manager';
import {bigqueryActionManagerFactory} from './bigquery.manager';
import {updateConditionSelection, copyPublishCredentials} from "./actionDirectives";
import {sftpActionManagerFactory} from './sftp.manager';

export let actionModule = angular.module('app.dataview.actions', [
  'app.models', 'ui.bootstrap', 'app.common', viewUtilsModule.name
]);

actionModule.service('ActionServiceFactory', ActionServiceFactory);
actionModule.factory('Action', ActionFactory);
actionModule.controller(dataviewConfig.actionController, actionController);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.mysql].manager, mysqlActionManagerFactory);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.redshift].manager, redshiftActionManagerFactory);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.elasticsearch].manager, elasticsearchActionManagerFactory);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.email].manager, emailActionManagerFactory);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.powerbi].manager, powerbiActionManagerFactory);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.internal_dataset].manager, internalDataSetFactory);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.postgres].manager, postgresqlActionManagerFactory);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.mssql].manager, mssqlActionManagerFactory);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.publishdb].manager, publishDBManagerFactory);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.s3].manager, s3ActionManagerFactory);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.bigquery].manager, bigqueryActionManagerFactory);
actionModule.service(dataviewConfig.actionConfig[dataviewConfig.actions.sftp].manager, sftpActionManagerFactory);

actionModule.directive('actionSequenceSelect', actionSequenceSelect);
actionModule.directive('updateConditionSelection', updateConditionSelection);
actionModule.directive('copyPublishCredentials', copyPublishCredentials);
actionModule.controller('ActionSequenceSelectController', ActionSequenceSelectController);
