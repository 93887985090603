/*jslint node: true */

import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
import { ExploreConsts } from './explore.constants';
/**
 * @ngInject
 */
explorePanelController.$inject = [
  'VuexStore',
  'CustomSummaryCard',
  'c',
  'TextColumnSummaryCard',
  'RangeExploreCard',
  'CardCollectionFactory',
  'globalFilterService',
  'utils',
  'analyticsService',
  '$timeout',
  'Notification',
  'eventCallbackManagerFactory',
];
export function explorePanelController(
  $store,
  CustomSummaryCard,
  c,
  TextColumnSummaryCard,
  RangeExploreCard,
  CardCollectionFactory,
  globalFilterService,
  utils,
  analyticsService,
  $timeout,
  Notification,
  eventCallbackManagerFactory
) {
  this.$onInit = function () {
    let panel = this;
    panel.internalID = 'explore_' + utils.string.random();
    panel.cardCollection = new CardCollectionFactory();
    panel.persistFilters = panel?.persistFilters || false;
    panel.globalFilter = globalFilterService.getBySource(panel.sourceId, panel.sourceType, panel.persistFilters);
    panel.removeCondition = removeCondition;
    panel.removeAllConditionsAfter = removeAllConditionsAfter;
    panel.removeAllConditions = removeAllConditions;
    panel.removeCard = removeCard;
    panel.openColumn = openColumn;
    panel.openCustomCard = openCustomCard;
    panel.toggleColumn = toggleColumn;
    panel.toggleCustomCard = toggleCustomCard;
    panel.toggleColumnByInternalName = toggleColumnByInternalName;
    panel.refreshAll = refreshAll;
    panel.init = init;
    panel.saveFilterAsRule = saveFilterAsRule;
    panel.saveFilterAsView = saveFilterAsView;
    panel.reconsile = _reconsile;
    panel.dataAt = null;
    panel.filterBarMenuOpen = false;
    panel.sequence = null;
    panel.sortableOptions = {
      'ui-floating': true,
      handle: 'header',
      cancel: '.explore-card.active',
      items: '.explore-card:not(.active)',
      start: function (event, ui) {
        analyticsService.userEventTrack(c.userEvents.exploreCards.dragged, {
          eventOrigin: panel.sourceType + '.explorePanel',
        });
        let origIndex = ui.item.sortable.index;
        let card = panel.cardCollection.cards[origIndex];
        if (card.isActive) {
          Notification.warning('Can not move an active card');
          ui.item.sortable.cancel();
        }
      },
      update: function (event, ui) {
        analyticsService.userEventTrack(c.userEvents.exploreCards.repositioned, {
          eventOrigin: `${panel.sourceType}.explorePanel`,
        });
        let origIndex = ui.item.sortable.index;
        let card = panel.cardCollection.cards[origIndex];

        if (card.isActive) {
          Notification.warning('Can not move an active card');
          ui.item.sortable.cancel();
          return;
        }
      },
      stop: function (event, ui) {
        analyticsService.userEventTrack(c.userEvents.exploreCards.dropped, {
          eventOrigin: `${panel.sourceType}.explorePanel`,
        });
        panel.cardCollection.fixAt();
        _saveConfig();
      },
    };

    function _wsChangeCb(changes) {
      if (changes.hasOwnProperty('data_updated_at') || changes.hasOwnProperty('status')) {
        _refreshIfNeeded();
      }

      function _refreshIfNeeded() {
        if (panel.sourceStatus == 'processing') {
          return;
        }
        if (panel.sourceDataUpdatedAt != panel.dataAt) {
          panel.dataAt = panel.sourceDataUpdatedAt;
          panel.refreshAll(true);
        }
      }
    }

    function _getColumnCard(column, renderType = null) {
      if (!renderType) {
        if (column.type == c.text) {
          renderType = ExploreConsts.renderTypes.list;
        } else {
          renderType = ExploreConsts.renderTypes.chart;
        }
      }
      let cls = _getBaseCardClass(column.type, renderType);
      let card = new cls(
        panel.sourceId,
        panel.sourceType,
        panel.sourceMetadata,
        column,
        panel.sourceFormatInfo,
        panel.sourceUpdateEvent,
        panel.getSourceData,
        panel.exportFileNamePrepend
      );
      card.changeRenderCb = getChangeRenderCb(renderType);
      card.saveConfig = _saveConfig;
      card.removeCondition = removeCondition;
      return card;
    }

    function getChangeRenderCb(currentRenderType) {
      return function (card, newRenderType) {
        const column = card.column;
        analyticsService.userEventTrack(c.userEvents.exploreCards.switchExploreCardView, {
          eventOrigin: panel.sourceType + '.exploreCard',
          previousCardType: card.type,
          previousViewType: currentRenderType,
          newViewType: newRenderType,
        });
        if (newRenderType == currentRenderType) {
          return;
        }
        card.hideCard = true;
        let idx = panel.cardCollection.cards.indexOf(card);
        let cls = _getBaseCardClass(column.type, newRenderType);
        let replaceCard = new cls(
          panel.sourceId,
          panel.sourceType,
          panel.sourceMetadata,
          column,
          panel.sourceFormatInfo,
          panel.sourceUpdateEvent,
          panel.getSourceData,
          panel.exportFileNamePrepend
        );
        if (card.isActive) {
          panel.removeCondition(card);
        }
        replaceCard.at = card.at;
        replaceCard.summary = card.summary;
        replaceCard.saveConfig = _saveConfig;
        replaceCard.removeCondition = removeCondition;
        replaceCard.changeRenderCb = getChangeRenderCb(newRenderType);
        $timeout(function () {
          addCard(replaceCard, idx, card.selectedAggregation, undefined, card.granularity, replaceCard.sortBy);
        }, 50);
      };
    }

    function _getBaseCardClass(coltype, renderType) {
      let cls = TextColumnSummaryCard;
      if (coltype == c.text || renderType == ExploreConsts.renderTypes.list) {
        cls = TextColumnSummaryCard;
      } else if (coltype == c.numeric) {
        cls = RangeExploreCard;
      } else {
        cls = RangeExploreCard;
      }
      return cls;
    }

    function init() {
      //initialize optional parameters
      panel.exportFileNamePrepend = panel.exportFileNamePrepend ? panel.exportFileNamePrepend : null;
      panel.saveCb = panel.saveCb ? panel.saveCb : _.noop;
      panel.conditionCb = panel.conditionCb ? panel.conditionCb : _.noop;
      panel.onSecondaryAction = panel.onSecondaryAction ? panel.onSecondaryAction : _.noop;
      panel.saveFilter = panel.saveFilter ? panel.saveFilter : false;
      panel.saveFilterCallback = panel.saveFilterCallback ? panel.saveFilterCallback : _.noop;
      panel.onVisibleCallback = panel.onVisibleCallback ? panel.onVisibleCallback : _.noop;
      panel.secondaryActionMode = panel.secondaryActionMode ? panel.secondaryActionMode : false;
      panel.sourceUpdateEvent = panel.sourceUpdateEvent
        ? panel.sourceUpdateEvent
        : new eventCallbackManagerFactory('source_updated');
      panel.configUpdatedCallback = panel.configUpdatedCallback ? panel.configUpdatedCallback : _.noop;

      panel.globalFilter?.onChange(panel.internalID, _handleChangedFilter);
      panel.globalFilter?.restoreCondition();
      panel.condition = panel.globalFilter?.getAllConditions();

      panel.sourceUpdateEvent?.add_callback(panel.internalID, _wsChangeCb);
      panel.exploreButtonClickEvent?.add_callback(panel.internalID, toggleColumnByInternalName);
      panel.stepPreviewSequenceUpdateEvent?.add_callback('sequence_updated_event_explr_cntr_cb', function (sequence) {
        panel.sequence = sequence;
        panel.secondaryActionMode = panel.saveFilter = !Number.isInteger(sequence);
        panel.refreshAll(true);
      });
      openCards();
    }

    /**
     * A function responsible for creating a new explore card on a column
     * @param column
     * @param renderType
     * @param [trackEvent]
     * @param [selectedAggregation]
     * @param [customWidth]
     * @param [granularity]
     * @param [sortBy]
     * @returns mixed
     */
    function openColumn(
      column,
      renderType = null,
      trackEvent?,
      selectedAggregation?,
      customWidth?,
      granularity?,
      sortBy?
    ) {
      var existingCards = $.grep(panel.cardCollection.cards, function (c: any) {
        if (c.column) {
          return c.column.internal_name == column.internal_name;
        }
      });
      if (existingCards.length) {
        return;
      }
      var newCard = _getColumnCard(column, renderType);
      if (!sortBy) {
        sortBy = newCard.sortBy;
      }
      let resultantCard = addCard(newCard, undefined, selectedAggregation, customWidth, granularity, sortBy);
    }

    function _saveConfig() {
      if (!panel.inited) {
        return;
      }

      var cfg = [];

      panel.cardCollection.cards.forEach(function (card) {
        if (card.type == 'custom') {
          cfg.push({
            isCustomCard: true,
          });
        } else {
          let renderType = ExploreConsts.renderTypes.list;
          let selectedAgg = card.selectedAggregation;
          let customWidth = undefined;
          let granularity = undefined;
          if (card.column.type == c.numeric || card.column.type == c.date) {
            if (card.type == 'numeric' || card.type == 'date') {
              renderType = ExploreConsts.renderTypes.chart;
            }
            if (card.granularity) {
              granularity = card.granularity;
            }
          }
          if (card.type == 'text' && card.customWidth) {
            customWidth = card.customWidth || ExploreConsts.textCardDefaultWidth;
          }

          cfg.push({
            isCustomCard: false,
            column: card.column.internal_name,
            renderType: renderType,
            selectedAggregation: selectedAgg,
            customWidth: customWidth,
            granularity: granularity,
            sortBy: card.sortBy,
          });
        }
      });
      panel.config = cfg;
      panel.configUpdatedCallback({ config: cfg });
      panel.saveCb({ config: cfg });
    }

    function openCustomCard(trackEvent?) {
      var card = new CustomSummaryCard(panel.dataview);
      var existingCards = $.grep(panel.cardCollection.cards, function (c: any) {
        return c.type == 'custom';
      });

      if (existingCards.length) {
        return;
      }
      analyticsService.userEventTrack(c.userEvents.exploreCards.add, {
        cardType: 'custom',
        eventOrigin: `${panel.sourceType}.exploreCard.menu`,
      }, {
        columnType: card.column.type,
      });
      addCard(card);
      _saveConfig();
    }

    function toggleColumn(column, eventSource?) {
      var ccard = $.grep(panel.cardCollection.cards, function (c: any) {
        return c.column && c.column.internal_name == column.internal_name;
      });
      if (ccard.length == 1) {
        analyticsService.userEventTrack(c.userEvents.exploreCards.removed, {
          cardType: 'column',
          dataType: column.type,
          eventOrigin: `${panel.sourceType}.${eventSource}`,
        });
        removeCard(ccard[0], eventSource, 'column');
      } else {
        analyticsService.userEventTrack(c.userEvents.exploreCards.add, {
          cardType: 'column',
          type: column.type,
          eventOrigin: `${panel.sourceType}.${eventSource}`,
        }, {
          columnType: column.type,
        });
        openColumn(column, undefined);
      }
    }

    function toggleColumnByInternalName(col_internal_name, eventSource?) {
      var col = $.grep(panel.sourceMetadata, function (col: any) {
        return col.internal_name == col_internal_name;
      });
      if (col.length) {
        toggleColumn(col[0], eventSource);
      }
    }

    function toggleCustomCard() {
      var ccard = $.grep(panel.cardCollection.cards, function (c: any) {
        return c.type == 'custom';
      });
      if (ccard.length == 1) {
        removeCard(ccard[0], 'exploreCard.menu', 'custom');
      } else {
        openCustomCard(true);
      }
    }

    function addCard(card, index?, selectedAggregation?, customWidth?, granularity?, sortBy?) {
      card.onSelectionChanged('from_within_the_panel', getFilterHandler(card.id));
      panel.cardCollection.addCard(card, index);
      card.secondaryActionMode = panel.secondaryActionMode;
      card.sequence = panel.sequence;
      card.globalFilter = panel.globalFilter;
      if (card.secondaryActionMode) {
        card.onSecondaryAction = panel.onSecondaryAction;
        card.deselectSecondary = function () {
          card.selectedValues = null;
          card.isActionPossible = false;
        };
      }
      if (selectedAggregation) {
        card.selectedAggregation = selectedAggregation;
      }
      card.granularity = null;
      if (granularity && granularity.hasOwnProperty('internal')) {
        if (card.column.type == 'NUMERIC') {
          card.granularity = _.filter(ExploreConsts.numGranularityOptions, {
            internal: granularity.internal,
          })[0];
        } else if (card.column.type == 'DATE' && granularity.hasOwnProperty('type')) {
          card.granularity = _.filter(ExploreConsts.dateGranularityOptions, {
            internal: granularity.internal,
            type: granularity.type,
          })[0];
        }
      }
      if (customWidth) {
        card.customWidth = customWidth;
      }
      card.sortBy = sortBy;
      const condition = panel.globalFilter.getCondition(card.id);
      if (condition) {
        card.setCondition(panel.globalFilter.getCondition(card.id));
      } else {
        card.query();
      }
      _saveConfig();
      card.new = panel.inited;
      return card;
    }

    function removeCard(card, eventSource?, cardType?) {
      panel.dataview.exploreCardCloseEvent.fire_event(card.id, 'removeCard');
      if (eventSource && cardType) {
        analyticsService.userEventTrack(c.userEvents.exploreCards.removed, {
          cardType: cardType,
          type: card.type,
          eventOrigin: `${panel.sourceType}.${eventSource}`,
        });
      }
      panel.globalFilter.removeCondition(card.id);
      panel.cardCollection.removeCard(card);
      _saveConfig();

      if (eventSource === 'crossButtonOnCard' || eventSource === 'crossButtonOnExploreCard') {
        $store.state.publish.gridRemoveColumn(card.id);
        $store.commit('publish/setIsExploreSectionOpen', panel.cardCollection.cards.length > 0);
      }
    }

    function getFilterHandler(cardId) {
      return function (condition) {
        var card = panel.cardCollection.getCardById(cardId);
        if (condition) {
          panel.globalFilter.setCondition(cardId, condition);
          analyticsService.userEventTrack(c.userEvents.exploreCards.filtered, {
            type: card.type,
            eventOrigin: panel.sourceType + '.exploreCard.filterButton',
          }, {
            columnType: card.column.type,
          });
        } else {
          panel.removeCondition(card);
        }
      };
    }

    function _handleChangedFilter() {
      panel.condition = panel.globalFilter.getCondition();
      panel.cardCollection.reorder(panel.globalFilter.order);
      panel.cardCollection.applyFilters(panel.globalFilter.getCondition);
      panel.conditionCb({ condition: panel.condition });
      _saveConfig();
    }

    function openCards() {
      if (angular.isArray(panel.config)) {
        $.each(panel.config, function (i, cfg) {
          if (cfg.isCustomCard) {
            openCustomCard();
          } else {
            var column = utils.metadata.get_column_by_internal_name(panel.sourceMetadata, cfg.column);
            if (column) {
              openColumn(
                column,
                cfg.renderType,
                undefined,
                cfg.selectedAggregation,
                cfg.customWidth,
                cfg.granularity,
                cfg.sortBy
              );
            }
          }
        });
      }
      panel.dataAt = panel.sourceDataUpdatedAt;
      panel.inited = true;
    }

    function removeCondition(card, trackEvent?, source?) {
      card.deselect();
      panel.globalFilter.removeCondition(card.id);
      if (trackEvent) {
        analyticsService.userEventTrack(c.userEvents.exploreCards.filterRemoved, {
          type: card.type,
          eventOrigin: panel.sourceType + '.exploreCard.' + source,
        });
      }
    }

    function removeAllConditionsAfter(card) {
      let index = panel.cardCollection.cards.indexOf(card);
      let nextCard = panel.cardCollection.cards[index + 1];
      removeCondition(nextCard);
    }

    function removeAllConditions(trackEvent) {
      panel.condition = null;
      $.each(panel.cardCollection.cards, function (i, c) {
        c.deselect();
      });
      panel.globalFilter.reset();
      if (trackEvent) {
        analyticsService.userEventTrack(c.userEvents.exploreCards.filterResetAll, {
          eventOrigin: panel.sourceType + '.exploreCard.resetAllButton',
        });
      }
    }

    function _reconsile() {
      let newMetadata = panel.sourceMetadata,
        origConfig = _.cloneDeep(panel.config),
        origLength = panel.cardCollection.cards.length,
        cards = panel.cardCollection.cards;
      let columnsAdded = panel.cardCollection.cards.map((card) => card.column.internal_name);
      let configsToAdd = panel.config.filter((c) => !columnsAdded.includes(c.column));
      for (let i = 0; i < cards.length; i++) {
        let card = cards[i];
        if (card && card.column) {
          let cardConfig = origConfig.find((c) => c.column == card.column.internal_name);
          let column = newMetadata.find((i) => i.internal_name == card?.column?.internal_name);
          if (column && cardConfig) {
            //only add the card if it is present in the config and its column is present in the metadata
            let selectedAggregation = cardConfig?.selectedAggregation,
              sort = cardConfig?.sortBy,
              customWidth = cardConfig?.customWidth,
              granularity = cardConfig?.granularity;
            if (column.type != card.column?.type) {
              selectedAggregation = sort = customWidth = granularity = null;
              // remove card condition if there is a change in column type
              panel.removeCondition(card);
            }
            addCard(
              _getColumnCard(column, cardConfig?.renderType),
              i,
              selectedAggregation,
              customWidth,
              granularity,
              sort
            );
            card.isActionPossible = false;
          } else {
            removeCard(card);
            i--;
            let currIndex;
            for (let j = 0; j < panel.config.length; j++) {
              if (panel.config[j].column == card.column.internal_name) {
                currIndex = j;
                break;
              }
            }
            panel.config.splice(currIndex, 1);
          }
        }
      }
      // Add cards that are present in config but not in the card collection
      configsToAdd.forEach((config) => {
        let column = newMetadata.find((col) => col.internal_name == config.column);
        if (column) {
          openColumn(
            column,
            config.renderType,
            undefined,
            config.selectedAggregation,
            config.customWidth,
            config.granularity,
            config.sortBy
          );
        }
      });
      if (origLength != panel.cardCollection.cards.length && panel.cardCollection.cards.length == 0) {
        panel.isOpen = false;
      }
      _saveConfig();
    }

    function refreshAll(keepCondition) {
      if (!keepCondition) {
        panel.removeAllConditions();
      } else {
        // dataview row counter depend on the global filter event. As adding tasks change the row count triggering event is necesary but condition remain same.
        panel.globalFilter.fire_event();
      }
      _reconsile();
    }

    function saveFilterAsRule() {
      analyticsService.userEventTrack(c.userEvents.exploreCards.filterRuleFromExploreMenu, {
        eventOrigin: panel.sourceType + '.exploreMenu',
      });
      panel.saveFilterCallback({ optype: 'rule', param: { CONDITION: panel.condition } });
      panel.filterBarMenuOpen = false;
    }

    function saveFilterAsView(name) {
      analyticsService.userEventTrack(c.userEvents.exploreCards.createNewViewWithFiltersApplied, {
        eventOrigin: panel.sourceType + '.exploreMenu',
      });
      panel.saveFilterCallback({ optype: 'view', param: { NAME: name, CONDITION: panel.condition } });
      panel.filterBarMenuOpen = false;
    }
  };
}
