/*jslint node: true */

import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * @ngInject
 */
pivotChartMenuManagerFactory.$inject = ['eventCallbackManagerFactory', 'c', 'utils', 'filterManagerFactory', 'filterUI','$timeout',
                                 'modalService', 'elementConfig', 'analyticsService'];
export function pivotChartMenuManagerFactory(eventCallbackManagerFactory, c, utils, filterManagerFactory, filterUI, $timeout,
                                 modalService, elementConfig, analyticsService) {
  var yAxisTypes = {
    line: 'line',
    bar: 'bar'
  };

  var chartLimit = 1000;

  // TODO: make the following return from a function
  var defaultChartDisplayProperties = {
    type: 'chart',
    title: "Untitled",
    titleOptions: {
      show: true,
      fontSize: "14",
      alignment: "center"
    },
    autoComputeTitle: true,
    chartType: 'xy',
    legend: {
      enabled: false,
      position: 'bottom',
      align: 'center'
    },
    xAxes: {
      1: {
        showAxisLabel: true,
        position: 'bottom',
        autoComputeLabel: true
      }
    },
    showAxisLabels: true,
    series: [],
    FORMAT_INFO: {},
    invertAxis: false,
    enableZoomAndPan: false,
    yAxisBreakDown: false,
    drilldown: {
      enabled: false
    },
    SORT: [[undefined, 'ASC']],
    applyLimit: true
  };

  var fnToNameMap = {
    'SUM': 'Total ',
    'AVG': 'Avg ',
    'MIN': 'Min ',
    'MAX': 'Max ',
    'STDDEV': 'SD of ',
    'COUNT': 'Count'
  };

  var chartTypesMenu = [
    {type: c.chartTypes.xy, title: 'Line/Column'},
    {type: c.chartTypes.pie, title: 'Pie'}
  ];

  var dateTruncateExtractOptions = c.dateTruncateExtractOptions;

  var eventTrack = {
    columnChanged: function (params) { analyticsService.userEventTrack(c.userEvents.elements.editor.columnChanged, params) },
    nonPivotChartAggChanged: function (params) { analyticsService.userEventTrack(c.userEvents.elements.editor.nonPivotChartAggChanged, params) },
  };

  return {
    get: getMenu
  };

  function getMenu(options) {
    return  ChartMenu(options);
  }

  function ChartMenu(options) {
    var manager: any = {
      limitMax: elementConfig.rowLimits.chart
    };
    manager.columnsInited = {
      x: false,
      y: false
    };
    manager.managerType = "AGGREGATION";
    manager.isPivot = true;
    manager.changeChartPivotType = changeChartPivotType;
    manager.getParam = getParam;
    manager.setParam = setParam;
    manager.getDisplayProperties = getDisplayProperties;
    manager.chart_random_name = 'chart_' + utils.getRandomString();
    var chartEvent = new eventCallbackManagerFactory(manager.chart_random_name);
    manager.on_valid_update = chartEvent.add_callback;
    manager.save_chart = chartEvent.fire_event;
    manager.openConditionMenu = openConditionMenu;

    manager.delayedFireUpdate = delayedFireUpdate;
    manager.dateTruncateExtractOptions = dateTruncateExtractOptions;
    manager.currentDrillItem = undefined;

    manager.addDrilldown = addDrilldown;
    manager.editDrilldown = editDrilldown;
    manager.deleteDrilldown = deleteDrilldown;
    manager.toggleDrilldown = toggleDrilldown;
    manager.options = options;
    manager.handleColumnAddition = handleColumnAddition;
    manager.eventTrack = eventTrack;

    manager.sortSpec = {
      axis: null,
      direction: 'ASC'
    };
    manager.columnBeingDragged = undefined;
    manager.log = function () {
      }

    manager.sortables = [];

    init();
    update(options);

    function openConditionMenu(){
      filterUI.open(manager.filterManager).then(chartEvent.fire_event);
    }


    function init() {
      manager.chartTypesMenu = chartTypesMenu;
      manager.backup = null;
      if (!options.element.param) {
        manager.chart =  new AggregateChart(manager.options.metadata);
      }
    }

    function toggleDrilldown() {
      if (manager.element.display_properties.drilldown && manager.element.display_properties.drilldown.elementId) {
        chartEvent.fire_event();
      } else {
        manager.element.display_properties.drilldown.enabled = false;
        chartEvent.fire_event();
        addDrilldown();
      }
    }


    function addDrilldown() {
      var rootElementId = manager.element.display_properties.isDrilldown ?
        manager.element.display_properties.rootElementId : manager.element.id;
      var _newElement: any = {type: "CHART"};
      var params = manager.getParam(true);
      var dispProps = manager.getDisplayProperties();
      if (manager.isPivot && params.PIVOT.GROUP_BY.length > 0) {
        params.PIVOT.GROUP_BY = [{COLUMN: undefined}];
      }
      _newElement.param = params;
      var _additionalDispProps: any = {
        isDrilldown: true,
        rootElementId: rootElementId,
        drillUp: {
          elementId: manager.element.id,
          key: 'default'
        }
      };
      if (manager.isPivot) {
        _additionalDispProps.series = dispProps.series;
      }
      manager.modalController.selectElement(_newElement, undefined, undefined,
        _additionalDispProps);
      if (manager.drilldownManager) {
        manager.drilldownManager.openEmptyDrilldown(_newElement, _additionalDispProps);
      }
    }

    function editDrilldown(drilldownElementId) {
      manager.drilldownManager.openDrilldown(drilldownElementId);
      // manager.modalController.selectElementById(drilldownElementId);
    }

    function deleteDrilldown(seriesInternalName) {
      var deleteAlertPromise = modalService.deleteDrilldown({type: "drilldown"});
      deleteAlertPromise.then(function () {
        if (!seriesInternalName) {
          manager.drilldownManager.deleteAllDrillDownElements(options.element.id);
          delete options.element.display_properties.drilldown.default.elementId;
          options.element.display_properties.drilldown.enabled = false;
        } else {
          angular.forEach(manager.chart.aggregations, function obj(agg) {
            if (agg.display.internal_name == seriesInternalName) {
              manager.drilldownManager.deleteAllDrillDownElements(options.element.id);
              delete agg.display.drilldown;
            }
          });
        }
        chartEvent.fire_event();
      });
    }

    function delayedFireUpdate(){
      if(options.element && options.element.fireUpdate){
        $timeout(function(){
          options.element.fireUpdate(false);
        }, 1000);
      }
    }

    function update(options) {
      manager.options = options;
      manager.modalController = options.modalController;
      manager.element = options.element;
      manager.drilldownManager = options.drilldownManager;
      manager.metadata = options.metadata;

      if (manager.element.id) {
        manager.filterManager = filterManagerFactory.get_manager({
          metadata: options.metadata,
          teardownOptions: {
            teardown_callback: chartEvent.fire_event
          },
          dataviewId: options.dataview.id,
          context: options.context
        });
      }
      if (!manager.chart) {
        if (options.element.param) {
          if (options.element.param.PIVOT.GROUP_BY && options.element.param.PIVOT.GROUP_BY.length > 0) {
            manager.chart = new AggregateChart(manager.options.metadata);
            manager.isPivot = true;
          } else {
            manager.chart = new PlotChart(manager.options.metadata);
            manager.isPivot = false;
          }
        }
      }
      if (options.element && options.element.display_properties && manager.chart) {
        manager.chart.setDisplayProperties(options.element.display_properties);
      }
      if (options.additionalDisplayProperties && manager.chart) {
        angular.merge(manager.chart.display_properties, options.additionalDisplayProperties);
      }

      if (options.element && options.element.param) {
        setParam(options.element.param);
      } else {
        // setParam();
      }

      if (manager.drilldownManager) {
        if (manager.element.id) {
          manager.currentDrillItem = manager.drilldownManager.getDrillItemById(manager.element.id);
        } else {
          manager.currentDrillItem = manager.drilldownManager.getLatestDrillItem();
        }
      }
    }

    function getParam(allowInvalid) {
       // TODO: SENTRYERROR:FRONTEND-KF:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/480137038
      return manager.chart.getParam(allowInvalid);
    }

    function setParam(param) {
      manager.isPivot = param.PIVOT.GROUP_BY.length > 0;
      manager.chart.setParam(param);
    }

    function changeChartPivotType(isPivot) {
      if (!isPivot) {
        manager.chart = new PlotChart(manager.options.metadata);
        manager.isPivot = false;
      } else {
        manager.chart = new AggregateChart(manager.options.metadata);
        manager.isPivot = true;
      }
    }

    function handleColumnAddition(column) {
      var current_type = manager.chart.display_properties.chartType;
      if (column.type == "NUMERIC") {
        if (!(manager.chart instanceof PlotChart)) {
          manager.chart = new PlotChart(manager.options.metadata);
        }
        manager.isPivot = false;
      } else {
        if (!(manager.chart instanceof AggregateChart)) {
          manager.chart = new AggregateChart(manager.options.metadata);
        }
        manager.isPivot = true;
      }
      manager.chart.display_properties.chartType = current_type;
      // update(manager.options);
      manager.chart.handleColumnAddition(column);
      if (manager.options.additionalDisplayProperties) {
        angular.merge(manager.chart.display_properties, manager.options.additionalDisplayProperties);
      }
      manager.eventTrack.columnChanged({'index': 0, column: column, axisType: 'X'});
    }

    function getDisplayProperties() {
      var displayProperties = angular.extend({}, manager.chart.getDisplayProperties());
      if (manager.isPivot === true) {
        var labelColumn = manager.chart.label.column;
        var dataview_format_info = options.dataview.display_properties.FORMAT_INFO;
        if (labelColumn) {
          displayProperties.labelColumn = labelColumn.internal_name;
          displayProperties.truncateExtractInfo = null;
          if (labelColumn.type == c.date){
            displayProperties.FORMAT_INFO[labelColumn.internal_name] = manager.chart.label.truncateExtractInfo.value;
            if(manager.chart.label.truncateExtractInfo.param.hasOwnProperty('COMPONENT')){
              if(displayProperties.SORT[0][0] == labelColumn.internal_name){
                displayProperties.SORT[0][2] = manager.chart.label.truncateExtractInfo.param.COMPONENT;
              }
            }
            displayProperties.truncateExtractInfo = manager.chart.label.truncateExtractInfo;
          }
          else if (labelColumn.type == c.numeric) {
            if (dataview_format_info && dataview_format_info[labelColumn.internal_name]) {
              displayProperties.FORMAT_INFO[labelColumn.internal_name] = _.cloneDeep(dataview_format_info[labelColumn.internal_name] || {});
            }
            else {
              displayProperties.FORMAT_INFO[labelColumn.internal_name] = c.numericDefaultFormat;
            }
          }
        }
      }
      displayProperties.LIMIT = manager.chart.rowLimit || chartLimit;
      return displayProperties;
    }


    function AggregateChart(metadata) {
      var chart = this;
      chart.metadata = metadata;
      chart.numericColumns = $.grep(chart.metadata, function (col: any) {
        return col.type == c.numeric;
      });
      var yAxisDefaultDispProps = {comma_separated: false, decimal_spec: 2};
      chart.checkAndSetTitle = checkAndSetTitle;
      chart.setParam = setParam;
      chart.getParam = getParam;
      chart.display_properties = {};
      chart.getDisplayProperties = getDisplayProperties;
      chart.setDisplayProperties = setDisplayProperties;
      chart.setXAxis = setXAxis;
      chart.addYAxis = addYAxis;
      chart.setDefaultSort = setDefaultSort;
      chart.removeYAxis = removeYAxis;
      chart.handleSortChanges = handleSortChanges;
      chart.handleBreakDownChanges = handleBreakDownChanges;
      chart.handleApplyLimitChanges = handleApplyLimitChanges;
      chart.display_properties = _.cloneDeep(defaultChartDisplayProperties);
      chart.rowLimit = chartLimit;
      chart.rowLimitBy = null;
      chart.aggregations = [];
      chart.handleTypeChange = handleTypeChange;
      chart.handleColumnAddition = handleColumnAddition;
      chart.getAvailableAxisIndices = getAvailableAxisIndices;
      chart.checkIfAxesIsStackedWith = checkIfAxesIsStackedWith;
      chart.checkIfAxesIsCombinedWith = checkIfAxesIsCombinedWith;
      chart.label = new Axis();
      chart.hasMinimumParams = false;  // special variable, don't ask why
      chart.checkIfMinimumParamsExist = checkIfMinimumParamsExist;
      chart.addYAxis(undefined);

      function checkIfAxesIsStackedWith(agg) {
        return agg && $.grep(chart.aggregations, function (_agg: any) {
          return _agg  && (_agg.selfIndex !== agg.selfIndex && _agg.display.stackWith === agg.selfIndex && agg.display.stackingOn);
        }).length;
      }

      function checkIfAxesIsCombinedWith(agg: any) {
        return $.grep(chart.aggregations, function (_agg: any) {
          return _agg.selfIndex !== agg.selfIndex && _agg.display.axisIndex === agg.selfIndex;
        }).length;
      }

      function getAvailableAxisIndices(currentAgg) {
        return $.grep(chart.aggregations, function (agg: any) {
          return agg != currentAgg && (agg.display.stackWith === null || agg.display.stackWith === undefined || !agg.display.stackingOn) && agg.display.axisIndex === agg.selfIndex;
        })
      }


      function getDisplayProperties() {
        getParam();
        var FORMAT_INFO = {}, WS_FORMAT_INFO = {};
        if (manager.options.dataview.display_properties && manager.options.dataview.display_properties.FORMAT_INFO) {
          WS_FORMAT_INFO = manager.options.dataview.display_properties.FORMAT_INFO;
        }
        chart.display_properties.series = [];
        chart.aggregations.forEach(function (yAxis, i) {
          var props = yAxis.getDisplayProperties();
          chart.display_properties.series.push(props);
          FORMAT_INFO[yAxis.internal_name] = yAxisDefaultDispProps;
          if(yAxis.function != 'COUNT' && yAxis.column){
            if (WS_FORMAT_INFO[yAxis.column.internal_name] && WS_FORMAT_INFO[yAxis.column.internal_name].decimal_spec) {
              FORMAT_INFO[yAxis.internal_name].decimal_spec = WS_FORMAT_INFO[yAxis.column.internal_name].decimal_spec;
            }
          }
          else{
            FORMAT_INFO[yAxis.internal_name].decimal_spec = 0
          }

        });
        chart.display_properties.FORMAT_INFO = FORMAT_INFO;
        return chart.display_properties;
      }

      function setDisplayProperties(displayProperties) {
        angular.merge(chart.display_properties, displayProperties);
      }


      function handleTypeChange(){
        if(chart.display_properties.chartType == 'pie'){
          chart.aggregations = [chart.aggregations[0]];
        }
        chartEvent.fire_event();
      }

      function handleColumnAddition(column) {
        setXAxis(column);
        if (!chart.aggregations.length) {
          chart.addYAxis();
        }
        chartEvent.fire_event();
      }

      function setParam(param) {
        chart.hasMinimumParams = true;
        chart.label = new Axis();
        chart.breakdownLabel = new Axis();
        chart.aggregations = [];
        if (!param) {
          // chart.addYAxis();
          setDefaultRowLimitBy();
        }
        else {
          if (param.PIVOT.GROUP_BY.length) {
            chart.label.column = utils.metadata.get_column_by_internal_name(chart.metadata, param.PIVOT.GROUP_BY[0].COLUMN);
            chart.label.truncateExtractInfo = get_truncate_extract_info(param.PIVOT.GROUP_BY[0]);
            options.switcherColumn = chart.label.column;

            if(param.PIVOT.GROUP_BY.length == 2){
              chart.breakdownLabel.column =  utils.metadata.get_column_by_internal_name(chart.metadata, param.PIVOT.GROUP_BY[1].COLUMN);
              chart.breakdownLabel.truncateExtractInfo = get_truncate_extract_info(param.PIVOT.GROUP_BY[1]);
            }
          }


          var selectMap = {};
          angular.forEach(param.PIVOT.SELECT, function (select) {
            selectMap[select.INTERNAL_NAME] = select;
          });
          angular.forEach(chart.display_properties.series, function (item) {
            var select = selectMap[item.internal_name];
            if (select) {
              var newAxis = chart.addYAxis();
              newAxis.function = select.FUNCTION;
              newAxis.column = utils.metadata.get_column_by_internal_name(chart.metadata, select.COLUMN);
              newAxis.internal_name = select.INTERNAL_NAME;

              if (item.type == 'stackedColumn' && item.stacks.length) {
                newAxis.stacks = [];
                angular.forEach(item.stacks, function (stk) {
                  var stkSelect = selectMap[stk.internal_name];
                  if (stkSelect) {
                    var newStk = newAxis.addStackColumn();
                    newStk.function = stkSelect.FUNCTION;
                    newStk.column = utils.metadata.get_column_by_internal_name(chart.metadata, stkSelect.COLUMN);
                    newStk.internal_name = stkSelect.INTERNAL_NAME;
                  }
                });
              }
            }
          });
          checkIfMinimumParamsExist();
          if (param.hasOwnProperty('CONDITION') && manager.filterManager) {
            manager.filterManager.setParam(param.CONDITION, param?.EXECUTION_TIMESTAMP);
          }
          manager.columnsInited.x = true;
          manager.columnsInited.y = true;
          chart.rowLimit = chart.display_properties.LIMIT || chartLimit;
        }
        _setAggregatesDisplayProperties();
      }

      function _get_param_for_agg_axis(aggAxis){
        var src_internal_name = undefined;
        if(aggAxis.column){
          src_internal_name = aggAxis.column.internal_name;
        }
        var display_name = aggAxis.display.name;
        if(!display_name){
          if(aggAxis.function == 'COUNT'){
            display_name =  'Row count';
          }
          else{
            display_name = aggAxis.function + ' of ' + aggAxis.column.display_name;
          }
        }
        return {
          FUNCTION: aggAxis.function,
            COLUMN: src_internal_name,
            AS: display_name,
            INTERNAL_NAME: aggAxis.internal_name
        }
      }

      function handleSortChanges(){
        if(chart.label.column.internal_name == chart.display_properties.SORT[0][0]){
          chart.display_properties.SORT[0][1] = 'ASC';
        }
        else{
          chart.display_properties.SORT[0][1] = 'DESC';
        }
      }

      function getParam(allowInvalid?) {
        var aggregations = [];
        chart.aggregations.forEach(function (yAxis) {
          if (yAxis.column || yAxis.function == 'COUNT') {

            aggregations.push(_get_param_for_agg_axis(yAxis));
            if (yAxis.display.type == 'stackedColumn') {
              angular.forEach(yAxis.stacks, function (stk) {
                if(!stk.function){
                  throw "Invalid param";
                }
                if (stk.function == 'COUNT' && !stk.column) {
                  throw "Invalid param";
                }
                aggregations.push(_get_param_for_agg_axis(stk));
              });
            }
          } else if (!allowInvalid) {
            throw("Invalid Param");
          }
        });

        if (chart.type === c.chartTypes.pie) {
          aggregations.splice(1, aggregations.length);
        }
       // TODO: SENTRYERROR:FRONTEND-KF:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/480137038

        var labelParam = chart.label.getParam();

        if(!labelParam && !allowInvalid){
          throw("Invalid Param");
        }

        if(!aggregations.length && !allowInvalid){
          throw("Invalid Param");
        }

        var groupByParam = [labelParam];
        if(chart.display_properties.yAxisBreakDown){
          var breakDownParam = chart.breakdownLabel.getParam(true);
          if(!allowInvalid && !breakDownParam){
            throw ("Invalid Param");
          }
          groupByParam.push(breakDownParam);
        }

        var ret: any = {
          PIVOT: {
            GROUP_BY: groupByParam,
            SELECT: aggregations
          }
        };

        if(chart.display_properties.applyLimit){
          if(!chart.rowLimitBy){
            setDefaultRowLimitBy();
          }
        }

        if(manager.filterManager && manager.filterManager.condition) {
          ret.CONDITION = manager.filterManager.getParam();
        }

        return ret;
      }

      function setDefaultSort(){
        if(chart.label){
          chart.display_properties.SORT[0][0] = chart.label.column.internal_name;
        }
      }

      function setDefaultRowLimitBy(){
        // chart.rowLimitBy = chart.aggregations[0];
      }

      function handleBreakDownChanges(){
        if(!chart.display_properties.yAxisBreakDown){
          chart.breakdownLabel = new Axis();
        }
      }

      function handleApplyLimitChanges(){
        if(chart.display_properties.applyLimit){
          chart.rowLimit = chartLimit;
        }
        else{
          chart.rowLimit = null;
        }
      }

      function get_truncate_extract_info(labelSpec){
        var info = null;
        angular.forEach(dateTruncateExtractOptions, function(dteo){
          if(dteo.param.hasOwnProperty('TRUNCATE') && labelSpec.hasOwnProperty('TRUNCATE') && dteo.param.TRUNCATE == labelSpec.TRUNCATE){
            info = dteo;
            return false;
          }
          else if(dteo.param.hasOwnProperty('COMPONENT') && labelSpec.hasOwnProperty('COMPONENT') && dteo.param.COMPONENT == labelSpec.COMPONENT){
            info = dteo;
            return false;
          }
        });
        if(!info){
          info = dateTruncateExtractOptions[dateTruncateExtractOptions.length - 1];
        }
        return info;
      }

      function _setAggregatesDisplayProperties() {
        var seriesMap = {};
        angular.forEach(chart.display_properties.series, function (_item) {
          seriesMap[_item.internal_name] = _item;
        });
        angular.forEach(chart.aggregations, function (yAxis) {
          var props = seriesMap[yAxis.internal_name];
          if (props) {
            yAxis.setDisplayProperties(props);
          }
        });
      }

      function checkAndSetTitle() {
        if (chart.display_properties.autoComputeTitle) {
          chart.display_properties.title = _computeTitle();
        }
      }

      function _computeTitle() {
        var groups = [];
        angular.forEach(chart.aggregations, function (agg) {
          if (agg.internal_name && ((agg.column && agg.function)) || agg.function == 'COUNT') {
            groups.push(agg.display.name);
          }
        });
        if (groups.length && chart.display_properties.xAxes[1]) {
          return groups.join(" & ") + " by " + chart.display_properties.xAxes[1].name;
        } else {
          return "Untitled";
        }
      }

      function setXAxis(column) {
        if (!chart.label) {
          chart.label = new Axis();
        }
        chart.label.column = column;
        chart.label.onColumnChange();
        chart.label.checkTypeSetTruncateInfo();
        chart.setDefaultSort();
        options.switcherColumn = chart.label.column;
      }
      function addYAxis(column, aggFunc) {
        var newAxis = new AggregateAxis(chart.aggregations ? chart.aggregations.length : 0);
        if (column) {
          newAxis.column = column;
          newAxis.onColumnChange();
        }
        if (aggFunc) {
          newAxis.function = aggFunc;
          newAxis.onFunctionChange();
        }
        if (chart.aggregations.length == 1 && chart.display_properties.yAxisBreakDown) {
          chart.display_properties.yAxisBreakDown = false;
        }
        chart.aggregations.push(newAxis);
        chartEvent.fire_event();
        checkIfMinimumParamsExist();
        return newAxis;
      }

      function removeYAxis(yAxis) {
        if(yAxis.internal_name == chart.display_properties.SORT[0][0]){
          setDefaultSort();
        }

        var idx = chart.aggregations.indexOf(yAxis);
        chart.aggregations.splice(idx, 1);
        angular.forEach(chart.aggregations, function (agg, i) {
          var currIndex = agg.display.axisIndex;
          agg.selfIndex = i;
          agg.selfIndexName = 'Y' + (i + 1);
          if (currIndex == idx) {
            agg.display.axisIndex = i;
          } else if (currIndex > idx) {
            agg.display.axisIndex = currIndex - 1;
          }
        });

        checkAndSetTitle();
        if(yAxis == chart.rowLimitBy){
          setDefaultRowLimitBy();
        }
        chartEvent.fire_event();
        checkIfMinimumParamsExist();
      }

      function checkIfMinimumParamsExist() {
        $timeout(function () {
          var hasMin = false;
          angular.forEach(chart.aggregations, function (agg, i) {
            if (agg.function && (agg.function === 'COUNT' || agg.column)) {
              hasMin = true;
            }
          });
          chart.hasMinimumParams = hasMin;
        }, 0);
      }

      function Axis() {
        var axis = this;
        axis.column = null;
        axis.onColumnChange = onColumnChange;
        axis.checkTypeSetTruncateInfo = checkTypeSetTruncateInfo;
        axis.truncateExtractInfo = null;
        axis.getParam = getParam;

        function checkTypeSetTruncateInfo(){
          if (!axis.column) {
            return;
          }
          if(axis.column.type == c.date){
            axis.truncateExtractInfo = dateTruncateExtractOptions[0];
          }
          else{
            axis.truncateExtractInfo = null;
          }
        }


        function onColumnChange() {
          if (!axis.column) {
            return;
          }
          manager.columnsInited.x = true;

          if (!chart.display_properties.xAxes) {
            chart.display_properties.xAxes = {};
          }
          var label = chart.display_properties.xAxes[1];
          if (!label) {
            chart.display_properties.xAxes[1] = {
              autoComputeLabel: true
            };
          }
          if (label.autoComputeLabel) {
            chart.display_properties.xAxes[1].name = axis.column.display_name;
          }

          checkAndSetTitle();
        }

        function getParam(generateInternalName){
          if (axis.column) {
            var param: any = {COLUMN: axis.column.internal_name};
            if(generateInternalName){
              param.INTERNAL_NAME = 'column_' + utils.string.random(5, {lowercase: true});
            }
            if(axis.column && axis.column.type == 'DATE'){
         // TODO: SENTRYERROR:FRONTEND-KF:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/480137038
            angular.merge(param, axis.truncateExtractInfo.param);
            }
            return param;
          }
        }
      }

      function AggregateAxis(selfIndex) {
        var agg = this;

        // Param Specs:
        agg.function = '';
        agg.column = undefined;
        agg.internal_name = 'agg_column_' + utils.getRandomString(10);  // NOTE: this is not the same as `agg.column.internal_name`
        agg.stacks = [new StackColumn(agg.internal_name)];

        // View options & methods
        agg.allowedAggregations = [{val: 'COUNT'}, {group: 'Aggregate', val: 'SUM'}, {group: 'Aggregate', val: 'AVG'}, {group: 'Aggregate', val: 'MIN'}, {group: 'Aggregate', val: 'MAX'}, {group: 'Aggregate', val: 'STDDEV'}];
        agg.onColumnChange = onColumnChange;
        agg.onFunctionChange = onFunctionChange;
        agg.addStackColumn = addStackColumn;
        agg.removeStackColumn = removeStackColumn;
        agg.selfIndex = selfIndex;
        agg.selfIndexName = 'Y' + (selfIndex + 1);
        agg.axisIndexViewModel = selfIndex;
        agg.getDisplayProperties = getDisplayProperties;
        agg.setDisplayProperties = setDisplayProperties;
        agg.axisIndexChangeEnabled = false;
        // agg._setAxisIndexChangeEnabled = _setAxisIndexChangeEnabled;

        // Display properties:
        agg.display = {
          name: '',
          type: 'column',
          axisIndex: selfIndex,
          axisPosition: selfIndex % 2 ? 'right' : 'left',
          autoComputeLabel: true,
          stackType: 'regular',
          stackWith: undefined,
          stackingOn: false,
          showAxisLabel: true
        };

        // function _setAxisIndexChangeEnabled() {
        //   if (agg.display.stackable) {
        //     agg.axisIndexChangeEnabled = true;
        //   } else {
        //     if (agg.axisIndexViewModel !== agg.selfIndex) {
        //       agg.axisIndexChangeEnabled = true;
        //     } else {
        //       agg.axisIndexChangeEnabled = false;
        //     }
        //   }
        // }
        function _recomputeAxisName() {
          if (agg.display.autoComputeLabel) {
            if(agg.function == 'COUNT'){
              agg.display.name = fnToNameMap[agg.function];
              checkAndSetTitle();
            }
            else if(agg.column){
              agg.display.name = fnToNameMap[agg.function] + agg.column.display_name;
              checkAndSetTitle();
            }
          }
        }

        function onFunctionChange() {
          checkIfMinimumParamsExist();
          _recomputeAxisName();
        }

        function onColumnChange() {
          manager.columnsInited.y = true;
          _recomputeAxisName();
          checkIfMinimumParamsExist();
          chartEvent.fire_event();
        }

        function addStackColumn() {
          var stk = new StackColumn(agg.internal_name);
          agg.stacks.push(stk);
          return stk;
        }

        function removeStackColumn(col) {
          var validStacks = $.grep(agg.stacks, function (stk: any) {
            return stk.function && stk.column && stk !== col;
          });
          if (!validStacks.length) {
            return;
          }
          var idx = agg.stacks.indexOf(col);
          agg.stacks.splice(idx, 1);
          if (!agg.stacks.length) {
            addStackColumn();
          }
          chartEvent.fire_event();
        }

        function getDisplayProperties() {
          var disp = agg.display;
          disp.axisIndex = agg.axisIndexChangeEnabled ? agg.axisIndexViewModel : agg.selfIndex;
          disp.internal_name = agg.internal_name;
          if (disp.type == 'stackedColumn') {
            disp.stacks = [];
            angular.forEach(agg.stacks, function (stk, i) {
              if (stk.column && stk.function) {
                var sdisp = stk.display;
                sdisp.internal_name = stk.internal_name;
                disp.stacks.push(sdisp);
              }
            });
          }
          return disp;
        }

        function setDisplayProperties(disp) {
          agg.display = disp;
          agg.axisIndexViewModel = disp.axisIndex;
          if (agg.axisIndexViewModel !== agg.selfIndex) {
            agg.axisIndexChangeEnabled = true;
          } else {
            agg.axisIndexChangeEnabled = false;
          }
          if (disp.type == 'stackedColumn') {
            var stackMap = {};
            angular.forEach(disp.stacks, function (stk, i) {
              stackMap[stk.internal_name] = stk;
            });

            angular.forEach(agg.stacks, function (stk) {
              if (stackMap.hasOwnProperty(stk.internal_name)) {
                stk.display = stackMap[stk.internal_name];
              }
            });
          }
        }
      }

      function StackColumn(stackParentInternalName) {
        var stk = this;
        stk.onColumnChange = _recomputeStackName;
        stk.onFunctionChange = _recomputeStackName;

        stk.function = 'SUM';
        stk.column = undefined;
        stk.internal_name = 'agg_column_' + utils.getRandomString(10);  // NOTE: this is not the same as `stk.column.internal_name`
        stk.display = {
          name: '',
          autoComputeLabel: true,
          type: 'stack',
          stack: stackParentInternalName
        };

        function _recomputeStackName() {
          if (stk.display.autoComputeLabel && stk.column) {
            stk.display.name = fnToNameMap[stk.function] + stk.column.display_name;
          }
        }
      }
    }

    var defaultPlotDisplayProperties = {
      type: 'chart',
      title: "Untitled",
      chartType: 'xy',
      xAxes: {
        1: {
          "name": 'Labels',
          "autoComputeLabel":true
        }
      },
      metadata: [{
        display_name: 'Labels',
        internal_name: 'x_axis',
        type: 'TEXT'
      }, {
        display_name: 'Values',
        internal_name: 'y_axis',
        type: 'NUMERIC'
      }],
      series: [
        {
          "axisIndex": 0,
          "name": "Values",
          "stacking": "normal",
          "axisPosition": "left",
          "internal_name": "y_axis",
          "autoComputeLabel": true,
          "type": "column"
        }
      ],
      simpleChart: true,
      autoComputeTitle: true,
      invertAxis: false,
      enableZoomAndPan: false,
      legend: {
        enabled: false,
        position: 'bottom',
        align: 'center'
      },
      showAxisLabels: false,
      FORMAT_INFO: {},
      SORT: [[undefined, 'ASC']]
    };

    function PlotChart(metadata){
      var plotChart = this;
      plotChart.metadata = metadata;
      plotChart.getParam = getParam;
      plotChart.setParam = setParam;
      plotChart.getDisplayProperties = getDisplayProperties;
      plotChart.setDisplayProperties = setDisplayProperties;
      plotChart.toggleColumn = toggleColumn;
      plotChart.addColumn = addColumn;
      plotChart.removeColumn = removeColumn;
      plotChart.chartTypesMenu = chartTypesMenu;

      plotChart.random_name = 'table_' + utils.getRandomString();
      var plotMenuEvent = new eventCallbackManagerFactory(plotChart.random_name);
      plotChart.on_valid_update = plotMenuEvent.add_callback;
      plotChart.selectedColumns = [];
      plotChart.allowedAggregations = ['SUM', 'AVG', 'MIN', 'MAX', 'STDDEV'];
      plotChart.selectedAggregation = '';
      plotChart.display_properties = defaultPlotDisplayProperties;
      plotChart.handleColumnAddition = handleColumnAddition;
      plotChart.handleTypeChange = handleTypeChange;
      plotChart.checkAndSetTitle = checkAndSetTitle;
      plotChart.hasMinimumParams = false;
      plotChart.checkIfMinimumParamsExist = checkIfMinimumParamsExist;
      plotChart.numericColumns = $.grep(plotChart.metadata, function (col: any) {
        return col.type == c.numeric;
      });
      // addColumn();

      function handleTypeChange(){
        checkAndSetTitle();
        chartEvent.fire_event();
      }

      function checkAndSetTitle() {
        if (plotChart.display_properties.xAxes[1].autoComputeLabel) {
          var groups = [], name ="Columns";
          angular.forEach(plotChart.selectedColumns, function (col, i) {
            if (col.internal_name) {
              groups.push(col.display_name);
            }
          });
          if (groups.length) {
            name = groups.join(" , ");
          }
          plotChart.display_properties.xAxes[1].name = name;
        }
        if (plotChart.display_properties.series[0].autoComputeLabel) {
          plotChart.display_properties.series[0].name = _.startCase(_.lowerCase(plotChart.selectedAggregation));
        }
        if (plotChart.display_properties.autoComputeTitle) {
          plotChart.display_properties.title = _computeTitle();
        }
      }

      function checkIfMinimumParamsExist() {
        $timeout(function () {
          plotChart.hasMinimumParams = plotChart.selectedColumns.length > 1;
        }, 0);
      }

      function _computeTitle() {
        if (plotChart.display_properties.series[0].name &&  plotChart.display_properties.xAxes[1].name) {
          return plotChart.display_properties.series[0].name + " of " + plotChart.display_properties.xAxes[1].name;
        } else {
          return "Untitled";
        }
      }

      function handleColumnAddition(column) {
        addColumn(column);
        options.switcherColumn = column;
      }

      function getParam() {
        var selected = [];
        if (!plotChart.selectedAggregation) {
          throw 'select aggregate';
        }
        for(var i = 0; i < plotChart.selectedColumns.length; i++){
          var column = plotChart.selectedColumns[i].column;
          if (column.type == 'NUMERIC') {
            selected.push({
              FUNCTION: plotChart.selectedAggregation,
              COLUMN: column.internal_name,
              AS: plotChart.selectedColumns[i].title || column.display_name,
              INTERNAL_NAME: plotChart.selectedColumns[i].result_internal_name
            });
          }
        }
        if(selected.length == 0){
          throw 'select at least one';
        }

        return {
          PIVOT: {
            GROUP_BY: [],
            SELECT: selected
          }
        };
      }

      function setParam(param) {
        plotChart.selectedColumns = [];
        angular.forEach(param.PIVOT.SELECT, function (selectParam) {
          var column = $.grep(plotChart.numericColumns, function (col: any) {
            return col.internal_name == selectParam.COLUMN;
          });
          if (column.length == 1) {
            var val: any = {};
            val.column = column[0];
            val.title = selectParam.AS;
            val.result_internal_name = selectParam.INTERNAL_NAME;
            plotChart.selectedColumns.push(val);
            plotChart.selectedAggregation = selectParam.FUNCTION;
          }
        });
        options.switcherColumn = plotChart.selectedColumns[0].column;
        checkIfMinimumParamsExist();
      }

      function getDisplayProperties() {
        getParam();
        return plotChart.display_properties;
      }

      function setDisplayProperties(displayProperties) {
        plotChart.display_properties = displayProperties;
      }

      function toggleColumn(column){
        var index = plotChart.selectedColumns.indexOf(column);
        if(index== -1){
          column.title = column.display_name;

          if(!column.result_internal_name){
            column.result_internal_name = 'plot_val_' + utils.string.random(5);
          }

          plotChart.selectedColumns.push(column);
        }
        else{
          plotChart.selectedColumns.splice(index, 1);
        }

        var selectedColumns = [];
        $.each(plotChart.selectedColumns, function(i, col){
          selectedColumns.push(col);
        })

        plotChart.selectedColumns = selectedColumns;
        chartEvent.fire_event();
      }

      function addColumn(col){
        if (col) {
          var series: any = {};
          series.column = col;
          series.title = col.display_name;
          series.result_internal_name = 'plot_val_' + utils.string.random(5);
          plotChart.selectedColumns.push(series);
          checkAndSetTitle();
          chartEvent.fire_event();
        } else {
          plotChart.selectedColumns.push({result_internal_name: 'plot_val_' + utils.string.random(5)});
        }
        checkIfMinimumParamsExist();
      }

      function removeColumn(column){
        var index = plotChart.selectedColumns.indexOf(column);
        if(index !== -1){
          plotChart.selectedColumns.splice(index, 1);
        }
        checkAndSetTitle();
        chartEvent.fire_event();
        if (plotChart.selectedColumns.length == 1) {
          options.switcherColumn = plotChart.selectedColumns[0].column;
        }
        checkIfMinimumParamsExist();
      }

    }
    return manager;
  }

}

