<template>
  <div class="action-bar-column-browser-list">
    <mm-list
      v-if="filteredColumns.length"
      class="height-100 p-x-2 p-y-3"
      :class="{ 'column-browser-rename': isRenameEnabled }"
      :items="filteredColumns"
      :itemProps="{ class: 'p-a-0' }"
      :reorderable="isReorderEnabled"
      multipleSelect
      ref="columnList"
      @reordered="onListItemReordered"
      @itemClick="onListItemClick"
    >
      <template #item="{ item, itemIndex }">
        <div
          class="d-flex p-x-3 p-y-2 m-b-1 w-100"
          :class="{ 'column-browser-hidden-column': !item.show }"
          :style="{ background: item.selected ? 'var(--mm-color--p200)' : 'transparent' }"
        >
          <mm-icon v-if="isReorderEnabled" class="m-r-2 flex-shrink-0" name="drag" />
          <mm-checkbox
            v-if="!isReorderEnabled && !isRenameEnabled"
            class="m-r-2"
            :value="item.selected"
            noFocusOnClick
          />
          <label
            class="mm-column-browser-index m-r-3"
            :class="{ 'm-t-2': isRenameEnabled }"
            style="text-align: right; min-width: 18px"
            v-text="`${itemIndex + 1}.`"
          />
          <mm-icon
            class="m-r-3 flex-shrink-0"
            :name="item.type === 'numeric' ? 'number' : item.type"
            :color="item.show ? 'n900' : 'n300'"
          />
          <label
            v-if="!isRenameEnabled"
            class="flex-grow-1"
            style="word-break: break-all"
            :class="{ 'mm-text--body-bold': lastReorderedColumnId === item.id }"
            v-text="item.name"
          />
          <mm-text-input
            v-if="isRenameEnabled"
            :value="renameColumnNames[item.id]"
            class="flex-grow-1"
            :class="{ 'column-browser-column-renamed': renameColumnNames[item.id] != item.name }"
            small
            hideDetails
            @input="$emit('update:renameColumnNames', { ...renameColumnNames, [item.id]: $event })"
          />
          <mm-icon v-if="!item.show && !isRenameEnabled" class="m-l-2 flex-shrink-0" name="hide" color="n300" />
          <mm-icon
            v-if="item.exploreCard && !isRenameEnabled"
            class="m-l-2 flex-shrink-0"
            name="explore"
            :color="item.show ? 'n900' : 'n300'"
          />
        </div>
      </template>
    </mm-list>

    <span
      v-else
      class="d-flex-center height-100"
      style="color: var(--mm-color--n400)"
      v-text="$t('action_bar.column_browser.no_matching_columns')"
    />
  </div>
</template>

<script>
import { USER_EVENTS } from '@/constants'
import emitter from '@/plugins/emitter'

export default {
  name: 'action-bar-column-browser-list',
  props: {
    columns: Array, // column format is similar to store.state.dataEditor.columns format
    filteredColumns: Array, // simlar to columns prop filtered by search
    isReorderEnabled: Boolean,
    reorderedColumnIds: Array,
    lastReorderedColumnId: String,
    isRenameEnabled: Boolean,
    renameColumnNames: {
      type: Object,
      default: () => ({
        // "column_1": "renamed text"
      })
    }
  },
  methods: {
    onListItemReordered({ oldIndex, newIndex }) {
      this.$emit('update:lastReorderedColumnId', this.filteredColumns[oldIndex].id)
      const columnIds = this.filteredColumns.map((column) => column.id)
      const [removedItem] = columnIds.splice(oldIndex, 1)
      columnIds.splice(newIndex, 0, removedItem)
      this.$emit('update:reorderedColumnIds', columnIds)
    },
    onListItemClick({ item, itemIndex }) {
      if (this.isRenameEnabled) {
        const items = this.$refs.columnList.$el.querySelectorAll('.mm-list-item')
        const textContainer = items[itemIndex].querySelector('.mm-text-input--text-container')
        textContainer.focus()
      } else {
        const payload = {
          selected: !item.selected,
          columnIds: [item.id]
        }
        this.$store.commit('dataEditor/setColumnSelection', payload)
        emitter.emit('dataEditorScrollToColumn', { columnId: item.id })
        this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.COLUMN_SELECT, {
          viewId: this.$store.state.dataEditor.viewId,
          ...payload
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';

.action-bar-column-browser-list {
  height: 351px;

  ::v-deep .mm-list {
    .mm-list-item {
      .mm-checkbox--checkbox {
        margin-right: 2px;
      }

      label {
        color: var(--mm-color--n900);
      }

      .column-browser-hidden-column {
        label {
          color: var(--mm-color--n300);
        }
      }
    }

    &.column-browser-rename {
      .mm-list-item--index {
        line-height: 24px !important;
      }
      .mm-icon {
        margin-top: 4px;
      }
      .column-browser-column-renamed {
        .mm-text-input--text-container {
          @extend .mm-text--body-bold;
        }
      }
    }
  }
}
</style>
