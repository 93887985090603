import * as _ from 'lodash-es';
/**
 * @ngInject
 */
ActionPanelFactory.$inject = ['$timeout', 'modalService', 'toastNotification', 'ActionServiceFactory', 'resources',
  'eventCallbackManagerFactory', 'analyticsService', 'dataviewConfig', 'c', 'modificationRequestTracker', '$q',
  'DataviewService', 'FutureService', '$resource', 'config', 'ClipboardService', 'utils', 'VuexStore'];
export function ActionPanelFactory($timeout, modalService, toastNotification, ActionServiceFactory, resources,
                                   eventCallbackManagerFactory, analyticsService, dataviewConfig, c,
                                   modificationRequestTracker, $q, DataviewService, FutureService, $resource, config, ClipboardService, utils, $store) {

  return {
    get: function (dataview, mainGridUnit) {
      return new ActionPanel(dataview, mainGridUnit);
    }
  };

  function ActionPanel(dataview, mainGridUnit) {
    var self = this;
    var actionPanelOpenEvent = new eventCallbackManagerFactory('actionPanelOpenEvent_' + dataview.id);
    var actionPanelCloseEvent = new eventCallbackManagerFactory('actionPanelCloseEvent_' + dataview.id);
    var actionAddedEvent = new eventCallbackManagerFactory('actionAddedEvent_' + dataview.id);
    var actionPastedEvent = new eventCallbackManagerFactory('actionPastedEvent' + dataview.id);
    var publishCredsReadyEvent = new eventCallbackManagerFactory('publishCredsReadyEvent' + dataview.id);
    var actionPanelEditEvent = new eventCallbackManagerFactory('actionPanelEditEvent' + dataview.id);
    var actionPanelPasteEvent = new eventCallbackManagerFactory('actionPanelPasteEvent' + dataview.id);

    self.submit_in_progress = false;
    self.validate_in_progress = false;
    self.is_open = false;
    self.actionPastedEvent = actionPastedEvent;
    self.publishCredsReadyEvent = publishCredsReadyEvent;
    self.actionPanelEditEvent = actionPanelEditEvent;
    self.actionPanelPasteEvent = actionPanelPasteEvent;
    self.openId = Math.random();
    self.action_name = undefined;
    self.editing_action = undefined;
    self.waiting_for_completion = false;
    self.resetting = false;
    self.createParams = undefined;
    self.manager = undefined;
      // methods
    self.open = open_actions_panel;
    self.close = close_actions_panel;

    self.edit = open_action_panel_for_edit;
    self.editActionNote = openModalToEditActionNote;
    self.renameAction = openModalToRenameAction;
    self.delete = delete_action;
    self.copy = copy;
    self.paste = paste;
    self.executeAction = executeAction;
    self.toggleSuspend = toggleSuspend;
    self.submit = undefined;
    self.serversideValidate = undefined;
    self.serversideValidationError = undefined;
    self.regenerate_password = regenerate_password;
    self.regenerated_password_info ={
      show: false,
      pwd: null
    } ;
    self.showCredential = showCredential;
    // events
    self.actionPanelCloseEvent = actionPanelCloseEvent;
    self.actionPanelOpenEvent = actionPanelOpenEvent;
    self.actionAddedEvent = actionAddedEvent;

    var ActionService = ActionServiceFactory.get_by_dataview_id(dataview.id);

    function regenerate_password(publish){
      modalService.openRegeneratePasswordModal(publish).then(regenerate_password_confirmation);

      function regenerate_password_confirmation() {
        publish.regenerate_password().then(function (data) {
          if (data.STATUS == 'PROCESSING') {
            if (data.future_id) {
              FutureService.track(data.future_id, function (future) {
                if (future.status == "processing") {
                  return;
                }
                else if (future.status == "success") {
                  // get new password from response
                  // This is required in order to show copy
                  // password in toast
                  var new_password = future.response.password;
                  self.regenerated_password_info.show = true;
                  self.regenerated_password_info.pwd = new_password;
                  ActionService.update_list();
                  $timeout(function () {
                    self.regenerated_password_info.show = false;
                    self.regenerated_password_info.pwd = null;
                  }, 10000);
                }
                else if (future.status == "error") {
                  toastNotification.error('Unable to change password');
                }
                analyticsService.userEventTrack(c.userEvents.publishEvents.publishPasswordReset, {
                  eventOrigin: "action_panel",
                  status: future.status
                });
              });
            }
          }
        });
      }
    }

    function open_actions_panel(action_name, createParams, editAction){
      $timeout(function () {
        close_actions_panel();
      });
      $timeout(function () {
        self.is_open = true;
        actionPanelOpenEvent.fire_event();
        self.submit_in_progress = false;
        self.openId = Math.random();
        self.action_name = action_name;
        if (editAction) {
          self.editing_action = editAction;
        }
        if (createParams) {
          self.createParams = createParams;
        }
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.actionMenuEvents.actionSettings, {
          actionName: action_name,
          menuType: "actions",
          eventOrigin:"dataview"
        });
      });
    }

    function close_actions_panel(){
      self.is_open = false;
      self.action_name = null;
      self.editing_action = undefined;
      self.createParams = undefined;
      self.manager = undefined;
      actionPanelCloseEvent.fire_event();
    }

    function copy(action, taskwise_info){
      var action_info = _.cloneDeep(action)
      let newTargetProperties = action_info['target_properties']
      delete action_info?.target_properties?.trigger_id;

      if ([c.actions.mysql, c.actions.postgres, c.actions.redshift, c.actions.elasticsearch, c.actions.mssql, c.actions.sftp].indexOf(action_info.handler_type)!=-1){
        delete action_info.target_properties.password
        delete action_info.target_properties.trigger_id
      }

      if (action_info.sequence){
        var metadata = taskwise_info[action_info.sequence]['metadata']
      }else{
        let task_sequences = Object.keys(taskwise_info)
        var metadata = _.cloneDeep(utils.metadata.getMetadata(taskwise_info, task_sequences.length-1));
      }

      /**For save as ds ensure the display names in the params are replaced with latest display names in wksp display properties */
      if (action_info.handler_type == 'internal_dataset'){
        // LAST_BATCH_ID tells the batch_id that was created in the last run
        // TARGET_DS_ID is the dataset id that was created by the trigger
        // For a new internal_dataset
        // -> there is no last batch_id
        // -> the target dataset is yet to be created
        delete newTargetProperties['LAST_BATCH_ID']
        newTargetProperties["TARGET_DS_ID"] = null

        if(action_info?.target_properties?.TRANSFORM?.CROSSTAB){
            // do nothing, bcz further the metadata will have latest display names and thats sufficient

        }else{
          for (const key in  action_info.target_properties.COLUMN_MAPPING){
            let col = utils.metadata.get_column_by_display_name(metadata, key)
            // Update column mapping
            if (col && dataview.display_properties?.COLUMN_NAMES){
              if (Object.keys(dataview.display_properties.COLUMN_NAMES).indexOf(col?.internal_name)!=-1){
                action_info.target_properties.COLUMN_MAPPING[dataview.display_properties.COLUMN_NAMES[col.internal_name]] = action_info.target_properties.COLUMN_MAPPING[key]
                delete action_info.target_properties.COLUMN_MAPPING[key]
              }
            }
          }

          // Update extended column mapping
          for (const ke in  action_info.target_properties.EXTENDED_COLUMN_MAPPING){
            if(dataview.display_properties.COLUMN_NAMES){
              if(Object.keys(dataview.display_properties.COLUMN_NAMES).indexOf(action_info.target_properties.EXTENDED_COLUMN_MAPPING[ke]['SOURCE_COLUMN']['internal_name'])!=-1){
                action_info.target_properties.EXTENDED_COLUMN_MAPPING[ke]['SOURCE_COLUMN']['display_name']=dataview.display_properties.COLUMN_NAMES[action_info.target_properties.EXTENDED_COLUMN_MAPPING[ke]['SOURCE_COLUMN']['internal_name']]
              }
            }
          }
        }
      }
      metadata = utils.metadata.applyDisplayChangesReturnMetadata(metadata, dataview.display_properties, [], {}, false);


      let requiredActionInfo = {}

      requiredActionInfo['metadata'] = metadata
      requiredActionInfo['target_properties'] = newTargetProperties
      requiredActionInfo['condition'] = action_info['condition']
      requiredActionInfo['sequence'] = action_info['sequence']
      requiredActionInfo['handler_type'] = action_info['handler_type']
      requiredActionInfo['desc'] = action_info['desc']
      requiredActionInfo['stepType'] = action_info['stepType']

      ClipboardService.set(requiredActionInfo).then((data)=>{
        self.pastePossible = true;
        toastNotification.success("Action copied");
      });
    }

    function paste(data, sequence?){
      $timeout(function () { close_actions_panel();})
      $timeout(function () {
        var action_name = data.handler_type
        if (data.handler_type =='internal_dataset' && data.target_properties.TRANSFORM){
          action_name = 'pivot_table'
        }
        if (data.handler_type =='internal_dataset' && data.target_properties.export_project){
          action_name = 'export_to_project'
          // reset some param as export to project doesn't have append
          data.target_properties.TARGET_DS_ID = null;
          data.target_properties.LAST_BATCH_ID = null;
        }

        if (sequence!=null){
          data.sequence = sequence
        }
        self.actionPastedEvent.fire_event(data)

        self.is_open = true;

        self.submit_in_progress = false;
        self.openId = Math.random();
        self.action_name = action_name;

        self.createParams = data;
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.actionMenuEvents.actionSettings, {
          actionName: action_name,
          menuType: "actions",
          eventOrigin:"dataview"
        });
        })

    }

    function delete_action(action) {
      if(!dataview.pipeline_autorun_enabled){
       _delete_action(action)
      }else{
        modalService.deleteOrSuspendAction(action, 'delete', _delete_action).then(delete_success);
      }
      function _delete_action(action){
        if(action.handler_type == 'publishdb'){
        ActionService.delete_publish(action).then(delete_success);
      } else {
        ActionService.delete_action(action).then(delete_success);
        }
      }
      function delete_success() {
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.actionMenuEvents.actionDeleted,
          {eventOrigin: "dataview", menuType: "actions"})
        if (action.handler_type != 'publishdb' && dataview.pipeline_autorun_enabled){
          toastNotification.success('Automatic Exporting stopped');
        }
        if (self.is_open && self.editing_action && self.editing_action.id===action.id) {
          close_actions_panel();
        }
        resources.update();
        DataviewService.fire_on_update_tasks_actions_dependencies(true, action.ws_id);
      }
    }

    function executeAction(action) {
      if (dataview.draft_mode === 'dirty' ||dataview.pipeline_status === 'error'|| ['suspended', 'suspending'].indexOf(action.status)!==-1){
        return
      }
      action.status = 'starts_executing'
      // when an action is executed, set action_in_progress to true.
      // It's use case is in pipeline reordering - when action is being run, dont allow reordering
      ActionService.actionProgressEvent.fire_event('in_progress');
      action.executeAction().then(function (data) {
        successActionExecuteCallback(data, dataview, action);
      }, function (data) {
        var error_message = "Error executing action. Contact support for help."
        if (data.hasOwnProperty('data') && data.data.hasOwnProperty('ERROR_MESSAGE')){
          error_message = data.data.ERROR_MESSAGE;
        }
        toastNotification.error(error_message)
        failureExecuteActionCallback(dataview);
      });
    }

    function toggleSuspend(action, newState) {
      if(!dataview.pipeline_autorun_enabled){
        _toggle_suspend(action)
      }else{
        if (newState == 'suspend')
          modalService.deleteOrSuspendAction(action, 'suspend', _toggle_suspend);
        else
          _toggle_suspend(action)
      }
      function _toggle_suspend(action){
        ActionService.actionProgressEvent.fire_event('in_progress');
        action.toggleSuspend(newState).then(function (response) {
          if (dataview.pipeline_autorun_enabled){
          toastNotification.success("Action " + response.trigger_status);
        }
          ActionService.update_list();
          ActionService.actionProgressEvent.fire_event('complete');
        }, function (response) {
          var error_message = "There was some error while peforming this action"
          if (response.hasOwnProperty('data') && response.data.hasOwnProperty('ERROR_MESSAGE')){
            error_message = response.data.ERROR_MESSAGE;
          }
          toastNotification.error(error_message);
          ActionService.update_list();
          ActionService.actionProgressEvent.fire_event('error');
        });
      }
    }

    function successActionExecuteCallback(data, dataview, action) {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.actionMenuEvents.runAction,
        {eventOrigin: "dataview", menuType: "actions"});
      // decoupling toast message so that Publish toast msg can be
      // over ridden.
      var toastString = 'Running Action';
      var toastStringSuccess = 'Action updated';
      var toastfailure = 'Action could not be updated'

      if (action.handler_type === "publishdb"){
        toastString = `Republishing to Database`;
        toastStringSuccess = 'View republished';
        toastfailure = 'Publish failed'
      }

      if (!['internal_dataset','sftp','bigquery'].includes(action.handler_type))
        toastNotification.success(toastString);

      function future_tracker(future) {
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          ActionService.actionProgressEvent.fire_event('complete');
          DataviewService.fire_on_update_tasks_actions_dependencies(true, action.ws_id);
          if (action.additional_properties && action.additional_properties.no_notification) {
            toastNotification.success(toastStringSuccess);
          }

        }
        else if (future.status == "error") {
          ActionService.update_list();
          ActionService.actionProgressEvent.fire_event('error');
          if (action.additional_properties && action.additional_properties.no_notification) {
            toastNotification.error(toastfailure);
          }
          if (future?.response?.message) {
            let failure_msg = future?.response?.message;
            toastNotification.error(failure_msg);
          }
          
        }
        // Updation of action list is needed in order to remove run now button.
        ActionService.update_list();

      }

      if (data.STATUS == 'PROCESSING') {
        if (data.future_id) {
          FutureService.track(data.future_id, future_tracker)
        }
      }else{
        ActionService.actionProgressEvent.fire_event('error');
      }
    }

    function failureExecuteActionCallback(dataview){
      ActionService.actionProgressEvent.fire_event('error');
    }

    function openModalToRenameAction(action) {
      modalService.openRenameActionModal(action).then(function (display_info) {
        ActionService.editDisplayInfo(action, display_info).then(ActionService.update_list, edit_failure);
      });

      function edit_failure() {
        toastNotification.error('Rename task failed. Please try again later.');
      }
    }


    function openModalToEditActionNote(action){
      modalService.openEditActionNoteModal(action).then(function (display_info) {
        ActionService.editDisplayInfo(action, display_info).then(ActionService.update_list, edit_failure);
        // ActionService.update_list();
      });

      function edit_failure() {
        toastNotification.error('Editing task note failed. Please try again later.');
      }
    }

    function open_action_panel_for_edit(edit_action) {
      let action_name = edit_action.handler_type;
      // additional check for crosstab as it shares same internal name with DS
      if (edit_action.handler_type == "internal_dataset") {
        if (edit_action.target_properties.TRANSFORM != null) {
          action_name = "pivot_table";
        }
        else if (edit_action.target_properties.export_project) {
          action_name = 'export_to_project';
        }
      }

      if (['postgres', 'mssql', 'bigquery', 'mysql', 'redshift', 'elasticsearch', 'sftp'].includes(edit_action.handler_type)) {
        actionPanelEditEvent.fire_event(edit_action)
      } else {
        open_actions_panel(action_name, undefined, edit_action);
      }
    }

    function showCredential(action) {

      let odbc_type = action.target_properties.odbc_type;
      let credential;
      const project_id = $store.state.projectId;
      let credentials_resource = $resource(config.api.publishCredentials);
      credentials_resource.get({odbc_type: odbc_type, project_id: project_id}).$promise.then(
        computeDisplayModalText, failure);

        function computeDisplayModalText(data){
          credential = data.credentials;
          if (credential){
          action.target_properties.database = credential.database;
          action.target_properties.password = credential.password;
          action.target_properties.user_name = credential.username;
          action.target_properties.host = credential.host_ip;
          action.target_properties.port = credential.port;

          // This is required to copy all credentials
          action.target_properties['all_credentials'] = {
            'host': action.target_properties.host,
            'port': action.target_properties.port,
            'database': action.target_properties.database,
            'username': action.target_properties.user_name,
            'password': action.target_properties.password,
            'table': action.target_properties.table
          };
          // modalService.showCredential(action, self);
          publishCredsReadyEvent.fire_event(action.target_properties['all_credentials'])
          analyticsService.userEventTrack(c.userEvents.publishEvents.openCredentials, {
            eventOrigin: "action_panel",
            status: "success"
          });
        }
       }
        function failure(){
          toastNotification.error('Failed to get credentials!');
          analyticsService.userEventTrack(c.userEvents.publishEvents.openCredentials, {
            eventOrigin: "action_panel",
            status: "failure"
          });
          return;
        }

    }
  }
}
