import { render, staticRenderFns } from "./AdminSidebar.vue?vue&type=template&id=26755adc&scoped=true&"
import script from "./AdminSidebar.vue?vue&type=script&lang=js&"
export * from "./AdminSidebar.vue?vue&type=script&lang=js&"
import style0 from "./AdminSidebar.vue?vue&type=style&index=0&id=26755adc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26755adc",
  null
  
)

export default component.exports