import * as angular from 'angular';
import * as _ from 'lodash-es';
/**
 * @ngInject
 */
dataviewSafeDeleteResetService.$inject = ['$q', 'config', 'modificationRequestTracker', '$resource', 'DataviewService','FutureService','$uibModal', '$rootScope'];
export function dataviewSafeDeleteResetService($q, config, modificationRequestTracker, $resource, DataviewService, FutureService, $uibModal, $rootScope){
  return {
    delete: del,
    reset: resetWksp
  };

  function del(dataview_id){
    var deferred = $q.defer();
    var safe_delete_res = $resource(config.api.dataviewSafeDeleteRequest);
    safe_delete_res.save({
      dataviewId: dataview_id
    }, {}).$promise.then(function (data) {
      future_request_tracker(data.future_id).then(deferred.resolve, deferred.reject);
      
    });

    function future_request_tracker(future_id) {
      var deferred = $q.defer();
      FutureService.track(future_id, data_tracker);
  
      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        } else {
          var scp = $rootScope.$new();
          scp.dataviewId = dataview_id;
          scp.type = 'discard_dataview';
          angular.extend(scp, response);
          modificationRequestTracker.handleFailedRequest(scp)

          const openConfirmationModal = scp => {
            var confirmation = $uibModal.open({
              templateUrl: config.templates.wkspModificationAlert,
              windowClass: 'modal-size-medium discard-step',
              scope: scp
            })
            return confirmation.result
          };

          var confirmationPromise = openConfirmationModal(scp);
          confirmationPromise.then(function (result) {
            deferred.reject(response);
          }, function(){
            deferred.reject(response);
          });
          
        }
      }
      return deferred.promise;
    }

    return deferred.promise;
  }

  function resetWksp(dataview_id){
    /**NOTE
     * Reset view is called only from the nav bar and its implemented there.
     * The following piece is obsolete and no longer used.
     * Unlike delete method in this service which is used when user deletes a view from the preview panel in landing page
     */
    var deferred = $q.defer();
    var resetResource = $resource(config.api.dataviewReset, {wsId: dataview_id});
    resetResource.save({}, {}).$promise.then(function (data) {
      modificationRequestTracker.trackWkspRequest(dataview_id, data.request_id).then(_resolve, deferred.reject);
    });

    function _resolve(data){
      var ws = DataviewService.get_by_id(dataview_id);
      ws.fire_reset();
      deferred.resolve(data);
    }
    return deferred.promise;
   
  }
  
}
/**
 * @ngInject
 */
dataviewRerunService.$inject = ['$q', 'config', '$resource'];
export function dataviewRerunService($q, config, $resource){
    return {
      rerun: rerun
    };

    function rerun(dataview_id,force_run=false){
      var deferred = $q.defer();
      $resource(config.api.dataviewRerun, {wsId: dataview_id}).save({'force_run': force_run}).$promise.then(success_callback, deferred.reject);
      function success_callback(data) {
        deferred.resolve(data)
      }
      return deferred.promise;
    }

}

