import * as $ from 'jquery';
import * as humanize from 'humanize';

/**
 * @ngInject
 *
 * Generic cell menu implementation, requires gridUnit instance
 */
cellMenu.$inject = ['clickEvents', '$timeout', 'utils'];
export function cellMenu(clickEvents, $timeout, utils) {
  return {
    link: function cellMenuViewLink(scope, element, attrs) {
      var gridUnit = scope.$eval(attrs.cellMenu),
        lastKnownScrollLeft = false,
        lastKnownScrollTop = false,
        cols,
        _clickLock = false;

      if(gridUnit.dataview.isReadOnly){
        return;
      }
      if (gridUnit.gridInstance && gridUnit.gridInstance.grid) {
        subscribeListeners();
      } else {
        gridUnit.onGridInit('subscribeCellMenuListeners', subscribeListeners);
      }


      function handleCellSelect(rowIndex, columnIndex) {
        gridUnit.cellMenu.show();
        if (rowIndex) { gridUnit.cellMenu.rowIndex = rowIndex; }
        if (columnIndex) { gridUnit.cellMenu.columnIndex = columnIndex; }
        cols = gridUnit.gridInstance.grid.getColumns();
        var columnId = cols[columnIndex].id;
        gridUnit.cellMenu.columnId = columnId;
        var _column = utils.metadata.get_column_by_internal_name(gridUnit.dataview.metadata, columnId);
        gridUnit.cellMenu.column = _column;
        gridUnit.cellMenu.value = gridUnit.gridInstance.grid.getDataItem(rowIndex)[columnId];

        if (gridUnit.columnMenu.visible) {
          gridUnit.columnMenu.hide(); // hide the column menu before showing cell menu
        }
        if (_repositionMenu(rowIndex, columnIndex)) {
          gridUnit.cellMenu.show();
          gridUnit.cellMenu._fireOnCellSelected();
        };
      }
      var _debouncedhandleCellSelect = utils.debounce(handleCellSelect, 50);

      function _cancelMenuAndReset() {
        _clickLock = false;
        clickEvents.clearSelection();
        gridUnit.cellMenu.reset();
        gridUnit.cellMenu.hide();
      }
      function clickListener(e) {
        if (_clickLock) {
          return;
        }
        _clickLock = true;
        gridUnit.cellMenu.reset();
        var cell = gridUnit.gridInstance.grid.getCellFromEvent(e);
        if (!cell || !cell.hasOwnProperty('row') || !cell.hasOwnProperty('cell')) {
          _cancelMenuAndReset();
          return;
        }
        var col = gridUnit.gridInstance.grid.getColumns()[cell['cell']];
        if (col.id == "index") {
          _cancelMenuAndReset();
          return;
        }
        _debouncedhandleCellSelect(cell.row, cell.cell);
        $timeout(function () {
          // add style to selected cell
          var cellCss = {};
          cellCss[cell.row] = {};
          var colId = cols[cell.cell].id;
/*
          cellCss[cell.row][colId] = "cell-menu-selected";
          gridUnit.gridInstance.grid.removeCellCssStyles("cellMenuSelected");
          $timeout(function () {
            if (!gridUnit.cellMenu.selection.length) {
              clickEvents.clearSelection();
              cellCss[cell.row][colId] = "cell-menu-selected";
            } else {
              cellCss[cell.row][colId] = "cell-menu-partial-selected";
            }
            gridUnit.gridInstance.grid.addCellCssStyles("cellMenuSelected", cellCss);
            _clickLock = false;
          }, 10);
 */
        }, 150);
      }

      var _debouncedRepositionOnScroll = utils.debounce(_repositionIfAlreadyVisible, 300);
      var _debouncedRepositionOnScrollLeadingEdge = utils.debounce(_repositionIfAlreadyVisible, 500, true);
      function scrollHandler(e, data) {
        gridUnit.cellMenu._inViewPort = false;
        if (gridUnit.cellMenu.visible) {
          $timeout(function () {
            gridUnit.cellMenu._inViewPort = false;
          });
          _debouncedRepositionOnScrollLeadingEdge();
          _debouncedRepositionOnScroll();
        }
      }

      // subscribe listeners
      function subscribeListeners() {
        clickEvents.onSelect("cellMenuSelectListener" + gridUnit.dataview.id, selectListener);
        gridUnit.gridInstance.grid.onClick.subscribe(handleClick);
        gridUnit.gridInstance.grid.onScroll.subscribe(scrollHandler);
        gridUnit.cellMenu.onHide('removeActiveCell', removeActiveCell);
        clickEvents.onClick('deselectCellMenuOnOutsideClick' + gridUnit.dataview.id, deselectCellMenuOnOutsideClick);
      }

      function handleClick(e) {
        $timeout(function () {
          clickListener(e);
        }, 150);
      }

      function _repositionMenu(rowIndex, columnIndex, preventAutoScroll = undefined) {
        var cell: any = {}, viewPort, grid, gridTop, viewPortEle, colId, colHeader, colHeaderHeight;
        var lastKnownRow = rowIndex != undefined ? rowIndex : gridUnit.cellMenu.rowIndex;
        var lastKnownColumn = columnIndex != undefined ? columnIndex : gridUnit.cellMenu.columnIndex;
        if (lastKnownColumn == undefined || lastKnownRow == undefined) { return false;}
        grid = gridUnit.gridInstance.grid;

        viewPort = grid.getViewport();
        viewPortEle = $(grid.getCanvasNode()).parent();
        colHeader = viewPortEle.parent().children('.slick-header').first();
        colHeaderHeight = colHeader.height();

        cell.row = lastKnownRow;
        cell.column = lastKnownColumn;
        colId = cols[cell.column].id;

        var cellNode = $(grid.getCellNode(cell.row, cell.column));
        cell.node = cellNode;
        if (!cell.node.length) {
          // scroll into view
        }
        gridTop = viewPortEle.scrollTop();
        viewPort.topPx = gridTop;
        viewPort.bottomPx = gridTop + viewPortEle.height();
        cell.position = grid.getCellNodeBox(cell.row, cell.column);
        cell.width = cell.node.outerWidth();
        cell.height = cell.node.outerHeight();

        // if cell is not in visible range do not show the menu
        if (
       // TODO: SENTRYERROR:FRONTEND-7S:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/412262807
          ((cell.position.left + cell.width) < viewPort.leftPx) ||
          (cell.position.left > viewPort.rightPx) ||
          (cell.position.top < viewPort.topPx) ||
          (cell.position.bottom > viewPort.bottomPx)
        ) {
          return false;
        }

        if (_checkIfMenuFitsOnTheRight(cell, viewPort)) {
          element.css('right', '');
          element.css('left', (cell.position.left - viewPort.leftPx) + cell.width - 18);
          element.removeClass('left-col-menu');
        } else {
          element.css('left', '');
          element.css('right', (viewPort.rightPx - cell.position.left ) + 2);
          element.addClass('left-col-menu');
        }

        if (_checkIfMenuFitsOnTheBottom(cell, viewPort)) {
          element.css('top', (cell.position.top + colHeaderHeight - gridTop));
        } else {
          element.css('top', (cell.position.top + (cell.height) + colHeaderHeight - gridTop - element.outerHeight()));
        }
        lastKnownScrollLeft = viewPort.leftPx;
        lastKnownScrollTop = viewPort.topPx;
        return true;
      }

      function selectListener(selection, clickEvent, selectionEvent) {
        var target = $(clickEvent.target);
        if (selection.length && target.closest('.slick-cell').length &&
          (!selectionEvent || (selectionEvent.anchorNode == selectionEvent.focusNode))) {
          clickListener(clickEvent);
          $timeout(function () {
            var offsets = [selectionEvent.anchorOffset, selectionEvent.focusOffset].sort(function (a,b) {
                return a - b;
              }
            );
            gridUnit.cellMenu.selection = selection;
            gridUnit.cellMenu.selectionStart = offsets[0];
            gridUnit.cellMenu.selectionEnd = offsets[1] - 1;
            gridUnit.cellMenu.shortenedSelection = humanize.truncatechars(selection, 10);
          }, 100);
        }
      }
      function removeActiveCell() {
        if (!gridUnit.cellMenu.visible) {
          gridUnit.gridInstance.grid.removeCellCssStyles("cellMenuSelected");
        }
      }

      function _checkIfMenuFitsOnTheRight(_cell, _viewPort) {
        return (_cell.position.left + _cell.width + element.outerWidth()) < _viewPort.rightPx;
      }
      function _checkIfMenuFitsOnTheBottom(_cell, _viewPort) {
        return (_cell.position.top + element.outerHeight()) < _viewPort.bottomPx;
      }

      function _repositionIfAlreadyVisible() {
        $timeout(function () {
        // TODO: SENTRYERROR:FRONTEND-7S:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/412262807
         gridUnit.cellMenu._inViewPort = _repositionMenu(undefined, undefined, true);
        })
      }

      function deselectCellMenuOnOutsideClick(e) {
        if (gridUnit.cellMenu.visible) {
          var target = $(e.target);
          if (!target.closest('.cell-menu-container').length && !target.closest('.slick-cell').length) {
            gridUnit.cellMenu.hide();
          }
        }
      }
    }
  };
}
