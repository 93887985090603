'use strict';


/**
 * @ngInject
 */

appMetaInfo.$inject = ['$rootScope', '$timeout', 'UserWorkspace', 'VuexStore'];
export function appMetaInfo($rootScope, $timeout, UserWorkspace, $store){
  var _isPageLoading = true;
  var service = {
    pageLoading: pageLoading,
    pageLoaded: pageLoaded,
    setPageTitle: setPageTitle,
    prefixPageTitleWith: prefixPageTitleWith,
    delayedPageLoaded: delayedPageLoaded
  };


  const default_page_title = () => $store.state.user.email + ' | ' + UserWorkspace?.workspace?.name;

  Object.defineProperty(service, 'isPageLoading', {
    get: function () {
      return _isPageLoading;
    }
  });
  pageLoading();

  return service;


  function setPageTitle(title) {
    $rootScope.pageTitle = title;
  }

  function prefixPageTitleWith(prefix) {
    $rootScope.pageTitle = prefix + ' | ' + default_page_title();
  }

  function pageLoading(){
    _isPageLoading = true;
  }

  function pageLoaded() {
    _isPageLoading = false;
  }

  function delayedPageLoaded(delay){
    delay = delay || 1000;
    $timeout(pageLoaded, delay)
  }
}
