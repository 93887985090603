// API
import API from './api/notifications.api'

// Constants
import { NOTIFICATION_STATUS } from '@/constants'

// Dependencies
import Vue from 'vue'
import moment from 'moment'

const getInitialState = () => ({
  notificationsMap: {
    /*{
      'notification-01': {
        id: notification-01
        status: '',
        isRead: false,
        dataUpdatedAt: '',
        details: {} 
        }
      }*/
  },

  isNewNotificationAvailable: false // Used to display a badge on the notifications icon on the navbar
})

const getters = {
  getNotifications: (state) =>
    Object.values(state.notificationsMap).sort((a, b) => moment(b.dataUpdatedAt) - moment(a.dataUpdatedAt))
}

const mutations = {
  setNotificationsMap: (state, notificationsMap) => (state.notificationsMap = notificationsMap),
  delete: (state, id) => Vue.delete(state.notificationsMap, id),
  setRead: (state, id) => (state.notificationsMap[id].isRead = true),
  setUnread: (state, id) => (state.notificationsMap[id].isRead = false),
  setIsNewNotificationAvailable(state, status) {
    localStorage.setItem('isNewNotificationAvailable', status)
    state.isNewNotificationAvailable = status
  }
}

const actions = {
  save(store, notifications) {
    // Join state notifications with changed notifications
    let newNotifications = notifications.concat(store.getters.getNotifications)
    // Sort by date
    newNotifications = newNotifications.sort((a, b) => moment(b.dataUpdatedAt) - moment(a.dataUpdatedAt))
    // Filter out duplicates (keep most recent iteration of the notification)
    newNotifications = newNotifications.filter((n, i, a) => a.findIndex((e) => e.id === n.id) === i)
    // Filter out dismissed notifications (which are first needed to be compared with the rest of the notifications,
    // to refresh notifications list, in case a notification is deleted from another tab)
    newNotifications = newNotifications.filter((n) => n.status != NOTIFICATION_STATUS.DISMISSED)

    if (newNotifications.length > 0) store.dispatch('checkNewNotificationStatus', newNotifications)

    // Convert to map
    let notificationsMap = {}
    newNotifications.forEach((n) => (notificationsMap[n.id] = n))
    store.commit('setNotificationsMap', notificationsMap)
  },
  remove(store, id) {
    store.commit('delete', id)
    API.remove(id)
  },
  read(store, id) {
    store.commit('setRead', id)
    API.read(id)
  },
  unread(store, id) {
    store.commit('setUnread', id)
    API.unread(id)
  },
  checkNewNotificationStatus(store, newNotifications) {
    const mostRecentNotification = localStorage.getItem('mostRecentNotification')
    const newMostRecentNotification = newNotifications[0].id

    let isNewNotificationAvailable = localStorage.getItem('isNewNotificationAvailable') === 'true'
    if (!isNewNotificationAvailable) isNewNotificationAvailable = mostRecentNotification != newMostRecentNotification
    store.commit('setIsNewNotificationAvailable', isNewNotificationAvailable)
    localStorage.setItem('mostRecentNotification', newMostRecentNotification)
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
