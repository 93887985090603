import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';


/**
 * @ngInject
 */
dataviewGridDirective.$inject = ['utils', '$timeout', 'gridFactory', 'resources', 'analyticsService', 'c', 'DataviewGlobalFilterService'];
export function dataviewGridDirective(utils, $timeout, gridFactory, resources, analyticsService, c, DataviewGlobalFilterService) {
  return {
    link: function (scope, element, attrs, ctrl) {
      var vm = scope.vm, metadata, dataURL;
      var mainGridUnit = scope.$eval(attrs.dataviewGrid);
      var globalFilter = DataviewGlobalFilterService.getByDataviewId(mainGridUnit.dataview.id);

      // Below variable is essential for column selection via grid- to identify where the click action is done
      var headerClickAdmitted = true;

      var options: any = {
        showColumnTypeIcons: true,
        rowHeight: 30,
        headerRowHeight: 50,
        topPanelHeight: 50,
        sequence: mainGridUnit.dataview?.stepPreview.sequence,
        defaultColumnWidth: 160,
        enableTextSelectionOnCells: true,
        condition: globalFilter.getCondition(),
        endPointMethod: "POST"
      };
      var debouncedReloader = utils.debounce(grid_reload, 200);
      mainGridUnit.onReload("grid_reload_directive", debouncedReloader);
      globalFilter.onChange('fromInsideWkspGridDirective', grid_reload);
      var columns = [], gridInstance, reload_in_progress = false;

      function recomputeColumns() {
        //Remove all the items from columns list to ensure its empty
        columns.splice(0, columns.length);
        //Add columns to the list based on wksp metadata and display properties
        utils.metadata.applyDisplayChanges(mainGridUnit.dataview.metadata, mainGridUnit.dataview.display_properties, columns);
        columns.splice(0, 0, {"name": "#", "id": "index", "field": "index", "width": 50, cssClass: "sequence-cell"});
        var linkFormatter = function (row, cell, value, columnDef, dataContext) {
          if (!value) {
            return value;
          }
          else {
            var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;\u4E00-\u9FFF\u3400-\u4DFF\u0400-\u04FF]*[-A-Z\u4E00-\u9FFF\u3400-\u4DFF\u0400-\u04FF0-9+&@#\/%=~_|])/ig;
            var text1 = value.replace(exp, "<a target=\"_blank\" href='$1'>$1</a>");
            var exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
            value = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
            return value;
          }
        };

        $.each(columns, function (i, c) {
            if (i > 0) {
              c.minWidth = 80;
            }
            if (c.type == 'TEXT' &&
              mainGridUnit.dataview.display_properties &&
              mainGridUnit.dataview.display_properties.FORMAT_INFO &&
              mainGridUnit.dataview.display_properties.FORMAT_INFO[c.internal_name]) {
              if (mainGridUnit.dataview.display_properties.FORMAT_INFO[c.internal_name].IS_URL) {
                c.formatter = linkFormatter;
              }
            }
            if (c.type == 'NUMERIC') {
              c.cssClass = 'text-end text-monospace';

            }
          }
        );
      }

      function resubscribeLoaders() {
        if(!Number.isInteger(options.sequence)){
          gridInstance.grid.onColumnsReordered.subscribe(colReorderedHandler);
          // gridInstance.grid.onColumnsResized.subscribe(colResizedHandler);
        }
        gridInstance.dataFactory.onDataLoading.subscribe(showScrollLoaders);
        gridInstance.dataFactory.onDataLoaded.subscribe(hideScrollLoader);

        function colReorderedHandler(e, args) {

          var cols = gridInstance.grid.getColumns();
          var newOrder = {};
          cols = $.grep(cols, function (col: any) {
            return col.id !== "index";
          });
          angular.forEach(cols, function (col, i) {
            newOrder[i] = col.id;
          });

          // Ensure hidden columns also make it to the column order list. So that their order is not lost
          var hiddenCols = mainGridUnit.dataview.display_properties.HIDDEN_COLUMNS || [];
          var existingorder = mainGridUnit.dataview.display_properties.COLUMN_ORDER|| {};
          hiddenCols.sort(function(c1, c2){
            return parseInt(utils.getKeyByValue(existingorder, c1)) - parseInt(utils.getKeyByValue(existingorder, c2))
          })
          for (var hiddencol of hiddenCols) {
            var hiddenColOrderInExistingOrder = utils.getKeyByValue(existingorder, hiddencol)
            var tempOrder = {}
            for (const order in newOrder) {
              if (parseInt(order) >= parseInt(hiddenColOrderInExistingOrder)) {
                var incrementedOrder = parseInt(order) + 1
                tempOrder[incrementedOrder.toString()] = newOrder[order]
              } else {
                tempOrder[order] = newOrder[order]
              }
            }
            tempOrder[hiddenColOrderInExistingOrder] = hiddencol
            newOrder = tempOrder
          }

          analyticsService.userEventTrack(c.userEvents.dataviewEvents.reorderColumns, { eventOrigin: 'dataview.grid' });
          mainGridUnit.dataview.setDisplayProperties({'COLUMN_ORDER': newOrder}).then(function () {
            // Reset column browser data when column order is changed
            var updated_display_properties = _.cloneDeep(mainGridUnit.dataview.display_properties);
            updated_display_properties['COLUMN_ORDER'] = newOrder;
            vm.columnRenamePanel.resetMetadata(updated_display_properties);
            resources.update();
            // columns in grid object is not updated until a refresh
            // and this is the object we use for setting column in colMenudirective post rename.
            // TODO: The ideal fix would be to trace all the objects that are being used/copied on landing and dataview and optimise the same.
            // mainGridUnit.reloadGrid();
          });
        }

        function showScrollLoaders(e, args) {
          $timeout(function () {
            mainGridUnit.onScrollLoader.hideOverlay = true;
            mainGridUnit.onScrollLoader.show = true;
          }, 0);
        }

        function hideScrollLoader(e, args) {
          reload_in_progress = false;
          $timeout(function () {
            mainGridUnit.onScrollLoader.show = false;
            mainGridUnit.onScrollLoader.hideOverlay = false;
          }, 0);
        }

        // TODO:
        // Ideally, column browser should be repopulated with updated columns whenever there is a change is dataview data
        // When columns are reordered via grid, resources.update method which in turn calls the Data source update and dataview update
        // There is no specific id based update on any of these methods hence they update all dataviews regardless of where the change is present
      }

      function grid_reload() {
        if (gridInstance) {
          options.coordinates = gridInstance.getCoordinates();
        }
        var a = [];
        mainGridUnit.metadata.forEach(
          function (c) {
            a.push(c.internal_name);
          }
        );

        recomputeColumns();
        options.condition = _.cloneDeep(globalFilter.getCondition());
        options.sequence = mainGridUnit.dataview?.stepPreview.sequence;
        gridInstance = new gridFactory(element, mainGridUnit.dataURL, columns, options);
        gridInstance.init();
        $timeout(function () {
          resubscribeLoaders();
          mainGridUnit.gridInstance = gridInstance;
          mainGridUnit._gridInitedEvent.fire_event();
        });
      }
    }
  };
}

/**
 * @ngInject
 */
dataviewLayout.$inject = ['$timeout', 'utils', 'VuexStore'];
export function dataviewLayout($timeout, utils, $store) {
  return {
    link: function dataviewLayoutLink(scope, ele, attr) {
      var stepsPanel, elementsPanel, gridPanel, summaryPanel, historyDataPanel, elementToggles,
        setValuesDeb = utils.debounce(setValues, 200, false),
        setHeightsDeb = utils.debounce(setHeights, 500, false);
      scope.$watch('vm.dataview.display_properties.COLUMN_SUMMARY.height', setHeights);
      scope.$watch('vm.explorePanel.config.open', (value) => {
        $store.commit('exploreSection/setIsOpen', value)
        setHeights()
      });
      scope.$watch('vm.globalFilter.isActive', setHeights);
      scope.$watch('vm.explorePanel.config.items.length', setHeightsDeb, true);
      // var colSummaryService = ColumnSummaryServiceFactory.getByDataviewId(scope.vm.dataview.id);
      // colSummaryService.onListChanged("wLayoutListener", setHeights);

      $timeout(setHeights, 300);

      function getElements() {
        stepsPanel = $(ele).children('.steps-panel').first();
        elementsPanel = $(ele).children('.elements-panel').first();
        gridPanel = $(ele).children('.grid-panel').first();
        summaryPanel = $(ele).children('explore-panel').first();
        elementToggles = $(ele).children('.element-panel-toggles').first();
        // historyDataPanel = $(ele).children('.previous-grid-container').first();
      }

      function setHeights() {
        if (!stepsPanel || !elementsPanel || !summaryPanel) {
          getElements();
        }
        if (scope.vm.explorePanel.config.open && scope.vm.explorePanel.config.items.length) {
          // var summaryHeight = scope.vm.explorePanel.height || 180;
          var summaryHeight = 180;
          if (scope.vm.globalFilter.isActive) {
            summaryHeight += 20;
          }
          setValuesDeb(summaryHeight);
        } else {
          setValuesDeb("");
        }
      }

      function setValues(summaryHeight) {
        var height = "";
        var top = "";
        if (summaryHeight) {
          height = "calc(100% - " + (summaryHeight + 35) + "px";
          top = summaryHeight + 35;
        }
        summaryPanel && summaryPanel.height(summaryHeight);
        // stepsPanel && stepsPanel.height(height).css("top", top);
        gridPanel && gridPanel.height(height).css("top", top);
        elementToggles && elementToggles.css("top", top ? top + 4 : top);
        elementsPanel && elementsPanel.height(height).css("top", top);
        window.dispatchEvent(new Event('resize'));
        $timeout(function () {
          window.dispatchEvent(new Event('resize'));
        }, 200);
        if (scope.vm.mainGridUnit && scope.vm.mainGridUnit.gridInstance && scope.vm.mainGridUnit.gridInstance.grid) {
          $timeout(scope.vm.mainGridUnit.gridInstance.grid.resizeCanvas, 200);
        }
      }
    }
  }
}

/**
 * @ngInject
 */
wsColMenuController.$inject = ['config', 'c'];
export function wsColMenuController(config, c) {
  return {
    controller: config.controllers.columnMenu,
    controllerAs: 'cm',
    bindToController: {
      gridUnit: "=",
      taskPanel: "=",
      dataview: "=",
      elementPanel: "=",
      menuType: "="
    },
    link: function (scope, elem, attr) {
      scope.cm.gridUnit.columnMenu.onColumnSelected('highlightColOnSelect',
        function (columnId) {
          scope.cm.hasColFormatInfoChanged = false;
          scope.cm.gridUnit.gridInstance.plugins.columnHighlighter.highlight(_getHighlightSpec(columnId));
          scope.cm.activePill = scope.cm.gridUnit.columnMenu.menuType == 'tasks' ? 0 : 1;
        });
      scope.cm.gridUnit.columnMenu.onHideColumn('unhighlightColOnHide',
        function (columnId) {
          scope.cm.gridUnit.gridInstance.plugins.columnHighlighter.unhighlightByClasses(['menu-highlight',
            'tasks-menu-highlight',
            'metrics-menu-highlight',
            'text-end',
            'text-monospace']);
        });

      function _getHighlightSpec(columnId) {
        var highlight = {};
        highlight[columnId] = ['menu-highlight'];
        if (scope.cm.gridUnit.columnMenu.menuType == 'tasks') {
          highlight[columnId].push('tasks-menu-highlight')
        } else if (scope.cm.gridUnit.columnMenu.menuType == 'metrics') {
          highlight[columnId].push('metrics-menu-highlight')
        }
        return highlight;
      }
    }
  };
}

/**
 * @ngInject
 */
wsCellMenuController.$inject = ['config', 'c'];
export function wsCellMenuController(config, c) {
  return {
    controller: config.controllers.columnMenu,
    controllerAs: 'cem',
    bindToController: {
      gridUnit: "=",
      taskPanel: "=",
      dataview: "=",
      elementPanel: "=",
      menuType: "=",
    },
    link: function (scope, elem, attr) {

    }
  };
}

/**
 * @ngInject
 */
 watchOptimiser.$inject = [];
export function watchOptimiser() {
  return {
    link: function (scope) {
      /*
      Issue: It was observed that in a 30 steps in the pipeline  when user would try to edit any task the task panel to open after a delay of around 4 seconds

      Reason:  When there are lot of two way bindings in angular application the no. of watchers on them increase and it is not efficient and makes the application rendering slow
      the no. of watchers on the two way bindings in this angular application were observed to be around 3600+ for 30 step pipeline
      andthe the no. of watchers would shoot around 7000+ when user opens a task panel on adding a task or editing a task
      during which application would seem to be unresponsive i.e any user click will have no effect anywhere in the app.
      The no. of watchers keeps on increasing as more rules are added to the pipeline and hence slowing down the application
      In ideal situation the no. of watchers in application should not exeeed 2000.

      Solution: We can reduce the no. of watchers temporarily by using the watch optimiser directive.
      How to:
      Add the directive to the element which needs to be optimised.
      After that when you want to suspend the watchers on that element broadcast the suspend event.
      this can be done by using the following code in the controller
      $rootScope.$broadcast('suspendWatchers');
      Similarly to resumme the watchers broadcast the resume event.
      $rootScope.$broadcast('resumeWatchers');

      To see no. of watchers at any time in the system log
      $rootScope.$$watchersCount
      */

      // Note: this might break is suspend/resume called out of order or if watchers are added while suspended
      var watchers;

      scope.$on('suspendWatchers', function () {
        watchers = scope.$$watchers;
        scope.$$watchers = [];
      });

      scope.$on('resumeWatchers', function () {
        if (watchers)
          scope.$$watchers = watchers;

        // discard our copy of the watchers
        watchers = void 0;
      });
    }
  };
}
/**
 * @ngInject
 */
taskView.$inject = ['dataviewConfig', '$compile', '$templateCache', '$log','$timeout'];
export function taskView(dataviewConfig, $compile, $templateCache, $log,$timeout) {
  return {
    controller: dataviewConfig.taskController,
    controllerAs: 'tvm',
    scope: {
      task_panel: "=taskPanel",
      mainGridUnit: '=',
      functionsPanel: '='
    },
    bindToController: true,
    link: function taskViewLink(scope, element, attrs, ctrl) {
      var task_panel = scope.tvm.task_panel;
      scope.functionsPanel = scope.tvm.functionsPanel;
      var task_name = task_panel.task_name;
      var taskConfig = dataviewConfig.taskConfig[task_name];
      // var element_to_append = '<div ng-include="\'' + taskConfig.template + '\'"></div>';
      var content_to_append = $templateCache.get(taskConfig.template);
      if (!content_to_append) {
        $log.error("Template not cached: ", taskConfig.template);
      }

      scope.tvm.initController().then(function (manager) {
        scope.tvm.task_panel.onTemplateReady()
        scope.tvm.task_panel.manager = manager;
        $(element).empty();
        $(element).append($compile(content_to_append)(scope));
        $timeout(function () {
        scope.$root.$broadcast('resumeWatchers')
        })

      });

      scope.$on('$destroy', scope.tvm.destroy);
    }
  };
}
/**
 * @ngInject
 */
actionView.$inject = ['dataviewConfig', '$compile', '$templateCache', '$log'];
export function actionView(dataviewConfig, $compile, $templateCache, $log) {
  return {
    controller: dataviewConfig.actionController,
    controllerAs: 'avm',
    scope: {
      action_panel: "=actionPanel",
      mainGridUnit: '=',
      actionPanelForm: "="
    },
    bindToController: true,
    link: function actionViewLink(scope, element, attrs, ctrl) {
      var action_panel = scope.avm.action_panel;
      var action_name = action_panel.action_name;
      var actionConfig = dataviewConfig.actionConfig[action_name];
      scope.actionPanelForm = scope.avm.actionPanelForm;
      var content_to_append = $templateCache.get(actionConfig.template);
      if (!content_to_append) {
        $log.error("Template not cached: ", actionConfig.template);
      }
      scope.avm.initController().then(function (manager) {
        scope.avm.action_panel.manager = manager;
        $(element).empty();
        $(element).append($compile(content_to_append)(scope));
      });

      scope.$on('$destroy', scope.avm.destroy);
    }
  };
}

/**
 * @ngInject
 */
includeDataviewPage.$inject = ['config', 'utils'];
export function includeDataviewPage(config, utils) {
  return {
    templateUrl: config.templates.dataview,
    controller: config.controllers.dataview,
    controllerAs: 'vm',
    scope: {
      dataview: "=dataview",
      isActiveDataview: "=isActiveDataview"
    },
    bindToController: true,
    link: function (scope, element, attributes) {
      scope.utils = utils;
      scope.demo_mode = scope.$root.demo_mode;
      scope.dataViewSampleMode = scope.$root.dataViewSampleMode;
    }
  };
}

/**
 *@ngInject
 */
includeElementPanel.$inject = ['$compile', 'config'];
export function includeElementPanel($compile, config) {
  return {
    replace: true,
    templateUrl: config.templates.element.display
  }
}

/**
 *@ngInject
 */
includeColumnExplorer.$inject = ['$compile', 'config'];
export function includeColumnExplorer($compile, config) {
  return {
    replace: true,
    templateUrl: config.templates.columnExplorer,
    link: function (scope, element, attrs) {
      scope.$watch('vm.columnRenamePanel.isOpen && vm.columnRenamePanel.inEditMode', function (val) {
        if (!val) {
          return;
        }
        let elementOfInterest = $('.col-table');
        let gridHeight = $('.ds-grid').height();
        if (scope.vm.explorePanel.config.open) {
          $(elementOfInterest).css('max-height', (gridHeight - 100) + 'px');
        }
        else {
          $(elementOfInterest).css('max-height', null);
        }
      });
    }
  }
}
/**
 *@ngInject
 */
includeAddRuleMenu.$inject = ['$compile', 'config'];
export function includeAddRuleMenu($compile, config) {
  return {
    replace: true,
    templateUrl: config.templates.addRuleMenu
  }
}
/**
 *@ngInject
 */
includeDataviewGridColMenu.$inject = ['config'];
export function includeDataviewGridColMenu(config) {
  return {
    replace: true,
    templateUrl: config.templates.wkspGridColumnMenu,
    link: function (scope, element, attrs) {
      scope.$watch('vm.mainGridUnit.columnMenu.visible', function (val) {
        if (val) {
          $(element).css('max-height', 'calc(100% - 37px)');
        }
        else {
          $(element).css('max-height', null);
        }
      });
    }
  }
}
/**
 * @ngInject
 */
setColmenuContentHeight.$inject = ['$timeout'];
export function setColmenuContentHeight($timeout) {
  return {
    link: function (scope, element, attrs) {
      $(element).css({"maxHeight": "unset"});
      $timeout(_checkAndSetSize, 100);
      $timeout(_checkAndSetSize, 300);
      $timeout(_checkAndSetSize, 700);

      function _checkAndSetSize() {
        var parent = $('.active-dataview .dv-dataview .ds-grid').first();
        if (parent) {
          var parentH = $(parent).height();
          var contentH = parentH - 92;
          $(element).css({"maxHeight": contentH + 'px'});
        }
      }

    }
  }
}

/**
 *@ngInject
 */
includeDataviewGridCellMenu.$inject = ['config'];
export function includeDataviewGridCellMenu(config) {
  return {
    replace: true,
    templateUrl: config.templates.wkspGridCellMenu
  }
}
/**
 *@ngInject
 */
includeDataviewSummaryMenu.$inject = ['config'];
export function includeDataviewSummaryMenu(config) {
  return {
    replace: true,
    templateUrl: config.templates.wkspColumnSummaryMenu
  }
}
/**
 *@ngInject
 */
includeJoinSourceGrids.$inject = ['config']
export function includeJoinSourceGrids(config) {
  return {
    templateUrl: config.templates.joinSourceGrids,
    controller: 'joinSourceGridsController',
    controllerAs: 'sg',
    bindToController: {
      taskHelper: "="
    }
  }
}
/**
 * @ngInject
 */
columnSummary.$inject = ['$timeout', 'clickEvents', 'utils'];
export function columnSummary($timeout, clickEvents, utils) {
  return {
    link: function columnSummaryLink(scope, elem, attr) {
      var globalFilter = scope.$eval(attr.globalFilter),
        summary = scope.$eval(attr.columnSummary),
        prevState = false,
        unsubOnChange,
        unsubClick
        ;

      changeListener(true);
      scope.$on('$destroy', destroy);

      unsubOnChange = globalFilter.onChange('columnSummaryDirectiveListener' + summary.column.internal_name, function () {
        changeListener();
      });

      function changeListener(initing = undefined) {
        var currState = globalFilter.getOrderedColumnList().indexOf(summary.column.internal_name) > -1;
        if (!prevState && currState && !initing) {
          $timeout(function () {
            (<any> $(elem)).scrollintoview({
              duration: 800,
              viewPadding: {y: 40, x: 200},
            });
          }, 1500);

        }
        prevState = currState;
      }

      var clearSelectionCb = scope.$eval(attr.clearSelectionCallback);
      unsubClick = clickEvents.onClick('columnSummaryClickListener' + utils.string.random(5), function (e) {
        var target = $(e.target);
        if (!((target.closest(elem).length || target.closest('.summary-menu-container').length) && target.closest('.prevent-deselect').length) && typeof clearSelectionCb == 'function') {
          clearSelectionCb();
        }
      });


      function destroy() {
        unsubOnChange();
        unsubClick();
      }
    }
  }
}
/**
 * @ngInject
 */
columnSummaryUniqueValues.$inject = ['$filter', 'DataviewGlobalFilterService', 'utils', '$sanitize'];
export function columnSummaryUniqueValues($filter, DataviewGlobalFilterService, utils, $sanitize) {
  return {
    link: function (scope, elem, attr) {
      var column_name = scope.$eval(attr.summaryColumnName);
      var column_type = scope.$eval(attr.summaryColumnType);
      var dataviewId = scope.$eval(attr.dataviewId);
      var valueSelection = null;

      var dataviewGlobalFilter = DataviewGlobalFilterService.getByDataviewId(dataviewId);
      var registry = {
        data: [],
        count: 0
      }

      dataviewGlobalFilter.onChange('fromInsideColumnSummaryUniqueValues.' + column_name, function () {
        _render();
      });

      scope.$watch(attr.columnSummaryUniqueValues, function (uniqueValues) {
        registry.data = uniqueValues.data;
        registry.count = uniqueValues.count;
        _render();
      });

      scope.$watch(attr.valueSelection, function (val) {
        valueSelection = _.cloneDeep(val);
        _render();
      }, true);

      function _render() {
        var selectedValues = dataviewGlobalFilter.getSelectedByColumn(column_name);
        var elemToAppend = [];
        var data = registry.data;
        if (data && data.length) {
          angular.forEach(data, function (item, i) {
            var countString = ' <span class="count" title="' + $filter("humanizeInt")(item.count) + '">(' + $filter("humanizeInt")(item.count) + ')</span>';
            var checked = "";

            var selectedClass = [];
            if (column_type == 'TEXT') {
              if (selectedValues.indexOf(item.value || null) != -1) {
                selectedClass.push('filtered');
              }
              if (angular.isArray(valueSelection) && valueSelection.indexOf(item.value || null) != -1) {
                selectedClass.push("selected");
              }
            }

            var summary_val_attr;
            var item_value;
            if (!item.value) {
              summary_val_attr = 'summary-value="null"'
              item_value = '(blank)';
            }
            else {
              summary_val_attr = 'summary-value="summary.data.UNIQUE_VALUES.data[' + i + '].value"';
              item_value = _.escape(item.value);
            }

            item_value = utils.string.format('<span class="label-text" title="{0}">{0}</span>', [item_value]);
            var value_string = utils.string.format("<span class='value'>{0}{1}</span>", [item_value, countString]);
            elemToAppend.push("<li " + summary_val_attr + " class='summary-value-selectable " + selectedClass.join(' ') + "'>" +
              value_string + "</li>");
          });
          if (data.length < registry.count) {
            elemToAppend[elemToAppend.length - 1] = "<li>. . .</li>";
          }
        } else {
          elemToAppend = ["<li>No Unique values</li>"];
        }
        $(elem).empty();
        $(elem).append(elemToAppend.join(""));
      }


    }
  }
}
/**
 * @ngInject
 */
printElementDiv.$inject = ['$window'];
export function printElementDiv($window) {
  return {
    link: function (scope, element, attrs) {
      $(element).click(_clickCb)

      function _clickCb() {
        var contents = $(element).closest('.elements-header').siblings('.element-container').html();
        var popupWin = window.open('', '_blank');
        var allCSS =
          [].slice.call(document.styleSheets)
            .reduce(function (prev, styleSheet) {
              if (styleSheet.cssRules) {
                return prev +
                  [].slice.call(styleSheet.cssRules)
                    .reduce(function (prev, cssRule) {
                      return prev + cssRule.cssText;
                    });
              } else {
                return prev;
              }
            }, '');

        allCSS += "\n.fa.fa-spinner.fa-pulse{display: none};"

        popupWin.document.open();
        popupWin.document.write('' +
          '<html><head><style type="text/css">' + allCSS + '</style></head>' +
          '<body onload="window.print()">' + contents + '</body>' +
          '</html>');
        popupWin.document.close();
      }
    }
  }
}

/*
scrolls the view whenever a new item is added.

scrollListToEndWhenNewAdded: this attr is watched. Whenever its count increases, scroll happens

scrollOnlyAfterScrollPercentage: this attr is optional. Setting this attr tell the directive to scroll only when
the view is already scrolled at this percentage
 */
export function scrollListToEndWhenNewAdded() {
  return {
    link: function (scope, element, attributes) {
      scope.$watch(attributes.scrollListToEndWhenNewAdded, function (vv, ov) {
        if ((vv > ov)) {
          let scrollAllowed = true;
          if(attributes.hasOwnProperty("scrollOnlyAfterScrollPercentage")){
            let scrollOnlyAfterScrollPercentage = parseInt(attributes.scrollOnlyAfterScrollPercentage);
            let domEle = element[0];
            let scrollHeight = (domEle.scrollHeight - domEle.offsetHeight);
            let scrollPercentage: number;
            if(scrollHeight == 0){
              scrollPercentage = 100;
            }
            else{
              scrollPercentage = 100 * domEle.scrollTop/scrollHeight;
            }
            if(scrollPercentage < scrollOnlyAfterScrollPercentage){
              scrollAllowed = false;
            }
          }
          // scroll only if scrollHeight goes beyong maxScrollHeight(set somewhere)
          // if maxScrollHeight is not set, then this if will always be true
          if(((attributes.hasOwnProperty("maxScrollHeight") && element.context.scrollHeight >= parseInt(attributes.maxScrollHeight)) ||
            !attributes.hasOwnProperty("maxScrollHeight")) && scrollAllowed) {
            $(element).animate({scrollTop: $(element).prop("scrollHeight")}, 50);
          }
        }
      }, true);
    }
  }
}


/**
 * @ngInject
 */
wsColRenamePanel.$inject = ['$compile'];
export function wsColRenamePanel($compile) {
  return {
    link: function wsColRenamePanelLink(scope, ele, attrs) {
      function init(cols) {
        var ul = $('<ul></ul>');
        var lis: any = [];
        if (cols && cols.length) {
          angular.forEach(cols, function (col, i) {
            var li = '<li>' +
              '<div class="col-dragger dragger"></div>' +
              '<input type="checkbox" ng-model="vm.columnRenamePanel.columns[' + i + '].is_visible">' +
              '<span class="col-number">' + col.idx + '.</span>' +
              '<input type="text" class="column-name" ng-model="vm.columnRenamePanel.columns[' + i + '].display_name" ' +
              'ng-model-options="{\'allowInvalid\': true}" required>' +
              '<div class="datatype type-' + col.type.toLowerCase() + '"></div>' +
              '</li>'
            lis.push(li);
          });
        }
        ul.html(lis);
        ele.empty();
        ele.append($compile(ul)(scope));
      }

      scope.$watch(attrs.wsColRenamePanel, function (columns) {
        if (scope.$eval(attrs.isOpen)) {
          init(columns);
        }
      })
    }
  }
}

export function determineMaxPillsForColSummary() {
  return {
    scope: {
      selected: "=selected",
      maxLimit: '=maxLimit'
    },
    link: function (scope) {
      // hard coded function with empirical justifications
      scope.$watchCollection('selected', function (selected) {
        var charWidth = 8, availableWidth = 220, padding = 30, maxLimit = 0;
        if (angular.isArray(selected)) {
          $.each(selected, function (i, word) {
            var divWidth = (((word || '(blank)').length) * charWidth + padding);
            if (divWidth > 100) {
              divWidth = 100;
            }
            availableWidth -= divWidth;
            if (availableWidth < 0) {
              return false;
            }
            maxLimit++;
          });

          scope.maxLimit = maxLimit;
        }
      })
    }
  }

}

/**
 * @ngInject
 */
handleElementPanelDrag.$inject = ['utils', '$timeout', 'featureAvailability', 'c'];
export function handleElementPanelDrag(utils, $timeout, featureAvailability, c) {
  return {
    link: function (scope, element, attrs) {
      let eState = new ElementPanelState();
      let maxAllowedState = 3;
      let dragStartAt = null;
      let _dragIndicatorElem = $(element).parents('.dv-dataview').find('.element-panel-drag-indicator').first();
      let clickIncreasesWidth = true;
      let dragIndicator = {
        element: _dragIndicatorElem,
        show: function () {
          _dragIndicatorElem.show();
          $(element).css("cursor", "-webkit-grabbing")
        },
        hide: function () {
          _dragIndicatorElem.addClass('dropped');
          $(element).css("cursor", "");
          setTimeout(function () {
            _dragIndicatorElem.removeClass('dropped');
            _dragIndicatorElem.hide();
          }, 700);

        },
        calculate: calculateDragHandle,
        calculateDebounced: _.throttle(calculateDragHandle, 100, {'leading': true}),
        moveElementPanel: moveElementPanel,
        stopMoveElementPanel: stopMoveElementPanel
      };
      if (!featureAvailability.isFeatureEnabled(c.appFeatures.nonMetricElements)) {
        maxAllowedState = 1;
      }
      let debouncedDragHandle = utils.debounce(dragHandle, 100, false);
      scope.$watch('vm.elementPanel.isOpen + vm.elementPanel.fullScreen', function () {
        $timeout(function () {
          eState.computeClasses();
        }, 100);
      });
      $(element).on('drag', function (e, data) {
        dragIndicator.calculateDebounced(e, data);
        let dragTimeVar: any = new Date();
        let dragDuration = dragTimeVar - dragStartAt;
        if (dragDuration > 200) {
          dragIndicator.show();
          dragIndicator.moveElementPanel(e, data);
        }
      });

      $(element).on('dragstart', function (e, data) {
        dragStartAt = new Date();
        dragIndicator.calculate(e, data);
      });

      $(element).on('dragend', function (e, data) {
        let dragTimeVar: any = new Date();
        let dragDuration = dragTimeVar - dragStartAt;
        if (dragDuration > 200) {
          debouncedDragHandle(e, data);
        }
        else {
          clickHandle();
        }
        dragIndicator.hide();
        dragIndicator.stopMoveElementPanel();
      });

      $(element).on("click", clickHandle);

      function addCssClass(cls?){
        dragIndicator.element.removeClass('state0 state1 state2 state3');
        $(element).removeClass('state0 state1 state2 state3');
        $(element).parent().removeClass('state0 state1 state2 state3');
        if(cls){
          dragIndicator.element.addClass(cls);
          $(element).addClass(cls);
          $(element).parent().addClass(cls);
        }
      }

      function calculateDragHandle(e, data) {
        let state;
        const bodyWidth = $('body').width();
        const dragWidth = bodyWidth - e.pageX;
        addCssClass();
        const currState = eState.getState();
        if (featureAvailability.isFeatureEnabled(c.appFeatures.nonMetricElements)) {
          if (dragWidth < 150) {
            state = 0;
          } else if (dragWidth < 400) {
            state = 1;
          } else if (dragWidth < Math.max(bodyWidth / 1.66, 750)) {
            state = 2;
          } else {
            state = 3;
          }
          addCssClass(`state${state}`);
        } else {

          if (scope.vm.elementPanel.isOpen) {
            if (data.deltaX > 80) {
              addCssClass('state0');
            } else {
              addCssClass('state1');
            }
          } else {
            if (data.deltaX < -80) {
              addCssClass('state1');
            } else {
              addCssClass('state0');
            }
          }
        }

      }

      function moveElementPanel(e, data) {
        var ele = $(element).parents('.elements-panel').first();
        ele.css("opacity", 0.9).css("transition", "none");
        const currState = eState.getState();
        const bodyWidth = $('body').width();
        const dragWidth = bodyWidth - e.pageX;
        if (featureAvailability.isFeatureEnabled(c.appFeatures.nonMetricElements)) {
          let width = undefined;
          let diff = data.deltaX;
          if (diff <= -10) {
            if (currState == 0) {
              if (diff <= -320) {
                width = Math.max((-1 * diff), 320);
              } else {
                diff = 320 + diff;
              }
            } else if (currState == 1) {
              width = 320 + (-1 * diff);
            } else if (currState == 2) {
              width = 580 + (-1 * diff);
            }
          } else {
            if (dragWidth < 320) {
              width = undefined;
            } else {
              width = Math.max(dragWidth, 320);
            }
          }
          if (width) {
            ele.width(width);
          } else {
            ele.width("");
            ele.css("transform", "translate(" + diff + "px, 0)");
          }
        } else {
          if (scope.vm.elementPanel.isOpen) {
            if (data.deltaX >= 0 && data.deltaX <= 310) {
              ele.css("transform", "translate(" + data.deltaX + "px, 0)")
            }
          } else {
            if (data.deltaX <= -10 && data.deltaX >= -320) {
              ele.css("transform", "translate(" + (320 + data.deltaX) + "px, 0)")
            }
          }
        }

      }

      function stopMoveElementPanel() {
        setTimeout(function () {
          $(element).parents('.elements-panel').first().css("opacity", "").css("transform", "").css("transition", "")
            .width("");
        }, 300);
      }

      function clickHandle() {
        // if disabled then return
        const isPipelineError = scope.vm.dataview.pipeline_status == 'error'
        const isPipelineDraft = scope.vm.dataview.draft_mode == 'dirty'
        if (isPipelineError || isPipelineDraft) {
          return
        }

        if (featureAvailability.isFeatureEnabled(c.appFeatures.nonMetricElements)) {
          $timeout(function () {
            var currState = eState.getState();
            if (currState == 0) {
              clickIncreasesWidth = true;
            } else if (currState == 3) {
              clickIncreasesWidth = false;
            }
            if (clickIncreasesWidth) {
              eState.plus();
            } else {
              eState.minus();
            }
          }, 50);
        } else {
          $timeout(function () {
            var currState = eState.getState();
            if (currState == 0) {
              eState.plus();
            } else {
              eState.minus();
            }
          }, 50);
        }

      }

      function dragHandle(e, data) {
        let state;
        const bodyWidth = $('body').width();
        const dragWidth = bodyWidth - e.pageX;
        if (featureAvailability.isFeatureEnabled(c.appFeatures.nonMetricElements)) {
          if (dragWidth < 150) {
            state = 0;
          } else if (dragWidth < 400) {
            state = 1;
          } else if (dragWidth < Math.max(bodyWidth / 1.66, 750)) {
            state = 2;
          } else {
            state = 3;
          }
          eState.goTo(state);
        } else {
          if (data.deltaX > 80) {
            eState.minus();
          }
          else if (data.deltaX < -80) {
            eState.plus();
          }
        }

      }

      function ElementPanelState() {
        $timeout(setClass, 100);
        this.plus = plus;
        this.minus = minus;
        this.getState = getState;
        this.computeClasses = setClass;
        this.goTo = goTo;

        function plus() {
          goTo(getState() + 1)
        }

        function minus() {
          goTo(getState() - 1)
        }

        function getState() {
          if (!scope.vm.elementPanel.isOpen) {
            return 0;
          }
          else if (scope.vm.elementPanel.isOpen && !scope.vm.elementPanel.fullScreen) {
            return scope.vm.elementPanel.expandedState || 1;
          }
          else if (scope.vm.elementPanel.isOpen && scope.vm.elementPanel.fullScreen) {
            return 3;
          }

        }

        function goTo(state) {
          if (state < 0 || state > maxAllowedState) {
            return;
          }
          if (state == 0) {
            scope.vm.elementPanel.toggle(false);
            scope.vm.elementPanel.toggleFullScreen(false);
          }
          if (state == 1) {
            scope.vm.elementPanel.toggle(true);
            scope.vm.elementPanel.toggleFullScreen(false);
          }
          if (state == 2) {
            scope.vm.elementPanel.toggle(true);
            scope.vm.elementPanel.toggleFullScreen(false);
          }
          if (state == 3) {
            scope.vm.elementPanel.toggle(true);
            scope.vm.elementPanel.toggleFullScreen(true);
          }
          scope.vm.elementPanel.setExpandedState(state);
          setClass(state);
        }

        function setClass(state) {
          if (state === undefined) {
            state = getState();
          }
          addCssClass('state' + state)
        }
      }
    }
  }
}
/**
 * @ngInject
 */
columnMenuHeightAdjuster.$inject = ['$timeout'];
export function columnMenuHeightAdjuster($timeout) {
  return {
    link: function (scope, element, attributes) {
      scope.$watch(attributes.columnMenuHeightAdjuster, checkAndResize);

      function checkAndResize(show) {
        if (show) {
          let parentHeight = $(element).parents('.grid-panel').height();
          $(element).css('max-height', (parentHeight - 250) + 'px');
        }
        else {
          $(element).css('max-height', '');
        }
      }
    }
  }
}

/**
 * @ngInject
 */
openFirstDataviewByDsId.$inject = ['navigationService'];
export function openFirstDataviewByDsId(navigationService){
    return {
      scope: {
        dsName: '=',
        dsId: '='
      },
      template: '<span class="ds-name">{{name}}</span>',
      link: function(scope, element, attrs){
        scope.name = unescape(scope.dsName);
        $(element).on('click', function(){
          navigationService.open_first_dataview_by_ds_id(parseInt(scope.dsId))
        });
      }
    }
}
openFirstDataviewByDsId.$inject = ['navigationService'];
export function openDataviewById(navigationService){
    return {
      scope: {
        wsName: '=',
        wsId: '='
      },
      template: '<span class="ds-name">{{wsName}}</span>',
      link: function(scope, element, attrs){
        $(element).on('click', function(){
          navigationService.open_dataview_by_id(parseInt(scope.wsId))
        });
      }
    }
}

export function multiLevelDropdownMenuDirective () {
  return {
    templateUrl: 'app/components/dataview/multi-level-dropdown-menu.tpl.html',
    restrict: "E"
  };
}

export function columnBrowserWidthAdjuster() {
  return {
    scope: {
      maxWidth: '=',
      isOpen: '=',
      hasChanges: '='
    },
    link: function (scope, element, attributes) {
      //Watch when the panel is opened/closed and when the max width changes
      scope.$watch('maxWidth', function () {
        scope.$watch('isOpen', function () {
          if (scope.isOpen) {
            let dropdownId = '.col-table .column-name';
            $(dropdownId).css({width: scope.maxWidth + 'px'});
          }
        });
      });
    }
  }
}

export function editButtonClick() {
  return {
    controllerAs: 'vm',
    scope: {
      currentCol: '=',
      crPanel: '='
    },
    link: function (scope, ele, attr) {
      ele.click(function(){
          ele.bind('keydown keypress', function (e) {
            if (e.which === 13) {
              scope.crPanel.columnExplorerEdit(scope.currentCol, false);
              e.preventDefault();
              e.stopPropagation();
            }
          });
      });

    }
  }
}
