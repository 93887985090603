googleAdsHelpers.$inject = ['apiHelpers'];

export function googleAdsHelpers(apiHelpers) {

  return {
    get_table_description: get_table_description,
    on_load: apiHelpers.rdbms_on_load,
    onSearchChange: apiHelpers.onSearchChange
  };

  function get_table_description(params, ele, scope, callback_fn, quote_char) {
    quote_char = "\"";
    apiHelpers.get_table_description(params, ele, scope, callback_fn, quote_char);
    checkForScheduleType(scope, scope.model.tables);
  }

  function checkForScheduleType(scope, tableName) {
    var ctrl = scope.options.data.parentController;
    var isPeriodTable = scope.options.data.parentController.addDsConfigForm.userInputDiscoverySpec.ux.attributes.periodic_tables.items.includes(tableName);
    if (isPeriodTable) {
      ctrl.scheduleRelatedParams.schedule_type = "PERIOD";
    } else {
      ctrl.scheduleRelatedParams.schedule_type = "MOMENT";
    }
  }
}
