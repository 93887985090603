<template>
  <mm-drawer value>
    <div class="sidebar-content p-x-3 p-y-5">
      <div class="resources-section">
        <section class="workspace-section p-b-4">
          <div v-if="computedCurrentWorkspaceName" class="d-flex width-100 m-b-3" style="min-height: 24px">
            <span
              class="mm-text--body-regular d-flex-align-center"
              style="width: calc(100% - 20px); text-transform: capitalize; color: var(--mm-color--n600)"
            >
              {{ $t('global.dictionary.workspace') }}
            </span>
            <workspace-menu v-if="!computedIsSettingsPage && $store.state.workspaces.length > 1" />
          </div>

          <div class="d-flex-align-center justify-space-between">
            <div class="d-flex-align-center" style="max-width: calc(100% - 32px)">
              <mm-icon name="workspace" class="d-flex-center icon-20px m-r-3" />
              <span class="mm-text--h200" style="word-break: break-word; overflow-wrap: break-word">
                {{ computedCurrentWorkspaceName }}
              </span>
            </div>
            <mm-tooltip :label="$t('settings.go_to')" position="right">
              <mm-button icon="settings" objective="tertiary" @click="openSettings()" />
            </mm-tooltip>
          </div>
        </section>

        <section
          v-if="$store.getters['resources/getReports'].length"
          class="cursor-pointer section-clickable"
          :class="{ 'menu-item-active mm-text--body-bold': $store.state.projectId == 0 }"
        >
          <div
            class="d-flex width-100"
            @click.prevent="
              goTo({
                id: 0,
                title: $tc('global.dictionary.report', 2),
                icon: 'data_library_20px',
                path: `#/workspaces/${$store.state.workspaceId}/shared-reports`
              })
            "
          >
            <mm-icon name="reports" class="icon-20px m-r-3" style="min-width: 20px" />
            <span class="menu-item-text mm-text--h200">
              {{ $tc('global.dictionary.report', 2) }}
            </span>
          </div>
        </section>

        <section>
          <div class="d-flex-align-center width-100 m-b-3" style="min-height: 24px; padding-right: 1px">
            <mm-icon name="projects" class="icon-20px m-r-3" style="min-width: 20px" />
            <span class="mm-text--h200 d-flex-align-center" style="width: calc(100% - 20px)">
              {{ $tc('global.dictionary.project', 2) }}
            </span>
            <mm-tooltip :label="$t('project.create_tooltip')" position="right">
              <mm-button icon="plus" objective="tertiary" size="small" @click="isAddProjectModalOpen = true" />
            </mm-tooltip>
          </div>

          <div class="overflow-y-auto" style="min-width: 200px">
            <div
              v-for="item in computedItems"
              class="m-l-7 m-y-2 sub-item cursor-pointer"
              :key="item.path"
              :class="{ 'sub-item-active': $store.state.projectId == item.id }"
              @click.prevent="goTo(item)"
            >
              <div class="width-100 d-flex-align-center justify-space-between">
                <span
                  style="overflow: hidden; text-overflow: ellipsis"
                  class="sub-item-text"
                  :class="{ 'mm-text--body-bold': $store.state.projectId == item.id }"
                  :label="item.title"
                >
                  {{ item.title }}
                </span>

                <mm-menu :key="`${item.id}_${menuKey}`" :close-on-content-click="false">
                  <template #activator="{ on }">
                    <mm-button
                      :key="`view_options_button_${menuKey}`"
                      class="m-r-2"
                      icon="menu_3_dots_vertical"
                      objective="tertiary"
                      size="small"
                      v-on="on"
                    />
                  </template>

                  <template #content>
                    <project-manage-menu
                      :projectId="item.id"
                      tooltips-position="right"
                      @optionExecuted="onProjectOptionExecuted($event, item.id)"
                      @modalClosed="menuKey++"
                    />
                  </template>
                </mm-menu>
              </div>
            </div>
          </div>
        </section>
      </div>

      <mm-tooltip
        v-if="computedAllowedUsage > 0"
        wrapper-class="width-100"
        :label="
          $t('settings.workspace.usage.info', {
            currentUsage: computedCurrentUsage.toLocaleString(),
            allowedUsage: computedAllowedUsage.toLocaleString()
          })
        "
      >
        <section
          class="sidebar-bottom mm-rounded-borders cursor-pointer m-b-6 m-x-2 p-y-5"
          :key="`workspace_storage_${$store.state.workspaceId}`"
          @click="openSettings(true)"
        >
          <div class="m-x-4">
            <p class="m-b-4 mm-text--h300">{{ $t('data_library.workspace_data_usage') }}</p>
            <div class="d-flex justify-space-between">
              <span class="mm-text--caption-regular m-b-0"
                >{{
                  $t('settings.workspace.usage.info', {
                    currentUsage: compactNumberFormat(computedCurrentUsage),
                    allowedUsage: compactNumberFormat(computedAllowedUsage)
                  })
                }}
              </span>
              <span
                class="m-b-0 mm-text--caption-bold"
                :style="{ color: `var(--mm-color--${this.computedStorageBarColor})` }"
              >
                {{ computedStorageUsagePercentage }}%
              </span>
            </div>

            <mm-progress-bar v-model="computedStorageUsagePercentage" class="m-y-2" :color="computedStorageBarColor" />
          </div>
        </section>
      </mm-tooltip>
    </div>

    <project-modal-name v-model="isAddProjectModalOpen" @submit="onProjectAdded" />
  </mm-drawer>
</template>

<script>
// Dependencies
import moment from 'moment'

//Constants
import { USER_EVENTS, COLLABORATION_BLOG, STORAGE_WARNING_PERCENTAGE, getOldAppUrl } from '@/constants'

// Components
import WorkspaceMenu from './components/data-library-sidebar-workspace-menu.vue'
import ProjectManageMenu from '@/modules/project/components/menu/project-manage-menu'
import ProjectModalName from '@/modules/project/components/modals/project-modal-name'

// Utils
import { compactNumberFormat } from '@/utils/filters'

export default {
  name: 'data-library-sidebar',
  components: {
    WorkspaceMenu,
    ProjectManageMenu,
    ProjectModalName
  },
  data: () => ({
    isAddProjectModalOpen: false,
    menuKey: 0
  }),
  computed: {
    computedCurrentWorkspaceTotalUsers() {
      return this.$store.getters.getCurrentWorkspace.workspaceUsers
    },
    computedCurrentWorkspaceName() {
      return this.$store.getters.getCurrentWorkspace.name || ''
    },
    computedIsSettingsPage() {
      //TODO remove when angular code is gone (use vue router instead)
      return window.location.hash.includes('settings')
    },
    computedItems() {
      let items =
        this.$store.getters.getCurrentWorkspace?.projects
          ?.filter((proj) => proj.subscribed)
          .map((project) => ({
            id: project.id,
            title: project.name,
            updatedAt: project.updatedAt,
            icon: 'data_library_20px',
            path: `#/workspaces/${this.$store.state.workspaceId}/projects/${project.id}`
          })) || []
      return items.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))
    },
    computedCurrentUsage() {
      return this.$store.state.usageInfo.workspace.current
    },
    computedAllowedUsage() {
      return this.$store.state.usageInfo.workspace.allowed
    },
    computedStorageUsagePercentage() {
      return Math.floor((100 * this.computedCurrentUsage) / this.computedAllowedUsage) || 0
    },
    computedStorageBarColor() {
      if (this.computedStorageUsagePercentage < STORAGE_WARNING_PERCENTAGE) return 'p800'
      if (this.computedStorageUsagePercentage < 100) return 'warn800'
      return 'dest700'
    }
  },
  methods: {
    openSettings(dataStorage) {
      this.$userEvents.save(USER_EVENTS.DATA_LIBRARY.SIDEBAR.SETTINGS)

      //TODO remove when angular code is gone
      window.open(
        `${getOldAppUrl()}/n/#/settings/workspace/${this.$store.getters.getCurrentWorkspace.id}${
          dataStorage ? '?dataStorage=true' : ''
        }`,
        'Settings'
      )
    },
    goTo(item) {
      //TODO remove when angular code is gone, also, add route in vue
      this.$store.commit('dataLibrary/setActiveSubItem', item)
      // TODO remove when angular migrated. This whole method is a workaround. we should find a better way of changing the currentProjectId in the store
      history.pushState({}, null, item.path)
      this.$userEvents.save(USER_EVENTS.DATA_LIBRARY.SIDEBAR.OPEN, item)
    },
    async onProjectAdded(project) {
      // TODO: revisit when Angular code is gone
      this.goTo({
        id: project.id,
        title: project.name,
        icon: 'data_library_20px',
        path: `#/workspaces/${this.$store.state.workspaceId}/projects/${project.id}`
      })
    },
    onLearnMore() {
      window.open(COLLABORATION_BLOG)
    },
    onProjectOptionExecuted(optionType, projectId) {
      if (projectId != this.$store.state.projectId) return

      if (['DELETE', 'LEAVE'].includes(optionType)) {
        this.$store.commit('deleteProject', projectId)
        //TODO remove when angular code is gone
        window.location.hash = ''
        window.location.href = `${window.location.origin}/#/landing`
        location.reload()
      }
    },
    getWorkspaceUsage() {
      // TODO this should not be in sidebar, should be in an initializing file, like and index or simply the App.vue, move this on migration
      if (this.$store.state.workspaceId !== -1 && this.$store.state.user.id !== -1)
        this.$store.dispatch('getWorkspaceUsage')
    },
    compactNumberFormat
  },
  created() {
    this.getWorkspaceUsage()
    this.fetchIntervalId = setInterval(this.getWorkspaceUsage, 45000)
  },
  beforeDestroy() {
    clearInterval(this.fetchIntervalId)
  }
}
</script>

<style lang="scss" scoped>
.icon-20px {
  height: 20px;
  min-width: 20px;
}
::v-deep .mm-drawer--content {
  height: 100%;
}

::v-deep .sidebar-bottom {
  background: var(--mm-color--n30);

  .action-buttons {
    .mm-button--content {
      justify-content: start;
    }
  }
}

.align-items-center {
  align-items: center;
}

.sidebar-content {
  width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .resources-section {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    section {
      padding: 8px;
      margin-bottom: 8px;
    }

    .section-clickable:hover {
      background: var(--mm-color--p75);
    }

    .workspace-section {
      background: var(--mm-color--n30);
      border-radius: 4px;
    }
  }

  .menu-item-active {
    background: var(--mm-color--p100);
  }

  .sub-item {
    align-items: center;
    display: flex;
    min-height: 40px;
    border-radius: 4px;
    padding: 4px 0 4px 8px;
    color: var(--mm-color--n600);

    &:hover {
      background: var(--mm-color--p100);
    }

    &.sub-item-active {
      background: var(--mm-color--p100);
    }
  }
}
</style>
