import * as angular from 'angular';
import {IAlert, IAlertFactory} from '../common/types/core';
import {get} from 'lodash-es';

export class Alert implements IAlert {
  public id: number;
  public alert_name: string;
  public metric_id: number;
  public condition: any;
  public template_info: any;
  public address_info: any;
  public dispatch_config_info: any;
  public alertResourceClass: ng.resource.IResourceClass<ng.resource.IResource<any>>;
  public static $inject: string[] = [ '$resource', 'config'];

  constructor(private dataviewId: any,
              private $resource: ng.resource.IResourceService,
              private config: any, private $q: any, public $timeout: any) {
    this.setAlertResourceClass();
  }

  private setAlertResourceClass(){
    let params = {dataviewId: parseFloat(this.dataviewId)};
    this.alertResourceClass = this.$resource(this.config.api.alert, params);
  }

  public save() {
    var data = {
      alert_name: this.alert_name,
      metric_id: this.metric_id,
      template_info: this.template_info,
      address_info: this.address_info,
      dispatch_config_info: this.dispatch_config_info,
      condition: this.condition
    };
    if (this.id) {
      data['id'] = this.id;
      return this.alertResourceClass.save(data).$promise.then((response) => {
        this.id = response.id;
      });
    } else {
      return this.alertResourceClass.save(data).$promise.then((response) => {
        this.id = response.id;
        this.setAlertResourceClass();
      });
    }
  }

  public fire(){
    var alertResourceClass: ng.resource.IResourceClass<ng.resource.IResource<any>> = this.$resource(
      this.config.api.alert,
      {dataviewId: parseFloat(this.dataviewId), alertId: this.id}
    );
    return alertResourceClass.save().$promise;
  }

  public setUpdates(updates: any) {
    if (!this.id) {
      this.id = get(updates, 'id', this.id);
    }
    this.alert_name = get(updates, 'alert_name', this.alert_name);
    this.metric_id = get(updates, 'additional_info.metric_id', this.metric_id);
    this.template_info = get(updates, 'template_info', this.template_info);
    this.address_info = get(updates, 'address_info', this.address_info);
    this.dispatch_config_info = get(updates, 'dispatch_config_info', this.dispatch_config_info);
    this.condition = get(updates, 'condition', this.condition);
  }

  public deleteAlert(alertId: number, dataviewId: any) {
    var alertResourceClass: ng.resource.IResourceClass<ng.resource.IResource<any>> = this.$resource(
      this.config.api.alert,
      {dataviewId: parseFloat(this.dataviewId), alertId: this.id}
    );
    var ret = alertResourceClass.delete();
    return ret.$promise.then((response) => {
      this.id = response.id;
    });
  }
}

/*
test
*/
export class AlertFactory implements IAlertFactory {
  public static $inject: string[] = ['$resource', 'config', '$timeout', '$q'];

  constructor(private $resource: ng.resource.IResourceService,
              private config: any,
              private $timeout: any,
              private $q: any
             ) {
  }

  public createAlert(dataviewId) {
    return new Alert(dataviewId, this.$resource, this.config, this.$q, this.$timeout);
  }
}
