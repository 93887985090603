/*jslint node: true */

import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * @ngInject
 */
tableRenderController.$inject = ['utils', '$timeout'];
export function tableRenderController(utils, $timeout){
  this.$onInit = function () {
    var tevm = this,
      unsubscribeOnUpdate,
      unsubscribeDisplayOnUpdate,
      fireDisplayUpdatedOnDestroy = false,
      unsubscribeOnRootUpdate,
      controllerId = utils.getRandomString(10);
    tevm.rerender = null;


    tevm.query = utils.debounce(query, 600);

    function query(displayProps?) {
      tevm.processing = true;
      tevm.element.row_limit = 10000;
      var condition = tevm.condition;
      if (tevm.element.display_properties.isDrilldown && tevm.drilldownManager) {
        condition = tevm.drilldownManager.addDrilldownConditionToExisting(tevm.element, condition);
        if (!tevm.drilldownManager.checkIfValuesSelected(tevm.element.id)) {
          tevm.processing = false;
          $timeout(tevm.query, 500);
          return;
        }
      }
      if (!_.isObject(displayProps)) {
        displayProps = undefined;
      }
      tevm.element.query(condition, undefined, displayProps).then(function (data) {
        tevm.processing = false;
        tevm.rerender(data.metadata, data.data);
      });
    };

    tevm.registerRenderer = function (fn) {
      tevm.rerender = fn;
    };
    query();

    unsubscribeOnUpdate = tevm.element.onUpdate("elementDirectiveUpdate" + controllerId, tevm.query);
    unsubscribeDisplayOnUpdate = tevm.element.onDisplayUpdate("elementDirectiveDisplayUpdate" + controllerId, function () {
      if (!fireDisplayUpdatedOnDestroy) {
        tevm.query(true);
      }
    });
    if (tevm.element.display_properties.isDrilldown && tevm.drilldownManager) {
      unsubscribeOnRootUpdate = tevm.drilldownManager.rootElement.onDisplayUpdate("updatedrilldown" + controllerId, function () {
        tevm.renderer();
      });

      tevm.drilldownManager.onValueUpdated("updateDrilldown" + controllerId, function () {
        tevm.query();
      })
    }

    tevm.handleDisplayUpdated = function (displayProperties) {
      fireDisplayUpdatedOnDestroy = true;
      tevm.element.display_properties = displayProperties;
      tevm.element.edit(undefined, displayProperties);
      tevm.element.displayUpdated();
    }

    tevm.destroy = function () {
      typeof unsubscribeOnUpdate === "function" && unsubscribeOnUpdate();
      typeof unsubscribeDisplayOnUpdate === "function" && unsubscribeDisplayOnUpdate();
      if (fireDisplayUpdatedOnDestroy) {
        tevm.element.displayUpdated();
      }
      typeof unsubscribeOnRootUpdate === "function" && unsubscribeOnRootUpdate();
    };
  }
}
