/*jslint node: true */
import * as angular from 'angular';
import _ = require('lodash');

/**
 * @ngInject
 */

thirdPartyAPIController.$inject = ['Singularity', '$window', '$timeout', 'modalService', 'MappedForm', 'Notification',
  'analyticsService', 'c', 'landingList'];
export function thirdPartyAPIController(Singularity, $window, $timeout, modalService, MappedForm, Notification,
  analyticsService, c, landingList) {
  this.$onInit = function () {
    var tpvm = this;
    tpvm.cancelButtonHandler = cancelButtonHandler;
    tpvm.integrationCategories = c.integrationCategories;
    tpvm.selectIntegration = selectIntegration;
    tpvm.saveIdentity = saveIdentity;
    tpvm.onCancel = onCancel;
    tpvm.editIdentity = false;
    tpvm.toEditIdentity = new Object();
    tpvm.editIdentityHandler = editIdentityHandler;
    tpvm.selectIdentity = selectIdentity;
    tpvm.selectDsConfig = selectDsConfig;
    tpvm.redirectForAuth = redirectForAuth;
    tpvm.saveDS = saveDS;
    tpvm.addIdentityHandler = addIdentityHandler;
    tpvm.selectProfile = selectProfile;
    tpvm.setProfiles = setProfiles;
    tpvm.deleteIdentity = deleteIdentity;
    tpvm.validateDsConfig = validateDsConfig;
    tpvm.autoValidate = autoValidate;
    tpvm.showLoader = false;
    tpvm.isDsConfigValid = false;
    tpvm.addDsMappedFields = [];
    tpvm.data = Singularity.data;
    tpvm.dataSampleVisible = false;
    tpvm.preview_grid_loader = {
      show: false,
      hideOverlay: false
    };
    tpvm.fileUploadDestinationResource = landingList.currentLabelView.label;
    tpvm.handleNotifications = function (notification) {
    };
    tpvm.previous = prev;
    tpvm.reset = reset;
    init();

    function prev() {
      if (tpvm.workFlow.currentStep === 'metrics') {
        tpvm.reset();
      }
      tpvm.workFlow.previous()
    }

    function _refreshIdentity(identity_key) {
      let integration = tpvm.selectedIntegration;
      tpvm.showLoader = true;
      tpvm.isProfileLoading = true;
      let identitiesListPromise = Singularity.getIdentities(integration.key).then(function (identities) {
        tpvm.identities = identities;
        tpvm.showLoader = false;
        if (identity_key !== undefined) {
          angular.forEach(tpvm.identities, function (identity) {
            if (identity.value === identity_key) {
              tpvm.selectIdentity(identity);
              return false;
            }
          })
        }
        tpvm.isProfileLoading = false;
      }, error_cb);

      let identitySpecPromise = Singularity.getAddIdentityConfig(integration.key).then(function (spec: any) {
        tpvm.showLoader = true;
        tpvm.addIdentitySpec = spec;
        if (
          spec.type === "API_KEY" ||
          spec.type === "PASSWORD" ||
          spec.type === "OAUTH2" ||
          spec.type === "CUSTOM_OAUTH2"
        ) {
          tpvm.showLoader = true;
          tpvm.addIdentityForm = new MappedForm(spec, tpvm);
          // spec.mappedFields = inputDiscoveryUtil.generateFormlyJsonFields(spec, tpvm);
          // spec.data = {};
        } else if (tpvm.addIdentitySpec.type === 'NO_AUTH') {
          tpvm.showLoader = true;
          tpvm.selectedIdentity = {
            name: 'no auth needed',
            value: tpvm.addIdentitySpec.NO_AUTH_KEY
          };
          Singularity.getAddDsConfig(tpvm.selectedIntegration.key, tpvm.selectedIdentity.value)
            .then(function (addDsConfigSpec) {
              setDsConfigSpec(addDsConfigSpec);
              tpvm.showLoader = false;
            }, function (response) {
              tpvm.showLoader = false;
            });
        }

        if (identity_key === undefined) {
          tpvm.showLoader = false;
        }
      }, error_cb);

      Promise.all([identitiesListPromise, identitySpecPromise]).then(function () {
        tpvm.showIdentityForm = !!(!tpvm.identities.length && tpvm.addIdentitySpec.type !== 'OAUTH2');
        tpvm.showLoader = false;
      }, function () {
        tpvm.showIdentityForm = false;
        tpvm.showLoader = false;
      });

      function error_cb(data) {
        tpvm.showLoader = false;
        tpvm.isProfileLoading = false;
      }
    }

    function selectIntegration(integration, identity_key) {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.newAPIIntegrationSelected, {
        integration: integration.key,
        eventOrigin: "thirdPartyModal"
      });
      tpvm.showLoader = true;
      tpvm.newConnectionInfo = '';
      reset();
      tpvm.selectedIntegration = integration;
      tpvm.workFlow.next('selectIntegration');
      _refreshIdentity(identity_key);
      tpvm.showLoader = false;
    }

    function editIdentityHandler(identity?) {
      if (identity) {
        if (tpvm.addIdentitySpec.type == 'OAUTH2') {
          tpvm.showIdentityForm = false;
          tpvm.redirectForAuth();

        } else {
          tpvm.showIdentityForm = !(tpvm.showIdentityForm);
          //prefill the form when user clicks edit identity button
          tpvm.addIdentityForm.modelData = new Object()
          Object.assign(tpvm.addIdentityForm.modelData, identity["identity_config"]);
        }
      }
    }

    function addIdentityHandler(identity?) {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.addnewDSAPINewConnection, {eventOrigin: "thirdPartyModal"});
      // Put this constants somewhere else
      if (tpvm.addIdentitySpec) {
        if (tpvm.addIdentitySpec.type === 'API_KEY' || tpvm.addIdentitySpec.type === 'PASSWORD' || tpvm.addIdentitySpec.type === 'CUSTOM_OAUTH2') {
          tpvm.showIdentityForm = !(tpvm.showIdentityForm);
          //When adding an identity make sure new identity is saved instead of updating an existing identity
          tpvm.editIdentity = false;
          tpvm.addIdentityForm.modelData = new Object()
          if (tpvm.addIdentitySpec.type === 'CUSTOM_OAUTH2') {
            tpvm.newConnectionInfo = "";
          }
        } else if (tpvm.addIdentitySpec.type === 'OAUTH2') {
          tpvm.showIdentityForm = false;
          tpvm.redirectForAuth();
        }
      }
    }

    function saveIdentity() {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.addNewAPIConnectClicked, {eventOrigin: "thirdPartyModal"});
      tpvm.showIdentityLoader = true;
      tpvm.saveIdentityResponseFailureMsg = "";
      tpvm.saveIdentityResponseSuccessMsg = "Establishing connection...";
      if (tpvm.addIdentitySpec.type == 'CUSTOM_OAUTH2') {
        tpvm.saveIdentityResponseSuccessMsg = "";
        tpvm.redirectForAuth();
      } else if (tpvm.editIdentity) {
        Singularity.updateIdentity(tpvm.selectedIntegration.key, tpvm.toEditIdentity.value, tpvm.addIdentityForm.modelData).then(function (data) {
          if (data.success === 500) {
            tpvm.showIdentityForm = false;
          } else {
            if (data.identity_key !== null && !data.message) {
              // Make sure identity edited is updated with changes so that it reflects on the third party modal immediately
              tpvm.toEditIdentity.name = data.identity['name'];
              Object.keys(tpvm.toEditIdentity.identity_config).forEach((key) => {
                tpvm.toEditIdentity.identity_config[key] = data.identity[key]
              });
              tpvm.showIdentityForm = false;

            } else {
              tpvm.saveIdentityResponseFailureMsg = data.message;
            }
          }
          tpvm.showIdentityLoader = false;
        }, function () {
          tpvm.showIdentityLoader = false;
        });
      } else {
        Singularity.saveIdentity(tpvm.selectedIntegration.key, tpvm.addIdentityForm.modelData).then(function (data) {
          if (data.success === 500) {
            tpvm.showIdentityForm = false;
          } else {
            if (data.identity_key !== null) {
              selectIntegration(tpvm.selectedIntegration, data.identity_key);
              tpvm.showIdentityForm = false;

            } else {
              tpvm.saveIdentityResponseFailureMsg = data.message;
            }
          }
          tpvm.showIdentityLoader = false;
        }, function () {
          tpvm.showIdentityLoader = false;
        });
      }

    }

    function setDsConfigSpec(addDsConfigSpec) {
      tpvm.addDsConfigSpec = addDsConfigSpec;
      tpvm.isDsConfigValid = !(addDsConfigSpec.capability.config_validation_required);
      tpvm.previewEnabled = addDsConfigSpec.capability.preview_enabled;
      tpvm.addDsConfigForm = new MappedForm(tpvm.addDsConfigSpec, tpvm);
      tpvm.enableUscReplace = false;
      if (tpvm.addDsConfigSpec.capability.hasOwnProperty('enable_usc_replace')) {
        tpvm.enableUscReplace = tpvm.addDsConfigSpec.capability.enable_usc_replace
      }
      if (tpvm.addDsConfigSpec.capability.hasOwnProperty('schedule_type')) {
        tpvm.scheduleType = tpvm.addDsConfigSpec.capability.schedule_type;
        var min_freq = tpvm.addDsConfigSpec.capability.minimum_schedule_frequency;
        if (min_freq === undefined) {
          min_freq = 0;
        }
        tpvm.minimumScheduleFrequency = min_freq;
        tpvm.scheduleRelatedParams.schedule_type = tpvm.scheduleType;
      }
      tpvm.dsConfigValidationMode = tpvm.addDsConfigSpec.capability.ds_config_validation_mode || "AUTO";
    }

    function selectIdentity(identity) {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.selectIdentity, {eventOrigin: "thirdPartyModal"});
      tpvm.selectedIdentity = identity;
      tpvm.showLoader = true;
      tpvm.addDsConfigSpec = {
        options: {
          removeChromeAutoComplete: true
        }
      };
      resetDsConfig();
      while (!tpvm.addIdentitySpec) {
        setTimeout(function () {
        }, 100);
      }
      if (tpvm.addIdentitySpec.has_profiles) {
        Singularity.getProfiles(tpvm.selectedIntegration.key, tpvm.selectedIdentity.value).then(function (data) {
          identity.profiles = data.profiles;
          if (data.identity_config && data.identity_config.hasOwnProperty('username')) {
            identity.username = data.identity_config.username;
          }
          tpvm.showLoader = false;
        }, function (data) {
          tpvm.showLoader = false;
          if (data.hasOwnProperty("reason")) {
            Notification.error(data["reason"])
          } else {
            Notification.error('Some error occurred, please try again.');
          }
        });
      } else {
        Singularity.getAddDsConfig(tpvm.selectedIntegration.key, tpvm.selectedIdentity.value).then(function (addDsConfigSpec) {
          setDsConfigSpec(addDsConfigSpec);
          tpvm.showLoader = false;
          if (tpvm.workFlow.currentStep === 'connection') {
            tpvm.workFlow.next('selectIdentity');
          }
        }, function (data) {
          tpvm.showLoader = false;
          let msg = 'Some error occurred while fetching configuration details, please try again.';
          msg = (data.hasOwnProperty('message') && typeof data.message === 'string') ? data.message : msg;
          if (tpvm.addIdentitySpec.type == 'OAUTH2' && data.hasOwnProperty('is_valid') && !data.is_valid && data.hasOwnProperty('auth_url') && data.auth_url) {
            $window.location.href = data.auth_url;
          }
          Notification.error(msg);
        });
      }
    }

    function deleteIdentity(identity) {
      modalService.deleteIdentity({type: 'identity', name: identity.name}).then(deleteConfirmed);

      function deleteConfirmed() {
        identity.showLoader = true;
        Singularity.deleteIdentity(tpvm.selectedIntegration.key, identity.value).then(function () {
          var idx = -1;
          angular.forEach(tpvm.identities, function (ident, i) {
            if (ident.value == identity.value) {
              idx = i;
            }
          });
          if (idx > -1) {
            tpvm.identities.splice(idx, 1);
            tpvm.showIdentityForm = !tpvm.identities.length && ['API_KEY', 'PASSWORD'].indexOf(tpvm.addIdentitySpec.type) !== -1;
            // In case all the identities are deleted and form shows up to add a new one. Make sure form is empty.
            if (tpvm.showIdentityForm) {
              tpvm.editIdentity = false;
              tpvm.addIdentityForm.modelData = new Object()
            }

          }
        });
      }
    }

    function setProfiles(profiles) {
      tpvm.selectedIdentity.profiles = profiles;
    }

    function selectProfile(identity, profile) {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.selectProfile, {eventOrigin: "thirdPartyModal"});
      //  similar things like select identity
      if (!!(tpvm.selectedProfile) && tpvm.selectedProfile.value === profile.value) {
        return;
      }
      // tpvm.dataSampleVisible = false;
      // tpvm.dataSample = null;
      tpvm.showLoader = true;
      resetDsConfig();
      tpvm.selectedIdentity = identity;
      tpvm.selectedProfile = profile;

      Singularity.getAddDsConfig(tpvm.selectedIntegration.key, tpvm.selectedIdentity.value, profile).then(function (addDsConfigSpec) {
        setDsConfigSpec(addDsConfigSpec);
        tpvm.showLoader = false;
        if (tpvm.workFlow.currentStep === 'connection') {
          tpvm.workFlow.next('selectProfile');
        }
      }, function () {
        tpvm.showLoader = false;
      });


    }

    tpvm.getDataSample = function (callback_fn) {
      if (tpvm.dataPreviewCheck) {
        tpvm.dataSampleVisible = true;
        tpvm.preview_grid_loader.show = true;
        tpvm.isDsConfigValid = false;
        Singularity.getDataSample(tpvm.selectedIntegration.key, tpvm.selectedIdentity.value, _getDsConfigwithProfile()).then(
          function (params) {
            tpvm.dataSampleVisible = true;
            tpvm.dataSample = params.data_sample;
            tpvm.isDsConfigValid = true;
            if (callback_fn !== undefined && typeof callback_fn === "function") {
              callback_fn();
            }
            tpvm.preview_grid_loader.show = false;
          },
          function () {
            if (callback_fn !== undefined && typeof callback_fn === "function") {
              callback_fn();
            }
            tpvm.preview_grid_loader.show = false;
            Notification.error("Couldn't generate preview.");
          });
      } else {
        tpvm.preview_grid_loader.show = false;
        delete tpvm.dataSample.rows;
      }
    };

    tpvm.togglePreviewGridLoader = function (is_true) {
      toggleGridLoader(tpvm.preview_grid_loader, is_true);
    };

    function toggleGridLoader(obj, is_true) {
      if (is_true !== undefined) {
        obj.show = !!is_true;
        obj.hideOverlay = !!is_true;
      } else {
        obj.show = !obj.show;
        obj.hideOverlay = !obj.hideOverlay;
      }
    }

    function postDsConfigValidationCallback(data) {
      tpvm.isDsConfigValid = data.is_valid;
      if (data.is_valid) {
        tpvm.dsConfigValidationMessage = {
          success_message: data.success_message
        };
        delete tpvm.selectedDsConfig["validate"];
        if (tpvm.previewEnabled) {
          tpvm.dataSampleCheck = true;
          tpvm.getDataSample();
        }
        if (tpvm.dsConfigValidationMode === "AUTO" && tpvm.scheduleType) {
          tpvm.workFlow.next('afterValidation');
        }
        if (tpvm.dsConfigValidationMode === "AUTO" && !tpvm.scheduleType) {
          tpvm.saveDS()
        }


      } else {
        tpvm.dataSampleCheck = false;
        tpvm.dsConfigValidationMessage = {
          error_description: data.error_description,
          error_message: data.error_message
        };
      }
      tpvm.configValidationInProgress = false;
    }

    function autoValidate() {
      if (tpvm.workFlow.currentStep === 'metrics' && tpvm.dsConfigValidationMode === "AUTO") {
        validateDsConfig();
      } else {
        tpvm.workFlow.next('nextButton');
      }
    }

    function validateDsConfig() {
      /**
       * Assumption is that the fields that are marked required on the spec are all filled
       */
      tpvm.configValidationInProgress = true;
      Singularity.validateDsConfig(_getDsConfigwithProfile());
    }

    function _getDsConfigwithProfile() {
      var dsConfig = _.cloneDeep(tpvm.addDsConfigForm.modelData);
      if (tpvm.selectedProfile) {
        dsConfig["profile"] = tpvm.selectedProfile.value;
      }
      dsConfig["ds_name"] = tpvm.dsName;
      dsConfig["data_start_date"] = tpvm.periodDataStartDate;
      return dsConfig
    }

    function selectDsConfig() {
      tpvm.workFlow.next();
    }

    function stepValidator(step) {
      var valid;
      if (step == 'api') {
        valid = true
      }
      if (step == 'connection') {
        valid = true
      }
      if (step == 'metrics') {
        valid = (tpvm.selectedIdentity !== null && tpvm.selectedIntegration !== null);
      }
      if (step == 'schedule') {
        valid = (tpvm.selectedIdentity !== null && tpvm.selectedIntegration !== null && tpvm.isDsConfigValid);
      }
      return valid
    }

    function saveDS() {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.submitClicked,
        {
          eventOrigin: "thirdPartyModal"
        });

      function final_cb() {
      }

      for (let cb of tpvm.callbacks_before_submit) {
        if (typeof cb === 'function') {
          cb();
        }
      }
      let destinationLabelResourceId = tpvm.fileUploadDestinationResource ? tpvm.fileUploadDestinationResource.resource_id : undefined;
      if (tpvm.scheduleRelatedParams.is_valid || tpvm.scheduleType === null) {
        tpvm.submitInProcess = true;
        tpvm.workFlow.changeStepInProcess = true;
        Singularity.saveDsConfig(
          tpvm.selectedIntegration.key, tpvm.selectedIdentity.value, _getDsConfigwithProfile(),
          tpvm.scheduleRelatedParams, tpvm.schedule, destinationLabelResourceId
        ).then(tpvm.close || function () {
          tpvm.submitInProcess = false;
          tpvm.workFlow.changeStepInProcess = false;
        }, error_cb, final_cb);
      }


      function error_cb(data) {
        if (data.STATUS === 'FAILURE') {
          Notification.error(data.ERROR_MESSAGE);
        }
        tpvm.submitInProcess = false;
        tpvm.workFlow.changeStepInProcess = false;
      }

    }

    function reset() {
      tpvm.configValidationInProgress = false;
      tpvm.selectedIdentity = null;
      tpvm.selectedProfile = null;
      tpvm.selectedDsConfig = {};
      tpvm.showIdentityForm = false;
      tpvm.dsConfigValidationMessage = null;
      tpvm.saveIdentityResponseFailureMsg = null;
      tpvm.saveIdentityResponseSuccessMsg = null;
      // tpvm.scheduleType = 'MOMENT';
      tpvm.dataSampleCheck = false;
      tpvm.previewLabelInfo = "";
      tpvm.submitInProcess = false;
      tpvm.dsName = "";
      tpvm.dsConfigDataNotAvailable = false;
      tpvm.dsConfigDataNotAvailableMessage = '';
      tpvm.periodDataStartDate = null;

      tpvm.scheduleRelatedParams = {
        schedule_type: 'MOMENT',
        on_refresh_action: 'REPLACE'
      };
      tpvm.start = null;
      tpvm.preview_grid_loader.show = false;
      if (tpvm.workFlow.currentStep !== 'metrics') {
        tpvm.identities = [];
      }
      tpvm.callbacks_before_submit = [];
    }

    function resetDsConfig() {
      tpvm.dataSampleVisible = false;
      tpvm.selectedDsConfig = {};
      tpvm.dsConfigValidationMessage = null;
      tpvm.dataSampleCheck = false;
      tpvm.dataPreviewCheck = true;
      delete tpvm.addDsConfigSpec;
      delete tpvm.addDsConfigForm;
      if (tpvm.dataSample) {
        delete tpvm.dataSample.rows;
      }
      tpvm.previewLabelInfo = "";
      tpvm.dataSample = undefined;
    }

    function augmentDsConfigParams(params) {
      tpvm.addDsConfigSpec = angular.merge(tpvm.addDsConfigSpec, params);
      tpvm.addDsConfigForm.update(tpvm.addDsConfigSpec);
      Singularity.augmentDsComplete();
    }

    tpvm.updateDsConfigForm = function () {
      tpvm.addDsConfigForm.update(tpvm.addDsConfigSpec);
    };

    function reInitializeDsConfigForm() {
      Singularity.getAddDsConfig(tpvm.selectedIntegration.key, tpvm.selectedIdentity.value, tpvm.selectedProfile).then(function (addDsConfigSpec) {
        setDsConfigSpec(addDsConfigSpec);
        tpvm.showLoader = false;
      });
    }

    function init() {
      tpvm.showLoader = true;
      tpvm.workFlow = new WorkFlow(stepValidator);
      tpvm.selectedIntegration = null;
      tpvm.schedule = null;
      tpvm.newConnectionInfo = '';
      reset();
      Singularity.onAugmentDsConfigSpecParams('on_ds_config_spec_params_update', augmentDsConfigParams);
      Singularity.onValidateDsConfig('on_ds_config_validation', postDsConfigValidationCallback);
      Singularity.onReInitializeDsConfigForm('on_reinitialize_ds_config_form', reInitializeDsConfigForm);
      Singularity.onNewIdentitySaved('from-api-controller', function () {
        $timeout(_refreshIdentity, 1000);
        $timeout(_refreshIdentity, 1000);
      });

      Singularity.getIntegrations().then(function (integrations) {
        tpvm.integrations = Singularity.data.integrations;
        tpvm.webApis = [];
        tpvm.dbApis = [];
        tpvm.fileApis = [];
        angular.forEach(integrations, function (integration) {
            switch (integration.type) {
              case 'DB':
                tpvm.dbApis.push(integration);
                break;
              case 'WEB':
                tpvm.webApis.push(integration);
                break;
              case 'FILE':
                tpvm.fileApis.push(integration);
                break;
            }
            if (integration.key === Singularity.selected_integration_key && Singularity.preselect_integration_from_oauth2_flow) {
              tpvm.selectIntegration(integration);
              Singularity.preselect_integration_from_oauth2_flow = false;
            }
          }
        );
        tpvm.showLoader = false;
      });

    }

    function redirectForAuth() {
      if (tpvm.addIdentitySpec.type == 'OAUTH2') {
        $window.location.href = tpvm.addIdentitySpec.AUTH_URL;
      } else if (tpvm.addIdentitySpec.type == 'CUSTOM_OAUTH2') {
        // Need to implement the custom logic part of oauth if any needed here
        if (tpvm.selectedIntegration.key == 'sharepoint') {
          var server_url = tpvm.addIdentityForm.modelData.server.replace(/\/$/, '');
          var ishttps = server_url.includes("https:");
          if (ishttps) {
            tpvm.saveIdentityResponseSuccessMsg = "Establishing connection...";
            const site = server_url.split("/");
            const protocol = site[0];
            const host = site[2];
            const siteUrl = protocol + '//' + host;
            $window.location.href = tpvm.addIdentitySpec.AUTH_URL + encodeURIComponent("&server=" + server_url) + "&scope=" + encodeURIComponent(`${siteUrl}/${tpvm.addIdentitySpec.scope}`);
          } else {
            tpvm.saveIdentityResponseFailureMsg = "Please check and enter a valid url";
            tpvm.showIdentityLoader = false;
          }
        }
        if (tpvm.selectedIntegration.key == 'shopify') {
          var shopUrl = tpvm.addIdentityForm.modelData.shopurl.replace(/\/$/, '');
          var isShopify = shopUrl.includes("myshopify.");
          var ishttps = shopUrl.includes("https:");
          if (isShopify && ishttps) {
            tpvm.saveIdentityResponseSuccessMsg = "Establishing connection...";
            $window.location.href = shopUrl + tpvm.addIdentitySpec.AUTH_URL;
          } else {
            tpvm.saveIdentityResponseFailureMsg = "Please check and enter valid shop url from the shopify";
            tpvm.showIdentityLoader = false;
          }
        }
        if (tpvm.selectedIntegration.key == 'salesforcecdata') {
          var instance_url = tpvm.addIdentityForm.modelData.instance_url.replace(/\/$/, '');
          tpvm.saveIdentityResponseSuccessMsg = "Establishing connection...";
          $window.location.href = instance_url + tpvm.addIdentitySpec.AUTH_URL + encodeURIComponent("&server=" + instance_url);
        }
      }
    }

    function WorkFlow(stepValidator) {
      var self = this;
      self.steps = ['api', 'connection', 'metrics', 'schedule'];
      self.changeStepInProcess = false;
      self.currentStep = self.steps[0];

      self.next = next;
      self.previous = prev;
      self.jump = jump;

      function jump(step) {
        if (stepValidator(step)) {
          $timeout(function () {
            self.currentStep = "";
          }, 0);
          $timeout(function () {
            self.currentStep = step;
          }, 100);
        }
      }

      function next(origin) {
        analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.gotoNextStep,
          {
            eventOriginStep: self.currentStep,
            eventOrigin: "thirPartyModal." + origin
          });
        brieflyLockMovement();
        var stepIndex = self.steps.indexOf(self.currentStep);
        if (stepIndex < self.steps.length - 1) {
          stepIndex += 1;
        }
        jump(self.steps[stepIndex]);
      }

      function prev(origin) {
        analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.gotoPreviousStep,
          {
            eventOriginStep: self.currentStep,
            eventOrigin: "thirPartyModal." + origin
          });
        brieflyLockMovement();
        var stepIndex = self.steps.indexOf(self.currentStep);
        if (stepIndex > 0) {
          stepIndex -= 1;
        }
        jump(self.steps[stepIndex]);
      }

      function brieflyLockMovement() {
        $timeout(function () {
          self.changeStepInProcess = true;
        }, 0);
        $timeout(function () {
          self.changeStepInProcess = false;
        }, 600);
      }
    }

    function cancelButtonHandler() {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.cancelButtonClicked, {eventorigin: "thirdPartyModal"});
      tpvm.close()
    }

    function onCancel() {
      tpvm.showIdentityForm = false;
      tpvm.showIdentityLoader = false;
      tpvm.addIdentityForm.modelData = null;
      tpvm.saveIdentityResponseFailureMsg = null;
      delete Singularity.pendingRequests['save_identity']
    }
  }
}

