/*jslint node: true */
/**
 * @ngInject
 */
mixpanelHelpers.$inject = ['c','analyticsService'];
export function mixpanelHelpers(c, analyticsService) {
  let datapull_options_to_schedule_type_override_map = {
    "user_profiles": 'MOMENT',
    "events_raw_data": 'PERIOD',
  };

  return {
    on_datapull_option_change: on_datapull_option_change,
    on_load: on_load
  };

  function on_datapull_option_change(value, element, scope, callback_fn) {
    analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.mixpanel.datapullOptionChange,
      {
        eventOrigin: "thirdPartyModal.mixpanel.metrics",
        value: value.datapull_options
      });
    var ctrl = scope.options.data.parentController;
    ctrl.origDsName = element.templateOptions.options.filter(opt => opt.value === element.value())[0].name;
    ctrl.scheduleType = datapull_options_to_schedule_type_override_map[value.datapull_options];
    ctrl.scheduleRelatedParams.schedule_type = ctrl.scheduleType;
    if (callback_fn !== undefined && typeof callback_fn === "function") {
      callback_fn();
    }
  }

  function on_load() {
    let ctrl = this.extraData.parentController;
    if (this.extraData.keyWiseFieldsMap.hasOwnProperty('datapull_options') && this.extraData.keyWiseFieldsMap.datapull_options){
      let datapull_options_field = this.extraData.keyWiseFieldsMap.datapull_options.mappedField;
      ctrl.origDsName = datapull_options_field.templateOptions.options.filter(opt => opt.value === datapull_options_field.defaultValue)[0].name;
    }
  }
}
