<template>
  <mm-modal v-model="computedIsModalOpen" :title="$t('project.manage.join.title')" width="480">
    <p>{{ $t('project.manage.join.info', { name: computedProjectName }) }}</p>

    <template #actions>
      <mm-button :label="$t('global.dictionary.cancel')" objective="secondary" @click="computedIsModalOpen = false" />
      <mm-button class="m-l-3" :label="$t('project.manage.join.title')" @click="joinProject" />
    </template>
  </mm-modal>
</template>

<script>
// API
import projectApi from '@/modules/project/api/project.api.js'

// Constants
import { PROJECT_PERMISSIONS } from '@/constants'

export default {
  name: 'project-modal-join',
  props: {
    value: Boolean,
    projectId: Number
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    computedProjectName() {
      return this.$store.getters.getProjectById(this.projectId).name
    }
  },
  methods: {
    async joinProject() {
      const response = await projectApi.addToProject(
        this.projectId,
        this.$store.state.user.id,
        PROJECT_PERMISSIONS.ADMIN
      )
      if (response) this.$store.state.user.roles.projects[this.projectId] = PROJECT_PERMISSIONS.ADMIN
      else this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })
      this.$store.dispatch('getWorkspaces', { invalidateCache: true })
      this.$store.dispatch('getCurrentUser', { invalidateCache: true })
      this.computedIsModalOpen = false
      this.$emit('submit')
    }
  }
}
</script>
