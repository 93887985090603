import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * @ngInject
 * @returns
 */
inputDiscoveryUtil.$inject = ['Singularity', 'apiUtils'];
export function inputDiscoveryUtil(Singularity, apiUtils) {
  var service = {
    generateFormlyJsonFields: generate_formly_json_fields
  };

  var col_classes = {
    '100': 'col-xs-12',
    '91.66': 'col-xs-11',
    '83.33': 'col-xs-10',
    '75': 'col-xs-9',
    '75.00': 'col-xs-9',
    '66.66': 'col-xs-8',
    '58.33': 'col-xs-7',
    '50': 'col-xs-6',
    '50.00': 'col-xs-6',
    '41.66': 'col-xs-5',
    '33.33': 'col-xs-4',
    '25': 'col-xs-3',
    '25.00': 'col-xs-3',
    '16.66': 'col-xs-2',
    '8.33': 'col-xs-1'
  };


  var dependencies_map = {};

  var self = this;

  function generate_formly_json_fields(userInputDiscoverySpec, parentController, mappedFields) {
    //toDo: console error if fields not found, say invalid UID spec received
    var mapped = [];
    self.parentController = parentController;
    self.mappedFields = mappedFields;
    self.moduleType = userInputDiscoverySpec.module_type;
    self.fieldsSpecJson = userInputDiscoverySpec.fields;
    self.fieldAttributes = userInputDiscoverySpec.ux.attributes;
    //ToDO : also standardize process for OAUTH2 type
    // ToDO : removed switch logic for spec.type from here, it doesn't belong to this service, put it somewhere else,
    // only after type check, control should come here
    // ToDO : type check for input params ie.e fields should be array etc
    // ToDO: len validation
    // ToDo: write a validator which matches the count for type=field objects in layout == fieldsSpecJson.length
    if (!!userInputDiscoverySpec.ux.layout && userInputDiscoverySpec.ux.layout.length > 0) {
      //ToDo; better naming -  apply_layout
      angular.forEach(userInputDiscoverySpec.ux.layout, function (item) {
        // todo optimize concat
        mapped = mapped.concat(_generate_fields(item, self.fieldAttributes));
      });
    }
    //  if layout info is missing
    else {
      for (var i = 0; i < self.fieldsSpecJson.length; i++) {
        mapped.push(_get_mapped_field(self.fieldsSpecJson[i], self.fieldAttributes[self.fieldsSpecJson[i].key]));
      }
    }
    return mapped;
  }

  function _generate_fields(layoutObject, __fieldAttributes?) {
    var mapped_fields: any[] | any = [];
    switch (layoutObject.type) {

      case "field":

        var field_spec = _get_field_by_key_value(layoutObject.key);
        var field = _get_mapped_field(field_spec, self.fieldAttributes[layoutObject.key]);
        if (field.type === 'button' || field.type == "label") {
          field.templateOptions['classes'] = layoutObject.classes;
        }
        else {
          field['className'] = layoutObject.classes;
        }
        field['className'] += ' field-' + layoutObject.key;
        if (layoutObject.width) {
          if (layoutObject.width.unit === "%") {
            // ToDO : doing % only for now, change it to accept integers as well,
            // also check if it is a valid width% in column spec
            field['className'] = col_classes[layoutObject.width.value] + ' ' + field['className'];
          }
          else {
            field['className'] = col_classes["100"] + ' ' + field['className'];
          }
        }

        if (layoutObject.parentLength && layoutObject.width && layoutObject.width.unit == "share") {
          // ToDO : doing % only for now, change it to accept integers as well,
          // also check if it is a valid width% in column spec
          var rowShare = '' + ((layoutObject.width.value / layoutObject.parentLength) * 100.0).toFixed(2);
          field['className'] = col_classes[rowShare] + ' ' + field['className'];
        }

        mapped_fields.push(field);
        break;

      case "row":

        var row = {
        };
        var fields = [];
        angular.forEach(layoutObject.items, function (item) {
          item["parentLength"] = layoutObject.items.length;
          fields = fields.concat(_generate_fields(item, self.fieldAttributes));
        });
        row["className"] = 'inputs ' + layoutObject.classes;
        row["fieldGroup"] = fields;
        mapped_fields = row;
        break;

      case "block":

        angular.forEach(layoutObject.items, function (item) {
          mapped_fields = mapped_fields.concat(_generate_fields(item, self.fieldAttributes));
        });
        break;

      default:
        console.error(
          "Unknown layout object type found!");
    }
    return mapped_fields;
  }

  function _get_field_by_key_value(key) {
    var matched_field = null;
    angular.forEach(self.fieldsSpecJson, function (field) {
      if (field.key === key) {
        matched_field = field;
        return false;
      }
    });
    return matched_field;
  }

  function _get_mapped_field(elem, attr) {
    var formly_elem = { 'templateOptions': {}, 'data': {}, type: null };

    formly_elem['key'] = elem.key;
    formly_elem['defaultValue'] = elem.default_value || '';

    formly_elem.templateOptions['placeholder'] = elem.placeholder || elem.short_desc || "";
    formly_elem.templateOptions['required'] = elem.required;
    //formly_elem.templateOptions['description'] = elem.short_desc;
    formly_elem.templateOptions['label'] = elem.label;

    if (elem.dependent_on !== null) {
      dependencies_map[elem.key] = elem.dependent_on;
    }

    formly_elem.data['parent_controller'] = self.parentController;
    formly_elem.data['mappedFields'] = self.mappedFields;
    formly_elem.data['server_validate'] = elem.server_validate;
    formly_elem.data['long_desc'] = elem.long_desc;
    formly_elem.data['server_auto_suggest'] = elem.server_auto_suggest;
    formly_elem.data['server_validate'] = elem.server_validate;
    formly_elem['hideExpression'] = attr.hidden_field || "";

    switch (elem.type) {
      case 'TEXT_BOX':
        {
          formly_elem['type'] = 'input';
          formly_elem.templateOptions['type'] = 'text';
          break;
        }
      case 'NUMERIC_TEXT_BOX':
        {
          formly_elem['type'] = 'input';
          formly_elem.templateOptions['type'] = 'number';
          break;
        }
      case 'EMAIL':
        {
          formly_elem['type'] = 'input';
          formly_elem.templateOptions['type'] = 'email';
          break;
        }
      case 'PASSWORD':
        {
          formly_elem['type'] = 'input';
          formly_elem.templateOptions['type'] = 'password';
          break;
        }
      case 'SERVICE_FILE':
        {
          break;
        }
      case 'PARAGRAPH_TEXT':
        {  // ToDo :  if row, columns required? if it should be non expandable?
          formly_elem['type'] = 'textarea';
          if (attr.hasOwnProperty('onChange')) {
            formly_elem.templateOptions['onChange'] = getCustomMethod(attr.onChange);
          }
          formly_elem.templateOptions['rows'] = attr.rows || 8;
          // formly_elem.templateOptions['cols'] = 75;
          break;
        }
      case 'URL':
        {
          formly_elem['type'] = 'input';
          formly_elem.templateOptions['type'] = 'url';
          break;
        }
      case 'CHECKBOX':
        { // Or Bool
          formly_elem['type'] = 'checkbox';
          break;
        }
      case 'SEARCHABLE_LIST':
        {
          formly_elem['type'] = attr.ui_type || 'ui-select';
          formly_elem.templateOptions['label'] = elem.label;
          formly_elem.templateOptions['options'] = elem.items;  // backend validation :  required key
          break;
        }
      case 'SINGLE_SELECT_LIST':
        {
          formly_elem['type'] = attr.ui_type || 'select';
          formly_elem['ngModelAttrs'] = {
            size: {
              attribute: 'size'
            }
          };

          formly_elem.templateOptions['size'] = attr.size || 0;
          formly_elem.templateOptions['options'] = elem.items;  // backend validation :  required key
          formly_elem.templateOptions['onChange'] = getCustomMethod(attr.onChange);/*function (value, element, scope) {
          //toOD ; put if check for existence of element in depedency map
          var params = {};
          params[elem.key] = value;
          Singularity.augmentDsConfigSpecParams(params, scope, element.onChangeCallback);
        };*/
          break;
        }
      case 'DISPLAY_LIST':
        {
          formly_elem['type'] = 'select';
          formly_elem['ngModelAttrs'] = {
            size: {
              attribute: 'size'
            },
            disabled: {}
          };

          formly_elem["controller"] = /*@ngInject*/['$scope', function ($scope) {

          }];
          formly_elem.templateOptions['size'] = attr.size;
          formly_elem.templateOptions['options'] = elem.items;  // backend validation :  required key
          break;
        }
      case 'GRID':
        {
          formly_elem['type'] = 'grid';
          formly_elem.templateOptions['rows'] = attr.rows;
          formly_elem.templateOptions['metadata'] = attr.metadata;

          break;
        }
      case 'BUTTON':
        {

          formly_elem['type'] = 'button';
          formly_elem.templateOptions['disableIf'] = attr.disableIf || "";
          formly_elem.templateOptions['text'] = elem.placeholder;
          formly_elem.templateOptions['onClickParams'] = attr.onClickParams;
          formly_elem.templateOptions['onClick'] = getCustomMethod(attr.onClick);
          formly_elem.templateOptions['enabled'] = attr.enabled;
          break;
        }
      case 'LABEL': {
        formly_elem['type'] = 'label';
        formly_elem.templateOptions['label'] = elem.label;
        formly_elem.templateOptions['enabled'] = attr.enabled;
        formly_elem.templateOptions['classes'] = attr.classes;
      }

    }
    return formly_elem;
  }

  function getCustomMethod(methodName) {
    var moduleFunctions = apiUtils[self.moduleType];
    if (moduleFunctions && moduleFunctions.hasOwnProperty(methodName)) {
      return moduleFunctions[methodName];
    }
    switch (methodName) {
      case 'validateDsConfig':
        return Singularity.validateDsConfig;
      case 'augmentDsConfig':
        return Singularity.augmentDsConfigSpecParams;
      case 'reInitializeDsConfigForm':
        return Singularity.triggerDsConfigFormReInitialization;
      default:
        // statements as string, not a function
        return methodName;
    }
  }


  return service;
}
