import {maValidate} from "../common/common.directives";
import * as angular from 'angular';
/**
 * @ngInject
 */
exportDialogController.$inject = ['$scope', '$timeout', 'ActionServiceFactory', '$injector', 'DataviewService', 'utils', '$q', '$stateParams',
                                '$log', 'resources', 'dataviewConfig', '$uibModalInstance', 'EmailExportManagerFactory', 'analyticsService', 'c', '$sce',
                                'ColumnRenamePanelFactory', 'TaskServiceFactory', 'MainGridUnitFactory', 'TaskPanelFactory', 'eventCallbackManagerFactory',
                                 'deploymentConsts', 'Notification', 'SftpExportManagerFactory'];
export function exportDialogController($scope, $timeout, ActionServiceFactory, $injector, DataviewService, utils, $q, $stateParams,
                                $log, resources, dataviewConfig,  $uibModalInstance, EmailExportManagerFactory, analyticsService, c, $sce,
                                ColumnRenamePanelFactory, TaskServiceFactory, MainGridUnitFactory, TaskPanelFactory, eventCallbackManagerFactory,
                                deploymentConsts, Notification, SftpExportManagerFactory) {
  this.$onInit = function () {
    var mvm = this;

    mvm.create = create;
    mvm.validationSuccess = false;
    mvm.dataview = $scope.dataview;
    mvm.condition = $scope.condition;
    mvm.onIncludeHiddenValueChange = onIncludeHiddenValueChange;
    mvm.onTargetUserWorkspaceChange = onTargetUserWorkspaceChange;
    mvm.closeExportDialog = closeExportDialog;
    mvm.exportVariant = [
      {"val": "csv", "disp": "Download CSV"},
      {"val": "database", "disp": "Database"},
      {"val": "email", "disp": "Send via Email"},
      {"val": "internal_dataset", "disp": "Send to another user in this Workspace"},
      {"val": "sftp", "disp": "Export to SFTP"},
    ];

    mvm.exportDBVariant = [
      {"val": "mysql", "disp": "MySQL"},
      {"val": "mssql", "disp": "Sql Server"},
      {"val": "postgres", "disp": "Postgresql"},
      {"val": "elasticsearch", "disp": "Elasticsearch"},
      {"val": "redshift", "disp": "AWS Redshift"}
      // {"val": "powerbi", "disp": "Power Bi"}
    ];

    mvm.connectionVariant = [
      {"val": "http", "disp": "HTTP"},
      {"val": "https", "disp": "HTTPS"}
    ];

    mvm.IncludeHiddenOptions = [
      {'val': true, 'disp': 'Include hidden columns'},
      {'val': false, 'disp': 'Exclude hidden columns'}
    ];

    mvm.selectDatasource = selectDatasource;
    mvm.selectDatabase = selectDatabase;
    mvm.changeDbOptions = changeDbOptions;

    // Setting default behaviour to export data as stored for all db export.
    // Only exception is data is exported as seen for csv export, where this
    // value of useFormat gets overridden.
    // Also, there is no UI in frontend to change this value.

    mvm.useFormat = false;
    mvm.includeHidden = false;
    mvm.exportOption = "csv";
    mvm.exportDBOption = "mysql";

    mvm.setDownloadOption = setDownloadOption;
    mvm.managerOption = "s3";
    mvm.submit = submit;
    mvm.submitInProgress = false;
    mvm.validateInProgress = false;
    mvm.serversideValidationError = undefined;
    mvm.waitingForCompletion = false;
    mvm.checkValidity = checkValidity;
    mvm.dataview = DataviewService.get_by_id(mvm.dataview.id);
    mvm.serversideValidate = serversideValidate;
    mvm.isEmail = false;
    mvm.triggerType = "none";
    mvm.columnRenamePanel = ColumnRenamePanelFactory.get(mvm.dataview);
    mvm.dataviewService = DataviewService;
    mvm.TaskService = TaskServiceFactory.get_by_dataview_id(mvm.dataview.id);
    mvm.mainGridUnit = MainGridUnitFactory.get(mvm.dataview);
    mvm.task_panel = TaskPanelFactory.get(mvm.dataview, mvm.mainGridUnit);
    mvm.showSftpIfEnabled = showSftpIfEnabled;


    var initPromise = $q.defer();

    var ActionService;
    ActionService = ActionServiceFactory.get_by_dataview_id(mvm.dataview.id);
    mvm.actionService = ActionService;

    function showSftpIfEnabled(item) {
      let flag = (item.val != 'sftp' || mvm.actionService.isSftpEnabled());
      return flag;
    }

    setDownloadOption();

    create(undefined, mvm.managerOption);

    function selectDatasource(dsVariant) {
      mvm.exportOption = dsVariant.val;
    }

    function selectDatabase(dbVariant) {
      mvm.exportDBOption = dbVariant.val;
    }

    function setDownloadOption(trackEvent?) {
      if (trackEvent) {
        analyticsService.userEventTrack(c.userEvents.exportEvents.exportOptionChanged,
          {
            eventOrigin: "exportDialog",
            value: mvm.exportOption
          });
      }

      if (mvm.exportOption == "csv") {
        mvm.managerOption = "s3";
        mvm.isEmail = false;

        // Setting default behaviour as seen for export as CSV.
        // This is need as there is no point of having as stored as
        // option for csv export (it is meant for export as db only).

        create(undefined, mvm.managerOption);
      } else if (mvm.exportOption == "database") {
        changeDbOptions();
      } else if (mvm.exportOption == 'email') {
        mvm.managerOption = 'email';
        create(undefined, mvm.managerOption);
      } else if (mvm.exportOption == 'sftp') {
        mvm.managerOption = 'sftp';
        create(undefined, mvm.managerOption);
      } else if (mvm.exportOption == 'internal_dataset') {
        mvm.managerOption = 'internal_dataset';
        create(undefined, mvm.managerOption);
      }

    }

    function changeDbOptions(trackEvent?) {
      if (trackEvent) {
        analyticsService.userEventTrack(c.userEvents.exportEvents.changeDbOption,
          {
            eventOrigin: "exportDialog",
            value: mvm.exportDBOption
          });
      }
      mvm.managerOption = mvm.exportDBOption;
      if (dataviewConfig.dbActions.includes(mvm.exportDBOption))
        mvm.newConnectionInfo = "Please make sure IP " + deploymentConsts.MAMMOTH_OUTBOUND_IP + " is whitelisted in your database settings.";
      else
        mvm.newConnectionInfo = null
      create(undefined, mvm.managerOption);
    }

    function create(createParams, managerName) {
      if (managerName == 'email') {
        mvm.manager = new EmailExportManagerFactory(mvm.dataview);
        initPromise.resolve(mvm.manager);
      }
        // else if(managerName == 'sftp'){
        //   mvm.manager = new SftpExportManagerFactory(mvm.dataview);
        //   initPromise.resolve(mvm.manager);
      // }
      else {
        mvm.serversideValidationError = undefined;
        if (managerName) {
          mvm.dataview.get_data().then(_getDataCb());
        }
      }

      function _getDataCb() {
        var manager_factory = $injector.get(dataviewConfig.get_manager_factory_from_action_name(managerName));
        mvm.manager = manager_factory.get_manager(
          {
            triggerType: mvm.triggerType,
            isEmail: mvm.isEmail,
            metadata: utils.metadata.add_type_to_display_name(mvm.dataview.metadata),
            context: {
              dataview: mvm.dataview,
              inEditMode: false,
              condition: mvm.condition,
              createFilterTransform: mvm.condition != null && Object.keys(mvm.condition).length > 0
            }
          }
        );
        if (createParams !== undefined) {
          mvm.manager.setParam(createParams);
        }
        initPromise.resolve(mvm.manager);
      }

    }

    function checkValidity() {
      if (mvm.exportDialogForm.$dirty && mvm.validationSuccess) {
        mvm.validationSuccess = false;
      }
    }

    function serversideValidate() {
      if (!mvm.manager.validate() || mvm.validateInProgress) {
        return;
      }
      mvm.validateInProgress = true;
      mvm.serversideValidationError = {};
      var param = mvm.manager.getParam();
      param.target_properties.include_hidden = mvm.includeHidden;
      param['DATAVIEW_ID'] = parseInt($stateParams.dataviewId);
      ActionService.serverside_validate(param).then(submission_success, submission_failure);

      function submission_success() {
        $timeout(function () {
          mvm.validateInProgress = false;
        });
        mvm.exportDialogForm.$setPristine();
        mvm.validationSuccess = true;
      }

      function submission_failure(data) {
        $timeout(function () {
          mvm.validateInProgress = false;
        });
        if (!data.hasOwnProperty("information") || !data.information.hasOwnProperty("message") || !data.information.hasOwnProperty("error")) {
          mvm.serversideValidationError['message'] = "Could not validate, please retry after sometime";
          mvm.serversideValidationError['error'] = undefined;
        } else {
          mvm.serversideValidationError['message'] = data.information.message;
          var error_with_url = utils.linkify(data.information.error);
          $sce.trustAsHtml(error_with_url);
          mvm.serversideValidationError['error'] = error_with_url;
        }
      }
    }

    function submit() {
      if (!mvm.manager.validate()) {
        return;
      }
      $uibModalInstance.close();
      mvm.submitInProgress = true;
      var param = mvm.manager.getParam();
      param['DATAVIEW_ID'] = parseInt($stateParams.dataviewId);
      if (mvm.condition) {
        param['condition'] = mvm.condition;
      }
      if (!param.target_properties) {
        param.target_properties = {}
      }
      if (param.handler_type == 's3' || param.handler_type == 'email') {
        // Default format for s3 and email (csv) is 'as seen'
        param.target_properties.use_format = true;

      } else {
        // Default format for DB Exports is 'as stored'
        param.target_properties.use_format = mvm.useFormat;

      }

      param.target_properties.include_hidden = mvm.includeHidden;
      ActionService.add_action(param).then(submission_success, submission_failure);

      function submission_success() {
        analyticsService.userEventTrack(c.userEvents.exportEvents.successfull, {
          handlerType: param.handler_type,
          eventOrigin: "exportDialog"
        });
        resources.update();
        ActionService.update_list();
        mvm.submitInProgress = false;
        $timeout(function () {
          mvm.waitingForCompletion = true;
        }, 0);
      }

      function submission_failure(data) {
        $log.error(arguments);
        mvm.submitInProgress = false;
        var message = 'The action could not be submitted, please try again or contact support for more information';
        if (data.data && data.data.ERROR_MESSAGE) {
          message = data.data.ERROR_MESSAGE;
          Notification.error(message);
        }
        if (data?.information?.error?.message) {
          Notification.error(data.information.error.message);
        }
        analyticsService.userEventTrack(c.userEvents.exportEvents.failure, {
          handlerType: param.handler_type,
          errorMessage: message,
          eventOrigin: "exportDialog"
        });

      }
    }

    function onIncludeHiddenValueChange() {
      analyticsService.userEventTrack(c.userEvents.exportEvents.exportHiddenColumnsChanged, {
        eventOrigin: "exportDialog",
        includeHidden: mvm.includeHidden
      });

    }


    function onTargetUserWorkspaceChange() {
      analyticsService.userEventTrack(c.userEvents.exportEvents.onTargetUserWorkspaceChange, {
        eventOrigin: "exportDialog",
      });
    }

    function closeExportDialog(origin) {
      analyticsService.userEventTrack(c.userEvents.exportEvents.cancelButtonClicked, {
        eventOrigin: "exportDialog." + origin
      });
    }

    return mvm;
  }
}

/**
 * @ngInject
 */
EmailExportManagerFactoryFn.$inject = ['UserWorkspace'];
export function EmailExportManagerFactoryFn(UserWorkspace){
  return EmailExportManager;

  function EmailExportManager(dataview){
    var self = this;
    self.emails = [UserWorkspace.loggedInEmail];
    self.subject = dataview.datasource.name + " - " + dataview.name + " is ready for download";
    self.message = null;
    self.getParam = getParam;
    self.validate = function(){
      return true;
    };

    function getParam(){
      let param: any = {
        "handler_type": "email",
        "trigger_type": "none",
        "run_immediately": true,
        "target_properties": {
          "emails": self.emails,
          "subject": self.subject,
          "message": self.message,
          "resource": dataview.datasource.name + " - " + dataview.name
        }
      };
      return param;
    }
  }
}

export function SftpExportManagerFactoryFn(){
  return SftpExportManager;

  function SftpExportManager(dataview){
    var self = this;
    self.host = "";
    self.port = 22;
    self.username = "";
    self.password = "";
    self.directory = "";
    self.getParam = getParam;
    self.validate = function(){
      return true;
    };

    function getParam(){
      let param: any = {
        "handler_type": "sftp",
        "trigger_type": "none",
        "run_immediately": true,
        "target_properties": {
          "host": self.host,
          "port": self.port,
          "username": self.username,
          "password": self.password,
          "directory": self.directory,
        }
      };
      return param;
    }
  }
}
