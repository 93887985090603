<template>
  <div class="mm-data-addition-webhook mm-rounded-borders">
    <div style="width: 380px">
      <mm-text-input
        v-model="datasetName"
        :title="$t('data_library.data_addition.webhook.new_ds_name')"
        :placeholder="$t('data_library.data_addition.webhook.new')"
        :input-attrs="{ maxlength: DATASET_NAME_MAX_LENGTH }"
      />
      <mm-dropdown
        v-model="datasetMode"
        :title="$t('data_library.data_addition.webhook.ds_mode')"
        :items="DATASET_MODES"
      />
    </div>

    <mm-row no-gutters class="m-t-7">
      <mm-button
        v-if="isBackButtonVisible"
        :label="$t('global.dictionary.back')"
        objective="tertiary"
        @click="redirectToMainMenu"
      />
      <mm-spacer />
      <mm-button :label="$t('global.dictionary.cancel')" objective="tertiary" @click="$emit('cancel')" />
      <mm-button
        class="m-l-3"
        :label="$t('global.dictionary.submit')"
        :disabled="!datasetName"
        :loading="loading"
        @click="submit"
      />
    </mm-row>
  </div>
</template>

<script>
// Constants
import { USER_EVENTS, DATASET_NAME_MAX_LENGTH } from '@/constants'

// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

export default {
  name: 'data-library-data-addition-webhook',
  props: {
    folderResourceId: [String, Number],
    isBackButtonVisible: Boolean
  },
  data: () => ({
    datasetName: '',
    datasetMode: 'Replace',
    loading: false
  }),
  created() {
    this.DATASET_NAME_MAX_LENGTH = DATASET_NAME_MAX_LENGTH
    this.DATASET_MODES = [
      { text: this.$t('data_library.data_addition.webhook.combine'), value: 'Combine' },
      { text: this.$t('data_library.data_addition.webhook.replace'), value: 'Replace' }
    ]
  },
  methods: {
    redirectToMainMenu() {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.BACK_TO_MAIN, { type: 'webhook' })
      this.$emit('back')
    },
    async submit() {
      this.loading = true
      const payload = {
        projectId: this.$store.state.projectId,
        folderResourceId: this.folderResourceId,
        name: this.datasetName,
        mode: this.datasetMode
      }
      const { data } = await dataLibraryApi.addWebhook(payload)

      if (data.ERROR_CODE) {
        const errorMessage = this.$te(`global.api.${data.ERROR_CODE}`)
          ? this.$t(`global.api.${data.ERROR_CODE}`)
          : this.$t('global.api.generic_error')
        this.$toast.show({ content: errorMessage, status: 'error' })
      }

      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.WEBHOOK.SUBMIT, payload)

      this.$emit('submit')
      this.loading = false
    }
  }
}
</script>
