import * as angular from 'angular';
import {
  sortElements, elementModalDataviewPreview, drilldownElement, elementDraggable,
  elementResizable, watchCollectionSaveForm, onElementInputChange, elementContainerDirective
} from './elements.directive';
import { elementModalService } from './element.modal.service';
import { elementCommonModule } from './common/index';
import { ElementModalController } from './element.modal.controller';
import { chartModule } from './chart/index';
import { filterModule } from './filter/index';
import { metricModule } from './metric/index';
import { tableModule } from './table/index';
import {config} from "../common/app.config";

var Highcharts = require('highcharts');
window['Highcharts'] = Highcharts;
require('highcharts/modules/exporting')(Highcharts);
var highchartsGrayTheme = require('../../../assets/scripts/lib/highcharts/grayTheme');

export let elementModule =  angular.module('app.element', [
  'app.core',
  elementCommonModule.name,
  chartModule.name,
  filterModule.name,
  metricModule.name,
  tableModule.name
  ]);

elementModule.service('elementModalService', elementModalService);
elementModule.controller(config.controllers.elementModal, ElementModalController);
elementModule.directive('elementContainer', elementContainerDirective);
elementModule.directive('onElementInputChange', onElementInputChange);
elementModule.directive('watchCollectionSaveForm', watchCollectionSaveForm);
elementModule.directive('elementResizable', elementResizable);
elementModule.directive('elementDraggable', elementDraggable);
elementModule.directive('drilldownElement', drilldownElement);
elementModule.directive('elementModalDataviewPreview', elementModalDataviewPreview)
elementModule.filter('sortElements', sortElements);
elementModule.run(onRun);

/**
 * @ngInject
 */

function onRun($templateCache, c, elementConfig, $http){
  // Object.keys(c.elementTypes).forEach(function (eType) {
  //   function putToTemplateCache(url) {
  //     $http.get(url).success(function (data) {
  //       $templateCache.put(url, data);
  //     });
  //
  //   }
  //
  //   putToTemplateCache(elementConfig[eType].createTemplate);
  //   putToTemplateCache(elementConfig[eType].displayTemplate);
  // });
  if (window['Highcharts']) {
    window['Highcharts'].setOptions(highchartsGrayTheme);
  }
}

onRun.$inject = ['$templateCache', 'c', 'elementConfig', '$http'];


