// APIs
import API from '@/api'
import globalApi from '@/api/global.api'

// Constants
import { REQUESTS_STATUS, EXPORT_CONNECTORS_MAP } from '@/constants'

const publishReport = ({ projectId, viewId, name, emails, message, autoSync }) =>
  API.post('reports', {
    project_id: projectId,
    dataview_id: viewId,
    name,
    emails,
    message,
    sync_type: autoSync ? 'AUTO' : 'MANUAL'
  })

const generateLiveLink = async ({ viewId, fileName, message }) => {
  const { data } = await API.post(`dataviews/${viewId}/actions`, {
    param: {
      handler_type: 's3',
      trigger_type: 'pipeline',
      run_immediately: true,
      sequence: null,
      additional_properties: {
        no_notification: true,
        autoRun: true,
        invisible: true,
        completionMessage: message,
        liveLink: true
      },
      target_properties: {
        file: fileName,
        is_format_set: true,
        use_format: true,
        include_hidden: false
      },
      DATAVIEW_ID: viewId
    }
  })
  return await globalApi.getFutureRequest(data.future_id)
}

const downloadCsv = async ({ viewId, fileName, condition, sequence, fileType }) => {
  const { data } = await API.post(`dataviews/${viewId}/actions`, {
    param: {
      handler_type: 's3',
      trigger_type: 'none',
      run_immediately: true,
      sequence,
      condition,
      additional_properties: {},
      target_properties: {
        file: fileName,
        is_format_set: true,
        use_format: true,
        include_hidden: false,
        file_type: fileType
      },
      DATAVIEW_ID: viewId
    }
  })
  return await globalApi.getFutureRequest(data.future_id)
}

const exportToExistingDatabase = async ({
  viewId,
  actionId,
  databaseHandler,
  targetProperties,
  sequence,
  onlyValidate
}) => {
  const { data } = await API.post(`dataviews/${viewId}/actions`, {
    param: {
      handler_type: databaseHandler,
      trigger_type: onlyValidate ? 'none' : 'pipeline',
      run_immediately: true,
      sequence,
      target_properties: {
        ...targetProperties,
        trigger_id: actionId || null
      },
      id: actionId || null,
      DATAVIEW_ID: viewId,
      validate_only: onlyValidate
    }
  })

  if (data.future_id) return await globalApi.getFutureRequest(data.future_id)
  return data
}

const saveToDataset = async ({ viewId, filterCondition, sequence, datasetName }) => {
  const { data } = await API.post(`dataviews/${viewId}/actions`, {
    param: {
      DATAVIEW_ID: viewId,
      condition: filterCondition,
      handler_type: 'internal_dataset',
      run_immediately: true,
      sequence,
      target_properties: {
        DS_NAME: datasetName,
        SAVE_AS_DS_MODE: 'REPLACE_IN_DS',
        TRANSFORM: null,
        include_hidden: false,
        use_format: false
      },
      trigger_type: 'none'
    }
  })

  if (data.future_id) return await globalApi.getFutureRequest(data.future_id)
  return data
}

const patchExport = async ({ viewId, actionId, databaseHandler, targetProperties, sequence }) => {
  const { data } = await API.patch(`dataviews/${viewId}/actions/${actionId}`, {
    patch: [
      {
        op: 'replace',
        path: 'params',
        value: {
          handler_type: databaseHandler,
          trigger_type: 'pipeline',
          run_immediately: true,
          sequence: sequence,
          target_properties: {
            ...targetProperties,
            trigger_id: actionId
          },
          targetDataset: null,
          id: actionId,
          DATAVIEW_ID: viewId
        }
      }
    ]
  })

  if (data.future_id) return await globalApi.getFutureRequest(data.future_id)
  return data
}

const getActionDetails = ({ viewId, actionId }) => API.get(`dataviews/${viewId}/actions/${actionId}`)

const getPublishCredentials = ({ odbcType, projectId }) =>
  API.get(`publish_credentials?odbc_type=${odbcType}&project_id=${projectId}`)

const createMammothManagedCredentials = async ({ viewId, tableName }) => {
  let response
  try {
    response = await API.post(
      `dataviews/${viewId}/publish`,
      {
        param: {
          odbc_type: EXPORT_CONNECTORS_MAP.POSTGRES.key,
          template: 'config.templates.publishdb',
          target_properties: {
            table: tableName,
            odbc_type: EXPORT_CONNECTORS_MAP.POSTGRES.key
          }
        }
      },
      {
        customErrorHandling: true
      }
    )
  } catch (e) {
    return e
  }

  return await globalApi.getFutureRequest(response.data.future_id)
}

const sftpExport = ({ viewId, targetProperties, sequence, onlyValidate }) =>
  API.post(`dataviews/${viewId}/actions`, {
    param: {
      handler_type: EXPORT_CONNECTORS_MAP.SFTP.key,
      trigger_type: onlyValidate ? 'none' : 'pipeline',
      run_immediately: true,
      sequence,
      target_properties: targetProperties,
      DATAVIEW_ID: viewId,
      validate_only: onlyValidate
    }
  })

const getBigQueryUrl = (projectId, identity) => {
  const integration = btoa(EXPORT_CONNECTORS_MAP.BIGQUERY.key)
  identity = identity ? '/' + identity : ''
  const query = `project_id=${projectId}&is_export=true` + (identity ? '&concern=profiles' : '')
  return `/integrations/${integration}/identities${identity}?${query}`
}

const createBigQueryIdentity = async ({ projectId, connectionData }) => {
  const { data } = await API.post(getBigQueryUrl(projectId), { connection_data: connectionData, project_id: projectId })
  return await globalApi.getFutureRequest(data.future_id)
}

const getBigQueryIdentities = (projectId) => API.get(getBigQueryUrl(projectId))

const getBigQueryIdentity = async (projectId, identity) => {
  const { data } = await API.get(getBigQueryUrl(projectId, identity))
  return await globalApi.getFutureRequest(data.future_id)
}

const deleteBigQueryIdentity = (projectId, identity) => API.delete(getBigQueryUrl(projectId, identity))

export default {
  publishReport,
  generateLiveLink,
  downloadCsv,
  exportToExistingDatabase,
  saveToDataset,
  patchExport,
  createMammothManagedCredentials,
  sftpExport,
  getActionDetails,
  getPublishCredentials,
  createBigQueryIdentity,
  getBigQueryIdentities,
  getBigQueryIdentity,
  deleteBigQueryIdentity
}
