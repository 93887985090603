<template>
  <mm-modal
    v-model="computedIsModalOpen"
    content-class="mm-project-modal-manage"
    :title="title || $t('project.manage.manage_users.title')"
    width="600"
    closable
  >
    <div style="color: var(--mm-color--n400)" class="m-b-7">
      <span v-html="$t('project.manage.info', { projectName: computedProjectName })" />
      <span v-if="$store.getters.getCurrentWorkspacePermissions != WORKSPACE_PERMISSIONS.MEMBER">
        {{ $t('project.manage.manage_users.add_users') }}
        <mm-link :label="$t('global.click_here')" @click="addUsers" />
        .
      </span>
    </div>

    <div class="d-flex">
      <mm-dropdown
        v-model="emailToShareId"
        style="flex-grow: 1"
        :disabled="computedDisableEmailToShare"
        :placeholder="$t('project.manage.emails_to_share_placeholder')"
        :items="computedUsersInWorkspace"
        itemText="email"
        itemValue="id"
        hide-details
        @change="setDefaultPermission"
      />

      <mm-dropdown
        v-model="userToAddPermission"
        class="m-x-3"
        :items="permissionOptions"
        :width="120"
        hide-details
        @change="onUserToAddPermissionChange"
      />

      <mm-button
        icon="plus"
        :label="$t('global.dictionary.add')"
        :disabled="!emailToShareId"
        :loading="isAddUserLoading"
        @click="addToProject(emailToShareId)"
      />
    </div>

    <mm-divider class="m-t-6 m-b-5" />

    <mm-loader v-if="isUsersListLoading" class="m-y-0 m-x-auto" size="large" />

    <template v-else>
      <div class="d-flex m-b-5">
        <span class="mm-text--h300">{{ $t('project.manage.users_with_access') }}</span>
        <mm-spacer />
        <div v-if="!hideActions" class="d-flex">
          <mm-button
            v-if="computedUsersInProject.length"
            :disabled="!computedIsAnyUserSelected"
            class="m-r-5"
            objective="secondary"
            icon="delete"
            size="small"
            :label="$t('project.manage.remove_users')"
            @click="removeUsers()"
          />
          <mm-button
            v-if="$store.state.user.roles.projects[projectId]"
            :disabled="computedIsAnyUserSelected"
            objective="secondary"
            size="small"
            :label="$t('project.manage.leave.title')"
            @click="openModal(MENU_OPTIONS.LEAVE)"
          />
          <mm-button
            v-else
            objective="secondary"
            size="small"
            :label="$t('project.manage.join.title')"
            @click="openModal(MENU_OPTIONS.JOIN)"
          />
        </div>
      </div>

      <div class="users-in-project">
        <span
          v-if="computedUsersInProject.length == 0"
          class="d-flex-center mm-text--h200 m-y-5"
          style="color: var(--mm-color--n300)"
        >
          {{ $t('project.manage.no_users') }}
        </span>

        <div
          v-for="user in computedUsersInProject"
          :key="user.email"
          class="d-flex justify-space-between mm-rounded-borders m-b-3"
          :class="{ 'selected-row': usersSelection[user.id] }"
          style="height: 32px"
        >
          <div class="d-flex-center p-l-2">
            <div v-if="!hideActions" style="width: 32px">
              <mm-checkbox v-if="!isCurrentUser(user)" v-model="usersSelection[user.id]" />
            </div>
            <mm-avatar :name="user.name" :is-current-user="isCurrentUser(user)" :picture="user.profilePic" small />
            <p class="m-l-3 m-b-0">
              {{ user.name }}
              <span v-if="isCurrentUser(user)" style="color: var(--mm-color--n400)">
                ({{ $t('dictionary.you').toLowerCase() }})
              </span>
            </p>
          </div>

          <span v-if="isCurrentUser(user)" class="d-flex-center m-r-8">{{ $t(`roles.${user.permission}`) }}</span>
          <mm-menu v-else menu-class="mm-project-modal-manage--user-menu">
            <template #activator="{ on }">
              <mm-dropdown
                v-model="user.permission"
                class="m-r-2"
                :items="permissionOptions"
                :width="90"
                inline
                hide-details
                readonly
                v-on="on"
              />
            </template>
            <template #content>
              <mm-list
                class="p-a-3"
                :items="userMenuOptions"
                :divider-at-index="[2]"
                @itemClick="onUserMenuClick(user, $event.item.value)"
              />
            </template>
          </mm-menu>
        </div>
      </div>
    </template>

    <component
      v-if="modalComponent"
      :is="modalComponent"
      v-model="isActionModalOpen"
      :projectId="projectId"
      @submit="
        $emit('optionExecuted', modalType)
        fetchData()
      "
    />
  </mm-modal>
</template>

<script>
// API
import globalApi from '@/api/global.api'
import projectApi from '@/modules/project/api/project.api.js'

// Constants
import { PROJECT_PERMISSIONS, WORKSPACE_PERMISSIONS, USER_EVENTS, getOldAppUrl } from '@/constants'

// Components
import ModalLeave from './project-modal-leave'
import ModalJoin from './project-modal-join'

// Plugins
import emitter from '@/plugins/emitter'

export default {
  name: 'project-modal-manage',
  props: {
    value: Boolean,
    projectId: {
      type: [String, Number],
      required: true
    },
    title: String,
    hideActions: Boolean
  },
  data() {
    return {
      isEmailToShareDisabled: false,
      emailToShareId: '',
      userToAddPermission: PROJECT_PERMISSIONS.ANALYST,
      usersInProject: [],
      usersInWorkspace: [],
      isAddUserLoading: false,
      isUsersListLoading: false,
      usersSelection: {},
      isActionModalOpen: false,
      modalComponent: null,
      modalType: ''
    }
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    computedProjectName() {
      return this.$store.getters.getProjectById(this.projectId)?.name
    },
    computedIsAnyUserSelected() {
      return !!(Object.values(this.usersSelection).filter((val) => val).length && this.computedUsersInProject.length)
    },
    computedDisableEmailToShare() {
      return this.isEmailToShareDisabled || this.usersInWorkspace.length === 0
    },
    computedUsersInWorkspace() {
      return this.usersInWorkspace
        .filter((user) => !this.usersInProject.some((userInProj) => user.id === userInProj.id))
        .sort((a, b) => a.email.localeCompare(b.email))
    },
    computedUsersInProject() {
      return this.usersInProject
        .map((user) => ({ ...user, permission: user.roles.projects[[this.projectId]] }))
        .sort((a, b) => {
          if (this.isCurrentUser(a)) return -1
          if (this.isCurrentUser(b)) return 1
          if (a.permission != b.permission) {
            if (a.permission == PROJECT_PERMISSIONS.ADMIN) return -1
            if (b.permission == PROJECT_PERMISSIONS.ADMIN) return 1
          }
          return a.name.localeCompare(b.name)
        })
    }
  },
  watch: {
    computedIsModalOpen: {
      async handler(val) {
        if (val) {
          this.isUsersListLoading = true
          this.emailToShareId = ''
          await this.fetchData()
          this.isUsersListLoading = false
        }
      },
      immediate: true
    }
  },
  methods: {
    async fetchData() {
      const [usersInProject, usersInWorkspace] = await Promise.all([
        projectApi.getUsersInProject(this.$store.state.workspaceId, this.projectId),
        globalApi.getUsersInWorkspace(this.$store.state.workspaceId)
      ])
      this.usersInProject = usersInProject
      this.usersInWorkspace = usersInWorkspace
    },
    isCurrentUser(user) {
      return user.id == this.$store.state.user.id
    },
    setDefaultPermission(userId) {
      const workspaceUserRole = this.computedUsersInWorkspace.find((user) => user.id == userId).roles.workspaces[
        this.$store.state.workspaceId
      ]
      this.userToAddPermission = [WORKSPACE_PERMISSIONS.ADMIN, WORKSPACE_PERMISSIONS.OWNER].includes(workspaceUserRole)
        ? PROJECT_PERMISSIONS.ADMIN
        : PROJECT_PERMISSIONS.ANALYST
    },
    onUserToAddPermissionChange(selection) {
      this.$userEvents.save(USER_EVENTS.SETTINGS.USERS.NEW_ROLE_DROPDOWN_SELECT, { selection })
    },
    async addToProject(userId) {
      this.isEmailToShareDisabled = true
      this.isAddUserLoading = true

      this.$userEvents.save(USER_EVENTS.SETTINGS.USERS.ADD_TO_PROJECT, { userId })

      const response = await projectApi.addToProject(this.projectId, this.emailToShareId, this.userToAddPermission)
      if (!response) {
        this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })
      } else {
        const userToAdd = this.usersInWorkspace.find((user) => user.id === userId)
        userToAdd.roles.projects[this.projectId] = this.userToAddPermission

        this.usersInProject.push(userToAdd)
        this.emailToShareId = ''
        this.$toast.show({ content: this.$t('project.manage.user_successfully_added'), status: 'success' })
      }

      this.isEmailToShareDisabled = false
      this.isAddUserLoading = false
    },
    async onUserMenuClick(user, selectedOption) {
      if (selectedOption == 'REMOVE_USER') this.removeUsers(user.id)
      else {
        this.$userEvents.save(USER_EVENTS.SETTINGS.USERS.ROLE_DROPDOWN_SELECT, { role: selectedOption })
        user.permission = selectedOption
        const response = await projectApi.updateUserPermissionInProject(this.projectId, user.id, selectedOption)
        if (!response) this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })
      }
    },
    async removeUsers(userId) {
      let userIds
      if (userId) userIds = [userId]
      else userIds = Object.keys(this.usersSelection).filter((key) => this.usersSelection[key])

      this.$userEvents.save(USER_EVENTS.SETTINGS.USERS.REMOVE_FROM_PROJECT, { userIds })

      await Promise.all([userIds.map((id) => this.removeUserFromProject(id))])
      this.$toast.show({ content: this.$tc('project.manage.user_successfully_removed', userIds.length) })

      if (userId) delete this.usersSelection[userId]
      else this.usersSelection = {}
    },
    async removeUserFromProject(userId) {
      const response = await projectApi.removeUserFromProject(this.projectId, userId)
      if (!response) this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })
      else this.usersInProject = this.usersInProject.filter((user) => user.id != userId)
    },
    openModal(option) {
      this.$userEvents.save(USER_EVENTS.SETTINGS.USERS.MANAGE_PROJECT_OPEN_MODAL, { option })

      const optionsMap = {
        [this.MENU_OPTIONS.JOIN]: ModalJoin,
        [this.MENU_OPTIONS.LEAVE]: ModalLeave
      }
      this.modalComponent = optionsMap[option]
      this.modalType = option
      this.isActionModalOpen = true
    },
    addUsers() {
      this.computedIsModalOpen = false

      if (window.location.hash.includes('settings')) emitter.emit('settingsInviteUsers')
      //TODO remove when angular code is gone
      else
        window.open(
          `${getOldAppUrl()}/n/#/settings/workspace/${this.$store.state.workspaceId}?addUsers=true`,
          'Settings'
        )
    }
  },
  async created() {
    this.WORKSPACE_PERMISSIONS = WORKSPACE_PERMISSIONS
    this.MENU_OPTIONS = { JOIN: 'JOIN', LEAVE: 'LEAVE' }

    this.permissionOptions = [
      { text: this.$t('global.dictionary.admin'), value: PROJECT_PERMISSIONS.ADMIN },
      { text: this.$t('global.dictionary.analyst'), value: PROJECT_PERMISSIONS.ANALYST }
    ]

    this.userMenuOptions = [
      ...this.permissionOptions,
      { text: this.$t('global.dictionary.remove'), value: 'REMOVE_USER', class: { 'remove-option': true } }
    ]
  }
}
</script>

<style lang="scss">
$selected-color: var(--mm-color--p100);

.mm-project-modal-manage {
  .users-in-project {
    max-height: 250px;
    overflow: auto;

    .selected-row {
      background: $selected-color;

      .mm-dropdown .v-input__slot {
        background: $selected-color;
      }
    }
  }
}

.mm-project-modal-manage--user-menu {
  .remove-option {
    label {
      color: var(--mm-color--dest800) !important;
    }
  }
}
</style>
