import * as angular from 'angular';
import { csvDataService } from './csvdata.service';
import { rawDataController } from './raw.controller';
import { rawDataModalService } from './rawdata.service';
import { unstructureDataController} from '../unstructured/unstructure.controller';
import { unstructureDataModalService} from '../unstructured/unstrutureDataModal.service';

export let rawDataModule = angular.module('app.rawData', ['ui.bootstrap']);
rawDataModule.service('csvData', csvDataService);
rawDataModule.controller('rawDataController', rawDataController);
rawDataModule.service('rawData', rawDataModalService);
rawDataModule.controller('unstructureDataController', unstructureDataController);
rawDataModule.service('unstructureDataModal', unstructureDataModalService);
