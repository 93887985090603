<template>
  <mm-modal
    v-model="computedShowModal"
    :title="$t('action_bar.export.export_to_database.mammoth_managed_database.table_name.modal_title')"
    :width="386"
    closable
  >
    <mm-text-input
      v-model="tableName"
      :title="$t('action_bar.export.export_to_database.existing_database.credentials.table_name')"
      :placeholder="$t('action_bar.export.export_to_database.existing_database.credentials.table_name')"
      :error="!!errorMessage.length"
      :message="errorMessage"
      @input="errorMessage = ''"
    />
    <p class="mm-text--caption-regular d-flex align-center m-t-5 m-b-7">
      <mm-icon class="m-r-2" name="information" />
      {{ $t('action_bar.export.export_to_database.mammoth_managed_database.table_name.info') }}
    </p>
    <template #actions>
      <div class="d-flex w-100" style="gap: 8px">
        <mm-button
          class="flex-grow-1"
          :label="$t('dictionary.cancel')"
          objective="tertiary"
          @click="computedShowModal = false"
        />
        <mm-button
          class="flex-grow-1"
          :label="$t('dictionary.apply')"
          :loading="loading"
          :disabled="!tableName"
          @click="generateCredentials"
        />
      </div>
    </template>
  </mm-modal>
</template>

<script>
import actionBarApi from '@/modules/data-editor/action-bar/action-bar.api'
import { USER_EVENTS, EXPORT_CONNECTORS_MAP } from '@/constants'

export default {
  name: 'action-bar-export-table-name',
  props: {
    value: Boolean
  },
  data: (vm) => ({
    loading: false,
    tableName: vm.getDefaultTableName(),
    errorMessage: ''
  }),
  computed: {
    computedShowModal: {
      get() {
        return this.value
      },
      set(showModal) {
        this.$emit('input', showModal)
      }
    }
  },
  methods: {
    async generateCredentials() {
      this.loading = true
      const viewId = this.$store.getters['dataEditor/view'].viewId
      const projectId = this.$store.state.projectId

      const response = await actionBarApi.createMammothManagedCredentials({
        viewId,
        tableName: this.tableName
      })
      if (response.status == 'error' || response.data?.ERROR_CODE) {
        this.errorMessage = response.message || this.$t(`global.api.${response.data.ERROR_CODE}`)
        this.$toast.show({ content: this.errorMessage, status: 'error' })
      } else {
        this.$root.legacyHandlers.actionServiceUpdateList()
        const publishResponse = await actionBarApi.getPublishCredentials({
          odbcType: EXPORT_CONNECTORS_MAP.POSTGRES.key,
          projectId: projectId
        })
        const { database, password, port, username } = publishResponse.data.credentials
        const host = publishResponse.data.credentials.host_ip
        const actionResponse = await actionBarApi.getActionDetails({
          viewId,
          actionId: response.publish_id
        })
        const table = actionResponse.data.target_properties.table
        this.$emit('apply', { host, port, database, username, password, table })
      }

      this.loading = false
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.SET_TABLE_NAME, {
        viewId: this.$store.state.dataEditor.viewId,
        tableName: this.tableName
      })
    },
    getDefaultTableName() {
      const datasetName = this.$store.getters['dataEditor/dataset'].properties.name
      const viewName = this.$store.getters['dataEditor/view'].properties.name
      return `${datasetName}_${viewName}`.replace(/[-&\\#, +()$~%.'":*?<>{}]/g, '_').toLowerCase()
    }
  }
}
</script>
