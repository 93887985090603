<template>
  <div class="mm-pipeline-card-footer d-flex">
    <mm-tooltip
      v-if="isDataSyncVisible && computedIsDataSyncFeatureEnabled"
      wrapper-class="data-sync"
      :label="computedDataSyncTooltipLabel"
      arrow-margin="20%"
    >
      <mm-icon class="m-x-3 m-y-1" :name="computedStep.dataSync ? 'check' : 'sync_disabled'" />
      <span class="mm-text--caption-regular">{{ $t('data_library.views.data_sync') }}</span>
    </mm-tooltip>

    <mm-spacer />

    <mm-tooltip :label="$t(`view.pipeline.${isPreviewActive ? 'exit_preview' : 'preview_at_task'}`)">
      <span
        v-if="computedStep.rowCount != null"
        class="row-quantity footer-action mm-text--caption-regular mm-rounded-borders m-r-2 p-a-2"
        @click="togglePreview"
      >
        {{
          $tc('view.pipeline.row_count', computedStep.rowCount, { quantity: computedStep.rowCount.toLocaleString() })
        }}
      </span>
    </mm-tooltip>
  </div>
</template>

<script>
//Constants
import { SPLITIO_FEATURE_FLAGS, USER_EVENTS } from '@/constants'

// Plugins
import { featuresVisibility } from '@/plugins/splitio'

// Transforms
import { transformStep } from '@/api/global.transform'

export default {
  name: 'pipeline-card-footer',
  props: {
    step: {
      type: Object,
      required: true
    },
    isPreviewActive: Boolean,
    isDataSyncVisible: Boolean
  },
  computed: {
    computedStep() {
      //TODO remove when angular code is gone
      return transformStep({ ...this.step })
    },
    computedDataSyncTooltipLabel() {
      if (this.computedStep.handlerType == 'internal_dataset')
        return this.$t(`view.pipeline.data_sync_tooltip.internal_dataset_${this.computedStep.dataSync ? 'on' : 'off'}`)
      return this.$t(`view.pipeline.data_sync_tooltip.${this.computedStep.dataSync ? 'on' : 'off'}`)
    },
    computedIsDataSyncFeatureEnabled() {
      return featuresVisibility[SPLITIO_FEATURE_FLAGS.DATA_SYNC]
    }
  },
  methods: {
    togglePreview() {
      const sequence = this.computedStep.sequence || 0
      this.$emit('togglePreview', sequence)
      this.$userEvents.save(USER_EVENTS.PIPELINE.TOGGLE_PREVIEW, {
        id: this.computedStep.id,
        type: this.computedStep.type,
        sequence
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mm-pipeline-card-footer {
  background: var(--mm-color--n30);
  align-items: center;

  ::v-deep .data-sync {
    align-items: center;
    display: flex;

    span {
      margin-top: 1px;
    }
  }

  .footer-action {
    cursor: pointer;

    &:hover {
      background: var(--mm-color--n60);
    }
  }

  .row-quantity {
    color: var(--mm-color--n400);
  }
}
</style>
