/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * @ngInject
 */
dateFormatterController.$inject = ['c', 'utils', 'moment'];
export function dateFormatterController(c, utils, moment) {
  this.$onInit = function () {
    var nf = this;
    nf.dateOptions = c.dateOptions;
    nf.dateType = "US";
    nf.editedFormat = "";
    nf.isOpen = false;

    nf.onToggle = onToggle;
    nf.submit = submit;
    nf.customFormat = {
      display: "",
      value: ""
    };

    nf.updateCustomFormat = function (format) {
      if (format !== undefined) {
        nf.customFormat.value = format;
      }
      nf.format = utils.date.getPythonFormat(nf.customFormat.value);
      if (moment(moment().format(nf.customFormat.value)).isValid() && nf.format) {
        nf.customFormat.display = moment().format(nf.customFormat.value);
      } else {
        nf.customFormat.display = 'Invalid Date Format!'
      }
    };

    nf.customDateFormatExamples = [];
    _.forEach(c.dateOptions.general, function (option) {
      nf.customDateFormatExamples.push({
        display: option.display,
        value: utils.date.getMomentFormat(option.value)
      })
    });

    nf.dateFormatValueDisplayMap = {};
    $.each(nf.dateOptions, function (i, category) {
      $.each(category, function (i, opt) {
        nf.dateFormatValueDisplayMap[opt.value] = opt.display;
      });
    });

    function onToggle(isOpen) {
      if (isOpen) {
        nf.editedFormat = _.cloneDeep(nf.format);
        nf.dateType = $.grep(nf.dateOptions.UK, function (opt: any) {
          return opt.value === nf.format;
        }).length ? "UK" : "US";
      }
    }

    if (nf.format) {
      onToggle(true);
    }

    function submit(selectedFormat) {
      nf.format = selectedFormat;
      if (nf.onSubmit && (typeof nf.onSubmit === "function")) {
        nf.onSubmit({date_format: selectedFormat, tz: 'UTC'});
      }
      nf.isOpen = false;
      nf.onSubmitCb();
    }
  }
}
