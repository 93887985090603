'use strict';


/**
 * @ngInject
 */

cannyService.$inject = ['deploymentConsts', '$window'];
export function cannyService(deploymentConsts, $window) {

    return {
        initChangelog: initChangelog,
        initCannyIdentify: initCannyIdentify,
        openCannyFeatureRequest: openCannyFeatureRequest
    };

    function initChangelog() {
        (window as any).Canny('initChangelog', {
            appID: deploymentConsts.CANNY_APP_ID,
            position: 'bottom',
            align: 'right'
        });
    }

    function initCannyIdentify(user_email, user_name) {
        (window as any).Canny('identify', {
            appID: deploymentConsts.CANNY_APP_ID,
            user: {
                email: user_email,
                name: user_name,
                id: user_email
            },
        });
    }

    function openCannyFeatureRequest(){
        $window.open('https://mammoth.canny.io/feature-request', '_blank');
    }
}
