<template>
  <mm-modal
    v-model="computedIsModalOpen"
    content-class="mm-restrictions-wrapper-modal-buy-storage"
    :title="$t('settings.workspace.plan.restricted.storage_full')"
    width="450"
  >
    <span>{{ $t('settings.workspace.plan.restricted.storage_full_buy_more_description') }}</span>

    <template #actions>
      <mm-button :label="$t('global.dictionary.not_now')" objective="tertiary" @click="computedIsModalOpen = false" />
      <mm-button class="m-l-3" :label="$t('settings.workspace.storage.title')" @click="openSettings" />
    </template>
  </mm-modal>
</template>

<script>
// Constants
import { getOldAppUrl } from '@/constants'

export default {
  name: 'restrictions-wrapper-modal-buy-storage',
  props: {
    value: Boolean
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    openSettings() {
      this.computedIsModalOpen = false
      //TODO remove when angular code is gone
      window.open(
        `${getOldAppUrl()}/n/#/settings/workspace/${this.$store.state.workspaceId}?dataStorage=true`,
        'Settings'
      )
    }
  }
}
</script>
