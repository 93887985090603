<template>
  <mm-modal
    v-model="computedIsModalOpen"
    content-class="mm-data-addition-third-party-connection-new"
    :title="
      $t(`data_library.data_addition.third_party.${selectedConnection ? 'edit_connection' : 'new_connection'}.title`)
    "
    :persistent="isLoading"
    width="700"
  >
    <mm-form v-model="isFormValid" ref="form">
      <mm-row no-gutters>
        <mm-col
          v-for="(field, key) in connector.connection.fields"
          :key="`field_${field.props.title}`"
          cols="12"
          :md="Object.keys(connector.connection.fields).length > 1 ? 6 : 12"
        >
          <component
            :is="storybookComponents.find((c) => c.name == field.component)"
            v-model="formData[key]"
            v-bind="field.props"
            class="p-x-1"
            @enterKeyPressed="connect"
            @input="errorMessage = ''"
          />
        </mm-col>
      </mm-row>
    </mm-form>

    <div v-if="connector.connection.showIpInfo" class="d-flex m-y-2">
      <mm-icon name="information" />
      <span class="m-l-2 mm-text--caption-regular">
        {{ $t('data_library.data_addition.third_party.new_connection.ip_whitelisted', { ip: MAMMOTH_IP }) }}
      </span>
    </div>

    <mm-tooltip v-if="errorMessage" :label="errorMessage" wrapper-class="error-message-wrapper">
      <div class="d-flex">
        <mm-icon name="error" color="dest800" class="m-r-2" />
        <span v-html="errorMessage" class="mm-text--caption-regular error-message" />
      </div>
    </mm-tooltip>

    <template #actions>
      <mm-button
        :label="$t('global.dictionary.cancel')"
        :disabled="isLoading"
        objective="tertiary"
        @click="computedIsModalOpen = false"
      />
      <mm-button
        class="m-l-3"
        :label="$t('global.dictionary.connect')"
        :loading="isLoading"
        :disabled="!isFormValid"
        @click="connect"
      />
    </template>
  </mm-modal>
</template>

<script>
// Components
import { components as storybookComponents } from '@mammoth_developer/mm-storybook'

// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

// Utils
import { isValidURL } from '@/utils/validations'

// Constants
import { USER_EVENTS } from '@/constants'

export default {
  name: 'data-library-data-addition-third-party-connection-new',
  props: {
    value: Boolean,
    connector: {
      type: Object,
      required: true
      /*{
        key: string,
        name: string,
        icon: string,
        category: string,
        connection: {...},
        metrics: {...}
      }*/
    },
    integrationInfo: Object,
    selectedConnection: Object
  },
  data: () => ({
    formData: {},
    isFormValid: false,
    isLoading: false,
    errorMessage: ''
  }),
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    this.storybookComponents = storybookComponents
    this.MAMMOTH_IP = process.env.VUE_APP_MAMMOTH_OUTBOUND_IP
  },
  watch: {
    computedIsModalOpen: {
      handler(val) {
        if (val) {
          // If there is a selected connection to edit, set formData to its fields
          if (this.selectedConnection) this.formData = { ...this.selectedConnection.fields }
          else {
            // Initialize formData object and set each field value, in case there is a default value
            const fields = this.connector.connection.fields
            this.formData = Object.keys(fields).reduce((o, k) => ({ ...o, [k]: fields[k].props?.value }), {})
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    async connect() {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.NEW_CONNECTION_SUBMIT)

      const formErrors = this.$refs.form.getFormErrors()
      if (formErrors.length) return

      this.errorMessage = ''
      this.isLoading = true

      // If connection auth url requires user input, validate it and redirect to it
      if (this.connector.connection.userInputAuthUrl) {
        if (isValidURL(this.$refs.serverUrl.value)) {
          const authURL = this.connector.connection.generateAuthUrlFn(
            this.integrationInfo,
            this.$refs.serverUrl.value.replace(/\/$/, '')
          )
          // TODO: revisit after migration
          const url = new URL(authURL)
          const stateParams = url.searchParams.get('state').split('?')[1]
          const stateUrl = `${window.location.origin}/#/workspaces/${this.$store.state.workspaceId}/projects/${this.$store.state.projectId}?workspace_id=${this.$store.state.workspaceId}&project_id=${this.$store.state.projectId}&${stateParams}`
          url.searchParams.set('state', stateUrl)
          window.location.href = url.toString()
        } else {
          this.$refs.serverUrl.message = this.$t('data_library.data_addition.third_party.new_connection.url_error')
          this.$refs.serverUrl.error = true
          this.isLoading = false
        }

        return
      }

      // If there is a field custom function to be applied to a formData field, apply it
      Object.keys(this.connector.connection.fields).forEach((k) => {
        const fieldCustomFn = this.connector.connection.fields[k].fieldCustomFn
        if (fieldCustomFn) this.formData[k] = fieldCustomFn(this.formData[k])
      })

      let connectionResponse
      this.formData.project_id = this.$store.state.projectId
      // Edit existing connection
      if (this.selectedConnection)
        connectionResponse = await dataLibraryApi.editConnection(
          this.connector.hash,
          this.selectedConnection.value,
          this.formData
        )
      // Create new connection
      else {
        connectionResponse = await dataLibraryApi.setNewConnection(
          this.connector.hash,
          this.formData,
          () => this._isDestroyed // Stop futures requests in case component is destroyed
        )
      }

      const errorMessage = connectionResponse.message || connectionResponse.reason
      if (errorMessage) this.errorMessage = errorMessage
      else {
        this.$emit('success', { name: connectionResponse.name })
        this.computedIsModalOpen = false
      }

      this.isLoading = false
    }
  },
  beforeDestroy() {
    this.formData = {}
    this.$emit('close')
  }
}
</script>

<style lang="scss">
.mm-data-addition-third-party-connection-new {
  .mm-text-input input {
    height: 16px;
  }

  .error-message-wrapper {
    max-width: 100%;

    .mm-icon {
      min-width: 16px;
    }

    .error-message {
      color: var(--mm-color--dest800);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
