
import * as angular from "angular";
import {CardCollectionFactory} from "./card.collection";
import {CustomSummaryCard} from "./custom.explore.card";
import {RangeExploreCard} from "./range.explore.card";
import {explorePanelController} from "./explore.controllers";
import {
  exploreButton,
  exploreCardDisplay,
  exploreFilterDescription,
  explorePanel,
  selectedOptionsExploreCard,
  showDateValInSummary,
  showWkspRowCount,
  textExploreCardClickListener,
  delayedAutofocus,
  scrollToExploreCard,
  ChangeExploreAggregationCtrl,
  changeExploreAggregation,
  allowExploreCardResize,
  showExploreListItemPercentage,
  checkIfOutsideViewport,
  exploreSortMenu,
  exploreMenuRepositon, 
  repositionPopover, 
  wkspRowCountService
} from './explore.directives';
import {globalFilterService} from "./filter.service";
import {renderSummaryDrilldownFilter} from "./explore.range.drilldown";
import {filterTextItems, TextColumnSummaryCard} from "./text.explore.card";
import {exploreButtonWithMenu, exploreButtonMenuController} from "./explore.add.menu";
import {coreModule} from "../core/index";
import {commonModule} from "../common/index";
import {modelsModule} from "../models/index";
import {CardSummaryDataFactory} from "./summary.data";
import {exploreCardService} from "./explore.card.service";

export let exploreModule = angular.module('app.explore', [coreModule.name, commonModule.name, modelsModule.name]);
exploreModule.controller("ChangeExploreAggregationCtrl", ChangeExploreAggregationCtrl);
exploreModule.controller("exploreButtonMenuController", exploreButtonMenuController);
exploreModule.controller('explorePanelController', explorePanelController);

exploreModule.directive('allowExploreCardResize', allowExploreCardResize);
exploreModule.directive('changeExploreAggregation', changeExploreAggregation);
exploreModule.directive('delayedAutofocus', delayedAutofocus);
exploreModule.directive('exploreButton', exploreButton);
exploreModule.directive('exploreButtonWithMenu', exploreButtonWithMenu);
exploreModule.directive('exploreCardDisplay', exploreCardDisplay);
exploreModule.directive('exploreFilterDescription', exploreFilterDescription);
exploreModule.directive('showExploreListItemPercentage', showExploreListItemPercentage);
exploreModule.directive('explorePanel', explorePanel);
exploreModule.directive('renderSummaryDrilldownFilter', renderSummaryDrilldownFilter);
exploreModule.directive('scrollToExploreCard', scrollToExploreCard);
exploreModule.directive('selectedOptionsExploreCard', selectedOptionsExploreCard);
exploreModule.directive('showDateValInSummary', showDateValInSummary);
exploreModule.directive('showWkspRowCount', showWkspRowCount);
exploreModule.directive('textExploreCardClickListener', textExploreCardClickListener);
exploreModule.directive('checkIfOutsideViewport', checkIfOutsideViewport);
exploreModule.directive('exploreSortMenu', exploreSortMenu);
exploreModule.directive('exploreMenuRepositon', exploreMenuRepositon);

exploreModule.factory('CardCollectionFactory', CardCollectionFactory);
exploreModule.factory('CardSummaryDataFactory', CardSummaryDataFactory);
exploreModule.factory('CustomSummaryCard', CustomSummaryCard);
exploreModule.factory('RangeExploreCard', RangeExploreCard);
exploreModule.factory('TextColumnSummaryCard', TextColumnSummaryCard);

exploreModule.filter('filterTextItems', filterTextItems);

exploreModule.service('globalFilterService', globalFilterService);
exploreModule.service('exploreCardService', exploreCardService)
exploreModule.service('wkspRowCountService', wkspRowCountService);
exploreModule.directive('repositionPopover', repositionPopover);

