import angular = require('angular');
import {valRenameResource} from '../common/common.directives';

renameController.$inject = ['$scope', 'DatasourceService', 'DataviewService', 'labelService', 'utils', '$timeout', 'analyticsService', 'c'];

export function renameController($scope, DatasourceService, DataviewService, labelService, utils, $timeout, analyticsService, c) {
  this.$onInit = function () {
    var rvm = this;
    //Note value of rvm.datasource will come from the datasource property mentioned in scope in the directive
    rvm.datasource;
    rvm.new_name;
    rvm.waiting = false;
    rvm.cancelClicked;
    rvm.cancelOnEscape = cancelOnEscape;
    rvm.onOutsideClickCallback = onOutsideClickCallback;
    rvm.renamingInProgress = false;
    rvm.rename = rename;
    rvm.renameSuccess = renameSuccess;
    rvm.renameFailure = renameFailure;
    rvm.cancelRename = cancelRename;
    rvm.editable = false;
    rvm.enableEditMode = enableEditMode;


    function enableEditMode() {
      rvm.editMode = true;
      rvm.cancelClicked = false;
      rvm.new_name = utils.decodeSanitizedName(rvm.datasource.name);
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.inlineRenameDS, {eventOrigin: "landingPage.previewPanel"});
    }

    function rename() {
      rvm.waiting = true;
      $timeout(function () {
        renameDatasource();
      }, 2000);
    }

    function cancelOnEscape(event) {
      if (event.which == 27) {
        cancelRename()
      }
    }

    function renameDatasource() {
      rvm.waiting = false;
      if ($scope.inplaceRenameForm.$invalid == true) {
        rvm.editMode = true;
      }
      if (rvm.cancelClicked) {
        rvm.editMode = false;
        return;
      }
      if (rvm.new_name != rvm.datasource.name) {
        rvm.renamingInProgress = true;
        if (rvm.datasource.type == 'datasource') {
          DatasourceService.rename(rvm.datasource, rvm.new_name).then(rvm.renameSuccess, rvm.renameFailure).catch(function (e) {
            rvm.editMode = true;
            rvm.renamingInProgress = false;
          });
        } else if (rvm.datasource.type == 'dataview') {
          DataviewService.rename(rvm.datasource, rvm.new_name).then(rvm.renameSuccess, rvm.renameFailure).catch(function (e) {
            rvm.editMode = true;
            rvm.renamingInProgress = false;
          });
        } else if (rvm.datasource.type == 'label') {
          labelService.rename(rvm.datasource, rvm.new_name).then(rvm.renameSuccess, rvm.renameFailure).catch(function (e) {
            rvm.editMode = true;
            rvm.renamingInProgress = false;
          });
        }
      } else if (rvm.new_name == rvm.datasource.name) {
        rvm.editMode = false;
      } else {
        // rvm.editMode = false;
      }
    }

    function renameSuccess() {
      rvm.renamingInProgress = false;
      $timeout(function () {
        rvm.editMode = false;
      }, 500);
    }

    function renameFailure() {
      rvm.renamingInProgress = false;
      rvm.editMode = true;
    }

    function cancelRename() {
      rvm.editMode = false;
      rvm.cancelClicked = true;
    }

    function onOutsideClickCallback() {
      cancelRename();
    }
  }
}
