import * as $ from 'jquery';
import * as _ from 'lodash-es';
/**
 * @ngInject
 *
 * */
conditionLine.$inject = ['$compile'];
export function conditionLine($compile){
  return {
    'restrict': 'E',
    link: function($scope, $domEle, iAttrs){
     $scope.$watch(iAttrs.condition, function(value){
        if(value){
          render();
        }
      });

      function render(){
        $($domEle).empty();

        var scope = $scope.$new();
        /*
        returnTagObject is a scope function that is called in ui-select tagging property
        see ui-select in filter.atomic.tpl.html ... tagging="returnTagObject" - tagging expects a string which should be a scope function
        This function will be passed the new item(selected by the user) as a string and should return an object which is the transformed value to be
         pushed to the items
         array. Items array here in our case is the operand(an array)
         for more information check https://github.com/angular-ui/ui-select/wiki/ui-select
         */
        scope.returnTagObject = function (item) {
          return {
            value: item,
          }
        };
        var condition = $scope.$eval(iAttrs.condition);
        var template;
        if(condition.type === 'logical'){
          scope.logicalCondition = condition;
          template = '<ng-include src="\'app/components/common/filter/filter.logical.tpl.html\'"></ng-include>';
          if(iAttrs.noBrackets !== 'true'){
            template = "<span class='bracket bracket-open'>(</span>" + template + "<span class='bracket bracket-close'>)</span>";
          }
        }
        else{
          scope.atomicCondition = condition;
          template = '<ng-include src="\'app/components/common/filter/filter.atomic.tpl.html\'" class="d-inline-flex"></ng-include>';
        }

        $($domEle).html($compile(template)(scope));
      }
    }
  }
}

/**
 * @ngInject
 *
 * */
filterCondition.$inject = ['$compile'];
export function filterCondition($compile){
  return {
    restrict: 'E',
    link: function($scope, element, attrs){
      $scope.$watch(attrs.filterManager, function(v){
        if(v){
          render();
        }
      });

      $scope.$watch(attrs.dropdownAllowed, function(v){
        $scope.dropdownAllowed = attrs.dropdownAllowed;
      });

      function render(){
        $(element).empty();
        var template = '<ng-include src="\'app/components/common/filter/filter.tpl.html\'"></ng-include>';
        var childScope = $scope.$new();
        childScope.filterManager = $scope.$eval(attrs.filterManager);
        $(element).html($compile(template)(childScope));
      }
    }
  }
}
/**
 * @ngInject
 */
conditionDescription.$inject = ['taskDescriber', 'DataviewService'];
export function conditionDescription(taskDescriber, DataviewService){
  return {
    restrict: "A",
    scope: {
      filterManager: "=filterManager",
      inline: '=inline'
    },
    link: function(scope, element, attrs){

      var dataview_id = scope.filterManager.dataviewId;
      var dataview = DataviewService.get_by_id(dataview_id);
      dataview.on_update('updateMetricCondition', function(){
        render();
      });

      scope.$watch('filterManager.condition', render, true); // the true means something. Look up docs for $watch
      scope.$watch('filterManager.case', render, true);
      scope.$watch('filterManager.filterType', render);
      var inited = false;
      function render(){
        var txt;
        if(scope.filterManager){
          if (scope.filterManager.condition !== null) {
            // since a service returns only one instance of a function, we need to set the default value of firstRecursiveCallDone variable
            taskDescriber.conditionDescriber.firstRecursiveCallDone = false;
            txt = taskDescriber.conditionDescriber.describeCondition(scope.filterManager.metadata, scope.filterManager.getParam(), scope.$parent.dropdownAllowed);
          } else {
            txt = "Conditions removed";
          }
          inited = true;
        }
        if(!scope.inline){
          $(element).html('<p>'+ txt + '</p>');
        }
        else{
          $(element).html('<span>'+ txt + '</span>');
        }

      }
    }
  }
}
/**
 * @ngInject
 */
filterSummaryAndModalMenu.$inject = ['config'];
export function filterSummaryAndModalMenu(config){
  return {
    scope: {
      openConditionMenu: "&",
      filterManager: "=",
      disableRemove: '=',
      isOpen:'='
    },
    templateUrl: config.templates.filterSection
  }
}


/**
 * @ngInject
 */
initialiseSelectizeForFilter.$inject = ['$timeout', 'utils'];
export function initialiseSelectizeForFilter($timeout, utils){
  return {
    scope: {
      atomicCondition: "=",
      column: "=",
      operatorValue: "=",
      selectizeSettings: '=',
      dropdownHeight: '='
    },
    link: function (scope, element, attrs) {
      /*
      apply selectize to the HTML element
      What selectize does to the HTML element?
      - makes it a multi-select dropdown
      - allows to load dropdown content from server
      - allows to create custom options(apart from the options already loaded from some array or server)
      - applies some default CSS
      - allows to handle some events e.g. when item is selected or un-selected, etc
      - few plugins enhance it further
       */
      let selectizeSettings = {};
      if(scope.selectizeSettings){
        selectizeSettings = scope.selectizeSettings;
      }
      var $select = (<any>$(element)).selectize({
        ...selectizeSettings,
        create: function(input) {
          input = utils.sanitizeName(input);
          return {
            id: input,
            name: input
          }
        },
        // custom rendering
        render: {
          // An option in the dropdown list of available options.
          option: function(data, escape) {
            let text = data[selectizeSettings['labelField']];
            return '<div class="option">' + escape(text) + '</div>';
          },
          // An item the user has selected.
          item: function(data, escape) {
            let text = data[selectizeSettings['labelField']];
            if(text && text.trim() == ''){
              let lengthText = '';
              if(text.length > 1){
                lengthText = ' x ' + text.length;
              }
              text = '[whitespace' + lengthText + ']';
              return '<div class="item" style="white-space: pre"><i>' + escape(text) + '</i>' + '</div>';
            }
            else{
              return '<div class="item" style="white-space: pre">' + escape(text) + '</div>';
            }
          },
          // The "create new" option at the bottom of the dropdown. The data contains one property: input (which is what the user has typed).
          option_create: function(data, escape) {
            if(data.input && data.input.trim() == ''){
              let lengthText = '';
              if(data.input.length > 1){
                lengthText = ' x ' + data.input.length;
              }
              let text = '[whitespace' + lengthText + ']';
              return '<div class="create" style="white-space: pre">Add <i><strong>' + escape(text) + '</strong>&hellip;</i></div>';
            }
            else{
              return '<div class="create" style="white-space: pre">Add <strong>' + escape(data.input) + '</strong>&hellip;</div>';
            }
          }
        },
        onItemAdd: function(value, $item){
          // when an item is added, add that item to the operand variable also
          var item = utils.sanitizeName(value);
          if (scope.atomicCondition.defaultValues && scope.atomicCondition.defaultValues.indexOf(item) !== -1){
            value = item;
          }
          if(Array.isArray(scope.atomicCondition.operand)){
            if(!scope.atomicCondition.operand.includes(value)){
              scope.atomicCondition.operand.push(value);
            }
          }
          else{
            scope.atomicCondition.operand = [];
            scope.atomicCondition.operand.push(value);
          }
        },
        onItemRemove: function(value){
          // when an item is un-selected, removed that item from the operand variable also
          if(scope.atomicCondition.operand){
            var index = scope.atomicCondition.operand.indexOf(value);
            if (index !== -1) scope.atomicCondition.operand.splice(index, 1);
          }
          /*
          options is a variable of selectize that represents the options shown in the dropdown
          ... we neeed to make sure that whenever an item is un-selected.. if it was a custom item then remove it from
          options variable to ensure that it doesn't show up in dropdown options
           */
          /* check for contains first as it is faster (and safer) than searching list of values in includes when
          operator is CONTAINS/NOT CONTAINS.
           */
          if((scope.operatorValue == 'CONTAINS' || scope.operatorValue == 'NOT_CONTAINS') || !scope.atomicCondition.defaultValues.includes(value)){
            delete this.options[value];
          }
        },
        load: function(query, callback) {
          /*
          load calls an api to get the unique column values
           */
          if(scope.operatorValue == 'CONTAINS' || scope.operatorValue == 'NOT_CONTAINS'){
            // in case of contains and not_contains ops, dropdown options are not supposed to be shown
            callback([]);
            // defaultValues is a variable to save the unique column values. In case of CONTAINS and NOT_CONTAINS, we dont show unique column
            // values, so setting it to an empty array
            if(scope.atomicCondition.defaultValues === undefined){
              scope.atomicCondition.defaultValues = [];
            }
          }
          else if(scope.operatorValue == 'IN_LIST' || scope.operatorValue == 'NOT_IN_LIST'){
            if(scope.atomicCondition.column.type == 'NUMERIC'){
              scope.atomicCondition.defaultValues = [];
            }else{            
              scope.atomicCondition.computeColumnUniqueValuesList(query).then(function (data) {
                data = _.filter(data, function(item) {
                  return (item.id && item.id.trim());
                });
                /*
                if query is an an empty string, that means server will give top default values and dropdown will get populated with those default
                values, we will save those default values in a variable for later use
                */
                if(!query){
                  let defaultValues = data.map(x => x.id);
                  scope.atomicCondition.defaultValues = defaultValues;
                  scope.atomicCondition.defaultValuesObject = data;
                }
                callback(data);
              });
            }
          }
        },
        onLoad: function(){
          /*
          if query string is empty, then update dropdown options with the defaultValues
           */
          if(this.currentResults && this.currentResults.query == '' || scope.operatorValue == 'CONTAINS' || scope.operatorValue == 'NOT_CONTAINS'){
            this.clearOptions();
          }
          if(this.currentResults && this.currentResults.query == ''){
            if(scope.operatorValue == 'IN_LIST' || scope.operatorValue == 'NOT_IN_LIST'){
              if(scope.atomicCondition.defaultValuesObject){
                this.addOption(scope.atomicCondition.defaultValuesObject);
              }
            }
          }
          this.refreshOptions(false);
        },
      });

      var selectize = $select[0].selectize;

      if(scope.dropdownHeight){
        selectize.$dropdown_content[0].style.maxHeight = scope.dropdownHeight;
      }

      scope.$watch('column', function(newColumn) {
        /*
        whenever column changes, we need to update the options with options from the server
        We also need to clear the previous options, otherwise they will get appended with the new ones
         */
        selectize.clear(); // clear the input
        selectize.clearOptions(); // clear the options
        /*
        this code is little tricky. The is needed because everytime selectize initializes, the selected items don't
        retain. Here we will get the selected itmes back from operand variable of atomicCondition
         */
        for(let item in scope.atomicCondition.operand){
          let value = scope.atomicCondition.operand[item];
          selectize.options[value] = {$order: Object.keys(selectize.options).length, id: value, name: value};
        }
        selectize.setValue(scope.atomicCondition.operand);
        // focus the element whenever column changes
        if(scope.atomicCondition.preColumn != newColumn){
          selectize.$control_input[0].focus();
        }
        // load options from the server
        let fn = selectize.settings.load;
        selectize.load(function(callback) {
          fn.apply(self, ['', callback]);
        });
        scope.atomicCondition.preColumn = newColumn;
      });

      scope.$watch('operatorValue', function(newOpeartor) {
        // in case of contains and not_contains, clear all the dropdown options
        if(scope.operatorValue == 'CONTAINS' || scope.operatorValue == 'NOT_CONTAINS'){
          selectize.clearOptions();
        }
        // in case of in_list or not_in_list operators, populate the dropdown options from the variable defaultValuesObject
        else if(scope.operatorValue == 'IN_LIST' || scope.operatorValue == 'NOT_IN_LIST'){
          if(scope.atomicCondition.defaultValuesObject){
            selectize.addOption(scope.atomicCondition.defaultValuesObject);
          }
          else{
            // load options from the server
            let fn = selectize.settings.load;
            selectize.load(function(callback) {
              fn.apply(self, ['', callback]);
            });
          }
        }
        selectize.refreshOptions(false);
        if(scope.atomicCondition.preOperator != newOpeartor){
          /*
          focus the element whenever operator changes, also trigger load manually
           */
          selectize.$control_input[0].focus();
        }
        scope.atomicCondition.preOperator = newOpeartor;
      });

      /*
      implements infinite scroll
       */
      var options = {scrollRatio: 0.85, scrollStep: 20};
      var dropdown_content = selectize.$dropdown_content;
      var original_maxOptions = selectize.settings.maxOptions;

      selectize.$dropdown_content.on('scroll', function(e) {
        var scrollPosition = (dropdown_content.scrollTop() + dropdown_content.innerHeight()) / dropdown_content[0].scrollHeight;
        if (scrollPosition > options.scrollRatio && selectize.settings.maxOptions < selectize.currentResults.total) {
          selectize.settings.maxOptions += options.scrollStep;
          if(selectize.settings.maxOptions > 50){
            selectize.settings.maxOptions = 50;
          }
          else{
            selectize.refreshOptions(false);
          }
        }
      });

      selectize.on('type', function (e) {
        selectize.settings.maxOptions = original_maxOptions;
        selectize.refreshOptions(false);
      });
    }
  }
}

export function scrollToBottom(){
  return {
    link: function(scope, element, attrs){
      scope.$watch(attrs.scrollToBottom, function(vv, ov){
        let sel = $('span.ui-select-match');
        $(sel).animate({scrollTop: $(sel).prop("scrollHeight")}, 1000);
        if(!ov){
          ov = 0;
        }
        if(vv > ov) {
          $(sel).animate({scrollTop: $(sel).prop("scrollHeight")}, 1000);
        }
      });
    }
  }
}



onDetectOutsideClick.$inject = ['eventCallbackManagerFactory', '$timeout'];
export function onDetectOutsideClick(eventCallbackManagerFactory, $timeout){
  return {
    restrict: 'A',
    link: function(scope, element, attrs){
      function _watchForChanges(){
        $(window).click(function(event) {
          if (!$(event.target).closest(element).length) {
            scope.$eval(attrs.onDetectOutsideClick);
          }
        });
      }
      // $timeout(_watchForChanges, 1000);
    }
  }
}

export function noFloat(){
  return  {
      restrict: 'A',
      link: function (scope, elm, attrs, ctrl) {
          elm.on('keydown', function (event) {
            if ([110, 190].indexOf(event.which) > -1) {
                  // dot and numpad dot
                  event.preventDefault();
                  return false;
              }
              else{
                return true;
              }
          });
      }
  }
  }