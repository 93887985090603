import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import { VueInstance } from '../../../vueApp/src/ngVueBridgeCode/ngVueComponentsModule.js';
/**
 * @ngInject
 */
NotificationsFactory.$inject = ['config', '$resource', '$q', 'Notification']
export function NotificationsFactory(config, $resource, $q, Notification) {

  // @ts-ignore:next-line
  Notification.error = (content) => VueInstance.$toast.show({ content: content.message || content, status: 'error' })
  // @ts-ignore:next-line
  Notification.warning = (content) => VueInstance.$toast.show({ content: content.message || content, status: 'warning' })
  // @ts-ignore:next-line
  Notification.success = (content) => VueInstance.$toast.show({ content: content.message || content, status: 'success' })

  return Notification

  /*
  var NotificationResource = $resource(config.api.notifications, {id: '@id'});

  function Notification(data){
    var self = this;
    self.update = updateNotification;
    self.markRead = markRead;
    self.poppedUp = poppedUp;
    self.dismissNotification = dismissNotification;
    self.deleteNotification = deleteNotification;
    self.markNotPersistent = markNotPersistent;
    var notificationResource = new NotificationResource();
    notificationResource.id = self.id;
    updateNotification(data);

    function updateNotification(d) {
      angular.extend(self, d);
      self.last_updated = moment.utc(self.last_updated_at)
      self.data_updated = moment.utc(self.data_updated_at)
    }

    function markRead() {
      var patchParams = {patch: [{op: "replace", path: "isRead", value: true}]};
      return NotificationResource.patch({"id": self.id}, patchParams).$promise;
    }

    function poppedUp() {
      var patchParams = {patch: [{op: "replace", path: "hasPoppedUp", value: true}]};
      return NotificationResource.patch({"id": self.id}, patchParams)
    }

    function dismissNotification() {
      var patchParams = {patch: [{op: "replace", path: "isDismissed", value: true}]};
      return NotificationResource.patch({"id": self.id}, patchParams)
    }

    function deleteNotification() {
      var patchParams = {patch: [{op: "replace", path: "isDeleted", value: true}]};
      var ret = NotificationResource.patch({"id": self.id}, patchParams);
      return ret.$promise;
    }

    function markNotPersistent() {
      var patchParams = {patch: [{op: "replace", path: "not_persistent", value: true}]};
      var ret = NotificationResource.patch({"id": self.id}, patchParams);
      return ret
    }

  }
  */
}
