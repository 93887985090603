import * as angular from 'angular';
import { valDuplicateAggColName } from './pivot.directives';
import {pivotManagerFactory} from './pivot.manager';

/**
 * Module containing math editor
 */
export let pivotModule = angular.module('app.common.pivot', ['ui.bootstrap', 'app.common.utils']);
pivotModule.directive('valDuplicateAggColName', valDuplicateAggColName);
pivotModule.service('pivotManagerFactory', pivotManagerFactory);
