import * as _ from 'lodash-es';

TaskPanelFactory.$inject = ['$rootScope', '$timeout', 'modalService', 'Notification', 'TaskServiceFactory', 'resources',
  'eventCallbackManagerFactory', 'analyticsService', 'dataviewConfig', 'c', 'utils', 'ClipboardService','toastNotification', '$injector', 'TaskHelperServiceFactory'];

export function TaskPanelFactory($rootScope, $timeout, modalService, Notification, TaskServiceFactory, resources,
                                 eventCallbackManagerFactory, analyticsService, dataviewConfig, c, utils, ClipboardService, toastNotification, $injector, TaskHelperServiceFactory) {

  return {
    get: function (dataview, mainGridUnit?) {
      return new TaskPanel(dataview, mainGridUnit);
    }
  };

  function TaskPanel(dataview, mainGridUnit?) {
    var self = this;
    var taskPanelCloseEvent = new eventCallbackManagerFactory('taskPanelCloseEvent_' + dataview.id);
    var taskAddedEvent = new eventCallbackManagerFactory('taskAddedEvent_' + dataview.id);
    var taskPastedEvent = new eventCallbackManagerFactory('taskPasted' + dataview.id);
    var taskCreationEvent = new eventCallbackManagerFactory('taskCreation' + dataview.id);
    var taskEditingEvent = new eventCallbackManagerFactory('taskEditing' + dataview.id);
    var taskTemplateReadyEvent = new eventCallbackManagerFactory('taskTemplateReady' + dataview.id);

    self.submit_in_progress = false;
    self.is_open = false;
    self.task_name = undefined;
    self.editing_task = undefined;
    self.pasting_task = undefined;
    self.waiting_for_completion = false;
    self.latest_params = undefined;
    self.resetting = false;
    self.createParams = undefined;
    self.manager = undefined;

      // methods
    self.open = open_tasks_panel;
    self.close = close_tasks_panel;

    self.edit = open_task_panel_for_edit;
    self.editTaskNote = openModalToEditTaskNote;
    self.renameTask = openModalToRenameTask;
    self.delete = delete_task;
    self.suspend = suspend_task;
    self.restore = restoreTask;
    self.submit = undefined;
    self.copy = copy;
    self.paste = paste;
    self.replicate = replicate;
    self.pastePossible = false;
    // events
    self.taskPanelCloseEvent = taskPanelCloseEvent;
    self.taskAddedEvent = taskAddedEvent;
    self.taskPastedEvent = taskPastedEvent;
    self.taskCreationEvent = taskCreationEvent;
    self.taskEditingEvent = taskEditingEvent;
    self.taskTemplateReadyEvent = taskTemplateReadyEvent;
    self.onTemplateReady = onTemplateReady;
    self.checkForPasteableItem = checkForPasteableItem;
    self.clearCLipboard = clearCLipboard;
    var TaskService = TaskServiceFactory.get_by_dataview_id(dataview.id);

    function clearCLipboard(){
      self.pastePossible = false;
    }
    function checkForPasteableItem(){
      var clipboardValueExists = !ClipboardService.isEmpty();
      self.pastePossible = clipboardValueExists
    }

    function open_tasks_panel(task_name, createParams, editTask){
      $rootScope.$broadcast('suspendWatchers');
      if (!editTask){
        $timeout(function () {
         close_tasks_panel();
      });
      }
      $timeout(function () {
        self.is_open = true;
        self.pasting_task = undefined;
        self.task_name = task_name;
        if (editTask) {
          self.editing_task = editTask;
        }
        if (createParams) {
          self.createParams = createParams;
        }
        var taskInfo: any = {}
        taskInfo['opname'] = task_name;
        if (editTask){
          self.taskEditingEvent.fire_event(taskInfo)
        }else{
          self.taskCreationEvent.fire_event(taskInfo)
        }
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.taskPanelEvents.taskInvoked, {
          taskName: task_name,
          menuType: "taskPanel",
          eventOrigin:"dataview"
        });
      });
    }

    function onTemplateReady() {
      self.taskTemplateReadyEvent.fire_event()
    }

    function close_tasks_panel(){
      self.is_open = false;
      self.task_name = null;
      self.editing_task = undefined;
      self.createParams = undefined;
      self.manager = undefined;
      taskPanelCloseEvent.fire_event();
    }

    function restoreTask(task, dataview, skip_validation?){
      if (!skip_validation){
        skip_validation = false;
      }
      skip_validation = skip_validation || (dataview.draft_mode == 'dirty' ? true: false);
      TaskService.restoreTask(task,skip_validation).then(resources.update, function (data){
        toastNotification.error(data.data.ERROR_MESSAGE);
        if (data.hasOwnProperty('proceed') && data.proceed == true) {
            restoreTask(task, dataview, true)
        }
      });

    }
    function delete_task(task, dataview, skip_validation?) {
      if (!skip_validation){
        skip_validation = false;
      }
      skip_validation = skip_validation || dataview.draft_mode == 'dirty' ? true: false;
      if(dataview.pipeline_autorun_enabled && skip_validation==false){
        modalService.deleteTask(task, dataview, TaskService).then(
          function successCallback(data) {
            TaskService.delete_task(task, skip_validation).then(resources.update,
              function (deleteTaskData){
                if (deleteTaskData.hasOwnProperty('proceed') && deleteTaskData.proceed == true) {
                  delete_task(task, dataview, true)
                }
              }
            )
          }
        )
      }
      else{
        TaskService.delete_task(task, skip_validation).then(resources.update,
          function (deleteTaskData){
            if (deleteTaskData.hasOwnProperty('proceed') && deleteTaskData.proceed == true) {
              delete_task(task, dataview, true)
            }
          }
        )
      }
    }
    function suspend_task(task, dataview, skip_validation?){
      if (!skip_validation){
        skip_validation = false;
      }
      skip_validation = skip_validation || dataview.draft_mode == 'dirty' ? true: false;
      if(dataview.pipeline_autorun_enabled && skip_validation==false){
        modalService.suspendTask(task, dataview, TaskService).then(
          function successCallback(data) {
            TaskService.suspend_task(task, skip_validation).then(resources.update,
              function (deleteTaskData){
                if (deleteTaskData.hasOwnProperty('proceed') && deleteTaskData.proceed == true) {
                  suspend_task(task, dataview, true)
                }
              }
            )
          }
        )
      }
      else{
        TaskService.suspend_task(task, skip_validation).then(resources.update,
          function (deleteTaskData){
            if (deleteTaskData.hasOwnProperty('proceed') && deleteTaskData.proceed == true) {
              suspend_task(task, dataview, true)
            }
          }
        )
      }
    }

    function openModalToRenameTask(task) {
      modalService.openRenameTaskModal(task).then(function (display_info) {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.taskPanelEvents.taskRenamed,{
          taskName: task.title,
          menuType: "taskPanel",
          eventOrigin:"dataview"
        })
        TaskService.editDisplayInfo(task, display_info).then(TaskService.update_list, edit_failure);
      });

      function edit_failure() {
        Notification.error('Rename task failed. Please try again later.');
      }
    }


    function openModalToEditTaskNote(task){
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.taskPanelEvents.addTaskNote,{
          taskName: task.title,
          menuType: "taskPanel",
          eventOrigin:"dataview"
        });
      modalService.openEditTaskNoteModal(task).then(function (display_info) {
        TaskService.editDisplayInfo(task, display_info).then(TaskService.update_list, edit_failure);
        // TaskService.update_list();
      });

      function edit_failure() {
        Notification.error('Editing task note failed. Please try again later.');
      }
    }

    function open_task_panel_for_edit(edit_task) {
      open_tasks_panel(edit_task.opname, undefined, edit_task);
    }

    function copy(task, task_name, dataview) {
      var task_ = _.cloneDeep(task)
      var columnsUsed = task_.transform_params['COLUMNS_USED']
      var taskInfo: any = {}
      taskInfo['opname'] = task_name;
      self.task_name = task_name;
      var sanitizedColInfo = utils.updateDisplayNames(columnsUsed, dataview.display_properties)
      taskInfo['COLUMNS_USED'] = sanitizedColInfo
      taskInfo['stepType'] = 'task'
      taskInfo['params'] = task_.params


      ClipboardService.set(taskInfo).then((data)=>{
        self.pastePossible = true;
        toastNotification.success("Task copied");
      });
    }

    function paste(data, sequence?, position?) {
      $timeout(function () { close_tasks_panel(); });
      $timeout(function () {

        if (data?.stepType !='task'){
          return
        }

        if (sequence != undefined && position && position == 'below') {
          data['sequence'] = sequence + 1;
        } else if (sequence != undefined && position && position == 'above') {
          data['sequence'] = sequence;
        }
        /**  TASK_NAMESPACE is meant for backend use only.
         If present in the task params during API call to backend.
         It will delete the columns from the db.Causing issues like MVP-4754
         So sanitize task params deleting TASK NAMESPACE from it before any use of task params.
         **/
        if (data['params'].hasOwnProperty('TASK_NAMESPACE')){
          delete data['params']['TASK_NAMESPACE']
        }
        self.taskPastedEvent.fire_event(data)
        self.task_name = data['opname'];
        self.createParams = data;
        self.pasting_task = true;
        self.is_open = true;
      })

    }

    function replicate(task, task_name, dataview){
      var task_ = _.cloneDeep(task)
      var columnsUsed = task_.transform_params['COLUMNS_USED']
      var taskInfo: any = {}
      taskInfo['opname'] = task_name;
      var updatedColumnsUsed = utils.updateDisplayNames(columnsUsed, dataview.display_properties)
      taskInfo['COLUMNS_USED'] = updatedColumnsUsed
      taskInfo['params'] = task_.params
      if (taskInfo['params'].hasOwnProperty('TASK_NAMESPACE')){
        delete  taskInfo['params']['TASK_NAMESPACE']
      }
      self.taskPastedEvent.fire_event(taskInfo)
      self.task_name = task_name
      self.createParams = taskInfo;
      self.pasting_task = true;
      self.is_open = true;
      if (dataview.pipeline_autorun_enabled){
        toastNotification.success("Task duplicated");
      }
    }
  }
}
