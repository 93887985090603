<template>
  <div
    class="mm-connector-card mm-rounded-borders cursor-pointer"
    :class="{
      'mm-connector-card--acquired': isAcquired,
      'mm-connector-card--disabled': disabled,
      'mm-connector-card--additional': isAdditional,
      'mm-connector-card--removable': isRemovable
    }"
    @click="onClick"
    @keyup.enter="onClick"
  >
    <mm-icon class="m-a-auto connector-logo" :name="connector.icon" />
    <div v-if="isPremium" class="premium-icon">
      <mm-icon name="premium" color="warn800" />
      <span class="m-l-2 mm-text--caption-bold">{{ $t('global.dictionary.upgrade') }}</span>
    </div>
    <div v-if="isAddable || isRemovable || isAdditional" class="corner-button">
      <mm-button v-if="isAdditional" icon="check" @click.stop="isRemoveModalVisible = true" />
      <mm-button v-else-if="isAddable" icon="plus" objective="secondary" @click.stop="add()" />
      <mm-button v-else-if="isRemovable" icon="delete" objective="secondary" @click.stop="onDeleteClick" />
    </div>
    <span v-if="isAcquired" class="acquired-info mm-text--caption-bold"> {{ $t('global.dictionary.enabled') }} </span>

    <mm-modal
      v-model="isRemoveModalVisible"
      :title="$t('settings.workspace.plan.add_connectors.remove.title')"
      width="450"
    >
      <template #default>
        <span class="mm-text--body-regular">
          {{ $t('settings.workspace.plan.add_connectors.remove.description') }}
        </span>
      </template>
      <template #actions>
        <mm-button :label="$t('global.dictionary.cancel')" objective="tertiary" @click="onRemoveCancel" />
        <mm-button class="m-l-3" objective="destructive" :label="$t('global.dictionary.remove')" @click="remove" />
      </template>
    </mm-modal>
  </div>
</template>

<script>
// Constants
import { USER_EVENTS } from '@/constants'

export default {
  name: 'connector-card',
  props: {
    connector: {
      type: Object,
      required: true
    },
    isPremium: Boolean,
    isAcquired: Boolean,
    isAddable: Boolean,
    isRemovable: Boolean,
    isAdditional: Boolean,
    disabled: Boolean
  },
  data: () => ({
    isRemoveModalVisible: false
  }),
  methods: {
    onClick() {
      this.$emit('click')

      if (this.isAddable) this.add()
    },
    add() {
      this.$emit('add')
      this.$userEvents.save(USER_EVENTS.SETTINGS.CONNECTORS.ADD, { connector: this.connector.key })
    },
    onRemoveCancel() {
      this.isRemoveModalVisible = false
      this.$userEvents.save(USER_EVENTS.SETTINGS.CONNECTORS.REMOVE.CANCEL, { connector: this.connector.key })
    },
    onDeleteClick() {
      this.isRemoveModalVisible = true
      this.$userEvents.save(USER_EVENTS.SETTINGS.CONNECTORS.REMOVE.CLICK, { connector: this.connector.key })
    },
    remove() {
      this.isRemoveModalVisible = false
      this.$emit('remove')
      this.$userEvents.save(USER_EVENTS.SETTINGS.CONNECTORS.REMOVE.REMOVE, { connector: this.connector.key })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@mammoth_developer/mm-storybook/src/styles/shadows.scss';
@import '@mammoth_developer/mm-storybook/src/styles/spacing.scss';

$default-border-color: var(--mm-color--n70);
$acquired-color: var(--mm-color--s800);

.mm-connector-card {
  border: 1px solid $default-border-color;
  align-items: center;
  position: relative;
  height: 100px;
  display: flex;

  .premium-icon {
    align-items: center;
    position: absolute;
    display: flex;
    bottom: 8px;
    right: 8px;

    span {
      display: none;
    }
  }

  .corner-button {
    background: var(--mm-color--n10);
    border-radius: 50%;
    position: absolute;
    height: 24px;
    width: 24px;
    right: -12px;
    top: -12px;

    .mm-button {
      @extend .p-a-0;
      min-width: 24px !important;
      min-height: 24px;
    }
  }

  &:hover {
    background: var(--mm-color--n20);
  }

  &:active {
    background: var(--mm-color--n50);
  }

  &:focus {
    @extend .mm-shadow--focus;
  }

  &.mm-connector-card--acquired {
    border: 1px solid $acquired-color;

    .acquired-info {
      color: $acquired-color;
      position: absolute;
      left: 10px;
      top: 5px;
    }
  }

  &.mm-connector-card--additional {
    border: 2px solid var(--mm-color--p700);
  }

  &.mm-connector-card--additional,
  &.mm-connector-card--removable {
    background: var(--mm-color--n10);
    cursor: default;
  }

  &.mm-connector-card--disabled {
    background: var(--mm-color--n30);

    &:hover {
      background: var(--mm-color--n40);

      .premium-icon span {
        display: block;
      }
    }

    .connector-logo {
      opacity: 0.7;
    }
  }
}
</style>
