import mmStorybookI18n from '@mammoth_developer/mm-storybook/src/plugins/i18n'

export function loadLocaleMessages(mmStorybookI18n) {
  const messages = {}

  const locales = require.context('..', true, /\.locale\.json$/i)
  locales.keys().forEach((filePath) => {
    const lang = filePath.split('/').slice(-1)[0].split('.')[1]
    const module = filePath.split('/').slice(-1)[0].split('.')[0]

    messages[lang] ||= {}
    messages[lang][module] = locales(filePath)
  })

  mmStorybookI18n.availableLocales.forEach((lang) => mmStorybookI18n.mergeLocaleMessage(lang, messages[lang]))
}

loadLocaleMessages(mmStorybookI18n)
export const i18n = mmStorybookI18n
