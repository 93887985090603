// API
import API from '@/api'

// Transforms
import { transformUsers } from '@/api/global.transform.js'
import { transformProject } from '@/modules/project/api/project.transform.js'

const deleteProject = (projectId) => API.delete(`projects/${projectId}`)

const renameProject = (projectId, newName) =>
  API.patch(`projects/${projectId}`, { patch: [{ op: 'replace', value: { new_project_name: newName }, path: 'name' }] })

const getUsersInProject = (workspaceId, projectId) => API.get(`workspaces/${workspaceId}/users?project_id=${projectId}`)

const addToProject = (projectId, userId, roleName) =>
  API.post(`projects/${projectId}/users?user_id=${userId}`, {
    role_name: roleName // project_analyst OR admin
  })

const updateUserPermissionInProject = (projectId, userId, roleName) =>
  API.patch(`projects/${projectId}/users?user_id=${userId}`, {
    patch: [{ path: 'permissions', op: 'replace', value: roleName }]
  })

const removeUserFromProject = (projectId, userId) => API.delete(`projects/${projectId}/users?user_id=${userId}`)

const addProject = (projectName) =>
  API.post('projects', { project_name: projectName }, { customErrorHandling: true }).catch((error) => error)

const leaveProject = (userId, projectId) => API.delete(`projects/${projectId}/users?user_id=${userId}`)

export default {
  deleteProject,
  renameProject,
  getUsersInProject: async (workspaceId, projectId) => transformUsers(await getUsersInProject(workspaceId, projectId)),
  addToProject,
  updateUserPermissionInProject,
  removeUserFromProject,
  addProject: async (projectName) => transformProject(await addProject(projectName)),
  leaveProject
}
