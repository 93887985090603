import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';

/**
 * @ngInject
 * @param $timeout
 * @returns {{require: string, link: link}}
 */
onElementInputChange.$inject = ['$timeout'];
export function onElementInputChange($timeout) {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, model) {
      model.$viewChangeListeners.push(function () {
        $timeout(function () {
          if (!scope.epvm.elementBuilderInterface.$invalid) {
            scope.epvm.saveElement();
          }
        }, 200);

      });
    }
  };
}
/**
 * @ngInject
 **/
export function sortElements() {
  return function (input, isExpanded, elementsOrder) {
    input = input || [];
    elementsOrder = elementsOrder || {};
    var final = [];
    var out = [];
    var outEnd = [];
    var order = (isExpanded ? elementsOrder.EXPANDED || [] : elementsOrder.NORMAL || []);
    angular.forEach(input, function (ele) {
      var idx = order.indexOf(ele.id);
      if (idx != -1) {
        out[idx] = ele;
      } else {
        outEnd.push(ele);
      }
    });
    outEnd.reverse();
    outEnd.forEach(function (ele) {
      final.push(ele);
    });
    out.forEach(function (ele) {
      if (ele) {
        final.push(ele);
      }
    });

    return final;
  };
}

/**
 * @ngInject
 * @returns {{require: string, link: link}}
 */
elementContainerDirective.$inject = ['$timeout', 'analyticsService', 'c', '$compile'];
export function elementContainerDirective($timeout, analyticsService, c, $compile) {
  return {
    link: function (scope, element, attrs) {
      var packery = (<any>$(element)).packery({
        itemSelector: '.elem-wrap',
        columnWidth: 280,
        transitionDuration: 0,
        gutter: 10,
        rowHeight: 0,
        percentPosition: true
      });
      var panelViewProperties = scope.$eval(attrs.elementContainer);
      panelViewProperties.container = $(element);

      element.on('dragItemPositioned', function () {
        $timeout(setItemsOrder, 500);
      });
      if(scope.vm.elementPanel){
        scope.vm.elementPanel.onRenderComplete("elementPanelRecalculate", recalculate);
        scope.vm.elementPanel.onResizeComplete("elementPanelRecalculate", _resizeHanlder);
      }
      if(scope.vm.onSizeChanges){
        scope.vm.onSizeChanges("elementPanelRecalculate", _resizeHanlder);
      }
      function _resizeHanlder(){
        recalculate();
        $timeout(recalculate, 400);
        $timeout(recalculate, 800);
      }

      scope.$watch('vm.elementService.elements', function () {
        recalculate();
      }, true);

      scope.$watch('vm.elementPanel.metricGridView', function (t) {
        recalculate();
        _resizeHanlder();
        if(t){
          fitMetricText();
        }
      }, true);


      function setItemsOrder() {
        var elems = element.packery('getItemElements');
        var newOrder = [];
        angular.forEach(elems, function (elem) {
          var element_var : any = angular.element(elem).scope();
          var _elemObject = element_var.element;
          if (_elemObject) {
            var id = _elemObject.id;
            newOrder.push(id);
          }
        });
        if (newOrder.length) {
          var prevOrder = scope.vm.dataview.display_properties.ELEMENTS_PANEL || {};
          prevOrder = _.cloneDeep(prevOrder.ELEMENTS_ORDER);
          scope.vm.elementPanel.setElementsDisplayOrder(newOrder);
          analyticsService.userEventTrack(c.userEvents.elements.reordered, {
            order: scope.vm.dataview.display_properties.ELEMENTS_PANEL.ELEMENTS_ORDER, prevOrder: prevOrder
          });
        }
      }

      function recalculate() {
        element.packery('reloadItems');
        $timeout(function () {
          element.packery('layout');
          // makeDraggable();
          if (scope.vm.elementPanel.fullScreen) {
            $(element).children(".elem-wrap.ui-draggable").draggable("option", "axis", false);
          } else {
            $(element).children(".elem-wrap.ui-draggable").draggable("option", "axis", "y");
          }
        }, 100);
      }


      function fitMetricText() {
        $.each($(element).find('.metric-value'), function (i, valEle) {
          let content = $(valEle).html();
          $(valEle).empty();
          if (content) {
            let eleScope = angular.element(valEle).scope();
            let htmlToAppend = $compile(content)(eleScope);
            $(valEle).html(htmlToAppend);
          }
        })
      }
    }
  };
}
/**
 * @ngInject
 */
export function elementDraggable() {
  return {
    link: function (scope, element, attrs) {
      var panelViewProperties = scope.$eval(attrs.viewProperties);
      element.draggable({'containment': $(element).parent()});
      if (panelViewProperties.container.packery) {
        panelViewProperties.container.packery('bindUIDraggableEvents', element);
      }
      $(element).on('mousedown', function () {
        $(element).addClass('is-clicked');
      });
      $(element).on('dragstart', function () {
        $(element).addClass('is-clicked');
      });

      $(element).on('mouseup', function () {
        $(element).removeClass('is-clicked');
      });
      $(element).on('dragend', function () {
        $(element).removeClass('is-clicked');
      });
    }
  }
}


/**
 * @ngInject
 */
export function drilldownElement() {
  return {
    link: function (scope, element, attrs) {
      var drilldownManager;
      if (attrs.drilldownElement) {
        drilldownManager = scope.$eval(attrs.drilldownElement);
      }
      if (drilldownManager && drilldownManager.rootElement && drilldownManager.rootElement.display_properties.size) {
        var width = drilldownManager.rootElement.display_properties.size.width;
        var height = drilldownManager.rootElement.display_properties.size.height;
        element.css("width", width + "px");
        element.css("height", height + "px");
      }
    }
  }
}

/**
 * @ngInject
 */
elementResizable.$inject = ['derivatives', '$timeout', 'analyticsService', 'c'];
export function elementResizable(derivatives, $timeout, analyticsService, c) {
  return {
    link: function (scope, element, attrs) {
      var panelViewProperties = scope.$eval(attrs.viewProperties),
        unsubscribeDrilldownChange,
        drilldownManager;
      var vElem = scope.$eval(attrs.elementResizable);
      if (vElem.type == "METRIC") {
        return;
      }
      setSizes();
      if (attrs.drilldownManager) {
        drilldownManager = scope.$eval(attrs.drilldownManager);
        if (drilldownManager) {
          unsubscribeDrilldownChange = drilldownManager.onChange("controllerChangeListener", function () {
            $timeout(setSizes, 50);
            $timeout(setSizes, 200);
          })
        }
      }

      element.resizable({
        grid: [285, 145],
        ghost: true,
        stop: function (e, ui) {
          var prevSize = vElem.display_properties.size || {};
          var prevWidth = prevSize.width;
          var prevHeight = prevSize.height;
          var wUnits = Math.round(ui.size.width / 285) || 1;
          var hUnits = Math.round(ui.size.height / 145) || 1;
          var calcWidth = wUnits * 280 + ((wUnits - 1) * 10);
          var calcHeight = hUnits * 140 + ((hUnits - 1) * 10);

          if (vElem.type == "CHART" || vElem.type == "TABLE") {
            calcWidth += 10;
            calcHeight += 10;
          }
          vElem.display_properties.size = {
            width: calcWidth,
            height: calcHeight
          };
          setSizes();
          vElem.displayUpdated();
          panelViewProperties.container.packery('fit', element[0]);
          $timeout(function () {
            derivatives.edit(vElem.dataviewId, vElem.id, undefined, vElem.display_properties);
          })
          var currentSize = vElem.display_properties.size || {};
          analyticsService.userEventTrack(c.userEvents.elements.resized, {
            elementId: vElem.id, dataviewId: vElem.dataviewId, width: currentSize.width,
            height: currentSize.height, prevWidth: prevWidth, prevHeight: prevHeight
          });
        }
      });

      function setSizes() {
        if (vElem.display_properties.size) {
          var width = vElem.display_properties.size.width;
          var height = vElem.display_properties.size.height;
          element.css("width", width + "px");
          element.css("height", height + "px");
          var elemMain = element.find('.elem-main');
          if (vElem.type == "CHART" || vElem.type == "TABLE") {
            width = width - 10;
            height = height - 10;
          }
          elemMain.each(function (i, ele) {
            ele = $(ele);
            ele.css("width", width + "px");
            ele.css("height", height + "px");
          });
        }
      }


    }
  }

}
/**
 * @ngInject
 * @param $timeout
 * @returns {{require: string, link: link}}
 */
watchCollectionSaveForm.$inject = [ '$timeout', 'utils'];
export function watchCollectionSaveForm($timeout, utils) {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, model) {
      var first_time = true;

      function save(value) {
        var save = false;
        if (attrs.allowEmpty == 'true') {
          save = true;
        }
        else {
          if (angular.isArray(value) && value.length) {
            save = true;
          }
        }
        if (!scope.epvm.elementBuilderInterface.$invalid && save) {
          scope.epvm.saveElement();
        }
      }

      var debouncedSave = save;
      if (!isNaN(parseInt(attrs.debounce))) {
        debouncedSave = utils.debounce(save, parseInt(attrs.debounce));
      }

      function _watchCb(value, oldValue) {
        if (first_time) {
          first_time = false;
        }
        else {
          $timeout(function () {
            if (value !== undefined) {
              debouncedSave(value); // timeout because the form is momentarily invalid sometimes but soon becomes valid.
            }
          }, 100);
        }
      }

      $timeout(function () {
        scope.$watch(attrs.ngModel, _watchCb, true);
      }, 250)
    }
  };
}

/**
 * @ngInject
 */
elementModalDataviewPreview.$inject = ['DataviewService', 'gridFactory', 'utils', 'config', '$timeout'];
export function elementModalDataviewPreview(DataviewService, gridFactory, utils, config, $timeout) {
  return {
    link: function (scope, elem, attrs) {
      var options = {
        showColumnTypeIcons: true,
        rowHeight: 20,
        defaultColumnWidth: 100,
        enableColumnReorder: false
      };
      var colOptions = {
        resizable: false
      };
      var indexColumn = {"name": "#", "id": "index", "field": "index", "width": 30,  cssClass: "sequence-cell",
        resizable: false};
      var gridInstance;

      scope.$watch(attrs['elementModalDataviewPreview'], function (wsId) {
        if (!wsId) {
          return;
        }
        var columns = [];
        var dataview = DataviewService.get_by_id(wsId);
        utils.metadata.applyDisplayChanges(dataview.metadata, dataview.display_properties, columns, colOptions);
        angular.forEach(columns, function (col, i) {
          if (col.width) {
            columns[i].width = Math.floor(col.width * 0.67);
          }
        });
        columns.splice(0, 0, indexColumn);
        if (gridInstance) {
          gridInstance.destroy();
          elem.empty();
        }
        gridInstance = new gridFactory(elem, config.apiUrl +dataview.data_url, columns, options);
        gridInstance.init();
      }, true);

      scope.$watch(attrs['refreshGrid'], function (val) {
        if (gridInstance) {
          $timeout(gridInstance.grid.resizeCanvas, 200);
        }
      }, true);
    }
  }
}
