// API
import API from '@/api'
import {
  transformUsers,
  transformWorkspaces,
  transformUsageInfo,
  transformConnectors,
  transformSmsDetails,
  transformOnboardingFlags
} from './global.transform.js'

// Store
import store from '@/plugins/store'

// Constants
import { REQUESTS_STATUS } from '@/constants'
const cacheConfig = { cacheable: true, cacheAge: 300000 }

const apiGenerator = (path, config) => async () => {
  /*
  @path: String // API end point
  @config: Its an object where we define custom properties on top of default config which axios provides
  https://axios-http.com/docs/req_config
  {
    cacheable: Boolean // allows the user to say if the request's response should be cached or not
    cacheAge: Number // time in milliseconds to say how long the cached response should be considered valid
    cacheKey: String // the key corsp to which the response will be cached in the browser storage 
    }
  */

  const response = await API.get(path, config)
  return { data: response.data }
}

let mostRecentFutureRequestsIds = {}

const getFutureRequest = async (futureId, key, futuresStopper, responseTrackerCallbackFn) => {
  // If a key for the request is specified, store the most recent futureId. Only the response for the most recent futureId should be returned
  if (key) mostRecentFutureRequestsIds[key] = futureId

  let response
  try {
    response = await apiGenerator(`/future/${futureId}`)()
    while (response.data.future.status == REQUESTS_STATUS.PROCESSING_1 && (futuresStopper ? !futuresStopper() : true)) {
      await new Promise((resolve) => setTimeout(resolve, 600))
      response = await apiGenerator(`/future/${futureId}`)()
      if (responseTrackerCallbackFn) responseTrackerCallbackFn(response)
    }
  } catch {
    return response
  }

  if (!key || mostRecentFutureRequestsIds[key] == futureId) {
    const future = response?.data?.future

    // If future's response is an object, return it directly together with other future's properties (these are needed in some cases)
    if (typeof future?.response === 'object')
      return { ...response.data.future, response: null, ...response.data.future.response }
    return future
  }
}

export default {
  getUsageInfo: async (workspaceId, userId) =>
    transformUsageInfo(await apiGenerator(`/workspaces/${workspaceId}/app-usage/${userId}`)()),
  getCurrentUser: async (config = {}) =>
    transformUsers(
      await apiGenerator('/self', {
        ...cacheConfig,
        invalidateCache: Boolean(config?.invalidateCache),
        cacheKey: `mmCache_user_${store.state.user.email}_/self`
      })()
    )[0],
  getWorkspaces: async (config = {}) =>
    transformWorkspaces(
      await apiGenerator('/workspaces', {
        ...cacheConfig,
        invalidateCache: Boolean(config?.invalidateCache),
        cacheKey: `mmCache_user_${store.state.user.email}_/workspaces`
      })()
    ),
  getWorkspace: async (workspaceId) => transformWorkspaces(await apiGenerator(`/workspace/${workspaceId}`)())?.[0],
  getSmsDetails: async (workspaceId, config) =>
    transformSmsDetails(
      await apiGenerator(
        `/workspaces/${workspaceId}/sms-details?${config?.fetchAllDetails ? 'fetch_plans=true&fetch_addons=true' : ''}`
      )()
    ),
  getChargebeePlan: () =>
    apiGenerator('/chargebee-plan', {
      ...cacheConfig,
      cacheKey: `mmCache_workspaceId_${store.state.workspaceId}_/chargebee-plan`
    })(),
  getOnboardingFlags: async () =>
    transformOnboardingFlags(
      await apiGenerator('/preferences', {
        ...cacheConfig,
        cacheKey: `mmCache_user_${store.state.user.email}_/preferences`
      })()
    ),
  toggleOnboardingFlag: (flag, status) =>
    API.patch('preferences', { patch: [{ op: 'replace', path: flag, value: status }] }),
  getUsersInWorkspace: async (workspaceId) => transformUsers(await apiGenerator(`workspaces/${workspaceId}/users`)()),
  getConnectorsList: async () => transformConnectors(await API.get('integrations')),
  getFutureRequest,
  setOrganisationName: async (organisationName, workspaceId, additionalInfo) =>
    transformUsers(
      await API.patch('/self', {
        patch: [
          {
            op: 'add',
            path: 'organisation',
            value: { name: organisationName, workspace_id: workspaceId, additional_info: additionalInfo }
          }
        ]
      })
    )
}
