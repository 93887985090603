import { dataviewConfig} from '../dataview.config';
import * as angular from 'angular';
import * as _ from 'lodash-es';

/**
 * @ngInject
 */
bigqueryActionManagerFactory.$inject = ['utils', 'DataviewService'];
export function bigqueryActionManagerFactory(utils, DataviewService) {
  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new BigqueryManager(options);
  }

  function BigqueryManager(options) {
    var self = this;
    self.metadata = utils.sanitizeMetadataWithType(options.metadata);
    self.dataviewId = options.context.dataview.id;
    self.dataview = DataviewService.get_by_id(self.dataviewId);
    self.serversideValidationRequired = true;
    self.inEditMode = options.context.inEditMode;
    self.upsertKeys = [];
    addColumn();
    self.saveAsDsAppendReplaceModeOptions = ['REPLACE', 'COMBINE', 'UPSERT'];
    self.exportMode = [
      { internal: "COMBINE", display: "Combine" },
      { internal: "REPLACE", display: "Replace" },
      { internal: "UPSERT", display: "Merge" }
    ];
    if (_.isUndefined(options.triggerType)) {
      options.triggerType = "pipeline";
    }

    self.param = {
      handler_type: "bigquery",
      trigger_type: options.triggerType,
      run_immediately: true,
      sequence: options.context.currentSequenceNumber,
    };
    self.param.target_properties = {
      table: null,
      selected_identity: null,
      selected_profile: null,
      host: null,
      database: null,
      exportType: 'REPLACE',
      upsertKeys: self.upsertKeys
    };
    self.identities = null;
    self.tables = null;
    self.connection_timeout = false;

    self.addColumn = addColumn;
    self.removeColumn = removeColumn;
    self.getParam = getParam;
    self.setParam = setParam;
    self.validate = validate;
    self.setIdentities = setIdentities;
    self.saveTables = saveTables;
    self.reset = reset;
    self.areSmartCombineKeysEmpty = areSmartCombineKeysEmpty;

    function areSmartCombineKeysEmpty() {
      if (self.param.target_properties.exportType != 'UPSERT')
        return false;
      if (!self.upsertKeys || self.upsertKeys.length == 0)
        return true;
      
      let emptyKeys = false;
      for (let key of self.upsertKeys) {
        if (!key.column)
        {
          emptyKeys = true;
          break;
        }
      }
      return emptyKeys;
    }

    function validate() {
      return true;
    }

    function addColumn() {
      if (!self.upsertKeys) {
        return;
      }

      if (self.upsertKeys.length + 1 < self.dataview.column_count) {
        self.upsertKeys.push({ column: undefined });
      }
    }

    function removeColumn(i) {
      if (!self.upsertKeys) {
        return;
      }
      
      if(self.upsertKeys.length > 1)
        self.upsertKeys.splice(i, 1);
    }

    function getParam() {
      if (self.param.target_properties.table) {
        self.param.target_properties.table = self.param.target_properties.table.trim();
      }

      if (self.param.target_properties.exportType != 'UPSERT') {
        self.param.target_properties.upsertKeys = [];
      } else {
        self.param.target_properties.upsertKeys = self.upsertKeys;
      }
      return self.param;
    }

    function setParam(param) {
      // For backwards compatability, if mode doesn't exist, assign default as 'REPLACE'
      param.target_properties.exportType = param.target_properties.exportType || 'REPLACE';
      angular.extend(self.param, param);

      //For backwards compatability, if there are upsert keys obtained from target, then assign to array
      if (!_.isEmpty(self.param.target_properties.upsertKeys)) {
        self.upsertKeys = self.param.target_properties.upsertKeys;
      }
    }

    function setIdentities(identities) {
      self.identities = identities;
    }

    function saveTables(tables) {
      self.tables = tables;
    }

    function reset() {
      self.param.target_properties = {
        table: null,
        selected_identity: null,
        selected_profile: null,
        host: null,
        database: null,
        exportType: 'REPLACE',
        upsertKeys: []
      };
    }
  }
}
