const authStore = {
  namespaced: true,

  state: {
    logout: null,
  },

  actions: {
    setLogout: (store, logout) => {
      store.commit("setLogout", logout);
    }
  },

  mutations: {
    setLogout: (state, logout) => {
      state.logout = logout;
    },
  },

  getters: {
    logout: state => state.logout,
  }
};

export default authStore;
