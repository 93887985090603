/**
 * @ngInject
 */
MainGridUnitFactory.$inject = ['eventCallbackManagerFactory', 'DataviewLayoutService', '$timeout', 'appMetaInfo', 'utils', 'config'];
export function MainGridUnitFactory(eventCallbackManagerFactory, DataviewLayoutService, $timeout, appMetaInfo, utils, config) {
  return {
    get: function (dataview) {
      return new MainGridUnit(dataview);
    }
  };

  function MainGridUnit(dataview) {
    var self = this;

    var colMenuRequestSelectionEvent = new eventCallbackManagerFactory('colMenuRequestSelectionEvent' + dataview.id);
    var colMenuSelectedEvent = new eventCallbackManagerFactory('colMenuSelectedEvent' + dataview.id);
    var colMenuHideEvent = new eventCallbackManagerFactory('colMenuHideEvent' + dataview.id);

    var cellMenuRequestSelect= new eventCallbackManagerFactory('cellMenuRequestSelect' + dataview.id);
    var cellMenuHideEvent = new eventCallbackManagerFactory('cellMenuHideEvent' + dataview.id);
    var cellMenuSelectedEvent = new eventCallbackManagerFactory('cellMenuSelectedEvent' + dataview.id);

    var onColHeaderRename = new eventCallbackManagerFactory('pivotManagerItemAdded' + dataview.id);
    var onRenameClick = new eventCallbackManagerFactory('onRenameClick' + dataview.id);

    var gridInitEvent = new eventCallbackManagerFactory('on_grid_init' + dataview.id);
    var grid_reload_event = new eventCallbackManagerFactory('on_grid_reload' + dataview.id);


    // properties
    self.gridInstance = undefined;
    self.vueGridInstance = undefined;
    self.metadata = undefined;
    self.dataview = dataview;
    self.dataURL = undefined;
    self.columnMenu = {
      // properties
      columnId: undefined,
      column: undefined,
      visible: false,
      _inViewPort: true,
      menuType: 'tasks',

      // methods
      show: showColMenu,
      hide: hideColMenu,
      selectColumn: selectColumn,

      // events
      onSelectColumn: colMenuRequestSelectionEvent.add_callback,  // on request for col selection
      onColumnSelected: colMenuSelectedEvent.add_callback,  // column selection successfully complete
      onHideColumn: colMenuHideEvent.add_callback,  // on request for col selection
      _fireOnColumnSelected: colMenuSelectedEvent.fire_event,
      onColHeaderRenameEvent: onColHeaderRename.add_callback,
      _fireOnColRename: onColHeaderRename.fire_event,
      onRenameClickEvent: onRenameClick.add_callback,
      _fireOnRenameClick: onRenameClick.fire_event

  };
    self.cellMenu = {
      // properties
      value: undefined,
      column: undefined,
      columnId: undefined,  // example "column_1"
      rowIndex: undefined,  // same as row number (zero indexed)
      columnIndex: undefined,  // index of a column, e.g.: 5 (zero indexed including serial no. column)
      visible: false,
      selection: '',
      selectionStart: undefined,
      selectionEnd: undefined,
      shortenedSelection: '',
      _inViewPort: false,

      // methods
      show: showCellMenu,
      hide: hideCellMenu,
      reset: resetCellMenu,

      // events
      onSelectCell: cellMenuRequestSelect.add_callback,
      _fireOnCellSelect: cellMenuRequestSelect.fire_event,
      onHide: cellMenuHideEvent.add_callback,  // on request for menu hide
      onCellSelected: cellMenuSelectedEvent.add_callback,
      _fireOnCellSelected: cellMenuSelectedEvent.fire_event,
    };
    self.loader = {
      show: false,
      hideOverlay: false
    };
    self.onScrollLoader = {
      show: false,
      hideOverlay: false
    };

    // methods
    self.reloadGrid = reloadGrid;

    // events
    self._gridInitedEvent = gridInitEvent;
    self.onGridInit = gridInitEvent.add_callback;
    self.onReload = grid_reload_event.add_callback;
    self._gridReloadEvent = grid_reload_event;

    function selectColumn(columnId, menuType) {
      colMenuRequestSelectionEvent.fire_event(columnId, undefined, menuType);
    }

    function showColMenu() {
      $timeout(function () {
        self.columnMenu._inViewPort = true;
        self.columnMenu.visible = true;
      });
    }

    function hideColMenu() {
      $timeout(function () {
        self.columnMenu.visible = false;
      });
      self.columnMenu.visible = false;
      colMenuHideEvent.fire_event(self.columnMenu.columnId);
      self.columnMenu.columnId = undefined;
    }

    function showCellMenu() {
      $timeout(function () {
        self.cellMenu._inViewPort = true;
        self.cellMenu.visible = true;
      });
    }

    function resetCellMenu() {
      self.cellMenu.selection = '';
      self.cellMenu.selectionStart = undefined;
      self.cellMenu.selectionEnd = undefined;
      self.cellMenu.value = undefined;
      self.cellMenu.shortenedSelection = '';
    }

    function hideCellMenu() {
      resetCellMenu();
      $timeout(function () {
        self.cellMenu.visible = false;
        cellMenuHideEvent.fire_event();
      });
    }

    function reloadGrid() {
      self.metadata = dataview.metadata;
      self.dataURL = config.apiUrl + dataview.data_url;
      self._gridReloadEvent.fire_event();
      // $timeout(DataviewLayoutService.dataviewSwitched, 100);
    }
  }
}
