import { config } from '../common/app.config';



export function includeThirdParty() {
  return {
    templateUrl: config.templates.thirdPartyAPI,
    scope: {
      'close': "&closeHandle"
    },
    restrict: 'A',
    controller: config.controllers.thirdPartyAPI,
    controllerAs: 'tpvm',
    bindToController: true
  }
}


export function includeUrl() {
  return {
    templateUrl: config.templates.URL,
    scope: {
      'close': "&closeHandle"
    },
    restrict: 'A',
    controller: 'datapullByUrlController',
    controllerAs: 'uvm',
    bindToController: true
  }
}



export function includeWebhookConfigScreen() {
  return {
    templateUrl: config.templates.webhook,
    scope: {
      'close': "&closeHandle"
    },
    restrict: 'A',
    controller: 'webhookConfigScreenController',
    controllerAs: 'wvm',
    bindToController: true
  }
}
