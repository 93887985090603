/*jslint node: true */
var dateformat = require('dateformat');

/**
 * @ngInject
 */
filterRenderController.$inject = ['c', 'utils', 'Notification', 'queryHelper', 'elementConfig'];
export function filterRenderController(c, utils, Notification, queryHelper, elementConfig){
  this.$onInit = function () {
    var fevm = this;
    var controllerId = utils.string.format('filter.display.{0}', [utils.string.random(10)]);
    var blankValueIdentifier = '___BLANK_ID_FOR_EMPTY_VALUE___';

    var init = utils.debounce(_init, 500);

    var unsubscribeOnUpdate = fevm.filter.onUpdate("elementDirectiveUpdate." + controllerId, function () {
      init();
    });

    var unsubscribeOnDisplayUpdate = fevm.filter.onDisplayUpdate("elementDirectiveDisplayUpdate." + controllerId, function () {
      init();
    });

    var onFilterResetEvent = fevm.filter.onFilterReset('filterResetEvent' + controllerId, function () {
      fevm.helper.selector.reset();
    })

    fevm.destroy = function () {
      typeof unsubscribeOnUpdate === "function" && unsubscribeOnUpdate();
      typeof unsubscribeOnDisplayUpdate === "function" && unsubscribeOnDisplayUpdate();
    };


    init();

    function _init() {
      var selectorClass = null;
      if (fevm.filter.display_properties.filterType == 'Dropdown') {
        selectorClass = SingleSelector;
      } else {
        selectorClass = MultiSelector;
      }

      fevm.helper = new FilterUIHelper(fevm.filter, selectorClass);

      if (!fevm.filter.hasOwnProperty('current_query')) {
        fevm.filter.current_query = null;
      }
    }

    fevm.apply = function () {
      fevm.filter.current_query = fevm.helper.getParam();
      fevm.applyCallback();
    }

    function FilterUIHelper(filter, SelectorClass) {
      var helper = this;
      helper.values = [];
      var root_column_internal_name = null;
      var root_column_internal_type = null;
      helper.query = null;
      helper.init = init;
      helper.query = init;
      helper.getParam = getParam;
      helper.selector = null;
      helper.groupParam = null;
      helper.formatted = {};
      helper.groupParam = filter.param.PIVOT.GROUP_BY[0];
      init();

      function getParam() {
        var selected = helper.selector.getSelected();
        if (selected.length) {
          var queries = [];
          $.each(selected, function ($i, $v) {
            queries.push(_getParamForValue($v));
          });

          if (queries.length == 1) {
            return queries[0];
          } else {
            return {OR: queries};
          }
        } else {
          return null;
        }
      }

      function init() {
        filter.row_limit = elementConfig.rowLimits.filter;
        helper.processing = true;
        helper.values = [];
        filter.query().then(function (data) {
          if (data.STATUS == 'READY') {

            root_column_internal_name = data.metadata[0].internal_name;
            root_column_internal_type = data.metadata[0].type;

            data.data.forEach(function (r) {
              var val = r[root_column_internal_name];
              if (val === null) {
                val = blankValueIdentifier;
              }
              helper.values.push({internal: val, display: getFormattedValue(val)});
            })
            helper.selector = new SelectorClass();
          }
          helper.processing = false;

        });
      }

      function _getParamForValue(value) {
        var query = {};
        if (value === blankValueIdentifier) {
          query[root_column_internal_name] = {IS_EMPTY: true};
        } else if (root_column_internal_type == c.numeric) {
          var sanitized_number = parseFloat(value.replace(/[^0-9-.]/g, ''));
          query[root_column_internal_name] = {EQ: sanitized_number};
        } else if (root_column_internal_type == c.text) {
          query[root_column_internal_name] = {EQ: value};
        } else if (root_column_internal_type == c.date) {
          query = queryHelper.getDateQuery(helper.groupParam, root_column_internal_name, value);
        }
        return query;
      }

      function getFormattedValue(v) {
        if (v === blankValueIdentifier) {
          return '(blank)';
        }
        if (root_column_internal_type == c.date && v) {
          if (filter.display_properties.truncateExtractInfo.param.hasOwnProperty('TRUNCATE')) {
            return utils.date.strftime(new Date(v), filter.display_properties.truncateExtractInfo.value);
          }
        }
        return v;
      }
    }

    function SingleSelector() {
      var selector = this;
      selector.select = select;
      selector.selected = null;
      selector.reset = reset;
      selector.getSelected = getSelected;
      selector.setSelectedOnFilter = setSelectedOnFilter;

      function getSelected() {
        if (selector.selected) {
          return [selector.selected];
        } else {
          return [];
        }
      }

      function reset() {
        selector.select(null);
      }

      function select(value) {
        selector.selected = value;
        fevm.filter.selectedValues = selector.getSelected();
      }

      function setSelectedOnFilter() {
        fevm.filter.selectedValues = selector.getSelected();
      }
    }


    function MultiSelector() {
      var selector = this;
      selector.selected = [];
      selector.select = select;
      selector.reset = reset;
      selector.getSelected = getSelected;


      function reset() {
        selector.selected = [];
        fevm.filter.selectedValues = selector.getSelected();
      }

      function select(value) {
        if (selector.selected.indexOf(value) == -1) {
          selector.selected.push(value);
        } else {
          selector.selected = $.grep(selector.selected, function (v) {
            return v != value;
          });
        }
        fevm.filter.selectedValues = selector.getSelected();
      }

      function getSelected() {
        return selector.selected;
      }
    }
  }
}
