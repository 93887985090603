import * as angular from 'angular';

/**
 * @ngInject
 */
tableDataGrid.$inject = ['$http', '$timeout', '$compile', 'gridFactory', '$q', 'FutureService'];
export function tableDataGrid($http, $timeout, $compile, gridFactory, $q, FutureService){
  return {
    link: tableDataGridLink
  };

  function tableDataGridLink(scope, element, attributes) {
    var toggleGridLoaderFn = scope.$eval(attributes.toggleGridLoader);
    var data_url;
    var gridInstance, metadata;

    /*Anytime metadata or resourceDataUrl gets changed, update both and do one of the following
     *    - render: Do this if metadata is not passed by the instantiater of tableDataGrid
     *    - _updateGridInstance: Do this metadata is passed by the instantiater of the tableDataGrid
     *
     *    `render` does two jobs:
     *        1: get-request on data_url with limit 1(row) so that it gets the metadata
     *        2: _updateGridInstance i.e. get the data and show that on the grid
     *    `_updateGridInstance` does only one job:
     *        1: update the gridInstance i.e. get the data and show that on the grid
     */
    scope.$watchGroup([attributes.resourceDataUrl, attributes.metadata], function (_newValues) {
      if(_newValues[0]){
        data_url = _newValues[0];
      }
      if(_newValues[1]){
        metadata = _newValues[1];
      }
      if(data_url){
        if(!metadata){
          render();
        }
        else{
          _updateGridInstance();
        }
      }
    });

    var grid_options = {rowHeight: 20};

    function render() {
      var deferred = $q.defer();
      var dataURl = data_url;
      if (Array.isArray(data_url)) {
        if (data_url.length > 0) {
          dataURl = data_url[0];
        } else {
          return;
        }
      }
      $http.get(dataURl, {
        "params": {
          "offset": 1,
          "limit": 1
        }
      }).then(function (data) {
        get_data_tracker(data).then(function (response) { 
          let d = response;
          if (d.metadata) {
            metadata = d.metadata;
          }
          if (metadata) {
            _updateGridInstance();
          }
          deferred.resolve();
        });
      }, deferred.reject);
      return deferred.promise;
    }

    function get_data_tracker(data) {
      var deferred = $q.defer();
      FutureService.track(data.data.future_id, data_tracker);
  
      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }
      return deferred.promise;
    }

    function _updateGridInstance() {
      if (!data_url || !metadata.length) {
        return;
      }
      if (attributes.gridOptions) {
        var gridOptionsFromAttr = scope.$eval(attributes.gridOptions);
        if (angular.isObject(gridOptionsFromAttr)) {
          grid_options = angular.merge(grid_options, gridOptionsFromAttr);
        }
      }
      $(element).empty();
      if (gridInstance) {
        gridInstance.destroy();
      }
      var columns = [];
      if (attributes.columns) {
        columns = scope.$eval(attributes.columns);
      } else {
        angular.forEach(metadata, function (col) {
          var _col = {"name": col.display_name, "id": col.internal_name, "field": col.internal_name};
          if (grid_options['showColumnTypeIcons'] && col.hasOwnProperty('type')) {
            _col['type'] = col.type;
          }
          columns.push(_col);
        });
      }

      // index column is added as part of the ticket MVP-4025
      let index_column = columns.filter(item => item.field === 'index') 
      // add index column only if not already added
      if (columns.length > 0 && index_column.length == 0) {
        var indexColumn = {
          "name": "", "id": "index", "field": "index", "width": 50, cssClass: "sequence-cell",
          resizable: false
        };
        columns.splice(0, 0, indexColumn);
      }

      gridInstance = gridFactory(element, data_url, columns, grid_options);
      gridInstance.init();
      scope.gridInstance = gridInstance;
      if (attributes.bindGridInstanceTo) {
        var _bindGridInstanceTo = scope.$eval(attributes.bindGridInstanceTo);
        _bindGridInstanceTo.gridInstance = gridInstance;
      }
      resubscribeLoaders();
    }
    function resubscribeLoaders() {
      gridInstance.dataFactory.onDataLoading.subscribe(function (e, args) {
        $timeout(function () {
          if (typeof toggleGridLoaderFn === "function") {
            toggleGridLoaderFn(true);
          }
        }, 0);
      });

      gridInstance.dataFactory.onDataLoaded.subscribe(function (e, args) {
        $timeout(function () {
          if (typeof toggleGridLoaderFn === "function") {
            toggleGridLoaderFn(false);
            $timeout(function () {
              if (scope.gridInstance && scope.gridInstance.grid) {
                scope.gridInstance.grid.resizeCanvas();
              }
            }, 300);
          }
        }, 0);
      });
    }
  }


}

/**
 * @ngInject
 */
tableRawDataGrid.$inject = ['gridFactory', '$timeout'];
export function tableRawDataGrid(gridFactory, $timeout){
  return {
    link: linkFn
  };


  function linkFn(scope, element, attributes) {
    var toggleGridLoaderFn = scope.$eval(attributes.toggleGridLoader);
    var gridInstance;
    var grid_options = {rowHeight: 20, };

    scope.$watchCollection(attributes.rawData, function (rawData) {
      if(rawData && rawData.length){
        var metadata = scope.$eval(attributes.metadata);
        var display_properties = scope.$eval(attributes.displayProperties);
        render(element, rawData, metadata, display_properties);
      }
      else{
        // $(element).empty();
        // $(element).html('<span class="empty">Empty table</span>');
        var metadata = scope.$eval(attributes.metadata);
        var display_properties = scope.$eval(attributes.displayProperties);

        render(element, [], metadata, display_properties);
      }
    });
    function render(element, rawData, metadata, display_properties){
      display_properties = display_properties || {};
      $(element).empty();
      if (gridInstance) {
        gridInstance.destroy();
      }

      var max_length = 0;
      rawData.forEach(function(row){
        if(row.length > max_length){
          max_length = row.length;
        }
      });

      var i = 0, columns = [];
      var colWidths = display_properties.COLUMN_WIDTHS || {};
      if(metadata){
        for (; i < metadata.length; i++) {
          var col: any = {"name": metadata[i].display_name, "id": i, "field": i};
          if (colWidths.hasOwnProperty(metadata[i].internal_name)){
            col.width = colWidths[metadata[i].internal_name];
          }
          columns.push(col);
        }
      }
      else{
        for (; i < max_length; i++) {
          columns.push({"name": 'Column ' + (i + 1), "id": i, "field": i});
        }
      }
      if (columns.length >0) {
        var indexColumn = {
          "name": "#", "id": "index", "field": "index", "width": 30, cssClass: "sequence-cell",
          resizable: false
        };
        columns.splice(0, 0, indexColumn);
      }
      // cleanup raw data
      rawData.forEach(function(r){
        while(r.length < max_length){
          r.push(null);
        }
      });

      gridInstance = gridFactory(element, undefined, columns, grid_options, rawData);
      gridInstance.init();
      resubscribeLoaders();
      scope.gridInstance = gridInstance;
    }

    function resubscribeLoaders() {
      gridInstance.dataFactory.onDataLoading.subscribe(function (e, args) {
        $timeout(function () {
          if (typeof toggleGridLoaderFn === "function") {
            toggleGridLoaderFn(true);
          }
        }, 0);
      });

      gridInstance.dataFactory.onDataLoaded.subscribe(function (e, args) {
        $timeout(function () {
          if (typeof toggleGridLoaderFn === "function") {
            toggleGridLoaderFn(false);
          }
        }, 0);
      });
    }
  }
}
