import * as angular from 'angular';

/**
 * @ngInject
 * @returns {{get_manager: get_manager}}
 */

runningTotalManagerFactory.$inject = ['destinationColumnManagerFactory', 'c', 'sortManagerFactory', 'utils'];
export function runningTotalManagerFactory(destinationColumnManagerFactory, c, sortManagerFactory, utils) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new RunningTotalManager(options);
  }

  function RunningTotalManager(options) {
    var self = this;
    var metadata = options.metadata, taskUtils = options.taskUtils, dataview = options.context.dataview;
    self.getParam = getParam;
    self.setParam = setParam;
    self.validate = validate;
    self.sourceColumnSelected = sourceColumnSelected;

    self.columns = (function(){
      var columns = [];
      metadata.forEach(function(col){
        if(col.type == c.numeric){
          columns.push(col);
        }
      });
      return columns;
    })();

    self.destinationManager = destinationColumnManagerFactory.get_manager(
      {metadata: metadata, allowedTypes: ['NUMERIC'], taskUtils: options.taskUtils, isDestinationFormatterVisible: true}
    );

    self.sortManager = sortManagerFactory.get_manager(options);
    self.source_column = null;

    function validate() {
      if (!self.source_column) {
        self.error = "Select a source column";
        return false;
      }

      if (self.destinationManager) {
        return self.destinationManager.validate();
      }
      return true;
    }

    function sourceColumnSelected() {
      taskUtils.highlight.sources(self.source_column);
      self.destinationManager.setDestinationFormat(dataview.display_properties.FORMAT_INFO[self.source_column.internal_name]);
    }

    function getParam() {
      var running_total_param: any = {SOURCE: self.source_column.internal_name};

      var destination_param = self.destinationManager.getParam();
      angular.extend(running_total_param, destination_param);

      var sortParams = self.sortManager.getParam();

      if (sortParams){
        running_total_param.ORDER_BY = sortParams;
      } else {
        delete running_total_param.ORDER_BY;
      }
      return {RUNNING_TOTAL: running_total_param};
    }

    function setParam(param) {
      self.source_column = utils.metadata.get_column_by_internal_name(metadata, param.RUNNING_TOTAL.SOURCE);
      sourceColumnSelected();
      if (param.RUNNING_TOTAL.hasOwnProperty('ORDER_BY')){
        self.sortManager.setParam(param.RUNNING_TOTAL.ORDER_BY);
      }
      self.destinationManager.setParam(param.RUNNING_TOTAL);
    }
  }
}
