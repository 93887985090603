import * as angular from 'angular';
import * as _ from 'lodash-es';

/**
 * @ngInject
 * @returns {{get_manager: get_manager}}
 */
sortManagerFactory.$inject = ['utils', 'c', 'analyticsService'];
export function sortManagerFactory(utils, c, analyticsService) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new SortManager(options);
  }

  function SortManager(options) {
    var self = this;
    var metadata = options.metadata,
      dataview = options.context ? options.context.dataview : undefined;
    self.context = options.context;   
    self.getParam = getParam;
    self.setParam = setParam;
    self.add_sort = add_sort;
    self.validate = validate;
    self.sortError = false;
    self.remove_sort = remove_sort;
    self.mandatory_sort = options.mandatory_sort;
    self.show_sort_rows = false;
    self.toggle_show_sort_rows = toggle_show_sort_rows;
    self.addOrRemoveSort = addOrRemoveSort;
    self.sortChangeCallback = function () {};

    self.metadata = metadata;
    self.updateMetadata = updateMetadata;
    self.sorts = [];
    self.compute_sort_description = compute_sort_description;
    self.sort_description = "";
    self.inEditMode = options.context ? options.context.inEditMode : undefined;
    self.getDisplayName = getDisplayName;

    self.allowedSorts = [
      {
        'display': "Ascending",
        'internal': "ASC"
      },
      {
        'display': "Descending",
        'internal': "DESC"
      }
    ];

    function validate(){
      var is_valid = true
      _.forEach(self.sorts, function (sort_spec){
        if (sort_spec[0]?.hasOwnProperty('error')){
          is_valid = false
          self.sortError = true
        }
      })
      return is_valid
    }

    function getDisplayName(internalName) {
      var column = utils.metadata.get_column_by_internal_name(self.metadata, internalName);
      return column!=undefined?column.display_name:'UNKNOWN';    
    }

    function setCurrentSort() {
      if (dataview && dataview.display_properties.SORT) {
        angular.forEach(dataview.display_properties.SORT, function (sort) {
          let column_internal_name = utils.metadata.get_column_by_internal_name(self.metadata, sort[0])
          if (column_internal_name) self.sorts.push([column_internal_name, sort[1]])
        })
        compute_sort_description()
      }
    }

    setCurrentSort();
    if (self.mandatory_sort === true) {
      self.show_sort_rows = true;
      add_sort();
    }

    function updateMetadata(metadata) {
      var sortInternalNames = [];
      angular.forEach(self.sorts, function (item) {
        if (item[0].internal_name) {
          sortInternalNames.push(item[0].internal_name);
        } else {
          sortInternalNames.push(undefined);
        }
      });
      self.metadata = metadata;
      angular.forEach(self.sorts, function (item, i) {
        if (sortInternalNames[i]) {
          self.sorts[i][0] = utils.metadata.get_column_by_internal_name(self.metadata, sortInternalNames[i]);
        } else {
          self.sorts[i][0] = sortInternalNames[i];
        }
      });
    }

    function toggle_show_sort_rows() {
      self.show_sort_rows = !self.show_sort_rows;
      addOrRemoveSort();
    }

    function addOrRemoveSort() {
      if (self.show_sort_rows) {
        if (self.sorts.length === 0) {
          add_sort();
        }
      } else {
        if (self.sorts.length === 1 && !self.sorts[0][0] && !self.mandatory_sort) {
          remove_sort(0);
        }
      }
    }

    function add_sort(){
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.addAnotherColumnToSort,
        {
        eventOrigin: "dataview.statusBar"
      });
      self.sorts.push([undefined, "ASC"]);
      compute_sort_description();
    }

    function remove_sort(index){
       analyticsService.userEventTrack(c.userEvents.dataviewEvents.removeColumnFromSort,
        {
        eventOrigin: "dataview.statusBar"
      });
      if (self.sorts.length === 1 && self.mandatory_sort === true) {
        return;
      }
      self.sorts = $.grep(self.sorts, function(s, i){
        return i != index;
      });
      if (self.sorts.length == 0) {
        self.show_sort_rows = false;
      }
      compute_sort_description();
    }

    function compute_sort_description() {
      self.sort_description = self.sorts.map(function (sort) {
        return sort[0] ? sort[0].display_name + ' (' + sort[1].toLowerCase() + ')' : "";
      }).join(", ");
      self.sortChangeCallback();
    }

    function getParam() {
      if(self.sorts.length == 0){
        return null;
      }
      var sorts = [];
      angular.forEach(self.sorts, function (sort) {
        if (sort[0]) {
          sorts.push([sort[0].internal_name, sort[1]]);
        }
      });
      return sorts;
    }

    function setParam(param) {
      self.sorts = [];
      _.forEach(param, function (sort) {
        let col_info = utils.metadata.get_column_by_internal_name(self.metadata, sort[0]);
        if (col_info) {
           self.sorts.push([col_info, sort[1]]);
        }
      });
      
      compute_sort_description();
    }

  }
}


