<template>
  <div class="mm-dataflow-settings">
    <mm-popup
      :value="computedShowDataFlowSettingsPopup"
      :title="$t('view.dataflow_settings.title')"
      wrapper-class="width-100"
    >
      <mm-accordion v-model="isAccordionOpen" small ascending>
        <template v-slot:header>
          <div class="d-flex align-items-center">
            <mm-icon class="m-r-3" name="settings" />
            <span :class="{ 'mm-text--body-bold': isAccordionOpen }">
              {{ $t('view.dataflow_settings.title') }}
            </span>
          </div>
        </template>
        <template v-slot:content>
          <mm-divider />

          <mm-tooltip :label="$t('view.dataflow_settings.auto_run_pipeline.tooltip')" wrapper-class="setting">
            <span
              class="setting--text width-100"
              @click="toggleAutoRun(!computedView.properties.pipelineAutoRunEnabled)"
            >
              {{ $t('view.dataflow_settings.auto_run_pipeline.title') }}
            </span>
            <mm-loader v-if="isToggleAutoRunLoading" class="m-r-2" />
            <mm-toggle
              v-else
              v-model="computedView.properties.pipelineAutoRunEnabled"
              :disabled="isAutoRunDisabled || computedView.properties.draftModeStatus === RESOURCE_DRAFT_STATUS.DIRTY"
              small
              @input="toggleAutoRun"
            />
          </mm-tooltip>

          <mm-tooltip
            v-if="featuresVisibility[SPLITIO_FEATURE_FLAGS.DATA_SYNC]"
            wrapper-class="width-100"
            :label="$t('view.dataflow_settings.data_sync_options.tooltip')"
          >
            <div
              class="setting"
              :class="{ 'active-setting': isDataSyncOptionsModalVisible }"
              @click="openDataSyncOptions"
            >
              <span class="setting--text">{{ $t('view.dataflow_settings.data_sync_options.title') }}</span>
              <mm-icon class="m-l-auto" name="chevron_right" />
            </div>
          </mm-tooltip>
        </template>
      </mm-accordion>

      <template v-slot:content>
        <label>{{ $t('view.dataflow_settings.popup_description') }}</label>
      </template>

      <template v-slot:actions>
        <mm-button
          class="m-l-auto"
          :label="$t('global.dictionary.got_it')"
          @click="toggleOnboardingFlag(ONBOARDING_FEATURES.DATAFLOW_SETTINGS)"
        />
      </template>
    </mm-popup>

    <data-sync-options
      v-model="isDataSyncOptionsModalVisible"
      :view-id="viewId"
      @submit="$emit('dataSyncOptionsUpdated')"
    />
  </div>
</template>

<script>
// Components
import DataSyncOptions from '@/modules/data-editor/components/data-sync-options/data-sync-options'

// Constants
import { RESOURCE_DRAFT_STATUS, SPLITIO_FEATURE_FLAGS, ONBOARDING_FEATURES, USER_EVENTS } from '@/constants'

// Plugins
import { featuresVisibility } from '@/plugins/splitio'

// API
import viewApi from '@/modules/view/api/view.api'

// Utils
import { toggleOnboardingFlag } from '@/utils/onboarding'

// Dependencies
import { mapState } from 'vuex'

export default {
  name: 'dataflow-settings',
  components: { DataSyncOptions },
  props: {
    viewId: {
      type: [String, Number],
      required: true
    },
    isAutoRunDisabled: Boolean,
    isPipelineOpen: Boolean
  },
  data: () => ({
    isAccordionOpen: false,
    isToggleAutoRunLoading: false,
    isDataSyncOptionsModalVisible: false,
    featuresVisibility
  }),
  created() {
    this.SPLITIO_FEATURE_FLAGS = SPLITIO_FEATURE_FLAGS
    this.RESOURCE_DRAFT_STATUS = RESOURCE_DRAFT_STATUS
    this.ONBOARDING_FEATURES = ONBOARDING_FEATURES
  },
  computed: {
    ...mapState(['onboardingFlags']),
    computedView() {
      return this.$store.getters['resources/getViewByViewId'](this.viewId)
    },
    computedShowDataFlowSettingsPopup() {
      const isSampleFlow = window.location.hash.includes('sampleFlow=true')
      return !this.onboardingFlags[ONBOARDING_FEATURES.DATAFLOW_SETTINGS] && this.isPipelineOpen && !isSampleFlow
    }
  },
  watch: {
    isAccordionOpen(status) {
      if (!this.onboardingFlags[ONBOARDING_FEATURES.DATAFLOW_SETTINGS])
        toggleOnboardingFlag(ONBOARDING_FEATURES.DATAFLOW_SETTINGS)

      this.$userEvents.save(USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.TOGGLE, { status })
    }
  },
  methods: {
    async toggleAutoRun(autoRunStatus) {
      this.$userEvents.save(USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.TOGGLE_AUTO_RUN, { autoRunStatus })

      this.isToggleAutoRunLoading = true
      this.computedView.properties.pipelineAutoRunEnabled = autoRunStatus
      await viewApi.toggleAutoRun(this.viewId, autoRunStatus)
      setTimeout(() => (this.isToggleAutoRunLoading = false), 3000) // Timeout so that view resource has time to update
    },
    openDataSyncOptions() {
      this.isDataSyncOptionsModalVisible = true
      this.$userEvents.save(USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.DATA_SYNC_OPTIONS.OPEN)
    },
    toggleOnboardingFlag
  }
}
</script>

<style lang="scss">
@import '@mammoth_developer/mm-storybook/src/styles/spacing.scss';
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';

.mm-dataflow-settings {
  .mm-divider {
    margin-bottom: 1px;
  }

  .setting {
    @extend .p-r-3;
    @extend .p-l-7;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 32px;
    width: 100%;

    &:hover {
      background: var(--mm-color--n30);
    }

    &.active-setting {
      background: var(--mm-color--p200);

      .setting--text {
        @extend .mm-text--body-bold;
      }
    }
  }
}
</style>
