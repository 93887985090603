/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * @ngInject
 */
renderMetricElement.$inject = ['$timeout','config', 'taskDescriber', 'DataviewService', 'utils'];
export function renderMetricElement($timeout, config, taskDescriber, DataviewService, utils){
  return {
    templateUrl: config.templates.metricElementContentTemplate,
    controller: 'metricRenderController',
    bindToController: true,
    controllerAs:'mevm',
    scope: {
      metric: "=",
      condition: "=",
      dataviewId:"=",
      big: "=",
      renderCompleteCallback: "=",
      metadata: '='
    },
    link: function(scope, e, a){
      $timeout(function(){
        let isLocked = scope.mevm.metric.display_properties.LOCKED;
        if (a.condition) {
          scope.$watchCollection('mevm.condition', function(newValue, oldValue){
            if(!angular.equals(newValue, oldValue) && !scope.mevm.metric.display_properties.LOCKED){
              scope.mevm.query();
            }
          });
        }

        function setConditionText(paramCondition, executionTimestamp?: string) {
          if(paramCondition && !angular.equals(paramCondition, {}) && scope.mevm.metadata){
            scope.mevm.hasCondition = true;
            var wksp = DataviewService.list[scope.mevm.metric.dataviewId]
            // meetric metadata is always the metadata of the latest pipeline sequence
            var metadata = wksp.taskwise_info[wksp.tasks_total_count].metadata

            let formattedMetadata = metadata;
            if (scope.mevm.metric.hasOwnProperty('reference_errors')) {
              formattedMetadata = utils.metadata.add_error_columns_to_metadata(metadata, scope.mevm.metric.reference_errors.reference_errors, wksp.id);
            }
            scope.mevm.conditionText = taskDescriber.describeCondition(formattedMetadata, paramCondition, false, executionTimestamp);
            if(scope.mevm.conditionText){
              scope.mevm.conditionText = scope.mevm.conditionText.replace(/<(?!<|=)(?:.|\n)*?>/gm, '');
            }
          }
          else{
            scope.mevm.hasCondition = false;
            scope.mevm.conditionText = '';
          }
        }

        function setErrorText() {
          scope.mevm.errorObject = {error_descriptions: {}};
          taskDescriber.describeErrors(scope.mevm.errorObject, scope.mevm.metric.reference_errors, scope.mevm.metric.display_properties);
        }

        scope.$watchCollection('mevm.metric.param', function(param){
          setConditionText(param.CONDITION, param.EXECUTION_TIMESTAMP);
          setErrorText();
        });

        var dataview = DataviewService.get_by_id(scope.mevm.dataviewId);
        if(dataview){
          dataview.on_update('updateMetricCondition', function(){
            setConditionText(scope.mevm.metric.param.CONDITION, scope.mevm.metric.param.EXECUTION_TIMESTAMP);
            setErrorText();
          });
        }

        scope.$watch('mevm.metric.display_properties.LOCKED', function (locked) {
          if(isLocked == locked){
            return;
          }
          isLocked = locked;
          if(!locked){
            scope.mevm.query(undefined, true);
          }
        });
      }, 200);
    }
  }
}

/**
 * @ngInject
 */
metricRenderController.$inject = ['utils','Notification'];
export function metricRenderController(utils, Notification){
  this.$onInit = function() {
    var mevm = this,
      controllerId = utils.getRandomString(10);

    mevm.queried = {
      name: undefined,
      value: undefined
    };
    mevm.query = utils.debounce(query, 1000);

    function query(paramUnchanged, justUnlocked) {
      let condition = mevm.condition;
      if (mevm.metric.display_properties.LOCKED || angular.equals(condition, {})) {
        condition = null;
      }
      if (justUnlocked && !condition) {
        return;
      }

      mevm.processing = true;

      mevm.metric.query(condition).then(function (data) {
        mevm.processing = false;
        mevm.queried.name = data.metadata[0].display_name;
        mevm.queried.value = data.data[0].RESULT || 0;
        mevm.queried.rawVal = parseFloat(mevm.queried.value);
        if (data.EXECUTION_TIMESTAMP) {
          mevm.metric.param.EXECUTION_TIMESTAMP = data.EXECUTION_TIMESTAMP;
        }
        if (mevm.renderCompleteCallback) {
          mevm.renderCompleteCallback();
        }
      }, _failureCb);

      function _failureCb(data) {
        mevm.processing = false;
        if (data.hasOwnProperty('data') && data['data'].hasOwnProperty('ERROR_CODE') && data['data']['ERROR_CODE'] == 7006
          && data['data'].hasOwnProperty('ERROR_MESSAGE')) {
          // Notification.error(data['data']['ERROR_MESSAGE']);
        }
      }
    }

    mevm.metric.onUpdate("elementDirectiveUpdate" + controllerId, mevm.query);
    mevm.query();
  }
}
