<template>
  <mm-modal
    v-model="computedShowModal"
    class="action-bar-export-big-query-modal"
    :title="$t('action_bar.export.export_to_database.existing_database.big_query.modal_title')"
    :width="485"
    closable
  >
    <div class="action-bar-export-big-query">
      <div v-if="showUpload">
        <p class="mm-text--caption-regular d-flex align-center">
          <mm-icon class="m-r-2 flex-shrink-0" name="information" />
          <span>
            {{ $t('action_bar.export.export_to_database.existing_database.big_query.caption') }}
            <mm-link
              class="text-lowercase"
              href="https://docs.mammoth.io/content/feature_guide/export_data/exporttobigquery.html"
              openInNewTab
              :label="$t('global.dictionary.documentation')"
            />
          </span>
        </p>

        <mm-file-uploader
          v-model="credentialFile"
          :title="$t('action_bar.export.export_to_database.existing_database.big_query.upload_file')"
          :allowedExtensions="['json']"
          dragAndDrop
        />
      </div>

      <div v-else>
        <div class="d-flex align-items-center m-b-3">
          <span class="mm-text--body-bold m-r-3">
            {{ $t('action_bar.export.export_to_database.existing_database.big_query.delete_connection') }}
          </span>
          <mm-button icon="delete" size="small" objective="tertiary" noFocusOnClick @click="onDeleteConnection" />
        </div>
        <div class="d-flex">
          <mm-dropdown
            v-model="selectedDataset"
            class="m-r-2"
            :width="200"
            :title="$t('action_bar.export.export_to_database.existing_database.big_query.project_name')"
            :placeholder="
              $t('action_bar.export.export_to_database.existing_database.big_query.project_name_placeholder')
            "
            :items="datasets"
            searchable
            item-text="name"
            item-value="name"
            @change="onProjectSelection"
          />
          <!-- table name -->
          <div class="action-bar-export-big-query-input-with-tooltip">
            <mm-text-input
              v-model="tableName"
              class="m-l-3"
              :title="$t('action_bar.export.export_to_database.existing_database.big_query.table_name.title')"
              :placeholder="
                $t('action_bar.export.export_to_database.existing_database.big_query.table_name.placeholder')
              "
              :error="!!tableNameErrorMessage"
              :message="tableNameErrorMessage"
              @blur="onTableNameChange"
            />
            <mm-tooltip
              position="bottom"
              contentClass="action-bar-export-big-query-tooltip"
              :label="$t('action_bar.export.export_to_database.existing_database.big_query.table_name.tooltip_label')"
            >
              <mm-icon name="information" />
            </mm-tooltip>
          </div>
        </div>

        <!-- update method -->
        <mm-dropdown
          v-model="dataUpdateMethod"
          :items="dataUpdateMethods"
          attach
          :title="$t('action_bar.export.export_to_database.existing_database.big_query.data_update_method')"
          :placeholder="
            $t('action_bar.export.export_to_database.existing_database.big_query.data_update_method_placeholder')
          "
          :width="200"
          @change="onDataUpdateMethodChange"
        />

        <!-- Reference Column -->
        <div v-if="dataUpdateMethod === 'UPSERT'" class="action-bar-export-big-query-input-with-tooltip">
          <mm-dropdown
            v-model="selectedUpsertKeys"
            :items="computedReferenceColumns"
            itemText="name"
            itemValue="id"
            itemDisabled="disabled"
            attach
            :title="$t('action_bar.export.export_to_database.existing_database.big_query.reference_column.title')"
            :placeholder="
              $t('action_bar.export.export_to_database.existing_database.big_query.reference_column.placeholder')
            "
            multiple
            style="width: 290px"
            @change="onReferenceColumnSelection"
          />
          <mm-tooltip
            position="bottom"
            contentClass="action-bar-export-big-query-tooltip"
            :label="
              $t('action_bar.export.export_to_database.existing_database.big_query.reference_column.tooltip_label')
            "
          >
            <mm-icon name="information" />
          </mm-tooltip>
        </div>

        <!-- Keep at the end of the pipeline -->
        <div class="d-flex align-items-center m-t-3">
          <mm-checkbox
            v-model="keepAtPipelineEnd"
            :label="$t('action_bar.export.export_to_database.keep_at_pipeline_end.label')"
            noFocusOnClick
            @click="onKeepAtEndOptionClick"
          />
          <mm-tooltip :label="$t('action_bar.export.export_to_database.keep_at_pipeline_end.tooltip')">
            <mm-icon class="m-l-2" name="information" />
          </mm-tooltip>
        </div>

        <div v-if="computedHasDateColumns" class="d-flex align-items-center m-t-3">
          <mm-checkbox
            v-model="enablePartitionOnDateColumn"
            :disabled="inEditMode && dataUpdateMethod != 'REPLACE'"
            :label="$t('action_bar.export.export_to_database.existing_database.big_query.partition_option_label')"
            noFocusOnClick
            @click="onTablePartitionToggle"
          />
          <mm-tooltip
            :label="$t('action_bar.export.export_to_database.existing_database.big_query.partition_option_tooltip')"
          >
            <mm-icon class="m-l-2" name="information" />
          </mm-tooltip>
        </div>

        <div v-if="enablePartitionOnDateColumn" class="m-t-3 d-flex">
          <mm-dropdown
            v-model="dateColumnForPartitioning"
            class="m-r-2"
            :disabled="inEditMode && dataUpdateMethod != 'REPLACE'"
            :title="$t('action_bar.export.export_to_database.existing_database.big_query.partition_column_label')"
            :placeholder="
              $t('action_bar.export.export_to_database.existing_database.big_query.partition_column_placeholder')
            "
            :items="computedDateColumns"
            item-text="name"
            item-value="name"
            :width="200"
            @change="onParititonColumnChange"
          />
          <mm-dropdown
            v-model="partitioningDatePart"
            class="m-l-3"
            :disabled="inEditMode && dataUpdateMethod != 'REPLACE'"
            :title="$t('action_bar.export.export_to_database.existing_database.big_query.partition_granularity_label')"
            :placeholder="
              $t('action_bar.export.export_to_database.existing_database.big_query.partition_granularity_placeholder')
            "
            :items="datePartOptions"
            :width="190"
            @change="onPartitionGranularityChange"
          />
        </div>
      </div>
    </div>

    <action-bar-export-big-query-delete
      v-model="showDeleteModal"
      :identityKey="identityKey"
      @deleted="onIdentityDelete"
    />

    <template #actions>
      <div class="d-flex justify-space-between w-100">
        <mm-button objective="tertiary" :label="$t('global.dictionary.back')" @click="onBack" />
        <mm-spacer />
        <mm-button class="m-r-4" :label="$t('dictionary.cancel')" objective="tertiary" @click="onCancel" />
        <mm-button
          :label="$t('dictionary.apply')"
          :loading="isApplyLoading"
          :disabled="computedIsApplyDisabled"
          @click="onApply"
        />
      </div>
    </template>
  </mm-modal>
</template>

<script>
import actionBarApi from '@/modules/data-editor/action-bar/action-bar.api'
import actionBarExportBigQueryDelete from './action-bar-export-big-query-delete.vue'
import { readFile } from '@/utils/files'
import { i18n } from '@/plugins/i18n'
import { USER_EVENTS, EXPORT_CONNECTORS_MAP, DATA_TYPES_MAP } from '@/constants'

const DATA_UPDATE_METHODS = [
  {
    value: 'REPLACE',
    text: i18n.t('global.dictionary.replace')
  },
  {
    value: 'COMBINE',
    text: i18n.t('global.dictionary.combine')
  },
  {
    value: 'UPSERT',
    text: i18n.t('global.dictionary.merge')
  }
]

export default {
  name: 'action-bar-export-big-query',
  components: { actionBarExportBigQueryDelete },
  props: {
    value: Boolean
  },
  created() {
    const datePartTranslationPath = 'action_bar.export.export_to_database.existing_database.big_query.date_part'
    this.datePartOptions = [
      { text: this.$t(`${datePartTranslationPath}.day`), value: 'DAY' },
      { text: this.$t(`${datePartTranslationPath}.month`), value: 'MONTH' },
      { text: this.$t(`${datePartTranslationPath}.year`), value: 'YEAR' }
    ]
  },
  data: () => ({
    inEditMode: false,
    showUpload: true,
    credentialFile: null,
    selectedIdentity: null,
    datasets: [],
    enablePartitionOnDateColumn: false,
    partitioningDatePart: null,
    dateColumnForPartitioning: null,
    selectedDataset: '',
    actionId: '',
    tableName: '',
    tableNameErrorMessage: '',
    dataUpdateMethods: DATA_UPDATE_METHODS,
    dataUpdateMethod: DATA_UPDATE_METHODS[0].value,
    selectedUpsertKeys: [],
    identityKey: null,
    validateResult: {
      isError: false,
      message: ''
    },
    keepAtPipelineEnd: true,
    isApplyLoading: false,
    showDeleteModal: false
  }),
  computed: {
    computedShowModal: {
      get() {
        return this.value
      },
      set(showModal) {
        this.$emit('input', showModal)
      }
    },
    computedIsApplyDisabled() {
      if (this.showUpload) return !this.credentialFile
      if (this.enablePartitionOnDateColumn && (!this.dateColumnForPartitioning || !this.partitioningDatePart)) {
        return true
      }
      const InvalidUpsertKeys = this.dataUpdateMethod == DATA_UPDATE_METHODS[2].value && !this.selectedUpsertKeys.length
      return !this.tableName || InvalidUpsertKeys
    },
    computedHasDateColumns() {
      return !!this.computedDateColumns.length
    },
    computedDateColumns() {
      const columns = this.$store.getters['dataEditor/columns']
      return columns.filter((c) => c.type == DATA_TYPES_MAP.DATE)
    },
    computedReferenceColumns() {
      const columns = this.$store.getters['dataEditor/columns']
      const isDisabledLastKey = this.selectedUpsertKeys.length === columns.length - 1

      return columns.map(({ id, name }) => ({
        id,
        name,
        disabled: isDisabledLastKey && !this.selectedUpsertKeys.includes(id)
      }))
    }
  },
  watch: {
    value(value) {
      if (value) this.checkIdentities()
      else {
        this.showUpload = true
        this.credentialFile = null
        this.selectedUpsertKeys = []
        this.tableName = ''
        this.tableNameErrorMessage = ''
        this.dataUpdateMethod = DATA_UPDATE_METHODS[0].value
        this.selectedDataset = this.datasets?.[0]?.name
        this.validateResult.isError = false
        this.validateResult.message = ''
      }
    }
  },
  mounted() {
    this.profileMap = {}
  },
  methods: {
    onCancel() {
      this.computedShowModal = false
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.CANCEL, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    onBack() {
      if (this.showUpload) {
        this.computedShowModal = false
        this.$emit('back')
      } else this.showUpload = true
    },
    onPartitionGranularityChange() {
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.SELECT_PARTITION_GRANULARITY, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    onParititonColumnChange() {
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.SELECT_PARTITION_COLUMN, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },

    onTablePartitionToggle() {
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.TABLE_PARTITION_TOGGLE, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    onKeepAtEndOptionClick() {
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.KEEP_AT_END_TOGGLE, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    onReferenceColumnSelection() {
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.SELECT_REFERENCE_COLUMNS, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    onDataUpdateMethodChange() {
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.SELECT_DATA_UPDATE_METHOD, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    onTableNameChange() {
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.TABLE_NAME_INPUT, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    onProjectSelection() {
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.SELECT_PROJECT, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    onDeleteConnection() {
      this.showDeleteModal = true
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.DELETE_CONNECTION, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    async checkIdentities() {
      const response = await actionBarApi.getBigQueryIdentities(this.$store.state.projectId)
      if (response.status == 'error') this.$toast.show({ content: response.message, status: 'error' })
      else {
        this.selectedIdentity = response.data.identities?.[0]
        if (this.selectedIdentity && !this.actionId) {
          this.identityKey = this.selectedIdentity.value
          this.showUpload = false
          this.loadDatasets()
        }
      }
    },
    async loadDatasets() {
      this.tables = []

      if (!this.profileMap[this.identityKey]) {
        const response = await actionBarApi.getBigQueryIdentity(this.$store.state.projectId, this.identityKey)
        if (response.status == 'error') this.$toast.show({ content: response.message, status: 'error' })
        else this.profileMap[this.identityKey] = response.profiles
      }
      this.datasets = this.profileMap[this.identityKey]
      if (!this.actionId) this.selectedDataset = this.datasets?.[0]?.name
    },
    async onApply() {
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.APPLY, {
        viewId: this.$store.state.dataEditor.viewId
      })
      if (this.showUpload) this.createIdentity()
      else this.exportBigQuery()
    },
    async createIdentity() {
      this.isApplyLoading = true
      const connectionData = await readFile(this.credentialFile)
      const response = await actionBarApi.createBigQueryIdentity({
        projectId: this.$store.state.projectId,
        connectionData
      })
      const identity = response.identity
      if (identity) {
        this.selectedIdentity = {
          value: identity.config_key,
          name: identity.name,
          identity_config: identity,
          profiles: [],
          selected_profile: {},
          host: identity.name,
          database: ''
        }
        this.identityKey = identity.config_key
        this.showUpload = false
        this.loadDatasets()
      } else
        this.$toast.show({
          content: this.$t('action_bar.export.export_to_database.existing_database.big_query.invalid_credentials'),
          status: 'error'
        })

      this.isApplyLoading = false
    },
    async getExportApiResponse({ onlyValidate, isEdit }) {
      const targetProperties = {
        host: this.selectedIdentity.name,
        database: this.selectedDataset,
        selected_identity: this.selectedIdentity,
        table: this.tableName,
        exportType: this.dataUpdateMethod,
        upsertKeys: this.$store.getters['dataEditor/columns']
          .filter((column) => this.selectedUpsertKeys.includes(column.id))
          .map((column) => {
            const type = column.type.toUpperCase()
            return {
              column: {
                internal_name: column.id,
                type,
                display_name: column.name,
                display_name_w_type: `${column.name} (${{ TEXT: 'text', DATE: 'date', NUMERIC: 'num' }[type]})`
              }
            }
          }),
        selected_profile: this.datasets.find((dataset) => dataset.name === this.selectedDataset)
      }
      const api = isEdit ? actionBarApi.patchExport : actionBarApi.exportToExistingDatabase
      if (this.enablePartitionOnDateColumn) {
        targetProperties.partition = {
          FIELD: this.dateColumnForPartitioning,
          GRANULARITY: this.partitioningDatePart
        }
      }
      return api({
        viewId: this.$store.getters['dataEditor/view'].viewId,
        actionId: this.actionId,
        databaseHandler: EXPORT_CONNECTORS_MAP.BIGQUERY.key,
        targetProperties,
        // Setting sequence as null keeps action at end of the pipeline,
        sequence: this.keepAtPipelineEnd ? null : this.$store.state.pipeline.taskCount,
        onlyValidate
      })
    },
    async exportBigQuery() {
      this.isApplyLoading = true
      this.validateResult.isError = false
      this.validateResult.message = ''
      const response = await this.getExportApiResponse({ onlyValidate: false, isEdit: !!this.actionId })
      if (response.status == 'error')
        this.$toast.show({
          content:
            response?.message ||
            response?.error?.message ||
            this.$t('action_bar.export.export_to_database.existing_database.credentials.apply_error'),
          status: 'error'
        })
      else {
        await this.$root.legacyHandlers.actionServiceUpdateList()
        this.$root.legacyHandlers.openPipeline()
        this.computedShowModal = false
      }

      this.isApplyLoading = false
    },

    onIdentityDelete() {
      this.credentialFile = null
      this.showUpload = true
    },
    handleEdit({ actionId, targetProperties, keepAtPipelineEnd }) {
      this.showUpload = false
      this.actionId = actionId
      this.inEditMode = true
      this.keepAtPipelineEnd = keepAtPipelineEnd
      this.enablePartitionOnDateColumn = !!targetProperties.partition
      if (this.enablePartitionOnDateColumn) {
        this.dateColumnForPartitioning = targetProperties.partition.FIELD
        this.partitioningDatePart = targetProperties.partition.GRANULARITY
      }
      this.identityKey = targetProperties.selected_identity.value
      this.selectedDataset = targetProperties.selected_profile.name
      this.dataUpdateMethod = targetProperties.exportType
      this.tableName = targetProperties.table
      this.selectedUpsertKeys = targetProperties.upsertKeys.map(({ column }) => column.internal_name)
      this.loadDatasets()
    }
  }
}
</script>

<style lang="scss">
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';

.action-bar-export-big-query {
  .mm-radio-button {
    label {
      word-break: break-all;
    }
    .mm-radio-button--radio {
      margin-top: 2px;
      flex-shrink: 0;
      align-self: flex-start;
    }
  }

  .action-bar-export-big-query-input-with-tooltip {
    position: relative;
    width: 290px;

    & > .mm-tooltip--slot-wrapper {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
  .action-bar-export-existing-credentials-validate {
    width: 75px;
    .mm-button--text {
      @extend .mm-text--caption-regular;
    }
  }
}

.action-bar-export-big-query-tooltip {
  .mm-tooltip--label {
    text-align: initial !important;
  }
}
</style>
