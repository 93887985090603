/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * ngInject
 *
 * @param $q
 * @param $http
 * @param config
 * @returns {{save_ds_config: saveDsConfig}}
 * @constructor
 */
CloudService.$inject = ['$q', '$http', 'config'];
export function CloudService($q, $http, config) {
  return {
    save_ds_config: saveDsConfig,
    edit_ds_config: editDsConfig
  };

  function saveDsConfig(integration_key, identity_key, ds_config, schedule_related_params, recurrence_info,
    label_resource_id) {
    var deferred = $q.defer();
    if( schedule_related_params.on_refresh_action == 'REPLACELATEST'){
      schedule_related_params.on_refresh_action = 'REPLACE'
    }
    var post_data = {
      params: {
        integration_key: integration_key,
        identity_key: identity_key,
        ds_config: ds_config,
        schedule_related_params: schedule_related_params,
        recurrence_info: recurrence_info
      },
      create_from_cloud: true
    };
    if (label_resource_id) {
      post_data['label_resource_id'] = label_resource_id;
    }
    var post_object = $http.post(config.api.datasourcesRoot, post_data);
    post_object.then(function (response) {
      let data = response.data;
      deferred.resolve(data);
    });

    post_object.catch(function (response) {
      let data = response.data;
      deferred.reject(data);
    });

    return deferred.promise;
  }

  function editDsConfig(integration_key, identity_key, ds_id, schedule_related_params, recurrence_info) {
    var deferred = $q.defer();

    var post_data = {
      params: {
        ds_id: ds_id,
        schedule_related_params: schedule_related_params,
        recurrence_schedule: recurrence_info
      },
    };
    var post_object = $http.post(config.api.editDatasource, post_data);
    post_object.then(function (response) {
      let data = response.data;
      deferred.resolve(data);
    });

    post_object.catch(function (response) {
      let data = response.data;
      deferred.reject(data);
    });

    return deferred.promise;
  }
}

