<template>
  <div class="mm-dataflow-status-action-required-data-updates table-wrapper">
    <mm-row no-gutters class="title-row mm-text--caption-bold p-y-3 p-l-5 p-r-6">
      <mm-col cols="4">
        <mm-checkbox
          v-model="computedAreAllDataUpdatesSelected"
          :disabled="!computedPendingDataUpdates.length"
          :indeterminate="computedIsAnyPendingDataUpdateSelected"
          :label="$t('global.dictionary.source')"
        />
      </mm-col>
      <mm-col cols="4">{{ $t('global.dictionary.destination') }}</mm-col>
      <mm-col cols="2">{{ $t('global.dictionary.type') }}</mm-col>
      <mm-col cols="2">{{ $t('dataflow_status.active_pipelines.pending_rows') }}</mm-col>
    </mm-row>

    <div class="d-flex function-row p-y-2 p-x-5">
      <restrictions-wrapper class="m-l-auto" validate-storage-limit>
        <mm-button
          :label="$t('dataflow_status.active_pipelines.update_data')"
          icon="sync"
          :disabled="!computedIsAnyPendingDataUpdateSelected"
          :loading="isUpdateDataLoading"
          objective="tertiary"
          size="small"
          no-focus-on-click
          @click="updateData"
        />
      </restrictions-wrapper>
    </div>

    <div v-if="!computedPendingDataUpdates.length" class="m-y-3 d-flex justify-content-center">
      <span class="mm-text--caption-regular">{{ $t('global.dictionary.no_data') }}</span>
    </div>

    <div v-else class="list-content">
      <mm-row
        v-for="(pendingDataUpdate, idx) in computedPendingDataUpdates"
        :key="`destination_${pendingDataUpdate.linkId}_${idx}_${listItemKey}`"
        class="mm-text--caption-regular list-item p-t-3 p-l-5 p-r-6"
        :class="{ 'highlighted-item': pendingDataUpdate.destination.highlighted }"
        no-gutters
        @click="toggleRow(pendingDataUpdate)"
      >
        <mm-col cols="4" class="d-flex">
          <mm-checkbox
            v-model="
              dataUpdatesSelection[`${pendingDataUpdate.sources.map((s) => s.opId).join()}_${pendingDataUpdate.opType}`]
            "
            @click="toggleRow(pendingDataUpdate)"
          />
          <div class="overflow-auto">
            <mm-tooltip
              v-for="(source, idx) in pendingDataUpdate.sources"
              :key="`source_${source.linkId}_${idx}`"
              :label="getResourcePath(source.resourceId).join(' / ')"
              wrapper-class="m-b-3"
            >
              <p class="mm-text--caption-regular m-a-0">{{ source.datasetName }}</p>
              <mm-link
                class="m-x-1"
                :label="source.viewName"
                underline
                small
                @click.stop="openResource(source.linkId, source.resourceDatasetId)"
              />
            </mm-tooltip>
          </div>
        </mm-col>
        <mm-col cols="4">
          <mm-tooltip
            v-if="$store.state.resources.resourcesMap[pendingDataUpdate.destination.dependency.resourceId]"
            :label="getResourcePath(pendingDataUpdate.destination.resourceId).join(' / ')"
          >
            <p v-if="pendingDataUpdate.destination.viewName" class="mm-text--caption-regular m-a-0">
              {{ pendingDataUpdate.destination.datasetName }}
            </p>
            <mm-link
              class="m-x-1"
              :label="pendingDataUpdate.destination.viewName || pendingDataUpdate.destination.datasetName"
              underline
              small
              @click.stop="
                openResource(pendingDataUpdate.destination.linkId, pendingDataUpdate.destination.resourceDatasetId)
              "
            />
          </mm-tooltip>
          <mm-tooltip
            :label="getDependencyProjectName(pendingDataUpdate.destination.dependency)"
            v-else-if="
              pendingDataUpdate.destination.dependency.operationName == RESOURCE_DEPENDENCIES.BRANCH_OUT_TO_PROJECT
            "
          >
            <p class="mm-text--caption-regular m-a-0">
              {{ getDependencyProjectName(pendingDataUpdate.destination.dependency) }}
            </p>
          </mm-tooltip>
          <mm-link
            v-else-if="pendingDataUpdate.destination.dependency.operationName == RESOURCE_DEPENDENCIES.LIVE_LINK"
            class="m-r-3 d-block"
            :label="pendingDataUpdate.destination.dependency.url"
            :href="pendingDataUpdate.destination.dependency.url"
            small
            @click.stop=""
          />

          <mm-tooltip v-else :label="getExportTooltip(pendingDataUpdate.destination.dependency)" wrapper-class="p-r-2">
            <div class="d-flex">
              <mm-icon class="m-r-2" :name="getConnector(pendingDataUpdate.destination.dependency.dbType).smallIcon" />
              <span class="mm-text--caption-regular">
                {{ getConnector(pendingDataUpdate.destination.dependency.dbType).name }}
              </span>
            </div>
          </mm-tooltip>
        </mm-col>
        <mm-col cols="2">
          {{
            $tc(
              `global.dictionary.${
                RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP[pendingDataUpdate.opName] ||
                pendingDataUpdate.opName.toLowerCase()
              }`
            )
          }}
        </mm-col>
        <mm-col cols="2">
          {{
            $tc(
              'global.dictionary.rows',
              pendingDataUpdate.destination.rowCount || pendingDataUpdate.sources[0].rowCount,
              {
                rowsQuantity: (
                  pendingDataUpdate.destination.rowCount || pendingDataUpdate.sources[0].rowCount
                ).toLocaleString()
              }
            )
          }}
        </mm-col>
      </mm-row>
    </div>
  </div>
</template>

<script>
//Constants
import {
  RESOURCE_STATUS,
  RESOURCE_DEPENDENCIES,
  RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP,
  EXPORT_CONNECTORS,
  USER_EVENTS,
  getOldAppUrl
} from '@/constants'

//Utils
import { getResourcePath, getDependencyProjectName } from '@/modules/resources/utils'
import { getExportTooltip } from '@/utils/string'

// Dependencies
import Vue from 'vue'

// API
import dataEditorApi from '@/modules/data-editor/api/data-editor.api'

// Components
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

export default {
  name: 'dataflow-status-action-required-data-updates',
  components: { RestrictionsWrapper },
  props: {
    highlightedViewId: [String, Number]
  },
  data: () => ({
    dataUpdatesSelection: {},
    isUpdateDataLoading: false,
    listItemKey: 1
  }),
  created() {
    this.RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP = RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP
    this.RESOURCE_DEPENDENCIES = RESOURCE_DEPENDENCIES
  },
  mounted() {
    if (this.highlightedViewId)
      setTimeout(() => this.$vuetify.goTo('.highlighted-item', { container: '.list-content' }), 100)
  },
  computed: {
    computedPendingDataUpdates() {
      // Mapper for output
      const mapper = (r, dependency) => ({
        resourceId: r?.resourceId,
        linkId: r?.viewId || r?.properties.viewsIds[0],
        viewName: r?.viewId && r.properties.name,
        datasetId: r?.datasetId || r?.properties.dataset.id,
        datasetName: r?.datasetId
          ? r.properties.name
          : r?.viewId && this.$store.getters['resources/getDatasetByViewId'](r.viewId).properties.name,
        rowCount: r?.properties.rowCount,
        opId: dependency?.operationId,
        highlighted: this.highlightedViewId && this.highlightedViewId == r?.viewId,
        dependency
      })

      let pendingDataUpdates = []

      Object.values(this.$store.state.resources.resourcesMap)
        .filter((r) => r.status !== RESOURCE_STATUS.DELETED)
        .forEach((r) => {
          r.dependencies?.out
            ?.filter(
              (dependency) =>
                !this.$store.getters['resources/getAllPendingResources'].some(
                  (resource) => resource[0] == dependency.resourceId
                )
            )
            .forEach((dependency) => {
              if (dependency.dataUpdatePending) {
                const pendingUpdateObject = {
                  opType: dependency.operationType,
                  opName: dependency.operationName,
                  destination: mapper(this.$store.state.resources.resourcesMap[dependency.resourceId], dependency),
                  sources: [mapper(r, dependency)]
                }

                const existingPendingUpdate = pendingDataUpdates.find(
                  (item) => item.destination?.resourceId === pendingUpdateObject.destination?.resourceId
                )

                if (
                  [RESOURCE_DEPENDENCIES.JOIN, RESOURCE_DEPENDENCIES.LOOKUP].includes(dependency.operationName) &&
                  existingPendingUpdate
                ) {
                  existingPendingUpdate.sources.push(...pendingUpdateObject.sources) // Merge arrays
                } else {
                  pendingDataUpdates.push(pendingUpdateObject)
                }
              }
            })
        })

      this.$emit('update:pendingDataUpdatesQuantity', pendingDataUpdates.length)
      return pendingDataUpdates
    },
    computedAreAllDataUpdatesSelected: {
      get() {
        // Stating 'listItemKey' here so that this computed property updates every time this variable changes.
        // https://stackoverflow.com/questions/48700142/vue-js-force-computed-properties-to-recompute

        const dataUpdatesSelectionValues = Object.values(this.dataUpdatesSelection)

        return (
          this.listItemKey &&
          dataUpdatesSelectionValues.length > 0 &&
          dataUpdatesSelectionValues.length == this.computedPendingDataUpdates.length &&
          dataUpdatesSelectionValues.every((value) => value)
        )
      },
      set(isSelected) {
        this.dataUpdatesSelection = this.computedPendingDataUpdates
          .map((du) => `${du.sources.map((s) => s.opId).join()}_${du.opType}`)
          .reduce((o, k) => ({ ...o, [k]: isSelected }), {})
      }
    },
    computedIsAnyPendingDataUpdateSelected() {
      // Stating 'listItemKey' here so that this computed property updates every time this variable changes.
      // https://stackoverflow.com/questions/48700142/vue-js-force-computed-properties-to-recompute
      return this.listItemKey && Object.values(this.dataUpdatesSelection).some((value) => value)
    }
  },
  methods: {
    getConnector(key) {
      return EXPORT_CONNECTORS.find((c) => c.key == key)
    },
    toggleRow(pendingDataUpdate) {
      Vue.set(
        this.dataUpdatesSelection,
        `${pendingDataUpdate.sources.map((s) => s.opId).join()}_${pendingDataUpdate.opType}`,
        !this.dataUpdatesSelection[`${pendingDataUpdate.sources.map((s) => s.opId).join()}_${pendingDataUpdate.opType}`]
      )

      pendingDataUpdate.destination.highlighted = false
      this.listItemKey++
    },
    openResource(viewId, datasetId) {
      this.$userEvents.save(USER_EVENTS.DATAFLOW_STATUS.ACTION_REQUIRED.DATA_UPDATES.OPEN_RESOURCE, { viewId })

      // TODO: remove when angular code is gone
      const redirectUrl = `${getOldAppUrl()}/#/workspaces/${this.$store.state.workspaceId}/projects/${
        this.$store.state.projectId
      }/dataviews/${viewId}`
      if (!datasetId) datasetId = this.$store.getters['resources/getDatasetByViewId'](viewId).datasetId
      window.open(redirectUrl, `_mammoth_ds_${datasetId}`)
    },
    async updateData() {
      this.isUpdateDataLoading = true

      const selectedItemsKeys = Object.keys(this.dataUpdatesSelection).filter((key) => this.dataUpdatesSelection[key])

      let itemsToUpdate = []
      selectedItemsKeys.forEach((key) => {
        const opIds = key.split('_')[0].split(',')
        const opType = key.split('_')[1]
        itemsToUpdate.push(...opIds.map((opId) => ({ opId, opType })))
      })

      this.$userEvents.save(USER_EVENTS.DATAFLOW_STATUS.ACTION_REQUIRED.DATA_UPDATES.UPDATE_DATA, { itemsToUpdate })

      const toggleResponse = await dataEditorApi.toggleRunPendingUpdate(itemsToUpdate, true)
      if (toggleResponse?.skipped?.length)
        this.$toast.show({ content: this.$t('dataflow_status.action_required.skipped_items_error'), status: 'error' })

      this.dataUpdatesSelection = {}
      this.isUpdateDataLoading = false
      this.$emit('submit')
    },
    getResourcePath,
    getExportTooltip,
    getDependencyProjectName
  }
}
</script>

<style lang="scss" scoped>
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';
@import '@mammoth_developer/mm-storybook/src/styles/spacing.scss';

.mm-dataflow-status-action-required-data-updates {
  .title-row {
    ::v-deep .mm-checkbox--label {
      @extend .mm-text--caption-bold;
    }
  }

  .mm-button {
    margin-right: 10px;

    &:not(.mm-button--disabled) {
      color: var(--mm-color--p700) !important;

      ::v-deep svg {
        --mm-icon--color: var(--mm-color--p700) !important;
        --mm-icon--color-hover: var(--mm-color--p700) !important;
      }
    }
  }

  .list-content {
    .list-item {
      border-bottom: 1px solid var(--mm-color--n40);
      cursor: pointer;

      &:hover {
        background: var(--mm-color--n30);
      }

      &.highlighted-item {
        border: solid var(--mm-color--warn800);
        background: var(--mm-color--warn500);
        border-width: 1px 1px 1px 4px;
      }

      .mm-link {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      ::v-deep .mm-tooltip--slot-wrapper {
        @extend .p-r-2;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        cursor: default;

        p,
        div {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
