import Vue from 'vue'
import Vuex from 'vuex'

//Global store
import globalStore from '@/store'

const modules = {}
const stores = require.context('..', true, /\.store\.js$/i)
stores.keys().forEach((filePath) => {
  const moduleName = filePath.split('/').slice(-1)[0].split('.')[0]
  modules[moduleName] = stores(filePath).default
})

Vue.use(Vuex)

export default new Vuex.Store({
  ...globalStore,
  modules
})
