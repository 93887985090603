/**
 * Created by mammoth on 10/12/19.
 */
import * as _ from "lodash-es";
export function reorderFactory(){
  return new ReOrder();

  function ReOrder(){

    /* dep includes all dependencies of items i.e. dependents and dependables
     e.g [6, 1, 2, 5, 0, 4, 3, 7] is the current order of sequencies
     4: [2, 5,0]
     it says the item having sequence of 4 has dependencies on sequencies [2,5,0]
     4 depends on 2 and 0 and 5 depends on 4
     */
    var self = this;
    self._dep = {};
    self._order = [];

    // moves the given item to given index
    this.move = function(item, toIdx){
      let fromIdx = this.indexOf(item);
      let ok = this.validate_move(item, toIdx);
      if(ok){
        this._order.splice(toIdx, 0, ...this._order.splice(fromIdx, 1));
      }
      return ok;
    };

    // moves the given item to the given index - if the item cant be moved exactly to the given index, then move as far as possible - so that
    // dependency is not broken
    this.move_as_far = function(item, toIdx){
      let fromIdx = this.indexOf(item);
      toIdx = this.howFar(item, toIdx);
      this._order.splice(toIdx, 0, ...this._order.splice(fromIdx, 1));
      return toIdx;
    };

    // how far the given item can be moved - returns the toIdx if item can be moved exactly that far.. otherwise return as far as possible index
    this.howFar = function(item, toIdx){
      let fromIdx = this.indexOf(item);
      let dep = this._dep[item];
      // indexes of dependencies
      var indexes = [];
      for(let item of dep){
        indexes.push(this._order.indexOf(item));
      }
      // only include indexes that matter: moving up - include only up dep, moving down - include only down dep
      indexes = indexes.filter(function(it){
        return fromIdx > toIdx ? it < fromIdx: it > fromIdx;
      });
      if(!indexes.length){
        return toIdx;
      }
      var farIndex = fromIdx > toIdx ? Math.max.apply(null, indexes) + 1 : Math.min.apply(null, indexes) - 1;
      // item can move upto farIndex but toIdx is not that much, then return toIdx
      if((fromIdx >= toIdx && farIndex < toIdx) || (fromIdx <= toIdx && farIndex > toIdx)) {
        return toIdx;
      }
      return farIndex;
    };

    // validates if item can ben moved to given index
    this.validate_move = function(item, toIdx, excludeDep=[], provideReason=false){
      /*
      excludeDep: when validating the movement of an item, ignore this list from among the dependencies of the item
       */
      excludeDep = excludeDep.filter(it=> it!=item);
      let fromIdx = this.indexOf(item);
      let dep = this._dep[item];
      dep = dep.filter(it=> !excludeDep.includes(it));

      let isValidMove = true;
      let tasksThatRestrictMovement = [];
      let msg = "";

      // this means item is being moved upwards
      if(fromIdx > toIdx){
        // to check dependency voilation, out of all items, we need only those items which are from index 'fromIdx' to 'toIdx'
        let itemsToCheckForDependency = this._order.slice(toIdx, fromIdx);

        // for every item in itemsToCheckForDependency, check for dependency
        _.forEach(itemsToCheckForDependency, function (item) {
          if(dep.includes(item)){
            tasksThatRestrictMovement.push(item);
          }
        });
        if(tasksThatRestrictMovement.length){
          isValidMove = false;
          let tasksTxt = tasksThatRestrictMovement.length == 1 ? 'Task ' : 'Tasks ';
          msg = "This Task is dependent on " + tasksTxt + tasksThatRestrictMovement.join(", ");
        }
      }
      //this means item is being moved downwards
      else{
        let self = this;
        let itemsToCheckForDependency = self._order.slice(fromIdx+1, toIdx+1);
        // excluding the array excludeDep
        itemsToCheckForDependency = itemsToCheckForDependency.filter(it=> !excludeDep.includes(it));
        _.forEach(itemsToCheckForDependency, function (it) {
          // check if this item is in the dependencies of any other item
          if(self._dep[it].includes(item)){
            tasksThatRestrictMovement.push(it);
          }
        });
        if(tasksThatRestrictMovement.length){
          isValidMove = false;
          let tasksTxt = tasksThatRestrictMovement.length == 1 ? 'Task ' : 'Tasks ';
          let isAre = tasksThatRestrictMovement.length == 1 ? ' is' : ' are';
          msg = tasksTxt + tasksThatRestrictMovement.join(", ") + isAre + " dependent on this task";
        }
      }
      if(provideReason){
        return  [isValidMove, msg];
      }
      else{
        return isValidMove;
      }
    };

    // return index of an item in the class
    this.indexOf = function(item){
      let idx = this._order.indexOf(item);
      if(idx < 0){
        throw "not found: " + item;
      }
      return idx;
    };

    // generator function: when iterating this class, the array '_order' inside this class will actually get iterated
    this.values = function* (){
      yield * this._order;
    }

    // iterator function: it will enable iteration of this class
    [Symbol.iterator] = function(){
      // when iterating this class, the function this.values() will yield values
      return this.values();
    }
  }
}
