/*jslint node: true */

import * as angular from "angular";
/**
 * @ngInject
 */

CustomSummaryCard.$inject = ['eventCallbackManagerFactory', 'utils', 'filterManagerFactory', 'taskDescriber', 'FilterEditHelperFactory', 'c', 'analyticsService'];
export function CustomSummaryCard(eventCallbackManagerFactory, utils, filterManagerFactory, taskDescriber, FilterEditHelperFactory, c, analyticsService){
  return Card;

  function Card(dataview){
    var card = this;
    card.at = 99999;
    card.dataviewId = dataview.id;
    card.type = 'custom';
    card.condition = null;
    card.id = utils.getRandomString(10);
    card.isActive = false;
    card.setActive = setActive;
    card.setCondition = setCondition;
    card.describe = describe;
    card.deselect = deselect;
    card.query = query;
    card.filterDescription = null;
    card.openCondition = openCondition;
    card.changeRenderCb = (renderType) => {
    };
    card.initial_condition = null;
    var cardUpdateEvent = new eventCallbackManagerFactory('CardUpdateEvent-' + card.id);
    card.onSelectionChanged = cardUpdateEvent.add_callback;

    function query(){
      var m = utils.metadata.add_type_to_display_name(dataview.metadata);
      card.filterEditHelper = new FilterEditHelperFactory();
      card.filterManager = filterManagerFactory.get_manager({
        metadata: m,
        dataviewId: dataview.id,
        teardownOptions: {
          teardown_callback: _conditionCb,
          allow_teardown: true
        }
      });
    }


    function openCondition(){
      card.filterManager.metadata = utils.metadata.add_type_to_display_name(dataview.metadata);
      // filterUI.open(card.filterManager).then(_conditionCb);
      card.filterEditHelper.open(card.filterManager).then(_conditionCb);
    }

    function _conditionCb(){
      card.filterManager.metadata = utils.metadata.add_type_to_display_name(dataview.metadata);
      var condition = card.filterManager.getParam();
      if(condition && !angular.equals(condition, {})){
        cardUpdateEvent.fire_event(condition);
      }
      else{
        if(card.isActive){
          analyticsService.userEventTrack(c.userEvents.exploreCards.filterRemovedFromCustomExploreCard,
            {
              eventOrigin: 'dataview.customExploreCard'
            });
          cardUpdateEvent.fire_event();
        }
      }
      card.filterDescription = taskDescriber.describeCondition(card.filterManager.metadata, card.filterManager.getParam());
    }

    function setActive(value){
      card.isActive = value;
    }

    function setCondition(condition){
      if(!angular.equals(card.initial_condition, condition)){
        card.initial_condition = condition;
        card.query();
      }
    }

    function describe(){
      return card.filterDescription;

    }

    function deselect(){
      card.isActive = false;
      card.filterManager.remove_root_condition();
    }
  }
}
