import * as angular from 'angular';
import { dateInputFormatController } from './dateInputFormatter.controller';
import { dateInputFormat, adjustDateInputDropdown } from './dateInputFormatter.directive';
import { CurrentDateTimeController } from './currentDateTime.controller';
import { currentDateTimeInput } from './currentDateTime.directive';

/**
 * Module containing input formatter for date values in text/numeric type column
 */

export let dateModule = angular.module('app.common.date', []);
dateModule.controller('currentDateTimeController', CurrentDateTimeController);
dateModule.controller('dateInputFormatController', dateInputFormatController);
dateModule.directive('currentDateTimeInput', currentDateTimeInput);
dateModule.directive('dateInputFormat', dateInputFormat);
dateModule.directive('adjustDateInputDropdown', adjustDateInputDropdown);

