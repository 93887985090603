export function saveFile(content, name, type = 'text/json') {
  const a = document.createElement('a')
  a.download = name
  const file = new Blob([content], { type })
  a.href = URL.createObjectURL(file)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

// This function will trigger the browser pop-up window for the user to pick file(s) to upload
// For the DSM custom file upload UI, the mm-file-uploader component should be used
export function readFiles(filesContainer, acceptedExtensions, isMultiple, readAsDataURL) {
  let input = document.createElement('input')
  input.type = 'file'
  input.accept = acceptedExtensions.map((e) => `.${e}`)
  input.multiple = isMultiple
  input.style.display = 'none'
  document.body.appendChild(input)

  input.click()

  input.addEventListener('change', (event) => {
    Array.prototype.forEach.call(event.target.files, (file) => {
      const fr = new FileReader()
      fr.file = file
      fr.addEventListener('load', (event) => {
        const loadedFile = {
          name: event.target.file.name,
          content: event.target.result,
          targetFile: event.target.file,
          extension: event.target.file.name.split('.').pop()
        }
        filesContainer.push(loadedFile)
      })
      if (readAsDataURL) fr.readAsDataURL(file)
      else fr.readAsText(file)
    })
    input.remove()
  })
}

export const readFile = (file, readAsDataURL) =>
  new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onload = () => resolve(fileReader.result)
    if (readAsDataURL) fileReader.readAsDataURL(file)
    else fileReader.readAsText(file)
  })
