import * as dateformat from 'dateformat';

export class CurrentDateTimeController {
  public options: any = [
    { text: 'DateTime', val: 'datetime' },
    { text: 'Current Date (UTC)', val: 'currentDate' },
    { text: 'Current Time (UTC)', val: 'currentTime' },
  ]
  public dateTimeValue;
  public dateTimeDefaultValue = dateformat(new Date(), 'yyyy-mm-dd') + ' 00:00:00';
  public option: any = this.options[0];
  public ngModel: any
  static $inject = ['utils', '$timeout']
  public $onInit: () => void
  public constructor() {
    this.$onInit = function () {
      if (this.ngModel == 'currentDate') {
         this.option = this.options[1]
        } else if (this.ngModel == 'currentTime') {
        this.option = this.options[2]
      }
      else { 
        this.option = this.options[0]
        this.dateTimeValue = this.ngModel
      }
      this.updateModel()
    }
  }
  updateModel() {
    if (['currentDate', 'currentTime'].includes(this.option.val)){
        this.ngModel = this.option.val
    }else{
        this.ngModel = this.dateTimeValue
    }
  }
  handleOptionsChange() {
    this.updateModel()
  }
}
