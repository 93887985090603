// Utils
import { flattenObject } from '@/utils/string'
import { DATA_TYPES_MAP } from '@/constants'
/**
 *
 * @param {*} data The data, coming from backend, that needs to be transformed into a frontend payload
 * @returns a payload suited to frontend's needs. The frontend relies on the returned result of this transformation
 */

export function transformUsers({ data }, workspaceId) {
  let tempUsers
  if (data.self) tempUsers = [data.self]
  else if (data.users) tempUsers = [...(data.users?.users || data.users || []), ...(data.invites || [])]
  else if (data.active_users) tempUsers = data.active_users

  return tempUsers.map((u) => ({
    firstName: u.first_name,
    lastName: u.last_name,
    email: u.email,
    name: u.name || (u.first_name ? `${u.first_name} ${u.last_name}` : ''),
    id: u.id,
    roles: u.user_roles || (workspaceId && { workspaces: { [workspaceId]: u.role } }),
    lastTimeActive: u.last_active || u.last_active_date,
    isInvited: ['invited', 'expired'].includes(u.status),
    status: u.status,
    createdAt: u.created_at,
    profilePic: u.profile_link
  }))
}

export function transformWorkspaces(requestResponse) {
  const workspaces = requestResponse.data?.workspaces || [requestResponse.data?.workspace]
  return workspaces?.map((w) => ({
    id: w.id,
    name: w.name,
    subscription: w.subscription,
    status: w.status,
    userPermissions: w.user_permissions,
    projects: w.projects?.map((p) => ({
      id: p.id,
      name: p.name,
      subscribed: p.subscribed,
      updatedAt: p.updated_at,
      datasetQuantity: p.ds_count
    })),
    workspaceUsers: w.user_count
  }))
}

export function transformSmsDetails({ data }) {
  if (!data) return

  const billingAddress = data.sms_details?.customer?.billing_address
  const subscription = data.sms_details?.subscription

  const transformPlan = (p) => ({
    id: p.id,
    name: p.name,
    description: p.description,
    monthlyPrice: p.price / 100,
    isSelfServe: p.cf_self_serve == 'Yes',
    startingUsersQuantity: p.cf_number_of_users,
    areNewUsersAllowed: p.cf_are_new_users_allowed == 'Yes',
    startingStorage: (p.cf_starting_row_count_in_millions || 0) * 1000000,
    isAdditionalStorageAllowed: p.cf_is_additional_storage_allowed == 'Yes',
    maxStorage: (p.cf_max_row_count_in_millions || 0) * 1000000,
    processingCapacity: p.cf_processing_capacity?.toLowerCase(),
    isCSVExcelImportEnabled: p.cf_import_csv_excel == 'Yes',
    isImportPublicDatasetsEnabled: p.cf_import_public_datasets == 'Yes',
    startingConnectorQuantity: p.cf_starting_connector_quantity,
    minimumPeriodicityForDataPulls:
      !p.cf_minimum_periodicity || p.cf_minimum_periodicity == 'None' ? '' : p.cf_minimum_periodicity.toLowerCase(),
    areAdditionalConnectorsAllowed: p.cf_are_additional_connectors_allowed == 'Yes',
    areAllTransformFeaturesEnabled: p.cf_all_transform_features == 'Yes',
    isCSVExcelExportEnabled: p.cf_export_csv_excel == 'Yes',
    isCreateDbEnabled: p.cf_create_db == 'Yes',
    isLiveLinkEnabled: p.cf_live_csv_link == 'Yes',
    isPrivateViewEnabled: p.cf_private_view == 'Yes',
    arePrivateViewersPerViewUnlimited: p.cf_private_view == 'Yes',
    isPublicViewEnabled: p.cf_public_view_access == 'Yes',
    isVersionControlEnabled: p.cf_version_control == 'Yes',
    areAuditReportsEnabled: p.cf_audit_reports == 'Yes',
    areAlertsEnabled: p.cf_alerts == 'Yes',
    isDataAccessControlEnabled: p.cf_data_access_control == 'Yes',
    hasCustomerSuccessManager: p.cf_customer_success_manager == 'Yes',
    isWebhookEnabled: p.cf_webhooks == 'Yes',
    allowedConnectorQuantity:
      (p.cf_starting_connector_quantity || 0) +
      (transformAddons(subscription?.addons)?.additionalConnectors.quantity || 0),
    allowedUsersQuantity:
      (p.cf_number_of_users || 0) + (transformAddons(subscription?.addons)?.additionalUsers.quantity || 0)
  })

  const transformAddons = (addons) => {
    if (!addons) return

    const additionalUserAddon = addons.find((a) => a.id == 'additional-user')
    const additionalConnectorAddon = addons.find((a) => a.id == 'additional-data-connector')
    const additionalStorage = addons.find((a) => a.id == 'add-on-rows')

    return {
      additionalConnectors: {
        quantity: additionalConnectorAddon?.quantity || 0,
        price: additionalConnectorAddon?.price / 100
      },
      additionalUsers: { quantity: additionalUserAddon?.quantity || 0, price: additionalUserAddon?.price / 100 },
      additionalStorage: {
        quantity: additionalStorage?.quantity || 0,
        price: additionalStorage?.price / 100,
        storagePerUnit: additionalStorage?.meta_data?.unit_size_for_additional_rows
      }
    }
  }

  return {
    subscriptionId: subscription?.id,
    currentPlan: data.current_plan && transformPlan(data.current_plan),
    status: subscription?.status,
    trialEnd: subscription?.trial_end * 1000,
    addons: subscription?.addons && transformAddons(subscription.addons),
    customerId: data.sms_details?.customer?.id,
    billing: {
      nextPaymentDate: subscription?.next_billing_at * 1000,
      maskedCardNumber: data.sms_details?.card?.masked_number,
      fullAddress: billingAddress
        ? `${billingAddress.line1} ${billingAddress.line2 || ''} ${billingAddress.city} ${billingAddress.country}`
        : ''
    },
    yearlyDiscount: data.available_coupons?.find((c) => c.id == 'YEARLYDISCOUNT')?.discount_percentage,
    availablePlans: data.available_plans?.map((p) => transformPlan(p)),
    availableAddons: transformAddons(data.available_addons)
  }
}

export function transformUsageInfo(info) {
  const { ROW_COUNT_UTILIZED, CURRENT_ROW_COUNT_ALLOWED, PLAN_ROW_COUNT_VALUE } = info?.data.workspace || {}

  return {
    workspace: {
      currentUsage: ROW_COUNT_UTILIZED,
      allowedUsage: CURRENT_ROW_COUNT_ALLOWED || PLAN_ROW_COUNT_VALUE
    }
  }
}

export function transformConnectors({ data }) {
  return data?.integrations.map((connector) => ({
    key: connector.key,
    isPremium: !!connector.is_paid,
    isAcquired: !!connector.is_added
  }))
}

export function transformOnboardingFlags({ data }) {
  return flattenObject(data)
}

export function transformStep(step) {
  return {
    id: step.id,
    viewId: step.ws_id,
    type: step.stepType,
    dataSync: step.data_pass_through,
    rowCount: step.row_count || step.desc?.row_count,
    sequence: step.sequence,
    handlerType: step.handler_type
  }
}

export function transformMetadata(data) {
  return data?.map((col) => ({
    name: col.display_name,
    id: col.internal_name,
    order: col.order,
    type: DATA_TYPES_MAP[col.type]
  }))
}
