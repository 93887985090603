
/**
 * @ngInject
 */
limitManagerFactory.$inject = ['c', 'sortManagerFactory', 'utils'];
export function limitManagerFactory(c, sortManagerFactory, utils) {
  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new limitManager(options);
  }

  function limitManager(options) {
    var self = this;
    var metadata = options.metadata;
    self.metadata = metadata;
    self.validate = validate;
    self.handlePasteParams = handlePasteParams;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap;
    self.getParam = getParam;
    self.setParam = setParam;

    self.param = {
      "LIMIT": {
        "LIMIT": undefined,
        "BOTTOM": false
      }
    };
    self.top_bottom_select = [{d:'Top', v:false}, {d: 'Bottom', v:true}];

    options.mandatory_sort = false;

    self.sortManager = sortManagerFactory.get_manager(options);

    function getParam() {
      var sortParams = self.sortManager.getParam();
      if (sortParams) {
        self.param.ORDER_BY = sortParams;
      } else {
        delete self.param.ORDER_BY;
      }
      if(options.context.hasOwnProperty('sequence')){
        self.param['SEQUENCE_NUMBER'] = options.context.sequence;
      }
      return self.param;
    }

    function handlePasteParams(taskInfo){
      /** Update params with suitable replacement columns, based on display name*/

      var params = taskInfo.params;
      if (params.hasOwnProperty('ORDER_BY')){
        for(const index in params.ORDER_BY){
          if (params.ORDER_BY[index].length = 2){
            utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.ORDER_BY[index], 0, taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
          }
        }
      }
      return params;
    }

    function setParam(param) {
      self.param = param;
      self.sortManager.setParam(param.ORDER_BY);
    }

    function validate() {
      return self.sortManager.validate();
    }
  }
}
