import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';

insertManagerFactory.$inject = ['$window','destinationColumnManagerFactory', 'filterManagerFactory', 'c', 'filterUI', 'utils', 'moment',
  'FilterEditHelperFactory', 'eventCallbackManagerFactory'];
export function insertManagerFactory($window, destinationColumnManagerFactory, filterManagerFactory, c, filterUI, utils, moment,
                                     FilterEditHelperFactory, eventCallbackManagerFactory) {
  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new InsertManager(options);
  }

  function InsertManager(options) {
    var self = this;
    var metadata = options.metadata, taskUtils = options.taskUtils;
    self.browser = $window.bowser.name
    self.metadata = metadata;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.validate = validate;
    self.getParam = getParam;
    self.setParam = setParam;
    self.handlePasteParams = handlePasteParams;
    self.valueConditionPairs = [];
    self.addValueConditionPair = addValueConditionPair;
    self.removeValueConditionPair = removeValueConditionPair;
    self.context = options.context

    self.destinationManager = destinationColumnManagerFactory.get_manager(
      {metadata: metadata, allowedTypes: [c.text, c.numeric, c.date], taskUtils: options.taskUtils, isDestinationFormatterVisible: true}
    );
    self.destinationManager.show_new_column_type_selector = true;

    addValueConditionPair();

    function getParam() {
      var set_param = {}
      var valuesParam = [];
      $.each(self.valueConditionPairs, function(i, vp){
        valuesParam.push(vp.getParam());
      });
      var insertParam = {VALUES: valuesParam};
      var destination_params = self.destinationManager.getParam()
      if (destination_params.hasOwnProperty('AS') && self.context.inEditMode==true &&  self.context.task){
        utils.sanatizeParamForDuplicateCols(destination_params['AS'], 'INTERNAL_NAME', self.context.task)
      }
      angular.merge(insertParam, destination_params);
      set_param = {SET: insertParam, VERSION: 2}
      if (self.context.hasOwnProperty('sequence')){
        set_param['SEQUENCE_NUMBER']  = self.context.sequence
      }
      return set_param
    }
    function handlePasteParams(taskInfo){
      /** Update destination and condition params with suitable replacement columns, based on display name*/
      var params = taskInfo.params
      if (params.SET.hasOwnProperty('DESTINATION')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.SET, 'DESTINATION', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        //Update destination manager metadata
        self.destinationManager.metadata = self.metadata
        self.destinationManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(self.metadata)

      }
      for (const index in params.SET.VALUES){
        var valuePair = params.SET.VALUES[index]
        if (valuePair.hasOwnProperty('CONDITION')){
          var columnUsedInCondition = utils.get_input_columns(_.cloneDeep(valuePair.CONDITION))
          if (columnUsedInCondition) {
            utils.metadata.findReplaceColumnsInCondition(self.metadata, self.displayNameAndTypeToColumnMap, params.SET.VALUES[index], columnUsedInCondition, taskInfo)
          }
        }
      }
      return params

    }
    function setParam(param) {
      var insertParam = param.SET;
      self.destinationManager.setParam(insertParam);
      self.valueConditionPairs = [];
      $.each(insertParam.VALUES, function(i, ip){
        var valueConditionPair = new ValueConditionPair();
        valueConditionPair.setParam(ip)
        self.valueConditionPairs.push(valueConditionPair);
      });
    }

    function validate() {
      if (self.valueConditionPairs.length > 0) {
        var is_valid = true;
        for (var i = 0; i < self.valueConditionPairs.length; i++) {
          if(self.valueConditionPairs[i].filterManager.condition){
            var vaidationResult = self.valueConditionPairs[i].filterManager.condition.validate();
            is_valid = is_valid && vaidationResult;
          }
        }
        is_valid = is_valid && self.destinationManager.validate()
        return is_valid;
      }
      return true;
    }

    function addValueConditionPair(){
      let vcp = new ValueConditionPair();
      self.valueConditionPairs.push(vcp);
    }

    function removeValueConditionPair(valueConditionPair){
      self.valueConditionPairs = $.grep(self.valueConditionPairs, function(vcp){
        return vcp != valueConditionPair;
      })
    }

    function ValueConditionPair(){
      var vcp = this;
      vcp.filterEditHelper = new FilterEditHelperFactory();
      vcp.vId = utils.string.random();
      vcp.filterManager = filterManagerFactory.get_manager({
        metadata: metadata,
        dataviewId: options.context.dataview.id,
        context: options.context,
        originalSequenceNumber: options.originalSequenceNumber
      });
      vcp.filterManager.conditionRemovedEvent = new eventCallbackManagerFactory('conditionRemovedEvent_');
      vcp.value = null;
      vcp.applyToAll = true;
      vcp.filterManager.applyToAll = vcp.applyToAll;

      vcp.openConditionMenu = openConditionMenu;
      vcp.openConditionModal = openConditionModal;
      vcp.filterEditHelper.onMaximizeFilterPopoverToModal('maximizeFilterPopoverToModal',  vcp.openConditionModal)
      vcp.getParam = getParam;
      vcp.setParam = setParam;
      self.destinationManager.onChangeColumnTypeEvent('i' + vcp.vId, _handleTypeChange);
      vcp.filterManager.conditionRemovedEvent.add_callback("condition_removed", function() {
      vcp.applyToAll = true;
      });


      function _setApplyToAll(condition, eventType=null, currenApplyToAll=null){
        /*
         * eventType: was the condition applied or cancelled. Possible values are
         * 'apply', cancel' or null
         */
        if(!condition){
          condition = {};
        }
        // if the condition was applied, just check whether the condition is empty or not
        // if condition is empty, that means label value will be applied to all rows
        if(eventType == 'apply' || eventType == null){
          vcp.applyToAll = Object.keys(condition).length == 0;
        }
        // if the condition was not applied(cancel button was hit), then set applyToAll the value
        // of currenApplyToAll so that value of applyToAll will
        else if(eventType == 'cancel'){
          // if currenApplyToAll is true, that means label value will be applied to all rows
          // so we dont need condition, setting it to null
          if(currenApplyToAll){
            vcp.filterManager.condition = null;
          }
          vcp.applyToAll = currenApplyToAll;
        }
      }

      function openConditionMenu(){
        // at the time of opening the condition menu, is the label value being
        // applied to all rows or not
        let currenApplyToAll = false;
        // if the label value has not condition in it, that means value is being applied
        // to all the rows
        if(vcp.filterManager.condition == null){
          currenApplyToAll = true;
        }
        let currentIndex = self.valueConditionPairs.indexOf(vcp);
        if(self.valueConditionPairs.length > 1 &&  currentIndex === self.valueConditionPairs.length - 1){
          if(vcp.filterManager.condition === null){
            let previousValueConditionPair = self.valueConditionPairs[currentIndex - 1];
            vcp.filterManager.setParam(previousValueConditionPair.filterManager.getParam());
          }
        }
        vcp.filterEditHelper.open(vcp.filterManager).then(apply, cancel);

        // condition is applied
        function apply(){
          _setApplyToAll(vcp.filterManager.getParam(), 'apply', currenApplyToAll);
        }

        // condition is not applied - cancel button was hit
        function cancel(data=null){
          if (!data){
            _setApplyToAll(vcp.filterManager.getParam(), 'cancel', currenApplyToAll)
          }
        }
      }

      function openConditionModal(param=null){
        filterUI.open(vcp.filterManager, param);
      }

      function getParam(){
        var param: any
        if(vcp.value == 'currentDate') {
          param = { PROVIDER_TYPE: 'EXPRESSION', PROVIDER: "__DATE__"}
        }else if(vcp.value == 'currentTime') {
          param = { PROVIDER_TYPE: 'EXPRESSION', PROVIDER: "__TIME__"}
        }else param = { PROVIDER_TYPE: 'FIXED', PROVIDER: vcp.value};

        if(vcp.filterManager.condition) {
          param.CONDITION = vcp.filterManager.getParam();
        }
        return param;

      }

      function setParam(param){
        if (param.PROVIDER_TYPE == 'EXPRESSION') {
          if(param.PROVIDER == '__DATE__'){
            vcp.value = 'currentDate'
          }else if (param.PROVIDER == '__TIME__'){
            vcp.value = 'currentTime'
          }
        }
        else if (param.PROVIDER_TYPE == 'FIXED') vcp.value = param.PROVIDER;
        if (param.hasOwnProperty('CONDITION')) {
          vcp.filterManager.setParam(param.CONDITION, param?.EXECUTION_TIMESTAMP);
        }
        _setApplyToAll(param.CONDITION);
      }

      function isValidDate(x: any) {
        let d:any = x;
        let isF = isFinite(d);
        return d instanceof Date && isF;
      }

      function _handleTypeChange(ctype){
          if (ctype == 'TEXT' || !ctype) {
              vcp.value = (vcp.value || vcp.value === 0) ? String(vcp.value) : null;
          }
      }
    }

  }
}
