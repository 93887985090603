/**
 * Created by ranjith on 15/11/18.
 */


stepOpenMenuItem.$inject = [];
export function stepOpenMenuItem(){
  return {
    templateUrl: 'stepOpenMenu.tpl.html',
    replace: true
  }
}

taskStepMenuItem.$inject = [];
export function taskStepMenuItem(){
  return {
    templateUrl: 'taskStepMenuItem.tpl.html',
    replace: true
  }
}

actionStepMenuItem.$inject = [];
export function actionStepMenuItem(){
  return {
    templateUrl: 'actionStepMenuItem.tpl.html',
    replace: true
  }
}



taskCardPipelineItem.$inject = [];
export function taskCardPipelineItem(){
  return {
    templateUrl: 'task.card.pipeline.item.tpl.html',
    replace: true
  }
}

actionCardPipelineItem.$inject = [];
export function actionCardPipelineItem() {
  return {
    templateUrl: 'action.card.pipeline.item.tpl.html',
    replace: true
  }
}


// scroll-up-if-not-completely-visible-on-click

scrollUpIfNotCompletelyVisibleOnClick.$inject = [];
export function scrollUpIfNotCompletelyVisibleOnClick() {
  return {
    link: function (scope, element, attrs) {
      $(element).on('click', _clickCb);
      let scrollIntoViewOps = {
        duration: 100,
        direction: 'vertical',
        viewPadding: {x: 60, y: 60}
      };
      function _clickCb() {
        $(element).scrollintoview(scrollIntoViewOps);
      }
    }
  }
}
