
import * as CodeMirror from 'codemirror';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/display/placeholder';

/**
 * @ngInject
 */
codeMirrorEditorDefiner.$inject = ['mammothExpressionHelperFactory', '$timeout'];
export function codeMirrorEditorDefiner(mammothExpressionHelperFactory, $timeout){
  var type_css_map = {
    operator: 'operator',
    column: 'column',
    metric: 'metric',
    brackets: 'bracket',
    "function": 'function',
    numeral: "number"
  };

  return {
    defineModeMime: defineModeMime,
    registerHintHelper: registerHintHelper,
    create: create,
    getShowHintFunction: getShowHintFunction,
    getTextInserter: getTextInserter
  };

  function defineModeMime(modeName, mimeName,  expression_helper){
    /**
     * tokenises a given string and returns the type of the last token
     *
     * @param text
     * @param nxtch
     * @returns {*}
     */
    function return_last_token_type(text, nxtch) {
      if(text == '(' || text == ')'){
        return 'operator';
      }
      return expression_helper.get_last_token_type(text, nxtch)
    }
    CodeMirror.defineMode(modeName, function () {
      return {
        /**
         * takes the code mirror stream and advances it till a token type can be determined
         * When the token type is determined, will return the right object class to use on the token
         * The classes are defined in 2_ws_codemirror.css in the codebase
         *
         * It will return error class if the token can not be determined and the token is not the last token in the stream
         *
         * If you need to understand all this, you should have an idea of how code mirror works and how to define a mode in it.
         *
         * @param stream
         * @returns {*}
         */
        token: function (stream) {
          if (stream.eatSpace()) return null;
          var text = '';
          var seperators = [].concat(
            mammothExpressionHelperFactory.operators,
            mammothExpressionHelperFactory.brackets
          );
          let quoteEncountered = false;
          let prevChar = null;
          while (!stream.eol()) {
            // the idea is to keep seeking the text till a operator is encountered or end of string is encountered
            var ch = stream.next();
            var nxtch = stream.peek();
            text += ch;
            if (ch == '"'){
              if(!quoteEncountered){
                quoteEncountered = true;
              }
              else{
                if(prevChar !== '\\'){
                    break;
                }
              }
            }
            if(!quoteEncountered){
              if (seperators.indexOf(ch) != -1 || !nxtch || seperators.indexOf(nxtch) != -1) {
                break;
              }
            }
            prevChar = ch;
          }
          var token_type = return_last_token_type(text, stream.peek());
          if (token_type) {
            // check if the text has whitespace characters.
            // if so back up cursor by the number of whitespace characters
            var i = text.length - 1;
            while (text[i] == ' ') {
              stream.backUp(1);
              i--;
            }

            if(text == '(' || text == ')'){
              return 'bracket';
            }

            return type_css_map[token_type]
          }
          else if (!stream.eol()) {
            return "error";
          }
          else {
            stream.skipToEnd()
            return 'last-unmatched';
          }
        }
      }
    });
     //Define a new mime type. Why not!
    CodeMirror.defineMIME(mimeName, modeName);
  }

  function registerHintHelper(modeName, expression_helper){
    CodeMirror.registerHelper("hint", modeName, function(editor){
      var cur = editor.getCursor();
      var curLine = editor.getLine(cur.line);
      var text = curLine.substring(0, cur.ch);
      var hintInfo = expression_helper.get_hints(text);
      var start_position, end_position;

      if (hintInfo.partial_match){
        if(hintInfo.last_token){
          start_position = CodeMirror.Pos(cur.line, cur.ch - hintInfo.last_token.length);
        }
        else{
          start_position = CodeMirror.Pos(cur.line, cur.ch)
        }
        end_position = CodeMirror.Pos(cur.line, cur.ch)
      }
      else{
        start_position = CodeMirror.Pos(cur.line, cur.ch)
      }

      $.each(hintInfo.options, function(i, opt){
        opt.render = function(ele, cm, data){
          var displayText = opt.text;
          if(opt.type == 'function'){
            var funcArgType = expression_helper.get_function_type(opt.text);
            opt.text += '('; // add a bracket
            if(funcArgType == mammothExpressionHelperFactory.funcArgTypes.zero){
              displayText += '()';
              opt.text += ')'; // add a bracket
            }
            else if(funcArgType == mammothExpressionHelperFactory.funcArgTypes.columnSingle){
              displayText += '(<span class="column">Column</span>)';
            }
            else{
              displayText += '(<span class="expression">expression</span>)';
            }

          }
          var className = type_css_map[opt.type];
          var hintHtml = '<div class="'+ className + '">' + displayText + '</div>';
          $(ele).append(hintHtml);
        }
      });

      return {list: hintInfo.options, from: start_position, to: end_position}
    });
  }

  function create(element, modeName, placeHolder){
    return CodeMirror(element, {
      mode: modeName,
      value: '',
      lineWrapping: true,
      completeSingle: false,
      matchBrackets: true,
      singleCursorHeightPerLine: true,
      autoCloseBrackets: true,
      autoFocus: true,
      placeholder: placeHolder
    });
  }

  function getShowHintFunction(editor, modeName){
    return function() {
     editor.showHint({
        hint: CodeMirror.hint[modeName],
        completeSingle: false,
        completeOnSingleClick: true
      });
    }
  }

  function getTextInserter(editor){
    return function (text) {
      var cursor_position = editor.getCursor().ch;
      var editor_text = editor.getValue();
      var new_text = editor_text.substring(0, cursor_position) + text + editor_text.substring(cursor_position, editor_text.length);
      editor.setValue(new_text);
      $timeout(function(){
        editor.setCursor(CodeMirror.Pos(0, cursor_position + text.length));
        editor.focus();
      }, 100)
    }
  }

}
