<template>
  <mm-modal
    v-model="computedIsModalOpen"
    :title="$t(projectId ? 'project.rename' : 'project.create_input_title')"
    width="480"
  >
    <mm-text-input
      v-model="projectName"
      class="m-t-7"
      style="min-width: 400px"
      ref="projectNameInput"
      :placeholder="$t(projectId ? 'project.rename_input_placeholder' : 'project.create_input_placeholder')"
      :inputAttrs="{ maxlength: 55 }"
      @keyup.enter="submit"
    />

    <template #actions>
      <mm-button :label="$t('global.dictionary.cancel')" objective="secondary" @click="computedIsModalOpen = false" />
      <mm-button
        class="m-l-3"
        :label="$t(projectId ? 'project.rename' : 'project.create')"
        :disabled="!projectName"
        :loading="isLoading"
        @click="submit"
      />
    </template>
  </mm-modal>
</template>

<script>
import projectApi from '@/modules/project/api/project.api.js'

export default {
  name: 'project-modal-name',
  props: {
    value: Boolean,
    projectId: Number
  },
  data: () => ({
    projectName: '',
    isLoading: false
  }),
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    computedIsModalOpen: {
      handler(val) {
        if (val) {
          if (this.projectId) this.projectName = this.$store.getters.getProjectById(this.projectId).name
          else this.projectName = ''
          setTimeout(() => this.$refs.projectNameInput.focusInputField(), 100)
        }
      },
      immediate: true
    }
  },
  methods: {
    submit() {
      if (!this.projectName) return
      this.isLoading = true

      if (this.projectId) this.rename()
      else this.add()
    },
    async add() {
      const project = await projectApi.addProject(this.projectName)

      this.isLoading = false

      if (!project?.id) {
        this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })
        return
      }

      this.$store.commit('addProject', { ...project, subscribed: true })
      this.$store.dispatch('getWorkspaces', { invalidateCache: true })
      this.$store.dispatch('getCurrentUser', { invalidateCache: true })

      this.$emit('submit', project)
      this.computedIsModalOpen = false
    },
    async rename() {
      const response = await projectApi.renameProject(this.projectId, this.projectName)

      this.isLoading = false

      if (response) {
        this.$emit('submit', this.projectName)
        this.$store.commit('setProjectName', { projectId: this.projectId, newName: this.projectName })
        this.$toast.show(this.$t('project.rename_toast_success', { projectName: this.projectName }))
        this.computedIsModalOpen = false
      } else this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })
    }
  }
}
</script>
