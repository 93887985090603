/**
 * @ngInject
 * @returns {{openByDsId: openByDsId}}
 */
csvDataService.$inject = ['config', '$resource', '$q', '$log', '$timeout'];
export function csvDataService(config, $resource, $q, $log, $timeout) {

  return {
    getByDsId: getByDsId,
    save: save,
    cancel: cancel
  };


  function getByDsId(ds_id){
    var deferred = $q.defer();
    var res = $resource(config.api.dsRawData, {dataset_id: ds_id});
    res.dataset_id = ds_id;
    res.get(null, deferred.resolve, deferred.reject);

    return deferred.promise;
  }

  function save(ds_id, has_header, initial_skip_count, date_format, delimiter, skip_auto_process_check, preview_mode){
    var deferred = $q.defer();
    var res = new $resource(config.api.dsRawData, {dataset_id: ds_id});
    var postData = {
      has_header: has_header,
      initial_skip_count: initial_skip_count,
      date_format: date_format,
      delimiter: delimiter,
      skip_auto_process_check: !!(skip_auto_process_check),
      preview_mode: !!(preview_mode)
    };
    res.save(postData).$promise.then(async_callback, deferred.reject);

    function async_callback(data) {

      if (data.hasOwnProperty('STATUS') && data.STATUS === 'FAILURE') {
        deferred.resolve(data);
      }
      if (preview_mode) {
        var async_request_id = data.request_id;
        $timeout(_query, 1000);
      }
      else {
        deferred.resolve(data);
      }
      function _query() {
        var asyncResponse = new $resource(config.api.asyncRequest, {'request_id': async_request_id});
        asyncResponse.get(_successQryCb);

        function _successQryCb(data) {
          if (data.STATUS === 'PROCESSING') {
            $timeout(_query, 1000);
          }
          else if (data.STATUS === 'FAILURE') {
            deferred.reject(data);
          }
          else {
            deferred.resolve(data);
          }
        }
      }
    }

    return deferred.promise;
  }

  function cancel(ds_id) {
    var deferred = $q.defer();
    var res = new $resource(config.api.dsRawData, {dataset_id: ds_id});
    var postData = {cancel: true};
    res.save(postData).$promise.then(deferred.resolve, deferred.reject);

  }
}

