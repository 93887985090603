import {localService} from './local.service';
import * as CryptoJS from 'crypto-js'

ClipboardService.$inject = ['$q','$resource', 'config', 'localService'];

export function ClipboardService ($q, $resource, config, localService){
  var service =  {
    get: get,
    set: set,
    clear: clear,
    isEmpty: isEmpty
  };

  function isEmpty(){
    var isEmpty = !localService.storage.hasOwnProperty('clipboardData') || (localService.storage.hasOwnProperty('clipboardData') && localService.storage.clipboardData == 'undefined')
    return isEmpty
  }
  function get() {
    var deferred = $q.defer();
    if (localService.storage.hasOwnProperty("clipboardData") && localService.storage.clipboardData!="undefined" ) {
      const decrypted = CryptoJS.AES.decrypt(localService.storage.clipboardData, '')
      let data = CryptoJS.enc.Utf8.stringify(decrypted)
      deferred.resolve(JSON.parse(data));
    } else {
      var clipboardAPI = $resource(config.api.clipboard);
      clipboardAPI.get().$promise.then(success_callback, failure_callback);
    }
    function success_callback(data) {
      localService.storage.clipboardData = CryptoJS.AES.encrypt(JSON.stringify(data['clipboard_data']), '').toString();
      deferred.resolve(data['clipboard_data']);
    }
    function failure_callback(data) {
      console.error("Failed to get clipboard");
      deferred.reject();
    }
    return deferred.promise;
  }

  function clear(){
    delete localService.storage.clipboardData
    var deferred = $q.defer();
    var clipboardAPI =  $resource(config.api.clipboard);
    clipboardAPI.delete().$promise.then(successCallback, failureCallback)

    function successCallback(){
      deferred.resolve();
    }
    function failureCallback(){
      deferred.reject();
    }
    return deferred.promise;
  }

  function set(data){
    /**Update the clipboard data in local storage as well as make an api call to update data in backend as well*/
    var deferred = $q.defer();
    var clipboardAPI =  $resource(config.api.clipboard);
    localService.storage.clipboardData = CryptoJS.AES.encrypt(JSON.stringify(data), '').toString()
    clipboardAPI.save({'data': data}).$promise.then(successCallback, failureCallback);
    function successCallback(data){
      deferred.resolve(data['clipboard_data']);
    }
    function failureCallback(data){
      console.error("Failed to get clipboard")
      deferred.reject();
    }
    return deferred.promise;
  }
  return service
}
