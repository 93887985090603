<template>
  <mm-modal
    v-model="computedIsModalOpen"
    content-class="mm-restrictions-wrapper-modal-upgrade-plan"
    :title="computedTitle"
    width="450"
    closable
  >
    <div v-if="computedSuggestedPlan">
      <span>{{ computedDescription }}</span>

      <mm-divider class="m-y-6" />

      <span class="mm-text--h400">{{ computedSuggestedPlan.name }}</span>

      <div class="d-flex">
        <ul class="m-t-6 p-a-0 width-100">
          <li v-for="feature in mixinPlans_getPlanFeatures(computedSuggestedPlan)" :key="feature" class="d-flex m-b-3">
            <mm-icon class="m-r-2" name="check" color="n400" />
            <span>{{ feature }}</span>
          </li>
        </ul>

        <div v-if="!computedSuggestedPlan.enterprise" class="d-flex m-b-3" style="align-self: end">
          <span class="mm-text--h700">${{ computedSuggestedPlan.monthlyPrice.toLocaleString() }}</span>
          <span class="plan-billing-info m-l-3">
            {{ $t('settings.workspace.plan.pricing.billing.month.per') }}
          </span>
        </div>
      </div>
    </div>
    <template #actions>
      <mm-button
        v-if="computedIsComparePlansButtonVisible"
        :label="$t('settings.workspace.plan.restricted.see_all')"
        objective="secondary"
        @click="openComparePlansModal"
      />

      <mm-button
        v-if="computedSuggestedPlan"
        class="premium-button m-l-3"
        :label="
          computedSuggestedPlan.enterprise
            ? $t('global.contact_support')
            : $t('settings.workspace.plan.restricted.choose', computedSuggestedPlan)
        "
        icon="premium"
        @click="selectSuggestedPlan"
      />
    </template>
  </mm-modal>
</template>

<script>
// Constants
import { ENTERPRISE_PLAN, getOldAppUrl } from '@/constants'

// Plugins
import { changeChargebeePlan } from '@/plugins/chargebee'
import emitter from '@/plugins/emitter'

// Mixins
import FreshdeskWidget from '@/mixins/support/freshdeskWidget'
import PlansMixin from '@/mixins/plans'

export default {
  name: 'restrictions-wrapper-modal-upgrade-plan',
  mixins: [FreshdeskWidget, PlansMixin],
  props: {
    value: Boolean,
    restrictedFeature: String,
    restrictedFeatureName: String
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    computedTitle() {
      if (this.restrictedFeature)
        return this.$t('settings.workspace.plan.restricted.upgrade_to_use', {
          featureName: this.restrictedFeatureName
        })
      return this.$t('settings.workspace.plan.restricted.storage_full_upgrade')
    },
    computedDescription() {
      if (this.restrictedFeature)
        return this.$t('settings.workspace.plan.restricted.upgrade_to_use_description', {
          featureName: this.restrictedFeatureName,
          name: this.computedSuggestedPlan.name
        })
      return this.$t('settings.workspace.plan.restricted.storage_full_upgrade_description')
    },
    computedSuggestedPlan() {
      const enterprisePlan = {
        ...ENTERPRISE_PLAN,
        ...{
          name: this.$t('settings.workspace.plan.enterprise.title'),
          description: this.$t('settings.workspace.plan.enterprise.description')
        }
      }

      const plansToSuggest = this.$store.state.smsDetails.availablePlans?.filter(
        (p) => p.isSelfServe && p.monthlyPrice > this.$store.state.smsDetails.currentPlan?.monthlyPrice
      )

      if (this.restrictedFeature) return plansToSuggest?.find((p) => p[this.restrictedFeature]) || enterprisePlan

      return (
        plansToSuggest?.find((p) => p.startingStorage > this.$store.state.usageInfo.workspace.current) || enterprisePlan
      )
    },
    computedIsComparePlansButtonVisible() {
      const { currentPlan, availablePlans } = this.$store.state.smsDetails
      return (
        this.restrictedFeature &&
        currentPlan?.isSelfServe &&
        availablePlans?.some((p) => p.isSelfServe && p.id != currentPlan.id)
      )
    }
  },
  methods: {
    openComparePlansModal() {
      this.computedIsModalOpen = false
      //TODO remove when angular code is gone
      if (window.location.hash.includes('settings')) emitter.emit('openComparePlansModal')
      //TODO remove when angular code is gone
      else {
        window.open(
          `${getOldAppUrl()}/n/#/settings/workspace/${this.$store.state.workspaceId}?comparePlans=true`,
          'Settings'
        )
      }
    },
    selectSuggestedPlan() {
      this.computedIsModalOpen = false

      if (this.computedSuggestedPlan.enterprise) this.mixinFreshdeskWidget_showSupportWidget()
      else {
        const onSuccessCloseFn = () => {
          this.$toast.show({
            content: this.$t('settings.workspace.plan.change_success', this.computedSuggestedPlan),
            status: 'success'
          })
        }

        changeChargebeePlan(this.computedSuggestedPlan, onSuccessCloseFn)
      }
    }
  }
}
</script>

<style lang="scss">
.mm-restrictions-wrapper-modal-upgrade-plan {
  ul {
    color: var(--mm-color--n400);
  }

  .plan-billing-info {
    color: var(--mm-color--n400);
    width: min-content;
    align-self: center;
  }

  .premium-button {
    svg {
      --mm-icon--color-hover: var(--mm-color--warn800) !important;
      --mm-icon--color: var(--mm-color--warn800) !important;
    }
  }
}
</style>
