import * as dateformat from 'dateformat';
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
import {numberWithCommas} from './utils';
var Highcharts = require('highcharts');
window['Highcharts'] = Highcharts;
require('highcharts/modules/exporting')(Highcharts);
require('highcharts-custom-events')(Highcharts);
let EXPORT_WIDTH = 1000;

/**
 * @ngInject
 *
 * Responsibilities
 *
 * 1. Render chart
 * 2. Allow point and range selection. Will callback when it is able to do so
 * 3.
 */
renderSummaryDrilldownFilter.$inject = ['$timeout', 'utils', 'c', 'analyticsService', 'toastNotification'];
export function renderSummaryDrilldownFilter($timeout, utils, c, analyticsService, toastNotification) {
  return {
    scope: {
      data: '=',
      active: '=',
      selected: '=',
      showNext: '=',
      name: '=',
      selectCb: '&',
      deselectCb: '&',
      inited: '=',
      labelPrefix: '=',
      card: '='
    },
    templateUrl: 'exploreRenderDrilldownChart.tpl.html',
    link: function (scope, element, attrs) {
      var chart;
      var container;
      var selectedColor = '#4d8770';
      var deselectedColor = '#7db4a3';
      var activeColor = '#ffd45b';
      var hoverColor = '#44a280';
      var activeBorderColor = '#7db4a3';
      var borderColor = 'transparent';
      var _debouncedSelectCb = _.debounce(scope.selectCb, 100);
      scope.rendered = false;
      scope.saveChart = saveChart;
      scope.$on("SaveChart", function (evt, data) {
          scope.saveChart();
      });
      scope.$watch('data', renderChart);
      scope.$watch('active', fixColors);
      scope.$watch('selected', function (v) {
        if (!v) {
          fixColors();
        }
      });
      var selected = [];

      $(window).click(function(e){
        //basically if a click happens inside another chart, this code would disable selection on this chart.
        var t: any;
        t = $(e.target);
        var isInsideClick = $(element).has(t).length;
        if(!isInsideClick && chart){
          var chartSel = $('.render-summary-drillldown-chart');
          if(chartSel.has(t).length){
            //disable the chart selection
            var series = chart.series[0];
            angular.forEach(series.points, function (point, i) {
              point.select(false);
            });
            scope.deselectCb();
          }
        }else if(isInsideClick && chart){
            $(element).closest('div.explore-card').focus();
        }
      });

      function fixColors() {
        var activeVals = _.cloneDeep(scope.active);
        if (!chart) {
          return;
        }
        if (!activeVals) {
          activeVals = [];
        }
        var series = chart.series[0];
        var colors = [];
        var categories = [];
        angular.forEach(series.points, function (point, i) {
          point.select(false);
          categories.push(point.category);
          colors.push(deselectedColor);
        });

        if (activeVals.length == 1) {
          colors[categories.indexOf(activeVals[0])] = activeColor;
        }
        else if (activeVals.length > 1) {
          var i1 = categories.indexOf(activeVals[0]);
          var i2 = categories.indexOf(activeVals[activeVals.length - 1]);
          $.each(colors, function (i) {
            if (i >= i1 && i <= i2) {
              colors[i] = activeColor;
            }
          });
        }
        chart.series[0].update({
            colors: colors,
            point: {
                events: {
                    click: pointClickHandler,
                    dblclick: dblClickHandler,
                }
            }
        });
      }

      function pointClickHandler(event) {
        var point = event.point;
        if(event.shiftKey){
          if(!selected.includes(point.category)){
            selected.push(point.category);
          } else {
            selected = selected.filter(x => x !== point.category);
          }
        } else {
          selected = [];
          selected.push(point.category);
        }

        function getRange(selected){
          let nums = []
          if(Array.isArray(selected)){
            selected.map(each => {
              let range = each.split('to');
              range.map(item => nums.push(parseFloat(item.replace(/,/g, ''))));
            });
          } else {
            let range = selected.split('to');
            range.map(item => nums.push(parseFloat(item.replace(/,/g, ''))));
          }
          let max = Math.max(...nums)
          let min = Math.min(...nums)
          return {max, min}
        }

        let selectedRange = getRange(selected);
        Highcharts.each(chart.series, function (series) {
          Highcharts.each(series.points, function (each) {
            let currentRange = getRange(each.category);
            if (selected.includes(each.category)) {
              each.select(true, true);
            } else if(currentRange.min > selectedRange.min && currentRange.max < selectedRange.max){
              each.select(true, true);
            }else {
              each.select(false, true);
            }
          });
        });
        $(element).closest('div.explore-card').focus();
        _debouncedSelectCb({values: selected})
      } 
      function dblClickHandler(e) {
          _debouncedSelectCb.cancel();
          var point = e.point;
          var xValue = point.category;
          point.select(!point.selected);

          scope.selectCb({values: [xValue]});
          if(scope.card.isActionPossible){
              scope.card.filter()
          }
          $(element).closest('div.explore-card').focus();
      }

      function _removeSelection(){
        Highcharts.each(chart.series, function (series) {
          Highcharts.each(series.points, function (point) {
            point.select(false);
          });
        });
        scope.deselectCb();
      }

      function _rangeSelectionCb(e) {
        let selected = [];
        $(element).trigger('click');

        Highcharts.each(chart.series, function (series) {
          Highcharts.each(series.points, function (point) {
            if (point.x >= e.xAxis[0].min && point.x <= e.xAxis[0].max) {
              point.select(true, true);
              selected.push(point.category);
            }
            else {
              point.select(false, true);
            }
          });
        });
        _debouncedSelectCb({values: selected});
        return false; //avoids the zoom  button
      }

      function _postRender() {
        scope.rendered = true;
        scope.$emit('CardRendered', scope.rendered);

      }

      function renderChart() {
        var data = scope.data;
        if (!angular.isArray(data)) {
          return;
        }
        let isVisible = $(element).is(':visible');
        if(!isVisible){
          $timeout(renderChart, 100);
          return;
        }
        container = $(element).find('.chart')[0];
        var values = [];
        var aggs = [];
        var colors = [];
        data.forEach(function (d) {
          values.push(d.formatted);
          aggs.push(d.agg);
          colors.push(deselectedColor);
        });

        if (!$.contains(<any>document, container)) {
          return;
        }
        var chartOptions = {
          chart: {
            type: 'column',
            zoomType: 'x',
            events: {
              selection: _rangeSelectionCb,
              click: _removeSelection
            }
          },
          xAxis: {
            categories: values,
            title: {
              enabled: false
            }
          },
          yAxis: {
            title: '',
            labels: {enabled: false}
          },
          plotOptions: {
            column: {
              allowPointSelect: true,
              pointPadding: 0.01,
              states:{
                hover: {
                  color: hoverColor
                },
                // select: {
                //   borderColor: activeBorderColor
                // }
              },
              borderColor: borderColor
            },
            series: {
              // pointPadding: 0.01,
              groupPadding: 0.01
            }
          },
          animation: false,
          series: [{
            name: 'Count',
            data: aggs,
            minPointLength: 4,
            animation: false,
            colorByPoint: true,
            colors: colors,
            title: {
              enabled: false
            },
            labels: {enabled: false},
            point: {
              events: {
                click: pointClickHandler,
                dblclick: dblClickHandler,
              }
            },
            states: {
              select: {
                color: selectedColor
              }
            }
          }],
          legend: {
            enabled: false
          },
          title: {text: ''},
          exporting: {enabled: false},
          credits: {enabled: false},
          tooltip: {
            formatter: function () {
              let numericValue = this.y;
              let numericValueWithComma = numberWithCommas(numericValue);
              let template = '<div class="tooltipContainer"><div><div>{1}</div><span class="count">{2}: <b>{0}</b></span></div></div>';
              return utils.string.format(template, [numericValueWithComma, this.x, scope.labelPrefix]);
            },
            shape: 'square',
            style: {
              fontSize: '10px'
            },
            shared: false,
            shadow: false,
            enabled: true,
            borderRadius: 0,
            borderWidth: 0,
            backgroundColor: 'none',
            useHTML: true,
            positioner: function (labelWidth, labelHeight, point) {
              var chart = this.chart;
              var left = chart.plotLeft + point.plotX;
              while (left + labelWidth > chart.plotSizeX) {
                left = left - 10;
              }
              var top = chart.plotTop + chart.chartHeight * 2 / 3 - 10;
              return {x: left, y: top};
            }
          }
        };
        $(container).empty();
        chart =  Highcharts.chart(container, chartOptions, _postRender);

        // on the initial load of explore card if there are active values then items are colored.
        if(scope.active){
          fixColors();
        }
      }

      function saveChart() {
        analyticsService.userEventTrack(c.userEvents.exploreCards.saveChart,
          {
            eventOrigin: 'dataview.exploreCard'
          });
        let cntnr: any = $(container);
        let chart:any = cntnr.highcharts();
        let filename = scope.name + ' vs ' + utils.string.capitalize(scope.labelPrefix.toLowerCase());
        let render_width = EXPORT_WIDTH;
        let render_height = render_width * chart.chartHeight / chart.chartWidth;

        let svg = $(container).find('svg')[0];
        var svgSize = svg.getBoundingClientRect();
        var svgData = new XMLSerializer().serializeToString( svg );

        let canvas:HTMLCanvasElement = document.createElement('canvas');
        canvas.height = render_height;
        canvas.width = render_width;

        let image = new Image;
        image.onload = function() {
          canvas.getContext('2d').drawImage(image, 0, 0, render_width, render_height);
          let data = canvas.toDataURL("image/png");
          utils.downloadDataAsFile(data, filename + '.png');
        };
        image.src = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svgData)));
        toastNotification.success('Downloaded PNG successfully');
      }
    }
  };
}
