import * as angular from 'angular'
import * as _ from 'lodash'

bulkCopyManagerFactory.$inject = ['destinationColumnManagerFactory', 'filterManagerFactory', 'utils', 'filterUI', 'c']
export function bulkCopyManagerFactory(destinationColumnManagerFactory, filterManagerFactory, utils, filterUI) {
  return {
    get_manager: get_manager,
  }

  function get_manager(options) {
    return new BulkCopyManager(options)
  }

  function BulkCopyManager(options) {
    let self = this

    //Shallow copy metadata
    let metadata = [...options.metadata],
      taskUtils = options.taskUtils,
      dataview = options.context.dataview

    // Sort metadata in ascending order of display names
    metadata.sort((firstColumn, secondColumn) =>
      firstColumn.display_name_w_type.localeCompare(secondColumn.display_name_w_type)
    )
    self.filteredMetadata = [...metadata]

    self.internal_name_to_col_map = options.internal_name_to_col_map
    let destinationManagerProps = {
      metadata: metadata,
      allowedTypes: undefined,
      taskUtils: options.taskUtils,
      isDestinationFormatterVisible: true,
      isDisabled: true,
      internal_name_to_col_map: self.internal_name_to_col_map,
    }
    let filterManagerProps = {
      metadata: metadata,
      internal_name_to_col_map: self.internal_name_to_col_map,
      dataviewId: options.context.dataview.id,
      context: options.context,
      originalSequenceNumber: options.originalSequenceNumber,
    }
    self.taskUtils = taskUtils
    self.getParam = getParam
    self.validate = validate
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.handlePasteParams = handlePasteParams
    self.setParam = setParam
    self.addCopy = addCopy
    self.removeCopy = removeCopy
    self.deduplicateFromAllSourceColumns = deduplicateFromAllSourceColumns
    self.deduplicateFromAllDestinationColumns = deduplicateFromAllDestinationColumns
    self.metadata = utils.sanitizeMetadataWithType(metadata)
    self.context = options.context
    self.copyColumnManagers = []

    if (!options.context.inEditMode) {
      addCopy()
    }

    function deduplicateFromAllSourceColumns() {
      // We collect all the columns selected in the destination drop-downs for bulk copy for existing column option
      const columnsSelectedInDestinationDropdowns = new Set();
      for(let copyColumnManager of self.copyColumnManagers) {
        if (copyColumnManager.destinationManager['destination_column']) {
          columnsSelectedInDestinationDropdowns.add(copyColumnManager.destinationManager['destination_column']['internal_name'])
        }
      }

      // Allow all such columns which haven't been selected in any destination dropdown
      self.filteredMetadata = _.filter(self.metadata, function (col) {
        return !columnsSelectedInDestinationDropdowns.has(col.internal_name)
      })
    }

    function deduplicateFromAllDestinationColumns() {
      // Collect internal names of existing sources columns as they all should be excluded
      let existingSources = new Set();
      for(let copyColumnManager of self.copyColumnManagers) {
        if (copyColumnManager.sourceColumn) {
          existingSources.add(copyColumnManager.sourceColumn.internal_name)
        }
      }

      for (let loopIndex = 0; loopIndex < self.copyColumnManagers.length; loopIndex++) {
        let currentCopyColumnManager = self.copyColumnManagers[loopIndex]

        // For current dropdown section, find the allowed types and default type of columns allowed in destination
        let allowedDestinationTypes = ['TEXT']
        let defaultType = null

        if (currentCopyColumnManager.sourceColumn && currentCopyColumnManager.sourceColumn.type != 'TEXT') {
          allowedDestinationTypes.push(currentCopyColumnManager.sourceColumn.type)
          defaultType = currentCopyColumnManager.sourceColumn.type
        }

        // Internal names of all existing destinations except the one for current dropdown
        // All existing destinations should be excluded except the current one which is selected
        let existingDestinations = new Set();
        for (let ccmIndex = 0; ccmIndex < self.copyColumnManagers.length; ccmIndex++) {
          let copyColumnManager = self.copyColumnManagers[ccmIndex];
          if (ccmIndex != loopIndex && copyColumnManager.destinationManager?.destination_column) {
            existingDestinations.add(copyColumnManager.destinationManager.destination_column.internal_name)
          }
        }

        //All source columns + destination columns other than current one
        let excludedColumnsForCurrentDestination = new Set([ ...existingDestinations, ...existingSources ])

        /* Set the destination type for destination column of currentCopyColumnManager to undefined 
          for it to be set by setAllowedTypesAndColumns function*/
        currentCopyColumnManager.destinationManager.destination_type = undefined

        currentCopyColumnManager.destinationManager.setAllowedTypesAndColumns(
          allowedDestinationTypes,
          defaultType,
          excludedColumnsForCurrentDestination
        )
      }
    }

    function addCopy() {
      let ccManager = new CopyColumnManager()
      self.copyColumnManagers.push(ccManager)
    }

    function CopyColumnManager() {
      let ccManager = this
      ccManager.isSourceColumnValid = true
      ccManager.sourceColumnMessage = ''

      ccManager.sourceColumn = null
      ccManager.previousDestinationColumn = null
      ccManager.filterUI = filterUI
      ccManager.filterManager = filterManagerFactory.get_manager(filterManagerProps)
      ccManager.destinationManager = destinationColumnManagerFactory.get_manager(destinationManagerProps)

      ccManager.openConditionMenu = openConditionMenu
      ccManager.selectSourceColumn = selectSourceColumn
      ccManager.selectDestinationColumn = selectDestinationColumn
      ccManager.clickNewColumnRadio = clickNewColumnRadio
      function openConditionMenu() {
        ccManager.filterUI.open(ccManager.filterManager)
      }

      function clickNewColumnRadio(isNew) {
        if (isNew) {
          if (ccManager.destinationManager.destination_column) {
            ccManager.previousDestinationColumn = ccManager.destinationManager.destination_column
          }
          ccManager.destinationManager.destination_column = null
        } else {
          ccManager.destinationManager.new_column_name = null
          ccManager.destinationManager.destination_column = ccManager.previousDestinationColumn
        }

        ccManager.destinationManager.setIfNewColumn(isNew)
        deduplicateFromAllDestinationColumns()
        deduplicateFromAllSourceColumns()
      }

      function selectSourceColumn(selectedColumnInternalName, highlight = true) {
        let selectedColumm = self.internal_name_to_col_map[selectedColumnInternalName]
        ccManager.sourceColumn = selectedColumm
        if (selectedColumm) {
          if (highlight) {
            taskUtils.highlight.sources(selectedColumm)
          }
          deduplicateFromAllDestinationColumns()

          let sourceColumnFormat = dataview.display_properties.FORMAT_INFO[selectedColumm.internal_name]

          if (!sourceColumnFormat) {
            sourceColumnFormat = selectedColumm['format']
          }

          ccManager.destinationManager.setDestinationFormat(sourceColumnFormat)
          ccManager.destinationManager.enable()
        } else {
          ccManager.destinationManager.disable()
        }
      }

      function selectDestinationColumn(destinationColumnInternalName) {
        let destinationColumn = self.internal_name_to_col_map[destinationColumnInternalName]
        ccManager.destinationManager.destination_column = destinationColumn
        ccManager.destinationManager.updateDestinationType()

        deduplicateFromAllDestinationColumns()
        deduplicateFromAllSourceColumns()
      }
    }

    function removeCopy(manager) {
      /*
       * This function is to remove a copy section when clicking the cross icon
       * When removing it, the source and destination columns selected should appear in other source-destination dropdowns.
       * For the source column being removed. It will appear in column options of other destination dropdowns
       * If it is not already being used as source in remaining sections
       
       * Some pre-requisite knowledge:
       * => Destination Manager has two fields called 'allowed_columns' and 'excluded_columns'
       * => 'allowed_columns' keep tracks of what should be allowed within the dropdown and is directly used in HTML
       * => 'excluded_columns' keeps tracks of what columns are restricted from being displayed in dropdown
       * => When we pass 'excluded_columns' into setAllowedTypesAndColumns(), it sets both these fields, which is used in this function
       */
      if (self.copyColumnManagers.length === 1) {
        return
      }

      // Save the internal names of source and destination columns being removed
      let removedSourceColumnInternalName = manager.sourceColumn?.internal_name
      let removedDestinationColumnInternalName = manager.destinationManager?.destination_column?.internal_name

      // Remove this copy section
      self.copyColumnManagers = $.grep(self.copyColumnManagers, function (ccm) {
        return ccm != manager
      })

      if (!removedSourceColumnInternalName) {
        return
      }

      /* 
       * Upon deleting a section; a source and a destination column got freed up
       * The destination that freed should reflect in source and destination dropdown options of all other sections
       * The source section that got freed should reflect in all the source dropdown options 
       * and reflect in destination dropdown options of other sections if its still not being used as source in any section
       */
      if (removedDestinationColumnInternalName) {
        let revisedMetadata = [...self.filteredMetadata, manager.destinationManager.destination_column]
        revisedMetadata.sort((firstColumn, secondColumn) =>
          firstColumn.display_name_w_type.localeCompare(secondColumn.display_name_w_type)
        )
        self.filteredMetadata = revisedMetadata
      }
      
      let sourceCount = self.copyColumnManagers.reduce((count, copyColumnManager) => {
        return count + (copyColumnManager.sourceColumn?.internal_name == removedSourceColumnInternalName)
      }, 0)
      let deletedSourceColumnNoLongerInUse = sourceCount == 0

      for (let copyColumnManager of self.copyColumnManagers) {
        let currentDestinationManager = copyColumnManager.destinationManager

        if (!currentDestinationManager.allowed_types) {
          continue
        }

        /*
         * Upon removing source column if it was excluded from current destination manager earlier
         * And now it's not chosen as source in any section, then it should be allowed now.
         */
        let isSourceExcluded = currentDestinationManager.excluded_columns.has(removedSourceColumnInternalName)
        if (isSourceExcluded && deletedSourceColumnNoLongerInUse) {
           currentDestinationManager.excluded_columns.delete(removedSourceColumnInternalName)
        }

        /*
         * Upon removing destination column if it was excluded from current destination manager earlier
         * Then it should be allowed now
         */
        let isDestinationExcluded = currentDestinationManager.excluded_columns.has(removedDestinationColumnInternalName)
        if (isDestinationExcluded) {
          currentDestinationManager.excluded_columns.delete(removedDestinationColumnInternalName)
        }

        currentDestinationManager.setAllowedTypesAndColumns(
          currentDestinationManager.allowed_types,
          undefined,
          currentDestinationManager.excluded_columns
        )
      }
    }

    function validate() {
      for (let managerInstance of self.copyColumnManagers) {
        // Destination Validation
        let destinationValidation = managerInstance.destinationManager.validate()
        if (!destinationValidation) {
          return false
        }

        // Source Column Validation
        let srcCol = managerInstance.sourceColumn
        if (!srcCol) {
          return false
        }
        if (srcCol.hasOwnProperty('error')) {
          // If any selected source columns are reporting an error
          // For example, column being deleted after reordering delete column rule
          // Report bulk copy as invalid
          managerInstance.isSourceColumnValid = false
          managerInstance.sourceColumnMessage = utils.getColumnErrorMessage(srcCol.error)
          return false
        } else {
          managerInstance.isSourceColumnValid = true
          managerInstance.sourceColumnMessage = ''
        }

        if (srcCol.type !== managerInstance.destinationManager.destination_type) {
          // Source type should be same as destination type
          // If they are not same, then destination should be TEXT type to be considered valid
          // In all other cases, report bulk copy as invalid
          if (managerInstance.destinationManager.destination_type != 'TEXT') {
            managerInstance.isSourceColumnValid = false
            managerInstance.sourceColumnMessage = utils.getColumnErrorMessage('type mismatch')
            return false
          } else {
            managerInstance.isSourceColumnValid = true
            managerInstance.sourceColumnMessage = ''
          }
        }

        // Condition Validation
        if (managerInstance.filterManager.condition) {
          let conditionValidation = managerInstance.filterManager.condition.validate()
          if (!conditionValidation) {
            return false
          }
        }
      }

      return true
    }

    function handlePasteParams(taskInfo) {
      /** Update source, destination and condition params with suitable replacement columns, based on display name*/
      let params = taskInfo.params
      let copyParams = params['COPY']
      for (let i = 0; i < copyParams.length; i++) {
        addCopy()
        if (copyParams[i].hasOwnProperty('DESTINATION')) {
          utils.metadata.replaceMatchingColumnAndUpdateMetadata(
            copyParams[i],
            'DESTINATION',
            taskInfo,
            self.metadata,
            self.displayNameAndTypeToColumnMap
          )
          //Update destination manager metadata
          self.copyColumnManagers[i].destinationManager.metadata = self.metadata
          self.copyColumnManagers[i].destinationManager.internal_name_to_col_map =
            utils.metadata.get_internal_name_to_col_map(self.metadata)
        }

        //Source column
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(
          copyParams[i],
          'SOURCE',
          taskInfo,
          self.metadata,
          self.displayNameAndTypeToColumnMap
        )
        self.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(self.metadata)

        //Handle columns used in condition
        let columnUsedInCondition = utils.get_input_columns(_.cloneDeep(copyParams[i].CONDITION))
        if (columnUsedInCondition) {
          utils.metadata.findReplaceColumnsInCondition(
            self.metadata,
            self.displayNameAndTypeToColumnMap,
            copyParams[i],
            columnUsedInCondition,
            taskInfo
          )
        }
        self.copyColumnManagers[i].filterManager.metadata = self.metadata
        self.copyColumnManagers[i].filterManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(
          self.metadata
        )
      }
      return params
    }

    function getParam() {
      let copyParams = []
      for (let copyManagerInstance of self.copyColumnManagers) {
        let copyParam = { SOURCE: copyManagerInstance.sourceColumn.internal_name }
        let destination_param = copyManagerInstance.destinationManager.getParam()
        if (destination_param.hasOwnProperty('AS') && self.context.inEditMode == true && self.context.task) {
          utils.sanatizeParamForDuplicateCols(destination_param['AS'], 'INTERNAL_NAME', self.context.task)
        }
        angular.extend(copyParam, destination_param)
        if (copyManagerInstance.filterManager.condition) {
          copyParam['CONDITION'] = copyManagerInstance.filterManager.getParam()
        }
        copyParams.push(copyParam)
      }

      let param: any = {
        COPY: copyParams,
        VERSION: 2,
      }

      if (self.context.hasOwnProperty('sequence')) {
        param['SEQUENCE_NUMBER'] = self.context['sequence']
      }
      return param
    }

    function setParam(param) {
      self.param = param.COPY
      let sourceColumns = []
      let destColumns = []
      for (let i = 0; i < param.COPY.length; i++) {
        //Do not add instances of copy column if they already exist
        if (self.copyColumnManagers.length != param.COPY.length) addCopy()
        let copyParam = param.COPY[i]
        let sourceColumn = self.internal_name_to_col_map[copyParam.SOURCE]
        sourceColumns.push(sourceColumn)
        let ccManager = self.copyColumnManagers[i]
        ccManager.selectSourceColumn(sourceColumn.internal_name, false)
        ccManager.destinationManager.setParam(copyParam)
        if (!ccManager.destinationManager.new_column) {
          destColumns.push(ccManager.destinationManager.destination_column)
        }
        if (copyParam.hasOwnProperty('CONDITION')) {
          ccManager.filterManager.setParam(copyParam.CONDITION, param?.EXECUTION_TIMESTAMP)
        }
      }

      taskUtils.highlight.columns(sourceColumns.concat(destColumns))
    }
  }
}

// This directive checks that across all input boxes for new column names the values should be unique
export function valNewColumnDuplicateName() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valNewColumnDuplicateName(scope, elem, attrs, ctrl) {
      ctrl.$validators.valNewColumnDuplicateName = function (modelValue, viewValue) {
        if (!modelValue) {
          return true
        }
        let input = scope.$eval(attrs.valNewColumnDuplicateName)
        let copyColumnManagers = input['list']
        let currentColumnManager = input['current']
        let existingNames = new Set()
        _.forEach(copyColumnManagers, function (copyColumnManager) {
          if (copyColumnManager.destinationManager.new_column && copyColumnManager != currentColumnManager) {
            existingNames.add(copyColumnManager.destinationManager.new_column_name)
          }
        })
        if (existingNames) {
          return !existingNames.has(modelValue)
        }
        return true
      }
    },
  }
}
