
const adminRoutes = [
  /**
   * @Code reference 4
   * -> Creating route for a Vue component
   * @Comment vue config for the route
   * @link: https://mammoth.atlassian.net/wiki/spaces/PD/pages/1363247150/Vue+Porting#Vue-component-with-routing
   */
  {
    path: "/admin",
    redirect: "/admin/workspaces",
  },
  {
    path: "/admin/workspaces",
    name: "workspaces",
    component: () => import("./pages/AdminWorkspaces.vue"),
  },
  {
    path: "/admin/integrations",
    name: "integrations",
    component: () => import("./pages/AdminIntegrations.vue"),
  },
];

export default adminRoutes;
