/*jslint node: true */
/**
 * @ngInject
 */

formulaBuilderInterface.$inject = ['config'];
export function formulaBuilderInterface(config){
  return {
    templateUrl: config.templates.builder,
    scope: {
      manager: "=",
      onTextAreaBlur: '&onTextAreaBlur',
      openHintsIfEmpty: '='
    }
  }
}

