// API
import API from '@/api'
import globalApi from '@/api/global.api'

// Transforms
import { transformUsers } from '@/api/global.transform.js'

async function getViewInfo(viewId, includeRowCount = true) {
  const { data } = await API.get(`/dataviews/${viewId}`, { params: { INCLUDE_ROW_COUNT: includeRowCount } })
  return await globalApi.getFutureRequest(data.future_id, 'getViewInfo')
}

/**
 *
 * @param {string} viewId the data-view we want to request data for
 * @param {string} offset the first row we want to start the request from
 * @param {string} limit the number of rows we want to request
 * @param {string} filterCondition filters can be applied to the dataset, if there is any, it comes here
 * @param {string} columns list of clumns to show the data for
 */

async function getViewData({ viewId, offset = 0, limit = 400, filterCondition = null, columns = null, sequence = 0 }) {
  let params = {
    limit,
    offset,
    SEQUENCE_NUMBER: sequence,
    condition: filterCondition
  }
  if (Array.isArray(columns) && columns.length) {
    params.columns = columns
  }
  const { data } = await API.post(`/dataviews/${viewId}/data`, params)
  return await globalApi.getFutureRequest(data.future_id, 'getViewData')
}

export async function getRowCount({ viewId, filterCondition, sequence }) {
  const param = {
    PIVOT: {
      SELECT: [
        {
          FUNCTION: 'COUNT',
          AS: 'COUNT',
          INTERNAL_NAME: 'count'
        }
      ]
    },
    CONDITION: filterCondition
  }

  if (Number.isInteger(sequence)) param.SEQUENCE_NUMBER = sequence

  const { data } = await API.post(`/dataviews/${viewId}/data/query`, { param, display_properties: {} })
  const results = await globalApi.getFutureRequest(data.future_id)
  const currentRowCount = results.data[0].count
  return currentRowCount
}

const getUsersViewingView = (viewId) => API.get(`dataviews/${viewId}/user`)

const setDisplayProperties = async ({ viewId, displayProperties }) => {
  const { data } = await API.post(`dataviews/${viewId}`, { display_properties: displayProperties })
  return await globalApi.getFutureRequest(data.future_id)
}

export default {
  toggleStepsDataSyncStatus: async (steps, status, individualStatusProperty) => {
    const { data } = await API.patch(
      'resource_dependencies',
      steps.map((step) => ({
        context_id: step.id,
        context_type: step.type.toLowerCase(),
        data_pass_through: individualStatusProperty ? step[individualStatusProperty] : status
      }))
    )
    return await globalApi.getFutureRequest(data.future_id)
  },
  toggleRunPendingUpdate: async (operations, status) => {
    const { data } = await API.patch(
      'resource_dependencies',
      operations.map((op) => ({
        context_id: op.opId,
        context_type: op.opType.toLowerCase(),
        run_pending_update: status
      }))
    )
    return await globalApi.getFutureRequest(data.future_id)
  },
  getViewInfo,
  getViewData,
  getRowCount,
  getUsersViewingView: async (viewId) => transformUsers(await getUsersViewingView(viewId)),
  setDisplayProperties
}
