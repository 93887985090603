<template>
  <mm-modal
    v-model="computedShowModal"
    contentClass="action-bar-export-sftp"
    :title="$t('action_bar.export.sftp.modal_title')"
    :width="485"
    closable
  >
    <mm-form v-model="isFormValid" ref="form">
      <div class="sftp-row">
        <mm-text-input
          v-model="credentials.host"
          class="flex-grow-1"
          :title="$t('global.dictionary.host')"
          :placeholder="$t('global.dictionary.host')"
          :validations="[$validators.required]"
          hideDetails
        />
        <mm-text-input
          v-model="credentials.port"
          ref="port"
          class="action-bar-credentials-port"
          :title="$t('global.dictionary.port')"
          :placeholder="SFTP_DEFAULT_PORT.toString()"
          :validations="[$validators.required]"
          hideDetails
        />
      </div>
      <div class="sftp-row">
        <mm-text-input
          v-model="credentials.username"
          :title="$t('global.dictionary.username')"
          :placeholder="$t('global.dictionary.username')"
          :validations="[$validators.required]"
          hideDetails
        />
        <mm-text-input
          v-model="credentials.password"
          type="password"
          :title="$t('global.dictionary.password')"
          :placeholder="$t('global.dictionary.password')"
          :validations="[$validators.required]"
          hideDetails
        />
      </div>
      <div class="sftp-row">
        <mm-text-input
          v-model="credentials.directory"
          class="flex-grow-1"
          :title="$t('global.dictionary.directory')"
          :placeholder="$t('action_bar.export.sftp.default_is_home')"
          :validations="[$validators.required]"
          hideDetails
        />
      </div>
    </mm-form>

    <!-- Include hidden columns -->
    <mm-checkbox
      v-if="computedHasHiddenColumn"
      class="m-t-5"
      v-model="includeHiddenColumns"
      :label="$t('action_bar.export.export_to_database.include_hidden_columns')"
      noFocusOnClick
    />

    <!-- Keep at the end of the pipeline -->
    <div class="sftp-row m-b-10" style="gap: 10px">
      <mm-checkbox
        v-model="keepAtPipelineEnd"
        :label="$t('action_bar.export.export_to_database.keep_at_pipeline_end.label')"
        noFocusOnClick
      />
      <mm-tooltip :label="$t('action_bar.export.export_to_database.keep_at_pipeline_end.tooltip')">
        <mm-button icon="information" size="small" objective="tertiary" noFocusOnClick />
      </mm-tooltip>
    </div>

    <template #actions>
      <mm-button
        class="flex-grow-1 m-r-2"
        :label="$t('dictionary.cancel')"
        objective="tertiary"
        @click="computedShowModal = false"
      />
      <mm-button
        class="flex-grow-1 m-l-2"
        :label="$t('dictionary.apply')"
        :loading="isApplyLoading"
        :disabled="!isFormValid"
        @click="onApplyClick"
      />
    </template>
  </mm-modal>
</template>

<script>
// API
import actionBarApi from '@/modules/data-editor/action-bar/action-bar.api'

// Constants
import { EXPORT_CONNECTORS_MAP } from '@/constants'

export default {
  name: 'action-bar-export-sftp',
  props: {
    value: Boolean
  },
  data: () => ({
    isFormValid: false,
    credentials: {
      host: '',
      port: '',
      username: '',
      password: '',
      directory: ''
    },
    validateResult: {
      isError: false,
      message: ''
    },
    actionId: '',
    keepAtPipelineEnd: true,
    includeHiddenColumns: false,
    isApplyLoading: false
  }),
  computed: {
    computedShowModal: {
      get() {
        return this.value
      },
      set(showModal) {
        this.$emit('input', showModal)
      }
    },
    computedHasHiddenColumn() {
      return this.$store.getters['dataEditor/columns']?.some((column) => !column.show)
    }
  },
  created() {
    this.SFTP_DEFAULT_PORT = EXPORT_CONNECTORS_MAP.SFTP.connection.fields.port.props.value
    this.credentials.port = this.SFTP_DEFAULT_PORT
  },
  watch: {
    computedShowModal(showModal) {
      if (!showModal) {
        this.actionId = ''
        this.credentials.host = ''
        this.credentials.port = this.SFTP_DEFAULT_PORT
        this.credentials.username = ''
        this.credentials.password = ''
        this.credentials.directory = ''
        this.validateResult.isError = false
        this.validateResult.message = ''
        this.keepAtPipelineEnd = true
        this.includeHiddenColumns = false
        this.isApplyLoading = false
      }
    }
  },
  methods: {
    async getSftpExportApiResponse({ onlyValidate, isEdit }) {
      const api = isEdit ? actionBarApi.patchExport : actionBarApi.exportToExistingDatabase
      return api({
        viewId: this.$store.getters['dataEditor/view'].viewId,
        actionId: this.actionId,
        databaseHandler: 'sftp',
        targetProperties: {
          ...this.credentials,
          include_hidden: this.includeHiddenColumns
        },
        // Setting sequence as null keeps action at end of the pipeline,
        sequence: this.keepAtPipelineEnd ? null : this.$store.state.pipeline.taskCount,
        onlyValidate
      })
    },
    async onApplyClick() {
      this.isApplyLoading = true
      this.validateResult.isError = false
      this.validateResult.message = ''

      const response = await this.getSftpExportApiResponse({ onlyValidate: false, isEdit: !!this.actionId })
      if (response.status == 'error') {
        this.isApplyDisabled = true
        this.$toast.show({ content: response.message, status: 'error' })
      } else {
        await this.$root.legacyHandlers.actionServiceUpdateList()
        this.$root.legacyHandlers.openPipeline()
        this.computedShowModal = false
      }
      this.isApplyLoading = false
    },
    edit({ actionId, targetProperties, keepAtPipelineEnd }) {
      this.computedShowModal = true
      this.$nextTick(() => {
        this.actionId = actionId
        Object.keys(this.credentials).forEach((key) => (this.credentials[key] = targetProperties[key]))
        this.includeHiddenColumns = targetProperties.include_hidden
        this.keepAtPipelineEnd = keepAtPipelineEnd
        this.$nextTick(this.$refs.form.getFormErrors)
      })
    }
  }
}
</script>

<style lang="scss">
.sftp-row {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  .mm-text-input {
    flex-basis: 194px;

    &.action-bar-credentials-port {
      flex-basis: 70px;

      .mm-text-input--text-container {
        width: 100%;
      }
    }
  }

  .mm-checkbox {
    .mm-icon {
      min-width: 12px;
    }
  }
}
</style>
