<template>
  <div class="mm-dataflow-status-non-sync-tasks d-flex flex-column width-100">
    <div v-show="computedDependencies.length" class="accordions-wrapper">
      <div class="m-b-4 m-x-2">
        <span>{{ $t('dataflow_status.non_sync_tasks.description') }}</span>
      </div>

      <div class="tasks-table mm-rounded-borders">
        <div class="p-x-5 p-y-2 d-flex">
          <span style="align-self: center" class="mm-text--body-bold">
            {{ $t('dataflow_status.non_sync_tasks.title') }}
          </span>
          <mm-spacer />
          <restrictions-wrapper validate-storage-limit>
            <mm-button
              :disabled="!computedIsAnyDependencySelected"
              :loading="isLoading"
              :label="$t('data_library.views.set_sync_on')"
              icon="sync"
              objective="tertiary"
              size="small"
              @click="setDataSyncOn"
            />
          </restrictions-wrapper>
        </div>

        <mm-divider />

        <mm-row no-gutters class="title-row mm-text--caption-bold p-y-3 p-l-5 p-r-6">
          <mm-col cols="5">
            <mm-checkbox
              v-model="computedAreAllDependenciesSelected"
              :disabled="computedIsMainCheckboxDisabled"
              :indeterminate="computedIsAnyDependencySelected"
              class="m-l-1"
              :label="$t('global.dictionary.view')"
            />
          </mm-col>
          <mm-col cols="5"> {{ $t('dataflow_status.non_sync_tasks.task_details') }} </mm-col>
          <mm-col cols="2" class="d-flex" style="justify-content: center">
            {{ $t('data_library.views.data_sync') }}
          </mm-col>
        </mm-row>

        <mm-divider />

        <div class="tasks-list overflow-auto">
          <div
            v-for="(dependency, idx) in computedDependencies"
            :key="`options_${dependency.linkView.resourceId}_${idx}_${listItemKey}`"
          >
            <mm-divider v-if="idx != 0" />

            <mm-row
              no-gutters
              class="p-y-2 p-x-5 align-items-center task-row"
              :class="{ 'cursor-pointer active-row': !isDependencyDisabled(dependency) }"
              @click="!isDependencyDisabled(dependency) && toggleRow(dependency)"
            >
              <mm-col cols="5" class="col-overflow">
                <div class="d-flex align-items-center">
                  <mm-checkbox
                    v-model="dependenciesSelection[dependency.operationId + dependency.operationName]"
                    style="align-self: flex-start"
                    :disabled="isDependencyDisabled(dependency)"
                    class="m-t-3 m-l-1"
                    @click="!isDependencyDisabled(dependency) && toggleRow(dependency)"
                  />
                  <mm-tooltip
                    :label="getResourcePath(dependency.linkView.resourceId).join(' / ')"
                    wrapper-class="m-b-2 m-t-3 p-r-2"
                  >
                    <div class="overflow-text p-x-2 p-b-2">
                      <p class="mm-text--caption-regular m-a-0">
                        {{ $store.getters['resources/getDatasetByViewId'](dependency.linkView.viewId).properties.name }}
                      </p>
                      <mm-link :label="dependency.linkView.properties.name" small @click.stop="openView(dependency)" />
                    </div>
                  </mm-tooltip>
                </div>
              </mm-col>
              <mm-col cols="5" class="col-overflow">
                <span v-if="dependency.operationName == RESOURCE_DEPENDENCIES.VIEW" class="mm-text--caption-bold">
                  {{ $tc('global.dictionary.dataview') }}
                </span>
                <div v-else class="overflow-text p-l-1">
                  <p class="mm-text--caption-bold m-a-0">
                    {{
                      $tc(
                        `global.dictionary.${
                          RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP[dependency.operationName] ||
                          dependency.operationName.toLowerCase()
                        }`
                      )
                    }}
                  </p>
                  <mm-tooltip
                    v-if="
                      [RESOURCE_DEPENDENCIES.EXPORT, RESOURCE_DEPENDENCIES.PUBLISH].includes(dependency.operationName)
                    "
                    :label="getExportTooltip(dependency)"
                    wrapper-class="p-r-2"
                  >
                    <div class="d-flex align-items-center">
                      <mm-icon class="m-r-2" color="n500" :name="getConnector(dependency.dbType).smallIcon" />
                      <span class="secondary-text mm-text--caption-regular">
                        {{ getConnector(dependency.dbType).name }}
                      </span>
                    </div>
                  </mm-tooltip>
                  <mm-tooltip
                    v-else-if="dependency.operationName == RESOURCE_DEPENDENCIES.BRANCH_OUT_TO_PROJECT"
                    :label="getDependencyProjectName(dependency)"
                    wrapper-class="overflow-text"
                  >
                    <span class="mm-text--caption-regular"> {{ getDependencyProjectName(dependency) }} </span>
                  </mm-tooltip>
                  <mm-link
                    v-else-if="dependency.operationName == RESOURCE_DEPENDENCIES.LIVE_LINK"
                    class="m-r-3 m-b-1"
                    :label="dependency.url"
                    :href="dependency.url"
                    small
                    @click.stop=""
                  />
                  <mm-tooltip
                    v-else-if="$store.state.resources.resourcesMap[dependency.inDependencyResource.resourceId]"
                    :label="getResourcePath(dependency.inDependencyResource.resourceId).join(' / ')"
                    wrapper-class="overflow-text m-y-2 p-r-2"
                  >
                    <span class="secondary-text mm-text--caption-regular">
                      {{
                        getResourcePath(dependency.inDependencyResource.resourceId, { excludeFolders: true }).join(
                          ' - '
                        )
                      }}
                    </span>
                  </mm-tooltip>
                </div>
              </mm-col>
              <mm-col cols="2" style="text-align: center">
                <mm-icon :name="dependency.dataSync ? 'check' : 'sync_disabled'" />
              </mm-col>
            </mm-row>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!computedDependencies.length" class="no-data">
      <p class="mm-text--h400">{{ $t('dataflow_status.non_sync_tasks.empty_info') }}</p>
      <span>{{ $t('dataflow_status.non_sync_tasks.empty_description') }}</span>
    </div>
  </div>
</template>

<script>
//Constants
import {
  USER_EVENTS,
  RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP,
  EXPORT_CONNECTORS,
  RESOURCE_DEPENDENCIES,
  DATAFLOW_STATUS_NON_SYNC_TASKS_RESOURCE_DEPENDENCIES,
  getOldAppUrl
} from '@/constants'

//Utils
import { getResourcePath, getDependencyProjectName } from '@/modules/resources/utils'
import { getExportTooltip } from '@/utils/string'

// Dependencies
import Vue from 'vue'

// API
import dataEditorApi from '@/modules/data-editor/api/data-editor.api'

// Constants
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

export default {
  name: 'dataflow-status-non-sync-tasks',
  components: { RestrictionsWrapper },
  data: () => ({
    dependenciesSelection: {},
    disabledDependencies: [],
    isLoading: false,
    listItemKey: 1
  }),
  created() {
    this.RESOURCE_DEPENDENCIES = RESOURCE_DEPENDENCIES
    this.RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP = RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP
  },
  computed: {
    computedDependencies() {
      let dependencies = []
      Object.values(this.$store.state.resources.resourcesMap).forEach((r) => {
        for (let direction of ['in', 'out'])
          dependencies.push(
            ...r.dependencies[direction]
              .filter((d) => DATAFLOW_STATUS_NON_SYNC_TASKS_RESOURCE_DEPENDENCIES[direction].includes(d.operationName))
              .filter((d) => !d.dataSync || this.isDependencyDisabled(d))
              .map((d) => ({
                ...d,
                inDependencyResource: r,
                linkView: this.$store.getters['resources/getViewByViewId'](d.linkId) || r
              }))
          )
      })

      this.$emit('update:nonSyncTasksQuantity', dependencies.filter((d) => !d.dataSync).length)

      // Sort by dataset name and view name if dataset is the same
      dependencies.sort((a, b) => {
        const datasetA = this.$store.getters['resources/getDatasetByViewId'](a.linkView.viewId)
        const datasetB = this.$store.getters['resources/getDatasetByViewId'](b.linkView.viewId)
        const datasetNameComparison = datasetA.properties.name.localeCompare(datasetB.properties.name)
        if (datasetNameComparison != 0) return datasetNameComparison
        return a.linkView.properties.name.localeCompare(b.linkView.properties.name)
      })
      return dependencies
    },
    computedIsAnyDependencySelected() {
      return this.computedDependencies.some(
        (d) => this.dependenciesSelection[d.operationId + d.operationName] && !this.isDependencyDisabled(d)
      )
    },
    computedIsMainCheckboxDisabled() {
      return !this.computedDependencies.length || this.computedDependencies.every((d) => this.isDependencyDisabled(d))
    },
    computedAreAllDependenciesSelected: {
      get() {
        const dependenciesSelectionValues = Object.values(this.dependenciesSelection)

        return (
          dependenciesSelectionValues.length > 0 &&
          dependenciesSelectionValues.length == this.computedDependencies.length &&
          dependenciesSelectionValues.every((value) => value)
        )
      },
      set(isSelected) {
        this.dependenciesSelection = this.computedDependencies.reduce(
          (o, d) => ({ ...o, [d.operationId + d.operationName]: this.isDependencyDisabled(d) || isSelected }),
          {}
        )
      }
    }
  },
  methods: {
    getConnector(key) {
      return EXPORT_CONNECTORS.find((c) => c.key == key)
    },
    openView(dependency) {
      this.$userEvents.save(USER_EVENTS.DATAFLOW_STATUS.NON_SYNC_TASKS.OPEN_RESOURCE, {
        id: dependency.linkView.viewId
      })

      // TODO: remove when angular code is gone
      const redirectUrl = `${getOldAppUrl()}/#/workspaces/${this.$store.state.workspaceId}/projects/${
        dependency.linkView.properties.projectId
      }/dataviews/${dependency.linkView.viewId}`
      window.open(redirectUrl, `_mammoth_ds_${dependency.linkView.properties.dataset.id}`)
    },
    toggleRow(dependency) {
      if (this.isLoading) return

      Vue.set(
        this.dependenciesSelection,
        dependency.operationId + dependency.operationName,
        !this.dependenciesSelection[dependency.operationId + dependency.operationName]
      )
      this.listItemKey++
    },
    async setDataSyncOn() {
      this.isLoading = true

      const toggledDataSyncDependencies = this.computedDependencies.filter(
        (d) => this.dependenciesSelection[d.operationId + d.operationName] && !d.dataSync
      )

      await dataEditorApi.toggleStepsDataSyncStatus(
        toggledDataSyncDependencies.map((d) => ({ id: d.operationId, type: d.operationType })),
        true
      )

      this.disabledDependencies.push(...toggledDataSyncDependencies.map((d) => d.operationId + d.operationName))
      this.$toast.show({ content: this.$t('view.dataflow_settings.data_sync_options.success'), status: 'success' })

      this.isLoading = false
    },
    isDependencyDisabled(dependency) {
      // Validate if dataSync is on because it may have been turned off meanwhile from another tab
      return (
        dependency.dataSync && this.disabledDependencies.includes(dependency.operationId + dependency.operationName)
      )
    },
    getResourcePath,
    getExportTooltip,
    getDependencyProjectName
  }
}
</script>

<style lang="scss" scoped>
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';
@import '@mammoth_developer/mm-storybook/src/styles/spacing.scss';

.mm-dataflow-status-non-sync-tasks {
  .tasks-table {
    border: 1px solid var(--mm-color--n80);

    .title-row {
      align-items: center;

      ::v-deep .mm-checkbox--label {
        @extend .mm-text--caption-bold;
      }
    }

    .tasks-list {
      max-height: 264px;

      .mm-row {
        .secondary-text {
          color: var(--mm-color--n500);
        }

        &.active-row:hover {
          background: var(--mm-color--n30);
        }

        .overflow-text,
        span,
        p,
        .mm-link {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        ::v-deep .overflow-text {
          flex-direction: column;
          display: flex;
          max-width: fit-content;
          width: auto;

          span,
          .mm-link {
            max-width: fit-content;
            line-height: 1;
            width: auto;
          }
        }

        .col-overflow {
          ::v-deep .mm-tooltip--slot-wrapper {
            @extend .m-r-3;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
