<template>
  <mm-data-grid
    ref="dataGrid"
    :mode="computedMode"
    :disabled="computedDisabled"
    :rowCount="computedTotalRowCount"
    :isEmpty="computedTotalRowCount === 0"
    :emptyMessage="emptyMessage"
    :columnDefs="columnDefs"
    :columnConfig="columnConfig"
    :onGetRows="onGetRows"
    class="data-editor-grid height-100"
    @init="onInit"
    @gridReady="onGridMounted"
    @exploreIconToggle="onExploreIconToggle"
    @columnMenuAction="onColumnMenuAction"
    @columnRename="onColumnRename"
    @columnMove="onColumnMove"
    @columnResized="onColumnResized"
    @columnSelect="onColumnSelect"
    @multipleColumnSelectedAction="onMultipleColumnSelectedAction"
    @createMetricAction="onCreateMetricAction"
  />
</template>

<script>
import dataEditorApi from '@/modules/data-editor/api/data-editor.api'
import { USER_EVENTS, REQUESTS_STATUS } from '@/constants'
import { mapGetters, mapState } from 'vuex'
import { debounce, isEqual, cloneDeep } from 'lodash'
import emitter from '@/plugins/emitter'

export default {
  name: 'data-editor-grid',
  props: {
    disabled: Boolean
  },
  data: () => ({
    emptyMessage: '',
    columnDefs: [],
    columnConfig: {},
    mode: 'dataset_view'
  }),
  computed: {
    ...mapState({
      computedColumnConfig: (state) => state.dataEditor.columnConfig,
      computedRowCount: (state) => state.dataEditor.rowCount,
      computedFilterCondition: (state) => state.dataEditor.viewsMap[state.dataEditor.viewId]?.filterCondition,
      computedVisibleRowCount: (state) => state.dataEditor.viewsMap[state.dataEditor.viewId]?.visibleRowCount
    }),
    ...mapGetters({
      computedDisplayColumnIds: 'dataEditor/visibleColumns',
      computedSelectedColumnIds: 'exploreSection/selectedColumnIds',
      computedExploreCardColumnIds: 'exploreSection/exploreCardColumnIds'
    }),
    computedColumnDefs() {
      return this.$store.getters['dataEditor/columns'].map((column) => ({
        field: column.id,
        datatype: column.type === 'numeric' ? 'number' : column.type,
        headerName: column.name,
        hide: !column.show
      }))
    },
    computedMode() {
      return this.$store.state.dataEditor.isPreview ||
        this.$store.getters['pipeline/isError'] ||
        this.$store.getters['pipeline/isDraft']
        ? 'dataset_preview'
        : 'dataset_view'
    },
    computedDisabled() {
      return this.$store.state.dataEditor.isLoading || this.disabled || this.$store.state.dataEditor.isDisabled
    },
    computedTotalRowCount() {
      return !this.computedFilterCondition ? this.computedRowCount : this.computedVisibleRowCount
    }
  },
  watch: {
    computedSelectedColumnIds(vals) {
      this.$refs.dataGrid?.selectColumns(vals)
    },
    computedTotalRowCount() {
      this.$refs.dataGrid?.gridApi.setRowCount(this.computedTotalRowCount)
    },
    computedExploreCardColumnIds: {
      handler(newExploreCardColumnIds, oldExploreCardColumnIds) {
        if (!isEqual(newExploreCardColumnIds, oldExploreCardColumnIds)) {
          const exploreCardColumns = this.$refs.dataGrid.gridApi.gridOptionsWrapper.gridOptions.context
            .exploreCardColumns
          if (!isEqual(exploreCardColumns, newExploreCardColumnIds)) {
            this.$refs.dataGrid.setExploreCardColumns(newExploreCardColumnIds)
          }
        }
      },
      deep: true
    }
  },
  methods: {
    async setDisplayProperties(displayProperties) {
      return await dataEditorApi.setDisplayProperties({
        viewId: this.$store.getters['dataEditor/view'].viewId,
        displayProperties
      })
    },
    async revertColumnRename({ colId }) {
      let originalName = this.columnConfig[colId]?.originalName
      if (originalName) {
        let displayProperties = {
          REVERT_COLUMN_NAMES: [colId]
        }
        const response = await this.setDisplayProperties(displayProperties)
        if (response.status === 'error') {
          this.$toast.show({ content: this.$t('data_editor.data_grid.error.apply_format'), status: 'error' })
        } else {
          this.columnDefs = this.computedColumnDefs.map((column) => {
            if (column.field === colId) {
              column.headerName = originalName
            }
            return column
          })
          delete this.columnConfig[colId].originalName
          this.$store.commit('dataEditor/setColumnConfig', this.columnConfig)
        }
        this.$refs.dataGrid.clearColumnNewNames()
      }
    },
    async onInit({ mmDataGrid }) {
      this.$emit('init', mmDataGrid)
    },
    onGridMounted() {
      const exploreCardColumnIds = this.$store.getters['exploreSection/exploreCardColumnIds']
      this.$refs.dataGrid.setExploreCardColumns(exploreCardColumnIds)
    },
    onExploreIconToggle({ exploreCardColumns, columnId }) {
      let debounced = debounce(() => {
        this.$store.commit('dataEditor/setExploreCardColumns', {
          columnIds: exploreCardColumns
        })
        this.$emit('exploreIconToggle', exploreCardColumns, columnId)
      }, 100)
      debounced()
    },
    async onGetRows(startRow, endRow) {
      const viewData = await dataEditorApi.getViewData({
        viewId: this.$store.getters['dataEditor/view'].viewId,
        columns: this.computedDisplayColumnIds,
        filterCondition: this.computedFilterCondition,
        offset: startRow + 1,
        limit: endRow - startRow,
        sequence: this.$store.state.dataEditor.sequence
      })

      if (viewData.status === REQUESTS_STATUS.ERROR) {
        this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })
      }

      if (!viewData.paging?.count || this.computedTotalRowCount === 0 || !viewData?.data) {
        this.emptyMessage = this.$t('data_editor.data_grid.error.empty_grid')
      }
      this.columnDefs = this.computedColumnDefs
      this.columnConfig = cloneDeep(this.computedColumnConfig)
      return viewData?.data || []
    },
    async onColumnMenuAction(data) {
      if (data.name == 'revertToOriginalColumnName') {
        this.revertColumnRename(data)
      } else if (data.name === 'treatAsUrl') {
        let displayProperties = {
          FORMAT_INFO: {
            column_7: {
              IS_URL: data.value
            }
          }
        }
        const response = await this.setDisplayProperties(displayProperties)
        if (response.status === 'error') {
          this.$toast.show({ content: this.$t('data_editor.data_grid.error.apply_format'), status: 'error' })
        } else {
          this.columnConfig[data.colId].treatAsUrl = data.value
          this.$store.commit('dataEditor/setColumnConfig', this.columnConfig)
          this.$refs.dataGrid?.resetDataSource()
        }
      } else {
        this.$emit('columnMenuAction', data)
      }
    },
    onColumnSelect(selectedColumnIds) {
      this.$store.commit('dataEditor/setColumnSelection', {
        selected: true,
        columnIds: selectedColumnIds
      })
      const notSelectedColumns = this.$store.getters['dataEditor/columns']
        .filter((column) => !selectedColumnIds.includes(column.id))
        .map((col) => col.id)
      this.$store.commit('dataEditor/setColumnSelection', {
        selected: false,
        columnIds: notSelectedColumns
      })
    },
    async onColumnRename(data) {
      const { colId, newName, oldName } = data
      let displayProperties = {
        COLUMN_NAMES: {
          [colId]: newName
        },
        COLUMN_ORDER: this.$store.getters['dataEditor/columns'].reduce((columnOrder, column, index) => {
          columnOrder[index] = column.id
          return columnOrder
        }, {})
      }
      const response = await this.setDisplayProperties(displayProperties)
      if (response.status === 'error') {
        this.$toast.show({ content: this.$t('data_editor.data_grid.error.apply_format'), status: 'error' })
      } else {
        this.columnDefs = this.computedColumnDefs.map((column) => {
          if (column.field === colId) {
            return { ...column, headerName: newName }
          }
          return column
        })
        if (!this.columnConfig[data.colId].originalName) {
          this.columnConfig[data.colId].originalName = oldName
        }
        this.$store.commit('dataEditor/setColumnConfig', this.columnConfig)
      }
      this.$refs.dataGrid.clearColumnNewNames()
      this.$userEvents.save(USER_EVENTS.DATA_EDITOR_GRID.RENAME_COLUMN, {
        viewId: this.$store.state.dataEditor.viewId,
        data
      })
    },
    async onColumnMove(data) {
      if (this.$store.state.dataEditor.isPreview || !data.column || !data.toIndex) return
      let columnIds = this.$store.getters['dataEditor/columns'].map((column) => column.id)
      columnIds = columnIds.filter((col) => col != data.column)
      columnIds.splice(data.toIndex - 1, 0, data.column)
      const columnOrder = columnIds.reduce((columnOrder, columnId, index) => {
        columnOrder[index] = columnId
        return columnOrder
      }, {})

      const newColumnDefs = columnIds.map((colId) => this.computedColumnDefs.find((c) => c.field === colId))
      if (!isEqual(newColumnDefs, this.computedColumnDefs)) {
        this.$store.commit('dataEditor/setDisabled', true)
        const response = await this.setDisplayProperties({ COLUMN_ORDER: columnOrder })
        if (response.status === 'error') {
          this.$toast.show({ content: this.$t('data_editor.data_grid.error.apply_format'), status: 'error' })
        } else {
          this.columnDefs = columnIds.map((colId) => this.computedColumnDefs.find((c) => c.field === colId))
        }
        this.$store.commit('dataEditor/setDisabled', false)
        this.$userEvents.save(USER_EVENTS.DATA_EDITOR_GRID.REORDER_COLUMNS, {
          viewId: this.$store.state.dataEditor.viewId,
          columnOrder
        })
      }
    },
    async onColumnResized(data) {
      if (this.$store.state.dataEditor.isPreview) return
      let newWidths = {}
      this.columnConfig[data.colId].width = data.width
      Object.keys(this.columnConfig).forEach((column) => {
        if (column === data.colId) newWidths[column] = data.width
        else newWidths[column] = this.columnConfig[column].width
      })
      await this.setDisplayProperties({ COLUMN_WIDTHS: newWidths })
      this.$userEvents.save(USER_EVENTS.DATA_EDITOR_GRID.RESIZE_COLUMNS, {
        viewId: this.$store.state.dataEditor.viewId,
        newWidths
      })
    },
    onMultipleColumnSelectedAction(data) {
      if (this.$store.state.dataEditor.isPreview) return
      this.$emit('multipleColumnSelectionAction', data)
    },
    onCreateMetricAction(data) {
      this.$emit('createMetricAction', data)
    }
  },
  created() {
    emitter.on('dataEditorScrollToColumn', (e) => this.$refs.dataGrid.scrollToColumn(e.columnId))
  },
  beforeDestroy() {
    emitter.off('dataEditorScrollToColumn')
  }
}
</script>
<style lang="scss">
.data-editor-grid {
  .mm-data-grid-metric-sidebar {
    display: none;
  }
}
</style>
