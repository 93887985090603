'use strict';

import filterStorageService from "../explore/filterStorage.service";
import jwt_decode from "jwt-decode";
import keyCloak from "../../../vueApp/src/mm-frontend/src/plugins/keycloak"

import VueApi from '../../../vueApp/src/mm-frontend/src/api'

/**
 * @ngInject
 * Authentication service
 * @namespace AuthService
 */
AuthService.$inject = ['$q', '$http', '$window', '$rootScope', 'config', 'UserWorkspace', 'eventCallbackManagerFactory', 'VuexStore', 'CacheFactory'];
export function AuthService($q, $http, $window, $rootScope, config, UserWorkspace, eventCallbackManagerFactory, $store, CacheFactory) {
  var logout_event = new eventCallbackManagerFactory('on_logout');
  var on_check_token_success = new eventCallbackManagerFactory('on_check_token_success');
  var service = {
    logout: logout,
    on_logout: logout_event.add_callback,
    on_check_token_success: on_check_token_success.add_callback,
    check_token: check_token,
    reset_password: reset_password,
    token: null
  };
  // Storing the keycloak instance in the Vue store to use the same instance in Vue side
  window.addEventListener('storage', (event) => {
    if (event.key === 'authenticated') {
      var logoutOptions = { redirectUri : window.location.href };
      keyCloak.logout(logoutOptions)
    }
  })

  var decoded_token = jwt_decode(keyCloak.token);
  UserWorkspace.loggedInEmail = decoded_token['email']
  UserWorkspace.is_super_admin = decoded_token['super_admin'];
  $store.commit('setUser', { name: decoded_token['given_name'], email: decoded_token['email'] })
  add_event_listeners();
  return service;

  function logout() {
    logout_event.fire_event();
    UserWorkspace.reset();
    new filterStorageService().resetFilters();
    // clear cache
    CacheFactory.destroyAll();
    keyCloak.logout()
  }

  function add_event_listeners() {
    $rootScope.$on(config.events.auth.logout, function () {
      logout();
    });
    $rootScope.$on(config.events.auth.checkTokenEvent, function () {
      check_token();
    });
  }

  /**
   * @desc Retrieves the token from cookie and validates it with the server, if no token is found in cookie, the
   * returned promise is rejected.
   *
   *    NOTE: This function needs to be called on every browser load because it sets the token as a default
   *    in the header.
   *
   * @returns {*}
   */
  async function check_token () {
    let deferred = $q.defer();

      var isInAdmin = $window.location.href.includes('/#/admin') || $window.location.href.includes('/#/newadmin')

      if (UserWorkspace.is_super_admin && !isInAdmin) {
        $window.location.href = '/#/newadmin';
      }


      let http_data = {headers: {}};

    let success_cb = function(data) {
      const redirectUrl = data.redirect_url;
      if (redirectUrl) {
        // Handle the redirect
        $window.location.assign(redirectUrl);
      }
      if (!UserWorkspace.workspace && !UserWorkspace.is_super_admin) {
        if (isInAdmin) {
          $window.location.href = '/#/landing';
        }
        UserWorkspace.get_self_details().then(function () {
          UserWorkspace.auto_select_workspace().then(function () {
            on_check_token_success.fire_event();
            deferred.resolve();
          }, function auto_select_failed(data) {
            if (data.no_workspace) {
              logout();
            }
          });
        })
      } else {
        on_check_token_success.fire_event();
        deferred.resolve();
      }
    }
    $http.post(config.api.set_sec, http_data).then(function(response) {
      success_cb(response.data);
    });
    return deferred.promise;
}

  function reset_password (email, recaptcha_response) {
    var deferred = $q.defer();
    var post_params = {
      'email': email
    };
    if (recaptcha_response && recaptcha_response.length) {
      post_params['recaptcha_response'] = recaptcha_response;
    }

    $http.post(config.api.resetPassword, post_params).then(function success_cb(response) {
      deferred.resolve(response.data);
    }).catch(function (response) {
      deferred.reject(response.data, response.status);
    });
    return deferred.promise;
  }

}

/**
 * @ngInject
 * Interceptor to logout when the token becomes invalid
 */
HttpRequestInterceptor.$inject = ['$q', '$rootScope', 'config', 'keyCloak'];
export function HttpRequestInterceptor($q, $rootScope, config, keyCloak) {
  let ANTI_CSRF_HEADER_NAME = 'X-CSRF-TOKEN';
  let anti_csrf_token = '';
  let AUTHORIZATION ='Authorization'
  let saved_token = ''

  async function bearer_headers() {
    await keyCloak.updateToken(20)
    saved_token = keyCloak.token
    return {
      [AUTHORIZATION]: "bearer "+ saved_token
    };
  }

  function get_saved_token(){
    // Since the resources call is running often and refreshes the token the token is updated eventhough it is saved
    return {
      [AUTHORIZATION]: "bearer "+ saved_token
    };
  }

  return {
    response: function (response) {
      //save anti csrf token from login or check token apis or totp api
      if (response.config.url.endsWith(config.api.token) || response.config.url.endsWith(config.api.login) || response.config.url.endsWith(config.api.totp)) {
        anti_csrf_token = response.headers(ANTI_CSRF_HEADER_NAME);
        VueApi.defaults.headers[ANTI_CSRF_HEADER_NAME] = anti_csrf_token
      }
      $rootScope.badTokenRetriesCount = 0;
      return response;
    },
    responseError: function (response) {
      if (response.status == 403 && response.data.ERROR_CODE == 12) {
        // $http depends on this interceptor, so service which have $http dependency can not become a dependency
        // for this interceptor (circular)
        $rootScope.badTokenRetriesCount += 1;
        if ($rootScope.badTokenRetriesCount === 3)
          $rootScope.$broadcast(config.events.auth.checkTokenEvent);
      }
      if (response.status == 403 && response.data.ERROR_CODE == 15)
        $rootScope.$broadcast(config.events.auth.reselectWorkspace);
      return $q.reject(response);
    },
    request: async function (config) {
      // add keycloak token to the header for all requests
      // Todo: Revisit the logic When resource polling is gone
      const _bearer_headers = await bearer_headers();
      config.headers = {...config.headers, ..._bearer_headers};
      return config;
    },
    bearer_headers: bearer_headers,
    get_saved_token:get_saved_token
  };
}

