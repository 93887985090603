<template>
  <div>
    <mm-button
      :label="$t('data_library.add_folder.create_folder')"
      icon="folder"
      objective="secondary"
      @click="isModalOpen = true"
    />

    <mm-modal
      v-model="isModalOpen"
      :title="$t('data_library.add_folder.title')"
      content-class="mm-data-library-add-folder"
      width="480"
      eager
      closable
    >
      <mm-text-input
        v-model="folderName"
        class="m-t-7"
        ref="nameInput"
        :title="$t('data_library.add_folder.new_folder_name')"
        :placeholder="$t('data_library.add_folder.placeholder')"
        :input-attrs="{ maxlength: FOLDER_NAME_MAX_LENGTH }"
        @enterKeyPressed="addFolder"
      />

      <template #actions>
        <mm-button :label="$t('global.dictionary.cancel')" objective="secondary" @click="isModalOpen = false" />
        <mm-button
          class="m-l-3"
          :label="$t('data_library.add_folder.create_folder')"
          :loading="isLoading"
          :disabled="!folderName.trim()"
          @click="addFolder"
        />
      </template>
    </mm-modal>
  </div>
</template>

<script>
// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

// Constants
import { FOLDER_NAME_MAX_LENGTH } from '@/constants'

export default {
  name: 'data-library-add-folder',
  props: {
    parentFolderResourceId: [String, Number]
  },
  data: () => ({
    isModalOpen: false,
    folderName: '',
    isLoading: false
  }),
  watch: {
    isModalOpen(val) {
      if (val) setTimeout(() => this.$refs.nameInput.focusInputField(), 10)
      else this.folderName = ''
    }
  },
  created() {
    this.FOLDER_NAME_MAX_LENGTH = FOLDER_NAME_MAX_LENGTH
  },
  methods: {
    async addFolder() {
      if (!this.folderName.trim() || this.isLoading) return

      this.isLoading = true

      const response = await dataLibraryApi.addFolder(
        this.$store.state.projectId,
        this.folderName,
        this.parentFolderResourceId
      )

      if (response?.data?.resource_id) {
        this.isModalOpen = false
        this.$toast.show(this.$t('data_library.add_folder.success', response.data))
      } else this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })

      this.isLoading = false
    }
  }
}
</script>
