/**
 * Created by ranjith on 15/11/18.
 */
import * as angular from "angular";
import * as _ from 'lodash-es';


actionSequenceSelect.$inject = ['config'];
export function actionSequenceSelect(config){
  return {
    restrict: 'E',
    replace: true,
    templateUrl: config.templates.actionSequenceSelect,
    controller: 'ActionSequenceSelectController',
    controllerAs: 'asc',
    bindToController: true,
    require: 'ngModel',
    scope: {
      ngModel: '=',
      dataviewId: '='
    }
  }
}


export class ActionSequenceSelectController{
  public sequenceOptions;
  public ngModel;
  public taskService;
  public dataviewId;
  public changeEvent;
  public onChange;
  public tasks;
  public $onInit: () => void;



  static $inject = ['TaskServiceFactory', 'eventCallbackManagerFactory'];
  public constructor(public TaskServiceFactory, public eventCallbackManagerFactory){
    this.$onInit = function (){
      this.init();
    }
  }

  public init(){
    let defaultOptions = [
      {internal: 0, display: 'at the beginning of pipeline'},
      {internal: null, display: 'at the end of pipeline'},
    ];
    let sequenceOptions = [defaultOptions[0]];
    this.taskService = this.TaskServiceFactory.get_by_dataview_id(this.dataviewId);
    this.changeEvent = new this.eventCallbackManagerFactory('ActionSequenceSelectController' + this.dataviewId);
    this.onChange = this.changeEvent.add_callback;
    this.tasks = this.taskService.orderedList;
    _.forEach(this.tasks, function (t) {
      let x = {
        internal: t.sequence,
        display: 'After task ' + t.sequence + ' (' + t.title + ')'
      };
      sequenceOptions.push(x);
    });
    sequenceOptions.push(defaultOptions[1]);
    this.sequenceOptions = sequenceOptions;
  }

  public handleChangRequest(){

  }
}
