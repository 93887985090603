export default {
  methods: {
    mixinPlans_getPlanFeatures(plan) {
      let features = [
        this.$t('settings.workspace.plan.pricing.starting_storage', {
          storage: plan.startingStorage.toLocaleString()
        })
      ]

      if (plan.isAdditionalStorageAllowed)
        features.push(
          this.$t('settings.workspace.plan.pricing.add_storage', { storage: plan.maxStorage.toLocaleString() })
        )
      features.push(this.$t('settings.workspace.plan.pricing.seats', { quantity: plan.startingUsersQuantity }))
      if (plan.areNewUsersAllowed) features.push(this.$t('settings.workspace.plan.pricing.add_seats'))
      if (plan.processingCapacity) {
        const capacity = this.$t(`settings.workspace.plan.pricing.processing.${plan.processingCapacity}`)
        features.push(this.$t('settings.workspace.plan.pricing.processing.capacity', { capacity }))
      }

      return features
    }
  }
}
