/**
 * @ngInject
 *
 * @param DatasourceService
 * @param $q
 * @param $timeout
 * @returns {{getByWskpId: getByDataviewId}}
 */
metricService.$inject = ['DatasourceService', '$q', '$timeout'];
export function metricService(DatasourceService, $q, $timeout){
  return {
    getByDataviewId: getByDataviewId
  };

  function getByDataviewId(dataview_id){
    var deferred = $q.defer();
    $timeout(function(){
      var list = DatasourceService.list;
      Object.keys(list).forEach(function (object_id) {

        var item = list[object_id];
        if (item.type == 'datasource') {
          var ws = item.dataviews[dataview_id];
          if (ws) {
            deferred.resolve(ws.metrics);
          }
        }
      });
      deferred.reject();
    }, 1000)


    return deferred.promise;
  }
}

