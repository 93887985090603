import * as angular from 'angular';
import * as _ from 'lodash-es';
import set = Reflect.set;

landingList.$inject = ['c', 'eventCallbackManagerFactory', '$timeout', 'labelService', '$state', 'config',
  '$q', 'utils', 'analyticsService'];
export function landingList(c, eventCallbackManagerFactory, $timeout, labelService, $store, config,
                            $q, utils, analyticsService) {

  class LabelView {
    public parent: undefined|LabelView = undefined;
    public label: any;
    public contents = []; // all items
    public folderLabelViews = [];  // only folderLabelViews
    public breadcrumbs = [];
    public name: any;
    public fileCount: number = 0;
    public folderCount: number = 0;
    public shortName: string;

    public constructor(label?: any) {
      if (label) {
        this.setLabel(label);
      }
    }

    public setLabel(label?: any) {
      this.label = label;
      this.name = label.name;
      this.shortName = utils.string.addCentreEllipsis(label.name, 20);
    }
  }

  let listUpdateEvent = new eventCallbackManagerFactory('mainListUpdated');
  let labelViewChanged = new eventCallbackManagerFactory('labelViewChanged');
  let labelViewMap = {};
  labelViewMap[c.HOME] = new LabelView();
  labelViewMap[c.HOME].name = 'Data Library';
  labelViewMap[c.HOME].shortName = 'Data Library';
  labelViewMap[c.HOME].breadcrumbs = [labelViewMap[c.HOME]];

  const publishedReportsView = new LabelView();
  publishedReportsView.name = 'Reports';
  publishedReportsView.shortName = 'Reports';
  publishedReportsView.breadcrumbs = [publishedReportsView];
  labelViewMap[c.PUBLISHED_REPORTS] = publishedReportsView;

  let initPromise = $q.defer();
  let service = {
    // properties
    inited: false,
    initPromise: initPromise,
    list: [],
    labelViewMap: labelViewMap,
    currentLabelView: undefined,
    currentLabelResourceId: undefined,
    sortOrder: {by: c.sortOptions.modified, reverse: true},
    //events
    onUpdate: listUpdateEvent.add_callback,
    onLabelViewChange: labelViewChanged.add_callback,
    //methods
    setSortOrder: setSortOrder,
    setCurrentLabelView: setCurrentLabelView,
    sortList: sortFn,
    // all published reports
    publishedReports: [],
    update: updateLabelViewMap,
    reset: reset
  };

  // on update of any list update main list
  return service;

  function reset() {
    updateLabelViewMap({}, true)
    service.inited = false
    service.initPromise = $q.defer();
    service.currentLabelView = undefined;
    service.currentLabelResourceId = undefined;
  }

  function setCurrentLabelView(resource_id) {
    if (!resource_id || !_.has(labelViewMap, resource_id)) {
      resource_id = c.HOME;
    }
    const upcomingLabelView = labelViewMap[resource_id];
    
    // update state change synchronously
    // earlier this used to be async with 100 ms delay
    // but if we switch state multiple times within that time, views
    // keep switching as each new update creates another async operation.
    if (upcomingLabelView === service.currentLabelView) {
      return;
    }
    service.currentLabelView = upcomingLabelView;
    service.currentLabelResourceId = resource_id == c.HOME ? undefined : resource_id;
    sortMainList(service.currentLabelView.contents);
    labelViewChanged.fire_event();
  }

  function updateLabelViewMap(resourcesMap, reset = false) {
    let existingLabelRIDs = _.pull(_.keys(labelViewMap), c.HOME);
    // create, delete LVMs according to the latest list of LVMs
    _.forEach(labelService.labelsDict, function (label) {
      if (!_.has(labelViewMap, label.resource_id)) {
        labelViewMap[label.resource_id] = new LabelView();
      }
      let lvm = labelViewMap[label.resource_id];
      lvm.setLabel(label);
      _.pull(existingLabelRIDs, label.resource_id + '');
    });
    _.forEach(existingLabelRIDs, function (RIDToDelete) {
      delete labelViewMap[parseInt(RIDToDelete)];
    });

    // update parent refs and contents of each LVM
    let resToBeAddedToRoot = [];
    _.forEach(resourcesMap, (o, k) => {o.type != 'label' && resToBeAddedToRoot.push(k)});
    _.forEach(labelViewMap, function (lvm: any, k) {
        if (lvm !== publishedReportsView) {
          lvm.contents.splice(0, lvm.contents.length);
        }
        lvm.folderLabelViews.splice(0, lvm.folderLabelViews.length);
        lvm.fileCount = 0;
        lvm.folderCount = 0;
    });
    _.forEach(labelViewMap, function (lvm: any, k) {
      if (k !== c.HOME && k !== c.PUBLISHED_REPORTS) {
        _.forEach(lvm.label.target_resource_ids, function (rid) {
          const res = _.get(resourcesMap, rid);
          if (res) {
            lvm.contents.push(res);
            lvm.fileCount += 1;
          }
          _.pull(resToBeAddedToRoot, rid + '');
        });
        let parentRID = lvm.label.parent_resource_id;
        if (!parentRID) {
          parentRID = c.HOME;
        }
        let parent = labelViewMap[parentRID] || labelViewMap[c.HOME];
        parent.contents.push(lvm.label);
        parent.folderCount += 1;
        parent.folderLabelViews.push(lvm);
        lvm.parent = parent;
        lvm.breadcrumbs.splice(0, lvm.breadcrumbs.length);
        lvm.breadcrumbs.push(lvm);
        while (parent !== labelViewMap[c.HOME]) {
          lvm.breadcrumbs.push(parent);
          parent = labelViewMap[parent.label.parent_resource_id] || labelViewMap[c.HOME]
        }
        lvm.breadcrumbs.push(labelViewMap[c.HOME]);
        _.reverse(lvm.breadcrumbs);
      }
    });

    const prvm = labelViewMap[c.PUBLISHED_REPORTS];
    prvm.contents = service.publishedReports

    const lvm = labelViewMap[c.HOME];
    _.forEach(resToBeAddedToRoot, function (rid) {
      const res = resourcesMap[rid];
      if (res.type !== "dataview") {
        lvm.contents.push(res);
      }
    });
    _.forEach(labelViewMap, function (_lvm: any, k) {
      sortMainList(_lvm.contents);
      _lvm.countsString = _generateCountsString(_lvm);
    });
    // sortMainList(lvm.contents);
    if(!reset) {
      $timeout(function () {
        service.inited = true;
        listUpdateEvent.fire_event();
        if (!service.initPromise.promise.$$state.status) {
          service.initPromise.resolve();
        }
      }, 200);
    }
  }

  function _generateCountsString(lvm) {
    const counts = [];
    if (lvm.folderCount) {
      counts.push(lvm.folderCount > 1 ? `${lvm.folderCount} folders` : `1 folder`);
    }
    if (lvm.fileCount) {
      counts.push(lvm.fileCount > 1 ? `${lvm.fileCount} files` : `1 file`);
    }
    return counts.length ? `(${counts.join(", ")})` : "(empty)";
  }
  function sortMainList(mainList) {
    sortFn(mainList);
    angular.forEach(mainList, function (item) {
      if (item.type === c.resourceTypes.ds && item.dataview_count > 1) {
        sortFn(item.dataviews_list);
      }
    });
  }

  function sortFn(list) {
    if (service.sortOrder.by == c.sortOptions.modified) {
      list.sort(function sortByModified(a, b) {
        var aHighest = getHighestModified(a);
        var bHighest = getHighestModified(b);
        var sort = aHighest < bHighest ? -1 : (aHighest === bHighest) ? 0 : 1;
        return service.sortOrder.reverse ? 0 - sort : sort;
      });
    }
    if (service.sortOrder.by == c.sortOptions.name) {
      list.sort(function sortByName(a, b) {
        var sort = a.name < b.name ? -1 : 1;
        return service.sortOrder.reverse ? 0 - sort : sort;
      });
    }
  }

  function getHighestModified(obj) {
    if (obj.type === c.resourceTypes.ds) {
      let highestModified = obj.updated_at;
      angular.forEach(obj.dataviews, function (ws) {
        if (ws.updated_at > highestModified) {
          highestModified = ws.updated_at;
        }
      });
      return highestModified;
    } else {
      return obj.updated_at;
    }
  }

  function setSortOrder(by) {
    analyticsService.userEventTrack(c.userEvents.landingPage.sortDatasets, { eventOrigin: 'landingPage', sortBy: by})
    if (service.sortOrder.by != by) {
      service.sortOrder.by = by;
    } else {
      service.sortOrder.reverse = !service.sortOrder.reverse;
    }
    _.forEach(service.labelViewMap, (lvm: any) => sortMainList(lvm.contents));
  }
}
