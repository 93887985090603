import * as _ from 'lodash-es';
import { VueInstance } from "../../../vueApp/src/ngVueBridgeCode/ngVueComponentsModule.js"

export class toastNotification {
  static $inject = ['Notification'];
  constructor(private Notification) {

  }

  private displayNotificationByTemplate(content, status) {
    if (typeof content === 'string' || content instanceof String){
      // @ts-ignore:next-line
      VueInstance.$toast.show({ content, status })
    } else {
      // @ts-ignore:next-line
      VueInstance.$toast.show({ content: content.message, status, timeout: content.delay, ...content })
    }
  }
  public success(config: string|any) {
    this.displayNotificationByTemplate(config, 'success');
  }

  public error(config: string|any) {
    this.displayNotificationByTemplate(config, 'error');
  }

  public warning(config: string|any) {
    this.displayNotificationByTemplate(config, 'warning');
  }

  public info(config: string|any) {
    this.displayNotificationByTemplate(config, 'success');
  }
}
