/*jslint node: true */
import * as _ from 'lodash-es';

/**
 * @ngInject
 */
/**
 * @Code reference 5
 * -> Vuex store for data flow
 * @Comment inject VuexStore as dependency in an angular controller
 * @link: https://mammoth.atlassian.net/wiki/spaces/PD/pages/1363247150/Vue+Porting#Data-flow
 */
adminController.$inject= ['adminService', 'VuexStore', 'Auth', 'appMetaInfo', '$timeout', 'Notification', 'resources', '$uibModal', '$rootScope'];
/**
 * @Code reference 5
 * -> Vuex store for data flow
 * @Comment alias VuexStore as $store
 * @link: https://mammoth.atlassian.net/wiki/spaces/PD/pages/1363247150/Vue+Porting#Data-flow
 */
export function adminController(adminService, $store, Auth, appMetaInfo, $timeout, Notification, resources, $uibModal, $rootScope){
  this.$onInit = function () {
    var vm = this;

    vm.domains = [];
    vm.workspaces = [];
    vm.registrations = [];
    vm.uiHelper = {};
    vm.subscriptions = [];
    vm.integrations = [];
    vm.smsPlans = [];

    vm.logout = logout;
    /**
     * @Code reference 5
     * -> Vuex store for data flow
     * @Comment set entity by in VuexStore by calling dispatch with payload
     * @link: https://mammoth.atlassian.net/wiki/spaces/PD/pages/1363247150/Vue+Porting#Data-flow
     */
    $store.dispatch('adminAuth/setLogout', logout)

    vm.getDomains = getDomains;
    vm.getWorkspaces = getWorkspaces;
    vm.getRegistrations = getRegistrations;

    vm.addWorkspace = addWorkspace;

    vm.reset = reset;
    vm.addDomain = addDomain;
    vm.approveRegistration = approveRegistration;
    vm.listIntegrations = listIntegrations;
    vm.changeSubscriptionType = changeSubscriptionType;
    vm.setIntegrationStatus = setIntegrationStatus;
    vm.filterDomains = filterDomains;
    vm.loadWorkspaceDetails = loadWorkspaceDeatils;
    vm.initNewUser = initNewUser;
    vm.addUserToWorkspace = addUserToWorkspace;
    vm.transferOwnerShip = transferOwnerShip;
    vm.deleteUserFromWorkspace = deleteUserFromWorkspace;
    vm.listSmsPlans = listSmsPlans;
    vm.deploymentEnv = determineEnv();
    vm.getDefaultsOwners = function () {
      return [];
    };

    Auth.check_token().then(reset);


    function logout() {
      Auth.logout()
    }


    function getDomains() {
      adminService.getDomains().then(function (domains) {
        vm.domains = domains;
        if (domains.length) {
          vm.new_workspace.backend_domain_id = domains.filter(d => !d.used)[0].id;
        }
      })
    }


    function getRegistrations() {
      adminService.getRegistrations().then(function (registrations) {
        vm.registrations = registrations;
        vm.registrations.forEach(function (r) {
          r.approve = false;
        })
      })
    }

    function getWorkspaces() {

      adminService.getWorkspaces().then(function (workspaces) {
        vm.workspaces = workspaces;
        vm.workspaces.forEach(function (acc) {
          acc.addUser = function () {
            if (!acc.new_user.first_name || !acc.new_user.last_name || !acc.new_user.email || !acc.new_user.permission) {
              return;
            }
            // If the owner for the workspace already exist, then send details of owner and workspace to the request
            if (acc.ownerUser) {
              adminService.addUser(acc.new_user.first_name, acc.new_user.last_name, acc.new_user.email, acc.ownerUser, acc.id).then(
                function (data) {
                  addUserToWorkspace(acc, data, acc.new_user.permission);
                }
              )
            } else {
              adminService.addUser(acc.new_user.first_name, acc.new_user.last_name, acc.new_user.email).then(
                function (data) {
                  addUserToWorkspace(acc, data, acc.new_user.permission);
                }
              )
            }
          }
        })
      })
    }


    function initNewUser(acc) {
      acc.onlyOwnerAddMode = acc.total_users == 0;
      acc.new_user = {
        email: null,
        last_name: null,
        first_name: null,
        permission: 'analyst'
      };

      if (acc.onlyOwnerAddMode) {
        acc.new_user.permission = 'owner';
      }
    }

    function loadWorkspaceDeatils(acc) {
      adminService.getWorkspaceDetails(acc.id).then(function (users) {
        acc.users = users;
        acc.loadedDetails = true;
        acc.add_new_user = false;
        acc.total_users = acc.users.length;
        acc.hasOwner = false;
        _.forEach(acc.users, function (u) {
          if (u.user_permissions) {
            if (u.user_permissions.owner == 'ACTIVE') {
              acc.hasOwner = true;
              acc.ownerUser = u;
              u.primaryPermission = 'owner';
            } else if (u.user_permissions.admin == 'ACTIVE') {
              u.primaryPermission = 'admin';
            } else if (u.user_permissions.analyst == 'ACTIVE') {
              u.primaryPermission = 'analyst';
            }
          }
        });
      });
      adminService.loadWorkspaceSMSUrl(acc.id).then(function (data) {
        acc.smsData = data;
        acc.smsDataJson = JSON.stringify(acc.smsData, null, 2);
      });
    }

    function addUserToWorkspace(workspace, user, permission) {
      adminService.linkUser(workspace.id, user.id, permission).then(function () {
        workspace.total_users += 1;
        loadWorkspaceDeatils(workspace);
      });
    }

    function transferOwnerShip(workspace, user) {
      let oldOwnerId = workspace.ownerUser.id;
      adminService.linkUser(workspace.id, user.id, 'owner', user.primaryPermission).then(function () {
        adminService.linkUser(workspace.id, oldOwnerId, 'admin', 'owner').then(function () {
          loadWorkspaceDeatils(workspace);
        });
      });
    }

    function deleteUserFromWorkspace(acc, user) {
      let scope = $rootScope.$new();
      scope.user = user;
      var modal = $uibModal.open({
        templateUrl: 'userDeleteConfirmation.admin.tpl.html',
        scope: scope,
        windowClass: 'modal-size-medium'
      });

      modal.result.then(function () {
        adminService.unLinkUser(acc.id, user.id).then(function () {
          loadWorkspaceDeatils(acc);
        }, function (e) {
          Notification.error(e.data.ERROR_MESSAGE);
        });
      });
    }


    function approveRegistration(registration, backend_domain_id, workspace_name) {
      adminService.approveRegistration(registration.id, backend_domain_id, registration.email, workspace_name).then(
        function () {
          Notification.success('Done!');
          $timeout(getRegistrations, 1000);
          $timeout(getWorkspaces, 2000);
          $timeout(getDomains, 1000);
        }
      );
    }

    function reset() {
      vm.add_new_domain = false;
      vm.add_new_workspace = false;
      vm.new_domain = {
        name: null,
        description: null,
        backendParams: {
          "host": null,
          "dbname": null,
          "username": null,
          "password": null
        }
      };
      vm.new_workspace = {
        name: null,
        backend_domain_id: 1
      };
      listSubscriptionGroups();
      listSmsPlans();
      getWorkspaces();
      getDomains();
      getRegistrations();
      listIntegrations();
      appMetaInfo.pageLoaded();
      resources.stop_update_polling();
    }

    function addDomain() {
      if (!vm.new_domain.name || !vm.new_domain.description || !vm.new_domain.backendParams.host || !vm.new_domain.backendParams.dbname || !vm.new_domain.backendParams.username || !vm.new_domain.backendParams.password) {
        return
      }
      adminService.addDomain(vm.new_domain.name, vm.new_domain.backendParams, vm.new_domain.description).then(vm.reset)
    }

    function addWorkspace() {
      if (!vm.new_workspace.name || !vm.new_workspace.backend_domain_id) {
        return
      }
      adminService.addWorkspace(vm.new_workspace.name, vm.new_workspace.backend_domain_id).then(vm.reset, console.error)
    }

    function filterDomains(d) {
      return !d.used;
    }

    function listSubscriptionGroups() {
      adminService.listSubscriptionGroups().then(function (data) {
        vm.subscriptions = data.subscriptions;
      })
    }

    function listSmsPlans() {
      adminService.listSmsPlans().then(function (data) {
        vm.smsPlans = data.plans;
      })
    }

    function changeSubscriptionType(subscription_id, workspace_id) {
      adminService.associateSubscriptionGroupToWorkspace(subscription_id, workspace_id).then(getWorkspaces);
    }

    function listIntegrations() {
      adminService.listIntegrations().then(function (data) {
        vm.integrations = data.integrations;
      })
    }

    function setIntegrationStatus(integration_id, enabled) {
      adminService.enableDisableIntegration(integration_id, enabled).then(function () {
        listIntegrations();
        Notification.success("Saved!");
      })
    }

    function determineEnv() {
      var url = window.location.href;
      var arr = url.split("/");
      let hostName = arr[0] + "//" + arr[2];
      let env = 'unit';
      if (hostName.search('qa.mammoth') != -1) {
        env = 'qa'
      }
      if (hostName.search('app.mammoth') != -1) {
        env = 'prod'
      }
      if (hostName.search('dev.mammoth') != -1) {
        env = 'dev'
      }

      return {
        hostname: hostName,
        env: env
      }

    }

  }
}
