import * as $ from 'jquery';
import { dataviewConfig } from './../dataview.config';
import * as angular from "angular";

/**
 * @ngInject
 *
 * */
metadataSort.$inject = ['$compile']
export function metadataSort($compile){
  return {
    restrict: 'E',
    scope: {
      sortManager:"=",
      defaultLabel: '=',
      defaultAddLabel: '=',
      groupByColumn: '=',
      singleSort: '='
    },
    link: function(scope, element, attributes){
      // Use single and mandatory sort for window function
      if(attributes.singleSort === 'true')
        scope.templateUrl = dataviewConfig.singleSortConfig.template;
      else
        scope.templateUrl = dataviewConfig.sortConfig.template;

      scope.$watch('sortManager', function(sortManager){
        $(element.empty());
        if(sortManager){
           var template = '<ng-include src="templateUrl"></ng-include>';
           var element_to_append = $compile(template)(scope);
           $(element).append(element_to_append);
        }
      })
    }
  }
}


export function valSortCol() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valSortCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valSortCol = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue && modelValue.hasOwnProperty('error')){
            is_valid =  false
        }
        return is_valid
      }
    }
  }
}

export function dataviewMetadataSort(){
  return {
    scope: {
      dataview: '=',
      grid: '<',
      isOpen: '='
    },
    templateUrl: 'dataviewSorManager.tpl.html',
    controller: 'WorksapceMetadataSortController',
    controllerAs: 'wmsc',
    bindToController: true
  }
}


export class WorksapceMetadataSortController{
  public dataview;
  public grid;
  public sortManager: any;
  public isOpen: boolean;
  static $inject = ['sortManagerFactory', 'c', 'analyticsService'];
  public prevSortParams: any;
  public constructor(public sortManagerFactory, public c, public analyticsService){
    // @ts-ignore
    this.$onInit =  function () {
      this.sortManager = this.sortManagerFactory.get_manager({metadata: this.dataview.metadata});
      let sortParam = [];
      if (this.dataview.display_properties && this.dataview.display_properties.SORT) {
        sortParam = this.dataview.display_properties.SORT;
      }
      this.prevSortParams = sortParam;
      this.sortManager.setParam(sortParam);
      this.sortManager.toggle_show_sort_rows();
    }
  }

  public apply(){
    this.analyticsService.userEventTrack(this.c.userEvents.dataviewEvents.sortDataview, { eventOrigin: "dataView.statusBar"});
    let sortParam = this.sortManager.getParam() || [];
    if(!angular.equals(sortParam, this.prevSortParams)){
        this.dataview.setDisplayProperties({'SORT': sortParam}).then(() => {
          this.grid.resetDataViewGrid();
        });
    }
    this.isOpen = false;
  }

  public cancel(){
    this.isOpen = false;
  }
}

/**
 * @ngInject
 * */
export function valDuplicateSortCol() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valDuplicateSortCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valDuplicateSortCol = function (modelValue, viewValue) {
        var input = scope.$eval(attrs.valDuplicateSortCol);
        var sorts = input[0];
        var sort_index = input[1];
        if (sorts) {
          var similar_cols = $.grep(sorts, function (sort, i) {
            return viewValue && sort[0] && (sort[0].internal_name === viewValue.internal_name) && sort_index != i;
          });
          return similar_cols.length === 0;
        }
        return true;
      };

    }
  };
}
