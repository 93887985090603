import * as angular from 'angular';
import {renderTableElement} from "./table.directives";
import {tableMenuManagerFactory} from "./table.menu.manager";
import {tableRenderController} from "./table.render.controller";

export let tableModule = angular.module('app.element.table', ['app.core', 'app.element.common']);

tableModule.directive('renderTableElement', renderTableElement);
tableModule.factory('tableMenuManagerFactory', tableMenuManagerFactory);
tableModule.controller('tableRenderController', tableRenderController);
