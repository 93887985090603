<template>
  <div class="mm-dataflow-status-action-required-pipeline-changes table-wrapper">
    <mm-row no-gutters class="title-row mm-text--caption-bold p-y-3 p-l-5 p-r-6">
      <mm-col cols="4">
        <mm-checkbox
          v-model="computedAreAllPendingPipelineChangesSelected"
          :disabled="computedIsPendingPipelineChangesMainCheckboxDisabled"
          :indeterminate="computedIsAnyPendingPipelineChangeSelected"
          :label="$t('global.dictionary.dataset')"
        />
      </mm-col>
      <mm-col cols="4">{{ $t('global.dictionary.view') }}</mm-col>
      <mm-col cols="2" style="text-align: center">{{ $t('dataflow_status.action_required.total_tasks') }}</mm-col>
      <mm-col cols="2">{{ $t('global.dictionary.pipeline_status') }}</mm-col>
    </mm-row>

    <div class="d-flex function-row p-y-2 p-x-5">
      <restrictions-wrapper class="m-l-auto" validate-storage-limit>
        <mm-button
          :label="$t('global.dictionary.apply_changes')"
          icon="sync"
          :disabled="!computedIsAnyPendingPipelineChangeSelected"
          :loading="isApplyChangesLoading"
          objective="tertiary"
          size="small"
          no-focus-on-click
          @click="applyChanges"
        />
      </restrictions-wrapper>
    </div>

    <div v-if="!computedPendingPipelineChanges.length" class="m-y-3 d-flex justify-content-center">
      <span class="mm-text--caption-regular">{{ $t('global.dictionary.no_data') }}</span>
    </div>

    <div v-else class="list-content">
      <mm-row
        v-for="(pipelineChange, idx) in computedPendingPipelineChanges"
        :key="`pipeline_change_${pipelineChange.viewId}_${idx}_${listItemKey}`"
        class="mm-text--caption-regular list-item p-y-3 p-l-5 p-r-6"
        :class="{ 'list-item-enabled': !pipelineChange.isPipelineInError }"
        no-gutters
        @click="!pipelineChange.isPipelineInError && toggleRow(pipelineChange)"
      >
        <mm-col cols="4" class="d-flex">
          <mm-tooltip
            :label="$t('dataflow_status.action_required.error_pipeline_tooltip')"
            wrapper-class="m-r-3 checkbox-tooltip"
            :visible="pipelineChange.isPipelineInError"
          >
            <mm-checkbox
              v-model="pendingPipelineChangesSelection[pipelineChange.viewId]"
              :disabled="pipelineChange.isPipelineInError"
              @click="!pipelineChange.isPipelineInError && toggleRow(pipelineChange)"
            />
          </mm-tooltip>
          <mm-tooltip
            v-if="$store.getters['resources/getDatasetByViewId'](pipelineChange.viewId)"
            :label="
              getResourcePath($store.getters['resources/getDatasetByViewId'](pipelineChange.viewId).resourceId).join(
                ' / '
              )
            "
          >
            <span class="mm-text--caption-regular"> {{ pipelineChange.datasetName }} </span>
          </mm-tooltip>
        </mm-col>
        <mm-col cols="4">
          <mm-tooltip
            v-if="$store.getters['resources/getViewByViewId'](pipelineChange.viewId)"
            :label="
              getResourcePath($store.getters['resources/getViewByViewId'](pipelineChange.viewId).resourceId).join(' / ')
            "
          >
            <mm-link
              class="m-x-1"
              :label="pipelineChange.viewName"
              underline
              small
              @click.stop="openResource(pipelineChange.viewId)"
            />
          </mm-tooltip>
        </mm-col>
        <mm-col cols="2" style="text-align: center">
          <span v-if="pipelineChange.pendingStepsCount != null">
            {{ pipelineChange.pendingStepsCount.toLocaleString() }}
          </span>
        </mm-col>
        <mm-col cols="2">
          <mm-tooltip
            v-if="pipelineChange.isPipelineInError"
            wrapper-class="d-flex"
            :label="getErrorTooltip(pipelineChange)"
          >
            <mm-icon name="error" color="dest800" class="m-r-2" />
            <span class="list-item-error-info mm-text--caption-regular">
              {{ $t('global.dictionary.error') }}
            </span>
          </mm-tooltip>
          <div v-else class="d-flex">
            <mm-icon name="sucess" color="p700" class="m-r-2" />
            <span class="list-item-ready-info mm-text--caption-regular">
              {{ $t('global.dictionary.ready_to_run') }}
            </span>
          </div>
        </mm-col>
      </mm-row>
    </div>
  </div>
</template>

<script>
// API
import resourcesApi from '@/modules/resources/api/resources.api'

//Constants
import { EXPORT_CONNECTORS, USER_EVENTS, getOldAppUrl } from '@/constants'

//Utils
import { getResourcePath } from '@/modules/resources/utils'

// Dependencies
import Vue from 'vue'

// Components
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

export default {
  name: 'dataflow-status-action-required-pipeline-changes',
  components: { RestrictionsWrapper },
  data: () => ({
    isApplyChangesLoading: false,
    pendingPipelineChangesSelection: {},
    listItemKey: 1,
    viewIdsWithAppliedChanges: []
  }),
  computed: {
    computedPendingPipelineChanges() {
      return this.$store.state.resources.pendingPipelineChanges
        .filter((pipelineChange) => !this.viewIdsWithAppliedChanges.some((viewId) => viewId == pipelineChange.viewId))
        .sort((a, b) => b.isPipelineInError - a.isPipelineInError)
    },
    computedIsAnyPendingPipelineChangeSelected() {
      // Stating 'listItemKey' here so that this computed property updates every time this variable changes.
      // https://stackoverflow.com/questions/48700142/vue-js-force-computed-properties-to-recompute

      return this.listItemKey && Object.values(this.pendingPipelineChangesSelection).some((value) => value)
    },
    computedIsPendingPipelineChangesMainCheckboxDisabled() {
      return (
        !this.computedPendingPipelineChanges.length ||
        this.computedPendingPipelineChanges.every((change) => change.isPipelineInError)
      )
    },
    computedAreAllPendingPipelineChangesSelected: {
      get() {
        // Stating 'listItemKey' here so that this computed property updates every time this variable changes.
        // https://stackoverflow.com/questions/48700142/vue-js-force-computed-properties-to-recompute

        const pendingPipelineChangesSelectionValues = Object.values(this.pendingPipelineChangesSelection)
        return (
          this.listItemKey &&
          pendingPipelineChangesSelectionValues.length > 0 &&
          pendingPipelineChangesSelectionValues.length ==
            this.computedPendingPipelineChanges.filter((change) => !change.isPipelineInError).length &&
          pendingPipelineChangesSelectionValues.every((value) => value)
        )
      },
      set(isSelected) {
        this.pendingPipelineChangesSelection = this.computedPendingPipelineChanges
          .filter((change) => !change.isPipelineInError)
          .map((change) => change.viewId)
          .reduce((o, k) => ({ ...o, [k]: isSelected }), {})
      }
    }
  },
  methods: {
    getErrorTooltip(pipelineChange) {
      const taskInError = pipelineChange.tasksInError[0] || pipelineChange.actionsInError[0]

      if (taskInError) {
        // TODO after data editor is fully migrated, get functions names from unique translations and delete these ones
        const taskName =
          EXPORT_CONNECTORS.find((c) => c.key == taskInError.type)?.name ||
          this.$t(`dataflow_status.action_required.tasks_in_error.${taskInError.type}`)

        return this.$t('dataflow_status.action_required.error_pipeline_tooltip_task', {
          sequence: taskInError.sequence ? ` ${taskInError.sequence}` : '',
          taskName
        })
      } 
      return this.$t('dataflow_status.action_required.error_info_unavailable')
    },
    toggleRow(pipelineChange) {
      Vue.set(
        this.pendingPipelineChangesSelection,
        pipelineChange.viewId,
        !this.pendingPipelineChangesSelection[pipelineChange.viewId]
      )

      this.listItemKey++
    },
    async applyChanges() {
      this.isApplyChangesLoading = true

      const viewIds = Object.keys(this.pendingPipelineChangesSelection).filter(
        (viewId) => this.pendingPipelineChangesSelection[viewId]
      )

      this.$userEvents.save(USER_EVENTS.DATAFLOW_STATUS.ACTION_REQUIRED.PIPELINE_CHANGES.RUN_PIPELINES, { viewIds })

      const response = await resourcesApi.applyPendingPipelineChanges(viewIds)
      this.viewIdsWithAppliedChanges.push(...viewIds)

      // Skipped views
      response.skippedViews.forEach((skipped) => {
        this.viewIdsWithAppliedChanges = this.viewIdsWithAppliedChanges.filter((viewId) => viewId != skipped.viewId)
        const viewName = this.$store.getters['resources/getViewByViewId'](skipped.viewId).properties.name
        this.$toast.show({
          content: `${this.$t('dataflow_status.action_required.changes_error.prefix', { viewName })} ${
            this.$te(`dataflow_status.action_required.changes_error.${skipped.errorCode}`)
              ? this.$t(`dataflow_status.action_required.changes_error.${skipped.errorCode}`)
              : skipped.reason
          }`,
          status: 'error'
        })
      })

      this.pendingPipelineChangesSelection = {}

      this.isApplyChangesLoading = false
      this.$emit('submit')
    },
    openResource(viewId, datasetId) {
      this.$userEvents.save(USER_EVENTS.DATAFLOW_STATUS.ACTION_REQUIRED.PIPELINE_CHANGES.OPEN_RESOURCE, { viewId })

      // TODO: remove when angular code is gone
      const redirectUrl = `${getOldAppUrl()}/#/workspaces/${this.$store.state.workspaceId}/projects/${
        this.$store.state.projectId
      }/dataviews/${viewId}`

      if (!datasetId) datasetId = this.$store.getters['resources/getDatasetByViewId'](viewId).datasetId
      window.open(redirectUrl, `_mammoth_ds_${datasetId}`)
    },
    getResourcePath
  }
}
</script>

<style lang="scss" scoped>
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';
@import '@mammoth_developer/mm-storybook/src/styles/spacing.scss';

.mm-dataflow-status-action-required-pipeline-changes {
  .title-row {
    ::v-deep .mm-checkbox--label {
      @extend .mm-text--caption-bold;
    }
  }

  .list-content {
    .list-item {
      border-bottom: 1px solid var(--mm-color--n40);

      &.list-item-enabled {
        cursor: pointer;

        &:hover {
          background: var(--mm-color--n30);
        }
      }

      ::v-deep .mm-tooltip--slot-wrapper:not(.checkbox-tooltip) {
        @extend .p-r-2;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        cursor: default;
      }

      ::v-deep .mm-checkbox--checkbox {
        @extend .m-a-1;
      }

      .list-item-error-info {
        color: var(--mm-color--dest800);
      }

      .list-item-ready-info {
        color: var(--mm-color--p700);
      }
    }
  }
}
</style>
