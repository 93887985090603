import * as angular from 'angular';
import _ = require('lodash');

/**
 * @ngInject
 */

rawDataController.$inject = ['csvData', '$uibModalInstance', 'Notification', '$timeout', 'unstructureDataModal', 'c', 'analyticsService'];
export function rawDataController(csvData, $uibModalInstance, Notification, $timeout, unstructureDataModal, c, analyticsService) {
  this.$onInit = function () {
    var rvm = this;
    rvm.apply = apply;
    rvm.csv = null;
    rvm.show_loader = false;

    rvm.delimiters = [
      {'name': 'Pipe', 'value': '|'},
      {'name': 'Comma', 'value': ','},
      {'name': 'Tab', 'value': '\t'},
      {'name': 'Semi-colon', 'value': ';'}
    ];

    rvm.editMode = false;
    rvm.isDirty = false;
    rvm.previewMode = false;
    rvm.datesAllowed = ['AUTO', 'US', 'UK'];

    rvm.raw_grid_loader = {
      show: true,
      hideOverlay: true
    };
    rvm.CheckIfDirty = CheckIfDirty;
    rvm.cancel = cancel;
    rvm.initiated = false;
    rvm.isUnprocessed = false;

    function toggleGridLoader(obj, is_true) {
      if (is_true !== undefined) {
        obj.show = !!is_true;
        obj.hideOverlay = !!is_true;
      } else {
        obj.show = !obj.show;
        obj.hideOverlay = !obj.hideOverlay;
      }
    }

    rvm.toggleRawGridLoader = function (is_true) {
      toggleGridLoader(rvm.raw_grid_loader, is_true);
    };

    rvm.openUnstructuredModal = function () {
      $uibModalInstance.dismiss();
      unstructureDataModal.openByDsId(rvm.datasource_id, rvm.datasource_status);
    };

    function CheckIfDirty() {
      var current_params: any = {};
      current_params.data_rows = rvm.csv.data_rows;
      current_params.initial_skip_count = rvm.csv.initial_skip_count;
      current_params.has_header = rvm.csv.has_header;
      current_params.date_format = rvm.csv.date_format;
      for (let i in rvm.delimiters) {
        if (rvm.delimiters[i].value == rvm.csv.delimiter) {
          current_params.delimiter = angular.toJson(rvm.delimiters[i]);
          break;
        } else if (rvm.csv.delimiter.length > 1 && rvm.delimiters[i].value == (JSON.parse(rvm.csv.delimiter)).value) {
          current_params.delimiter = angular.toJson(rvm.delimiters[i]);
          break;
        }
      }
      rvm.csv.delimiter = current_params.delimiter;
      //current_params.delimiter = rvm.csv.delimiter;
      rvm.isDirty = !angular.equals(current_params, rvm.prev_params);
    }

    function initializeData() {
      rvm.show_loader = true;
      csvData.getByDsId(rvm.datasource_id).then(function (csv) {
        delete rvm.csv;
        rvm.csv = csv;
        rvm.csv.date_format = rvm.csv.date_format || 'AUTO';
        //Todo Index column is already handled in tableRawDataGrid directive
        //Following code creates a column '#' and adds a serial number to each row
        // $.each(csv.data_rows, function (i, r) {
        //   r.unshift(csv.first_data_row + i);
        // });

        // csv.metadata.unshift({'display_name': '#', internal_name: "column_serial"});
        // csv.display_properties = {
        //   COLUMN_WIDTHS: {
        //     column_serial: 40
        //   }
        // };
        csv.total_rows_in_result = csv.skipped_rows.length + csv.data_rows.length;
        var new_params = {
          initial_skip_count: csv.initial_skip_count,
          data_rows: csv.data_rows,
          has_header: csv.has_header,
          date_format: csv.date_format,
          //delimiter: csv.delimiter
        };
        for (let i in rvm.delimiters) {
          if (rvm.delimiters[i].value == csv.delimiter) {
            new_params['delimiter'] = angular.toJson(rvm.delimiters[i]);
            break;
          } else if (csv.delimiter.length > 1 && rvm.delimiters[i].value == (JSON.parse(csv.delimiter)).value) {
            new_params['delimiter'] = angular.toJson(rvm.delimiters[i]);
            break;
          }
        }
        rvm.prev_params = _.cloneDeep(new_params);
        if (!rvm.initiated) {
          rvm.initial_params = _.cloneDeep(new_params);
          rvm.initiated = true;
        }

        CheckIfDirty();
        rvm.show_loader = false;
      });
    }

    function apply(skip_auto_process, preview_mode) {
      rvm.show_loader = true;
      if (rvm.csv.delimiter.length == 1) {
        for (let i in rvm.delimiters) {
          if (rvm.delimiters[i].value == rvm.csv.delimiter) {
            rvm.csv.delimiter = angular.toJson(rvm.delimiters[i]);
            break;
          }
        }
      }
      csvData.save(rvm.datasource_id,
        rvm.csv.has_header,
        rvm.csv.initial_skip_count,
        rvm.csv.date_format,
        (JSON.parse(rvm.csv.delimiter)).value,
        skip_auto_process, preview_mode).then(success_callback, error_callback);

      function success_callback(data) {

        if (data.STATUS === 'FAILURE') {
          $uibModalInstance.close();
          Notification.error(data.ERROR_MESSAGE);
        }
        if (preview_mode) {
          analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.previewClicked, {eventOrigin: "landingPage.previewPanel.dataSettingsModal"});
          $timeout(function () {
            rvm.isUnprocessed = true;
            initializeData();
          }, 500);
        } else {
          analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.settingsDone, {eventOrigin: "landingPage.previewPanel.dataSettingsModal"});
          $uibModalInstance.close();
        }
      }

      function error_callback(data) {
        $timeout(function () {
          initializeData();
        }, 500);

        Notification.error('Changes could not be applied at this time. Please try again later.');
      }
    }

    function cancel() {
      if (rvm.isUnprocessed) {
        csvData.cancel(rvm.datasource_id);
      }
      $uibModalInstance.dismiss()
    }

    initializeData();

  }
}
