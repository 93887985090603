import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';
import * as humanize from 'humanize';
import {getHtmlFormattedBlankValueOrSpaces} from "../../explore/utils";

/**
 * @ngInject
 */

taskDescriber.$inject = ['utils', 'c', 'DatasourceService', 'DataviewService', 'mammothExpressionHelperFactory',
  'describeDateInputValue', '$sce', 'VuexStore'];
export function taskDescriber(utils, c, DatasourceService, DataviewService, mammothExpressionHelperFactory,
  describeDateInputValue, $sce, $store){
  var expression_helper = mammothExpressionHelperFactory.get_helper();

  return {
    describe: describe,
    getAffectedColumns: getAffectedColumns,
    describeErrors: describeErrors,
    describeCondition: (new ConditionDescriber()).describeCondition,
    conditionDescriber: new ConditionDescriber(),
    getHtmlFormattedBlankValueOrSpaces: getHtmlFormattedBlankValueOrSpaces
  };

  function _getDestColumn(destSpec) {
    return destSpec.AS ? destSpec.AS.INTERNAL_NAME : destSpec.DESTINATION
  }
  function getAffectedColumns(params) {
    var affCols = {
      sources:[],
      destinations:[],
      deletions:[]
    };
    if (params.hasOwnProperty('COMBINE')) {
      angular.forEach(params.COMBINE.SOURCE, function (col_def) {
        affCols.sources.push(col_def.COLUMN);
      });
      affCols.destinations.push(_getDestColumn(params.COMBINE));
      return affCols;
    }

    else if (params.hasOwnProperty('COPY')) {
      if (params['COPY'].hasOwnProperty('VERSION') && params['COPY']['VERSION'] == 2) {
        for (let copyParam of params['COPY']) {
          affCols.sources.push(copyParam.SOURCE);
          affCols.destinations.push(_getDestColumn(copyParam));
        }
      }
      else {
        affCols.sources.push(params.COPY.SOURCE);
        affCols.destinations.push(_getDestColumn(params.COPY));
      }
      return affCols;
    }
    else if (params.hasOwnProperty('FILL')) {
      affCols.sources.push(params.FILL.COLUMN);
      return affCols;
    }
    else if (params.hasOwnProperty('JSON_HANDLE')) {
      affCols.sources.push(params.JSON_HANDLE.SOURCE);
      affCols.destinations.push(_getDestColumn(params.JSON_HANDLE));
      return affCols;
    }

    else if (params.hasOwnProperty('EXTRACT_DATE')) {
      affCols.sources.push(params.EXTRACT_DATE.SOURCE);
      affCols.destinations.push(_getDestColumn(params.EXTRACT_DATE));
      return affCols;
    }

    else if (params.hasOwnProperty('SET')) {
      affCols.destinations.push(_getDestColumn(params.SET));
      return affCols;
    }

    else if (params.hasOwnProperty('LIMIT')) {
      return affCols;
    }

    else if (params.hasOwnProperty('LOOKUP')) {
      affCols.sources.push(params.LOOKUP.SOURCE);
      affCols.destinations.push(_getDestColumn(params.LOOKUP));
      return affCols;
    }

    else if (params.hasOwnProperty('JOIN')) {
      angular.forEach(params.JOIN.ON, function (col_def) {
        affCols.sources.push(col_def.LEFT);
      });
      return affCols;
    }

    else if (params.hasOwnProperty('PIVOT')) {
      return affCols;
    }

    else if (params.hasOwnProperty('REPLACE')) {
      angular.forEach(params.REPLACE.SOURCE, function (col_def) {
        affCols.destinations.push(col_def);
      });
      return affCols;
    }

    else if (params.hasOwnProperty('SELECT')) {
      return affCols;
    }

    else if (params.hasOwnProperty('SPLIT')) {
      affCols.sources.push(params.SPLIT.SOURCE);
      angular.forEach(params.SPLIT.AS, function (col) {
        affCols.destinations.push(col.INTERNAL_NAME);
      });
      return affCols;
    }

    else if (params.hasOwnProperty('SUBSTRING')) {
      affCols.sources.push(params.SUBSTRING.SOURCE);
      affCols.destinations.push(_getDestColumn(params.SUBSTRING));
      return affCols;
    }

    else if (params.hasOwnProperty('TEXT_TRANSFORM')) {
      angular.forEach(params.TEXT_TRANSFORM.SOURCE, function (col_def) {
        affCols.destinations.push(col_def);
      });
      return affCols;
    }

    else if (params.hasOwnProperty('DISCARD_DUPLICATES')) {
      // doesn't require
      return affCols;
    }

    else if (params.hasOwnProperty('MATH')) {
      affCols.destinations.push(_getDestColumn(params.MATH));
      return affCols;
    }

    else if (params.hasOwnProperty('LARGE') || params.hasOwnProperty('SMALL')){
      var smallOrLarge;
      if (params.hasOwnProperty('SMALL')){
        smallOrLarge = 'SMALL';
      }
      else if(params.hasOwnProperty('LARGE')){
        smallOrLarge = 'LARGE';
      }
      var smallOrLargeParam = params[smallOrLarge];
      smallOrLargeParam.VALUES.forEach(function(item){
        if(item.TYPE == 'COLUMN'){
          affCols.sources.push(item.VALUE);
        }
      });
      affCols.destinations.push(_getDestColumn(smallOrLargeParam));
      return affCols;
    }


    else if (params.hasOwnProperty('CONVERT')) {
      angular.forEach(params.CONVERT, function (col_def) {
        affCols.sources.push(col_def.SOURCE);
      });
      return affCols;
    }

    else if (params.hasOwnProperty('RUNNING_TOTAL')) {
      affCols.sources.push(params.RUNNING_TOTAL.SOURCE);
      affCols.destinations.push(_getDestColumn(params.RUNNING_TOTAL));
      return affCols;
    }
    else if (params.hasOwnProperty('WINDOW')) {
      if(params.WINDOW.EVALUATE && params.WINDOW.EVALUATE.ARGUMENT){
        affCols.sources.push(params.WINDOW.EVALUATE.ARGUMENT);
      }
      affCols.destinations.push(_getDestColumn(params.WINDOW));
      return affCols;
    }

    else if (params.hasOwnProperty('INCREMENT_DATE')) {
      affCols.sources.push(params.INCREMENT_DATE.SOURCE);
      affCols.destinations.push(_getDestColumn(params.INCREMENT_DATE));
      return affCols;
    }
    else if (params.hasOwnProperty('DATE_DIFF')) {
      affCols.sources.push(params.DATE_DIFF.MINUEND.VALUE);
      affCols.sources.push(params.DATE_DIFF.SUBTRAHEND.VALUE);
      affCols.destinations.push(_getDestColumn(params.DATE_DIFF));
      return affCols;
    }

    else if (params.hasOwnProperty('ADD_COLUMN')) {
      angular.forEach(params.ADD_COLUMN, function (col_def) {
        affCols.destinations.push(col_def.INTERNAL_NAME);
      });
      return affCols;
    }

    else if (params.hasOwnProperty('DELETE')) {
      angular.forEach(params.DELETE, function (col_def) {
        affCols.deletions.push(col_def);
      });
      return affCols;
    }

    else {
      return affCols;
    }
  }

  function describe(metadata, params, desc, display_properties, task) {
    let executionTimestamp = task.params.EXECUTION_TIMESTAMP
    try {
       return _describe(metadata, params, desc, display_properties, task, executionTimestamp);
    }
    catch(e){
      console.error(e);
      return null;
    }
  }
  function describeErrors(desc, reference_errors, display_properties){
    try{
      return _describeErrors(desc, reference_errors, display_properties);
    }
    catch(e){
      console.error(e);
      return null;
    }
  }

  function _describeErrors(desc,  reference_errors, display_properties){
    let dependent_pipelines_in_error = [];
    let cols_not_available = [];
    let type_mismatch_cols = [];
    let duplicate_cols = [];
    let dup_display_names =[]
    let right_dataview_missing = false;
    let name_of_missing_dataview = '';
    let right_dataview_has_pending_changes = false;
    if (reference_errors && reference_errors.hasOwnProperty('error_code') && [401, 7001].indexOf(reference_errors['error_code']) != -1){

      _.forEach(reference_errors['reference_errors'], function (ref_err) {
        if (ref_err['reason'] == 'not available'){
          var col_display_name = display_properties.hasOwnProperty('COLUMN_NAMES') && display_properties.COLUMN_NAMES[ref_err['column']['internal_name']] ? display_properties.COLUMN_NAMES[ref_err['column']['internal_name']] : ref_err['column']['display_name']
          cols_not_available.push(col_display_name)
        } else if (ref_err['reason'] == 'type mismatch'){
          var col_display_name = display_properties.hasOwnProperty('COLUMN_NAMES') && display_properties.COLUMN_NAMES[ref_err['column']['internal_name']] ? display_properties.COLUMN_NAMES[ref_err['column']['internal_name']] : ref_err['column']['display_name']
          var type_mismatch_col_info = col_display_name + ' ('+ utils.metadata.typeToTypeDescMap[ref_err['column']['type']] +') '
          type_mismatch_cols.push(type_mismatch_col_info)
        } else if (ref_err['reason'] == 'dependency pipeline in error'){
          let wksp_in_err = DataviewService.list[ref_err['dataview']].getLongName()
          dependent_pipelines_in_error.push(wksp_in_err)
        } else if (ref_err['error_code'] == 7005){
          right_dataview_has_pending_changes = true;
        } else if (ref_err['error_code'] == 7007){
          right_dataview_missing = true;
          name_of_missing_dataview = ref_err?.dependency_info?.name;
        } else if (ref_err['error_code'] == 7008){
          for(var c of  ref_err['columns']){
            dup_display_names.push(c['display_name'])
          }
          var dup_display_name_map = dup_display_names.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
          dup_display_name_map.forEach(function(value, key, map){
            var duplicate_str = ' ' + key 
            duplicate_cols.push(duplicate_str)
          })
          
        } 
      });
      var str_missing_cols = JSON.stringify(cols_not_available)
      str_missing_cols = str_missing_cols.slice(0,-1)
      str_missing_cols = str_missing_cols.substring(1)

      var str_type_mismtach_cols = JSON.stringify(type_mismatch_cols)
      str_type_mismtach_cols = str_type_mismtach_cols.slice(0,-1)
      str_type_mismtach_cols = str_type_mismtach_cols.substring(1)

      var str_dependency_view_err = JSON.stringify(dependent_pipelines_in_error)
      str_dependency_view_err = str_dependency_view_err.slice(0,-1)
      str_dependency_view_err = str_dependency_view_err.substring(1)

      var str_duplicate_cols = JSON.stringify(duplicate_cols)
      str_duplicate_cols = str_duplicate_cols.slice(0,-1)
      str_duplicate_cols = str_duplicate_cols.substring(1)

      // dependent_pipelines_in_error = dependent_pipelines_in_error.filter((currentElement, index, array_) => array_.indexOf(currentElement) === index);
      desc.error_descriptions['column_missing']= cols_not_available.length >0 ? cols_not_available.length == 1 ? cols_not_available.length + ' column is missing i.e '+ str_missing_cols + '. ':
        cols_not_available.length + ' columns are missing i.e '+ str_missing_cols + '. ': '';
      desc.error_descriptions['type_mismatch']= type_mismatch_cols.length > 0 ? type_mismatch_cols.length ==1 ? 'Type mismatch for column '+ str_type_mismtach_cols + ". ":
        ' Type mismatch for columns ' + str_type_mismtach_cols + ". "  : '';
      desc.error_descriptions['view_error'] = dependent_pipelines_in_error.length > 0 ?  str_dependency_view_err + ' used in this task is in error' :'';
      desc.error_descriptions['view_error'] = right_dataview_has_pending_changes?  'View used in join has pending changes' : desc.error_descriptions['view_error'];
      desc.error_descriptions['view_error'] = right_dataview_missing ?  'View' + (name_of_missing_dataview? ':- ' + name_of_missing_dataview + ' ' :' ')  + 'used in this task is missing' : desc.error_descriptions['view_error'];
      desc.error_descriptions['duplicate_columns'] = duplicate_cols.length > 0 ? 'Duplicate columns found - '+ str_duplicate_cols + '. ': '';

    }else if(reference_errors && reference_errors.hasOwnProperty('error_code') && ([7000, 7002, 400, 402].indexOf(reference_errors['error_code']) != -1 )){
      desc.error_descriptions['runtime_exception'] = 'Runtime Error - '+  reference_errors['exception']
    }
    return desc
  }

  function _describe(metadata, params, desc, display_properties, task, executionTimestamp){
    if (!display_properties) {
      display_properties = {}
    }

    var typeToTypeDescMap = utils.metadata.typeToTypeDescMap;
    var metrics = [];

    $.each(Object.keys(DatasourceService.list), function(i, object_id){
      var item = DatasourceService.list[object_id];

      if(item.type == 'datasource'){
        $.each(Object.keys(item.dataviews), function(j, ws_id){
          var ws = item.dataviews[ws_id];
          if(angular.isArray(ws.metrics)){
            $.each(ws.metrics, function(k, m){
              var metric_name = item.name + '> ' + ws.name + '> ' + m.display_name;
              var metricInfo = {
                display_name: metric_name,
                internal_name: m.internal_name,
                dataview_id: ws.id,
                name: m.display_name,
                dependencies: m.dependencies
              };
              metrics.push(metricInfo);
            })
          }
        });
      }
    });

    //ToDO : place it somewhere else
    var date_component_to_text = {
      year: "Year",
      month: "Month",
      month_text: "Month Name",
      year_month: "Year & Month",
      quarter: "Quarter",
      weekday_text: "Weekday",
      day_of_year: "Day of year",
      day: "Day",
      hour: "Hour",
      minute: "Minutes",
      second: "Seconds",
      week: 'Week of the Year',
      'year_month_day': 'Date part',
      'year_month_day_as_date': 'Date part',
      'year_month_number': 'Year & Month Number',
      'hour_minute_second_millisecond': 'Time with millisecond',
      'hour_minute_second': 'Time without millisecond',
      'millisecond': 'Millisecond'
    };


    var conditionDescription = '';

    // Condition text should be displayed only when:
    // 1. Param has CONDITION key
    // 2. CONDITION should not be empty object.
    
    if(params.hasOwnProperty('CONDITION') && Object.keys(params.CONDITION).length > 0){
      conditionDescription = '<br>Condition: ' + (new ConditionDescriber()).describeCondition(metadata, params.CONDITION, false, executionTimestamp);
    }

    if (params.hasOwnProperty('COMBINE')) {

      angular.forEach(params.COMBINE.SOURCE, function (col_def) {
        desc.description += (col_def.COLUMN ? ('<b>' + _get_display_name(col_def.COLUMN) + '</b> + ') : ('" <b>' + col_def.STRING) + '</b>" + ')
      });
      cleanDescription();
      desc.description += conditionDescription + '<br>' + _describeDestination(params.COMBINE);
      return  desc;
    }

    else if(params.hasOwnProperty('UNNEST')){
      let colsUsed = params.UNNEST.COLUMNS;
      let cNames = [];
      _.forEach(colsUsed, function(c, i){
        if(Number(i) > 2){
          return;
        }
        cNames.push(_get_display_name(c.COLUMN));
      });
      desc.description = 'Reshaped data using '+ params.UNNEST.VALUE.TYPE +' columns ' + cNames.join(', ');
      let extraCols = colsUsed.length - cNames.length;
      if(extraCols > 0){
        desc.description += ' & ' + String(extraCols) + ' other';
        if(extraCols == 1){
          desc.description += ' column';
        }
        else{
          desc.description += ' columns';
        }
      }
      return desc;
    }

    else if (params.hasOwnProperty('COPY')) {
      if (!angular.isArray(params['COPY'])) {
        // Old schema where COPY contains an object
        desc.description = 'Copied from: <b>' + _get_display_name(params.COPY.SOURCE) + '</b>' +
        conditionDescription + '<br>' + _describeDestination(params.COPY);
      } else {
        // New schema where COPY contains an array of objects
        let copyArrayLen = params['COPY'].length;
        for (let i = 0; i < copyArrayLen; i++) {
          if(params['COPY'][i].hasOwnProperty('CONDITION') && Object.keys(params['COPY'][i].CONDITION).length > 0){
            conditionDescription = '<br>Condition: ' + (new ConditionDescriber()).describeCondition(metadata, params['COPY'][i].CONDITION, false, executionTimestamp);
          } else {
            conditionDescription = '';
          }
          if (i != 0) {
            desc.description += '<br><br> ';
          }
          desc.description += 'Copied from: <b>' + _get_display_name(params['COPY'][i].SOURCE) + '</b>' +
          conditionDescription + '<br>' + _describeDestination(params['COPY'][i]);
        }
      }
      
      return desc;
    }

    else if (params.hasOwnProperty('FILL')) {
      desc.description = 'Filled column: <b>' + _get_display_name(params.FILL.COLUMN) + '</b>' + '<br>' +
      'With: <b>' + _get_desc(params.FILL.WITH) + '</b>' + '<br>'
        if (params.FILL.hasOwnProperty('PARTITION_BY')) {
         desc.description+= 'Group by: <b>' + _get_display_name(params.FILL.PARTITION_BY) + '</b>' + '<br>'
        }
      desc.description+='Ordered by: <b>' + OrderByCols(params.FILL.ORDER_BY) + '</b>';
      return desc;
    }

    else if (params.hasOwnProperty('EXTRACT_DATE')) {
      let textops = ['month_text', 'weekday_text', 'year_month',
        'hour_minute_second', 'year_month_day', 'year_month_number',
        'hour_minute_second_millisecond'];

      let dateops = ['year_month_day_as_date'];
      let col_type = (textops.indexOf(params.EXTRACT_DATE.COMPONENT ) !== -1 ? '(txt)' : '(num)');
      if (dateops.indexOf(params.EXTRACT_DATE.COMPONENT) !== -1){
        // display column type as date if it is date component
        col_type = "(date)";
      }

      desc.description = 'From column: <b>' + _get_display_name(params.EXTRACT_DATE.SOURCE)
        + '</b><br>Extract <b>' + date_component_to_text[params.EXTRACT_DATE.COMPONENT] + '</b> ' +
        col_type + '<br>' +
        _describeDestination(params.EXTRACT_DATE);
      return desc;
    }

    else if (params.hasOwnProperty('SET')) {
      desc.description += _describeDestination(params.SET)  + '<div class="gap"></div>';
      var formatValue = function (val, type, format) {
        if (type == c.date && val.indexOf && val.indexOf('blank') > 0 ){
          return val;
        }
        if (!!format) {
          if (type == c.date) {
            let date_format = _.get(format, 'date_format');
            return utils.date.strftime(val, date_format);

          }
          else if (type == c.numeric) {
            return utils.number.format(val, format);
          }
          else {
            return val;
          }
        }
        else {
          return val
        }
      };

      if (params.SET.hasOwnProperty('AS') && params.SET.AS.hasOwnProperty('FORMAT') && params.SET.AS.FORMAT) {
        if (params.SET.AS.TYPE == c.date){

        }
        else if (params.SET.AS.TYPE == c.numeric) {

        }
      }

      var value_format = params.SET.AS ? (params.SET.AS.FORMAT ? params.SET.AS.FORMAT: null) : null;
        $.each(params.SET.VALUES, function(i: number, v){
          let value = '';
          let lastRun;
          if (v.PROVIDER_TYPE == 'EXPRESSION') {
            if(v.VALUE) {
              let formattedTime = utils.prettifyTimestampInUTC(v.VALUE)
              if (v.PROVIDER == '__TIME__') {
                lastRun = 'last run time in UTC'
                value = utils.string.format("{0} ({1})", [formattedTime, lastRun]);
              } else if (v.PROVIDER == '__DATE__') {
                lastRun = 'last run date in UTC'
                formattedTime = formattedTime.substring(0, formattedTime.length - 9)
                value = utils.string.format("{0} ({1})", [formattedTime, lastRun]);
              }
            } else {
              if (v.PROVIDER == '__TIME__') value = 'Current Time (UTC, evaluated at runtime)'
              else if (v.PROVIDER == '__DATE__') value = 'Current Date (UTC, evaluated at runtime)'
            }
            desc.description += 'Value: <b style="white-space: pre-wrap">' + value  + '</b>';
          } else {
            let input = v.VALUE
            if (!input) input = v.PROVIDER // in case of draft mode when `VALUE` is not present pick from `PROVIDER`
            value = getHtmlFormattedBlankValueOrSpaces(input);
          
            if(value_format){
              desc.description += 'Value: <b style="white-space: pre-wrap">' + formatValue(value, params.SET.AS.TYPE, value_format)  + '</b>';
            }
            else{
              desc.description += 'Value: <b style="white-space: pre-wrap">' + value  + '</b>';
            }
        }
        if(v.hasOwnProperty('CONDITION')){
          desc.description += '<br>When: ' + (new ConditionDescriber()).describeCondition(metadata, v.CONDITION, false, executionTimestamp);
        }
        else{
          if(params.SET.VALUES.length != 1){
            desc.description += '<br> in other cases '
          }
        }

        if(i != params.SET.VALUES.length - 1){
          desc.description += '<div class="gap"></div>';
        }
      });
      // without $sce.trustAsHtml, ng-bind-html will remove any css style applied to the html because it considers
      // styles as unsafe
      desc.description = $sce.trustAsHtml(desc.description);
      return desc;
    }

    else if (params.hasOwnProperty('LIMIT')) {
      desc.description = 'Showing <b>' + (params.LIMIT.BOTTOM ? 'Bottom ' : 'Top ') + params.LIMIT.LIMIT.toLocaleString() + '</b> rows<br>';
      _describeOrder(params);
      desc.description += conditionDescription;
      return desc;
    }

    else if (params.hasOwnProperty('LOOKUP')) {
      var lookup_wksp = _.cloneDeep(DataviewService.get_by_id(params.LOOKUP.DATAVIEW_ID));


      if(task && task.hasOwnProperty('has_referror') && task.has_referror && task.reference_errors.type == 'referror'){
        var reference_errors = task.reference_errors.reference_errors
        _.forEach(reference_errors, function(referror_info){
          if (referror_info.reason == "not available" && referror_info.dataview == params.LOOKUP.DATAVIEW_ID){
            var col = referror_info.column
            col['error'] = referror_info.reason
            lookup_wksp.metadata.push(col)
          }
        })
      }
      var value_column_name = '';
      var column = utils.metadata.get_column_by_internal_name(lookup_wksp.metadata, params.LOOKUP.VALUE);
      var key = utils.metadata.get_column_by_internal_name(lookup_wksp.metadata, params.LOOKUP.KEY);
      if (column) {
        value_column_name = '<br> Value column: ' + column.display_name;
      }
      var location = `/#/workspaces/${$store.state.workspaceId}/projects/${$store.state.projectId}/dataviews/${lookup_wksp.id}`
      desc.description = $sce.trustAsHtml('This table<br>Key column: ' + _get_display_name(params.LOOKUP.SOURCE) + '<br>' + _describeDestination(params.LOOKUP) +
        '<br><br>'+ '<a style=\"color: #069762; text-decoration: underline;\" target=\"_blank\" href='+ location+ '>'+  lookup_wksp.datasource.name + ' > ' + lookup_wksp.name + '</a>' +
        (!!(params.LOOKUP.MAPPING) ? _describeDict(params.LOOKUP.MAPPING) :
          ('<br>Key column: ' + key.display_name + value_column_name )) +
        '<br>');

      return desc;
    }

    else if (params.hasOwnProperty('JOIN')) {

      // var wksp = DataviewService.get_by_id(params.JOIN.DATAVIEW_ID);
      // var location = "/#/dataviews/"+ wksp.id
      // desc.description = $sce.trustAsHtml('Joined with: ' + '<a style=\"color: #069762; text-decoration: underline;\" target=\"_blank\" href='+ location+ '>'+  wksp.datasource.name + ' > ' + wksp.name + '</a>');
      /*
       * description of JOIN rule includes the following:
       * 1: description of the Datset/ View, the join happens with
       * 2: description of the onParams i.e. the common keys selected from the left and right dataview
       * 3: description of selected columns i.e. the columns being fecthed from the right dataview
       * 4: description of the Join type
       */
      desc.description = '';
      let rightView = DataviewService.get_by_id(params.JOIN.DATAVIEW_ID);
      var location = 'Not Available';
      let onParamsDescription;
      let selectedColumnsDescription;


      let onParams = params.JOIN.ON || [];
      let joinType = params.JOIN.TYPE
      let selectedColumns = params.JOIN.SELECT || [];
      let prefix = params.JOIN.COLUMN_PREFIX || '';
      if (rightView)
      {
      location = `/#/workspaces/${$store.state.workspaceId}/projects/${$store.state.projectId}/dataviews/${rightView.id}`;
      // get the description of common keys
       onParamsDescription = getOnParamsDescription(onParams, rightView);

      // get the description of the columns selected/ fecthed from the right dataview
       selectedColumnsDescription = getSelectedColumnsDescription(selectedColumns, rightView);
      }
      let joinedWithDescription = '<b>Joined with:</b> ' + '<a style=\"color: #069762; text-decoration: underline;\" target=\"_blank\" href='+ location+ '>'+  rightView.datasource.name + ' > ' + rightView.name + '</a>';

      let joinTypeDescription = '<b>Join type:</b> ' + _.capitalize(joinType);

      let joinPrefixDescription = '<b>Prefix:</b> ' + prefix;


      // gap is just a separator between different sections
      let gap = '<div class="gap"></div>';

      desc.description += joinedWithDescription + '<br>' + gap +  onParamsDescription +
        '<br>' + gap + selectedColumnsDescription + '<br>' + gap + joinTypeDescription + gap + joinPrefixDescription;

      desc.description = $sce.trustAsHtml(desc.description);

      return desc;

    }

    else if (params.hasOwnProperty('PIVOT')) {
      let groupByDescription = "";
      groupByDescription += 'Group by: ';
      angular.forEach(params.PIVOT.GROUP_BY, function (group_col, i) {
        if (params._UI_CUSTOM == 'collapseRows') {
          groupByDescription += '<b>' + _get_display_name(group_col.COLUMN) + (i < params.PIVOT.GROUP_BY.length - 1 ? ", " : "") + '</b>';
        }
        else {
          groupByDescription += _get_display_name(group_col.COLUMN) + (i < params.PIVOT.GROUP_BY.length - 1 ? ", " : "");
        }
      });
      cleanDescription();

      if (params.PIVOT.SELECT){
        if (params._UI_CUSTOM == 'collapseRows') {
          desc.description += 'Concatenate values from: ';
          desc.description += '<b>' + _get_display_name(params.PIVOT.SELECT[0].COLUMN) + '</b>' + "<br>";
          desc.description += "With column name: " + '<b>' + params.PIVOT.SELECT[0].AS + '</b>' + "<br>";
          desc.description += groupByDescription + "<br>";
          desc.description += 'Separated by: ' + '<b> <span style="white-space: pre">' + "'" + params.PIVOT.SELECT[0].DELIMITER + "'" + "</span> </b>";
        }
        else {
          if(params.PIVOT.GROUP_BY.length){
            desc.description += groupByDescription + "<br>";
          }
          if (params.PIVOT.SELECT.length) {
            desc.description += 'Aggregate by: ';
            angular.forEach(params.PIVOT.SELECT, function (aggregate_col) {
              desc.description += '<b>' + aggregate_col.AS + '</b>' + ', ';
            });
          } 
        }
      }
      cleanDescription();
      return desc;
    }

    else if (params.hasOwnProperty('REPLACE')) {
      var sources = [], keyValStrings = [];
      params.REPLACE.SOURCE.forEach(function(internal_name, i){
        sources.push(_get_display_name(internal_name));
      });

      if(params.REPLACE.VALUE_PAIR){
        params.REPLACE.VALUE_PAIR.forEach(function(vp){
          keyValStrings.push(getHtmlFormattedBlankValueOrSpaces(vp.SEARCH_VALUE) + ': <em>' + getHtmlFormattedBlankValueOrSpaces(vp.REPLACE_VALUE) + '</em>');
        });
      }
      else if(params.REPLACE.MAPPING){
        params.REPLACE.MAPPING.forEach(function(m){
          var searches = [];
          m.SEARCH_VALUE.forEach(function(sv){
            searches.push(getHtmlFormattedBlankValueOrSpaces(sv));
          });
          var search_string = searches.join(',');
          keyValStrings.push(getHtmlFormattedBlankValueOrSpaces(search_string) + ': <em>' + getHtmlFormattedBlankValueOrSpaces(m.REPLACE_VALUE) + '</em>');
        });
      }


      desc.description += "on <b>" + sources.join(', ') + "</b><br>" + '<span style="white-space: pre-wrap">' +
        keyValStrings.join('<br>') + '</span>';
      if(params.REPLACE.MATCH_WORDS && params.REPLACE.MATCH_CASE){
        desc.description += '<br>(case sensitive, entire cell)'
      }
      else{
        if(params.REPLACE.MATCH_WORDS){
          desc.description += '<br>(entire cell only)'
        }

        if(params.REPLACE.MATCH_CASE){
          desc.description += '<br>(case sensitive)'
        }
      }
      desc.description += ('<br>' + conditionDescription);
      // to preserve the css style we have applied to the html
      desc.description = $sce.trustAsHtml(desc.description);
      return desc;
    }

    else if (params.hasOwnProperty('SELECT')) {
      var conditionDesciber = new ConditionDescriber();
      desc.description = conditionDesciber.describeCondition(metadata, params.CONDITION, true, executionTimestamp);
      desc.description = $sce.trustAsHtml(desc.description);
      return desc;
    }

    else if (params.hasOwnProperty('SPLIT')) {
      desc.description = 'Split: <b>' + _get_display_name(params.SPLIT.SOURCE) + '</b><br>Delimiter: <b>'
        + params.SPLIT.DELIMITER + '</b><br>Into columns: ';
      angular.forEach(params.SPLIT.AS, function (col) {
        desc.description += col.COLUMN + ', ';
      });
      cleanDescription();
      return desc;
    }

    else if (params.hasOwnProperty('SUBSTRING')) {
      desc.description = 'From column: <b>' + _get_display_name(params.SUBSTRING.SOURCE) + '</b><br>';
      var partDescription = '';
      if(params.SUBSTRING.WILDCARD){
        if(params.SUBSTRING.INC_WILDCARD){
          partDescription = '<b>'+ (params.SUBSTRING.NUM_CHAR ? params.SUBSTRING.NUM_CHAR : 'All') +
              '</b> chars <b>' + params.SUBSTRING.DIRECTION.toLowerCase() + '</b> of and including <b>"' +
              params.SUBSTRING.WILDCARD + '"';
        }
        else {
          partDescription = '<b>'+ (params.SUBSTRING.NUM_CHAR ? params.SUBSTRING.NUM_CHAR : 'All') +
              '</b> chars <b>' + params.SUBSTRING.DIRECTION.toLowerCase() + '</b> of <b>"' +
              params.SUBSTRING.WILDCARD + '"';
        }

      }
      else if (params.SUBSTRING.CHAR_POSITION){
        partDescription = '<b>'+ (params.SUBSTRING.NUM_CHAR ? params.SUBSTRING.NUM_CHAR : 'All') +
          '</b> chars <b>'+ params.SUBSTRING.DIRECTION.toLowerCase() + (params.SUBSTRING.INC_WILDCARD ? ' of and including position <b>' : ' of position <b>') + params.SUBSTRING.CHAR_POSITION + '</b>';
      }
      else if (params.SUBSTRING.REGEX) {
        if(params.SUBSTRING.REGEX.PRESET) {
          if(params.SUBSTRING.REGEX.INVERT === false) {
            if(params.SUBSTRING.REGEX.PRESET === 'StartsWith') {
              partDescription = 'Starts with: ' + '`' + '<b>'+ params.SUBSTRING.REGEX.EXPRESSION + '</b>' + '`';
            }
            else if(params.SUBSTRING.REGEX.PRESET === 'EndsWith') {
              partDescription = 'Ends with: ' + '`' + '<b>'+ params.SUBSTRING.REGEX.EXPRESSION + '</b>' + '`';
            }
            else if(params.SUBSTRING.REGEX.PRESET === 'StartsWithANumber') {
              partDescription = 'Starts with a number';
            }
            else if(params.SUBSTRING.REGEX.PRESET === 'EndsWithANumber') {
              partDescription = 'Ends with a number';
            }
            else if(params.SUBSTRING.REGEX.PRESET === 'ContainsANumber') {
              partDescription = 'Contains a number';
            }
          }
          else {
            if(params.SUBSTRING.REGEX.PRESET === 'DoesNotStartWith') {
              partDescription = 'Does not start with: ' + '`' + '<b>'+ params.SUBSTRING.REGEX.EXPRESSION + '</b>' + '`';
            }
            else if(params.SUBSTRING.REGEX.PRESET === 'DoesNotEndWith') {
              partDescription = 'Does not end with: ' + '`' + '<b>'+ params.SUBSTRING.REGEX.EXPRESSION + '</b>' + '`';
            }
            else if(params.SUBSTRING.REGEX.PRESET === 'DoesNotStartWithANumber') {
              partDescription = 'Does not start with a number';
            }
            else if(params.SUBSTRING.REGEX.PRESET === 'DoesNotEndWithANumber') {
              partDescription = 'Does not end with a number';
            }
            else if(params.SUBSTRING.REGEX.PRESET === 'DoesNotContainANumber') {
              partDescription = 'Does not contain a number';
            }
            
          }
        }
        else {
          if(params.SUBSTRING.REGEX.INVERT === false)
            partDescription = 'Regular expression: ' + '`' + '<b>'+ params.SUBSTRING.REGEX.EXPRESSION + '</b>' + '`';
          else
            partDescription = 'All except regular expression: ' + '`' + '<b>'+ params.SUBSTRING.REGEX.EXPRESSION + '</b>' + '`';
        }
      }
      else{
        partDescription = '<b>'+ params.SUBSTRING.NUM_CHAR + '</b> chars from <b>' + (params.SUBSTRING.DIRECTION==="START" ? "beginning" : "end");
      }
      desc.description += partDescription + '</b>' + conditionDescription + '<br>' +_describeDestination(params.SUBSTRING);
      return desc;
    }

    else if (params.hasOwnProperty('TEXT_TRANSFORM')) {
      var sources = [];
      params.TEXT_TRANSFORM.SOURCE.forEach(function (internal_name, i) {
        sources.push(_get_display_name(internal_name));
      });

      var case_description = {
        UPPER: "UPPERCASE",
        LOWER: "lowercase",
        TITLE: "Capital Case"
      };

      desc.description += "On columns: <b>" + sources.join(', ') + "</b>" + (params.TEXT_TRANSFORM.CASE ? "</br> Values converted to " + case_description[params.TEXT_TRANSFORM.CASE] : "") + (params.TEXT_TRANSFORM.TRIM ? "</br> Sequence of whitespaces are collapsed" : "");

       desc.description = desc.description + conditionDescription;
      return desc;

    }

    else if (params.hasOwnProperty('DISCARD_DUPLICATES')) {
      desc.description =  'Removed duplicate rows.';
      if(_.get(params, 'IGNORE_COLUMNS', []).length > 0){
          desc.description += '<br> <b> Ignored columns:</b> ';
          angular.forEach(params.IGNORE_COLUMNS, function (col) {
              desc.description += _get_display_name(col) + ', '
          });
          cleanDescription();
      }
      return desc;
    }

    else if (params.hasOwnProperty('MATH')) {
      var expression = "";
      try{
        expression = 'Expression: ' + expression_helper.getExpressionString(params.MATH.EXPRESSION, metadata, metrics);
      }
      catch (e){
        return "";
      }
      desc.description = expression + conditionDescription + '<br>' + _describeDestination(params.MATH);
      return desc;
    }

    else if (params.hasOwnProperty('LARGE') || params.hasOwnProperty('SMALL')){
      var smallOrLarge;
      var largeSmallDesc = '';
      if (params.hasOwnProperty('SMALL')){
        smallOrLarge = 'SMALL';
        largeSmallDesc = ' smallest ';
      }
      else if(params.hasOwnProperty('LARGE')){
        smallOrLarge = 'LARGE';
        largeSmallDesc = ' largest ';
      }
      var smallOrLargeParam = params[smallOrLarge];
      var valuesDesc = [];
      smallOrLargeParam.VALUES.forEach(function(item){
        var valueDesc = '';
        if(item.TYPE == 'COLUMN'){
          valueDesc = _get_display_name(item.VALUE);
        }
        else{
          valueDesc = item.VALUE;
        }
        valuesDesc.push('<b>' + valueDesc + '</b>');
      });

      desc.description = 'Get the <b>' + humanize.ordinal(smallOrLargeParam.INDEX) + '</b>' +
        largeSmallDesc + ' value from ' + valuesDesc.join(', ') +'<br>' + _describeDestination(smallOrLargeParam);

      return desc;
    }


    else if (params.hasOwnProperty('CONVERT')) {
      angular.forEach(params.CONVERT, function (conSpec, index) {
        desc.description += _get_display_name(conSpec.SOURCE) + ' from <b>' +
          typeToTypeDescMap[_get_type(conSpec.SOURCE)] + '</b> to <b>' + typeToTypeDescMap[conSpec.TO_TYPE] + '</b><br>'
        if (conSpec.hasOwnProperty('FORMAT')
          && !_.isEmpty(conSpec.FORMAT)) {
          let formatString = "";
          if (conSpec.FORMAT.hasOwnProperty('date_format')) {
            formatString += conSpec.FORMAT.date_format;
          }
          else if (conSpec.FORMAT.hasOwnProperty('postgres_date_format')) {
            formatString += conSpec.FORMAT.postgres_date_format;
          }
          else if (conSpec.FORMAT.hasOwnProperty('enabled') && conSpec.FORMAT.enabled) {
            formatString += utils.number.format(123456,conSpec.FORMAT);
          }

          if (formatString) {
            desc.description += 'Format: ' + formatString + '<br>';
          }

          if (index != params.CONVERT.length - 1)
            desc.description += '<br>'
        }
      });
      return desc;
    }

    else if (params.hasOwnProperty('RUNNING_TOTAL')) {
      desc.description = 'On column: <b>' + _get_display_name(params.RUNNING_TOTAL.SOURCE) + '</b>';
      _describeOrder(params.RUNNING_TOTAL);
      desc.description += '<br>'+ _describeDestination(params.RUNNING_TOTAL);
      return desc;
    }
    else if (params.hasOwnProperty('WINDOW')) {
      desc.description = 'Evaluate: <b>' + params.WINDOW.EVALUATE.FUNCTION + '</b> <br>';
      if (params.WINDOW.EVALUATE.ARGUMENTS) {
        if (params.WINDOW.EVALUATE.FUNCTION != 'NTILE' ){
          desc.description += 'On column: <b>' + _get_display_name(params.WINDOW.EVALUATE.ARGUMENTS[0]) + '</b> <br>';
        }
        
        if(params.WINDOW.EVALUATE.FUNCTION == 'LEAD' || params.WINDOW.EVALUATE.FUNCTION == 'LAG'  ) {
          desc.description += '<i>Offset: ' + params.WINDOW.EVALUATE.ARGUMENTS[1] + ' of </i><b>'+ _get_display_name(params.WINDOW.EVALUATE.ARGUMENTS[0])+'</b> <br>';
        }
        else if (params.WINDOW.EVALUATE.FUNCTION == 'NTH_VALUE'){
          desc.description += '<i>N: ' + params.WINDOW.EVALUATE.ARGUMENTS[1] + '</i> <br>';
        }
        else if ( params.WINDOW.EVALUATE.FUNCTION == 'NTILE') {
          desc.description += '<i>N: ' + params.WINDOW.EVALUATE.ARGUMENTS[0] + '</i> <br>';
        }
      }
      if (params.WINDOW.GROUP_BY){
        desc.description += 'Partition by: ';
        let temp = ' ';
        let windowParamLength = params.WINDOW.GROUP_BY.length;
        angular.forEach(params.WINDOW.GROUP_BY, function (group, index) {
          temp += '<b>' + _get_display_name(group.COLUMN);
          if (index != windowParamLength - 1)
            temp += ',';
          temp += ' ';
          temp += '</b>';
        });
        temp += '<br>';
        desc.description += temp;
      }
      if (params.WINDOW.LIMIT){
        desc.description += '<i>Limit ranking result: '+params.WINDOW.LIMIT+' (top ranks)</i> <br>';
      }
      //Calculation type is need to shown only for specified functions
      let allowedFunctionsForCalTypes=['SUM','AVG','MIN','MAX','STDDEV','VARIANCE','COUNT','NTILE','FIRST_VALUE','LAST_VALUE','NTH_VALUE'];
      if (allowedFunctionsForCalTypes.indexOf(params.WINDOW.EVALUATE.FUNCTION) > -1) {
        if (params.WINDOW.RANGE == 'RUNNING') {
          desc.description += '<i> Calculation type: Accumulate </i> <br>';
        }
        if (params.WINDOW.RANGE == 'UNBOUNDED') {
          desc.description += '<i> Calculation type: Aggregate </i> <br>';
        }
      }

      if (params.WINDOW.ORDER_BY && params.WINDOW.ORDER_BY.length > 0){
        _describeOrder(params.WINDOW);
        desc.description += '<br>';
      }
      if (params.WINDOW.EVALUATE.FUNCTION == 'RANK' && !params.WINDOW.ORDER_BY){
        desc.description += 'Sorted by - Rank (asc) <br>';
      }
      else if (params.WINDOW.EVALUATE.FUNCTION == 'ROW_NUMBER' && !params.WINDOW.ORDER_BY){
        desc.description += 'Sorted by - Row number (asc) <br>';
      }
      desc.description += _describeDestination(params.WINDOW);
      return desc;
    }
    else if (params.hasOwnProperty('JSON_HANDLE')) {
      let jsonHandleParam = params.JSON_HANDLE;
      let colSpec = jsonHandleParam.JSON_EXTRACT;
      desc.description = 'From  <b>' + _get_display_name(params.JSON_HANDLE.SOURCE) + '</b><br>';
      if(jsonHandleParam.TYPE == 'JSON_OBJECT'){
        if(jsonHandleParam.JSON_OBJECT_OP_TYPE == 'JSON_OBJECT_TO_ROWS'){
          desc.description += 'extract (keys, value) pairs as rows'
        }
        else{
          let keys = [];
          angular.forEach(colSpec, function(c){
            let keyText = c.KEY;
            if(c.type == 'NUMERIC'){
              keyText += '(numeric)';
            }
            keys.push(keyText)
          });
          desc.description += 'extract keys: <b>' + keys.join(', ') + '</b> as new columns';
        }
      }
      else if(jsonHandleParam.TYPE == 'JSON_LIST'){
        if(jsonHandleParam.JSON_LIST_OP_TYPE == 'JSON_LIST_TO_COLUMNS'){
          desc.description += 'extract ' + '<b>' + colSpec.length + 'columns </b>  from JSON <b>array</b>';
        }
        else{
          desc.description += 'extract JSON array as new rows<br>';
        }
      }
      return desc;
    }

    else if (params.hasOwnProperty('INCREMENT_DATE')) {
      desc.description = 'From: <b>' + _get_display_name(params.INCREMENT_DATE.SOURCE) + '</b><br>' +
        (Math.sign(params.INCREMENT_DATE.DELTA[0]) === -1 ? 'Subtract ' : 'Add ');
      angular.forEach(Object.keys(params.INCREMENT_DATE.DELTA), function (component_key) {
        desc.description += '<b>' + params.INCREMENT_DATE.DELTA[component_key] + '</b> ' + component_key.toLowerCase() +
          (params.INCREMENT_DATE.DELTA[component_key]>1 ? 's, ' : ', ');
      });
      cleanDescription();

      desc.description += conditionDescription+ '<br>' + _describeDestination(params.INCREMENT_DATE);
      return desc;
    }
    else if (params.hasOwnProperty('DATE_DIFF')) {
      let subtrahendPart = ''
      let minuendPart = ''
      let lastRun = 'last run time in UTC'
      let executionTimestamp = params.EXECUTION_TIMESTAMP

      // executionTimestamp is string representation of timestamp YYYY-MM-DD HH:MM:SS
      // formattedTime will be of format Day, DD Mon YYYY HH:MM:SS
      let formattedTime = utils.prettifyTimestampInUTC(executionTimestamp)

      if(params.DATE_DIFF.SUBTRAHEND.TYPE=='COLUMN') subtrahendPart = _get_display_name(params.DATE_DIFF.SUBTRAHEND.VALUE)
      else if(params.DATE_DIFF.SUBTRAHEND.TYPE==c.SYSTEM_TIME) subtrahendPart = utils.string.format("{0} ({1})", [formattedTime, lastRun]);

      if(params.DATE_DIFF.MINUEND.TYPE=='COLUMN') minuendPart = _get_display_name(params.DATE_DIFF.MINUEND.VALUE)
      else if(params.DATE_DIFF.MINUEND.TYPE==c.SYSTEM_TIME) minuendPart = utils.string.format("{0} ({1})", [formattedTime, lastRun]);
      desc.description = 'Start Date: <b>' + subtrahendPart + '</b><br>';
      desc.description += 'End Date: <b>' + minuendPart + '</b><br>';
      desc.description += 'Component: <b>' + params.DATE_DIFF.COMPONENT + '</b><br>';
      desc.description += '<br>' + _describeDestination(params.DATE_DIFF);
      return desc;
    }

    else if (params.hasOwnProperty('ADD_COLUMN')) {
      angular.forEach(params.ADD_COLUMN,function (col_def) {
        desc.description+= col_def.COLUMN + ', '
      });

      cleanDescription();
      return desc;
    }

    else if (params.hasOwnProperty('DELETE')) {
      angular.forEach(params.DELETE, function (col_def) {
        desc.description += _get_display_name(col_def) + ', '
      });
      cleanDescription();
      return desc;
    }

    function OrderByCols(cols){
      if(cols) {
        var msg = "";
        angular.forEach(cols, function (col) {
          let dn = _get_display_name(col[0]) + " (" + col[1] + ")";
          msg += dn + ", ";
        });
        if (msg.length) {
          msg = msg.substring(0, msg.length - 2);
        }
        return msg;
      }
      else{
        return "Row number (Default)";
      }
    }

    // ToDo get it done by utils.js metadatautils
    function _get_display_name(internal_name) {
      var column = utils.metadata.get_column_by_internal_name(metadata, internal_name);
      var column_names = display_properties.COLUMN_NAMES || {};
      if(column){
        return column_names[internal_name] || column.display_name;
      }
      /*var column = $.grep(metadata, function (col) {
       return col.internal_name === internal_name;
       });
       return column[0].display_name;*/
    }

    function _get_desc(value){
      if(value == 'FIRST_VALUE'){
        return "Value above the missing value";
      }
      else{
        return "Value below the missing value";
      }
    }

    function _get_type(internal_name) {
      var column = $.grep(metadata, function (col: any) {
        return col.internal_name === internal_name;
      });
      return column[0].type;
    }

    function cleanDescription() {
      desc.description = desc.description.replace(/[,+]\s*$/, "");
    }


    function _describeDestination(dest_param) {
      if(display_properties.COLUMN_NAMES){
        return 'Into column: ' + (dest_param.AS ? (display_properties.COLUMN_NAMES[dest_param.AS.INTERNAL_NAME]? display_properties.COLUMN_NAMES[dest_param.AS.INTERNAL_NAME] : dest_param.AS.COLUMN): _get_display_name(dest_param.DESTINATION));
      }
      return 'Into column: ' + (dest_param.AS ? dest_param.AS.COLUMN : _get_display_name(dest_param.DESTINATION));
    }

    function _describeOrder(param) {
      if (param.ORDER_BY && param.ORDER_BY.length > 0) {
        desc.description += ' Sorted by - ';
        angular.forEach(param.ORDER_BY, function (col) {
          desc.description += _get_display_name(col[0]) + ' ('+col[1].toLowerCase() + '),';
        });
      }
      cleanDescription();
      // return desc;
    }

    function _describeDict(mapping) {
      var map_str = '';
      angular.forEach(mapping.keys(), function (key) {
        map_str += 'Value: ' + key + ', ' + mapping.key;
      });
      return map_str;
    }

    function getOnParamsDescription(onParams, rightWksp){
      /*
       * onParams: the common keys selected in the join rule
       * return: description of the common keys in the format:
       * left_columns_display_name/ right_column_display_name (column_type)
       */
      let onParamsDescription = '<b>Common keys:</b> <br>'
      _.forEach(onParams, function (onParamObject, index) {
        let rightFormattedMetadata = utils.metadata.applyDisplayChangesReturnMetadata(rightWksp.metadata, rightWksp.display_properties, undefined, undefined, false);
        let leftColumn = utils.metadata.get_column_by_internal_name(metadata, onParamObject.LEFT) || {};
        let rightColumn = utils.metadata.get_column_by_internal_name(rightFormattedMetadata, onParamObject.RIGHT) || {};
        let description = leftColumn.display_name + ', ' + rightColumn.display_name_w_type;
        if(parseInt(index) < onParams.length - 1){
          onParamsDescription += description + '<br>';
        }
        else{
          onParamsDescription +=  description;
        }
      })
      return onParamsDescription;
    }

    function getSelectedColumnsDescription(selectedColumns, rightWksp){
      /*
       * selectedColumns: the columns selected in join rule from the right dataview
       * return: description of the selected columns in the format:
       * column1_display_name; column2_display_name; column2_display_name...
       */
      let selectedColumnsDescription = '<b>Joined columns:</b> <br>';
      _.forEach(selectedColumns, function(selectedColumnObject, index){
        let columnFetchedFromRight = utils.metadata.get_column_by_internal_name(rightWksp.metadata, selectedColumnObject.COLUMN) || {};
        let descriptionRight = columnFetchedFromRight.display_name_w_type;
        if (selectedColumnObject.hasOwnProperty('ALIAS') && selectedColumnObject['ALIAS'] != columnFetchedFromRight.display_name) {
          descriptionRight += ' as ' + selectedColumnObject['ALIAS'];
        }
        if(parseInt(index) < selectedColumns.length - 1){
          selectedColumnsDescription += descriptionRight + '<br>';
        }
        else{
          selectedColumnsDescription +=  descriptionRight;
        }
      })
      return selectedColumnsDescription;
    }

    return desc;
  }


  function ConditionDescriber() {
    let self = this;
    self.describeCondition = describeCondition;
    // self.firstRecursiveCallDone = false;
    function _detectType(condition) {
      var keys = Object.keys(condition);
      if (["AND", "OR"].indexOf(keys[0]) !== -1) {
        return 'logical';
      }
      else {
        return 'atomic';
      }
    }

    // there is a param in filterParams called "STRING_PROP", this function describes that part
    function describeCase(filterParams){
      let caseDescriber = '';
      if(filterParams['STRING_PROP']) {
        // describe case
        if(filterParams['STRING_PROP']['CASE']) {
          if(filterParams['STRING_PROP']['CASE'] == 'CASE-SENSITIVE'){
            caseDescriber = '<span style="color: #9da4ad"><br> <i>cs</i></span>';
          }
        }
      }
      return caseDescriber;
    }

    // there is a param in filterParams called "FILTER_TYPE", this function describes that part
    function describeFilterType(filterParams){
      let filterTypeDescriber = "";
      if(filterParams['FILTER_TYPE']){
        let filterType = filterParams['FILTER_TYPE'];
        let describeText = (filterType == 'SHOW' ? '<b>Keep</b> values where ' : '<b>Remove</b> values where ');
        filterTypeDescriber = '<span>' + describeText + ' </span>';
      }
      else{
        filterTypeDescriber = '<span><b>Show</b> values where </span>';
      }
      return filterTypeDescriber;
    }

    function describeConditionPart(metadata, filterParams, executionTimestamp?: string){
      var type = _detectType(filterParams);
      var executor = {
        'atomic': _describeAtomicCondition,
        'logical': _describeLogicalCondition
      };
      let conditionDescription = executor[type](metadata, filterParams, executionTimestamp);
      return conditionDescription;
    }

    // returns description of condition(including other params e.g. string_prop, filter_type)
    function describeCondition(metadata, filterParams, filterTypeDescriptionRequired=false, executionTimestamp?: string) {
      if (!filterParams) {
        return ''
      }
      let filterTypeDescription = "";
      let caseDescription = "";

      // This function is called multiple times(in case there are multiple conditions set),
      // we want to call this block of code only once (the first time this funtion gets called) because we want filterTypeDescription and
      // caseDescription per whole condition not per subconditions
      if(!self.firstRecursiveCallDone){
        self.isConditionAtomic = Object.keys(filterParams).every(function(item){
          return ['AND', 'OR'].indexOf(item) == -1;
        });

        if(filterTypeDescriptionRequired){
          filterTypeDescription = describeFilterType(filterParams);
        }
        caseDescription = describeCase(filterParams);
      }

      self.firstRecursiveCallDone = true;

      filterParams = Object.keys(filterParams).filter(key => ['STRING_PROP' ,'FILTER_TYPE'].indexOf(key) == -1).reduce((obj, key) => {
        obj[key] = filterParams[key];
        return obj;
      }, {});

      let conditionPartDescription = describeConditionPart(metadata, filterParams, executionTimestamp);

      if(self.isConditionAtomic){
        return filterTypeDescription + '(' + conditionPartDescription + ')' + caseDescription;
      }
      else{
        return filterTypeDescription + conditionPartDescription + caseDescription;
      }
    }

    function _describeLogicalCondition(metadata, param, executionTimestamp?: string) {
      var logicalOp;
      if (param.AND && angular.isArray(param.AND)) {
        logicalOp = 'AND';
      }
      else if (param.OR && angular.isArray(param.OR)) {
        logicalOp = "OR";
      }
      else {
        return 'Unknown logical operand';
      }

      var sub_params = [];
      param[logicalOp].forEach(function (subcondition) {
        sub_params.push(describeCondition(metadata, subcondition, false, executionTimestamp));
      });
      return '(' + sub_params.join(' ' + logicalOp + ' ') + ')'
    }

    function _describeOperationDict(op_dict, ctype, metadata, executionTimestamp?: string) {
      if (Object.keys(op_dict).length == 0) {
        return '';
      }
      var op = Object.keys(op_dict)[0];
      var value = op_dict[op];
      /*
      value is dict having a key which is either 'column' (whose value is column name)
       or 'value' (whose value is raw value).
       */
      // This is to support legacy spec
      var isLegacy = !(value.hasOwnProperty('COLUMN') || value.hasOwnProperty('VALUE'));
      if (Array.isArray(value) && value.length > 0 && value[0] && op == 'IN_RANGE') {
        isLegacy = !(value[0].hasOwnProperty('COLUMN') || value[0].hasOwnProperty('VALUE'));
      }
      if (isLegacy && !Array.isArray(value)) {
        value = { VALUE: value };
      } else if (isLegacy && op == 'IN_RANGE') {
        value = [{ VALUE: value[0] }, { VALUE: value[1] }];
      } else if (isLegacy && Array.isArray(value)) {
        value = { VALUE: value };
      }
      if (typeof value == 'object') {
        if (Array.isArray(value)) {
          var new_values = '';
          var i=0;
          value.forEach(function (v){
            var col = _.get(v, 'COLUMN', null);
            if (col) {
              var column = utils.metadata.get_column_by_internal_name(metadata, col);
              if (column) {
                new_values += '<span class="di-name">' + column.display_name + '</span>';
              }
            } else {
              let currentValue = _.get(v, 'VALUE', '');
              if (typeof currentValue == 'number') {
                // https://mammoth.atlassian.net/browse/MVP-6100
                // Code comes here if we have are using conditions modal and
                // the selected number column is 'in between' two numbers
                // The numbers should be displayed directly
                new_values += currentValue;
              }
              else if (utils.date.isValidDate(currentValue)){
                //Bug fix MVP-7337 (Regression from MVP-6100) The date case was not handled where user perform IN_RANGE operation on date values
                new_values += currentValue;
              }
              else {
                // https://mammoth.atlassian.net/browse/MVP-3896
                // The column name should be displayed properly when we select column types
                // The ticket also handled duplicate columns appearing in both these dropdowns
                // Code comes here if the condition is 'in between' two columns, or first box is left blank
                // This has a different schema and is handled similar to the if condition above
                var col = _.get(currentValue, 'COLUMN', null);
                var val = _.get(currentValue, 'VALUE', null);
                if (col) {
                  var column = utils.metadata.get_column_by_internal_name(metadata, col);
                  if (column) {
                    new_values += '<span class="di-name">' + column.display_name + '</span>';
                  }
                }
                else if (val) {
                  new_values += val;
                }
              }
            }
            if (i == 0) {
              new_values += ' , ';
            }
            i++;
          });
          value = new_values;
        }
        else {
          var col = _.get(value, 'COLUMN', null);
          if (col) {
            var column = utils.metadata.get_column_by_internal_name(metadata, col);
            if (column) {
              value = '<span class="di-name">' + column.display_name + '</span>';
            }
            else {
              value = ' ';
            }
          } else {
            value = _.get(value, 'VALUE');
          }
        }
      }

      /*
      describing operand
      operand an be a single value or an array
      */
      if (c.text == ctype && value !== null && value !== undefined) {
        var v = _.cloneDeep(value);
        if (Array.isArray(v)) {
          // for each item of the array, check if it is a whitespace or a value
          _.forEach(value, function (value, i) {
            // if it is a value, then show surround it with quotes
            if (value.trim()) {
              v[i] = "'" + v[i] + "'";
            }
            // if it is a whitespace(but not a blank), then get HTML formatted value
            else {
              if (value.length > 0) {
                v[i] = getHtmlFormattedBlankValueOrSpaces(value);
              }
            }
          });
          v = v.join(', ');
        } else {
          // if the value is not a whitespace, show it in quotes
          if (typeof v === 'string') {
            if (value && value.trim()) {
              v = "'" + v + "'";
            } else {
              if (value.length > 0) {
                v = getHtmlFormattedBlankValueOrSpaces(value);
              }
            }
          } else if (v.hasOwnProperty('VALUE') && v.hasOwnProperty('TRUNCATE')) {
            v = describeDateInputValue.describe(value, executionTimestamp);
          }
        }

        value = v;
      } else if (c.date == ctype) {
        value = describeDateInputValue.describe(value, executionTimestamp);
      } else if (value === null || value === undefined) {
        value = '';
      }

      value = '<span class="value" style="white-space: pre-wrap">' + value + '</span>';
      value = $sce.trustAsHtml(value);
      var op_to_text = {
        EQ: '=',
        IN_LIST: 'is',
        NOT_IN_LIST: 'is NOT',
        IN_RANGE: 'in between ',
        NE: '!=',
        CONTAINS: 'contains any of',
        NOT_CONTAINS: 'does NOT contain any of',
        STARTS_WITH: 'starts with',
        ENDS_WITH: 'ends with',
        NOT_STARTS_WITH: 'does NOT start with',
        NOT_ENDS_WITH: 'does NOT end with',
        LT: '<',
        LTE: '<=',
        GT: '>',
        GTE: '>=',
        IS_MAXVAL: 'is MAX value',
        IS_NOT_MAXVAL: 'is NOT MAX value',
        IS_MINVAL: 'is MIN value',
        IS_NOT_MINVAL: 'is NOT MIN value',
        IS_EMPTY: 'is <b>Empty</b>',
        IS_NOT_EMPTY: 'is <b>NOT Empty</b>',
      };

      if (op_to_text[op]) {
        op = op_to_text[op];
      }

      var ret = '';

      if (
        op === op_to_text.IS_EMPTY ||
        op === op_to_text.IS_NOT_EMPTY ||
        op === op_to_text.IS_MAXVAL ||
        op === op_to_text.IS_NOT_MAXVAL ||
        op === op_to_text.IS_MINVAL ||
        op === op_to_text.IS_NOT_MINVAL
      ) {
        ret = ' <span class="op">' + op + '</span> ';
      } else if (op === op_to_text.IN_RANGE) {
        ret = ' <span class="op">' + op + '</span> [' + value + ']';
      } else if (op === op_to_text.CONTAINS || op === op_to_text.NOT_CONTAINS) {
        //get op from dict again
        var op = Object.keys(op_dict)[0];
        var v = op_dict[op];
        //find number of words, if list is empty return 0
        var num_of_words = 0;
        if (v.VALUE) {
          num_of_words = v.VALUE.length; 
        } else {
          v.VALUE = '';
        }
        if (num_of_words <= 1) {
          var containText = '';
          if (op === 'CONTAINS') {
            containText = 'contains';
          } else {
            containText = 'does NOT contain';
          }
          ret = ` <span class="op"> ${containText} </span>` + value;
        } else {
          // Display in form of 'contains any of (x, y.z)'
          var values = v.VALUE;
          values = values.join(', ');
          ret = ` <span class="op">  ${op_to_text[op]}  </span> (${values})`;
        }
      } else {
        ret = ` <span class="op"> ${op} </span> ${value}`;
      }

      return ret;
    }

    function _describeAtomicCondition(metadata, param, executionTimestamp?: string) {
      var keys = Object.keys(param);
      if (keys.length === 0) {
        return "";
      }
      var column_name = keys[0];
      var column = utils.metadata.get_column_by_internal_name(metadata, column_name);
      var display_name = column!=undefined?column.display_name:'UNKNOWN';
      var column_type = column!=undefined?column.type:'UNKNOWN';

      return '<span class="di-name">' + display_name + "</span>" + _describeOperationDict(param[column_name], column_type, metadata, executionTimestamp);
    }

  }

}
