<template>
  <mm-modal
    v-model="computedIsModalOpen"
    content-class="mm-combine-with-dataset"
    :title="$t('data_library.combine.title')"
    max-width="1500px"
    width="80%"
  >
    <div v-if="step == 1">
      <p class="m-b-1">{{ $t('data_library.combine.following') }}:</p>
      <div class="options-area d-flex-align-center mm-rounded-borders p-a-4 m-b-4">
        <span class="mm-text--body-bold overflow-ellipsis">{{ initialDataset.properties.name }}</span>
        <mm-icon class="m-x-4" name="plus" />
        <mm-dropdown
          v-model="selectedDataset"
          style="max-width: 450px"
          class="width-100"
          :placeholder="$t('data_library.combine.select_ds')"
          :items="computedOtherDatasets"
          searchable
          hide-details
          @change="onDatasetChange"
        />
      </div>

      <div style="min-height: 283px">
        <div v-if="selectedDataset" class="d-flex m-b-2">
          <span class="mm-text--caption-regular">
            {{ $t('data_library.combine.previewing') }}: {{ selectedDataset.properties.name }}
          </span>
          <mm-loader v-if="isPreviewLoading" class="m-l-3" size="small" />
        </div>

        <mm-data-grid
          v-if="datasetPreview"
          ref="dataGrid"
          :row-count="datasetPreview.rows.length"
          :column-defs="datasetPreview.columnDefs"
          :on-get-rows="() => datasetPreview.rows"
          mode="preview"
          :is-empty="!datasetPreview.columnDefs || !datasetPreview.rows.length"
          :empty-message="$t('data_library.combine.no_data')"
        />
      </div>

      <mm-checkbox
        v-model="deleteDatasetAfterCombine"
        class="m-t-3"
        :disabled="!selectedDataset"
        :label="$t('data_library.combine.delete_ds')"
      />
    </div>

    <div v-else-if="step == 2 && selectedDataset">
      <span
        v-html="
          $t('data_library.combine.datasets_combined', {
            initialDsName: initialDataset.properties.name,
            selectedDsName: selectedDataset.properties.name
          })
        "
      />
      <div class="d-flex-align-center justify-space-between p-y-2 m-t-4 width-100">
        <div class="d-flex-align-center">
          <label>{{ $t('data_library.combine.filter_by') }}: </label>
          <mm-segmented-control v-model="filterValue" class="m-l-3" :items="filterOptions" small hide-details />
        </div>

        <mm-text-input
          v-model="searchValue"
          :placeholder="$t('data_library.combine.search_columns')"
          type="search"
          small
          hide-details
        />
      </div>
      <div class="options-area columns-list mm-rounded-borders overflow-kit">
        <mm-row
          v-for="col in computedInitialDatasetColumns"
          :key="`${col.id}_${columnsMap[col.id].key}`"
          class="m-y-5"
          :class="{
            'valid-row': columnsMap[col.id].colId,
            'changed-row': !columnsMap[col.id].colId && columnsMap[col.id].changed
          }"
          no-gutters
        >
          <mm-col class="d-flex-align-center p-relative p-l-4">
            <mm-icon :name="col.type" />
            <mm-divider style="height: auto" class="m-x-3" vertical />
            <div class="d-flex-align-center width-100">
              <span class="col-name">{{ col.name }}</span>

              <mm-divider />
            </div>

            <div v-if="columnsMap[col.id].colId || columnsMap[col.id].changed" class="status-circle">
              <mm-icon v-if="columnsMap[col.id].colId" name="check" color="p600" />
              <span v-else-if="columnsMap[col.id].changed" class="mm-text--h300">?</span>
            </div>
          </mm-col>

          <mm-col class="d-flex-align-center second-col p-r-4">
            <mm-dropdown
              v-model="columnsMap[col.id].colId"
              :items="computedSelectedDatasetColumns"
              :disabled="isSubmitLoading"
              item-value="id"
              searchable
              small
              hide-details
              @change="onColumnChange(col.id, $event)"
            />

            <div v-if="isColTypeChangeVisible(col)" class="d-flex-align-center m-l-3">
              <mm-segmented-control
                v-model="columnsMap[col.id].type"
                :items="getColChangeItems(col)"
                :disabled="isSubmitLoading"
                small
                hide-details
                @input="isValid = false"
              />
              <mm-tooltip wrapper-class="m-l-2" :label="getTooltipText(col)">
                <mm-icon name="information" />
              </mm-tooltip>
            </div>
          </mm-col>
        </mm-row>
      </div>

      <div class="options-area m-t-3 p-a-3">
        <span class="mm-text--body-bold m-l-2">{{ $t('data_library.combine.save_results') }}:</span>
        <div class="d-flex-align-center m-t-1">
          <mm-segmented-control
            v-model="saveResultOption"
            :items="computedSaveResultsItems"
            :disabled="isSubmitLoading"
            hide-details
          />
          <mm-text-input
            v-if="saveResultOption == 'new'"
            v-model="newDatasetName"
            class="m-l-3"
            :disabled="isSubmitLoading"
            :placeholder="$t('global.new_ds_name')"
            :input-attrs="{ maxlength: DATASET_NAME_MAX_LENGTH }"
            hide-details
          />
        </div>
      </div>
    </div>

    <template #actions>
      <mm-button
        class="m-r-3"
        :label="$t('global.dictionary.cancel')"
        objective="tertiary"
        :disabled="isSubmitLoading"
        @click="computedIsModalOpen = false"
      />
      <mm-button
        v-if="step == 1"
        :disabled="!selectedDataset"
        :label="$t('global.dictionary.next')"
        @click="onNextClick"
      />
      <div v-else-if="step == 2" class="d-flex">
        <mm-button
          class="m-r-3"
          :disabled="isSubmitLoading"
          :label="$t('global.dictionary.previous')"
          objective="tertiary"
          @click="step--"
        />
        <mm-button
          v-if="saveResultOption == 'replace' && !isValid"
          :label="$t('global.dictionary.validate')"
          :disabled="!computedIsSubmitEnabled"
          :loading="isSubmitLoading"
          @click="submit(true)"
        />
        <mm-button
          v-else
          :label="$t('global.dictionary.done')"
          :loading="isSubmitLoading"
          :disabled="!computedIsSubmitEnabled"
          @click="submit(false)"
        />
      </div>
    </template>

    <combine-with-dataset-review-modal
      v-model="isReviewModalOpen"
      :review-payload="reviewPayload"
      @submit="submit(false)"
    />
  </mm-modal>
</template>

<script>
// API
import previewPanelApi from '@/modules/data-library/components/preview-panel/api/preview-panel.api'

// Transforms
import { transformTableData } from '@/modules/data-library/api/data-library.transform'

// Constants
import { DATASET_NAME_MAX_LENGTH, DATA_TYPES_MAP, REQUESTS_STATUS } from '@/constants'

// Components
import CombineWithDatasetReviewModal from './review-modal/combine-with-dataset-review-modal'

// Utils
import { getColumnLabel } from '@/modules/data-editor/modules/functions-panel/functions-panel-common-utils'

export default {
  name: 'combine-with-dataset',
  components: { CombineWithDatasetReviewModal },
  props: {
    initialDataset: {
      type: Object,
      required: true
    },
    value: Boolean
  },
  data: () => ({
    step: 1,
    selectedDataset: null,
    datasetPreview: null,
    isPreviewLoading: false,
    deleteDatasetAfterCombine: false,
    columnsMap: {},
    newDatasetName: '',
    saveResultOption: 'replace',
    isValid: false,
    isSubmitLoading: false,
    isReviewModalOpen: false,
    reviewPayload: [],
    filterValue: 'all',
    searchValue: ''
  }),
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    computedOtherDatasets() {
      return this.$store.getters['resources/getResourcesByProject']()
        .filter((r) => r.datasetId && r.datasetId != this.initialDataset.datasetId && r.properties.viewsIds.length)
        .map((d) => ({ text: d.properties.name, value: d }))
        .sort((a, b) => a.text.localeCompare(b.text))
    },
    computedInitialDatasetColumns() {
      return [...this.initialDataset.metadata]
        .filter((c) => {
          if (c.id.includes('batch')) return false

          const nameIncludesSearch = c.name.toLowerCase().includes(this.searchValue.toLowerCase())
          const colId = this.columnsMap[c.id]?.colId
          const colIdIncludesSearch =
            colId &&
            this.computedSelectedDatasetColumnIdToNameMap[colId].toLowerCase().includes(this.searchValue.toLowerCase())

          if (this.filterValue === 'all') return nameIncludesSearch || colIdIncludesSearch
          if (this.filterValue === 'mapped') return colId && (nameIncludesSearch || colIdIncludesSearch)
          if (this.filterValue === 'unmapped') return !colId && (nameIncludesSearch || colIdIncludesSearch)

          return false
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    computedSelectedDatasetColumns() {
      let columns = [...this.selectedDataset.metadata]
        .map((c) => ({ ...c, text: getColumnLabel(c.name, c.type) }))
        .sort((a, b) => a.name.localeCompare(b.name))
      columns.unshift({ text: this.$t('global.select_column'), id: null })
      return columns
    },
    computedSelectedDatasetColumnNames() {
      return this.computedSelectedDatasetColumns.map((c) => c.name)
    },
    computedSelectedDatasetColumnIdToNameMap() {
      return this.computedSelectedDatasetColumns.reduce((acc, c) => {
        acc[c.id] = c.name
        return acc
      }, {})
    },
    computedSaveResultsItems() {
      return [
        { text: this.initialDataset.properties.name, value: 'replace' },
        { text: this.$t('global.new_ds'), value: 'new' }
      ]
    },
    computedIsSubmitEnabled() {
      return (
        Object.values(this.columnsMap).some((c) => c.colId) &&
        (this.saveResultOption == 'replace' ? true : !!this.newDatasetName.trim().length)
      )
    }
  },
  created() {
    this.DATASET_NAME_MAX_LENGTH = DATASET_NAME_MAX_LENGTH
    this.filterOptions = [
      { text: this.$t('global.dictionary.all'), value: 'all' },
      { text: this.$t('global.dictionary.matched'), value: 'mapped' },
      { text: this.$t('global.dictionary.unmatched'), value: 'unmapped' }
    ]
    this.initialDatasetColumnNames = this.initialDataset.metadata.map((c) => c.name)
  },
  watch: {
    computedIsModalOpen(newVal) {
      if (!newVal) {
        this.selectedDataset = null
        this.datasetPreview = null
        this.deleteDatasetAfterCombine = false
        this.columnsMap = {}
        this.newDatasetName = ''
        this.saveResultOption = 'replace'
        this.isValid = false
        this.step = 1
      }
    }
  },
  methods: {
    async onDatasetChange() {
      this.isPreviewLoading = true

      const response = await previewPanelApi.getDataset(this.selectedDataset.datasetId)
      this.datasetPreview = transformTableData(response)
      this.$refs.dataGrid?.resetDataSource()
      this.isPreviewLoading = false
    },
    updateColumnsMap(key, value) {
      if (typeof value === 'object' && value !== null) {
        this.$set(this.columnsMap, key, {})
        Object.keys(value).forEach((nestedKey) => {
          this.$set(this.columnsMap[key], nestedKey, value[nestedKey])
        })
      } else {
        this.$set(this.columnsMap, key, value)
      }
    },
    onNextClick() {
      this.columnsMap = {}
      this.computedInitialDatasetColumns.forEach((col) => {
        this.updateColumnsMap(col.id, {
          colId: this.computedSelectedDatasetColumns.find((c) => c.name == col.name && c.type == col.type)?.id || null,
          changed: false,
          type: col.type,
          key: 0
        })
      })
      this.step++
    },
    onColumnChange(mainColId, newColId) {
      this.isValid = false
      if (newColId) {
        const oldMainCol = this.initialDataset.metadata.find(
          (c) => this.columnsMap[c.id].colId == newColId && c.id != mainColId
        )

        if (oldMainCol)
          this.columnsMap[oldMainCol.id] = {
            colId: null,
            changed: true,
            type: oldMainCol?.type,
            key: this.columnsMap[oldMainCol?.id].key++
          }
      } else this.columnsMap[mainColId].changed = false

      //Increment key to force re-render of the row which we need to show the type options
      this.columnsMap[mainColId].key += 1
    },
    getMappedCol(colId) {
      const mappedColId = this.columnsMap[colId].colId
      return this.computedSelectedDatasetColumns.find((c) => c.id == mappedColId)
    },
    isColTypeChangeVisible(col) {
      const mappedColType = this.getMappedCol(col.id)?.type
      return mappedColType && col.type != mappedColType
    },
    getColChangeItems(col) {
      const colType = col.type
      const mappedColType = this.getMappedCol(col.id)?.type
      return [colType, mappedColType].map((type) => ({
        value: type,
        icon: type
      }))
    },
    getTooltipText(col) {
      const mainColType = col.type
      const mappedColType = this.columnsMap[col.id].type
      const isColTypeRetained = mainColType == mappedColType

      const firstPoint = this.$t(`data_library.combine.tooltip.${isColTypeRetained ? 'retain' : 'change'}.type`, {
        type: this.$t(`global.dictionary.${mappedColType}`)
      })

      let secondPoint
      if (isColTypeRetained || (mainColType == DATA_TYPES_MAP.TEXT && mappedColType == DATA_TYPES_MAP.DATE))
        secondPoint = this.$t('data_library.combine.tooltip.retain.all_values')
      else if (mainColType == DATA_TYPES_MAP.TEXT && mappedColType == DATA_TYPES_MAP.NUMERIC)
        secondPoint = this.$t('data_library.combine.tooltip.change.all_values')
      else if ([DATA_TYPES_MAP.DATE, DATA_TYPES_MAP.NUMERIC].includes(mainColType))
        secondPoint = this.$t('data_library.combine.tooltip.change.retained', {
          type: this.$t(`global.dictionary.${mainColType}`)
        })

      let thirdPoint
      if (isColTypeRetained && [DATA_TYPES_MAP.DATE, DATA_TYPES_MAP.NUMERIC].includes(mappedColType))
        thirdPoint = this.$t('data_library.combine.tooltip.change.retained', {
          type: this.$t(`global.dictionary.${mappedColType}`)
        })
      else thirdPoint = this.$t('data_library.combine.tooltip.all_source_accepted')

      return [firstPoint, secondPoint, thirdPoint].map((p) => `• ${p}`).join('\n')
    },
    async submit(validate) {
      this.isSubmitLoading = true

      let mapping = {}
      let changeMap = []

      Object.keys(this.columnsMap).forEach((colId) => {
        const col = this.columnsMap[colId]
        if (col.colId) {
          mapping[colId] = col.colId
          changeMap.push({
            destination: colId,
            source: col.colId,
            action:
              col.type != this.initialDataset.metadata.find((c) => c.id == colId).type
                ? 'change_col_type'
                : 'retain_col_type'
          })
        }
      })

      const payload = {
        change_map: changeMap,
        delete_source_ds: this.deleteDatasetAfterCombine,
        is_validation_required: validate,
        mapping,
        new_ds_params: this.saveResultOption == 'replace' ? null : { name: this.newDatasetName },
        replace: this.saveResultOption == 'replace',
        source: 'datasource',
        source_id: this.selectedDataset.datasetId
      }
      if (!validate) this.computedIsModalOpen = false

      const initialDsName = this.initialDataset.properties.name
      const selectedDsName = this.selectedDataset.properties.name

      const response = await previewPanelApi.combineWithDataset(this.initialDataset.datasetId, payload)
      this.isValid = response.is_valid

      if (validate) {
        this.reviewPayload = Object.keys(response?.info || {}).map((colId) => ({
          colName: this.initialDataset.metadata.find((c) => c.id == colId).name,
          info: response.info[colId]
        }))
        this.isReviewModalOpen = !this.isValid
      } else if (response.status != REQUESTS_STATUS.SUCCESS) {
        this.$toast.show({
          content: `${this.$t('data_library.combine.failure_message', {
            initialDsName,
            selectedDsName
          })} ${this.$t('global.api.generic_error')}`,
          status: 'error'
        })
      }
      this.isSubmitLoading = false
    }
  }
}
</script>

<style lang="scss">
@import '@mammoth_developer/mm-storybook/src/styles/spacing.scss';
@import '@/styles/app.scss';
$changed-color: var(--mm-color--warn800);
$valid-color: var(--mm-color--p600);
$status-circle-size: 26px;

.mm-combine-with-dataset {
  .second-col {
    max-width: 550px;
    width: 100%;
  }

  .options-area {
    border: 1px solid var(--mm-color--n70);
    background: var(--mm-color--n20);

    .mm-icon {
      min-width: 16px;
    }

    .mm-row {
      max-height: 48px;

      .mm-divider--vertical {
        min-height: $status-circle-size;
      }

      .col-name {
        max-width: 500px;
        min-width: 100px;
        display: block;
        word-wrap: break-word;
      }

      .status-circle {
        background: var(--mm-color--n10);
        height: $status-circle-size;
        width: $status-circle-size;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        border: 3px solid;
        display: flex;
        left: 75%;
      }

      .mm-dropdown {
        width: 350px;
      }

      &.valid-row {
        .mm-divider--horizontal {
          background: $valid-color;
          &::before {
            color: $valid-color;
            content: '';
            background-color: $valid-color;
            position: relative;
            top: -9px;
            border-radius: 100%;
            width: 8px;
            display: inline-block;
            height: 9px;
            left: -8px;
          }
        }

        .status-circle {
          border-color: $valid-color;
        }

        .v-input__slot {
          border-color: $valid-color;
        }
      }

      &.changed-row {
        .mm-divider--horizontal {
          background: $changed-color;
          &::before {
            color: $changed-color;
            content: '';
            background-color: $changed-color;
            position: relative;
            top: -9px;
            border-radius: 100%;
            width: 8px;
            display: inline-block;
            height: 9px;
            left: -8px;
          }
        }

        .status-circle {
          border-color: $changed-color;
          color: $changed-color;
        }

        .v-input__slot {
          border-color: $changed-color;
        }
      }
    }

    .mm-segmented-control--item {
      max-width: 300px;
    }
  }

  .mm-data-grid {
    height: 262px;
  }

  .columns-list {
    height: 262px;
  }
}
</style>
