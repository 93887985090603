<template>
  <div class="mm-navbar">
    <mm-row no-gutters class="navbar">
      <mm-col class="justify-align-center navbar-fixed-col">
        <div class="go-to-home justify-align-center cursor-pointer p-l-5">
          <div v-if="title" class="justify-align-center">
            <mm-tooltip arrow-margin="12%" position="bottom" :label="$t('navbar.logo_tooltip')">
              <a @click="goToHome">
                <mm-icon name="mammoth_24px" color="n10" hover-color="p300" />
              </a>
            </mm-tooltip>
            <mm-divider class="navbar-divider m-x-4" vertical />
            <mm-tooltip position="bottom" :label="title" :open-delay="1500">
              <a class="navbar-title cursor-pointer mm-text--h300" @click="openResourceFolder"> {{ title }} </a>
            </mm-tooltip>
          </div>
          <button v-else style="height: inherit" @click="goToHome">
            <mm-icon name="mammoth_full" color="n10" hover-color="p300" />
          </button>
        </div>
      </mm-col>

      <mm-col v-if="computedWebhook" class="navbar-fixed-col">
        <mm-tooltip
          v-if="computedWebhook.properties.webhookHasUpdates && !webhookLoading"
          position="bottom"
          :label="$t('navbar.webhook.tooltip')"
          wrapper-class="p-l-3 d-flex align-self-center"
        >
          <restrictions-wrapper validate-storage-limit>
            <mm-button icon="sync" size="small" dark objective="secondary" @click="refreshWebhook" />
          </restrictions-wrapper>
        </mm-tooltip>
      </mm-col>

      <mm-col
        v-if="searchItems && !computedIsWorkspaceExpiredPage && $vuetify.breakpoint.mdAndUp"
        class="navbar-center-col"
      >
        <search-bar
          :items="searchItems"
          @selected="$emit('searchBarSelect', $event)"
          @filter="$emit('searchBarFilter', $event)"
        />
      </mm-col>

      <mm-col class="justify-align-center navbar-fixed-col m-l-auto">
        <div v-if="!isSettingsPage">
          <mm-pill
            v-if="$store.state.smsDetails.status == SMS_STATUS.CANCELLED"
            :label="$t('global.dictionary.expired')"
            bg-color="dest700"
            font-color="n10"
          />
          <mm-pill
            v-else-if="$store.state.smsDetails.status == SMS_STATUS.TRIAL && computedRemainingDaysInTrial < 10"
            :label="$t('navbar.sms.trial', { days: computedRemainingDaysInTrial })"
            :max-characters="100"
            @click="openSettings"
          />
        </div>

        <mm-tooltip
          v-if="
            featuresVisibility[SPLITIO_FEATURE_FLAGS.DATAFLOW_STATUS] &&
            !isSettingsPage &&
            computedIsSmsStatusEnabled &&
            !$store.getters.isOnboardingDataLibraryActive
          "
          wrapper-class="m-l-5"
          position="bottom"
          :label="
            $t(
              $store.state.resources.areActionsRequiredAvailable
                ? 'dataflow_status.action_required.tooltip'
                : 'dataflow_status.title'
            )
          "
        >
          <div class="d-flex" style="position: relative">
            <div v-if="$store.state.resources.areActionsRequiredAvailable" class="navbar-icons-badge dataflow-badge" />
            <div class="dataflow-icon-wrapper cursor-pointer" @click="toggleDataflowStatusModal">
              <dataflow-status-animated-icon
                v-if="$store.getters['resources/areResourcesRunning']"
                class="animated-icon"
                :class="{ 'animated-icon-active': showDataflowStatusModal }"
              />
              <mm-icon
                v-else
                :color="showDataflowStatusModal ? 'p300' : 'n10'"
                hover-color="p300"
                name="workflow_24px"
              />
            </div>
          </div>
        </mm-tooltip>

        <mm-modal v-model="showDataflowStatusModal" :title="$t('dataflow_status.title')" closable eager>
          <dataflow-status :is-visible="showDataflowStatusModal" />
        </mm-modal>

        <mm-tooltip
          v-if="!isSettingsPage && computedIsSmsStatusEnabled"
          wrapper-class="m-l-5"
          position="bottom"
          :label="$t('navbar.releases.tooltip')"
          arrow-margin="50%"
        >
          <navbar-releases />
        </mm-tooltip>

        <mm-menu
          v-if="!$store.getters.isOnboardingDataLibraryActive && !isSettingsPage && computedIsSmsStatusEnabled"
          v-model="isNotificationsMenuOpen"
          shadow-class="mm-shadow--heavy-high"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <div>
              <mm-tooltip wrapper-class="m-l-5" position="bottom" :label="$t('navbar.notifications.tooltip')">
                <div class="d-flex" style="position: relative">
                  <div v-if="$store.state.notifications.isNewNotificationAvailable" class="navbar-icons-badge" />
                  <mm-icon
                    :color="isNotificationsMenuOpen ? 'p300' : 'n10'"
                    hover-color="p300"
                    name="notification_24px"
                    class="cursor-pointer"
                    v-on="on"
                    @click="hideNotificationsBadge(), $userEvents.save(USER_EVENTS.NAVBAR.NOTIFICATIONS.OPEN)"
                  />
                </div>
              </mm-tooltip>
            </div>
          </template>
          <template #content>
            <navbar-notifications :notifications="$store.getters['notifications/getNotifications']" />
          </template>
        </mm-menu>

        <mm-menu v-model="isHelpMenuOpen" shadow-class="mm-shadow--heavy-high" eager>
          <template #activator="{ on }">
            <div v-if="!isSettingsPage && computedIsSmsStatusEnabled">
              <mm-tooltip wrapper-class="m-l-5" position="bottom" :label="$t('navbar.help.tooltip')">
                <mm-icon
                  :color="isHelpMenuOpen ? 'p300' : 'n10'"
                  hover-color="p300"
                  name="help_24px"
                  class="cursor-pointer"
                  v-on="on"
                  @click="$userEvents.save(USER_EVENTS.NAVBAR.HELP.OPEN)"
                />
              </mm-tooltip>
            </div>
          </template>
          <template #content>
            <navbar-help />
          </template>
        </mm-menu>

        <mm-menu shadow-class="mm-shadow--heavy-high">
          <template #activator="{ on }">
            <div class="m-r-5">
              <mm-tooltip
                position="bottom"
                wrapper-class="m-l-5 cursor-pointer"
                :label="$t('navbar.profile.tooltip')"
                arrow-margin="80%"
              >
                <mm-avatar
                  :name="$store.state.user.name"
                  :picture="$store.state.user.profilePic"
                  is-current-user
                  hide-tooltip
                  small
                  v-on="on"
                  @click="$userEvents.save(USER_EVENTS.NAVBAR.PROFILE.OPEN)"
                />
              </mm-tooltip>
            </div>
          </template>
          <template #content>
            <navbar-profile :is-settings-page="isSettingsPage" />
          </template>
        </mm-menu>
      </mm-col>
    </mm-row>
    <navbar-tabs v-if="tabs" :tabs="tabs" />
  </div>
</template>

<script>
//Constants
import { USER_EVENTS, SMS_STATUS, SPLITIO_FEATURE_FLAGS, getOldAppUrl } from '@/constants'

// Components
import NavbarTabs from './navbar-tabs/navbar-tabs'
import NavbarReleases from './navbar-releases/navbar-releases'
import NavbarNotifications from './navbar-notifications/navbar-notifications'
import NavbarHelp from './navbar-help/navbar-help'
import NavbarProfile from './navbar-profile/navbar-profile'
import DataflowStatus from '@/components/dataflow-status/dataflow-status'
import SearchBar from '@/components/search-bar/search-bar'
import DataflowStatusAnimatedIcon from '@/components/icons/dataflow-status-animated-icon'
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

// API
import globalApi from '@/api/global.api'
import resourcesApi from '@/modules/resources/api/resources.api'
import authApi from '@/modules/auth/auth.api'

// Plugins
import { initializeSplitIo, featuresVisibility } from '@/plugins/splitio'

// Dependencies
import moment from 'moment'

export default {
  name: 'navbar',
  components: {
    NavbarTabs,
    NavbarReleases,
    NavbarNotifications,
    NavbarHelp,
    NavbarProfile,
    DataflowStatus,
    SearchBar,
    DataflowStatusAnimatedIcon,
    RestrictionsWrapper
  },
  props: {
    title: String,
    tabs: Array,
    searchItems: Array,
    isSettingsPage: Boolean
  },
  data: () => ({
    showDataflowStatusModal: false,
    webhookLoading: false,
    isNotificationsMenuOpen: false,
    isHelpMenuOpen: false,
    featuresVisibility
  }),
  computed: {
    computedIsWorkspaceExpiredPage() {
      //TODO remove when angular code is gone (use vue router instead)
      return window.location.hash.includes('expired')
    },
    computedWebhook() {
      const dataset = this.$store.getters['resources/getDatasetByDatasetId'](this.tabs?.[0].properties.dataset.id)
      if (!dataset?.properties?.webhook?.id) return
      return this.$store.getters['resources/getWebhookByWebhookId'](dataset.properties.webhook.id)
    },
    computedIsSmsStatusEnabled() {
      return this.$store.state.smsDetails?.status !== SMS_STATUS.CANCELLED
    },
    computedRemainingDaysInTrial() {
      const today = moment()
      const trialEndDate = moment.utc(this.$store.state.smsDetails.trialEnd).local()
      return trialEndDate.diff(today, 'days')
    }
  },
  async created() {
    this.USER_EVENTS = USER_EVENTS
    this.SMS_STATUS = SMS_STATUS
    this.SPLITIO_FEATURE_FLAGS = SPLITIO_FEATURE_FLAGS
    // IMPORTANT: This is a security endpoint that sets required cookies for authentication.
    // Please ensure this endpoint is called first, before any other APIs are initiated.
    // TODO: After angular migration this can be deleted, as it is already being called on App.vue
    await authApi.setSecurityCookie()
    this.$store.dispatch('getCurrentUser')
    await this.fetchWorkspaceDetails()
    await this.fetchSmsDetails({ fetchAllDetails: true })
    initializeSplitIo()

    // TODO remove when angular code is gone
    setInterval(() => this.fetchSmsDetails(), 30000)
  },
  methods: {
    async fetchSmsDetails(config) {
      // TODO remove when angular code is gone
      const smsDetails = await globalApi.getSmsDetails(this.$store.state.workspaceId, config)
      this.$store.commit('setSmsDetails', smsDetails)
    },
    async fetchWorkspaceDetails() {
      // TODO remove when angular code is gone
      // this is a workaround to the lack of $route at this point
      await this.$store.dispatch('getWorkspaces')

      if (!this.$store.state.workspaces.length) {
        this.$toast.show({ content: this.$t('auth.login.no_workspaces_error'), status: 'error' })
        setTimeout(() => this.$store.dispatch('auth/logout'), 3000)
      }
    },
    hideNotificationsBadge() {
      this.$store.commit('notifications/setIsNewNotificationAvailable', false)
    },
    goToHome() {
      this.$userEvents.save(USER_EVENTS.NAVBAR.LOGO.CLICK)

      //TODO remove when angular code is gone
      if (window.location.hash.includes('dataviews')) {
        window.location.hash = window.location.hash.split('/dataviews')[0]
      } else {
        window.location.hash = ''
        window.location.href = `${window.location.origin}/#/landing`
      }
      location.reload()
    },
    openResourceFolder() {
      const dataset = this.$store.getters['resources/getDatasetByDatasetId'](this.tabs?.[0].properties.dataset.id)

      //TODO remove when angular code is gone
      let url = '#' + `/workspaces/${this.$store.state.workspaceId}/projects/${this.$store.state.projectId}`
      if (dataset?.folderResourceId) {
        url += `/folders/${dataset.folderResourceId}`
      }
      window.location.href = `${window.location.origin}/${url}`
      location.reload()
    },
    toggleDataflowStatusModal() {
      this.showDataflowStatusModal = true
      this.$userEvents.save(USER_EVENTS.NAVBAR.DATAFLOW_STATUS.OPEN)
    },
    async refreshWebhook() {
      this.webhookLoading = true
      await resourcesApi.refreshWebhook(this.computedWebhook.webhookId)
      this.webhookLoading = false
    },
    openSettings() {
      //TODO remove when angular code is gone
      window.open(
        `${getOldAppUrl()}/n/#/settings/workspace/${this.$store.state.workspaceId}?currentPlan=true`,
        'Settings'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

$background-color: var(--mm-color--p700);
$icons-badge-color: var(--mm-color--s200);
$logo-height: 20px;

.mm-navbar {
  max-height: $navbar-height-with-tabs;

  .navbar {
    display: flex;
    height: $navbar-height;
    background: $background-color;

    .go-to-home {
      height: inherit;

      a {
        text-decoration: none;

        &:hover {
          color: var(--mm-color--p300) !important;
        }
      }
    }

    .navbar-divider {
      background: var(--mm-color--p900);
      height: 16px;
    }

    .navbar-title {
      color: var(--mm-color--n10);
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 350px;
      overflow: hidden;
      display: block;
    }

    .navbar-fixed-col {
      max-width: fit-content;
      display: inline-flex;
      height: inherit;

      .dataflow-icon-wrapper {
        max-height: 24px;

        ::v-deep .animated-icon {
          ellipse,
          path {
            fill: var(--mm-color--n10);
          }

          &.animated-icon-active,
          &:hover {
            ellipse,
            path {
              fill: var(--mm-color--p300);
            }
          }
        }
      }

      ::v-deep .mm-avatar {
        label {
          cursor: pointer;
        }
      }
    }

    .navbar-center-col {
      display: flex;
      overflow: hidden;
    }

    .navbar-icons-badge {
      background-color: $icons-badge-color;
      border: 1px solid $background-color;
      position: absolute;
      border-radius: 50%;
      padding: 3px;
      right: -1px;
      top: -2px;

      &.dataflow-badge {
        background-color: var(--mm-color--a600);
      }
    }
  }
}
</style>
