import * as angular from 'angular';
import * as numeral from 'numeral';
import * as _ from 'lodash-es';
import * as humanize from 'humanize';
import * as FPP  from "formatter-plus-plus";
var FormatterPlusPlus = FPP.FormatterPP;
let formatterPlusPlus = new FormatterPlusPlus();

/**
 * @ngInject
 **/
utils.$inject = ['moment', 'c', 'config', '$http', '$window', '$sce', '$sanitize', '$q', 'FutureService'];
export function utils(moment, c, config, $http, $window, $sce, $sanitize, $q, FutureService) {
  var asyncErrorUtils = new AsyncErrorUtils();
  var metadataUtils = new MetadataUtils();
  var stringUtils = new StringUtils();
  var numericUtils = new NumericUtils();
  var dateUtils = new DateUtils();

  var service = {
    _: _,
    parseDateMoment: parseDateMoment,
    asyncErrors: {
      set: asyncErrorUtils.setAsyncErrors,
      reset: asyncErrorUtils.resetAsyncErrors
    },
    metadata: {
      get_column_by_internal_name: metadataUtils.get_column_by_internal_name,
      get_internal_name_to_col_map: metadataUtils.get_internal_name_to_col_map,
      get_column_by_display_name: metadataUtils.get_column_by_display_name,
      add_type_to_display_name: metadataUtils.add_type_to_display_name,
      add_type_to_display_name_in_column: metadataUtils.add_type_to_display_name_in_column,
      sortMetadataByOrderSpec: metadataUtils.sortMetadataByOrderSpec,
      add_error_columns_to_metadata: metadataUtils.add_error_columns_to_metadata,
      applyDisplayChanges: metadataUtils.applyDisplayChanges,
      applyDisplayChangesReturnMetadata: metadataUtils.applyDisplayChangesReturnMetadata,
      typeToTypeDescMap: metadataUtils.type_to_type_desc_map,
      getListOfIntNamesFromListOfCols: metadataUtils.getListOfIntNamesFromListOfCols,
      generateInternalName: generateInternalName,
      getColumnType: getColumnType,
      generateInternalNameMap: generateInternalNameMap,
      getMatchingColumnMap: getMatchingColumnMap,
      addErrorColumnsToMetadata_v2: addErrorColumnsToMetadata_v2,
      getDisplayNameAndTypeToColumnMap: getDisplayNameAndTypeToColumnMap,
      updateDisplayNameAndTypeToColumnMap: updateDisplayNameAndTypeToColumnMap,
      isColumnPresent: isColumnPresent,
      replaceMatchingColumnAndUpdateMetadata: replaceMatchingColumnAndUpdateMetadata,
      findReplaceColumnsInCondition: findReplaceColumnsInCondition,
      getMetadata: getMetadata
    },
    math: {
      isMathExpressionValid: isMathExpressionValid
    },
    number: {
      format: numericUtils.format,
      unformat: numericUtils.unformat,
      toWords: numericUtils.toWords,
      compact: numericUtils.compactNumber,
      humanizeNumberAsHtml: formatterPlusPlus.humanize
    },
    date: {
      strptime: dateUtils.strptime,
      strftime: dateUtils.strftime,
      getMomentFormat: dateUtils.getMomentFormat,
      getPythonFormat: dateUtils.getPythonFormat,
      isValidDate: isValidDate
    },
    string: {
      capitalize: stringUtils.capitalize,
      format: stringUtils.format,
      random: randomString,
      isRegexValid: isRegexValid,
      addCentreEllipsis: stringUtils.addCentreEllipsis
    },
    array: {
      setPropertyOfAllObjectsTo: setPropertyOfAllObjectsTo,
      removeElement: removeElement,
      elementInArray: elementInArray
    },
    schedule: {
      summarize: summarize,
      getScheduleFrequency: getScheduleFrequency
    },
    getRandomString: randomString,
    debounce: debounce,
    objectShallowDiff: objectShallowDiff,
    saveJSON: saveJSON,
    getUrlParams: getUrlParams,
    metric: {
      getIdealFormat: getIdealFormatForMetric
    },
    getComputedStyle: getComputedStyle,
    filterVerticalWhitespaces: filterVerticalWhitespaces,
    copyToClipboard: copyToClipboard,
    downloadDataAsFile: downloadDataAsFile,
    linkify:linkify,
    prettifyTimestampInUTC: prettifyTimestampInUTC,
    fetchCurrentTime: fetchCurrentTime,
    moveCursorToEndOfText: moveCursorToEndOfText,
    getKeyByValue: getKeyByValue,
    insertAt: insertAt,
    isKeyValuePairPresentInObject: isKeyValuePairPresentInObject,
    prepare_message: prepare_message,
    downloadStringDataAsFile: downloadStringDataAsFile,
    replaceKeysDeep: replaceKeysDeep,
    getInternalToDisplayName: getInternalToDisplayName,
    get_input_columns: get_input_columns,
    getCircularReplacer: getCircularReplacer,
    updateDisplayNames: updateDisplayNames,
    decodeSanitizedName: decodeSanitizedName,
    decodeEntityNumber: decodeEntityNumber,
    sanitizeName: sanitizeName,
    sanatizeParamForDuplicateCols: sanatizeParamForDuplicateCols,
    sanitizeData: sanitizeData,
    sanitizeDataWithType: sanitizeDataWithType,
    sanitizeMetadata: sanitizeMetadata,
    sanitizeMetadataWithType: sanitizeMetadataWithType,
    sanitizeMetadataName: sanitizeMetadataName,
    getNewAppUrl: getNewAppUrl,
    futureDataTracker:  futureDataTracker,
    getColumnErrorMessage
  };

  function getColumnErrorMessage(errorString){
    return c.columnMessages[errorString] || 'Invalid'
  }

  function sanatizeParamForDuplicateCols(params, key_to_sanitize, taskInfo){
    // Check internal name of column being created, if it already exist remove it from the params so that backend will generate a fresh one.
    if (params.hasOwnProperty(key_to_sanitize) && taskInfo.has_referror && taskInfo.reference_errors['error_code']==7001){
      for (var i of taskInfo.reference_errors.reference_errors ){
        if (i['error_code'] == 7008){
          var internal_names_of_duplicate_cols = []
          _.forEach(i['columns'], function(duplicate_col){
            internal_names_of_duplicate_cols.push(duplicate_col['internal_name'])
          })
          if (internal_names_of_duplicate_cols.indexOf(params[key_to_sanitize])!=-1){
            delete params[key_to_sanitize]
          }
        }
      }
    }
  }
  function getMetadata(taskwise_info, sequence){
    while(!taskwise_info[sequence]){
      sequence = sequence -1
    }
    return taskwise_info[sequence]['metadata']
  }

  function getCircularReplacer(){
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  function generateInternalNameMap(internalNameToColInfoMap){
    /**Using oldColInternalName to newColInfo map generate
     * oldColInternalName to newColInternalName map*/
    var colInternalNameMap = {}
    for (const colInternalName in internalNameToColInfoMap){
      colInternalNameMap[colInternalName] = internalNameToColInfoMap[colInternalName]['internal_name']
    }
    return colInternalNameMap
  }

  function updateDisplayNames(columnUsed, wkspDisplayProps){
    /*Update column display names with latest names for renamed columns
    * columnUsed : internalName to colInfo map*/
    var updateColInfoMap: any = {}
    _.forEach(columnUsed, function(colInfo, colInternalName){
      if(wkspDisplayProps.hasOwnProperty('COLUMN_NAMES') && Object.keys(wkspDisplayProps.COLUMN_NAMES).indexOf(colInternalName)!=-1){
        updateColInfoMap[colInternalName] = {
          'display_name' : wkspDisplayProps.COLUMN_NAMES[colInternalName],
          'type': colInfo['type']
        }
      }else{
        updateColInfoMap[colInternalName] = {
          'display_name' : colInfo['display_name'],
          'type': colInfo['type']
        }
      }
    })
    return updateColInfoMap
  }

  function sanitizeName(name) {
    let sanitizedValue = '';
    angular.forEach(name, function (item) {
      sanitizedValue = sanitizedValue + $sanitize(item);
    });
    return decodeEntityNumber(sanitizedValue);
  }

  function decodeEntityNumber(entityNumber) {
    /*
     * Decode the given entity number and return entity name
     * e.g. `&lt;` is the entity number of `<`. This function makes use of  temp html to
     * decode an entity Number into a entity Name.
     *
     * Caveat : This fn does not decoee reserved HTML characters such as `<`, `>`, `©`
     */
    let tmpElement = document.createElement('span');
    tmpElement.innerHTML = entityNumber;
    return tmpElement.innerHTML;
}

  function decodeSanitizedName(name) {
    let value = angular.element('<div />').html(name).text();
    return $sce.trustAsHtml(value);
  }

  function isColumnPresent(displayNameAndTypeToColumnMap, column){
    /*Determine using displayNameAndTypeToColumnMap whether given column is present or not*/
    var uniqueKey= column['display_name']+'_'+column['type']
     return Object.keys(displayNameAndTypeToColumnMap).indexOf(uniqueKey) != -1
  }

  function updateDisplayNameAndTypeToColumnMap(displayNameAndTypeToColumnMap, column){
    displayNameAndTypeToColumnMap[column['display_name']+'_'+column['type']] = column
    return displayNameAndTypeToColumnMap
  }

  function getDisplayNameAndTypeToColumnMap(metadata){
    /*Generate {displayName_Type: colInfo, ..} map from metadata*/
    var displayNameAndTypeToColumnMap = {}
    for (const metadatum of metadata) {
      displayNameAndTypeToColumnMap[metadatum['display_name']+'_'+metadatum['type']] = metadatum
    }
    return displayNameAndTypeToColumnMap
  }

  function addErrorColumnsToMetadata_v2(replacementColumnMaps, metadata, displayNameAndTypeToColumnMap){
    /*replacementColumnMaps: internal_name to colInfo map
     * Iterate over replacement column map and update metadata and  displayNameAndTypeToColumnMap with error columns
     */
    for (const internalName in replacementColumnMaps) {
      if (replacementColumnMaps[internalName].hasOwnProperty('error') && !isColumnPresent(displayNameAndTypeToColumnMap, replacementColumnMaps[internalName])){
        metadata.push(replacementColumnMaps[internalName]);
        displayNameAndTypeToColumnMap = updateDisplayNameAndTypeToColumnMap(displayNameAndTypeToColumnMap, replacementColumnMaps[internalName])
      }
    }
  }

  function replaceMatchingColumnAndUpdateMetadata(params, column_key, column_info, metadata, displayNameAndTypeToColumnMap) {
    /**
     * Find a replacement column for column present at given column key in the patams
     * Replace it in the params
     * If the column has errors add it to the metadata and update the displayNameAndTypeToColumnMap as well */

    var replacementColumnMap: any = getMatchingColumnMap([params[column_key]], column_info.COLUMNS_USED, metadata)
    var matchedColumn = Object.values(replacementColumnMap)[0]
    params[column_key] = matchedColumn['internal_name']
    // if error, update map
    if (matchedColumn.hasOwnProperty('error') && !isColumnPresent(displayNameAndTypeToColumnMap, matchedColumn)) {
      metadata.push(matchedColumn)
      updateDisplayNameAndTypeToColumnMap(displayNameAndTypeToColumnMap, matchedColumn)
    }
  }

  function findReplaceColumnsInCondition(metadata, displayNameAndTypeToColumnMap, params, columnUsedInCondition, column_info, key?) {
    /**
     * Deduplicate the columns used in the condition
     * Get replacement column
     * Check and add col info to metadata if it has error
     * Create map of existing to replacement column internal name
     * Update condition params with replacement columns
     * */
    var uniqueColumnUsedInCondition = columnUsedInCondition.filter((v, i, a) => a.indexOf(v) === i);
    var replacementColumnMapsForCondition = getMatchingColumnMap(uniqueColumnUsedInCondition, column_info.COLUMNS_USED, metadata)
    addErrorColumnsToMetadata_v2(replacementColumnMapsForCondition, metadata, displayNameAndTypeToColumnMap)
    var internalNameMap = generateInternalNameMap(replacementColumnMapsForCondition)
    if (key){

      params[key] = replaceKeysDeep(params[key], internalNameMap)
    }
    else{

      params.CONDITION = replaceKeysDeep(params.CONDITION, internalNameMap)
    }
  }

  function getMatchingColumnMap(srcColInternalNames, srcColInfo, metadata){
    /**
     * Check for column in the metadata
     *  if found, add it to map as replacement column
     *  else create a temporary columns with random internal name and mark it as error and use it as replacement column
     *
     * Returns oldColumnInternalName: newColumnInfo
     */
    var matchedColumnMap: any = {};
    _.forEach(srcColInternalNames, function(colInternalName){
      var srcColDisplayName = srcColInfo[colInternalName]['display_name']
      var column = metadataUtils.get_column_by_display_name(metadata, srcColDisplayName)
      if (column){
        matchedColumnMap[colInternalName] = column
      }
      else{
        var randomInternalName = 'missing_'+ randomString(10, {lowercase: true})
        var type = getColumnType(srcColInfo, srcColDisplayName)
        var typeDisplay = metadataUtils.type_to_type_desc_map[type]
        var temp_col =
          {'display_name': srcColDisplayName,
            'internal_name': randomInternalName,
            'type': type,
            'display_name_w_type':  srcColDisplayName + ' (' + typeDisplay + ')',
            'error': 'not available'}
        matchedColumnMap[colInternalName] = temp_col
      }
    })
    return matchedColumnMap
  }

  function getInternalToDisplayName(internalNameToColInfo){
    /** returns { internal_name: display_name, ..}*/
    var internalToDisplayName: any = {}
    _.forEach(internalNameToColInfo, function(value, key) {
      internalToDisplayName[key] = value['display_name']
    } );
    return internalToDisplayName
  }

  function replaceKeysDeep(obj, keysMap) { // keysMap = { oldKey1: newKey1, oldKey2: newKey2, etc...
    return _.transform(obj, function(result, value, key) { // transform to a new object
      var currentKey = keysMap[key] || key; // if the key is in keysMap use the replacement, if not use the original key

      //Following if statement is condition param specific
      if (key.toString() == 'COLUMN'){
        value = keysMap[value.toString()] //replace value of key 'COLUMN' with replacement
      }

      result[currentKey] = _.isObject(value) ? replaceKeysDeep(value, keysMap) : value; // if the key is an object run it through the inner function - replaceKeys
    });
  }

  function getUrlParams() {
    var match,
      pl = /\+/g,  // Regex for replacing addition symbol with a space
      urlParams = {},
      search = /([^&=]+)=?([^&]*)/g,
      decode = function (s) {
        return decodeURIComponent(s.replace(pl, " "));
      },
      query = window.location.search.substring(1);

    while (match = search.exec(query)) {
      urlParams[decode(match[1])] = decode(match[2]);
    }
    return urlParams;
  }


  function setPropertyOfAllObjectsTo(arrayOfObjects, key, value) {
    angular.forEach(arrayOfObjects, function (item) {
      item[key] = value;
    });
  }

  function removeElement(arr, element) {
    arr = arr.filter(item => item !== element);
    return arr
  }

  function elementInArray(arr, element) {
    var index = arr.indexOf(element);
    if (index > -1) {
      return true
    }
    else {
      return false
    }
  }

  function objectShallowDiff(a, b) {
    var list = [];
    if (angular.equals(a, b)) {
      return list;
    }
    var _keys = {};
    Object.keys(a).forEach(function (key) {
      _keys[key] = true;
    });
    Object.keys(b).forEach(function (key) {
      _keys[key] = true;
    });
    var _allKeys = Object.keys(_keys);
    _allKeys.forEach(function (key) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key) || !angular.equals(a[key], b[key])) {
        list.push(key);
      }
    });
    return list;
  }

  function parseDateMoment(str) {
    return moment(str, moment.ISO_8601);
  }


  function AsyncErrorUtils() {
    this.resetAsyncErrors = function (ctrl, validator_name) {
      if (ctrl.maValAsyncErrors) {
        delete ctrl.maValAsyncErrors[validator_name];
      }
    };

    this.setAsyncErrors = function (data, ctrl, validator_name) {
      if (!ctrl.hasOwnProperty("maValAsyncErrors")) {
        ctrl.maValAsyncErrors = {};
      }
      ctrl.maValAsyncErrors[validator_name] = data.ERROR_MESSAGE;
    };
  }


  function MetadataUtils() {
    var type_to_type_desc_map = {
      TEXT: 'text',
      DATE: 'date',
      NUMERIC: 'num'
    };

    var self = this;
    self.type_to_type_desc_map = type_to_type_desc_map;
    self.add_error_columns_to_metadata = add_error_columns_to_metadata;
    self.get_internal_name_to_col_map = function(metadata) {
      let metadata_dict = {};
      angular.forEach(metadata, function (c) {
        metadata_dict[c.internal_name] = c;
      });
      return metadata_dict;
    }
    self.get_column_by_internal_name = function (metadata, internal_name) {
      var col = undefined;
      angular.forEach(metadata, function (c) {
        if (c.internal_name == internal_name) {
          col = c;
          return false;
        }
      });
      return col;
    };
    self.get_column_by_display_name = function (metadata, display_name) {
      var col = undefined;
      angular.forEach(metadata, function (c) {
        if (c.display_name == display_name) {
          col = c;
          return false;
        }
      });
      return col;
    };
    self.add_type_to_display_name = _add_type_to_display_name;
    self.add_type_to_display_name_in_column = _add_type_to_display_name_in_column;

    self.sortMetadataByOrderSpec = function _sortMetadataByOrderSpec(metadata, spec, resultList) {
      var columns = resultList || [];
      var _COLUMN_ORDER = spec || {};
      var _allColumns = {};
      var _pendingColumns = [];
      var finalColumns = [];

      angular.forEach(metadata, function (col, i) {
        _allColumns[col.internal_name] = col;
        _pendingColumns.push(col.internal_name);
      });

      if (Object.keys(_COLUMN_ORDER).length) {
        for (var i = 0; i < metadata.length; i++) {
          var colName = _COLUMN_ORDER[i];
          if (colName) {
            var index = _pendingColumns.indexOf(colName);
            if (index !== -1) {
              finalColumns[i] = _allColumns[colName];
              _pendingColumns.splice(index, 1);
            }
          }
        }
      }
      for (i = 0; i < metadata.length; i++) {
        if (!finalColumns[i]) {
          finalColumns[i] = _allColumns[_pendingColumns.shift()];
        }
        columns.push(finalColumns[i]);
      }
      return columns;
    };

    self.applyDisplayChangesReturnMetadata = function (metadata, displayProperties, columns = [], extraColumnProperties = {}, excludeHiddenColumns = true) {
      var _COLUMN_WIDTHS = displayProperties.COLUMN_WIDTHS || {};
      var _COLUMN_ORDER = displayProperties.COLUMN_ORDER || {};
      var _HIDDEN_COLUMNS = displayProperties.HIDDEN_COLUMNS || [];
      var _COLUMN_NAMES = displayProperties.COLUMN_NAMES || {};
      metadata = _.cloneDeep(metadata);
      var sortedMetadata = self.sortMetadataByOrderSpec(metadata, _COLUMN_ORDER);
      angular.forEach(sortedMetadata, function (col, i) {
        if (_HIDDEN_COLUMNS.indexOf(col.internal_name) !== -1 && excludeHiddenColumns) {
          return;
        }
        /*
         *The value of display_name property in col info in the metadata gets overriden by the renamed display name and original display name is lost.
          so we need an extra property to hold the value of original display name.
         */
        if(!col.hasOwnProperty('old_display_name')) {
          col.old_display_name = col.display_name;
        }
        col['display_name'] = _COLUMN_NAMES[col.internal_name] || col.display_name;
        if (_COLUMN_WIDTHS.hasOwnProperty(col.internal_name)) {
          col.width = _COLUMN_WIDTHS[col.internal_name];
        }
        angular.extend(col, extraColumnProperties);
        columns.push(col);
      });
      return columns;
    };


    self.applyDisplayChanges = function (metadata, displayProperties, columnsList, extraColumnProperties) {
      var columns = self.applyDisplayChangesReturnMetadata(metadata, displayProperties, columnsList, extraColumnProperties);
      var _columns = [];
      angular.forEach(columns, function (col, i) {
        col.name = col.display_name;
        col.id = col.internal_name;
        col.field = col.internal_name;
        _columns.push(col);
      });
      return _columns;
    };

    self.getListOfIntNamesFromListOfCols = function (colSpec) {
      var internalNames = [];
      if (colSpec) {
        if (typeof colSpec === 'string') {
          internalNames.push(colSpec)
        }

        if (angular.isArray(colSpec)) {
          angular.forEach(colSpec, function (col) {
            if (typeof col === 'string') {
              internalNames.push(col);
            } else if (typeof col === 'object' && col.hasOwnProperty('internal_name')) {
              internalNames.push(col.internal_name);
            }
          });
        } else if (typeof colSpec === "object") {
          internalNames.push(colSpec.internal_name);
        }
        return internalNames;
      }
    };
    function _add_type_to_display_name_in_column(col){
      col.display_name_w_type = col.display_name + ' (' + type_to_type_desc_map[col.type] + ')';
      return col
    }
    function _add_type_to_display_name(metadata) {
      var new_metadata = _.cloneDeep(metadata);
      angular.forEach(new_metadata, function (item) {
        item.display_name_w_type = item.display_name + ' (' + type_to_type_desc_map[item.type] + ')';
      });
      return new_metadata;
    }

    function add_error_columns_to_metadata(metadata, error_columns, current_ws_id){
      /** Extend metadata with only those error columns which are missing/ no more available*/
      var new_metadata = _.cloneDeep(metadata);
      _.forEach(error_columns, function(err_col){
         var column = metadataUtils.get_column_by_display_name(metadata, err_col['display_name'])
        if (!column){
          if (err_col['reason'] == 'not available' && err_col['dataview']==current_ws_id ) {
            if(!err_col.column.hasOwnProperty('old_display_name')) {
              err_col.column.old_display_name = err_col.column.display_name;
            }
            err_col['column']['display_name_w_type'] = err_col['column'].display_name + ' (' + type_to_type_desc_map[err_col['column'].type] + ')';
            err_col['column']['error'] = err_col['reason']
            new_metadata.push(err_col['column']);
          }
        }
      })
    return new_metadata
    }
  }

  function getColumnType(columnsUsed, displayName){
    /* searches the column info in columnsUsed using display name and returns column type
     * columnsUsed: internal_name to colInfo map */
    var colType;
    for (const colInternalName in columnsUsed){
      if(columnsUsed[colInternalName]['display_name'] == displayName){
        colType = columnsUsed[colInternalName]['type']
        break;
      }
    }
    return colType
  }


  function get_input_columns(condition) {
    /*Iterates over the condition params and gathers columns used in an array*/
    var columns_used = [];
    var logical_op = undefined;

    //  In case condition is None or {}
    if (!condition || _.isEmpty(condition)) {
      return [];
    }

    //Remove the properties not required
    for (const property in condition) {
      if (['STRING_PROP', 'FILTER_TYPE'].indexOf(property) != -1) {
        delete condition[property];
      }
    }

    //In case condition starts with NOT operator, make a recursive call using its values
    if (Object.keys(condition).indexOf('NOT') != -1) {
      return get_input_columns(condition['NOT']);
    }

    //determine the logical operation
    if (Object.keys(condition).indexOf('OR') != -1) {
      logical_op = 'OR';
    } else if (Object.keys(condition).indexOf('AND') != -1) {
      logical_op = 'AND';
    }

    //In case logical operation is peresent iterate over the array of arguments and fetch columns recursively
    if (logical_op) {
      var i;
      for (i = 0; i < condition[logical_op].length; i++) {
        columns_used = columns_used.concat(get_input_columns(condition[logical_op][i]));
      }
    } else {
      //For atomic condition
      var keys = Object.keys(condition);
      if (keys.length > 0) {
        columns_used.push(keys[0]);
        for (const key in condition) {
          if (condition[key] && Object.keys(condition[key]).length > 0) {
            var subcondition = condition[key];
            for (const nestedKey in subcondition) {
              if (subcondition[nestedKey] && Object.keys(subcondition[nestedKey]).length > 0) {
                if (Object.keys(subcondition[nestedKey]).indexOf('COLUMN') != -1) {
                  columns_used.push(subcondition[nestedKey]['COLUMN']);
                }
                if (Array.isArray(subcondition[nestedKey])) {
                  for (const index in subcondition[nestedKey]) {
                    if (Object.keys(subcondition[nestedKey][index]).indexOf('COLUMN') != -1) {
                      columns_used.push(subcondition[nestedKey][index]['COLUMN']);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return columns_used;
  }

  function randomString(len = 10, options = undefined) {
    var text = "";
    if (!options) {
      options = {};
    }
    var defaultsOptions = {
      chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
      lowercase: false
    };

    angular.merge(defaultsOptions, options);

    if (defaultsOptions.lowercase) {
      defaultsOptions.chars = defaultsOptions.chars.toLowerCase();
    }

    len = len || 10;
    for (var i = 0; i < len; i++) {
      text += defaultsOptions.chars.charAt(Math.floor(Math.random() * defaultsOptions.chars.length));
    }
    return text;
  }

  function isMathExpressionValid(expression) {
    if (!angular.isArray(expression)) {
      return false;
    }
    return expression.length != 0;
  }

  /**
   * Source: https://davidwalsh.name/javascript-debounce-function
   *
   * Returns a function, that, as long as it continues to be invoked, will not
   * be triggered. The function will be called after it stops being called for
   * N milliseconds. If `immediate` is passed, trigger the function on the
   * leading edge, instead of the trailing.
   *
   * @param {Function} func Function that should be executed
   * @param {number} wait Time to debounce in milliseconds
   * @param {boolean} immediate trigger the function on the leading edge, instead of the trailing
   **/
  function debounce(func, wait, immediate): any {
    var timeout;
    return function () {
      var context = this, args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  function StringUtils() {
    this.capitalize = function (str, lowercaseFirst) {
      if (lowercaseFirst) {
        str = str.toLowerCase();
      }
      return str.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
    };

    this.format = function (str, args) {
      return str.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined'
          ? args[number]
          : match
          ;
      });
    };

    //This function adds ellipsis in the centre of a string instead of towards the end
    this.addCentreEllipsis = function (str, min_length) {
      if (str == undefined) {
        return
      }
      if (!min_length) {
        min_length = 35;
      }
      if (str.length > min_length) {
        return str.substr(0, Math.floor(min_length / 2)) + '...' + str.substr(str.length - Math.floor(min_length / 2), str.length);
      }
      return str
    }
  }

  function generateInternalName() {
    return ('gcf_' + randomString()).toLowerCase();
    // gc stands for column generated on fronted
  }

  function NumericUtils() {
    function _constructFormatString(format) {
      var format_string = '';
      // if (format.currency_symbol) {
      //   format_string = format.currency_symbol;
      // }

      if (format.comma_separated) {
        format_string += '0,0';
      }
      else {
        format_string += '0';
      }

      if (format.hasOwnProperty('decimal_spec') && format.decimal_spec !== null) {
        if (format.decimal_spec != 0) {
          var decimal_part = '.';
          if (!isNaN(format.decimal_spec)) {
            for (var i = 0; i < parseInt(format.decimal_spec); i++) {
              decimal_part += '0';
            }
          }
          if (decimal_part.length) {
            format_string += decimal_part;
          }
        }
      }
      else {
        format_string += '[.]00000000';
      }

      if (format.is_percentage) {
        format_string += '%';
      }
      return format_string;
    }

    this.format = function (number, format) {
      if (typeof number !== "number") {
        return number;
      }
      var format_string = _constructFormatString(format);
      if (format.is_percentage) {
        number = number / 100;
      }
      var ret = numeral(number).format(format_string);
      if (format.currency_symbol) {
        ret = format.currency_symbol + ret;
      }
      return ret;
    };

    this.unformat = function (number, format) {
      if (typeof number !== "string") {
        return number;
      }
      var ret = numeral().unformat(number);
      if (format.is_percentage) {
        ret = ret * 100;
      }
      return ret;
    };

    this.toWords = function toWords(s) {
      var th = ['', 'thousand', 'million', 'billion', 'trillion'];
      var dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
      var tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
      var tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
      s = s.toString();
      s = s.replace(/[\, ]/g, '');
      if (s != parseFloat(s)) {
        return 'not a number';
      }
      var x = s.indexOf('.');
      if (x == -1) {
        x = s.length;
      }
      if (x > 15) {
        return 'too big';
      }
      var n = s.split('');
      var str = '';
      var sk = 0;
      for (var i = 0; i < x; i++) {
        if ((x - i) % 3 == 2) {
          if (n[i] == '1') {
            str += tn[Number(n[i + 1])] + ' ';
            i++;
            sk = 1;
          }
          else if (n[i] != 0) {
            str += tw[n[i] - 2] + ' ';
            sk = 1;
          }
        }
        else if (n[i] != 0) {
          str += dg[n[i]] + ' ';
          if ((x - i) % 3 == 0) {
            str += 'hundred ';
          }
          sk = 1;
        }


        if ((x - i) % 3 == 1) {
          if (sk) {
            str += th[(x - i - 1) / 3] + ' ';
          }
          sk = 0;
        }
      }
      if (x != s.length) {
        var y = s.length;
        str += 'point ';
        for (var i: number = x + 1; i < y; i++) {
          str += dg[n[i]] + ' ';
        }
      }
      return str.replace(/\s+/g,' ');
    };

    this.compactNumber = function(value, thresholdLength, precision) {

      var str_num = null;
      precision = parseInt(precision || 3);
      thresholdLength = parseInt(thresholdLength || 8);
      //  do type checking
      if (isNaN(Number(value)))
      {
        // return invalid input value as it is
        return value;
      }

      if (typeof value == 'number') {
        str_num = value.toString();
      }
      else if (typeof value == 'string' && value.length) {
        str_num = value;
      }

      if (str_num == null || str_num.length <= thresholdLength) {
        return numeral(value).format('0,0.00');
      }

      return Number(str_num).toExponential(precision).replace('e+0', '');
    };
  }

  function DateUtils() {
    var pythonToMomentFormatMap = {
      a: 'ddd',
      A: 'dddd',
      b: 'MMM',
      B: 'MMMM',
      d: 'DD',
      e: 'D',
      F: 'YYYY-MM-DD',
      H: 'HH',
      I: 'hh',
      j: 'DDDD',
      k: 'H',
      l: 'h',
      m: 'MM',
      M: 'mm',
      p: 'A',
      S: 'ss',
      s: 'X',
      u: 'E',
      w: 'd',
      W: 'WW',
      y: 'YY',
      Y: 'YYYY',
      z: 'ZZ',
      Z: 'ZZ',
      '%': '%'
    };

    this.getMomentFormat = getMomentFormat;
    this.getPythonFormat = getPythonFormat;
    this.strptime = strptime;
    this.strftime = strftime;

    function getMomentFormat(format) {

      if (!Object.keys(format).length){
        format="%Y-%m-%d %H:%M:%S";
      }

      var momentFormat = format, value;
      Object.keys(pythonToMomentFormatMap).forEach(function (key) {
        value = pythonToMomentFormatMap[key];
        momentFormat = momentFormat.replace("%" + key, value);
      });
      return momentFormat;
    }

    function getPythonFormat(format) {
      var pythonFormat = format;
      var pythonFormatSeq = format.split(/[\s.,:\/\-T]+/);
      if (pythonFormatSeq.length === 1) {
        var pythonDirective = "";
        var pythonDirectiveContender = "";
        for (let i = 0; i < pythonFormatSeq[0].length; i++) {
          var val = pythonFormatSeq[0][i];
          if (pythonDirectiveContender === "" || pythonDirectiveContender[0] === val) {
            pythonDirectiveContender += val;
            if (i < pythonFormatSeq[0].length - 1) {
              continue;
            }
          }
          pythonDirective = Object.keys(pythonToMomentFormatMap).find(x => pythonToMomentFormatMap[x] === pythonDirectiveContender)
          if (pythonDirective === undefined) {
            pythonFormat = null;
            break;
          }
          pythonFormat = pythonFormat.replace(pythonDirectiveContender, "%" + pythonDirective);
          pythonDirectiveContender = val;
        }
      }
      else {
        pythonFormatSeq.every(function (val) {
          var pythonDirective = Object.keys(pythonToMomentFormatMap).find(x => pythonToMomentFormatMap[x] === val)
          if (pythonDirective === undefined) {
            pythonFormat = null;
            return false;
          }
          pythonFormat = pythonFormat.replace(val, "%" + pythonDirective);
          return true;
        });
      }
      return pythonFormat;
    }

    function strptime(date, format) {
      var momentFormat = getMomentFormat(format);
      return moment(date, momentFormat);
    }

    function strftime(date, format) {
      var momentFormat = getMomentFormat(format);
      return moment(date).format(momentFormat);
    }

  }

  function saveJSON(name, content) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(content, null, 2)));
    pom.setAttribute('download', name);

    if (document.createEvent) {
      var event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    }
    else {
      pom.click();
    }
  }


  function summarize(recurrence_info) {

    /* recurrence_info here is the mammoth recurrence info json object,
     not the RRule object provided by rrule-alt module,
     Also, this function does validate recurrence_info object*/

    var summary = "Every ";

    var summary_map = {
      minutely: function () {
        return parseInt(recurrence_info.INTERVAL) === 1 ? ' minute' : recurrence_info.INTERVAL + ' minutes';
      },
      hourly: function () {
        return parseInt(recurrence_info.INTERVAL) === 1 ? ' hour' : recurrence_info.INTERVAL + ' hours';
      },
      daily: function () {
        return parseInt(recurrence_info.INTERVAL) === 1 ? 'day' : recurrence_info.INTERVAL + ' days';
      },
      weekly: function () {
        var week: any = _.filter(c.weekdays, {key: recurrence_info.BYWEEKDAY[0]});
        var weekday = week[0].value;
        return parseInt(recurrence_info.INTERVAL) === 1 ? ' week on ' + weekday : recurrence_info.INTERVAL + ' weeks on ' + weekday;
      },
      monthly: function () {
        var monthday = humanize.ordinal(recurrence_info.BYMONTHDAY[0]);
        return parseInt(recurrence_info.INTERVAL) === 1 ? ' month on ' + monthday : recurrence_info.INTERVAL + ' months on ' + monthday;
      }
    };

    if (recurrence_info && recurrence_info.hasOwnProperty("FREQUENCY") && recurrence_info.FREQUENCY) {
      summary = summary.concat(summary_map[recurrence_info.FREQUENCY.toLowerCase()]());

      if (recurrence_info.UNTIL) {
        summary = summary.concat(' until ', moment.utc(recurrence_info.UNTIL).local().format('MMMM D, YYYY HH:mm'))
      }
      else if (recurrence_info.COUNT && parseInt(recurrence_info.COUNT) > 0) {
        summary = summary.concat(' for ', recurrence_info.COUNT, (parseInt(recurrence_info.COUNT) === 1 ? ' time' : ' times'));
      }
    }
    return summary;
  }

  function getScheduleFrequency(recurrenceInfo) {
    const displayMap = {
      MINUTELY: 'Minutely',
      HOURLY: 'Hourly',
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly'
    };
    return recurrenceInfo.FREQUENCY ? displayMap[recurrenceInfo.FREQUENCY] : "Just once";
  }

  function getIdealFormatForMetric(dataviewDisplayProperties, wkspMetadata, expression) {
    let format_info = _.cloneDeep(dataviewDisplayProperties.FORMAT_INFO);
    let metadata = _.cloneDeep(wkspMetadata);
    let idealFormat: any = {
      comma_separated: true,
      decimal_spec: 2
    };
    if (expression.length == 1) {
      let atom = expression[0];
      if (atom.TYPE == "FUNCTION") {
        let val = atom.VALUE;
        let arg = val.ARGUMENT;
        var column = _.find(metadata, function (c) {
          return c.internal_name == arg;
        });
        if (['AVG', 'STDDEV'].indexOf(val.FUNCTION) != -1) {
          let origColFormatInfo = format_info[arg] || column['format'];
          idealFormat.is_percentage = origColFormatInfo?.is_percentage;
        }
        else if (val.FUNCTION == "INT") {
          idealFormat = {
            numtype: "int",
            decimal_spec: 0,
            comma_separated: true
          };
        }
        else if (['SUM', 'MIN', "MAX"].indexOf(val.FUNCTION) != -1) {
          let arg = val.ARGUMENT;
          idealFormat = format_info[arg] || column['format'] || idealFormat;
        }
      }
    }
    idealFormat.comma_separated = true;
    return idealFormat;
  }

  function getComputedStyle(el, styleProp) {
    var camelize = function (str) {
      return str.replace(/\-(\w)/g, function (str, letter) {
        return letter.toUpperCase();
      });
    };

    if (el.currentStyle) {
      return el.currentStyle[camelize(styleProp)];
    } else if (document.defaultView && document.defaultView.getComputedStyle) {
      return document.defaultView.getComputedStyle(el, null)
        .getPropertyValue(styleProp);
    } else {
      return el.style[camelize(styleProp)];
    }
  }

  function filterVerticalWhitespaces(text) {
    let verticalWhitespaceRegex = /[\r\n\f]+/g
    return text.replace(verticalWhitespaceRegex, ' ')
  }

  function copyToClipboard(value) {
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

    function downloadStringDataAsFile(strData, strFileName, strMimeType) {
        var D = document,
            a = D.createElement("a");
        strMimeType = strMimeType || "application/octet-stream";

        if (navigator.msSaveBlob) { // IE10
            return navigator.msSaveBlob(new Blob([strData], {type: strMimeType}), strFileName);
        } /* end if(navigator.msSaveBlob) */

        if ('download' in a) { //html5 A[download]
            a.href = "data:" + strMimeType + "," + encodeURIComponent(strData);
            a.setAttribute("download", strFileName);
            a.innerHTML = "downloading...";
            D.body.appendChild(a);
            setTimeout(function () {
                a.click();
                D.body.removeChild(a);
            }, 66);
            return true;
        } /* end if('download' in a) */

        //do iframe dataURL download (old ch+FF):
        var f = D.createElement("iframe");
        D.body.appendChild(f);
        f.src = "data:" + strMimeType + "," + encodeURIComponent(strData);

        setTimeout(function () {
            D.body.removeChild(f);
        }, 333);
        return true;
    }

  function downloadDataAsFile(data, filename) {
    var a = document.createElement('a');
    a.download = filename;
    a.href = data;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  function linkify(text) {
    var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
  }

  function prettifyTimestampInUTC(timestamp: string) {
    /***
     * Accept a time string as YYYY-MM-DD HH:MM:SS
     * Output it as Weekday, DD MMM YYYY HH:MM:SS
     * e.g Accept "2023-10-25 09:34:21" and output Wed, 25 Oct 2023 09:34:21
     */

    // We add 'Z' to let this string be considered in UTC, if not already present
    if (!timestamp.endsWith('Z')) {
      timestamp += 'Z'
    }

    // formattedTime will be of format Day, DD Mon YYYY HH:MM:SS GMT
    let formattedTime = new Date(timestamp).toUTCString()

    // We now remove GMT from the end
    formattedTime = formattedTime.substring(0, formattedTime.length - 4);
    return formattedTime;
  }

  function fetchCurrentTime() {
    /*
     returns the UTC time synced with the server
     TODO:
     If !localstorage.currenttime or diff(currenttime-localstoragetime) > 1 hour{
     1a. get server time
     1b. calc offset and save to local storage
     1c. save current time to local storage
     }
     else{
     2a. Get offset from local
     2b. Calc diff with current time and return
     }
     */

    var current_timestamp = new Date(); // UTC
    // var UTC_in_ms = (current_timestamp.getTime() + current_timestamp.getTimezoneOffset() * 60 * 1000); //Not required
    var UTC_in_ms = (current_timestamp.getTime());
    var last_synced_at = $window.localStorage.last_synced_at;
    var server_offset = $window.localStorage.serverOffset;

    // Computing offset and return current time with offset
    if (!last_synced_at || (last_synced_at && Math.abs(UTC_in_ms - last_synced_at) >= 900000) || !server_offset) {
      $window.localStorage.last_synced_at = UTC_in_ms;
      var dataURL = config.api.timeSync;

      let success_cb = function(data) {
        let serverDate = data.time;
        server_offset = Math.abs(serverDate - UTC_in_ms);
        $window.localStorage.serverOffset = server_offset;
        let current_time = currentServerDate(server_offset);
        return +current_time._d;
      }
      $http.get(dataURL, {}).then(function (response) {
        success_cb(response.data);
      });
    }
    else {
      var current_time = currentServerDate(server_offset);
      return + current_time._d
    }

    function currentServerDate(serverOffset) {
      return moment().add('milliseconds', serverOffset);
    }
  }

  function moveCursorToEndOfText(element_id) {
    var inputElement = <HTMLInputElement>document.getElementById(element_id);
    var strLength = inputElement.value.length;
    inputElement.focus();
    inputElement.setSelectionRange(strLength, strLength);
  }

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  function insertAt(array, index, element) {
    array.splice(index, 0, element);
  }

  function isKeyValuePairPresentInObject(key, val, obj){
    // It is not possible to break foreach https://github.com/angular/angular.js/issues/263
    // Hence appending to a list and returning a boolean value based on it
    var existing_values = [];
    // TODO: Use for loop instead of foreach to avoid using a new variable
    angular.forEach(obj, function(col, i){
      // Considering values that only starts with the string and not exact match
      // Will not work if there is no col that matches
      if (col[key].toLowerCase().trim() == val.toLowerCase().trim()){
        existing_values.push(i);
      }
    });
    return existing_values.length > 0;
  }

  function prepare_message(error_code, data){
    let msg = c.errorCodetoMessageMap[error_code]
    if(error_code == 35){
      msg += '"' + data.ADDITIONAL_INFO.display_name +'"'
    }
    return msg
  }

  function sanitizeData(value){
    return $("<p/>").html(value).text();
  }

  function sanitizeDataWithType(col){
    col.display_name_w_type = sanitizeData(col.display_name_w_type)
    return col;
  }

  function sanitizeMetadata(metadata){
    for (var i=0; i<metadata.length; i++){
      metadata[i].display_name_w_type = $("<p/>").html(metadata[i].display_name_w_type).text();
      metadata[i].display_name = $("<p/>").html(metadata[i].display_name).text();
    }
    return metadata
  }

  function sanitizeMetadataWithType(metadata){
    for (var i=0; i<metadata.length; i++){
      metadata[i].display_name_w_type = $("<p/>").html(metadata[i].display_name_w_type).text();
    }
    return metadata
  }

  function sanitizeMetadataName(metadata){
    for (var i=0; i<metadata.length; i++){
      metadata[i].display_name = $("<p/>").html(metadata[i].display_name).text();
    }
    return metadata
  }
  function getNewAppUrl() {
    return `//${window.location.host}/n`;
  }

  function isValidDate(dateString) {
    var date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  function isRegexValid(regexString) {
    try {
      new RegExp(regexString);
    } catch(e) {
      return false;
    }
    return true;
  }

  function futureDataTracker(data, track_on_processing_flag=false, identifier=null, request_initiated_at=Date.now()) {
    /*
    *  Common function to track future data.
    * data: future data
    * track_on_processing_flag: if true, will track the future data even if the status is processing
    * identifier: identifier to track the future data
    * request_initiated_at: time at which the request was initiated. This is client time, not server time.
    *
    * */
      let deferred = $q.defer();
      FutureService.track(data.future_id, data_tracker, track_on_processing_flag, identifier, request_initiated_at);

      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }
      return deferred.promise;
    }

  return service;
}
