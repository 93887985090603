/*jslint node: true */
import * as $ from 'jquery'
import * as angular from 'angular'
import * as _ from 'lodash-es'
import {
  getGranularityOption,
  getListCondition,
  getNumericInListCondition,
  getPivotSelectParam,
  handleReduceResolutionRequest,
  numberWithCommas,
  processExploreRangeData,
  reconsileAggParams,
  _filterVerticalWhitespaces
} from './utils'
import { ExploreConsts } from './explore.constants'
import { config } from '../common/app.config'
import { exploreCardService } from './explore.card.service'

/**
 * @ngInject
 */
TextColumnSummaryCard.$inject = [
  'derivatives',
  'eventCallbackManagerFactory',
  'utils',
  'c',
  'CardSummaryDataFactory',
  'analyticsService',
  '$resource',
  '$q',
  'FutureService',
  'globalFilterService',
  'toastNotification',
  'exploreCardService',
]
export function TextColumnSummaryCard(
  derivatives,
  eventCallbackManagerFactory,
  utils,
  c,
  CardSummaryDataFactory,
  analyticsService,
  $resource,
  $q,
  FutureService,
  globalFilterService,
  toastNotification,
  exploreCardService
) {
  return Card

  function Card(
    sourceId,
    sourceType,
    metadata,
    column,
    formatInfo = {},
    sourceUpdateEvent,
    getSourceData,
    exportFileNamePrepend
  ) {
    var card = this
    card.at = 99999
    card._isMenuOpen = false
    card.column = column
    card.sourceId = sourceId
    card.sourceType = sourceType
    card.id = column.internal_name
    // card.id = utils.getRandomString(10);
    card.dirty = false
    card.querying = false
    card.type = 'text'
    card.customWidth = null
    card.selectedValues = []
    card.latestQuery = null
    card.initial_condition = null
    card.sortBy = [['unformatted', 'DESC']]
    card.metadata = metadata
    card.filterDescription = null
    card.sequence = null
    if (column.type == c.date) {
      card.granularity = ExploreConsts.dateGranularityOptions[0]
    } else {
      card.granularity = null
    }
    card.selectedAggregation = {
      aggregation: 'COUNT',
      target: null,
      format: { scientific: true },
    }
    card.formatInfo = formatInfo
    card.changeAggOptionsCb = changeAggOptionsCb
    card.search = {
      open: false,
      string: '',
      toggle: function () {
        card.search.open = !card.search.open
        analyticsService.userEventTrack(c.userEvents.exploreCards.search, {
          eventOrigin: 'dataview.exploreCard.searchButton',
          enabled: card.search.open,
        })
        card.search.string = ''
      },
      reset: function () {
        card.search.open = false
        card.search.string = ''
      },
    }
    card.row_count = null
    card.summary = new CardSummaryDataFactory()
    card.sort = sortFn
    card.setCondition = setCondition
    card.setActive = setActive
    card.unset = unset
    card.describe = describe
    card.textDescribe = textDescribe
    card.deactivate = deactivate
    card.getSelected = getSelected
    card.filter = filter
    card.toggle = toggle
    card.query = query
    card.deselect = deselect
    card.getSelectionCondition = getCondition
    card.getSelectionConditionInverse = getSelectionConditionInverse
    card.lastNotifiedAt = null
    card.loadMoreData = loadMoreData
    card.toggleAndFilter = toggleAndFilter
    card.copyText = copyText
    card.exportData = exportData
    card.describeCardValues = describeCardValues
    card.changeRenderCb = (renderType) => {}
    card.saveConfig = () => {}

    sourceUpdateEvent.add_callback(
      'fromTextExploreCard_' + card.column.internal_name,
      function (changedProps, prevData, newData) {
        card.metadata = _.get(newData, 'metadata', card.metadata)
        if (changedProps.display_properties && _.includes(changedProps.display_properties, 'COLUMN_NAMES')) {
          card.column = _.find(card.metadata, { internal_name: card.column.internal_name }) || card.column
        }
      }
    )

    var cardUpdateEvent = new eventCallbackManagerFactory('CardUpdateEvent-' + card.id)
    card.onSelectionChanged = cardUpdateEvent.add_callback

    var createElementRequest = new eventCallbackManagerFactory('CreateElement-' + card.id)
    card.onElementCreationRequested = createElementRequest.add_callback
    card.fireElementCreationRequest = createElementRequest.fire_event

    function describeCardValues(item) {
      if (!item.value) {
        return '(blank)';
      }
      if (item.value.trim().length) {
        return _filterVerticalWhitespaces(item.value)
      }

      if (item.value.length > 1) {
        return '(spaces)'
      }
      if (item.value == ' ') {
        return 'space'
      }

      return '(blank)'
    }

    function getCondition() {
      var subconditions = []
      var identifier = card.column.internal_name
      let listOfSelected = []
      let isEmpty = false
      let suitableForInListOperation = false
      angular.forEach(card.selectedValues, function (item, i) {
        let val
        if (card.column.type == c.date) {
          val = item.unformatted_value
        } else if (card.column.type == c.numeric) {
          // If value does not contain the text 'to' and its not empty i.e used for range values it is suitableForInListOperation
          if (!item.formatted.includes('to') && item.unformatted_value) {
            suitableForInListOperation = true
          } 
          val = item.unformatted_value
          
        } else {
          val = item.value
        }

        if (val === null || val === undefined) {
          isEmpty = true
        } else {
          listOfSelected.push(val)
        }
      })
      if (isEmpty) {
        var subcondition = {}
        subcondition[identifier] = { IS_EMPTY: true }
        subconditions.push(subcondition)
      }
      if (listOfSelected.length) {
        let subcondition
        if (card.column.type == c.text) {
          subcondition = {}
          subcondition[identifier] = { IN_LIST: listOfSelected }
        } else {
          if (card.column.type == c.numeric && suitableForInListOperation) {
            subcondition = getNumericInListCondition(card.column.internal_name, listOfSelected)
          } else {
            subcondition = getListCondition(
              listOfSelected,
              card.column.internal_name,
              card.column.type,
              'EQ',
              card.level
            )
          }
        }
        subconditions.push(subcondition)
      }
      if (subconditions.length == 1) {
        return subconditions[0]
      } else if (subconditions.length == 0) {
        return null
      } else {
        return { OR: subconditions }
      }
    }
    function copyText() {
      let data = []
      if (card.selectedValues.length > 0) {
        data = card.selectedValues
      } else {
        data = card.data.slice(0, 200)
      }
      exploreCardService.copyToClipboard(card, data)
    }

    async function exportData() {
      let data = []
      if (card.column.type == c.text) {
        if (card.selectedValues.length) {
          exploreCardService.exportAsCSV(card, card.selectedValues, exportFileNamePrepend)
        } else {
          let param = _getParams(100000, null)
          var deferred = $q.defer()
          let requestInitiatedAt = Date.now()
          let identifier = exploreCardService.cardIdentifier(card)
          getSourceData({ args: param }).then(function (data) {
            utils.futureDataTracker(data, false, identifier, requestInitiatedAt).then(function (response) {
              exploreCardService.exportAsCSV(card, response.data, exportFileNamePrepend)
              deferred.resolve(response)
            })
          }, deferred.reject)
        }
      } else {
        if (card.selectedValues?.length) {
          data = card.selectedValues
        } else {
          data = card.data
        }
        exploreCardService.exportAsCSV(card, data, exportFileNamePrepend)
      }
    }

    function getSelectionConditionInverse() {
      var subconditions = []
      var identifier = card.column.internal_name
      let listOfSelected = []
      let isEmpty = false
      angular.forEach(card.selectedValues, function (item, i) {
        let val
        if (card.column.type == c.date || card.column.type == c.numeric) {
          val = item.unformatted_value
        } else {
          val = item.value
        }

        if (val === null || val === undefined) {
          isEmpty = true
        } else {
          listOfSelected.push(val)
        }
      })
      if (isEmpty) {
        var subcondition = {}
        subcondition[identifier] = { IS_NOT_EMPTY: true }
        subconditions.push(subcondition)
      }
      if (listOfSelected.length) {
        let subcondition
        if (card.column.type == c.text) {
          subcondition = {}
          subcondition[identifier] = { NOT_IN_LIST: listOfSelected }
        } else {
          subcondition = getListCondition(listOfSelected, card.column.internal_name, card.column.type, 'NE', card.level)
        }
        subconditions.push(subcondition)
      }
      if (subconditions.length == 1) {
        return subconditions[0]
      } else if (subconditions.length == 0) {
        return null
      } else {
        return { AND: subconditions }
      }
    }

    function changeAggOptionsCb(removeCondition = true) {
      analyticsService.userEventTrack(c.userEvents.exploreCards.changeAggregation, {
        eventOrigin: 'dataview.exploreCard',
        cardType: 'column',
        dataType: 'text',
      })
      if (removeCondition) {
        card.removeCondition(card)
      }
      card.query()
      card.saveConfig()
    }

    function getSelected() {
      var selected = []
      card.selectedValues.forEach(function (item) {
        selected.push(item.value)
      })
      return selected
    }

    function deactivate(preventSearchReset) {
      if (card.isActionPossible && !preventSearchReset) {
        card.search.reset()
      }
      card.isActionPossible = false
      card.selectedValues = []
      if (card.data) {
        card.data.forEach(function (i) {
          i.selected = false
        })
      }
    }

    function _setGranularity(level, save = false) {
      let granularity = getGranularityOption(card.column.type, level)
      if (!angular.equals(granularity, card.granularity)) {
        card.granularity = granularity
        card.level = level
        if (save) {
          card.saveConfig()
        }
      }
    }

    function _raiseToastWarning(msg) {
      console.error(msg)
    }

    function setCondition(condition?) {
      let globalFilterCondition = card.globalFilter.getByConditionId(card.id)
      if (!globalFilterCondition) {
        card.deselect()
      }
      if (!angular.equals(card.initial_condition, condition)) {
        card.initial_condition = condition
        card.query()
      }
    }

    function filter(accumulate?) {
      if (accumulate) {
        card.data.forEach(function (item) {
          if (item.isActive) {
            if (!_isSelected(item)) {
              card.selectedValues.push(item)
            }
          }
        })
      }

      var condition = getCondition()
      card.isActionPossible = false
      if (!condition) {
        card.isActive = false
      }
      cardUpdateEvent.fire_event(condition)
      card.filterDescription = _describeSelected()
      card.selectedValues = []

      // store card filter
      const cardState = { 0: { filterDescription: card.filterDescription, initialCondition: card.initial_condition } }
      card.globalFilter.setCardState(card.id, cardState)
    }

    function toggle(item) {
      item.selected = !item.selected
      if (item.selected) {
        card.selectedValues.push(item)
      } else {
        card.selectedValues.splice(card.selectedValues.indexOf(item), 1)
      }
      card.isActionPossible = !!card.selectedValues.length
    }

    function _getParams(limit?, skip?) {
      function _getPivotParam() {
        let minMaxColumn = card.column.type == c.text ? null : card.column.internal_name
        let selectParam = getPivotSelectParam(
          card.selectedAggregation.aggregation,
          card.selectedAggregation.target,
          minMaxColumn
        )
        let groupByParam
        if (card.column.type == c.date) {
          let queryLevel = 'AUTO'
          if (card.granularity) {
            queryLevel = card.granularity.internal
          }
          groupByParam = [
            { COLUMN: card.column.internal_name, INTERNAL_NAME: 'value', TRUNCATE: queryLevel },
            { COLUMN: card.column.internal_name, INTERNAL_NAME: 'unformatted_value', TRUNCATE: queryLevel },
          ]
        } else if (card.column.type == c.numeric) {
          let queryLevel = 'AUTO'
          if (card.granularity) {
            queryLevel = card.granularity.internal
          }
          groupByParam = [
            { COLUMN: card.column.internal_name, INTERNAL_NAME: 'value', RESOLUTION: queryLevel },
            { COLUMN: card.column.internal_name, INTERNAL_NAME: 'unformatted_value', RESOLUTION: queryLevel },
          ]
        } else {
          groupByParam = [{ COLUMN: card.column.internal_name, INTERNAL_NAME: 'value' }]
        }

        var pivotParam: any = {
          PIVOT: {
            SELECT: selectParam,
            GROUP_BY: groupByParam,
          },
        }
        if (card.initial_condition) {
          pivotParam.CONDITION = card.initial_condition
        }
        if (Number.isInteger(card.sequence)) {
          pivotParam[c.sequenceNumber] = card.sequence
        }
        return pivotParam
      }

      function _getDisplayProps(skip?, limit?) {
        let format = _getFormat(),
          nullsSorting
        if (_.get(card, 'sortBy[0][1]') == 'DESC') {
          nullsSorting = 'NULLSLAST'
        } else {
          nullsSorting = 'NULLSFIRST'
        }
        let displayProps = {
          FORMAT_INFO: format,
          SORT: [
            ['value', 'DESC', 'NULLSONLY'],
            [_.get(card, 'sortBy[0][0]', 'unformatted'), _.get(card, 'sortBy[0][1]', 'DESC'), nullsSorting],
          ],
        }
        // Adding the text condition in display props for search.
        // This will ensure that it does not effect the query hash computed for caching
        // only add condition for text column type. For other column types, the condition is frontend based.
        if (card.column.type == c.text && card.search.string && '(blank)'.includes(card.search.string)) {
          displayProps['CONDITION'] = {
            OR: [{ value: { IS_EMPTY: true } }, { value: { ICONTAINS: card.search.string } }],
          }
        } else if (card.column.type == c.text && card.search.string) {
          displayProps['CONDITION'] = { value: { ICONTAINS: card.search.string } }
        }

        if (limit) {
          displayProps['LIMIT'] = limit
        } else if (card.column.type == c.text) {
          displayProps['LIMIT'] = card.pagination.pageSize
        }
        if (skip) {
          displayProps['SKIP'] = skip
        }
        return displayProps
      }

      function _getFormat() {
        let format = {
          percentage: { numtype: 'float', decimal_spec: 1 },
          agg: card.selectedAggregation.format,
        }
        if (card.column.type == c.date) {
          if (card.granularity) {
            format['value'] = { date_format: card.granularity.format }
          }
          format['unformatted_value'] = { date_format: c.datetimeServerSideFormat }
        }
        return format
      }
      return {
        param: _getPivotParam(),
        display_properties: _getDisplayProps(skip, limit),
      }
    }

    function query(limit?, skip?, append?) {
      // get the stored condition
      let cardState = card.globalFilter.getCardState(card.id)
      if (!_.isEmpty(cardState)) {
        const { filterDescription, initialCondition } = cardState[0]
        card.initial_condition = initialCondition
        card.filterDescription = filterDescription
        card.isActive = true
      }
      // text cards does not include active values

      let valid = reconsileAggParams(card.metadata, card.selectedAggregation, changeAggOptionsCb)
      if (!valid) {
        return
      }
      if (!append) {
        card.pagination = {
          noOfPages: 1,
          currentPage: 0,
          pageSize: 200,
        }
        card.data = null
      }
      card.querying = true
      let param = _getParams(limit, skip)

      var deferred = $q.defer()
      let requestInitiatedAt = Date.now()
      let identifier: string = exploreCardService.cardIdentifier(card)
      getSourceData({ args: param }).then(function (result) {
        utils.futureDataTracker(result, false, identifier, requestInitiatedAt).then(function (response) {
          _dataCb(response, append)
          deferred.resolve(response)
        })
      }, deferred.reject)
      _querySummaryData()
      return deferred.promise
    }

    function loadMoreData() {
      if (card.querying || card.column.type !== c.text) {
        return
      }
      if (card.pagination.currentPage + 1 >= card.pagination.noOfPages) {
        return
      }
      card.pagination.currentPage += 1
      card.offset = card.pagination.currentPage * card.pagination.pageSize
      card.limit = card.pagination.pageSize
      card.query(card.limit, card.offset, true)
    }
    function _querySummaryData() {
      card.summary.query(
        card.sourceId,
        card.sourceType,
        card.column,
        card.initial_condition,
        card.formatInfo,
        card.sequence,
        getSourceData
      )
    }

    /**
     * A callback function that processes the query data for text explore cards
     * @param {object} data
     * @param append
     * @returns void
     */
    function _dataCb(data, append?) {
      // Text explore card - data loaded event tracker
      let row_count = data.row_count
      // when there is no data as row_count is zero show blank card.
      if (row_count) {
        card.unique_count = numberWithCommas(row_count)
        card.pagination.noOfPages = Math.ceil(row_count / card.pagination.pageSize)
        if (card.column.type == c.text) {
          data.data.forEach(function (r) {
            r.unformatted = parseFloat(r.unformatted)
          })
          if (append) {
            card.data = card.data.concat(data.data)
          } else {
            card.data = data.data
          }
        } else if (card.column.type == c.numeric || card.column.type == c.date) {
          let currentLevel = 'AUTO'
          if (card.granularity) {
            currentLevel = card.granularity.internal
          }
          let roundAggs = false
          if (angular.equals(card.selectedAggregation.format, {})) {
            roundAggs = true
          }
          let result = processExploreRangeData(
            card.column.type,
            card.type,
            data.data,
            currentLevel,
            0,
            ExploreConsts.listThreshold,
            roundAggs
          )
          if (result.reduceResolution) {
            handleReduceResolutionRequest(
              card.column.type,
              card.column.display_name,
              result.level,
              _setGranularity,
              _raiseToastWarning,
              query
            )
            return
          }
          card.level = result.level
          if (!card.granularity) {
            _setGranularity(result.level)
          }

          if (!angular.equals(card.data, result.data)) {
            card.data = result.data
          }
        }
      }
      card.querying = false
      card.setActive(card.isActive)
    }
    function toggleAndFilter(item) {
      card.toggle(item)
      if (card.isActionPossible) {
        card.filter()
      }
    }

    function deselect() {
      if (card.data) {
        card.data.forEach(function (r) {
          r.selected = false
        })
      }
      card.isActive = false
      card.selectedValues = []
      card.filterDescription = null
    }

    function _isSelected(item) {
      var _filtered = $.grep(card.selectedValues, function (v: any) {
        return v.value == item.value
      })
      return _filtered.length > 0
    }

    function setActive(value) {
      card.isActive = value
      if (card.data) {
        let selectedValues = []
        card.data.forEach(function (r) {
          let items = card.filterDescription || []
          r.isActive =
            items.filter(function (v: any) {
              return v.value == r.value
            }).length > 0
          let selected = _isSelected(r)
          if (selected && !r.isActive) {
            r.selected = true
            selectedValues.push(r)
          } else {
            r.selected = false
            selectedValues.splice(selectedValues.indexOf(r), 1)
          }
        })
      }
    }

    function unset(item) {
      analyticsService.userEventTrack(c.userEvents.exploreCards.unsetItemFromExploreCardFilter, {
        eventOrigin: 'dataview.exploreCard',
      })
      let itemInData = card.data.find((i) => i.value == item.value)
      itemInData.selected = false
      itemInData.isActive = false
      card.selectedValues = []
      card.data.forEach(function (r) {
        r.selected = r.isActive
        if (r.selected) {
          card.selectedValues.push(r)
        }
      })
      card.search.reset()
      filter()
    }

    function describe() {
      if (!angular.isArray(card.filterDescription)) {
        return _filterVerticalWhitespaces(card.filterDescription)
      }
      return card.filterDescription
    }

    function textDescribe() {
      if (angular.isArray(card.filterDescription)) {
        let valKey = 'value'
        if (card.column.type != c.text) {
          valKey = 'formatted'
        }
        let blankHtml = '<span class="font-weight-bold ">blank</span>'
        let describedText = card.filterDescription.map((x) => x[valKey] || blankHtml).join(', ')
        return _filterVerticalWhitespaces(describedText)
      }
      return _filterVerticalWhitespaces(card.filterDescription)
    }

    function _describeSelected() {
      var vals = []
      $.each(card.selectedValues || [], function (i, item) {
        vals.push(item)
      })
      return vals
    }

    function sortFn(spec) {
      card.sortBy = spec
      var sortByKey = spec[0][0]
      var sortByDirection = spec[0][1] == 'ASC' ? 1 : -1

      card.data.sort(function (a, b) {
        let aVal = a[sortByKey]
        let bVal = b[sortByKey]
        let aActualVal = a.value
        let bActualVal = b.value
        if (aActualVal === null) {
          return -1
        }
        if (bActualVal === null) {
          return 1
        }
        // sort empty values according to the sortByDirection
        if (!aVal) {
          return sortByDirection * -1
        }
        if (!bVal) {
          return sortByDirection
        }
        if (aVal > bVal) {
          return sortByDirection
        } else {
          return sortByDirection * -1
        }
      })
    }
  }
}

filterTextItems.$inject = ['$filter']

export function filterTextItems($filter) {
  return function (items, search) {
    if (!search || search === '') {
      // search string is not provided return the original list
      return items
    } else {
      if (items.length > 1) {
        // ignore the presence of blank as the first item in the list
        if (typeof items[1].value == 'string') {
          return $filter('filter')(items, { formatted: search })
        } else {
          //this is a range explore card
          return items.filter(function (el) {
            //we had added comma in formatting elsewhere. remove those before searching. also do lowercase search
            return el.formatted.replace(/,/g, '').toLowerCase().includes(search.replace(/,/g, '').toLowerCase())
          })
        }
      }
    }
  }
}
