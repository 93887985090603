import * as $ from 'jquery';
import * as _ from 'lodash-es';

/**
 * @ngInject
 * @returns {{get_manager: get_manager}}
 */

destinationColumnManagerFactory.$inject = ['utils', 'eventCallbackManagerFactory', 'c'];
export function destinationColumnManagerFactory(utils, eventCallbackManagerFactory, c) {
  var service: any = {};
  service.get_manager = get_manager;

  return service;

  function get_manager(options) {
    return new DestinationColumnManager(options);
  }

  function DestinationColumnManager(options) {
    var metadata = options.metadata, allowed_types = options.allowedTypes, taskUtils = options.taskUtils;
    var self = this;
    self.internal_name_to_col_map = options.internal_name_to_col_map || utils.metadata.get_internal_name_to_col_map(metadata);
    let changeTypeEvent = new eventCallbackManagerFactory('ChangeColumnTypeEvent');
    let allowedColumnChangeEvent = new eventCallbackManagerFactory('ChangeColumnTypeEvent');
    self.inited = false;
    self.validate = validate;
    self.getParam = getParam;
    self.setParam = setParam;
    self.sanitizeParam = sanitizeParam;
    self.isDisabled = options.isDisabled || false;
    self.isDestinationColumnValid = true;
    self.destinationColumnMessage = '';
    self.enable = enable;
    self.disable = disable;
    self.fireChangedColumnType = fireChangedColumnType;
    self.onChangeColumnTypeEvent = changeTypeEvent.add_callback;
    self.onAllowedColumnChangeEvent = allowedColumnChangeEvent.add_callback;

    self.new_column = true;
    self.new_column_name = '';
    self.new_column_internal_name = utils.metadata.generateInternalName();
    self.show_new_column_type_selector = true;
    self.destination_column = null;
    self.isDestinationFormatterVisible = options.isDestinationFormatterVisible || false;
    self.allowed_columns = [];
    self.excluded_columns = new Set();
    self.allowed_types = allowed_types;
    self.internal_name_to_allowed_col_map = {};
    self.destinationFormat = {};
    self.setAllowedTypesAndColumns = setAllowedTypesAndColumns;
    self.setIfNewColumn = setIfNewColumn;
    self.updateDestinationType = updateDestinationType;
    self.setDestinationFormat = setDestinationFormat;

    if (allowed_types != undefined) {
      setAllowedTypesAndColumns(allowed_types);
    }
    self.inited = true;
    self.onChangeColumnTypeEvent('ChangeColumnTypeEvent', resetDestinationFormatting);

    function enable() {
      self.isDisabled = false;
    }

    function disable() {
      self.isDisabled = true;
    }

    function setIfNewColumn(isNew) {

      // The destination type should remain as the source type if there is only one allowed type else it should be set to null and the user
      // should explicitly set the type.
      if (self.allowed_types.length > 1) {
        self.destination_column = self.destination_type = null;
      }
      if (isNew !== undefined) {
        self.new_column = !!isNew;
        _setDestinationHighlight(self.destination_column && !self.new_column);
      }
    }

    function setAllowedTypesAndColumns(allowed_types, default_type = undefined, exclude_columns: Set<string> = undefined) {
      self.allowed_types = allowed_types;
      self.allowed_columns = [];
      self.internal_name_to_allowed_col_map = {};
      if (!exclude_columns) {
        exclude_columns = new Set();
      }
      self.excluded_columns = exclude_columns;

      $.each(metadata, function (i, column) {
        //If column type is allowed and column is not excluded
        if (allowed_types.includes(column.type) && !exclude_columns.has(column.internal_name)) {
          let col = utils.sanitizeDataWithType(column);
          self.allowed_columns.push(col);
          self.internal_name_to_allowed_col_map[col.internal_name] = col;
        }
      });

      if (!default_type && self.allowed_types.length == 1) {
        default_type = allowed_types[0];
      }

      if (!self.new_column && self.destination_column) {
        if (!self.internal_name_to_allowed_col_map[self.destination_column.internal_name]) {
          self.destination_column = null;
        } else {
          default_type = self.destination_column.type;
        }
      }

      if (self.destination_type != default_type) {
        self.destination_type = default_type;
      }
      allowedColumnChangeEvent.fire_event()
    }

    function updateDestinationType(highlight_column = true) {
      if (self.destination_column && self.allowed_types) {
        if (self.allowed_types.includes(self.destination_column.type)){
          self.destination_type = self.destination_column.type;
        }else{
          self.destination_type = self.allowed_types[0]
        }
      }
      if (highlight_column) {
        _setDestinationHighlight(self.destination_column && !self.new_column);
      }
    }

    function setDestinationFormat(format) {
      if (self.destination_type == 'DATE'){
        self.destinationFormat = _.get(format, 'date_format');

      } else {
        self.destinationFormat = format;
      }
    }

    function _setDestinationHighlight(shouldSet) {
      if (taskUtils) {
        if (shouldSet) {
          taskUtils.highlight.destinations(self.destination_column);
        } else {
          taskUtils.highlight.destinations();
        }
      }
    }


    function getParam() {
      if (self.new_column) {
        var retParam: any = {
          AS: {
            COLUMN: self.new_column_name,
            TYPE: self.destination_type
          }
        };
        if (self.destinationFormat !== undefined && ['DATE'].indexOf(retParam.AS.TYPE) !== -1 && self.isDestinationFormatterVisible) {
          retParam.AS.FORMAT = { "date_format": self.destinationFormat, tz: "UTC" };
        }
        if (self.destinationFormat !== undefined && ['NUMERIC'].indexOf(retParam.AS.TYPE) !== -1 && self.isDestinationFormatterVisible) {
          retParam.AS.FORMAT = self.destinationFormat;
        }
        retParam.AS.INTERNAL_NAME = self.new_column_internal_name;
        return retParam;
      }
      else {
        return {
          DESTINATION: self.destination_column.internal_name
        };
      }
    }
    function sanitizeParam(param) {
      if (param.hasOwnProperty('AS') && self.allowed_types && self.allowed_types.indexOf(param.AS.TYPE) == -1) {
        param.AS.TYPE = self.destination_type;
        param.AS.FORMAT = {}
      }
    }
    function setParam(param, highlight_column = true) {
      // self.sanitizeParam(param);
      if (param.hasOwnProperty('AS') && !_.isEmpty(param.AS)) {
        self.new_column_name = param.AS.COLUMN;
        self.destination_type = param.AS.TYPE;
        if (param.AS.INTERNAL_NAME) {
          self.new_column_internal_name = param.AS.INTERNAL_NAME;
        }
        if (param.AS.FORMAT) {
          self.setDestinationFormat(param.AS.FORMAT);
        }
      }
      /* When both save to new column and save to existing column params are present
         save to existing column (if it is not empty)
         BugFix: MVP-10736
      */
      if (param.hasOwnProperty('DESTINATION') && !_.isEmpty(param.DESTINATION)) {
        self.new_column = false;
        self.destination_column = this.internal_name_to_col_map[param['DESTINATION']];
        /** Note: If destination column not there in allowed columns then add it**/
        // var dst_col_in_allowed = utils.metadata.get_column_by_internal_name(self.allowed_columns, param['DESTINATION']);
        let dst_col_in_allowed = self.internal_name_to_allowed_col_map[param['DESTINATION']];
        if (!dst_col_in_allowed && self.destination_column) {
          self.allowed_columns.push(self.destination_column)
          self.internal_name_to_allowed_col_map[self.destination_column.internal_name] = self.destination_column;
        }
        updateDestinationType(highlight_column);
      }
    }


    function validate() {
      if (self.isDisabled) {
        return true
      }
      if (self.new_column) {
        if (!self.new_column_name) {
          self.error = "New column name can not be empty";
          return false;
        }
        if (!self.destination_type) {
          self.error = "Select a type for the column";
        }
        // if($.grep(metadata, function(c: any){
        //     return c.display_name.toLowerCase() == self.new_column_name.toLowerCase()
        //   }).length > 0){
        //   self.error = "New column name is not unique";
        //   return false;
        // }
      }
      else {
        if (!self.destination_column) {
          self.error = "Destination column can not be empty";
          return false;
        }
        else if (self.allowed_types.indexOf(self.destination_column.type) == -1) {
          self.error = "Select column of allowed type";
          self.isDestinationColumnValid = false
          self.destinationColumnMessage = utils.getColumnErrorMessage('type mismatch')
          return false;
        }
        else if (self.destination_column.hasOwnProperty('error')) {
          self.isDestinationColumnValid = false
          self.destinationColumnMessage = utils.getColumnErrorMessage(self.destination_column.error)
          return false;
        } 
        self.isDestinationColumnValid = true
        self.destinationColumnMessage = ''
      }
      return true;
    }
    function resetDestinationFormatting(type) {
      if (type == c.date) {
        self.destinationFormat = c.datetimeServerSideFormat;
      }
      else {
        self.destinationFormat = {};
      }

    }
    function fireChangedColumnType() {
      changeTypeEvent.fire_event(self.destination_type);
    }
  }
}


export function valDestinationCol() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valDestinationCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valDestinationCol = function (modelValue, viewValue) {
        var is_valid = true
        var allowedTypes = scope.$eval(attrs.valDestinationCol)
        if (modelValue && modelValue.hasOwnProperty('error')) {
          is_valid = false
        }
        if (allowedTypes && modelValue && allowedTypes.indexOf(modelValue.type) == -1) {
          is_valid = false
        }
        return is_valid
      };
    }
  };
}
