<template>
  <div class="mm-data-addition-third-party-metrics-file-list height-100">
    <div v-if="isLoading" class="loading height-100">
      <mm-loader class="m-x-auto" size="large" />
    </div>

    <div v-else class="content">
      <div class="content-wrapper m-x-2">
        <mm-dropdown
          v-model="selectedFile"
          :items="fileList"
          item-text="name"
          :title="$t('data_library.data_addition.third_party.file')"
          :error="!!errorMessage"
          :message="errorMessage"
          searchable
          hide-details
          @change="validatePattern"
        />

        <div class="d-flex m-t-2 m-b-4">
          <mm-icon name="information" />
          <span class="m-l-2 mm-text--caption-regular">
            {{ $t('data_library.data_addition.third_party.file_list_info', { types: connector.metrics.fileTypes }) }}
          </span>
        </div>

        <div v-if="connector.metrics.enableFuturePulls">
          <mm-dropdown
            v-model="pullMode"
            :items="PULL_MODE_ITEMS"
            :title="$t('data_library.data_addition.third_party.future_pulls_options')"
          />

          <div v-if="pullMode == PULL_MODE_VALUES.PATTERN">
            <mm-dropdown
              v-model="folderToPull"
              :items="folderList"
              item-text="name"
              :title="$t('data_library.data_addition.third_party.folder_to_pull')"
              searchable
            />

            <mm-text-input
              v-model="filePattern"
              :title="$t('data_library.data_addition.third_party.file_pattern')"
              :placeholder="$t('data_library.data_addition.third_party.file_pattern_placeholder')"
              :error="!!filePatternErrorMessage"
              :message="filePatternErrorMessage"
              @input="validatePattern"
            />
            <div class="d-flex m-t-2 m-b-3">
              <mm-icon name="information" />
              <span class="m-l-2 mm-text--caption-regular">
                {{ $t('data_library.data_addition.third_party.file_pattern_description') }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="actions d-flex p-a-2">
        <mm-button :label="$t('global.dictionary.back')" objective="tertiary" @click="$emit('back')" />
        <mm-button
          class="m-l-auto"
          :label="$t(`global.dictionary.${connector.scheduler && connector.scheduler.skip ? 'submit' : 'next'}`)"
          :disabled="!computedIsSubmitEnabled"
          :loading="isSubmitted || isSubmitLoading"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

// Utils
import { isFilePatternValid } from '@/utils/validations'

export default {
  name: 'data-library-data-addition-third-party-metrics-file-list',
  props: {
    connector: {
      type: Object,
      required: true
      /*{
        key: string,
        name: string,
        icon: string,
        category: string,
        connection: {...},
        metrics: {...}
      }*/
    },
    connection: {
      type: Object,
      required: true
      /*{
        value: string,
      }*/
    },
    isSubmitLoading: Boolean
  },
  data: () => ({
    selectedFile: null,
    isLoading: true,
    isSubmitted: false,
    errorMessage: '',
    fileList: [],
    pullMode: '',
    folderToPull: '',
    folderList: [],
    filePattern: '',
    filePatternErrorMessage: ''
  }),
  computed: {
    computedIsSubmitEnabled() {
      if (!this.connector.metrics.enableFuturePulls || this.pullMode == this.PULL_MODE_VALUES.SAME)
        return !!this.selectedFile
      return this.selectedFile && this.folderToPull && this.filePattern && !this.filePatternErrorMessage
    }
  },
  async created() {
    // Specify pull mode values recognized by the backend
    this.PULL_MODE_VALUES = { SAME: 'Pull same file', PATTERN: 'Pull next file based on name pattern' }
    this.PULL_MODE_ITEMS = [
      { text: this.$t('data_library.data_addition.third_party.pull_same_file'), value: this.PULL_MODE_VALUES.SAME },
      { text: this.$t('data_library.data_addition.third_party.pull_next_file'), value: this.PULL_MODE_VALUES.PATTERN }
    ]
    if (this.connector.metrics.enableFuturePulls) this.pullMode = this.PULL_MODE_VALUES.SAME

    this.metricsInfo = await dataLibraryApi.getMetricsInfo(
      this.connector.hash,
      this.connection.value,
      this.$store.state.projectId
    )

    if (this.metricsInfo.fileList) {
      this.fileList = this.metricsInfo.fileList
      this.folderList = this.metricsInfo.folderList
    } else {
      if (this.metricsInfo.message) this.$toast.show({ content: this.metricsInfo.message, status: 'error' })
      this.$emit('back')
    }

    this.isLoading = false
  },
  methods: {
    async submit() {
      this.isSubmitted = true
      this.errorMessage = ''

      const response = await dataLibraryApi.validateFileList(
        this.connector.hash,
        this.connection.value,
        this.selectedFile,
        this.$store.state.projectId,
        () => this._isDestroyed
      )

      if (response?.is_valid)
        this.$emit('submit', {
          selectedFile: this.selectedFile,
          table: { name: this.selectedFile.split('/').slice(-1)[0] },
          pullMode: this.pullMode,
          ...(this.pullMode == this.PULL_MODE_VALUES.PATTERN && {
            folderToPull: this.folderToPull,
            filePattern: this.filePattern
          })
        })
      else this.errorMessage = this.$t('data_library.data_addition.third_party.invalid_file')

      this.isSubmitted = false
    },
    validatePattern() {
      if (!this.selectedFile) return

      this.errorMessage = ''
      this.filePatternErrorMessage = ''

      const validation = isFilePatternValid(
        this.selectedFile,
        this.selectedFile.split('/').slice(-1)[0],
        this.filePattern
      )
      if (!validation?.isValid) this.filePatternErrorMessage = validation?.errorMessage
    }
  }
}
</script>

<style lang="scss" scoped>
.mm-data-addition-third-party-metrics-file-list {
  flex-direction: column;
  display: flex;

  .loading {
    align-items: center;
    display: flex;
  }

  .content {
    flex-direction: column;
    display: flex;
    height: 100%;

    .content-wrapper {
      flex-direction: column;
      display: flex;
      flex-grow: 1;
    }
  }

  .actions {
    align-items: end;
    flex-grow: 1;
  }
}
</style>
