
/**
 * @ngInject
 * @returns {{get_manager: get_manager}}
 */
mathManagerFactory.$inject = ['expressionManagerFactory'];
export function mathManagerFactory(expressionManagerFactory) {
  return {get_manager: get_manager};

  function get_manager(options) {
    options.mode = 'math';
    return new expressionManagerFactory.get_manager(options);
  }
}

