import * as _ from 'lodash-es';

/**
 * @ngInject
 * Future Request service
 */
FutureService.$inject = ['$timeout', 'eventCallbackManagerFactory', 'config', '$resource', 'c'];
export function FutureService($timeout, eventCallbackManagerFactory, config, $resource, c) {
  const futureRequest = $resource(config.api.futureRequest);
  var listeners_by_future_id = {};
  let processing_status_track_map = {}; // only if corresponding value is true, we make get request for futures in processing state
  let query_set = [];
  let identifier_to_future_map = {}
  let request_tracked_event = new eventCallbackManagerFactory('track_future_request');
  let zero_pending_requests_event = new eventCallbackManagerFactory('fire_request_listeners');
  var future_service = {
    set_updates: _set_updates,
    track: track,
    on_track_request: request_tracked_event.add_callback,
    on_zero_pending_requests: zero_pending_requests_event.add_callback,
  };

  function track(future_id, listener, track_on_processing_flag=false, request_identifier=null, request_initiated_at=Date.now()) {
    /*
    * Track a future request by providing future_id and listener.
    * Arguments:
    *   future_id: unique id for the future request, as returned by the server
    *   listener: callback function to be called when the future request is updated
    *   track_on_processing_flag: boolean flag to indicate whether to track the future request even if it is in processing state. Default is false.
    *                              If track_on_processing_flag is true, then listener will be called on updates even if the future is in processing state.
    *   request_identifier: unique identifier for the request. Default is null. If provided, then only the latest request with
    *                       the same identifier will be tracked. This is useful to prevent race conditions when multiple requests are made
    *                       for the same resource. For example, if we want to track the latest request for a particular component, we can provide
    *                       the component name as the identifier or a dynamic identifier for e.g. in case of explore cards, we can create a unique identifier
    *                       as ${card.sourceType}_${card.sourceId}_${card.column.display_name}.
    *   request_initiated_at: timestamp when the request was initiated. Default is Date.now(). This is useful to prevent processing stale requests.
    * */

    if (request_identifier){
      if (!identifier_to_future_map[request_identifier] || request_initiated_at > identifier_to_future_map[request_identifier].request_initiated_at) {
        if (identifier_to_future_map[request_identifier]) {
          untrack_future(identifier_to_future_map[request_identifier].future_id);
        }
        identifier_to_future_map[request_identifier] = {future_id: future_id, request_initiated_at: request_initiated_at};
      }
    } else {
      console.warn("Provide an identifier and the request start timestamp for the future tracker to prevent stale future response")
    }
    if (!listeners_by_future_id[future_id]) {
      listeners_by_future_id[future_id] = [];
    }
    if (listeners_by_future_id[future_id].indexOf(listener) == -1) {
      listeners_by_future_id[future_id].push(listener);
      processing_status_track_map[future_id] = track_on_processing_flag
    }
    request_tracked_event.fire_event();
  }

  function _set_updates(data) {
    let _ids_to_query = [];
    _.forEach(data, function(item){
      // make sure that future item belongs to pending set of future requests and also not part of already being queried
      if (pending_request_ids().indexOf(item.id) != -1 && query_set.indexOf(item.id) == -1){
        // ignore the items whose status is 'processing' but processing_status_track_map entry is false
        if (!(item.status === c.futureStatus.processing && processing_status_track_map[item.id] === false)){
          _ids_to_query.push(item.id);
        }
      }
    })
    _.forEach(_ids_to_query, function (id) {
      query_set.push(id);
      let futureDetails = futureRequest.get({'request_id': id});
      futureDetails.$promise.then(fut_success_callback, fut_failure_callback);

      function fut_success_callback(data){
        const future = data.future;
        if ([c.futureStatus.success, c.futureStatus.error].indexOf(future.status) > -1) {
          _fire_listeners(future);
          untrack_future(future.id);
        }
        pending_requests_check();
      }
      function fut_failure_callback(e){
        remove_from_query_set(id);
      }
    })
  }

  function _fire_listeners(item) {
      _.forEach(listeners_by_future_id[item.id], function (listener) {
        if (typeof listener === "function") {
          var self = this;
          $timeout(function () {
            listener.apply(self, [item]);
          }, 0);
        }
      });
  }

  const pending_requests_count = () => Object.keys(listeners_by_future_id).length;
  const pending_request_ids = () => Object.keys(listeners_by_future_id).map( item => parseInt(item));
  const pending_requests_check = function() {
    if (pending_requests_count() == 0) {
      zero_pending_requests_event.fire_event();
    }
  }

  const untrack_future = function(future_id) {
      delete listeners_by_future_id[future_id];
      delete processing_status_track_map[future_id];
    remove_from_query_set(future_id);
  }

  const remove_from_query_set = (id) => { query_set = query_set.filter(i => i !== id)};

  return future_service;
}
