<template>
  <div class="navbar-profile">
    <div class="p-x-4 p-y-5">
      <p class="mm-text--body-bold m-a-0">{{ computedWorkspaceName }}</p>
      <p class="mm-text--body-regular m-a-0">{{ $store.state.user.email }}</p>
    </div>
    <mm-divider />

    <div v-if="!isSettingsPage && $store.state.workspaces.length > 1" class="m-b-3">
      <p class="mm-text--body-bold m-a-0 p-a-4">{{ $t('navbar.profile.workspaces') }}</p>
      <ul class="workspaces-list basic-list overflow-kit">
        <li
          v-for="workspace in $store.state.workspaces"
          :key="`workspace_${workspace.id}`"
          :disabled="workspace.id == $store.state.workspaceId"
          @click="setCurrentWorkspace(workspace.id)"
        >
          {{ workspace.name }}
        </li>
      </ul>
    </div>
    <mm-divider v-if="$store.state.workspaces.length > 1" />

    <div>
      <ul class="basic-list">
        <li v-if="!isSettingsPage" class="m-b-2" @click="openSettings">
          {{ $t('navbar.profile.settings') }}
        </li>
        <li class="d-flex" @click="logout">
          <mm-icon color="n600" name="logout" class="m-r-3" />
          {{ $t('navbar.profile.logout') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//Constants
import { USER_EVENTS, getOldAppUrl } from '@/constants'

// API
import API from '@/modules/auth/auth.api'
import keycloak from '@/plugins/keycloak'

export default {
  name: 'navbar-profile',
  props: {
    isSettingsPage: Boolean
  },
  created() {
    this.USER_EVENTS = USER_EVENTS
  },
  computed: {
    computedWorkspaceName() {
      return this.$store.state.workspaces.find((workspace) => workspace.id == this.$store.state.workspaceId)?.name
    }
  },
  methods: {
    setCurrentWorkspace(workspaceId) {
      // this.$router.push({ name: 'Landing', params: { workspaceId: workspaceId } }) // ideally we should redirect to an workspace id e.g. /#/:workspaceId/landing
      //TODO remove when angular code is gone
      this.$store.commit('setWorkspaceId', workspaceId)
      this.$userEvents.save(USER_EVENTS.NAVBAR.PROFILE.CHANGE_WORKSPACE, { id: workspaceId })
      const workspace = this.$store.state.workspaces.find((workspace) => workspace.id === workspaceId)
      const project = workspace.projects.filter((project) => project.subscribed)[0]
      // this.$router.push({ name: 'Landing', params: { workspaceId: workspaceId } }) // ideally we should redirect to an workspace id e.g. /#/:workspaceId/landing
      //TODO remove when angular code is gone
      if (project) {
        window.location.href = `${getOldAppUrl()}/#/workspaces/${workspaceId}/projects/${project.id}`
      } else {
        window.location.href = `${getOldAppUrl()}/#/workspaces/${workspaceId}`
      }
      window.location.reload()
    },
    logout() {
      this.$userEvents.save(USER_EVENTS.NAVBAR.PROFILE.LOGOUT)
      this.$store.commit('reset')
      API.blacklistToken({ token: keycloak.token })
      this.$store.dispatch('auth/logout')
    },
    openSettings() {
      this.$userEvents.save(USER_EVENTS.NAVBAR.PROFILE.SETTINGS)

      //TODO remove when angular code is gone
      window.open(`${getOldAppUrl()}/n/#/settings/profile`, 'Settings')
    }
  }
}
</script>

<style lang="scss" scoped>
$navbar-profile-width: 200px;

.navbar-profile {
  width: $navbar-profile-width;

  .workspaces-list {
    max-height: 250px;
  }
}
</style>
