import * as angular from 'angular';
import { dataviewConfig } from './dataview.config';
import { dataviewOnConfig } from './dataview.onConfig';
import { taskModule } from './tasks/index';
import { actionModule } from './actions/index';
import { DataviewLayoutService } from './dataviewLayout.service';
import {
  dataviewGridDirective,
  wsColMenuController,
  wsCellMenuController,
  taskView,
  actionView,
  includeDataviewPage,
  includeElementPanel,
  includeColumnExplorer,
  includeAddRuleMenu,
  includeDataviewGridColMenu,
  setColmenuContentHeight,
  includeDataviewGridCellMenu,
  includeJoinSourceGrids,
  includeDataviewSummaryMenu,
  columnSummary,
  columnSummaryUniqueValues,
  printElementDiv,
  wsColRenamePanel,
  determineMaxPillsForColSummary,
  dataviewLayout,
  handleElementPanelDrag,
  columnMenuHeightAdjuster,
  scrollListToEndWhenNewAdded,
  openFirstDataviewByDsId,
  multiLevelDropdownMenuDirective,
  columnBrowserWidthAdjuster,
  editButtonClick,
  openDataviewById,
  watchOptimiser
} from './dataview.directives';
import {
  preventClickEventPropagationExplore, ExplorePanelFactory
} from './explore.panel.and.menu';
import { ElementPanelFactory } from './elementPanel.factory';
import { ColumnRenamePanelFactory } from './columnRenamePanel.factory';
import { columnMenuCtrl } from './columnMenu.controller';
import { ColumnMenuService } from './columnMenu.service';
import { ActionPanelFactory } from './actionPanel.factory';
import { EmailExportManagerFactoryFn, SftpExportManagerFactoryFn, exportDialogController } from './exportDialog.controller';
import { DataviewGlobalFilterService } from './globalFilter.service';
import { historicDataviewData } from './history';
import { HistoryDataViewPanelFactory } from './historyDataViewPanel.service';
import { metricService } from './metric.service';
import { wkspPreviewService, wkspTaskStatusFactory } from './preview.mode.service';
import { TaskHelperServiceFactory } from './task.helper.service';
import { TaskPanelFactory } from './taskPanel.factory';
import { TemplateService, TemplateDependencyChooserService, TemplateDependencyChooserResolverCtrl } from './template';
import { NumericVal, DataviewCtrl } from './dataview.controller';
import { MainGridUnitFactory } from './wsGridUnit.factory';
import { DataviewLayoutCtrl } from './dataviewLayout.controller';
import {elementModule} from '../element/index';
import {elementCommonModule} from '../element/common/index';
import {config} from "../common/app.config";
import {alertsDialogController} from "./alerts.controller";
import {viewUtilsModule} from "./viewUtils/index";
export let dataviewModule = angular.module('app.dataview',
  ['ngSanitize', 'app.models', viewUtilsModule.name,  taskModule.name, actionModule.name, 'app.common', 'ngAnimate', elementModule.name, elementCommonModule.name]);

dataviewModule.constant("dataviewConfig", dataviewConfig).config(dataviewOnConfig);
dataviewModule.service('DataviewLayoutService', DataviewLayoutService);
dataviewModule.service('ActionPanelFactory', ActionPanelFactory);
dataviewModule.controller(config.controllers.columnMenu, columnMenuCtrl);
dataviewModule.service('ColumnRenamePanelFactory', ColumnRenamePanelFactory);
dataviewModule.service('ElementPanelFactory', ElementPanelFactory);
dataviewModule.service('ColumnMenuService', ColumnMenuService);
dataviewModule.factory('ExplorePanelFactory', ExplorePanelFactory);
dataviewModule.directive('preventClickEventPropagationExplore', preventClickEventPropagationExplore);
dataviewModule.controller(config.controllers.exportDialog, exportDialogController);
dataviewModule.factory('EmailExportManagerFactory', EmailExportManagerFactoryFn);
dataviewModule.factory('SftpExportManagerFactory', SftpExportManagerFactoryFn);
dataviewModule.service('DataviewGlobalFilterService', DataviewGlobalFilterService);
dataviewModule.factory('historicDataviewData', historicDataviewData);
dataviewModule.service('HistoryDataViewPanelFactory', HistoryDataViewPanelFactory);
dataviewModule.service('metricService', metricService);
dataviewModule.service('wkspPreviewService', wkspPreviewService);
dataviewModule.factory('wkspTaskStatusFactory', wkspTaskStatusFactory);
dataviewModule.service('TaskHelperServiceFactory', TaskHelperServiceFactory);
dataviewModule.service('TaskPanelFactory', TaskPanelFactory);
dataviewModule.service('TemplateService', TemplateService);
dataviewModule.controller(config.controllers.dataview, DataviewCtrl);
dataviewModule.filter('numeric', NumericVal);
dataviewModule.controller(config.controllers.dataviewLayout, DataviewLayoutCtrl);
dataviewModule.service('MainGridUnitFactory', MainGridUnitFactory);
dataviewModule.service('TemplateDependencyChooserService', TemplateDependencyChooserService);
dataviewModule.controller('TemplateDependencyChooserResolverCtrl', TemplateDependencyChooserResolverCtrl);
dataviewModule.controller('alertsDialogController', alertsDialogController);


// directives
dataviewModule.directive('dataviewGrid', dataviewGridDirective);
dataviewModule.directive('wsColMenuController', wsColMenuController);
dataviewModule.directive('wsCellMenuController', wsCellMenuController);
dataviewModule.directive('watchOptimiser', watchOptimiser);
dataviewModule.directive('taskView', taskView);
dataviewModule.directive('actionView', actionView);
dataviewModule.directive('includeDataviewPage', includeDataviewPage);

dataviewModule.directive('includeElementPanel', includeElementPanel);
dataviewModule.directive('includeColumnExplorer', includeColumnExplorer);
dataviewModule.directive('includeAddRuleMenu', includeAddRuleMenu);
dataviewModule.directive('includeDataviewGridColMenu', includeDataviewGridColMenu);
dataviewModule.directive('setColmenuContentHeight', setColmenuContentHeight);
dataviewModule.directive('includeDataviewGridCellMenu', includeDataviewGridCellMenu);
dataviewModule.directive('includeJoinSourceGrids', includeJoinSourceGrids);
dataviewModule.directive('includeDataviewSummaryMenu', includeDataviewSummaryMenu);
dataviewModule.directive('columnSummary', columnSummary);
dataviewModule.directive('columnSummaryUniqueValues', columnSummaryUniqueValues);
dataviewModule.directive('printElementDiv', printElementDiv);
dataviewModule.directive('wsColRenamePanel', wsColRenamePanel);
dataviewModule.directive('determineMaxPillsForColSummary', determineMaxPillsForColSummary);

dataviewModule.directive('dataviewLayout', dataviewLayout);
dataviewModule.directive("handleElementPanelDrag", handleElementPanelDrag);
dataviewModule.directive("columnMenuHeightAdjuster", columnMenuHeightAdjuster);
dataviewModule.directive("scrollListToEndWhenNewAdded", scrollListToEndWhenNewAdded);




dataviewModule.run(onRun);

function onRun($templateCache, dataviewConfig, $http) {
  return;
  // function addTemplateToCache(item) {
  //   if (item.template && !$templateCache.get(item.template)) {
  //     putToTemplateCache(item.template);
  //   }
  // }
  //
  // angular.forEach(dataviewConfig.taskConfig, addTemplateToCache);
  // angular.forEach(dataviewConfig.actionConfig, addTemplateToCache);
  //
  // function putToTemplateCache(url) {
  //   $http.get(url).success(function (data) {
  //     $templateCache.put(url, data);
  //   });
  // }
}
onRun.$inject = ['$templateCache', 'dataviewConfig', '$http'];
dataviewModule.directive('openFirstDataviewByDsId', openFirstDataviewByDsId);
dataviewModule.directive('openDataviewById', openDataviewById);
dataviewModule.directive('columnBrowserWidthAdjuster', columnBrowserWidthAdjuster);
dataviewModule.directive('editButtonClick', editButtonClick);
// dataviewModule.directive('copyUrl', copyUrl);
dataviewModule.directive('multiLevelDropdownMenuDirective', multiLevelDropdownMenuDirective);
