import * as angular from 'angular';

/**
 * @ngInject
 */
baseElementServiceFactory.$inject = ['Element', 'c', 'utils', 'eventCallbackManagerFactory'];
export function baseElementServiceFactory(Element, c, utils, eventCallbackManagerFactory){
  return ElementServiceBase;

  function ElementServiceBase() {
    var self = this;
    self.processList = _processList;

    self.mainList = {}; //everything
    self.elements = {}; // all charts, tables, metric
    self.metrics = {};
    self.charts = {};
    self.tables = {};
    self.filters = {};
    self.metricStatuses = {}
    self.metricsInError = false;
    var elementsUpdated = new eventCallbackManagerFactory('elementsListUpdated_' + utils.string.random());
    self.onElementsUpdated = elementsUpdated.add_callback;


    function _updateSubLists(item){
      if (item.category == 'PIVOT') {
        if (item.display_properties.type == 'filter') {
          if (!item.display_properties.isDrilldown) {
            self.filters[item.id] = item;
          }
          item.type = c.elementTypes.FILTER;
        }
        else if (item.display_properties.type == 'table') {
          if (!item.display_properties.isDrilldown) {
            self.tables[item.id] = item;
            self.elements[item.id] = item;
          }
          item.type = c.elementTypes.TABLE;
        }
        else if (item.display_properties.type == 'chart') {
          if (!item.display_properties.isDrilldown) {
            self.charts[item.id] = item;
            self.elements[item.id] = item;
          }
          item.type = c.elementTypes.CHART;
        }
      }
      else if (item.category == 'funnel') {
        self.charts[item.id] = item;
        self.elements[item.id] = item;
        item.type = c.elementTypes.FUNNEL;
      }
      else if (item.category == 'METRIC') {
        if (!item.display_properties.isDrilldown) {
          self.metrics[item.id] = item;
          self.metricStatuses[item.id] = item.metric_status;
          self.elements[item.id] = item;
        }
        
        item.type = c.elementTypes.METRIC;
      }
    }

    function _processList(derivativeList) {
      var _deleted_ids = Object.keys(self.mainList);
      angular.forEach(derivativeList, function (derivative, i) {
        if(!derivative.display_properties){
          return true;
        }

        if (!self.mainList.hasOwnProperty(derivative.id)) {
          self.mainList[derivative.id] = new Element();
        }
        self.mainList[derivative.id].update(derivative);
        _updateSubLists(self.mainList[derivative.id]);
        if (Object.values(self.metricStatuses).indexOf('ERROR')!=-1){
          self.metricsInError = true;
        }else{
          self.metricsInError = false;
        }
        // remove existing item from list to be deleted
        var _idx = _deleted_ids.indexOf(derivative.id + "");
        if (_idx > -1) {
          _deleted_ids.splice(_idx, 1);
        }

      });

      angular.forEach(_deleted_ids, function (id) {
        delete self.mainList[id];
        delete self.elements[id];
        delete self.metrics[id];
        delete self.charts[id];
        delete self.tables[id];
        delete self.filters[id];
      });

      self.mainListLength = Object.keys(self.mainList).length;
      self.elementsLength = Object.keys(self.elements).length;
      self.metricsLength = Object.keys(self.metrics).length;
      self.chartsLength = Object.keys(self.charts).length;
      self.tablesLength = Object.keys(self.tables).length;
      self.filtersLength = Object.keys(self.filters).length;

      elementsUpdated.fire_event();
    }
  }

}

/**
 * @ngInject
 */
oldElementServiceFactory.$inject = ['derivatives', '$q', '$interval', 'baseElementServiceFactory'];
export function oldElementServiceFactory(derivatives, $q, $interval, baseElementServiceFactory) {
  var registry = {};
  var factory = {
    getByDataviewId: getByDataviewId
  };


  function getByDataviewId(dataviewId) {
    if (!registry.hasOwnProperty(dataviewId)) {
      registry[dataviewId] = new WkspElementService(dataviewId);
    }
    return registry[dataviewId];
  }

  function WkspElementService(dataviewId) {
    var self = this,
      _pollIntervalPromise;
    var baseElementService = new baseElementServiceFactory();
    self.mainList = baseElementService.mainList; //everything
    self.elements = baseElementService.elements; // all charts, tables, metric
    self.metrics = baseElementService.metrics;
    self.charts = baseElementService.charts;
    self.tables = baseElementService.tables;
    self.filters = baseElementService.filters;
    self.onElementsUpdated = baseElementService.onElementsUpdated;

    self.dataviewId = dataviewId;
    self.updateList = _updateList;
    self.pollForUpdatesUntilReady = pollForUpdatesUntilReady;

    function _updateList() {
      var deferred = $q.defer();
      derivatives.list(self.dataviewId).then(function(l) {
        baseElementService.processList(l);
        self.mainListLength = Object.keys(self.mainList).length;
        self.elementsLength = Object.keys(self.elements).length;
        self.metricsLength = Object.keys(self.metrics).length;
        self.chartsLength = Object.keys(self.charts).length;
        self.tablesLength = Object.keys(self.tables).length;
        self.filtersLength = Object.keys(self.filters).length;
        self.metricsInError = baseElementService.metricsInError;
        deferred.resolve();
      }, deferred.reject );
      return deferred.promise;
    }

    function pollForUpdatesUntilReady() {
      if (_pollIntervalPromise) {
        $interval.cancel(_pollIntervalPromise);
        _pollIntervalPromise = undefined;
      }
      var deferred = $q.defer();
      _updateList().then(updateSuccess);
      _pollIntervalPromise = $interval(function () {
        _updateList().then(updateSuccess);
      }, 2000);
      return deferred.promise;

      function updateSuccess() {
        var atleastOneProcessing = false;
        angular.forEach(baseElementService.mainList, function (element) {
          if (element.status == "processing") {
            atleastOneProcessing = true;
          }
        });
        if (!atleastOneProcessing) {
          $interval.cancel(_pollIntervalPromise);
          deferred.resolve();
        }
      }
    }
  }

  return factory;
}

