/**
 * @ngInject
 */
windowEvents.$inject = ['eventCallbackManagerFactory'];
export function windowEvents(eventCallbackManagerFactory) {
  var resizeEvent = new eventCallbackManagerFactory('main_on_window_resize');
  var stateChangeEvent = new eventCallbackManagerFactory('main_on_online');
  var service = {
    isOnline: true,
    onResize: resizeEvent.add_callback,  // register callback listener- name, function(event)
    resize: resizeEvent.fire_event,
    onNetworkStateChange: stateChangeEvent.add_callback,  // register callback listener- name, function(event)
    setNetworkStateChange: setNetworkStateChange
  };

  return service;

  function setNetworkStateChange(isOnline){
    service.isOnline = isOnline;
    stateChangeEvent.fire_event(isOnline);
  }
}
