import * as angular from 'angular';
import { filterUI, FilterEditHelperFactory } from './filter.service';
import {filterManagerFactory, valColumn, valColumnSelected, valOperator} from './filter.manager';
import { FilterHintsFactory } from './filter.hints';
import {taskDescriber} from '../../dataview/tasks/taskDescriber.service';
import {
  filterSummaryAndModalMenu,
  conditionDescription,
  filterCondition,
  conditionLine,
  initialiseSelectizeForFilter,
  scrollToBottom,
  onDetectOutsideClick,
  noFloat
} from './filter.directive';
import {
  dateInputForCondition, describeDateInputValue, DateFilterInputController
} from './date.input';
import {coreModule} from "../../core/index";
import 'ui-select';
// import 'selectize';


/**
 * Module containing math editor
 */
export let filterModule = angular.module('app.common.filter', ['ui.bootstrap', 'app.common.utils',
  coreModule.name, 'ui.select', 'ngSanitize']);
filterModule.service('filterUI', filterUI);
filterModule.service('filterManagerFactory', filterManagerFactory);
filterModule.service('describeDateInputValue', describeDateInputValue);
filterModule.service('taskDescriber', taskDescriber);
filterModule.factory('FilterHintsFactory', FilterHintsFactory);
filterModule.controller('DateFilterInputController', DateFilterInputController);
filterModule.directive('conditionLine', conditionLine);
filterModule.directive('filterCondition', filterCondition);
filterModule.directive('conditionDescription', conditionDescription);
filterModule.directive('filterSummaryAndModalMenu', filterSummaryAndModalMenu);
filterModule.directive('dateInputForCondition', dateInputForCondition);
filterModule.directive('initialiseSelectizeForFilter', initialiseSelectizeForFilter);
filterModule.directive('scrollToBottom', scrollToBottom);
filterModule.service('FilterEditHelperFactory', FilterEditHelperFactory);
filterModule.directive('onDetectOutsideClick', onDetectOutsideClick);
filterModule.directive('noFloat', noFloat);
filterModule.directive('valOperator', valOperator);
filterModule.directive('valColumn', valColumn);
filterModule.directive('valColumnSelected', valColumnSelected);
