'use strict';
import { module } from 'angular';
import { LandingCtrl } from './landing.controller';
import { datapullModule } from './datapull';
import { DSBatchService } from './Datasource.batch.service';
import { CombineDsCtrl } from './combine.ds.controller';
import {
  datasourcePreviewPanel, lineItem, listenForFileDragsIntoLanding, selectResource, showHideAsideSection,
  lineItemDraggable, lineItemDroppable
} from './landing.directives';
import {dataviewPreviewGrid, datasetInplaceRename} from './preview.directives';
import { dsPreviewController } from  './preview.controller';
import {config} from "../common/app.config";
import { LabelCreateController } from './label.controllers';
import { landingList } from './landing.list.service';
import { fileUploadService } from './file.upload.service';
import { addDataController } from './addData.controller';
import { includeThirdParty, includeWebhookConfigScreen, includeUrl } from './addData.directives';
import { landingSelection } from './landingSelection.service';
import {renameController} from './rename.controller';
import {dsModificationAlertCtrl} from './dsModificationAlertController';

export let landingModule = module('app.landing', [
  'ui.bootstrap', datapullModule.name, 'angularMoment', 'angular-humanize',
  'app.common', 'app.rawData'
]);

landingModule.service('landingList', landingList);
landingModule.controller(config.controllers.landing, LandingCtrl);
landingModule.service('DSBatchService', DSBatchService);
landingModule.controller(config.controllers.combineDs, CombineDsCtrl);
landingModule.controller(config.controllers.dsModificationAlertCtrl, dsModificationAlertCtrl);

landingModule.directive('datasourcePreviewPanel', datasourcePreviewPanel);
landingModule.directive('lineItem', lineItem);
landingModule.directive('selectResource', selectResource);
landingModule.directive('showHideAsideSection', showHideAsideSection);
landingModule.directive('listenForFileDragsIntoLanding', listenForFileDragsIntoLanding);
landingModule.controller('dsPreviewController', dsPreviewController);
landingModule.directive('dataviewPreviewGrid', dataviewPreviewGrid);
landingModule.controller(config.controllers.createLabel, LabelCreateController);
landingModule.controller(config.controllers.renameController, renameController);

landingModule.service('fileUploadService', fileUploadService);
landingModule.service('landingSelection', landingSelection);
landingModule.controller(config.controllers.addData, addDataController);
landingModule.directive('includeThirdPartyPartial', includeThirdParty);
landingModule.directive('includeWebhookConfigScreen', includeWebhookConfigScreen);
landingModule.directive('includeUrlPartial', includeUrl);
landingModule.directive('lineItemDraggable', lineItemDraggable);
landingModule.directive('lineItemDroppable', lineItemDroppable);
landingModule.directive('datasetInplaceRename', datasetInplaceRename);
export default landingModule;
