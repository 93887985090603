<template>
  <action-bar ref="actionBar" />
</template>

<script>
import ActionBar from './action-bar.vue'

// Used to store view and its corresponding variables
// check - $store.state.dataEditor.viewId watch
window.ACTION_BAR_VIEW_MAP = {}

export default {
  name: 'action-bar-frontend-wrapper',
  components: { ActionBar },
  props: {
    // props/variables from the legacy angular code.
    accountUsers: Array,
    isPipelineOpen: Boolean,
    isPipelineError: Boolean,
    isPipelineDraft: Boolean,
    taskCount: Number,
    actionCount: Number,
    isPublishedReport: Boolean,
    isPublishedToDb: Boolean,
    isLiveLinkGenerated: Boolean,
    metaData: Array,
    displayProperties: Object,
    // handlers to invoke a functionality in the legacy angular code
    legacyHandlers: Object
  },
  computed: {
    computedUsers() {
      return this.accountUsers.map(({ email }) => ({ email }))
    },
    computedIsPipelineEmpty() {
      return this.taskCount == 0 && this.actionCount == 0 && !this.isPublishedReport
    }
  },
  watch: {
    '$store.state.dataEditor.viewId': {
      handler(viewId) {
        const view = window.ACTION_BAR_VIEW_MAP[viewId]
        if (view) {
          Object.values(view).forEach(({ commit, value }) => this.$store.commit(commit, value))
        } else {
          window.ACTION_BAR_VIEW_MAP[viewId] = {}
        }
      },
      immediate: true
    }
  },
  created() {
    this.$root.legacyHandlers = window.legacyHandlers = this.legacyHandlers
    this.handleLegacyPropChange()
  },
  mounted() {
    this.$emit('mounted', this.$refs.actionBar.$refs)
  },
  methods: {
    handleLegacyPropChange() {
      // watch this[key] and commit its value to store
      Object.entries({
        isPipelineOpen: 'pipeline/setIsOpen',
        isPipelineError: 'pipeline/setIsError',
        isPipelineDraft: 'pipeline/setIsDraft',
        taskCount: 'pipeline/setTaskCount',
        isPublishedReport: 'pipeline/setIsReportPublished',
        isPublishedToDb: 'pipeline/setIsMammothManagedExport',
        isLiveLinkGenerated: 'pipeline/setIsLiveLinkGenerated',
        computedUsers: 'workspace/setUsers',
        computedIsPipelineEmpty: 'pipeline/setIsEmpty'
      }).forEach(([key, commit]) => {
        this.$watch(
          key,
          (value) => {
            if (commit.startsWith('pipeline') || commit.startsWith('dataEditor')) {
              const view = window.ACTION_BAR_VIEW_MAP[this.$store.state.dataEditor.viewId]
              view[key] = { commit, value }
            }
            this.$store.commit(commit, value)
          },
          { deep: true, immediate: true }
        )
      })
    }
  }
}
</script>
