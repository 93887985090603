/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
/**
 * @ngInject
 */
 numericFormatterControllerNew.$inject = ['c'];
export function numericFormatterControllerNew(c){
  var nf = this;
  nf.currencyOptions = c.currencyOptions;
  nf.decimalPlacesOptions = c.decimalPlacesOptions;

  nf.enableFormats = true;
  nf.decimalInfo = null;
  nf.numtype = 'float';
  nf.currencySymbol = null;
  nf.isPercentage = false;
  nf.isCommaSeparated = false;
  nf.init = init;
  nf.handleChange = handleChange;
  nf.currencyToggle=currencyToggle;

  this.$onInit = init;

  function init(){
    nf.enableFormats = true;
    nf.decimalInfo = null;
    nf.numtype = 'float';
    nf.currencySymbol = null;
    nf.isPercentage = false;
    nf.isCommaSeparated = false;

    if(!nf.format){
      nf.format = {}
    }
    if(nf.format.enabled === false){
      nf.enableFormats = false;
      return;
    }

    nf.enableFormats = true;
    nf.isCommaSeparated = nf.format.comma_separated;

    if(nf.format.is_percentage){
      nf.isPercentage = true;
      nf.decimalInfo = nf.format.decimal_spec;
      nf.numtype = 'float';
      return;
    }

    if(nf.currencyOptions.indexOf(nf.format.currency_symbol) != -1){
      nf.currencySymbol = nf.format.currency_symbol;
      if(nf.format.decimal_spec === undefined){
        nf.format.decimal_spec = 2;
      }
      nf.decimalInfo = nf.format.decimal_spec;
      nf.numtype = 'float';
      return;
    }

    nf.numtype = nf.format.numtype;
    nf.decimalInfo = nf.format.decimal_spec;

    if(nf.decimalInfo == undefined){
      nf.decimalInfo = null;
    }
  }

  function getFormat(){
    let format: any = {};
    if(!nf.enableFormats){
      format.enabled = false;
    }
    else{
      format.is_percentage = nf.isPercentage;
      format.decimal_spec = nf.decimalInfo;
      format.comma_separated = nf.isCommaSeparated;
      format.currency_symbol = nf.currencySymbol;
      format.numtype = nf.numtype;
      format.enabled = true;
    }
    return format;

  }

  function submit(){
    nf.format = getFormat();
    nf.onSubmitCb({format: nf.format});
  }

  function handleChange(changed){
    nf.enableFormats = true;
    if(changed == 'percentage'){
      adjustAfterPercentageChange();
    }
    else if(changed == 'currency'){
      adjustAfterCurrencyChange();
    }
    else if(changed == 'decimal'){
      adjustAfterDecimalChange();
    }
    submit();
  }


  function adjustAfterCurrencyChange(){
    if(nf.currencySymbol == ''){
      nf.currencySymbol = null;
    }
    if(nf.currencySymbol){
      nf.isPercentage = false;
      setToFloat();
    }
  }


  function setToFloat(){
    if(nf.decimalInfo == null || nf.decimalInfo == undefined){
      nf.decimalInfo = 2;
      nf.numtype = 'float';
    }
  }

  function adjustAfterPercentageChange(){
    if(nf.isPercentage){
      nf.currencySymbol = null;
      setToFloat();
    }
  }

  function adjustAfterDecimalChange(){
    if(nf.decimalInfo === null){
      nf.isPercentage = false;
      nf.currencySymbol = null;
      nf.enableFormats = false;
    }

    else if(nf.decimalInfo == 0){
      nf.numtype = 'int';
    }
    else{
      nf.numtype = 'float';
    }
  }
  function currencyToggle(){
    if (nf.currencySymbol !=null ){
      nf.currencySymbol=null;
      nf.handleChange('currency');
    }
  }


}

/**
 * @ngInject
 */
function numericFormatterController(c) {
  var nf = this;
  nf.decimalPlacesOptions = [];
  nf.editedFormat = null;
  nf.currencyOptions = c.currencyOptions;
  nf.onToggle = onToggle;
  nf.currencySelected = currencySelected;
  nf.percentSelected = percentSelected;
  nf.onDecimalOptionChange = onDecimalOptionChange;
  nf.submit = submit;

  if (nf.format) {
    onToggle();
  }
  changeDecimalPlaceOptions();

  function onToggle() {
    nf.editedFormat = _.cloneDeep(nf.format);
    changeDecimalPlaceOptions();
  }



  function changeDecimalPlaceOptions(){
    nf.decimalPlacesOptions = $.grep(c.decimalPlacesOptions, function(o: any){
      if(o.value === null && nf.editedFormat && nf.editedFormat.is_percentage){
        return false;
      }
      return true;
    })
  }

  function currencySelected() {
    if (nf.editedFormat.currency_symbol) {
      nf.editedFormat.is_percentage = false;
      setToFloat()
    }
  }

  function percentSelected() {
    if (nf.editedFormat.is_percentage) {
      nf.editedFormat.currency_symbol = null;
      setToFloat();
    }
    changeDecimalPlaceOptions();
  }

  function setToFloat(){
    if(nf.editedFormat.numtype == 'int' || !nf.editedFormat.decimal_spec){
      nf.editedFormat.decimal_spec = 2;
      nf.editedFormat.numtype = 'float';
    }
  }

  function onDecimalOptionChange(){

  }

  function submit() {
    if (typeof nf.format == "object") {
      angular.extend(nf.format, nf.editedFormat);
    } else {
      nf.format = nf.editedFormat;
    }
    if (nf.onSubmit && typeof nf.onSubmit == "function") {
      nf.onSubmit(nf.format);
    }
    nf.isOpen = false;
    nf.onSubmitCb();
  }
}
