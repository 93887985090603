unstructuredDataService.$inject = ['$resource', 'config', '$q', 'FutureService'];
export function unstructuredDataService($resource, config, $q, FutureService) {
  var unstructured_data_api = $resource(config.api.dsUnstructuredData);

  var service =  {
    get_unstructured_rows: get_unstructured_rows,
    validate_row: validate_row,
    discard_row: discard_row,
    discard_all: discard_all,
    merge_row: merge_row
  };

  return service;

  function get_unstructured_rows(ds_id) {
    var deferred = $q.defer();
    unstructured_data_api.get({dataset_id: ds_id}).$promise.then(function(data){
      deferred.resolve(data);
    }, deferred.reject);
    return deferred.promise;
  }

  function validate_row(ds_id, line_num, batch_id, line) {
    var deferred = $q.defer();
    unstructured_data_api.patch({dataset_id: ds_id}, {
      "patch": [{
        "op": "replace",
        "path": "unstructured_rows",
        "value": {line_num: line_num, batch_id: batch_id, line: line}
      }]
    }).$promise.then(deferred.resolve, deferred.reject);
    return deferred.promise;
  }

  function discard_all(ds_id) {
    var deferred = $q.defer();
    unstructured_data_api.delete({dataset_id: ds_id}).$promise.then(deferred.resolve, deferred.reject);
    return deferred.promise;
  }

  function discard_row(ds_id, line_num, batch_id) {
    var deferred = $q.defer();
    unstructured_data_api.patch({dataset_id: ds_id}, {
      "patch": [{
        "op": "remove",
        "path": "unstructured_rows",
        "value": {line_num: line_num, batch_id: batch_id}
      }]
    }).$promise.then(deferred.resolve, deferred.reject);
    return deferred.promise;
  }

  function merge_row(ds_id, batch_id, data) {
    var deferred = $q.defer();

    unstructured_data_api.patch({dataset_id: ds_id}, {
      "patch": [{
        "op": "add",
        "path": "unstructured_rows",
        "value": {batch_id: batch_id, data: data}
      }]
    }).$promise.then(merge_success, deferred.reject);

    function merge_success(data) {
      FutureService.track(data.future_id, merge_request_tracker);
      function merge_request_tracker(future) {
        if (future.status == "processing") { return; }
        if (future.status == "success") {
          deferred.resolve();
        }
        else {
          deferred.reject();
        }
      }
    }
    return deferred.promise;
  }

}
