import * as angular from 'angular';
import * as d3TimeFormat from 'd3-time-format';
import _ = require('lodash');


/**
 * @ngInject
 */
dateInputFormatController.$inject= ['c', 'eventCallbackManagerFactory', '$resource', 'config', 'utils', '$timeout'];
export function dateInputFormatController(c, eventCallbackManagerFactory, $resource, config, utils, $timeout) {
  var df = this;
  df.examples = c.dateInputFormatOptions;
  df.isOpen = false;
  let showEvent =  new eventCallbackManagerFactory('dateInputFormatPanelShowEvent');
  let SuggestionResource = new $resource(config.api.suggestions);
  df.inited = false;
  let findAndSetValidFormatDedounced = utils.debounce(findAndSetValidFormat, 500, false);
  df.submit = submit;
  df.close = close;
  df.selectFormatOpt = selectFormatOpt;
  df.applyOnSamples = applyOnSamples;
  df.debouncedApplyOnSamples = utils.debounce(applyOnSamples, 500, false);
  df.onToggle = onToggle;
  df.onToggleCb = showEvent.add_callback;

  this.$onInit = function () {
    $timeout(init, 100);
  }

  function init(){
    if (!df.inputFormat){
      df.format = {
        postgres_date_format: undefined
      }
    }
    else{
      df.format = _.cloneDeep(df.inputFormat);
      df.inited = true;
    }
  }

  function onToggle(){
    if(df.isOpen){
      showEvent.fire_event();
    }
    showEvent.fire_event();
  }

  function selectFormatOpt(postgres_format){
    df.format.postgres_date_format = postgres_format;
  }

  function close(){
    df.isOpen = false;
  }

  function submit(preventClose) {
    if (df.onSubmit && (typeof df.onSubmit === "function")) {
      df.onSubmit(_.cloneDeep(df.format));
    }
    df.inputFormat = df.format;
    if(!preventClose){
      df.isOpen = false;
    }
    df.inited = true;
  }

  function findAndSetValidFormat(samples){
    if(!df.autoFind){
      return;
    }
    SuggestionResource.save({}, {samples: samples, type: "date-format"}).$promise.then(function(data){
      if(data.suggestions.length){
        let suggestions = data.suggestions;
        suggestions.sort();

        df.format.postgres_date_format = data.suggestions[0];
        applyOnSamples(true);
        df.inited = true;
      }
    }, console.error);
  }

  function applyOnSamples(forceValidityCheck) {
    if(df.sampleValuesLoading || !df.sampleValues || !df.sampleValues.length){
      return;
    }
    let samples = [];
    let mapping = {};
    angular.forEach(df.sampleValues, function(sample) {
      if(sample.value && samples.length < 5){
        samples.push(sample.value);
        mapping[sample.value] = sample;
      }
    });

    if(df.inited && (df.format.postgres_date_format||forceValidityCheck)){
      let payload = {samples: samples, type: "date-format", format: df.format.postgres_date_format};
      SuggestionResource.save({}, payload).$promise.then(function(data){
        let totalValid = 0;
        if(data.validity){
          samples.forEach(function(s){
            mapping[s].validFormat = data.validity[s];
            if(data.validity[s]){
              totalValid += 1;
            }
          });
          if(samples.length && totalValid){
            if (totalValid/samples.length > 0.5){
              submit(true);
            }
          }
        }
      }, console.error);
    }
    else{
      if(samples.length){
        findAndSetValidFormatDedounced(samples);
      }
    }
  }
}
