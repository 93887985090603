import * as angular from 'angular';
import * as _ from 'lodash-es';


/**
 * @ngInject
 * Datasource Factory
 */

DSBatchService.$inject = ['$resource', 'config', '$q', 'moment', '$filter', '$uibModal','DatasourceService', 'eventCallbackManagerFactory', '$timeout', 'analyticsService', 'c', 'toastNotification', 'FutureService'];

export function DSBatchService($resource, config, $q, moment, $filter, $uibModal, DatasourceService, eventCallbackManagerFactory, $timeout, analyticsService, c, toastNotification, FutureService) {
  var batchChangeEvent = new eventCallbackManagerFactory('BatchChangeEvent');
  var service = {
    getByDsId: getByDsId,
    getDsBatchIds: getDsBatchIds,
    addBatch: addBatch,
    dropByDsIdBatchId: dropByDsIdBatchId,
    dropBatchesByDsId: dropBatchesByDsId,
    multiModifyBatchState: multiModifyBatchState,
    onBatchAdded: batchChangeEvent.add_callback
  };

  function getDsBatchIds(ds_id) {
    // Get the batch_ids of the given datasource id
    var deferred = $q.defer();
    var dsBatchIdsResource = new $resource(config.api.datasourceBatches, {'ds_id': ds_id, batch_ids_only: true});
    dsBatchIdsResource.get().$promise.then(successCb, failureCb);
    return deferred.promise;

    function successCb(data) {
      deferred.resolve(data.batch_ids);
    }
    function failureCb(data) {
      deferred.reject();
    }
  }

  function getByDsId(ds_id) {
    var deferred = $q.defer();
    var dsBatchResource = new $resource(config.api.datasourceBatches, {'ds_id': ds_id});
    dsBatchResource.get().$promise.then(function (data) {
      get_data_tracker(data).then(function (response) {
        var batches = $filter('orderBy')(response, 'created_at');
        _.forEach(batches, function (batch, i) {
          batch.createdAt = moment(batch.created_at).format('MMM D, YYYY HH:mm');
          batch.created_at = moment.utc(batch.created_at).local().format('MMM D, YYYY HH:mm');
          batch.label = 'Batch ' + (i + 1);

          batch.dataUrl = config.apiUrl + '/datasets/' + ds_id + '/batches/' + batch.id;
        });
        deferred.resolve(batches);
      });
    }, deferred.reject);
    return deferred.promise;
  }

  function get_data_tracker(data) {
    var deferred = $q.defer();
    FutureService.track(data.future_id, data_tracker);

    function data_tracker(future) {
      let response = future.response;
      if (future.status == "processing") {
        return;
      }
      if (future.status == "success") {
        deferred.resolve(response);
      } else {
        deferred.reject(response);
      }
    }
    return deferred.promise;
  }

  function addBatch(ds_id, source_id, mapping, actionMaps, newDsParams, isValidationRequired, delete_source_ds=false, replace=false) {
    var deferred = $q.defer();
    var dsBatchResource = new $resource(config.api.datasourceBatches, {'ds_id': ds_id});

    var postData = {
      source: 'datasource',
      source_id: source_id,
      mapping: mapping,
      change_map: actionMaps,
      new_ds_params: newDsParams,
      is_validation_required: isValidationRequired,
      delete_source_ds: delete_source_ds,
      replace: replace
    };
    dsBatchResource.save(postData).$promise.then(add_batch_success, add_batch_failure);

    function add_batch_success(data) {
      if (!newDsParams ) {
        batchChangeEvent.fire_event(ds_id);
      }
      deferred.resolve(data);
    }

    function add_batch_failure(data) {
      var errorMessage = "Append failed";
      if (data.data && data.data.ERROR_MESSAGE) {
        errorMessage += ": " + data.data.ERROR_MESSAGE
      }
      toastNotification.error(errorMessage);
      deferred.reject(data);
    }

    return deferred.promise;
  }

    function dropByDsIdBatchId(ds_id, batch_id) {
      var datasource = DatasourceService.get_by_id(ds_id);

      var deferred = $q.defer();

      function delete_fn() {
        var dsBatchResource = new $resource(config.api.datasourceBatches, {'ds_id': ds_id, batch_id: batch_id});
        dsBatchResource.delete(function (data) {
          if (data.STATUS == 'PROCESSING') {
            toastNotification.success('Deleted the batch');
            analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.deletedBatch, {eventOrigin: "landingPage.previewPanel"});
            deferred.resolve();
          } else {
            deferred.reject();
          }
        }, deferred.reject);
      }

      var modal = $uibModal.open({
        templateUrl: config.templates.deleteBatchConfirmation,
        windowClass: 'modal-size-small delete-batch-modal',
        controller: function () {
          var mvm = this;
          mvm.autoSync = datasource.autosync;
          mvm.multiple = false;
        }
      });

      modal.result.then(delete_fn);

      return deferred.promise;
  }

  function dropBatchesByDsId(ds_id, batches, batchObects) {
    var datasource = DatasourceService.get_by_id(ds_id);

    var deferred = $q.defer();

    function delete_fn() {
      var dsBatchResource = new $resource(config.api.datasourceBatches, {'ds_id': ds_id});
      dsBatchResource.patch({patch: [{'op': 'remove', 'path': 'batches', 'value': batches}]}).$promise.then(del_success, del_error);

      function del_success(data) {
        deferred.resolve();
        toastNotification.success('Deleting batches');
        get_data_tracker(data).then(function(response) {
          if(batchObects.length == 1 ){
            toastNotification.success('One batch deleted');
          }
          else {
            toastNotification.success(batchObects.length + ' batches deleted');
          }
          analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.deletedMultipleBatches, {eventOrigin: "landingPage.previewPanel", numberOfBatchesDeleted: batches.length});
        }, deferred.reject)
      }

      function del_error(data) {
        deferred.reject();
      }

    }

    var modal = $uibModal.open({
      templateUrl: config.templates.deleteBatchConfirmation,
      windowClass: 'modal-size-small delete-batch-modal',
      controller: function () {
        var mvm = this;
        mvm.multiple = true;
        mvm.autoSync = datasource.autosync;
        mvm.batchesForDeletion = batchObects;
      },
      controllerAs: 'mvm'
    });

    modal.result.then(delete_fn);

    return deferred.promise;
  }

  function multiModifyBatchState(ds_id, batchObects, modifyType) {
    var datasource = DatasourceService.get_by_id(ds_id);
    var deferred = $q.defer();
    let opToBatchIdMap = {};
    if (modifyType == 'toggle') {
      angular.forEach(batchObects, function (batch) {
        if (batch.state == 'suspended') {
          if (!opToBatchIdMap['unsuspend']) {
            opToBatchIdMap['unsuspend'] = [batch.id];
          }
          else {
            opToBatchIdMap['unsuspend'].push(batch.id);
          }
        }
        else {
          if (!opToBatchIdMap['suspend']) {
            opToBatchIdMap['suspend'] = [batch.id];
          }
          else {
            opToBatchIdMap['suspend'].push(batch.id);
          }
        }
      })
    }
    else {
      angular.forEach(batchObects, function (batch) {
        if (!opToBatchIdMap[modifyType]) {
          opToBatchIdMap[modifyType] = [batch.id];
        }
        else {
          opToBatchIdMap[modifyType].push(batch.id);
        }
      })
    }

    function modifyBachesFn() {
      var dsBatchResource = new $resource(config.api.datasourceBatches, {'ds_id': ds_id});
      dsBatchResource.patch({patch: [{'op': 'replace', 'path': 'batches', 'value': opToBatchIdMap}]}).$promise.then(successCb, errorCb);

      function successCb(data) {
        deferred.resolve();
        toastNotification.success('Modifying batches');
        get_data_tracker(data).then(function(response) {
          if(batchObects.length == 1 ){
            toastNotification.success('One batch affected');
          }
          else {
            toastNotification.success(batchObects.length + ' batches affected');
          }
          analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.suspendUnsuspendBatches, {eventOrigin: "landingPage.previewPanel", numberOfBatchesAffected: batchObects.length});
        }, deferred.reject)
      }

      function errorCb(data) {
        toastNotification.error('Failed');
        deferred.reject();
      }

    }

    var modal = $uibModal.open({
      templateUrl: config.templates.suspendBatchConfirmation,
      windowClass: 'modal-size-small delete-batch-modal',
      controller: function () {
        var mvm = this;
        mvm.autoSync = datasource.autosync;
        mvm.batchesForModification = batchObects;
      },
      controllerAs: 'mvm'
    });

    modal.result.then(modifyBachesFn);

    return deferred.promise;
  }

  return service;
}
