/*jslint node: true */

import * as angular from "angular";
import _ = require('lodash');
import filterStorageService from "./filterStorage.service";


/**
 * @ngInject
 * @returns {{get_manager: get_manager}}
 */

globalFilterService.$inject = ['eventCallbackManagerFactory'];
export function globalFilterService(eventCallbackManagerFactory) {
  var registry = {};
  return {
    getBySource: getBySource
  };

  function getBySource(sourceId, sourceType, persistFilters) {
    var wid = '' + sourceId;
    if (!registry.hasOwnProperty(wid)) {  
      registry[wid] = new GlobalFilter(sourceId, sourceType, persistFilters);
    }
    return registry[wid];
  }

  function GlobalFilter(sourceId, sourceType, persistFilters) {
    var gf = this;
    var _conditionMap = {};
    gf.order = [];
    let filterManager = new filterStorageService().getBySource(sourceId, sourceType);

    var changeEvent = new eventCallbackManagerFactory("DataviewGlobalFilter." + sourceId);
    gf.isActive = false;
    gf.setCondition = setCondition;
    gf.getCondition = getCondition;
    gf.removeCondition = removeCondition;
    gf.onChange = changeEvent.add_callback;
    gf.fire_event = changeEvent.fire_event;
    gf.getByConditionId = getByConditionId;
    gf.getAllConditions = getAllConditions;

    gf.reset = reset;

    gf.restoreCondition = restoreCondition;

    // filterStorageService functions exposes here
    gf.getCardState = getCardState;
    gf.setCardState = setCardState;

    function restoreCondition() {
      if(persistFilters){
        const filters = filterManager?.getFilters();
        _conditionMap = filters.conditions;
        gf.order = filters.order;
      }
      changeEvent.fire_event();
    }


    // get all cards conditions
    function getAllConditions() {
      var conditions = [];
      for (var i = 0; i < gf.order.length; i++) {
        var conditionId = gf.order[i];
        conditions.push(_conditionMap[conditionId])
      }
      if (conditions.length == 0) {
        return null;
      }
      else if (conditions.length == 1) {
        return conditions[0];
      }
      else {
        return { AND: conditions };
      }
    }


    function getCondition(conditionIdBefore) {
      var conditions = [];
      for (var i = 0; i < gf.order.length; i++) {
        var conditionId = gf.order[i];
        if (conditionIdBefore == conditionId) {
          break;
        }
        conditions.push(_conditionMap[conditionId])
      }
      if (conditions.length == 0) {
        return null;
      }
      else if (conditions.length == 1) {
        return conditions[0];
      }
      else {
        return { AND: conditions };
      }
    }

    function getByConditionId(conditionId) {
      if (_conditionMap && _conditionMap.hasOwnProperty(conditionId)) {
        return _conditionMap[conditionId]
      }
    }

    function setCondition(conditionId, condition, prevent_fire_event) {
      if(_.isEqual(_conditionMap[conditionId], condition)){
        return;
      }
      removeCondition(conditionId, true)
      _conditionMap[conditionId] = condition;
      gf.order.push(conditionId);
      if (!prevent_fire_event) {
        changeEvent.fire_event();
      }
      if(persistFilters){
        filterManager.setFilters(_conditionMap, gf.order);
      }
    }

    function removeCondition(conditionId, prevent_fire_event) {
      if (_conditionMap.hasOwnProperty(conditionId)) {
        var orderIndex = gf.order.indexOf(conditionId);
        if (orderIndex != -1) {
          var to_be_deleted = gf.order.splice(orderIndex, gf.order.length - orderIndex);

          // delete card filters
          angular.forEach(to_be_deleted, function (cid) {
            delete _conditionMap[cid];
          });
        }
        if (!prevent_fire_event) {
          changeEvent.fire_event();
          if(persistFilters){
            filterManager.setFilters(_conditionMap, gf.order);
          }
        }
      }

    }

    function reset() {
      if (!_.isEmpty(_conditionMap)) {
        _conditionMap = {};
        gf.order = [];
        filterManager.resetFilters();
        changeEvent.fire_event();
      }
    }

    // all functions related to filter are added here
    function setCardState(cardId, state){
      if(persistFilters){
        filterManager.setCardState(cardId, state);
      }
    }

    function getCardState(cardId) {
      if(persistFilters){
        return filterManager.getCardState(cardId);
      }
      return {};
    }
  }
}

