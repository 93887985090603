import * as _ from 'lodash-es';

googleAnalyticsHelpers.$inject = ['Singularity', 'c', 'analyticsService', 'utils'];

export function googleAnalyticsHelpers(Singularity, c, analyticsService, utils) {

  let _reportSearchChange = utils.debounce(() => {
    analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.ggl_anltcs.onSearchChange,
      {
        eventOrigin: "thirdPartyModal.googleAnalytics"
      });
  }, 2000, false);
  let _reportSegmentChange = utils.debounce(() => {
    analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.ggl_anltcs.onSegmentChange,
      {
        eventOrigin: "thirdPartyModal.googleAnalytics"
      });
  }, 2000, false);

  return {
    onCategoryChange: onCategoryChange,
    validateMetricsDimensions: validateMetricsDimensions,
    onMetricSelected: onMetricSelected,
    onSegmentSelected: onSegmentSelected,
    onSearchChange: onSearchChange,
    on_load: onGoogleAnalyticsLoad,
    selectReport: selectReport
  };

  function selectReport() {
    
  }
  function onCategoryChange(params, elem, scope) {
    let dimensionArray = scope.options.data.keyWiseFieldsMap.data.ux.attributes.items[0][params]['dimensions'];
    let metricArray = scope.options.data.keyWiseFieldsMap.data.ux.attributes.items[0][params]['metrics'];
    let segmentArray = scope.options.data.keyWiseFieldsMap.data.ux.attributes.items[1];
    scope.options.data.keyWiseFieldsMap.dimensions.mappedField.templateOptions.options = dimensionArray.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    scope.options.data.keyWiseFieldsMap.metric_type.mappedField.templateOptions.options = metricArray.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    scope.options.data.keyWiseFieldsMap.segment.mappedField.templateOptions.options = segmentArray.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
  }

  function validateMetricsDimensions(params, elem, scope, callback_fn) {
    analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.ggl_anltcs.validateMetricsDimensions,
    {
      eventOrigin: "thirdPartyModal.googleAnalytics"
    });
    scope.options.data.parentController.showLoader = true;
    Singularity.validateDsConfig(params, scope, callback_fn).then(function (data) {
      scope.options.data.parentController.isDsConfigValid = !!data.is_valid;
      if (data.is_valid == true) {
        scope.form.$invalid = false;
      }
      scope.options.data.parentController.showLoader = false;
    }, function (data) {
      scope.options.data.parentController.showLoader = false;
    });
  }


  function onSegmentSelected(params, elem, scope, callback_fn) {
    _reportSegmentChange();
    scope.form.$invalid = true;
    scope.model.validate = false;
    scope.options.data.parentController.dsConfigValidationMessage = { error_message: ''};
    if (scope.model.metric_type.length == 0) {
      scope.options.data.parentController.dsConfigValidationMessage = { error_message: 'Metric Required'};
      scope.model.validate = true;
    }
    if (scope.model.metric_type && scope.model.metric_type.length > 10) {
      scope.options.data.parentController.dsConfigValidationMessage = { error_message: 'Selected ' + scope.model.metric_type.length + ' metrics. Only 10 are allowed'};
      scope.model.validate = true;
    }
    if (scope.model.dimensions && scope.model.dimensions.length > 7) {
      scope.options.data.parentController.dsConfigValidationMessage = {error_message: 'Selected ' + scope.model.dimensions.length + ' dimensions. Only 7 are allowed'};
      scope.model.validate = true;
    }

  }

  function onGoogleAnalyticsLoad() {
    let parentController = this.extraData.parentController;
    let keyWiseFieldsMap = this.extraData.keyWiseFieldsMap;
    let dimensionsArray: any = keyWiseFieldsMap.data.ux.attributes.items[0]['dimensions'];
    let metricsArray: any = keyWiseFieldsMap.data.ux.attributes.items[0]['metrics'];
    let segmentsArray: any = keyWiseFieldsMap.data.ux.attributes.items[1];
    keyWiseFieldsMap.dimensions.mappedField.templateOptions.options = dimensionsArray.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    keyWiseFieldsMap.metric_type.mappedField.templateOptions.options = metricsArray.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    keyWiseFieldsMap.segment.mappedField.templateOptions.options = segmentsArray.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

  }



  function onSearchChange(params, elem, scope) {
    _reportSearchChange();
    var filterDimensions = scope.options.data.keyWiseFieldsMap.data.ux.attributes.items[0]['dimensions'];
    var filterMetrics = scope.options.data.keyWiseFieldsMap.data.ux.attributes.items[0]['metrics'];
    scope.options.data.keyWiseFieldsMap.dimensions.mappedField.templateOptions.options = filterDimensions.filter(function (el) {
      return el.name.toLowerCase().includes(params.toLowerCase());
    });
    scope.options.data.keyWiseFieldsMap.metric_type.mappedField.templateOptions.options = filterMetrics.filter(function (el) {
      return el.name.toLowerCase().includes(params.toLowerCase());
    });
  }

  function onMetricSelected(params, elem, scope, callback_fn) {
    scope.form.$invalid = true;
    scope.options.data.parentController.dsConfigValidationMessage = {error_message: ""};
    let cancelledItems = scope.model.metrics_selected;
    let selections = getSelections(scope, cancelledItems);
    let metrics = selections[1];
    let dimensions = selections[2];


    scope.model.metrics_selected = [];
    scope.options.data.keyWiseFieldsMap.metrics_selected.mappedField.templateOptions.options = selections[0];
    if (metrics.length > 10) {
      scope.options.data.parentController.dsConfigValidationMessage = { error_message : 'Selected ' + metrics.length + ' metrics. Only 10 are allowed'};
      scope.model.validate = true;
      return;
    } else {
      scope.options.data.parentController.dsConfigValidationMessage = { error_message : ''} ;
      scope.model.validate = false;
    }
    if (metrics.length == 0) {
      scope.options.data.parentController.dsConfigValidationMessage = {error_message : 'Metric required'};
      scope.model.validate = true;
    }
    if (scope.model.dimensions && scope.model.dimensions.length > 7) {
      scope.options.data.parentController.dsConfigValidationMessage = { error_message : 'Selected ' + scope.model.dimensions.length + ' dimensions. Only 7 are allowed'};
      scope.model.validate = true;
    }
    if (dimensions.length > 7) {
      scope.options.data.parentController.dsConfigValidationMessage = { error_message : 'Selected ' + dimensions.length + ' dimensions. Only 7 are allowed'};
      scope.model.validate = true;
      return;
    } else {
      scope.options.data.parentController.dsConfigValidationMessage = { error_message : ''};
      scope.model.validate = false;
    }
    if (scope.model.metric_type.length == 0) {
      scope.options.data.parentController.dsConfigValidationMessage = { error_message : 'Metric Required'} ;
      scope.model.validate = true;
    }
    if (scope.model.metric_type && scope.model.metric_type.length > 10) {
      scope.options.data.parentController.dsConfigValidationMessage = { error_message : 'Selected ' + scope.model.metric_type.length + ' metrics. Only 10 are allowed'};
      scope.model.validate = true;
    }
  }
}

function getSelections(scope, cancelledItems) {
  let metrics = scope.options.data.keyWiseFieldsMap.data.ux.attributes.items[0].metrics;
  let dimensions = scope.options.data.keyWiseFieldsMap.data.ux.attributes.items[0].dimensions;
  let selectedArray = [];
  let selectedMetrics = [];
  let selectedDimensions = [];
  _.forEach(metrics, function (valueOfMetric) {
    if (valueOfMetric.selected) {
      if (valueOfMetric.value != cancelledItems[0]) {
        selectedMetrics.push(valueOfMetric);
      } else {
        valueOfMetric.selected = false;
        scope.model.metric_type = scope.model.metric_type.filter((el) => !cancelledItems.includes(el));
      }
    }
  });
  _.forEach(dimensions, function (valueOfDimension) {
    if (valueOfDimension.selected) {
      if (valueOfDimension.value != cancelledItems[0]) {
        selectedDimensions.push(valueOfDimension);
      } else {
        valueOfDimension.selected = false;
        scope.model.dimensions = scope.model.dimensions.filter((el) => !cancelledItems.includes(el));
      }
    }
  });

  selectedArray = selectedMetrics.concat(selectedDimensions);
  return [selectedArray, selectedMetrics, selectedDimensions];

}
