/*jslint node: true */
"use strict";

/**
 * @ngInject
 */
export function featureAvailability(){
  let subscriptionDetails = {
    name: null,
    features: []
  };

  return {
    isFeatureEnabled: isFeatureEnabled,
    init: init
  }

  function isFeatureEnabled(featureName){
           // TODO: SENTRYERROR:FRONTEND-FW:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/451535500
    return subscriptionDetails.features.indexOf(featureName) != -1;
  }

  // These features will be enabled by default, but will pass through Split.io
  // We want to remove backend dependency of features like publish to DB and handle them via Split
  // Idea is to keep these default features enabled always, unless disabled by Split.io
  // In case of a disable, we do a delete operation in app.run.ts
  function init(subscription, splitFeatures){
    subscriptionDetails = subscription;
    for(let feature in splitFeatures)
    {
      if(!isFeatureEnabled(splitFeatures[feature]))
        subscriptionDetails.features.push(splitFeatures[feature]);
    }
  }
}



/**
 * @ngInject
 */
onRun.$inject = ['$rootScope', 'featureAvailability', 'c'];
export function onRun($rootScope, featureAvailability, c){
  $rootScope.isFeatureEnabled = featureAvailability.isFeatureEnabled;
  $rootScope.appFeatures = c.appFeatures;
}
