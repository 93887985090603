<template>
  <section class="grid-section">
    <mm-data-grid
      v-if="isInitComplete"
      style="height: 100%"
      :columnDefs="columnDefs"
      :rowCount="totalRows"
      :metrics="metrics"
      :onGetRows="onGetRows"
      @init="onInit"
      @gridReady="onGridMounted"
      @columnSelect="onColumnSelect"
      @columnMove="onColumnMove"
      @exploreIconToggle="onExploreIconToggle"
    />
  </section>
</template>

<script>
import {
  PublishApi_getPublishReportInfo,
  PublishApi_getExploreCardData,
  PublishApi_getMetrics,
  PublishApi_getGridRows,
} from '@vueSrc/modules/publish/viewer/api/publish.api.js'

// Constants
import { USER_EVENTS } from '@/constants'

export default {
  data() {
    return {
      openSidebar: false,
      sidebarButtonHovered: false,
      reportId: null,
      metrics: [],
      isLoadingData: false,
      isInitComplete: false,

      mmDataGridRef: null,
      columnDefs: [],
      totalRows: 0,
    }
  },
  methods: {
    onInit({ mmDataGrid }) {
      this.mmDataGridRef = mmDataGrid
    },
    onGridMounted() {
      const removeColumnWithoutEvent = (columnId) => {
        const context = this.mmDataGridRef.gridApi.gridOptionsWrapper.gridOptions.context
        const indexOfColumn = context.exploreCardColumns.indexOf(columnId)
        context.exploreCardColumns.splice(indexOfColumn, 1)
        this.mmDataGridRef.gridApi.refreshHeader()
      }
      this.$store.commit('publish/setGridRemoveColumnFunc', removeColumnWithoutEvent)

      this.$store.state.publish.exploreConfig.forEach((column) => {
        this.mmDataGridRef.addExploreIcon(column.column)
      })
    },
    onColumnSelect() {},
    onColumnMove() {},
    /**
     * @payload Object { exploreCardColumns: Array, columndId: Number/String }
     */
    onExploreIconToggle(payload) {
      this.$userEvents.save(USER_EVENTS.REPORTS.EXPLORE_SECTION.TOGGLE, { columnId: payload.columnId })
      this.$store.state.publish.columnOpenEvent.fire_event(payload.columnId, 'column_header')
      this.$store.commit('publish/setIsExploreSectionOpen', payload.exploreCardColumns.length > 0)
    },

    async onGetRows(params) {
      const rowsResponse = await PublishApi_getGridRows(
        this.reportId,
        params.startRow,
        params.endRow,
        this.$store.state.publish.dataset.condition,
        this.$store.state.publish.reportDisplayProps
      )
      this.columnDefs = rowsResponse.columnDefs
      this.totalRows = rowsResponse.paging.total
      this.$store.commit('publish/setReportMetadata', rowsResponse.metadata)
      this.$store.commit('publish/setRowCount', this.totalRows)
      return rowsResponse.data
    },
  },

  watch: {
    '$store.state.publish.dataset.condition': function (condition) {
      this.mmDataGridRef.resetDataSource() // on filter toggle
    },
  },

  async created() {
    this.$store.commit('publish/setGetSourceDataFunc', PublishApi_getExploreCardData)
    const apiResponse = await PublishApi_getPublishReportInfo(window.location.href.split('/').pop()).catch((error) => {
      this.$toast.show({ content: this.$t('data_library.report_not_found'), status: 'error' })
      console.error('ERROR: PublishApi_getPublishReportInfo', error)
      window.location.href = '/#/landing/home'
    })

    const exploreCardsDisplayProps = apiResponse.data.components
      .filter((exploreInfo) => exploreInfo.component_type === 'EXPLORE_CARD')
      .map((exploreInfo) => exploreInfo.display_props)

    const dataGrid = apiResponse.data.components.find((comp) => {
      return comp.component_type === 'DATA_GRID'
    })
    this.reportId = dataGrid.report_id

    this.$store.commit('publish/setExploreConfig', exploreCardsDisplayProps)
    this.$store.commit('publish/setReport', apiResponse.data)
    this.$store.commit('publish/setDisplayProps', dataGrid.display_props)
    this.$store.commit('publish/setFormatInfo', dataGrid.display_props['FORMAT_INFO'])
    this.$store.commit('publish/setReportId', this.reportId)

    this.metrics = await PublishApi_getMetrics(
      apiResponse.data.components.filter((component) => component.component_type === 'METRIC')
    )

    this.isInitComplete = true
  },
}
</script>

<style lang="scss" scoped>
.grid-section {
  position: relative;

  .mm-data-grid-header-cell--header-text {
    line-height: 1 !important;
  }
}
</style>
