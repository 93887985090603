import * as $ from 'jquery';
import * as angular from 'angular';
import * as _ from 'lodash-es';

dateDiffManagerFactory.$inject = ['destinationColumnManagerFactory', 'utils', 'c'];
export function dateDiffManagerFactory(destinationColumnManagerFactory, utils, c) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new DateDiffManager(options);
  }

  function DateDiffManager(options) {
    var self = this;
    var metadata = options.metadata, taskUtils = options.taskUtils, dataview = options.context.dataview;
    self.getParam = getParam;
    self.handlePasteParams = handlePasteParams;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.validate = validate;
    self.setParam = setParam;
    self.sourceColumnSelected = sourceColumnSelected;
    self.context = options.context

    self.metadata = utils.sanitizeMetadataWithType(metadata);
    self.destinationManager = destinationColumnManagerFactory.get_manager({
      metadata: metadata,
      allowedTypes: ['NUMERIC'],
      taskUtils: options.taskUtils,
      isDestinationFormatterVisible: true
    });

    self.isStartDateDynamic = false;
    self.toggleIsStartDateDynamic = onToggleIsStartDateDynamic;
    self.toggleIsEndDateDynamic = onToggleIsEndDateDynamic;
    self.isEndDateDynamic = false;
    self.minuendColumn = null;
    self.subtrahendColumn = null;
    self.source_columns = _.orderBy(_.filter(metadata, {type: "DATE"}), ['display_name_w_type']);
    self.highlightSource = taskUtils.highlight.sources;
    let allowedComponents = [
      "YEAR",
      "MONTH",
      "WEEK",
      "DAY",
      "HOUR",
      "MINUTE",
      "SECOND"
    ];
    self.allowedComponents = [];
    angular.forEach(allowedComponents, function(ac, i){
      self.allowedComponents.push({
        internal: ac,
        display: utils.string.capitalize(ac.toLowerCase()) + 's'
      });
    });
    self.param = {
      DATE_DIFF: {
        COMPONENT: "DAY",
        MINUEND: {
          TYPE: "COLUMN",
          VALUE: null
        },
        SUBTRAHEND: {
          TYPE: "COLUMN",
          VALUE: null
        }
      }
    };
    function onToggleIsEndDateDynamic(){
      self.param.DATE_DIFF.MINUEND.VALUE = null;
    }
    function onToggleIsStartDateDynamic(){
      self.param.DATE_DIFF.SUBTRAHEND.VALUE = null;
      
    }
    function validate() {
      if (self.destinationManager) {
        return self.destinationManager.validate();
      }
      return true;
    }

    function handlePasteParams(taskInfo){
      /** Update params with suitable replacement columns, based on display name*/

      var params = taskInfo.params;
      //Minuend Column
      if (params.DATE_DIFF.MINUEND.TYPE == 'COLUMN') {
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(
          params.DATE_DIFF.MINUEND,
          'VALUE',
          taskInfo,
          self.metadata,
          self.displayNameAndTypeToColumnMap
        )
      }
      //Subtrahend
      if (params.DATE_DIFF.SUBTRAHEND.TYPE == 'COLUMN') {
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(
          params.DATE_DIFF.SUBTRAHEND,
          'VALUE',
          taskInfo,
          self.metadata,
          self.displayNameAndTypeToColumnMap
        )
      }

      //Destination params
      if (params.DATE_DIFF.hasOwnProperty('DESTINATION')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.DATE_DIFF, 'DESTINATION', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        //Update destination manager metadata
        self.destinationManager.metadata = self.metadata
        self.destinationManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(self.metadata)
      }
      return params;
    }

    function getParam() {
      var param = _.cloneDeep(self.param);
      var destination_param = self.destinationManager.getParam();
      delete param.DATE_DIFF.DESTINATION;
      delete param.DATE_DIFF.AS;
      if (destination_param.hasOwnProperty('AS') && self.context.inEditMode==true  &&  self.context.task){
        utils.sanatizeParamForDuplicateCols(destination_param['AS'], 'INTERNAL_NAME', self.context.task)
      }
      angular.extend(param.DATE_DIFF, destination_param);
      if(options.context.hasOwnProperty('sequence')){
        param['SEQUENCE_NUMBER'] = options.context.sequence;
      }

      if (self.isEndDateDynamic ) {
        param.DATE_DIFF.MINUEND.TYPE=c.SYSTEM_TIME
        param.DATE_DIFF.SUBTRAHEND.TYPE='COLUMN'
        delete param.DATE_DIFF.MINUEND.VALUE

      }
      if (self.isStartDateDynamic ) {
        param.DATE_DIFF.SUBTRAHEND.TYPE=c.SYSTEM_TIME
        param.DATE_DIFF.MINUEND.TYPE='COLUMN'
        delete param.DATE_DIFF.SUBTRAHEND.VALUE
      }

      if(!self.isEndDateDynamic && !self.isStartDateDynamic) {
        param.DATE_DIFF.SUBTRAHEND.TYPE='COLUMN'
        param.DATE_DIFF.MINUEND.TYPE='COLUMN'
      }
      
      return param;
    }

    function sourceColumnSelected(column_internal_name) {
      if(self.param.DATE_DIFF.MINUEND.VALUE == self.param.DATE_DIFF.SUBTRAHEND.VALUE){
        self.param.DATE_DIFF.SUBTRAHEND.VALUE = null;
      }
      let sources = [];
      if(column_internal_name){
        sources.push(column_internal_name);
        let other;
        if(column_internal_name == self.param.DATE_DIFF.MINUEND.VALUE){
          other = self.param.DATE_DIFF.SUBTRAHEND.VALUE;
        }
        else{
          other = self.param.DATE_DIFF.MINUEND.VALUE;
        }
        if(other){
          sources.push(other);
        }
        sources.reverse();
      }
      else{
        if(self.param.DATE_DIFF.MINUEND.VALUE){
          sources.push(self.param.DATE_DIFF.MINUEND.VALUE);
        }
        if(self.param.DATE_DIFF.SUBTRAHEND.VALUE){
          sources.push(self.param.DATE_DIFF.SUBTRAHEND.VALUE);
        }
      }
      self.highlightSource(sources);
    }

    function setParam(param) {
      self.param.DATE_DIFF.COMPONENT = param.DATE_DIFF.COMPONENT;
      self.param.DATE_DIFF.MINUEND.VALUE = param.DATE_DIFF.MINUEND.VALUE;
      self.param.DATE_DIFF.SUBTRAHEND.VALUE = param.DATE_DIFF.SUBTRAHEND.VALUE;
      self.param.DATE_DIFF.MINUEND.TYPE = param.DATE_DIFF.MINUEND.TYPE
      self.param.DATE_DIFF.SUBTRAHEND.TYPE = param.DATE_DIFF.SUBTRAHEND.TYPE
      if(self.param.DATE_DIFF.MINUEND.TYPE==c.SYSTEM_TIME) {
        delete self.param.DATE_DIFF.MINUEND.VALUE
        self.isEndDateDynamic = true
      }
      if(self.param.DATE_DIFF.SUBTRAHEND.TYPE==c.SYSTEM_TIME){
        delete self.param.DATE_DIFF.SUBTRAHEND.VALUE
        self.isStartDateDynamic = true
      }  

      var sourceColumnInternalNames = _.map(self.source_columns,'internal_name')

      if (
        self.param.DATE_DIFF.SUBTRAHEND.TYPE=='COLUMN' && self.param.DATE_DIFF.MINUEND.VALUE &&
        !sourceColumnInternalNames.includes(self.param.DATE_DIFF.MINUEND.VALUE)
      ) {
        var src_minuend_col = utils.metadata.get_column_by_internal_name(metadata, self.param.DATE_DIFF.MINUEND.VALUE)
        self.source_columns.push(src_minuend_col)
      }
      if (
        self.param.DATE_DIFF.SUBTRAHEND.TYPE=='COLUMN' && self.param.DATE_DIFF.SUBTRAHEND.VALUE &&
        !sourceColumnInternalNames.includes(self.param.DATE_DIFF.SUBTRAHEND.VALUE)
      ) {
        var src_subtr_col = utils.metadata.get_column_by_internal_name(metadata, self.param.DATE_DIFF.SUBTRAHEND.VALUE)
        self.source_columns.push(src_subtr_col)
      }
      self.destinationManager.setParam(param.DATE_DIFF);
    }
  }
}

valDateDiffCol.$inject = ['utils']
export function valDateDiffCol(utils) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valDateDiffCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valDateDiffCol = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue){
          var col_info = utils.metadata.get_column_by_internal_name(scope.tvm.manager.metadata, modelValue)
          if( col_info.hasOwnProperty('error')){
            is_valid =  false
          }else if(col_info.type != 'DATE'){
            is_valid = false
          }
        }
        return is_valid
      }
    }
  }
}


/**
 * @ngInject
 */
export function filterDateOptionsForDiff(){
  return function(values, avoid){
    return _.filter(values, function(v){
      return v.internal_name != avoid;
    });
  }
}
