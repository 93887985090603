import * as angular from 'angular';
import * as _ from 'lodash-es';

var COMPONENTS = [
  'YEAR',
  'MONTH',
  'WEEK',
  'DAY',
  'HOUR',
  'MINUTE',
  'SECOND'
];



/**
 * @ngInject
 */
dateInputForCondition.$inject = ['config'];
export function dateInputForCondition(config) {
  return {
    templateUrl: config.templates.dateFilterInput,
    scope: {
      ngModel: '=',
      comparisonOp:'='
    },
    controller: 'DateFilterInputController',
    controllerAs: 'dvm',
    bindToController: true,
    link: function (scope){
      scope.$watch('dvm.comparisonOp', function(){
        scope.dvm.showHideOptions();
      });
    }

  }
}

/**
 * @ngInject
 */
export class DateFilterInputController{
  public options: any = [
    {internal: 'DAY', display: 'Date', type: 'TRUNCATE', valtype: 'date', exactOnly: false},
    {internal: 'SECOND', display: 'Date - Time in seconds', type: 'TRUNCATE', valtype: 'datetime', exactOnly: false},
    {internal: 'MINUTE', display: 'Date/Minute', type: 'TRUNCATE', valtype: 'date-hour-minute', exactOnly: false},
    {internal: 'HOUR', display: 'Date/Hour', type: 'TRUNCATE', valtype: 'date-hour', exactOnly: false},
    {internal: 'MONTH', display: 'Year/Month', type: 'TRUNCATE', valtype: 'year-month', exactOnly: false},
    {internal: 'YEAR', display: 'Year', type: 'TRUNCATE', valtype: 'number', exactOnly: false},

    {internal: 'day', display: 'Day of Month', type: 'COMPONENT', valtype: 'number', exactOnly: false},
    {internal: 'month_text', display: 'Month', type: 'COMPONENT', valtype: 'month', exactOnly: true},
    {internal: 'weekday_text', display: 'Weekday', type: 'COMPONENT', valtype: 'month', exactOnly: true},

    {internal: 'MIN', display: 'Earliest single value', type: null, valtype: 'number', exactOnly: true},
    {internal: 'MAX', display: 'Latest single value', type: null, valtype: 'number', exactOnly: true},
    {internal: 'MIN+', display: 'Earliest Date - Time', type: 'DELTA', valtype: 'number', exactOnly: false},
    {internal: 'MAX-', display: 'Latest Date - Time', type: 'DELTA', valtype: 'number', exactOnly: false},
    {internal: 'SYSTEM_DATE', display: 'Current Date (UTC)', type: 'DELTA', valtype: 'date-hour', exactOnly: false},
    {internal: 'SYSTEM_TIME', display: 'Current Time (UTC)', type: 'DELTA', valtype: 'datetime', exactOnly: false}
  ];
  public weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
    'November', 'December'
  ];
  public years = Array.from(new Array(100),(val,index)=>String(index+1950));
  public operand: any;
  public ngModel: any;
  public comparisonOp: any;
  private $timeout: any;
  private utils: any;
  public option: any = this.options[0];
  public value: any;
  public unit: string;
  public dateComponents: any;
  public sign: number;
  public signOptions: any;

  static $inject = ['utils', '$timeout'];
  public $onInit: () => void;
  public constructor(utils, $timeout){
    this.utils = utils;
    this.$timeout = $timeout;
    this.initConsts();
    this.$onInit = function(){
      this.interpretParams(this.ngModel);
    }
  }

  public showHideOptions(){
    if(this.comparisonOp === 'EQ' || this.comparisonOp == 'NE'){
      _.forEach(this.options, function(o){
        if (o.internal == 'SYSTEM_TIME') {
          /* Don't show SYSTEM_TIME in
          equality option. */
          o.show = false;
        } else {
          o.show = true;
        }
      });
    }
    else{
      _.forEach(this.options, function (o) {
        if(o.exactOnly){
          o.show = false;
        }
        else{
          o.show = true;
        }
      });
    }
  }

  interpretParams(param){
    if(param){
      if(param.hasOwnProperty('DELTA')){
        let func = param.FUNCTION;
        let delta  = param.DELTA;
        let filtered;
        if(delta){
          this.unit = Object.keys(delta)[0];
          this.value = delta[this.unit];
          if(func == 'MAX'){
            filtered = _.filter(this.options, {type: "DELTA", internal: 'MAX-'});
            this.value = -1 * this.value;
          }
          else if(func == 'MIN'){
            filtered = _.filter(this.options, {type: "DELTA", internal: 'MIN+'});
          }
          else if (func == 'SYSTEM_DATE'){
            var current_date_display_name = 'Current Date (UTC)';
            if (this.value) {
              if ((this.value/Math.abs(this.value)) > 0){
                this.sign = 1;
              } else {
                this.sign = -1;
              }
              this.value = Math.abs(this.value);
            }
            filtered = _.filter(this.options, {type: "DELTA", internal: 'SYSTEM_DATE', display: current_date_display_name});
          }
          else {
             if ((this.value/Math.abs(this.value)) > 0){
             this.sign = 1;
            } else {
              this.sign = -1;
            }
            this.value = Math.abs(this.value);
            filtered = _.filter(this.options, {type: "DELTA", internal: 'SYSTEM_TIME'});
          }
        }
        else{
          filtered = _.filter(this.options, {type: "DELTA", internal: func});
        }
        this.option = filtered[0];
      }
      else if(param.hasOwnProperty('COMPONENT')){
        let component = param.COMPONENT;
        let filtered = _.filter(this.options, {type: 'COMPONENT', internal: component});
        this.option = filtered[0];
        this.value = param.VALUE;
      }
      else {
        let truncate, value;
        if(typeof param == 'string'){
          value = param;
          truncate = 'SECOND';
        }
        else{
          truncate = param.TRUNCATE;
          if(truncate){
            if (truncate == "MONTH") {
              let splitVal = param.VALUE.split('-');
              value = [splitVal[0], this.months[Number(splitVal[1]) - 1]];
            }
            else if (truncate == "YEAR") {
              let splitVal = param.VALUE.split('-');
              value = splitVal[0];
            }
            else {
              value = param.VALUE;
            }
          }
        }
        if(truncate){
          let filtered = _.filter(this.options, {type: 'TRUNCATE', internal: truncate});
          this.option = filtered[0];
          this.value = value;
        }
        else{
          if(param.FUNCTION == 'MAX' || param.FUNCTION  == 'MIN'){
            let filtered = _.filter(this.options, {type: null, internal: param.FUNCTION});
            this.option = filtered[0];
          }
        }
      }
    }
  }

  handleOptionsChange(){
    let today = new Date();
    this.unit = 'DAY';
    this.value = null;
    if(this.option.internal == 'MONTH' && this.option.type == 'TRUNCATE'){
      this.value = [String(today.getFullYear()), this.months[today.getMonth() - 1]];
    }
    else if(this.option.internal == 'YEAR' && this.option.type == 'TRUNCATE'){
      this.value = String(today.getFullYear());
    }
    this.updateModel();
  }

  updateModel(){
    let param: any;
    if(this.option.type == 'COMPONENT'){
      param = {
        COMPONENT: this.option.internal,
        VALUE: this.value
      };
    }
    else if(this.option.type == 'TRUNCATE'){
      let value = this.value;
      if(this.option.internal == 'MONTH'){
        if(value[0] && value[1]){
          let monthNum = String(this.months.indexOf(value[1]) + 1);
          if(monthNum.length == 1){
            monthNum = '0' + monthNum;
          }
          value = value[0] + '-' + monthNum + '-01';
        }
      }
      else if(this.option.internal == 'YEAR'){
        if(value){
          value = value + '-01-01';
        }
      }
      param = {
        TRUNCATE: this.option.internal,
        VALUE: value
      };
    }
    else if(this.option.type == 'DELTA'){
      if(this.option.internal == 'MAX-'){
        let delta = {};
        delta[this.unit] = -1 * this.value;
        param = {
          FUNCTION: 'MAX',
          DELTA: delta
        };
      }
      else if (this.option.internal == 'MIN+'){
        let delta = {};
        delta[this.unit] = this.value;
        param = {
          FUNCTION: 'MIN',
          DELTA: delta
        };
      }
      else if (this.option.internal == 'SYSTEM_DATE'){
        let delta = {};
        if(!this.value){
          this.value = 0;
        }

        delta[this.unit] = this.sign * Math.abs(this.value);
        param = {
          FUNCTION: 'SYSTEM_DATE',
          DELTA: delta
        };
      }
      else if (this.option.internal == 'SYSTEM_TIME'){
        let delta = {};
        if(!this.value){
          this.value = 0;
        }
        delta[this.unit] = this.sign * Math.abs(this.value);
        param = {
          FUNCTION: 'SYSTEM_TIME',
          DELTA: delta
        };
      }
    }
    else{
      param = {FUNCTION: this.option.internal};
    }
    this.ngModel = param;
  }

  initConsts(){
    let dateComponents = [];
    for(let i=0; i< COMPONENTS.length; i++){
      dateComponents.push({
        internal: COMPONENTS[i],
        display: this.utils.string.capitalize(COMPONENTS[i].toLowerCase()) + 's'
      });
    }
    this.dateComponents = dateComponents;
    this.sign = 1;
    this.signOptions = [
      {
        val: +1, display: '+'
      },
      {
        val: -1, display: '-'
      }
    ];
  }
}


/**
 * @ngInject
 */
describeDateInputValue.$inject = ['utils'];
export function describeDateInputValue(utils) {
  return {
    describe: function (v, executionTimestamp?: string) {
      try {
        return describe(v, executionTimestamp);
      }
      catch (e) {
        console.error(e);
        return '';
      }
    }
  };

  function _describeArray(v) {
    var r = [];
    v.forEach(function (val) {
      r.push(describe(val));
    });
    return r.join(', ');
  }

  function describe(v, executionTimestamp?: string) {
    if (angular.isArray(v)) {
      return _describeArray(v);
    }
    if (v) {
      var truncateInfo;
      if (v.hasOwnProperty('TRUNCATE') && !v.hasOwnProperty('FUNCTION')) {
        truncateInfo = v.TRUNCATE;
        v = v.VALUE;
        if (!v) {
          return;
        }
        let lastIndex = null;
        if (truncateInfo == 'MINUTE') {
          lastIndex = v.lastIndexOf(':00');
        }
        else if (truncateInfo == 'HOUR') {
          lastIndex = v.lastIndexOf(':00:00');
        }
        else if (truncateInfo == 'DAY') {
          lastIndex = v.lastIndexOf('00:00:00');
        }
        else if (truncateInfo == 'MONTH') {
          lastIndex = v.lastIndexOf('-01');
        }
        else if (truncateInfo == 'YEAR') {
          lastIndex = v.lastIndexOf('-01-01');
        }
        if (lastIndex !== null && lastIndex > -1) {
          v = v.substring(0, lastIndex)
        }
      }


      var s = '';
      if (typeof v == 'string') {
        s = v;
      }
      else if(v.hasOwnProperty('COMPONENT')){
        s = v.COMPONENT + '(' + v.VALUE + ')';
      }
      else {
        if (v.hasOwnProperty('FUNCTION') && v.FUNCTION) {
          if (v.FUNCTION == 'MIN') {
            s = 'Earliest';
          }
          else if (v.FUNCTION == 'MAX') {
            s = 'Latest';
          }
          else if (v.FUNCTION == 'SYSTEM_DATE') {
            s = 'Current Date (UTC)';
          }
          else if (v.FUNCTION == 'SYSTEM_TIME') {
            s = 'Current Time (UTC)';
          }
          else {
            s = utils.string.capitalize(v.FUNCTION.toLowerCase());
          }
          if (v.DELTA && (v.FUNCTION != 'SYSTEM_DATE' && v.FUNCTION != 'SYSTEM_TIME')) {
            truncateInfo = Object.keys(v.DELTA)[0];
            var val = v.DELTA[truncateInfo];
            var plus_or_minus = '+';
            if (val < 0) {
              plus_or_minus = '-';
              val = -1 * val;
            }
            if (!val){
              // If delta is not defined (delta is not > 0)
              // then just display it as blank instead of undefined
              val = " ";
            }
            s = utils.string.format("{0} {1} {2} {3}",
              [s, utils.string.capitalize(truncateInfo.toLowerCase()), plus_or_minus, val]);
          }
          /*
          Separate if block for system date/time because other functions with delta have different truncation possibilities
          eg. earliest can have earliest day, week, minute based on truncation data in delta, this is
          not true for system date/time as it is bolted to either date (DAY) or time (SECONDS).
           */
          else if (v.DELTA && (v.FUNCTION == 'SYSTEM_DATE' || v.FUNCTION == 'SYSTEM_TIME')) {
            truncateInfo = Object.keys(v.DELTA)[0];
            let timeStr = "";
            if (executionTimestamp) {
              let lastRun;
              // executionTimestamp is string representation of timestamp YYYY-MM-DD HH:MM:SS
              // formattedTime will be of format Day, DD Mon YYYY HH:MM:SS
              let formattedTime = utils.prettifyTimestampInUTC(executionTimestamp)
              if (v.FUNCTION == 'SYSTEM_DATE') {
                // The substring is to filter out the HH:MM:SS string
                formattedTime = formattedTime.substring(0, formattedTime.length - 9)
                lastRun = 'last run date';
              } else {
                lastRun ='last run time';
              }
              timeStr = utils.string.format("({0} ({1}))", [formattedTime, lastRun]);
            }
            var val = v.DELTA[truncateInfo];
            if (val){
              //if val is not defined it will simply display current day/time
              truncateInfo = Math.abs(val) > 1 ? truncateInfo + 'S' : truncateInfo; //pluralize
              var plus_or_minus = '+';
              if (val < 0) {
                plus_or_minus = '-';
                val = -1 * val;
              }
              if (timeStr.length == 0){
                timeStr = s;
              }
              s = utils.string.format("{0} {1} {2} {3}",
                  [timeStr, plus_or_minus, val, utils.string.capitalize(truncateInfo.toLowerCase())]);
            }
            else {
              s = utils.string.format("{0} {1}",
                  [s, timeStr]);
            }
          }
          else {
            if(truncateInfo){
              s += (' ' + utils.string.capitalize(truncateInfo.toLowerCase()));
            }
          }
        }
      }
      return s.trim();
    }
    else {
      return "";
    }
  }
}

