import * as $ from 'jquery';
import * as angular from 'angular';
import * as _ from 'lodash-es';
import { Grid, Data, Formatters, Plugins, Event, Slick } from 'slickgrid-es6';

/**
 * @ngInject
 *
 * Factory to generate complete grid instances including the data factory.
 */
gridFactory.$inject = ['ajaxGridDataFactory', '$timeout', '$q', 'eventCallbackManagerFactory', 'keyboardEvents'];
export function gridFactory(ajaxGridDataFactory, $timeout, $q, eventCallbackManagerFactory, keyboardEvents) {

  /**
   *  Constructor for GridInstance that represents a complete grid.
   *
   *  @param element - element object (jquery/jqlite) where the grid should be inserted
   *  @param {string} [dataURL=undefined] - the URL from where the data needs to be fetched. Can be set to undefined if
   *                                data is provided via `dataContent` argument
   *  @param {Object[]} columns - column definitions
   *  @param {string} columns[].name - Display name of the column
   *  @param {string|integer} columns[].id - Unique ID to identify the column with
   *  @param {string|integer} columns[].field - Attribute name of the value in data which will be used to populate
   *                                            the cell
   *  @param {Object} [options=undefined] - Grid options
   *  @param {Object[]|Array[]} - the complete grid can be supplied instead of `dataURL` if required. This required
   *                              `dataURL` to be set to `undefined`
   */
  return GridInstance;

  function GridInstance(element, dataURL, columns, options, dataContent) {
    var defaultOptions = {
      rowHeight: 28,
      editable: false,
      enableAddRow: false,
      field: "id",
      fullWidthRows: true,
      defaultColumnWidth: 100,
      columnHighlightCssClass: 'column-highlight',
      selectionMenuButtonCssClass: 'header-select-menu-button',
      explicitInitialization: true,
      enableTextSelectionOnCells: true,
      endPointMethod: "GET",
      enableCellNavigation: true
    };

    var grid, _viewPortChangedTimeout, dataFactory, _headerColumnLockVal = false;
    var _options = angular.merge(defaultOptions, options || {});
    var onSortEvent = new eventCallbackManagerFactory('onGridSort');
    var onHeaderClickEvent = new eventCallbackManagerFactory('onHeaderClick');
    var onCellClickEvent = new eventCallbackManagerFactory('onCellClick');
    var onCommandClickEvent = new eventCallbackManagerFactory('onCommandClick');
    var service = {
      'grid': undefined,
      'init': init,
      'dataFactory': undefined,
      'reload': undefined,
      'destroy': destroy,
      'update': update,
      'condition': options.condition,
      'plugins': <any>{},
      'coordinates': {},
      'columns': _.cloneDeep(columns),
      'refreshHeaders': refreshHeaders,
      'getCoordinates': getCoordinates,
      // getter/setter
      'columnHeaderLock': columnHeaderLockGetterSetter,
      'setSort': setSort,
      'onSort': onSortEvent.add_callback,
      'sortSpec': [],
      'onClickHeader': onHeaderClickEvent.add_callback,
      'onCellClick': onCellClickEvent.add_callback,
      'onCommandClick': onCommandClickEvent.add_callback
    };

    function init() {
      destroy();
      dataFactory = new ajaxGridDataFactory(dataURL, columns, _options, dataContent);
      const headerMenuPlugin = new Plugins.HeaderMenu({});

      $timeout(function () {
        $(element).addClass('slickgrid-container');
      });
      grid = new Grid(element, dataFactory.data, columns, _options);
      var highlightPlugin = new ColumnHighlighter({});
      var selectionMenuButtonPlugin = new selectionMenuButton({});
      var scrollToColumnPlugin =  new ScrollToColumn({});
      var tooltipPlugin = new Plugins.AutoTooltips();
      grid.registerPlugin(tooltipPlugin);
      grid.registerPlugin(highlightPlugin);
      grid.registerPlugin(scrollToColumnPlugin);
      grid.registerPlugin(selectionMenuButtonPlugin);
      grid.registerPlugin(headerMenuPlugin);

      service.plugins.columnHighlighter = highlightPlugin;
      service.plugins.selectionMenuButton = selectionMenuButtonPlugin;
      service.plugins.scrollToColumn = scrollToColumnPlugin.scrollToColumn;
      service.plugins.generateColHeaderInputElement = generateColHeaderInputElement;
      service.plugins.setRowCssClass = setRowCssClass;
      service.plugins.HeaderButtons = Plugins.HeaderButtons;
      service.plugins.HeaderMenu = Plugins.HeaderMenu;

      grid.onViewportChanged.subscribe(onViewPortChanged);
      dataFactory.onDataLoading.subscribe(dataFactoryOnDataLoading);
      dataFactory.onDataLoaded.subscribe(dataFactoryOnDataLoaded);
      grid.onColumnsResized.subscribe(_lockHeaderColumnForShortTime);
      grid.onColumnsReordered.subscribe(_lockHeaderColumnForShortTime);
      grid.onHeaderCellRendered.subscribe(_addTitleToHeaderCells);
      grid.onHeaderClick.subscribe(clickColHeader);
      grid.onActiveCellChanged.subscribe(selectCell);

      headerMenuPlugin.onBeforeMenuShow.subscribe(function(e, args){
        // Add search and replace if text type
        if (args.column.type == 'TEXT') {
          const menu = args.menu;
          if (menu.items.length < 6) {
            menu.items.push({
              title: 'Find & Replace',
              command: 'replace',
              iconCssClass: 'find-replace'
            });
          }
        }
        //  close menu on escape key press
        $(document).on("keydown", this, function (e) {
          var keycode = ((typeof e.keyCode !='undefined' && e.keyCode) ? e.keyCode : e.which);
          if (keycode === 27) {
              Array.from(document.getElementsByClassName('slick-header-menu') as HTMLCollectionOf<HTMLElement>)[0].style.visibility = "hidden";
            var headerElement = Array.from(document.getElementsByClassName('slick-header-column-active') as HTMLCollectionOf<HTMLElement>);
            if((headerElement).length > 0){
              headerElement[0].classList.remove('slick-header-column-active');
            }
            return false
          }
        });
      });

      headerMenuPlugin.onCommand.subscribe(function(e, args){
        onCommandClickEvent.fire_event(args.command);
      });

      if (_options.showColumnTypeIcons) {
        grid.onHeaderCellRendered.subscribe(_addTypeIconsIfAvailable);
      }
      if (_options.enableViewerSort) {
        grid.onHeaderCellRendered.subscribe(_addSortIconsIfAvailable);
        grid.onHeaderClick.subscribe(_addSortListeners);
      }
      grid.onClick.subscribe(_handleClick);
      grid.onActiveCellChanged.subscribe(handleCellChange);
      grid.onScroll.subscribe(scrollHandler);

      // get first fetch
      service.grid = grid;
      service.dataFactory = dataFactory;
      grid.init();
      $(window).resize(grid.resizeCanvas);

      if (options.coordinates && options.coordinates) {
        // Scroll to current position only if the 1st column is not visible. This fixes MVP-5790
        if (options.coordinates.left>0){
          // Following logic keeps the grid to intact to its current position when user filters via explore card
          grid.scrollCellIntoView(options.coordinates.top, options.coordinates.right);
        }
      }
      grid.onViewportChanged.notify();
    }

    function getCoordinates() {
      if (service.grid) {
        return getCellCoordinates();
      }

      return {};
    }

    /**
     * Updates the grid.
     * @param updateSpec Object that contains the update specification.
     * @param updateSpec.dataURL Updated URL
     * @param updateSpec.columns Updated column Spec
     * @param updateSpec.options Updated options
     * @param updateSpec.dataContent Updated options
     */
    function update(updateSpec) {
      var _updatePromise = $q.defer();
      var dataFactoryUpdateSpec = _.cloneDeep(updateSpec);
      var coordinates = getCellCoordinates();
      var columnsChanged = false;
      if (updateSpec.hasOwnProperty('columns')) {
        grid.setColumns(_.cloneDeep(updateSpec.columns));
        columnsChanged = !angular.equals(updateSpec.columns, service.columns);
        service.columns = updateSpec.columns;
        if (columnsChanged) {
          service.plugins.columnHighlighter.unhighlight();
        }
      }
      if (updateSpec.hasOwnProperty('condition')) {
        service.condition = updateSpec.condition;
      }
      dataFactory.update(dataFactoryUpdateSpec).then(function () {
        grid.setData(dataFactory.data);
        grid.invalidateAllRows();
        grid.scrollRowIntoView(coordinates.from);
        grid.onViewportChanged.notify();
        if (!columnsChanged) {
          service.plugins.columnHighlighter.renderAll();
        }
        _updatePromise.resolve();
      }, function () {
        _updatePromise.reject();
      });
      return _updatePromise.promise;
    }

    function _addTitleToHeaderCells(e, args) {
      var column = args.column;
      var colId = args.column.id;
      if (colId !== 'index') {
        var columnNameElement = args.node.childNodes[0];
        columnNameElement.title = args.column.old_display_name !== args.column.name ? "(Original Name): " + args.column.old_display_name + " \n(Current Name): " + args.column.name :args.column.name
      }
    }

    function _handleClick(e, args) {
      var cell = grid.getCellFromEvent(e);
      selectAllText(e.target);
    }

    function handleCellChange(e, args){
      var c= grid.getCellNode(args.row, args.cell);
      selectAllText(c);
    }

    function clickColHeader(e, args) {
      if(e.target.type != 'text'){
        var multiselect = {'shift': e.shiftKey, 'ctrl': e.ctrlKey || e.metaKey};
        onHeaderClickEvent.fire_event(args, multiselect);
      }
    }

    function selectCell(e, args){
        onCellClickEvent.fire_event();
    }

    function scrollHandler(e, data) {
      var cell = grid.getActiveCell();
      if(cell) {
        var vp = grid.getViewport();
        var c = grid.getCellNode(cell.row, cell.cell);
        if (c) {
          selectAllText(c);
        }
      }
    }

    function selectAllText(el) {
      let docObj: any = document;
       if (typeof window.getSelection != "undefined" && typeof docObj.createRange != "undefined") {
           var range = document.createRange();
           range.selectNodeContents(el);
           var sel = window.getSelection();
           sel.removeAllRanges();
           sel.addRange(range);
       } else if (typeof docObj.selection != "undefined" && typeof docObj.body.createTextRange != "undefined") {
           var textRange = docObj.body.createTextRange();
           textRange.moveToElementText(el);
           textRange.select();
       }
    }


    function _addTypeIconsIfAvailable(e, args) {
      var column = args.column;
      if (!column.type) {
        return;
      }
      var colTypeClass = "type-" + column.type.toLowerCase();
      if (column.id !== "index" && column.type && !$(args.node).find('.' + colTypeClass).length) {
        var existingTypeIcon = $(args.node).find(".datatype");
        if (existingTypeIcon.length) {
          existingTypeIcon.remove();
        }
        var $el = $("<span></span>").addClass(colTypeClass).addClass("datatype");
        $($el).prependTo(args.node);
        $(args.node).addClass('grid-header-' + column.type.toLowerCase());
      }
    }

    function _addSortIconsIfAvailable(e, args) {
      var column = args.column;
      var existingSortIcon = $(args.node).find(".sort-icon");
      if (existingSortIcon.length) {
        existingSortIcon.remove();
      }
      _.forEach(service.sortSpec, function (item, idx) {
        if (item[0] == column.id) {
          if (item[1] == "ASC") {
            var $el = $('<i class="fa fa-caret-up sort-icon"></i>');
          } else {
            var $el = $('<i class="fa fa-caret-down sort-icon"></i>');
          }
          $($el).prependTo(args.node);
        }
      });
    }

    function _addSortListeners(e, args) {
      var column = args.column;
      if (!column || _headerColumnLockVal) {
        return;
      }
      var currCollSort = undefined;
      var currCollIdx = undefined;
      _.forEach(service.sortSpec, function (item, idx) {
        if (item[0] == column.id) {
          currCollSort = item[1];
          currCollIdx = idx;
        }
      });
      var newColSpec = [column.id, currCollSort == "ASC" ? "DESC" : "ASC"];
      if (!keyboardEvents.isKeyDown('shift')) {
        service.sortSpec.splice(0);
        service.sortSpec.push(newColSpec);
      } else {
        if (currCollIdx !== undefined) {
          service.sortSpec[currCollIdx] = newColSpec;
        } else {
          service.sortSpec.push(newColSpec);
        }
      }
      refreshHeaders();
      onSortEvent.fire_event(service.sortSpec);
    }

    function setSort(newSpec) {
      service.sortSpec = _.cloneDeep(newSpec);
      refreshHeaders();
    }

    // getter/setter to toggle column header lock to prevent accidental column selects
    function columnHeaderLockGetterSetter(setTo) {
      if (setTo === undefined) {
        return _headerColumnLockVal;
      } else {
        _headerColumnLockVal = !!setTo;
      }
    }

    function refreshHeaders() {
      var cols = grid.getColumns();
      if (cols.length) {
        for(const _col of cols){
          grid.updateColumnHeader(_col.id, _col?.old_display_name, _col?.old_display_name);
        }
      }
    }

    // locks for a short time then unlocks
    function _lockHeaderColumnForShortTime() {
      columnHeaderLockGetterSetter(true);
      $timeout(function () {
        columnHeaderLockGetterSetter(false);
      }, 200);
    }

    function onViewPortChanged() {
      if (_viewPortChangedTimeout) {
        clearTimeout(_viewPortChangedTimeout);
      }
      _viewPortChangedTimeout = setTimeout(function () {
        if (dataFactory) {
          dataFactory.ensureData(getCellCoordinates());
        }
      }, 120);
    }

    function getCellCoordinates() {
      var vp = grid.getViewport();
      var rightCell = grid.getCellFromPoint(vp.rightPx, 0);
      var leftCell = grid.getCellFromPoint(Math.max(vp.leftPx, 1), 0);
      return {from: vp.top, to: vp.bottom, left: leftCell.cell, right: rightCell.cell};
    }

    function dataFactoryOnDataLoading(e, args) {
    }

    function dataFactoryOnDataLoaded(e, args) {
      for (var i = args.from; i <= args.to; i++) {
        grid.invalidateRow(i);
      }
      grid.updateRowCount();
      grid.render();
      grid.resizeCanvas();
    }

    function destroy() {
      if (grid) {
        grid.onViewportChanged.unsubscribe(onViewPortChanged);
        grid.destroy();
      }
      if (dataFactory) {
        dataFactory.onDataLoading.unsubscribe(dataFactoryOnDataLoading);
        dataFactory.onDataLoaded.unsubscribe(dataFactoryOnDataLoaded);
        dataFactory.destroy();
        dataFactory = undefined;
      }
      $(element).empty();
    }

    //this method is called from column menu controller and dataview directive for
    //Rename option on column menu and double click on column header
    function generateColHeaderInputElement(header_col_id, existing_col_name) {
      var class_name = "col-header-" + header_col_id;
      var header_col_input_id = header_col_id + "_input";
      var input_element = '<input class="' + class_name + '"' + ' ' + 'type="text" id="' + header_col_input_id
          + '" data-col-header-id="' + header_col_id + '" value="' + existing_col_name + '" title="">';
      return [input_element, header_col_input_id];

    }
    function setRowCssClass(rowIndexs, cssClass) {
        let changes = {};
        let columns = grid.getColumns();
        angular.forEach(rowIndexs, function (rowIndex) {
            changes[rowIndex] = {};
            angular.forEach(columns, function (column) {
                changes[rowIndex][column.id] = cssClass;
            });
        });
        grid.invalidateRows(rowIndexs);
        grid.setCellCssStyles("highlight", changes);
        grid.render();
    }

    return service;
  }


}

/**
 * @ngInject
 *
 * This factory generates the ajax Data manager instances. This is reponsible for paging and fetching data.
 */
ajaxGridDataFactory.$inject = ['$http', 'config', '$q', 'FutureService', 'toastNotification', 'c'];
export function ajaxGridDataFactory($http, config, $q, FutureService, toastNotification, c) {
  return AjaxGridData;
  function AjaxGridData(dataURL, columns, options, dataContent) {
    var data = [];
    var PAGESIZE = config.PAGESIZE;
    var PAGEWIDTH = config.PAGEWIDTH;
    var _columns = _.cloneDeep(columns);
    var _condition = _.cloneDeep(options.condition);
    var sequence = options.sequence;
    var _endPointMethod = options.endPointMethod;
    var _dataContent = _.cloneDeep(dataContent);
    var _ensureDataPromise, _updatePromise;
    var preData = null;
    var callCount = 0;

    if (_dataContent !== undefined) {
      data = _.cloneDeep(_dataContent);
    }
    // events
    var onDataLoading = new Slick.Event();

    var onDataLoaded = new Slick.Event();

    var service = {
      "ensureData": ensureData,
      "onDataLoading": onDataLoading,
      "onDataLoaded": onDataLoaded,
      "data": data,
      "columns": _columns,
      "destroy": destroy,
      "update": update
    };
    function update(updateSpec) {
      _updatePromise = $q.defer();
      var shouldResetData = false;
      if (updateSpec.hasOwnProperty('columns')) {
        _columns = updateSpec.columns;
        shouldResetData = true;
      }
      if (updateSpec.hasOwnProperty('dataURL')) {
        dataURL = updateSpec.dataURL;
        shouldResetData = true;
      }
      if (updateSpec.hasOwnProperty('condition')) {
        _condition = updateSpec.condition;
        shouldResetData = true;
      }

      if (shouldResetData) {
        _resetData();
      } else {
        _updatePromise.resolve();
      }
      if (_dataContent !== undefined && updateSpec.dataContent !== undefined) {
        data = updateSpec.dataContent;
        service.data = data;
        _dataContent = updateSpec.dataContent;
      }
      return _updatePromise.promise;
    }


    function ensureData(coordinates) {
      _ensureDataPromise = $q.defer();
      var from = coordinates.from, to = Math.max(coordinates.to, PAGESIZE), left = coordinates.left, right = coordinates.right;
      if (from < 0) {
        from = 0;
      }
      // var fromPage = Math.floor(from / PAGESIZE);

      if (data.length > 0) {
        // There are cases where data length is more than 'to' variable, which leads to offset being set to a greater value.
        // Below logic of getting min value only for the data that is required to be validated handles this use case. Context: MVP-3492
        to = Math.min(to, data.length - 1);
      }

      var fromPage = Math.floor(from / PAGESIZE);

      var toPage = Math.floor(to / PAGESIZE);
      /*Get data from the next page only when data for current page is present completely i.e
       enure the data exists for the first row of the page including both the left and right most columns of the visible grid
       This prevents fetching data for next vertical page even when user has performed a horizontal scroll
       Hence solving the issues mentioned in MVP-3492 and MVP-4654
       */
      while (data[fromPage * PAGESIZE] !== undefined
            && fromPage < toPage
            && Object.keys(data[0]).includes(_columns[left].internal_name)
            && Object.keys(data[0]).includes(_columns[right].internal_name)) {
        fromPage++;
      }

      while (data[toPage * PAGESIZE] !== undefined && fromPage < toPage) {
        toPage--;
      }

      var leftPage = Math.floor(left / PAGEWIDTH);
      var rightPage = Math.floor(right / PAGEWIDTH);

      var allColumnsPresent = false;
      if (data[fromPage * PAGESIZE] !== undefined) {
        var row = data[fromPage * PAGESIZE];
        if (_columns[Math.max(leftPage * PAGEWIDTH, 1)]) {
          while (row[_columns[Math.max(leftPage * PAGEWIDTH, 1)].id] !== undefined && leftPage < rightPage) {
            leftPage++;
          }
          if (_columns[rightPage * PAGEWIDTH]) {
            while (row[_columns[rightPage * PAGEWIDTH].id] !== undefined && leftPage < rightPage) {
              rightPage--;
            }
            allColumnsPresent = row[_columns[Math.max(leftPage * PAGEWIDTH, 1)].id] !== undefined && row[_columns[rightPage * PAGEWIDTH].id] !== undefined;
          }
        } else {
          allColumnsPresent = true;
        }
        // this logic only works with the assumption that at any given time there can be only less than 50 columns visible in a viewport
      }
      if ((fromPage > toPage || ((fromPage == toPage) && data[fromPage * PAGESIZE] !== undefined)) && allColumnsPresent) {
        onDataLoaded.notify({from: from, to: to});
        return _ensureDataPromise.promise;
      }

      onDataLoading.notify({from: from, to: to});

      var columnsList = [];
      var max_column = Math.min((rightPage * PAGEWIDTH) + PAGEWIDTH, _columns.length);
      for (var i = (leftPage * PAGEWIDTH); i < max_column; i++) {
        if (_columns[i] && _columns[i].id !== "index") {
          columnsList.push(_columns[i].id);
        }
      }
      if (_dataContent !== undefined) {
        // add index to data
        for (var i = 0; i < data.length; i++) {
          data[i].index = i + 1;
        }
        setTimeout(function () {
          onDataLoaded.notify({from: from, to: to});
        }, 200);
      } else if (dataURL !== undefined) {
        if (_endPointMethod != 'POST') {
          _endPointMethod = 'GET';
        }
        var methodMap = {
          GET: $http.get,
          POST: $http.post
        };

        let httpOptions;
        if (_endPointMethod == 'GET') {
          let payLoad = {
            "offset": fromPage * PAGESIZE,
            "limit": (((toPage - fromPage) * PAGESIZE) + PAGESIZE),
            "columns": JSON.stringify(columnsList),
            "condition": JSON.stringify(_condition)
          };
          if(Number.isInteger(sequence)){
            payLoad[c.sequenceNumber] = sequence
          }
          httpOptions = {
            params: payLoad
          };
        }
        else {
          httpOptions = {
            "offset": fromPage * PAGESIZE,
            "limit": (((toPage - fromPage) * PAGESIZE) + PAGESIZE),
            "columns": columnsList,
            "condition": _condition
          };
          if (Number.isInteger(sequence)) {
            httpOptions[c.sequenceNumber] = sequence
          }
        }
        if(Array.isArray(dataURL)) {
          for (let i = 0; i < dataURL.length; i++) {
            methodMap[_endPointMethod](dataURL[i], httpOptions).then(function(response){
              get_data_success(response.data);
            }).catch(console.error);
          }
        }
        else{
          methodMap[_endPointMethod](dataURL, httpOptions).then(function (response){
            get_data_success(response.data);
          }).catch(console.error);
        }
      }
      return _ensureDataPromise.promise;
    }

    function get_data_success(data){
      if (data.hasOwnProperty('future_id')) {
        get_data_tracker(data)
      }
      else {
        onSuccess(data);
      }
    }

    function get_data_tracker(data) {
      var deferred = $q.defer();
      FutureService.track(data.future_id, data_tracker);
      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          onSuccess(response);
          deferred.resolve(response);
        }
        else {
          onDataLoaded.notify({from:0, to: 200});
          deferred.reject(response);
        }
      }
    }

    function onSuccess(resp) {
      if(preData && Array.isArray(dataURL)) {
        preData.data = (preData.data).concat(resp.data);
        preData.paging.count += resp.paging.count;
        resp.data = preData.data;
        resp.paging.count = preData.paging.count;
      }
      else{
        preData = resp;
      }
      var from = resp.paging.offset - 1, to = from + resp.paging.count;

      // Add some delta at end of grid to enable infinite scrolling
      // if response data is not multiple of PAGESIZE then there is
      // no more data left to scroll. Don't add delta then.
      var delta = resp.data.length % PAGESIZE == 0 ? 2 : 0;

      data.length = to + delta;

      for (var i = 0; i < resp.paging.count; i++) {
        var item = resp.data[i];
        if (data[from + i] === undefined || data[from + i] === null) {
          data[from + i] = {};
        }
        angular.merge(data[from + i], item);
        data[from + i].index = from + i + 1;
      }
      onDataLoaded.notify({from: from, to: to});
      if (_ensureDataPromise && _ensureDataPromise.resolve) {
        _ensureDataPromise.resolve();
      }
    }

    function _resetData() {
      data = [];
      service.data = data;
      $http.get(dataURL, {
        "params": {
          "offset": 0,
          "limit": 0
        }
      }).then(function (response) {
        let resp = response.data;
        data.length = parseInt(resp.paging.total);
        _updatePromise.resolve();
      }).catch(function (response) {
        let data = response.data;
        _updatePromise.reject(data);
      });
    }

    function destroy() {
      // placeholder
    }

    return service;
  };
}


/**
 * Column Highlighter plugin
 */
// (function ($) {
//   // register namespace
//   $.extend(true, window, {
//     "Slick": {
//       "Plugins": {
//         "ColumnHighlighter": ColumnHighlighter
//       }
//     }
//   });

  function ColumnHighlighter(options) {
    var _grid;
    var _self = this;
    var _handler = new Slick.EventHandler();
    var _defaults = {
      columnHighlightCssClass: 'column-highlight'
    };
    var registry = {};


    function init(grid) {
      options = $.extend(true, {}, _defaults, options);
      _grid = grid;
    }

    function destroy() {
      _handler.unsubscribeAll();
    }


    /**
     * Highlights columns
     *
     * @param highlightSpec spec to add highlight classes:
     *  ```
     *  {
     *    'col-A': ['highlightX'],
     *    'col-B': ['highlightX', 'highlightY'],
     *    'col-C': ['highlightZ']
     *  }
     *  ```
     *  here both the highlight classes will be added on `colB`
     */
    function highlightColumns(highlightSpec, renderManually) {
      if (typeof highlightSpec === "object") {
        $.each(highlightSpec, function (col, cssClasses) {
          if (registry[col] === undefined) {
            registry[col] = {};
          }
          $.each(cssClasses, function (i, cssClass) {
            registry[col][cssClass] = true;
          });
        });
      }
      if (!renderManually) {
        rerenderAll();
      }
    }

    function unhighlightColumns(highlightSpec, renderManually) {
      if (!highlightSpec) {
        registry = {};
      } else {
        $.each(highlightSpec, function (col, cssClasses) {
          if (typeof cssClasses === "string") {
            cssClasses = [cssClasses];
          }
          if (!Object.keys(cssClasses).length) {
            delete registry[col];
          } else {
            $.each(cssClasses, function (i, cssClass) {
              delete registry[col][cssClass];
            });
          }

        });
      }
      if (!renderManually) {
        rerenderAll();
      }
    }

    function unhighlightByClasses(cssClasses, renderManually) {
      if (!cssClasses) {
        registry = {};
      } else {
        $.each(cssClasses, function (i, cssClass) {
          $.each(registry, function (colName, colDef) {
            if (typeof colDef === "object") {
              delete registry[colName][cssClass];
            }
          });
        });
      }
      if (!renderManually) {
        rerenderAll();
      }
    }

  function rerenderAll() {
    var colDefs = _grid.getColumns();
    $.each(colDefs, function (i, colDef) {
      if (colDef.id == 'index') {
        return;
      }
      var keys = Object.keys(registry[colDef.id] || {});
      if (registry.hasOwnProperty(colDef.id) && keys.length) {
        if (colDef.type == 'NUMERIC') {
          colDef.cssClass = keys.join(' ') + ' highlighted text-end text-monospace';
        }
        else {
          colDef.cssClass = keys.join(' ') + ' highlighted ';
        }
        colDef.headerCssClass = keys.join(' ') + ' highlighted';
      }
      else {
        if (colDef.type == 'NUMERIC') {
          colDef.cssClass = 'text-end text-monospace';
        } else {
          colDef.cssClass = '';
        }
        colDef.headerCssClass = '';
      }
    });

    _grid.setColumns(colDefs);
  }

  function rerenderWithMenuButton() {
    var colDefs = _grid.getColumns();
    $.each(colDefs, function (i, colDef) {
      if (colDef.id == 'index') {
        return;
      }
      var keys = Object.keys(registry[colDef.id] || {});
      if (registry.hasOwnProperty(colDef.id) && keys.length) {
        if (colDef.type == 'NUMERIC') {
          colDef.cssClass = keys.join(' ') + ' highlighted text-end text-monospace';
        }
        else {
          colDef.cssClass = keys.join(' ') + ' highlighted ';
        }
        colDef.headerCssClass = keys.join(' ') + ' highlighted' + ' '  +'optionbutton';
      }
      else {
        if (colDef.type == 'NUMERIC') {
          colDef.cssClass = 'text-end text-monospace';
        } else {
          colDef.cssClass = '';
        }
        colDef.headerCssClass = '';
      }
    });

      _grid.setColumns(colDefs);
    }

    function highlightMultipleColumns(itemClass, internalNames, doNotRender, addMenuButton) {
      internalNames = internalNames || [];
      unhighlightByClasses([itemClass], true);
      if (internalNames.length) {
        var highlightSpec = {};
        $.each(internalNames, function (i, col) {
          if (!col) return;
          highlightSpec[col] = [itemClass];
        });
        highlightColumns(highlightSpec, true);
      }
      if (!doNotRender) {
        if(addMenuButton){
          rerenderWithMenuButton();
        }
        else{
          rerenderAll();
        }
      }
    }

  function highlightColumnsByIndexRange(startRange, endRange, internalNameMap, addMenuButton) {
    var itemClass = 'source-highlight';
    unhighlightByClasses([itemClass], true);
    var highlightSpec = {};
    for(var i=startRange; i<=endRange; i++){
      highlightSpec[internalNameMap[i]] = [itemClass]
    }
    highlightColumns(highlightSpec, true);
    if(addMenuButton){
      rerenderWithMenuButton();
    }
    else{
      rerenderAll();
    }
  }

    $.extend(this, {
      "init": init,
      "destroy": destroy,

      "highlight": highlightColumns,
      "highlightMultipleColumns": highlightMultipleColumns,
      'highlightColumnsByRange': highlightColumnsByIndexRange,
      "unhighlight": unhighlightColumns,
      "unhighlightByClasses": unhighlightByClasses,
      "renderAll": rerenderAll,
      "rerenderWithMenuButton": rerenderWithMenuButton
    });
  }
// })(jQuery);



/**
 * Column selection menu button plugin
 */

function selectionMenuButton(options) {
  var _grid;
  var _self = this;
  var _handler = new Slick.EventHandler();
  var _defaults = {
    selectionMenuButtonCssClass: 'header-select-menu-button'
  };
  var registry = {};

  function init(grid) {
    options = $.extend(true, {}, _defaults, options);
    _grid = grid;
  }

  function destroy() {
    _handler.unsubscribeAll();
  }

  function addMenuButton(col_name) {
    var colDefs = _grid.getColumns();
    $.each(colDefs, function (i, colDef) {
      // condition
      colDef.header = {
      menu: {
        tooltip: 'Menu button tooltip',
        buttonCssClass: 'slick-header-menubutton',
        items: [
          {
            title: 'Hide',
            command: 'hide',
            tooltip: "Hide selected columns",
            iconCssClass:'hide-icon'
          },
          {
            title: 'Hide only these',
            command: 'hideOnly',
            tooltip: "Hide only these columns",
            iconCssClass:'hide-icon'
          },
          {
             title: 'Show only these',
             command: 'showOnly',
             tooltip: "Show only these columns",
             iconCssClass:'show-icon'
          },
          {
            title: 'Data Pipeline:',
            iconCssClass:'data-pipeline'
          },
          {
            title: 'Remove',
            command: 'delete',
            disabled: false,
            tooltip: "Remove selected columns",
            iconCssClass:'remove-icon'
          }
        ]
      }
    }
    });
    _grid.setColumns(colDefs);
  }

  $.extend(this, {
    "init": init,
    "destroy": destroy,
    "addMenuButton": addMenuButton,
  });
}

/**
 * Column Scroll plugin
 */
// (function ($) {
//   // register namespace
//   $.extend(true, window, {
//     "Slick": {
//       "Plugins": {
//         "ScrollToColumn": ScrollToColumn
//       }
//     }
//   });

  function ScrollToColumn(options) {
    var _grid;
    var _self = this;
    var _handler = new Slick.EventHandler();
    var _defaults = {
    };


    function init(grid) {
      options = $.extend(true, {}, _defaults, options);
      _grid = grid;
    }

    function destroy() {
      _handler.unsubscribeAll();
    }

    function scrollToColumn(columnId) {
      var vp = _grid.getViewport();
      var colIndex = _grid.getColumnIndex(columnId);

      if (colIndex) {
        _grid.scrollCellIntoView(vp.top, colIndex);
      }
    }


    $.extend(this, {
      "init": init,
      "destroy": destroy,
      "scrollToColumn": scrollToColumn
    });
  }
// })(jQuery);
