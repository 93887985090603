/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * @ngInject
 */
apiUtils.$inject = ['filterFilter', 'utils', 'moment', 'ftpHelpers',
  'Notification', 'mixpanelHelpers', 'googleAnalyticsHelpers', 'c', 'salesforceHelpers', 'googleDriveHelpers', 'dropboxHelpers', 'googleAdsHelpers', 'deploymentConsts', 'apiHelpers'];
export function apiUtils(filterFilter, utils, moment, ftpHelpers,
  Notification, mixpanelHelpers, googleAnalyticsHelpers,
  c, salesforceHelpers, googleDriveHelpers, dropboxHelpers, googleAdsHelpers, deploymentConsts, apiHelpers) {

  var helper_functions_map = {
    ftp: ftpHelpers,
    mixpanel: mixpanelHelpers,
    ggl_anltcs: googleAnalyticsHelpers,
    salesforce: salesforceHelpers,
    google_drive: googleDriveHelpers,
    dropbox: dropboxHelpers,
    ggl_ads: googleAdsHelpers
  };

  helper_functions_map.ftp.validate_config = apiHelpers.validate_ds_config;

  var service: any = {
    get_module_functions: get_module_functions,
    ftp: helper_functions_map.ftp,
    mixpanel: helper_functions_map.mixpanel,
    ggl_anltcs: helper_functions_map.ggl_anltcs,
    salesforce: helper_functions_map.salesforce,
    google_drive: helper_functions_map.google_drive,
    dropbox: helper_functions_map.dropbox,
    ggl_ads: helper_functions_map.ggl_ads
  };

  function get_module_functions(module_name) {
    if (([undefined, null].indexOf(module_name) !== -1) || typeof (module_name) !== "string") {
      return;
    }
    if (service.hasOwnProperty(module_name)) {
      return service[module_name];
    }
    else {
      return service._generic_functions;
    }
  }

  var rdbms_functions = {
    on_query_change: apiHelpers.on_query_change,
    validate_query_change: apiHelpers.validate_query_change,
    on_load: apiHelpers.rdbms_on_load,
    onSearchChange: apiHelpers.onSearchChange
  };

  service.web_analytics = {
    get_preview: apiHelpers.get_preview,
    validateDsConfig: apiHelpers.force_validate_ds_config
  };

  service.postgres = _.cloneDeep(rdbms_functions);
  service.postgres.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.redshift = _.cloneDeep(rdbms_functions);
  service.redshift.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.mysql = _.cloneDeep(rdbms_functions);
  service.mysql.get_table_description = apiHelpers.get_table_description_for_mysql;

  service.mssql = _.cloneDeep(rdbms_functions);
  service.mssql.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.mdynamics = _.cloneDeep(rdbms_functions);
  service.mdynamics.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.hubspot = _.cloneDeep(rdbms_functions);
  service.hubspot.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.mailchimp = _.cloneDeep(rdbms_functions);
  service.mailchimp.get_table_description = apiHelpers.get_table_description_for_postgresql

  service.facebook_ads = _.cloneDeep(rdbms_functions);
  service.facebook_ads.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.salesforcecdata = _.cloneDeep(rdbms_functions);
  service.salesforcecdata.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.google_analytics = _.cloneDeep(rdbms_functions);
  service.google_analytics.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.google_analyticsv4 = _.cloneDeep(rdbms_functions);
  service.google_analyticsv4.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.xero = _.cloneDeep(rdbms_functions);
  service.xero.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.sharepoint = angular.copy(rdbms_functions);
  service.sharepoint.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.snowflake = _.cloneDeep(rdbms_functions);
  service.snowflake.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.shopify = _.cloneDeep(rdbms_functions);
  service.shopify.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.sendgrid = _.cloneDeep(rdbms_functions);
  service.sendgrid.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.bigquery = _.cloneDeep(rdbms_functions);
  service.bigquery.get_table_description = apiHelpers.get_table_description_for_mysql;

  service.zoom = _.cloneDeep(rdbms_functions);
  service.zoom.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service.ggl_ads = _.cloneDeep(rdbms_functions);
  service.ggl_ads.get_table_description = googleAdsHelpers.get_table_description;
  
  service.parquet = _.cloneDeep(rdbms_functions);
  service.parquet.get_table_description = apiHelpers.get_table_description_for_postgresql;
  
  service.xactly = _.cloneDeep(rdbms_functions);
  service.xactly.get_table_description = apiHelpers.get_table_description_for_postgresql;

  service._generic_functions = {
    augmentDsConfigSpec: apiHelpers.augment_ds_config_spec,
    validateDsConfig: apiHelpers.validate_ds_config,
    reInitializeDsConfigForm: apiHelpers.re_initialize_ds_config_form
  };

  return service;

}
