<template>
  <mm-modal
    v-model="computedIsModalOpen"
    content-class="mm-restrictions-wrapper-modal-contact-sales"
    :title="computedTitle"
    width="415"
    closable
  >
    <span>{{ computedDescription }}</span>
    <template #actions>
      <mm-button :label="$t('global.dictionary.not_now')" objective="secondary" @click="computedIsModalOpen = false" />
      <mm-button class="m-l-3" :label="$t('global.contact_support')" @click="contactSales" />
    </template>
  </mm-modal>
</template>

<script>
// Mixins
import FreshdeskWidget from '@/mixins/support/freshdeskWidget'

export default {
  name: 'restrictions-wrapper-modal-contact-sales',
  mixins: [FreshdeskWidget],
  props: {
    value: Boolean,
    restrictedFeature: String,
    restrictedFeatureName: String
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    computedTitle() {
      if (this.restrictedFeature) {
        const title = this.$t('settings.workspace.plan.restricted.not_available', {
          featureName: this.restrictedFeatureName
        })
        return title.charAt(0).toUpperCase() + title.slice(1)
      }

      return this.$t('settings.workspace.plan.restricted.storage_limit_reached')
    },
    computedDescription() {
      if (this.restrictedFeature) return this.$t('settings.workspace.plan.restricted.contact_sales_to_access')
      return this.$t('settings.workspace.plan.restricted.storage_limit_reached_description')
    }
  },
  methods: {
    contactSales() {
      this.computedIsModalOpen = false
      this.mixinFreshdeskWidget_showSupportWidget()
    }
  }
}
</script>
