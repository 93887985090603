<template>
  <div class="mm-data-addition-third-party-connection">
    <div v-if="isLoading" class="identities-loading height-100">
      <mm-loader class="m-x-auto" size="large" />
    </div>

    <div v-else class="connections height-100">
      <div v-if="computedIsUserAdminOfCurrentProject" class="m-l-auto m-b-3" @click="setNewConnection">
        <mm-button
          v-if="connector.connection.googleAuth"
          icon="logo_google_18px"
          objective="secondary"
          :label="$t('data_library.data_addition.third_party.sign_in_google')"
        />
        <mm-button v-else :label="$t('data_library.data_addition.third_party.new_connection.title')" />
      </div>

      <div v-if="identities.length == 0" class="no-identities m-t-3">
        <span class="mm-text--h200">{{ $t('data_library.data_addition.third_party.no_identities') }}</span>
      </div>

      <div class="identities">
        <div
          class="identity mm-rounded-borders m-b-3"
          v-for="(identity, idx) in identities"
          :key="`${identity.name}_${idx}`"
        >
          <div
            class="d-flex align-items-center p-a-3"
            :class="{ 'cursor-pointer': !(identity.fetchedProfiles && identity.fetchedProfiles.length) }"
            @click="openIdentity(identity)"
          >
            <mm-tooltip wrapper-class="p-a-2" :label="decode(identity.name)" position="bottom">
              <span v-if="identity.fetchedProfiles && identity.fetchedProfiles.length">
                {{ decode(identity.name) }}
              </span>
              <mm-link v-else :label="decode(identity.name)" />
            </mm-tooltip>

            <mm-spacer />

            <div v-if="computedIsUserAdminOfCurrentProject" class="d-flex">
              <mm-button icon="edit" objective="tertiary" size="medium" @click.stop="editConnection(identity)" />
              <mm-button
                icon="delete"
                objective="tertiary"
                size="medium"
                @click.stop="openDeleteConnectionModal(identity)"
              />
            </div>
          </div>

          <div
            v-if="identity.loading || (identity.fetchedProfiles && identity.fetchedProfiles.length)"
            class="p-x-3 p-b-3"
          >
            <mm-divider class="m-b-3" />
            <div v-if="identity.loading" class="d-flex m-l-2">
              <mm-loader />
              <span class="m-l-3">{{ $t('data_library.data_addition.third_party.fetching_connection_data') }}</span>
            </div>

            <div v-else>
              <p>{{ connector.connection.profileAlias || $t('data_library.data_addition.third_party.profile') }}</p>
              <ul>
                <li v-for="profile in identity.fetchedProfiles" :key="`profile_${profile.value}`" class="m-b-1">
                  <mm-tooltip wrapper-class="p-a-2" :label="decode(profile.name)" position="bottom" :open-delay="1500">
                    <mm-link :label="decode(profile.name)" @click="openProfile(identity, profile)" />
                  </mm-tooltip>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="back-btn-wrapper">
        <mm-button class="m-r-auto" :label="$t('global.dictionary.back')" objective="tertiary" @click="$emit('back')" />
      </div>
    </div>

    <mm-modal
      v-model="isDeleteConnectionModalVisible"
      content-class="delete-connection"
      :title="$t('data_library.data_addition.third_party.delete_connection')"
      max-width="500"
    >
      <span>{{ $t('data_library.data_addition.third_party.delete_connection_desc') }}</span>

      <div v-if="selectedConnectionToDelete" class="d-flex m-t-3">
        <mm-icon name="delete" color="n100" class="m-r-3" />
        <span>{{ decode(selectedConnectionToDelete.name) }}</span>
      </div>

      <template #actions>
        <mm-button
          :label="$t('global.dictionary.cancel')"
          objective="tertiary"
          @click="isDeleteConnectionModalVisible = false"
        />
        <mm-button
          class="m-l-3"
          :label="$t('global.dictionary.delete')"
          objective="destructive"
          :loading="isModalLoading"
          @click="deleteConnection"
        />
      </template>
    </mm-modal>

    <data-library-data-addition-third-party-connection-new
      v-if="connector.connection.onAppAuth || connector.connection.userInputAuthUrl"
      v-model="isConnectionModalVisible"
      :key="`new_connection_modal_${newConnectionModalKey}`"
      :connector="connector"
      :selected-connection="selectedConnectionToEdit"
      :integration-info="integrationInfo"
      @close="selectedConnectionToEdit = null"
      @success="fetchIdentitiesInfo"
    />

    <component
      v-if="connector.connection.customNewConnectionComponent"
      :is="connector.connection.customNewConnectionComponent"
      v-model="isCustomConnectionModalVisible"
      :connector="connector"
      :selected-connection="selectedConnectionToEdit"
      @openRegularConnectionModal="isConnectionModalVisible = true"
      @close="selectedConnectionToEdit = null"
      @success="fetchIdentitiesInfo"
    />
  </div>
</template>

<script>
// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

// Components
import DataLibraryDataAdditionThirdPartyConnectionNew from './data-library-data-addition-third-party-connection-new/data-library-data-addition-third-party-connection-new'

// Constants
import { PROJECT_PERMISSIONS, USER_EVENTS } from '@/constants'

// Dependencies
import { decode } from 'html-entities'

export default {
  name: 'data-library-data-addition-third-party-connection',
  components: { DataLibraryDataAdditionThirdPartyConnectionNew },
  props: {
    connector: {
      type: Object,
      required: true
      /*{
        key: string,
        name: string,
        icon: string,
        category: string,
        connection: {...},
        metrics: {...}
      }*/
    }
  },
  data: () => ({
    identities: [],
    integrationInfo: null,
    isLoading: false,
    isModalLoading: false,
    isDeleteConnectionModalVisible: false,
    selectedConnectionToDelete: null,
    selectedConnectionToEdit: null,
    isConnectionModalVisible: false,
    isCustomConnectionModalVisible: false,
    newConnectionModalKey: 0
  }),
  computed: {
    computedIsUserAdminOfCurrentProject() {
      return this.$store.getters.getCurrentProjectPermissions === PROJECT_PERMISSIONS.ADMIN
    }
  },
  watch: {
    isConnectionModalVisible(val) {
      if (!val) this.newConnectionModalKey++
    }
  },
  async created() {
    this.fetchIdentitiesInfo()
  },
  methods: {
    setNewConnection() {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.OPEN_NEW_CONNECTION, {
        key: this.connector.key,
        name: this.connector.name
      })

      if (this.connector.connection.authUrl) this.openAuthUrl()
      else if (this.connector.connection.customNewConnectionComponent) this.isCustomConnectionModalVisible = true
      else this.isConnectionModalVisible = true

      this.selectedConnectionToEdit = null
    },
    async fetchIdentitiesInfo() {
      this.isLoading = true
      this.connector.hash = btoa(this.connector.key) // Hash needed due to blocking policies
      const response = await dataLibraryApi.getConnectorIdentities(this.connector.hash, this.$store.state.projectId)

      if (!response) {
        this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })
        this.$emit('back')
      }

      this.identities = response.data.identities.map((i) => ({ ...i, loading: false }))

      this.integrationInfo = await dataLibraryApi.getConnectorIntegration(
        this.connector.hash,
        this.$store.state.projectId
      )

      if (this.computedIsUserAdminOfCurrentProject && this.identities.length == 0 && !this.connector.connection.authUrl)
        this.setNewConnection()

      this.isLoading = false
    },
    async fetchProfiles(identity) {
      identity.loading = true

      const response = await dataLibraryApi.getProfilesInfo(
        this.connector.hash,
        identity.value,
        this.$store.state.projectId
      )
      // eslint-disable-next-line require-atomic-updates
      identity.fetchedProfiles = response.profiles

      if (!identity.fetchedProfiles?.length)
        this.$toast.show({
          content: this.$t('data_library.data_addition.third_party.no_profiles', { identity: identity.name }),
          status: 'error'
        })

      // eslint-disable-next-line require-atomic-updates
      identity.loading = false
    },
    openAuthUrl() {
      const url = new URL(this.integrationInfo.authUrl)
      const stateParams = url.searchParams.get('state')
      const regex = /integration_key=([^&#]+)/
      const match = regex.exec(stateParams)
      const integrationKey = match[1]
      const stateUrl = `${window.location.origin}/#/workspaces/${this.$store.state.workspaceId}/projects/${this.$store.state.projectId}?integration_key=${integrationKey}&workspace_id=${this.$store.state.workspaceId}&project_id=${this.$store.state.projectId}`
      url.searchParams.set('state', stateUrl)
      window.location.href = url.toString()
    },
    editConnection(identity) {
      if (this.connector.connection.authUrl) this.openAuthUrl()
      else if (this.connector.connection.customNewConnectionComponent) this.isCustomConnectionModalVisible = true
      else this.isConnectionModalVisible = true

      this.selectedConnectionToEdit = { ...identity, fields: identity.identity_config }
    },
    openDeleteConnectionModal(connection) {
      this.selectedConnectionToDelete = connection
      this.isDeleteConnectionModalVisible = true
    },
    async deleteConnection() {
      this.isModalLoading = true
      await dataLibraryApi.deleteConnection(
        this.connector.hash,
        this.selectedConnectionToDelete.value,
        this.$store.state.projectId
      )
      this.identities = this.identities.filter((i) => i.value != this.selectedConnectionToDelete.value)
      this.isDeleteConnectionModalVisible = false
      this.isModalLoading = false
    },
    openIdentity(identity) {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.OPEN_CONNECTION, { identity })

      if (this.connector.connection.hasProfiles) this.fetchProfiles(identity)
      else this.$emit('submit', { connection: identity })
    },
    openProfile(connection, profile) {
      const profileObj = {
        ...profile,
        value: Array.isArray(profile.value[0]) ? JSON.stringify(profile.value[0]) : profile.value,
        arrayValue: Array.isArray(profile.value[0]) && profile.value // needed for certain connectors, i.e. bigquery
      }
      this.$emit('submit', { connection, profile: profileObj })
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.OPEN_PROFILE, { connection, profile: profileObj })
    },
    decode
  }
}
</script>

<style lang="scss" scoped>
.mm-data-addition-third-party-connection {
  flex-grow: 1;

  .identities-loading {
    align-items: center;
    display: flex;
  }

  .connections {
    flex-direction: column;
    display: flex;

    .no-identities {
      color: var(--mm-color--n300);
      text-align: center;
    }

    .identities {
      max-height: 475px;
      overflow: auto;

      .identity {
        background: var(--mm-color--n50);

        ::v-deep .mm-tooltip--slot-wrapper {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .back-btn-wrapper {
      justify-content: flex-end;
      flex-direction: column;
      display: flex;
      flex-grow: 1;
    }
  }
}

.delete-connection .mm-icon {
  min-width: 16px;
}
</style>
