import * as angular from 'angular';
import * as _ from 'lodash-es';

declare var openSupportApp;
declare var initSupportApp;

/**
 * @ngInject
 */
TopNavController.$inject = ['VuexStore', 'UserWorkspace', 'Auth', '$state', '$scope', 'config', 'DataviewService', 'modalService',
  'DataviewLayoutService', 'navigationService', 'resources', 'appMetaInfo', '$window', '$timeout', 'DatasourceService',
  'TemplateService', 'utils', 'NotificationService',  'dataviewSafeDeleteResetService', 
  'analyticsService', 'c', 'appHelperService', '$rootScope', 'eventCallbackManagerFactory', 'localService',
  'landingList', 'TaskServiceFactory', 'ActionServiceFactory', 'webhooks', 'cannyService', '$resource', 'toastNotification'];
export function TopNavController($store, UserWorkspace, Auth, $state, $scope, config, DataviewService, modalService, DataviewLayoutService,
                          navigationService, resources, appMetaInfo, $window, $timeout, DatasourceService,
                          TemplateService, utils, NotificationService,  dataviewSafeDeleteResetService, analyticsService, c, appHelperService, $rootScope,
                          eventCallbackManagerFactory, localService, landingList, TaskServiceFactory,
                                 ActionServiceFactory, webhooks, cannyService, $resource, toastNotification) {
  this.$onInit = function () {
    // ViewModel
    var wsInMemory = [];
    var tnc = this;
    tnc.gotoDoc = gotoDoc;
    tnc.cannyFeatureRequest = cannyFeatureRequest;
    tnc.cannyChangelog = cannyChangelog;
    tnc.helpSupport = helpSupport;
    tnc.UserWorkspace = UserWorkspace;
    tnc.Canny = cannyService;
    tnc.Auth = Auth;
    tnc.breadcrumb_list = [];
    tnc.breadcrumb_items = {};
    tnc.navigationService = navigationService;
    tnc.adding_ws_loader = false;
    tnc.add_dataview = add_dataview;
    tnc.update_resources = resources.update;
    tnc.rename_ws = rename_ws;
    tnc.delete_ws = delete_ws;
    tnc.reset_ws = reset_ws;
    tnc.snooze_ws = snooze_ws;
    tnc.unsnooze_ws = unsnooze_ws;
    tnc.viewPendingUpdates = viewPendingUpdates;
    tnc.saveTemplateConfig = TemplateService.saveConfig;
    tnc.prepareForExportConfigSubmission = TemplateService.prepareWsForExport;
    tnc.handleExportConfigSubmission = handleExportConfigSubmission;
    tnc.manageUsers = manageUsers;
    tnc.NotificationService = NotificationService;
    tnc.refreshAndSync = refreshAndSync;
    tnc.appHelperService = appHelperService;
    tnc.isHelperMenuTooltipVisible = false;
    tnc.showDataViewMenu = false;
    tnc.dataViewsWidthOverflowing = false;
    tnc.toggleDataViewMenu = toggleDataViewMenu;
    tnc.openBillingPage = openBillingPage;
    tnc.guidedTourShownAlready = true;
    tnc.$state = $state;
    tnc.scope = $scope;
    tnc.contactSupport = openContactSupport;
    tnc.DataviewService = DataviewService;
    tnc.resources = resources.resourcesMap;
    tnc.$store = $store;
    tnc.landingList = landingList;

    tnc.onPopulateBreadcrumbEvent = new eventCallbackManagerFactory('onPopulateBreadcrumbEvent');
    $window.onfocus = onFocus;

    resources.onUpdate('fromTopNavCtrl', handleResourceUpdate);

    function openContactSupport() {
      initSupportApp(UserWorkspace.selfDetails.email, UserWorkspace.selfDetails.name);
      openSupportApp()
    }

    function handleResourceUpdate() {

      // Update vue aux store with tabs data
      if (tnc.breadcrumb_list.length) {
        // Set vue tabs variable value
        const currentViewId = tnc.breadcrumb_items.dataview.item.id
        const dataset = $store.getters['resources/getDatasetByViewId'](currentViewId) || {}
        const tabs = dataset.properties.viewsIds?.map((viewId) => {
          let view = $store.getters['resources/getViewByViewId'](viewId)
          view.isActive = viewId == currentViewId
          return view
        })

        $store.commit('auxNavbar/setTabs', tabs)
      }
    }

    function toggleDataViewMenu() {
      $timeout(function () {
        tnc.showDataViewMenu = !tnc.showDataViewMenu;
      });
    }

    tnc.workspaceDetail = new (function workspaceDetail() {
      var self = this;
      self.toggle = function (val) {
        if (val != false) {
          self.isOpen = !self.isOpen;
        } else {
          self.isOpen = false;
        }
      };
    })();

    var check_and_show_locked = false;
    var debounced_check_and_show_onboarding = utils.debounce(check_and_show_onboarding, 2000, false);
    UserWorkspace.on_ui_preferences_update('tnc_listener', debounced_check_and_show_onboarding);


    const Report = $resource(config.api.publish_report)
    UserWorkspace.on_workspace_select('reports_updated', function () {
      const indexOfPublish = window.location.href.split('/').indexOf('publish')
      if (indexOfPublish != -1) {
        let reportID = window.location.href.split('/')[indexOfPublish + 1]
        Report.get({reportId: reportID}).$promise.then(function (reportData) {
          tnc.breadcrumb_items['report'] = {
            name: reportData.name,
            item: reportData
          };
        })
      }
    })

    debounced_check_and_show_onboarding();
    $rootScope.$on('$stateChangeSuccess', debounced_check_and_show_onboarding);
    $timeout(tnc.onPopulateBreadcrumbEvent.fire_event, 2000);

    appHelperService.onOnboardingEnd('tncFlashTooltip', function () {
      if (!tnc.isHelperMenuTooltipVisible) {
        $timeout(function () {
          tnc.isHelperMenuTooltipVisible = true;
        });
        $timeout(function () {
          tnc.isHelperMenuTooltipVisible = false;
        }, 2000);
      }
    });

    $scope.$on('populate_breadcrumbs',
      function () {
        populate_breadcrumbs($state.current.name, $state.params);
        handleResourceUpdate();
      });
    DatasourceService.on_list_update('update_top_nav_controller', _on_ws_list_updates);

    function _setPageTitle() {
      var datasourceName = utils.decodeSanitizedName(tnc.breadcrumb_items['datasource'].name);
      var dataviewName = utils.decodeSanitizedName(tnc.breadcrumb_items['dataview'].name);
      var pageTitle = datasourceName + ' - ' + dataviewName;
      appMetaInfo.prefixPageTitleWith(pageTitle);
    }

    function identifyNewWs(datasource) {
      let firstTime = wsInMemory.length == 0;
      angular.forEach(_.get(datasource, 'dataviews'), function (w) {
        if (firstTime) {
          wsInMemory.push(w.id);
        } else {
          if (wsInMemory.indexOf(w.id) == -1) {
            w._freshlyCreatedAsPerTopNav = true;
            $timeout(function () {
              w._freshlyCreatedAsPerTopNav = false;
            }, 3000);
            wsInMemory.push(w.id);
          }
        }
      });
    }

    function populate_breadcrumbs(toState, toParams) {
      if (toState === config.states.dataview) {
        var ws = DataviewService.get_by_id(toParams.dataviewId);
        // add datasource
        if (tnc.breadcrumb_list.indexOf('datasource') === -1) {
          tnc.breadcrumb_list.push('datasource');
        }
        tnc.breadcrumb_items['datasource'] = {
          name: ws.datasource.name,
          item: ws.datasource
        };
        $store.commit('auxNavbar/setTitle', ws.datasource.name)
        // add dataview
        if (tnc.breadcrumb_list.indexOf('dataview') === -1) {
          tnc.breadcrumb_list.push('dataview');
        }
        tnc.breadcrumb_items['dataview'] = {
          name: ws.name,
          item: ws
        };

        // update page title
        _setPageTitle();
        identifyNewWs(ws.datasource);
      }
      tnc.onPopulateBreadcrumbEvent.fire_event();
    }

    function rename_ws(ws) {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.viewOptions.renameView, {eventOrigin: "dataview.viewOptions"});
      modalService.renameWs(ws).then(resources.update);
    }

    function manageUsers() {
      modalService.manageUsers().then(resources.update);
    }


    function delete_ws(ws) {
      var dsId = ws.datasource.id;
      modalService.deleteWs(ws, "dataview.viewOptions").then(function () {
        $timeout(function () {
          ws.deleting_ws_loader = true;
          DataviewLayoutService.dataviewSwitching();
        });
        resources.update().then(function () {
          var ds = DatasourceService.list[dsId];
          var _ws = ds.dataviews[Object.keys(ds.dataviews)[0]];
          DataviewLayoutService.unset_active_ws(ws.id)
          navigationService.open_dataview(_ws, _ws);
          if (ws) {
            //Since $promise has returned success, irrespective of whether deletion was
            //actually successful or not, there is no need to show loader anymore.
            ws.deleting_ws_loader = false;
          }
        });
      });
    }

    function snooze_ws(ws) {
      var inbound_updates_resource = $resource(config.api.inboundUpdates, {ws_id: '@ws_id'});
      inbound_updates_resource.save({ws_id: ws.id}, {'disable': true}).$promise.then(successCallback, failureCallback);

      function successCallback() {
        resources.update();
      }

      function failureCallback() {

      }
    }

    function unsnooze_ws(ws) {
      var inbound_updates_resource = $resource(config.api.inboundUpdates, {ws_id: '@ws_id'});
      inbound_updates_resource.save({ws_id: ws.id}, {'disable': false}).$promise.then(successCallback, failureCallback);

      function successCallback() {
        resources.update();
      }

      function failureCallback() {

      }

    }

    function viewPendingUpdates(ws) {
      let pipelineResource = $resource(config.api.pipelineInfo, {ws_id: '@ws_id', inbound_updates: '@inbound_updates'});
      pipelineResource.get({ws_id: ws.id, inbound_updates: true}).$promise.then(function (response) {
        ws.inbound_updates_contexts = response['inbound_updates_contexts']
        ws.inbound_updates_pending = response['inbound_updates_pending']
        ws.inbound_updates_enabled = response['inbound_updates_enabled']
        modalService.viewPendingUpdates(ws).then(resources.update);
      })
    }

    function reset_ws(ws) {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.viewOptions.resetView, {eventOrigin: "dataview.viewOptions"});
      modalService.resetWs().then(function () {
        dataviewSafeDeleteResetService.reset(ws.id).then(function () {
          ws.update({display_properties: {}});
          resources.update();
        });
      });
    }

    function add_dataview(ds, copy_from_wksp_id, dataview_config) {
      let _derigister = DataviewService.on_list_updates('add_ws_wait_' + ds.id, _resourceUpdateCallback);
      var dsId = ds.id;
      var existingDataviews = Object.keys(ds.dataviews);
      $timeout(function () {
        tnc.adding_ws_loader = true;
      });
      DatasourceService.add_dataview(ds, copy_from_wksp_id, dataview_config).then(
        resources.update, _failureCallback);

      function derigister() {
        if (_derigister) {
          _derigister();
          _derigister = null;
        }
      }

      function _failureCallback(data) {
        tnc.adding_ws_loader = false;
        derigister();
        if (copy_from_wksp_id) {
          modalService.duplicateWs().then(function () {
          });
        }
      }

      function _resourceUpdateCallback() {
        var ds = DatasourceService.list[dsId], _ws;
        angular.forEach(ds.dataviews, function (ws, ws_id: any) {
          if (existingDataviews.indexOf(ws_id) == -1) {
            _ws = ws;
            tnc.adding_ws_loader = false;
          }
        });
        if (_ws) {
          navigationService.open_dataview(_ws, _ws);
          derigister();
        }
      }
    }

    function _on_ws_list_updates() {
      if (tnc.breadcrumb_items.datasource) {
        if (tnc.breadcrumb_items.datasource.name != tnc.breadcrumb_items.datasource.item.name) {
          tnc.breadcrumb_items.datasource.name = tnc.breadcrumb_items.datasource.item.name;
          $store.commit('auxNavbar/setTitle', tnc.breadcrumb_items.datasource.item.name)
          _setPageTitle();
        }

        var ds = DatasourceService.list[tnc.breadcrumb_items.datasource.item.id];
        var wsId = tnc.breadcrumb_items.dataview.item.id;
        if (ds && !ds.dataviews.hasOwnProperty(wsId)) {
          var _ws = ds.dataviews[Object.keys(ds.dataviews)[0]];
          navigationService.open_dataview(_ws, _ws);
        }
        identifyNewWs(ds);
      }
      debounced_check_and_show_onboarding();
    }

    tnc.Breadcrumbs = [];

    tnc.Notifications = ["notif 1", "notif 2", "notif 3"];
    tnc.workspaceMenuItems = [
      {
        label: 'Logout',
        class: 'logout',
        on_click: logout
      }
    ];

    function logout() {
      analyticsService.userEventTrack(c.userEvents.userLoggedOut, {eventOrigin: "topNav.userOptions"});
      Auth.logout();
    }

    function handleExportConfigSubmission(ws, cfgJson) {
      try {
        let templateCfg = JSON.parse(cfgJson);
        TemplateService.applyConfigToWs(ws, templateCfg);
      } catch (e) {
        TemplateService.cancelWsTemplateApply(ws, true)
      }
    }

    function refreshAndSync(datasource) {
      var webhookInfo = datasource.webhookInfo;
      var unprocessed_count = webhookInfo.unprocessed_count;

      webhooks.updateChildData(datasource).then(successCb, errorCb);
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.refreshWebHook,
        {
          eventOrigin: "dataview",
        });

      function successCb(data) {
      }

      function errorCb(data) {
        webhookInfo.unprocessed_count = unprocessed_count;
        datasource.webhookInfo.unprocessed_count = unprocessed_count;
        let error_message = "Failed";
        if (data.data && data.data.ERROR_MESSAGE) {
          error_message += ": " + data.data.ERROR_MESSAGE;
        }
        toastNotification.error(error_message);
      }
    }

    function check_and_show_onboarding() {
      if (appMetaInfo.isPageLoading) {
        $timeout(check_and_show_onboarding, 1000);
        return;
      } else {
        if (_.has(UserWorkspace.ui_preferences, 'inited')) {
          const currentPage = appHelperService.getCurrentPage();
          const globalPreferences = _.get(UserWorkspace.ui_preferences, 'GLOBAL.ONBOARDING', {});
          tnc.guidedTourShownAlready = _.get(globalPreferences, currentPage, false);
          if (!tnc.guidedTourShownAlready) {
            appHelperService.startOnBoarding();
          } else {
            const draftModeGuideShownAlready = _.get(globalPreferences, 'editMode', false);
            if (currentPage == 'dataview' && !draftModeGuideShownAlready) {
              var elements = document.getElementsByClassName('active-pipeline')
              if (elements.length) {
                appHelperService.startOnBoarding('editMode');
              }
            }
          }
        }
      }
    }

    function openBillingPage() {
      navigationService.openSettings('billing');
  }


    function gotoDoc() {
      analyticsService.userEventTrack(c.userEvents.gotoDoc, {eventOrigin: "landingPage"});
    }

    function helpSupport() {
      analyticsService.userEventTrack(c.userEvents.helpSupport, {eventOrigin: "landingPage"});
    }

    function cannyFeatureRequest() {
      var eventOrigin = window.location.hash.includes("dataview") ? 'dataview' : 'landingPage';
      analyticsService.userEventTrack(c.userEvents.cannyFeatureRequestClick, {eventOrigin: eventOrigin});
    }

    function cannyChangelog() {
      var eventOrigin = window.location.hash.includes("dataview") ? 'dataview' : 'landingPage';
      analyticsService.userEventTrack(c.userEvents.cannyChangelogClick, {eventOrigin: eventOrigin});
    }

    function onFocus() {
      UserWorkspace.getSmsData();
    }
  }
}


export class OpenSupportApp implements ng.IDirective {
  link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) => {
    let UserWorkspace = this.UserWorkspace;
    $(element).on('click', function(){
      openSupportApp();
    });

    UserWorkspace.onSelfDetailsLoaded('support_app', _checkAndInit);

    function _checkAndInit(){
      let email = UserWorkspace.selfDetails.email;
      let name = UserWorkspace.selfDetails.name;
      initSupportApp(email, name);
    }
  };
  public constructor(private UserWorkspace) {
  }
  static factory(): ng.IDirectiveFactory {
    const directive = (UserWorkspace) => new OpenSupportApp(UserWorkspace);
    directive.$inject = ['UserWorkspace'];
    return directive;
  }
}
