<template>
  <mm-modal v-model="computedShowModal" :title="title" :width="424" closable>
    <restrictions-wrapper>
      <ul name="modal-list" class="p-l-0 m-t-6 m-b-5" style="list-style: none">
        <li class="export-share-item" v-for="(item, itemIndex) in items" :key="itemIndex">
          <div class="d-flex">
            <div class="d-flex-col flex-grow-1 m-r-4">
              <span class="mm-text--h300" v-text="item.title" />
              <span class="m-t-2" v-text="item.description" />
            </div>
            <mm-tooltip :label="item.tooltip || ''" :disabled="!item.tooltip">
              <div class="d-flex align-items-center h-100">
                <mm-button
                  class="m-y-auto flex-shrink-0"
                  :label="$t('dictionary.select')"
                  :loading="item.loading"
                  :disabled="item.disabled"
                  size="medium"
                  objective="secondary"
                  noFocusOnClick
                  @click="$emit('itemClick', item)"
                />
              </div>
            </mm-tooltip>
          </div>
          <mm-divider v-if="itemIndex < items.length - 1" class="m-y-5" />
        </li>
      </ul>
    </restrictions-wrapper>
  </mm-modal>
</template>

<script>
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

export default {
  name: 'action-bar-export-list-modal',
  components: { RestrictionsWrapper },
  props: {
    value: Boolean,
    title: String,
    items: {
      type: Array,
      default: () => [
        // {
        //   id: String,
        //   title: String,
        //   description: String,
        //   loading: Boolean,
        //   tooltip: String,
        //   disabled: Boolean
        // }
      ]
    }
  },
  computed: {
    computedShowModal: {
      get() {
        return this.value
      },
      set(showModal) {
        this.$emit('input', showModal)
      }
    }
  }
}
</script>
