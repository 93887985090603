<template>
  <mm-avatar-group
    v-if="usersViewingView.length > 0"
    :currentUserId="$store.state.user.id"
    :users="usersViewingView"
    combinedTooltipExpression="data_editor.data_grid.avatar_group.aggregate_message"
    maxUsersInTooltipExpression="data_editor.data_grid.avatar_group.max_users_message"
    small
  />
</template>

<script>
import dataEditorAPI from '@/modules/data-editor/api/data-editor.api'

export default {
  data() {
    return {
      getViewingUsersTimeout: null,
      usersViewingView: []
    }
  },
  async mounted() {
    const getViewingUsers = async () => {
      clearTimeout(this.getViewingUsersTimeout)

      if (document.hidden) return

      const response = await dataEditorAPI.getUsersViewingView(this.$store.state.dataEditor.viewId)
      if (!response) this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })

      this.usersViewingView = response

      this.getViewingUsersTimeout = setTimeout(getViewingUsers, 60000)
    }

    document.addEventListener('visibilitychange', getViewingUsers)
    getViewingUsers()
  },
  beforeDestroy() {
    removeEventListener('visibilitychange')
  }
}
</script>
