// Dependencies
import axios from 'axios'
//Store
import store from '@/plugins/store'
import keycloak from '@/plugins/keycloak'

//const vueInstance = window.STORYBOOK_ENV ? {} : require('../main')
//TODO remove when angular code is gone
const baseURL = process.env.VUE_APP_API_URL || `${window.location.origin}/api/v1`

let vueInstance

// Axios instance
const apiInstance = axios.create({
  baseURL,
  timeout: 30000,
  withCredentials: true
})

// Response interceptors
apiInstance.interceptors.response.use(
  // For success responses
  async (response) => {
    if (!response.fromCache && response.config.cacheable) {
      const cacheValue = {
        cacheData: response.data,
        cacheAge: response.config.cacheAge,
        cacheUpdateAt: Date.now()
      }
      localStorage.setItem(response.config.cacheKey, JSON.stringify(cacheValue))
    }
    return response
  },
  // For error responses
  async ({ response, config }) => {
    // If user is unauthorized redirect to login
    if (response?.status === 401) {
      vueInstance?.default.$store.dispatch('auth/logout')
      vueInstance?.default.$store.commit('reset')
      vueInstance?.default.$router.push({ name: 'Login' })
      return Promise.reject(response)
    }

    // customErrorHandling defines if a request has a custom way of handling error messages
    // e.g., output error message on text input
    if (config?.customErrorHandling) return Promise.reject(response || { error: 'global.api.network_error' })

    let errorMessage = vueInstance?.default.$t(response ? 'global.api.generic_error' : 'global.api.network_error')

    if (response?.status === 403) {
      errorMessage = vueInstance?.default.$t('global.api.unauthorized_error')
    }

    // Display error message the default way (toast)
    vueInstance?.default.$toast.show(errorMessage)
    return response
  }
)

// Request interceptors
apiInstance.interceptors.request.use(
  // For success
  async (config) => {
    // TODO remove when angular is gone, use VueRouter capabilities to verify path
    if (config.url.endsWith('/change-password') || config.url.endsWith('/reset-password')) return config

    await keycloak.updateToken(30)
    // eslint-disable-next-line require-atomic-updates
    config.headers.Authorization = `bearer ${keycloak.token}`
    if (config.cacheable) {
      const cachedResponse = JSON.parse(localStorage.getItem(config.cacheKey))
      if (cachedResponse) {
        // Checking if the cacheed value is still valid, otherwise we remove it
        if (Date.now() - cachedResponse.cacheUpdateAt < cachedResponse.cacheAge && !config.invalidateCache) {
          // Read about adapter here: https://mammoth.atlassian.net/l/cp/LB8q6xHD
          config.adapter = () => {
            return Promise.resolve({
              config,
              data: cachedResponse.cacheData,
              fromCache: true
            })
          }
        } else {
          localStorage.removeItem(config.cacheKey)
        }
      }
    }
    return config
  },
  // For error
  (error) => Promise.reject(error)
)

// TODO when angular is fully migrated, this can be removed. Check main.js
//Set up headers for api calls
let workspaceId = store.state?.workspaceId
// Following is needed for the admin page
if (workspaceId == -1) workspaceId = 1
apiInstance.defaults.headers['X-WORKSPACE-ID'] = workspaceId

export default apiInstance
