
import * as dataviewConfig from  './../dataview.config';
import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';
/**
 * @ngInject
 * @returns {{get_manager: get_manager}}
 */

JsonExtractManagerFactory.$inject = ['c', 'utils', 'eventCallbackManagerFactory', 'config', '$resource'];
export function JsonExtractManagerFactory(c, utils, eventCallbackManagerFactory, config, $resource) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new JsonExtractManager(options);
  }

  function JsonExtractManager(options) {
    var self = this;
    var metadata = options.metadata, taskUtils = options.taskUtils, dataview = options.context.dataview;
    self.getParam = getParam;
    self.setParam = setParam;
    self.validate = validate;
    self.context = options.context;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap;
    self.sourceColumnSelected = sourceColumnSelected;
    self.handleJsonTypeChange = handleJsonTypeChange;
    self.checkIfEditParamExistsAndChangeSource = checkIfEditParamExistsAndChangeSource;
    self.editMode = false;
    self.keepSource = false;
    self.handlePasteParams = handlePasteParams;

    self.allowedJsonTypes = [
      {
        internal: 'JSON_OBJECT',
        display: 'dictionary'
      },
      {
        internal: 'JSON_LIST',
        display: 'array/list'
      }
    ];
    self.selectedJsonType = null;
    self.handler = null;
    self.suggestions = [];
    self.columns = getTextColumns();
    self.source_column = null;
    self.editCache = {
      column: null,
      param: null
    };
    self.samplesProvider = new SamplesProvider();
    let autoDetectDerigister;
    let _InternalHandlerMap = {
      JSON_OBJECT: new JsonObjectHandler(),
      JSON_LIST: new JsonListHandler()
    };

      function getTextColumns () {
        var columns = [];
        metadata.forEach(function (col) {
          if (col.type == c.text) {
            columns.push(utils.sanitizeDataWithType(col));
          }
        });
      return columns;
    }
    function handleJsonTypeChange() {
      self.handler = _InternalHandlerMap[self.selectedJsonType];
    }

    function autoDetectType() {
      if(self.editMode){
        return;
      }
      let suggestion = self.samplesProvider.getSuggestions();
      if (suggestion.JSON_LIST.occurrences > suggestion.JSON_OBJECT.occurrences) {
        self.selectedJsonType = 'JSON_LIST';
      }
      else {
        self.selectedJsonType = 'JSON_OBJECT';
      }
      handleJsonTypeChange();
      if(autoDetectDerigister){
        autoDetectDerigister();
      }
    }

    function validate() {
      if (!self.source_column) {
        self.error = "Select a source column";
        return false;
      }
      if (self.handler && !self.handler.validate()) {
        self.error = "Handler validation error";
        return false;
      }
      return true;
    }

    function checkIfEditParamExistsAndChangeSource(){
      if(self.editCache.column && self.source_column.internal_name == self.editCache.column){
        self.setParam(self.editCache.param);
      }
      else{
        if(self.handler){
          self.handler.reset();
        }
        sourceColumnSelected()
      }
    }

    function sourceColumnSelected(noLoad=false) {
      taskUtils.highlight.sources();
      autoDetectDerigister = self.samplesProvider.onSamplesReady('from_main', autoDetectType);
      self.samplesProvider.loadWsData(self.source_column.internal_name);
    }

    function handlePasteParams(taskInfo){
      var param = taskInfo.params
      /** Update source params with suitable replacement columns, based on display name*/
      utils.metadata.replaceMatchingColumnAndUpdateMetadata(param.JSON_HANDLE, 'SOURCE', taskInfo, metadata, self.displayNameAndTypeToColumnMap);
      self.columns = getTextColumns()
      return param
    }

    function getParam() {
      let handlerParam = self.handler.getParam();
      handlerParam.SOURCE = self.source_column.internal_name;
      handlerParam.TYPE = self.selectedJsonType;
      handlerParam.JSON_KEEP_SOURCE = self.keepSource;
      var params = {
        JSON_HANDLE: handlerParam
      }
      if (self.context.hasOwnProperty('sequence')){
        params['SEQUENCE_NUMBER'] = self.context['sequence']
      }
      return params
    }

    function setParam(param) {
      var sequence_number = null;
      self.editMode = options.context.hasOwnProperty('task') && options.context.task && options.context.task.hasOwnProperty('sequence');
      if (self.editMode) {
        sequence_number = options.context.task.sequence;
        self.samplesProvider.sequence_number = sequence_number;

        var src_internal_name = param.JSON_HANDLE.SOURCE
        var sourceColumnInternalNames = _.map(self.columns,'internal_name')
        if(sourceColumnInternalNames.indexOf(src_internal_name) == -1){
          var src_col = utils.metadata.get_column_by_internal_name(metadata, src_internal_name)
          self.columns.push(src_col)
        }
      }
      self.source_column = utils.metadata.get_column_by_internal_name(metadata, param.JSON_HANDLE.SOURCE);
      self.editCache.column = param.JSON_HANDLE.SOURCE;
      self.editCache.param = param;
      self.selectedJsonType = param.JSON_HANDLE.TYPE;
      handleJsonTypeChange();
      self.handler.reset();
      self.handler.setParam(param.JSON_HANDLE);
      self.keepSource = param.JSON_HANDLE.JSON_KEEP_SOURCE || false;
      taskUtils.highlight.sources(self.source_column);
    }

    function _getExistingNames() {
      let existingNames = [];
      for (let i = 0; i < options.metadata.length; i++) {
        existingNames.push(options.metadata[i].display_name);
      }
      return existingNames;
    }

    function _getNewName(existingNames, prefix) {
      prefix = utils.string.capitalize(prefix);

      /*
      Match a character not present in the [^\u0000-\uFFFF]+ (unicode index from 0 to 65535)
      and replaces it with empty character.
      */
      let newName = prefix.replace(/[^\u0000 -\uFFFF]+/g, '');

      if (existingNames.indexOf(newName) != -1) {
        newName = self.source_column.display_name + ' ' + prefix;
      }
      let scaffold = newName;
      let x = 2;
      while (existingNames.indexOf(newName) != -1) {
        newName = scaffold + ' ' + x;
        x++;
      }
      if (newName.match(/^\d/)){
        newName = 'Key ' + newName;
      }
      return newName;
    }

    function JsonListHandler(){
      var jh = this;
      self.samplesProvider.onSamplesReady('from_list_handler', handleSourceChange);

      jh.jsonListOpsAllowed = [{
        display: 'rows',
        internal: 'JSON_LIST_TO_ROWS'
      }, {
        display: 'columns',
        internal: 'JSON_LIST_TO_COLUMNS'
      }];
      jh.selectedJsonListOp = 'JSON_LIST_TO_ROWS';
      jh.name = null;
      jh.totalColumns = 1;
      jh.colSpec = {
        JSON_LIST_TO_ROWS: [],
        JSON_LIST_TO_COLUMNS: []
      };
      jh.resultType = 'TEXT';
      jh.range = [];
      for(var i=0; i<19; i++){
        jh.range.push(i + 1)
      }
      jh.prefix = 'Item';

      jh.setParam = setParam;
      jh.getParam = getParam;
      jh.validate  = validate;


      jh.reset = reset;

      function reset(){
        jh.name = null;
        jh.totalColumns = 1;
        jh.colSpec = {
          JSON_LIST_TO_ROWS: [],
          JSON_LIST_TO_COLUMNS: []
        };
        jh.resultType = 'TEXT';
        jh.range = [];
        for(var i=0; i<19; i++){
          jh.range.push(i + 1)
        }
        jh.selectedJsonListOp = 'JSON_LIST_TO_ROWS';
      }

      function setParam(json_handle_param){
        jh.selectedJsonListOp = json_handle_param.JSON_LIST_OP_TYPE;
        jh.totalColumns = json_handle_param.JSON_EXTRACT.length;
        jh.colSpec[jh.selectedJsonListOp] = json_handle_param.JSON_EXTRACT;
        jh.resultType = json_handle_param.JSON_EXTRACT[0].TYPE;
      }

      function getParam(){
        let existingNames = _getExistingNames();
        let colSpec: any = [];
        if(jh.selectedJsonListOp == 'JSON_LIST_TO_COLUMNS'){
          while(colSpec.length < jh.totalColumns){
            if(colSpec.length < jh.colSpec.JSON_LIST_TO_COLUMNS.length){
              colSpec.push(jh.colSpec.JSON_LIST_TO_COLUMNS[colSpec.length]);
            }
            else{
              let newDisplayName = _getNewName(existingNames,
                    self.source_column.display_name + ' ' +colSpec.length);
              let cspec = {
                COLUMN: newDisplayName,
                INTERNAL_NAME: ('json_item_' + utils.string.random(5)).toLowerCase(),
                TYPE: jh.resultType,
                INDEX: colSpec.length
              };
              colSpec.push(cspec);
            }
            existingNames.push(colSpec.display_name);
          }
        }
        else if (jh.selectedJsonListOp == 'JSON_LIST_TO_ROWS'){
          let isValidSpec = false;
          if(self.editMode){
            if(jh.colSpec.length == 2){
              if(jh.colSpec[0]._IS_ITEM && jh.colSpec[1]._IS_INDEX){
                colSpec = jh.colSpec.JSON_LIST_TO_ROWS;
                isValidSpec = true;
              }
            }
          }
          if (!isValidSpec){
            let indexDisplayName = _getNewName(existingNames, 'index');
            let itemDisplayName = _getNewName(existingNames, 'item');
            colSpec = [{
                COLUMN: itemDisplayName,
                INTERNAL_NAME: ('json_item_' + utils.string.random(5)).toLowerCase(),
                TYPE: jh.resultType,
                _IS_ITEM: colSpec.length
              }, {
              COLUMN: indexDisplayName,
              INTERNAL_NAME: ('json_index_' + utils.string.random(5)).toLowerCase(),
              TYPE: 'NUMERIC',
              _IS_INDEX: colSpec.length
            }];
          }
        }
        return {
          TYPE: "JSON_LIST",
          JSON_LIST_OP_TYPE: jh.selectedJsonListOp,
          JSON_EXTRACT: colSpec
        }
      }

      function validate(){
        if(jh.selectedJsonListOp == "JSON_LIST_TO_COLUMNS"){
          return jh.totalColumns > 0
        }
        return true;
      }

      function handleSourceChange(){
        if(self.source_column){
          let suggestion = self.samplesProvider.getSuggestions();
          if(suggestion.JSON_LIST.occurrences > 0){
            if(suggestion.JSON_LIST.maxLength < 20){
              jh.totalColumns = suggestion.JSON_LIST.maxLength;
            }
            else{
              jh.totalColumns = 15;
            }

            jh.resultType = suggestion.JSON_LIST.item_type;
          }
        }
      }
    }

    function JsonObjectHandler(){
      var jh = this;

      jh.keys = [];
      jh.keyMap = {};
      jh.objToRowColSpec = null;
      jh.objValueType = 'TEXT';
      jh.selectedJsonObjectOp = 'JSON_OBJECT_TO_COLUMNS';

      self.samplesProvider.onSamplesReady('from_object_handler', handleSourceChange);

      jh.jsonObjectOpsAllowed = [{
        display: 'rows',
        internal: 'JSON_OBJECT_TO_ROWS'
      }, {
        display: 'columns',
        internal: 'JSON_OBJECT_TO_COLUMNS'
      }];


      jh.addKey = addKey;
      jh.removeKey = removeKey;
      jh.setParam = setParam;
      jh.getParam = getParam;
      jh.validate  = validate;
      jh.regenKeys = regenKeys;
      jh.reset = reset;


      function reset(){
        jh.keys = [];
        jh.keyMap = {};
        jh.objToRowColSpec = null;
        jh.objValueType = 'TEXT';
        jh.selectedJsonObjectOp = 'JSON_OBJECT_TO_COLUMNS';
      }


      function setParam(json_handle_param){
        if (json_handle_param.JSON_OBJECT_OP_TYPE == 'JSON_OBJECT_TO_ROWS'){
          jh.selectedJsonObjectOp = 'JSON_OBJECT_TO_ROWS';
          jh.objToRowColSpec = json_handle_param.JSON_EXTRACT;
          jh.objValueType = json_handle_param.JSON_EXTRACT[1].TYPE;
        }
        else{
          jh.selectedJsonObjectOp = 'JSON_OBJECT_TO_COLUMNS';
          jh.keys = [];
          jh.keyMap = {};
          let json_exract_param = json_handle_param.JSON_EXTRACT;
          for(let i=0; i < json_exract_param.length; i++){
            let colspec = json_exract_param[i];
            addKey(colspec.KEY, colspec.TYPE, colspec.INTERNAL_NAME);
          }
        }
      }

      function regenKeys(){
        self.samplesProvider.loadWsData(self.source_column.internal_name);
      }

      function getParam(){
        let colSpecs = [];
        let existingNames = _getExistingNames();
        if (jh.selectedJsonObjectOp == 'JSON_OBJECT_TO_ROWS'){
          if(jh.objToRowColSpec){
            colSpecs = jh.objToRowColSpec;
          }
          else{
            let keyDisplayName = _getNewName(existingNames, 'key');
            let valueDisplayName = _getNewName(existingNames, 'value');
            colSpecs = [{
              COLUMN: keyDisplayName,
              INTERNAL_NAME: ('json_key_' + utils.string.random(5)).toLowerCase(),
              TYPE: "TEXT",
              _IS_KEY: true
            }, {
              COLUMN: valueDisplayName,
              INTERNAL_NAME: ('json_index_' + utils.string.random(5)).toLowerCase(),
              TYPE: jh.objValueType,
              _IS_INDEX: true
            }];
          }
        }
        else{
          for (let i=0; i < jh.keys.length; i++){
            let key = jh.keys[i];
            let underscoreKey = '____' + key;
            if(!jh.keyMap[underscoreKey].COLUMN){
              let prefix = key;
              if(prefix.match(/^\d/)){
                prefix = "key " + prefix;
              }
              let newDisplayName = _getNewName(existingNames, prefix);
              // if display_name uses any of the reserved column names, rename it
              if(c.RESERVED_BATCH_COLUMN_NAMES.findIndex(item => newDisplayName.toLowerCase() === item.toLowerCase()) != -1){
                newDisplayName = _getNewName(existingNames.concat(newDisplayName), newDisplayName);
              }
              existingNames.push(newDisplayName);
              jh.keyMap[underscoreKey].COLUMN = newDisplayName;
            }
            jh.keyMap[underscoreKey].KEY = key;
            colSpecs.push(jh.keyMap[underscoreKey]);
          }
        }
        return {
          TYPE: 'JSON_OBJECT',
          JSON_OBJECT_OP_TYPE: jh.selectedJsonObjectOp,
          JSON_EXTRACT: colSpecs
        }
      }

      function validate(){
        if(jh.selectedJsonObjectOp == 'JSON_OBJECT_TO_ROWS'){
          return true;
        }
        else{
          return jh.keys.length > 0;
        }
      }

      function addKey(val, ctype, internalName=null){
        if(!ctype){
          ctype = 'TEXT';
        }
         if(jh.keys.indexOf(val) == -1){
           jh.keys.push(val);
           if(!jh.keyMap['____' + val]){
             if(internalName === null){
               internalName = "key_"+ utils.string.random(10).toLowerCase();
             }
             jh.keyMap['____' + val] = {TYPE: ctype, INTERNAL_NAME: internalName};
           }
         }
      }

      function removeKey(item){
        let index = jh.keys.indexOf(item);
        jh.keys.splice(index, 1);
      }

      function handleSourceChange(){
        if(self.source_column){
          let knowledge = self.samplesProvider.getSuggestions();
          if(knowledge.JSON_OBJECT.occurrences && knowledge.JSON_OBJECT.keys.length){
            if(!angular.isArray(knowledge.JSON_OBJECT.keys)){
              knowledge.JSON_OBJECT.keys = [];
            }
            let samples = knowledge.JSON_OBJECT.keys.sort();
            let types = knowledge.JSON_OBJECT.types;
            for(let i = 0; i < samples.length; i++){
              let suggestion = samples[i];
              let dataType = "TEXT";
              if(types[suggestion]){
                dataType = types[suggestion];
              }
              jh.addKey(suggestion, dataType);
            }
          }
        }
      }
    }

    function SamplesProvider(){
      let sp = this;
      let SuggestionResource = new $resource(config.api.suggestions);
      let sampleEvent = new eventCallbackManagerFactory('samplesProvider');
      let suggestions = _emptySuggestion();

      sp.loadingWsData = true;
      sp.onSamplesReady = sampleEvent.add_callback;

      sp.loadWsData = loadWsData;
      sp.getSuggestions = getSuggestions;

      function loadWsData(internal_name){
        let request = {
          dataview_id: dataview.id,
          internal_name: internal_name,
          sequence_number: sp.sequence_number - 1,
          type: "json-suggest"
        };
        SuggestionResource.save({}, request).$promise.then(_successCb)
      }

      function _successCb(data){
        suggestions = data.suggestions;
        if(suggestions){
          sampleEvent.fire_event();
        }
      }

      function _emptySuggestion() {
        return {
          JSON_OBJECT: {
            keys: [],
            types: {},
            occurrences: 0
          },
          JSON_LIST: {
            maxLength: 0,
            item_type: "TEXT",
            occurrences: 0
          }
        }
      }

      function getSuggestions(){
        return suggestions;
      }
    }
  }
}



valJsonCol.$inject = ['utils'];
export function valJsonCol(utils) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valJsonCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valJsonCol = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue){
          if( modelValue.hasOwnProperty('error')){
            is_valid =  false
          }
          else if( modelValue.type != 'TEXT'){
            is_valid =  false
          }
        }
        return is_valid
      };
    }
  };
}

/**
 * @ngInject
 * For validating if the small or large para is right
 */

shouldBeValidJsonKeyExtraction.$inject = ['utils'];
export function shouldBeValidJsonKeyExtraction(utils){
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, e, a, ctrl){
      ctrl.$validators.shouldBeValidJsonKeyExtraction = function(mV, vV){
        return mV > 0;
      }
    }
  }
}
