<template>
  <mm-menu v-model="isMenuOpen" shadowClass="mm-shadow--heavy-low" :closeOnContentClick="false">
    <template #activator="{ on }">
      <restrictions-wrapper validate-storage-limit>
        <action-bar-button
          class="action-bar-button--combine"
          :activated="isMenuOpen"
          :label="$t('dictionary.combine')"
          :tooltipLabel="computedTooltipLabel"
          :disabled="!!computedTooltipLabel.length"
          icon="combine"
          v-on="on"
        />
      </restrictions-wrapper>
    </template>
    <template #content>
      <mm-list class="p-a-3" style="min-width: 145px" :items="combineItems" @itemClick="onItemClick">
        <template #item="{ item }">
          <label class="flex-grow-1 cursor-pointer" v-text="item.label" />
        </template>
      </mm-list>
    </template>
  </mm-menu>
</template>

<script>
//Components
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'
import ActionBarButton from '../action-bar-button/action-bar-button.vue'

// Constants
import { USER_EVENTS } from '@/constants'

export default {
  name: 'action-bar-combine',
  components: { ActionBarButton, RestrictionsWrapper },
  data: () => ({
    isMenuOpen: false
  }),
  computed: {
    computedTooltipLabel() {
      if (this.$store.state.dataEditor.isPreview) return this.$t(`action_bar.combine.preview_tooltip`)
      return ''
    }
  },
  created() {
    this.combineItems = [
      {
        label: this.$tc('global.dictionary.branch_out'),
        action: 'branchout'
      },
      {
        label: this.$t('global.dictionary.branch_out_to_project'),
        action: 'export_to_project'
      },
      {
        label: this.$tc('global.dictionary.lookup'),
        action: 'lookup'
      },
      {
        label: this.$tc('global.dictionary.join'),
        action: 'join'
      }
    ]
  },
  methods: {
    onItemClick({ item }) {
      this.$root.legacyHandlers.openTaskOrActionPanel(item.action)
      this.isMenuOpen = false
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COMBINE_ITEM_CLICK, {
        viewId: this.$store.state.dataEditor.viewId
      })
    }
  }
}
</script>
