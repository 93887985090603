<template>
  <mm-modal
    v-model="computedShowModal"
    contentClass="action-bar-export-existing-credentials"
    :title="
      $t('action_bar.export.export_to_database.existing_database.credentials.modal_title', {
        database: selectedDatabase.name
      })
    "
    :width="485"
    closable
  >
    <p class="mm-text--caption-regular d-flex align-center">
      <mm-icon class="m-r-2" name="information" />
      {{ $t('action_bar.export.export_to_database.existing_database.credentials.whitelist_note') }}
    </p>

    <mm-form v-model="isFormValid" ref="form">
      <div class="action-bar-credentials-row">
        <mm-text-input
          v-model="credentials.host"
          class="flex-grow-1"
          :title="$t('global.dictionary.host')"
          :placeholder="$t('global.dictionary.host')"
          :validations="[$validators.required]"
          hideDetails
        />
        <mm-text-input
          v-model="credentials.port"
          class="action-bar-credentials-port"
          :title="$t('global.dictionary.port')"
          :placeholder="selectedDatabase.defaultPort.toString()"
          :validations="[$validators.required]"
          hideDetails
        />
      </div>
      <div class="action-bar-credentials-row">
        <mm-text-input
          v-model="credentials.username"
          :title="$t('global.dictionary.username')"
          :placeholder="$t('global.dictionary.username')"
          :validations="[$validators.required]"
          hideDetails
        />
        <mm-text-input
          v-model="credentials.password"
          type="password"
          :title="$t('global.dictionary.password')"
          :placeholder="$t('global.dictionary.password')"
          :validations="[$validators.required]"
          hideDetails
        />
      </div>
      <div class="action-bar-credentials-row" v-if="!computedIsElasticsearch">
        <mm-text-input
          v-model="credentials.database"
          :title="$t('global.dictionary.database')"
          :placeholder="$t('global.dictionary.database')"
          :validations="[$validators.required]"
          hideDetails
        />
        <mm-text-input
          v-model="credentials.table"
          :title="$t('action_bar.export.export_to_database.existing_database.credentials.table_name')"
          :placeholder="$t('action_bar.export.export_to_database.existing_database.credentials.table_name_optional')"
          hideDetails
        />
      </div>
      <div class="action-bar-credentials-row" v-if="computedIsElasticsearch">
        <mm-text-input
          v-model="elasticsearch.batchSize"
          :title="$t('action_bar.export.export_to_database.existing_database.credentials.batch_size')"
          :placeholder="$t('action_bar.export.export_to_database.existing_database.credentials.batch_size')"
          hideDetails
        />
        <mm-text-input
          v-model="elasticsearch.indexName"
          :title="$t('action_bar.export.export_to_database.existing_database.credentials.index_name')"
          :placeholder="$t('action_bar.export.export_to_database.existing_database.credentials.index_name')"
          hideDetails
        />
      </div>
      <div class="action-bar-credentials-row" v-if="computedIsElasticsearch">
        <mm-dropdown
          v-model="elasticsearch.connectVia"
          :items="['HTTP', 'HTTPS']"
          :title="$t('action_bar.export.export_to_database.existing_database.credentials.connect_via')"
          :width="194"
          hideDetails
        />
      </div>
    </mm-form>

    <!-- Include hidden columns -->
    <mm-checkbox
      v-if="computedHasHiddenColumn"
      class="m-t-5"
      v-model="includeHiddenColumns"
      :label="$t('action_bar.export.export_to_database.include_hidden_columns')"
      noFocusOnClick
    />

    <!-- Keep at the end of the pipeline -->
    <div class="d-flex align-items-center m-t-5" style="gap: 10px">
      <mm-checkbox
        v-model="keepAtPipelineEnd"
        :label="$t('action_bar.export.export_to_database.keep_at_pipeline_end.label')"
        noFocusOnClick
      />
      <mm-tooltip :label="$t('action_bar.export.export_to_database.keep_at_pipeline_end.tooltip')">
        <mm-button icon="information" size="small" objective="tertiary" noFocusOnClick />
      </mm-tooltip>
    </div>

    <!-- Validate -->
    <div class="d-flex flex-column m-t-5">
      <span>{{ $t('action_bar.export.export_to_database.existing_database.credentials.validate_message') }}</span>
      <mm-button
        class="action-bar-export-existing-credentials-validate m-t-3"
        size="medium"
        objective="secondary"
        :loading="isValidateLoading"
        :disabled="!isFormValid"
        :label="$t('dictionary.validate')"
        @click="onValidateClick"
      />
      <span
        class="m-t-3"
        :style="{ color: `var(--mm-color--${this.validateResult.isError ? 'dest800' : 'p600'})`, 'min-height': '40px' }"
        v-text="validateResult.message"
      />
    </div>

    <template #actions>
      <div class="d-flex justify-space-between width-100">
        <mm-button :label="$t('global.dictionary.back')" objective="tertiary" @click="onBackClick" />
        <mm-spacer />
        <mm-button
          class="m-r-2"
          :label="$t('dictionary.cancel')"
          objective="tertiary"
          @click="computedShowModal = false"
        />
        <mm-button
          class="m-l-2"
          :label="$t('dictionary.apply')"
          :loading="isApplyLoading"
          :disabled="!isFormValid"
          @click="onApplyClick"
        />
      </div>
    </template>
  </mm-modal>
</template>

<script>
import actionBarApi from '@/modules/data-editor/action-bar/action-bar.api'
import { EXPORT_CONNECTORS_MAP } from '@/constants'

export default {
  name: 'action-bar-export-existing-credentials',
  props: {
    value: Boolean,
    selectedDatabase: {
      type: Object,
      default: () => ({
        // name: String,
        // handler: String,
        // defaultPort: String
      })
    }
  },
  data: () => ({
    isFormValid: false,
    actionId: '',
    credentials: {
      host: '',
      port: '',
      username: '',
      password: '',
      database: '',
      table: ''
    },
    elasticsearch: {
      batchSize: 2000,
      indexName: '',
      connectVia: 'HTTPS'
    },
    validateResult: {
      isError: false,
      message: ''
    },
    keepAtPipelineEnd: true,
    includeHiddenColumns: false,
    isApplyLoading: false,
    isValidateLoading: false
  }),
  computed: {
    computedShowModal: {
      get() {
        return this.value
      },
      set(showModal) {
        this.$emit('input', showModal)
      }
    },
    computedIsElasticsearch() {
      return this.selectedDatabase.name === EXPORT_CONNECTORS_MAP.ELASTICSEARCH.name
    },
    computedHasHiddenColumn() {
      return this.$store.getters['dataEditor/columns'].some((column) => !column.show)
    }
  },
  watch: {
    computedShowModal(showModal) {
      if (!showModal) this.$nextTick(this.reset)
    },
    selectedDatabase: {
      handler(selectedDatabase) {
        this.credentials.port = selectedDatabase.defaultPort.toString()
      },
      deep: true
    }
  },
  methods: {
    async getExportToExistingDatabaseApiResponse({ onlyValidate, isEdit }) {
      const targetProperties = this.computedIsElasticsearch
        ? {
            host: this.credentials.host,
            port: this.credentials.port,
            username: this.credentials.username,
            password: this.credentials.password,
            index: this.elasticsearch.indexName,
            chunksize: this.elasticsearch.batchSize,
            connection: this.elasticsearch.connectVia.toLowerCase()
          }
        : this.credentials
      targetProperties.include_hidden = this.includeHiddenColumns

      const api = isEdit ? actionBarApi.patchExport : actionBarApi.exportToExistingDatabase
      return api({
        viewId: this.$store.getters['dataEditor/view'].viewId,
        actionId: this.actionId,
        databaseHandler: this.selectedDatabase.handler,
        targetProperties,
        // Setting sequence as null keeps action at end of the pipeline,
        sequence: this.keepAtPipelineEnd ? null : this.$store.state.pipeline.taskCount,
        onlyValidate
      })
    },
    async onValidateClick() {
      this.isValidateLoading = true
      this.validateResult.isError = false
      this.validateResult.message = ''

      const response = await this.getExportToExistingDatabaseApiResponse({ onlyValidate: true })
      if (response.status == 'error' || response.ERROR_CODE) {
        this.validateResult.isError = true
        const errorMessage = this.$te(`global.api.${response.ERROR_CODE}`)
          ? this.$t(`global.api.${response.ERROR_CODE}`)
          : response.ERROR_MESSAGE ||
            this.$t('action_bar.export.export_to_database.existing_database.credentials.validation_error')
        this.validateResult.message = errorMessage
      } else {
        this.validateResult.isError = false
        this.validateResult.message = this.$t(
          'action_bar.export.export_to_database.existing_database.credentials.validation_success'
        )
      }
      this.isValidateLoading = false
    },
    async onApplyClick() {
      this.isApplyLoading = true
      this.validateResult.isError = false
      this.validateResult.message = ''

      const response = await this.getExportToExistingDatabaseApiResponse({
        onlyValidate: false,
        isEdit: !!this.actionId
      })
      if (response.status == 'error' || response.ERROR_CODE) {
        const errorMessage = this.$te(`global.api.${response.ERROR_CODE}`)
          ? this.$t(`global.api.${response.ERROR_CODE}`)
          : response.ERROR_MESSAGE ||
            this.$t('action_bar.export.export_to_database.existing_database.credentials.apply_error')
        this.$toast.show({ content: errorMessage, status: 'error' })
      } else {
        await this.$root.legacyHandlers.actionServiceUpdateList()
        this.$root.legacyHandlers.openPipeline()
        this.computedShowModal = false
      }
      this.isApplyLoading = false
    },
    reset() {
      this.validateResult.isError = false
      this.validateResult.message = ''
      this.isApplyLoading = false
      this.isValidateLoading = false
      this.keepAtPipelineEnd = true
      this.includeHiddenColumns = false
      Object.keys(this.credentials).forEach((key) => (this.credentials[key] = ''))
      this.credentials.port = this.selectedDatabase?.defaultPort

      this.$refs.form?.formContext.formElements.forEach((formElement) => {
        if (formElement.elRef.title !== this.$t('global.dictionary.port')) formElement.clear()
      })
    },
    onBackClick() {
      this.computedShowModal = false
      this.$emit('back')
    }
  }
}
</script>

<style lang="scss">
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';

.action-bar-export-existing-credentials {
  .action-bar-credentials-row {
    display: flex;
    gap: 16px;
    margin-top: 16px;

    .mm-text-input {
      flex-basis: 194px;

      &.action-bar-credentials-port {
        flex-basis: 78px;

        .mm-text-input--text-container {
          width: 100%;
        }
      }
    }
  }

  .action-bar-export-existing-credentials-validate {
    width: 75px;
    .mm-button--text {
      @extend .mm-text--caption-regular;
    }
  }

  .mm-checkbox {
    .mm-icon {
      min-width: 12px;
    }
  }
}
</style>
