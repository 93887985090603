/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
import 'amcharts3';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/xy';
import 'amcharts3/amcharts/pie';
import 'amcharts3/amcharts/funnel';
import 'amcharts3/amcharts/plugins/responsive';
import 'amcharts3/amcharts/plugins/animate';
import { chartRenderController} from './chart.display.controller';
import {AmCharts} from 'amcharts3';

let AmCharts: AmCharts = window['AmCharts'];

/**
 * @ngInject
 */
renderChartElement.$inject = ['c', '$timeout', 'config', 'utils', 'gridFactory', 'chartThemes'];
export function renderChartElement(c, $timeout, config, utils, gridFactory, chartThemes) {

  var seriesTypeMap = {
    line: "line",
    column: "column",
    area: "line",
    scatter: "scatter",
    stackedColumn: "column"
  };

  return {
    scope: {
      element: "=element",
      big: "=",
      condition: '=',
      drilldownManager: "=",
      datastore: "="
    },
    templateUrl: config.templates.chartElementContentTemplate,
    controller: chartRenderController,
    controllerAs: 'crvm',
    bindToController: true,
    link: function (scope, ele, attributes) {
      $(ele).css('height', 'inherit');
      var chartUnit: any = {
        elem: ele,
        scope: scope

      };
      var colorsArray = chartThemes.getThemeByName(scope.crvm.element.display_properties.theme).colors;
      scope.crvm.registerRenderer(utils.debounce(render, 200));

      scope.crvm.directiveChart = chartUnit; // for debugging, delete
      ele.addClass('elem-container');
      var _bigFirst = true,
        _conditionFirst = true,
        _drilldownListenerAdded = false;
      addListeners();

      function addListeners() {
        $timeout(function () {
          scope.$watch('crvm.big', function () {
            if (_bigFirst) {
              _bigFirst = false;
              return;
            }
            ele.css('opacity', '0');
               // TODO: SENTRYERROR:FRONTEND-HQ:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/465912978
             $timeout(function () {
              render();
              ele.css('opacity', '1');
            }, 500);
          });
          scope.$watchCollection('crvm.condition', function (newValue, oldValue) {
            if (_conditionFirst) {
              _conditionFirst = false;
              return;
            }
            if(!angular.equals(newValue, oldValue)){
              scope.crvm.query();
            }
          });
        }, 200);
      }


      function render(renderOptions?) {
        var reinit = false;
        colorsArray = chartThemes.getThemeByName(scope.crvm.element.display_properties.theme).colors;
        if (renderOptions && renderOptions.displayOnly && renderOptions.display_properties) {
          if (chartUnit.display_properties && chartUnit.display_properties.chartType != renderOptions.display_properties.chartType) {
            reinit = true;
          }
          chartUnit.display_properties = renderOptions.display_properties;
        }
        else if (renderOptions && renderOptions.metadata && renderOptions.data) {
          chartUnit.metadata = renderOptions.metadata;
          chartUnit.metadataMap = _.mapKeys(renderOptions.metadata, function(col){return col['internal_name']});
          chartUnit.data = renderOptions.data;
          chartUnit.display_properties = renderOptions.display_properties;
          reinit =  true;
        }
        if(!chartUnit.display_properties){
          return;
        }
        chartUnit.type = chartUnit.display_properties.chartType;
        if (!chartUnit.metadata) {
          return;
        }
        if (!chartUnit.chart || reinit) {
              // TODO: SENTRYERROR:FRONTEND-K4:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/474979741
      // TODO: SENTRYERROR:FRONTEND-9F:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/414760104
       // TODO: SENTRYERROR:FRONTEND-6G:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/410689056
          initChart(chartUnit);
        } else {
          updateChart(chartUnit);
          renderSourceTable(chartUnit.metadata, chartUnit.data);
        }

        if (scope.crvm.datastore) {
          scope.crvm.datastore.metadata = chartUnit.metadata;
          scope.crvm.datastore.data = chartUnit.data;
          scope.crvm.datastore.display_properties = chartUnit.display_properties;
               // TODO: SENTRYERROR:FRONTEND-HQ:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/465912978
          if (chartUnit.metadata && chartUnit.metadata.length) {
            var xAxisValues = [];
            angular.forEach(chartUnit.data, function (dataItem) {
              xAxisValues.push(dataItem[chartUnit.metadata[0].internal_name]);
            });
            scope.crvm.datastore.valueSets = [{column: chartUnit.metadata[0], values: xAxisValues}];
          }
        }
        if (scope.crvm.element.display_properties.isDrilldown && scope.crvm.drilldownManager && scope.crvm.drilldownManager.visibleDrilldowns) {
          var drillitem: any = $.grep(scope.crvm.drilldownManager.visibleDrilldowns, function (dItem: any) {
            return dItem.element.id == scope.crvm.element.id;
          });
          if (drillitem.length) {
            drillitem = drillitem[0];
            drillitem.chart = chartUnit;
          }
        }
        if (!scope.crvm.element.display_properties.isDrilldown && scope.crvm.drilldownManager) {
          scope.crvm.drilldownManager.rootChart = chartUnit;
        }
      }

      function initChart(chartUnit) {
        if (!chartUnit.childDiv) {
          chartUnit.childDiv = $(chartUnit.elem).find('.chart')[0];
        }
        if(chartUnit.chart){
          chartUnit.chart.clear();
          $(chartUnit.childDiv).empty();
        }
        var chartconfig = getDefaultConfig(chartUnit)();
        chartconfig.dataProvider = chartUnit.data;
       // TODO: SENTRYERROR:FRONTEND-6G:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/410689056
        populateSeriesAndAxes(chartUnit, chartconfig);
       // TODO: SENTRYERROR:FRONTEND-6G:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/410689056
       chartUnit.chart = AmCharts.makeChart(chartUnit.childDiv, chartconfig);
        _drilldownListenerAdded = false;
        updateChart(chartUnit);
      }

      function updateChart(chartUnit) {
        chartUnit.chart.responsive.currentRules = {};
       // TODO: SENTRYERROR:FRONTEND-K4:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/474979741
        updateDisplayProperties(chartUnit);
        chartUnit.chart.dataProvider = chartUnit.data;
        populateSeriesAndAxes(chartUnit, chartUnit.chart);
        if (chartUnit.display_properties.hasOwnProperty('drilldown') && chartUnit.display_properties.drilldown.enabled && _drilldownListenerAdded == false) {
          chartUnit.chart.addListener && chartUnit.chart.addListener('clickGraphItem', handleDrilldown);
          chartUnit.chart.addListener && chartUnit.chart.addListener('pullOutSlice', handleDrilldownPie);
          _drilldownListenerAdded = true;
        } else {
          chartUnit.chart.removeListener && chartUnit.chart.removeListener('clickGraphItem', handleDrilldown);
          chartUnit.chart.removeListener && chartUnit.chart.removeListener('pullOutSlice', handleDrilldownPie);
        }
        chartUnit.chart.responsive.currentRules = {};
        chartUnit.chart.realWidth = undefined;
        chartUnit.chart.realHeight = undefined;
        chartUnit.chart.validateNow(true);
        chartUnit.chart.invalidateSize();
        $timeout(function () {
          chartUnit.chart.invalidateSize();
        }, 100);
        $timeout(function () {
          chartUnit.chart.invalidateSize();
        }, 600);

        function handleDrilldown(event) {
          var drilledValue = event.item.category;
          var xAxis = chartUnit.metadata[0];
          doDrilldown(drilledValue, xAxis);
        }

        function doDrilldown(drilledValue, column) {
          chartUnit.scope.crvm.drilldownManager.openDrilldown(chartUnit.display_properties.drilldown.default.elementId,
            [{ value: drilledValue, column: column }])
        }

        function handleDrilldownPie(data) {
          var xAxis = chartUnit.metadata[0];
          var drilledValue = data.dataItem.title;
          doDrilldown(drilledValue, xAxis);
        }
      }

      function populateSeriesAndAxes(chartUnit, config) {
        // generate series
        var yAxesMap = [];
        if (chartUnit.type == c.chartTypes.pie) {
          config.titleField = chartUnit.metadata[0].internal_name;
          config.valueField = chartUnit.display_properties.series[0].internal_name;
        } else if (chartUnit.type == c.chartTypes.funnel){
          config.titleField = "VALUE";
          config.valueField = "COUNT";
        } else {
          config.categoryField = chartUnit.metadata[0].internal_name;
          config.graphs.splice(0);
          config.valueAxes.splice(0);
          var allowStacking = [];
          var axisIndexToGraphIndexMap = {};
          angular.forEach(chartUnit.display_properties.series, function (series, i) {
            var col = chartUnit.metadataMap[series.internal_name];
            var display_name = series.name || col.display_name;
            var stacking = series.stackType || 'regular';
            var seriesCfg: any = {
              "balloonText": "[[title]]: <b>[[value]]</b>",
              "type": series.type ? seriesTypeMap[series.type] : 'line',
              "valueField": series.internal_name,
              "title": display_name,
              "bulletSize": 2,
              "bulletAlpha": 0.7,
              "bulletHitAreaSize": 20,
              "lineThickness": series.type == 'line' ? 2 : 0,
              "fillAlphas": series.type == 'line' ? 0 : 1,
              "lineAlpha": 1,
              "stackable": false,
              "columnWidth": 0.5
            };
            if (seriesCfg.type == 'line') {
              seriesCfg.bullet = 'round';
            }
            if (series.stackWith !== undefined && series.stackWith !== null && series.stackingOn) {
              allowStacking.push(series.axisIndex);
              allowStacking.push(series.stackWith);
            }
            if (chartUnit.display_properties.yAxisBreakDown && seriesCfg.type == 'column') {
              seriesCfg.stackable = true;
            }
            axisIndexToGraphIndexMap[series.axisIndex] = i;

            var axisIndex = (series.stackWith === undefined || series.stackWith === null ||  !series.stackingOn) ? ((series.axisIndex === undefined) ? i : series.axisIndex) : series.stackWith;
            var axisId = "axis" + axisIndex;
            // first group all the series descriptions that are supposed to be on the same axis,
            // then generate axis definition
            if (yAxesMap[axisIndex] == undefined) {
              yAxesMap[axisIndex] = {
                name: [display_name],
                id: axisId,
                stacking: stacking
              }
            } else {
              yAxesMap[axisIndex].name.push(display_name);
            }
            if (series.axisIndex === i) { // retain only positions that have their own axis
              yAxesMap[axisIndex].position = series.axisPosition ? series.axisPosition : (i % 2 ? 'left' : 'right') ;
            }
            if (series.showAxisLabel === false) {
              yAxesMap[axisIndex].showAxisLabel = false;
            }

            // map axis to series
            seriesCfg["valueAxis"] = axisId;
            config.graphs.push(seriesCfg);
          });
          angular.forEach(_.uniq(allowStacking), function (axisIndex) {
            var graph = config.graphs[axisIndex];
            graph.stackable = true;
          });
          // consolidate axes definitions
          angular.forEach(yAxesMap, function (axis, axisIndex) {
            var display_name;
            if(chartUnit.display_properties.yAxisBreakDown){
              display_name = chartUnit.display_properties.yAxisTitle;
            }
            else{
              display_name = _.truncate(axis.name.join(" & "));
            }
            if (!chartUnit.display_properties.showAxisLabels) {
              display_name = undefined;
            }
            var position = axis.position; // get the first specified position
            if (config.rotate) {
              position = position == 'left' ? 'top' : 'bottom';
            }
            var stackType = "regular";
            if (allowStacking.indexOf(axisIndex) > -1 && axis.stacking) {
              stackType = axis.stacking;
            }
            if (axis.showAxisLabel === false) {
              display_name = "";
            }
            var valueAxis = {
              title: display_name,
              position: position,
              id: axis.id,  // ID will be the same for all entries
              stackType: stackType,
              usePrefixes: true,
              color: colorsArray[axisIndex],
              titleColor: colorsArray[axisIndex],
              axisColor: colorsArray[axisIndex],
              titleBold: false,
              axisAlpha: 0
            };
            config.valueAxes.push(angular.merge(getValueAxisDefaultConfig(), valueAxis));
          });
        }
      }

      function updateDisplayProperties(chartUnit) {
        var chart = chartUnit.chart;
        var display_properties = chartUnit.display_properties;
        if (display_properties.title) {
          chart.titles[0].text = display_properties.title;
        }
        if (display_properties.titleOptions) {
          if (display_properties.titleOptions.show === false) {
            chart.titles[0].text = "";
          }
          if (display_properties.titleOptions.fontSize) {
            chart.titles[0].size = parseInt(display_properties.titleOptions.fontSize) || 14;
          }
        }

        if (chart.categoryAxis) {
          if (display_properties.showAxisLabels) {
            if (display_properties.xAxes.hasOwnProperty(1) && display_properties.xAxes[1].name) {
              chart.categoryAxis.title = display_properties.xAxes[1].name;
              if (display_properties.xAxes[1].position) {
                chart.categoryAxis.position = display_properties.xAxes[1].position;
              }
            } else {
              chart.categoryAxis.title = chartUnit.metadata[0].display_name;
            }
            if (display_properties.xAxes[1].showAxisLabel === false) {
              chart.categoryAxis.title = "";
            }
          } else {
            chart.categoryAxis.title = undefined;
          }
        }
       // TODO: SENTRYERROR:FRONTEND-9F:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/414760104
        if (chart.type == "serial" && chart.chartData.length > chart.categoryAxis.autoRotateCount) {
          chart.categoryAxis.minHorizontalGap = 40;
        }
        chart.chartScrollbar.enabled = display_properties.enableZoomAndPan;
        chart.legend.enabled = display_properties.legend.enabled && scope.crvm.big;
        chart.legend.position = display_properties.legend.position || 'bottom';
        chart.legend.align = display_properties.legend.align || 'center';
        chart.rotate = display_properties.invertAxis;
      }

      function getDefaultConfig(chartUnit) {
        var typeMap = {};
        var commonConfig = {
          "valueAxes": [],
          "startDuration": 0,
          "graphs": [],
          "sequencedAnimation": false,
          "percentPrecision": 2,
          "precision": -1,
          "radius": "33%",
          "innerRadius": "30%",
          "labelRadius": 5,
          "theme": "light",
          "autoMargins": true,
          "autoMarginOffset": 10,
          "addClassNames": true,
          "pathToImages": "../../assets/images/amcharts/",
          "colors": colorsArray,
          "chartCursor": {
            "categoryBalloonEnabled": true,
            "categoryBalloonText": "[[category]]",
            "fullWidth": true,
            "categoryBalloonColor": "#999",
            "cursorAlpha": 0,
            "adjustment": 20
          },
          "chartScrollbar": {
            "scrollbarHeight":4,
            "offset":-1,
            "backgroundAlpha":0.1,
            "backgroundColor":"#888888",
            "selectedBackgroundColor":"#67b7dc",
            "selectedBackgroundAlpha":1,
            "enabled": false
          },
          "legend": {
            "enabled": false,
            "position": "bottom",
            "color": "#999"
          },
          "categoryAxis": {
            "gridPosition": "start",
            "gridAlpha": 0,
            "autoRotateCount": 5,
            "autoRotateAngle": 50,
            "centerRotatedLabels": false,
            "minHorizontalGap": 50,
            "minVerticalGap": 20,
            "color": "#999",
            "axisColor": "#999",
            "titleColor": "#999",
            "titleBold": false,
            "labelFunction": function (valueText, serialDataItem, categoryAxis) {
              return _.truncate(valueText, {'length': 18});
            }
          },
          "titles" : [{
            "id": "maintitle",
            "bold": false,
            "size": 14,
            "color": "#333"
          }],
          "export": {
            "enabled": true
          },
          "responsive": {
            "addDefaultRules": false,
            "enabled": true
          }
        };
        typeMap[c.chartTypes.xy] = function xyDefaults() {
          var config: any = {
            "type": "serial"
          };
          config = angular.merge(commonConfig, config);
          config.responsive.rules = responsiveConfig(config.type);
          return config;
        };
        typeMap[c.chartTypes.pie] = function pieDefaults() {
          var config: any = {
            "type": "pie",
            "groupPercent": 1,
            "groupedTitle": "(Others)",
            "labelText": "[[title]]",
            "marginLeft": 20,
            "marginRight": 20,
            "marginBottom": 20,
            "autoMargins": false,
            "legend": {
              "enabled": false
            },
            "labelFunction": function (slice, text) {
              return _.truncate(text, {'length': 15})
            }
          };
          config = angular.merge(commonConfig, config);
          config.responsive.rules = responsiveConfig(config.type);
          return config;
        };
        typeMap[c.chartTypes.funnel] = function pieDefaults() {
          var config = {
            "type": "funnel",
            "neckWidth": "40%",
            "neckHeight": "30%",
            "marginLeft": 20,
            "marginRight": 20,
            "marginBottom": 20,
            "showZeroSlices": true
          };
          var ret = angular.merge(commonConfig, config);
          delete ret.valueAxes;
          delete ret.categoryAxis;
          ret.responsive.rules = responsiveConfig(config.type);
          return ret;
        };
        return typeMap[chartUnit.type];
      }

      function responsiveConfig (type){
        var base = [
          /**
           * Disable legend in certain cases
           */
          {
            "maxWidth": 100,
            "overrides": {
              "legend": {
                "enabled": false
              }
            }
          }, {
            "maxHeight": 100,
            "overrides": {
              "legend": {
                "enabled": false
              }
            }
          },


          /**
           * Narrow chart
           */
          {
            "maxWidth": 350,
            "overrides": {
              "autoMarginOffset": 0,
              "graphs": {
                "hideBulletsCount": 10
              }
            }
          }, {
            "maxWidth": 350,
            "rotate": false,
            "overrides": {
              "marginLeft": 10,
              "marginRight": 10,
              "categoryAxis": {
                "autoRotateCount": 0,
                "autoRotateAngle": 85,
                "minHorizontalGap": 20,
                "labelFunction": function (valueText, serialDataItem, categoryAxis) {
                  return _.truncate(valueText, {'length': 12});
                }
              },
              "valueAxes": {
                "inside": true,
                "ignoreAxisWidth": true,
                "labelsEnabled": false,
                "axisAlpha": 0,
                "title": "",
                "showFirstLabel": false,
                "showLastLabel": false
              },
              "graphs": {
                "bullet": "none"
              }
            }
          }, {
            "maxWidth": 350,
            "rotate": true,
            "overrides": {
              "marginLeft": 10,
              "marginRight": 10,
              "categoryAxis": {
                "ignoreAxisWidth": true,
                "inside": true,
                "title": "",
                "labelFunction": function (valueText, serialDataItem, categoryAxis) {
                  return _.truncate(valueText, {'length': 12});
                }
              }
            }
          }, {
            "maxWidth": 200,
            "rotate": false,
            "overrides": {
              "marginLeft": 10,
              "marginRight": 10,
              "marginTop": 10,
              "marginBottom": 10,
              "categoryAxis": {
                "ignoreAxisWidth": true,
                "labelsEnabled": false,
                "inside": true,
                "title": "",
                "guides": {
                  "inside": true
                }
              },
              "valueAxes": {
                "ignoreAxisWidth": true,
                "labelsEnabled": false,
                "axisAlpha": 0,
                "guides": {
                  "label": ""
                }
              },
              "legend": {
                "enabled": false
              }
            }
          }, {
            "maxWidth": 200,
            "rotate": true,
            "overrides": {
              "chartScrollbar": {
                "scrollbarHeight": 4,
                "graph": "",
                "resizeEnabled": false
              },
              "categoryAxis": {
                "ignoreAxisWidth": true,
                "labelsEnabled": false,
                "inside": true,
                "title": "",
                "guides": {
                  "inside": true
                }
              },
              "valueAxes": {
                "ignoreAxisWidth": true,
                "labelsEnabled": false,
                "axisAlpha": 0,
                "guides": {
                  "label": ""
                }
              },
              "legend": {
                "enabled": false
              }
            }
          }, {
            "maxWidth": 100,
            "rotate": false,
            "overrides": {
              "valueAxes": {
                "gridAlpha": 0
              }
            }
          }, {
            "maxWidth": 100,
            "rotate": true,
            "overrides": {
              "categoryAxis": {
                "gridAlpha": 0
              }
            }
          },

          /**
           * Short chart
           */
          {
            "maxHeight": 300,
            "overrides": {
              "autoMarginOffset": 10,
              "graphs": {
                "hideBulletsCount": 10
              },
              "categoryAxis": {
                "labelFunction": function (valueText, serialDataItem, categoryAxis) {
                  return _.truncate(valueText, {'length': 12});
                }
              }
            }
          }, {
            "maxHeight": 200,
            "rotate": false,
            "overrides": {
              "marginTop": 10,
              "marginBottom": 10,
              "categoryAxis": {
                "ignoreAxisWidth": true,
                "labelsEnabled": false,
                "axisAlpha": 1,
                "inside": true,
                "title": "",
              },
              "titles": {
                "size": 11
              }
            }
          }, {
            "maxHeight": 200,
            "rotate": true,
            "overrides": {
              "marginTop": 10,
              "marginBottom": 10,
              "valueAxes": {
                "labelsEnabled": false,
                "axisAlpha": 0,
                "title": ""
              },
              "categoryAxis": {
                "labelsEnabled": false
              },
              "graphs": {
                "bullet": "none"
              },
              "titles": {
                "size": 11
              }
            }
          }, {
            "maxHeight": 150,
            "rotate": false,
            "overrides": {
              "titles": {
                "size": 10
              },
              "chartScrollbar": {
                "scrollbarHeight": 4,
                "graph": "",
                "resizeEnabled": false
              },
              "categoryAxis": {
                "labelsEnabled": false,
                "ignoreAxisWidth": true,
                "axisAlpha": 1,
                "axisColor": "#999",
                "guides": {
                  "label": ""
                }
              }
            }
          }, {
            "maxHeight": 150,
            "rotate": true,
            "overrides": {
              "titles": {
                "size": 10
              },
              "categoryAxis": {
                "labelsEnabled": false
              },
              "valueAxes": {
                "labelsEnabled": false,
                "axisAlpha": 0,
                "ignoreAxisWidth": true,
                "axisColor": "#999",
                "guides": {
                  "label": ""
                }
              }
            }
          }, {
            "maxHeight": 100,
            "rotate": false,
            "overrides": {
              "titles": {
                "enabled": false
              },
              "valueAxes": {
                "labelsEnabled": false,
                "ignoreAxisWidth": true,
                "axisAlpha": 0,
                "gridAlpha": 0,
                "guides": {
                  "label": ""
                }
              }
            }
          }, {
            "maxHeight": 100,
            "rotate": true,
            "overrides": {
              "categoryAxis": {
                "labelsEnabled": false,
                "ignoreAxisWidth": true,
                "axisAlpha": 0,
                "gridAlpha": 0,
                "guides": {
                  "label": ""
                }
              }
            }
          },

          /**
           * Really small charts: microcharts and sparklines
           */
          {
            "maxWidth": 100,
            "overrides": {
              "autoMargins": false,
              "marginTop": 0,
              "marginBottom": 0,
              "marginLeft": 0,
              "marginRight": 0,
              "categoryAxis": {
                "labelsEnabled": false
              },
              "valueAxes": {
                "labelsEnabled": false
              }
            }
          }, {
            "maxHeight": 100,
            "overrides": {
              "autoMargins": false,
              "marginTop": 0,
              "marginBottom": 0,
              "marginLeft": 0,
              "marginRight": 0,
              "categoryAxis": {
                "labelsEnabled": false,
                "axisColor": "#999",
              },
              "valueAxes": {
                "labelsEnabled": false
              }
            }
          }
        ];
        var rules = {
          "pie": [

            /**
             * Narrow chart
             */
            {
              "maxWidth": 400,
              "overrides": {
                "labelsEnabled": false
              }
            },

            /**
             * Short chart
             */
            {
              "maxHeight": 350,
              "overrides": {
                "pullOutRadius": 0
              }
            }, {
              "maxHeight": 200,
              "overrides": {
                "labelsEnabled": false
              }
            }
          ],
          "funnel": [
            {
              "maxHeight": 200,
              "overrides": {
                "legend": {
                  "enabled": false
                },
                "labelsEnabled": false
              }
            },
            {
              "maxWidth": 200,
              "overrides": {
                "legend": {
                  "enabled": false
                },
                "labelsEnabled": false
              }
            }
          ],
          "serial": []
        };
        return base.concat(rules[type])
      }


      function getValueAxisDefaultConfig() {
        return {
          "gridAlpha": 0,
          "autoOffset": true
        }
      }

      function getCommonYAxisSettings(){
        var yAxis = {labels: {style: {fontSize: null}}, title: {style: {font: null}}};
        yAxis.labels.style.fontSize='11px';
        yAxis.title.style.font='12px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif';
        return yAxis;
      }


      var sourceTableGridInstance, _watchSubscribed = false, _listenersSubscribed = false;
      function renderSourceTable(metadata, data) {
        var options = {
          showColumnTypeIcons: true,
          rowHeight: 35,
          defaultColumnWidth: 100
        };
        var sourceTableDiv = $(ele).find('.source-table')[0];
        if (sourceTableGridInstance) {
          sourceTableGridInstance.destroy();
          $(sourceTableDiv).empty();
        }
        var columns = [];
        utils.metadata.applyDisplayChanges(metadata, chartUnit.display_properties, columns);
        sourceTableGridInstance = new gridFactory(sourceTableDiv, undefined, columns, options, data);
        sourceTableGridInstance.init();
        scope.crvm.sourceTableGridInstance = sourceTableGridInstance;
        _watchSubscribed = true;

        if (!_listenersSubscribed) {
          sourceTableGridInstance.grid.onColumnsResized.subscribe(_gridResizeCb);
          sourceTableGridInstance.grid.onColumnsReordered.subscribe(_gridReorderCb);
          _listenersSubscribed = true;
        }
      }

      scope.$watch('crvm.datastore.sourceTableView', function (sourceTableView) {
        if (sourceTableView && !_watchSubscribed) {
          renderSourceTable(chartUnit.metadata, chartUnit.data);
        }
        if (sourceTableView && sourceTableView.visible) {
          $timeout(sourceTableGridInstance.grid.resizeCanvas, 100);
        }
      }, true);

      function _gridResizeCb(){
        var cols = sourceTableGridInstance.grid.getColumns();
        var newWidths = chartUnit.display_properties.COLUMN_WIDTHS || {};
        angular.forEach(cols, function (col, i) {
          if (col.width != col.previousWidth) {
            newWidths[col.id] = col.width;
          }
        });
        chartUnit.display_properties.COLUMN_WIDTHS = newWidths;
        scope.crvm.handleDisplayUpdated(chartUnit.display_properties);
      }

      function _gridReorderCb(){
        var cols = sourceTableGridInstance.grid.getColumns();
        var newOrder = {};
        cols = $.grep(cols, function (col: any) {
          return col.id !== "index";
        });
        angular.forEach(cols, function (col, i) {
          newOrder[i] = col.id;
        });
        chartUnit.display_properties.COLUMN_ORDER = newOrder;
        scope.crvm.handleDisplayUpdated(chartUnit.display_properties);
      }

      scope.$on("$destroy", function () {
        scope.crvm.destroy && scope.crvm.destroy();
      });
    }
  };
}

