/**
 * @ngInject
 */
import * as angular from "angular";
import * as _ from 'lodash-es';


editMetricsController.$inject = [
  "Singularity",
  "Notification",
  "MappedForm",
  "$uibModal",
  "config",
  "utils"
];
export function editMetricsController(
  Singularity,
  Notification,
  MappedForm,
  $uibModal,
  config,
  utils
){
  this.$onInit = function() {
    var tpvm = this;
    tpvm.showLoader = false;
    tpvm.configValidationInProgress = false;
    tpvm.selectedIdentity = {};
    tpvm.dsConfig = {};
    tpvm.dataSample = {};
    tpvm.dataSampleVisible = false;
    tpvm.isQueryEdited = false;
    tpvm.editQueryChangeNotification = false;
    tpvm.preview_grid_loader = {
      show: false,
      hideOverlay: false
    };

    // functions
    tpvm.submitMetricsChange = submitMetricsChange;
    tpvm.selectIdentity = selectIdentity;
    tpvm.updateQuery = updateQuery;
    init();

    function updateDefaultValues() {
      // decoding the query form display value to convert the sanitized data to decoded data
      tpvm.addDsConfigForm.modelData.query_string = utils.decodeSanitizedName(tpvm.dsConfig.query_string);
      tpvm.addDsConfigForm.modelData.hidden_query = tpvm.dsConfig.hidden_query;
      tpvm.getDataSample();
    }

    function augmentDsConfigParams(params) {
      tpvm.addDsConfigSpec = angular.merge(tpvm.addDsConfigSpec, params);
      tpvm.addDsConfigForm.update(tpvm.addDsConfigSpec);
      Singularity.augmentDsComplete();
    }

    function postDsConfigValidationCallback(data) {
      tpvm.isDsConfigValid = data.is_valid;
      if (data.is_valid) {
        tpvm.dsConfigValidationMessage = {
          success_message: data.success_message
        };
        // delete tpvm.selectedDsConfig["validate"];
        if (tpvm.previewEnabled) {
          tpvm.dataSampleCheck = true;
          tpvm.getDataSample();
        }
        if (tpvm.dsConfigValidationMode === "AUTO" && tpvm.scheduleType) {
          tpvm.workFlow.next("afterValidation");
        }
        if (tpvm.dsConfigValidationMode === "AUTO" && !tpvm.scheduleType) {
          tpvm.saveDS();
        }
      } else {
        tpvm.dataSampleCheck = false;
        tpvm.dsConfigValidationMessage = {
          error_description: data.error_description,
          error_message: data.error_message
        };
      }
      tpvm.configValidationInProgress = false;
    }

    function reInitializeDsConfigForm() {
      const profile = {
        value: tpvm.dsConfig.profile
      }
      Singularity.getAddDsConfig(
        tpvm.selectedIntegration.key,
        tpvm.selectedIdentity.value,
        profile
      ).then(function (addDsConfigSpec) {
        setDsConfigSpec(addDsConfigSpec);
        tpvm.showLoader = false;
      });
    }

    function init() {
      tpvm.showLoader = true;
      tpvm.selectedIntegration = tpvm.config_keys;
      tpvm.selectedIntegration.key = tpvm.config_keys.integration_key;
      Singularity.getIdentities(tpvm.selectedIntegration.key).then(function (
          identities
        ) {
          tpvm.identities = identities;
          tpvm.showLoader = false;
          if (tpvm.selectedIntegration.identity_key !== undefined) {
            angular.forEach(tpvm.identities, function (identity) {
              if (identity.value === tpvm.selectedIntegration.identity_key) {
                tpvm.selectIdentity(identity);
                return false;
              }
            });
          }
          tpvm.isProfileLoading = false;
        },
        error_cb);
      Singularity.selected_integration_key =
        tpvm.selectedIntegration.integration_key;
      Singularity.onAugmentDsConfigSpecParams(
        "on_ds_config_spec_params_update",
        augmentDsConfigParams
      );
      Singularity.onValidateDsConfig(
        "on_ds_config_validation",
        postDsConfigValidationCallback
      );
      Singularity.onReInitializeDsConfigForm(
        "on_reinitialize_ds_config_form",
        reInitializeDsConfigForm
      );

      function error_cb(data) {
        tpvm.showLoader = false;
      }

      tpvm.isQueryEdited = false;
    }

    function selectIdentity(identity) {
      // analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.selectIdentity, { eventOrigin: "thirdPartyModal" });
      tpvm.selectedIdentity = identity;
      tpvm.showLoader = true;
      tpvm.addDsConfigSpec = {
        options: {
          removeChromeAutoComplete: true
        }
      };
      Singularity.getIdentityDsConfig(
        tpvm.selectedIntegration.key,
        tpvm.selectedIdentity.value,
        tpvm.selectedIntegration.ds_config_key
      ).then(dsConfig => {
        tpvm.dsConfig = dsConfig;
        const profile = {
          value: dsConfig.profile
        }
        Singularity.getAddDsConfig(
          tpvm.selectedIntegration.integration_key,
          tpvm.selectedIntegration.identity_key,
          profile
        ).then(
          function (addDsConfigSpec) {
            setDsConfigSpec(addDsConfigSpec);
            updateDefaultValues();
            tpvm.showLoader = false;
            tpvm.isQueryEdited = false;
          },
          function (response) {
            tpvm.showLoader = false;
            let msg = "Couldn't generate preview. "
            if (response?.message) {
              msg += response.message
            }
            Notification.error(msg);
          }
        );
      });
    }

    function setDsConfigSpec(addDsConfigSpec) {
      tpvm.addDsConfigSpec = addDsConfigSpec;
      tpvm.isDsConfigValid = !addDsConfigSpec.capability
        .config_validation_required;
      tpvm.previewEnabled = addDsConfigSpec.capability.preview_enabled;
      tpvm.addDsConfigForm = new MappedForm(tpvm.addDsConfigSpec, tpvm);
      tpvm.dsConfigValidationMode =
        tpvm.addDsConfigSpec.capability.ds_config_validation_mode || "AUTO";
    }

    function _getDsConfigwithProfile() {
      var dsConfig = _.cloneDeep(tpvm.addDsConfigForm.modelData);
      // passing the query in the orignal format to the api
      dsConfig.query_string = unescape(dsConfig.query_string);
      if (tpvm.dsConfig && tpvm.dsConfig.profile) {
        dsConfig["profile"] = tpvm.dsConfig.profile;
      }
      dsConfig["ds_name"] = tpvm.datasource_name;
      dsConfig["data_start_date"] = tpvm.periodDataStartDate;
      return dsConfig;
    }

    // This function is to detect change in columns on edit query and notify the user based on changes
    // Updates the editQueryChangeNotification state to true in case of modification in schema
    function detectSchemaChange(newMetadata) {
      var existingMetadata = tpvm.datasource_metadata;
      var newMetadataColDisplayNames = []
      var newMetadataColDisplayNamesToTypeMap = {}
      for (var col of newMetadata) {
        newMetadataColDisplayNames.push(col['display_name'])
        newMetadataColDisplayNamesToTypeMap[col['display_name']] = col['type']
      }
      var existingColDisplayNames = []
      var existingColDisplayNamesToTypeMap = {}
      for (var exitingCol of existingMetadata) {
        existingColDisplayNames.push(exitingCol['display_name'])
        existingColDisplayNamesToTypeMap[exitingCol['display_name']] = exitingCol['type']
      }
      var colsAdded = newMetadataColDisplayNames.filter(x => existingColDisplayNames.indexOf(x) === -1);
      var colsRemoved = existingColDisplayNames.filter(x => newMetadataColDisplayNames.indexOf(x) === -1);
      var matchingCols = existingColDisplayNames.filter(x => newMetadataColDisplayNames.indexOf(x) != -1);
      var typeMismatchCols = []
      for (var column of matchingCols) {
        if (newMetadataColDisplayNamesToTypeMap[column] != existingColDisplayNamesToTypeMap[column]) {
          typeMismatchCols.push(column)
        }
      }

      if (colsAdded.length || colsRemoved.length || typeMismatchCols.length) {
        // when columns are added, removed or type changed a warning dailog will be shown
        tpvm.editQueryChangeNotification = true;
      }
      // var colAddedString = ''
      // for (var colAdded of colsAdded) {
      //   colAddedString += ' ' + colAdded + ', '
      // }
      // var messageForColsAdded = '<h6>Columns Added: ' + colAddedString + '</h6><br>'

      // var colRemovedString = ''
      // for (var colRemoved of colsRemoved) {
      //   colRemovedString += ' ' + colRemoved + ', '
      // }
      // var messageForColsRemoved = '<h6>Columns Removed: ' + colRemovedString + '</h6><br>'

      // var colTypeMismatchString = ''
      // for (var typeMismatchCol of typeMismatchCols) {
      //   colTypeMismatchString += ' ' + typeMismatchCol + ', '
      // }
      // var messageForColsTypeMismatch = '<h6>Columns Removed: ' + colTypeMismatchString + '</h6><br>'
      // var finalMessage = ''
      // if (colAddedString) {
      //   finalMessage += messageForColsAdded
      // }
      // if (colRemovedString) {
      //   finalMessage += messageForColsRemoved
      // }
      // if (colTypeMismatchString) {
      //   finalMessage += messageForColsTypeMismatch
      // }
      // if (finalMessage) {
      //   tpvm.editQueryChangesNotification = finalMessage;
      // }


    }

    tpvm.getDataSample = function (callback_fn) {
      tpvm.dataSampleVisible = true;
      tpvm.preview_grid_loader.show = true;
      tpvm.isDsConfigValid = false;
      tpvm.isQueryEdited = true;
      Singularity.getDataSample(
        tpvm.selectedIntegration.key,
        tpvm.selectedIdentity.value,
        _getDsConfigwithProfile()
      ).then(
        function (params) {
          tpvm.dataSampleVisible = true;
          tpvm.dataSample = params.data_sample;
          tpvm.isDsConfigValid = true;
          if (callback_fn !== undefined && typeof callback_fn === "function") {
            callback_fn();
          }
          tpvm.preview_grid_loader.show = false;
          detectSchemaChange(params['data_sample']['metadata'])
        },
        function () {
          if (callback_fn !== undefined && typeof callback_fn === "function") {
            callback_fn();
          }
          tpvm.preview_grid_loader.show = false;
          Notification.error("Couldn't generate preview.");
        }
      );
    };

    function updateQuery() {
      const modelData = tpvm.addDsConfigForm.modelData;
      if (modelData.query_string !== modelData.hidden_query) {
        modelData.hidden_query = modelData.query_string;
      }
      Singularity.updateMetrics(
        tpvm.selectedIntegration.key,
        tpvm.selectedIdentity.value,
        tpvm.dsConfig.profile,
        tpvm.selectedIntegration.ds_config_key,
        tpvm.datasource_id,
        modelData
      ).then(
        function (data) {
          tpvm.showLoader = false;
        },
        function () {
          tpvm.showLoader = false;
        }
      );
      tpvm.cancelButtonHandler();
    }

    function submitMetricsChange() {
      if (tpvm.editQueryChangeNotification) {
        // If change in schema, display a warning dialog
        var modal = $uibModal.open({
          templateUrl: config.templates.confirmQueryUpdate,
          windowClass: 'modal-size-small delete-batch-modal',
          controllerAs: 'mvm'
        });

        modal.result.then(() => {
          tpvm.updateQuery();
        });
      } else {
        tpvm.updateQuery();
      }
    }
  }
}
