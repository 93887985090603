'use strict';

import * as angular from 'angular';
import * as moment from 'moment';
import * as _ from 'lodash-es';

/**
 * @ngInject
 * Datasource service
 */
DatasourceService.$inject = ['Datasource', 'DataviewService', 'eventCallbackManagerFactory', '$q', '$resource', 'config',
                                  'FutureService', '$timeout', 'c', 'Notification', 'analyticsService', 'utils', 'toastNotification'];
export function DatasourceService(Datasource, DataviewService, eventCallbackManagerFactory, $q, $resource, config,
                                  FutureService, $timeout, c, Notification, analyticsService, utils, toastNotification) {
  var _ds_list = {}, _prev_ds_data, _prev_ws_data;
  var list_updated = new eventCallbackManagerFactory('ds_list_updated');
  var ds_deletion = new eventCallbackManagerFactory('ds_deletion');
  var post_ds_deletion = new eventCallbackManagerFactory('post_ds_deletion.' + utils.string.random());
  var on_add_dataview = new eventCallbackManagerFactory('add_dataview');
  var datasetNavigation = new eventCallbackManagerFactory('datasetNavigation');


  var datasources_service = {
    list_last_updated_at: window['moment'].utc(),
    _set_updates: _set_updates,
    list: _ds_list,  // tentative, to be replaced with a method
    delete: del,
    rename: rename,
    add_dataview: add_dataview,
    get_by_id: get_by_id,
    updateBatchColumns: updateBatchColumns,
    multiDelete: multiDelete,
    updateSchedule: updateSchedule,
    refreshDatasource: refresh,
    listInited: false,
    reset: reset,
    // Custom Events
    // - list update
    on_list_update: list_updated.add_callback,
    remove_on_list_update: list_updated.remove_callback,
    // - add dataview
    on_add_dataview: on_add_dataview.add_callback,
    remove_add_dataview: on_add_dataview.remove_callback,
    // - ds deletion
    on_delete: ds_deletion.add_callback,
    remove_on_delete: ds_deletion.remove_callback,
    on_post_ds_deletion: post_ds_deletion.add_callback,
    onNaviagteToDataset: datasetNavigation.add_callback,
    navigateToDataset: navigateToDataset,
  };
  return datasources_service;

  function navigateToDataset(ds_id) {
    datasetNavigation.fire_event(ds_id);
  }

  function get_by_id(ds_id) {
    return _.get(_ds_list, ds_id);
  }

  ////

  function reset() {
    _prev_ds_data = undefined;
    _prev_ws_data = undefined;
    datasources_service.listInited = false;
    _.forOwn(_ds_list, function (v, k) {_.unset(_ds_list, k)})
  }

  function del(ds) {
    var deferred = $q.defer();
    ds.delete().then(del_success, deferred.reject);

    function del_success(data) {
      // delete _ds_list[ds.id];
      ds_deletion.fire_event();
      deferred.resolve();
      FutureService.track(data.future_id, future_tracker)
    }

    function future_tracker(future) {
      if (future.status == "processing") {
        return;
      }
      if (future.status == "error") {
        Notification.error('Could not delete <strong>' + ds.name + '</strong>');
        return;
      }
      var undeletable_ds = _.keys(future.response);
      if (undeletable_ds.length) {
        Notification.error('Could not delete <strong>' + ds.name + '</strong> as other items depend on it. Please edit/delete the dependent items before deleting this dataset.');
      } else {
      }
    }

    return deferred.promise;
  }

  function multiDelete(items) {
    // return;
    //TODO: method should be implemented as a safe delete op.
    var deferred = $q.defer();
    var ds_ids = [];
    var dss = [];
    var datasources = $resource(config.api.datasources);

    angular.forEach(items, function (item) {
      if (item.type === 'datasource') {
        ds_ids.push(item.id);
        dss[item.id] = item;
      }
    });
    datasources.patch({
      patch: [{
        "op": "remove",
        "path": "datasources",
        "value": ds_ids
      }]
    }).$promise.then(del_success, del_error);

    function del_success(data) {
      angular.forEach(items, function (item) {
        // item.status = c.dsStatus.deleting;
      });
      ds_deletion.fire_event();
      deferred.resolve();
      FutureService.track(data.future_id, future_tracker)
    }

    function del_error(data) {
      console.error(data);
      if (data.status == 403) {
        Notification.error("You do not have permission to delete one or more of the selected Datasets");
      }
    }

    function future_tracker(future) {
      if (future.status == "processing") {
        return;
      }
      if (future.status == "error") {
        Notification.error('Could not delete the selected Datasources, please try again later.');
        return;
      }
      var undeletable_ds = _.map(_.keys(future.response), _.parseInt);
      var deleted_ds = _.difference(ds_ids, undeletable_ds);
      if (undeletable_ds.length) {
        undeletable_ds = _.map(_.map(undeletable_ds, function (ds_id) {
          return dss[ds_id];
        }), 'name');
        Notification.error({
          message: 'Could not delete <strong>' + undeletable_ds.join(", ") + '</strong> as other items depend on it. ' +
          'Please edit/delete the dependent items before deleting selected datasets.'
        });
      }
      if (!deleted_ds.length) {
      } else {
        deleted_ds = _.map(_.map(deleted_ds, function (ds_id) {
          return dss[ds_id];
        }), 'name');
      }
    }

    return deferred.promise;
  }

  function rename(ds, new_name, validation_only) {
    var deferred = $q.defer();
    ds.rename(new_name, validation_only).then(rename_success, rename_error).catch(function (e) {
    });

    function rename_success() {
      if (!validation_only) {
        ds.update({name: new_name});
      }
      deferred.resolve();
    }

    function rename_error() {
      deferred.reject.apply(this, arguments);
    }

    return deferred.promise;
  }

    function updateBatchColumns(ds, add_columns, delete_columns) {
        var deferred = $q.defer();
        ds.updateBatchColumns(add_columns, delete_columns).then(_success, _error).catch(function (e) {
        });
        function _success(d) {
            // d contains future_id not the actual data
            FutureService.track(d.future_id, function (future) {
                let response = future.response;
                if (future.status == "processing") {
                    return;
                }
                if (future.status == "success") {
                    deferred.resolve(response);
                } else {
                    deferred.reject(response);
                }
            });
        }

        function _error(data) {
          let error_message = "Failed to update batch columns";
          if(data.data && data.data.ERROR_MESSAGE) {
            error_message += ": " + data.data.ERROR_MESSAGE;
          }
          toastNotification.error(error_message);
          deferred.reject.apply(this, arguments);
        }

        return deferred.promise;
    }
  function refresh(ds) {
    var deferred = $q.defer();
    ds.refresh().then(refresh_success, refresh_error);

    function refresh_success() {
      deferred.resolve();
    }

    function refresh_error(data) {
      let error_message = "Failed to retrieve latest data";
      if(data.data && data.data.ERROR_MESSAGE) {
        error_message += ": " + data.data.ERROR_MESSAGE;
      }
      toastNotification.error(error_message);

      deferred.reject.apply(this, arguments);
    }

    return deferred.promise;
  }

  function add_dataview(ds, copy_from_wksp_id, dataview_config, extra_condition, name) {
    var deferred = $q.defer();
    var triggerEvent = "New view";
    if (copy_from_wksp_id) {
      triggerEvent = "Duplicate View"
    }
    analyticsService.userEventTrack(c.userEvents.dataviewEvents.viewOptions.openNewView, {actionName: triggerEvent});
    ds.add_dataview(name, copy_from_wksp_id, dataview_config, extra_condition).then(success_cb, error_cb);
    return deferred.promise;

    function success_cb(data) {
      if (data['STATUS'] == 'FAILURE') {
        deferred.reject(data)
      }
      else{
        deferred.resolve(data);
        on_add_dataview.fire_event();
      }
    }

    function error_cb() {
      deferred.reject.apply(this, arguments);
    }
  }

  function _set_updates(datasources_data, dataview_by_ds_id_data, resource_update_callback, resource_delete_callback) {
    if (angular.equals(datasources_data, _prev_ds_data) && angular.equals(dataview_by_ds_id_data, _prev_ws_data)) {
      return false;
    }
    _prev_ds_data = _.cloneDeep(datasources_data);
    _prev_ws_data = _.cloneDeep(dataview_by_ds_id_data);
    
    let ds_ids = [];
    _.forEach(datasources_data, function(ds_data){
      ds_ids.push(ds_data.id);
    })
 
    // if only worskpace resources are updated
    if (datasources_data.length == 0 && dataview_by_ds_id_data) {
      angular.forEach(Object.keys(dataview_by_ds_id_data), function (ds_id) {
        let ds: any = get_by_id(parseInt(ds_id));
        if(ds) {
          angular.extend(ds.dataviews, DataviewService._set_updates(dataview_by_ds_id_data[ds_id], ds,
            resource_update_callback, resource_delete_callback));
          // delete non-existent WS
          angular.forEach(ds.dataviews, function (ws) {
            if (ws.objectStatus == c.coreListObjectStatus.deleted) {
              delete ds.dataviews[ws.id];
            }
          });
          setDsDataviewsInfo(ds);
        }
      })
    }
    angular.forEach(datasources_data, function (ds_data) {
      if(ds_data.objectStatus == c.coreListObjectStatus.deleted) {
        // check for already deleted resource
        if (_ds_list[ds_data.id]) {
          _.forEach(_ds_list[ds_data.id].dataviews, function (ws) {
            resource_delete_callback(ws);
          });
          resource_delete_callback(_ds_list[ds_data.id]);
          delete _ds_list[ds_data.id];
          post_ds_deletion.fire_event()
        }
      }
      else {
        if (_ds_list.hasOwnProperty(ds_data.id)) {
          _ds_list[ds_data.id].update(ds_data);
          resource_update_callback(_ds_list[ds_data.id]);
        } else {
          _ds_list[ds_data.id] = new Datasource(ds_data);
          resource_update_callback(_ds_list[ds_data.id]);
        }
      }
      var ds = _ds_list[ds_data.id];
      if (ds) {
        // get WS objects
        angular.extend(ds.dataviews, DataviewService._set_updates(dataview_by_ds_id_data[ds_data.id], ds,
          resource_update_callback, resource_delete_callback));

        // delete non-existent WS
        angular.forEach(ds.dataviews, function (ws) {
          if (ws.objectStatus == c.coreListObjectStatus.deleted) {
            delete ds.dataviews[ws.id];
          } 
        });
        setDsDataviewsInfo(ds);
      }
    });
    /**Handle the case where there are datasource updates as well as dataview updates
     * but some dataviews are not related to any of the datasets that have updated 
     * Note keep this function here in the end when _ds_list is available and its not empty as the code above fills it up
     * */
     if (datasources_data.length != 0 && dataview_by_ds_id_data){
      angular.forEach(Object.keys(dataview_by_ds_id_data), function (ds_id) {
        if (ds_ids.indexOf(parseInt(ds_id)) == -1){
          let ds: any = get_by_id(parseInt(ds_id));
          if (ds) {
            angular.extend(ds.dataviews, DataviewService._set_updates(dataview_by_ds_id_data[ds_id],
                                                                        ds,
                                                                        resource_update_callback,
                                                                        resource_delete_callback));
            // delete non-existent WS
            angular.forEach(ds.dataviews, function (ws) {
              if (ws.objectStatus == c.coreListObjectStatus.deleted) {
                delete ds.dataviews[ws.id];
              }
            });
            setDsDataviewsInfo(ds);
          }
        }
      })
    }
    datasources_service.list_last_updated_at = moment.utc();
    datasources_service.listInited = true;
    list_updated.fire_event();
    return true;
  }

  function setDsDataviewsInfo(ds) {
    ds.dataviews_list.splice(0, ds.dataviews_list.length);
    angular.forEach(ds.dataviews, function (ws) {
      ds.dataviews_list.push(ws);
    });
    ds.dataview_count = Object.keys(ds.dataviews).length;
  }

  function updateSchedule(ds_id, scheduleState, scheduleJob, notification_id?) {
    var ds:any = get_by_id(ds_id);

    var deferred = $q.defer();
    ds.updateSchedule(scheduleState, scheduleJob, notification_id).then(refresh_success, refresh_error);

    function refresh_success() {
      deferred.resolve();
    }

    function refresh_error() {
      deferred.reject.apply(this, arguments);
    }

    return deferred.promise;
  }
}
