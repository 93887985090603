import * as angular from "angular";
import * as _ from 'lodash-es';
/**
 * @ngInject
 */
mssqlActionManagerFactory.$inject = ['eventCallbackManagerFactory', 'utils'];
export function mssqlActionManagerFactory(eventCallbackManagerFactory, utils) {
  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new MssqlActionManager(options);
  }

  function MssqlActionManager(options) {
    var self = this;
    self.serversideValidationRequired = true;
    if (_.isUndefined(options.triggerType)) {
      options.triggerType = "pipeline";
    }
    self.dataviewId = options.context.dataview.id;

    self.param = {
      "handler_type": "mssql",
      "trigger_type": options.triggerType,
      "run_immediately": true,
      sequence: options.context.currentSequenceNumber
    };

    self.param.target_properties = {};
    self.param.target_properties = {};

    self.param.target_properties.host = null;
    self.param.target_properties.port = 1433;
    self.param.target_properties.username = null;
    self.param.target_properties.password = null;
    self.param.target_properties.database = null;
    self.param.target_properties.table = null;

    self.getParam = getParam;
    self.setParam = setParam;
    self.validate = validate;

    function validate() {
      return true;
    }
    function getParam() {
      if (self.param.target_properties.table) {
        self.param.target_properties.table = self.param.target_properties.table.trim();
      }
      return self.param;
    }

    function setParam(param) {
      angular.extend(self.param, param);
    }

  }
}
