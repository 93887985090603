var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mm-drawer',{attrs:{"value":""}},[_c('div',{staticClass:"sidebar-content p-x-3 p-y-5"},[_c('div',{staticClass:"resources-section"},[_c('section',{staticClass:"workspace-section p-b-4"},[(_vm.computedCurrentWorkspaceName)?_c('div',{staticClass:"d-flex width-100 m-b-3",staticStyle:{"min-height":"24px"}},[_c('span',{staticClass:"mm-text--body-regular d-flex-align-center",staticStyle:{"width":"calc(100% - 20px)","text-transform":"capitalize","color":"var(--mm-color--n600)"}},[_vm._v("\n            "+_vm._s(_vm.$t('global.dictionary.workspace'))+"\n          ")]),_vm._v(" "),(!_vm.computedIsSettingsPage && _vm.$store.state.workspaces.length > 1)?_c('workspace-menu'):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex-align-center justify-space-between"},[_c('div',{staticClass:"d-flex-align-center",staticStyle:{"max-width":"calc(100% - 32px)"}},[_c('mm-icon',{staticClass:"d-flex-center icon-20px m-r-3",attrs:{"name":"workspace"}}),_vm._v(" "),_c('span',{staticClass:"mm-text--h200",staticStyle:{"word-break":"break-word","overflow-wrap":"break-word"}},[_vm._v("\n              "+_vm._s(_vm.computedCurrentWorkspaceName)+"\n            ")])],1),_vm._v(" "),_c('mm-tooltip',{attrs:{"label":_vm.$t('settings.go_to'),"position":"right"}},[_c('mm-button',{attrs:{"icon":"settings","objective":"tertiary"},on:{"click":function($event){return _vm.openSettings()}}})],1)],1)]),_vm._v(" "),(_vm.$store.getters['resources/getReports'].length)?_c('section',{staticClass:"cursor-pointer section-clickable",class:{ 'menu-item-active mm-text--body-bold': _vm.$store.state.projectId == 0 }},[_c('div',{staticClass:"d-flex width-100",on:{"click":function($event){$event.preventDefault();_vm.goTo({
              id: 0,
              title: _vm.$tc('global.dictionary.report', 2),
              icon: 'data_library_20px',
              path: ("#/workspaces/" + (_vm.$store.state.workspaceId) + "/shared-reports")
            })}}},[_c('mm-icon',{staticClass:"icon-20px m-r-3",staticStyle:{"min-width":"20px"},attrs:{"name":"reports"}}),_vm._v(" "),_c('span',{staticClass:"menu-item-text mm-text--h200"},[_vm._v("\n            "+_vm._s(_vm.$tc('global.dictionary.report', 2))+"\n          ")])],1)]):_vm._e(),_vm._v(" "),_c('section',[_c('div',{staticClass:"d-flex-align-center width-100 m-b-3",staticStyle:{"min-height":"24px","padding-right":"1px"}},[_c('mm-icon',{staticClass:"icon-20px m-r-3",staticStyle:{"min-width":"20px"},attrs:{"name":"projects"}}),_vm._v(" "),_c('span',{staticClass:"mm-text--h200 d-flex-align-center",staticStyle:{"width":"calc(100% - 20px)"}},[_vm._v("\n            "+_vm._s(_vm.$tc('global.dictionary.project', 2))+"\n          ")]),_vm._v(" "),_c('mm-tooltip',{attrs:{"label":_vm.$t('project.create_tooltip'),"position":"right"}},[_c('mm-button',{attrs:{"icon":"plus","objective":"tertiary","size":"small"},on:{"click":function($event){_vm.isAddProjectModalOpen = true}}})],1)],1),_vm._v(" "),_c('div',{staticClass:"overflow-y-auto",staticStyle:{"min-width":"200px"}},_vm._l((_vm.computedItems),function(item){return _c('div',{key:item.path,staticClass:"m-l-7 m-y-2 sub-item cursor-pointer",class:{ 'sub-item-active': _vm.$store.state.projectId == item.id },on:{"click":function($event){$event.preventDefault();return _vm.goTo(item)}}},[_c('div',{staticClass:"width-100 d-flex-align-center justify-space-between"},[_c('span',{staticClass:"sub-item-text",class:{ 'mm-text--body-bold': _vm.$store.state.projectId == item.id },staticStyle:{"overflow":"hidden","text-overflow":"ellipsis"},attrs:{"label":item.title}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")]),_vm._v(" "),_c('mm-menu',{key:((item.id) + "_" + _vm.menuKey),attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('mm-button',_vm._g({key:("view_options_button_" + _vm.menuKey),staticClass:"m-r-2",attrs:{"icon":"menu_3_dots_vertical","objective":"tertiary","size":"small"}},on))]}},{key:"content",fn:function(){return [_c('project-manage-menu',{attrs:{"projectId":item.id,"tooltips-position":"right"},on:{"optionExecuted":function($event){return _vm.onProjectOptionExecuted($event, item.id)},"modalClosed":function($event){_vm.menuKey++}}})]},proxy:true}],null,true)})],1)])}),0)])]),_vm._v(" "),(_vm.computedAllowedUsage > 0)?_c('mm-tooltip',{attrs:{"wrapper-class":"width-100","label":_vm.$t('settings.workspace.usage.info', {
          currentUsage: _vm.computedCurrentUsage.toLocaleString(),
          allowedUsage: _vm.computedAllowedUsage.toLocaleString()
        })}},[_c('section',{key:("workspace_storage_" + (_vm.$store.state.workspaceId)),staticClass:"sidebar-bottom mm-rounded-borders cursor-pointer m-b-6 m-x-2 p-y-5",on:{"click":function($event){return _vm.openSettings(true)}}},[_c('div',{staticClass:"m-x-4"},[_c('p',{staticClass:"m-b-4 mm-text--h300"},[_vm._v(_vm._s(_vm.$t('data_library.workspace_data_usage')))]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"mm-text--caption-regular m-b-0"},[_vm._v(_vm._s(_vm.$t('settings.workspace.usage.info', {
                  currentUsage: _vm.compactNumberFormat(_vm.computedCurrentUsage),
                  allowedUsage: _vm.compactNumberFormat(_vm.computedAllowedUsage)
                }))+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"m-b-0 mm-text--caption-bold",style:({ color: ("var(--mm-color--" + (this.computedStorageBarColor) + ")") })},[_vm._v("\n              "+_vm._s(_vm.computedStorageUsagePercentage)+"%\n            ")])]),_vm._v(" "),_c('mm-progress-bar',{staticClass:"m-y-2",attrs:{"color":_vm.computedStorageBarColor},model:{value:(_vm.computedStorageUsagePercentage),callback:function ($$v) {_vm.computedStorageUsagePercentage=$$v},expression:"computedStorageUsagePercentage"}})],1)])]):_vm._e()],1),_vm._v(" "),_c('project-modal-name',{on:{"submit":_vm.onProjectAdded},model:{value:(_vm.isAddProjectModalOpen),callback:function ($$v) {_vm.isAddProjectModalOpen=$$v},expression:"isAddProjectModalOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }