<template>
  <mm-modal
    v-model="computedShowModal"
    :title="$t('action_bar.export.export_to_database.existing_database.modal_title')"
    width="510"
    closable
  >
    <div class="database-items d-flex">
      <div
        class="database-item cursor-pointer m-a-2"
        v-for="(database, index) in computedDatabaseItems"
        :key="index"
        @click="$emit('select', database)"
      >
        <mm-icon class="m-x-4 m-t-1" :name="database.logo" />
        <p class="database-name text-center m-x-2">{{ database.name }}</p>
      </div>
    </div>
  </mm-modal>
</template>

<script>
import { EXPORT_CONNECTORS_MAP, SPLITIO_FEATURE_FLAGS } from '@/constants'
import { featuresVisibility } from '@/plugins/splitio'

export default {
  name: 'action-bar-export-database-selection',
  props: {
    value: Boolean
  },
  data: () => ({
    featuresVisibility
  }),
  computed: {
    computedShowModal: {
      get() {
        return this.value
      },
      set(showModal) {
        this.$emit('input', showModal)
      }
    },
    computedDatabaseItems() {
      const connectors = [
        EXPORT_CONNECTORS_MAP.BIGQUERY,
        EXPORT_CONNECTORS_MAP.ELASTICSEARCH,
        EXPORT_CONNECTORS_MAP.MSSQL,
        EXPORT_CONNECTORS_MAP.MYSQL,
        EXPORT_CONNECTORS_MAP.POSTGRES,
        EXPORT_CONNECTORS_MAP.REDSHIFT
      ]

      const mappedConnectors = connectors.map((c) => ({
        name: c.name,
        handler: c.key,
        logo: c.mediumIcon,
        defaultPort: c.connection.exportDefaultPort || c.connection.fields.port.props.value
      }))

      return mappedConnectors.filter((c) => this.featuresVisibility[SPLITIO_FEATURE_FLAGS.EXPORT[c.handler]])
    }
  }
}
</script>

<style lang="scss" scoped>
.database-items {
  flex-wrap: wrap;

  .database-item {
    border: 1px solid var(--mm-color--n50);
    border-radius: 8px;

    &:hover {
      border-color: var(--mm-color--n90);
    }

    .database-name {
      margin-top: -11px;
      margin-bottom: 10px;
    }
  }
}
</style>
