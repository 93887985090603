/*jslint node: true */
import * as angular from "angular";
import * as $ from "jquery";
/**
 * @ngInject
 */
CardCollectionFactory.$inject = ['eventCallbackManagerFactory', 'utils'];
export function CardCollectionFactory(eventCallbackManagerFactory, utils){
  return CardCollection;


  function CardCollection(){
    var cc = this;
    let sortEvent = new eventCallbackManagerFactory('card_sort_event' + utils.string.random());
    cc.cards = [];
    cc.activeCardOrder = [];
    cc.addCard = addCard;
    cc.removeCard = removeCard;
    cc.reorder = reorder;
    cc.isCardActive = isCardActive;
    cc.applyFilters = applyFilters;
    cc.getCardById = getCardById;
    cc.fixAt = fixAt;
    cc.onReorder = sortEvent.add_callback;

    function addCard(card, index){
      if(!isNaN(index) && index >=0){
        card.at = index;
        cc.cards[index] = card;
      }
      else{
        cc.cards.push(card);
      }
      _reOrderCards();

    }

    function reorder(order){
      cc.activeCardOrder = order;
      _reOrderCards();
    }

    function _sort(a, b) {
      var aOrder = cc.activeCardOrder.indexOf(a.id);
      var bOrder = cc.activeCardOrder.indexOf(b.id);

      if(aOrder != -1 && bOrder != -1){
        return aOrder - bOrder;
      }
      else if(aOrder == -1 && bOrder != -1){
        return 1;
      }
      else if(aOrder != -1 && bOrder == -1){
        return -1;
      }
      else{
        return a.at - b.at;
      }
    }

    function fixAt(){
      angular.forEach(cc.cards, function(col, i){
        col.at = i;
      });
      sortEvent.fire_event();
    }

    function _reOrderCards(){
      cc.cards = cc.cards.sort(_sort);
      fixAt();
    }

    function removeCard(card){
      var cardIndex = cc.cards.indexOf(card);
      if(cardIndex != -1){
        cc.cards.splice(cardIndex, 1);
      }
      _reOrderCards();
    }

    function isCardActive(card){
      return cc.activeCardOrder.indexOf(card.id) != -1;
    }

    function applyFilters(getCondition){
      $.each(cc.cards, function(i, card){
        card.setCondition(getCondition(card.id));
        card.setActive(cc.activeCardOrder.indexOf(card.id) != -1)
      });
    }

    function getCardById(cid){
      var c = $.grep(cc.cards, function(card: any){
        return cid == card.id;
      });
      if(c.length == 1){
        return c[0];
      }
    }
  }
}







