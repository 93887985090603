import * as angular from 'angular';

/**
 * Creates an instance of event callback manager.
 *
 * @ngInject
 *
 * */
eventCallbackManagerFactory.$inject = ['$log', '$timeout'];
export function eventCallbackManagerFactory($log, $timeout) {
  return eventCallBackManager;
  function eventCallBackManager(event_name) {
    var callback_registry = {};
    var self = this;
    self.event_name = event_name;
    self.add_callback = add_callback;
    self.remove_callback = remove_callback;
    self.fire_event = fire_event;

    /**
     * Method to add a callback that gets executed (without any arguments) when self.fire_event is called.
     * Register the callback with a unique name.
     */
    function add_callback(cb_name, cb_fn) {
      if (typeof cb_fn !== "function" || typeof cb_name !== "string") {
        $log.error("Incorrect callback registration for " + self.event_name, cb_name, cb_fn);
        return
      }
      callback_registry[cb_name] = cb_fn;
      return function () {
        remove_callback(cb_name);
      }
    }

    function remove_callback(cb_name) {
      if(callback_registry.hasOwnProperty(cb_name)) {
        delete callback_registry[cb_name];
      }
    }

    function fire_event() {
      var fire_args = arguments;
      if (Object.keys(callback_registry).length) {
        angular.forEach(callback_registry, function (callback_fn: Function, callback_name) {
          if (typeof callback_fn === "function") {
            var self = this;
            $timeout(function(){
              callback_fn.apply(self, fire_args);
            }, 0);
          }
        });
      }
    }
  }
}
