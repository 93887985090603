import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
/**
 * @ngInject
 */
datapullByUrlController.$inject = ['$resource', 'config', 'toastNotification', 'landingList', 'utils', 'c', 'analyticsService'];
export function datapullByUrlController($resource, config, toastNotification, landingList, utils, c, analyticsService){
  this.$onInit = function() {
    var uvm = this;
    var weburlHandle = $resource(config.api.weburls);
    uvm.webUrl = "";
    uvm.sampleUrls = [
      'https://s3.amazonaws.com/mammothsamples/International-Visitors-London.csv.zip',
      'https://s3.amazonaws.com/mammothsamples/McDonalds-Menu.csv',
      'https://s3.amazonaws.com/mammothsamples/Store-Inactive_Employees.csv',
      'https://mammothsamples.s3.amazonaws.com/Store_Transactions.csv'];
    uvm.fileUploadDestinationResource = landingList.currentLabelView.label;
    uvm.landingList = landingList;

    uvm.submitUrl = function (url) {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.submitUrl,
        {
          eventOrigin: "addNewDsModal.fetchByUrl"
        });
      uvm.isLocked = true;
      let options = {'url': url};
      if (uvm.fileUploadDestinationResource) {
        options['label_resource_id'] = uvm.fileUploadDestinationResource.resource_id;
      }
      weburlHandle.save(options).$promise.then(function (data) {
        if (data.STATUS === 'FAILURE') {
          toastNotification.error(data.ERROR_MESSAGE);
          uvm.isLocked = false;
        } else {
          uvm.isLocked = false;
          uvm.close();
          let uploadedIntoResId = _.get(uvm.fileUploadDestinationResource, "resource_id");
          if (landingList.currentLabelResourceId !== uploadedIntoResId) {
            let label = uvm.fileUploadDestinationResource;
            let folderLink = ` <a href="#/landing/home">Data Library</a>`;
            if (uploadedIntoResId) {
              folderLink = ` folder <a href="#/landing/${label.resource_id}">${label.shortName}</a>`;
            }
            toastNotification.success(`File at <em>${utils.string.addCentreEllipsis(url)}</em> has been downloaded into` + folderLink);
          }
        }
      }, function (data) {
        let error_message = "Failed";
        if (data.data && data.data.ERROR_MESSAGE) {
          error_message += ": " + data.data.ERROR_MESSAGE;
        }
        toastNotification.error(error_message);

        uvm.isLocked = false;
      });
    };

    uvm.setUrl = function (sampleUrl) {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.setSampleUrl, {
        eventOrigin: "addNewDsModal.fetchByUrl"
      });
      uvm.webUrl = uvm.sampleUrls[sampleUrl];
    };

    uvm.onClose = function () {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.closeModal, {
        eventOrigin: "addNewDsModal.fetchByUrl.cancelButton"
      });
      uvm.close();
    };

  }
}
