'use strict';
/**
 * Main Mammoth app module that loads all the sub-modules as its dependencies.
 * @module app
 */
import "regenerator-runtime/runtime";
import * as $ from 'jquery';
window['$'] = $;
window['jquery'] = $;
window['jQuery'] = $;
import { jQueryBridget } from "../assets/scripts/lib/jQueryBridget";
import * as jqueryEventDrag from 'jquery.event.drag';
jqueryEventDrag($);
import * as jqueryScrollintoview from 'jquery.scrollintoview';
jqueryScrollintoview($);
import 'jquery-ui';
window['Flatpickr'] = Flatpickr;
window['confirmDatePlugin'] = confirmDatePlugin;
import * as angular from 'angular';
import 'angular-resource';
import { OnConfig } from './app.module.onConfig';
import * as numeral from 'numeral';
window['numeral'] = numeral;
import 'qtip2';
import '@uirouter/angularjs';
import 'angular-ui-bootstrap';
import 'angular-ui-sortable';
import 'angular-file-upload';
import 'angulartics';
import * as moment from 'moment';
window['moment'] = moment;
import 'angular-moment';
import 'angular-numeraljs';
import 'angular-ui-notification';
import 'angular-dragdrop';
import 'angular-animate';
import 'angular-sanitize';
import 'angular-vs-repeat';
import 'angular-recaptcha';
import 'angular-cache';
import * as humanize from 'humanize';
window['humanize'] = humanize;
import '../assets/scripts/lib/angular/angularjs-humanize/src/angular-humanize';
import * as Flatpickr from 'flatpickr';
import * as confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import * as bowser from 'bowser';
import './components/core';
import './components/raw';
import './components/admin';
import './components/publish';
import * as Raven from 'raven-js/dist/raven.js';
window['Raven'] = Raven;
import * as RavenAngularPlugin from 'raven-js/dist/plugins/angular.js';
import { config } from './components/common/app.config';
import { constants } from './components/common/app.constants';
import { deploymentConsts } from './app.deploymentConsts';
import { landingModule } from './components/landing';
import { expiredModule } from "./components/expired";
import { coreModule } from './components/core/index';
import { dataviewModule } from './components/dataview';
import { modelsModule } from './components/models';
import { elementModule } from './components/element/index';
import { helpModule } from './components/help/index';
import { topNavModule } from './components/topNav/index';
import { commonModule } from './components/common/index';
import { exploreModule } from './components/explore';
import { tabModule } from './components/tab/index';
import { rawDataModule } from './components/raw/index';
import { adminModule } from './components/admin/index';
import { publishModule } from './components/publish/index';
import * as papaParse from 'papaparse';
window['papaParse'] = papaParse;
import * as Packery from 'packery';
jQueryBridget('packery', Packery, $);
import * as draggabilly from 'draggabilly';
window['Draggabilly'] = draggabilly;
import 'amcharts3';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/xy';
import 'amcharts3/amcharts/pie';
import 'amcharts3/amcharts/funnel';
import 'amcharts3/amcharts/plugins/responsive';
import 'amcharts3/amcharts/plugins/animate';
import 'amcharts3/amcharts/themes/light';
import '../assets/scripts/lib/amCharts/autoOffsetAxis.js';
import { OnRun } from './app.run';
import { unstructuredDataModule } from './components/unstructured';
import 'ui-select';
import 'mammoth-selectize';
import "ngVue";
import "ngVue/build/plugins.js";
import keyCloakInstance, {KEYCLOAK_INIT_OPTIONS} from "../vueApp/src/mm-frontend/src/plugins/keycloak"

import ngVueComponentsModule from '../vueApp/src/ngVueBridgeCode/ngVueComponentsModule'

let isProd: boolean = false;
let releaseTag;
if (process && process.env && process.env.NODE_ENV == 'production' && process.env.gitTag) {
  isProd = true;
  releaseTag = process.env.gitTag;
}

/* angular formly uses api-check, with edit query stuff, this causing trouble.  - disabling it for production env.
 Reference: https://github.com/kentcdodds/api-check/issues/33#issuecomment-139446593
*/
var apiCheckFactory = require('api-check');
apiCheckFactory.globalConfig.disabled = isProd; // <-- do this before anything else to get maximum perf benefits

Raven
  .config('https://83264d42a5ba498482f39ebd9d596525@sentry.io/246082', {
    release: releaseTag,
    shouldSendCallback: function () {
      return isProd;
    },
    autoBreadcrumbs: isProd
  })
  .addPlugin(RavenAngularPlugin, angular)
  .install();


window['bowser'] = bowser;
if (window['bowser'].windows) {
  var body = document.body;
  body.classList ? body.classList.add('windows') : body.className += ' windows';
}

angular.element(document).ready(function () {
  var requires = [
    'ngResource',
    'ngAnimate',
    'ui.router',
    'ui.bootstrap',
    'ui.sortable',
    rawDataModule.name,
    coreModule.name,
    modelsModule.name,
    tabModule.name,
    commonModule.name,
    topNavModule.name,
    landingModule.name,
    expiredModule.name,
    dataviewModule.name,
    helpModule.name,
    exploreModule.name,
    adminModule.name,
    publishModule.name,
    unstructuredDataModule.name, //TODO: convert to TS
    'angulartics',
    'ui-notification',
    'ngDragDrop',
    'ngSanitize',
    'vs-repeat',
    'ngNumeraljs',
    'ui.select',
    ngVueComponentsModule.name,
    "ngVue",
    "ngVue.plugins",
    "angular-cache",
  ];
  // mount on window for testing
  window['angular'] = angular;
  let app = window['angular'].module('app', requires);
  app.constant('config', config);
  app.constant('c', constants);
  app.constant('keyCloak', keyCloakInstance);
  app.config(OnConfig);
  app.run(OnRun);
  app.factory('$exceptionHandler', ['$log', '$window', function ($log, $window) {
    return function myExceptionHandler(exception, cause) {
      $log.error(exception, cause);
      Raven.captureException(exception);

    };
  }]);
  var bootstrap = function () {

    // rewrite bookmarked workspaces url to dataviews
    // if (angular.element.ajaxSettings.url.match('workspaces')){
    //   window.location.href = angular.element.ajaxSettings.url.replace('workspaces', 'dataviews');
    // }

    keyCloakInstance.init(KEYCLOAK_INIT_OPTIONS as any).then(
      function (authenticated) {
        if (authenticated) {
          localStorage.setItem("authenticated", "true")
          angular.element(document).ready(function () {
            angular.bootstrap(document, ['app']);//, {strictDi: true});
          });
        }
        else {
          // clear all entries in local storage whose key starts with 'mmCache_', use this to clear all cache entries
          Object.keys(localStorage)
            .filter(key => key.startsWith('mmCache_'))
            .forEach(key => localStorage.removeItem(key));

          keyCloakInstance.login();
        }
      }
    )
  };
  Raven.context(function () {
    bootstrap();
  });

});
