import * as _ from 'lodash-es';

/**
 * @ngInject
 * @returns {{get_manager: get_manager}}
 */
selectManagerFactory.$inject = ['filterManagerFactory', 'utils'];
export function selectManagerFactory(filterManagerFactory, utils) {
  return {get_manager: get_manager};

  function get_manager(options) {
    return new SelectManager(options);
  }

  function SelectManager(options){
    var self = this;
    var metadata = options.metadata;
    self.metadata = metadata;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.getParam = getParam;
    self.setParam = setParam;
    self.validate = validate;
    self.handlePasteParams = handlePasteParams;

    let filterOptions = {
      metadata:metadata,
      teardownOptions: {allow_teardown: false},
      dataviewId: options.context.dataview.id,
      originalSequenceNumber: options.originalSequenceNumber,
      context: options.context
    };

    self.filterManager = filterManagerFactory.get_manager(filterOptions);
    self.filterManager.add_root_condition();

    function validate(){
      return self.filterManager.condition.validate();
    }

    function handlePasteParams(taskInfo){
      var param = taskInfo.params
      /** Update condition params with suitable replacement columns, based on display name*/
      var columnUsedInCondition = utils.get_input_columns(_.cloneDeep(param.CONDITION))
      if (columnUsedInCondition) {
        utils.metadata.findReplaceColumnsInCondition(self.metadata, self.displayNameAndTypeToColumnMap, param, columnUsedInCondition, taskInfo)
      }
      self.filterManager.metadata = self.metadata
      return param
    }

    function getParam(){
      var params = {
        CONDITION: self.filterManager.getParam(),
        SELECT: 'ALL'
      };
      if (options.context.hasOwnProperty('sequence')){
        params['SEQUENCE_NUMBER'] = options.context['sequence']
      }
      return params
    }

    function setParam(param) {
      self.filterManager.setParam(param.CONDITION, param?.EXECUTION_TIMESTAMP);
    }
  }
}

