<template>
  <div class="data-flow-container d-flex flex-column justify-content-center align-items-center">
    <h4 v-if="error" class="data-flow-map-title text-danger">{{ error }}</h4>
    <div id="cy" class="cy"></div>
    <div id="cy-navigator"></div>
    <mm-row justify="space-between" class="w-100 align-items-center">
      <mm-col cols="8">
        <mm-row>
          <mm-checkbox label="Join" v-model="showJoins" @click="getResourceData(resourceId)" class="m-x-4" />
          <mm-checkbox label="Branch Out" v-model="showBranchOuts" @click="getResourceData(resourceId)" class="m-x-4" />
          <mm-checkbox label="Barren Views" v-model="showViews" @click="getResourceData(resourceId)" class="m-x-4" />
          <mm-checkbox label="Lookup" v-model="showLookup" @click="getResourceData(resourceId)" class="m-x-4" />
          <mm-checkbox label="Crosstab" v-model="showCrosstab" @click="getResourceData(resourceId)" class="m-x-4" />
        </mm-row>
      </mm-col>
      <mm-col cols="4" class="action-container">
        <button @click="resizeModal">
          <svg
            v-if="maximize"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#3D4352"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-minimize"
          >
            <path
              d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"
            ></path>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#3D4352"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-maximize"
          >
            <path
              d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"
            ></path>
          </svg>
        </button>
      </mm-col>
    </mm-row>
  </div>
</template>

<script>
import { find } from 'lodash'
import cytoscape from '../../../../../node_modules/cytoscape/dist/cytoscape.min.js'
import cydagre from 'cytoscape-dagre'
import nodeHtmlLabel from 'cytoscape-node-html-label'
import navigator from 'cytoscape-navigator'

nodeHtmlLabel(cytoscape)
cydagre(cytoscape)
navigator(cytoscape)

function renderIcon(icon, active) {
  let textColor = '#3D4352'
  if (active) {
    textColor = '#ffffff'
  }

  if (icon === 'dataset') {
    return `
      <svg data-name="dataset" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mm-icon" style="--mm-icon--color: ${textColor}; --mm-icon--color-hover: ${textColor};"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.76411 2.06612C7.91038 1.97796 8.09323 1.97796 8.23949 2.06612L13.7763 5.40358C13.9151 5.48725 14 5.63777 14 5.80019C14 5.96261 13.9151 6.11313 13.7763 6.1968L12.6166 6.89581L13.7732 7.59504C13.9119 7.6789 13.9966 7.82956 13.9964 7.99199C13.9962 8.15443 13.911 8.30485 13.7721 8.38831L12.5889 9.09904L13.7732 9.81504C13.9119 9.8989 13.9966 10.0496 13.9964 10.212C13.9962 10.3744 13.911 10.5248 13.7721 10.6083L8.23528 13.9342C8.08911 14.022 7.90659 14.0219 7.76054 13.9339L2.22375 10.5971C2.08478 10.5134 1.99984 10.3626 2 10.2C2.00016 10.0374 2.0854 9.88685 2.22454 9.80338L3.41014 9.09211L2.22375 8.37712C2.08478 8.29337 1.99984 8.14262 2 7.98003C2.00016 7.81743 2.0854 7.66685 2.22454 7.58338L3.37881 6.8909L2.22732 6.1968C2.08851 6.11313 2.0036 5.96261 2.0036 5.80019C2.0036 5.63777 2.08851 5.48725 2.22732 5.40358L7.76411 2.06612ZM4.3069 9.63254L3.35869 10.2014L7.99858 12.9976L12.6392 10.2101L11.6923 9.63761L8.23528 11.7142C8.08911 11.802 7.90659 11.8019 7.76054 11.7139L4.3069 9.63254ZM8.23949 9.53426L11.7216 7.43532L12.6392 7.99006L7.99858 10.7776L3.35869 7.98139L4.27548 7.43139L7.76411 9.53426C7.91038 9.62242 8.09323 9.62242 8.23949 9.53426ZM3.36067 5.80019L8.0018 8.59776L12.6429 5.80019L8.0018 3.00262L3.36067 5.80019Z" fill="#3D4352"></path></svg>
      `
  }
  return `
    <svg
      data-name="view"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mm-icon"
      style="--mm-icon--color: ${textColor}; --mm-icon--color-hover:${textColor};"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3ZM6 3H13V5L6 5V3ZM5 3H3L3 5H5V3ZM5 6H3L3 13H5V6ZM6 13H13V6L6 6V13Z"
        fill="${textColor}"
      ></path>
    </svg>
    `
}

function renderHtmlNode(data) {
  let textClasses = 'node-name'
  if (data.active) {
    textClasses = textClasses + ' active'
  }
  return `
  <div class="node-container">
    <div class="icon-container m-r-2">
      ${renderIcon(data.icon, data.active)}
  </div>
  <div class="icon-container">
    <p class="${textClasses}">${data.name}</p>
    </div>
  </div>`
}

export default {
  name: 'DependencyGraph',
  data() {
    return {
      nodes: [],
      edges: [],
      error: null,
      showJoins: true,
      showViews: true,
      showBranchOuts: true,
      showLookup: true,
      showCrosstab: true,
    }
  },
  props: {
    resourceId: Number | String,
    maximize: Boolean,
  },
  computed: {
    computedResources() {
      return this.$store.state.resources.resourcesMap
    },
  },
  mounted() {
    this.getResourceData(this.resourceId)
  },
  beforeDestroy() {
    this.nav.destroy()
  },
  methods: {
    getChartData(response, datasource) {
      let nodes = []
      // relationship
      let links = []

      let icon = 'dataset'
      if (datasource.type === 'view') {
        icon = 'view'
      }

      nodes.push({
        data: {
          id: datasource.resourceId,
          name: datasource.properties.name,
          description: '',
          width: datasource.properties.name?.length * 12,
          active: true,
          icon,
        },
      })

      const getNode = (datasource, data, active = false) => {
        let type = data.operationName || ''
        let name = datasource.properties.name
        let icon = 'dataset'
        if (datasource.type === 'view') {
          icon = 'view'
        }
        if (type) {
          if (type === 'JOIN') {
            type = 'Join'
            let dataset = Object.values(this.computedResources).find(
              (r) => r.type === 'dataset' && r.datasetId === datasource.properties.dataset.id
            )
            name = `${dataset.properties.name} > ${name}`
          } else if (type === 'BRANCH_OUT') {
            type = 'Branch Out'
          } else if (type === 'DATAVIEW') {
            type = ''
          } else if (type === 'LOOKUP') {
            type = 'Lookup'
            let dataset = Object.values(this.computedResources).find(
              (r) => r.type === 'dataset' && r.datasetId === datasource.properties.dataset.id
            )
            name = `${dataset.properties.name} > ${name}`
          } else if (type === 'CROSSTAB') {
            type = 'Crosstab'
          }
        }
        return {
          id: data.resourceId,
          name,
          description: type,
          width: name?.length * 12,
          active,
          type,
          icon,
        }
      }

      // this function is to find the parent or children resources based on the direction.
      // if direction is 0 function will recurse through parent resources
      // if direction is 1 function will recurse through children resources
      const getData = (datasource, direction = 0) => {
        const resources = direction ? datasource?.dependenciesUnfiltered?.out : datasource.dependenciesUnfiltered?.in
        for (const element of resources) {
          const newDatasource = response[element.resourceId]
          if (!newDatasource) continue
          const node = getNode(newDatasource, element)
          if (!this.showBranchOuts && node.type === 'Branch Out') {
            continue
          } else if (!this.showJoins && node.type === 'Join') {
            continue
          } else if (!this.showViews && node.type === '' && !newDatasource.dependenciesUnfiltered.out.length) {
            continue
          } else if (!this.showLookup && node.type === 'Lookup') {
            continue
          } else if (!this.showCrosstab && node.type === 'Crosstab') {
            continue
          }

          if (!find(nodes, (x) => x.data.id == node.id)) {
            nodes.push({
              data: node,
            })
          }

          let source = direction ? datasource.resourceId : node.id
          let target = direction ? node.id : datasource.resourceId
          if (!find(links, (item) => item.data.source === source && item.data.target === target)) {
            links.push({
              data: {
                source,
                target,
                label: node.description,
              },
            })
          }
          if (newDatasource) {
            newDatasource.resourceId = node.id
            getData(newDatasource, direction)
          }
        }
      }

      getData(datasource, 0)
      getData(datasource, 1)
      return { nodes, links }
    },

    async getResourceData(resourceId) {
      // resource id is the active dataset in preview  panel
      const datasource = this.computedResources[resourceId]
      if (!datasource) {
        this.error = 'No data found! Please try refreshing the page'
        return
      }
      datasource.resourceId = resourceId
      // all entries
      let { nodes, links } = this.getChartData(this.computedResources, datasource)
      this.nodes = nodes
      this.edges = links
      setTimeout(() => {
        this.drawGraph()
      }, 500)
    },
    drawGraph() {
      let cy = cytoscape({
        container: document.getElementById('cy'),
        boxSelectionEnabled: false,
        autounselectify: true,
        wheelSensitivity: 0.4,
        style: cytoscape
          .stylesheet()
          .selector('node')
          .css({
            shape: 'roundrectangle',
            height: 40,
            width: 'data(width)',
            'background-color': (node) =>
              node.data('active') ? 'green' : node.data('icon') === 'view' ? '#D8ECE0' : '#1BE296',
            color: (node) => (node.data('active') ? 'white' : 'gray'),
            'border-color': (node) => (node.data('active') ? '#004C3A' : '#004C3A'),
            'border-width': 3,
            'border-radius': 4,
            // content: 'data(name)',
            'text-wrap': 'wrap',
            'text-valign': 'center',
            'text-halign': 'left',
          })
          .selector('edge')
          .css({
            // http://js.cytoscape.org/#style/labels
            label: 'data(label)', // maps to data.label
            'text-outline-color': 'white',
            'text-outline-width': 3,
            'text-valign': 'top',
            'text-halign': 'left',
            // https://js.cytoscape.org/demos/edge-types/
            'curve-style': 'bezier',
            width: 3,
            'target-arrow-shape': 'triangle',
            'line-color': '#004C3A',
            'target-arrow-color': '#004C3A',
          }),

        elements: {
          nodes: this.nodes,
          edges: this.edges,
        },

        layout: {
          name: 'dagre',
          spacingFactor: 1.5,
          rankDir: 'LR',
        },
      })
      cy.bind('dblclick', (event) => {
        let item = event.target.data()
        this.getResourceData(item.id)
      })
      // Initialise the HTML Label
      cy.nodeHtmlLabel([
        {
          query: 'node',
          tpl: function (data) {
            return renderHtmlNode(data)
          },
        },
      ])
      cy.fit(cy.nodes()[0].data(this.resourceId), 260)
      let nav = cy.navigator({
        container: '#cy-navigator',
        viewLiveFramerate: 0, // set false to update graph pan only on drag end; set 0 to do it instantly; set a number (frames per second) to update not more than N times per second
        thumbnailEventFramerate: 30, // max thumbnail's updates per second triggered by graph updates
        thumbnailLiveFramerate: false, // max thumbnail's updates per second. Set false to disable
        dblClickDelay: 200, // milliseconds
        removeCustomContainer: true, // destroy the container specified by user on plugin destroy
        rerenderDelay: 100, // ms to throttle rerender updates to the panzoom for performance
      })
      this.nav = nav
      this.cy = cy
    },
    resizeModal() {
      this.$emit('resize')
    },
  },
}
</script>
<style lang="scss">
@import '../../../../../node_modules/cytoscape-navigator/cytoscape.js-navigator.css';

.data-flow-container {
  overflow: hidden;
  .data-flow-map-title {
    background-color: transparent !important;
  }

  .cy {
    @media only screen and (min-height: 900px) {
      min-height: 730px;
    }
    min-width: 100%;
    min-height: 540px;
  }
  .node-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .node-name {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active {
    color: white;
    fill: white;
  }

  .action-container {
    display: flex;
    justify-content: end;
  }
}

#cy-navigator {
  position: absolute;
  border: 1px solid #000;
  background: #fff;
  z-index: 99999;
  width: 200px;
  height: 140px;
  bottom: 60px;
  right: 40px;
  overflow: hidden;
}
</style>
