<template>
  <div v-if="details">
    <strong>{{ details.id }}. {{ details.name }}</strong>
    <span>({{ details.status }})</span>
    <div class="container mt-3">
      <div class="row">
        <div class="col-6">
          <div>
            <p>
              <strong><i class="fa fa-hashtag"></i> Workspace ID: </strong>{{ details.id }}
            </p>
          </div>
          <div>
            <p>
              <strong><i class="fa fa-database"></i> Usage: </strong>{{ details.storageUsedInBytes | pretty }}
            </p>
          </div>
          <div>
            <p>
              <strong><i class="fa fa-calendar"></i> Created: </strong>{{ new Date(details.created_at) }}
            </p>
          </div>
          <div>
            <p>
              <strong><i class="fa fa-calendar"></i> Subscription ID: </strong>{{ details.subscription_id || 'NA' }}
            </p>
            <div v-if="details.subscription_id">
              <mm-button
                v-if="!enabledEditSubscription"
                class="m-l-4"
                objective="primary"
                small
                label="Edit Susbscription"
                @click="enabledEditSubscription = !enabledEditSubscription"
              />
              <div v-else class="d-flex p-l-4" style="align-items: end">
                <mm-text-input
                  v-model="subscriptionInput"
                  hideDetails
                  title="Change Subscription ID"
                  placeholder="Enter Subscription ID"
                />
                <mm-button
                  class="m-l-4"
                  :loading="showSubscriptionIdUpdateLoader"
                  label="Submit"
                  objective="primary"
                  @click="updateSubscriptionId"
                />
                <mm-button
                  class="m-l-4"
                  :loading="showSubscriptionIdUpdateLoader"
                  label="Canel"
                  objective="destructive"
                  @click="enabledEditSubscription = !enabledEditSubscription"
                />
              </div>
            </div>
          </div>
          <div v-if="!details.subscription_id" class="p-l-4">
            <mm-button
              v-if="!showAddNewCustomerInfoOptions"
              objective="primary"
              label="ADD CUSTOMER INFO"
              @click="showAddNewCustomerInfoOptions = !showAddNewCustomerInfoOptions"
            />
            <div v-if="showAddNewCustomerInfoOptions">
              <mm-segmented-control
                v-model="isNewCustomer"
                class="m-t-4"
                :items="[
                  { text: 'New Customer', value: true },
                  { text: 'Existing Customer', value: false },
                ]"
              />
              <div v-if="isNewCustomer" class="m-t-4">
                <div clas="d-flex">
                  <mm-text-input
                    v-model="customerFirstName"
                    style="display: inline-block"
                    title="First name"
                    placeholder="e.g. John"
                    type="text"
                  />
                  <mm-text-input
                    v-model="customerLastName"
                    class="m-l-4"
                    style="display: inline-block"
                    title="Last name"
                    placeholder="e.g. Anderson"
                    type="text"
                  />
                </div>
                <div class="d-flex m-t-2">
                  <mm-text-input
                    v-model="customerEmail"
                    style="display: inline-block"
                    title="Email"
                    placeholder="e.g. abc@example.com"
                    type="text"
                  />
                  <mm-text-input
                    v-model="customerCompanyName"
                    class="m-l-4"
                    style="display: inline-block"
                    title="Company Name"
                    placeholder="e.g. ABC Corp"
                    type="text"
                  />
                </div>
              </div>
              <div v-else class="m-t-4">
                <mm-dropdown
                  v-model="selectedExistingCustomer"
                  :items="customerOptions"
                  :width="355"
                  title="Customer"
                  placeholder="Select an existing customer"
                />
              </div>
              <mm-dropdown
                title="Choose a plan"
                :value="planId"
                :width="355"
                :items="plans"
                placeholder="Select plan"
                item-text="name"
                item-value="id"
                @input="onPlanChange"
              />
              <mm-button
                objective="primary"
                label="SUBMIT"
                :loading="isCustomerInfoUpdationInProgress"
                @click="updateCustomerInfo"
              />
              <mm-button
                class="m-l-6"
                objective="destructive"
                label="CANCEL"
                :loading="isCustomerInfoUpdationInProgress"
                @click="resetCustomerInfo"
              />
            </div>
          </div>

          <div class="m-t-4 p-l-4" v-show="chargebeeUrl">
            <h6><b>Chargebee Details:</b></h6>
            <div class="view-lnk">
              <div>View: <mm-link label="Profile" :href="'https://' + chargebeeUrl" openInNewTab /></div>
              <div>
                Dev View:
                <mm-link
                  label="Toggle"
                  :href="null"
                  :disabled="null"
                  :small="null"
                  :openInNewTab="null"
                  @click="showsmsdata = !showsmsdata"
                />
              </div>
            </div>
            <pre v-show="showsmsdata" class="border p-2">{{ smsDataJson }}</pre>
          </div>
          <div class="m-t-4" @click="showUsers = !showUsers" style="width: fit-content">
            <p>
              <strong class="users">
                <i class="fa fa-users"></i> Users: ({{ users.length || 'NA' }})
                <span>
                  <i class="fa fa-eye" v-show="showUsers"></i><i class="fa fa-eye-slash" v-show="!showUsers"></i>
                </span>
              </strong>
            </p>
          </div>
          <div class="m-t-4 p-l-4">
            <mm-button objective="destructive" label="DELETE WORKSPACE" @click="openDeleteWorkspaceModal" />
            <component
              :is="workspaceDeletionModalComponent"
              v-model="isDeleteWorkspaceModalOpen"
              :details="details"
            ></component>
          </div>
        </div>
        <div class="col-6 ps-0">
          <div class="users ps-3 p-3 border-start" v-show="showUsers">
            <strong class="mt-5"><i class="fa fa-users"></i> Users ({{ details.total_users }})</strong>
            <mm-button
              class="ml-btn ml-btn-success m-l-4"
              v-if="details.name != 'MAMMOTH_SUPER_ADMIN_WORKSPACE'"
              label="+ ADD USER"
              small
              @click="showAddUserModal"
            />
            <div v-if="addUserModal">
              <div class="add-user row">
                <mm-text-input
                  class="input-user-name col-6"
                  title="First name"
                  :value="firstName"
                  placeholder="Enter first name"
                  type="text"
                  @input="setFirstName"
                />
                <mm-text-input
                  class="input-user-name col-6"
                  title="Last name"
                  :value="lastName"
                  placeholder="Enter last name"
                  type="text"
                  @input="setLastName"
                />
                <mm-text-input
                  class="input-user-email col-6"
                  title="Email"
                  :value="emailVal"
                  placeholder="Enter email"
                  type="email"
                  @input="setEmail"
                  :error="showError"
                  :message="emailError"
                />

                <mm-dropdown
                  class="input-user-email drp col-6"
                  title="Role"
                  placeholder="Select Role"
                  :value="selectedRole"
                  :items="options"
                  @input="onRoleChange"
                />
              </div>
              <mm-button class="ml-btn ml-btn-success mb-10" label="Submit" @click="addUser" :disabled="!formFilled" />
              <mm-button
                class="ml-btn ml-btn-success mb-10"
                objective="tertiary"
                label="Cancel"
                @click="addUserModal = false"
              />
            </div>
            <template>
              <div>
                <mm-modal
                  :value="showModal"
                  :persistent="null"
                  :width="null"
                  :maxWidth="modalWidth"
                  title="Remove user"
                  :closable="null"
                  :actions="null"
                  :default="null"
                  :input="null"
                >
                  <template #default>
                    <label class="mm-text--body-regular" v-if="deleteUser">
                      Are you sure that you want to remove <b>{{ deleteUser.name }}</b> ({{ deleteUser.email }})?
                    </label>
                  </template>
                  <template #actions>
                    <div class="d-flex">
                      <div class="p-r-2 w-50">
                        <mm-button class="width-100" label="Cancel" objective="tertiary" @click="showModal = false" />
                      </div>
                      <div class="p-r-2 w-50">
                        <mm-button
                          class="width-100"
                          label="Remove user"
                          objective="destructive"
                          @click="deleteUserFromWorkspace"
                        />
                      </div>
                    </div>
                  </template>
                </mm-modal>
              </div>
            </template>
            <div class="mt-3 user-list" v-for="(user, index) in users" :key="user.id" v-show="!loading">
              <p class="mb-0">
                <strong>{{ index + 1 }}.</strong> {{ user.name }} (<strong
                  ><small
                    >{{ primaryRole(user.user_roles) }}{{ setOwnerUser(primaryRole(user.user_roles), user) }}</small
                  ></strong
                >)
              </p>
              <p class="ms-2 mb-0">{{ user.email }}</p>
              <button
                v-if="primaryRole(user.user_roles) !== 'workspace_owner'"
                class="ms-2 btn-style"
                @click="transferOwnership(details, user)"
              >
                <span v-if="!transferLoading" v-show="!(details.name == 'MAMMOTH_SUPER_ADMIN_WORKSPACE')"
                  >Transfer Ownership</span
                ><span v-if="transferLoading">loading...</span>
              </button>
              <i
                class="fa fa-trash ms-2 trash"
                v-show="!(details.name == 'MAMMOTH_SUPER_ADMIN_WORKSPACE')"
                @click="openDeleteModal(details, user)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalApi from '@/api/global.api'
import humanize from 'humanize'
import { admin_roles } from '../../../admin.const'
import WorkspaceDeletionModal from './WorkspaceDeletionConfirmation.vue'

export default {
  components: { WorkspaceDeletionModal },
  props: ['details'],
  data: (vm) => {
    return {
      isDeleteWorkspaceModalOpen: false,
      addUserModal: false,
      firstName: '',
      lastName: '',
      emailVal: '',
      selectedRole: '',
      options: ['workspace_member', 'workspace_admin'],
      plans: [],
      users: [],
      loading: true,
      transferLoading: false,
      showUsers: false,
      ownerUser: null,
      chargebeeUrl: null,
      smsDataJson: null,
      showsmsdata: false,
      planId: null,
      showError: false,
      emailError: '',
      formFilled: false,
      showModal: false,
      deleteUser: null,
      modalWidth: 400,
      workspace: null,
      workspaceDeletionModalComponent: WorkspaceDeletionModal,
      isNewCustomer: true,
      showAddNewCustomerInfoOptions: false,
      customerFirstName: null,
      customerLastName: null,
      customerEmail: null,
      customerCompanyName: null,
      selectedExistingCustomer: null,
      customerOptions: [],
      isCustomerInfoUpdationInProgress: false,
      enabledEditSubscription: false,
      subscriptionInput: '',
      showSubscriptionIdUpdateLoader: false,
    }
  },
  created() {
    this.subscriptionInput = { ...this.details }.subscription_id
  },
  methods: {
    openDeleteWorkspaceModal() {
      this.isDeleteWorkspaceModalOpen = true
    },
    primaryRole(roles) {
      return roles.workspaces[this.details.id]
    },
    setFirstName(value) {
      this.firstName = value
      this.isFormFilled()
    },
    setLastName(value) {
      this.lastName = value
      this.isFormFilled()
    },
    setEmail(value) {
      this.emailVal = value
      this.isFormFilled()
      this.showError = false
      this.emailError = ''
    },
    onRoleChange(value) {
      this.selectedRole = value
      this.isFormFilled()
    },
    isFormFilled() {
      if (this.firstName && this.lastName && this.emailVal && this.selectedRole) {
        this.formFilled = true
      } else {
        this.formFilled = false
      }
    },
    showAddUserModal() {
      this.formFilled = false
      this.firstName = ''
      this.lastName = ''
      this.emailVal = ''
      this.showError = false
      this.emailError = ''
      this.addUserModal = true
      this.isFirstUser()
    },
    onPlanChange(value) {
      this.planId = value
    },
    updateSubscriptionId() {
      this.showSubscriptionIdUpdateLoader = true
      this.$axios
        .patch(`/admin/workspaces/${this.details.id}`, {
          patch: [
            {
              op: 'replace',
              path: 'subscription_id',
              value: this.subscriptionInput,
            },
          ],
        })
        .then((response) => {
          this.details.subscription_id = this.subscriptionInput
          this.enabledEditSubscription = false
          this.$toast.show({ content: `Subscription ID updated successfully`, status: 'success' })
          this.showSubscriptionIdUpdateLoader = false
        })
        .catch((error) => {
          const message = error.response?.data?.ERROR_MESSAGE || 'Error updating susbcription id'
          this.$toast.show({ content: message, status: 'error' })
          this.showSubscriptionIdUpdateLoader = false
        })
    },
    async trackFutureRequest(response) {
      let viewInfoResponse = await globalApi.getFutureRequest(response.data.future_id)
      if (viewInfoResponse.status == 'success') {
        this.$toast.show({ content: `Customer info updated successfully`, status: 'success' })
        this.uponCustomerInfoUpdation()
      } else this.$toast.show({ content: `Error updating customer info`, status: 'error' })
      this.isCustomerInfoUpdationInProgress = false
    },
    uponCustomerInfoUpdation() {
      this.$axios.get('admin/workspaces').then((response) => {
        this.$store.dispatch('setWorkspaces', response.data.workspaces)
        const currentWorkspace = response.data.workspaces.find((wksp) => wksp.id === this.details.id)
        this.details.subscription_id = currentWorkspace.subscription_id
        this.resetCustomerInfo()
      })
    },
    resetCustomerInfo() {
      this.customerFirstName = ''
      this.customerLastName = ''
      this.customerEmail = ''
      this.customerCompanyName = ''
      this.showAddNewCustomerInfoOptions = false
    },
    updateCustomerInfo() {
      let postData
      if (this.isNewCustomer) {
        postData = {
          first_name: this.customerFirstName,
          last_name: this.customerLastName,
          email: this.customerEmail,
          company: this.customerCompanyName,
          plan_id: this.planId,
        }
      } else {
        postData = {
          customer_id: this.selectedExistingCustomer,
          plan_id: this.planId,
        }
      }

      this.$axios
        .post(`/admin/workspaces/${this.details.id}`, postData)
        .then((response) => {
          if (response.data.STATUS == 'PROCESSING') {
            this.isCustomerInfoUpdationInProgress = true
            this.trackFutureRequest(response)
          } else if (response.data.STATUS == 'SUCCESS') {
            this.$toast.show({ content: `Customer info updated successfully`, status: 'success' })
            this.uponCustomerInfoUpdation()
          }
        })
        .catch((error) => {
          this.$toast.show({ content: `Error updating customer info`, status: 'error' })
        })
    },
    ValidateEmailAddress(emailString) {
      // check for @ sign
      var atSymbol = emailString.indexOf('@')
      if (atSymbol < 1) return false
      var dot = emailString.indexOf('.')
      if (dot < 1) return false
      // check that the dot is not at the end
      if (dot === emailString.length - 1) return false
      return true
    },
    addUser() {
      if (this.firstName && this.lastName && this.emailVal && this.selectedRole) {
        if (this.ValidateEmailAddress(this.emailVal)) {
          var postData = {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.emailVal,
            role: this.selectedRole.toLowerCase(),
          }
          if (this.details.id) {
            postData['workspace_id'] = this.details.id
          }
          if (this.ownerUser) {
            postData['owner'] = this.ownerUser
          }
          this.$axios.post('/admin/users', postData).then((response) => {
            if (response.status === 200) {
              this.firstName = ''
              this.lastName = ''
              this.emailVal = ''
              this.addUserModal = false
              this.$axios.get(`admin/workspaces/${this.details.id}`).then((response) => {
                this.users = response.data.users || []
                this.isFirstUser()
              })
            }
          })
        } else {
          this.showError = true
          this.emailError = 'Please enter a valid email address'
        }
      }
    },

    setOwnerUser(role, user) {
      if (role === admin_roles.owner) {
        this.ownerUser = user
      }
    },
    isFirstUser() {
      var isOwnerExist = false
      for (var i = 0; i < this.users.length; i++) {
        var role = this.primaryRole(this.users[i].user_roles)
        if (role == admin_roles.owner) {
          isOwnerExist = true
          break
        }
      }
      if (isOwnerExist) {
        this.options = ['workspace_member', 'workspace_admin']
      } else {
        this.options = ['workspace_member', 'workspace_admin', 'workspace_owner']
      }
    },
    transferOwnership(workspace, user) {
      this.transferLoading = true
      let oldOwnerId = this.ownerUser.id
      let current_user_role = user.user_roles.workspaces[workspace.id]
      let patchData = {
        "patch": [
          {
            "op": "replace",
            "path": "role",
            "value": {
              "user_id": user.id,
              "role": admin_roles.owner,
              "remove_role": current_user_role
            }
          },
          {
            "op": "replace",
            "path": "role",
            "value": {
              "user_id": oldOwnerId,
              "role": admin_roles.admin,
              "remove_role": admin_roles.owner
            }
          }
        ]
      }

      this.$axios.patch(`admin/workspaces/${workspace.id}`, patchData).then((response) => {
        if (response.status === 200) {
              this.$axios.get(`admin/workspaces/${workspace.id}`).then((response) => {
              this.users = response.data.users || []
              this.ownerUser.id = user.id
              this.transferLoading = false
              this.isFirstUser()
            })
        }
      })
    },
    openDeleteModal(workspace, user) {
      this.workspace = workspace
      this.deleteUser = user
      this.showModal = true
    },
    deleteUserFromWorkspace() {
      this.showModal = false
      var workspace = this.workspace
      var user = this.deleteUser
      this.$axios.delete(`/admin/workspaces/${workspace.id}/users/${user.id}`).then((response) => {
        if (response.status === 200) {
          this.$axios.get(`admin/workspaces/${workspace.id}`).then((response) => {
            this.users = response.data.users || []
            this.isFirstUser()
          })
        }
      })
    },
    setupUserDetailsScreen() {
      this.addUserModal = false
      if (this.details.id) {
        this.$axios.get(`/admin/workspaces/${this.details.id}/sms`).then((response) => {
          this.chargebeeUrl = response.data.url
          this.smsDataJson = response.data
          this.isFirstUser()
        })
      }
    },
  },
  filters: {
    pretty: function (value) {
      return humanize.filesize(value)
    },
  },
  mounted() {
    this.$axios.get(`admin/workspaces/${this.details.id}`).then((response) => {
      this.loading = false
      this.users = response.data.users || []
    })

    this.$axios.get('/admin/sms?query=plans').then((response) => {
      let available_plans = response.data.available_plans
      const planIds = available_plans.map((plan) => plan.id)
      this.plans = planIds
      this.planId = planIds[0]
    })
    this.$axios.get('/admin/sms?query=customers').then((response) => {
      this.customers = response.data.customers
      this.customerOptions = this.customers
        .map((customer) => {
          return {
            text: (customer.customer_name || 'NA') + ' (' + (customer.email || 'NA') + ') - ' + customer.customer_id,
            value: customer.customer_id,
          }
        })
        .sort((a, b) => a.text.localeCompare(b.text))
    })
    this.setupUserDetailsScreen()
  },
  watch: {
    details: function (newDetails, oldDetails) {
      this.options = ['workspace_member', 'workspace_admin']
      this.chargebeeUrl = null
      this.smsDataJson = null
      this.loading = true
      this.subscriptionInput = { ...newDetails}.subscription_id
      this.$axios.get(`admin/workspaces/${newDetails.id}`).then((response) => {
        this.loading = false
        this.users = response.data.users || []
        this.setupUserDetailsScreen()
      })
    },
  },
}
</script>
<style scoped lang="scss">
.trash:hover {
  cursor: pointer;
}
.users:hover {
  cursor: pointer;
}
.input-user-name {
  margin-top: 10px;
}
.input-user-email {
  margin-top: 10px;
}

.view-lnk {
  margin-left: 20px;
}
.drp {
  min-width: 150px !important;
  margin-top: 8px;
}
.subs-btn {
  margin-left: 20px;
  margin-top: -5px;
}
.subs-drp {
  max-width: 250px;
  min-width: 250px;
  margin-left: 20px;
  margin-top: -5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ml-5 {
  margin-left: 5px;
}
.user-list {
  display: flex;
  flex: 1 1 0px;
  align-items: center;
}
.btn-style {
  border: none;
  padding: 0px;
  background: skyblue;
  border-radius: 1em;
}
</style>
