/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
/**
 * @ngInject
 */

metricMenuManagerFactory.$inject = ['expressionManagerFactory', 'eventCallbackManagerFactory', 'c', 'utils', 'mammothExpressionHelperFactory'];
export function metricMenuManagerFactory(expressionManagerFactory, eventCallbackManagerFactory, c, utils, mammothExpressionHelperFactory){
  return {
    get: getMenu
  };

  function getMenu(options){
    return new MetricMenu(options);
  }

  function MetricMenu(options){
    var self = this;
    self.decimalPlacesOptions = c.decimalPlacesOptions;
    self.currencyOptions = c.currencyOptions;
    self.isCurrencyAvailable = false;
    self.editedFormat = {};
    self.getParam = getParam;
    self.setParam = setParam;
    self.expressionUpdateCb = expressionUpdateCb;
    self.getDisplayProperties = getDisplayProperties;
    self.setFunctionsDefaultSelectedColumn = setFunctionsDefaultSelectedColumn;
    self.update = update;

    self.random_name = 'table_' + utils.getRandomString();
    var metricMenuEvent = new eventCallbackManagerFactory(self.random_name);
    self.on_valid_update = metricMenuEvent.add_callback;

    self.setFormatToDirty = function () {
      self.displayProperties.IS_FORMAT_INFO_DIRTY = true;
    };

    self.displayProperties = {
      FORMAT_INFO: {
        RESULT: {
          comma_separated: false,
          decimal_spec: 2
        }
      },
      USE_SCIENTIFIC_FORMAT: c.useScientificFormatByDefaultInMetrics,
      IS_FORMAT_INFO_DIRTY: false,
      type: 'metric',
      info: {
        'title': '',
        'subtitle': '',
        'description': '',
        'edited': false
      }
    };

    init();
    update(options);

    function init() {
      self.expressionManager = null;
    }

    function setFunctionsDefaultSelectedColumn(column) {
      if (self.expressionManager) {
        self.expressionManager.setFunctionsDefaultSelectedColumn(column);
      }
    }

    function update(options) {
      var context: any = {
        dataview: options.dataview
      };
      if(options.element && options.element.internal_name){
        context.metric_internal_name = options.element.internal_name;
      }
      if(options.element && options.element.display_properties){
        angular.merge(self.displayProperties, options.element.display_properties);
      }
      var metric_status = options.element.hasOwnProperty('metric_status')? options.element.metric_status: 'SUCCESS'
      self.expressionManager = expressionManagerFactory.get_manager({
        metadata: options.metadata,
        mode: 'metric',
        context: context,
        metric_status: metric_status
      });

      self.expressionManager.onUpdate('fromWithinMetricMenu', expressionUpdateCb);
      if (options.element && options.element.display_properties){
        angular.merge(self.displayProperties, options.element.display_properties);
      }

      if (options.element && options.element.param){
        self.setParam(options.element.param);
      }
      if (options.functionsDefaultSelectedColumn) {
        setFunctionsDefaultSelectedColumn(options.functionsDefaultSelectedColumn);
      }
    }

    function expressionUpdateCb(){
      let param = self.expressionManager.getParam();
      if(param){
        let expression = param.METRIC.EXPRESSION;
        if(!self.displayProperties.IS_FORMAT_INFO_DIRTY && expression){
          self.displayProperties.FORMAT_INFO = {
            RESULT: utils.metric.getIdealFormat(options.dataview.display_properties, options.dataview.metadata,
              expression)
          }
        }
        metricMenuEvent.fire_event();
      }

    }

    function getUpdatedMetricName(expression){
      var expression = mammothExpressionHelperFactory.functions.expression;
      var base_display_name = 'Metric';
      var exisiting_display_names = [];
      $.each(options.dataview.metrics, function (i, m) {
        exisiting_display_names.push(m.display_name);
      });

      if (angular.isArray(expression) && expression.length == 1) {
        if (expression[0].TYPE == 'FUNCTION' && expression.includes(expression[0].VALUE.FUNCTION)) {
          if (expression[0].VALUE.FUNCTION != 'COUNT') {
            var function_name = utils.string.capitalize(expression[0].VALUE.FUNCTION, true);
            var column_name = utils.metadata.get_column_by_internal_name(
              options.dataview.metadata, expression[0].VALUE.ARGUMENT).display_name;
            base_display_name = function_name + ' of ' + column_name;
          }
          else {
            base_display_name = 'Row count';
          }
        }
      }
      return base_display_name;
    }

    function getParam() {
      var param = self.expressionManager.getParam();
      if(angular.isArray(param.METRIC.EXPRESSION)){
        if (param.METRIC.EXPRESSION.length){
          param.METRIC.AS = getUpdatedMetricName(param.METRIC.EXPRESSION);
          /*this is funny*/
          if(!self.displayProperties.info.edited && !self.displayProperties.info.title){
            self.displayProperties.info.title = param.METRIC.AS;
          }
          return param;
        }
      }
      throw "Invalid param";
    }

    function setParam(param) {
      self.expressionManager.setParam(_.cloneDeep(param));
    }

    function getDisplayProperties() {
      return self.displayProperties;
    }
  }
}
