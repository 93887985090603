<template>
  <div class="mm-data-addition-fetch-from-url mm-rounded-borders">
    <mm-text-input
      v-model="fileUrl"
      :message="errorMessage"
      :error="!!errorMessage"
      :title="$t('data_library.data_addition.fetch_from_url.enter_url')"
      :placeholder="$t('global.dictionary.example_url')"
    />

    <div class="d-flex">
      <mm-icon name="information" />
      <span class="m-l-2 mm-text--caption-regular" style="margin-top: 1px">
        {{ $t('data_library.data_addition.fetch_from_url.enter_url_description') }}
      </span>
    </div>

    <mm-divider class="m-y-5" />

    <div class="m-b-2">
      <span class="mm-text--h200">{{ $t('data_library.data_addition.fetch_from_url.sample_group.title') }}</span>
    </div>
    <div v-for="group in SAMPLE_FILES_GROUPS" :key="group.title" class="m-b-5">
      <span class="mm-text--body-bold group-title">{{ group.title }}</span>
      <div
        v-for="subTitle in group.subTitles"
        :key="subTitle.name"
        class="subtitle cursor-pointer m-l-3 p-y-1 p-x-2"
        @click="fileUrl = subTitle.url"
      >
        <span>{{ subTitle.name }}:</span>
        <span class="subtitle-description">{{ subTitle.description }}</span>
      </div>
    </div>

    <mm-row no-gutters class="m-t-7">
      <mm-button
        v-if="isBackButtonVisible"
        :label="$t('global.dictionary.back')"
        objective="tertiary"
        @click="redirectToMainMenu"
      />
      <mm-spacer />
      <mm-button :label="$t('global.dictionary.cancel')" objective="tertiary" @click="$emit('cancel')" />
      <mm-button
        class="m-l-3"
        :label="$t('global.dictionary.submit')"
        :disabled="!fileUrl"
        :loading="loading"
        @click="submit"
      />
    </mm-row>
  </div>
</template>

<script>
// Constants
import { USER_EVENTS } from '@/constants'

// API
import API from '@/modules/data-library/api/data-library.api'

export default {
  name: 'data-library-data-addition-fetch-from-url',
  props: {
    folderResourceId: [String, Number],
    isBackButtonVisible: Boolean
  },
  data: () => ({
    fileUrl: '',
    errorMessage: '',
    loading: false
  }),
  created() {
    this.SAMPLE_FILES_GROUPS = [
      {
        title: this.$t('data_library.data_addition.fetch_from_url.sample_group.cupcake.title'),
        subTitles: [
          {
            name: this.$t('data_library.data_addition.fetch_from_url.sample_group.cupcake.subtitle.transactions.name'),
            description: this.$t(
              'data_library.data_addition.fetch_from_url.sample_group.cupcake.subtitle.transactions.description'
            ),
            url: 'https://mammothsamples.s3.amazonaws.com/Store_Transactions.csv'
          },
          {
            name: this.$t(
              'data_library.data_addition.fetch_from_url.sample_group.cupcake.subtitle.inactive_employees.name'
            ),
            description: this.$t(
              'data_library.data_addition.fetch_from_url.sample_group.cupcake.subtitle.inactive_employees.description'
            ),
            url: 'https://s3.amazonaws.com/mammothsamples/Store-Inactive_Employees.csv'
          }
        ]
      },
      {
        title: this.$t('data_library.data_addition.fetch_from_url.sample_group.other.title'),
        subTitles: [
          {
            name: this.$t('data_library.data_addition.fetch_from_url.sample_group.other.subtitle.mcdonalds.name'),
            description: this.$t(
              'data_library.data_addition.fetch_from_url.sample_group.other.subtitle.mcdonalds.description'
            ),
            url: 'https://s3.amazonaws.com/mammothsamples/McDonalds-Menu.csv'
          },
          {
            name: this.$t('data_library.data_addition.fetch_from_url.sample_group.other.subtitle.london_visitors.name'),
            description: this.$t(
              'data_library.data_addition.fetch_from_url.sample_group.other.subtitle.london_visitors.description'
            ),
            url: 'https://s3.amazonaws.com/mammothsamples/International-Visitors-London.csv.zip'
          }
        ]
      }
    ]
  },
  methods: {
    redirectToMainMenu() {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.BACK_TO_MAIN, { type: 'fetchFromUrl' })
      this.$emit('back')
    },
    async submit() {
      this.loading = true
      this.errorMessage = ''

      const { data } = await API.fetchFromUrl(this.$store.state.projectId, this.folderResourceId, this.fileUrl)

      if (data.ERROR_CODE)
        this.errorMessage = this.$te(`global.api.${data.ERROR_CODE}`)
          ? this.$t(`global.api.${data.ERROR_CODE}`)
          : this.$t('global.api.generic_error')
      else this.$emit('submit')

      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.FETCH_FROM_URL.SUBMIT)

      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.mm-data-addition-fetch-from-url {
  .group-title {
    color: var(--mm-color--p700);
  }

  .subtitle {
    &:hover {
      background: var(--mm-color--n50);
      border-radius: 4px;
    }

    .subtitle-description {
      color: var(--mm-color--n400);
    }
  }
}
</style>
