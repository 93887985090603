'use strict';
import * as angular from 'angular';
import { renderFilterElement } from './filter.directives'
import {filterRenderController} from "./filter.display.controller";
import {filterMenuManagerFactory} from "./filter.menu.manager";
import {filterElementFactory} from "./filter.model";

export let filterModule = angular.module('app.element.filter', ['app.core', 'app.element.common']);

filterModule.controller('filterRenderController', filterRenderController);
filterModule.directive('renderFilterElement', renderFilterElement);
filterModule.factory('filterMenuManagerFactory', filterMenuManagerFactory);
filterModule.factory('filterElementFactory', filterElementFactory);
