/**
 * @ngInject
 * @returns {}
 */

TaskHelperServiceFactory.$inject = ['DataviewService', 'dataviewConfig', 'eventCallbackManagerFactory'];
export function TaskHelperServiceFactory(DataviewService, dataviewConfig, eventCallbackManagerFactory) {
  var registry = {};
  var factory: any = {};
  factory.getByDataviewId = getByDataviewId;

  return factory;

  function getByDataviewId(dataview_id){
    if (!registry.hasOwnProperty(dataview_id)){
      registry[dataview_id] = new TaskHelperService(dataview_id);
    }
    return registry[dataview_id];
  }


  function TaskHelperService(dataview_id) {
    var dataview = DataviewService.get_by_id(dataview_id);
    var self = this;
    self.dataview_id = dataview_id;
    self.current = {
      task: undefined,
      helper: undefined
    };
    var taskHelperMap = {};
    //methods
    self.initHelper = initHelper;
    self.destroyHelper = destroyHelper;
    taskHelperMap[dataviewConfig.tasks.join] = joinHelper;

    function initHelper(task, options) {
      self.current.task = task;
      var args = {
        options: options
      };
      self.current.helper = new taskHelperMap[task](args);
      return self.current.helper;
    }

    function destroyHelper() {
      self.current.task = undefined;
      self.current.helper = undefined;
    }

    function joinHelper(args) {
      var helper = this;
      var joinRefreshEvent = new eventCallbackManagerFactory("_on_join_refresh_" + self.dataview_id);
      helper.isOpen = true;
      helper.options = args.options;
      helper.onRefresh = joinRefreshEvent.add_callback;
      helper.refreshGrids = refresh;

      function refresh() {
        joinRefreshEvent.fire_event();
      }
    }
  }

}
