import * as angular from 'angular';
import * as _ from 'lodash-es';

/**
 * @ngInject
 */
fileUploadService.$inject = ['FileUploader', 'config', 'UserWorkspace', 'Auth', '$timeout', 'analyticsService', '$log', '$uibModal', '$rootScope',
  'toastNotification', 'DatasourceService', 'utils', 'c', 'eventCallbackManagerFactory', 'landingList', 'HttpRequestInterceptor', 'VuexStore'];

export function fileUploadService(FileUploader, config, UserWorkspace, Auth, $timeout, analyticsService, $log, $uibModal,
  $rootScope, toastNotification, DatasourceService, utils, c, eventCallbackManagerFactory, landingList, HttpRequestInterceptor, $store) {
  var service = {
    getUploader: getUploader,
    openAddDataDialog: openAddDataDialog,
    openEditDatapullConfigDialog: openEditDatapullConfigDialog,
    openEditMetricsDialog: openEditMetricsDialog,
    fileUploadsInProgress: 0,
    lastFileUploadsCount: 0
  };
  return service;
  /**
   *
   * @param uploader
   * @param options:
   *      file_upload_only: default False. Means show only file upload section
   *      closeOnAdd: defaults to True. Close after adding file.
   *      destinationString: String to show as destination. Defaults to null
   *      activeOption: A string. It should be one of 'file', 'api' or 'url'
   * @returns {*}
   */
  function openAddDataDialog(uploader, options) {

    var scope = $rootScope.$new();
    scope.uploader = uploader;
    service.lastFileUploadsCount = 0;

    var default_options = {
      fileUploadsOnly: false,
      closeOnAdd: true,
      destinationString: null,
      activeOption: 'file'
    };

    angular.extend(default_options, options);
    angular.extend(scope, default_options);

    var modalReference = $uibModal.open({
      animation: true,
      templateUrl: config.templates.addData,
      controller: config.controllers.addData,
      controllerAs: 'vm',
      scope: scope,
      bindToController: true,
      windowClass: 'ui-datapull modal-size-large'
    });
    modalReference.result.then(final_cb, final_cb);

    function final_cb() { }

    return modalReference.result;
  }

  function openEditDatapullConfigDialog(datasource, isScheduleRunning, updateScheduleState) {
    var scope = $rootScope.$new();
    const { integration_key, identity_key } = datasource.config_keys;
    scope.integration_key = integration_key;
    scope.identity_key = identity_key;
    scope.isScheduleRunning = isScheduleRunning;
    scope.updateScheduleState = updateScheduleState;
    scope.datasource = datasource;
    var modalReference = $uibModal.open({
      animation: true,
      templateUrl: config.templates.editDatapullConfig,
      controller: config.controllers.editDatapullConfig,
      controllerAs: 'vm',
      scope: scope,
      bindToController: true,
      size: 'dialog-centered',
      windowClass: 'ui-datapull modal-size-medium edit-schedule-modal modal-dialog-centered',
    });

    scope.close = function () {
      modalReference.close();
    }
    return modalReference.result;
  }

  function openEditMetricsDialog(datasource_id, ds_name, ds_metadata, config_keys) {

    var scope = $rootScope.$new();
    scope.datasource_name = ds_name;
    scope.datasource_id = datasource_id;
    scope.datasource_metadata = ds_metadata;
    scope.config_keys = config_keys;
    var modalReference = $uibModal.open({
      animation: true,
      templateUrl: config.templates.editMetrics,
      controller: config.controllers.editMetrics,
      controllerAs: 'tpvm',
      scope: scope,
      bindToController: true,
      windowClass: 'ui-datapull modal-size-large edit-metrics'
    });
    scope.close = function () {
      modalReference.close();
    }
    modalReference.result.then(final_cb, final_cb);

    function final_cb() {
      modalReference.close();
    }

    scope.cancelButtonHandler = function () {
      modalReference.close();
    }
    return modalReference.result;
  }


  function getUploader(formData?, singleFileUpload?) {
    var onItemRemove = new eventCallbackManagerFactory('onFileRemoveEvent' + utils.getRandomString(5));
    var runtimeOptions = {};
    var fileUploader = new FileUploader(get_file_uploader_config(formData, singleFileUpload, onItemRemove, runtimeOptions));
    fileUploader.onItemRemove = onItemRemove.add_callback;
    fileUploader.runtimeOptions = runtimeOptions;
    return fileUploader;
  }

  function get_file_uploader_config(formData, singleFileUpload, onItemRemove, runtimeOptions) {
    let headers = HttpRequestInterceptor.get_saved_token();
    headers[config.headers.WORKSPACE_ID] = UserWorkspace.workspace.id;
    var ret = {
      url: config.api.files,
      withCredentials: true,
      autoUpload: true,
      headers: headers,
      filters: [{
        name: 'fileTypeFilter',
        fn: function (item) {
          // do not allow json, jsonp file extensions
          // var reg_file_extention = new RegExp('^((?!(json|jsonp)).)*$', 'gm')
          //  Though mammoth backend checks the file based on contents, we're adding this restriction here just to get security analysis reports green
          var reg_file_extention = new RegExp('(txt|csv|tsv|psv|xls|xlsx|zip|bz2|gz|tar|7z)$', 'gm')
          var file_name_split = item.name.split('.');
          var file_extenstion = file_name_split[file_name_split.length - 1];
          var is_valid_format = reg_file_extention.exec(file_extenstion.toLowerCase());
          var item_name_with_centre_ellipsis = utils.string.addCentreEllipsis(item.name, 26);
          if (!is_valid_format) {
            toastNotification.error('<strong>' + file_extenstion.toUpperCase() + '</strong> file <span class="file-name">' + utils.sanitizeName(item_name_with_centre_ellipsis) + '</span> is not supported.');
          }
          return is_valid_format ? true : false;
        }
      }],
      formData: formData,
      onSuccessItem: function (item, response, status, headers) {
        if (response.STATUS == 'SUCCESS') {
          item.file_id = response.id;
        }
        $timeout(function () {
          if (item.uploader && item.uploader.queue.indexOf(item) != -1) {
            onItemRemove.fire_event(item);
            item.remove();
          }
        }, 10000);
        analyticsService.userEventTrack(c.userEvents.landingPage.fileUploaded, {
          fileType: item.file.type,
          fileSize: item.file.size,
          eventOrigin: "landingPage"
        });
        // $analytics.eventTrack('uploaded-file', {'filename': item.name});
      },
      onErrorItem: function (item, response, status, headers) {
        let error_message = "File upload failed";
        if(response.ERROR_MESSAGE) {
          error_message += ": " + response.ERROR_MESSAGE;
        }
        toastNotification.error(error_message);
        item.remove();
        $log.error('UPLOAD FAILED', response);
      },
      onCompleteItem: function (item) {
        service.fileUploadsInProgress--;
        if (!!item.appending_to) {
          delete item.appending_to.add_data_uploading_file;
          onItemRemove.fire_event(item);
          $timeout(function () {
            item.remove();
          }, 3000);
        } else {
          let uploadedIntoResId = _.get(runtimeOptions.fileUploadDestinationResource, "resource_id");
          if (landingList.currentLabelResourceId !== uploadedIntoResId) {
            let label = runtimeOptions.fileUploadDestinationResource;
            let folderLink = ` <a href="#/landing/home">Data Library</a>`;
            if (uploadedIntoResId) {
              folderLink = ` folder <a href="#/landing/${label.resource_id}">${label.name}</a>`;
            }
            toastNotification.success(`${item.file.name} uploaded into` + folderLink);
          }
        }
        service.lastFileUploadsCount++;

      },
      onCompleteAll: function () {
        //$scope.global_file_uploading_status.file_upload_ops_count -= 1;
      },
      onCancelItem: function (item) {
        $timeout(function () {
          if (!!item.appending_to) {
            delete item.appending_to.add_data_uploading_file;
          }
          onItemRemove.fire_event(item);
          item.remove();
        }, 1000);

      },
      onAfterAddingAll: function () {
      },
      onAfterAddingFile: function (item) {
        $timeout(function () {
          item.shortName = utils.string.addCentreEllipsis(item.file.name);
          let ext = item.file.name.split(".");
          var reg_file_extention = new RegExp('txt|csv|psv|tsv|xls|xlsx|zip|rar|json|jsonp');
          if (ext.length > 1) {
            ext = ext[ext.length - 1];
            if (reg_file_extention.exec(ext.toLowerCase())) {
              item.extension = _.upperCase(ext);
            }
          } else {
            item.extension = "";
          }
        });
      },
      onBeforeUploadItem: function (item) {
        service.fileUploadsInProgress++;
        if (item.file.size > c.maxFileUploadSize * 1024 * 1024) {
          var error_msg = 'File too large to upload';
          if (item.file.type == 'text/csv') {
            error_msg += '. Consider compressing to zip format.'
          }
          toastNotification.error({
            message: error_msg,
            delay: 10000
          });
          item.cancel();
          throw 'file too big error';
        }
        if (item.file.size > c.requestZipFileUploadSize * 1024 * 1024 && item.file.type != 'application/zip') {
          toastNotification.warning({
            message: item.file.name + " is large, consider compressing files into ZIP formats to speed up uploads.",
            delay: 10000
          });
          item.isLarge = true;
        }

        item.unique_id = 'upload_' + utils.getRandomString(5);
        item.type = c.resourceTypes.upload;
        if (runtimeOptions.fileUploadDestinationResource) {
          if (!item.formData) {
            item.formData = [{}];
          }
          item.formData[0]['label_resource_id'] = runtimeOptions.fileUploadDestinationResource.resource_id;
        }
        item.formData[0]['project_id'] = $store.state.projectId;
        if (formData && formData[0].hasOwnProperty('append_to_ds_id')) {
          var ds = DatasourceService.list[formData[0]['append_to_ds_id']];
          ds.add_data_uploading_file = item;
          item.appending_to = ds;
        }
      }
    };

    if (singleFileUpload) {
      ret['queueLimit'] = 1;
    }

    return ret;
  }
}
