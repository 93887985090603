import * as angular from 'angular';
import * as _ from 'lodash-es';
import {constants} from "../../common/app.constants";

internalDataSetFactory.$inject = ['DatasourceService', 'UserWorkspace','c', 'filterManagerFactory',
                                  'FilterEditHelperFactory',  'DataviewService', 'utils', 'VuexStore', 'filterUI'];
export function internalDataSetFactory(DatasourceService, UserWorkspace, c, filterManagerFactory,
                                       FilterEditHelperFactory, DataviewService, utils, $store, filterUI) {

  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new saveAsDatasetManager(options);
  }

  function saveAsDatasetManager(options) {
    var self = this;
    let defaultDsName = "Result Dataset";
    self.saveAsDsOptions = [
      {internal: null, display: 'Save as is'},
      {internal: 'crosstab', display: 'Perform Pivot'},
    ];
    self.context = options.context;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.inEditMode = options.context.inEditMode;
    self.saveAsDsAppendReplaceModeOptions = ['REPLACE_IN_DS', 'APPEND_TO_DS']
    self.saveAsDsOption = null;
    var metadata = options.metadata;
    //SAVE_AS_DS_MODE
    self.hideAsSeenOption = true;
    self.hideKeepAtEndOfPipelineOption = false;
    self.hideHiddenColOption = false;
    self.appendToExisting = false;
    self.metadata = _.cloneDeep(options.metadata);
    self.isInvalidEdit = false;
    self.saveOptions = [
        { internal: true, display: "Add to an existing Dataset" },
        { internal: false, display: "Create a new Dataset" }
    ];
    self.filterOptions = [
        { internal: true, display: "All rows" },
        { internal: false, display: "Some rows" }
    ];
    self.saveAsDsMode = [
        { internal: "APPEND_TO_DS", display: "Combine" },
        { internal: "REPLACE_IN_DS", display: "Replace" }
    ];
    self.filterEditHelper = new FilterEditHelperFactory();
    self.filterManager = filterManagerFactory.get_manager({
        metadata: metadata,
        dataviewId: options.context.dataview.id,
        originalSequenceNumber: options.originalSequenceNumber
    });
    self.applyToAll = true;
    self.filterManager.applyToAll = self.applyToAll;
    self.openConditionMenu = openConditionMenu;
    self.openConditionModal = openConditionModal;
    self.filterEditHelper.onMaximizeFilterPopoverToModal('maximizeFilterPopoverToModal',  self.openConditionModal)
    DataviewService.on_update_metadata_hiddenColumns('updateMetadataHiddenColumns', handleHiddenColumnsUpdate);
    self.dataview = DataviewService.get_by_id(options.context.dataview.id);
    self.hiddenColumns = _.get(self.dataview,'display_properties.HIDDEN_COLUMNS', []);
    self.includeHiddenColumnsMapping = false;
    self.dsList = [];
    self.projects = [];
    self.selectedProjectId = -1;
    self.project_name = ""; // for display purpose only

    self.param = options.context.defaultParams;
    self.exportToProject = self.param?.target_properties?.export_project;
    self.currWorkspace = $store.state.workspaces.filter((workspace) => workspace.id == $store.state.workspaceId)[0];
    self.currentProjectId = $store.state.projectId;

    function populateProjects() {
      if (!self.exportToProject) {
        return;
      }

      const subscribedProjects = self.currWorkspace?.projects.filter(project => project.subscribed && project.id !== self.currentProjectId);

      // Handle the case when there are no subscribed projects
      if (!subscribedProjects || subscribedProjects.length === 0) {
        // Todo: Decide how to handle this situation
        return;
      }

      self.projects = subscribedProjects;
      self.selectedProjectId = subscribedProjects[0].id;
    }

    populateProjects();

    function allowBranchOutToAnyDs() {
      return localStorage.getItem('enableBranchoutToAnyDs') == 'true';
    }

    /*
    This method populate self.metadata with visible columns and hidden columns (with respect to
    depending on includeHidden flag).
    */
    function handleHiddenColumnsUpdate(includeHidden){
      self.includeHiddenColumnsMapping = includeHidden;
      if (includeHidden){
        self.metadata = metadata;
      } else {
        self.metadata = {};
        self.metadata = metadata.filter(function(col){ return self.hiddenColumns.indexOf(col.internal_name) == -1});
      }
      populateSelfColumnMappings();
     }

     /*
     Populate datasource list with all ds created using branch out or crosstab
     This is used to show only create new ds option when there is no possibility of
     branching out to existing ds (as they are absent).
     */
     function populateDsList(){
      self.dsList = [];
      _.forEach(DatasourceService.list, function(ds){
        // Allow any type dataset for branchout. This applies when allowBranchOutToAnyDs is true
        if(ds && (ds.additional_info && ds.additional_info.DATAVIEW_ID && ds.additional_info.TRIGGER_ID) || allowBranchOutToAnyDs()){
          if(ds.id != options.context.dataview.id){
          self.dsList.push(ds);
        }
        }
      });
    }
    populateDsList();

    function openConditionMenu(){
      // remove conditions if user selects send all rows
      if (self.applyToAll == true){
        self.filterManager.condition = null;
        return;
      }
        
        self.filterEditHelper.open(self.filterManager).then({}, fail);

        function fail(data=null){
          if (!data && !self.filterManager.condition){
            self.applyToAll = true;
          }
        }
    }
    
    function openConditionModal(param=null){
      filterUI.open(self.filterManager, param).then({}, fail);
      function fail(data=null){
        if (!data && !self.filterManager.condition){
          self.applyToAll = true;
        }
      }
    }

    let target_properties: any = {
      DS_NAME: defaultDsName,
      TRANSFORM: null,
      SAVE_AS_DS_MODE: 'REPLACE_IN_DS',
      USER_ID: UserWorkspace.selfDetails.id
    };

    self.serversideValidationRequired = false;
    if (_.isUndefined(options.triggerType)) {
      options.triggerType = "pipeline";
    }
    self.dataviewId = options.context.dataview.id;
    self.parentDsId = options.context.dataview.datasource.id;
    self.targetUsers = [];

    _.forEach(UserWorkspace.users, function(user){
      let display = user.name;
      if(user.id == UserWorkspace.selfDetails.id){
        display = 'mr';
      }
      display = display + ' <' + user.email + '>';
      self.targetUsers.push({id: user.id, display: display});
    });

    self.param = {
      "handler_type": "internal_dataset",
      "trigger_type": options.triggerType,
      "run_immediately": true,
      "sequence": options.context.currentSequenceNumber
    };


    self.param.target_properties = target_properties;
    self.param.additional_properties = {};

    self.getParam = getParam;
    self.setParam = setParam;
    self.handlePasteParams = handlePasteParams;
    self.getConditionParam = getConditionParam;
    self.setConditionParam = setConditionParam;
    self.handleTargetTypeChange = handleTargetTypeChange;
    self.validate = validate;


    function validate() {
      var isValid = true;
      /*
        FilterManager's validate return true if valid
        Otherwise it returns undefined
      */
      isValid = !!self.filterManager.validate()

      if (self.exportToProject) {
        /*
          if we are exporting to project, there
          should be selected project id.
         */
        isValid = isValid && self.selectedProjectId != -1
      }

      return isValid;
    }
    /* For default option (branch out to new ds)
       All columns should be mapped.
    */
   handleHiddenColumnsUpdate(self.includeHiddenColumnsMapping);

    function getParam() {
      let param = self.param;

      if (options.context.createFilterTransform && options.context.condition) {
        if (!param.target_properties.TRANSFORM) {
          param.target_properties.TRANSFORM = {
            SELECT: "ALL",
            CONDITION: options.context.condition
          };
        }
      }

      param.condition = self.getConditionParam();

      let paramCopy = _.cloneDeep(param);

      // If save as ds runs for the first time
      if (!paramCopy.target_properties.hasOwnProperty('trigger_id')
        && isNaN(paramCopy.target_properties.TARGET_DS_ID)
        && paramCopy.target_properties.hasOwnProperty('TRANSFORM')
        && !paramCopy.target_properties.TRANSFORM){

        let column_mapping = paramCopy.target_properties.COLUMN_MAPPING;
        /*
         * For each mapping in column_mapping, set mapping to null. The intention here is to tell the backend
         * to create these columns on the destination side
         */
        _.forEach(column_mapping, function (destination, source) {
          column_mapping[source] = null;
        });
      }
      else {
        let column_mapping = paramCopy.target_properties.COLUMN_MAPPING;
        /*
         * For each mapping in column_mapping, set mapping to null if destination is `Create a new column`
         * This is one way to tell backend to create new columns on the destination side
         */
        _.forEach(column_mapping, function (destination, source) {
          if(destination == constants.saveAsDSCreateColIdentifier) {
            column_mapping[source] = null;
          }
        });
      }
      if (param.target_properties.export_project)
      {
        paramCopy.target_properties.project_id = parseInt(self.selectedProjectId);
        paramCopy.target_properties.source_project_id = self.currentProjectId;
      }
      
      return paramCopy;
    }

    function handleTargetTypeChange(){
      if(self.appendToExisting){
        self.param.target_properties.DS_NAME = undefined;
        self.hideHiddenColOption = true;
        self.param.target_properties.COLUMN_MAPPING = {};
        self.param.target_properties.TARGET_DS_ID = null;
      }
      else{
        self.param.target_properties.DS_NAME = defaultDsName;
        self.param.target_properties.TARGET_DS_ID = null;
        self.param.target_properties.COLUMN_MAPPING = {};
        handleHiddenColumnsUpdate(self.includeHiddenColumnsMapping);
        populateSelfColumnMappings();
        self.hideHiddenColOption = false;
      }
    }

    function populateSelfColumnMappings(){
      /*
      Add column mappings as following:
      {
        source : dest,
        ..
      }
      */

      // Do not populate column mappings for CROSSTAB because crosstab doesnt need it
      if(self.param.target_properties.TRANSFORM){
        return;
      }

      if(!self.param.hasOwnProperty("targetDatasetId")){
        // self populate column mappings only if column mapping is empty
        if (self.param.target_properties.COLUMN_MAPPING && !_.isEmpty(self.param.target_properties.COLUMN_MAPPING)){
          return
        }
        self.param.target_properties.TARGET_DS_ID = null;
        self.param.target_properties.COLUMN_MAPPING = {};

        self.metadata.forEach(function (column){
          var col_display_name = _.get(column, 'old_display_name');
          // add all columns to mapping
          self.param.target_properties.COLUMN_MAPPING[col_display_name] = col_display_name;

        });
        
      }
    }

    function handlePasteParams(action_info){
    
      /** Update condition params with suitable replacement columns, based on display name*/
      var columnUsedInCondition = utils.get_input_columns(_.cloneDeep(action_info.target_properties.condition))
      var column_info = {'COLUMNS_USED':{}}
      if (action_info?.metadata){

        for(var col of action_info?.metadata){
          column_info['COLUMNS_USED'][col['internal_name']] = col
        }
        if (columnUsedInCondition) {
          utils.metadata.findReplaceColumnsInCondition(self.metadata, self.displayNameAndTypeToColumnMap, action_info.target_properties, columnUsedInCondition, column_info, 'condition')
        }
        self.filterManager.metadata = self.metadata
        action_info.condition = action_info.target_properties.condition

        if (action_info?.target_properties?.TRANSFORM?.CROSSTAB?.COLUMNS){
          for(const index in action_info?.target_properties?.TRANSFORM?.CROSSTAB?.COLUMNS){
            utils.metadata.replaceMatchingColumnAndUpdateMetadata(action_info?.target_properties?.TRANSFORM?.CROSSTAB?.COLUMNS[index], 'COLUMN', column_info, self.metadata, self.displayNameAndTypeToColumnMap);
          }
        }
        if (action_info?.target_properties?.TRANSFORM?.CROSSTAB?.ROWS){
          for(const index in action_info?.target_properties?.TRANSFORM?.CROSSTAB?.COLUMNS){
            utils.metadata.replaceMatchingColumnAndUpdateMetadata(action_info?.target_properties?.TRANSFORM?.CROSSTAB?.ROWS[index], 'COLUMN', column_info, self.metadata, self.displayNameAndTypeToColumnMap);
          }
        }
        if (action_info?.target_properties?.TRANSFORM?.CROSSTAB?.SELECT && action_info?.target_properties?.TRANSFORM?.CROSSTAB?.SELECT?.COLUMN ){
          utils.metadata.replaceMatchingColumnAndUpdateMetadata(action_info?.target_properties?.TRANSFORM?.CROSSTAB?.SELECT, 'COLUMN', column_info, self.metadata, self.displayNameAndTypeToColumnMap);
        }
      }
        return action_info
    }

    function setParam(param) {
      self.param.target_properties.COLUMN_MAPPING = {};
      _.merge(self.param, param);
      self.param = _.cloneDeep(self.param);
      populateSelfColumnMappings();
      let tp = self.param.target_properties;
      if (tp && tp.hasOwnProperty('include_hidden')){
        self.hideHiddenColOption = true;
        self.includeHiddenColumnsMapping = true;
        handleHiddenColumnsUpdate(self.includeHiddenColumnsMapping);
      }
      if (tp && tp.hasOwnProperty('TRANSFORM')){
        if(tp.TRANSFORM && tp.TRANSFORM.hasOwnProperty('CROSSTAB')) {
          self.saveAsDsOption = 'crosstab';
          self.hideHiddenColOption = true;
          if (self.param.target_properties.COLUMN_MAPPING){
            // Column mappings is added for default for saveasdataset
            // Delete these maps as it is not required for crosstab
            delete self.param.target_properties.COLUMN_MAPPING;
          }
        }
      }
      if(tp && tp.hasOwnProperty('TARGET_DS_ID') && Number.isInteger(tp.TARGET_DS_ID) && tp.hasOwnProperty('COLUMN_MAPPING')) {
        self.appendToExisting = !(tp.TARGET_DS_ID === self.parentDsId);
      }
      if(self.saveAsDsOption === null && Number.isInteger(self.param.target_properties.TARGET_DS_ID)){
           self.appendToExisting = true;
      }
      if (tp.export_project){
        // no append to existing in export to project
        self.appendToExisting = false;
        self.exportToProject = self.param.target_properties.export_project;
      }
      if(self.param && self.param.hasOwnProperty('condition') && Object.keys(self.param.condition).length != 0){
        setConditionParam(self.param);
      } else {
        self.applyToAll = true;
      }
      if (tp.project_id){
      self.selectedProjectId = tp.project_id;
      populateProjects();
      self.project_name = self.projects.filter(project => project.id == tp.project_id)[0].name;
      }
      _validatePostSetParam();
    }

    function _validatePostSetParam(){
      if(self.inEditMode){
        // For existing save as ds/ crosstab, if target ds doesnt exist, mark the action as invalid
        if(self.param.target_properties.trigger_id){
          let ds = DatasourceService.get_by_id(self.param.target_properties.TARGET_DS_ID);
          if(ds){
            self.isInvalidEdit = false;
          }else{
            // If ds is not present in edit mode mark as invalid only if action is executed. Otherwise if might be edited after adding in autorun off mode which is a valid case
            // ds is always not present in case of branch out to project
            // so do not mark as invalid in that case
            if(self.context.action.status=='executed' && !self.exportToProject){
              self.isInvalidEdit = true;
            }
          }
        }
      }
      if(self.param.handler_type !== c.actions.internal_ds){
        self.isInvalidEdit = true;
      }
    }

    function getConditionParam(){
      var param = {};
      if(self.filterManager.condition) {
          param =  self.filterManager.getParam();
        }
        return param;
    }
    function setConditionParam(param){
        if (param.hasOwnProperty('condition')) {
          self.filterManager.setParam(_.get(param, 'condition'), param.target_properties?.EXECUTION_TIMESTAMP);
        }
        self.applyToAll = false;
    }

  }
}

