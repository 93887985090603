import * as angular from 'angular';


/**
 * @ngInject
 */
dataviewOnConfig.$inject = ['$stateProvider', 'dataviewConfig'];
export function dataviewOnConfig($stateProvider, dataviewConfig) {
  angular.forEach(dataviewConfig.taskConfig, function (task) {
    convertTags(dataviewConfig.taskConfig);
    convertTags(dataviewConfig.actionConfig);
    const _templateUrl = "templateUrl";
    if (task.state) {
      $stateProvider
        .state(task.state, {
          views: {
            "taskBody@Dataviews": {
              [_templateUrl]: task.template,
              controllerAs: 'tvm',
              controller: dataviewConfig.taskController
            }
          }
        });
    }
  });
}
function convertTags(taskConfig) {
  angular.forEach(taskConfig, function (item) {
    if (item.info) {
      if (item.info.tags) {
        let new_tags = [];
        angular.forEach(item.info.tags, function (tag) {
          new_tags.push(tag.toLowerCase());
        });
        item.info.tags=new_tags;
      }

    }
  });
}
