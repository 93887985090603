/**
 *
 * @param {*} data The data, coming from backend, that needs to be transformed into a frontend payload
 * @returns a payload suited to frontend's needs. The frontend relies on the returned result of this transformation
 */

export function transformGridColumns(columns) {
  // mm-storybook was built assuming a different data type then what backend sends e.g. number instead of NUMERIC
  const typeMapping = {
    NUMERIC: 'number',
    DATE: 'date',
    TEXT: 'text'
  }

  return columns.map(column => {
    return {
      field: column.internal_name,
      datatype: typeMapping[column.type],
      headerName: column.display_name
    }
  })
}

export function transformMetrics(metricsArray, metricsValues) {
  return metricsArray.map((metric, index) => {
    const metricArrayInfo = metric.display_props.info
    const value = metricsValues[index].data.data.data[0].RESULT

    return {
      title: metricArrayInfo.title,
      subtitle: metricArrayInfo.subtitle,
      note: metricArrayInfo.description,
      value: value
    }
  })
}
