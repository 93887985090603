import * as angular from 'angular';
import _ = require('lodash');
/**
 * @ngInject
 * Element service
 */

ElementFactory.$inject = ['derivatives', 'eventCallbackManagerFactory', 'utils', '$timeout', '$q', 'c', 'analyticsService'];
export function ElementFactory(derivatives, eventCallbackManagerFactory, utils, $timeout, $q, c, analyticsService) {
  return Element;

  function Element() {
    var self = this;
    self.unique_id = utils.getRandomString(15);
    var onUpdateEvent = new eventCallbackManagerFactory("elementOnUpdate" + self.unique_id);
    var onDisplayUpdateEvent = new eventCallbackManagerFactory("elementOnVisualUpdate" + self.unique_id);
    var onFilterReset = new eventCallbackManagerFactory("filterReset" + self.unique_id);
    var _serverSideDisplayProps;
    self.update = update;
    self.onUpdate = onUpdateEvent.add_callback;
    self.fireUpdate = onUpdateEvent.fire_event;
    self.onDisplayUpdate = onDisplayUpdateEvent.add_callback;
    self.displayUpdated = onDisplayUpdateEvent.fire_event;
    self.getOriginalDisplayProps = getServerSideDisplayProps;
    self.query = query;
    self.edit = edit;
    self.toggleLock = toggleLock;
    self.row_limit = null;
    self.dataStatus = null;

    var _legacy_data;

    function update(derivative) {
      if (angular.equals(_legacy_data, derivative)) {
        return;
      }
      var paramUnchanged = false;
      if (_legacy_data && _legacy_data.param) {
        paramUnchanged = angular.equals(_legacy_data.param, derivative.param);
      }
      _legacy_data = _.cloneDeep(derivative);
      _serverSideDisplayProps = _.cloneDeep(derivative.display_properties);
      angular.extend(self, derivative);
      $timeout(function () {
        onUpdateEvent.fire_event(paramUnchanged);
      });
      if(derivative.display_properties.type == "filter"){
        self.onFilterReset = onFilterReset.add_callback;
        self.fireFilterReset = onFilterReset.fire_event;
      }
    }

    function getServerSideDisplayProps(){
      return _.cloneDeep(_serverSideDisplayProps);
    }

    function query(condition?, extra_group_by?, queryDisplayProperties?) {
      var deferred = $q.defer();
      var dataPromise;
      if(self.hasOwnProperty('dataviewId')){
        dataPromise = derivatives.get_derivative(self.dataviewId, self.id, condition, self.row_limit,
          extra_group_by, queryDisplayProperties);
      }

      dataPromise.then(function(data){
        self.dataStatus = data.STATUS;
        deferred.resolve(data);
      }, deferred.reject);

      return deferred.promise;
    }

    function edit(new_param, displayProperties){
      self.dataStatus = null;
      return derivatives.edit(self.dataviewId, self.id, new_param, displayProperties)
    }

    function toggleLock(condition){

      self.display_properties.LOCKED = !self.display_properties.LOCKED;

      analyticsService.userEventTrack(c.userEvents.elements.toggleMetricValueLock,
        {
          eventOrigin: "elementsPanel.metric.metricOptions",
          locked: self.display_properties.LOCKED
        });

      let paramChange = false;
      if(angular.equals(condition, {})){
        condition = null;
      }
      if(self.display_properties.LOCKED && condition){
        let existingCondition = self.param.CONDITION;
        if(angular.equals(existingCondition, {})){
          existingCondition = null;
        }
        let conditionAlreadyThere = false;
        if(angular.equals(existingCondition, condition)){
          conditionAlreadyThere = true;
        }
        if(existingCondition && existingCondition.AND){
          existingCondition.AND.forEach(function(cn){
            if(angular.equals(cn, condition)){
              conditionAlreadyThere = true;
            }
          })
        }
        if(!conditionAlreadyThere){
          let conditions = [condition];
          if(existingCondition && !angular.equals(condition, existingCondition)){
            conditions.push(existingCondition);
          }
          let finalCondition;
          if(conditions.length == 2){
            finalCondition = {AND: conditions}
          }
          else if(conditions.length == 1){
            finalCondition = conditions[0];
          }
          self.param.CONDITION = finalCondition;
          paramChange = true;
        }
      }
      if(paramChange){
        _legacy_data.param = self.param;
        self.edit(self.param, self.display_properties);
      }
      else{
        self.edit(undefined, self.display_properties);
      }
    }
  }
}
