<template>
  <mm-menu class="mm-data-library-sidebar-workspace-menu" v-model="isWorkspaceMenuOpen">
    <template #activator="{ on }">
      <mm-tooltip :label="$t('data_library.switch_workspace_tooltip')" position="right">
        <mm-button
          icon="chevron_double"
          objective="tertiary"
          v-on="on"
          @click="$userEvents.save(USER_EVENTS.DATA_LIBRARY.SIDEBAR.SELECT_WORKSPACE)"
        />
      </mm-tooltip>
    </template>

    <template #content>
      <div class="p-a-2 data-library-workspace-menu-content">
        <ul class="basic-list">
          <li
            v-for="workspace in $store.state.workspaces"
            :key="workspace.id"
            class="workspace-container p-x-2"
            @click="setCurrentWorkspace(workspace.id)"
          >
            <p class="mm-text--body-regular m-b-0">{{ workspace.name }}</p>

            <div class="m-l-5" style="width: 20px">
              <mm-icon v-if="workspace.id == $store.state.workspaceId" name="check"></mm-icon>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </mm-menu>
</template>

<script>
import { USER_EVENTS, getOldAppUrl } from '@/constants'

export default {
  data() {
    return {
      isWorkspaceMenuOpen: false
    }
  },
  created() {
    this.USER_EVENTS = USER_EVENTS
  },
  methods: {
    setCurrentWorkspace(workspaceId) {
      this.$store.commit('setWorkspaceId', workspaceId)
      this.$userEvents.save(USER_EVENTS.NAVBAR.PROFILE.CHANGE_WORKSPACE, { id: workspaceId })
      const workspace = this.$store.state.workspaces.find((workspace) => workspace.id === workspaceId)
      const project = workspace.projects.filter((project) => project.subscribed)[0]
      // this.$router.push({ name: 'Landing', params: { workspaceId: workspaceId } }) // ideally we should redirect to an workspace id e.g. /#/:workspaceId/landing
      //TODO remove when angular code is gone
      if (project) {
        window.location.href = `${getOldAppUrl()}/#/workspaces/${workspaceId}/projects/${project.id}`
      } else {
        window.location.href = `${getOldAppUrl()}/#/workspaces/${workspaceId}`
      }
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">
.workspace-menu {
  display: flex;
  flex-direction: column;
}

.data-library-workspace-menu-content {
  .workspace-container {
    min-width: 200px;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background: var(--mm-color--n50);
    }
  }
}
</style>
