<template>
  <ul class="mm-navbar-help basic-list">
    <li v-for="item in LIST_ITEMS" :key="item.text" @click="item.clickHandler(), $userEvents.save(item.event)">
      {{ item.text }}
    </li>
  </ul>
</template>

<script>
// Support widget
import FreshdeskWidget from '@/mixins/support/freshdeskWidget'

// Constants
import { USER_EVENTS } from '@/constants'

export default {
  name: 'navbar-help',
  mixins: [FreshdeskWidget],
  created() {
    this.LIST_ITEMS = [
      {
        text: this.$t('navbar.help.support'),
        clickHandler: () => this.mixinFreshdeskWidget_showSupportWidget(),
        event: USER_EVENTS.NAVBAR.HELP.SUPPORT
      },
      {
        text: this.$t('navbar.help.documentation'),
        clickHandler: () => this.openURL('https://docs.mammoth.io/'),
        event: USER_EVENTS.NAVBAR.HELP.DOCS
      }
    ]
  },
  methods: {
    openURL(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
