/**
 * Created by divya on 12/11/18.
 */
import * as _ from 'lodash-es';
import * as angular from 'angular';
import {monthDays} from '../../components/landing/datapull/scheduler.utils';

alertsDialogController.$inject = ['AlertServiceFactory', '$timeout', 'toastNotification', 'UserWorkspace', '$scope', 'oldElementServiceFactory','modalService', 'analyticsService', 'c'];


const AlertDefaults = {
    subject: '{{alert_name}} 🔔 ',
    body: 'Current value of the {{metric_title}} is  {{metric_value}}'
}

export function alertsDialogController(AlertServiceFactory, $timeout, toastNotification, UserWorkspace, $scope, oldElementServiceFactory,modalService, analyticsService, c) {
  this.$onInit = function () {
    var mvm = this;
    var alertService = AlertServiceFactory.getByDataviewId(parseFloat($scope.dataview.id));
    mvm.editMode = false;
    mvm.alertSourceActive = false;
    mvm.typeOfAlertActive = false;
    mvm.sendByActive = false;
    mvm.selectedAlert = null;
    mvm.showLoaderOnLeftPane = true;
    mvm.noAlertsPresent = true;
    mvm.addNewAlert = false;
    mvm.discardChanges = discardChanges;
    mvm.checkIfMetricsPresent = checkIfMetricsPresent;
    mvm.metricsPresent = undefined;
    mvm.listPopulated = false;
    mvm.alertName = undefined;
    mvm.selectAlert = selectAlert;
    mvm.addAlert = addAlert;
    mvm.alertScheduleValuesPresent = false;
    mvm.scheduleCondition = undefined;
    mvm.selectedMetric = null;
    mvm.elementService = oldElementServiceFactory.getByDataviewId($scope.dataview.id);
    mvm.alertSourceOptions = [
      {val: 'metrics', disp: 'Element - metrics'}
    ];
    mvm.alertSource = this.alertSourceOptions[0].val;
    mvm.metricList = [];
    mvm.metricName = undefined;
    mvm.metricId = undefined;
    mvm.alertAllowedEmails = [];
    _.forEach(UserWorkspace.users, (user) => {
      mvm.alertAllowedEmails.push({val: {name: user.name, email: user.email}, disp: user.email});
    });
    mvm.toggleAlertSource = false;
    mvm.toggleTypeOfAlert = false;
    mvm.setTypeOfAlert = setTypeOfAlert;
    mvm.typeOfAlert = 'condition';
    mvm.scheduleFrequencyOptions = [
      {val: 'hourly', disp: 'Hourly'},
      {val: 'daily', disp: 'Daily'},
      {val: 'weekly', disp: 'Weekly'},
      {val: 'monthly', disp: 'Monthly'}
    ];
    mvm.scheduleFrequency = undefined;
    mvm.hourly = 'hourly';
    mvm.weekly = 'weekly';
    mvm.monthly = 'monthly';
    mvm.daily = 'daily';
    mvm.hourlyOptions = [
      {val: 3, disp: '3 hours'},
      {val: 6, disp: '6 hours'},
      {val: 12, disp: '12 hours'}
    ];
    mvm.dailyOptions = [
      {val: 'am', disp: 'AM'},
      {val: 'pm', disp: 'PM'}
    ];
    mvm.dailyHourInput = 10;
    mvm.dailyMinuteInput = 15;
    mvm.dailyAmPm = 'am';
    mvm.hourlyFrequency = 3;
    mvm.sendOnDataChange = false;
    mvm.dataChangeText = 'Do not send alert if there is no change in the data';
    mvm.weeklyOptions = [
      {val: 'monday', disp: 'Monday', selected: true},
      {val: 'tuesday', disp: 'Tuesday', selected: false},
      {val: 'wednesday', disp: 'Wednesday', selected: false},
      {val: 'thursday', disp: 'Thursday', selected: false},
      {val: 'friday', disp: 'Friday', selected: false},
      {val: 'saturday', disp: 'Saturday', selected: false},
      {val: 'sunday', disp: 'Sunday', selected: false},
    ];
    mvm.monthDays = monthDays;
    mvm.byMonthDay = 1;
    mvm.toggleSendBy = false;
    mvm.alertSendByOptions = [
      {val: 'email', disp: 'Email'}
      // ,{val: 'sms', disp: 'Sms'}
      // ,{val: 'slack', disp: 'Slack'}
    ];
    mvm.alertSendBy = this.alertSendByOptions[0].val;
    mvm.alertEmailSubject = AlertDefaults.subject;
    mvm.alertEmailMessage = AlertDefaults.body;
    mvm.email = 'email';
    mvm.sms = 'sms';
    mvm.slack = 'slack';
    mvm.alertSmsMessage = undefined;
    mvm.charCount = 0;
    mvm.slackWebhookUrl = undefined;
    mvm.slackMessage = undefined;
    mvm.emailRecipients = [];
    mvm.alertSourceValuesPresent = false;
    mvm.toggleRecipients = undefined;
    mvm.alertConditionOperand = undefined;
    mvm.alertConditionOperatorOptions = [
      {val: 'EQ', disp: 'is equal to'},
      {val: 'NE', disp: 'is NOT equal to'},
      {val: 'LT', disp: 'is less than'},
      {val: 'LTE', disp: 'is less than or equal to'},
      {val: 'GT', disp: 'is greater than'},
      {val: 'GTE', disp: 'is greater than or equal to'}
      // { val: 'IN_RANGE', disp: 'in between' }
    ];
    mvm.alertConditionOperator = undefined;
    mvm.setupAlert = setupAlert;
    mvm.templateInfo = {template_string: {}};
    mvm.alertCondition = {metric: {}};
    mvm.addressInfo = {address_type: '', from_block: {}, to_block: {}};
    mvm.dispatchConfigInfo = {};
    mvm.listOfAlerts = {};
    mvm.updateList = updateList;
    mvm.runNow = runNow;

    mvm.updateList();
    mvm.checkIfMetricsPresent();
    mvm.deleteAlertById = deleteAlertById;

    function updateList() {
      alertService.fetchUpdates().then(_updateCb);

      function _updateCb() {
        mvm.listOfAlerts = alertService.alertsRegister;
        if (Object.keys(mvm.listOfAlerts).length === 0) {
          mvm.noAlertsPresent = true;
        } else {
          mvm.noAlertsPresent = false;
        }
        if (mvm.alertId) {
          selectAlert(mvm.listOfAlerts[mvm.alertId])
        } else if (mvm.addNewAlert == false) {
          selectAlert(mvm.listOfAlerts[Object.keys(mvm.listOfAlerts)[0]]);
        }
        mvm.alertsList = Object.values(mvm.listOfAlerts);
        mvm.listPopulated = true;
        mvm.showLoaderOnLeftPane = false;
      }
    }

    function selectMetricFromScope() {
      if (mvm.metricList.length && mvm.metricId) {
        mvm.selectedMetric = _.filter(mvm.metricList, {id: mvm.metricId})[0];
      }
    }

    function runNow() {
      if (mvm.selectedAlert) {
        mvm.selectedAlert.fire().then(function (data) {
          toastNotification.success("Done!")
        });
      }
    }

    function selectAlert(value?) {
      if (value) {
        mvm.selectedAlert = value;
        mvm.alertId = value.id;
        mvm.alertName = value.alert_name;
        mvm.alertSource = mvm.alertSourceOptions[0].val;
        mvm.metricId = value.metric_id;
        $timeout(selectMetricFromScope);
        setTypeOfAlert('condition');
        mvm.alertConditionOperator = Object.keys(value.condition.metric)[0];
        mvm.alertConditionOperatorInSummary = mvm.alertConditionOperatorOptions.find(obj => obj.val === mvm.alertConditionOperator)['disp'];
        mvm.alertConditionOperand = Object.values(value.condition.metric)[0];
        mvm.alertSendBy = value.dispatch_config_info.dispatch_type;
        mvm.alertEmailSubject = value.template_info.template_string.subject;
        mvm.alertEmailMessage = value.template_info.template_string.message;
        mvm.emailRecipients = value.address_info.to_block.email;
        mvm.emailRecipientsArray = [];
        mvm.emailRecipients.forEach((data) => {
          mvm.emailRecipientsArray.push(data.email);
        });
        mvm.emailRecipientsString = mvm.emailRecipientsArray.join(',');
      } else {
        mvm.alertId = undefined;
        mvm.alertName = undefined;
        if (mvm.metricList.length === 1) {
          mvm.metricName = mvm.metricList[0].disp;
        } else {
          mvm.metricName = undefined;
        }
        mvm.alertConditionOperator = undefined;
        mvm.alertConditionOperand = undefined;
        mvm.alertEmailSubject = AlertDefaults.subject;
        mvm.alertEmailMessage = AlertDefaults.body;
        mvm.emailRecipients = [];
      }
    }

    function setupAlert() {
      mvm.showLoaderOnLeftPane = true;
      mvm.editMode = false;
      mvm.addNewAlert = false;
      //Setting alert condition
      setAlertCondition();

      //Setting templateInfo
      setTemplateInfo();

      //Setting addressInfo
      setAddressInfo();
      // set dispatchInfo
      setDispatchConfig();

      //wait for 2 seconds so that metricParams are set
      //TODO USE THE CONCEPT OF PROMISES HERE
      $timeout(function () {
        createAlertandSend();
      }, 2000);
    }

    function createAlertandSend() {
      var alert = alertService.createAlert();
      alert.alert_name = mvm.alertName;
      if (mvm.alertId) {
        alert.id = mvm.alertId;
      }
      alert.metric_id = mvm.metricId;
      alert.condition = mvm.alertCondition;
      alert.template_info = mvm.templateInfo;
      alert.address_info = mvm.addressInfo;
      alert.dispatch_config_info = mvm.dispatchConfigInfo;
      alert.save().then(updateList, updateList);
    }

    function setAlertCondition() {
      mvm.alertCondition = {metric: {}};
      mvm.alertCondition['metric'][mvm.alertConditionOperator] = mvm.alertConditionOperand;
    }

    function setTemplateInfo() {
      mvm.templateInfo['template_string']['subject'] = mvm.alertEmailSubject;
      mvm.templateInfo['template_string']['message'] = mvm.alertEmailMessage;
    }

    function setAddressInfo() {
      mvm.addressInfo['address_type'] = mvm.alertSendBy;
      // mvm.addressInfo['from_block']['name'] = UserWorkspace.logged_in_user.name;
      // mvm.addressInfo['from_block']['email'] = UserWorkspace.loggedInEmail;
      mvm.addressInfo['from_block']['name'] = "Mammoth Alerts";
      mvm.addressInfo['from_block']['email'] = "alerts@mammoth.io";
      mvm.addressInfo['to_block']['email'] = mvm.emailRecipients;

    }

    function setDispatchConfig() {
      mvm.dispatchConfigInfo['dispatch_type'] = mvm.alertSendBy;
      mvm.dispatchConfigInfo['config_type'] = 'GLOBAL';
      mvm.dispatchConfigInfo['handler_type'] = 'MAILGUN';
      mvm.dispatchConfigInfo['config_param'] = {
        api_key: 'key-032ddeba8b219888f7ee43e80a4223fe'
      };
    }

    function deleteAlertById(alertId, alert) {
      selectAlert();
      var item = {
        name: alert.alert_name,
        type: "alert",
      };
      modalService.deleteAlert(item)
        .then(_deleteAlert, discardChanges);

      function _deleteAlert() {
        alert.deleteAlert(alertId, $scope.dataview.id).then(updateList, updateList);
        mvm.showLoaderOnLeftPane = false;
      }

      analyticsService.userEventTrack(c.userEvents.alertEvents.deleteAlert, {eventOrigin: "alertModal.alert"});
    }

    function setTypeOfAlert(value: string) {
      mvm.typeOfAlert = value;
    }

    function addAlert() {
      mvm.alertId = undefined;
      mvm.addNewAlert = true;
      selectAlert();
      analyticsService.userEventTrack(c.userEvents.alertEvents.createNewAlert, {eventOrigin: "alertModal.alert"});
    }

    function discardChanges() {
      // selectAlert();
      mvm.addNewAlert = false;
      mvm.editMode = false;
      updateList();
      analyticsService.userEventTrack(c.userEvents.alertEvents.editAlert, {eventOrigin: "alertModal.alert"});
    }

    function checkIfMetricsPresent() {
      mvm.elementService.updateList().then(() => {
        _.forEach(mvm.elementService.metrics, (metric, mid) => {
          let display_name = metric.display_properties.info.title;
          var stringToDisplay: string = display_name;
          mvm.metricList.push({id: metric.id, disp: stringToDisplay});
        });
        mvm.metricsPresent = Object.keys(mvm.elementService.metrics).length > 0;
        selectMetricFromScope();
      });
    }

  }
}
