/*jslint node: true */
import * as _ from 'lodash'
/**
 * @ngInject
 */
publishController.$inject = ['Auth', '$scope', 'VuexStore', 'appMetaInfo', 'eventCallbackManagerFactory', 'utils']
export function publishController(Auth, $scope, $store, appMetaInfo, eventCallbackManagerFactory, utils) {
  this.$onInit = function () {
    var vm = this
    vm.dataview = {
      exploreCardCloseEvent: new eventCallbackManagerFactory('exploreCardCloseEvent'),
    }
    vm.appMetaInfo = appMetaInfo;

    Auth.check_token().then(() => {
      appMetaInfo.pageLoaded();
    })

    vm.$store = $store
    vm.publishCopy = null

    // workaround because the angular directive is bi-directional and therefore would update the vuex values, which is not allowed
    $scope.$watch('vm.$store.state.publish.reportMetadata', function (newValue, oldValue) {
      vm.publishCopy = JSON.parse(JSON.stringify($store.state.publish))
    })

    $scope.$watch('vm.$store.state.publish.report', function (newValue, oldValue) {
      if ($store?.state?.publish?.report) {
        let report_name = utils.decodeSanitizedName($store.state.publish.report?.name)
        if (report_name)
          appMetaInfo.prefixPageTitleWith(report_name);
      }
    })

    vm.onFilterSelect = condition => {
      vm.$store.commit('publish/setDatasetCondition', condition)
    }
    vm.onConfigUpdated = config => {
      vm.$store.commit('publish/setExploreConfig', config)
    }
    vm.getSourceData = params => {
      const {param, display_properties} = params
      return vm.$store.state.publish.getSourceDataFunc(param, display_properties, vm.$store.state.publish.reportId)
    }

    vm.columnOpenEvent = new eventCallbackManagerFactory('columnOpenEvent')
    vm.$store.commit('publish/setColumnOpenEvent', vm.columnOpenEvent)

    vm.onExploreColumnsClick = function () {
      vm.$store.commit('publish/setIsExploreSectionOpen', !vm.$store.state.publish.isExploreSectionOpen)
    }
  }
}
