import * as _ from 'lodash-es';
import * as angular from 'angular';

export class landingSelection {
  private isLocked = false;
  private dropEvent;
  c: any;
  resources: any;
  public selection = {
    items: [],
    resource_ids: {}
  };

  public dragging = {
    items: [],
    inProcess: false,
    onDragStart: undefined,
    fireDragStart: undefined,
    onDragStop: undefined,
    fireDragStop: undefined,
    onDrop: undefined,
    fireDrop: undefined,
  };
  resourceSelectionChangeEvent;
  onSelectionChange;
  public onUploaderItemRemove = undefined;
  private _debouncedSelectResource = _.debounce((lineItem) => this.selectResource(lineItem), 500);

  static $inject = ['landingList', 'eventCallbackManagerFactory', 'c', 'resources'];

  public constructor(private landingList, eventCallbackManagerFactory, c, resources) {
    this.c = c;
    this.resources = resources;
    this.resourceSelectionChangeEvent = new eventCallbackManagerFactory('onResourceSelectionChangeEvent');
    this.onSelectionChange = this.resourceSelectionChangeEvent.add_callback;
    const dragStartEvent = new eventCallbackManagerFactory('landingSelectionDragStart');
    const dragStopEvent = new eventCallbackManagerFactory('landingSelectionDragStop');
    this.dropEvent = new eventCallbackManagerFactory('landingSelectionDrop');
    this.dragging.onDragStart = dragStartEvent.add_callback;
    this.dragging.fireDragStart = dragStartEvent.fire_event;
    this.dragging.onDragStop = dragStopEvent.add_callback;
    this.dragging.fireDragStop = dragStopEvent.fire_event;
    this.dragging.onDrop = this.dropEvent.add_callback;
    this.dragging.fireDrop = (data) => this.fireDrop(data);
  }

  public lock() {
    this.isLocked = true;
  }

  public unlock() {
    this.isLocked = false;
  }

  public selectResource(resource: any, isMultiselect?) {
    if (this.isLocked || (resource.type !== this.c.resourceTypes.ws && _.findIndex(this.landingList.currentLabelView.contents, (item: any) => item.id === resource.id) == -1)) {
      return;
    }
    // isMultiselect = false // temporary disabling multiselect
    var wasPreviouslyUnselected = !this.selection.items.length;
    if (isMultiselect) {  // multiselect
      const alreadySelected = this._removeResourceFromSelectionIfExists(resource);
      if (alreadySelected) {
        this.resourceSelectionChangeEvent.fire_event();
      }
      else {
        if (resource.type === this.c.resourceTypes.ws) {
          if (this._removeResourceFromSelectionIfExists(resource.datasource)) {
            this.resourceSelectionChangeEvent.fire_event();
          }
        }
        else if (resource.type === this.c.resourceTypes.ds) {
          angular.forEach(resource.dataviews_list, (wksp) => {
            if (this._removeResourceFromSelectionIfExists(wksp)) {
              this.resourceSelectionChangeEvent.fire_event();
            }
          });
        }
        this._addResourceToSelection(resource);
        this.resourceSelectionChangeEvent.fire_event(wasPreviouslyUnselected);
      }
    }
    else {  // single select
      if (this.selection.items.length > 1) {  // if already multi-selected deselect everything
        this.clearSelection();
      }
      if (this.selection.items.length === 1) {
        if (!this.selection.resource_ids[resource.resource_id]) {  // set new selection
          this.clearSelection();
        } else {
          return;
        }
      }
      this._addResourceToSelection(resource);
      this.resourceSelectionChangeEvent.fire_event(wasPreviouslyUnselected);
    }
  }

  private _addResourceToSelection(resource) {
    this.selection.items.push(this.resources.resourcesMap[resource.resource_id]);
    this.selection.resource_ids[resource.resource_id] = true;
  };

  /**
   * @return {boolean} True if resource found and removed, else false
   */
  private _removeResourceFromSelectionIfExists(resource):boolean {
    const res_id = resource.resource_id;
    if (_.has(this.selection.resource_ids, res_id)) {
      delete this.selection.resource_ids[res_id];
      const _key_index = _.findIndex(this.selection.items, ['resource_id', res_id]);
      if (_key_index > -1) {
        this.selection.items.splice(_key_index, 1);
      }
      return true;
    }
    return false;
  };

  public clearSelection() {
    _.forOwn(this.selection.resource_ids, (v, k, o) => {delete o[k]});
    this.selection.items.splice(0, this.selection.items.length);
  };


  public selectResourceDebounced(lineItem) {
    if (this.selection.items.length === 0) {
      this._debouncedSelectResource(lineItem);
    }
  }

  private fireDrop(data) {
    if (this.selection.items && _.intersection(this.selection.items, this.dragging.items).length) {
      this.clearSelection();
    }
    this.dropEvent.fire_event(data);
  }
}

