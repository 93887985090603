/*jslint node: true */
import * as $ from "jquery";
import * as angular from "angular";
import * as _ from 'lodash-es';
import {ExploreConsts} from "./explore.constants";
import {getWindowSize} from '../common/common.directives';
import {config} from "../common/app.config";


/**
 * @ngInject
 */
explorePanel.$inject = ['config', '$timeout', '$location'];
export function explorePanel(config, $timeout, $location){
  return {
    scope: {
      isOpen: '=',
      config: '=',
      sourceId: '=',
      sourceType: '=',
      sourceMetadata: '=',
      sourceStatus: '=',
      dataview: '=',
      sourceDataUpdatedAt: '=',
      sourceFormatInfo: '=',
      getSourceData: '&',
      condition: '=?',
      saveCb: '&?',
      stepPreviewSequenceUpdateEvent: '=?',
      sourceUpdateEvent: '=?',
      exportFileNamePrepend : '=?',
      conditionCb: '&?',
      exploreButtonClickEvent: '=?',
      secondaryActionMode: '=?',
      onSecondaryAction: '&?',
      onToggleExplorePanel: '&',
      saveFilter: '=?',
      saveFilterCallback: '&?',
      onVisibleCallback: '&?',
      configUpdatedCallback: '&?',
      persistFilters: '=?'
    },
    controller: "explorePanelController",
    controllerAs: "panel",
    bindToController: true,
    templateUrl: config.templates.explorePanel,
    link: function(scope, element, attrs){
      let derigister = null;
      setup();

      function setup(){
        scope.panel.init();
        scope.panel.onVisibleCallback({cb: _visibleCallback});

        if(derigister){
          derigister();
          derigister = null;
        }
      }

      function _visibleCallback(){
        $timeout(function(){
          scope.$broadcast('vsRepeatTrigger');
        }, 200);
      }
      scope.panel.cardCollection.onReorder("Main directive", _visibleCallback);
    }
  }
}

/**
 * @ngInject
 */
exploreButton.$inject = ['config'];
export function exploreButton(config){
  return {
    scope: {
      isOpen: '=',
      hideButton: '='
    },
    replace: true,
    templateUrl: config.templates.explorePanelButton
  }
}

/**
 * @ngInject
 */
exploreFilterDescription.$inject = ['config', '$templateCache', '$compile'];
export function exploreFilterDescription(config, $templateCache, $compile){
  return {
    scope: {
      card: '=',
      panel: '=',
      isOpen: '='
    },
    link: function(scope, element){
      let uri = config.templates.exploreFilterDescription;
      let templateStr = $templateCache.get(uri);
      var element_to_append = $compile(templateStr)(scope);
      let dropdownId = '.filter-dropdown-menu-' + scope.card.id;
      $(document.body).append(element_to_append);

      scope.$watch('isOpen', _reposition);
      scope.$on('$destroy', function(){
        $(dropdownId).remove();
      });

      function _reposition(isOpen){
        let p1 = $(element).offset();
        let w1 = $(element).width();
        $(dropdownId).css({top: p1.top + 30, left: p1.left - 10, width: w1, 'min-width': '200px'});
      }
    }
  }
}


exploreMenuRepositon.$inject = ['$timeout'];
export function exploreMenuRepositon($timeout){
  return {
    link: function(scope, element, attrs){
      scope.$watch(attrs.isOpen, _delayedRepositon);

      function _delayedRepositon(){
        let dropdownCls = attrs.exploreMenuRepositon;
        var elementToPosition = $('.' + dropdownCls);
        elementToPosition.addClass('ng-hide');
        $timeout(_reposition, 10);
        function _reposition() {
          let isOpen = scope.$eval(attrs.isOpen);
          if (!isOpen) {

            return;
          }


          let menuButton = $(element);
          var buttonOffset = $(menuButton).offset();

          var elmCss: any = {
            top: buttonOffset.top
          };
          var ele_width = $(elementToPosition).outerWidth();
          var total_width = $('body').width();
          var menu_button_left = buttonOffset.left;
          var spaceToRight = total_width - menu_button_left - ele_width;
          if (spaceToRight < 0) {
            elmCss.left = (menu_button_left + 27) - ele_width;
          }
          else {
            elmCss.left = (menu_button_left + 27);
          }
          $(elementToPosition).css(elmCss);
          elementToPosition.removeClass('ng-hide');

        }
      }


    }
  }
}

/**
 * @ngInject
 */
exploreCardDisplay.$inject = ['$templateCache', '$compile', 'utils', 'c'];
export function exploreCardDisplay($templateCache, $compile, utils, c){
  return {
    scope: {
      card: '=',
      isActive: '=',
      removeCard: "&"
    },
    replace: true,

    link: function(scope, element, attrs){
      scope.rendered = false;
      scope.saveChart = function () {
          scope.$broadcast("SaveChart");
      };
      scope.$on("CardRendered", function (evt, data) {
          scope.rendered = data;
      });
      var templateMap = {
        'text': 'textExploreCard.tpl.html',
        'numeric': 'drilldownExploreCard.tpl.html',
        'date': 'drilldownExploreCard.tpl.html',
        'custom': 'customExploreCard.tpl.html',
      };
        var debouncedSearch = utils.debounce(function () {
            scope.card.query()
        }, 500);
      //Backend search only implemented for text column explore cards for now
      if (_.get(scope.card, 'column.type', '') == c.text) {
        scope.$watch(
            function (scope) {
                return scope.card.search.string
            },
            function (oldVal, newVal) {
                if(oldVal !== newVal){
                    debouncedSearch()
                }
        });
      }
      var content = $templateCache.get(templateMap[scope.card.type]);
      var element_to_append = $compile(content)(scope);
      $(element).html(element_to_append);
      $(window).click(function(e){
        var t: any;
        t = $(e.target)
        var isInsideClick = $(element).has(t).length;
        if(!isInsideClick){
          if(scope.card.deactivate){
            scope.card.deactivate();
          }
        }
      });
    }
  }
}

/**
 * @ngInject
 */
export function selectedOptionsExploreCard(){
    return {
      templateUrl: 'selectedOptionsExploreCard.tpl.html',
      restrict: 'E',
      replace: true
    }
}

/**
 * @ngInject
 */
export function showDateValInSummary(){
  return {
    link: function(s, e, a){
      function _render(){
        var dateVal = s.$eval(a.showDateValInSummary);
        if(!dateVal){
          return;
        }

        var parts = dateVal.split(' ');
        var content = "<span class=\"date\">" + parts[0] + "</span>";
        if(parts.length > 1){
          content += "<span class=\"time\">" + parts[1] + "</span>";
        }
        $(e).html(content);
      }
      s.$watch(a.showDateValInSummary, _render);
    }
  }
}

/**
 * @ngInject
 */
textExploreCardClickListener.$inject = ['clickEvents', '$filter', 'c'];
export function textExploreCardClickListener(clickEvents, $filter, c){
    return {
      link: function(scope, element, attrs){
        var _prevSelected = null;
        var data;
        $(element).click(_clickListener);
        function _clickListener(event){
          if(!scope.card.data){
            return;
          }
          if (scope.card.column.type == c.text){
              data = scope.card.data;
          }else{
              data = $filter("filterTextItems")(scope.card.data, scope.card.search.string);
          }
          var scope_of_item:any = angular.element(event.target).scope();
          var item = scope_of_item.item;
          var _prevSelectedIndex = data.indexOf(_prevSelected);
          if(item && event.shiftKey && _prevSelectedIndex != -1){
            var _currentIndex = data.indexOf(item);
            var indeces = [_prevSelectedIndex, _currentIndex];
            indeces.sort();
            for(var i = indeces[0]; i <= indeces[1]; i++){
              if(!data[i].selected){
                scope.card.toggle(data[i]);
              }
            }
            _prevSelected = item;
          }
          else if(item && (event.ctrlKey || event.metaKey)){
            scope.card.toggle(item);
            _prevSelected = item;
          }
          else if(item){
            if(item.selected){
              scope.card.toggle(item);
              _prevSelected = null;
            }
            else{
              scope.card.deactivate(true);
              scope.card.toggle(item);
              _prevSelected = item;
            }
          }
        }
      }
    }
}

/**
 * @ngInject
 */

wkspRowCountService.$inject = ['derivatives', 'globalFilterService', '$resource', 'c', 'FutureService', '$q', 'VuexStore'];
export function wkspRowCountService(derivatives, globalFilterService, $resource, c, FutureService, $q, $store){
  var _reg = {};
  return {
    template: '<small-inline-loader ng-show="service.isLoading"></small-inline-loader><span class="sort" ng-hide="service.isLoading"><span ng-show="!!service.filterCondition">Showing <span ng-bind="(service.visibleRowCount | humanizeInt)" class="count"></span> of </span><span ng-bind="(service.rowCount | humanizeInt)"></span> rows</span>',
    getByWs: getByWs
  };

  function getByWs(ws, persistFilters){
    var str_ws_id = '' + ws.id;
    if(!_reg.hasOwnProperty(str_ws_id)){
      _reg[str_ws_id] = new WsRowCount(ws, persistFilters);
    }
    return _reg[str_ws_id];
  }

  function get_data_tracker(data) {
    var deferred = $q.defer();
    FutureService.track(data.future_id, data_tracker);

    function data_tracker(future) {
      let response = future.response;
      if (future.status == "processing") {
        return;
      }
      if (future.status == "success") {
        deferred.resolve(response);
      } else {
        deferred.reject(response);
      }
    }
    return deferred.promise;
  }

  function WsRowCount(ws, persistFilters){
    var self = this;
    self.conditionEnabled = false;
    self.loading = false;
    self.dataview = ws;
    var gf = globalFilterService.getBySource(ws.id, ws.type, persistFilters);
    gf.onChange('fromInsideRowCountDirective', getDataviewCountForCondition);

    function getDataviewCountForCondition(){
      var condition = gf.getCondition();
      if(condition){
        self.conditionEnabled = true;
        self.loading = true;
        var param = {
          PIVOT: {
            SELECT: [
              {
                FUNCTION: "COUNT",
                AS: "COUNT",
                INTERNAL_NAME: 'count'
              }
            ]
          },
          CONDITION: condition
        };
        let query_res = $resource(config.api.volatile_query);
        if(Number.isInteger(ws.stepPreview.sequence)) {
          param[c.sequenceNumber] = ws.stepPreview.sequence;
        }
        var deferred = $q.defer();
        query_res.save(
          {dataviewId: ws.id},
          {param: param, display_properties: {}}
        ).$promise.then(function (data) {
          get_data_tracker(data).then(function (response) {
            self.currentRowCount = response.data[0].count;
            self.loading = false;
            $store.commit('dataEditor/setVisibleRowCount', { viewId: ws.id, visibleRowCount: self.currentRowCount})
            deferred.resolve(response.data);
          });
        }, deferred.reject);
      }
      else{
        self.loading = false;
        self.conditionEnabled = false;
      }
    }
  }
}

/**
 * @ngInject
 */
showWkspRowCount.$inject = ['wkspRowCountService'];
export function showWkspRowCount(wkspRowCountService){
  return {
    template: '<small-inline-loader ng-show="service.loading"></small-inline-loader><span ng-hide="service.loading"><span ng-show="service.conditionEnabled">Showing <span ng-bind="(service.currentRowCount | humanizeInt)" class="count"></span> of </span><span ng-bind="service.dataview.row_count | humanizeInt"></span> rows</span>',
    scope: {dataview: "="},
    link: function(scope, element, attrs){
      scope.service = wkspRowCountService.getByWs(scope.dataview, scope.$parent.vm.persistFilters);
    }
  }
}

/**
 * @ngInject
 */
delayedAutofocus.$inject = ['$timeout'];
export function delayedAutofocus($timeout){
  return {
    link: function(s, e, a){
      $timeout(function(){
        $(e).focus();
      }, 200);
    }
  }
}




/**
 * @ngInject
 */
checkIfOutsideViewport.$inject = ['$timeout'];
export function checkIfOutsideViewport($timeout){
  return {
    link: function(scope, element, attributes){
      scope.$watch(attributes.onVisible, function(){
        $timeout(_check, 10);
      });
      function _check(){
        let e = $(element);
        let offset = e.offset();
        let width = e.outerWidth();
        let docWidth =$(document.body).width();
        if(offset.left + width > docWidth){
          $(element).offset({left: docWidth - (width + 10), top: undefined});
        }
      }
    }
  }
}



/**
 * @ngInject
 */
export function showExploreListItemPercentage(){
  return {
    link: function (scope, element) {
      let p = parseFloat(scope.item.percentage);
      let t;
      if(p < 0.1){
        t = '<0.1%';
      }
      else if(p < 1){
        t = String(p) + '%';
      }
      else {
        t = parseInt(scope.item.percentage) + '%';
      }
      t = '(' + t + ')';
      $(element).html(t);
    }
  }
}

/**
 * @ngInject
 */
changeExploreAggregation.$inject = ['$timeout'];
export function changeExploreAggregation($timeout){
    return {
      scope: {
        properties: '=',
        column: '=',
        columns: '=',
        saveCb: '&',
        formatInfo: '=',
        granularity: '=',
        isOpen: '=',
        showFormat: '='
      },
      replace: true,
      templateUrl: 'changeExploreAggreagtionForm.tpl.html',
      controller: 'ChangeExploreAggregationCtrl',
      controllerAs: 'cm',
      bindToController: true,
      link: function($scope){
        $timeout(function(){
          $scope.inited = true;
        }, 100);
        $scope.$watch('cm.granularity', function(){
          $scope.cm.granularitySelected = $scope.cm.granularity;
        })
      }
    }
}

/**
 * @ngInject
 */
export class ChangeExploreAggregationCtrl {
  public allowedAggregations = ExploreConsts.aggregationOptions;
  public granularityOptions = ExploreConsts.dateGranularityOptions;
  public target: string;
  public aggregation: string;
  public format: any;
  public column: any;
  public columns: any;
  public hasChanged:boolean;
  public formatInfo:any;
  public saveCb: Function;
  public properties: any;
  public granularity: any;
  public granularitySelected: any;
  public isOpen: boolean;
  public showFormat: boolean;
  public humanized: boolean;

  static $inject = ['$timeout'];
  public $onInit: () => void;
  constructor(public $timeout){
    this.$onInit = function () {
      this._reset();
    }
  }

  private  _reset(){
    this.target = this.properties.target;
    this.aggregation = this.properties.aggregation;
    this.format = this.properties.format;
    this.humanized = this.properties?.format?.scientific;
    this.granularitySelected = this.granularity;
    if(this.column.type == 'NUMERIC'){
      this.granularityOptions = ExploreConsts.numGranularityOptions;
    }
    else if (this.column.type == 'DATE'){
      this.granularityOptions = ExploreConsts.dateGranularityOptions;
    }
  }

  public handleChange(){
    if (this.humanized) {
      this.format = {scientific: true}
    }else{
      this.format = {comma_separated: true}
    }
    this.setAsChanged();
  }

  public setAsChanged(){
    this.hasChanged = true;
  }

  public cancel(){
    this._reset();
    this.isOpen = false;
  }

  public save(){
    let _this = this;
    _this.granularity = _this.granularitySelected;
    _this.isOpen = false;
    //Wrapping the below statements in $timeout because the $watch won't be triggered until the current function execution is compete.
    _this.$timeout(()=> {
      _this.properties.aggregation = _this.aggregation;
      _this.properties.target = _this.target;
      _this.properties.format = _this.format;
      _this.properties.humanized = _this.humanized;
      _this.saveCb();
    });
  }
}


/**
 * @ngInject
 */
export function scrollToExploreCard(){
    return {
      link: function(scope, element, attrs){
        let _state = scope.card.isActive;
        if(scope.card.new){
          _scroll();
        }

        scope.$watch('card.isActive', function (val) {
          if(val != _state){
            _state = val;
          }
          if(_state){
            _scroll();
          }
        });

        function _scroll(){
         (<any>$(element)).scrollintoview({
            duration: 200,
            direction: 'x',
            viewPadding: {x: 50},
          });
        }
      }
    }
}



/**
 * @ngInject
 */
allowExploreCardResize.$inject = ['$timeout'];
export function allowExploreCardResize($timeout){
  return {
    link: function(scope, element, attributes){
      if(scope.card.type == 'text'){
        if(parseInt(scope.card.customWidth) > 300){
          $(element).css({width: scope.card.customWidth + 'px'});
        }
        $(element).resizable({
          handles: 'e',
          create: function( event, ui ) {
            $(".ui-resizable-e").css("cursor","col-resize");
          },
          minWidth: 300,
          stop: function(event, ui){
            scope.card.customWidth = $(element).width();
            scope.card.saveConfig();
          }
        });
      }
    }
  }
}


exploreSortMenu.$inject = ['c', 'analyticsService'];
export function exploreSortMenu(c, analyticsService){
  return {
    scope: {
      card: '=',
      isOpen: '='
    },
    templateUrl: "cardSortMenu.tpl.html",
    controller: function CardSortMenuCtrl() {
      var srtc = this;
      srtc.sortByDirs = [
        {"internal": "ASC", display: "ASCENDING"},
        {"internal": "DESC", display: "DESCENDING"},
      ];

      this.init = function(){
        if(!srtc.card){
          return;
        }
        srtc.sortByKey = _.get(srtc, 'card.sortBy[0][0]');
        srtc.sortByDir = _.get(srtc, 'card.sortBy[0][1]');
        let valSortKey = 'value';
        if(srtc.card.column.type == c.numeric || srtc.card.column.type == c.date){
          valSortKey = 'unformatted_value';
        }
        srtc.sortByKeys = [
          {internal: 'unformatted', display: srtc.card.selectedAggregation.aggregation},
          {internal: valSortKey, display: srtc.card.column.display_name}
        ];
      };
      this.submit = function(){
        analyticsService.userEventTrack(c.userEvents.exploreCards.sort, {eventOrigin: "dataview.exploreCard.sortMenu"})
        srtc.card.sort([[srtc.sortByKey, srtc.sortByDir]]);
        srtc.card.changeAggOptionsCb(false);
        srtc.isOpen = false;
      };

      this.cancel = function(){
        srtc.init();
        srtc.isOpen = false;
      };
    },
    controllerAs: 'srtc',
    bindToController: true,
    link: function(scope, ele, attrs){

      scope.$watch('srtc.card.column.display_name', function (v) {
        if (scope.srtc.card.type == 'text' && v) {
          scope.srtc.init();
        }
      });

      scope.$watch('srtc.card.sortBy', function _apply(v){
        if(v){
          scope.srtc.init();
        }
      });
      scope.$watch('srtc.card.selectedAggregation.aggregation', function (newValue) {
        if (newValue && scope.srtc.sortByKeys) {
          scope.srtc.sortByKeys[0].display = newValue;
        }
      });
    }
  }
}


export function repositionPopover() {
  return {
    scope: {
      isPopoverOpen: '@',
      isCardActive: '@'
    },
    link: function ($scope, $element, $attrs) {

      $attrs.$observe('isPopoverOpen', function (v) {

        let reposition = false;

        if (v == 'true') {

          setTimeout(function () {
            let popoverElem: any = document.getElementsByClassName('filter-popover-custom')[0];
            if (popoverElem) {
              if (parseFloat(popoverElem.style.left) > getWindowSize()[0]/2) {
                reposition = true;
              }
              if (reposition) {
                let elem = document.getElementsByClassName('filter-popover-wrapper-custom')[0];
                if (elem) {
                  elem.classList.replace('filter-popover-wrapper-custom', 'filter-popover-wrapper-custom-move-left');
                }
                popoverElem.classList.replace('filter-popover-custom', 'filter-popover-custom-move-left');
              }
            }
          }, 500);

        }
        else if (v == 'false') {

          setTimeout(function () {
            let elem = document.getElementsByClassName('filter-popover-wrapper-custom-move-left')[0];
            if (elem) {
              elem.classList.replace('filter-popover-wrapper-custom-move-left', 'filter-popover-wrapper-custom');
            }
          }, 100);
        }
      });

      $attrs.$observe('isCardActive', function (v) {
        if (v == 'true') {
          //TODO when card is active change the classes
        }
      });

    }
  };
}
