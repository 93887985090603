/*jslint node: true */

import {config} from "../common/app.config";

var urls = {
  'backends': config.apiUrl + '/admin/backends',
  users: config.apiUrl + '/admin/users',
  workspaces: config.apiUrl + '/admin/workspaces',
  workspace: config.apiUrl + '/admin/workspaces/:workspace_id',
  registrations: config.apiUrl + '/admin/registrations',
  subscriptions: config.apiUrl + '/admin/subscriptions',
  sms: config.apiUrl + '/admin/sms',
  integrations: config.apiUrl + '/admin/integrations',
  workspaceUser: config.apiUrl + '/admin/workspaces/:workspace_id/users/:user_id',
  workspaceSms: config.apiUrl + '/admin/workspaces/:workspace_id/sms'
}

/**
 * @ngInject
 */
adminService.$inject = ['$q', '$resource'];
export function adminService($q, $resource) {
  return {
    getDomains: getDomains,
    getWorkspaces: getWorkspaces,
    getWorkspaceDetails: getWorkspaceDetails,
    addDomain: addDomain,
    addUser: addUser,
    addWorkspace: addWorkspace,
    linkUser: linkUser,
    unLinkUser: unLinkUser,
    getRegistrations: getRegistrations,
    approveRegistration: approveRegistration,
    listSubscriptionGroups: listSubscriptionGroups,
    associateSubscriptionGroupToWorkspace: associateSubscriptionGroupToWorkspace,
    listIntegrations: listIntegrations,
    enableDisableIntegration: enableDisableIntegration,
    loadWorkspaceSMSUrl: loadWorkspaceSMSUrl,
    listSmsPlans: listSmsPlans
  }



  function getDomains(){
    var deferred = $q.defer();
    var res = new $resource(urls.backends);

    res.get(
      function (data) {
        deferred.resolve(data.domain_list);
      },
      deferred.reject);
    return deferred.promise;
  }

  function getWorkspaces(){
    var deferred = $q.defer();

    var res = new $resource(urls.workspaces);
    res.get(
      function (data) {
        deferred.resolve(data.workspaces);
      },
      deferred.reject);

    return deferred.promise;
  }

  function getRegistrations(){
    var deferred = $q.defer();

    var res = new $resource(urls.registrations);
    res.get(
      function (data) {
        deferred.resolve(data.registrations);
      },
      deferred.reject);

    return deferred.promise;
  }

  function getWorkspaceDetails(workspace_id){
    var deferred = $q.defer();
    var res = new $resource(urls.workspace, {workspace_id: workspace_id});

    res.get(
      function (data) {
        deferred.resolve(data.users);
      },
      deferred.reject);

    return deferred.promise;
  }

  function loadWorkspaceSMSUrl(workspace_id){
    var deferred = $q.defer();
    var res = new $resource(urls.workspaceSms, {workspace_id: workspace_id});
    res.get(
      function (data) {
        deferred.resolve(data);
      },
      deferred.reject);

    return deferred.promise;
  }

  function addDomain(domain_name, backend_params, domain_desc){
    var deferred = $q.defer();
    var postData = {
      name: domain_name,
      parameters: backend_params,
      description: domain_desc,
      type: 'postgres'
    }
    var res = new $resource(urls.backends);

    res.save(postData).$promise.then(deferred.resolve, deferred.reject);

    return deferred.promise;
  }

  function addUser(first_name, last_name, email, owner=null, workspace_id=null){
    var deferred = $q.defer();
    var postData = {
      first_name: first_name,
      last_name: last_name,
      email: email
    }
    // if the owner and workspace id present in the data then load the payload with the owner and workspace details
    if(owner && workspace_id){
      postData['owner'] = owner,
      postData['workspace_id'] = workspace_id
    }
    var res = new $resource(urls.users);

    res.save(postData).$promise.then(deferred.resolve, deferred.reject);
    return deferred.promise;
  }



  function addWorkspace(name, backend_domain_id){
    var deferred = $q.defer();
    var postData = {
      name: name,
      backend_domain_id: backend_domain_id
    }

    var res = new $resource(urls.workspaces);

    res.save(postData).$promise.then(deferred.resolve, deferred.reject);
    return deferred.promise;
  }

  function approveRegistration(reg_id, backend_domain_id, owner_email_id, workspace_name){
    var deferred = $q.defer();
    var postData = {
      registration_id: reg_id,
      backend_domain_id: backend_domain_id,
      workspace_name: workspace_name,
      owner_email_id: owner_email_id
    };

    var res = new $resource(urls.workspaces);

    res.save(postData).$promise.then(deferred.resolve, deferred.reject);
    return deferred.promise;
  }

  function linkUser(workspace_id, user_id, permission, remove_permission){
    var deferred = $q.defer();
    var postData = {
      user_id: user_id,
      permission: permission,
      remove_permission: remove_permission
    };
    var res = new $resource(urls.workspace, {workspace_id: workspace_id});
    res.save(postData).$promise.then(deferred.resolve, deferred.reject);
    return deferred.promise;
  }

  function unLinkUser(workspace_id, user_id){
    var deferred = $q.defer();
    var res = new $resource(urls.workspaceUser, {workspace_id: workspace_id, user_id: user_id});
    res.delete().$promise.then(deferred.resolve, deferred.reject);
    return deferred.promise;
  }

  function listSmsPlans() {
    var res = new $resource(urls.sms);
    return res.get().$promise
  }

  function listSubscriptionGroups() {
    var res = new $resource(urls.subscriptions);
    return res.get().$promise
  }



  function associateSubscriptionGroupToWorkspace(subscription_id, workspace_id) {
    var res = new $resource(urls.subscriptions);
    var postData = {
      subscription_id: subscription_id,
      workspace_id: workspace_id
    };
    return res.save(postData).$promise;
  }



  function listIntegrations(){
    var res = new $resource(urls.integrations);
    return res.get().$promise
  }

  function enableDisableIntegration(integration_id, enabled){
    var res = new $resource(urls.integrations);
    var postData = {
      integration_id: integration_id,
      enabled: enabled
    };
    return res.save(postData).$promise;
  }
}
