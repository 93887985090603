<template>
  <mm-modal
    v-model="computedIsModalOpen"
    :title="$t('data_library.data_addition.third_party.add_connectors.more')"
    width="450"
  >
    <span>{{ $t('data_library.data_addition.third_party.add_connectors.support') }}</span>

    <template #actions>
      <mm-button :label="$t('global.dictionary.not_now')" objective="tertiary" @click="computedIsModalOpen = false" />
      <mm-button
        class="m-l-3"
        :label="$t('global.contact_support')"
        @click="
          computedIsModalOpen = false
          mixinFreshdeskWidget_showSupportWidget()
        "
      />
    </template>
  </mm-modal>
</template>

<script>
import FreshdeskWidget from '@/mixins/support/freshdeskWidget'

export default {
  name: 'settings-workspace-plan-add-connectors-support',
  mixins: [FreshdeskWidget],
  props: {
    value: Boolean
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
