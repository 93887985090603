import * as angular from 'angular';
import _ = require('lodash');
/**
 * @ngInject
 */

filterUI.$inject = ['$uibModal', '$rootScope', 'filterManagerFactory', '$q', 'config', 'FilterEditHelperFactory'];
export function filterUI($uibModal, $rootScope, filterManagerFactory, $q, config, FilterEditHelperFactory){
  return {
    open: open
  };

  function open(manager, param=null){
    var openPromise = $q.defer();
    var scope = $rootScope.$new();
    var modal_manager = filterManagerFactory.get_manager({
      metadata: _.cloneDeep(manager.metadata),
      teardownOptions: {
        allow_teardown: true,
        teardown_callback: manager.teardown_options.teardown_callback
      },
      dataviewId: manager.dataviewId,
      context: manager.context
    });

    if (param) {
      modal_manager.setParam(param)
    } else if (!manager.condition) {
      modal_manager.add_root_condition();
    } else {
      modal_manager.setParam(_.cloneDeep(manager.getParam()));
    }
    scope.filterManager = modal_manager;
    var modalInstance = $uibModal.open({
      templateUrl: config.templates.filter,
      windowClass: 'modal-size-large filter-modal',
      scope: scope,
      backdrop: 'static'
    });
    modalInstance.result.then(function () {
      if (modal_manager.validate()) {
        manager.setParam(_.cloneDeep(modal_manager.getParam()));
      }
      openPromise.resolve();
    }, openPromise.reject);

    return openPromise.promise;
  }
}


FilterEditHelperFactory.$inject = ['filterManagerFactory', '$q', '$timeout','eventCallbackManagerFactory'];
export function FilterEditHelperFactory(filterManagerFactory, $q, $timeout, eventCallbackManagerFactory){
  return FilterEditHelper;


  function FilterEditHelper(){
    let deferred;
    let fp = this;
    fp.isOpen = false;
    fp.open = open;
    fp.save = save;
    fp.cancel = cancel;
    fp.close = close;
    fp.mainFM = null;
    fp.tempFM = null;
    fp.openAsModal = openAsModal
    var maximizeFilterPopoverToModal = new eventCallbackManagerFactory('maximizeFilterPopoverToModal');
    fp.onMaximizeFilterPopoverToModal = maximizeFilterPopoverToModal.add_callback;

    function open(manager){
      deferred = $q.defer();
      if(fp.isOpen){
        close();
        $timeout(deferred.resolve, 10);
        return deferred.promise;
      }

      var modal_manager = filterManagerFactory.get_manager({
        metadata: _.cloneDeep(manager.metadata),
        teardownOptions: {
          allow_teardown: true,
          teardown_callback: manager.teardown_options.teardown_callback
        },
        dataviewId: manager.dataviewId,
        context: manager.context
      });
      if (!manager.condition) {
        modal_manager.add_root_condition();
      } else {
        modal_manager.setParam(_.cloneDeep(manager.getParam()));
      }

      fp.mainFM = manager;
      fp.tempFM = modal_manager;
      fp.isOpen = true;
      return deferred.promise;
    }

    function save(){
      if (fp.tempFM.validate()) {
        let param = _.cloneDeep(fp.tempFM.getParam());
        fp.mainFM.setParam(param);
        deferred.resolve(param);
      }
      close();
    }

    function openAsModal(){
      let param = _.cloneDeep(fp.tempFM.getParam())
      fp.isOpen = false;
      maximizeFilterPopoverToModal.fire_event(param)
      deferred.reject({'reason': 'openAsModal'});

    }

    function cancel(){
      close();
      deferred.reject();
    }

    function close() {
      fp.isOpen = false;
    }

  }

}
