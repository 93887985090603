<template>
  <mm-modal
    :key="`upload_more_data_modal_${modalKey}`"
    v-model="computedIsModalOpen"
    :title="$t(`data_library.data_addition.upload_files.more_data.${mode}.title`)"
    content-class="mm-modal--data-addition-upload-files-more-data"
  >
    <template #default>
      <div class="mm-data-addition-upload-files-more-data">
        <span>{{ datasetName }}</span>

        <mm-file-uploader
          v-model="file"
          class="m-t-7"
          :title="$t('data_library.data_addition.upload_files.title')"
          drag-and-drop
          :allowed-extensions="DATA_ADDITION_EXTENSIONS"
        />

        <div class="d-flex m-t-3">
          <mm-icon class="m-r-3" name="information" />
          <span>{{ $t(`data_library.data_addition.upload_files.more_data.${mode}.description`) }}</span>
        </div>
      </div>
    </template>

    <template #actions>
      <mm-button :label="$t('global.dictionary.cancel')" objective="tertiary" @click="computedIsModalOpen = false" />
      <mm-button class="m-l-3" :label="$t('global.dictionary.submit')" :disabled="!file" @click="submit" />
    </template>
  </mm-modal>
</template>

<script>
// Constants
import { DATA_ADDITION_EXTENSIONS, USER_EVENTS } from '@/constants'

// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

export default {
  name: 'data-library-data-addition-upload-files-more-data',
  props: {
    value: Boolean,
    mode: {
      type: String,
      required: true
    },
    datasetName: {
      type: String,
      required: true
    },
    datasetId: {
      type: [String, Number],
      required: true
    },
    folderResourceId: [String, Number]
  },
  data: () => ({
    file: null,
    modalKey: 0
  }),
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    this.DATA_ADDITION_EXTENSIONS = DATA_ADDITION_EXTENSIONS
  },
  watch: {
    computedIsModalOpen(val) {
      if (!val) {
        this.modalKey++
        this.file = null
      }
    }
  },
  methods: {
    async submit() {
      this.$emit('submit')
      this.computedIsModalOpen = false

      let formData = new FormData()
      formData.append('file', this.file)
      formData.append('label_resource_id', this.folderResourceId)
      formData.append('append_to_ds_id', this.datasetId)
      formData.append('replace', this.mode == 'replace')

      this.$toast.show(this.$tc('data_library.data_addition.upload_files.start', 1, { fileName: this.file.name }))

      const { data } = await dataLibraryApi.addDataFromFile(formData)

      if (data.ERROR_CODE) {
        const errorMessage = this.$te(`global.api.${data.ERROR_CODE}`)
          ? this.$t(`global.api.${data.ERROR_CODE}`)
          : this.$t('data_library.data_addition.upload_files.title', { fileName: this.file.name })
        this.$toast.show({ content: errorMessage, status: 'error' })
      }

      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.FILE_UPLOAD.MORE_DATA.SUBMIT, {
        fileName: this.file.name,
        fileSize: this.file.size,
        mode: this.mode,
        datasetId: this.datasetId
      })
    }
  }
}
</script>

<style lang="scss">
.mm-data-addition-upload-files-more-data {
  min-width: 400px;

  .mm-file-uploader--file-wrapper {
    min-height: 40px;
  }
}

.mm-modal--data-addition-upload-files-more-data {
  overflow-x: hidden;
}
</style>
