// ngVue config
const angular = require('angular')
import * as Vue from 'vue'
import 'ngVue'
import 'ngVue/build/plugins.js'
import 'regenerator-runtime/runtime'

// plugins
import '../vueCode/plugins/axios'
import router from '../vueCode/plugins/router.js'
import store from '../vueCode/plugins/store'
import { setCommonHeader } from '../vueCode/plugins/axios'
import { loadLocaleMessages } from '../mm-frontend/src/plugins/i18n.js'
import userEvents from '../mm-frontend/src/plugins/userEvents'

// global vue components
import VueNavbar from '@vue/mm-frontend/src/components/navbar/navbar.vue'
import VueAdminContainer from '../vueCode/admin/pages/AdminLayout.vue'
import PublishGridSection from '../vueCode/modules/publish/viewer/components/grid-section/PublishGridSection.vue'
import PublishActionsBar from '../vueCode/modules/publish/viewer/components/actions-bar/PublishActionsBar.vue'
import VueLinkedInOut from '@vue/mm-frontend/src/components/linked-in-out/linked-in-out.vue'
import VueFolderBreadcrumb from '@vue/mm-frontend/src/components/folder-breadcrumb/folder-breadcrumb.vue'
import VueDataLibraryDataAdditionFetchFromUrl from '@vue/mm-frontend/src/modules/data-library/components/data-library-data-addition/data-library-data-addition-fetch-from-url/data-library-data-addition-fetch-from-url.vue'
import VueDataLibraryDataAdditionUploadFiles from '@vue/mm-frontend/src/modules/data-library/components/data-library-data-addition/data-library-data-addition-upload-files/data-library-data-addition-upload-files.vue'
import VueDataLibraryDataAdditionUploadFilesMoreData from '@vue/mm-frontend/src/modules/data-library/components/data-library-data-addition/data-library-data-addition-upload-files/data-library-data-addition-upload-files-more-data/data-library-data-addition-upload-files-more-data.vue'
import VueDataLibraryDataAdditionWebhook from '@vue/mm-frontend/src/modules/data-library/components/data-library-data-addition/data-library-data-addition-webhook/data-library-data-addition-webhook.vue'
import VueDataEditorGrid from '@vue/mm-frontend/src/modules/data-editor/components/data-editor-grid/data-editor-grid.vue'
import VueDataLibrarySidebar from '@vue/mm-frontend/src/modules/data-library/components/data-library-sidebar/data-library-sidebar.vue'
import VueDataLibraryDatasetViews from '@vue/mm-frontend/src/modules/data-library/components/data-library-dataset-views/data-library-dataset-views.vue'
import VueDataflowSettings from '@vue/mm-frontend/src/modules/data-editor/components/dataflow-settings/dataflow-settings.vue'
import VuePipelineCardFooter from '@vue/mm-frontend/src/modules/data-editor/components/pipeline-card-footer/pipeline-card-footer.vue'
import VueInconsistentDataWarning from '@vue/mm-frontend/src/modules/data-editor/components/inconsistent-data-warning/inconsistent-data-warning.vue'
import VueDataLibraryContentHeader from '@vue/mm-frontend/src/modules/data-library/components/data-library-content-header/data-library-content-header.vue'
import VueDataLibraryAddFolder from '@vue/mm-frontend/src/modules/data-library/components/data-library-add-folder/data-library-add-folder.vue'
import VueCombineWithDataset from '@vue/mm-frontend/src/modules/data-library/components/preview-panel/components/combine-with-dataset/combine-with-dataset.vue'
import VueDataLibraryDataAddition from '@vue/mm-frontend/src/modules/data-library/components/data-library-data-addition/data-library-data-addition.vue'
import VueDataLibraryDataAdditionThirdPartyConnectionNew from '@vue/mm-frontend/src/modules/data-library/components/data-library-data-addition/data-library-data-addition-third-party/data-library-data-addition-third-party-connection/data-library-data-addition-third-party-connection-new/data-library-data-addition-third-party-connection-new.vue'
import VueDataLibraryDataAdditionThirdPartyMetricsQuery from '@vue/mm-frontend/src/modules/data-library/components/data-library-data-addition/data-library-data-addition-third-party/data-library-data-addition-third-party-metrics/data-library-data-addition-third-party-metrics-query/data-library-data-addition-third-party-metrics-query.vue'
import VueDataLibraryDataAdditionThirdPartyScheduler from '@vue/mm-frontend/src/modules/data-library/components/data-library-data-addition/data-library-data-addition-third-party/data-library-data-addition-third-party-scheduler/data-library-data-addition-third-party-scheduler.vue'
import VueDataFlowModal from '../vueCode/components/DataFlowMap.vue'
import VueActionBarFrontendWrapper from '@vue/mm-frontend/src/modules/data-editor/action-bar/action-bar-frontend-wrapper.vue'
import VueExploreSectionToggle from '@vue/mm-frontend/src/modules/data-editor/explore-section/components/explore-section-toggle.vue'
import VueRestrictionsWrapper from '@vue/mm-frontend/src/components/restrictions-wrapper/restrictions-wrapper.vue'
import VueManageProjectMenu from '@vue/mm-frontend/src/modules/project/components/menu/project-manage-menu'
import VueShareProjectMenu from '@vue/mm-frontend/src/modules/project/components/modals/project-modal-manage'
import VueDataEditorViewingUsersWrapper from '@vue/mm-frontend/src/modules/data-editor/components/data-editor-viewing-users/data-editor-viewing-users-wrapper.vue'
import VueEmptyWorkspace from '@vue/mm-frontend/src/modules/data-library/components/data-library-empty-workspace/data-library-empty-workspace.vue'

import '@vue/mm-frontend/src/styles/app.scss'

const ngVueComponentsModule = angular.module('app.ngVueComponents', ['ngVue', 'ngVue.plugins'])

import MMStorybook, { components as storybookComponents } from '@mammoth_developer/mm-storybook'

import * as Raven from 'raven-js/dist/raven.js'
import * as RavenVue from 'raven-js/dist/plugins/vue.js'

const mmStorybookOptions = { useVuetify: true }
Vue.use(MMStorybook, mmStorybookOptions)
Vue.use(mmStorybookOptions.mmToasts, { vuetify: mmStorybookOptions.vuetify })
Vue.use(mmStorybookOptions.mmBanners)
loadLocaleMessages(mmStorybookOptions.i18n)
Vue.use(userEvents)

ngVueComponentsModule.config(($ngVueProvider) => {
  $ngVueProvider.setRootVueInstanceProps({
    vuetify: mmStorybookOptions.vuetify,
    i18n: mmStorybookOptions.i18n,
  })
})

// storybook components
storybookComponents.forEach((component) => {
  var cmpName = component.name.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase()
  })
  ngVueComponentsModule.directive(
    cmpName,
    /** @ngInject */
    (createVueComponent) => createVueComponent(Vue.component(cmpName, component))
  )
})

// directives
ngVueComponentsModule.directive(
  'vueAdminContainer',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueAdminContainer', VueAdminContainer))
)

ngVueComponentsModule.directive(
  'vueLinkedInOut',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueLinkedInOut', VueLinkedInOut))
)

ngVueComponentsModule.directive(
  'vueFolderBreadcrumb',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueFolderBreadcrumb', VueFolderBreadcrumb))
)

ngVueComponentsModule.directive(
  'vueDataLibraryDataAdditionFetchFromUrl',
  /** @ngInject */
  (createVueComponent) =>
    createVueComponent(Vue.component('vueDataLibraryDataAdditionFetchFromUrl', VueDataLibraryDataAdditionFetchFromUrl))
)

ngVueComponentsModule.directive(
  'vueDataLibraryDataAdditionUploadFiles',
  /** @ngInject */
  (createVueComponent) =>
    createVueComponent(Vue.component('vueDataLibraryDataAdditionUploadFiles', VueDataLibraryDataAdditionUploadFiles))
)

ngVueComponentsModule.directive(
  'vueDataLibraryDataAdditionUploadFilesMoreData',
  /** @ngInject */
  (createVueComponent) =>
    createVueComponent(
      Vue.component('vueDataLibraryDataAdditionUploadFilesMoreData', VueDataLibraryDataAdditionUploadFilesMoreData)
    )
)

ngVueComponentsModule.directive(
  'vueDataLibraryDataAdditionWebhook',
  /** @ngInject */
  (createVueComponent) =>
    createVueComponent(Vue.component('vueDataLibraryDataAdditionWebhook', VueDataLibraryDataAdditionWebhook))
)

ngVueComponentsModule.directive(
  'vueDataLibraryDataAddition',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueDataLibraryDataAddition', VueDataLibraryDataAddition))
)

ngVueComponentsModule.directive(
  'vueDataLibraryDataAdditionThirdPartyConnectionNew',
  /** @ngInject */
  (createVueComponent) =>
    createVueComponent(
      Vue.component(
        'vueDataLibraryDataAdditionThirdPartyConnectionNew',
        VueDataLibraryDataAdditionThirdPartyConnectionNew
      )
    )
)

ngVueComponentsModule.directive(
  'vueDataLibraryDataAdditionThirdPartyMetricsQuery',
  /** @ngInject */
  (createVueComponent) =>
    createVueComponent(
      Vue.component(
        'vueDataLibraryDataAdditionThirdPartyMetricsQuery',
        VueDataLibraryDataAdditionThirdPartyMetricsQuery
      )
    )
)

ngVueComponentsModule.directive(
  'vueDataLibraryDataAdditionThirdPartyScheduler',
  /** @ngInject */
  (createVueComponent) =>
    createVueComponent(
      Vue.component('vueDataLibraryDataAdditionThirdPartyScheduler', VueDataLibraryDataAdditionThirdPartyScheduler)
    )
)

ngVueComponentsModule.directive(
  'vueDataLibraryAddFolder',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueDataLibraryAddFolder', VueDataLibraryAddFolder))
)

ngVueComponentsModule.directive(
  'vueDataLibraryContentHeader',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueDataLibraryContentHeader', VueDataLibraryContentHeader))
)

ngVueComponentsModule.directive(
  'vueDataLibrarySidebar',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueDataLibrarySidebar', VueDataLibrarySidebar))
)

ngVueComponentsModule.directive(
  'vueDataLibraryDatasetViews',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueDataLibraryDatasetViews', VueDataLibraryDatasetViews))
)

ngVueComponentsModule.directive(
  'vueDataflowSettings',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueDataflowSettings', VueDataflowSettings))
)

ngVueComponentsModule.directive(
  'vuePipelineCardFooter',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vuePipelineCardFooter', VuePipelineCardFooter))
)

ngVueComponentsModule.directive(
  'vueInconsistentDataWarning',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueInconsistentDataWarning', VueInconsistentDataWarning))
)

ngVueComponentsModule.directive(
  'vueActionBarFrontendWrapper',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueActionBarFrontendWrapper', VueActionBarFrontendWrapper))
)

ngVueComponentsModule.directive(
  'vueExploreSectionToggle',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueExploreSectionToggle', VueExploreSectionToggle))
)

ngVueComponentsModule.directive(
  'vueRestrictionsWrapper',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueRestrictionsWrapper', VueRestrictionsWrapper))
)

ngVueComponentsModule.directive(
  'vueMmPublishGrid',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueMmPublishGrid', PublishGridSection))
)

ngVueComponentsModule.directive(
  'vueMmPublishActionsBar',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueMmPublishActionsBar', PublishActionsBar))
)

ngVueComponentsModule.directive(
  'vueNavbar',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueNavbar', VueNavbar))
)

ngVueComponentsModule.directive(
  'vueDataFlowModal',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueDataFlowModal', VueDataFlowModal))
)
ngVueComponentsModule.directive(
  'vueCombineWithDataset',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueCombineWithDataset', VueCombineWithDataset))
)

ngVueComponentsModule.directive(
  'vueDataEditorGrid',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueDataEditorGrid', VueDataEditorGrid))
)

ngVueComponentsModule.directive(
  'vueManageProjectMenu',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueManageProjectMenu', VueManageProjectMenu))
)

ngVueComponentsModule.directive(
  'vueShareProjectMenu',
  /** @ngInject */
  (createVueComponent) => createVueComponent(Vue.component('vueShareProjectMenu', VueShareProjectMenu))
)

ngVueComponentsModule.directive('vueDataEditorViewingUsersWrapper', (createVueComponent) =>
  createVueComponent(Vue.component('vueDataEditorViewingUsersWrapper', VueDataEditorViewingUsersWrapper))
)

ngVueComponentsModule.directive('vueEmptyWorkspace', (createVueComponent) =>
  createVueComponent(Vue.component('vueEmptyWorkspace', VueEmptyWorkspace))
)

// global vue plugins
ngVueComponentsModule.config(($ngVueProvider) => {
  $ngVueProvider.activeQuirkMode()
  $ngVueProvider.setRootVueInstanceProps({ router, store })
})

// factories
ngVueComponentsModule.factory('VuexStore', [() => store])

export function mammothVueApp() {
  return {
    setCommonHeader: setCommonHeader,
    i18n: mmStorybookOptions.i18n,
  }
}
ngVueComponentsModule.service('mammothVueApp', mammothVueApp)
export default ngVueComponentsModule

export const VueInstance = new Vue({ i18n: mmStorybookOptions.i18n })

Raven.config('https://83264d42a5ba498482f39ebd9d596525@sentry.io/246082').addPlugin(RavenVue, Vue).install()

Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  console.error(err)
  Raven.captureException(err)
}
