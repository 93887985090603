  /*jslint node: true */


  /**
 * @ngInject
 */
copyWebhookUrl.$inject = ['utils', 'toastNotification','c', 'analyticsService'];
export function copyWebhookUrl(utils, toastNotification, c, analyticsService){
  return {
    link: function(scope, element, attrs){
      $(element).click(function () {
        analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.copyWebhookUrl, {eventOrigin: "webhookProperties"})
        utils.copyToClipboard(attrs.copyWebhookUrl);
        toastNotification.success('Copied to clipboard');
      });
    }
  }
}
/**
 * @ngInject
 */
webhookConfigScreenController.$inject = ['webhooks', '$timeout', 'toastNotification', 'landingList', 'c', 'analyticsService'];
export function webhookConfigScreenController(webhooks, $timeout, toastNotification, landingList, c, analyticsService){
  this.$onInit = function () {
    var wvm = this;
    wvm.list = [];
    wvm.init = init;
    wvm.create = create;
    wvm.onClose = onClose;
    wvm.fileUploadDestinationResource = landingList.currentLabelView.label;
    wvm.modeOptions = c.modeOptions;
    wvm.mode = this.modeOptions[1].value;
    wvm.landingList = landingList;
    reset();
    wvm.init();

    function reset() {
      wvm.dsName = "";
      wvm.allowedOrigins = "*";
      wvm.isSecure = false;
      wvm.mode = "Replace";
    }

    function init() {
      webhooks.list().then(function (l) {
        wvm.list = l;
      });
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.webhookDs.selectOptions,
        {
          eventOrigin: "webhooks.selectOptions"
        });
    }

    function create() {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.webhookDs.createWebhookDs,
        {
          eventOrigin: "addNewDsModal.webhooks.doneButton"
        });
      let origins = wvm.allowedOrigins.trim();
      let dsName = wvm.dsName;
      if (!isNaN(dsName[0])) {
        dsName = 'Dataset ' + dsName;
      }
      let mode = wvm.mode;
      let destinationLabelResourceId = wvm.fileUploadDestinationResource ? wvm.fileUploadDestinationResource.resource_id : undefined;
      webhooks.create(dsName, origins, wvm.isSecure, mode, destinationLabelResourceId).then(successCb, errorCb);

      function successCb(data) {
        reset();
        $timeout(init, 2000);
        toastNotification.success('Created dataset');
      }

      function errorCb(data) {
        let error_message = "Failed to create webhook";
        if (data.data && data.data.ERROR_MESSAGE) {
          error_message += ": " + data.data.ERROR_MESSAGE;
        }
        toastNotification.error(error_message);
      }
    }

    function onClose() {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.webhookDs.closeModal,
        {
          eventOrigin: "addNewDsModal.webhooks.cancelButton"
        });
      wvm.close();
    }
  }
}

