<template>
  <div class="justify-align-center flex-column">
    <p class="mm-text--h600">{{ $t('data_library.empty_workspace_title') }}</p>
    <p class="mm-text--h200">{{ $t('data_library.empty_workspace_sub_title') }}</p>
    <mm-button
      v-if="featuresVisibility[SPLITIO_FEATURE_FLAGS.PROJECTS.CREATE]"
      class="m-b-6"
      icon="plus"
      :label="$t('project.create')"
      @click="isAddProjectModalOpen = true"
    />

    <project-modal-name v-model="isAddProjectModalOpen" @submit="onProjectAdded" />
  </div>
</template>

<script>
// Plugins
import { featuresVisibility } from '@/plugins/splitio'

// Constants
import { SPLITIO_FEATURE_FLAGS, USER_EVENTS } from '@/constants'

// Components
import ProjectModalName from '@/modules/project/components/modals/project-modal-name'

export default {
  name: 'data-library-empty-workspace',
  components: { ProjectModalName },
  data: () => ({
    featuresVisibility,
    isAddProjectModalOpen: false
  }),
  created() {
    this.SPLITIO_FEATURE_FLAGS = SPLITIO_FEATURE_FLAGS
  },
  methods: {
    onProjectAdded(project) {
      const item = {
        id: project.id,
        title: project.name,
        icon: 'data_library_20px',
        path: `#/workspaces/${this.$store.state.workspaceId}/projects/${project.id}`
      }

      //TODO remove when angular code is gone, also, add route in vue
      this.$store.commit('dataLibrary/setActiveSubItem', item)
      this.$store.commit('setProjectId', +item.path.split('/').pop())
      history.pushState({}, null, item.path)
      this.$userEvents.save(USER_EVENTS.DATA_LIBRARY.SIDEBAR.OPEN, item)
    }
  }
}
</script>
