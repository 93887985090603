'use strict';
import { dataviewConfig } from '../dataview.config';
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
import {isNullOrUndefined} from "util";

/**
 * @ngInject
 */
s3ActionManagerFactory.$inject = ['UserWorkspace', 'utils'];
export function s3ActionManagerFactory(UserWorkspace, utils) {
  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new s3ActionManager(options);
  }

  function s3ActionManager(options) {
    var self = this;
    self.dataviewId = options.context.dataview.id;
    self.serversideValidationRequired = false;
    self.hideRunImmediatelyCheckBox = true;
    if (_.isUndefined(options.triggerType)) {
      options.triggerType = "pipeline";
    }

    self.param = {
      "handler_type": "s3",
      "trigger_type": options.triggerType,
      "run_immediately": true,
      sequence: null
    };
    if(!self.param.additional_properties) {
      self.param.additional_properties = {};
    }
    if(self.param.trigger_type == "pipeline") {
      self.param.additional_properties.no_notification = true;
      self.param.additional_properties.autoRun = true;
      self.param.additional_properties.invisible = true;
      self.param.additional_properties.completionMessage = "Live Link generated";
      self.param.additional_properties.liveLink = true;
    }

    function getFileName(){
      let ws = options.context.dataview;
      let ds_name = ws.datasource.name;
      if(ds_name.length > 10){
        ds_name = ds_name.substring(0, 7);
      }

      let ws_name = ws.name;
      if(ws_name.length > 10){
        ws_name = ws_name.substring(0, 7);
      }
      let file_name = ds_name + ' ' + ws_name + ' ' + utils.string.random(16);
      return file_name.toLowerCase().replace(/\s+/g, '_').replace(/[^\x00-\x7F]/g, "") + '.csv';
    }

    self.getParam = getParam;
    self.setParam = setParam;
    self.validate = validate;

    function validate() {
      return true;
    }

    function getParam() {
      if(!self.param.target_properties){
        self.param.target_properties = {};
      }
      if(!self.param.target_properties.file){
        self.param.target_properties.file = getFileName();
      }
      self.param.target_properties.is_format_set = true;
      self.param.target_properties.use_format = true;
      return self.param;
    }

    function setParam(param) {
      self.param = param;
    }

  }
}
