currentDateTimeInput.$inject = ['config'];
export function currentDateTimeInput(config){
  return {
    templateUrl: config.templates.currentDateTimeInput,
    scope: {
      ngModel: '=',
    },
    controller: 'currentDateTimeController',
    controllerAs: 'cdtc',
    bindToController: true,
    link: function (scope){
    }

  }
}