import * as $ from 'jquery';
import * as angular from 'angular';
import * as _ from 'lodash-es';

/**
 * @ngInject
 */
convertManagerFactory.$inject = ['utils', 'eventCallbackManagerFactory', '$timeout', 'FilterHintsFactory', 'c', '$resource', 'config', '$q'];
export function convertManagerFactory(utils, eventCallbackManagerFactory, $timeout, FilterHintsFactory, c, $resource, config, $q) {
  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new convertManager(options);
  }

  function convertManager(options) {
    var self = this;
    var onColumnAdded = new eventCallbackManagerFactory('convertColumnManagerColumnAdded');
    self.metadata = utils.sanitizeMetadataWithType(options.metadata);
    var taskUtils = options.taskUtils;
    self.context= options.context;
    self.validate = validate;
    self.getParam = getParam;
    self.handlePasteParams = handlePasteParams;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap;
    self.setParam = setParam;
    self.addColumn = addColumn;
    self.removeColumn = removeColumn;
    self.convertInputArray = [];
    self.selectedColumns = [];
    self.examples = extractValues(c.dateInputFormatOptions);
    self.convertFormat = convertDateFormatToPostgres;
    self.setFormat = setFormatFromOption;
    self.addDefaultFormat = addDefaultFormat;
    var allowedTypes = ['Text', 'Numeric', 'Date'];
    self.runValidators = new eventCallbackManagerFactory('convertColumnValidators');
    self.onColumnAdded = onColumnAdded.add_callback;
    self.context = options.context

    self.param = {
      "CONVERT": []
    };
    addColumn();

    function addDefaultFormat(conSpec) {
      if (conSpec.column.type === 'DATE' && conSpec.toType === 'TEXT') {
        let internalColumnName = conSpec.column.internal_name;

        //Search for format in display properties
        let displayProperties = self.context.dataview.display_properties;
        let formatInfo = displayProperties.FORMAT_INFO[internalColumnName];
        let columnFormat1 = formatInfo?.date_format;

        //Search for format in dataview metadata
        let dataviewMetadata = self.context.dataview.metadata;
        let columnInfoFromDataviewMetadata = utils.metadata.get_column_by_internal_name(dataviewMetadata, internalColumnName);
        let columnFormat2 = columnInfoFromDataviewMetadata?.format?.date_format;

        // Search for format in datasource metadata
        let datasourceMetadata = self.context.dataview.datasource.metadata;
        let columnInfoFromDatasourceMetadata = utils.metadata.get_column_by_internal_name(datasourceMetadata, internalColumnName);
        let columnFormat3 = columnInfoFromDatasourceMetadata?.format?.date_format;

        /*
        * Priority order:
        * 1. Display Properties
        * 2. Dataview Metadata
        * 3. Datasource Metadata
        * 4. If all are absent, select first option from examples DD-MM-YYYY
        */
        let currentFormat = null;
        if(columnFormat1) {
          currentFormat = columnFormat1;
        } else if(columnFormat2) {
          currentFormat = columnFormat2;
        } else if(columnFormat3) {
          currentFormat = columnFormat3;
        } else
          currentFormat = self.examples[0];

        convertDateFormatToPostgres(currentFormat).then(function(convertedFormat){
          if(!(self.examples.includes(convertedFormat))) {
            self.examples.push(convertedFormat);
          }
          conSpec.selectedOption = convertedFormat;
          setFormatFromOption(conSpec);
        });
      }
    }

    function setFormatFromOption(conSpec) {
      if(conSpec.selectedOption !== 'custom') {
        if(!conSpec.format) {
          conSpec.format = {};
        }
        conSpec.format[c.DATE_FORMAT] = conSpec.selectedOption;
      }
    }

    function extractValues(dateList) {
      let newDateList = [];
      for(let date of dateList) {
         if(date.format != null) {
          newDateList.push(date.format);
        }
      }
      return newDateList;
    }

    function convertDateFormatToPostgres(currentFormat) {
      let deferred = $q.defer();
      let queryParams = {};
      queryParams[c.DATE_FORMAT] = currentFormat;
      let convertRes = $resource(config.api.dateConvert);
      convertRes.get(queryParams).$promise.then(success, failure);
      return deferred.promise;

      function success(response) {
        deferred.resolve(response[c.POSTGRES_DATE_FORMAT]);
      }

      function failure(response) {
        deferred.reject();
      }
    }

    function ConvertObject(autoFind=true) {
      var filterHints;
      var obj = {
        column: undefined,
        toType: undefined,
        format: undefined,
        selectedOption: '',
        isDuplicate: false,
        autoFind: autoFind,
        allowedTypes: [],
        sampleUniqueValues: [],
        sampleUniqueValuesLoading: false,

        columnSelected: columnSelected,
        getParam: getConvertObjectParam,
        setParam: setConvertObjectParam,
        getSampleUniqueValues: getSampleUniqueValues
      };
      return obj;

      function columnSelected() {
        filterHints = new FilterHintsFactory(options.context.dataview.id);
        var obj = this;
        obj.allowedTypes = [];
        $.each(allowedTypes, function (i, type) {
          if (type.toUpperCase() !== obj.column.type) {
            obj.allowedTypes.push({d: type, i: type.toUpperCase()});
          }
        });

        // commented below lines for MVP-353
        if (obj.column.type !== c.date) {
          if (self.context.task) {
            filterHints.init(obj.column.internal_name, self.context.task.sequence);
          }
          else{
            filterHints.init(obj.column.internal_name);
          }
        }
        obj.format = null;
        obj.toType = null;
        _recomputeSelectedColumns();
        obj.getSampleUniqueValues();
      }

      function getSampleUniqueValues() {
        var obj = this;


        if (obj.toType === 'DATE') {
          $timeout(function () {
            obj.sampleUniqueValuesLoading = true;
          });

          return filterHints.getHints(undefined, 10).then(function (data) {
            $timeout(function () {
              obj.sampleUniqueValuesLoading = false;
              obj.sampleUniqueValues = data;
            });
            return data;
          }, function () {
            $timeout(function () {
              obj.sampleUniqueValuesLoading = false;
            });
          });
        }
        else {
          $timeout(function () {
            obj.sampleUniqueValuesLoading = false;
          });
          return [];
        }
      }

      function getConvertObjectParam() {
        var obj = this;
        if (!obj.column) {
          return null;
        }
        var param: any = {"SOURCE": obj.column.internal_name, "TO_TYPE": obj.toType};
        if (obj.format) {
          param.FORMAT = obj.format;
        }
        return param;
      }

      function setConvertObjectParam(param, has_referror) {
        var obj = this;
        obj.column = utils.metadata.get_column_by_internal_name(self.metadata, param.SOURCE);
        obj.columnSelected();
          if (has_referror && obj.allowedTypes.indexOf(obj.column.type) == -1){
          var lowercase = obj.column.type.toLowerCase()
          var display = lowercase[0].toUpperCase() + lowercase.substring(1)
          obj.allowedTypes.push({d: display, i: obj.column.type})
        }
        if (param.TO_TYPE) {
          obj.toType = param.TO_TYPE;
          if (filterHints.derivativeId){
            obj.getSampleUniqueValues();
          }
          else {
            $timeout(function(){
              obj.getSampleUniqueValues();
            }, 2000);
          }
        }
        if (param.FORMAT) {
          obj.format = param.FORMAT;

          if(param.FORMAT.date_format) {
            if(self.examples.includes(param.FORMAT.date_format)) {
              obj.selectedOption = param.FORMAT.date_format;
            }
            else {
              obj.selectedOption = 'custom';
            }
          }
        }
      }
    }

    function _recomputeSelectedColumns() {
      var selectedCols = [];
      angular.forEach(self.convertInputArray, function (obj) {
        if (obj.column) {
          selectedCols.push(obj.column.internal_name);
        }
      });
      self.selectedColumns = selectedCols;
      taskUtils.highlight.destinations(self.selectedColumns);
      self.runValidators.fire_event();
    }

    function addColumn(_obj = undefined) {
      var obj = _obj || ConvertObject(true);
      self.convertInputArray.push(obj);
      onColumnAdded.fire_event();
    }

    function removeColumn(i) {
      self.convertInputArray.splice(i, 1);
      if (!self.convertInputArray.length) {
        addColumn();
      }
   _recomputeSelectedColumns();
    }

    function getParam() {
      self.param.CONVERT = [];
      $.each(self.convertInputArray, function (i, convertObj) {
        if (convertObj.column && convertObj.toType) {
          self.param.CONVERT.push(convertObj.getParam());
        }
      });
      if (self.context.hasOwnProperty('sequence')){
        self.param['SEQUENCE_NUMBER'] = self.context.sequence
      }
      return self.param;
    }

    function handlePasteParams(taskInfo){
      /** Update source params with suitable replacement columns, based on display name*/

      var params = taskInfo.params
      for(const index in params.CONVERT){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.CONVERT[index], 'SOURCE', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }
      return params
    }

    function setParam(param) {
      self.param = param;
      self.convertInputArray.splice(0, self.convertInputArray.length);
      var has_referror = false
      if (self.context.inEditMode && self.context.hasOwnProperty('task') && self.context.task.hasOwnProperty('has_referror')){
         has_referror = self.context.task.has_referror
      }
      $.each(param.CONVERT, function (i, subParam) {
        var obj = ConvertObject(false);
        obj.setParam(subParam, has_referror);
        if(!obj.toType){
          obj.autoFind = true;
        }
        addColumn(obj);
      });
      _recomputeSelectedColumns();
    }

    function validate() {
      var is_valid = true;
      _.forEach(self.convertInputArray, function(column_to_convert){
        if (column_to_convert['column'] && column_to_convert['column'].hasOwnProperty('error')){
          is_valid = false;
        }
        else if(column_to_convert['column'] && column_to_convert['column']['type'] == column_to_convert.toType){
          is_valid = false;
        }

      });
      return is_valid;
    }
  }
}


/**
 * @ngInject
 * */
export function valColConversion() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valColConversion(scope, elem, attrs, ctrl) {
      ctrl.$validators.valColConversion = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue && modelValue.hasOwnProperty('error')){
          is_valid =  false
        }
        return is_valid
      };
    }
  };
}

/**
 * @ngInject
 * */
export function valToType() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valToType(scope, elem, attrs, ctrl) {
      ctrl.$validators.valToType = function (modelValue, viewValue) {
        var is_valid = true

        if (modelValue && scope.conSpec.column.type == modelValue){
          is_valid =  false
        }
        return is_valid
      };
    }
  };
}

/**
 * @ngInject
 * */
export function valConvertColDuplicateCols() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valConvertColDuplicateCols(scope, elem, attrs, ctrl) {
      ctrl.$validators.valConvertColDuplicateCols = function (modelValue, viewValue) {
        if (modelValue) {
          return scope.tvm.manager.selectedColumns.indexOf(modelValue.internal_name) ===
            scope.tvm.manager.selectedColumns.lastIndexOf(modelValue.internal_name);
        } else {
          return true;
        }
      };

      var run_validator_key = Math.random().toString();
      scope.tvm.manager.runValidators.add_callback(run_validator_key, ctrl.$validate);

      scope.$on('$destroy', function () {
        scope.tvm.manager.runValidators.remove_callback(run_validator_key);
      });
    }
  };
}

export function columnDuplicateFilter() {
  return function (cols, convertInputArray, currentIndex) {
    let columnIntNames = [];
    if (convertInputArray) {
      angular.forEach(convertInputArray, function(inputObject){
        if (inputObject.hasOwnProperty("column") && inputObject["column"]) {
          let sample = inputObject["column"]["internal_name"];
          columnIntNames.push(sample);
        }
      });
    }
    return _.filter(cols, function (col) {
      return [-1, currentIndex].indexOf(columnIntNames.indexOf(col.internal_name)) !== -1;
    });
  };
}


