import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';

lookupManagerFactory.$inject = ['destinationColumnManagerFactory', 'DatasourceService', 'utils', '$http',
  'DataviewService', 'config', 'c', '$q', 'FutureService', '$resource'];
export function lookupManagerFactory(destinationColumnManagerFactory, DatasourceService, utils, $http,
                                     DataviewService, config, c, $q, FutureService, $resource) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new LookupManager(options);
  }

  function LookupManager(options) {
    var self = this;
    var metadata = options.metadata, taskUtils = options.taskUtils, dataview = options.context.dataview;
    if(options.context.hasOwnProperty('task')){
      var task = options.context.task
    }

    var _registry = {
      source: null,
      lookupWksp: null,
      key: null,
      value: null
    };

    self.param = {
      LOOKUP: {
        SOURCE: null,
        KEY: null,
        DATAVIEW_ID: null,
        VALUE: null
      }
    };
    self.dataviews = [];
    self.context = options.context;
    self.lookupWkspData = {
      key: [],
      value: []
    };
    self.dependentDataviews = [];
    self.metadata = options.metadata;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap;
    self.validate = validate;
    self.getParam = getParam;
    self.handlePasteParams = handlePasteParams;
    self.setParam = setParam;

    self.destinationManager = destinationColumnManagerFactory.get_manager(
      {metadata: metadata, allowedTypes: ['TEXT'], taskUtils: options.taskUtils, isDestinationFormatterVisible: true}
    );

    self.getLookupKeyColumns = getLookupKeyColumns;
    self.getLookupValueColumns = getLookupValueColumns;

    self.sourceColumn = _sourceColumnGetterSetter;
    self.lookupWkspGetSet = _lookupWkspGetterSetter;
    self.lookupKeyCol = _lookupKeyColGetterSetter;
    self.lookupValueCol = _lookupValueColGetterSetter;
    let updated_wksp = null;

    self.allowedSrcColumns = metadata

    let wsResource =  $resource(config.api.dataviews);
    var deferred = $q.defer();
    wsResource.get({id: dataview.id}).$promise.then(function (response) {
      get_data_tracker(response).then(function (data) {
        updated_wksp = data;
        _updateWksplist();
        DatasourceService.on_list_update('ForLookupUI', _updateWksplist);
        deferred.resolve(data);
      });
    }, deferred.reject);

    function get_data_tracker(data) {
      var deferred = $q.defer();
      FutureService.track(data.future_id, data_tracker);

      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }
      return deferred.promise;
    }

    function _updateWksplist(){
      self.dataviews = [];
      self.dependentDataviews = [];
      var lookupWkspExists = false;

      $.each(Object.keys(DatasourceService.list), function(i, object_id) {
        var item = DatasourceService.list[object_id];

        if (item.type == 'datasource') {
          $.each(Object.keys(item.dataviews), function (j, ws_id) {
            var ws = item.dataviews[ws_id];
            if(ws.id == _registry.lookupWksp?.id){
              lookupWkspExists = true;
            }
            if (ws.id != dataview.id){
              if (updated_wksp && updated_wksp.all_dependent_dataview_ids.includes(parseInt(ws_id))) {
                  self.dependentDataviews.push(parseInt((ws_id)));
              }
              ws.display_name_for_lookup = item.name + ' → ' + ws.name;
              self.dataviews.push(ws);
            }
          });
        }
      });
      if(!lookupWkspExists  && !self.foreignViewMissing){
        self.param.LOOKUP.DATAVIEW_ID = null;
        self.param.LOOKUP.KEY = null;
        self.param.LOOKUP.VALUE = null;

        _registry.lookupWksp = null;
        _registry.key = null;
        _registry.value = null;
      }
      else{
        if(_registry.lookupWksp.metadata.indexOf(_registry.key) == -1){
          _registry.key = null;
          self.param.LOOKUP.KEY = null;
        }
        if(_registry.lookupWksp.metadata.indexOf(_registry.value) == -1){
          _registry.value = null;
          self.param.LOOKUP.VALUE = null;
        }
      }
    }

    function getParam() {
      var cp_param = _.cloneDeep(self.param);
      var destination_params = self.destinationManager.getParam()
      if (destination_params.hasOwnProperty('AS') && self.context.inEditMode==true &&  self.context.task){
        utils.sanatizeParamForDuplicateCols(destination_params['AS'], 'INTERNAL_NAME', self.context.task)
      }
      angular.merge(cp_param.LOOKUP, destination_params);
      if(options.context.hasOwnProperty('sequence')){
        cp_param['SEQUENCE_NUMBER'] = options.context.sequence;
      }
      return cp_param;
    }

    function handlePasteParams(taskInfo){
      /** Update source and destination params with suitable replacement columns, based on display name*/
      var params = taskInfo.params;
      //Destination params
      if (params.LOOKUP.hasOwnProperty('DESTINATION')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.LOOKUP, 'DESTINATION', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        //Update destination manager metadata
        self.destinationManager.metadata = self.metadata
        self.destinationManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(self.metadata)

      }
      //Source params
      utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.LOOKUP, 'SOURCE', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);

      return params
    }

    function setParam(param) {
      self.param = _.cloneDeep(param);
      self.immutableParam = _.cloneDeep(param);
      var lookup_param = _.cloneDeep(param.LOOKUP);

      if(param.LOOKUP.AS){
        delete self.param.LOOKUP.AS;
      }

      if(param.LOOKUP.DESTINATION){
        delete self.param.LOOKUP.DESTINATION;
      }

      _registry.source = utils.metadata.get_column_by_internal_name(metadata, self.param.LOOKUP.SOURCE);
      taskUtils.highlight.sources(_registry.source);
      _registry.lookupWksp = DataviewService.get_by_id(self.param.LOOKUP.DATAVIEW_ID);
      // Fetch wksp data for lookup wksp to ensure taskwise info is present
      let pipelineResource =  $resource(config.api.pipelineInfo);
      if (_registry.lookupWksp){
        self.foreignViewMissing = false;
        pipelineResource.get({ws_id: _registry.lookupWksp.id}).$promise.then(function (response) {
          _registry.lookupWksp['taskwise_info'] = response['taskwise_info']
          lookupWkspCallback()
        })
      }
      else{
        self.foreignViewMissing = true;
        if (task && task.hasOwnProperty('has_referror') && task.has_referror && task.reference_errors.type == 'referror') {
          _.forEach(task.reference_errors.reference_errors, function (referror_info) {
            if (referror_info.error_code ==  7007){
              _registry.lookupWksp = {
                id: param.LOOKUP.DATAVIEW_ID,
                name: referror_info.dependency_info.name,
                display_name_for_lookup: referror_info.dependency_info.name,
                metadata:  utils.metadata.add_type_to_display_name(referror_info.dependency_info.metadata),
                pipeline_status: 'error'

              }
              self.lookupWksp = _registry.lookupWksp;
              _registry.key =  utils.metadata.get_column_by_internal_name(_registry.lookupWksp.metadata,
                self.immutableParam.LOOKUP.KEY);
              _registry.value =  utils.metadata.get_column_by_internal_name(_registry.lookupWksp.metadata,
                self.immutableParam.LOOKUP.VALUE);
            }

          })
        }
      }
      function lookupWkspCallback() {
          _registry.lookupWksp.display_name_for_lookup = _registry.lookupWksp.datasource.name + ' → ' + _registry.lookupWksp.name;
          _registry.lookupWksp.metadata = _registry.lookupWksp.taskwise_info[_registry.lookupWksp.tasks_total_count].metadata
          _registry.lookupWksp.metadata = utils.metadata.add_type_to_display_name(_registry.lookupWksp.metadata)
          if(task && task.hasOwnProperty('has_referror') && task.has_referror && task.reference_errors.type == 'referror'){
            var reference_errors = task.reference_errors.reference_errors
            _.forEach(reference_errors, function(referror_info){
              if (referror_info.reason == "not available" && referror_info.dataview == self.param.LOOKUP.DATAVIEW_ID){
                var col = referror_info.column
                col['error'] = referror_info.reason
                _registry.lookupWksp.metadata.push(col)
              }
            }.bind(self))

          }
          _lookup_wksp_get_data_cb()
          _updateWksplist()

      }
      self.destinationManager.setParam(lookup_param);
    }

    function _lookup_wksp_get_data_cb(){
      // Use props from immutableParam as self.params keep changing based on whether lookup wksp loads first or current wksp
      var keyCol = utils.metadata.get_column_by_internal_name(_registry.lookupWksp.metadata,
        self.immutableParam.LOOKUP.KEY);
        _lookupKeyColGetterSetter(keyCol)
      var valueCol = utils.metadata.get_column_by_internal_name(_registry.lookupWksp.metadata,
        self.immutableParam.LOOKUP.VALUE);
        _lookupValueColGetterSetter(valueCol)

      if (valueCol){
        self.destinationManager.setAllowedTypesAndColumns([_registry.value.type], _registry.value.type);
      }
      _get_lookup_wksp_data();
    }

    function _get_lookup_wksp_data(){
      var key_data = [];
      var val_data = [];
      self.lookupWkspData = {
        key: key_data,
        value: val_data
      };

      var cols_to_get = [];
      if(self.param.LOOKUP.KEY){
        cols_to_get.push(self.param.LOOKUP.KEY);
      }

      if(self.param.LOOKUP.VALUE){
        cols_to_get.push(self.param.LOOKUP.VALUE);
      }

      $http.get(config.apiUrl + _registry.lookupWksp.data_url, {
        "params": {
          "columns": JSON.stringify(cols_to_get)
        }
      }).then(_success_cb);

      function _success_cb(response){
        let data = response.data;
        if (data.hasOwnProperty('future_id')) {
          get_data_tracker(data);
        } else {
          onSuccess(data);
        }
      }
      function onSuccess(data) {
        $.each(data.data, function(i, row){
          if(self.param.LOOKUP.KEY){
            key_data.push(row[self.param.LOOKUP.KEY]);
          }

          if(self.param.LOOKUP.VALUE){
            val_data.push(row[self.param.LOOKUP.VALUE]);
          }
        })
      }

      function get_data_tracker(data) {
        var deferred = $q.defer();
        FutureService.track(data.future_id, data_tracker);

        function data_tracker(future) {
          let response = future.response;
          if (future.status == "processing") {
            return;
          }
          if (future.status == "success") {
            onSuccess(response);
            deferred.resolve(response);
          } else {
            deferred.reject(response);
          }
        }
      }
    }

    function validate() {
      var is_valid=true;
      var srcColumn = self.sourceColumn()
      var lookupKeyCol = self.lookupKeyCol()
      if ( (srcColumn && srcColumn.hasOwnProperty('error')) ||
          (lookupKeyCol && lookupKeyCol.hasOwnProperty('error')) ||
           (srcColumn && lookupKeyCol &&
             self.lookupKeyCol().hasOwnProperty('type') &&
             self.sourceColumn().hasOwnProperty('type') &&
             self.lookupKeyCol().type!=self.sourceColumn().type)){
        is_valid=false;

      }
      if(self.lookupWksp && self.lookupWksp.pipeline_status=='error'){
        is_valid = false
      }
      return is_valid;
    }

    function _sourceColumnGetterSetter(value){
      if(value === undefined){
        return _registry.source;
      }
      else{
        self.param.LOOKUP.SOURCE = value.internal_name;
        _registry.source = value;
        taskUtils.highlight.sources(_registry.source);
        if (_registry.key && _registry.source.type != _registry.key.type){
          _registry.key = null;
          self.param.LOOKUP.KEY = null;
        }
      }
    }

    function _lookupWkspGetterSetter(value){
      if(value === undefined){
        return _registry.lookupWksp;
      }
      else{
        self.param.LOOKUP.DATAVIEW_ID = value.id;
        self.param.LOOKUP.KEY = null;
        self.param.LOOKUP.VALUE = null;
        self.foreignViewMissing = false;
        self.lookupWksp = value;
        _registry.lookupWksp = value;
        _registry.lookupWksp.get_data();
        _registry.key = null;
        _registry.value = null;
        return _registry.lookupWksp;
      }
    }

    function _lookupKeyColGetterSetter(value){
      if(value === undefined){
        return _registry.key;
      }
      else{
        self.param.LOOKUP.KEY = value.internal_name;
        _registry.key = value;

        if(_registry.key == _registry.value){
          _registry.value = null;
          self.param.LOOKUP.VALUE = null;
        }
        _get_lookup_wksp_data();
        return _registry.key
      }
    }

    function _lookupValueColGetterSetter(value){
      if(value === undefined){
        return _registry.value;
      }
      else{
        self.param.LOOKUP.VALUE = value.internal_name;
        _registry.value = value;
        self.destinationManager.setAllowedTypesAndColumns([value.type], value.type);
        if(value.type == c.numeric || value.type == c.date){
          var source_column_format = _registry.lookupWksp.display_properties.FORMAT_INFO[self.param.LOOKUP.VALUE];
          if (!source_column_format) {
            let column = _.find(_registry.lookupWksp.metadata, function (c) {
              return c.internal_name == self.param.LOOKUP.VALUE;
            });
            source_column_format = column['format'];
          }
          self.destinationManager.setDestinationFormat(source_column_format);
        }
        _get_lookup_wksp_data();
        return _registry.value;
      }
    }

    function getLookupKeyColumns(){
      if (!_registry.lookupWksp){
        return [];
      }

      if(!_registry.lookupWksp.hasOwnProperty('metadata')){
        return [];
      }

      if(!_registry.source){
        return _registry.lookupWksp.metadata;
      }
      else{
        var key_col = utils.metadata.get_column_by_internal_name(_registry.lookupWksp.metadata, self.param.LOOKUP.KEY)
        var lookupKeyColumns = $.grep(_registry.lookupWksp.metadata, function(col: any){
          return col.type == _registry.source.type;})
        var lookupKeyColumnsInternalNames = _.map(lookupKeyColumns,'internal_name')
        if (key_col && lookupKeyColumnsInternalNames.indexOf(key_col.internal_name) == -1){
          lookupKeyColumns.push(key_col)
        }
        return lookupKeyColumns

      }

    }

    function getLookupValueColumns(){
      if (!_registry.lookupWksp){
        return [];
      }

      if(!_registry.lookupWksp.hasOwnProperty('metadata')){
        return [];
      }

      return $.grep(_registry.lookupWksp.metadata, function(col){
        return col != _registry.key;
      })
    }

  }
}



valKeyCol.$inject = ['utils'];
export function valKeyCol(utils) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valKeyCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valKeyCol = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue){
          if( modelValue.hasOwnProperty('error')){
            is_valid =  false
          }
        }
        return is_valid
      };
    }
  };
}
