export function getColumnConfig(metadata, displayProperties) {
  const columnWidths = displayProperties?.COLUMN_WIDTHS || {}
  const columnFormat = displayProperties?.FORMAT_INFO || {}
  let columnConfig = {}

  let colFormat = {
    TEXT: (format) => ({ treatAsUrl: format?.IS_URL || false }),
    DATE: (format) => ({
      format: {
        date: {
          format: format?.date_format
        }
      }
    }),
    NUMERIC: (format) => ({
      format: {
        number: {
          shortForm: false,
          separator: format?.comma_separated || false,
          percentage: format?.is_percentage || false,
          currency: format?.currency_symbol || '',
          decimalPrecision: format?.decimal_spec || 0
        }
      }
    })
  }

  metadata.forEach((col) => {
    let currColFormat = colFormat[col.type](columnFormat[col.internal_name])
    let currColConfig = { width: columnWidths[col.internal_name] || 161, ...currColFormat }
    if (col?.old_display_name !== col.display_name) {
      currColConfig.originalName = col.old_display_name
    }
    columnConfig[col.internal_name] = currColConfig
  })
  return columnConfig
}

export function transformGridColumnsMetaData(data) {
  const columnConfig = getColumnConfig(data.metadata, data.display_properties)
  return { columnConfig }
}
