import * as angular from 'angular';
import * as _ from 'lodash-es';

let allTypes = ['TEXT', 'NUMERIC', 'DATE'];

unnestManagerFactory.$inject = ['$filter', 'c', 'utils', '$timeout'];
export function unnestManagerFactory($filter, c, utils, $timeout) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new UnnestManager(options);
  }

  function UnnestManager(options) {
    var self = this;
    var metadata = _.cloneDeep(options.metadata), taskUtils = options.taskUtils, dataview = options.context.dataview;
    self.getParam = getParam;
    self.sanitizeParam = sanitizeParam;
    self.setParam = setParam;
    self.handlePasteParams = handlePasteParams;
    self.validate = validate;
    self.handleSelectionChange = handleSelectionChange;
    self.recalculateValidity = recalculateValidity;
    self.isValid = false;
    self.metadata = utils.sanitizeMetadataName(metadata);
    self.internal_name_to_column_map = {};
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap;
    self.typeCounts = {};
    self.columnLabels = {};
    self.sources = [];
    self.allowedTypes = allTypes;
    self.toggleFilteredColumns = toggleFilteredColumns;

    self.columns = evaluateColumns();
    function evaluateColumns(){
      var columns = [];
      metadata.forEach(function(col){
        col.isSelected = false;
        columns.push(col);
        self.internal_name_to_column_map[col.internal_name] = col;
        self.typeCounts[col.type] = (self.typeCounts[col.type] || 0) + 1;
        self.columnLabels[col.internal_name] = col.display_name;
      });
      return columns;
    }

    let displayNames = metadata.map(c => c.display_name);

    self.labelParam = {
      COLUMN: getUniqueString('Label', displayNames),
      INTERNAL_NAME: 'lbl_' + utils.string.random(5, {lowercase: true}),
      TYPE: 'TEXT'
    };

    self.valueParam = {
      COLUMN: getUniqueString('Value', displayNames),
      INTERNAL_NAME: 'val_' + utils.string.random(5, {lowercase: true}),
      TYPE: 'NUMERIC'
    }

    function recalculateValidity(){
      self.isValid = _validateSources();
    }

    function _validateSources(){
      return (self.sources.length >= 1);
    }

    function validate() {
      let isValid = _validateSources();
      return isValid;
    }

    function setAllowedValTypes(type?){
      if (type){
        self.allowedTypes = [type]
      } else {
        if(self.sources.length){
          self.allowedTypes = [self.sources[0].type];
          self.valueParam.TYPE = self.sources[0].type;
        }
        else{
          self.allowedTypes = allTypes;
        }
      }
    }

    function handleSelectionChange(column){
      if(column.isSelected){
          self.sources.push(column);
      }
      else{
        let idx = self.sources.indexOf(column);
        self.sources.splice(idx, 1);
      }
      setAllowedValTypes();
      recalculateValidity();
    }

    function toggleFilteredColumns(filterByTxt, setVal?){
      let filteredColumns = $filter('filter')(self.columns, filterByTxt);
      if(self.allowedTypes.length == 1){
        filteredColumns = _.filter(filteredColumns, {type: self.allowedTypes[0]});
      }
      if(filteredColumns.length == 0){
        return;
      }
      if(setVal === null || setVal === undefined){
        let firstCol = filteredColumns[0];
        setVal = !firstCol.isSelected;
      }
      _.forEach(filteredColumns, function(fc){
        let idx = self.sources.indexOf(fc);
        if(setVal){
          if(idx == -1){
            self.sources.push(fc);
            fc.isSelected = true;
          }
        }
        else{
          if(idx != -1){
            fc.isSelected = false;
            self.sources.splice(idx, 1);
          }
        }
      });
      setAllowedValTypes();
      recalculateValidity();
    }

    function getParam() {
      let columnsToUnnest = [];
      _.forEach(self.sources, function(src){
        columnsToUnnest.push({
          COLUMN: src.internal_name,
          LABEL: self.columnLabels[src.internal_name] || src.display_name
        });
      });
      let param = {
        UNNEST: {
          COLUMNS: columnsToUnnest,
          VALUE: self.valueParam,
          LABEL: self.labelParam
        }
      };

      if (self.valueParam.TYPE == "DATE") {
        self.valueParam.FORMAT = {
          "date_format": self.valueParam.FORMAT
        };
      }

      if (options.context.inEditMode==true && options.context.task){
        utils.sanatizeParamForDuplicateCols(self.valueParam, 'INTERNAL_NAME', options.context.task)
        utils.sanatizeParamForDuplicateCols(self.labelParam, 'INTERNAL_NAME', options.context.task)
      }
      if(options.context.hasOwnProperty('sequence')){
        param['SEQUENCE_NUMBER'] = options.context.sequence;
      }
      return param;
    }

    function sanitizeParam(param){
      let indicesOfColumnNotAvailable = [];
      let indicesOfColumnWithTypeMismatch = [];
      _.forEach(param.UNNEST.COLUMNS, function(col){
        let col_info = utils.metadata.get_column_by_internal_name(self.metadata, col.COLUMN);
        if(!col_info){
          indicesOfColumnNotAvailable.push(param.UNNEST.COLUMNS.indexOf(col))
        }else if(col_info['type']!==param.UNNEST.VALUE.TYPE){
          indicesOfColumnWithTypeMismatch.push(param.UNNEST.COLUMNS.indexOf(col))
        }
      });
      param.UNNEST.COLUMNS = param.UNNEST.COLUMNS.filter(function(value,index){
        return indicesOfColumnNotAvailable.indexOf(index) == -1 && indicesOfColumnWithTypeMismatch.indexOf(index) == -1 ;
      });

    }

    function handlePasteParams(taskInfo){
      /** Update params with suitable replacement columns, based on display name*/

      var params = taskInfo.params;
      for(const index in params.UNNEST.COLUMNS){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.UNNEST.COLUMNS[index], 'COLUMN', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }
      self.columns = evaluateColumns();
      return params
    }

    function setParam(param) {
      // self.sanitizeParam(param);
      let uParam = param.UNNEST;
      self.valueParam = uParam.VALUE;
      if (self.valueParam.TYPE == "DATE") {
        self.valueParam.FORMAT = _.get(self.valueParam.FORMAT, 'date_format');
      }
      self.labelParam = uParam.LABEL;
      _.forEach(uParam.COLUMNS, function(c){
        let column = utils.metadata.get_column_by_internal_name(self.metadata, c.COLUMN);
        self.sources.push(column);
        column.isSelected = true;
        self.columnLabels[column.internal_name] = c.LABEL || column.display_name;
      });
      setAllowedValTypes(self.valueParam.TYPE);
      recalculateValidity();
    }
  }
}



/**
 * @ngInject
 * For validating if the small or large param is right
 */

shouldBeValidUnnestSelection.$inject = ['utils'];
export function shouldBeValidUnnestSelection(utils){
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, e, a, ctrl){
      ctrl.$validators.shouldBeValidUnnestSelection = function(mV, vV){
        return mV === true;
      }
    }
  }
}

function getUniqueString(prefix:String, vals: String[]){
  let index = 2;
  let oString = prefix;
  while(vals.indexOf(oString) != -1){
    oString = prefix + ' ' + String(index);
    index ++;
  }
  return oString;
}

/**Validate that columns selected for formatting are of type 'TEXT'*/
valUnnestCol.$inject = [];
export function valUnnestCol() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valUnnestCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valUnnestCol = function (modelValue, viewValue) {
        let isValid = true;
        var allowedTypes = scope.$eval(attrs.valUnnestCol)

        if (modelValue && allowedTypes.indexOf(scope.column.type) ==-1){
          isValid = false;
        }else if(modelValue && scope.column.hasOwnProperty('error')){
          isValid = false;
        }
        return isValid;
      };
    }
  };
}
