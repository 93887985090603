<template>
  <component
    :is="storeInfo.page"
    v-if="storeInfo.payload"
    :details="storeInfo.payload"
  ></component>
  <span v-else><strong>Nothing selected</strong></span>
</template>

<script>
import { mapGetters } from "vuex";
import AdminInfoWorkspaces from "./templates/AdminInfoWorkspaces.vue";
import AdminInfoIntegrations from "./templates/AdminInfoIntegrations.vue";

export default {
  components: {
    AdminInfoWorkspaces,
    AdminInfoIntegrations
  },
  computed: {
    ...mapGetters({ storeInfo: "info" })
  },
  mounted() {
    this.$axios.post("/token")
  }
};
</script>
