<template>
  <mm-modal
    v-model="computedShowModal"
    class="mm-action-bar-export-save-as-dataset"
    contentClass="action-bar-export-save-as-dataset"
    :title="$t('action_bar.export.save_as_dataset.title')"
    :width="485"
    closable
  >
    <mm-text-input
      v-model="datasetName"
      class="flex-grow-1 m-t-5"
      ref="datasetNameInput"
      :title="$t('global.dictionary.name')"
      :placeholder="$t('action_bar.export.save_as_dataset.dataset_name_input_placeholder')"
      hideDetails
      @keyup.enter="onApplyClick"
    />

    <template #actions>
      <mm-button
        class="flex-grow-1 m-r-2"
        :label="$t('dictionary.cancel')"
        objective="tertiary"
        @click="computedShowModal = false"
      />
      <mm-button
        class="flex-grow-1 m-l-2"
        :label="$t('dictionary.apply')"
        :disabled="!datasetName.length > 0"
        @click="onApplyClick"
      />
    </template>
  </mm-modal>
</template>

<script>
// API
import actionBarApi from '@/modules/data-editor/action-bar/action-bar.api'
import { USER_EVENTS } from '@/constants'

// Dependencies
import { mapState } from 'vuex'

export default {
  name: 'action-bar-export-save-as-dataset',
  props: {
    value: Boolean
  },
  data: () => ({
    datasetName: ''
  }),
  computed: {
    ...mapState({ computedDataEditorSequence: (state) => state.dataEditor.sequence }),
    computedShowModal: {
      get() {
        return this.value
      },
      set(showModal) {
        this.$emit('input', showModal)
      }
    }
  },
  watch: {
    computedShowModal(showModal) {
      if (showModal) {
        setTimeout(() => this.$refs.datasetNameInput.focusInputField(), 10)
      } else {
        this.datasetName = ''
      }
    }
  },
  methods: {
    async onApplyClick() {
      if (!this.datasetName > 0) return

      try {
        const data = await actionBarApi.saveToDataset({
          viewId: this.$store.getters['dataEditor/view'].viewId,
          datasetName: this.datasetName,
          filterCondition:
            this.$store.state.dataEditor.viewsMap[this.$store.getters['dataEditor/view'].viewId].filterCondition,
          sequence: this.computedDataEditorSequence
        })
        this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.SAVE_TO_DATASET, {
          taskName: this.$t('action_bar.export.save_as_dataset.title')
        })

        if (data.ERROR_CODE) {
          const errorMessage = this.$te(`global.api.${data.ERROR_CODE}`)
            ? this.$t(`global.api.${data.ERROR_CODE}`)
            : this.$t('global.api.generic_error')
          this.$toast.show({ content: errorMessage, status: 'error' })
        }

        this.datasetName = ''
        this.computedShowModal = false
      } catch (error) {
        this.$toast.show({
          content: error.message,
          status: 'error'
        })
      }
    }
  }
}
</script>
