import * as angular from 'angular';
import * as _ from 'lodash-es';

/**
 * @ngInject
 * Task service
 * @returns {}
 */
TaskServiceFactory.$inject = ['$resource', '$q', 'config', 'dataviewConfig', '$timeout', 'Task','$rootScope', '$uibModal',
  'eventCallbackManagerFactory', 'toastNotification', 'taskDescriber',
  'modificationRequestTracker', 'DataviewService', 'wkspPreviewService', 'analyticsService', 'utils', 'c', 'FutureService', 'resources'];
export function TaskServiceFactory($resource, $q, config, dataviewConfig, $timeout, Task, $rootScope, $uibModal,
                                   eventCallbackManagerFactory, toastNotification, taskDescriber,
                                   modificationRequestTracker, DataviewService, wkspPreviewService, analyticsService,
                                   utils, c, FutureService, resources) {
  var registry = {};
  var factory: any = {};
  factory.get_by_dataview_id = get_by_dataview_id;
  factory.get_unregistered_instance = get_unregistered_instance;

  return factory;

  function get_unregistered_instance(dataview_id) {
    return new TaskService(dataview_id);
  }

  function get_by_dataview_id(dataview_id){
    if (!registry.hasOwnProperty(dataview_id)){
      registry[dataview_id] = new TaskService(dataview_id);
    }
    return registry[dataview_id];
  }

  function TaskService(dataview_id): void {
    var taskUpdateEvent = new eventCallbackManagerFactory('taskUpdateEvent_' + dataview_id);
    var taskSubmitEvent = new eventCallbackManagerFactory('taskSubmitEvent_' + dataview_id);
    var taskProgressEvent = new eventCallbackManagerFactory('taskProgressEvent_' + dataview_id);

    var _task_list = {}, _prev_task_data, self = this;
    var tasks_resource = $resource(config.api.tasks, {ws_id: dataview_id});
    var dataview = DataviewService.get_by_id(dataview_id);

    var task_service = this;
    task_service.update_list =  update_list;
    task_service.add_task =  add_task;
    task_service.edit_task =  edit_task;
    task_service.editDisplayInfo =  editDisplayInfo;
    task_service.delete_task =  delete_task;
    task_service.suspend_task =  suspend_task;
    task_service.restoreTask =  restoreTask;
    task_service.transformToggled =  true;
    task_service.actionToggled =  true;
    task_service.freshTaskAdded = false;
    task_service.count_of_pipeline_changes = 0;
    task_service.onUpdate =  taskUpdateEvent.add_callback;
    task_service.onSubmit =  taskSubmitEvent.add_callback;
    task_service.taskProgressEvent = taskProgressEvent;
    task_service.onTaskProgress =  taskProgressEvent.add_callback;
    task_service.list =  _task_list;
    task_service.orderedList =  [];
    task_service.getBySequence =  getBySequence;
    task_service.getResultMetadataBySequence = getResultMetadataBySequence;
    task_service.generateTaskProperties =  generate_task_properties;
    task_service.getTaskOpnameFromParams =  get_task_opname_from_params;
    task_service.taskList =  taskList;
    task_service.updateTasksDescription = _set_updates;
    self.desc_resolver = {};
    $timeout(dataview.datasource.get);

    const TASKS_WITH_FOREIGN_COLUMNS = ['JOIN', 'LOOKUP'];
    dataview.dependencies.in.reduce((foreignDataviews, currentDependency) => {
      // For those tasks which have foreign columns
      if (currentDependency.op_type == 'TASK' && TASKS_WITH_FOREIGN_COLUMNS.indexOf(currentDependency.op_name) !== -1) {
        const resource_id = currentDependency.resource_id;
        const dataview = resources.resourcesMap[resource_id];
        foreignDataviews.push(dataview);
      }
      // Remove duplicates to avoid reprocessing the same dataview
      return [...new Set(foreignDataviews)];
    }, []).forEach((foreignView) => {
      // When foreign view is updated, update description of source (self) view
      foreignView.on_update('dataviewUpdateTaskDescriptionListener', () => {
        task_service.update_list(true);
      })
    });

    return task_service;

    ////////
    //vm.task_list
    function _set_updates(tasks_data ?) {
      if(!tasks_data){
        tasks_data = _task_list;
      }

      var existing_task_ids = [];
      angular.forEach(tasks_data, function (task) {

        task = angular.merge(task, generate_task_properties(task));

        if (_task_list.hasOwnProperty(task.id)) {
          _task_list[task.id].update(task);
        } else {
          _task_list[task.id] = new Task(task);
        }
        existing_task_ids.push(task.id);
      });

      angular.forEach(_task_list, function (task: any) {
        if (existing_task_ids.indexOf(task.id) == -1) {
          delete _task_list[task.id];
        }
      });

      task_service.orderedList = _.values(_task_list);
    }

    function future_request_tracker(future_id) {
      var deferred = $q.defer();
      FutureService.track(future_id, data_tracker);
  
      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }
      return deferred.promise;
    }

    function update_list(force) {
      var deferred = tasks_resource.get();
      deferred.$promise.then(get_list_success, get_list_failure);

      function get_list_success(data) {
        var deferred = $q.defer();
        future_request_tracker(data.future_id).then(function (response) {
          var tasks_data = response['tasks'];
          if(tasks_data.length > 0 && dataview.tasks_total_count > 0 && Object.keys(self.list).length==0){
          }
          else {
            if (dataview.status!='ready' || (dataview.hasOwnProperty('task_in_progress') && dataview.task_in_progress==true)){
            return;
           }
          }
          _.remove(tasks_data, function (task) {
            return task['display_info'] && (task['display_info']['invisible'])
          })
          if (angular.equals(tasks_data, _prev_task_data) && !force) {
            return;
          }
          _prev_task_data = _.cloneDeep(tasks_data);
          _set_updates(tasks_data);
          taskUpdateEvent.fire_event() 
          deferred.resolve(response);
        });
      }

      function get_list_failure(data) {}

      return deferred.$promise;
    }

    function getBySequence(sequence) {
      // @ts-ignore
      return _.find(_task_list, {sequence: sequence});
    }

    function getResultMetadataBySequence(sequence){
      if(sequence === 0){
        return dataview.datasource.metadata;
      }
      let t:any = getBySequence(sequence + 1);
      // if sequence is null, that means we are trying to get metadata for an action which is kept at the end of pipeline
      if(sequence!=null && t && t.prev_metadata){
        return t.prev_metadata;
      }
      else{
        return dataview.metadata;
      }
    }

    /**
     * Add task method
     */

    function add_task(param) {
      /* when a task is added, set task_in_progress to true.
       It's use case is in pipeline reordering - when a task is being added, dont allow reordering*/
      taskProgressEvent.fire_event('in_progress');
      var deferred = $q.defer();
      tasks_resource.save({'param': param}).$promise.then(
        function(data){
          if (data.STATUS == 'SUCCESS') {
            if (data.information.status == 'done') {
              deferred.resolve(data);
              addTaskSuccessHandler(data)
            }
            else if (data.information.status == 'error') {
              handleError(dataview_id, data, deferred, undefined)
            }
            else if (data.information.status == 'processing') {
              future_request_tracker(data.information.future_id).then(
                function () {
                  deferred.resolve(data);
                  addTaskSuccessHandler(data)
                },
                function(){
                  taskProgressEvent.fire_event('error');
                  deferred.reject(data);
                })
            }
          }
          else {
            taskProgressEvent.fire_event('error');
            deferred.reject();
          }
        },
        function (data) {
          taskProgressEvent.fire_event('error');
          deferred.reject(data);
        }
      );
      return deferred.promise;
    }
    
    function handleError(dataview_id, data, deferred, metadata) {
      var scp = $rootScope.$new();
      scp.dataviewId = dataview_id;
      scp.task_result_metadata = metadata;
      angular.extend(scp, data);
      _handleFailedRequest(scp);
      const openConfirmationModal = scp => {
        var confirmation = $uibModal.open({
          templateUrl: config.templates.wkspModificationAlert,
          windowClass: 'modal-size-medium discard-step',
          scope: scp
        })
        return confirmation.result
      };
      var confirmationPromise = openConfirmationModal(scp);
      confirmationPromise.then(
        function (result) {
          angular.extend(data, result);
          deferred.reject(data);
        }, function () {
          taskProgressEvent.fire_event('complete')
          deferred.reject(data);
        });
    }
    
    function _get_asset_display_name_type(scope, dataview, asset_internal_name){
      var column;
      if(scope.task_result_metadata){
       column = utils.metadata.get_column_by_internal_name(scope.task_result_metadata, asset_internal_name);
      }
      else {
        column = utils.metadata.get_column_by_internal_name(dataview.metadata, asset_internal_name);
      }
      var metric = _.filter(dataview.metrics, function(m){
            return m.internal_name == asset_internal_name
        });
  
      if (column){
        var display_name = column.display_name
        if (dataview.display_properties.hasOwnProperty('COLUMN_NAMES') && Object.keys(dataview.display_properties.COLUMN_NAMES).indexOf(column.internal_name) != -1){
          display_name = dataview.display_properties.COLUMN_NAMES[column.internal_name]
        }
        return [display_name, 'Column'];
      }
      else if (metric.length){
        return [metric[0].display_name, 'Metric']
      }
      else {
          return ['unknown', 'asset']
      }
    }
    
    function _handleFailedRequest(scope){

      scope.title = "Dependencies detected"
      if (scope.information.status == 'error' && scope.information.error_info!= undefined && scope.information.error_info.column_to_dependencies_dict) {
        scope.affected = {
          num: Object.keys(scope.information.error_info.column_to_dependencies_dict).length,
          txt: utils.number.toWords(Object.keys(scope.information.error_info.column_to_dependencies_dict).length)
        };
      }
  
      var usages = [];
      var wksp = DataviewService.get_by_id(scope.dataviewId);
  
      if(!wksp.metadata){
        wksp.get_data().then(function(){
          _handleFailedRequest(scope);
        });
        return;
      }
  
      if (scope.information.status == 'error' && scope.information.error_info!= undefined && scope.information.error_info.column_to_dependencies_dict) {
          angular.forEach(Object.keys(scope.information.error_info.column_to_dependencies_dict), function (internalName) {
            var ret = _get_asset_display_name_type(scope, wksp, internalName);
            var asset = ret[0];
            var assetType = ret[1];
  
            var tasks = scope.information.error_info.column_to_dependencies_dict[internalName].tasks || [];
            var derivatives = scope.information.error_info.column_to_dependencies_dict[internalName].derivatives || [];
            tasks.forEach(function (t) {
              var taskWs = wksp;
              var foreign = false;
              if (t.dataview_id !=undefined && t.dataview_id != wksp.id) {
                taskWs = DataviewService.get_by_id(t.dataview_id);
                foreign = true;
              }
              usages.push({
                asset: asset,
                assetType: assetType,
                type: 'task',
                task: t,
                dataview: taskWs,
                foreign: foreign
              });
            });
  
            derivatives.forEach(function (d) {
              var derivativeWs = wksp;
              var foreign = false;
  
              if(d.dataview_id !=undefined && d.dataview_id != wksp.id){
                derivativeWs = DataviewService.get_by_id(d.dataview_id);
                foreign = true;
              }
              usages.push({
                asset: asset,
                assetType: assetType,
                type: 'derivative',
                derivative: d,
                foreign: foreign,
                dataview: derivativeWs
              });
            });
          });
      }
  
      scope.usages = usages;
    }

    function addTaskSuccessHandler(data){
      
      if(dataview.pipeline_autorun_enabled){
        taskSubmitEvent.fire_event();
        // Show toast message only when auto run is ON
        toastNotification.success('Task added');
      }else{
        // In case pipeline auto run is disabled no need to fire task submit event which causes dataview data to be fetched and reloads the grid
        // rather fire just event to update dependency dict and its related changes
        DataviewService.fire_on_update_tasks_actions_dependencies(true, dataview_id);
      }
      const taskInternalName = get_task_opname_from_params(data.information.param);
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.taskPanelEvents.taskAdded, {
        taskName: get_task_opname_from_params(data.information.param),
        eventOrigin:"dataview.TransformMenu"
      }, {
        taskName: dataviewConfig.taskConfig[taskInternalName]["label"]
      });
      //To highlight the toggle steps icon on the left of add task icon for 3 seconds
      task_service.freshTaskAdded = true;
      $timeout(function () {
        task_service.update_list();
        taskProgressEvent.fire_event('complete');
        task_service.freshTaskAdded = false;
      }, 3000);  
    }

    function restoreTask(task, skip_validation?){
      taskProgressEvent.fire_event('in_progress');
      var deferred = $q.defer();
      task.restore({skip_validation: skip_validation}).then(function(data){
        if(data.STATUS == 'SUCCESS'){
          if (data.information.status == 'done') {
            deferred.resolve(data);
            editTaskSuccessHandler(data, true);
          }
          else if (data.information.status == 'error') {           
            var metadata_by_sequence_num = getResultMetadataBySequence(task.sequence);
            handleError(dataview_id, data, deferred, metadata_by_sequence_num)
          }
          else if (data.information.status == 'processing') {
            future_request_tracker(data.information.future_id).then(
              function () {
                deferred.resolve(data);
                editTaskSuccessHandler(data,true)
              },
              function(){
                taskProgressEvent.fire_event('error');
                deferred.reject(data);
              })
          }
        }
        else{
          taskProgressEvent.fire_event('error');
          deferred.reject();
        }
      }, function (data) {
        taskProgressEvent.fire_event('error');
        deferred.reject(data);
      });
      return deferred.promise;
    }
    
    function edit_task(task, param) {
      // when a task is edited, set task_in_progress to true.
      // It's usecase is in pipeline reordering - when a task is being edited, dont allow reordering
      taskProgressEvent.fire_event('in_progress');
      var deferred = $q.defer();
      task.edit(param).then(
        function(data){
          if(data.STATUS == 'SUCCESS'){
            if (data.information.status == 'done') {
              deferred.resolve(data);
              editTaskSuccessHandler(data);
            }
            else if (data.information.status == 'error') {           
              var metadata_by_sequence_num = getResultMetadataBySequence(task.sequence);
              handleError(dataview_id, data, deferred, metadata_by_sequence_num)
            }
            else if (data.information.status == 'processing') {
              future_request_tracker(data.information.future_id).then(
                function () {
                  deferred.resolve(data);
                  editTaskSuccessHandler(data)
                },
                function(){
                  taskProgressEvent.fire_event('error');
                  deferred.reject(data);
                })
            }
          }
          else{
            taskProgressEvent.fire_event('error');
            deferred.reject();
          }
        }, 
        function (data) {
          taskProgressEvent.fire_event('error');
          deferred.reject(data);
        });
      return deferred.promise;
    }
    
    function editTaskSuccessHandler(data, restore?){
      var event = c.userEvents.dataviewEvents.taskPanelEvents.taskEdited;
      if (!restore){
        restore = false;
      }else{
        event = c.userEvents.dataviewEvents.taskPanelEvents.taskRestored;
      }
      
      if(dataview.pipeline_autorun_enabled){
        if (restore == true){
          toastNotification.success('Task restored');
        }else{
          toastNotification.success('Task edited');
        }
        taskSubmitEvent.fire_event();
      }else{
        DataviewService.fire_on_update_tasks_actions_dependencies(true, dataview_id);
      }
      analyticsService.userEventTrack(event, {
        taskName: get_task_opname_from_params(data.information.param),
        eventOrigin:"dataview"
      });
      //To highlight the toggle steps icon on the left of add task icon for 3 seconds
      task_service.freshTaskAdded = true;
      $timeout(function () {
        taskProgressEvent.fire_event('complete');
        task_service.update_list(true);
        task_service.freshTaskAdded = false;
      }, 3000);
    }

    function editDisplayInfo(task, display_info) {
      return task.editDisplayInfo(display_info);
    }

    function suspend_task(task, skip_validation?){
      if (skip_validation==undefined){
        skip_validation = false
      }
      
      taskProgressEvent.fire_event('in_progress');
      var deferred = $q.defer();
      task.suspend({skip_validation: skip_validation}).then(function(data){
        if(data.STATUS == 'SUCCESS'){
          if (data.information.status == 'done') {
            deferred.resolve(data);
            deleteTaskSuccessHandler(data, true);
          }
          else if (data.information.status == 'error') {           
            var metadata_by_sequence_num = getResultMetadataBySequence(task.sequence);
            handleError(dataview_id, data, deferred, metadata_by_sequence_num)
          }
          else if (data.information.status == 'processing') {
            future_request_tracker(data.information.future_id).then(
              function () {
                deferred.resolve(data);
                deleteTaskSuccessHandler(data, true)
              },
              function(){
                taskProgressEvent.fire_event('error');
                deferred.reject(data);
              }
            )
          }
        }
        else{
          taskProgressEvent.fire_event('error');
          deferred.reject();
        }
      }, function (data) {
        // Failure callback for edit request
        toastNotification.error(data.data.ERROR_MESSAGE);
        taskProgressEvent.fire_event('error');
        deferred.reject(data);
      });
      return deferred.promise;
    }

    function delete_task(task, skip_validation?) {
      if (skip_validation==undefined){
        skip_validation = false
      }
   
      taskProgressEvent.fire_event('in_progress');
      var deferred = $q.defer();
      task.delete(skip_validation).then(
        function (data) {
          if (data.STATUS == 'SUCCESS') {
            if (data.information.status == 'done') {
              deferred.resolve(data);
              deleteTaskSuccessHandler(data);
            }
            else if (data.information.status == 'error') {           
              var metadata_by_sequence_num = getResultMetadataBySequence(task.sequence);
              handleError(dataview_id, data, deferred, metadata_by_sequence_num)
            }
            else if (data.information.status == 'processing') {
              future_request_tracker(data.information.future_id).then(
                function () {
                  deferred.resolve(data);
                  deleteTaskSuccessHandler(data)
                },
                function(){
                  taskProgressEvent.fire_event('error');
                  deferred.reject(data);
                }
              )
            }
          }
          else {
            taskProgressEvent.fire_event('error');
            deferred.reject();
          }
        }, 
        function (data) {
          toastNotification.error(data.data.ERROR_MESSAGE);
          taskProgressEvent.fire_event('error');
          deferred.reject();
        }
      );
      return deferred.promise;
    }

    function deleteTaskSuccessHandler(data, suspend?){
      var event = c.userEvents.dataviewEvents.taskPanelEvents.taskDeleted;
      if (!suspend){
        suspend = false;
      }else{
        event = c.userEvents.dataviewEvents.taskPanelEvents.taskSuspended;
      }
      
      if(dataview.pipeline_autorun_enabled){
        if (suspend == true){
          toastNotification.success('Task suspended');
        }else{
          toastNotification.success('Task deleted');
        }
        taskSubmitEvent.fire_event();
      }else{
        DataviewService.fire_on_update_tasks_actions_dependencies(true, dataview_id);
      }
      analyticsService.userEventTrack(event, {
        taskName: get_task_opname_from_params(data.information.param),
        eventOrigin:"dataview"
      });
      $timeout(function () {
        task_service.update_list(true);
        taskProgressEvent.fire_event('complete');
      }, 3000);
    }

    function _get_task_properties_object(opname, desc): any {
      return {
        "opname": opname,
        "desc": desc,
        "title": dataviewConfig.taskConfig[opname]["list_title"]
      };
    }
    function generate_task_properties(task) {
      var params = task.params;

      task.desc = {
        description: "",
        row_count : task.row_count,
        error_descriptions: {}
      };
      var formattedMetadata = utils.metadata.applyDisplayChangesReturnMetadata(task.prev_metadata,
        dataview.display_properties, undefined, undefined, false);
      if (task.reference_errors && task.reference_errors.hasOwnProperty('reference_errors')){
        formattedMetadata = utils.metadata.add_error_columns_to_metadata(formattedMetadata, task.reference_errors.reference_errors, dataview.id);
      }
      taskDescriber.describe(formattedMetadata, params, task.desc, dataview.display_properties, task);
      taskDescriber.describeErrors(task.desc, task.reference_errors, dataview.display_properties);
      var desc = task.desc;

      var task_opname = get_task_opname_from_params(params);
      if (task_opname) {
        var _task_obj = _get_task_properties_object(task_opname, desc);
        return _task_obj;
      }
      else {
        console.error('did not understand:', params);
      }

    }

    function taskList(){
      var tasks = task_service.list;
      var flag = false;
      for(var key in tasks){
        flag = true;
        return flag;
      }
    }

    function get_task_opname_from_params(params){
      if (params.hasOwnProperty('COMBINE')) {
        return dataviewConfig.tasks.combine;
      }
      else if (params.hasOwnProperty('COPY')) {
        if (params.hasOwnProperty('VERSION') && params['VERSION'] == 2) {
          return dataviewConfig.tasks.bulkCopy;
        } else {
          return dataviewConfig.tasks.copy;
        }
      }
      else if (params.hasOwnProperty('FILL')) {
        return dataviewConfig.tasks.fillValues;
      }
      else if (params.hasOwnProperty('EXTRACT_DATE')) {
        return dataviewConfig.tasks.extract_date;
      }
      else if (params.hasOwnProperty('SET')) {
        return dataviewConfig.tasks.insert;
      }
      else if (params.hasOwnProperty('LIMIT')) {
        return dataviewConfig.tasks.limit;
      }
      else if (params.hasOwnProperty('LOOKUP')) {
        return dataviewConfig.tasks.lookup;
      }
      else if (params.hasOwnProperty('PIVOT')) {
        if (params._UI_CUSTOM == 'collapseRows') {
          return dataviewConfig.tasks.collapseRows;
        } else {
          return dataviewConfig.tasks.pivot;
        }
      }
      else if (params.hasOwnProperty('REPLACE')) {
        if (params._UI_CUSTOM == 'groupReplace'){
          return dataviewConfig.tasks.bulkReplace;
        }
        else {
          return dataviewConfig.tasks.replace;
        }
      }
      else if (params.hasOwnProperty('SELECT')) {
        return dataviewConfig.tasks.filter;
      }
      else if (params.hasOwnProperty('SPLIT')) {
        return dataviewConfig.tasks.split;
      }
      else if (params.hasOwnProperty('SUBSTRING')) {
        return dataviewConfig.tasks.extract_text;
      }
      else if (params.hasOwnProperty('TEXT_TRANSFORM')) {
        return dataviewConfig.tasks.format_text;
      }
      else if (params.hasOwnProperty('DISCARD_DUPLICATES')) {
        return dataviewConfig.tasks.duplicates;
      }
      else if (params.hasOwnProperty('MATH')) {
        return dataviewConfig.tasks.math;
      }
      else if (params.hasOwnProperty('METRIC')) {
        return dataviewConfig.tasks.metric;
      }
      else if (params.hasOwnProperty('CONVERT')) {
        return dataviewConfig.tasks.convert;
      }
      else if (params.hasOwnProperty('RUNNING_TOTAL')) {
        return dataviewConfig.tasks.running_total;
      }
      else if (params.hasOwnProperty('WINDOW')) {
        return dataviewConfig.tasks.window_function;
      }
      else if (params.hasOwnProperty('INCREMENT_DATE')) {
        return dataviewConfig.tasks.increment_date;
      }
      else if (params.hasOwnProperty('DATE_DIFF')) {
        return dataviewConfig.tasks.date_diff;
      }
      else if (params.hasOwnProperty('ADD_COLUMN')) {
        return dataviewConfig.tasks.addColumn;
      }
      else if (params.hasOwnProperty('DELETE')) {
        return dataviewConfig.tasks.delete;
      }
      else if (params.hasOwnProperty('JOIN')) {
        return dataviewConfig.tasks.join;
      }
      else if (params.hasOwnProperty('JSON_HANDLE')) {
        return dataviewConfig.tasks.json_handle;
      }
      else if (params.hasOwnProperty('UNNEST')) {
        return dataviewConfig.tasks.unnest;
      }
      else if(params.hasOwnProperty('SMALL') || params.hasOwnProperty('LARGE') ){
        return dataviewConfig.tasks.smallOrLarge;
      }
      else {
        return null;
      }
    }
  }
}