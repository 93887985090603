/**
 * Created by mammoth on 10/12/19.
 */
import {config} from "../../common/app.config";
import * as _ from 'lodash-es';
reorderDirective.$inject = ['reorderFactory', '$resource', 'TaskServiceFactory', 'toastNotification'];
export function reorderDirective (reorderFactory, $resource, TaskServiceFactory, toastNotification) {
  var algo = null;
  var wksp_id = null;
  return {
    scope: {
      wkspId: '=',
      sp: '='
    },
    link: function($scope, element, attrs) {
      // we need the native object
      var el = element[0];
      if($scope.wkspId != wksp_id){
        algo = reorderFactory;
      }
      if(attrs.allowreorder == 'true') {
        el.draggable = true;
      }
      wksp_id = $scope.wkspId;

      el.addEventListener(
        'dragstart',
        function(e) {
          if(!e.target.attributes || !e.target.attributes.allowreorder || e.offsetX > 15 || el.attributes.livelink.value == 'true'){
            return;
          }
          e.stopPropagation();
          let self = this;
          setTimeout(function(){
            self.style.opacity = '0';
          });
          e.dataTransfer.effectAllowed = 'move';
          e.dataTransfer.setData('Text', self.id);
          e.dataTransfer.setData(e.target.id, '');
          /*
          save the nearest task of the dragged action item. e.g. if an action is under a task having sequence no. 3
          then 3 will be saved in the dataTransfer object.
          This will help us knowing the initial position of the dragged action while reordering it
           */
          let initialNearestTaskSeq = $scope.getNearestTaskSeq(e.target, $scope.sp.itemsList);
          e.dataTransfer.setData('initialNearestTaskSeq', initialNearestTaskSeq);
          return false;
        },
        false
      );

      el.addEventListener(
        'dragend',
        function(e) {
          e.stopPropagation();
          this.style.opacity = '1';
          // current index of the dragged element
          var idx = Array.prototype.indexOf.call(this.parentNode.children, this);
          // this should have been the index of the dragged element
          var idxReal = algo.indexOf(this.id);
          // if they are out of sync, attempt to recover the previous state
          if(idx != idxReal){
            if(idxReal > idx){
              this.parentNode.insertBefore(this, this.parentNode.children[idxReal].nextSibling);
            }
            else{
              this.parentNode.insertBefore(this, this.parentNode.children[idxReal]);
            }
          }
          return false;
        },
        false
      );

      el.addEventListener(
        'dragover',
        function(e) {
          e.stopPropagation();
          e.preventDefault();
          e.dataTransfer.dropEffect = 'move';
          return false;
        },
        false
      );

      el.addEventListener(
        'dragenter',
        function(e) {
          e.stopPropagation();
          // MVP-4339: Commenting out code related to event-stopper to allow click events on pipeline items(tasks&actions) when drag and drop is enabled
          // this.classList.remove('event-stopper');
          // types contains the data that we have added in dragStart event - id of the item that has been dragged
          let types = e.dataTransfer.types;
          types = types.filter(it=>(it!="text/plain" && it!='initialnearesttaskseq'));
          var item:any;
          // all nodes of parent node
          var allNodes = this.parentNode.children;
          for(let it of allNodes){
            if(it.id == types[0]){
              item = it;
              break;
            }
          }
          if(!item){
            return;
          }
          if(this.parentNode != item.parentNode){
            return;
          }
          // if the item being dragged is going up
          if ($scope.goingUp(item, this)){
            this.parentNode.insertBefore(item, this);
          }
          else{
            this.parentNode.insertBefore(item, this.nextSibling);
          }
        },
        false
      );

      el.addEventListener(
        'dragleave',
        function(e) {
          e.stopPropagation();
          // MVP-4339: Commenting out code related to event-stopper to allow click events on pipeline items(tasks&actions) when drag and drop is enabled
          // this.classList.add('event-stopper');
        },
        false
      );

      el.addEventListener(
        'drop',
        function(e) {
          // Stops some browsers from redirecting.
          if(e.preventDefault) { e.preventDefault(); }
          if (e.stopPropagation) e.stopPropagation();

          var item: any;
          var allNodes = this.parentNode.children;
          for(let it of allNodes){
            if(it.id == e.dataTransfer.getData('Text')){
              item = it;
              break;
            }
          }
          if(!item){
            // MVP-4339: Commenting out code related to event-stopper to allow click events on pipeline items(tasks&actions) when drag and drop is enabled
            // for(let item of allNodes){
            //   item.classList.remove('event-stopper');
            // }
            return;
          }
          var itemid = item.id;
          var fromIdx = algo.indexOf(item.id);
          var idx = Array.prototype.indexOf.call(this.parentNode.children, item);
          var toIdx = idx;
          // dropping at the same place where it was
          if(fromIdx === idx ){
            return;
          }
          // do reordering in the data structure
          let initialNearestTaskSeq = e.dataTransfer.getData('initialnearesttaskseq');
          let actionMovementValid = true;
          let itemObj = $scope.getItemByItemId(item.id, $scope.sp.itemsList);
          if(itemObj.stepType == 'action'){
            actionMovementValid = $scope.validateActionsMovement(item, parseInt(initialNearestTaskSeq), $scope.sp.itemsList);
          }
          let result = algo.validate_move(itemid, toIdx, [], true);
          let isValidMovement = result[0];
          let reason = result[1];
          if(!isValidMovement){
            toastNotification.error("Can't move. " + reason);
          }
          if(!actionMovementValid){
            toastNotification.error("Can't move this action to the same task");
            return
          }
          if(isValidMovement && actionMovementValid){
            algo.move(itemid, toIdx);
            let borderedItem = allNodes[toIdx];
            borderedItem.classList.add('border-warning');
            itemObj.reordered=true;
          }

          let sorted = true;
          let arr = algo._order;

          let itemsReordered = $scope.sp.taskReorderPending();
          $scope.sp.dataview.rf.reorderPending = itemsReordered;
          $scope.sp.dataview.rf.orderChangedLocally = itemsReordered
          if(!itemsReordered){
            for(let item of allNodes){
              item.classList.remove('border-warning');
              let itemObject_ = $scope.getItemByItemId(item.id, $scope.sp.itemsList);
              itemObject_.reordered = false;
            }
          }
          if(!$scope.sp.dataview.pipeline_autorun_enabled && !itemObj.hasOwnProperty('dummy')){
            $scope.sp.reorder($scope.sp.dataview.id).then(function(){
              for(let item of allNodes){
                item.classList.remove('border-warning');
              }
            })
          }
          if(itemObj.hasOwnProperty('dummy')){
            $scope.sp.dataview.rf.reorderPending = false
          }
          return false;
        },
        false
      );

      $scope.goingUp = function(el1, el2) {
        if (el2.parentNode === el1.parentNode) {
          for (var cur = el1.previousSibling; cur && cur.nodeType !== 9; cur = cur.previousSibling) {
            if (cur === el2) {
              return true;
            }
          }
        }
        return false;
      };

      $scope.validateActionsMovement = function(domItem, initialNearestTaskSeq, itemsList){
        /*
         domItem: the action dom element that is being moved/dragged
         initialNearestTaskSeq: initial position of domItem(action item)
         itemsList: array of tasks and actions dom elements

         Validates that the action that is being moved to another position is valid or not.
         Valid movement indicates that the action is moved to a different place
         Invalid movement indicates that the action is moved but it doesn't matter because it's new sequence will still remain same
         */
        let nearestTaskSeq = $scope.getNearestTaskSeq(domItem, itemsList);
        if(initialNearestTaskSeq == nearestTaskSeq){
          return false;
        }
        return true;
      };

      $scope.getItemByItemId = function(itemId, itemsList){
        return itemsList[_.findKey(itemsList, {itemId: itemId})];
      };

      $scope.getNearestTaskSeq = function(domItem, itemsList){
        /*
        this returns the nearest task sequence of the domItem - useful for knowing where action is currently
        itemsList: array containing all tasks and action dom elements
        domItem: an action domItem
         */
        let item = $scope.getItemByItemId(domItem.id, itemsList);
        if (!item){
          return
        }
        let domItemAbove = domItem;
        let itemAbove = item;
        while(domItemAbove && itemAbove.stepType != 'task'){
          domItemAbove = domItemAbove.previousElementSibling;
          if(domItemAbove){
            itemAbove = $scope.getItemByItemId(domItemAbove.id, itemsList);
          }
          else{
            itemAbove = null
          }
        }
        if(itemAbove){
          return itemAbove.sequence;
        }
        else{
          return 0;
        }
      };

      $scope.allNodes = function(root) {
        var nodes = root.children;
        var allNodes = [];
        for (var i = 0; i< nodes.length; i++){
          allNodes.push(nodes[i].id);
        }
        return allNodes;
      };
    }
  }
}

