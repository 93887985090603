// API
import API from '@/api'
import globalApi from '@/api/global.api'

const rename = (id, newName) =>
  API.patch(`dataviews/${id}`, { patch: [{ op: 'replace', path: 'name', value: newName }] })

const reset = async (id) => {
  const { data } = await API.post(`dataviews/${id}/reset`)
  return await globalApi.getFutureRequest(data.future_id)
}

const remove = async (id) => {
  const { data } = await API.post(`dataviews/${id}/safe-delete-request`)
  return await globalApi.getFutureRequest(data.future_id)
}

const loadTemplate = async (id) => {
  const { data } = await API.get(`dataviews/${id}/exportable-config`)
  return await globalApi.getFutureRequest(data.future_id)
}

const applyTemplate = (id, template) => API.post(`dataviews/${id}/exportable-config`, { config: template })

const toggleAutoRun = (viewId, status) =>
  API.post(`dataviews/${viewId}/draft-mode`, { draft_operation: status ? 'exit' : 'enter' })

const toggleViewsDataSyncStatus = async (viewsIds, status) => {
  const { data } = await API.patch(
    'resource_dependencies',
    viewsIds.map((viewId) => ({ context_id: viewId, context_type: 'dataview', data_pass_through: status }))
  )
  return await globalApi.getFutureRequest(data.future_id)
}

export default {
  rename,
  reset,
  remove,
  loadTemplate,
  applyTemplate,
  toggleAutoRun,
  toggleViewsDataSyncStatus
}
