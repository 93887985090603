import * as angular from 'angular';
import * as dateformat from 'dateformat';

export function queryHelperService() {
  return {
    getDateQuery: getDateQuery
  }

  function getDateQuery(groupParam, rootColumnInternalName, value) {
    var query: any = {};
    if(groupParam.hasOwnProperty('COMPONENT')){
      query[rootColumnInternalName] = {EQ: {
        COMPONENT: groupParam.COMPONENT,
        VALUE: value
      }};
    }
    else if((groupParam.hasOwnProperty('TRUNCATE'))){
      var date = new Date(value);
      var next_date = new Date(value);
      if (groupParam.TRUNCATE == 'DAY'){
        next_date.setDate(next_date.getDate() + 1);
      }
      else if(groupParam.TRUNCATE == 'MONTH'){
        next_date.setMonth(next_date.getMonth() + 1);
      }
      else if (groupParam.TRUNCATE == 'WEEK') {
        date.setDate(date.getDate() - 7);
      }

      var gte = {};
      var lt = {};
      gte[rootColumnInternalName] = {GTE: dateformat(date, 'yyyy-mm-dd') + ' 00:00:00'};
      lt[rootColumnInternalName] = {LT: dateformat(next_date, 'yyyy-mm-dd') + ' 00:00:00'};
      query.AND = [gte, lt];
    }
    return query;
  }
}
