/*jslint node: true */

import * as angular from 'angular';
import * as $ from 'jquery';
import _ = require('lodash');

/**
 * @ngInject
 */
filterMenuManagerFactory.$inject = ['c', 'eventCallbackManagerFactory', 'utils', 'elementConfig'];
export function filterMenuManagerFactory(c, eventCallbackManagerFactory, utils, elementConfig){
  var dateTruncateExtractOptions = c.dateTruncateExtractOptions;
  var defaultLimit = 10;

  return {
    get: getMenu
  }

  function getMenu(options){
    return new FilterMenu(options);
  }

  function FilterMenu(options){
    var self = this;
    self.getParam = getParam;
    self.setParam = setParam;
    self.getDisplayProperties = getDisplayProperties;
    self.onColumnChange = onColumnChange;
    self.handleApplyLimitChanges = handleApplyLimitChanges;
    self.dateTruncateExtractOptions = dateTruncateExtractOptions;

    self.random_name = 'table_' + utils.getRandomString();
    var filterMenuEvent = new eventCallbackManagerFactory(self.random_name);
    self.on_valid_update = filterMenuEvent.add_callback;
    self.limitMax = elementConfig.rowLimits.filter;
    self.limit = defaultLimit;

    self.filterTypes = [
      'Labels', 'Dropdown'
    ]

    var defaultDisplayProperties = {
      type: 'filter', title: null,
      filterType: self.filterTypes[0],
      applyLimit: true
    };

    init();
    update(options);

    function init() {
      self.metadata = options.metadata;
      self.column = null;
      self.sortOption = 'ASC';
      self.displayProperties = _.cloneDeep(defaultDisplayProperties);
      if(!self.displayProperties.hasOwnProperty('truncateExtractInfo')){
        self.displayProperties.truncateExtractInfo = null;
      }
    }

    function update(options) {
      self.metadata = options.metadata;
      if (options.element ){
        if(options.element.param){
          self.setParam(options.element.param)
        }
        if (options.element.display_properties){
          self.displayProperties = options.element.display_properties;
        }
      }
    }

    function getParam() {
      var groupParam = {COLUMN: self.column.internal_name};
      if(self.column.type == 'DATE'){
        angular.merge(groupParam, self.displayProperties.truncateExtractInfo.param);
      }
      var ret: any =  {
        PIVOT: {
          GROUP_BY: [groupParam],
          SELECT: [{
            FUNCTION: "COUNT",
            INTERNAL_NAME: 'count_column_for_filter',
            AS: 'Count'
          }]
        }
      };

      if(self.displayProperties.applyLimit){
        ret.PIVOT.LIMIT = {
          LIMIT: self.limit,
          BOTTOM: false
        };
        ret.PIVOT.ORDER_BY = [['count_column_for_filter', 'DESC']];
      }
      return ret;
    }

    function handleApplyLimitChanges(){
      if(self.displayProperties.applyLimit){
        self.limit = defaultLimit;
      }
      else{
        self.limit = null;
      }
    }


    function setParam(param) {
      var column_internal_name = param.PIVOT.GROUP_BY[0].COLUMN;

      self.column = $.grep(self.metadata, function(c: any){
        return c.internal_name == column_internal_name;
      })[0];

      if(param.PIVOT.hasOwnProperty('LIMIT') && param.PIVOT.LIMIT.hasOwnProperty('LIMIT')){
        self.limit = param.PIVOT.LIMIT.LIMIT;
      }
    }

    function getDisplayProperties() {
      var sortSpec = [self.column.internal_name, self.sortOption];
      if(self.column.type == 'DATE'){
        if(self.displayProperties.truncateExtractInfo.param.hasOwnProperty('COMPONENT')){
          sortSpec.push(self.displayProperties.truncateExtractInfo.param.COMPONENT);
        }
      }
      var dataview_format_info = options.dataview.display_properties.FORMAT_INFO || {};
      if(!self.displayProperties.FORMAT_INFO){
        self.displayProperties.FORMAT_INFO = {};
      }

      if (self.column.type == c.numeric) {
        if (dataview_format_info && dataview_format_info[self.column.internal_name]) {
          self.displayProperties.FORMAT_INFO[self.column.internal_name] = _.cloneDeep(dataview_format_info[self.column.internal_name] || c.numericDefaultFormat);
        }
        else {
          self.displayProperties.FORMAT_INFO[self.column.internal_name] = c.numericDefaultFormat;
        }
      }
      self.displayProperties.SORT = [sortSpec];

      if(self.column.type != c.date){
        self.displayProperties.truncateExtractInfo = undefined;
      }
      return self.displayProperties;
    }

    function onColumnChange(){
      self.displayProperties.title = self.column.display_name;
      if(self.column.type == c.date){
        if(!self.displayProperties.truncateExtractInfo){
          self.displayProperties.truncateExtractInfo = dateTruncateExtractOptions[0];
        }
      }
      else{
        self.displayProperties.truncateExtractInfo = null;
      }
    }
  }
}
