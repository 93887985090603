<template>
  <mm-list class="action-bar-transform-list p-a-3" :items="items">
    <template #item="{ item }">
      <mm-menu :disabled="!item.children.length" position="right" openOnHover>
        <template #activator="{ on }">
          <div
            class="d-flex flex-grow-1 cursor-pointer p-a-3"
            v-on="on"
            @click="item.action && $emit('action', item.action)"
          >
            <label class="flex-grow-1 cursor-pointer" v-text="item.label" />
            <mm-icon v-if="item.children.length" name="chevron_right" />
          </div>
        </template>
        <template #content>
          <action-bar-transform-list :items="item.children" @action="$emit('action', $event)" />
        </template>
      </mm-menu>
    </template>
  </mm-list>
</template>

<script>
export default {
  name: 'action-bar-transform-list',
  props: {
    items: Array
  }
}
</script>

<style lang="scss" scoped>
.action-bar-transform-list {
  ::v-deep .mm-list-item {
    margin: 0px !important;
    padding: 0px !important;

    &:focus {
      background: var(--mm-color--n10) !important;
    }
    &:hover {
      background: var(--mm-color--p75) !important;
    }
  }
}
</style>
