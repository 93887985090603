import * as angular from 'angular';
import * as _ from 'lodash-es';

extractTextManagerFactory.$inject = ['destinationColumnManagerFactory', 'filterManagerFactory', 'utils', 'c', 'FilterEditHelperFactory','filterUI'];
export function extractTextManagerFactory(destinationColumnManagerFactory, filterManagerFactory, utils, c, FilterEditHelperFactory, filterUI) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new ExtractTextManager(options);
  }

  function ExtractTextManager(options) {
    var metadata = options.metadata, taskUtils = options.taskUtils;
    var self = this;
    self.getParam = getParam;
    self.validate = validate;
    self.handlePasteParams = handlePasteParams;
    self.context = options.context;
    self.sanitizeParam = sanitizeParam;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.setParam = setParam;
    self.openConditionMenu = openConditionMenu;
    self.openConditionModal = openConditionModal;
    self.highlightColumns = highlightColumns;
    self.handleSwitchType = handleSwitchType;
    self.enableRegex = enableRegex;
    self.isRegexValid = true;

    self.metadata = metadata;
    self.source_columns = $.grep(self.metadata, function (col: any) {
      return col.type === c.text;
    });
    self.filterEditHelper = new FilterEditHelperFactory();
    self.filterEditHelper.onMaximizeFilterPopoverToModal('maximizeFilterPopoverToModal',  self.openConditionModal)
    self.filterManager = filterManagerFactory.get_manager({
      metadata: metadata,
      dataviewId: options.context.dataview.id,
      context: options.context,
      originalSequenceNumber: options.originalSequenceNumber
    });

    self.destinationManager = destinationColumnManagerFactory.get_manager(
      {metadata: metadata, allowedTypes: ['TEXT'], taskUtils: options.taskUtils}
    );

    self.view = {
      type: 'wc',  // be: Beginning/End | pos: Position | wc: wildcard
      all: true,
      left: 1,
      begn: true,
      num: undefined,
      pos: undefined,
      wc: undefined,
      incWc: false,
      regex: '',
      regexPreset: '',

      // constants
      ALLOPS: [{'d': 'All', 'v': true}, {'d': 'Specific', 'v': false}],
      LEFTOPS: [{'d': 'left of', 'v': 1}, {'d': 'left of and including', 'v': 2}, {'d': 'right of', 'v': 3}, {'d': 'right of and including', 'v': 4}],
      BEGNOPS: [{'d': 'beginning', 'v': true}, {'d': 'end', 'v': false}],
    };

    self.param = {
      'SUBSTRING': {
      }
    };

    function enableRegex() {
      return localStorage.getItem('enableRegexInExtractText') == 'true';
    }

    function handleSwitchType(){
      self.view = {
        type: self.view.type,  // be: Beginning/End | pos: Position | wc: wildcard
        all: true,
        left: 1,
        begn: true,
        num: undefined,
        pos: undefined,
        wc: undefined,
        incWc: false,
        regex: self.view.regex,
        regexPreset: self.view.regexPreset,

        // constants
        ALLOPS: [{'d': 'All', 'v': true}, {'d': 'Specific', 'v': false}],
        LEFTOPS: [{'d': 'left of', 'v': 1}, {'d': 'left of and including', 'v': 2}, {'d': 'right of', 'v': 3}, {'d': 'right of and including', 'v': 4}],
        BEGNOPS: [{'d': 'beginning', 'v': true}, {'d': 'end', 'v': false}],
      };
    }

    function openConditionMenu(){
      // filterUI.open(self.filterManager);
      self.filterEditHelper.open(self.filterManager);
    }

    function openConditionModal(param=null){
      filterUI.open(self.filterManager, param);
    }

    function validate() {
      let is_valid = true;
      if (self.filterManager.condition){
        is_valid = self.filterManager.condition.validate()
      }
      if(self.destinationManager){
        is_valid = is_valid && self.destinationManager.validate();
      }
      if (self.view.regex) {
        is_valid = is_valid && utils.string.isRegexValid(self.view.regex);
        self.isRegexValid = is_valid;
      }
      else {
        self.isRegexValid = true;
      }
      return is_valid;

    }

    function getParam() {
      var destination_param = self.destinationManager.getParam();
      self.param.SUBSTRING = {SOURCE: self.param.SUBSTRING.SOURCE};
      delete self.param.SUBSTRING.DESTINATION;
      delete self.param.SUBSTRING.AS;
      if (destination_param.hasOwnProperty('AS') && self.context.inEditMode==true  &&  self.context.task){
        utils.sanatizeParamForDuplicateCols(destination_param['AS'], 'INTERNAL_NAME', self.context.task)
      }
      angular.extend(self.param.SUBSTRING, destination_param);

      if(self.filterManager.condition){
        self.param.CONDITION = self.filterManager.getParam();
      } else {
        delete self.param.CONDITION;
      }
      var subParam = self.param.SUBSTRING;

      let wcpos = {
        1: {dir: 'LEFT', wc: false},
        2: {dir: 'LEFT', wc: true},
        3: {dir: 'RIGHT', wc: false},
        4: {dir: 'RIGHT', wc: true}
      };

      if (self.view.type === 'pos') {
        subParam.CHAR_POSITION = parseInt(self.view.pos);
        subParam.INC_WILDCARD = wcpos[self.view.left]['wc'];
      }

      if (self.view.type === 'pos' || self.view.type === 'wc') {
        subParam.DIRECTION = wcpos[self.view.left]['dir'];
      } else {
        subParam.DIRECTION = self.view.begn ? 'START' : 'END';
      }

      if (self.view.type === 'be' || !self.view.all) {
        subParam.NUM_CHAR = parseInt(self.view.num);
      }

      if (self.view.type === 'wc') {
        subParam.WILDCARD = self.view.wc;
        subParam.INC_WILDCARD = wcpos[self.view.left]['wc'];
      }

      if (self.view.type === 'regex') {
        subParam.REGEX = {
          EXPRESSION: self.view.regex,
          INVERT: false
        }
      }

      if(self.view.type === 'regexInvert') {
        subParam.REGEX = {
          EXPRESSION: self.view.regex,
          INVERT: true
        }
      }

      if(self.view.type.startsWith('regexPreset')) {
        let presetType = self.view.type.substring('regexPreset'.length);
        subParam.REGEX = {
          EXPRESSION: self.view.regexPreset,
          INVERT: presetType.startsWith('DoesNot'),
          PRESET: presetType
        }
      }
      

      if(options.context.hasOwnProperty('sequence')){
        self.param['SEQUENCE_NUMBER'] = options.context.sequence;
      }
      return self.param;
    }

    function handlePasteParams(taskInfo){
      /** Update params with suitable replacement columns, based on display name*/

      var param = taskInfo.params;

      //Condition param
      var columnUsedInCondition = utils.get_input_columns(_.cloneDeep(param.CONDITION))
      if (columnUsedInCondition) {
        utils.metadata.findReplaceColumnsInCondition(self.metadata, self.displayNameAndTypeToColumnMap, param, columnUsedInCondition, taskInfo)
      }
      //destination param
      if (param.SUBSTRING.hasOwnProperty('DESTINATION')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(param.SUBSTRING, 'DESTINATION', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }

      //Source param
      utils.metadata.replaceMatchingColumnAndUpdateMetadata(param.SUBSTRING, 'SOURCE', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);

      self.destinationManager.metadata = self.metadata
      self.destinationManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(self.metadata)
      self.filterManager.metadata = self.metadata
      return param
    }

    function highlightColumns() {
      taskUtils.highlight.sources(self.param.SUBSTRING.SOURCE);
    }

    function sanitizeParam(param){
      let col_info = utils.metadata.get_column_by_internal_name(metadata, param.SUBSTRING.SOURCE);
      if (!col_info){
        param.SUBSTRING.SOURCE = undefined;
      }else if(col_info['type']!=='TEXT'){
        param.SUBSTRING.SOURCE = undefined;
      }
    }
    function setParam(param) {
      // self.sanitizeParam(param);
      self.destinationManager.setParam(param.SUBSTRING);

      if (param.hasOwnProperty('CONDITION')) {
        self.filterManager.setParam(param.CONDITION, param?.EXECUTION_TIMESTAMP);
      }
      self.param = param;
      var subParam = param.SUBSTRING;
      var col_info = utils.metadata.get_column_by_internal_name(self.source_columns, param.SUBSTRING.SOURCE)
      if (!col_info){
        col_info = utils.metadata.get_column_by_internal_name(self.metadata, param.SUBSTRING.SOURCE)
        self.source_columns.push(col_info)
      }
      else if (subParam.REGEX) {
        if (subParam.REGEX.PRESET) {
          self.view.type = 'regexPreset' + subParam.REGEX.PRESET;
          self.view.regexPreset = subParam.REGEX.EXPRESSION;
        }
        else {
          if(subParam.REGEX.INVERT === false) {
            self.view.type = 'regex';
          }
          else {
            self.view.type = 'regexInvert';
          }
          self.view.regex = subParam.REGEX.EXPRESSION;
        }
      }
      else if (subParam.DIRECTION === 'START' || subParam.DIRECTION === 'END') {
        self.view.type = 'be';
        self.view.begn = subParam.DIRECTION === 'START';
        self.view.num = subParam.NUM_CHAR;
      } else if (subParam.DIRECTION === 'LEFT' || subParam.DIRECTION === 'RIGHT') {
        if (subParam.hasOwnProperty('CHAR_POSITION') && subParam.CHAR_POSITION !== null &&
          subParam.CHAR_POSITION !== undefined) {
          self.view.type = 'pos';
          self.view.pos = subParam.CHAR_POSITION;
        } else {
          self.view.type = 'wc';
          self.view.wc = subParam.WILDCARD;
          self.view.incWc = subParam.INC_WILDCARD === true;
        }
        if (subParam.hasOwnProperty('NUM_CHAR') && subParam.NUM_CHAR !== null &&
          subParam.NUM_CHAR !== undefined) {
          self.view.num = subParam.NUM_CHAR;
          self.view.all = false;
        } else {
          self.view.all = true;
        }
        if (subParam.DIRECTION == 'LEFT') {
          if (subParam.INC_WILDCARD) {
            self.view.left = 2;
          } else {
            self.view.left = 1;
          }
        } else if (subParam.DIRECTION == 'RIGHT') {
          if (subParam.INC_WILDCARD) {
            self.view.left = 4;
          } else {
            self.view.left = 3;
          }
        }
      }
      highlightColumns();
    }
  }
}

valExtractCol.$inject = ['utils']
export function valExtractCol(utils) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valExtractCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valExtractCol = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue) {
          var colInfo = utils.metadata.get_column_by_internal_name(scope.tvm.manager.metadata, modelValue);
          if (colInfo && colInfo.hasOwnProperty('error')) {
            is_valid = false;
          } else if (colInfo && colInfo.type !='TEXT') {
            is_valid = false;
          }

        }
        return is_valid
      }
    }
  }
}
