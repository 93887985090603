<template>
  <mm-row class="mm-data-addition-third-party-selection" no-gutters>
    <mm-col class="search-col" cols="6" md="3">
      <mm-text-input
        v-model="search"
        class="width-100 m-l-2 p-r-3"
        :placeholder="$t('data_library.data_addition.third_party.search_connectors')"
        type="search"
      />

      <ul class="basic-list">
        <li
          v-for="category in CONNECTOR_CATEGORIES"
          :key="category"
          :active="category == currentCategory"
          @click="currentCategory = category"
        >
          {{ $t(`data_library.data_addition.third_party.category.${category}`) }}
        </li>
      </ul>
      <div v-if="isBackButtonVisible">
        <mm-button :label="$t('global.dictionary.back')" objective="tertiary" @click="redirectToMainMenu" />
      </div>
    </mm-col>

    <mm-col class="connectors-col">
      <div class="p-x-3 m-b-5">
        <span class="mm-text--h400">
          {{ $t(`data_library.data_addition.third_party.category.${currentCategory}`) }}
        </span>
        <p class="connector-description">{{ $t('data_library.data_addition.third_party.connector_description') }}</p>
      </div>

      <mm-row style="align-content: flex-start" no-gutters>
        <mm-col
          v-for="connector in computedConnectors"
          :key="connector.key"
          class="connector-col p-a-3"
          cols="6"
          md="4"
          lg="3"
        >
          <connector-card
            :connector="connector"
            :is-premium="connector.isPremium"
            :is-acquired="isConnectorCardAcquired(connector)"
            :disabled="connector.disabled"
            @click="selectConnector(connector)"
          />
        </mm-col>

        <div v-if="computedConnectors.length == 0" style="text-align: center" class="no-connectors width-100 m-t-5">
          <span class="mm-text--h200">{{ $t('data_library.data_addition.third_party.no_connectors') }}</span>
        </div>
      </mm-row>
    </mm-col>

    <mm-modal
      v-model="isAddConnectorsModalVisible"
      :title="$t('data_library.data_addition.third_party.add_connectors.title')"
      width="450"
    >
      <span>{{ $t('data_library.data_addition.third_party.add_connectors.description') }}</span>
      <template #actions>
        <mm-button
          :label="$t('global.dictionary.not_now')"
          objective="tertiary"
          @click="isAddConnectorsModalVisible = false"
        />
        <mm-button
          class="m-l-3"
          :label="$t('data_library.data_addition.third_party.add_connectors.title')"
          @click="openSettings"
        />
      </template>
    </mm-modal>

    <add-connectors-support v-model="isAddConnectorsSupportModalVisible" />
  </mm-row>
</template>

<script>
// Plugins
import { featuresVisibility } from '@/plugins/splitio'

// Constants
import {
  SPLITIO_FEATURE_FLAGS,
  USER_EVENTS,
  CONNECTOR_CATEGORIES,
  WORKSPACE_PERMISSIONS,
  getOldAppUrl
} from '@/constants'

// Components
import ConnectorCard from '@/components/connector-card/connector-card'
import AddConnectorsSupport from '@/modules/settings/components/settings-workspace/settings-workspace-plan/settings-workspace-plan-upgrade-modals/settings-workspace-plan-add-connectors/settings-workspace-plan-add-connectors-support/settings-workspace-plan-add-connectors-support'

export default {
  name: 'data-library-data-addition-third-party-selection',
  components: { ConnectorCard, AddConnectorsSupport },
  props: {
    connectors: {
      type: Array,
      required: true
      /*[{
        key: string,
        name: string,
        icon: string,
        category: string,
        connection: {...},
        metrics: {...}
      }]*/
    },
    isBackButtonVisible: Boolean
  },
  data: () => ({
    search: '',
    currentCategory: 'all',
    isAddConnectorsModalVisible: false,
    isAddConnectorsSupportModalVisible: false
  }),
  computed: {
    computedConnectors() {
      return this.connectors
        .filter((c) => featuresVisibility[SPLITIO_FEATURE_FLAGS.CONNECTORS[c.key]])
        .filter((c) => this.currentCategory == CONNECTOR_CATEGORIES.ALL || c.category == this.currentCategory)
        .filter((c) => c.name.toLowerCase().includes(this.search.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  created() {
    this.CONNECTOR_CATEGORIES = CONNECTOR_CATEGORIES
  },
  methods: {
    redirectToMainMenu() {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.BACK_TO_MAIN, { type: 'thirdParty' })
      this.$emit('back')
    },
    isConnectorCardAcquired(connector) {
      return (
        connector.isPremium &&
        connector.isAcquired &&
        this.$store.getters.getCurrentWorkspacePermissions != WORKSPACE_PERMISSIONS.MEMBER
      )
    },
    selectConnector(connector) {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.SELECT, {
        key: connector.key,
        name: connector.name
      })

      this.selectedConnector = connector

      if (connector.disabled)
        this.$toast.show({
          title: this.$t('data_library.data_addition.third_party.disabled_connector.title'),
          content: this.$t('data_library.data_addition.third_party.disabled_connector.description'),
          status: 'warning'
        })
      else if (connector.isPremium && !connector.isAcquired)
        if (!this.$store.state.smsDetails.currentPlan.isSelfServe) {
          const acquiredConnectorsQuantity = this.computedConnectors.filter((c) => c.isAcquired).length
          const isMaxAcquiredConnectorsQuantityReached =
            acquiredConnectorsQuantity >= this.$store.state.smsDetails.currentPlan.allowedConnectorQuantity
          if (isMaxAcquiredConnectorsQuantityReached) this.isAddConnectorsSupportModalVisible = true
          else this.isAddConnectorsModalVisible = true
        } else if (this.$store.state.smsDetails.currentPlan.areAdditionalConnectorsAllowed)
          this.isAddConnectorsModalVisible = true
        else
          this.$toast.show({
            content: this.$t('data_library.data_addition.third_party.add_connectors.restricted_plan'),
            status: 'error'
          })
      else this.$emit('submit', connector)
    },
    openSettings() {
      this.isAddConnectorsModalVisible = false
      //TODO remove when angular code is gone
      window.open(
        `${getOldAppUrl()}/n/#/settings/workspace/${this.$store.state.workspaceId}?addConnectors=${
          this.selectedConnector.key
        }`,
        'Settings'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.mm-data-addition-third-party-selection {
  min-height: 0;
  display: flex;
  flex-grow: 1;

  .search-col {
    flex-direction: column;
    display: flex;
    height: 100%;

    .basic-list {
      flex-direction: column;
      overflow: auto;
      min-height: 0;
      display: flex;
      height: 100%;
    }
  }

  .connectors-col {
    flex-direction: column;
    display: flex;
    height: 100%;

    .mm-row {
      overflow: auto;
      height: 100%;
    }

    .connector-description {
      color: var(--mm-color--n600);
    }

    .connector-col {
      flex-direction: column;
      height: fit-content;
      display: flex;
    }

    .no-connectors {
      color: var(--mm-color--n300);
    }
  }
}
</style>
