<template>
  <div
    class="notification-item"
    :class="[
      `notification-item-${notification.status}`,
      notification.isRead ? 'notification-item-read' : 'cursor-pointer'
    ]"
    @click.stop="setReadState(true)"
  >
    <div class="notification-item-indicator" />

    <div class="p-y-3 p-x-4">
      <div>
        <div class="d-flex">
          <div class="d-flex" style="width: 285px">
            <mm-icon v-if="computedIsError" name="error" color="dest800" class="m-r-2" style="width: 24px" />
            <div>
              <p class="mm-text--body-bold m-b-1">{{ computedWorkspaceName }}</p>
              <p class="m-a-0">{{ computedDescription }}</p>
            </div>
          </div>

          <div class="mm-notification-item-hover-buttons m-l-auto">
            <mm-button
              :icon="notification.isRead ? 'circle' : 'sucess'"
              objective="tertiary"
              size="small"
              @click.stop="setReadState(!notification.isRead)"
            />
            <mm-button icon="close" objective="tertiary" size="small" @click.stop="close" />
          </div>
        </div>

        <p v-if="!computedIsProcessing" :key="dateKey" class="mm-text--caption-regular notification-date m-a-0">
          {{ getFromNowDate(notification.dataUpdatedAt) }}
        </p>
      </div>

      <mm-progress-bar v-if="computedIsProcessing" v-model="notification.progress" class="m-t-3" />
    </div>
  </div>
</template>

<script>
// Filters
import { getFromNowDate } from '@/utils/filters'

//Constants
import { NOTIFICATION_STATUS, USER_EVENTS } from '@/constants'

// Dependencies
import moment from 'moment'

export default {
  name: 'navbar-notifications-item',
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dateKey: 0
  }),
  computed: {
    computedIsError() {
      return this.notification.status === NOTIFICATION_STATUS.ERROR
    },
    computedIsProcessing() {
      return this.notification.status === NOTIFICATION_STATUS.PROCESSING
    },
    computedDescription() {
      const n = this.notification
      if (this.$te(`navbar.notifications.error.${n.details.errorKey}`))
        return this.$t(`navbar.notifications.error.${n.details.errorKey}`, n.details)
      if (this.$te(`navbar.notifications.${n.details.type}.${n.details.subType}.${n.status}`))
        return this.$t(`navbar.notifications.${n.details.type}.${n.details.subType}.${n.status}`, n.details)
      return this.$t('navbar.notifications.error.generic')
    },
    computedWorkspaceName() {
      return this.$store.state.workspaces.find((workspace) => workspace.id == this.notification.workspaceId)?.name
    }
  },
  created() {
    // If notification is recent (less than 1h), update its date output every X minutes, else update every hour
    const notificationHours = moment
      .duration(moment().diff(moment.utc(this.notification.dataUpdatedAt).local()))
      .asHours()
    this.reRenderNotificationDate(notificationHours < 1 ? 60000 : 3600000)
  },
  methods: {
    setReadState(state) {
      if (this.notification.isRead == state) return
      if (state) {
        this.$store.dispatch('notifications/read', this.notification.id)
        this.$userEvents.save(USER_EVENTS.NAVBAR.NOTIFICATIONS.READ, { id: this.notification.id })
      } else {
        this.$store.dispatch('notifications/unread', this.notification.id)
        this.$userEvents.save(USER_EVENTS.NAVBAR.NOTIFICATIONS.UNREAD, { id: this.notification.id })
      }
    },
    close() {
      this.$store.dispatch('notifications/remove', this.notification.id)
      this.$userEvents.save(USER_EVENTS.NAVBAR.NOTIFICATIONS.DELETE, { id: this.notification.id })
    },
    reRenderNotificationDate(delay) {
      this.dateKey++
      setTimeout(() => this.reRenderNotificationDate(), delay)
    },
    getFromNowDate
  }
}
</script>

<style lang="scss" scoped>
$date-font-color: var(--mm-color--n400);
$error-font-color: var(--mm-color--dest800);
$read-font-color: var(--mm-color--n400);
$indicator-width: 4px;
$indicator-completed-color: var(--mm-color--info800);
$indicator-processing-color: var(--mm-color--n80);
$indicator-error-color: var(--mm-color--dest800);
$indicator-read-color: var(--mm-color--n10);

.notification-item {
  white-space: pre-line;
  position: relative;

  .notification-item-indicator {
    width: $indicator-width;
    height: 100%;
    position: absolute;
  }

  .notification-date {
    color: $date-font-color;
    white-space: initial;
  }

  .mm-notification-item-hover-buttons {
    height: fit-content;
    display: flex;
    opacity: 0;
  }

  &:hover .mm-notification-item-hover-buttons {
    opacity: 1;
  }

  &.notification-item-completed {
    .notification-item-indicator {
      background: $indicator-completed-color;
    }
  }

  &.notification-item-processing {
    .notification-item-indicator {
      background: $indicator-processing-color;
    }
  }

  &.notification-item-error {
    .notification-item-indicator {
      background: $indicator-error-color;
    }
  }

  &.notification-item-read {
    .notification-item-indicator {
      background: $indicator-read-color;
    }

    p {
      color: $read-font-color !important;
    }

    ::v-deep svg {
      --mm-icon--color: #{$read-font-color} !important;
      --mm-icon--color-hover: #{$read-font-color} !important;
    }
  }
}
</style>
