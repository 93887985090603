/*jslint node: true */
/**
 * @ngInject
 */
ftpHelpers.$inject = ['moment'];
export function ftpHelpers(moment) {
  var date_patterns =
  {
    YYYY_MM_DD: /^\d{4}[_]\d{2}[_]\d{2}$/,
    "YYYY_MM_DD_HH:MM": /^\d{4}[_]\d{2}[_]\d{2}[_]\d{2}[:]\d{2}$/

  };

  return {
    on_path_change: on_path_change,
    on_pattern_change: on_pattern_change,
    on_load: on_ds_spec_load,
    onFileListChange: onFileListChange,
    onPatternChange: onPatternChange,
    onNextFilePullSelected: onNextFilePullSelected,
    onFolderListChange: onFolderListChange
  };


  function on_ds_spec_load(){
    // doing this for weird reason
    this.extraData.parentController.isDsConfigValid = true;
  };

  function on_path_change(value, element, scope) {
    var ctrl = scope.options.data.parentController;
    ctrl.dsConfigValidationMessage = null;
    var filename = scope.model.filename ? scope.model.filename : "";
    scope.options.data.keyWiseFieldsMap.hidden_path.mappedField.templateOptions.enabled = !!(scope.model.filename && scope.model.folder_path && ctrl.isDsConfigValid);
    if (scope.model.name_pattern) {

      var dateReg = date_patterns[scope.model.name_pattern];
      if (dateReg && scope.model.date_input && scope.model.date_input.match(dateReg)) {
        scope.options.data.keyWiseFieldsMap.hidden_path.mappedField.templateOptions.enabled = !!(scope.model.filename && scope.model.folder_path);
        ctrl.isDsConfigValid = true;
        scope.model.hidden_path = scope.model.folder_path + (scope.model.folder_path.endsWith('/') ? "" : "/") + filename + scope.model.date_input + '.csv';
      }
      else {
        ctrl.isDsConfigValid = false;
        scope.options.data.keyWiseFieldsMap.hidden_path.mappedField.templateOptions.enabled = false;
        ctrl.dsConfigValidationMessage = {
          error_message: "Invalid date"
        };
      }
    }
    else {
         // TODO: SENTRYERROR:FRONTEND-K1:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/474545779
     // TODO: SENTRYERROR:FRONTEND-K0:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/474367778
     scope.model.hidden_path = scope.model.folder_path;
      scope.options.data.keyWiseFieldsMap.hidden_path.mappedField.templateOptions.enabled = !!(scope.model.filename && scope.model.folder_path);
      ctrl.isDsConfigValid = !!(scope.model.folder_path);
    }
  }

  function on_pattern_change(value, element, scope) {
    var ctrl = scope.options.data.parentController;
    ctrl.dsConfigValidationMessage = null;

    var lv_text;
    lv_text = "You can use this date format pattern to schedule your file imports. Example filename: ";

    if (scope.model.name_pattern) {
      scope.options.data.keyWiseFieldsMap.filename.mappedField.templateOptions.label = "Prefix: ";

      var lv_date = moment();

      var lv_filename = "'products_" + lv_date.format(scope.model.name_pattern) + ".csv'";
      scope.options.data.keyWiseFieldsMap.help_text.mappedField.templateOptions.label = lv_text + lv_filename;

    }
    else {
      scope.options.data.keyWiseFieldsMap.help_text.mappedField.templateOptions.label = "";
      scope.options.data.keyWiseFieldsMap.filename.mappedField.templateOptions.label = "Filename: ";
    }

    on_path_change(value, element, scope);

  }

  function onFileListChange(params, ele, scope, callback_fn){
    let file_name = scope.model.file_list.split("/").slice(-1);
    let fileName = file_name[0];
    scope.options.data.parentController.origDsName = fileName;
    if (scope.model.data_pull_file == 'Pull next file based on name pattern'){
      onPatternChange(params, ele, scope, callback_fn);
    }
  }

  function onFolderListChange(params, ele, scope, callback_fn){
    if (scope.model.data_pull_file == 'Pull next file based on name pattern' && scope.model.folder_list.length > 0){
      onPatternChange(params, ele, scope, callback_fn);
    }
  }

  function onNextFilePullSelected(params, ele, scope, callback_fn){
    if (scope.model.data_pull_file == 'Pull next file based on name pattern'){
      onPatternChange(params, ele, scope, callback_fn);
    } else {
      scope.options.data.parentController.isDsConfigValid = true;
      scope.options.data.parentController.dsConfigValidationMessage = { error_message: ''};
      scope.model.name_pattern = "";
      scope.model.folder_list = "";
    }
  }

  function getStringBetween(str, start, end) {
    const result = str.match(new RegExp(start + "(.*)" + end));
    return result[1];
  }

  function getDatePattern(namePattern, date_formats){
    const extractingDateFormat = /\<[^<>]+\>/g;
    var date_pattern = '';
    let match = null;
    const datePatterns = [];
    do {
        match = extractingDateFormat.exec(namePattern);
        if(match) {
            var givenPattern = match[0];
            let pattern = givenPattern.replace('<', '').replace('>', '');
            pattern = pattern.toLowerCase();
            let formt = Object.keys(date_formats).includes(pattern);
            if (formt){
              datePatterns.push(givenPattern);
              date_pattern = date_pattern + givenPattern;
            }
        }
    } while (match);
    return datePatterns;
  }

  function getRegexForFindingPattern(fileName, prefix, suffix, date_formats, datePatterns, dateSeperators){
    var datePartOfFileame = fileName.replace(prefix, '').replace(suffix, '');
    var date_regex = '^';
    date_regex = date_regex + prefix;
    for (let i = 0; i < datePatterns.length; i++) {
        let pattern = datePatterns[i].replace('<', '').replace('>', '');
        pattern = pattern.toLowerCase();
        var compPattern = date_formats[pattern];
        
        if (Array.isArray(compPattern)){
            for (let x of compPattern) {
                var startIndex = -1;
                var stringLength = x.length
                if (datePartOfFileame.includes(x.toLowerCase())){
                    startIndex = datePartOfFileame.indexOf(x.toLowerCase());
                }
                if (datePartOfFileame.includes(x.toUpperCase())){
                    startIndex = datePartOfFileame.indexOf(x.toUpperCase());
                }
                if (datePartOfFileame.includes(x[0].toUpperCase() + x.slice(1))){
                    startIndex = datePartOfFileame.indexOf(x[0].toUpperCase() + x.slice(1));
                }
                if (startIndex > -1){
                    var patternValue = datePartOfFileame.substring(startIndex, startIndex + stringLength);
                    date_regex = date_regex + patternValue;
                    break;
                }
            }
        } else {
            date_regex = date_regex + compPattern;
        }
        if(i < datePatterns.length-1){
            date_regex = date_regex + dateSeperators[i];
        };
    };
    date_regex = date_regex + suffix + '$';
    return date_regex;
  }

  function onPatternChange(params, ele, scope, callback_fn){
      if (scope.model.file_list !== ""){
        var fileName = scope.options.data.parentController.origDsName;
        var namePattern = scope.model.name_pattern;
        scope.options.data.parentController.isDsConfigValid = false;

        const DATE_FORMATS = {
            "dd": '(0?[1-9]|[12][0-9]|3[01])',
            "mm": '(0?[1-9]|1[012])',
            "yy": '[0-9]{2}',
            "yyyy": '[0-9]{4}',
            "mon": ['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec'],
            "month": ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
            "w": '[0-6]{1}',
            "dy": ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
            "day": ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
            "hh": '(0[1-9]|1[0-2])',
            "hh12": '(0[1-9]|1[0-2])',
            "hh24": '(0[0-9]|1[0-9]|2[0-3])',
            "mi": '[0-5]{1}[0-9]{1}',
            "ss": '[0-5]{1}[0-9]{1}',
            "am": "am", 
            "pm": "pm",
        }

        var datePatterns = [];
        if (namePattern.length > 0) {
          datePatterns = getDatePattern(namePattern, DATE_FORMATS);
          if (datePatterns.length > 0){
              scope.model.error_message = "";
              let prefix = namePattern.substring(0, namePattern.indexOf(datePatterns[0]))
              let last_index = namePattern.split(datePatterns.slice(-1)[0]);
              let suffix = last_index.slice(-1)[0];
              
              var dateSeperators = [];
              if (datePatterns.length > 1){
                for(let i=0; i < (datePatterns.length - 1); i++){
                  let sep = getStringBetween(namePattern, datePatterns[i], datePatterns[i+1]);
                  dateSeperators.push(sep);
                }
              }
              let date_regex = ""
              date_regex = getRegexForFindingPattern(fileName, prefix, suffix, DATE_FORMATS, datePatterns, dateSeperators);
              let dateRegex = new RegExp(date_regex);
  
              if (dateRegex.test(fileName) == true){
                  scope.options.data.parentController.dsConfigValidationMessage = { error_message: ''};
                  if (scope.model.folder_list !== "") {
                    scope.options.data.parentController.isDsConfigValid = true;
                  }
              } else {
                  scope.options.data.parentController.dsConfigValidationMessage = { error_message: 'Pattern does not match with the selected file name'};
              }
          } else {
            scope.options.data.parentController.dsConfigValidationMessage = { error_message: 'Atleast one date format should be in the selected file and in pattern to detect next file'};
          }       
        }
        
      } else {
        scope.options.data.parentController.dsConfigValidationMessage = { error_message: 'Please select a file before specifying pattern'};
      }
  }
}
