
import {isEmpty} from 'lodash-es';

/**
 * @ngInject
 */
navigationService.$inject = ['$window', 'config', '$rootScope', '$location', 'UserWorkspace', 'analyticsService', 'c', 'DatasourceService', 'DataviewService', 'VuexStore'];
export function navigationService($window, config, $rootScope, $location, UserWorkspace, analyticsService, c, DatasourceService, DataviewService, $store) {
  var service = {
    open_dataview: open_dataview,
    open_first_dataview: open_first_dataview,
    open_dataview_by_id: open_dataview_by_id,
    open_first_dataview_by_ds_id: open_first_dataview_by_ds_id,
    go_to_landing: go_to_landing,
    openSettings: openSettings,
    populate_breadcrumbs: populate_breadcrumbs,
    openExpiredWorkspacePage: openExpiredWorkspacePage,
    openFirstProjectView: openFirstProjectView
  };

  function open_first_dataview(ds, current_ws?) {
    //$state.go(config.states.tabs.dataviews, {dataviewId: Object.keys(ds.dataviews)[0]});
    open_dataview(ds.dataviews[Object.keys(ds.dataviews)[0]], current_ws);
  }

  function openFirstProjectView(reload=false) {
    const currentWorkspace = $store.getters.getCurrentWorkspace;
    if(isEmpty(currentWorkspace)) {
      for (let index = 0; index < $store.state.workspaces.length; index++) {
        const workspace = $store.state.workspaces[index];
        let project = workspace.projects.filter(project => project.subscribed)[0]
        if ($store.getters.isOnboardingDataLibraryActive && project) {
          window.location.href = `${window.location.origin}/n/#/onboarding`
          return
        } else if(project) {
          window.location.href = `${window.location.origin}/#/workspaces/${workspace.id}/projects/${project.id}`
          return
        } else window.location.href = `${window.location.origin}/#/workspaces/${$store.state.workspaces[0].id}`
      }
    } else {
      const workspace = $store.state.workspaces.find(workspace => workspace.id == currentWorkspace.id);
      let project = workspace?.projects.filter(project => project.subscribed)[0]
      if ($store.getters.isOnboardingDataLibraryActive && project) {
        window.location.href = `${window.location.origin}/n/#/onboarding`
        return
      } else if(project) {
        window.location.href = `${window.location.origin}/#/workspaces/${workspace.id}/projects/${project.id}`
        return
      } else window.location.href = `${window.location.origin}/#/workspaces/${parseInt(currentWorkspace.id)}`
    }
    if (reload) {
      window.location.reload()
    }
  }

  function open_first_dataview_by_ds_id(ds_id, current_ws) {
    //$state.go(config.states.tabs.dataviews, {dataviewId: Object.keys(ds.dataviews)[0]});
    var ds = DatasourceService.get_by_id(ds_id);
    if (ds) {
      open_dataview(ds.dataviews[Object.keys(ds.dataviews)[0]], current_ws);
    }
  }

  function open_dataview_by_id(dataview_id, current_ws) {
      let dataview = DataviewService.get_by_id(dataview_id);
      if (dataview) {
          open_dataview(dataview, current_ws);
      }
  }
  function open_dataview(ws, current_ws) {
    analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.openDataView, {eventOrigin: "landingPage.previewPanel"});
    if (current_ws) {
      if (current_ws.datasource.dataviews.hasOwnProperty(ws.id)) {
        let url = `/workspaces/${$store.state.workspaceId}/projects/${parseInt($store.state.projectId)}/dataviews/${ws.id}`;
        $location.url(url);
        return;
      }
    }
    var ws_url = '#' +`/workspaces/${$store.state.workspaceId}/projects/${parseInt($store.state.projectId)}/dataviews/${ws.id}`;
    var win_name = undefined;
    if ($window.bowser.webkit || $window.bowser.blink) {
      win_name = config.windowNames.datasource + ws.datasource.id;
    }
    $window.open(ws_url, win_name).focus();
  }

  function go_to_landing(inCurrentTab, reload, breadcrumbs) {
    analyticsService.userEventTrack(c.userEvents.dataviewEvents.goToLandingPage, {eventOrigin: "dataview"});
    if (!inCurrentTab) {
      var win_name = undefined;
      if ($window.bowser.webkit || $window.bowser.blink) {
        win_name = config.windowNames.landing;
      }
      let desiredUrl;
      if (window.location.hash.includes('dataviews')) {
        desiredUrl = window.location.hash.split('/dataviews')[0]
      } else {
        desiredUrl = '#' + config.url.landing;
      }

      /* If there are no label resource ids associated with the dataset, it is present in the data library root and
       when there is a label resource id present, the id is added to the url */
      if(breadcrumbs.datasource && !isEmpty(breadcrumbs.datasource.item.label_resource_ids)){
        desiredUrl = desiredUrl + '/folders' +'/'+breadcrumbs.datasource.item.label_resource_ids[0];
      }
      $window.open(desiredUrl, win_name).focus();
    } else {
      let desiredUrl;
      if (window.location.hash.includes('dataviews')) {
        desiredUrl = window.location.hash.split('/dataviews')[0]
      } else {
        desiredUrl = '#' + config.url.landing;
      }
      window.location.href = `${window.location.origin}/${desiredUrl}`
    }
    if(reload) {
      window.location.reload()
    }
  }

  function populate_breadcrumbs() {
    $rootScope.$broadcast('populate_breadcrumbs');
  }



  function openSettings(section){
    analyticsService.userEventTrack(c.userEvents.dataviewEvents.goToSettingsPage, {eventOrigin: "topNavigationBar"});
    let url = '/#settings';
    if(section){
      url = url + '?section=' + section;
    }
    var win_name = 'mammoth_settings_page_' + UserWorkspace.workspace.id;
    var w = $window.open(url, win_name);
    w.focus();
  }

 

  function openExpiredWorkspacePage() {
    var url = '#/expired';
    var w = $window.open(url, '_self');  
    w.focus();
  }

  return service;
}
