
expiredController.$inject = ['appMetaInfo', 'Auth']

export function expiredController(appMetaInfo, Auth) {
  Auth.check_token().then(() => init())

  function init() {
    appMetaInfo.pageLoaded()
  }

}
