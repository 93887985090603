import * as Vue from 'vue'
import * as Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import localForage from 'localforage';

import adminStore from '../admin/admin.store.js'
import authStore from '../auth/auth.store.js'
import auxNavbarStore from '../auxStores/aux-navbar.store.js'
import cacheStore from '../cache/cache.store.js'
import publishStore from '../modules/publish/viewer/publish.store.js'
import mmFrontendStore from '@/plugins/store.js'

Vue.use(Vuex)

mmFrontendStore.registerModule('admin', adminStore)
mmFrontendStore.registerModule('adminAuth', authStore)
mmFrontendStore.registerModule('auxNavbar', auxNavbarStore)
mmFrontendStore.registerModule('publish', publishStore)
mmFrontendStore.registerModule('cache', cacheStore)

const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
  reducer: (state) => ({
    cache: {
      resources: state.cache.resources,
    },
  }),
})

vuexLocal.plugin(mmFrontendStore)
export default mmFrontendStore
