import * as angular from 'angular';
import * as _ from 'lodash-es';

/**
 * @ngInject
 */
joinSourceGridsController.$inject = ['DataviewService', 'config', '$timeout', 'historicDataviewData', 'FutureService','c'];
export function joinSourceGridsController(DataviewService, config, $timeout, historicDataviewData, FutureService, c) {
  this.$onInit = function () {
    var sg = this,
      options = sg.taskHelper.options,
      _prevRightWsId;
    sg.showSourceDataviewGrid = false;
    var _prevJoinMaps = [], _prevSourceCols = [], _prevRightCols = [];
    var highlightClasses = ["join-highlight-1", "join-highlight-2", "join-highlight-3", "join-highlight-4", "join-highlight-5"];

    _prevJoinMaps = _.cloneDeep(options.joinMaps);

    sg.primaryURL = config.apiUrl + '/dataviews/' + options.sourceDataview.dataviewId + '/data';

    if (!isNaN(options.sourceDataview.sequence_number)) {
      sg.primaryURL += "?" + c.sequenceNumber + "=" + options.sourceDataview.sequence_number;
      historicDataviewData.get(options.sourceDataview.dataviewId, options.sourceDataview.sequence_number).then(function (data) {
        FutureService.track(data.future_id, data_tracker);

        function data_tracker(future) {
          let response = future.response;
          if (future.status == "processing") {
            return;
          }
          if (future.status == "success") {
            // Show grid only when the data is available
            sg.showSourceDataviewGrid = true;
            sg.primaryMetadata = response.metadata;
            init();
          }
          if (future.status == "error") {
            // Do not show grid when the data not available
            sg.showSourceDataviewGrid = false;
          }
        }
      });
    } else {
      var primary_dataview = DataviewService.get_by_id(options.sourceDataview.dataviewId);
      sg.primaryMetadata = primary_dataview.metadata;
      sg.showSourceDataviewGrid = true
      init();
    }
    sg.primaryDataview = DataviewService.get_by_id(options.sourceDataview.dataviewId);

    function init() {
      sg.sourceGridOptions = {
        showColumnTypeIcons: true,
        rowHeight: 30
      };
      sg.rightGridOptions = {
        showColumnTypeIcons: true,
        rowHeight: 30
      };


      sg.sourceGridInstance = {};
      sg.rightGridInstance = {};
      sg.rightDataview = undefined;

      sg.taskHelper.onRefresh('sourceGridController', _onRefresh);
      _onRefresh();
    }

    function _onRefresh() {
      if (_prevRightWsId != options.rightDataview.dataviewId) {
        _prevRightWsId = options.rightDataview.dataviewId;
        updateRightDataview();
      }
      if (!angular.equals(_prevJoinMaps, options.joinMaps)) {
        _prevJoinMaps = _.cloneDeep(options.joinMaps);
        updateColumnHighlights();
      }
    }

    function updateRightDataview() {
      $timeout(function () {
        if (options.rightDataview.dataviewId) {
          var right_dataview = DataviewService.get_by_id(options.rightDataview.dataviewId);
          if (!right_dataview) {
            $timeout(updateRightDataview, 500);
            return;
          }
          sg.rightDataview = right_dataview;
          if (!right_dataview.metadata) {
            right_dataview.get_data().then(updateRightDataview);
          } else {
            sg.rightMetadata = right_dataview.metadata;
          }
          sg.rightURL = config.apiUrl + '/dataviews/' + options.rightDataview.dataviewId + '/data';
          $timeout(updateColumnHighlights, 500);
        }
      });
    }


    function updateColumnHighlights() {
      if (!sg.sourceGridInstance.gridInstance || !sg.rightGridInstance.gridInstance) {
        return;
      }
      sg.sourceGridInstance.gridInstance &&
      sg.sourceGridInstance.gridInstance.plugins.columnHighlighter.unhighlightByClasses(highlightClasses, true);
      sg.rightGridInstance.gridInstance &&
      sg.rightGridInstance.gridInstance.plugins.columnHighlighter.unhighlightByClasses(highlightClasses, true);
      var sourceSpec = {}, rightSpec = {}, hClassCount = highlightClasses.length;
      var _currSourceCols = [], _currRightCols = [];
      angular.forEach(options.joinMaps, function (jMap, i) {
        if (jMap.left && jMap.left.internal_name) {
          sourceSpec[jMap.left.internal_name] = [highlightClasses[i % hClassCount]];
          _currSourceCols.push(jMap.left.internal_name)
        }
        if (jMap.right && jMap.right.internal_name) {
          rightSpec[jMap.right.internal_name] = [highlightClasses[i % hClassCount]];
          _currRightCols.push(jMap.right.internal_name);
        }
      });
      sg.sourceGridInstance.gridInstance.plugins.columnHighlighter.highlight(sourceSpec);
      sg.rightGridInstance.gridInstance.plugins.columnHighlighter.highlight(rightSpec);
      var _newSourceCols = _.difference(_currSourceCols, _prevSourceCols);
      var _newRightCols = _.difference(_currRightCols, _prevRightCols);
      _prevSourceCols = _currSourceCols;
      _prevRightCols = _currRightCols;
      if (_newSourceCols.length) {
        sg.sourceGridInstance.gridInstance.plugins.scrollToColumn(_newSourceCols[0]);
      }
      if (_newRightCols.length) {
        sg.rightGridInstance.gridInstance.plugins.scrollToColumn(_newRightCols[0]);
      }
    }

  }
}
