import * as angular from 'angular';
/**
 * @ngInject
 */
dateInputFormat.$inject = ['config'];
export function dateInputFormat(config) {
  return {
    templateUrl: config.templates.dateInputFormat,
    controller: config.controllers.dateInputFormat,
    controllerAs: 'df',
    bindToController: true,
    scope: {
      inputFormat: "=",
      onSubmit: "=",
      displayText: "=",
      appendToBody: '=',
      sampleValues: '=',
      sampleValuesLoading: '=',
      autoFind: '='
    },
    link: function(scope, element, attrs){
      scope.$watch('df.sampleValuesLoading', function (v) {
        if(!v){
          scope.df.applyOnSamples();
        }
      });
      scope.df.menuPosition = attrs.menuPosition;
      scope.formatHelperHtmlDLink = location.protocol + '//' + location.host + '/' + config.templates.dateInputFormatDoc;
    }
  };
}



/**
 * @ngInject
 */
export function adjustDateInputDropdown(){
    return {
      link: function(scope, element, attrs){
        function isBottomHidden(){
          return ($(element).offset().top + $(element).outerHeight() + 40)  >= $(window).height();
        }

        function fixBottom(){
          while(isBottomHidden()){
            let newtop = $(element).offset().top - 10;
            $(element).css('top', newtop + 'px');
          }
        }
        scope.df.onToggleCb('fromDirective', fixBottom);
      }

    }
}

