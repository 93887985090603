

/**
 * @ngInject
 */
tabService.$inject = ['$state'];
export function tabService($state) {
  var service = {

  };

  return service;
}

// function tabService($controller, $rootScope, config) {
//  var service = {
//    'get_controller': get_controller
//  };
//
//  // tab registry that holds the controller instances of currently opened tabs
//  var tab_registry = {};
//
//  // register tab handlers for different states, each handler either creates a new controller instance and returns it
//  // or returns an existing instance if the tab is already open
//  var controller_handlers = {};
//  populate_handlers_and_registry();
//
//  return service;
//
//  ////
//
//  /**
//   * Tab class, represents an instance of the class
//   * @constructor
//   */
//  function Tab () {
//    var tab = this;
//    // holds the controller instance
//    tab.controller = null;
//  }
//
//  function get_controller(state, state_params) {
//    return controller_handlers[state](state_params).controller;
//  }
//
//  function populate_handlers_and_registry() {
//    // landing
//    tab_registry[config.states.landing] = null;
//    controller_handlers[config.states.landing] = _landing_handler;
//
//    // Dataviews
//    tab_registry[config.states.tabs.dataviews] = {};
//    controller_handlers[config.states.tabs.dataviews] = _dataview_handler;
//  }
//
//  function _get_new_tab_instance_by_controller(controller_name) {
//    // create a controller instance
//    var controller_prototype = $controller(controller_name, {$scope: $rootScope.$new()});
//    var fn = new Function;  // constructor class which will be used to generate the final controller
//    fn.prototype = controller_prototype;
//
//    // create new tab instance
//    var tab = new Tab();
//    tab.controller = fn;
//    return tab;
//  }
//
//  function _dataview_handler(state_params) {
//    var ws_registry = tab_registry[config.states.tabs.dataviews];
//    if (!ws_registry.hasOwnProperty(state_params.dataviewId)) {
//      ws_registry[state_params.dataviewId] = _get_new_tab_instance_by_controller(config.controllers.dataview);
//    }
//    return ws_registry[state_params.dataviewId];
//  }
//
//  function _landing_handler() {
//    if (!tab_registry[config.states.landing]) {
//      tab_registry[config.states.landing] = _get_new_tab_instance_by_controller(
//        config.controllers.landing
//      );
//    }
//    return tab_registry[config.states.landing];
//  }
//}

