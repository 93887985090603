import * as angular from 'angular';


/**
 * @ngInject
 * Dataview service
 */

DataviewLayoutService.$inject = ['DataviewService', 'resources', 'navigationService', '$window', 'config', 'DatasourceService',
                                'Notification', '$timeout', 'analyticsService', 'c', 'reorderFactory', 'VuexStore'];
export function DataviewLayoutService(DataviewService, resources, navigationService, $window, config, DatasourceService,
                                Notification, $timeout, analyticsService, c,reorderFactory, VuexStore) {
  var dataviewSwitchListeners = {}; // dataviewId: event to be fired on switch
  var service = {
    open_dataview: open_dataview,
    active_ws: null,
    active_ds: null,
    inited_dataviews: {},
    showDataviewSwitchLoader: false,
    dataviewSwitching: dataviewSwitching,
    dataviewSwitched: dataviewSwitched,
    dataviewSwitchListeners: dataviewSwitchListeners,
    unset_active_ws: unset_active_ws
  };

  DataviewService.on_delete('layoutServiceHandleDelete', handleDelete);
  DatasourceService.on_list_update('update_inited_wss_list', _on_ws_list_updates);
  return service;

  ////////
  function open_dataview(ws_id) {
    function _open(ws_id) {
      var ws = DataviewService.get_by_id(ws_id);
      if (!ws) {
        Notification.error("The View could not be found");
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.goToLandingPage, {eventOrigin: "dataviewURL.broken"});
        navigationService.go_to_landing(true);
        return;
      }
      service.dataviewSwitching();

      /*
      when switching to another view, reset reorderFactory so that the previously set reorderFactory is not used
      (otherwise it causes the taskReorderPending to be True and shows continous loader)
      and update if it has new view's dependency dictionary
      */
      reorderFactory._order = []
      reorderFactory._dep = {}
      if(ws.rf){
        reorderFactory._order = ws.rf._order;
        reorderFactory._dep = ws.rf._dep;
      }

      /*
      this has be to outside if because we need ws_id to ensure incorrect dataview's dependency dict doesnt save in
      reorderFactory
       */
      reorderFactory.ws_id = ws.id;

      service.active_ws = ws;
      VuexStore.commit("dataEditor/setViewId", ws.id)

      service.active_ds = ws.ds_id;
      service.inited_dataviews[ws_id] = ws;
      navigationService.populate_breadcrumbs();
      if (dataviewSwitchListeners.hasOwnProperty(ws_id) && dataviewSwitchListeners[ws_id].hasOwnProperty('fire_event')) {
        dataviewSwitchListeners[ws_id].fire_event();
      }
      if (!$window.name && $window.bowser.webkit) {
        $window.name = config.windowNames.datasource + ws.datasource.id;
      }
    }
    // This is to fetch the latest resources before opening the view.
    // Fixes issue with creating New view and duplicate view
    resources.update().then(function () {
      _open(ws_id);
    });
  }

  function _on_ws_list_updates() {
    if (Object.keys(service.inited_dataviews).length) {
      var ds = DatasourceService.list[service.active_ds];
      if (!ds) {
        // Notification.error("The Datasource and its Views could not be found");
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.goToLandingPage, {eventOrigin: "dataview.mammothLogo"});
        navigationService.go_to_landing(true, true);
        return;
      }
      var wsList = Object.keys(ds.dataviews);
      angular.forEach(service.inited_dataviews, function (ws, ws_id) {
        if (wsList.indexOf(ws_id) == -1) {
          delete service.inited_dataviews[ws_id];
        }
      });
    }
  }

  function unset_active_ws(ws_id) {
    if (service.active_ws && service.active_ws.id === ws_id) {
      service.active_ws = null;
    }
  }
  function handleDelete(wsId) {
    delete service.inited_dataviews[wsId];
  }

  function dataviewSwitching() {
    service.showDataviewSwitchLoader = true;
  }
  function dataviewSwitched() {
    service.showDataviewSwitchLoader = false;
  }

}
