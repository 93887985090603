import * as angular from 'angular';

/**
 * @ngInject
 */
wkspPreviewService.$inject = ['$resource', 'config', 'dataviewConfig', '$uibModal', '$rootScope'];
export function wkspPreviewService($resource, config, dataviewConfig, $uibModal, $rootScope){
  var service = {
    enter: enter,
    exit: exit,
    showConfirmation: showConfirmation,
    setAlert: setAlert
  };
  var previewModeResource = $resource(config.api.dataviewPreviewMode);
  return service;

  function enter(dataviewId, limit, param?){
    if(!limit){
      limit = dataviewConfig.rowLimitForSampleWarning;
    }
    var data = {limit: limit};
    if(param){
      data['add_task_param'] = param;
    }

    return previewModeResource.save({dataviewId: dataviewId}, data).$promise;
  }

  function exit(dataviewId){
    return previewModeResource.delete({dataviewId: dataviewId}).$promise;
  }

  function showConfirmation(exitPreviewMode, userPrompted?, actionPresent?, information?){
    var scp = $rootScope.$new();
    angular.merge(scp, {
      exitPreviewMode: exitPreviewMode,
      rowLimit: dataviewConfig.rowLimitForSample,
      userPrompted: userPrompted,
      isActionPresent: actionPresent,
      info: information
    });
    return $uibModal.open({
      templateUrl: config.templates.sampleWarningModal,
      windowClass: 'modal-size-medium modal-preview',
      scope: scp
    }).result;
  }

  function setAlert(dataviewId, params){
    var alertResource = $resource(config.api.dataviewsAlerts);
    return alertResource.save({ws_id: dataviewId}, params).$promise;
  }
}

/**
 * @ngInject
 */
wkspTaskStatusFactory.$inject = ['$resource', 'config', '$timeout', '$q'];
export function wkspTaskStatusFactory($resource, config, $timeout, $q){
  var registry = {};
  var service = {
    get: getService
  };
  var wsStatusRes = $resource(config.api.dataviewTaskStatuses);
  return service;

  function getService(dataviewId){
    if (!registry.hasOwnProperty(dataviewId)){
      registry[dataviewId] = new DataviewTaskStatus(dataviewId, false);
    }
    return registry[dataviewId];
  }


  function DataviewTaskStatus(dataviewId, do_update=true){
    var self = this;
    self.statuses = {};
    self.update = update;
    self.doPolling = false;
    self.setPolling = function(value){
      self.doPolling = value;
      self.update();
    };
    if(do_update){
      self.update();
    }

    function update(){
      var deferred = $q.defer();
      wsStatusRes.get({dataviewId: dataviewId}).$promise.then(function (d) {
        self.statuses = d.statuses;
        deferred.resolve();
        if (self.doPolling) {
          $timeout(update, 1000);
        }
      });
      return deferred.promise;
    }
  }

}
