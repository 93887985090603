import * as angular from 'angular';

/**
 * @ngInject
 * */
export function valAtLeastOneDateComponent() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valAtLeastOneDateComponent(scope, elem, attrs, ctrl) {
      ctrl.$validators.valAtLeastOneDateComponent = function () {
        var vals = [];
        if (scope.tvm.manager) {
          angular.forEach(scope.tvm.manager.param.INCREMENT_DATE.DELTA, function (val) {
            if (val) {
              vals.push(val);
            }
          });
        }
        return vals.length > 0;
      };

      scope.$watch('tvm.manager.param.INCREMENT_DATE.DELTA', function () {
        ctrl.$validate();
      }, true);
    }
  };
}
