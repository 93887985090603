import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

export function MappedFormHelperService() {
  var self = this;

  var _col_classes = {
    '100': 'col-12',
    '91.66': 'col-11',
    '83.33': 'col-10',
    '75': 'col-9',
    '75.00': 'col-9',
    '66.66': 'col-8',
    '58.33': 'col-7',
    '50': 'col-6',
    '50.00': 'col-6',
    '41.66': 'col-5',
    '33.33': 'col-4',
    '25': 'col-3',
    '25.00': 'col-3',
    '16.66': 'col-2',
    '8.33': 'col-1'
  };

  self.applyLayoutToField = applyLayoutToField;
  self.updateField = updateField;

  function applyLayoutToField(field, layoutObject) {

    if (field.type === 'button' || field.type == "label") {
      field.templateOptions['classes'] = layoutObject.classes;
    }
    else {
      field['className'] = layoutObject.classes;
    }
    field['className'] += ' field-' + layoutObject.key;
    if (layoutObject.width) {
      if (layoutObject.width.unit === "%") {
        // ToDO : doing % only for now, change it to accept integers as well,
        // also check if it is a valid width% in column spec
        field['className'] = _col_classes[layoutObject.width.value] + ' ' + field['className'];
      }
      else {
        field['className'] = _col_classes["100"] + ' ' + field['className'];
      }
    }

    if (layoutObject.parentLength && layoutObject.width && layoutObject.width.unit == "share") {
      // ToDO : doing % only for now, change it to accept integers as well,
      // also check if it is a valid width% in column spec
      var rowShare = '' + ((layoutObject.width.value / layoutObject.parentLength) * 100.0).toFixed(2);
      field['className'] = _col_classes[rowShare] + ' ' + field['className'];
    }
  }

  function updateField(fieldMap) {
    _updateFieldSpecAndAttributes(fieldMap.mappedField, fieldMap.jsonSpec, fieldMap.ux.attributes);
  }

  function _updateFieldSpecAndAttributes(field, fieldSpec, fieldAttributes) {

    /*
    * Properties which are assumed not to be updated
    * have been commented out. This can be undone as
    * per requirements.
    * */

    field.defaultValue = fieldSpec.default_value || '';
    field.templateOptions.placeholder = fieldSpec.placeholder || fieldSpec.short_desc || "";
    field.templateOptions.required = fieldSpec.required;
    field.templateOptions.label = fieldSpec.label;

    /*if (fieldSpec.dependent_on !== null) {
      dependencies_map[fieldSpec.key] = fieldSpec.dependent_on;
    }*/

    field.data.server_validate = fieldSpec.server_validate;
    field.data.long_desc = fieldSpec.long_desc;
    field.data.server_auto_suggest = fieldSpec.server_auto_suggest;
    field.data.server_validate = fieldSpec.server_validate;
    field.hideExpression = fieldAttributes.hidden_field || "";

    switch (fieldSpec.type) {
      case 'TEXT_BOX':
        {
          break;
        }
      case 'NUMERIC_TEXT_BOX':
        {
          break;
        }
      case 'EMAIL':
        {
          break;
        }
      case 'PASSWORD':
        {
          break;
        }
      case 'PARAGRAPH_TEXT':
        {
          // ToDo :  if it should be non expandable?
          // if (fieldAttributes.hasOwnProperty('onChange')) {
          //   field.templateOptions.onChange = _getCustomMethod(fieldAttributes.onChange);
          // }
          field.templateOptions.rows = fieldAttributes.rows || 8;
          break;
        }
      case 'URL':
        {
          break;
        }
      case 'CHECKBOX':
        { // Or Bool
          break;
        }
      case 'MULTI_SELECT_LIST':
        {
          break;
        }
      case 'SEARCHABLE_LIST':
        {
          field.templateOptions.options = fieldAttributes.items;
          break;
        }
      case 'SINGLE_SELECT_LIST':
        {
          field.ngModelAttrs = {
            size: {
              attribute: 'size'
            }
          };

          field.templateOptions.size = fieldAttributes.size || 0;
          field.templateOptions.options = fieldAttributes.items;
          // field.templateOptions.onChange = _getCustomMethod(fieldAttributes.onChange);
          break;
        }
      case 'DISPLAY_LIST':
        {
          field.ngModelAttrs = {
            size: {
              attribute: 'size'
            },
            disabled: {}
          };

          field.controller = /*@ngInject*/['$scope', function ($scope) {

          }];
          field.templateOptions.size = fieldAttributes.size;
          field.templateOptions.options = fieldAttributes.items;
          break;
        }

      case 'GRID':
        {
          field.templateOptions.rows = fieldAttributes.rows;
          field.templateOptions.metadata = fieldAttributes.metadata;
          break;
        }

      case 'BUTTON':
        {
          field.templateOptions.text = fieldSpec.placeholder;
          // field.templateOptions.onClickParams = fieldAttributes.onClickParams;
          // field.templateOptions.onClick = _getCustomMethod(fieldAttributes.onClick);
          field.templateOptions.enabled = fieldAttributes.enabled;
          break;
        }

      case 'LABEL':
        {
          field.templateOptions.label = fieldSpec.label;
          field.templateOptions.enabled = fieldAttributes.enabled;
          field.templateOptions.classes = fieldAttributes.classes;
          break;
        }
    }

    /*function _getCustomMethod(methodName) {
      if (moduleFunctions && moduleFunctions.hasOwnProperty(methodName)) {
        return moduleFunctions[methodName];
      }
      switch (methodName) {
        case 'validateDsConfig':
          return Singularity.validateDsConfig;
        case 'augmentDsConfig':
          return Singularity.augmentDsConfigSpecParams;
        case 'reInitializeDsConfigForm':
          return Singularity.triggerDsConfigFormReIntialization;
        default:
          // statements as string, not a function
          return methodName;
      }
    }*/

  }
}

