<template>
  <div class="mm-search-bar">
    <mm-menu v-model="isMenuOpen" :closeOnContentClick="false" menu-class="mm-search-bar--mm-menu">
      <template #activator>
        <div
          class="search-box"
          :class="{ 'has-options': isMenuOpen, 'active-search': isActive || isMenuOpen || filter }"
          tabindex="0"
        >
          <div class="icon-container">
            <mm-icon name="search" class="search-icon" />
          </div>
          <input
            v-model="filter"
            ref="searchInput"
            type="text"
            :placeholder="$t('dictionary.search')"
            class="search-input"
            @input="onSearchInput"
            @focus="isActive = true"
            @blur="onBlur"
          />
          <button v-if="filter" class="align-items-end m-r-3" @click="onClearFilter">
            {{ $t('dictionary.clear') }}
          </button>
        </div>
      </template>
      <template #content>
        <ul v-if="computedFilteredItems.length" class="bg-light p-a-1 list-group">
          <li
            v-for="(option, index) in computedFilteredItems"
            :key="index"
            class="d-flex flex-row p-x-3 p-y-2 list-item justify-content-between m-y-1"
            :class="{ 'bg-light-green': activeIndex === index }"
            @click="onSelectOption(option)"
            @mousedown="onSelectOption(option)"
          >
            <div class="d-flex">
              <div class="icon-container">
                <mm-icon :name="option.icon" />
              </div>
              <p v-html="highlightMatches(option.label)" class="m-b-0 m-x-2 option-text" />
              <mm-tooltip v-if="option.fullPath" :label="option.fullPath">
                <div class="d-flex justify-content-center align-items-center p-x-1">
                  <span class="text-muted path-text">
                    {{ $t('search_bar.in', { path: ellipseMiddle(option.fullPath) }) }}
                  </span>
                </div>
              </mm-tooltip>
            </div>
            <button class="action-text m-x-1" @click="onSelectOption(option)" @mousedown="onSelectOption(option)">
              {{ option.actionText }}
            </button>
          </li>
        </ul>
        <div v-else class="p-a-4">
          <p class="no-options-text">{{ $t('search_bar.no_results') }}</p>
        </div>
      </template>
    </mm-menu>
  </div>
</template>

<script>
export default {
  name: 'search-bar',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    isMenuOpen: false,
    isActive: false,
    filter: '',
    activeIndex: null
  }),
  computed: {
    computedFilteredItems() {
      return this.items.filter((row) => {
        const name = row.label.toLowerCase()
        const searchTerm = this.filter.toLowerCase().trim()
        return name.includes(searchTerm)
      })
    }
  },
  mounted() {
    // to handle move events on search options
    document.addEventListener('mouseover', this.onMouseEvent)
    document.addEventListener('onmouseleave', this.onMouseEvent)
    // handle keyboard navigation events
    document.addEventListener('keydown', this.onSearchBarFocus)
  },
  methods: {
    onClearFilter() {
      this.filter = ''
      this.filterResources()
    },
    onMouseEvent() {
      this.activeIndex = null
    },
    onBlur() {
      this.isMenuOpen = false
      this.isActive = false
      this.onClearFilter()
    },
    onSearchBarFocus(e) {
      switch (e.key) {
        case 'f':
          if (e.ctrlKey || e.metaKey) {
            e.preventDefault() // prevent "Inbuild search" from getting triggered.
            this.$refs.searchInput?.focus()
          }
          break
        case 'ArrowDown':
          // This is the handle arrow key functionality in dropdown.
          if (this.activeIndex === null || this.activeIndex >= this.computedFilteredItems.length - 1)
            this.activeIndex = 0
          else this.activeIndex++
          break
        case 'ArrowUp':
          if (this.activeIndex > 0) this.activeIndex--
          else this.activeIndex = this.computedFilteredItems.length - 1
          break
        case 'Enter':
          e.preventDefault()
          this.filterResources()
          break
        case 'Escape':
          this.filter = ''
          this.isMenuOpen = false
          this.filterResources()
      }
    },
    onSearchInput() {
      if (!this.filter) this.isMenuOpen = false

      // Add timeout so that search-box has time to expand
      setTimeout(() => {
        if (this.filter) this.isMenuOpen = true
      }, 500)
    },
    filterResources() {
      this.isMenuOpen = false

      if (this.activeIndex !== null) {
        const value = this.computedFilteredItems[this.activeIndex]
        this.$emit('selected', value)
        this.filter = value.label
      } else this.$emit('filter', this.filter)
    },
    onSelectOption(value) {
      this.isMenuOpen = false
      this.$emit('selected', value)
    },
    /**
     * To highlight the matching text in dataset, view and folder
     * @param {html} text highlighted text based on the match
     */
    highlightMatches(text) {
      const matchExists = text.toLowerCase().includes(this.filter.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(this.filter, 'ig')
      return text.replace(re, (matchedText) => `<strong>${matchedText}</strong>`)
    },
    //To ellipse text in between in case of long string
    ellipseMiddle(str) {
      if (str.length > 35) {
        const middle = str.substr(20, str.length - 10)
        return (
          str.substr(0, 20) +
          '../'.repeat(middle.split('/').length - 1) +
          '..' +
          str.substr(str.length - 10, str.length)
        )
      }
      return str
    }
  },
  beforeDestroy() {
    document.removeEventListener('mouseover', this.onMouseEvent)
    document.removeEventListener('onmouseleave', this.onMouseEvent)
    document.removeEventListener('keydown', this.onMouseEvent)
  }
}
</script>

<style lang="scss">
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';
@import '@mammoth_developer/mm-storybook/src/styles/shadows.scss';

.mm-search-bar--mm-menu {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;

  .mm-menu--container {
    margin-top: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;

    .list-group {
      width: 640px;
      max-height: 500px;
      overflow: scroll;

      .list-item {
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: var(--mm-color--p200) !important;
        }

        &.bg-light-green {
          background-color: var(--mm-color--p200) !important;
        }

        .action-text {
          color: var(--mm-color--n300);
          white-space: nowrap;
        }

        .option-text {
          color: var(--mm-color--n900);
        }

        .path-text {
          white-space: nowrap;
          vertical-align: middle;
        }
      }
    }

    .no-options-text {
      color: var(--mm-color--n300);
    }
  }
}

.mm-search-bar {
  .search-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--mm-color--n70);
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 24px;
    padding: 10px;
    width: 420px;
    transition: width 0.5s;
    background-color: var(--mm-color--p700);
    z-index: 1;

    &.has-options {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    &.active-search {
      @extend .mm-shadow--heavy-low;
      background: var(--mm-color--n10) !important;
      width: 640px;
      border: none !important;
      border-radius: 12px;
    }

    &:not(.active-search) {
      .search-input {
        &::placeholder {
          color: var(--mm-color--n60);
        }
      }

      .search-icon {
        --mm-icon--color: var(--mm-color--n60) !important;
        --mm-icon--color-hover: var(--mm-color--n60) !important;
      }
    }

    /* input */
    .search-input {
      @extend .mm-text--body-regular;
      outline: none;
      border: none !important;
      background: none !important;
      box-shadow: none !important;
      width: 100%;
      padding-left: 4px;
      padding-right: 4px;
      float: left;
      transition: 0.3s;
    }
  }
}

.mm-search-bar--mm-menu,
.mm-search-bar {
  .icon-container {
    height: 16px;
    width: 16px;
  }
}
</style>
