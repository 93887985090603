<template>
  <mm-modal v-model="computedIsModalOpen" :title="$t('project.manage.delete.title')" width="480">
    <div class="d-flex">
      <mm-icon class="m-r-2" name="information" style="min-width: 16px"></mm-icon>
      <p class="mm-text--caption-regular">{{ $t('project.manage.delete.info', { name: computedProjectName }) }}</p>
    </div>

    <p v-html="$t('project.manage.delete.info_confirmation')" class="mm-text--body-regular"></p>

    <mm-text-input
      v-model="deleteConfirmation"
      style="min-width: 400px"
      ref="deleteConfirmationInput"
      :placeholder="$t('global.dictionary.delete').toUpperCase()"
      hide-details
      @keyup.enter="deleteProject"
    ></mm-text-input>

    <template #actions>
      <mm-button :label="$t('global.dictionary.cancel')" objective="secondary" @click="computedIsModalOpen = false" />
      <mm-button
        class="m-l-3"
        :label="$t('global.dictionary.delete')"
        objective="destructive"
        :loading="isLoading"
        :disabled="deleteConfirmation !== $t('global.dictionary.delete').toUpperCase()"
        @click="deleteProject"
      />
    </template>
  </mm-modal>
</template>

<script>
import projectApi from '@/modules/project/api/project.api.js'
import { REQUESTS_STATUS } from '@/constants'

export default {
  name: 'project-modal-delete',
  props: {
    value: Boolean,
    projectId: Number
  },
  data: () => ({
    deleteConfirmation: '',
    isLoading: false
  }),
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    computedProjectName() {
      return this.$store.getters.getProjectById(this.projectId).name
    }
  },
  methods: {
    async deleteProject() {
      if (this.deleteConfirmation !== this.$t('global.dictionary.delete').toUpperCase()) return
      this.isLoading = true
      const response = await projectApi.deleteProject(this.projectId)
      if (response.data.STATUS === REQUESTS_STATUS.SUCCESS_1) {
        this.$store.commit('deleteProject', this.projectId)
        this.$store.dispatch('getWorkspaces', { invalidateCache: true })
        this.$store.dispatch('getCurrentUser', { invalidateCache: true })
        // Note: Timeout is used to prevent page refresh before the cache is cleaned up. Once ticket MVP-11005 is resolved, we need to revisit this.
        setTimeout(() => this.$emit('submit'), 1000)
      } else this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })

      this.isLoading = false
      this.computedIsModalOpen = false
    }
  },
  watch: {
    computedIsModalOpen: {
      handler(val) {
        if (val) setTimeout(() => this.$refs.deleteConfirmationInput.focusInputField(), 10)
        else this.deleteConfirmation = ''
      },
      immediate: true
    }
  }
}
</script>
