<template>
  <div class="mm-data-addition-third-party-metrics-query height-100">
    <div v-if="isLoading" class="loading height-100">
      <mm-loader class="m-x-auto" size="large" />
    </div>

    <mm-row v-else class="height-100" no-gutters>
      <mm-col cols="6" md="3" class="height-100 tables-col">
        <span class="mm-text--body-bold p-l-2 m-b-2">
          {{ $t('data_library.data_addition.third_party.list_of_tables') }}
        </span>
        <mm-text-input v-model="search" class="m-b-2 p-l-2" type="search" hide-details />

        <div class="table-list">
          <span v-if="!computedTables.length" class="m-l-3 m-t-2">
            {{ $t('data_library.data_addition.third_party.no_tables') }}
          </span>

          <ul class="basic-list">
            <li
              v-for="table in computedTables"
              :key="table.value"
              :active="table.value == selectedTable.value"
              @click="selectTable(table)"
            >
              {{ table.name }}
            </li>
          </ul>
        </div>
      </mm-col>
      <mm-col class="query p-x-4 overflow-auto">
        <div class="d-flex m-b-3">
          <mm-text-input
            v-model="queryString"
            class="width-100 m-r-3"
            type="textarea"
            :title="$t('data_library.data_addition.third_party.query_box')"
            hide-details
            :disabled="isQueryLoading"
            @input="
              isRunTestQueryEnabled = true
              isSubmitEnabled = false
            "
          />
          <mm-button
            style="align-self: end"
            variant="primary"
            :label="$t('data_library.data_addition.third_party.run_test_query')"
            :disabled="!isRunTestQueryEnabled || !queryString.trim()"
            @click="validateQuery"
          />
        </div>

        <div v-if="selectedQuery" class="m-b-3">
          <div class="d-flex m-b-2">
            <mm-icon name="information" />
            <span class="m-l-2 mm-text--caption-regular">
              {{ $t('data_library.data_addition.third_party.edit_query_info') }}
            </span>
          </div>
          <mm-divider />
        </div>

        <div v-if="errorMessage" class="height-100 query-error-info">
          <div class="invalid-query d-flex">
            <mm-icon class="m-r-2" name="error" color="dest800" />
            <span>{{ $t('data_library.data_addition.third_party.invalid_query') }}</span>
          </div>
          <span>{{ errorMessage }}</span>
        </div>

        <div v-else class="preview-grid">
          <span class="m-b-2">
            {{ $t('data_library.data_addition.third_party.preview') }}:
            <span class="preview-query-string">{{ previewQueryString }}</span>
          </span>

          <div v-if="isQueryLoading" class="grid-placeholder mm-rounded-borders height-100">
            <mm-loader size="large" />
          </div>

          <div v-else-if="!previewTableData.columnDefs" class="grid-placeholder mm-rounded-borders height-100">
            <span> {{ $t('data_library.data_addition.third_party.select_table_preview') }}</span>
          </div>

          <mm-data-grid
            v-else
            :key="`preview_table_${gridKey}`"
            class="height-100"
            :row-count="previewTableData.rows.length"
            :column-defs="previewTableData.columnDefs"
            :on-get-rows="() => previewTableData.rows"
            mode="preview"
            :is-empty="!previewTableData.columnDefs || !previewTableData.rows.length"
            :empty-message="$t('data_library.data_addition.third_party.no_data')"
          />
        </div>

        <div class="d-flex m-t-3 m-b-2">
          <mm-button
            v-if="!selectedQuery"
            :label="$t('global.dictionary.back')"
            objective="tertiary"
            @click="$emit('back')"
          />
          <restrictions-wrapper class="m-l-auto" validate-storage-limit>
            <mm-button
              :label="$t(`global.dictionary.${selectedQuery ? 'submit' : 'next'}`)"
              :loading="isSubmitLoading"
              :disabled="!isSubmitEnabled"
              @click="submit"
            />
          </restrictions-wrapper>
        </div>
      </mm-col>
    </mm-row>
  </div>
</template>

<script>
// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

// Transforms
import { transformTableData } from '@/modules/data-library/api/data-library.transform'

// Constants
import { USER_EVENTS } from '@/constants'

// Dependencies
import { decode } from 'html-entities'

// Components
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

export default {
  name: 'data-library-data-addition-third-party-metrics-query',
  components: { RestrictionsWrapper },
  props: {
    connector: {
      type: Object,
      required: true
      /*{
        key: string,
        name: string,
        icon: string,
        category: string,
        connection: {...},
        metrics: {...}
      }*/
    },
    connection: {
      type: Object,
      required: true
      /*{
        value: string,
      }*/
    },
    selectedQuery: {
      type: Object
      /*{
        queryString: string,
        datasetId: string,
        configKey: string
      }*/
    },
    profile: {
      type: Object
      /*{
        value: string,
        arrayValue: [],
      }*/
    }
  },
  data: () => ({
    isLoading: true,
    isQueryLoading: false,
    isSubmitLoading: false,
    metricsInfo: {},
    search: '',
    selectedTable: {},
    queryString: '',
    previewQueryString: '',
    previewTableData: {},
    isRunTestQueryEnabled: false,
    isSubmitEnabled: false,
    errorMessage: '',
    gridKey: 0
  }),
  computed: {
    computedTables() {
      return this.metricsInfo?.tables.filter((t) => t.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  async created() {
    this.metricsInfo = await dataLibraryApi.getMetricsInfo(
      this.connector.hash,
      this.connection.value,
      this.$store.state.projectId,
      this.profile?.value
    )

    if (!this.metricsInfo.tables) {
      if (this.metricsInfo.message) this.$toast.show({ content: this.metricsInfo.message, status: 'error' })
      this.$emit('back')
    }

    // If there is a selected query to edit, input on textarea
    if (this.selectedQuery) {
      this.queryString = this.selectedQuery.queryString
      this.isRunTestQueryEnabled = true
    }

    this.isLoading = false
  },
  methods: {
    async selectTable(table) {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.OPEN_TABLE, { table })
      this.selectedTable = table

      const quoteChar = this.connector.metrics.quoteChar || '"'
      if (
        this.connector.connection.hasProfiles &&
        this.connector.metrics.defaultSchemaPrefix &&
        this.profile.value != this.connector.metrics.defaultSchemaPrefix
      )
        this.queryString = decode(
          `SELECT * FROM ${quoteChar}${this.profile.value}${quoteChar}.${quoteChar}${table.value}${quoteChar}`
        )
      else if (this.connector.connection.hasProfiles && this.connector.metrics.catalog && this.profile.value)
        this.queryString = decode(
          `SELECT * FROM ${quoteChar}${table.catalog}${quoteChar}.${quoteChar}${this.profile.value}${quoteChar}.${quoteChar}${table.value}${quoteChar}`
        )
      else this.queryString = decode(`SELECT * FROM ${quoteChar}${table.value}${quoteChar}`)

      await this.validateQuery()
    },
    async validateQuery() {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.RUN_QUERY, {
        key: this.connector.key,
        name: this.connector.name,
        profile: this.profile?.value,
        queryString: decode(this.queryString)
      })
      this.isRunTestQueryEnabled = false
      this.isQueryLoading = true

      const response = await dataLibraryApi.runTableQuery(
        this.connector.hash,
        this.connection.value,
        {
          profile: this.profile?.arrayValue || this.profile?.value,
          query_string: decode(this.queryString),
          hidden_query: decode(this.queryString),
          tables: this.selectedTable.value,
          validate: true,
          project_id: this.$store.state.projectId
        },
        () => this._isDestroyed
      )

      if (!response.is_valid) {
        if (response?.error_code == 400)
          this.errorMessage = this.$t('data_library.data_addition.third_party.connection_not_found')
        else this.errorMessage = decode(response.error_description || response.error_message)

        this.isQueryLoading = false
        this.isSubmitEnabled = false
      } else this.runQuery()
    },
    async runQuery() {
      this.isRunTestQueryEnabled = false
      this.isQueryLoading = true
      this.errorMessage = ''
      this.previewQueryString = decode(this.queryString)

      const response = await dataLibraryApi.runTableQuery(
        this.connector.hash,
        this.connection.value,
        {
          profile: this.profile?.arrayValue || this.profile?.value,
          query_string: decode(this.queryString),
          hidden_query: decode(this.queryString),
          tables: this.selectedTable.value,
          data_sample: true,
          project_id: this.$store.state.projectId
        },
        () => this._isDestroyed
      )

      this.previewTableData = transformTableData(response)
      this.gridKey++
      this.isQueryLoading = false
      this.isSubmitEnabled = true
    },
    async submit() {
      if (this.selectedQuery) {
        this.isSubmitLoading = true

        await dataLibraryApi.editQuery(
          this.connector.hash,
          this.connection.value,
          this.selectedQuery.configKey,
          decode(this.queryString),
          this.selectedQuery.datasetId
        )

        this.isSubmitLoading = false
      }

      this.$emit('submit', {
        queryString: decode(this.queryString),
        table: { ...this.selectedTable, cols: this.previewTableData.columnDefs }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mm-data-addition-third-party-metrics-query {
  max-height: -webkit-fill-available;
  overflow: auto;

  .loading {
    align-items: center;
    display: flex;
  }

  .tables-col {
    flex-direction: column;
    display: flex;

    ::v-deep .mm-text-input--text-container {
      width: 0;
    }

    .table-list {
      overflow: auto;
    }
  }

  .query {
    flex-direction: column;
    display: flex;

    .preview-grid {
      flex-direction: column;
      display: flex;
      height: 100%;

      .preview-query-string {
        color: var(--mm-color--p500);
      }

      .mm-data-grid {
        display: contents;

        ::v-deep .mm-data-grid--container {
          height: 100% !important;
        }
      }

      .grid-placeholder {
        border: 1px dashed var(--mm-color--n90);
        color: var(--mm-color--n100);
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }

    .query-error-info {
      white-space: pre-wrap;
      word-wrap: break-word;
      width: 100%;

      .invalid-query {
        color: var(--mm-color--dest800);
      }
    }
  }
}
</style>
