import * as angular from "angular";
import {copyToClipboard, includeStepsPanel, showMenuWithinViewport, StepsPanelController} from "./steps.panel";
import {CrossTabController, crossTabParams, crossTabGenSpecCanNotBeEmpty, valColType} from "./crosstab";
import {
  stepOpenMenuItem,
  taskStepMenuItem,
  actionStepMenuItem,
  taskCardPipelineItem,
  actionCardPipelineItem, scrollUpIfNotCompletelyVisibleOnClick
} from "./steps.menu.item";
import {
  TargetDatasetDestinationCtrl,
  TargetDatasetChoices,
  filterColumnsInTargetDs,
  columnMappingShouldBeValid
} from "./target.dataset";
import { taskModule } from '../tasks/index';

export let viewUtilsModule = angular.module('app.dataview.viewUtils', [
  'app.models', 'ui.bootstrap', 'app.common', 'ngDragDrop', taskModule.name
]);

viewUtilsModule.controller('StepsPanelController', StepsPanelController);
viewUtilsModule.directive('includeStepsPanel', includeStepsPanel);
viewUtilsModule.directive('stepOpenMenuItem', stepOpenMenuItem);
viewUtilsModule.directive('taskStepMenuItem', taskStepMenuItem);
viewUtilsModule.directive('actionStepMenuItem', actionStepMenuItem);
viewUtilsModule.directive('taskCardPipelineItem', taskCardPipelineItem);
viewUtilsModule.directive('actionCardPipelineItem', actionCardPipelineItem);
viewUtilsModule.directive('scrollUpIfNotCompletelyVisibleOnClick', scrollUpIfNotCompletelyVisibleOnClick);
viewUtilsModule.directive('showMenuWithinViewport', showMenuWithinViewport);

viewUtilsModule.controller('TargetDatasetDestinationCtrl', TargetDatasetDestinationCtrl);
viewUtilsModule.directive('targetDatasetChoices', TargetDatasetChoices.factory());
viewUtilsModule.directive('columnMappingShouldBeValid', columnMappingShouldBeValid);
viewUtilsModule.filter('filterColumnsInTargetDs', filterColumnsInTargetDs);



viewUtilsModule.controller('CrossTabController', CrossTabController);
viewUtilsModule.directive('crossTabParams', crossTabParams);
viewUtilsModule.directive('crossTabGenSpecCanNotBeEmpty', crossTabGenSpecCanNotBeEmpty);
viewUtilsModule.directive('valColType', valColType);
viewUtilsModule.directive('copyToClipboard', copyToClipboard);

