// This is an aux store created to prevent reactivity issues on the navbar view tabs
// A parallel on mm-frontend is not needed

export default {
  namespaced: true,
  state: {
    tabs: null,
    title:''
  },
  getters: {},
  actions: {},
  mutations: {
    setTabs: (state, tabs) => {
      state.tabs = tabs
    },
    setTitle: (state, title) => {
      state.title = title
    },
  },
}
