import * as angular from 'angular';

import { dateFormatterController } from './date.formatter';
import { colorChars, dateFormatter, numericFormatter } from './formatter.directives';
import { numericFormatterControllerNew } from './numeric.formatter';

export let commonFormattersModule = angular.module('app.common.formatters', ['ui.bootstrap', 'app.common.utils']);
commonFormattersModule.controller('dateFormatterController', dateFormatterController);

commonFormattersModule.directive('numericFormatter', numericFormatter);
commonFormattersModule.directive('dateFormatter', dateFormatter);
commonFormattersModule.directive('colorChars', colorChars);
commonFormattersModule.controller('numericFormatterController', numericFormatterControllerNew);
