/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * @ngInject
 */
funnelChartMenuManagerFactory.$inject = ['eventCallbackManagerFactory', 'c', 'utils', 'filterManagerFactory', 'filterUI', '$timeout',
                                       'elementConfig'];
export function funnelChartMenuManagerFactory(eventCallbackManagerFactory, c, utils, filterManagerFactory, filterUI, $timeout,
                                       elementConfig) {
  var defaultChartDisplayProperties = {
    type: 'chart',
    title: "Untitled",
    autoComputeTitle: true,
    chartType: 'funnel',
    legend: {
      enabled: false,
      verticalAlign: 'bottom',
    },
    xAxes: {},
    showAxisLabels: true,
    series: [],
    FORMAT_INFO: {},
    invertAxis: false,
    enableZoomAndPan: false,
    yAxisBreakDown: false,
    drilldown: {
      enabled: false
    },
    SORT: [[undefined, 'ASC']],
    applyLimit: true
  };


  return {
    get: getMenu
  };

  function getMenu(options) {
    return ChartMenu(options);
  }

  function ChartMenu(options) {
    var manager: any = {
      limitMax: elementConfig.rowLimits.chart
    };
    manager.columnsInited = {
      x: false,
      y: false
    };
    manager.managerType = "AGGREGATION";

    manager.getParam = getParam;
    manager.setParam = setParam;
    manager.getDisplayProperties = getDisplayProperties;
    manager.chart_random_name = 'chart_' + utils.getRandomString();
    var chartEvent = new eventCallbackManagerFactory(manager.chart_random_name);
    manager.on_valid_update = chartEvent.add_callback;
    manager.save_chart = chartEvent.fire_event;
    manager.openConditionMenu = openConditionMenu;

    manager.delayedFireUpdate = delayedFireUpdate;
    manager.currentDrillItem = undefined;
    manager.options = options;
    manager.columnBeingDragged = undefined;

    manager.log = function () {
      }

    manager.sortables = [];

    init();
    update(options);

    function openConditionMenu(){
      filterUI.open(manager.filterManager).then(chartEvent.fire_event);
    }


    function init() {
      manager.backup = null;
      manager.chart = null;
    }


    function delayedFireUpdate(){
      if(options.element && options.element.fireUpdate){
        $timeout(function(){
          options.element.fireUpdate(false);
        }, 1000);
      }
    }

    function update(options) {
      manager.options = options;
      manager.modalController = options.modalController;
      manager.element = options.element;
      manager.drilldownManager = options.drilldownManager;
      manager.metadata = options.metadata;

      if (manager.element.id) {
        manager.filterManager = filterManagerFactory.get_manager({
          metadata: options.metadata,
          teardownOptions: {
            teardown_callback: chartEvent.fire_event
          },
          dataviewId: options.dataview.id,
          context: options.context
        });
      }
      if (!manager.chart) {
        manager.chart = new FunnelChart(manager.options.metadata);
      }
      if (options.element && options.element.display_properties && manager.chart) {
        manager.chart.setDisplayProperties(options.element.display_properties);
      }
      if (options.additionalDisplayProperties && manager.chart) {
        angular.merge(manager.chart.display_properties, options.additionalDisplayProperties);
      }

      if (options.element && options.element.param) {
        setParam(options.element.param);
      } else {
        // setParam();
      }

      if (manager.drilldownManager) {
        if (manager.element.id) {
          manager.currentDrillItem = manager.drilldownManager.getDrillItemById(manager.element.id);
        } else {
          manager.currentDrillItem = manager.drilldownManager.getLatestDrillItem();
        }
      }
    }

    function getParam(allowInvalid) {
      return manager.chart.getParam(allowInvalid);
    }

    function setParam(param) {
      manager.chart.setParam(param);
    }

    function getDisplayProperties() {
      var displayProperties = angular.extend({}, manager.chart.getDisplayProperties());
      return displayProperties;
    }


    function FunnelChart(metadata) {
      var chart = this;
      var columnSummaryService = options.columnSummaryService;
      chart.metadata = metadata;
      chart.numericColumns = $.grep(chart.metadata, function (col: any) {
        return col.type == c.numeric;
      });
      chart.checkAndSetTitle = checkAndSetTitle;
      chart.setParam = setParam;
      chart.getParam = getParam;
      chart.display_properties = {};
      chart.getDisplayProperties = getDisplayProperties;
      chart.setDisplayProperties = setDisplayProperties;
      chart.sourceColumn = undefined;
      chart.uniqueColumn = undefined;
      chart.timestampColumn = undefined;
      chart.values = [{val: ""}, {val: ""}];
      chart.addValue = addValue;
      chart.removeValue = removeValue;
      chart.uniqueColumnsLoading = true;
      chart.getColumnUniqueValues = getColumnUniqueValues;
      chart.valuesColumnChanged = valuesColumnChanged;
      chart.display_properties = _.cloneDeep(defaultChartDisplayProperties);
      chart.display_properties.areValuesPristine = true;

      function getColumnUniqueValues(val) {
        if (columnSummaryService && chart.sourceColumn.internal_name && chart.sourceColumn.type != "NUMERIC") {
          $timeout(function () { chart.uniqueColumnsLoading = true; });
          if (!angular.isString(val)) {
            val = "";
          }
          return columnSummaryService.getFilteredUniqueValues(chart.sourceColumn.internal_name, val, 100).then(function (data) {
            $timeout(function () { chart.uniqueColumnsLoading = false; });
            return data;
          }, function () {
            $timeout(function () { chart.uniqueColumnsLoading = false; });
          });
        } else {
          $timeout(function () { chart.uniqueColumnsLoading = false; });
          return [];
        }
      }

      function valuesColumnChanged() {
          if (chart.display_properties.areValuesPristine && chart.sourceColumn && chart.sourceColumn.internal_name) {
            columnSummaryService.getFilteredUniqueValues(chart.sourceColumn.internal_name, "", 3, [[['count', 'DESC']]]).then(function (data) {
              chart.values = [];
              angular.forEach(data.slice(0,3), function (item) {
                if (item.value != ". . .") {
                  chart.values.push({"val": item.value});
                }
              });
              $timeout(function () {
                manager.save_chart();
              }, 500);
            })
          } else {
            manager.save_chart();
          }
      }

      function addValue() {
        chart.values.push({val: ""});
      }

      function removeValue(valObj) {
        var idx = chart.values.indexOf(valObj);
        if (idx > -1) {
          chart.values.splice(idx, 1);
        }
      }


      function getDisplayProperties() {
        return chart.display_properties;
      }

      function setDisplayProperties(displayProperties) {
        chart.display_properties = displayProperties;
      }

      function setParam(param) {
        chart.uniqueColumn = utils.metadata.get_column_by_internal_name(chart.metadata, param.FUNNEL.UNIQUE);
        chart.timestampColumn = utils.metadata.get_column_by_internal_name(chart.metadata, param.FUNNEL.TIMESTAMP);
        chart.sourceColumn = utils.metadata.get_column_by_internal_name(chart.metadata, param.FUNNEL.SOURCE);
        chart.values = _.map(param.FUNNEL.VALUES, function (val) {
          return {"val": val};
        });
      }

      function getParam(allowInvalid) {
        var values = _.map(chart.values, "val");
        if (!allowInvalid) {
          if (values.length < 2) {
            throw "Invalid Param";
          }
          if (!chart.uniqueColumn || !chart.timestampColumn || !chart.sourceColumn) {
            throw "Invalid Param";
          }
        }
        var ret = {
          UNIQUE: chart.uniqueColumn ? chart.uniqueColumn.internal_name : undefined,
          TIMESTAMP: chart.timestampColumn ? chart.timestampColumn.internal_name : undefined,
          SOURCE: chart.sourceColumn ? chart.sourceColumn.internal_name : undefined,
          VALUES: values
        };
        return {'FUNNEL': ret};
      }


      function checkAndSetTitle() {
        if (chart.display_properties.autoComputeTitle) {
          chart.display_properties.title = _computeTitle();
        }
      }

      function _computeTitle() {
        if (chart.sourceColumn) {
          return chart.sourceColumn.display_name;
        } else {
          return "Untitled";
        }
      }
    }


    return manager;
  }

}
