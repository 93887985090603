// This is a service file created for the new workspace grid implementation. This is a copy of columnMenu.controller.ts without the reference to angular grid component. There are few unwanted functions and properties present in the file which will be cleared as a part of data view page migration
import * as angular from 'angular'
import * as _ from 'lodash'
/**
 * @ngInject
 */
ColumnMenuService.$inject = [
  'utils',
  'dataviewConfig',
  'toastNotification',
  'resources',
  'derivatives',
  'oldElementServiceFactory',
  '$timeout',
  'TaskServiceFactory',
  '$injector',
  'elementConfig',
  'elementModalService',
  '$log',
  'analyticsService',
  'DataviewGlobalFilterService',
  'c',
]
export function ColumnMenuService(
  utils,
  dataviewConfig,
  toastNotification,
  resources,
  derivatives,
  oldElementServiceFactory,
  $timeout,
  TaskServiceFactory,
  $injector,
  elementConfig,
  elementModalService,
  $log,
  analyticsService,
  DataviewGlobalFilterService,
  c
) {
  return {
    get: function (dataview) {
      return new ColumnMenu(dataview)
    },
  }
  function ColumnMenu(dataview) {
    var cm = this
    var gridUnit = cm.gridUnit, // initialized via directive
      taskPanel = cm.taskPanel,
      elementPanel = cm.elementPanel, // initialized via directive
      menuType = cm.menuType // initialized via directive
    cm.hasColFormatInfoChanged = false

    var paramGeneratorMap = _initParamGenerator()
    var globalFilter = DataviewGlobalFilterService.getByDataviewId(dataview.id)

    cm.eventTrack = {
      columnChanged: function (params) {
        params['eventSource'] = 'columnMenu'
        analyticsService.userEventTrack(c.userEvents.elements.editor.columnChanged, params)
      },
      changeChartType: function (params) {
        params['eventSource'] = 'columnMenu'
        analyticsService.userEventTrack(c.userEvents.elements.editor.changeChartType, params)
      },
      elementTypeSelected: function (params) {
        params['eventSource'] = 'columnMenu'
        analyticsService.userEventTrack(c.userEvents.elements.editor.elementTypeSelected, params)
      },
    }

    cm.open = openTask
    cm.submit = submitTaskInBackground
    cm.hideMenu = hideMenu
    cm.setSort = setSort
    cm.colFormatInfo = undefined
    cm.setFormatInfo = setFormatInfo
    cm.renameColumn = renameColumn
    cm.sortProcessing = false
    cm.defaultSortDirection = 'undefined'
    cm.sortDirection = _.cloneDeep(cm.defaultSortDirection)
    cm.createMetric = createMetric
    cm.openCreateMetricModal = openCreateMetricModal
    cm.handleMetricInputChange = handleMetricInputChange
    cm.limitSelect = limitSelect
    cm.setColFormatInfoDirty = setColFormatInfoDirty
    cm.getParams = getParams
    cm.getMetricParam = getMetricParam

    function setColFormatInfoDirty() {
      cm.hasColFormatInfoChanged = true
    }

    function limitSelect(word, size) {
      if (word.length <= size) {
        return word
      } else {
        return word.substr(0, size - 3) + '...'
      }
    }

    cm.chart = {
      element: undefined,
      manager: undefined,
      isMenuOpen: false,
      create: createChart,
      openCreateModal: openCreateChartModal,
    }
    cm.selectedColTypeTasks = []
    cm.hideColumn = hideColumn
    cm.columnHideInProgress = false
    cm.cellFilter = {
      type: 'keep',
      op: {
        TEXT: 'IN_LIST',
        NUMERIC: 'EQ',
        DATE: 'EQ',
      },
    }
    cm.currentMenu = undefined

    cm.metric = {
      element: undefined,
      manager: undefined,
      aggregation: undefined,
      isMenuOpen: false,
      create: createMetric,
      openCreateModal: openCreateMetricModal,
      title: 'metric',
      dirtyTitle: false,
      recomputeTitle: function (column) {
        if (cm.metric.dirtyTitle) {
          return
        }
        cm.metric.title =
          cm.metric.aggregation[0] + cm.metric.aggregation.slice(1).toLowerCase() + ' of ' + column.display_name
      },
      reset: function () {
        cm.metric.title = 'metric'
        cm.metric.dirtyTitle = false
        cm.metric.aggregation = 'SUM'
        cm.metric.recomputeTitle()
      },
    }
    cm.metricAggregationOptions = [
      c.aggregations.sum,
      c.aggregations.avg,
      c.aggregations.stddev,
      c.aggregations.min,
      c.aggregations.max,
    ]

    cm.newLeftColumn = {
      value: '',
      isMenuOpen: false,
    }

    cm.newRightColumn = {
      value: '',
      isMenuOpen: false,
    }

    cm.insert = {
      value: '',
      isMenuOpen: false,
      hide: hideInsertMenu,
    }

    function hideInsertMenu() {
      $timeout(function () {
        cm.insert.isMenuOpen = false
      })
    }

    cm.cellReplace = {
      value: '',
      isMenuOpen: false,
      hide: hideCellReplace,
    }

    function hideCellReplace() {
      $timeout(function () {
        cm.cellReplace.isMenuOpen = false
      })
    }

    cm.formatMenu = {
      isMenuOpen: false,
      toggle: toggleFormatMenu,
      allowChangeCase: false,
      param_case: 'UPPER',
      validate_params: validateFormatTextParams,
      reset: resetFormatMenu,
    }

    function resetFormatMenu() {
      cm.formatMenu.allowChangeCase = false
      cm.formatMenu.param_case = 'UPPER'
      cm.formatMenu.param_trim = false
    }

    function validateFormatTextParams(val) {
      return (
        (val.allowChangeCase && ['UPPER', 'LOWER', 'TITLE'].indexOf(val.param_case) !== -1) ||
        (val.hasOwnProperty('param_trim') && val.param_trim === true)
      )
    }

    cm.selectedElement = undefined

    function toggleFormatMenu(isOpen) {
      var setTo = isOpen === undefined ? !cm.formatMenu.isMenuOpen : isOpen
      $timeout(function () {
        cm.formatMenu.isMenuOpen = setTo
      })
    }

    cm.numericColumns = []
    // plot options
    cm.plot = {
      type: 'xy',
      xAxis: undefined,
      yAxis: undefined,
      yFunc: 'SUM',
      isMenuOpen: false,
    }

    var OP_INVERSE_MAP = {
      CONTAINS: 'NOT_CONTAINS',
      EQ: 'NE',
      GTE: 'LT',
      LTE: 'GT',
      GT: 'LTE',
      LT: 'GTE',
      IS_EMPTY: 'IS_NOT_EMPTY',
    }

    /*
  cm.haveFormatOptionsChanged = haveFormatOptionsChanged;
  function haveFormatOptionsChanged() {
    return angular.equals(cm.formatInfo, cm.previousFormatInfo);
  }
*/
    function renameColumn() {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.columnMenuEvents.renameColumn, {
        eventOrigin: 'Column Menu',
      })
      // Event is fired. Listener is present on dataview directives
      gridUnit.columnMenu._fireOnRenameClick(cm.currentMenu.columnId)
      // Closing the column menu below, since calling hidemenu method
      // fires another event that overrides the focus on input element in this method
      hideColMenuOnRename()
    }

    function hideColMenuOnRename() {
      cm.currentMenu.visible = false
      cm.currentMenu.columnId = undefined
      onHideColumnMenu()
    }

    function onHideColumnMenu() {
      $timeout(function () {
        cm.chart.isMenuOpen = false
      }, 20)
      cm.formatMenu.toggle(false)
      cm.formatMenu.reset()
      cm.elementsMenu.toggle(false)
    }

    function setFormatInfo(colId, format) {
      var new_format_info = _.cloneDeep(dataview.display_properties.FORMAT_INFO)
      new_format_info[colId] = format
      dataview.setDisplayProperties({ FORMAT_INFO: new_format_info })
      cm.hasColFormatInfoChanged = false
    }

    function getParams(taskName, col, params) {
      var param
      if (paramGeneratorMap.hasOwnProperty(taskName)) {
        param = paramGeneratorMap[taskName](col, params)
      }
      return param
    }

    function openTask(taskName, col) {
      var param
      if (paramGeneratorMap.hasOwnProperty(taskName)) {
        param = paramGeneratorMap[taskName](col)
      }
      taskPanel.open(taskName, param)
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.taskPanelEvents.taskInvoked, {
        menuType: menuType,
        taskName: taskName,
        eventOrigin: 'dataview',
      })
      $timeout(function () {
        if (taskName == 'math' && taskPanel.manager) {
          taskPanel.manager.setFunctionsDefaultSelectedColumn(col.internal_name)
        }
      }, 300)
    }

    function hideColumn() {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.columnMenuEvents.hideColumn, {
        menuType: menuType,
        eventOrigin: 'dataview',
      })
      $timeout(function () {
        cm.columnHideInProgress = true
      })
      var hidden_columns = _.cloneDeep(gridUnit.dataview.display_properties.HIDDEN_COLUMNS) || []
      hidden_columns.push(cm.currentMenu.columnId)
      gridUnit.dataview
        .setDisplayProperties({ HIDDEN_COLUMNS: hidden_columns })
        .then(cm.currentMenu.hide, cm.currentMenu.hide)
    }

    function submitTaskInBackground(taskName, col, taskParam, dataview) {
      var param
      if (paramGeneratorMap.hasOwnProperty(taskName)) {
        param = paramGeneratorMap[taskName](col, taskParam)
      }
      var manager_factory = $injector.get(dataviewConfig.get_manager_factory_from_task_name(taskName))
      var manager = manager_factory.get_manager({
        metadata: utils.metadata.add_type_to_display_name(dataview.metadata),
        context: {
          dataview: dataview,
          inEditMode: false,
          simpleMode: true, // useful in search replace. Else ignored
        },
      })
      manager.setParam(param)
      var TaskService = TaskServiceFactory.get_by_dataview_id(dataview.id)
      var finalParam = manager.getParam()
      finalParam['DATAVIEW_ID'] = parseInt(dataview.id)
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.taskPanelEvents.taskInvoked, {
        menuType: menuType,
        taskName: taskName,
        eventOrigin: 'dataview',
      })

      TaskService.add_task(finalParam).then(submission_success, submission_failure)
      resources.update()
      function submission_success() {
        resources.update()
      }

      function submission_failure(data) {
        $log.error(arguments)
        var message = 'The task could not be submitted, please try again or contact support for more information'
        if (data.data && data.data.ERROR_MESSAGE) {
          message = data.data.ERROR_MESSAGE
        }
        toastNotification.error(message)
      }
    }

    function hideMenu() {
      cm.currentMenu.hide()
    }

    function setSort(colId, sortDirection) {
      cm.sortProcessing = true
      var patch = { SORT: [[colId, sortDirection]] }
      dataview.setDisplayProperties(patch).then(setSortSuccess, setSortFailure)

      function setSortSuccess() {
        cm.sortProcessing = false
        resources.update()
        // gridUnit.reloadGrid()
        // cm.currentMenu.hide()
      }

      function setSortFailure() {
        cm.sortProcessing = false
        toastNotification.error('Could not set Sort, please try again.')
      }
    }

    function handleMetricInputChange() {
      cm.metric.recomputeTitle()
      // cm.metric.manager.setParam(getMetricParam())
      cm.metric.manager.expressionUpdateCb()
      cm.eventTrack.columnChanged({
        index: 0,
        column: cm.currentMenu.column.internal_name,
        elementType: 'metric',
        elementId: undefined,
        agg: cm.metric.aggregation,
      })
    }

    function getMetricParam(param) {
      return {
        METRIC: {
          EXPRESSION: [
            {
              TYPE: 'FUNCTION',
              VALUE: { FUNCTION: param.metric.function.toUpperCase(), ARGUMENT: param.columnId },
            },
          ],
          AS: param.metric.name,
        },
        CONDITION: null,
        IS_PERMANENT: true,
      }
    }

    function createMetric(dataview, param, format, shortForm = false) {
      prepareMetric(dataview)
      cm.dataview = dataview
      var mParam = getMetricParam(param)
      cm.metric.manager.setParam(mParam)
      var finalParam = cm.metric.manager.getParam()
      var displayProperties = cm.metric.manager.getDisplayProperties()
      displayProperties.info.title = mParam.METRIC.AS
      displayProperties.FORMAT_INFO.RESULT = format
      displayProperties.USE_SCIENTIFIC_FORMAT = shortForm
      return _addDerivative(finalParam, displayProperties)
    }

    function openCreateMetricModal(dataview, param) {
      prepareMetric(dataview)
      let displayProps = cm.metric.manager.getDisplayProperties()
      let metricParam = getMetricParam(param)
      displayProps.info.title = metricParam.METRIC.AS
      var element = {
        type: 'METRIC',
        param: metricParam,
        display_properties: displayProps,
      }
      $timeout(function () {
        elementModalService.open({
          element: _.cloneDeep(element),
          dataview: dataview,
          functionsDefaultSelectedColumn: param.columnId,
        })
      })
    }

    function _getManagerInstanceAndElement(type, dataview) {
      var menuFactory = $injector.get(elementConfig[type].menuFactory)
      var element = { type: type }
      var manager = menuFactory.get({
        element: element,
        dataview: dataview,
        metadata: dataview.metadata,
      })
      return {
        element: element,
        manager: manager,
      }
    }

    function prepareMetric(dataview) {
      var managerAndElement = _getManagerInstanceAndElement('METRIC', dataview)
      cm.metric.element = managerAndElement.element
      cm.metric.manager = managerAndElement.manager
      cm.metric.aggregation = 'SUM'
      // cm.metric.recomputeTitle()
      // cm.handleMetricInputChange()
    }

    function createChart() {
      cm.chart.manager.provider.chart.setDefaultSort()
      if (gridUnit.columnMenu.column.type == 'DATE') {
        cm.chart.manager.provider.chart.label.checkTypeSetTruncateInfo()
      }
      // TODO: SENTRYERROR:FRONTEND-KF:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/480137038
      // TODO: SENTRYERROR:FRONTEND-KG:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/480137334
      var displayProperties = cm.chart.manager.getDisplayProperties()
      var param = cm.chart.manager.getParam()
      param.IS_PERMANENT = true
      _addDerivative(param, displayProperties)
    }

    function openCreateChartModal() {
      var element: any = { type: 'CHART', display_properties: { chartType: 'xy' } }
      try {
        var param = cm.chart.manager.getParam(true)
        if (param) {
          element.param = param
        }
        var displayProperties = cm.chart.manager.getDisplayProperties()
        if (displayProperties) {
          element.display_properties = displayProperties
        }
      } catch (e) {
        throw e
      }
      $timeout(function () {
        elementModalService.open({
          element: element,
          dataview: dataview,
        })
      })
    }

    function _addDerivative(param, displayProperties) {
      analyticsService.userEventTrack(c.userEvents.exploreCards.metricAdded, { eventOrigin: 'dataview.columnMenu' })
      param.IS_PERMANENT = true
      return derivatives.add(cm.dataview.id, param, displayProperties)
    }

    function _initParamGenerator() {
      var map = {}
      map[dataviewConfig.tasks.rTotal] = function (col) {
        return {WINDOW: {RANGE: "RUNNING", EVALUATE: {FUNCTION: "SUM", ARGUMENTS: [col.internal_name] }}}
      }
      map[dataviewConfig.tasks.rank] = function (col) {
        return {WINDOW: {RANGE: "RUNNING", EVALUATE: {FUNCTION: "RANK" }, ORDER_BY: [[col.internal_name, 'ASC']]}}
      }
      map[dataviewConfig.tasks.combine] = function (col) {
        return { COMBINE: { SOURCE: [{ COLUMN: col.internal_name }] } }
      }
      map[dataviewConfig.tasks.copy] = function (col) {
        return {
          COPY: {
            SOURCE: col.internal_name,
            AS: {
              COLUMN: 'Copy of ' + col.display_name,
              TYPE: col.type,
              FORMAT: {},
              INTERNAL_NAME: 'gcf_' + utils.string.random(10).toLowerCase(),
            },
          },
        }
      }
      map[dataviewConfig.tasks.bulkCopy] = function (col) {
        return {
          COPY: [{
            SOURCE: col.internal_name,
            AS: {
              COLUMN: 'Copy of ' + col.display_name,
              TYPE: col.type,
              FORMAT:{},
              INTERNAL_NAME: 'gcf_' + utils.string.random(10).toLowerCase()
            }
          }]
        }
      }
      map[dataviewConfig.tasks.convert] = function (col) {
        return { CONVERT: [{ SOURCE: col.internal_name }] }
      }
      map[dataviewConfig.tasks.split] = function (col) {
        return { SPLIT: { SOURCE: col.internal_name } }
      }
      map[dataviewConfig.tasks.insert] = function (col) {
        var val = null
        if (cm.insert.isMenuOpen) {
          val = cm.insert.value
        }
        return {
          SET: { DESTINATION: col.internal_name, VALUES: [{ VALUE: val, CONDITION: globalFilter.getCondition() }] },
        }
      }
      map[dataviewConfig.tasks.extract_text] = function (col) {
        let { selection, selectionStart, selectionEnd } = cm.currentMenu || {}
        var substr: any = { SOURCE: col.internal_name }
        if (menuType != 'column' && selection?.length) {
          substr.CHAR_POSITION = selectionStart
          substr.NUM_CHAR = selectionEnd - selectionStart + 1
          substr.DIRECTION = 'RIGHT'
        }
        return { SUBSTRING: substr }
      }
      map[dataviewConfig.tasks.math] = function (col) {
        return { MATH: { EXPRESSION: [{ TYPE: 'COLUMN', VALUE: col.internal_name }] } }
      }
      map[dataviewConfig.tasks.window_function] = function (col) {
        return {
          WINDOW: {
            EVALUATE: {
              FUNCTION: 'SUM',
              ARGUMENTS: [col.internal_name],
            },
            AS: {
              COLUMN: 'Sum of ' + col.display_name,
              TYPE: 'NUMERIC',
              FORMAT: {},
              INTERNAL_NAME: 'gcf_' + utils.string.random(10).toLowerCase(),
            },
            RANGE: 'RUNNING',
          },
        }
      }
      map[dataviewConfig.tasks.increment_date] = function (col) {
        return { INCREMENT_DATE: { SOURCE: col.internal_name } }
      }
      map[dataviewConfig.tasks.extract_date] = function (col) {
        return { EXTRACT_DATE: { SOURCE: col.internal_name } }
      }
      map[dataviewConfig.tasks.addColumn] = function (col, param) {
        var colOrder = param.colOrder
        return { ORDER: parseInt(utils.getKeyByValue(colOrder, col.internal_name)) }
      }
      map[dataviewConfig.tasks.delete] = function (col) {
        if(!col) return {}
        return { DELETE: [col.internal_name] }
      }
      map[dataviewConfig.tasks.bulkReplace] = (col) => {
        return {
          REPLACE: {
            SOURCE: [col.internal_name],
            MAPPING: [{ SEARCH_VALUE: [], REPLACE_VALUE: null }],
            MATCH_CASE: true,
            MATCH_WORDS: true,
          },
          CONDITION: globalFilter.getContextualCondition(col.internal_name),
        }
      }
      map[dataviewConfig.tasks.replace] = function (col) {
        const { value, selection } = cm.currentMenu
        if (col.type != 'TEXT') {
          return
        }

        if (menuType == 'column') {
          return {
            REPLACE: {
              SOURCE: [col.internal_name],
              MAPPING: [{ SEARCH_VALUE: [], REPLACE_VALUE: null }],
              MATCH_CASE: true,
              MATCH_WORDS: true,
            },
            CONDITION: globalFilter.getContextualCondition(col.internal_name),
          }
        } else {
          var r = cm.cellReplace.isMenuOpen && cm.cellReplace.value.length ? cm.cellReplace.value : null
          if (selection.length) {
            return {
              REPLACE: {
                SOURCE: [col.internal_name],
                VALUE_PAIR: [{ SEARCH_VALUE: selection, REPLACE_VALUE: r }],
                MATCH_CASE: true,
                MATCH_WORDS: false,
              },
              CONDITION: globalFilter.getContextualCondition(col.internal_name),
            }
          } else {
            var val = value
            if (!_.isArray(val)) {
              val = [val]
            }
            return {
              REPLACE: {
                SOURCE: [col.internal_name],
                MAPPING: [{ SEARCH_VALUE: val, REPLACE_VALUE: r }],
                MATCH_CASE: true,
                MATCH_WORDS: true,
              },
              CONDITION: globalFilter.getContextualCondition(col.internal_name),
            }
          }
        }
      }
      function getConditionItems(col, op, val, isPartial, cellFilterType) {
        if (menuType != 'column') {
          if (col.type == 'TEXT') {
            if (isPartial) {
              op = 'CONTAINS'
            } else {
            }
          } else if (col.type == 'NUMERIC') {
            val = window['numeral'](val).value()
          } else if (col.type == 'DATE') {
          } else {
            return
          }
          if (val === null || val == undefined || (col.type == 'NUMERIC' && isNaN(val)) || val === '') {
            val = true
            op = 'IS_EMPTY'
          }
        }
        op = cellFilterType == 'keep' ? op : OP_INVERSE_MAP[op]
        return [op, val]
      }
      map[dataviewConfig.tasks.filter] = function (col) {
        var condition = {},
          op,
          val
        let value,
          selection = undefined
        value = undefined
        if (_.isArray(value)) {
          if (col.type == 'TEXT' || (col.type == 'NUMERIC' && value.length != 2)) {
            var _logical = [],
              _logicalOp = 'OR'
            angular.forEach(value, function (v) {
              ;[op, val] = getConditionItems(col, cm.cellFilter.op[col.type], v, false, cm.cellFilter.type)
              var _col = {}
              _col[col.internal_name] = {}
              _col[col.internal_name][op] = val
              _logical.push(_col)
            })
            if (op == 'NE') {
              _logicalOp = 'AND'
            }
            condition[_logicalOp] = _logical
          } else if (col.type == 'NUMERIC' && value.length == 2) {
            if (cm.cellFilter.type == 'keep') {
              condition[col.internal_name] = { IN_RANGE: value }
            } else {
              value = _.sortBy(value)
              var lt = {},
                gt = {}
              lt[col.internal_name] = { LT: value[0] }
              gt[col.internal_name] = { GT: value[1] }
              condition['OR'] = [lt, gt]
            }
          }
        } else {
          if (selection && selection.length) {
            val = selection
          } else {
            val = value
          }
          ;[op, val] = getConditionItems(
            col,
            cm.cellFilter.op[col.type],
            val,
            selection && selection.length,
            cm.cellFilter.type
          )
          condition[col.internal_name] = {}
          condition[col.internal_name][op] = val
        }
        return { CONDITION: condition, SELECT: 'ALL' }
      }
      map[dataviewConfig.tasks.format_text] = function (col, params) {
        var param = {
          TEXT_TRANSFORM: {
            SOURCE: [col.internal_name],
          },
        }
        if (params.hasOwnProperty('changeCase')) {
          param.TEXT_TRANSFORM['CASE'] = params.changeCase
        }
        if (params.normalizeWhitespaces) {
          param.TEXT_TRANSFORM['TRIM'] = params.normalizeWhitespaces
        }

        return param
      }
      return map
    }
  }
}
