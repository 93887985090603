import * as angular from 'angular';
import * as _ from 'lodash-es';
import { transformGridColumnsMetaData } from '../../../vueApp/src/mm-frontend/src/modules/data-editor/api/data-editor.transform';

import {config} from "../common/app.config";
// import {toastNotification} from "../common/toastNotification.service";

/**
 * @ngInject
 */
DataviewCtrl.$inject = ['appHelperService', 'TaskServiceFactory', 'ColumnMenuService', '$q', 'dataviewConfig', 'resources', 'c', 'eventCallbackManagerFactory',
                        '$timeout', 'oldElementServiceFactory', 'Notification', 'DataviewLayoutService', 'TaskHelperServiceFactory',
                        'utils', 'clickEvents', 'ElementPanelFactory', 'MainGridUnitFactory',
                        'TaskPanelFactory', 'dataviewSafeDeleteResetService', 'dataviewRerunService', 'toastNotification',
                        'DataviewGlobalFilterService', 'wkspPreviewService', 'wkspTaskStatusFactory', 'ActionPanelFactory', 'ExplorePanelFactory',
                        'ActionServiceFactory', 'CompatibilityWarnings', 'modalService', 'HistoryDataViewPanelFactory', 'taskDescriber', 'analyticsService',
                        'FutureService', 'DataviewService', '$resource', 'modificationRequestTracker', 'reorderFactory',
                        'Singularity', '$location', '$rootScope', 'globalFilterService', 'UserWorkspace', 'VuexStore', 'appMetaInfo'];
export function DataviewCtrl (appHelperService, TaskServiceFactory, ColumnMenuService, $q, dataviewConfig, resources, c, eventCallbackManagerFactory,
                        $timeout, oldElementServiceFactory, Notification, DataviewLayoutService, TaskHelperServiceFactory,
                        utils, clickEvents, ElementPanelFactory, MainGridUnitFactory,
                        TaskPanelFactory, dataviewSafeDeleteResetService, dataviewRerunService, toastNotification,
                        DataviewGlobalFilterService, wkspPreviewService, wkspTaskStatusFactory, ActionPanelFactory, ExplorePanelFactory,
                        ActionServiceFactory, CompatibilityWarnings, modalService, HistoryDataViewPanelFactory, taskDescriber, analyticsService,
                        FutureService, DataviewService, $resource, modificationRequestTracker, reorderFactory,
                        Singularity, $location, $rootScope, globalFilterService, UserWorkspace, $store, appMetaInfo) {
  this.$onInit = function () {
    CompatibilityWarnings.init();
    var vm = this; // why do we copy here ?
    if (!vm.dataview) {
      // TODO: show a warning and redirect to landing and close the tab.
      // This can happen if the user enters an invalid WS id in the URL
    }
    var dataview_id = vm.dataview.id;
    var _inited = $q.defer();
    var _wsSwitchEventListener = new eventCallbackManagerFactory('_wsSwitchEvent' + dataview_id);
    var TaskService = TaskServiceFactory.get_by_dataview_id(dataview_id);
    var ActionService = ActionServiceFactory.get_by_dataview_id(dataview_id);
    vm.actionService = ActionService;

    DataviewLayoutService.dataviewSwitchListeners[dataview_id] = _wsSwitchEventListener;
    _wsSwitchEventListener.add_callback('wsControllerSwitchCb', _onDataviewSwitch);

    vm.inited_promise = _inited.promise;
    vm.sortInfo = []; // array of { column: undefined, direction: undefined }
    vm.sortInfoAsText = [];
    vm.rerunDataview = rerunDataview;
    vm.removeSort = removeSort;
    vm.highlightAndScrollColumns = highlightAndScrollColumns;
    vm.editModeInfo = {};
    vm.globalCondition = null;
    vm.exportDialog = exportDialog;
    vm.getFilteredTasks = getFilteredTasks;
    vm.resetSearchString = resetSearchString;
    vm.searchTaskMenu = searchTaskMenu;
    vm.copyUrl = copyUrl;
    vm.alertsDialog = alertsDialog;
    vm.ruleTooltip = ruleTooltip;
    vm.reorderFactory = reorderFactory;
    vm.editLinkedDs = editLinkedDs;
    vm.deleteLinkedDs = deleteLinkedDs;

    vm.dataviewConfig = dataviewConfig;
    vm.handlePreviewModeChanges = handlePreviewModeChanges;

    vm.task_service = TaskService;
    vm.task_menu_items = vm.dataviewConfig.newTaskMenu;
    vm.disabledTasks = $rootScope.disabledTasks;
    vm.task_list = TaskService.list;
    vm.action_triggers_lists = ActionService.categorized_lists;
    vm.elementService = oldElementServiceFactory.getByDataviewId(dataview_id);
    vm.clickEvents = clickEvents;
    vm.waitingForTaskCompletion = false;
    vm.DataviewService = DataviewService;
    vm.pipelineRunningLoader = {
      cancelTimeout: undefined,
      show: false
    };

    vm.sortPanel = {
      isOpen: false
    };
    vm.dataPullLastAutoOpened = null;
    // grid reload methods
    // request for selection
    vm.mainGridUnit = MainGridUnitFactory.get(vm.dataview);
    vm.task_panel = TaskPanelFactory.get(vm.dataview, vm.mainGridUnit);
    vm.task_panel.checkForPasteableItem()
    vm.explorePanel = new ExplorePanelFactory(vm.dataview);
    vm.columnMenu = ColumnMenuService.get(vm.dataview);
    vm.explorePanel.setTaskPanel(vm.task_panel);
    vm.action_panel = ActionPanelFactory.get(vm.dataview, vm.mainGridUnit);
    vm.elementPanel = ElementPanelFactory.get(vm.dataview, vm.elementService);
    vm.taskHelperService = TaskHelperServiceFactory.getByDataviewId(vm.dataview.id);
    vm.globalFilter = DataviewGlobalFilterService.getByDataviewId(vm.dataview.id);
    vm.historyDataViewPanel = HistoryDataViewPanelFactory.getByDataviewId(vm.dataview.id);
    vm.modalService = modalService;
    vm.sourceDataUpdated = false;
    vm.isAlreadyPublished = false;
    vm.persistFilters = true;
    vm.showGridLoader = true;
    vm.updateDataviewData = updateDataviewData;
    vm.exportFileNamePrepend = `${_.get(vm, 'dataview.datasource.name', '')}_${_.get(vm, 'dataview.name', '')}`;
    vm.getExploreData = function getExploreData(params) {
      return $resource(config.api.volatile_query).save({dataviewId: dataview_id}, params).$promise;
    };

    vm.globalFilter.onChange('dataview_ctrl', async () => {
      if (vm.showGridLoader) {
        vm.showGridLoader = false
        DataviewLayoutService.dataviewSwitched();
      } else {
        vm.dataViewGrid.resetDataViewGrid()
      }
    })

    // remove explore icon from workspace grid
    vm.dataview.exploreCardCloseEvent.add_callback('removeExploreColumn', (columnId) => {
      vm.dataViewGrid.onExploreCardRemove(columnId)
    })

    vm.dataPushLastAutoOpened = null;
    Singularity.onDataPushDialogOpenRequest('dataview_ctrl', checkAndOpenIfSingularityDialog);
    $timeout(checkAndOpenIfSingularityDialog, 2000);
    vm.liveLink = new (function liveLink() {
      var self = this;
      self.toggle = function (val) {
        if (val != false) {
          self.isOpen = !self.isOpen;
        } else {
          self.isOpen = false;
        }
      };
    })();
    vm.linkedDs = new (function linkedDs() {
      var self = this;
      self.toggle = function (val) {
        if (val != false) {
          self.isOpen = !self.isOpen;
          analyticsService.userEventTrack(c.userEvents.dataviewEvents.linkedDsEvents.linkedDS, {eventOrigin: "dataviewPage.linkedDS"});
        } else {
          self.isOpen = false;
        }
      };
    })();
    vm.taskbutton = new (function taskbutton() {
      var self = this;
      self.isOpen = false;
      self.toggle = function (val) {
        if (val != false) {
          self.isOpen = !self.isOpen;
        } else {
          self.isOpen = false;
        }
      };
    })();

// edit linkedDS function
    function editLinkedDs(action) {
      vm.action_panel.edit(action);
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.linkedDsEvents.configureLinkedDs, {eventOrigin: "dataviewPage.linkedDS"});
    };

// delete linkedDS function
    function deleteLinkedDs(action) {
      vm.action_panel.delete(action);
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.linkedDsEvents.unlinkDs, {eventOrigin: "dataviewPage.linkedDS"});
    };
    vm.wkspTaskStatusManager = wkspTaskStatusFactory.get(vm.dataview.id);
    vm.dataview.stepPreview.sequenceUpdateEvent.add_callback('sequence_updated_event_wksp_ctrl_cb', async function () {
      vm.dataViewGrid.isProcessing = true
      updateDataviewData(true, undefined, true, true).then(async function () {
        vm.dataview.stepPreviewSequenceUpdateEvent.fire_event(vm.dataview?.stepPreview?.sequence)
        vm.dataViewGrid.isProcessing = false
        if (vm.dataview.stepPreview.inPreviewMode && vm.dataview.stepPreview.sequence > 0) {
          let task = TaskService.getBySequence(vm.dataview.stepPreview.sequence)
          var affectedColumns = taskDescriber.getAffectedColumns(task.params)
          if (affectedColumns.destinations.length) {
            vm.dataViewGrid.selectColumns(affectedColumns.destinations)
          }
          var scrollTo = affectedColumns.destinations[0] || affectedColumns.sources[0]
          vm.dataViewGrid.scrollToColumn(scrollTo)
        }
      })
    });

    vm.stepsPanel = new function StepsPanel() {
      var self = this;
      self.isOpen = vm.dataview.draft_mode == 'dirty' || vm.dataview.pipeline_status == 'error' ? true : false;
      self.isPristine = true;
      self.animateTaskToggle = false;
      self.waitForTaskAddition = false;
      self.toggle = function () {
        if (vm.dataview.draft_mode == 'dirty' || vm.dataview.pipeline_status == 'error') {
          return
        }
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.taskPanelEvents.taskPanelToggle, {eventOrigin: "dataview"});
        self.isOpen = !self.isOpen;
        self.isPristine = false
        if (self.isOpen) {
          const currentPage = $rootScope.appHelperService.getCurrentPage();
          var ui_preferences = $rootScope.UserWorkspace.ui_preferences
          const globalPreferences = _.get(ui_preferences, 'GLOBAL.ONBOARDING', {});
          var editModeOnBoardingShownAlready = _.get(globalPreferences, 'editMode', false);
          if (!editModeOnBoardingShownAlready) {
            $rootScope.appHelperService.startOnBoarding('editMode');
          }
        }

      };
      self.open = function () {
        self.isOpen = true
      };
      self.close = function () {
        self.isOpen = false
      };
      self.determine = function () {
        if (!self.isPristine) {
          return
        }
        if (Object.keys(vm.task_list).length) {
          self.open()
        } else {
          self.close()
        }
      };
      TaskService.onUpdate("steps_panel", function () {
        if (self.waitForTaskAddition) {
          self.waitForTaskAddition = false;
        }
        self.isOpen = self.isOpen || vm.dataview.draft_mode == 'dirty' || vm.dataview.pipeline_status == 'error' ? true : false;
      });
      vm.task_panel.taskAddedEvent.add_callback("highlight_task_toggle", function () {
        self.waitForTaskAddition = true;
        TaskService.update_list();
        vm.stepsPanel.open();

      });
      DataviewService.on_apply_template("apply_template", function () {
        self.open()
        vm.dataview.task_in_progress = false;
      })
      DataviewService.on_rename_col("renameCol_", function () {
        // after renaming cols, update dataview
        updateDataviewData();
      });


      vm.action_panel.actionAddedEvent.add_callback("highlight_action_toggle", function () {
        self.waitForActionAddition = true;
        ActionService.update_list();
        vm.stepsPanel.open();
      });

      ActionService.publishReportUpdateEvent.add_callback("highlight_report_toggle", function () {
        vm.stepsPanel.open();
      })

    }();


    // vm.stepsPanel.determine();
    // TaskService.onUpdate('stepsPanelDetermine', vm.stepsPanel.determine);
    TaskService.onSubmit('WaitingForTaskChange', function () {
      vm.waitingForTaskCompletion = true;
      // call updateDataview data to prevent dataviewMainGridLoader, even after task is successfully submitted
      updateDataviewData(undefined, true);
    });

    // callback for taskProgressEvent event, it sets task_in_progress variable in dataview object to true or false
    // depending on the status of the task
    TaskService.onTaskProgress("taskProgress", function (status) {
      if (status == 'in_progress') {
        vm.dataview.task_in_progress = true;
      } else if (status == 'complete' || status == 'error') {
        vm.dataview.task_in_progress = false;
      }
    });

    // callback for actionProgressEvent event, it sets action_in_progress variable in dataview object to true or false
    // depending on the status of the action
    ActionService.onActionProgress("actionProgress", function (status) {
      if (status == 'in_progress') {
        vm.dataview.action_in_progress = true;
      } else if (status == 'complete' || status == 'error') {
        vm.dataview.action_in_progress = false;
      }
    });

    vm.actionAndStepMenu = (function () {
      var self: any = {};
      self.search = '';
      self.info = {
        content: '',
        label: 'INFO'
      };

      self.assignInfo = function (action) {
        self.info = {
          content: action.info.content,
          label: action.label
        }
      };


      self.resetInfo = function () {
        self.info = {
          content: '',
          label: 'INFO'
        }
      };
      return self
    })();

    vm.actionMenu = new function (): void {
      var self = this;
      self.isOpen = false;
      self.close = function () {
        self.isOpen = false;
      };
      self.open = function () {
        self.isOpen = true;
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.actionMenuEvents.actionMenuToggle, {
          eventOrigin: "dataview",
          toggleState: self.isOpen
        });
      };
      self.toggleDropdown = function ($event) {
        if ($event) {
          $event.preventDefault();
          $event.stopPropagation()
        }
        $timeout(function () {
          self.isOpen = !self.isOpen;
        }, 10);
      };
      return self
    }();

    vm.threeDotsMenu = new function (): void {
      var self = this;
      self.isOpen = false;
      self.close = function () {
        self.isOpen = false
      };
      self.toggleDropdown = function () {
        $timeout(function () {
          self.isOpen = !self.isOpen
        }, 10)
      };
      return self
    }();
    vm.task_menu_is_open = false;
    vm.addTaskMenu = new function (): void {
      var self = this;
      self.lockToggle = false;
      self.isOpen = false;
      self.close = function () {
        self.isOpen = false;
      };
      self.open = function () {
        self.isOpen = true;
      };
      self.toggleDropdown = function (isOpen) {
        $timeout(function () {
          self.isOpen = isOpen === undefined ? !self.isOpen : isOpen;
        }, 10);
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.taskMenuToggle, {
          eventOrigin: "dataView.taskMenu",
          toggleState: self.isOpen
        });
      };
      self.closeAddTaskMenuOnEscape = function (event) {
        if (event.which == 27) {
          self.isOpen = false;
        }
      };
      return self
    }();

    vm.dataview.on_update('dataviewUpdateListener', dataviewUpdateListener);
    vm.dataview.on_reset('dataviewResetListener', function () {
      location.reload();
      const dataviewFilters = globalFilterService.getBySource(vm.dataview.id, 'dataview', vm.persistFilters);
      dataviewFilters.reset();
    });

    // init
    init()

    // functions

    async function init() {
      const filterCondition = vm.globalFilter.getCondition()
      updateDataviewData(filterCondition !== undefined, true).then(() => {
        vm.explorePanel.init()
        if (window.location.hash.includes('?onboarding=true')) {
          setTimeout(() => {
            const type = window.location.hash.includes('sampleFlow=true') ? 'sampleFlow' : 'intro'
            appHelperService.startOnBoarding('dataview', false, type)
            $location.search('onboarding', null); 
            $location.search('sampleFlow', null);
          }, 2500)
        }
    });
    }

    function isDataviewPipelineToBeTracked() {
      return vm.dataview.status == c.wsStatus.processing || vm.dataview.pipeline_status == c.pipelineStatus.submitted || vm.dataview.pipeline_status == c.pipelineStatus.running
    }

    function trackDataviewPipeline() {
      vm.wkspTaskStatusManager.update().then(function () {
        if (_.intersection(Object.values(vm.wkspTaskStatusManager.statuses), ['PROCESSING', 'QUEUED', 'LOCKED']).length) {
          vm.pipelineRunningLoader.cancelTimeout = $timeout(function () {
            vm.pipelineRunningLoader.show = true;
          }, 3000);
        }
      });
      vm.wkspTaskStatusManager.setPolling(true);
      vm.waitingForTaskCompletion = true;
    }

    function dataviewUpdateListener(changes, prevData) {
      if (!changes) {
        changes = {
          derivative_count: true,
          tasks_total_count: 0,
          display_properties: {}
        }
      }
      var _updateWSGrid = false;
      var _wsDataChanged = false;
      let changedProperties = []
      var _ignoreDispChangesForReload = [c.COLUMN_SUMMARIES, c.COLUMN_SUMMARY, c.SHOW_CUSTOM_CARD,
        c.EXPLORE_PANEL, c.GLOBAL_DISPLAY_CONDITION];
      vm.task_panel.checkForPasteableItem()

      if (changes.hasOwnProperty('derivative_count') || changes.hasOwnProperty('metrics')) {
        if (vm.dataview.status == c.wsStatus.ready) {
          vm.elementService.updateList().then(vm.elementPanel.fireRenderComplete)
        }
      }

      if (changes.hasOwnProperty('data_updated_at') || changes.hasOwnProperty('row_count') || changes.hasOwnProperty('column_count')) {
        _updateWSGrid = true;
        _wsDataChanged = true;
      }

      if (changes.hasOwnProperty('in_preview_mode')) {
        vm.preview_mode_changes_submitted = false
      }

    if (changes.hasOwnProperty('display_properties')) {
      changedProperties = changes.display_properties.filter(function (i) {
        return _ignoreDispChangesForReload.indexOf(i) == -1
      });
      if (changedProperties.length) {
        if (changedProperties.includes('SORT')) {
          _updateWSGrid = true;
          _wsDataChanged = true;
        }
        if (!(changedProperties.length == _.intersection(changedProperties, ["COLUMN_NAMES", "COLUMN_ORDER"]).length)) {
          _updateWSGrid = true;
        }
        if(changedProperties.includes('COLUMN_WIDTHS') || changedProperties.includes('FORMAT_INFO') || changedProperties.includes('COLUMN_NAMES')) {
          _updateWSGrid = true
        }
        if(changedProperties.includes("HIDDEN_COLUMNS")) {
          _updateWSGrid = true
          _wsDataChanged = true;
        }
      }
      if (changedProperties.indexOf('ELEMENTS_PANEL') > -1) {
        vm.elementPanel.initState()
      }
      _updateSortInfo();
    }

      if ((changes.hasOwnProperty('tasks_total_count') || changes.hasOwnProperty('actions_total_count')) && vm.dataview.draft_mode == 'off') {
        _updateWSGrid = true;
      }

      /**Update condition to consider pipeline status because sometimes the backend finishes the work so quickly that wksp status change does not reflect on the frontend
       * Consider Scenario of MVP-5625 where publish is added, removed in auto run off and pipeline is submitted.
       * Here sometimes the resources/ API response for dataview keeps showing status as ready. So frotnend does not detect the change.
       * and updateDataviewData is not triggered and in turn the task and actions are not fetched and pipeline looks stale.
       */
      if (isDataviewPipelineToBeTracked()) {
        trackDataviewPipeline()
      } else if (vm.dataview.status == c.wsStatus.ready) {
        $timeout.cancel(vm.pipelineRunningLoader.cancelTimeout);
        if (vm.waitingForTaskCompletion) {
          _updateWSGrid = true;
          vm.waitingForTaskCompletion = false;
        }
        if (vm.task_panel.resetting) {
          _postDataviewReset();
        }
        vm.wkspTaskStatusManager.setPolling(false);
        if (_updateWSGrid) {
          vm.dataViewGrid.isProcessing = _wsDataChanged
          updateDataviewData(_wsDataChanged, false, false, false).then(() => {
            vm.pipelineRunningLoader.show = false;
            vm.dataViewGrid.isProcessing = false
            if (changedProperties?.includes('COLUMN_NAMES')) {
              TaskService.updateTasksDescription();
            }
          });
        } else if (!vm.dataViewGrid.isProcessing) {
          vm.pipelineRunningLoader.show = false;
        }
      }
    }

    function _postTaskSubmission() {
      if (vm.task_panel.latest_params) {
        var affectedColumns = taskDescriber.getAffectedColumns(vm.task_panel.latest_params);
        vm.dataViewGrid.selectColumns(affectedColumns.destinations)
        var scrollTo = affectedColumns.destinations[0];
        vm.dataViewGrid.scrollToColumn(scrollTo)
        $timeout(function () {
          vm.dataViewGrid.selectColumns([])
        }, 3000);

      }
    }

    function updateDataviewData(dataChanged = undefined, updateDependencyDict = undefined, forceDataUpdate = false, includeRowCount = false) {
      /*
      updateDependencyDict: if true, then on_update_tasks_actions_dependencies event will be fired which will update
      tasks dependency dictionary of the view
       */
      var deferred = $q.defer();
      vm.dataview.get_data(forceDataUpdate, includeRowCount).then(async function (wkspDataResponse) {
        vm.mainGridUnit.dataview = vm.dataview;
        vm.mainGridUnit.metadata = vm.dataview.metadata;

        // This information is only available after modifying column order for the first time. Hence adding here.
        // Required for shift+select of columns in grid
        vm.dataViewGrid.transformGridMetaData(vm.dataview)

        if (appMetaInfo.isPageLoading) {
          appMetaInfo.pageLoaded();
        }
        $timeout(function () {
          TaskService.update_list();
          ActionService.update_list();
        });

        /*
        whenever dataview updates, tasks dependencies might also change, in that case get the updated tasks dependencies
        and do necessary steps(for example exit reordering mode)
         */
        if (updateDependencyDict) {
          DataviewService.fire_on_update_tasks_actions_dependencies(true, vm.dataview.id);
        }
        vm.elementService.updateList().then(
          function () {
            vm.elementPanel.loaded();
            // if (dataChanged) {
            //  fire elemtn update unconditionally so that sufficient requests are made to query the latest data for them.
            //  Its doubted that metrics do not update immediately when wksp is modified and some queries giveback stale data
            //  adding more queries helps fetch latest data
            angular.forEach(vm.elementService.mainList, function (element) {
              element.fireUpdate()
            })
            // }
          },
          vm.elementPanel.loaded
        );
        _updateSortInfo();


        if (dataChanged) {
          vm.showGridLoader = false
        if(vm.dataview.draft_mode!=='dirty'){
          vm.dataViewGrid?.resetDataViewGrid()
        }
          DataviewLayoutService.dataviewSwitched();
          deferred.resolve(wkspDataResponse)
        } else {
          deferred.resolve(wkspDataResponse)
        }
        $timeout(function () {
          if (vm.task_panel.waiting_for_completion) {
            $timeout(_postTaskSubmission, 1000);
            vm.task_panel.waiting_for_completion = false;
          }
        }, 400);
        
        if (isDataviewPipelineToBeTracked()) {
          trackDataviewPipeline()
        }
      });
      return deferred.promise
    }

    function _updateSortInfo() {
      vm.sortInfo = [];
      if (vm.dataview.display_properties.SORT) {
        angular.forEach(vm.dataview.display_properties.SORT, function (sort) {
          let col_info = utils.metadata.get_column_by_internal_name(vm.dataview.metadata, sort[0]);
          if (col_info) {
            var sortItem = {
              column: utils.metadata.get_column_by_internal_name(vm.dataview.metadata, sort[0]),
              direction: sort[1]
            };
            vm.sortInfo.push(sortItem)
          }
        });
      }
      let dirMap = {
        'ASC': '↑',
        'DESC': '↓'
      };
      let sortsAsText = vm.sortInfo.map(x => x.column.display_name + ' ' + dirMap[x.direction]);
      let newSortAsText = sortsAsText.join(', ');
      vm.sortInfoAsText = newSortAsText;
    }

    function _postDataviewReset() {
      if (vm.task_panel.resetting) {
        vm.elementPanel.current_query = null;
        // vm.elementPanel.close();
        vm.task_panel.resetting = false
      }
    }


    function _onDataviewSwitch() {
      resources.update();
      DataviewLayoutService.dataviewSwitched();
      if (isDataviewPipelineToBeTracked()) {
        trackDataviewPipeline()
      }
      vm.elementService.updateList().then(vm.elementPanel.fireRenderComplete);
      vm.task_panel.checkForPasteableItem()
      /**  Update task and action service so steps panel controller can be updated with latest state of the pipeline
       * otherwise if a pipeline is submitted for execution in view1 and then user switch to another view 2
       * When user comes back to the view1 after its pipeline has finished execution
       * User still sees the small inline loader in the pipeline because pipeline is still in previous state.
       * */
      TaskService.update_list();
      ActionService.update_list();
    }

    // TODO: verify this if needed to highlight the columns
    // vm.dataview.onColumnsAdded('when_waiting_for_columns_to_change', function (changedColumns) {
    //   highlightAndScrollColumns(changedColumns)
    // });

    function highlightAndScrollColumns(columns, affectedClass = undefined, delay = undefined, duration = undefined, source?) {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.highlightAndScrollColumns,
        {
          eventOrigin: "dataview." + source
        });

      // highlight logic
      vm.dataViewGrid.selectColumns(columns);
      if (columns && columns.length) {
        vm.dataViewGrid.scrollToColumn(columns[columns.length - 1])
      }
    }

    function removeSort() {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.removeDataviewSort,
        {
          eventOrigin: "dataview.statusBar"
        });
      vm.dataview.setDisplayProperties({'SORT': []}).then(setSortSuccess, setSortFailure);

      function setSortSuccess() {
        resources.update();
        vm.dataViewGrid.resetDataViewGrid()
      }

      function setSortFailure() {
        Notification.error('Could not remove Sort, please try again.')
      }
    }

    function rerunDataview(force_run?) {
      $timeout(function () {
        vm.showGridLoader = true
      }, 0);
      dataviewRerunService.rerun(vm.dataview.id, force_run).then(function () {
        // Notification.success('Applying new data...');
        resources.update();
        vm.globalFilter.deselectAll()
      })
    }


    function enterPreviewMode() {
      vm.preview_mode_changes_submitted = true;
      var actionsPresent = Object.keys(ActionService.list).length > 0 ? true : false
      var information = ActionService.getSummaryOfActions()
      wkspPreviewService.showConfirmation(false, true, actionsPresent, information).then(function () {
        wkspPreviewService.enter(vm.dataview.id, dataviewConfig.rowLimitForSample).then(successCallback);
        vm.preview_mode_changes_submitted = true;
      }, function () {
        vm.preview_mode_changes_submitted = false;
      });
    }

    function exitPreviewMode() {
      if (Object.keys(vm.task_list).length) {
        var actionsPresent = Object.keys(ActionService.list).length > 0 ? true : false
        var information = ActionService.getSummaryOfActions()
        wkspPreviewService.showConfirmation(true, true, actionsPresent, information).then(_exit_cb)
      } else {
        _exit_cb()
      }

      function _exit_cb() {
        vm.preview_mode_changes_submitted = true;
        wkspPreviewService.exit(vm.dataview.id).then(successCallback)
      }
    }

    function successCallback(data) {
      if (data.hasOwnProperty('future_id')) {
        get_data_tracker(data).then(function (response) {
          DataviewService.fire_on_update_tasks_actions_dependencies(true, vm.dataview.id);
        });
      }
    }

    function onSuccess() {
      resources.update();
    }

    function get_data_tracker(data) {
      var deferred = $q.defer();
      FutureService.track(data.future_id, data_tracker);

      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          onSuccess();
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }

      return deferred.promise;
    }

    function handlePreviewModeChanges(option) {
      if (option === vm.dataview.in_preview_mode || vm.dataview.status == c.wsStatus.processing || vm.dataview.draft_mode == 'dirty') {
        return
      }
      if (option) {
        enterPreviewMode()
      } else {
        exitPreviewMode()
      }
      vm.wkspTaskStatusManager.setPolling(true)
    }

    function exportDialog() {
      modalService.exportDialog(vm.dataview, vm.globalCondition);
    }

    function searchTaskMenu() {
      // If search string is empty
      if (vm.actionAndStepMenu.searchString.length === 0) {
        vm.task_menu_items = vm.dataviewConfig.newTaskMenu;
      } else {
        vm.task_menu_items = this.getFilteredTasks();
      }
    }

    var filtered_tasks = [];

    function getFilteredTasks(arr) {
      // if(vm.actionAndStepMenu.searchString.length===0){
      //   filtered_tasks=[];
      // return;
      // }

      if (!arr) {
        arr = vm.dataviewConfig.newTaskMenu;
        filtered_tasks = [];
      }

      angular.forEach(arr, function (item) {
        if (vm.actionAndStepMenu.searchString.length > 0) {
          if (item.subOps.length == 0) {
            for (let i = 0; i < item.op.info.tags.length; i++) {
              if (item.op.info.tags[i].indexOf(vm.actionAndStepMenu.searchString.toLowerCase()) > -1) {
                if (filtered_tasks.indexOf(item) == -1)
                  filtered_tasks.push(item);
              }
            }
            let label = item.op.label.toLowerCase().split(" ");
            angular.forEach(label, function (each_label_word) {
              if (each_label_word.indexOf(vm.actionAndStepMenu.searchString.toLowerCase()) > -1) {
                if (filtered_tasks.indexOf(item) == -1) {
                  filtered_tasks.push(item);
                }
              }
            });
          } else {
            getFilteredTasks(item.subOps)
          }
        }
      });
      return filtered_tasks;
    }

    function resetSearchString(open) {
      if (open) {
        vm.actionAndStepMenu.searchString = undefined;
        vm.task_menu_items = vm.dataviewConfig.newTaskMenu;
      }
    }

    function alertsDialog() {
      modalService.alertsDialog(vm.dataview, vm.globalCondition);
    }

    function ruleTooltip(draft_mode, preview_mode, default_tooltip, pipeline_status?) {
      var tooltip;
      if (pipeline_status && pipeline_status.status == 'error') {
        tooltip = pipeline_status.tooltip
      } else {
        if (draft_mode.status == 'dirty') {
          tooltip = draft_mode.tooltip;
        } else {
          if (preview_mode.status) {
            tooltip = preview_mode.tooltip;
          } else {
            tooltip = default_tooltip;
          }
        }
      }

      return tooltip;
    }

    function copyUrl() {
      utils.copyToClipboard(vm.actionService.liveCsvLink);
      toastNotification.success('Copied to clipboard');
    }

    function checkAndOpenIfSingularityDialog() {
      if ($location.search()['datapush']) {
        var params = $location.search();
        params.activeOption = 'api';
        Singularity.selected_integration_key = params['datapush'];
        Singularity.preselect_integration_from_oauth2_flow = true;
        let now: any = new Date();
        if (!vm.dataPushLastAutoOpened || now - vm.dataPushLastAutoOpened > 3000) {
          vm.dataPushLastAutoOpened = now;
          var editingActionId = undefined;
          if ($location.search()['editId']) {
            editingActionId = params['editId'];
            $location.search('editId', null);
          }
          toggleDataPushDialog('api', params, editingActionId);
          $location.search('datapush', null);
          $location.search('activeOption', null);
        }
      }
    }

    function toggleDataPushDialog(section, params, editingActionId) {
      var actionName = params['datapush'];
      var editingAction = undefined;
      if (editingActionId) {
        vm.actionService.getAction(editingActionId).then(function (trigger) {
          editingAction = trigger;
          vm.stepsPanel.open();
          return vm.action_panel.open(actionName, undefined, editingAction);
        });
      } else {
        return vm.action_panel.open(actionName, undefined, editingAction);
      }
    }


    /******************* Start Data Grid **************/
    vm.dataViewGrid = {
      mmDataGridRef: null,
      columnDefs: [],
      isProcessing: false,
      transformGridMetaData(dataview) {
        const viewInfo = transformGridColumnsMetaData(dataview)

        function computedColumns(display_properties, user_display_properties, metadata) {
          const hiddenColumnIds = display_properties.HIDDEN_COLUMNS || []
          const exploreCardColumnIds = user_display_properties?.EXPLORE_PANEL?.items.map((item) => item.column) || []
          return metadata.map(({type, internal_name, display_name}) => ({
            id: internal_name,
            order: parseInt(_.findKey(display_properties.COLUMN_ORDER, (value, key) => value === internal_name)),
            name: display_name,
            type: type.toLowerCase(),
            show: !hiddenColumnIds.includes(internal_name),
            exploreCard: exploreCardColumnIds.includes(internal_name),
            selected: false
          })).sort((curentValue, nextValue) => curentValue.order - nextValue.order)
        }

        let columns = computedColumns(vm.dataview.display_properties, vm.dataview?.user_display_properties, vm.dataview.metadata)
        $store.commit('dataEditor/setColumns', { viewId: vm.dataview.id, columns })
        $store.commit('dataEditor/setColumnConfig', viewInfo.columnConfig)
        $store.commit('dataEditor/setRowCount', vm.dataview.row_count)
        $store.commit('dataEditor/setSequence', vm.dataview.stepPreview.sequence)
        $store.commit('dataEditor/setIsPreview', vm.dataview.stepPreview.inPreviewMode)
        $store.commit('exploreSection/setIsOpen', vm.explorePanel.config.open)
      },
      hideOrShowSelectedCols(option, columns) {
        vm.dataViewGrid.isProcessing = true
        var colOrder = vm.dataview.display_properties.COLUMN_ORDER || {};
        var colNames = vm.dataview.display_properties.COLUMN_NAMES || {};
        var hiddenColumns = vm.dataview.display_properties.HIDDEN_COLUMNS || [];

        if (option == 'hide') {
          hiddenColumns = hiddenColumns.concat(columns);
        } else if (option == 'show') {
          hiddenColumns = _.difference(hiddenColumns, columns);
        } else if (option == 'showOnly') {
          hiddenColumns = _.difference(Object.values(vm.dataview.display_properties.COLUMN_ORDER), columns);
        } else if (option == 'hideOnly') {
          hiddenColumns = columns;
        }

        var patch = {
          'COLUMN_ORDER': colOrder,
          'COLUMN_NAMES': colNames,
          'HIDDEN_COLUMNS': hiddenColumns
        };
        vm.dataview.setDisplayProperties(patch).then(saveSuccess, saveError);

        function saveSuccess() {
          vm.dataViewGrid.isProcessing = false
        }

        function saveError(data) {
          vm.dataViewGrid.isProcessing = true
          Notification.error(data.data.ERROR_MESSAGE);
        }
      },
      onInit(ref) {
        vm.dataViewGrid.mmDataGridRef = ref
      },
      onExploreIconToggle(exploreCardColumns, columnId) {
        const activeDataviewId = parseInt(window.location.href.split('/').pop())
        if (vm.dataview.id !== activeDataviewId) return
        vm.dataview.exploreButtonClickEvent.fire_event(columnId, 'exploreColumnMenu')
        analyticsService.userEventTrack(c.userEvents.exploreCards.exploreToggled,
          {eventOrigin: 'dataview.columnMenu'});
      },
      handleMetaDataChanges() {
        vm.columnBrowser.columns = vm.dataview.metadata.map((metaData) => {
          const type = {
            NUMERIC: 'number',
            TEXT: 'text',
            DATE: 'date'
          }[metaData.type]

          return {
            id: metaData.internal_name,
            text: metaData.display_name,
            type: type,
            icon: type,
            show: !vm.columnBrowser.config.hiddenColumnIds.includes(metaData.internal_name),
            exploreCard: vm.columnBrowser.config.exploreCardColumnIds.includes(metaData.internal_name),
            selected: false
          }
        })
      },
      onExploreCardRemove(columnId) {
        $store.commit('dataEditor/setExploreCardColumn', {selected: false, columnId})
        analyticsService.userEventTrack(c.userEvents.exploreCards.remove, {eventOrigin: 'dataview.columnMenu'});
      },
      resetDataViewGrid() {
        const activeDataviewId = parseInt(window.location.href.split('/').pop())
        if (vm.dataview.id !== activeDataviewId) return
        vm.dataViewGrid.mmDataGridRef?.resetDataSource()
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.resetDataGrid, {eventOrigin: "dataEditor.grid"});
      },
      onColumnMenuAction(event) {
        const activeDataviewId = parseInt(window.location.href.split('/').pop())
        if (vm.dataview.id !== activeDataviewId) return
        let params = {};
        let col
        let columnMenuAction =
          {
            new: 'addColumn',
            calculateRunningTotal: 'rTotal',
            ranking: 'rank',
            performMathFunction: 'math',
            applyFilter: 'filter',
            convertType: 'convert',
            extractText: 'extract_text',
            labelInsertValue: 'insert',
            formatTextFormatApply: 'format_text',
            formatMoreOptions: 'format_text',
            remove: 'delete',
            extractDate: 'extract_date',
            incrementDecrementDate: 'increment_date'
          }[event.name] || event.name
        switch (columnMenuAction) {
          case 'sort':
            vm.dataViewGrid.isProcessing = true;
            let sortType = {
              "Descending": "DESC",
              "Ascending": "ASC",
            }[event.value] || "UPPER";
            const setSortSuccess = () => {
              analyticsService.userEventTrack(c.userEvents.dataviewEvents.sortDataview, {eventOrigin: "dataEditor.grid"});
              resources.update();
              vm.dataViewGrid.resetDataViewGrid()
              vm.dataViewGrid.isProcessing = false;
            }

            const setSortFailure = () => {
              Notification.error('Could not apply Sort, please try again.')
              vm.dataViewGrid.isProcessing = false;
            }

            var patch = {SORT: [[event.colId, sortType]]}
            vm.dataview.setDisplayProperties(patch).then(setSortSuccess, setSortFailure)
            break
          case 'rename':
            break
          case 'hide':
            vm.dataViewGrid.hideOrShowSelectedCols(columnMenuAction, [event.colId])
            analyticsService.userEventTrack(c.userEvents.dataviewEvents.columnMenuEvents.hideColumn, {
              menuType: "columnMenu",
              eventOrigin: "dataEditor.grid"
            });
            break
          case 'format_text':
            col = vm.dataview.metadata.find((col) => col.internal_name === event.colId)
            let caseFormat = {
              "Upper": "UPPER",
              "Lower": "LOWER",
              Capitalized: "TITLE"
            }[event.value.changeCase] || "UPPER";
            let format = {
              changeCase: caseFormat,
              normalizeWhitespaces: event.value.normalizeWhitespaces
            }
            if (event.name === "formatMoreOptions") {
              params = vm.columnMenu.getParams(columnMenuAction, col, format)
              vm.task_panel.open(columnMenuAction, params)
            } else {
              vm.columnMenu.submit(columnMenuAction, col, format, vm.dataview)
            }
            break
          case 'formatDate':
            vm.dataViewGrid.isProcessing = true;
            let dateFormat = {
              date_format: event.value.format
            }
            var new_format_info = _.cloneDeep(vm.dataview.display_properties.FORMAT_INFO)
            new_format_info[event.colId] = dateFormat
            vm.dataview
              .setDisplayProperties({FORMAT_INFO: new_format_info})
              .then(() => {
                vm.dataViewGrid.isProcessing = false;
                vm.dataViewGrid.resetDataViewGrid()
              })
              .catch((error) => {
                vm.dataViewGrid.isProcessing = false;
                Notification.error('Could not apply Format, please try again.')
              })
            break
          case "formatNumber":
            vm.dataViewGrid.isProcessing = true;
            const newFormat = event.value.format
            let numFormat = {
              comma_separated: newFormat.separator,
              currency_symbol: newFormat.currency,
              decimal_spec: newFormat.decimalPrecision,
              enabled: true,
              is_percentage: newFormat.percentage,
              numtype: 'float',
            }
            var new_format_info = _.cloneDeep(vm.dataview.display_properties.FORMAT_INFO)
            new_format_info[event.colId] = numFormat
            vm.dataview
              .setDisplayProperties({FORMAT_INFO: new_format_info})
              .then(() => {
                vm.dataViewGrid.isProcessing = false;
                vm.dataViewGrid.resetDataViewGrid()
              })
              .catch((error) => {
                vm.dataViewGrid.isProcessing = false;
                Notification.error('Could not apply Format, please try again.')
              })

            break
          case 'addColumn':
            params['colOrder'] = vm.dataview.display_properties.COLUMN_ORDER
            col = vm.dataview.metadata.find((col) => col.internal_name === event.colId)
            params = vm.columnMenu.getParams(columnMenuAction, col, params)
            vm.task_panel.open(columnMenuAction, params)
            break
          default:
            col = vm.dataview.metadata.find((col) => col.internal_name === event.colId)
            params = vm.columnMenu.getParams(columnMenuAction, col)
            vm.task_panel.open(columnMenuAction, params)
            break
        }
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.columnMenuEvents.selectColumnMenu, {
          menuType: "Column Menu",
          eventOrigin: "dataview",
          columnMenuName: columnMenuAction
        });
      },
      selectColumns(columnIds = []) {
        const activeDataviewId = parseInt(window.location.href.split('/').pop())
        if (vm.dataview.id !== activeDataviewId) return
        $store.dispatch('dataEditor/selectColumns', columnIds);
      },
      scrollToColumn(selectedColumn) {
        const activeDataviewId = parseInt(window.location.href.split('/').pop())
        if (vm.dataview.id !== activeDataviewId) return
        vm.dataViewGrid.mmDataGridRef.scrollToColumn(selectedColumn);
      },
      onMultipleColumnSelectedAction(data) {
        const activeDataviewId = parseInt(window.location.href.split('/').pop())
        if (vm.dataview.id !== activeDataviewId) return
        let params = {};
        let col;
        let selectedColumnMenuAction =
          {
            showOnlyThese: 'showOnly',
            hideOnlyThese: 'hideOnly',
            remove: 'delete',
            search: 'replace'
          }[data.action] || data.action
        switch (selectedColumnMenuAction) {
          case 'replace':
            const selectedColumnIds = data.selectedColumnIds.filter(colId => vm.dataview.metadata.find(col => col.internal_name === colId).type === 'TEXT')
            params = {
              REPLACE: {
                SOURCE: selectedColumnIds,
                VALUE_PAIR: [{SEARCH_VALUE: '', REPLACE_VALUE: ''}],
                MATCH_CASE: true,
                MATCH_WORDS: true,
              },
              CONDITION: null,
            }

            vm.task_panel.open(selectedColumnMenuAction, params)
            break
          case 'delete':
            params = {DELETE: data.selectedColumnIds}
            vm.task_panel.open(selectedColumnMenuAction, params)
          default:
            vm.dataViewGrid.isProcessing = true;
            vm.dataViewGrid.hideOrShowSelectedCols(selectedColumnMenuAction, data.selectedColumnIds)
            break
        }
      },
      onCreateMetricAction(data) {
        const activeDataviewId = parseInt(window.location.href.split('/').pop())
        if (vm.dataview.id !== activeDataviewId) return

        function addCallback() {
          vm.elementPanel.open()
          vm.elementService.pollForUpdatesUntilReady().then(function () {
            toastNotification.success('Metric added')
          })
        }

        function addFailure() {
          toastNotification.error('The Metric could not be created, please try again')
        }

        if (data.name == "moreOptions") {
          vm.columnMenu.openCreateMetricModal(vm.dataview, data.value);
        } else {
          let numFormat = {
            comma_separated: data.value.metric.format.separator,
            currency_symbol: data.value.metric.format.currency,
            decimal_spec: data.value.metric.format.decimalPrecision,
            enabled: true,
            is_percentage: data.value.metric.format.percentage,
            numtype: 'float',
          }
          vm.columnMenu.createMetric(vm.dataview, data.value, numFormat, data.value.metric.format.shortForm).then(addCallback, addFailure)
        }
      },
    }

    vm.mainGridUnit.vueGridInstance = vm.dataViewGrid;

    vm.task_panel.taskTemplateReadyEvent.add_callback("column-browser", function () {
      const {taskPanelParams, needTaskPanel} = vm.columnBrowser
      if (needTaskPanel) {
        vm.columnBrowser.needTaskPanel = false;
        const {columnIds, taskName} = taskPanelParams
        const manager = vm.task_panel.manager

        if (taskName === "delete") {
          manager.deleteColumnInputArray = columnIds
        } else if (taskName === "replace") {
          columnIds.forEach(columnId => manager.helper.toggleSelection(columnId))
        } else if (taskName === "convert") {
          const convertInputArray = manager.convertInputArray
          columnIds.forEach((columnId, index) => {
            const column = manager.metadata.find(e => e.internal_name === columnId)
            const input = convertInputArray[index]
            input.column = column
            input.columnSelected()
            manager.addColumn()
          })
          convertInputArray.pop()
        }
      }
    })
    /******************* End Data Grid **************/


    /******************* Start Action Bar **************/
    vm.userWorkspace = UserWorkspace

    const ACTION_BAR_LEGACY_TASK_NAME_MAP = {
      apply_filter: 'filter',
      find_and_replace: 'replace',
      label_insert_values: 'insert',
      show_top_bottom_rows: 'limit',
      group_and_aggregate: 'pivot',
      columns_to_rows: 'unnest',
      remove_duplicate_rows: 'duplicates',
      extract_json: 'json_handle',
      window_function: 'window_function',
      concatenate_values: 'collapseRows',
      create_column: 'addColumn',
      duplicate_column: 'copy',
      bulk_duplicate_columns: 'bulkCopy',
      fill_missing_values: 'fillValues',
      combine_multiple_columns: 'combine',
      convert_column_type: 'convert',
      remove_column: 'delete',
      split_multiple_columns: 'split',
      perform_math_functions: 'math',
      obtain_large_or_small_values: 'small_or_large',
      extract_text: 'extract_text',
      text_formatting: 'format_text',
      increment_decrement_date_values: 'increment_date',
      extract_date_part: 'extract_date',
      calculate_date_difference: 'date_diff',
      lookup: 'lookup',
      join: 'join'
    }

    vm.mmFrontendWrapper = {
      taskPanel: {
        name: '',
        params: {},
        preloadManagerVariables: false,
        open(taskName, params) {
          const taskPanel = vm.mmFrontendWrapper.taskPanel
          taskPanel.name = taskName
          taskPanel.params = params
          taskPanel.preloadManagerVariables = true;
          vm.task_panel.open(taskName)
        },
        handleManager() {
          const taskPanel = vm.mmFrontendWrapper.taskPanel
          if (taskPanel.preloadManagerVariables) {
            taskPanel.preloadManagerVariables = false;
            const manager = vm.task_panel.manager
            taskPanel.managers[taskPanel.name](manager, taskPanel.params)
          }
        },
        managers: {
          // Column Browser - Remove
          delete(manager, params) {
            manager.deleteColumnInputArray = params.columnIds
          },
          // Column Browser - Find and Replace
          replace(manager, params) {
            params.columnIds.forEach(columnId => manager.helper.toggleSelection(columnId))
          },
          // Column Browser - Convert column type
          convert(manager, params) {
            const convertInputArray = manager.convertInputArray
            params.columnIds.forEach((columnId, index) => {
              const column = manager.metadata.find(e => e.internal_name === columnId)
              const input = convertInputArray[index]
              input.column = column
              input.columnSelected()
              manager.addColumn()
            })
            convertInputArray.pop()
          },
        }
      },
    }

    vm.actionBar = {
      $refs: {},
      legacyHandlers: {
        openPipeline: vm.stepsPanel.open,
        togglePipeline: vm.stepsPanel.toggle,
        toggleExplore: vm.explorePanel.toggleExplore,
        trackFuture: FutureService.track,
        openAlertsDialog: vm.alertsDialog,
        openTaskOrActionPanel: actionBarOpenTaskOrActionPanel,
        openTaskPanelWithParams: vm.mmFrontendWrapper.taskPanel.open,
        updateDataviewData: updateDataviewData,
        openExploreColumn: (columnId) => vm.dataview.exploreButtonClickEvent.fire_event(columnId, 'columnBrowser'),
        actionServiceUpdateList: ActionService.update_list,
      },
      mounted: (actionBarRefs) => vm.actionBar.$refs = actionBarRefs
    }

    function actionBarOpenTaskOrActionPanel(item) {
      vm.task_menu_is_open = false;
      vm.action_panel.close();
      vm.task_panel.close();

      switch (item) {
        case 'crosstab':
          vm.action_panel.open('pivot_table', dataviewConfig.actionConfig['pivot_table'].defaultParams)
          break;

        case 'branchout':
          vm.action_panel.open('internal_dataset', {target_properties: {DS_NAME: 'Result Dataset'}})
          break;
      case 'export_to_project':
        vm.action_panel.open('export_to_project',dataviewConfig.actionConfig['export_to_project'].defaultParams)
        break;

        case 'bulk_replace':
          vm.task_panel.open('bulkReplace', {
            REPLACE: {
              SOURCE: [],
              MAPPING: [{REPLACE_VALUE: null, SEARCH_VALUE: []}],
              MATCH_CASE: true,
              MATCH_WORDS: true
            },
            _UI_CUSTOM: 'groupReplace'
          })
          break;

        default:
          vm.task_panel.open(ACTION_BAR_LEGACY_TASK_NAME_MAP[item])
      }
    }

  vm.action_panel.publishCredsReadyEvent.add_callback("show_database_credentials_modal", function(credentials) {
    vm.actionBar.$refs.export.$refs.exportToDatabase.showMammothManagedCredentials(credentials)
  });

  const handleEditOrPaste = (data) => {
    if (data.handler_type === 'sftp') {
      vm.actionBar.$refs.export.$refs.sftp.edit({
        actionId: data.id,
        targetProperties: data.target_properties,
        keepAtPipelineEnd: data.sequence == null
      });
    } else {
      vm.actionBar.$refs.export.$refs.exportToDatabase.editDatabaseCredntials({
        actionId: data.id,
        databaseHandler: data.handler_type,
        targetProperties: data.target_properties,
        keepAtPipelineEnd: data.sequence == null
      })
    }
  }
  vm.action_panel.actionPanelEditEvent.add_callback("show_existing_credentials_modal", handleEditOrPaste);
  vm.action_panel.actionPanelPasteEvent.add_callback("show_existing_credentials_modal", handleEditOrPaste)
  vm.task_panel.taskTemplateReadyEvent.add_callback("column-browser", vm.mmFrontendWrapper.taskPanel.handleManager)
  /******************* End Action Bar **************/
}
}
/**
 * @ngInject
 */
NumericVal.$inject = ['$filter'];
export function NumericVal ($filter) {
  return function (val, decimal_spec) {
    if (val === null || val === undefined) {
      return val
    }

    if (val.includes('e') || val.includes('E')) {
      return val
    } else {
      var newVal = $filter('number')(val, decimal_spec);
      if (newVal) {
        return newVal
      } else {
        return val
      }
    }
  }
}
