<template>
  <mm-modal
    v-model="computedShowModal"
    :title="$t('action_bar.export.export_to_database.existing_database.big_query.delete.title')"
    width="373"
  >
    <p class="mm-text--caption-regular d-flex align-center">
      <mm-icon class="m-r-2 flex-shrink-0" name="information" />
      {{ $t('action_bar.export.export_to_database.existing_database.big_query.delete.description') }}
    </p>

    <template #actions>
      <mm-button
        class="flex-grow-1 m-r-4"
        :label="$t('dictionary.cancel')"
        objective="tertiary"
        @click="computedShowModal = false"
      />
      <mm-button
        class="flex-grow-1"
        :label="$t('global.dictionary.remove')"
        :loading="removeLoading"
        objective="destructive"
        @click="onRemove"
      />
    </template>
  </mm-modal>
</template>

<script>
import actionBarApi from '@/modules/data-editor/action-bar/action-bar.api'

export default {
  name: 'action-bar-export-big-query-delete',
  props: {
    value: Boolean,
    identityKey: String
  },
  data: () => ({
    removeLoading: false
  }),
  computed: {
    computedShowModal: {
      get() {
        return this.value
      },
      set(showModal) {
        this.$emit('input', showModal)
      }
    }
  },
  methods: {
    async onRemove() {
      this.removeLoading = true
      try {
        await actionBarApi.deleteBigQueryIdentity(this.$store.state.projectId, this.identityKey)
        this.$emit('deleted')
      } catch (error) {
        this.$toast.show({ content: error.message, status: 'error' })
      }
      this.removeLoading = false
      this.computedShowModal = false
    }
  }
}
</script>
