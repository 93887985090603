import * as moment from 'moment';
import * as angular from 'angular';
import * as _ from 'lodash-es';
import VueDataLibraryApi from '../../../vueApp/src/mm-frontend/src/modules/data-library/api/data-library.api'
import { CONNECTORS } from '../../../vueApp/src/mm-frontend/src/constants'
import { decode } from 'html-entities'


dsPreviewController.$inject = ['$scope', 'navigationService', 'fileUploadService', 'modalService', 'DSBatchService', 'utils', '$http',
  'DatasourceService', 'DataviewService', 'TaskServiceFactory', 'c', 'FileService', 'VuexStore',
  'appHelperService', 'config', 'webhooks',  'analyticsService', 'rawData',
  'unstructureDataModal', 'landingList', 'landingSelection', 'UserWorkspace', 'keyboardEvents', 'NotificationService', 'Singularity', 'FutureService', '$q', 'toastNotification',
  '$resource'] as ReadonlyArray<string>;

export function dsPreviewController($scope, navigationService, fileUploadService, modalService, DSBatchService, utils, $http,
  DatasourceService, DataviewService, TaskServiceFactory, c, FileService, $store,
  appHelperService, config, webhooks, analyticsService, rawData,
  unstructureDataModal, landingList, landingSelection, UserWorkspace, keyboardEvents, NotificationService,
  Singularity, FutureService, $q, toastNotification, $resource) {
  this.$onInit = function () {
    var pvm = this,
      deregister,
      wsUpdateDeregister,
      uploadingFileUpdateDeregister,
      fileObjectUpdateDeregister,
      dsUpdateDerigister,
      dsListUpdateDerigister;
    pvm.$store = $store
    pvm.selectedBatches = [];
    pvm.batchIsThere = batchIsThere;
    pvm.previewShown = false;
    pvm.dataUrls = [];
    pvm.batch_ids = [];
    pvm.selectAll = false;
    pvm.collapseBatches = true;
    pvm.togglePrev = false;
    pvm.toggleProps = false;
    pvm.toggleFolder = false;
    // for query panel
    pvm.dsConfig = null;
    pvm.utils = utils;
    pvm.reset = reset;
    pvm.updateSchedule = updateSchedule;
    pvm.editConnection = editConnection;
    pvm.editSchedule = editSchedule;
    pvm.editMetrics = editMetrics;
    pvm.openFirstDataview = openFirstDataview;
    pvm.openDataview = openDataview;
    pvm.goToLabel = goToLabel;
    pvm.addData = addData;
    pvm.deleteDs = deleteDs;
    pvm.deleteWs = modalService.deleteWs;
    pvm.openDSCombineModal = modalService.combineDS;
    pvm.isCombineWithDatasetModalOpen = false;
    pvm.onCombineDsModalChange = onCombineDsModalChange;
    pvm.deselect = deselect;
    pvm.deleteLabel = (label) => {
      modalService.deleteLabel(label).then(deselect)
    };
    pvm.init = init;
    pvm.refreshBatchInfo = refreshBatchInfo;
    pvm.resetBatchCallback = resetBatchCallback;
    pvm.dropSelectedBatch = dropSelectedBatch;
    pvm.dropBatches = dropBatches;
    pvm.multiModifyBatchState = multiModifyBatchState;
    pvm.modifyBatchesOption = modifyBatchesOption;
    pvm.refreshCloudData = refreshCloudData;
    pvm.validateSheetSelection = validateSheetSelection;
    pvm.syncData = syncData;
    pvm.openRawDataModel = openRawDataModal;
    pvm.openUnstructureDataModal = openUnstructureDataModal;
    pvm.sheetSelectionInfoModels = {};  // needed to keep selections persistent else will be lost when list repopulates
    pvm.updateFromSourceWebhookStore = updateFromSourceWebhookStore;
    pvm.getWebhookAbsUrl = getWebhookAbsUrl;
    pvm.getWebhookMode = getWebhookMode;
    pvm.saveWebhookMode = saveWebhookMode;
    pvm.modeOptions = c.modeOptions;
    pvm.saveWebhookOriginString = saveWebhookOriginString;
    pvm.toggleWebHookSecurityHeader = toggleWebHookSecurityHeader;
    pvm.resourceTypes = {};
    pvm.UserWorkspace = UserWorkspace;
    pvm.navigationService = navigationService;
    pvm.notificationService = NotificationService;
    pvm.isKeyDown = keyboardEvents.isKeyDown;
    pvm.landingSelection = landingSelection;
    pvm.threeDotsMenu = {
      show: false,
      toggle: toggleThreeDotsMenu
    };
    pvm.sanitization = sanitization;
    pvm.openSource = openSource;
    pvm.hasLinkToSource = hasLinkToSource;
    pvm.getBatchStyles = getBatchStyles;
    pvm.show_raw_data = false;
    pvm.batch_grid_loader = {
      show: false,
      hideOverlay: false
    };
    pvm.toggleFilesAndFoldersSection = toggleFilesAndFoldersSection;
    pvm.togglePreviewSection = togglePreviewSection;
    pvm.togglePropertySection = togglePropertySection;

    pvm.raw_grid_loader = {
      show: true,
      hideOverlay: true
    };

    pvm.ws_preview = {};

    pvm.wksp_grid_loader = {
      show: true,
      hideOverlay: true
    };
    pvm.toggleSelection = toggleSelection;
    pvm.toggleSelectAll = toggleSelectAll;
    pvm.addbatchToDataset = false;
    pvm.sourcePropsTemplate = 'batchSourceProps.tpl.html';
    pvm.batch_col_ctrl = new BatchColCtrl();
    pvm.addOrRemoveBatchCols = addOrRemoveBatchCols;
    pvm.setRowCounts = setRowCounts;
    pvm.isBatchModifying = isBatchModifying;

    pvm.vueCurrentConnector;
    pvm.vueCurrentConnection;
    pvm.vueCurrentProfile;
    pvm.vueConnectionToEdit;
    pvm.vueConnectionIntegrationInfo;
    pvm.vueIsEditConnectionModalVisible = false;
    pvm.onEditConnectionModalToggle = onEditConnectionModalToggle;
    pvm.vueQueryToEdit;
    pvm.vueIsEditQueryModalVisible = false;
    pvm.onEditQueryModalToggle = onEditQueryModalToggle;
    pvm.vueScheduleToEdit;
    pvm.vueIsEditScheduleModalVisible = false;
    pvm.onEditScheduleModalToggle = onEditScheduleModalToggle;
    pvm.vueDecode = decode;
    pvm.filePwdInput = filePwdInput;
    pvm.submitPassword = submitPassword;
    pvm.filePassword = null;
    pvm.showErrorMessage = null;
    pvm.errorMessage = null;

    function onCombineDsModalChange(value){
      pvm.isCombineWithDatasetModalOpen = value;
    }
    
    function filePwdInput(pwd) {
      pvm.showErrorMessage = null
      pvm.filePassword = pwd;
    }

    function submitPassword() {
      if (pvm.filePassword) {
        FileService.passwordValidationForProtectedFile(pvm.file.id, pvm.filePassword).then((response) => success(response))
      }

      function success(response) {
        pvm.filePassword = null;
        if (response) {
          pvm.showErrorMessage = true;
          pvm.errorMessage = response;
        }
      }

      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.filePasswordSubmit, {
        eventOrigin: "landingPage.previewPanel"
      });
    }

    function setRowCounts() {
      pvm.totalRows = 0;
      pvm.suspendedRows = 0;
      pvm.inActiveRows = 0;
      if (pvm.batches && pvm.batches.length) {
        angular.forEach(pvm.batches, function (batch) {
          pvm.totalRows += batch.count;
          if (batch.state == 'suspended') {
            pvm.suspendedRows += batch.count;
          } else if (batch.state == 'sync_pending') {
            pvm.inActiveRows += batch.count;
          }
        })
      }
    }

    function sanitization(value) {
      return $("<p/>").html(value).text();
    }

    function openSource(batch) {
      if (batch.source.Type === 'MAMMOTH_BRANCH_IN') {
        const dataview_id = batch.source["Dataview Id"];
        navigationService.open_dataview_by_id(dataview_id);
      } else if (batch.source.Type === 'DUPLICATE') {
        const datasource_id = batch.source["Datasource Id"];
        navigationService.open_first_dataview_by_ds_id(datasource_id);
      } else if (batch.source.Type === "COMBINE_DS") {
        const datasource_id = batch.source["Datasource Id"];
        navigationService.open_first_dataview_by_ds_id(datasource_id);
      }
    }

    function isDataviewIdValid(batch) {
      const dataview_id = batch.source["Dataview Id"];
      let dataview = DataviewService.get_by_id(dataview_id);
      return dataview ? true : false;
    }

    function isDataSourceValid(batch) {
      const datasource_id = batch.source["Datasource Id"];
      let datasource = DatasourceService.get_by_id(datasource_id);
      return datasource ? true : false;
    }

    function getBatchStyles(batch) {
      if (pvm.hasLinkToSource(batch)) {
        return "highlight"
      }
      return "disabled";
    }

    function isBatchModifying(batch_id) {
      return pvm.datasource?.additional_info?.modifying_batches && pvm.datasource?.additional_info?.modifying_batches?.includes(batch_id);
    }

    function hasLinkToSource(batch) {
      if (batch.source.Type === 'MAMMOTH_BRANCH_IN') {
        return isDataviewIdValid(batch);
      } else if (batch.source.Type === 'DUPLICATE' || batch.source.Type === 'COMBINE_DS') {
        return isDataSourceValid(batch);
      }
      return false;
    }

    function BatchColCtrl() {
      let self = this;
      self.state = {};
      self.origState = {};
      self.isDirty = false;
      self.reset = reset;
      self.apply = apply;
      self.checkIfDirty = checkIfDirty;
      self.setBatchColKeys = setBatchColKeys;
      self.setBatchColKeys();

      function close() {
        self.isOpen = false;
      }

      function checkIfDirty() {
        self.isDirty = !angular.equals(self.state, self.origState);
      }

      function apply() {
        analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.batchColumnsUpdate,
          {
            eventOrigin: "landingPage.previewPanel"
          });
        pvm.addOrRemoveBatchCols();
        self.isDirty = false;
        _.set(pvm, 'datasource.additional_info.batch_cols_processing', true);
        close();
      }

      function reset() {
        for (let i = 0; i < (_.map(c.batch_table_colums_list, 'key')).length; i++) {
          let column = (_.map(c.batch_table_colums_list, 'key'))[i];
          self.state[column] = self.batch_cols_in_ds_keys.indexOf(column) != -1
        }
        self.origState = _.cloneDeep(self.state);
        self.isDirty = false;
      }

      function setBatchColKeys() {
        let metadata = _.get(pvm, 'datasource.metadata', []);
        let metadata_display_names = metadata.map(function (i) {
          return i.display_name
        });
        self.batch_cols_in_ds_keys = c.batch_table_colums_list.reduce(function (acc, curr) {
          if (metadata_display_names.includes(curr.display_name)) {
            acc.push(curr.key)
          }
          return acc;
        }, []);
        self.reset();
      }
    }

    var defaultPreviewOption = 'wksp_data';
    pvm.previewDataOption = defaultPreviewOption;

    function toggleThreeDotsMenu(isVisible) {
      pvm.threeDotsMenu.show = isVisible === undefined ? !pvm.threeDotsMenu.show : !!isVisible;
    }

    pvm.onPreviewDataOptionChange = function () {
      // When switching to "Latest" option from the dropdown i.e. user wants to preview the latest dataview data.
      // If dataview preview is not available in pvm, fetch it
      if (pvm.previewDataOption == 'wksp_data' && pvm.datasource.dataviews_list.length == 1 && _.isEmpty(pvm.ws_preview)) {
        prepare_preview_grid(pvm.datasource.dataviews_list[0].id);
      }
    };

    pvm.toggleBatchGridLoader = function (is_true) {
      toggleGridLoader(pvm.batch_grid_loader, is_true);
    };
    pvm.toggleWkspGridLoader = function (is_true) {
      toggleGridLoader(pvm.wksp_grid_loader, is_true);
    };

    pvm.toggleRawGridLoader = function (is_true) {
      toggleGridLoader(pvm.raw_grid_loader, is_true);
    };

    function init() {
      pvm.resources = landingSelection.selection.items;
      if (deregister === undefined) {
        var add_callback = landingSelection.onSelectionChange;

        deregister = add_callback("_landingSelectionListener", function () {
          _initSheetSelectionModel();
          pvm.reset();
          if (pvm.datasource && pvm.datasource.hasOwnProperty('display_info')) {
            if (pvm.datasource.display_info?.hasOwnProperty('schedule_status')) {
              pvm.isScheduleRunning = pvm.datasource.display_info['schedule_status'].value == 'RUNNING';
            }
          }
        });
      }
      $scope.$watch('pvm.editMode', function (value) {
      })
    }

    function toggleGridLoader(obj, is_true) {
      if (is_true !== undefined) {
        obj.show = !!is_true;
        obj.hideOverlay = !!is_true;
      } else {
        obj.show = !obj.show;
        obj.hideOverlay = !obj.hideOverlay;
      }
    }

    function openFirstDataview() {
      navigationService.open_first_dataview(pvm.datasource);
    }

    function openDataview(ws) {
      navigationService.open_dataview(ws);
    }

   

  function goToLabel(resource_id) {
    analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.goToFolder, { eventOrigin: "landingPage.previewPanel" });
    window.location.href = `${window.location.origin}/#/workspaces/${$store.getters.getCurrentWorkspace.id}/projects/${$store.state.projectId}/folders/${resource_id}`
  }
  function deleteDs() {
    modalService.deleteDs(pvm.datasource).then(() => pvm.deselect()); // deselect if deleted
  }


    function addData() {
      let formData = [{
        'append_to_ds_id': pvm.datasource.id
      }];
      if (landingList.currentLabelResourceId) {
        formData[0]['label_resource_id'] = landingList.currentLabelResourceId;
      }
      var uploader = fileUploadService.getUploader(formData, true);
      fileUploadService.openAddDataDialog(uploader, {
        fileUploadsOnly: true,
        destinationString: pvm.datasource.name
      });
    }

    /**
     getQuery fetches the query of the dataset which is not present in the dataset, It updates dsConfig state value.
     In case of file type connectors where integration key is absent it returns out of the function without assigning.
     */

  function getQuery() {
    pvm.dsConfig = null;
    if (!pvm.datasource?.config_keys) return;
    const { identity_key, ds_config_key, integration_key } = pvm.datasource.config_keys;
    // file type connectors won't contain integration key and they don't have a query associated with it.
    // Returning out of the function.
    if (!integration_key) {
      return;
    }
    Singularity.getIdentities(integration_key, $store.state.projectId).then(function (identities) {
      if (identity_key !== undefined) {
        angular.forEach(identities, function (identity) {
          if (identity.value === identity_key) {
            Singularity.getIdentityDsConfig(
              integration_key,
              identity.value,
              ds_config_key,
              $store.state.projectId
            ).then(dsConfig => {
              pvm.dsConfig = dsConfig;
              if(dsConfig.unique_sequence_column){
                for(var element in pvm.datasource.metadata){
                  var data = pvm.datasource.metadata[element]
                  if(dsConfig.unique_sequence_column && dsConfig.unique_sequence_column==data.internal_name){
                    pvm.dsConfig['unique_sequence_display_name'] = data.display_name
                    break;
                  }
                }
              }
              })
            return false;
          }
        });
      }
    },
      error_cb);

      function error_cb(data) {
      }
    }

    /**
     * Resets the controller.
     */
    function reset() {
      pvm.folderPickerMenuIsOpen = false;
      pvm.threeDotsMenu.show = false;
      pvm.filePassword = null;
      pvm.errorMessage = null;
      pvm.showErrorMessage = false;
      if (pvm.resources.length) {
        pvm.datasource = null;
        pvm.datasetResource = null;
        pvm.file = null;
        pvm.uploading_file = null;
        pvm.dataview = null;
        pvm.show_raw_data = false;
        pvm.selectedBatches = [];
        pvm.label = undefined;
        pvm.labelView = undefined;
        pvm.resourceTypes = {};
        pvm.selectAll = false;
        pvm.dataUrls = [];
        pvm.deleteFile = true;
      }
      pvm.previewDataOption = defaultPreviewOption;
      pvm.ws_preview = {};
      if (wsUpdateDeregister) {
        wsUpdateDeregister();
      }
      if (dsListUpdateDerigister) {
        dsListUpdateDerigister();
      }
      if (fileObjectUpdateDeregister) {
        fileObjectUpdateDeregister();
      }
      if (uploadingFileUpdateDeregister) {
        uploadingFileUpdateDeregister();
      }
      if (dsUpdateDerigister) {
        dsUpdateDerigister();
      }
      
      if (!pvm.resources) {
        return;
      }

      dsListUpdateDerigister = DatasourceService.on_list_update('previewPanelUpdate', function () {
        validateSelectedItems();
        _init();
      });

      _init();
      appHelperService.reRenderHints();

      function _init() {
        pvm.resourceTypes = _.countBy(pvm.resources, 'type');
        if (pvm.resources.length == 1) {
          var resource = pvm.resources[0];
          if (resource.type === c.resourceTypes.ds) {
            if (!angular.equals(pvm.datasource, resource) && resource) {
              pvm.datasource = resource;
              pvm.datasetResource = pvm.$store.getters['resources/getDatasetByDatasetId'](pvm.datasource.id)
              pvm.batch_col_ctrl.setBatchColKeys();
              pvm.datasource.updated_at_backup = pvm.datasource.updated_at;
              pvm.datasource.dataUrl = config.apiUrl + '/datasets/' + pvm.datasource.id;
              pvm.refreshBatchInfo();
              if ([c.dsStatus.ready].indexOf(pvm.datasource.status) !== -1) {
                pvm.wkspDataUrl = config.apiUrl + pvm.datasource.dataviews_list[0].data_url;
              }
              // Commented the below line because this was causing unwanted loaders when preview is open during a file upload (5 times)
              // dsUpdateDerigister = pvm.datasource.onUpdate('fromPreviewPanel', pvm.refreshBatchInfo);
              if ([c.dsStatus.empty].indexOf(pvm.datasource.status) === -1) {
                updateDataview();
              }
            }
            prepareDisplayProperties();
            // for edit query panel, fetching the query to update ds config
            getQuery();
          } else if (resource.type === c.resourceTypes.file) {
            pvm.file = resource;
            fileObjectUpdateDeregister = FileService.on_list_update('previewPanelUpdate', validateSelectedItems);
          }  else if (resource.type === c.resourceTypes.ws) {
            if (!angular.equals(pvm.dataview, resource)) {
              pvm.dataview = resource;
              pvm.dataview.datasource.dataUrl = config.apiUrl + '/datasets/' + pvm.dataview.ds_id;
              pvm.previewDataOption = 'wksp_data';
              prepare_preview_grid(pvm.dataview.id);
              wsUpdateDeregister = pvm.dataview.on_update('previewPanelUpdate', function () {
                prepare_preview_grid(pvm.dataview.id);
              });
            }
          } else if (resource.type === c.resourceTypes.upload) {
            pvm.uploading_file = resource;
            if (landingSelection.onUploaderItemRemove) {
              uploadingFileUpdateDeregister = landingSelection.onUploaderItemRemove('previewPanelUpdate', validateSelectedItems);
            }
          } else if (resource.type === c.resourceTypes.label) {
            pvm.label = resource;
            pvm.labelView = landingList.labelViewMap[resource.resource_id];
          }
        }
        if (pvm?.datasource) {
          pvm.activeResourceId = pvm.datasource?.resource_id;
        } else {
          pvm.activeResourceId = pvm.dataview?.resource_id
        }
      }

    }

    function validateSelectedItems() {
      if (pvm.resources.length == 1) {
        var resource = pvm.resources[0];
        if (resource.type === c.resourceTypes.ds) {
          if (!DatasourceService.list[resource.id]) {
            landingSelection.clearSelection();
            reset();
          }
          if (!(pvm.datasource.updated_at_backup === pvm.datasource.updated_at)) {
            pvm.datasource.updated_at_backup = pvm.datasource.updated_at;
            refreshBatchInfo(true); // refresh batch info
          }

        } else if (resource.type === c.resourceTypes.ws) {
          if (!DataviewService.get_by_id(resource.id)) {
            landingSelection.clearSelection();
            reset();
          }
          var ds = DatasourceService.list[resource.ds_id];
          if (ds.dataview_count === 1) {
            landingSelection.selectResource(ds);
            reset();
          }
        } else if (resource.type === c.resourceTypes.upload) {
          if (resource.isCancel || resource.isUploaded || resource.isError) {
            landingSelection.clearSelection();
            reset();
          }
        } else if (resource.type === c.resourceTypes.file) {
          var file = FileService.list[resource.id];
          if (!file || file.status === c.fileStatus.processed) {
            landingSelection.clearSelection();
            reset();
          }
          _initSheetSelectionModel();
        }
      }
    }

    function resetBatchCallback(previewOption = undefined) {
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.previewBatch, {eventOrigin: "landingPage.previewPanel"})
      pvm.dataUrls = [];
      pvm.selectedBatches.forEach(function (batch) {
        pvm.dataUrls.push(batch.dataUrl);
      });
      if (!pvm.dataUrls.length) {
        pvm.dataUrls.push(_.get(pvm.datasource, 'dataUrl', []));
      }
      pvm.show_raw_data = false;
      if (pvm.selectedBatches.length >= 0 && pvm.datasource) {
        pvm.previewDataOption = pvm.datasource.batch_count === 1 && pvm.datasource.dataviews_list.length === 1 && !previewOption ?
          defaultPreviewOption : 'processed_data';
      }
      // Commented below code because this was calling prepare preview grid unnecessarily for a second time after the panel has already been loaded
      // prepare wksp grid only when previewDataOption is wksp_data
      if (pvm.datasource && pvm.datasource.dataviews_list.length === 1 && !pvm.ws_preview.loading && pvm.previewDataOption == 'wksp_data') {
        prepare_preview_grid(pvm.datasource.dataviews_list[0].id);
      }
    }

    function syncData() {
      if (pvm.datasource) {
        pvm.datasource.syncState.sync();
      }
    }

    function dropSelectedBatch(batch) {
      DSBatchService.dropByDsIdBatchId(pvm.datasource.id, batch.id).then(function () {
        pvm.reset();
        pvm.collapseBatches = false;
      });
    }

    function dropBatches() {
      var batchesToDrop = [];
      pvm.selectedBatches.forEach(function (batch) {
        batchesToDrop.push(batch.id);
      });
      DSBatchService.dropBatchesByDsId(pvm.datasource.id, batchesToDrop, pvm.selectedBatches).then(function () {
        pvm.reset();
        pvm.collapseBatches = false;
      });
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.deleteBatches, {eventOrigin: "landingPage.previewPanel"});
    }

    function modifyBatchesOption() {
      let suspended = _.filter(pvm.selectedBatches, function (batch) {
        return batch.state == 'suspended';
      })
      return suspended.length == pvm.selectedBatches.length ? 'unsuspend' : suspended.length == 0 ? 'suspend' : 'toggle';
    }

    function multiModifyBatchState(modifyType) {
      DSBatchService.multiModifyBatchState(pvm.datasource.id, pvm.selectedBatches, modifyType).then(function () {
        pvm.selectedBatches = [];
        pvm.collapseBatches = false;
      });
    }

    function addOrRemoveBatchCols() {
      const cols_to_add = Object.keys(pvm.batch_col_ctrl.state).filter(k => pvm.batch_col_ctrl.state[k]);
      let _difference = new Set(pvm.batch_col_ctrl.batch_cols_in_ds_keys);
      new Set(cols_to_add).forEach(function (v, k, s) {
          _difference.delete(v)
        }
      );
      const cols_to_delete = Array.from(_difference);

      DatasourceService.updateBatchColumns(pvm.datasource, cols_to_add, cols_to_delete).then(function (d) {
        pvm.datasource.collapseAddToView = false;
        toastNotification.success(`Batch info updated`);
      }, function () {
        _.set(pvm, 'datasource.additional_info.batch_cols_processing', false);
      });
    }

    function refreshBatchInfo(hideloader) {
      pvm.show_loader = !hideloader;
      // A trivial change to appropiately show loader
      pvm.batches = _.get(pvm, 'datasource.batches');
      if (pvm.batches) {
        pvm.setRowCounts();
      }
      pvm.batch_col_ctrl.setBatchColKeys();
      // MVP-4931: We need need this code, so keeping it.
      // DSBatchService.getDsBatchIds(pvm.datasource.id).then(function(dsBatchIds) {
      //   pvm.dsBatchIds = dsBatchIds;
      // });
      DSBatchService.getByDsId(pvm.datasource.id).then(utils.debounce(updateBatchInfoCallback, 500));

      function updateBatchInfoCallback(batches) {
        pvm.batches = batches;
        pvm.setRowCounts();
        // set the batches on the pvm's datasource property only if it exists
        if (_.has(pvm, 'datasource') && pvm.datasource) {
          pvm.datasource.batches = batches
        }
        pvm.show_loader = false;
      }

      // moved this call out of the block of batch's get request so that datasource data doesnt wait for batch api to finish
      resetBatchCallback();
    }

    function updatePreviewDisplayProperties(wksp_id) {
      var dataview = DataviewService.get_by_id(wksp_id);
      pvm.ws_preview.metadata = utils.metadata.applyDisplayChangesReturnMetadata(pvm.ws_preview.metadata, dataview.display_properties);
    }


    function prepare_preview_grid(dataview_id) {
      // Marked this as false to avoid showing any loader at all.
      pvm.ws_preview = {loading: true};
      var deferred = $q.defer();
      var dataviewResource = $resource(config.api.dataviews);
      dataviewResource.get({id: dataview_id, preview: true}).$promise.then(function (data) {
        future_request_tracker(data.future_id)
      }, deferred.reject);

      function future_request_tracker(future_id) {
        var deferred = $q.defer();
        FutureService.track(future_id, data_tracker);

        function data_tracker(future) {
          let response = future.response;
          if (future.status == "processing") {
            return;
          }
          if (future.status == "success") {
            var previews = future.response;
            if (previews && previews.length > 0) {
              pvm.ws_preview = previews[0];
              _.remove(pvm.ws_preview.tasks, function (task) {
                return task['display_info'] && (task['display_info']['invisible'])
              });
              var taskService = TaskServiceFactory.get_unregistered_instance(dataview_id);
              angular.forEach(pvm.ws_preview.tasks, function (task) {
                angular.merge(task, taskService.generateTaskProperties(task));
                task.toggleDesc = false;
              });
              pvm.ws_preview.tasks.sort(function (task1, task2) {
                return task1.sequence - task2.sequence;
              });
              updatePreviewDisplayProperties(dataview_id);
              pvm.ws_preview.loading = false;
            } else {
              pvm.ws_preview.loading = false;
            }
            deferred.resolve(response);
          }
        }
      }
    }

    function get_data_tracker(data) {
      var deferred = $q.defer();
      FutureService.track(data.data.future_id, data_tracker);

      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
          pvm.ws_preview.loading = true;
        } else {
          deferred.reject(response);
        }
      }

      return deferred.promise;
    }


    function deselect() {
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.closePreviewPanel, {eventOrigin: "landingPage.previewPanel"});
      pvm.reset();
      landingSelection.clearSelection();
      appHelperService.reRenderHints();
    }

    function refreshCloudData() {
      modalService.refreshCloudData(pvm.datasource).then(refreshConfirmed);

      function refreshConfirmed() {
        analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.pullCloudData, { eventOrigin: "landingPage.previewPanel" });
        DatasourceService.refreshDatasource(pvm.datasource);
      }
    }

    function validateSheetSelection(sheetsInfo, minimum_selected) {
      if (!minimum_selected) {
        minimum_selected = 1;
      }
      if (sheetsInfo) {
        var selected = 0;
        angular.forEach(sheetsInfo, function (sheet) {
          if (sheet.value) {
            selected++;
          }
        });
        return selected >= minimum_selected;
      }
    }

    function _initSheetSelectionModel() {
      if (landingSelection.selection.items.length == 1) {
        analyticsService.userEventTrack(c.userEvents.landingPage.selectDS, {eventOrigin: "landingPage"});
        var file = landingSelection.selection.items[0];
        if (file.type === c.resourceTypes.file && file.status == c.fileStatus.actionNeeded &&
          file.additional_data.sheet_info && !pvm.sheetSelectionInfoModels.hasOwnProperty(file.id)) {
          pvm.sheetSelectionInfoModels[file.id] = file.additional_data.sheet_info;
        }
      }
    }

    function updateDataview() {
      if (!pvm.datasource) {
        return;
      }
      var wksp = pvm.datasource.dataviews_list[0];
      if (wksp) {
        if (wksp.metadata) {
          pvm.wkspMetadata = wksp.metadata;
        } else {
          wksp.get_data().then(updateDataview);
        }
      }
    }

    function updateFromSourceWebhookStore(ds) {
      var unprocessed_count = ds.webhookInfo.unprocessed_count;

      webhooks.updateChildData(ds).then(successCb, errorCb);

      function successCb() {
      }

      function errorCb(data) {
        pvm.datasource.webhookInfo.unprocessed_count = unprocessed_count;
        ds.webhookInfo.unprocessed_count = unprocessed_count;

        let error_message = "Failed";
        if (data.data && data.data.ERROR_MESSAGE) {
          error_message += ": " + data.data.ERROR_MESSAGE;
        }
        toastNotification.error(error_message);
      }
    }

    function prepareDisplayProperties() {
      var disp_info = pvm.datasource.display_info;
      if (disp_info && disp_info.hasOwnProperty('rrule') && disp_info.rrule && disp_info.rrule.FREQUENCY) {

        disp_info.schedule_summary = {
          label: "Refresh Schedule",
          value: utils.schedule.summarize(disp_info.rrule)
        };

        disp_info.start_date = {
          label: "Starting",
          value: moment.utc(disp_info.rrule.START).local().format('MMMM D, YYYY HH:mm')
        };
      }
      // if (disp_info) {
      //   delete disp_info.rrule;
      // }
    }

    function openRawDataModal(ds_id, ds_status) {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.openDataSettings, {eventOrigin: "landingPage.previewPanel"});
      var modalInstance = rawData.openByDsId(ds_id, ds_status);
      modalInstance.then(function () {
        DataviewService.fire_on_ds_reprocess();
      });
      return modalInstance;
    }

    function openUnstructureDataModal(ds_id, ds_status) {
      analyticsService.userEventTrack(c.userEvents.landingPage.openUnstructureDataModal, {eventOrigin: "landingPage.previewPanel"});
      var modalInstance = unstructureDataModal.openByDsId(ds_id, ds_status);
      modalInstance.then(function () {
        DataviewService.fire_on_ds_reprocess();
      });
      return modalInstance;
    }

    function getWebhookAbsUrl() {
      return webhooks.getAbsUrl(pvm.datasource.additional_info.webhook.url);
    }

    function getWebhookMode(ds) {
      return webhooks.getMode(ds)
    }

    function saveWebhookMode(webhookMode) {
      pvm.datasource.additional_info.webhook.mode = webhookMode;
      webhooks.editMode(pvm.datasource.additional_info.webhook.id, webhookMode);
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.editWebhookMode, {eventOrigin: "webhookProperties"})

    }

    function saveWebhookOriginString(originsString) {
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.editWebhookOriginSites, {eventOrigin: "webhookProperties"})
      let origins = originsString.trim();
      webhooks.editOrigins(pvm.datasource.additional_info.webhook.id, origins);
    }

    function toggleWebHookSecurityHeader(value) {
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.toggleWebHookSecurityHeader, {
        eventOrigin: "webhookProperties",
        enabled: value
      })
      webhooks.toggleSecurityHeader(pvm.datasource.additional_info.webhook.id, value);
    }

    function mousePositionChanged(value) {
      pvm.mouseLeave = value;

    }

    function toggleFilesAndFoldersSection() {
      pvm.toggleFolder = !pvm.toggleFolder;
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.filesAndFoldersSectionCollapsed, {
        eventOrigin: 'landingPage.previewPanel',
        isCollapsed: pvm.toggleFolder
      });
    }

    function togglePreviewSection() {
      pvm.togglePrev = !pvm.togglePrev;
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.previewSectionCollapsed, {
        eventOrigin: 'landingPage.previewPanel',
        isCollapsed: pvm.togglePrev
      });
    }

    function togglePropertySection() {
      pvm.toggleProps = !pvm.toggleProps;
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.propertySectionCollapsed, {
        eventOrigin: 'landingPage.previewPanel',
        isCollapsed: pvm.toggleProps
      });
    }

    function toggleSelectAll() {
      pvm.selectAll = !pvm.selectAll;
      pvm.previewShown = false;
      pvm.selectedBatches = [];
      if (pvm.selectAll) {
        pvm.batches.forEach(function (batch) {
          if (!isBatchModifying(batch.id)) {
            pvm.selectedBatches.push(batch);
          }
        });
      } else {
        pvm.selectedBatches = [];
      }
    }

    function toggleSelection(batch) {
      pvm.previewShown = false;
      var idx = pvm.selectedBatches.findIndex(el => el.id == batch.id);
      if (idx > -1) {
        pvm.selectedBatches.splice(idx, 1);
      } else {
        pvm.selectedBatches.push(batch);
      }
    }

    function batchIsThere(batch) {
      var flag = false;
      pvm.selectedBatches.forEach(function (el) {
        if (el.id == batch.id) {
          flag = true;
        }
      })
      return flag;
    }

    /** Input:
     *    1.ds_id(type- int)
     *    2.scheduleJob (String)
     *  Purpose: to update schedule state using DatasourceService*/
    function updateSchedule(ds_id: number, scheduleJob: string) {
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.scheduledUpdate,
        {
          eventOrigin: "landingPage.previewPanel"
        });
      pvm.iseditScheduleInProgress = true
      var id = getNotificationId(ds_id, {type: 'singularity', operation: 'pull_data_as_csv'})
      if (pvm.isScheduleRunning == false) {
        DatasourceService.updateSchedule(ds_id, 'pause', scheduleJob, id).then(successCallback, failureCallback)
      } else {
        DatasourceService.updateSchedule(ds_id, 'resume', scheduleJob, id).then(successCallback, failureCallback)
      }

      function successCallback() {
        pvm.iseditScheduleInProgress = false
      }

      function failureCallback() {
        pvm.iseditScheduleInProgress = false
      }
    }

    /**Inputs:
     *  1.ds_id(type- int)
     *  2.matchingCriteria (type- Object, it will have properties on which you want to filter out the notifications)
     * Puropose: to get notification id of an existing notification that has certain matching properties */
    function getNotificationId(ds_id, matchingCriteria: any) {
      var list = NotificationService.list;
      var matchingNoification: any = _.filter(list, function (o) {
        return o.details.type == matchingCriteria.type && o.details.op == matchingCriteria.operation && o.details.data.ds_id == ds_id;
      });
      if (matchingNoification.length > 0) {
        return matchingNoification[0]['id']
      }
    }

    /** Purpose: to open the third party modal with pre-selected integration*/
    async function editConnection() {
      document.body.style.cursor = 'wait'

      pvm.vueCurrentConnector = getCurrentConnector(pvm.datasource.config_keys.integration_key)

    if (pvm.vueCurrentConnector.connection.userInputAuthUrl || pvm.vueCurrentConnector.connection.authUrl){
      pvm.vueConnectionIntegrationInfo = await VueDataLibraryApi.getConnectorIntegration(pvm.vueCurrentConnector.hash, $store.state.projectId)
      if (pvm.vueCurrentConnector.connection.authUrl) {
        window.location.href = pvm.vueConnectionIntegrationInfo.authUrl
        return
      }
    }

      const identity = await getCurrentConnection(pvm.vueCurrentConnector.hash, pvm.datasource.config_keys.identity_key)
      pvm.vueConnectionToEdit = {...identity, fields: identity.identity_config};
      pvm.vueIsEditConnectionModalVisible = true;

      document.body.style.cursor = 'default'
    }

    function onEditConnectionModalToggle(modalStatus) {
      pvm.vueIsEditConnectionModalVisible = modalStatus;
      pvm.vueConnectionToEdit = null;
      pvm.vueCurrentConnector = null;
    }

    function updateScheduleState(dsState) {
      pvm.isScheduleRunning = dsState;
    }

    async function editMetrics() {
      pvm.vueCurrentConnector = getCurrentConnector(pvm.datasource.config_keys.integration_key)
      pvm.vueCurrentConnection = {value: pvm.datasource.config_keys.identity_key}
      pvm.vueQueryToEdit = {
        queryString: pvm.dsConfig.query_string,
        configKey: pvm.datasource.config_keys.ds_config_key,
        datasetId: pvm.datasource.id
      }

      if (pvm.vueCurrentConnector.connection.hasProfiles) {
        const profile = pvm.dsConfig.profile
        pvm.vueCurrentProfile = {
          value: Array.isArray(profile[0]) ? JSON.stringify(profile[0]) : profile,
          arrayValue: Array.isArray(profile[0]) && profile
        }
      }

      pvm.vueIsEditQueryModalVisible = true;
    }

    function onEditQueryModalToggle(modalStatus) {
      pvm.vueIsEditQueryModalVisible = !!modalStatus;
      pvm.vueQueryToEdit = null;
      pvm.vueCurrentConnector = null
      pvm.vueCurrentConnection = null
      pvm.vueCurrentProfile = null
    }

    function getCurrentConnector(connectorKey) {
      let connector = CONNECTORS.find(c => c.key == connectorKey)
      connector.hash = btoa(connectorKey)
      return connector
    }

  async function getCurrentConnection(connectorKey, connectionKey){
    const { data } = await VueDataLibraryApi.getConnectorIdentities(connectorKey, $store.state.projectId)
    return data.identities.find(i => i.value == connectionKey)
  }

    function editSchedule() {
      pvm.vueCurrentConnector = getCurrentConnector(pvm.datasource.config_keys.integration_key)

      pvm.vueScheduleToEdit = {
        ...pvm.datasource.display_info.rrule,
        datasetId: pvm.datasource.id,
        datasetName: decode(pvm.datasource.name)
      };
      if (pvm.vueScheduleToEdit.FREQUENCY == 'WEEKLY') pvm.vueScheduleToEdit.unit = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'].findIndex(w => w == pvm.vueScheduleToEdit.BYWEEKDAY[0])
      else if (pvm.vueScheduleToEdit.FREQUENCY == 'MONTHLY') pvm.vueScheduleToEdit.unit = pvm.vueScheduleToEdit.BYMONTHDAY[0]
      else pvm.vueScheduleToEdit.unit = pvm.vueScheduleToEdit.INTERVAL
      pvm.vueScheduleToEdit.datasetMode = pvm.datasource.display_info?.refresh_type?.value?.toLowerCase() == 'replace' ? "REPLACE" : "APPEND"

      pvm.vueIsEditScheduleModalVisible = true;
    }

    function onEditScheduleModalToggle(modalStatus) {
      pvm.vueIsEditScheduleModalVisible = !!modalStatus;
      pvm.vueScheduleToEdit = null;
      pvm.vueCurrentConnector = null
      pvm.vueCurrentConnection = null
    }
  }
}
