/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * UPtown funktion you up!
 * @param name
 * @param type
 * @constructor
 */

function Funktion(name, type) {
  var self = this;
  self.name = name;
  self.type = type;
  self.selected = null;

  if (type == 'zero') {
    self.getText = function () {
      var txt = self.name + '()';
      return txt;
    }
  }
  else if (type == 'columnSingle') {
    self.getText = function () {
      return self.name + '(' + self.selected.quotedName + ')';
    }
  }
  else {
    self.getText = function () {
      return self.name + '('
    }
  }
}


/**
 * @ngInject
 */
MetricNameDestinationManagerFactory.$inject= ['utils'];
export function MetricNameDestinationManagerFactory(utils){
  return {
    get_manager: function(){
      return new MetricDestinationManager()
    }
  };
  function MetricDestinationManager(){
    var self = this;
    self.validate = validate;
    self.setParam = setParam;
    self.getParam = getParam;
    self.name = null;
    self.internal_name = null;


    function validate(){
      if (self.name.length == 0){
        self.error = 'invalid metric name';
        return false;
      }
      return true;
    }

    function getParam(){
      return {
        AS: self.name,
        INTERNAL_NAME: self.internal_name
      }
    }

    function setParam(metric_param){
      self.name = metric_param.AS;
      self.internal_name = metric_param.INTERNAL_NAME;
      if(!self.internal_name){
        self.internal_name = 'm_' + utils.string.random(10, {lowercase: true});
      }
    }
  }
}
/**
 * @ngInject
 * @returns {{get_manager: get_manager}}
 */

expressionManagerFactory.$inject = ['destinationColumnManagerFactory', 'filterManagerFactory', 'mammothExpressionHelperFactory',
                                  'DatasourceService', 'c', 'FilterEditHelperFactory', 'utils', 'dependencyChecker', '$timeout', 'MetricNameDestinationManagerFactory',
                                  'eventCallbackManagerFactory', 'filterUI', 'analyticsService'];
export function expressionManagerFactory(destinationColumnManagerFactory, filterManagerFactory, mammothExpressionHelperFactory,
                                  DatasourceService, c, FilterEditHelperFactory, utils, dependencyChecker, $timeout, MetricNameDestinationManagerFactory,
                                  eventCallbackManagerFactory, filterUI, analyticsService) {
  return {get_manager: get_manager};

  /**
   * The manager yielder
   *
   * @param metadata
   * @param mode: can be either 'metric' or 'math'.
   * @returns {ExpressionManager}
   */
  function get_manager(options) {
    return new ExpressionManager(options);
  }

  function ExpressionManager(options) {
    var self = this;
    var changeEvent = new eventCallbackManagerFactory("MammothExpressionEvent." + utils.string.random());
    var metadata = options.metadata, taskUtils = options.taskUtils, mode = options.mode, context = options.context;
    self.onUpdate = changeEvent.add_callback;
    self._functionsDefaultSelectedColumn = undefined;
    self.expression = [];
    self.metadata = metadata;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap;
    self.columns = getNumericColumns(metadata);
    self.numericNonErrorColumns = getNumericNonErrorColumns(metadata);
    self.metrics = [];
    self.context = context;
    self.quotedText = quotedText;
    self.getParam = getParam;
    self.handlePasteParams = handlePasteParams;
    self.validate = validate;
    self.setParam = setParam;
    self.is_dirty = false;
    self.openConditionMenu = openConditionMenu;
    self.openConditionModal = openConditionModal;
    self.expressionUpdateCallback = expressionUpdateCallback;
    self.trackToggleSelectors = toggleSelectors;
    self.trackFunctionSelection = trackFunctionSelection;
    self.expressionValidityChecker =  utils.math.isMathExpressionValid;
    self.setFunctionsDefaultSelectedColumn = setFunctionsDefaultSelectedColumn;
    self.options = options
    let dataviewId = null;
    if(options.context.dataview){
     dataviewId = options.context.dataview.id
    }
    self.filterEditHelper = new FilterEditHelperFactory();
    self.filterEditHelper.onMaximizeFilterPopoverToModal('maximizeFilterPopoverToModal',  self.openConditionModal)
    self.filterManager = filterManagerFactory.get_manager({
      metadata: metadata,
      dataviewId: dataviewId,
      context: options.context,
      originalSequenceNumber: options.originalSequenceNumber
    });

    self.mode = mode;

    if(mode == 'math'){
      self.destinationManager = destinationColumnManagerFactory.get_manager(
          { metadata: metadata,
            allowedTypes: ['NUMERIC'],
            taskUtils: options.taskUtils,
            isDestinationFormatterVisible: true}
      );
      self.disAllowColumnNames = false;
    }
    else{
      self.destinationManager = MetricNameDestinationManagerFactory.get_manager();
      self.disAllowColumnNames = true;
    }

    self.insert_in_editor = null;

    self.setTextInsert = setTextInsert;

    function openConditionMenu(asModal=false){
      if(asModal){
        analyticsService.userEventTrack(c.userEvents.elements.editor.openFilterConditionBuilderModal,
          {
            eventOrigin: "metricCreatorModal"
          });
        filterUI.open(self.filterManager);
      }
      else{
        self.filterEditHelper.open(self.filterManager);
      }
    }
    
    function openConditionModal(param=null){
      filterUI.open(self.filterManager, param);
    }
    
    function setTextInsert(fn) {
      self.insert_in_editor = fn;
    }

    function quotedText(text) {
      return '"' + text + '"';
    }

    function _update_metric_list(list){
      self.metrics = [];

      $.each(Object.keys(list), function(i, object_id){
        var item = list[object_id];

        if(item.type == 'datasource'){
          $.each(Object.keys(item.dataviews), function(j, ws_id){
            var ws = item.dataviews[ws_id];
            if(angular.isArray(ws.metrics)){
              $.each(ws.metrics, function(k: any, m){
                if(m.internal_name != self.context.metric_internal_name){
                  var metric_name = item.name + '> ' + ws.name + '> ' + m.display_name;
                  var metricInfo = {
                    display_name: metric_name,
                    internal_name: m.internal_name,
                    dataview_id: ws.id,
                    name: m.display_name,
                    dependencies: m.dependencies,
                    ws: undefined,
                    ds: undefined
                  };
                  if (k == 0){
                    // Q: why did I do this? Is this even sound logic?
                    // A: Just a classic case of mixing up UI representation logic
                    // with the logic of how to define clean models.
                    // The cleaner way of doing this is to actually write a directive to
                    // render metric section of function builder. But at the time
                    // brain freeze made mr write this ugly logic.
                    // Not a big deal so not fixing it.
                    metricInfo.ws = ws;
                    metricInfo.ds = item;
                  }
                  self.metrics.push(metricInfo);
                }
              })
            }
          });
        }
      });
      if(context.dataview){
        self.metrics = dependencyChecker.filterMetrics(self.metrics, self.metadata, self.context);
      }
    }

    self.functions = [];

    mammothExpressionHelperFactory.functions.zero.forEach(function (f) {
      self.functions.push(new Funktion(f, 'zero'));
    });

    mammothExpressionHelperFactory.functions.columnSingle.forEach(function (f) {
      var fn: any = new Funktion(f, 'columnSingle');
      fn.selected = self._functionsDefaultSelectedColumn || self.columns[0];
      self.functions.push(fn);
    });

    mammothExpressionHelperFactory.functions.expression.forEach(function (f) {
      self.functions.push(new Funktion(f, 'expression'));
    });

    self.exampleText = "Lorem * Ipsum";
    if(self.columns.length > 1){
      // self.exampleText = "(" + self.columns[0].display_name + ' * ' + self.columns[1].display_name +  ") / 100";
      self.exampleCol1 = self.columns[0].display_name;
      self.exampleCol2 = self.columns[1].display_name;
      // self.exampleText = "(" + self.exampleCol1 + ' * ' + self.exampleCol2 +  ") / 100";
    }

    function setFunctionsDefaultSelectedColumn(col_name) {
      var column = utils.metadata.get_column_by_internal_name(metadata, col_name);
      self._functionsDefaultSelectedColumn = column;
      $timeout(function () {
        angular.forEach(self.functions, function (fn) {
          fn.selected = column;
        });
      });
    }

    function validate() {
      let is_valid = true;
      if (!utils.math.isMathExpressionValid(self.expression)){
        self.error = "invalid expression";
        is_valid = false;
      }
      if (self.destinationManager && mode == 'math') {
        is_valid = is_valid && self.destinationManager.validate();
      }
      if (self.filterManager.condition){
        is_valid = is_valid && self.filterManager.condition.validate()
      }
      return is_valid;
    }

    function getParam() {
      var sub_param = {EXPRESSION: self.expression};
      var destination_param = self.destinationManager.getParam();
      if (destination_param.hasOwnProperty('AS') && self.context.inEditMode==true && self.context.task){
        utils.sanatizeParamForDuplicateCols(destination_param['AS'], 'INTERNAL_NAME', self.context.task)
      }
      angular.extend(sub_param, destination_param);

      var param;

      if (mode === 'metric'){
        param = {
          METRIC: sub_param
        };
      }
      else{
        param = {
          MATH: sub_param
        };
      }

      if (self.filterManager) {
        param.CONDITION = self.filterManager.getParam();
      } else {
        delete self.param.CONDITION;
      }

      if (self.context.hasOwnProperty('sequence')){
        param['SEQUENCE_NUMBER'] = self.context.sequence
      }
      return param;
    }

    function expressionUpdateCallback(){
      changeEvent.fire_event();
    }

    function handleExpression(expression, taskInfo) {
      /**Following logic finds and replaces columns used in the mathematical expression, recursively*/
      for (const index in expression) {
        if (angular.isArray(expression[index])) {
          handleExpression(expression[index], taskInfo);
        }
        else if(expression[index]['TYPE']=='FUNCTION' && ['COUNT', 'INT'].indexOf(expression[index]['VALUE']['FUNCTION'])==-1){
          utils.metadata.replaceMatchingColumnAndUpdateMetadata(expression[index]['VALUE'], 'ARGUMENT', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        }
        else if(expression[index]['TYPE']=='COLUMN'){
          utils.metadata.replaceMatchingColumnAndUpdateMetadata(expression[index], 'VALUE', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        }
      }
    }

    function addErrorCols(){
      /**Find error cols and concatenate them to the list of columns that can be use in math rule*/
      var errorCols = $.grep(self.metadata, function (col: any) {
        return col.hasOwnProperty('error')
      });
      self.columns = self.columns.concat(errorCols)
    }

    function handlePasteParams(taskInfo){
      /** Update expression, condition and destination params with suitable replacement columns, based on display name*/

      var params = taskInfo.params;

      handleExpression(params.MATH.EXPRESSION, taskInfo)
      addErrorCols()

      if (params.MATH.hasOwnProperty('DESTINATION')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.MATH, 'DESTINATION', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        //Update destination mngr metadata
        self.destinationManager.metadata = self.metadata
        self.destinationManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(self.metadata)

      }

      var columnUsedInCondition = utils.get_input_columns(_.cloneDeep(params.CONDITION))
      if (columnUsedInCondition) {
        utils.metadata.findReplaceColumnsInCondition(self.metadata, self.displayNameAndTypeToColumnMap, params, columnUsedInCondition, taskInfo)
      }

      self.filterManager.metadata = self.metadata

      return params
    }

    function getNumericColumns(metadata) {
      return $.grep(metadata, function (col: any) {
        return col.type == c.numeric
      });
    }
    function getNumericNonErrorColumns(metadata) {
      return $.grep(metadata, function (col: any) {
        return col.type == c.numeric && !col.hasOwnProperty('error')
      });
    }

    function setParam(param) {
      if(mode === 'math'){
        if (param.MATH.EXPRESSION) {
          self.expression = param.MATH.EXPRESSION;
        }
        if (self.context.hasOwnProperty('task') && self.context.task.has_referror) {
          self.columns = self.metadata;
        }
        self.destinationManager.setParam(param.MATH);
      }
      else{
        self.destinationManager.setParam(param.METRIC);
        self.expression = param.METRIC.EXPRESSION;
        if(options.metric_status == 'ERROR'){
          self.columns = self.metadata;
        }
      }
      if (param.hasOwnProperty('CONDITION')) {
        self.filterManager.setParam(param.CONDITION, param?.EXECUTION_TIMESTAMP);
      }
    }

    function toggleSelectors() {
      analyticsService.userEventTrack(c.userEvents.elements.editor.toggleSelectors,
        {
        eventOrigin: "metricCreatorModal"
      })
    }

    function trackFunctionSelection(value) {
      analyticsService.userEventTrack(c.userEvents.elements.editor.functionSelected,
        {
        eventOrigin: "metricCreatorModal.selectors",
        type: value
      });
    }

    if(options.context.dataview){
      _update_metric_list(DatasourceService.list);
    }
  }


}



