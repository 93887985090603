import * as $ from 'jquery';
import * as angular from 'angular';
import * as _ from 'lodash-es';


extractDateManagerFactory.$inject = ['destinationColumnManagerFactory', 'c','utils'];
export function extractDateManagerFactory(destinationColumnManagerFactory, c, utils) {
  return {
    get_manager: get_manager
  };


  function get_manager(options) {
    return new ExtractDateManager(options);
  }

  function ExtractDateManager(options) {
    var metadata = options.metadata, taskUtils = options.taskUtils;
    var self = this;
    self.getParam = getParam;
    self.validate = validate;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.context = options.context;
    self.sanitizeParam = sanitizeParam;
    self.setParam = setParam;
    self.handlePasteParams = handlePasteParams;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.changeComponent = changeComponent;
    self.highlightColumns = highlightColumns;

    self.destinationManager = destinationColumnManagerFactory.get_manager(
      {metadata: metadata, allowedTypes: undefined, taskUtils: taskUtils, isDestinationFormatterVisible: false}
    );
    self.destinationManager.disable();

    self.param = {
      EXTRACT_DATE: {}
    };

    //To discuss:  month_text = month and month = month_number for UI text,
    let text_results = ['month_text', 'weekday_text', 'year_month',
      'hour_minute_second', 'year_month_day', 'year_month_number',
      'hour_minute_second_millisecond'];
    let date_results = ['year_month_day_as_date'];

    self.components =
      [
        {
          DISPLAY_NAME: "Year (num)",
          VALUE: "year"
        },
        {
          DISPLAY_NAME: "Month (num)",
          VALUE: "month"
        },
        {
          DISPLAY_NAME: "Month Name (txt)",
          VALUE: "month_text"
        },
        {
          DISPLAY_NAME: "Year & Month (txt)",
          VALUE: "year_month"
        },
        {
          DISPLAY_NAME: "Date Part without time (date)",
          VALUE: "year_month_day_as_date"
        },
        {
          DISPLAY_NAME: "Millisecond (num)",
          VALUE: "millisecond"
        },
        {
          DISPLAY_NAME: "Time with millisecond (txt)",
          VALUE: "hour_minute_second_millisecond"
        },
        {
          DISPLAY_NAME: "Time without millisecond (txt)",
          VALUE: "hour_minute_second"
        },
        {
          DISPLAY_NAME: "Quarter (num)",
          VALUE: "quarter"
        },
        {
          DISPLAY_NAME: "Weekday (txt)",
          VALUE: "weekday_text"
        },
        {
          DISPLAY_NAME: "Day of year (num)",
          VALUE: "day_of_year"
        },
        {
          DISPLAY_NAME: "Day (num)",
          VALUE: "day"
        },
        {
          DISPLAY_NAME: "Hour (num)",
          VALUE: "hour"
        },
        {
          DISPLAY_NAME: "Minutes (num)",
          VALUE: "minute"
        },
        {
          DISPLAY_NAME: "Seconds (num)",
          VALUE: "second"
        },
        {
          DISPLAY_NAME: "Week of Year (num)",
          VALUE: "week"
        },
        {
          DISPLAY_NAME: "Date Part without time (txt)",
          VALUE: "year_month_day"
        },
        {
          DISPLAY_NAME: "Year & Month Number (txt)",
          VALUE: "year_month_number"
        }
      ];

      self.metadata = utils.sanitizeMetadataWithType(metadata);
      self.source_columns = $.grep(self.metadata, function (col: any) {
      return col.type === c.date;
    });


    function getParam() {
      delete self.param.EXTRACT_DATE.DESTINATION;
      delete self.param.EXTRACT_DATE.AS;
      var destination_param = self.destinationManager.getParam()
      if (destination_param.hasOwnProperty('AS') && self.context.inEditMode==true  &&  self.context.task){
        utils.sanatizeParamForDuplicateCols(destination_param['AS'], 'INTERNAL_NAME', self.context.task)
      }
      angular.merge(self.param.EXTRACT_DATE,destination_param );
      if (self.context.hasOwnProperty('sequence')){
        self.param['SEQUENCE_NUMBER'] = self.context['sequence']
      }
      return self.param;
    }

    function handlePasteParams(taskInfo){
      /** Update params with suitable replacement columns, based on display name*/
      var params = taskInfo.params;
      //Destination param
      if (params.EXTRACT_DATE.hasOwnProperty('DESTINATION')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.EXTRACT_DATE, 'DESTINATION', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        //Update destination mngr metadata
        self.destinationManager.metadata = self.metadata
        self.destinationManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(self.metadata)

      }
      //Source param
      utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.EXTRACT_DATE, 'SOURCE', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      return params;
    }

    function sanitizeParam(param) {
      let col_info = utils.metadata.get_column_by_internal_name(metadata, param.EXTRACT_DATE.SOURCE);
      if (!col_info){
        param.EXTRACT_DATE.SOURCE = undefined;
      }else if (col_info['type']!=='DATE'){
        param.EXTRACT_DATE.SOURCE = undefined;
      }
    }

    function setParam(param) {
      // self.sanitizeParam(param);
      var src_internal_name = param.EXTRACT_DATE.SOURCE
      var sourceColumnInternalNames = _.map(self.source_columns,'internal_name')
      if(sourceColumnInternalNames.indexOf(src_internal_name) == -1){
        var src_col = utils.metadata.get_column_by_internal_name(metadata, src_internal_name)
        self.source_columns.push(src_col)
      }
      self.param.EXTRACT_DATE.COMPONENT = param.EXTRACT_DATE.COMPONENT;
      self.param = param;
      //Set destination param afer chnage component so that allowed types for destination are known
      changeComponent();
      self.destinationManager.setParam(param.EXTRACT_DATE);
      self.destinationManager.enable();
      highlightColumns();
    }


    function validate() {
      if (self.destinationManager) {
        return self.destinationManager.validate();
      }
      return true;
    }


    function changeComponent() {
      var allowed_destination_type = c.numeric;

      if (text_results.indexOf(self.param.EXTRACT_DATE.COMPONENT )  != -1) {
        allowed_destination_type = c.text;
      }
      if (date_results.indexOf(self.param.EXTRACT_DATE.COMPONENT) != -1) {
        allowed_destination_type = c.date;
      }
      self.destinationManager.setAllowedTypesAndColumns([allowed_destination_type]);
      self.destinationManager.enable();
    }


    function highlightColumns() {
      taskUtils.highlight.sources(self.param.EXTRACT_DATE.SOURCE);
    }

  }
}


valDateExtractCol.$inject = ['utils'];
export function valDateExtractCol(utils) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valDateExtractCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valDateExtractCol = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue){
          var col_info = utils.metadata.get_column_by_internal_name(scope.tvm.manager.metadata, modelValue)
          if( col_info.hasOwnProperty('error')){
            is_valid =  false
          }
          else if( col_info.type != 'DATE'){
            is_valid =  false
          }
        }
        return is_valid
      };
    }
  };
}
