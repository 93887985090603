<template>
  <div class="mm-data-addition-third-party-scheduler height-100 m-t-3">
    <mm-tooltip
      v-if="selectedSchedule"
      :label="selectedSchedule.datasetName"
      wrapper-class="d-flex max-width-100 overflow-ellipsis m-r-auto m-b-5"
    >
      <span class="overflow-ellipsis">{{ selectedSchedule.datasetName }}</span>
    </mm-tooltip>

    <div v-if="!selectedSchedule" class="m-b-4">
      <div class="d-flex">
        <span class="section-title--text">
          {{ $t('data_library.data_addition.third_party.retrieve.dataset_definitions') }}
        </span>
        <mm-text-input
          v-model="datasetName"
          style="width: 300px"
          :title="$t('data_library.data_addition.webhook.new_ds_name')"
          :input-attrs="{ maxlength: DATASET_NAME_MAX_LENGTH }"
        />
      </div>
      <mm-divider />
    </div>

    <div class="m-b-4">
      <div class="d-flex">
        <span class="section-title--text">
          {{ $t('data_library.data_addition.third_party.retrieve.retrieval_definitions') }}
        </span>

        <div>
          <div class="d-flex m-b-3">
            <restrictions-wrapper :key="`restrictions_wrapper_${restrictionsWrapperKey}`">
              <mm-segmented-control
                v-model="retrievalFrequencyType"
                :items="RETRIEVAL_FREQUENCY_TYPES"
                :title="$t('data_library.data_addition.third_party.retrieve.frequency')"
                hide-details
                @input="onRetrievalFrequencyTypeChange"
              />
            </restrictions-wrapper>

            <div v-if="retrievalFrequencyType == 'CUSTOM'" class="d-flex m-l-4">
              <mm-dropdown
                v-model="retrievalFrequency"
                :title="$t('data_library.data_addition.third_party.retrieve.custom_frequency')"
                :items="computedRetrievalFrequencyItems"
                hide-details
              />
              <restrictions-wrapper-modal-contact-sales
                v-model="upgradePlanPayload.isModalOpen"
                v-bind="upgradePlanPayload"
              />

              <mm-dropdown
                v-model="retrievalFrequencyUnit"
                class="m-l-4"
                :title="$t('data_library.data_addition.third_party.retrieve.frequency_ratio')"
                :items="computedRetrievalFrequencyUnitItems"
                hide-details
              />
            </div>
          </div>

          <div class="d-flex">
            <mm-date-picker
              v-if="computedIsScheduleTypePeriod"
              class="m-r-3"
              v-model="dataStartingFrom"
              timestamp
              hide-details
              :max="moment().format('YYYY-MM-DD')"
              :title="$t('data_library.data_addition.third_party.retrieve.data_starting_from')"
            />

            <div v-if="computedIsFirstRetrievalVisible" class="d-flex m-b-3">
              <mm-segmented-control
                v-model="firstRetrievalDate"
                :title="$t('data_library.data_addition.third_party.retrieve.start')"
                :items="FIRST_RETRIEVAL_ITEMS"
                hide-details
              />
              <div v-if="firstRetrievalDate == 'LATER'" class="m-l-4">
                <mm-date-picker
                  v-model="firstRetrievalSpecifiedTime"
                  timestamp
                  :min="moment().format('YYYY-MM-DD')"
                  :title="$t('data_library.data_addition.third_party.retrieve.start_date')"
                  hide-details
                />
              </div>
            </div>
          </div>

          <div v-if="!selectedSchedule">
            <div v-if="retrievalFrequencyType == 'CUSTOM'" class="d-flex m-b-3">
              <mm-segmented-control
                v-model="retrievalFrequencyEnding"
                :title="$t('data_library.data_addition.third_party.retrieve.last_date')"
                :items="RETRIEVAL_FREQUENCY_ENDING_ITEMS"
                hide-details
              />
              <mm-date-picker
                v-if="retrievalFrequencyEnding == 'ON_SET_DATE'"
                v-model="endRetrievalSpecifiedTime"
                class="m-l-4"
                timestamp
                :min="
                  (firstRetrievalDate == 'LATER' ? moment(firstRetrievalSpecifiedTime) : moment()).format('YYYY-MM-DD')
                "
                :title="$t('data_library.data_addition.third_party.retrieve.end_date')"
                hide-details
              />
            </div>

            <div v-else-if="retrievalFrequencyType == 'ONCE' && computedIsScheduleTypePeriod" class="d-flex m-b-3">
              <mm-dropdown
                v-model="dataEndingOn"
                :title="$t('data_library.data_addition.third_party.retrieve.end_date')"
                :items="DATA_ENDING_ON_ITEMS"
                hide-details
              />
              <mm-date-picker
                v-if="dataEndingOn == 'ON_SET_DATE'"
                v-model="dataEndingOnSpecifiedTime"
                class="m-l-4"
                timestamp
                hide-details
                :min="moment(dataStartingFrom).format('YYYY-MM-DD')"
                :max="moment().format('YYYY-MM-DD')"
                :title="$t('global.dictionary.date')"
              />
            </div>
          </div>
        </div>
      </div>
      <mm-divider class="m-t-3" />
    </div>

    <div v-if="!selectedSchedule" class="d-flex">
      <span class="section-title--text">{{ $t('global.dictionary.on_refresh') }}</span>

      <div class="width-100">
        <div v-for="(mode, idx) in DATASET_MODES" :key="mode.value" :class="{ 'usc-ds-mode': mode.showUsc }">
          <mm-row class="d-flex m-y-2" no-gutters>
            <mm-col>
              <mm-radio-button v-model="datasetMode" name="datasetModes" :input-value="mode.value" :label="mode.text" />
              <span class="description--text">{{ mode.description }}</span>
            </mm-col>
            <mm-col
              v-if="!connector.scheduler.hideUsc && mode.showUsc && DATASET_MODES.findIndex((m) => m.showUsc) == idx"
            >
              <mm-dropdown
                v-model="uniqueSequenceColumn"
                :disabled="computedIsUscDisabled"
                small
                :placeholder="$t('global.select_column')"
                hide-details
                :title="$t('data_library.data_addition.third_party.unique_seq_col')"
                :items="UNIQUE_SEQUENCE_COLUMN_ITEMS"
              />
              <span class="description--text">
                {{ $t('data_library.data_addition.third_party.unique_seq_col_description') }}
              </span>
            </mm-col>
          </mm-row>
          <mm-divider v-if="DATASET_MODES[idx + 1] && DATASET_MODES[idx + 1].showUsc != mode.showUsc" class="m-b-4" />
        </div>
      </div>
    </div>

    <mm-callout
      v-if="retrievalFrequencyType == 'CUSTOM'"
      class="m-y-4"
      :message="computedRetrievalInfo || $t('data_library.data_addition.third_party.no_occurrences')"
    />

    <div class="action-buttons-wrapper d-flex">
      <div class="d-flex">
        <mm-button
          v-if="!selectedSchedule"
          :label="$t('global.dictionary.back')"
          objective="tertiary"
          @click="$emit('back')"
        />
        <mm-button
          class="m-l-auto"
          :label="$t('global.dictionary.submit')"
          :disabled="!computedIsSubmitEnabled"
          :loading="loading || isSubmitLoading"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Dependencies
import moment from 'moment'

// Constants
import { DATASET_NAME_MAX_LENGTH, DATA_TYPES_MAP } from '@/constants'
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

// Components
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper.vue'
import RestrictionsWrapperModalContactSales from '@/components/restrictions-wrapper/restrictions-wrapper-modals/contact-sales/restrictions-wrapper-modal-contact-sales.vue'

export default {
  name: 'data-library-data-addition-third-party-scheduler',
  components: { RestrictionsWrapper, RestrictionsWrapperModalContactSales },
  props: {
    connector: {
      type: Object,
      required: true
      /*{
        key: string,
        name: string,
        icon: string,
        category: string,
        connection: {...},
        metrics: {...}
      }*/
    },
    table: {
      type: Object
      /*{
        name: string,
        scheduleType: string,
        cols: [],
      }*/
    },
    selectedSchedule: {
      type: Object
      /*{
        FREQUENCY: string,
        datasetMode: string,
        unit: number,
        datasetId: string,
      }*/
    },
    hideFirstRetrieval: Boolean,
    loading: Boolean
  },
  data: (vm) => ({
    datasetName: vm.table?.name?.slice(0, DATASET_NAME_MAX_LENGTH) || vm.connector.name.toUpperCase(),
    retrievalFrequencyType: 'ONCE',
    retrievalFrequency: '',
    firstRetrievalDate: 'NOW',
    firstRetrievalSpecifiedTime: moment().add(5, 'minutes').set('second', 0).format(DEFAULT_DATE_FORMAT),
    retrievalFrequencyUnit: '',
    retrievalFrequencyEnding: 'NEVER',
    endRetrievalSpecifiedTime: '',
    datasetMode: '',
    uniqueSequenceColumn: '',
    dataStartingFrom: moment().set('second', 0).format(DEFAULT_DATE_FORMAT),
    dataEndingOn: 'LATEST',
    dataEndingOnSpecifiedTime: '',
    isSubmitLoading: false,
    upgradePlanPayload: {
      isModalOpen: false,
      restrictedFeatureName: '',
      restrictedFeature: ''
    },
    restrictionsWrapperKey: 0,
    moment
  }),
  computed: {
    computedIsFirstRetrievalVisible() {
      return !this.hideFirstRetrieval && (!this.selectedSchedule || this.retrievalFrequencyType == 'CUSTOM')
    },
    computedIsSubmitEnabled() {
      // Check if 'firstRetrievalSpecifiedTime' is after now, in case 'firstRetrievalDate' is not set to 'NOW'
      if (
        this.firstRetrievalDate != 'NOW' &&
        (!this.firstRetrievalSpecifiedTime || moment() > moment(this.firstRetrievalSpecifiedTime))
      )
        return false

      // If data is going to be retrieved multiple times until a specific end date, check if end date is after first date
      if (
        this.retrievalFrequencyType == 'CUSTOM' &&
        this.retrievalFrequencyEnding != 'NEVER' &&
        (!this.endRetrievalSpecifiedTime ||
          (this.firstRetrievalDate != 'NOW' &&
            moment(this.firstRetrievalSpecifiedTime) > moment(this.endRetrievalSpecifiedTime)))
      )
        return false

      // If schedule type is 'PERIOD' and there is data to be retrieved until a specific date, check if that date is after 'dataStartingFrom' date
      if (
        this.computedIsScheduleTypePeriod &&
        this.dataEndingOn == 'ON_SET_DATE' &&
        (!this.dataEndingOnSpecifiedTime || moment(this.dataStartingFrom) > moment(this.dataEndingOnSpecifiedTime))
      )
        return false

      // If data pull only occurs once the form is valid, else check if there are any valid retrieval dates
      if (this.retrievalFrequencyType == 'ONCE') return true
      return !!this.computedRetrievalInfo
    },
    computedRetrievalFrequencyItems() {
      const minimumPeriodicity = this.$store.state.smsDetails?.currentPlan?.minimumPeriodicityForDataPulls
      const frequencyUnits = Object.values(this.RETRIEVAL_FREQUENCY_UNITS)
      let isMinimumPeriodicityApplied = !minimumPeriodicity
      let items = []

      frequencyUnits.forEach((unit) => {
        const item = {
          text: this.$t(`global.dictionary.${unit.toLowerCase()}`),
          value: unit,
          isFrequencyRestrictedByPlan: isMinimumPeriodicityApplied
        }
        if (!this.connector.scheduler?.hiddenFrequencies?.[unit.toLowerCase()]) items.unshift(item)
        if (minimumPeriodicity == unit.toLowerCase()) isMinimumPeriodicityApplied = true
      })

      return items
    },
    computedRetrievalFrequencyUnitItems() {
      // Depending on the frequency that was chosen (minutely, hourly, daily, weekly, monthly, yearly) different units are shown on the dropdown
      switch (this.retrievalFrequency) {
        case this.RETRIEVAL_FREQUENCY_UNITS.MINUTELY:
          return [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map((i) => ({
            text: this.$tc('global.dictionary.minutes', i, { count: i }),
            value: i
          }))
        case this.RETRIEVAL_FREQUENCY_UNITS.HOURLY:
          return [...Array(23).keys()].map((i) => ({
            text: this.$tc('global.dictionary.hours', i + 1, { count: i + 1 }),
            value: i + 1
          }))
        case this.RETRIEVAL_FREQUENCY_UNITS.DAILY:
          return [...Array(6).keys()].map((i) => ({
            text: this.$tc('global.dictionary.days', i + 1, { count: i + 1 }),
            value: i + 1
          }))
        case this.RETRIEVAL_FREQUENCY_UNITS.WEEKLY: {
          const weekdays = { monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6, sunday: 0 }
          return Object.keys(weekdays).map((d) => ({
            text: this.$t('global.dictionary.week_days')[d],
            value: weekdays[d]
          }))
        }
        case this.RETRIEVAL_FREQUENCY_UNITS.MONTHLY:
          return [...Array(31).keys()].map((i) => ({ text: moment.localeData().ordinal(i + 1), value: i + 1 }))
        case this.RETRIEVAL_FREQUENCY_UNITS.YEARLY:
          return [...Array(5).keys()].map((i) => ({
            text: this.$tc('global.dictionary.years', i + 1, { count: i + 1 }),
            value: i + 1
          }))
      }

      return []
    },
    computedRetrievalInfo() {
      // Returns an info description string containing the list of the next few valid data retrieval occurrences, with dates specified

      const maxDatesToOutput = 3
      let dates = []
      let isEndingBeforeOutputDates = false

      // Display the next 3 (max) dates on which the data will be pulled
      switch (this.retrievalFrequency) {
        case this.RETRIEVAL_FREQUENCY_UNITS.MINUTELY:
        case this.RETRIEVAL_FREQUENCY_UNITS.HOURLY:
        case this.RETRIEVAL_FREQUENCY_UNITS.DAILY:
        case this.RETRIEVAL_FREQUENCY_UNITS.YEARLY: {
          const MOMENT_UNITS = {
            [this.RETRIEVAL_FREQUENCY_UNITS.MINUTELY]: 'minutes',
            [this.RETRIEVAL_FREQUENCY_UNITS.HOURLY]: 'hours',
            [this.RETRIEVAL_FREQUENCY_UNITS.DAILY]: 'days',
            [this.RETRIEVAL_FREQUENCY_UNITS.YEARLY]: 'years'
          }
          const MOMENT_UNIT_KEY = MOMENT_UNITS[this.retrievalFrequency]

          for (let i = 0; dates.length != maxDatesToOutput; i++) {
            // Get next date
            let nextDate
            if (this.firstRetrievalDate == 'NOW')
              nextDate = moment().add(this.retrievalFrequencyUnit * (i + 1), MOMENT_UNIT_KEY)
            else
              nextDate = moment(this.firstRetrievalSpecifiedTime).add(this.retrievalFrequencyUnit * i, MOMENT_UNIT_KEY)

            if (!nextDate._isValid) break

            // Check if date should be output
            if (nextDate < moment()) continue
            if (this.retrievalFrequencyEnding == 'NEVER' || nextDate < moment(this.endRetrievalSpecifiedTime))
              dates.push(nextDate.format('llll'))
            else break

            // Check if the next date would be shown, to define if the information suffix should be added or not
            if (this.retrievalFrequencyEnding != 'NEVER')
              isEndingBeforeOutputDates =
                nextDate.add(this.retrievalFrequencyUnit, MOMENT_UNIT_KEY) >= moment(this.endRetrievalSpecifiedTime)
          }
          break
        }
        case this.RETRIEVAL_FREQUENCY_UNITS.WEEKLY:
          for (let i = 0; dates.length != maxDatesToOutput; i++) {
            // Get next date
            let nextDate
            if (this.firstRetrievalDate == 'NOW') nextDate = moment().day(this.retrievalFrequencyUnit + 7 * i)
            else nextDate = moment(this.firstRetrievalSpecifiedTime).day(this.retrievalFrequencyUnit + 7 * i)

            if (!nextDate._isValid) break

            // Check if date should be output
            if (nextDate < moment()) continue
            if (this.retrievalFrequencyEnding == 'NEVER' || nextDate < moment(this.endRetrievalSpecifiedTime))
              dates.push(nextDate.format('llll'))
            else break

            // Check if the next date would be shown, to define if the information suffix should be added or not
            if (this.retrievalFrequencyEnding != 'NEVER')
              isEndingBeforeOutputDates = nextDate.add(7, 'd') >= moment(this.endRetrievalSpecifiedTime)
          }
          break

        case this.RETRIEVAL_FREQUENCY_UNITS.MONTHLY:
          for (let i = 0; dates.length != maxDatesToOutput; i++) {
            const nextDate = (this.firstRetrievalDate == 'NOW' ? moment() : moment(this.firstRetrievalSpecifiedTime))
              .set('date', this.retrievalFrequencyUnit)
              .add(1 * i, 'M')

            if (!nextDate._isValid) break

            // Check if date should be output
            if (nextDate < moment()) continue
            if (this.retrievalFrequencyEnding == 'NEVER' || nextDate < moment(this.endRetrievalSpecifiedTime))
              dates.push(nextDate.format('llll'))
            else break

            // Check if the next date would be shown, to define if the information suffix should be added or not
            if (this.retrievalFrequencyEnding != 'NEVER')
              isEndingBeforeOutputDates = nextDate.add(1, 'M') >= moment(this.endRetrievalSpecifiedTime)
          }
          break
      }

      // If there are no valid dates, return
      if (!dates.length) return

      let info = this.$t(
        `data_library.data_addition.third_party.retrieve.${this.firstRetrievalDate == 'NOW' ? 'info_now' : 'info'}`,
        { dates: dates.join('; ') }
      )
      if (!isEndingBeforeOutputDates)
        info = `${info} ${this.$t('data_library.data_addition.third_party.retrieve.info_suffix')}`
      return info
    },
    computedIsScheduleTypePeriod() {
      return this.table?.scheduleType == 'PERIOD'
    },
    computedIsUscDisabled() {
      return this.DATASET_MODES?.filter((m) => !m.showUsc)
        .map((m) => m.value)
        .includes(this.datasetMode)
    }
  },
  created() {
    // Declaring constants
    this.DATASET_NAME_MAX_LENGTH = DATASET_NAME_MAX_LENGTH

    this.RETRIEVAL_FREQUENCY_TYPES = [
      { text: this.$t('data_library.data_addition.third_party.retrieve.once'), value: 'ONCE' },
      {
        text: this.$t('global.dictionary.custom'),
        value: 'CUSTOM',
        featureToValidate: 'minimumPeriodicityForDataPulls'
      }
    ]

    this.FIRST_RETRIEVAL_ITEMS = [
      { text: this.$t('data_library.data_addition.third_party.retrieve.start_now'), value: 'NOW' },
      { text: this.$t('global.dictionary.later'), value: 'LATER' }
    ]

    if (this.connector.scheduler?.enableReplaceWithNewData)
      this.DATASET_MODES = [
        {
          text: this.$t('data_library.data_addition.third_party.replace_all'),
          description: this.$t('data_library.data_addition.third_party.replace_all_description'),
          value: 'REPLACE'
        },
        {
          text: this.$t('data_library.data_addition.third_party.add_new_data'),
          description: this.$t('data_library.data_addition.third_party.add_new_data_description'),
          value: 'APPEND',
          showUsc: true
        },
        {
          text: this.$t('data_library.data_addition.third_party.replace_with_new'),
          description: this.$t('data_library.data_addition.third_party.replace_with_new_description'),
          value: 'REPLACE_LATEST',
          showUsc: true
        }
      ]
    else
      this.DATASET_MODES = [
        {
          text: this.$t('data_library.data_addition.webhook.combine'),
          description: this.$t('data_library.data_addition.webhook.combine_description'),
          value: 'APPEND',
          showUsc: true
        },
        {
          text: this.$t('data_library.data_addition.webhook.replace'),
          description: this.$t('data_library.data_addition.webhook.replace_description'),
          value: 'REPLACE'
        }
      ]
    this.datasetMode = this.DATASET_MODES[0].value

    this.RETRIEVAL_FREQUENCY_UNITS = {
      YEARLY: 'YEARLY',
      MONTHLY: 'MONTHLY',
      WEEKLY: 'WEEKLY',
      DAILY: 'DAILY',
      HOURLY: 'HOURLY',
      MINUTELY: 'MINUTELY'
    }

    this.RETRIEVAL_FREQUENCY_ENDING_ITEMS = [
      { text: this.$t('data_library.data_addition.third_party.retrieve.never_ending'), value: 'NEVER' },
      { text: this.$t('data_library.data_addition.third_party.retrieve.on_set_date'), value: 'ON_SET_DATE' }
    ]
    this.DATA_ENDING_ON_ITEMS = [
      { text: this.$t('data_library.data_addition.third_party.retrieve.latest'), value: 'LATEST' },
      { text: this.$t('data_library.data_addition.third_party.retrieve.on_set_date'), value: 'ON_SET_DATE' }
    ]

    this.UNIQUE_SEQUENCE_COLUMN_ITEMS =
      this.table?.cols
        ?.filter((c) => c.datatype != DATA_TYPES_MAP.TEXT)
        .map((c) => ({
          text: `${c.headerName} (${this.$t('global.dictionary.' + c.datatype).toLowerCase()})`,
          value: c.field
        })) || []

    if (this.UNIQUE_SEQUENCE_COLUMN_ITEMS.length)
      this.UNIQUE_SEQUENCE_COLUMN_ITEMS.unshift({ text: this.$t('global.dictionary.none'), value: 'NO_UNIQUE_SEQ_COL' })
  },
  mounted() {
    // If there is a selected schedule to be edit
    if (this.selectedSchedule) {
      this.retrievalFrequencyType = this.selectedSchedule.FREQUENCY ? 'CUSTOM' : 'ONCE'
      this.retrievalFrequency = this.selectedSchedule.FREQUENCY
      this.retrievalFrequencyUnit = this.selectedSchedule.unit
      this.restrictionsWrapperKey++ // TODO after migration is finished verify if this key is still needed for the restrictions wrapper to work properly. remove key if not needed
    }
  },
  watch: {
    retrievalFrequency(newVal, oldVal) {
      const isFrequencyRestrictedByPlan = this.computedRetrievalFrequencyItems.find((i) => i.value == newVal)
        ?.isFrequencyRestrictedByPlan
      if (isFrequencyRestrictedByPlan) {
        this.upgradePlanPayload.isModalOpen = true
        this.upgradePlanPayload.restrictedFeature = newVal
        this.upgradePlanPayload.restrictedFeatureName = this.$t(
          'data_library.data_addition.third_party.retrieve.frequency_retrieval',
          { frequency: this.$t(`global.dictionary.${newVal?.toLowerCase()}`).toLowerCase() }
        )
        this.$nextTick(() => (this.retrievalFrequency = oldVal))
      } else {
        this.retrievalFrequencyUnit = this.computedRetrievalFrequencyUnitItems?.[0]?.value
        this.datasetName = `${this.table?.name || this.connector.name.toUpperCase()}${
          this.retrievalFrequencyType == 'CUSTOM' ? ` - ${this.$t('global.dictionary.' + newVal?.toLowerCase())}` : ''
        }`.slice(0, DATASET_NAME_MAX_LENGTH)
      }
    }
  },
  methods: {
    onRetrievalFrequencyTypeChange(type) {
      if (type == 'CUSTOM')
        this.retrievalFrequency = this.computedRetrievalFrequencyItems.find(
          (i) => !i.isFrequencyRestrictedByPlan
        )?.value
      else this.retrievalFrequency = null
    },
    async submit() {
      const weekdays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
      const payload = {
        datasetName: this.datasetName,
        firstRetrievalDate: (this.firstRetrievalDate == 'NOW' ? moment() : moment(this.firstRetrievalSpecifiedTime))
          .utc()
          .format(DEFAULT_DATE_FORMAT),
        isFirstRetrievalDateNow:
          this.selectedSchedule && this.retrievalFrequencyType == 'ONCE' ? 'NOW' : this.firstRetrievalDate,
        datasetMode:
          this.selectedSchedule?.datasetMode || (this.datasetMode == 'REPLACE_LATEST' ? 'REPLACE' : this.datasetMode),
        uniqueSequenceColumn: !this.connector.scheduler?.hideUsc
          ? this.table?.cols.find((c) => c.field === this.uniqueSequenceColumn)
          : null,
        retrievalFrequency: this.retrievalFrequency || null,
        retrievalFrequencyUnit:
          this.retrievalFrequency == this.RETRIEVAL_FREQUENCY_UNITS.WEEKLY
            ? weekdays[this.retrievalFrequencyUnit]
            : this.retrievalFrequencyUnit,
        endRetrievalSpecifiedTime:
          this.retrievalFrequencyEnding != 'NEVER'
            ? moment(this.endRetrievalSpecifiedTime).utc().format(DEFAULT_DATE_FORMAT)
            : null,
        dataStartingFrom: moment(this.dataStartingFrom).utc().format(DEFAULT_DATE_FORMAT),
        dataEndingOn:
          this.computedIsScheduleTypePeriod &&
          this.dataEndingOn == 'ON_SET_DATE' &&
          moment(this.dataEndingOnSpecifiedTime).utc().format(DEFAULT_DATE_FORMAT),
        datasetId: this.selectedSchedule?.datasetId
      }

      if (this.selectedSchedule) {
        this.isSubmitLoading = true
        await dataLibraryApi.editSchedule(payload)
        this.isSubmitLoading = false
      }

      this.$emit('submit', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';

.mm-data-addition-third-party-scheduler {
  flex-direction: column;
  display: flex;

  .section-title--text {
    @extend .mm-text--body-bold;
    min-width: 190px;
  }

  .description--text {
    @extend .mm-text--caption-regular;
    color: var(--mm-color--n400);
  }

  .usc-ds-mode ~ .usc-ds-mode {
    margin-top: -24px;
  }

  .mm-dropdown,
  .mm-date-picker {
    max-width: 200px;
    width: 100%;
  }

  ::v-deep .mm-tooltip--slot-wrapper {
    align-self: center;
  }

  .action-buttons-wrapper {
    justify-content: flex-end;
    flex-direction: column;
    display: flex;
    flex-grow: 1;
  }
}
</style>
