import Vue from 'vue'

const getInitialState = () => ({
  viewId: '',
  viewsMap: {
    // viewId: {visibleRowCount: null, filterCondition: null, columns: []}
  },
  columnConfig: {
    //   column_1: {
    //     width: 300,
    //     format: {
    //       number: {
    //         shortForm: false,
    //         separator: true,
    //         percentage: true,
    //         currency: '',
    //         decimalPrecision: 2
    //       }
    //     }
    //   }
  },
  rowCount: null,
  isLoading: false,
  isDisabled: false,
  isPreview: false,
  sequence: null,
  gridKey: 0 // TODO after migration, verify is this key is still needed. This is needed because of some reactivity issues between angular's ngVue and Vue. Reference: MVP-10983
})

const getters = {
  view: (state, getters, rootState, rootGetters) => rootGetters['resources/getViewByViewId'](state.viewId),
  dataset: (state, getters, rootState, rootGetters) => rootGetters['resources/getDatasetByViewId'](state.viewId),
  columns: (state) => state.viewsMap[state.viewId]?.columns || [],
  visibleColumns: (state, getters) => getters.columns?.filter((column) => column.show).map((col) => col.id)
}

const mutations = {
  setViewId: (state, viewId) => (state.viewId = viewId),
  setLoading: (state, newState) => (state.isLoading = newState),
  setDisabled: (state, newState) => (state.isDisabled = newState),
  setIsPreview: (state, newState) => (state.isPreview = newState),
  setRowCount: (state, newState) => (state.rowCount = newState),
  setSequence: (state, newState) => (state.sequence = newState),
  setColumns: (state, { viewId, columns }) => {
    if (viewId in state.viewsMap) Vue.set(state.viewsMap[viewId], 'columns', columns)
    else Vue.set(state.viewsMap, viewId, { columns })
    state.gridKey++
  },
  setColumnSelection: (state, { selected, columnIds }) => {
    const view = state.viewsMap[state.viewId] || {}
    view.columns.filter((column) => columnIds.includes(column.id)).forEach((column) => (column.selected = selected))
    Vue.set(state.viewsMap, state.viewId, view)
  },
  setFilterCondition: (state, { viewId, filterCondition }) => {
    Vue.set(state.viewsMap[viewId], 'filterCondition', filterCondition)
  },
  setVisibleRowCount: (state, { viewId, visibleRowCount }) => {
    Vue.set(state.viewsMap[viewId], 'visibleRowCount', visibleRowCount)
  },
  setExploreCardColumns: (state, { columnIds }) => {
    const view = state.viewsMap[state.viewId] || {}
    view.columns.forEach((column) => (column.exploreCard = columnIds.includes(column.id)))
    Vue.set(state.viewsMap, state.viewId, view)
  },
  setExploreCardColumn: (state, { selected, columnId }) => {
    const view = state.viewsMap[state.viewId] || {}
    const column = view.columns.find((column) => column.id == columnId)
    if (column) column.exploreCard = selected
    Vue.set(state.viewsMap, state.viewId, view)
  },
  setColumnConfig: (state, newState) => (state.columnConfig = newState)
}

const actions = {
  selectColumns({ state, commit }, selectedColumnIds) {
    commit('setColumnSelection', {
      selected: true,
      columnIds: selectedColumnIds
    })
    const notSelectedColumns = state.viewsMap[state.viewId].columns
      .filter((column) => !selectedColumnIds.includes(column.id))
      .map((col) => col.id)
    commit('setColumnSelection', {
      selected: false,
      columnIds: notSelectedColumns
    })
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
