import * as angular from 'angular';

import { chartMenuManagerFactory } from './chart.create.menu';
import { renderChartElement } from './chart.directives';
import { chartRenderController } from './chart.display.controller';
import { funnelChartMenuManagerFactory } from './funnelChart.menu.manager';
import { pivotChartMenuManagerFactory } from './pivotChart.menu.manager';
import { plotMenuManagerFactory } from './plot.menu.manager';
import { chartThemes } from './themes';

export let chartModule = angular.module('app.element.chart', ['app.core', 'app.element.common']);
chartModule.factory('chartMenuManagerFactory', chartMenuManagerFactory);
chartModule.directive('renderChartElement', renderChartElement);
chartModule.controller('chartRenderController', chartRenderController);
chartModule.factory('funnelMenuManagerFactory', funnelChartMenuManagerFactory);
chartModule.factory('pivotChartMenuManagerFactory', pivotChartMenuManagerFactory);
chartModule.factory('plotMenuManagerFactory', plotMenuManagerFactory);
chartModule.service('chartThemes', chartThemes);
