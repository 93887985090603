
unstructureDataModalService.$inject = ['$uibModal', 'config', '$rootScope'];
export function unstructureDataModalService($uibModal, config, $rootScope){
  return {
    openByDsId: openByDsId
  };

  function openByDsId(ds_id, ds_status){
    var scope = $rootScope.$new();
    scope.datasource_id = ds_id;
    scope.datasource_status = ds_status;

    var modal = $uibModal.open({
      templateUrl: config.templates.unstructureData,
      controller: 'unstructureDataController',
      windowClass: 'modal-rawdata modal-size-xlarge',
      controllerAs: 'uvm',
      bindToController: true,
      scope: scope
    });

    return modal.result;
  }
}
