<template>
  <v-slide-group v-model="computedActiveTabIndex" center-active show-arrows class="navbar-tabs p-l-5">
    <v-slide-item v-for="(tab, idx) in computedVisibleTabs" :key="tab.viewId">
      <v-tab
        class="navbar-tab p-x-3"
        :class="{ 'navbar-tab-active': tab.viewId == computedActiveTab.viewId }"
        :ripple="false"
        @click="setActiveTab(tab)"
      >
        <div class="navbar-tab-content">
          <mm-tooltip position="bottom" :label="tab.properties.name" :open-delay="1500">
            <div class="navbar-tab--text">{{ tab.properties.name }}</div>
          </mm-tooltip>
          <mm-pill
            v-if="tab.properties.tasksQuantity"
            class="cursor-pointer m-l-3"
            :label="$tc('global.dictionary.tasks', tab.properties.tasksQuantity, tab.properties)"
            no-focus-on-click
            small
          />
        </div>

        <keep-alive>
          <mm-menu
            v-if="tab.viewId == computedActiveTab.viewId"
            :key="`${tab.viewId}_${menuKey}`"
            :close-on-content-click="false"
          >
            <template #activator="{ on }">
              <mm-button
                v-on="on"
                v-show="tab.viewId == computedActiveTab.viewId"
                class="m-l-2"
                :key="`view_options_button_${menuKey}`"
                icon="menu_3_dots_vertical"
                objective="tertiary"
                size="small"
                no-focus-on-click
              />
            </template>
            <template #content>
              <view-options
                :view="computedActiveTab"
                @delete="onDelete"
                @duplicate="onDuplicate"
                @rename="onRename"
                @optionExecuted="menuKey++"
                @toggleLoading="loading = $event"
              />
            </template>
          </mm-menu>
        </keep-alive>

        <mm-divider
          v-if="computedActiveTabIndex != idx && computedActiveTabIndex - 1 != idx"
          class="navbar-tab-divider m-l-5"
          vertical
        />
      </v-tab>
    </v-slide-item>

    <template slot="prev">
      <div class="height-100 justify-align-center">
        <mm-button class="m-r-3" icon="chevron_left" objective="tertiary" size="small" no-focus-on-click />
      </div>
    </template>

    <template slot="next">
      <div class="height-100 justify-align-center" style="max-width: fit-content">
        <div class="slider-next height-100 justify-align-center">
          <mm-button class="m-l-2" icon="chevron_right" objective="tertiary" size="small" no-focus-on-click />
          <mm-divider class="navbar-tab-divider m-x-3" vertical />
        </div>
        <div class="m-r-5">
          <mm-loader v-if="loading" class="m-l-2" />
          <mm-tooltip v-else position="bottom" :label="$t('navbar.new_view.tooltip')">
            <restrictions-wrapper validate-storage-limit>
              <mm-button icon="plus" objective="tertiary" size="small" no-focus-on-click @click.stop="addNew" />
            </restrictions-wrapper>
          </mm-tooltip>
        </div>
      </div>
    </template>
  </v-slide-group>
</template>

<script>
// Components
import ViewOptions from '@/modules/view/components/view-options/view-options'
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

// Constants
import { RESOURCE_STATUS, USER_EVENTS, getOldAppUrl } from '@/constants'

// API
import resourcesApi from '@/modules/resources/api/resources.api'

export default {
  name: 'navbar-tabs',
  components: { ViewOptions, RestrictionsWrapper },
  props: {
    tabs: {
      type: Array,
      required: true
      /*[{
      viewId: 1,
      isActive: Boolean,
      properties: {
        dataset: {
          id: 3,
          name: String
        },
        name: String,
        tasksQuantity: Number
      },
      type: "view"
      }]*/
    }
  },
  data: () => ({
    loading: false,
    menuKey: 0
  }),
  computed: {
    computedVisibleTabs() {
      return this.tabs.filter((t) => t.status != RESOURCE_STATUS.DELETED)
    },
    computedActiveTab() {
      return this.tabs.find((t) => t.isActive)
    },
    computedActiveTabIndex() {
      return this.computedVisibleTabs.findIndex((t) => t.isActive)
    }
  },
  methods: {
    setActiveTab(tab) {
      //this.$router.push({ name: 'View', params: { viewId: tab.viewId } })
      //TODO remove when angular code is gone
      window.location.href = `${getOldAppUrl()}/#/workspaces/${this.$store.state.workspaceId}/projects/${
        this.$store.state.projectId
      }/dataviews/${tab.viewId}`
      this.menuKey++

      this.$userEvents.save(USER_EVENTS.NAVBAR.TABS.OPEN_VIEW, { id: tab.viewId })
    },
    onDelete() {
      // this.$router.push({ name: 'View', params: { viewId: this.computedVisibleTabs[0].viewId } })
      //TODO remove when angular code is gone
      window.location.href = `${getOldAppUrl()}/#/workspaces/${this.$store.state.workspaceId}/projects/${
        this.$store.state.projectId
      }/dataviews/${this.computedVisibleTabs[0].viewId}`
    },
    onDuplicate({ newView }) {
      this.setActiveTab(newView)
    },
    onRename({ modalInputs }) {
      this.computedActiveTab.properties.name = modalInputs.newName
    },
    async addNew() {
      this.loading = true
      const { newView, error } = await resourcesApi.addNewView(this.computedActiveTab.properties.dataset.id)
      //TODO remove when angular code is gone, default api error handling should be used
      if (error) this.$toast.show({ content: this.$t(`global.api.${error.code}`), status: 'error' })
      else if (newView.viewId) this.setActiveTab(newView)

      this.$userEvents.save(USER_EVENTS.NAVBAR.TABS.ADD_NEW, { id: this.computedActiveTab.properties.dataset.id })
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';
@import '@mammoth_developer/mm-storybook/src/styles/spacing.scss';

$font-color: var(--mm-color--n800);
$navbar-active-tab-color: var(--mm-color--n10);
$navbar-active-tab-hover-color: var(--mm-color--n40);
$navbar-tabs-height: 26px;
$navbar-tab-top-margin: 2px;
$navbar-tabs-background-color: var(--mm-color--n60);
$active-tab-border-radius: 4px;

.navbar-tabs {
  background: $navbar-tabs-background-color;
  height: $navbar-tabs-height;

  .navbar-tab-divider {
    height: 16px;
  }

  .navbar-tab {
    min-width: 0px;

    .navbar-tab-content {
      display: flex;

      .mm-pill {
        text-transform: initial;
      }
    }

    .navbar-tab--text {
      @extend .mm-text--caption-regular;
      text-overflow: ellipsis;
      text-transform: none;
      white-space: nowrap;
      color: $font-color;
      max-width: 150px;
      overflow: hidden;
    }

    &:before {
      content: none;
    }

    &.navbar-tab-active {
      @extend .m-x-3;
      height: $navbar-tabs-height - $navbar-tab-top-margin;
      margin-top: $navbar-tab-top-margin;
      background: $navbar-active-tab-color;
      border-radius: $active-tab-border-radius $active-tab-border-radius 0px 0px;

      .navbar-tab--text {
        @extend .mm-text--caption-bold;
        @extend .m-l-2;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        box-shadow: 0px $active-tab-border-radius 0px 0px $navbar-active-tab-color;
        background: $navbar-tabs-background-color;
        width: $active-tab-border-radius;
        height: 24px;
        opacity: 1;
      }

      &::before {
        border-bottom-right-radius: $active-tab-border-radius;
        left: -$active-tab-border-radius;
      }

      &::after {
        border-bottom-left-radius: $active-tab-border-radius;
        right: -$active-tab-border-radius;
      }

      &:hover {
        background: $navbar-active-tab-hover-color;

        &::before,
        &::after {
          background: $navbar-active-tab-hover-color;
        }
      }
    }

    &:not(.navbar-tab-active) {
      .navbar-tab-content {
        @extend .p-t-1;
      }

      &:hover .navbar-tab--text {
        @extend .mm-text--caption-bold;
      }
    }
  }

  // Sliders
  ::v-deep .v-slide-group__prev,
  ::v-deep .v-slide-group__next {
    display: block !important;
    min-width: auto;
    flex: auto;
  }

  ::v-deep .v-slide-group__prev {
    display: flex;

    &.v-slide-group__prev--disabled {
      display: none !important;
    }
  }

  ::v-deep .v-slide-group__next {
    pointer-events: all;
    cursor: default;

    .slider-next {
      display: flex;
    }

    &.v-slide-group__next--disabled {
      .slider-next {
        display: none;
      }
    }
  }

  ::v-deep .v-slide-group__wrapper {
    max-width: fit-content;

    .v-slide-group__content {
      max-width: fit-content;
    }
  }
}
</style>
