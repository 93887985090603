/**
 * @ngInject
 **/
export function collections() {

  var service = {
    Queue: queue
  };

  function queue(elements) {
    this.elements = elements || [];
  }

  queue.prototype.enqueue = function(element) {
     this.elements.push(element);
   };

  queue.prototype.dequeue = function() {

    if (!this.isEmpty()) {
      return this.elements.shift();
    }
    else {
      return null;
    }
  };

  queue.prototype.isEmpty = function() {
    return this.elements.length === 0;
  };

  queue.prototype.length = function() {
    return this.elements.length;
  };

  return service;
}

