<template>
  <div class="content-header d-flex justify-space-between">
    <div class="folder-breadcrumb-container">
      <folder-breadcrumb v-if="!hideBreadcrumbs" :folderResourceId="folderResourceId" />
    </div>

    <div class="d-flex justify-end" style="height: fit-content">
      <data-addition
        v-if="!hideAddData"
        :folderResourceId="folderResourceId"
        :defaultConnectorKey="defaultConnectorKey"
        :defaultType="defaultType"
        @addDataModalClose="onAddDataModalClose"
      />
      <add-folder v-if="!hideAddFolder" class="m-l-5" :parentFolderResourceId="folderResourceId" />

      <mm-button
        v-if="!hideShareProject && computedIsUserAdminOfThisProject"
        class="m-l-5"
        icon="share"
        :label="$t('global.dictionary.share')"
        objective="tertiary"
        @click="isProjectManageModalOpen = true"
      />
      <project-modal-manage
        v-model="isProjectManageModalOpen"
        :title="$t('project.manage.share')"
        :project-id="$store.state.projectId"
        hide-actions
        @optionExecuted="onProjectOptionExecuted"
      />

      <div v-if="!hideManageProject" class="m-l-5">
        <mm-menu
          v-model="isProjectOptionsMenuOpen"
          :close-on-content-click="false"
          @input="$userEvents.save(USER_EVENTS.PROJECT_OPTIONS.TOGGLE)"
        >
          <template #activator="{ on }">
            <mm-button icon="menu_3_dots_horizontal" objective="tertiary" v-on="on" />
          </template>

          <template #content>
            <project-manage-menu
              :projectId="$store.state.projectId"
              @optionExecuted="onProjectOptionExecuted"
              @modalClosed="isProjectOptionsMenuOpen = false"
            />
          </template>
        </mm-menu>
      </div>
    </div>
  </div>
</template>

<script>
import FolderBreadcrumb from '@/components/folder-breadcrumb/folder-breadcrumb'
import DataAddition from '@/modules/data-library/components/data-library-data-addition/data-library-data-addition.vue'
import AddFolder from '@/modules/data-library/components/data-library-add-folder/data-library-add-folder'
import ProjectManageMenu from '@/modules/project/components/menu/project-manage-menu'
import ProjectModalManage from '@/modules/project/components/modals/project-modal-manage'

import { PROJECT_PERMISSIONS, USER_EVENTS } from '@/constants'

export default {
  components: {
    FolderBreadcrumb,
    ProjectManageMenu,
    ProjectModalManage,
    DataAddition,
    AddFolder
  },
  props: {
    hideBreadcrumbs: Boolean,
    hideAddData: Boolean,
    hideAddFolder: Boolean,
    hideShareProject: Boolean,
    hideManageProject: Boolean,
    folderResourceId: [String, Number],
    defaultConnectorKey: String,
    defaultType: String,
    onAddDataModalClose: Function
  },
  data() {
    return {
      isProjectOptionsMenuOpen: false,
      isProjectManageModalOpen: false
    }
  },
  created() {
    this.USER_EVENTS = USER_EVENTS
  },
  computed: {
    computedIsUserAdminOfThisProject() {
      return this.$store.getters.getCurrentProjectPermissions === PROJECT_PERMISSIONS.ADMIN
    }
  },
  methods: {
    onProjectOptionExecuted(optionType) {
      if (['DELETE', 'LEAVE'].includes(optionType)) {
        //TODO remove when angular code is gone
        this.$store.commit('deleteProject', this.$store.state.projectId)
        window.location.hash = ''
        window.location.href = `${window.location.origin}/#/landing`
        location.reload()
      }
    }
  }
}
</script>
<style scoped>
.folder-breadcrumb-container {
  flex-grow: 1;
  min-width: 300px;
}
</style>
