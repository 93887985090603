<template>
  <div>
    <span class="mm-text--caption-bold">
      {{ $tc(`global.dictionary.${operations[0].operationName.toLowerCase()}`, operations.length) }}:
    </span>
    <ul class="operation-list m-b-3">
      <li v-for="(operation, idx) in computedOperations" :key="`${operation.operationId}_${idx}`">
        <div class="operation-wrapper p-a-1">
          <div v-if="operation.operationName === RESOURCE_DEPENDENCIES.BRANCH_OUT_TO_PROJECT">
            <span class="mm-text--caption-regular"> {{ getDependencyProjectName(operation) }} </span>
          </div>
          <div v-else>
            <span v-if="operation.resourcePath" class="mm-text--caption-regular">
              {{ operation.resourcePath }} /
            </span>
            <mm-link :label="operation.resourceName" small underline @click="openResource(operation)" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// This component includes the In/Out operations output for the following operations: BRANCH_OUT, BRANCH_OUT_TO_PROJECT, CROSSTAB, JOIN, LOOKUP

//Constants
import { USER_EVENTS, RESOURCE_DEPENDENCIES, getOldAppUrl } from '@/constants'

//Utils
import { getDependencyProjectName, getResourcePath } from '@/modules/resources/utils'

export default {
  name: 'linked-in-out-list-operations',
  props: {
    operations: {
      type: Array,
      /*
      [
        {
          operationId: id,
          operationName: string,
          linkId: id,
        }
      ]
      */
      required: true
    }
  },
  created() {
    this.RESOURCE_DEPENDENCIES = RESOURCE_DEPENDENCIES
  },
  computed: {
    computedOperations() {
      return this.operations
        .map((operation) => {
          const resourceName = this.$store.state.resources.resourcesMap[operation.resourceId].properties.name
          const resourcePath = getResourcePath(operation.resourceId, { excludeResourcePath: true }).join(' / ')
          return {
            ...operation,
            resourceName,
            resourcePath,
            fullPath: resourcePath + resourceName
          }
        })
        .sort((a, b) => a.fullPath.localeCompare(b.fullPath))
    }
  },
  methods: {
    openResource(operation) {
      // TODO: remove when angular code is gone
      const redirectUrl = `${getOldAppUrl()}/#/workspaces/${this.$store.state.workspaceId}/projects/${
        this.$store.state.projectId
      }/dataviews/${operation.linkId}`
      window.open(redirectUrl, `_mammoth_ds_${operation.resourceDatasetId}`)

      this.$userEvents.save(USER_EVENTS.LINKED_IN_OUT.OPEN_VIEW, { id: operation.linkId })
    },
    getResourcePath,
    getDependencyProjectName
  }
}
</script>

<style lang="scss" scoped>
.operation-list {
  padding-left: 21px;
  display: grid;

  li {
    .operation-wrapper {
      overflow-wrap: anywhere;
      overflow: hidden;
    }

    &::marker {
      font-size: 13px;
    }
  }
}
</style>
