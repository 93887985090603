const getInitialState = () => ({
  activeSubItem: {
    /*
      title: 'Project',
      icon: 'publish_as_view_20px',
      path: '#/workspaces/2/shared-reports',
    */
  }
})

const getters = {}

const mutations = {
  setActiveSubItem(state, activeSubItem) {
    state.activeSubItem = activeSubItem
  }
}

const actions = {}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
