
/**
 * @ngInject
 */
clickEvents.$inject = ['eventCallbackManagerFactory'];
export function clickEvents(eventCallbackManagerFactory) {
  var clickEvent = new eventCallbackManagerFactory('main_on_click');
  var rightClickEvent = new eventCallbackManagerFactory('main_on_right_click');
  var selectEvent = new eventCallbackManagerFactory('main_on_select');
  var service = {
    onClick: clickEvent.add_callback,  // register callback listener- name, function(event)
    click: clickEvent.fire_event,
    onRightClick: rightClickEvent.add_callback,  // register callback listener- name, function(event)
    rightClick: rightClickEvent.fire_event,
    onSelect: selectEvent.add_callback,
    select: selectEvent.fire_event,
    clearSelection: clearSelection
  };
  function clearSelection() {
    if (window.getSelection().empty) {  // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {  // Firefox
      window.getSelection().removeAllRanges();
    }
  }
  return service;
}
