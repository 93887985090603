import * as angular from 'angular';

LabelFactory.$inject = ['utils', 'config', '$resource'] as ReadonlyArray<string>;
export function LabelFactory(utils, config, $resource) {
  let labelsResource = $resource(config.api.labels, {labelRId: '@labelRId'});
  return LabelObject;
  function LabelObject(initLabelData) {
    var self = this,
      _prevLabelData;

    self.update = update;
    self.del = del;
    self.rename = rename;

    if (initLabelData) {
      self.update(initLabelData);
    }

    function update(labelData) {
      if (angular.equals(_prevLabelData, labelData)) {
        return false;
      }
      angular.extend(self, labelData);
      self.unique_id = self.type + '_' + self.resource_id;
      self.shortName = utils.string.addCentreEllipsis(self.name);
      return true;
    }

    function del(args) {
      const label = new labelsResource();
      label.labelRId = self.resource_id;
      return label.$delete(args);
    }

    function rename(newName) {
      const patchParams = {"patch": [
        {"op": "replace", "path": "name", "value": newName}
      ]};
      return labelsResource.patch({labelRId: self.resource_id}, patchParams).$promise;
    }
  }
}
