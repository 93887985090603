import * as angular from 'angular';
import * as _ from 'lodash-es';

/**
 * @ngInject
 * Notification Request service
 */
NotificationService.$inject = ['eventCallbackManagerFactory', 'utils', 'NotificationsFactory', 'config',
                             '$resource', 'analyticsService', '$timeout', 'c', 'DatasourceService'];
export function NotificationService(eventCallbackManagerFactory, utils, NotificationsFactory, config,
                             $resource, analyticsService, $timeout, c, DatasourceService) {
  var NotificationsResource = $resource(config.api.notifications);
  var list_updated = new eventCallbackManagerFactory('notification_list_updated');
  var _list_dict:any = {};
  var _list = [];
  var _display_list = [];
  var highestDataUpdatedAt = 0;
  var prevItems = [];
  var service = {
    unreadCount: 0,
    updateList: utils.debounce(updateList, 500, false),
    onListUpdate: list_updated.add_callback,
    list: _list,
    display_list: _display_list,
    display_count: undefined,
    markRead: markRead,
    markReadMultiple: markReadMultiple,
    poppedUp: poppedUp,
    dismissNotification: dismissNotification,
    markNotPersistent: markNotPersistent,
    deleteNotification: utils.debounce(deleteNotification, 500, false)
  };
  function getById(id) {
    id = parseInt(id);
    return _list_dict[id] || undefined;
  }

  function updateList(notificationList) {
    if(_.isEqual(prevItems, notificationList)){
      return
    }
    prevItems = _.cloneDeep(notificationList);
    var notificationIds = [];

    // filter out those notifications having a property "no_notification" in "additional_properties"
    // no_notification here means that the action triggered, do not need notifications to be shown
    var visibleNotificationList = _.cloneDeep(notificationList).filter(function (el) {
      if(el.details.data){
        var no_notification = false;
        if(el.details.data.additional_properties){
          if(el.details.data.additional_properties.no_notification){
            no_notification = true;
          }
        }
      }
      return !no_notification;
    });
    angular.forEach(visibleNotificationList, function (item) {
      if (item.type == "REQUIRES_USER_DISMISSAL"){
        item.is_read = true;
      }
      
      var r = getById(item.id);
      if (!r) {
        return;
        _list_dict[item.id] = new NotificationsFactory(item);
        r = _list_dict[item.id];
      } else if (r.update instanceof Function){
        r.update(item);
      }
      var dataUpdatedAt = r.data_updated?.unix();
      if (dataUpdatedAt > highestDataUpdatedAt) {
        highestDataUpdatedAt = dataUpdatedAt;
      }
      // r.messageHTML = notificationsDescriber.htmlGen(item);
      notificationIds.push(item.id);
    });
    service.list = _.values(_list_dict);
    var display_list = _.filter(_list_dict, ["details.panel_display", true]);
    service.display_count = display_list.length;
    list_updated.fire_event();
    var unreadCount =  _.filter(_.filter(_list_dict, {"is_read":false}), ["details.panel_display", true]);
    service.unreadCount = unreadCount.length;

    let autosync_warningications = _.filter(service.list, ['details.type', 'autosync']);
    angular.forEach(autosync_warningications, function(notif){
      let eta = notif.details.data.ETA;
      let now: any = new Date();
      let etaInDate: any = new Date(eta);
      let timeLeft = etaInDate - now;
      if(timeLeft > 0){
        let ds_id = notif.details.data.DATASOURCE_ID;
        let dataset = DatasourceService.get_by_id(ds_id);
        if(dataset){
          let secondsLeft = Math.floor(timeLeft / 1000);
          dataset.syncState.setEta(eta, secondsLeft)
        }
        $timeout(function(){
          deleteNotification(notif);
        }, 60000);
      }
      else{
        deleteNotification(notif);
      }
    });
  }

  function markRead(notification) {
    if (_.isArray(notification)) {
      angular.forEach(notification, function (item) {
        item.markRead();
      });
    }
    else {
      notification.is_read = true;
      notification.markRead();
      //TODO: include callbacks for failures
      //   .then(function () {
      //   notification.is_read = true;
      // });
    }
  }

  function markReadMultiple(updatedUntil) {
    if (updatedUntil === undefined) {
      updatedUntil = highestDataUpdatedAt;
    }
    var patchParams = {patch: [{op: "replace", path: "isReadMultiple", value: updatedUntil}]};
    return NotificationsResource.patch({}, patchParams).$promise;
  }

  function poppedUp(notification){
    $timeout(function(){
      notification.details.has_popped_up = true;
      notification.poppedUp();
    });
  }

  function dismissNotification(notification) {
    analyticsService.userEventTrack(c.userEvents.notificationEvents.dismissNotification, {eventOrigin: "notification"});
    notification.status = "dismissed";
    notification.dismissNotification();
  }

  function markNotPersistent(notification){
    notification.details.not_persistent = true;
    notification.markNotPersistent();
  }

  function deleteNotification(notification) {
    analyticsService.userEventTrack(c.userEvents.notificationEvents.deleteNotification, {eventOrigin: "notification"});
    delete _list_dict[notification.id];
    service.list = _.values(_list_dict);
    list_updated.fire_event();
    notification.deleteNotification();
    // notification.deleteNotification().then(function () {
    //   delete _list_dict[notification.id];
    //   service.list = _.values(_list_dict);
    //   list_updated.fire_event();
    // });
  }
  return service;
}
