const getInitialState = () => ({
  isOpen: false,
  isError: true,
  isDraft: false,
  taskCount: 0,
  isReportPublished: false,
  isLiveLinkGenerated: false,
  isMammothManagedExport: false, // previous published to db
  isEmpty: true // remove isEmpty and derive it from pipelineItems.length
})

const getters = {
  isEmpty: (state) => state.isEmpty,
  isError: (state) => state.isError,
  isDraft: (state) => state.isDraft,
  isReportPublished: (state) => state.isReportPublished,
  isLiveLinkGenerated: (state) => state.isLiveLinkGenerated,
  isMammothManagedExport: (state) => state.isMammothManagedExport
}

const mutations = {
  setIsOpen: (state, isOpen) => (state.isOpen = isOpen),
  setIsError: (state, isError) => (state.isError = isError),
  setIsDraft: (state, isDraft) => (state.isDraft = isDraft),
  setTaskCount: (state, taskCount) => (state.taskCount = taskCount),
  setIsReportPublished: (state, isReportPublished) => (state.isReportPublished = isReportPublished),
  setIsLiveLinkGenerated: (state, isLiveLinkGenerated) => (state.isLiveLinkGenerated = isLiveLinkGenerated),
  setIsMammothManagedExport: (state, isMammothManagedExport) => (state.isMammothManagedExport = isMammothManagedExport),
  setIsEmpty: (state, isEmpty) => (state.isEmpty = isEmpty)
}

const actions = {}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
