export default {
  mounted() {
    try {
      this.setupWidget()

      window.FreshworksWidget('hide', 'launcher')
    } catch (error) {
      console.error('Freshworks widget failed to be mounted', error)
    }
  },
  methods: {
    setupWidget() {
      if (document.getElementById('freshdeskWidgetScript')) return

      // Append script element
      let widgetScript = document.createElement('script')
      widgetScript.id = 'freshdeskWidgetScript'
      widgetScript.setAttribute('src', 'https://widget.freshworks.com/widgets/43000002413.js')
      document.head.appendChild(widgetScript)

      // The following code has been generated by the Freshdesk Admin Panel
      // https://mammothio.freshdesk.com/a/admin/widgets/43000002413

      // Set widget id
      window.fwSettings = { widget_id: 43000002413 }

      // Initialize widget function
      if ('function' != typeof window.FreshworksWidget) {
        let n = function () {
          n.q.push(arguments)
        }
        n.q = []
        window.FreshworksWidget = n
      }
    },
    mixinFreshdeskWidget_showSupportWidget(prefilledFields) {
      window.FreshworksWidget('open')

      // Prefill name and email
      const { name, email } = this.$store.state.user
      if (name && email) window.FreshworksWidget('identify', 'ticketForm', { name, email, ...prefilledFields })
    }
  }
}
