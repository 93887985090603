/**
 * Module containing components that are common to all other modules.
 * @filterModule app.common
 * @memberof app
 */
import * as angular from 'angular';
import { utilsModule } from './utils';
import { analyticsService } from './analytics.service';
import { cannyService } from './canny.service';
import { appMetaInfo } from './appMetaInfo.service';
import { cellMenu } from './cellMenu.directive';
import { clickEvents } from './clickEvents.service';
import { colMenu } from './colMenu.directive';
import {config} from './app.config';
import {constants} from './app.constants';
import {deploymentConsts} from '../../app.deploymentConsts';
import {
  accordion,
  colTypeInput,
  dateFormatHasToBeNonNull,
  datepicker,
  draggable,
  droppable,
  executeWindowFocusChecks,
  hasToBeNonEmptyArray,
  hasToBeValidExpression,
  includeReplace,
  mainClicksListener,
  mainKeypressListener,
  maRevalidateOnModelChange,
  maValidate,
  maValidateForm,
  maValidateSubmit,
  multiSelect,
  observeScopeVariable,
  onEnterKey,
  onOutsideClick,
  pauseChildrenWatchersIf,
  scrollIntoViewCb,
  addBorderReplace,
  addAndRemoveClass,
  scrollIntoViewIf,
  scrollToTopOnChange,
  selectAllOnFocus,
  setFocusOnInit,
  setFocusOnTrue,
  showSpinnerLoader,
  smallInlineLoader,
  syncScroll,
  textTruncate,
  timepicker,
  valColumnName,
  valDate,
  valDuplicateCol,
  valReservedColName,
  valEmail,
  validateMetricName,
  validateViaFunction,
  valInteger,
  valNumber,
  valRenameResource,
  windowUnloadListener,
  mainWindowListener,
  bindNumberAsHumanisedHtml,
  tetherMenu,
  showPaymentError,
  showHorizontalScrollableControls,
  folderPicker,
  onScrolledToEnd,
  trustHtmlForBinding,
  bindScopeDisplayContent,
  allowAlphaNum, setFocusOnError, bindCopyKeys,
  mmTitle,
  mmSelect,
  mmLimit,
} from './common.directives';
import { gridFactory, ajaxGridDataFactory } from './grid.service';
import { keyboardEvents } from './keyboardEvents.service';
import { manageUsersController } from './manageUsers.controller';
import { modalService } from './modal.service';
import { modificationRequestTracker } from './modification.request';
import { navigationService } from './navigation.service';
import { summaryMenu } from './summaryMenu.directive';
import { tableRawDataGrid, tableDataGrid } from './table.data.grid.directive';
import { validationConstants } from './validation.constants';
import { validationService } from './validation.service';
import { dataviewRerunService, dataviewSafeDeleteResetService } from './dataview.utils';
import { mathModule } from './math/index';
import { pivotModule } from './pivot/index';
import { filterModule } from './filter/index';
import { dateModule } from './date/index';
import { CompatibilityWarnings } from './compatibility.warnings';
import { windowEvents } from './windowEvents.service';
import { commonFormattersModule } from './formatters/index';

import { folderPickerController } from './folderPicker.controller';
import { toastNotification } from './toastNotification.service';

export let commonModule = angular.module('app.common', [
  'ui.bootstrap', utilsModule.name, mathModule.name, filterModule.name, pivotModule.name, dateModule.name,
  commonFormattersModule.name, 'angulartics', 'ngResource'
]);
commonModule.constant('config', config);
commonModule.constant('c', constants);
commonModule.service('analyticsService', analyticsService);
commonModule.service('cannyService', cannyService);
commonModule.service('appMetaInfo', appMetaInfo);
commonModule.directive('cellMenu', cellMenu);
commonModule.service('clickEvents', clickEvents);
commonModule.directive('colMenu', colMenu);
commonModule.service('ajaxGridDataFactory', ajaxGridDataFactory);
commonModule.service('gridFactory', gridFactory);
commonModule.service('keyboardEvents', keyboardEvents);
commonModule.controller(config.controllers.manageUsers, manageUsersController);
commonModule.service('modalService', modalService);
commonModule.service('modificationRequestTracker', modificationRequestTracker);
commonModule.service('navigationService', navigationService);
commonModule.directive('summaryMenu', summaryMenu);
commonModule.directive('tableDataGrid', tableDataGrid);
commonModule.directive('tableRawDataGrid', tableRawDataGrid);
commonModule.constant('validationConstants', validationConstants);
commonModule.service('validationService', validationService);
commonModule.service('dataviewSafeDeleteResetService', dataviewSafeDeleteResetService);
commonModule.service('dataviewRerunService', dataviewRerunService);


// common.directives.ts --
commonModule.directive('colTypeInput', colTypeInput);
commonModule.directive('pauseChildrenWatchersIf', pauseChildrenWatchersIf);
commonModule.directive('includeReplace', includeReplace);
commonModule.directive('mainWindowListener', mainWindowListener);
commonModule.directive('mainKeypressListener', mainKeypressListener);
commonModule.directive('mainClicksListener', mainClicksListener);
commonModule.directive('showSpinnerLoader', showSpinnerLoader);
commonModule.directive('smallInlineLoader', smallInlineLoader);
commonModule.directive('scrollIntoViewCb', scrollIntoViewCb);
commonModule.directive('addBorderReplace', addBorderReplace);
commonModule.directive('addAndRemoveClass', addAndRemoveClass);
commonModule.directive('scrollIntoViewIf', scrollIntoViewIf);
commonModule.directive('executeWindowFocusChecks', executeWindowFocusChecks);
commonModule.directive('datepicker', datepicker);
commonModule.directive('timepicker', timepicker);
commonModule.directive('accordion', accordion);
commonModule.directive('draggable', draggable);
commonModule.directive('droppable', droppable);
commonModule.directive('onEnterKey', onEnterKey);
commonModule.directive('mmTitle', mmTitle);
commonModule.directive('mmSelect', mmSelect);

// Validators
commonModule.directive('valDate', valDate);
commonModule.directive('valDuplicateCol', valDuplicateCol);
commonModule.directive('valReservedColName', valReservedColName);
commonModule.directive('valInteger', valInteger);
commonModule.directive('valNumber', valNumber);
commonModule.directive('valEmail', valEmail);
commonModule.directive('dateFormatHasToBeNonNull', dateFormatHasToBeNonNull);
commonModule.directive('valRenameResource', valRenameResource);
commonModule.directive('valColumnName', valColumnName);
commonModule.directive('allowAlphaNum', allowAlphaNum);
commonModule.directive('hasToBeValidExpression', hasToBeValidExpression);
commonModule.directive('hasToBeNonEmptyArray', hasToBeNonEmptyArray);
commonModule.directive('maValidate', maValidate);
commonModule.directive('maRevalidateOnModelChange', maRevalidateOnModelChange);
commonModule.directive('maValidateForm', maValidateForm);
commonModule.directive('maValidateSubmit', maValidateSubmit);
commonModule.directive('windowUnloadListener', windowUnloadListener);
commonModule.directive('validateMetricName', validateMetricName);
commonModule.directive('multiSelect', multiSelect);
commonModule.directive('syncScroll', syncScroll);
commonModule.directive('setFocusOnInit', setFocusOnInit);
commonModule.directive('setFocusOnError', setFocusOnError);
commonModule.directive('setFocusOnTrue', setFocusOnTrue);
commonModule.directive('onOutsideClick', onOutsideClick);
commonModule.directive('validateViaFunction', validateViaFunction);
commonModule.directive('scrollToTopOnChange', scrollToTopOnChange);
commonModule.directive('observeScopeVariable', observeScopeVariable);
commonModule.filter('textTruncate', textTruncate);
commonModule.filter('trustHtmlForBinding', trustHtmlForBinding);
commonModule.directive('bindScopeDisplayContent', bindScopeDisplayContent);
commonModule.directive('selectAllOnFocus', selectAllOnFocus);
commonModule.service('CompatibilityWarnings', CompatibilityWarnings);
commonModule.service('toastNotification', toastNotification);
commonModule.service('windowEvents', windowEvents);
commonModule.service('deploymentConsts', deploymentConsts);
commonModule.directive('bindNumberAsHumanisedHtml', bindNumberAsHumanisedHtml);
commonModule.directive('bindCopyKeys', bindCopyKeys);
commonModule.directive('tetherMenu', tetherMenu);
commonModule.controller('folderPickerController', folderPickerController);
commonModule.directive('folderPicker', folderPicker);
commonModule.directive('showHorizontalScrollableControls', showHorizontalScrollableControls);
commonModule.directive('onScrolledToEnd', onScrolledToEnd);
commonModule.directive('showPaymentError', showPaymentError);

// common.filter.ts --
commonModule.filter('mmLimit', mmLimit);
