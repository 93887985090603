import * as _ from 'lodash-es';
import * as $ from 'jquery';
import introJs from 'intro.js';

let OVERLAY_OPACITY = 0.2;

/**
 * @ngInject
 */
appHelperService.$inject = ['$templateCache', 'appHelperHintsConfig', 'appHelperOnBoardingConfig', 'windowEvents', '$timeout', 'c',
  '$location', 'eventCallbackManagerFactory', 'UserWorkspace', 'DatasourceService', 'analyticsService',
  '$rootScope', '$compile'];

export function appHelperService($templateCache, appHelperHintsConfig, appHelperOnBoardingConfig, windowEvents, $timeout, c,
                                 $location, eventCallbackManagerFactory, UserWorkspace, DatasourceService, analyticsService,
                                 $rootScope, $compile) {
  let introSession;
  let currentHints;
  let currentPage = _detectCurrentPage();
  let dismissedHints = [];
  let onboardingEndEvent = new eventCallbackManagerFactory('onboardingEndEvent');
  let scope = $rootScope.$new();
  let eventOrigin = undefined;

  windowEvents.onResize('app_helper_resize', _closeHintsIfOpen);

  return {
    startOnBoarding: startOnBoarding,
    toggleHints: toggleHints,
    reRenderHints: reRenderHints,
    onOnboardingEnd: onboardingEndEvent.add_callback,
    getCurrentPage: _detectCurrentPage,
    markOnBoardingSessionAsSeenGlobal: markOnBoardingSessionAsSeenGlobal,
    navigateToDataset: navigateToDataset,
    get isHintsOn() {
      return !!currentHints;
    },
  };
  function navigateToDataset(ds_id){
    DatasourceService.navigateToDataset(ds_id)
  }
  function _closeHintsIfOpen() {
    if (introSession && currentHints) {
      toggleHints();
    }
  }

  function _refreshCurrentPage() {
    currentPage = _detectCurrentPage();
  }

  function _enrichClass(item, defaultCls) {
    item.tooltipClass = defaultCls;
    if (item.customClass) {
      item.tooltipClass = item.tooltipClass + ' ' + item.customClass;
    }
  }


  function startOnBoarding(section, force=false, type='intro') {
    _refreshCurrentPage();
    if (!section) {
      section = currentPage;
    } 
    if(!force){
      if (section == 'landing' && !_.filter(DatasourceService.list, {'type': 'datasource', 'status': 'ready'}).length) {
        return;
      }
    }
    let options;

    options = {
      showStepNumbers: false,
      scrollToElement: false,
      overlayOpacity: OVERLAY_OPACITY,
      highlightClass: 'product-tour-highlight',
      exitOnOverlayClick: false,
      nextLabel: 'Get Started',
      doneLabel: 'Got it',
      hidePrev: true,
    };


    const stepType = type === 'sampleFlow' ? 'dataviewSampleFlow' : section;
    let steps = getIntroSteps(stepType);
    _.forEach(steps, function (step, i) {
      let tooltipClass = 'product-tour-tooltip tooltip-' + i;
      if (i == 0) {
        tooltipClass += ' tooltip-first';
      }
      if (i == steps.length - 1) {
        tooltipClass += ' tooltip-last';
      }
      _enrichClass(step, tooltipClass);
    });


    options.steps = steps;

    if (section == 'dataview') {
      eventOrigin = 'dataview';
    } else if (section == 'landing') {
      eventOrigin = 'landingPage';
    } else {
      eventOrigin = section;
    }


    if (options.steps.length) {
      currentHints = null;
      _initNewSession();
      introSession.setOptions(options);
      introSession.start();
      analyticsService.userEventTrack(c.userEvents.onboarding.start, {onboardingSection: section, eventOrigin: eventOrigin});
      introSession.onbeforeexit(function () {
        const currentStep = introSession._currentStep;
        var step = _.get(_.get(introSession, '_options.steps'), currentStep);
        if (step && currentStep !== _.get(introSession, '_options.steps').length - 1) {
          $timeout(function () {
            analyticsService.userEventTrack(c.userEvents.onboarding.exit, {
              onboardingSection: section,
              onboardingId: step._mammothId,
              selector: step.element,
              'class': step.tooltipClass,
              eventOrigin: eventOrigin
            }, {
              onboardingId: step._mammothId,
              onboardingStepsCompleted: currentStep,
            });
          });
        }
      });
      introSession.onexit(function () {
        introSession = undefined;
        onboardingEndEvent.fire_event();
        markOnBoardingSessionAsSeen(section);
      } as any);
      introSession.onafterchange(function (targetElement) {
    

        var step = _.get(_.get(introSession, '_options.steps'), introSession._currentStep);
        $timeout(function () {
          analyticsService.userEventTrack(c.userEvents.onboarding.change, {
            onboardingSection: section,
            onboardingId: step._mammothId,
            selector: step.element,
            'class': step.tooltipClass,
            eventOrigin: eventOrigin
          });
        });
      })

      introSession.onbeforechange(function(targetElement) {
        const key = introSession._currentStep;
        var step = _.get(_.get(introSession, '_options.steps'), key);

        if(key === 0) {
          introSession.setOption("nextLabel", "Get Started");
        } else {
          introSession.setOption("nextLabel", "Next");
        }

        if(step._mammothId === 'exploreButton' || step._mammothId === 'sampleFlowExploreButton'){
          const elem = $(step.element).get(0)
          introSession._introItems[key].element = elem;
        }

        
        if(step._mammothId === 'exploreCard' || step._mammothId === 'sampleFlowExploreCard'){
          if(document.querySelector('.explore-card') === null) {
            return new Promise<void>((resolve) => {
              triggerElementClick('.header-cell-action-wrapper .mm-tooltip--slot-wrapper svg[data-name=explore]')
              setTimeout(() => {
                const elem = $('.explore-card').get(0)
                introSession._introItems[key].element = elem;
                resolve()
              }, 300)
            });
          } else {
            const elem = $('.explore-card').get(0)
            introSession._introItems[key].element = elem;
          }
        }
      });
      
      introSession.oncomplete(function () {
        markOnBoardingSessionAsSeen(section);
        analyticsService.userEventTrack(c.userEvents.onboarding.complete, {onboardingSection: section, eventOrigin: eventOrigin});
      });
    }
  }

  function triggerElementClick(elem) {
    var event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
    const element = $(elem).get(0)
    element?.dispatchEvent(event)
  }

  function markOnBoardingSessionAsSeenGlobal(value) {
    UserWorkspace.set_ui_preferences('GLOBAL.ONBOARDING', {landing: value, dataview: value});
    if (value) {
      _exitExistingSessions();
    }
  }

  function markOnBoardingSessionAsSeen(section) {
    UserWorkspace.set_ui_preferences('GLOBAL.ONBOARDING.' + section, true);
  }

  function toggleHints(section = undefined, resetDismissed = undefined) {
    _refreshCurrentPage();
    if (!section) {
      section = currentPage;
    }
    let currentSessionLength;
    let options;
    if (resetDismissed) {
      dismissedHints = [];
    }
    if (currentHints) {
      _exitExistingSessions();
      currentHints = null;
      return;
    }
    options = {};
    let hints = getPageHints(section);
    hints = _.filter(hints, function (h, i) {
      return dismissedHints.indexOf(h.element) == -1;
    });
    _.forEach(hints, function (h) {
      _enrichClass(h, 'product-hint-tooltip');
    });


    options.hints = hints;
    currentSessionLength = options.hints.length;

    if (options.hints.length) {
      currentHints = section;
      _initNewSession();
      introSession.setOptions(options);
      introSession.showHints();
      _alignHints();
      introSession.onhintclose(_onHintClose);
      _showHintsOnHover();
      analyticsService.userEventTrack(c.userEvents.hints.turnOn, {hintSection: section, eventOrigin: eventOrigin});
    }

    function _onHintClose(hintIndex) {
      dismissedHints.push(options.hints[hintIndex].element);
      currentSessionLength--;
      if (!currentSessionLength) {
        toggleHints(section);
      }
    }

    function _alignHints() {
      _.forEach(options.hints, function (hint, i) {
        let hintDotEle = $('.introjs-hint[data-step=\'' + i + '\']');
        let targetEle = $(hint.element);
        let elePosition = getAbsPos(targetEle);
        let left = elePosition.left - 10;
        let top = elePosition.top - 10;
        if (left || top) {
          hintDotEle.css({left: left, top: top});
        }
      });

      function getAbsPos(ele) {
        var offset = ele.offset();
        var posY = offset.top + ele.outerHeight() / 2;
        var posX = offset.left + ele.outerWidth() / 2;
        return {
          top: posY,
          left: posX
        };
      }
    }
  }

  function _showHintsOnHover() {
    var hintEle = $('.introjs-hint.introjs-fixedhint');
    hintEle.mouseenter(_onMouseEnter);
    hintEle.mouseleave(_onMouseLeave);
    hintEle.click(_onHintButtonClick);

    function _onMouseEnter(event) {
      let dataStep = parseInt($(event.currentTarget).attr('data-step'));
      introSession.showHintDialog(dataStep);
      var step = _.get(_.get(introSession, '_options.hints'), dataStep);
      $timeout(function () {
        analyticsService.userEventTrack(c.userEvents.hints.enterHover, {
          hintSection: currentHints,
          hintId: step._mammothId,
          selector: step.element,
          'class': step.tooltipClass,
          eventOrigin: eventOrigin
        });
      });

    }

    function _onMouseLeave() {
      let dataStep = parseInt($(event.currentTarget).attr('data-step'));
      introSession.showHintDialog(dataStep);
      var step = _.get(_.get(introSession, '_options.hints'), dataStep);
      $timeout(function () {
        analyticsService.userEventTrack(c.userEvents.hints.leaveHover, {
          hintSection: currentHints,
          hintId: step._mammothId,
          selector: step.element,
          'class': step.tooltipClass,
          eventOrigin: eventOrigin
        });
      });
    }

    function _onHintButtonClick() {
      let dataStep = parseInt($(event.currentTarget).attr('data-step'));
      introSession.showHintDialog(dataStep);
      var step = _.get(_.get(introSession, '_options.hints'), dataStep);
      $timeout(function () {
        analyticsService.userEventTrack(c.userEvents.hints.clicked, {
          hintSection: currentHints,
          hintId: step._mammothId,
          selector: step.element,
          'class': step.tooltipClass,
          eventOrigin: eventOrigin
        });
      });
    }
  }

  function _initNewSession() {
    _exitExistingSessions();
    introSession = introJs();
  }


  function reRenderHints(delay) {
    if (!delay) {
      delay = 1000;
    }
    if (introSession && currentHints) {
      toggleHints();
      $timeout(function () {
        toggleHints(currentHints);
      }, delay);
    }
  }

  function _detectCurrentPage() {
    let currentPath = $location.path();
    if (currentPath.search('dataviews') != -1) {
      return 'dataview';
    }
    return 'landing';
  }


  function _exitExistingSessions() {
    if (introSession) {
      let container = document.querySelector('.introjs-hints');
      if (container) {
        while (container.firstChild) {
          container.removeChild(container.firstChild);
        }
      }
      introSession.exit(true); //force exit older intro sessions
      introSession = null;
      analyticsService.userEventTrack(c.userEvents.hints.turnOff, {hintSection: currentHints, eventOrigin: eventOrigin});
    }
  }

  function getIntroSteps(page) {
    return getFiltered('intro', appHelperOnBoardingConfig[page]);
  }

  function getPageHints(page) {
    return getFiltered('hint', appHelperHintsConfig[page]);
  }

  function formatHint(htmlKey, tip) {
    let templateId = 'app.helper.template.' + tip.id
    let item = <any>{
      _mammothId: tip.id,
      hintAnimation: true,
    }
    item[htmlKey] = $templateCache.get(templateId)
    if (tip.customClass) {
      item.customClass = tip.customClass
    }
    if (tip.hintPosition) {
      item.position = tip.hintPosition
    } else if(!item.position) {
      item.position = 'bottom-middle'
    }
    return item
  }

  function getFiltered(htmlKey, pageCfg) {
    let items = [];
    _.forEach(pageCfg, function (tip, i) {
      const item = formatHint(htmlKey, tip);
      if (tip.element) {
        if (isElementVisible(tip.element)) {
          item.element = tip.element;
          items.push(item);
        }
      } else {
        items.push(item);
      }
    });
    return items;
  }

  function isElementVisible(element) {
    var domEle = $(element).get(0);
    var isComputedDisplay = true;
    if (domEle) {
      var cStyle = window.getComputedStyle(domEle, null);
      isComputedDisplay = cStyle.getPropertyValue('display') != 'none';
      if (!isComputedDisplay) {
        $(element).css('display', '');
        isComputedDisplay = true;
      }
    }
    return $(element).is(':visible') && isComputedDisplay;
  }


}
