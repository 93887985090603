<template>
  <div class="mm-view-options">
    <restrictions-wrapper>
      <mm-list
        class="p-a-3"
        :items="computedOptionsListItems"
        :divider-at-index="computedOptionsListDividerIndexes"
        @itemClick="$event.item.clickHandler()"
      />
    </restrictions-wrapper>

    <!-- view-options-modal -->
    <component
      :is="modalComponent"
      v-model="showModal"
      :key="`view-options-modal_${modalKey}`"
      :views="[view]"
      :view="view"
      :resources="[view]"
      @submit="submitModal"
      @input="$emit('optionExecuted')"
    />
  </div>
</template>

<script>
//Constants
import {
  SPLITIO_FEATURE_FLAGS,
  RESOURCE_DRAFT_STATUS,
  RESOURCE_PIPELINE_STATUS,
  REQUESTS_STATUS,
  RESOURCE_STATUS,
  USER_EVENTS
} from '@/constants'
const TEMPLATE_EXTENSION = 'mammoth'

// Plugins
import { featuresVisibility } from '@/plugins/splitio'

// API
import resourcesApi from '@/modules/resources/api/resources.api'
import viewApi from '@/modules/view/api/view.api'

// Utils
import { saveFile, readFiles } from '@/utils/files'

// Components
import ResourceModalDelete from '@/modules/resources/components/resource-modal-delete/resource-modal-delete.vue'
import ViewOptionsModalRename from './view-options-modals/view-options-modal-rename'
import ViewOptionsModalReset from './view-options-modals/view-options-modal-reset'
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

export default {
  name: 'view-options',
  components: { RestrictionsWrapper },
  props: {
    view: {
      type: Object,
      required: true
      /*
      {
        viewId: -1,
        properties: { 
          name: '',
          dataSync: false,
          pipelineStatus: '',
          draftModeStatus: '',
          tasksQuantity: -1,
          dataset: { id: -1 }
        }
      }
      */
    }
  },
  data: () => ({
    showModal: false,
    templateFiles: [],
    modalKey: 0,
    modalComponent: null,
    tempDataSyncStatus: null
  }),
  computed: {
    computedOptionsListItems() {
      return [
        {
          text: this.$t('data_library.views.data_sync'),
          icon: this.tempDataSyncStatus ? 'check' : 'sync_disabled',
          clickHandler: () => this.toggleDataSync(),
          divider: true,
          render: () => featuresVisibility[SPLITIO_FEATURE_FLAGS.DATA_SYNC],
          validateStorageLimit: true
        },
        {
          text: this.$t('global.dictionary.rename'),
          disabled: this.view.properties.pipelineStatus === RESOURCE_PIPELINE_STATUS.ERROR,
          clickHandler: () => this.openModal(ViewOptionsModalRename)
        },
        {
          text: this.$t('global.dictionary.duplicate'),
          disabled: this.view.properties.pipelineStatus === RESOURCE_PIPELINE_STATUS.ERROR,
          clickHandler: () => this.duplicate(),
          validateStorageLimit: true
        },
        {
          text: this.$t('global.dictionary.reset'),
          clickHandler: () => this.openModal(ViewOptionsModalReset)
        },
        {
          text: this.$t('global.dictionary.delete'),
          disabled: this.computedIsDeleteDisabled,
          clickHandler: () => this.openModal(ResourceModalDelete),
          divider: true
        },
        {
          text: this.$t('navbar.tab.menu.save_as_template.title'),
          disabled:
            this.view.properties.pipelineStatus === RESOURCE_PIPELINE_STATUS.ERROR ||
            this.view.properties.draftModeStatus === RESOURCE_DRAFT_STATUS.DIRTY,
          clickHandler: () => this.saveAsTemplate(),
          render: () => featuresVisibility[SPLITIO_FEATURE_FLAGS.TEMPLATES]
        },
        {
          text: this.$t('navbar.tab.menu.apply_template.title'),
          disabled: this.view.properties.tasksQuantity > 0,
          clickHandler: () => readFiles(this.templateFiles, [TEMPLATE_EXTENSION]),
          render: () => featuresVisibility[SPLITIO_FEATURE_FLAGS.TEMPLATES],
          validateStorageLimit: true
        }
      ].filter((option) => (option.render ? option.render() : true))
    },
    computedOptionsListDividerIndexes() {
      return this.computedOptionsListItems.map((item, idx) => item.divider && idx + 1).filter((item) => item)
    },
    computedIsDeleteDisabled() {
      const datasetViews = Object.values(this.$store.state.resources.resourcesMap).filter(
        (r) => r.viewId && r.properties.dataset.id == this.view.properties.dataset.id
      )
      return datasetViews.length < 2
    }
  },
  watch: {
    templateFiles(val) {
      if (val.length > 0) this.applyTemplate()
    },
    'view.properties.dataSync': {
      handler(val) {
        this.tempDataSyncStatus = val
      },
      immediate: true
    }
  },
  methods: {
    openModal(modalComponent) {
      this.modalComponent = modalComponent
      this.modalKey++
      this.showModal = true
    },
    async submitModal(modalInputs) {
      this.$emit('optionExecuted')
      this.$emit('toggleLoading', true)

      switch (this.modalComponent) {
        case ViewOptionsModalRename:
          this.$userEvents.save(USER_EVENTS.VIEW_OPTIONS.RENAME, {
            id: this.view.viewId,
            previousName: this.view.properties.name,
            newName: modalInputs.newName
          })
          await viewApi.rename(this.view.viewId, modalInputs.newName)
          this.$emit('rename', { modalInputs, view: this.view })

          break

        case ViewOptionsModalReset: {
          this.$userEvents.save(USER_EVENTS.VIEW_OPTIONS.RESET, { id: this.view.viewId })

          const resetResponse = await viewApi.reset(this.view.viewId)
          if (resetResponse.status != REQUESTS_STATUS.SUCCESS)
            this.$toast.show({ content: this.$t('navbar.tab.menu.reset.error'), status: 'error' })
          else this.$emit('reset', this.view)

          break
        }
        case ResourceModalDelete: {
          const viewId = this.view.viewId
          this.$userEvents.save(USER_EVENTS.VIEW_OPTIONS.DELETE, { id: viewId })

          // TODO: remove this when angular code is gone. This is done in order prevent infinite loader in navbar if angular refreshes the page before the future request is resolved.
          this.$emit('toggleLoading', false)
          const removeResponse = await viewApi.remove(viewId)
          if (removeResponse.status != REQUESTS_STATUS.SUCCESS)
            this.$toast.show({
              content: this.$t('navbar.tab.menu.delete.error', { viewName: this.view.properties.name }),
              status: 'error'
            })
          else {
            // Get deleted tab view to prevent active tab being changed during deletion process
            const deletedView = this.$store.getters['resources/getViewByViewId'](viewId)
            if (deletedView) deletedView.status = RESOURCE_STATUS.DELETED
            this.$emit('delete', deletedView || this.view)
          }

          break
        }
      }

      this.$emit('toggleLoading', false)
    },
    async toggleDataSync() {
      this.$userEvents.save(USER_EVENTS.VIEW_OPTIONS.TOGGLE_DATA_SYNC, {
        id: this.view.viewId,
        status: !this.view.properties.dataSync
      })

      this.tempDataSyncStatus = !this.view.properties.dataSync
      await viewApi.toggleViewsDataSyncStatus([this.view.viewId], this.tempDataSyncStatus)
      this.$toast.show({ content: this.$t('view.dataflow_settings.data_sync_options.success'), status: 'success' })
    },
    async saveAsTemplate() {
      this.$emit('optionExecuted')
      this.$userEvents.save(USER_EVENTS.VIEW_OPTIONS.SAVE_AS_TEMPLATE, { id: this.view.viewId })

      const templateResponse = await viewApi.loadTemplate(this.view.viewId)
      const templateContent = JSON.stringify(templateResponse, null, 4)
      const datasetName = this.$store.getters['resources/getDatasetByViewId'](this.view.viewId).properties.name
      const templateName = `${datasetName}_${this.view.properties.name}.${TEMPLATE_EXTENSION}`
        .toLowerCase()
        .split(' ')
        .join('_')

      saveFile(templateContent, templateName)
      this.$emit('saveAsTemplate', this.view)
    },
    async applyTemplate() {
      this.$emit('optionExecuted')
      this.$userEvents.save(USER_EVENTS.VIEW_OPTIONS.APPLY_TEMPLATE, { id: this.view.viewId })

      if (this.templateFiles[0]?.name.endsWith(TEMPLATE_EXTENSION)) {
        const { data } = await viewApi.applyTemplate(this.view.viewId, JSON.parse(this.templateFiles[0].content))
        if (data.ERROR_CODE) {
          const errorMessage = this.$te(`global.api.${data.ERROR_CODE}`)
            ? this.$t(`global.api.${data.ERROR_CODE}`)
            : this.$t('global.api.generic_error')
          this.$toast.show({ content: errorMessage, status: 'error' })
        }
        this.$emit('applyTemplate', this.view)
      } else this.$toast.show({ content: this.$t('navbar.tab.menu.apply_template.invalid_file'), status: 'error' })

      this.templateFiles = []
    },
    async duplicate() {
      this.$emit('optionExecuted')
      this.$emit('toggleLoading', true)
      this.$userEvents.save(USER_EVENTS.VIEW_OPTIONS.DUPLICATE, { originalViewId: this.view.viewId })

      const { newView, error } = await resourcesApi.duplicateView(this.view.viewId, this.view.properties.dataset.id)
      if (error) this.$toast.show({ content: this.$t(`global.api.${error.code}`), status: 'error' })
      else if (newView.viewId) this.$emit('duplicate', { newView, originalView: this.view })

      this.$emit('toggleLoading', false)
    }
  }
}
</script>
