/**
 * Created by mammoth2 on 29/08/18.
 */
import * as angular from "angular";
import * as _ from 'lodash-es';
import {ExploreConsts, max_sample_run_count} from "./explore.constants";
import * as dateformat from "dateformat";
import * as moment from "moment"
// let moment = require('moment');

export function digestSelection(data, selected){
  let selectedValues = [];
  let nullSelected = false;
  angular.forEach(selected, function(val){
    if(val == '(blank)'){
      nullSelected = true;
    }
    else{
      selectedValues.push(val);
    }
  });
  return {
    selected: selectedValues,
    nullSelected: nullSelected
  }
}


export function combineCondition(logicalOp, condition1, condition2){
  let conditionArray = [];
  if(condition1){
    conditionArray.push(condition1);
  }
  if(condition2){
    conditionArray.push(condition2);
  }

  if(conditionArray.length == 0){
    return null;
  }
  else if(conditionArray.length == 1){
    return conditionArray[0];
  }
  else{
    let finalCondition = {};
    finalCondition[logicalOp] = conditionArray;
    return finalCondition;
  }
}

export function isNullCondition(condition, internal_name){
  if(condition.hasOwnProperty(internal_name)){
    if(condition[internal_name].hasOwnProperty('IS_EMPTY')){
      return true;
    }
  }
  return false;
}


export function getListCondition(values, internal_name, column_type, operator, level){
  if(column_type == 'DATE'){
    return getDateSelectedConditions(internal_name, values, level, operator == 'NE');
  }
  else if(column_type == 'NUMERIC'){
    return getNumSelectedConditions(internal_name, values, level, operator == 'NE');
  }
}

export function getPivotGroupByParam(internalName, ctype, level){
  if(ctype == 'NUMERIC'){
    return [{COLUMN: internalName, RESOLUTION: level, INTERNAL_NAME: 'value'}];
  }
  else if(ctype == 'DATE'){
    return [{COLUMN: internalName, TRUNCATE: level, INTERNAL_NAME: 'value'}];
  }
}


export function getPivotSelectParam(aggType, argInternalName, minMaxCol?): any[] {
  let result;
  if(aggType == 'COUNT'){
    result = [
      {FUNCTION: 'COUNT', 'AS': 'count', 'INTERNAL_NAME': 'agg'},
      {FUNCTION: 'COUNT', 'AS': 'Unformatted', 'INTERNAL_NAME': 'unformatted'},
      {FUNCTION: 'PERCENTAGE', 'AS': 'percentage', 'INTERNAL_NAME': 'percentage', 'AGGREGATION': 'COUNT'}
    ];
  }
  else{
    result = [
      {FUNCTION: aggType, 'AS': aggType, 'INTERNAL_NAME': 'agg', COLUMN: argInternalName},
      {FUNCTION: aggType, 'AS': 'Unformatted', 'INTERNAL_NAME': 'unformatted', COLUMN: argInternalName},
      {FUNCTION: 'PERCENTAGE', 'AS': 'percentage', 'INTERNAL_NAME': 'percentage', 'AGGREGATION': aggType, COLUMN: argInternalName}
    ];
  }
  if(minMaxCol){
    result.push({FUNCTION: 'MIN', INTERNAL_NAME: 'min', AS: 'MIN_VAL', COLUMN: minMaxCol});
    result.push({FUNCTION: 'MAX', INTERNAL_NAME: 'max', AS: 'MAX_VAL', COLUMN: minMaxCol});
  }
  return result;
}


function getDateSelectedConditions(col_internal_name, listOfSelected, truncateLevel, isNegative){
  let subConditions = [];
  _.forEach(listOfSelected, function(val){
    let subcondition = {};
    if(!isNegative){
      subcondition[col_internal_name] = {EQ:{'VALUE': {TRUNCATE: truncateLevel, VALUE: val}}}
    }
    else{
      subcondition[col_internal_name] = {NE:{'VALUE':{TRUNCATE: truncateLevel, VALUE: val}}}
    }
    subConditions.push(subcondition);
  });
  if(subConditions.length == 1){
    return subConditions[0]
  }
  else if(subConditions.length == 0){
    return null;
  }
  else{
    if(isNegative){
      return {AND: subConditions}
    }
    else{
      return {OR: subConditions}
    }
  }
}

function constructBaseCondition(colname, operator, operand){
  let c = {};
  c[colname] = {};
  c[colname][operator] = operand;
  return c;
}

export function getNumericInListCondition(col_internal_name, listOfSelected) {
  let condition = {};
  condition[col_internal_name] = {}
  condition[col_internal_name] = {'IN_LIST': {
    'VALUE': listOfSelected
  }}
  return condition
 
    

}
function getNumSelectedConditions(col_internal_name, listOfSelected, queryLevel, isNegative){
  let subConditions = [];
  _.forEach(listOfSelected, function(val){
    let subcondition = {};
    let upperBound = getRangeUpperBound('NUMERIC', val, queryLevel);

    if(!isNegative){
      let lowerBoundCondition = constructBaseCondition(col_internal_name, 'GTE', val);
      let upperBoundCondition = constructBaseCondition(col_internal_name, 'LT', upperBound);
      subcondition['AND'] = [lowerBoundCondition, upperBoundCondition];
    }
    else{
      let lowerBoundCondition = constructBaseCondition(col_internal_name, 'LT', val);
      let upperBoundCondition = constructBaseCondition(col_internal_name, 'GTE', upperBound);
      subcondition['OR'] = [lowerBoundCondition, upperBoundCondition];
    }
    subConditions.push(subcondition);
  });

  if(subConditions.length == 1){
    return subConditions[0]
  }
  else if(subConditions.length == 0){
    return null;
  }
  else{
    if(isNegative){
      return {AND: subConditions}
    }
    else{
      return {OR: subConditions}
    }
  }
}


export function getNumRangeUpperBound(val, pow){
  let pow10 =  Math.pow(10, pow);
  val = parseFloat(val) + pow10;
  if(pow < 0){
    val = Math.round(val/pow10) * pow10;
    val = parseFloat(val.toFixed(-1 * pow));
  }
  else{
    Math.round(val);
  }
  return val;
}

export function getDateRangeUpperBound(val, level){
  let momentMap = {
    YEAR: 'years',
    MONTH: 'months',
    DAY: 'days',
    HOUR: 'hours',
    MINUTE: 'minutes',
    SECOND: "seconds"
  };
  // Replacing hyphen with slash because hyphenated formats do not work on safari
  // Ref: https://stackoverflow.com/questions/4310953/invalid-date-in-safari
  val = val.replace(/-/g, "/");
  return moment(new Date(val)).add(1, momentMap[level]).format('YYYY-MM-DD HH:mm:ss');
}

export function getRangeUpperBound(valtype, val, level){
  let mapped = {
    NUMERIC: getNumRangeUpperBound,
    DATE: getDateRangeUpperBound
  };
  return mapped[valtype](val, level);
}


export function genExploreFilterDescription(ctype, vals, level){
  if(ctype == 'DATE'){
    var items = [];
    let granularity = getGranularityOption(ctype, level);
    var df = granularity.listFormat;
    vals.forEach(function(v){
      // @ts-ignore
      items.push(dateformat(moment(v), df));
    });
    return items.join(' to ');
  }
  else if(ctype == 'NUMERIC'){
    let startValue = vals[0];
    let endValue = getRangeUpperBound(ctype, vals[vals.length - 1], level);
    return numberWithCommas(startValue) + ' to ' + numberWithCommas(endValue);
  }
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatDataForExplore(ctype, value, level, queryIndex?, minInBucket?, maxInBucket?){
  if(ctype == 'NUMERIC'){
    if(minInBucket == maxInBucket && parseFloat(minInBucket) == parseFloat(value)){
      return numberWithCommas(value);
    }
    let startValue = value;
    let endValue = getRangeUpperBound(ctype, value, level);
    return numberWithCommas(startValue) + ' to ' + numberWithCommas(endValue)
  }
  else if(ctype == 'DATE'){
    let granularity = _.filter(ExploreConsts.dateGranularityOptions, {internal: level})[0];
    var df;
    if(queryIndex == 0){
      df = granularity.listFormat;
    }
    else{
      df = granularity.graphFormat;
    }
    let date = moment(value);
      // @ts-ignore
    return dateformat(date, df);
  }
}



export function getGranularityOption(ctype, level){

  if(ctype == 'NUMERIC'){
    return _.filter(ExploreConsts.numGranularityOptions, {internal: level})[0];
  }
  else if(ctype == 'DATE'){
    return _.filter(ExploreConsts.dateGranularityOptions, {internal: level})[0];
  }
}


export function processExploreRangeData(columnType, cardType, data, level, queryIndex, threshold, roundAgg = true) {
  let hasVariety = true;
  let reduceResolution = false;

  var date_query_levels = ExploreConsts.dateGranularityOrder;

  if(columnType == 'NUMERIC'){
    //do not show if it is not the first query and has a single value
    if(level != 'AUTO' && data.length == 1){
      if(data[0].min == data[0].max){
        hasVariety = false;
      }
    }
  }

  var rows = [];
  let nullData;
  data.forEach(function(d){
    if(d.value !== null){
      rows.push(d);
    }
    else{
      nullData = d;
    }
    if(roundAgg){
      d.agg = Math.round(parseFloat(d.agg) * 100)/100;
    }
  });
  data = rows;

  if(level == 'AUTO'){
    /*
      Since the level/resolution is not known when level is AUTO,
      this part of the code looks at the data it has received form the server
      and determines the level
    */
    if(data.length > 1){
      /*
        When there is more than one data point returned from the server,
        a bunch of samples are taken from the data. max_sample_run_count
        is the maximum number of samples considered for making the
        determination of level

        Note: Older iteration of this code was only considering the first 2
        values in the data. This was resulting in the issue
        https://mammoth.atlassian.net/browse/MVP-2514
        for some data
      */
      let samplesRunCount = data.length > max_sample_run_count?  max_sample_run_count:data.length - 1;
      let levels = {}; // to store the different levels guessed against their count in sample runs
      for(var r=0; r<samplesRunCount; r++){
        var row1_val = data[r].value;
        var row2_val = data[r+1].value;
        let gl = _guessLevel(row1_val, row2_val, 0);
        levels[gl] = !isNaN(levels[gl]) ? levels[gl]+1:1;
      }
      // now the levels will look like this {1: 2, 0: 8, -1: 0} or {MONTH: 10}
      // key is the hash & value is the count.
      // We determine through the following reduce function the key with
      // the highest value
      level = Object.keys(levels).reduce(function(a, b){ return levels[a] > levels[b] ? a : b });
      if (typeof level == "string" && columnType == "NUMERIC"){
        level = parseInt(level)
      }
    }
    else if (data.length == 1){
      let minVal = data[0].min;
      let maxVal = data[0].max;
      level = _guessLevel(minVal, maxVal, 1);
    }
    else{
      if(columnType == "DATE"){
        level = 'DAY';
      }
      else if(columnType == "NUMERIC"){
        level = 0
      }
    }
  }


  if(data.length > threshold) {
    if(!(columnType == 'DATE' && level == 'YEAR')){
        reduceResolution = true;
    }

  }
  if(!reduceResolution){
    _.forEach(data, function(d){
      d.formatted = formatDataForExplore(columnType, cardType === 'text' ? d.unformatted_value : d.value,level, queryIndex, d.min, d.max);
      if(d.unformatted !== undefined){
        d.unformatted = parseFloat(d.unformatted);
      }
    });

    if(nullData){
      nullData.formatted = '(blank)';
      data = [nullData].concat(data);
    }
  }

  return {
    hasVariety: hasVariety,
    data: data,
    reduceResolution: reduceResolution,
    level: level
  };

  function _guessLevel(val1, val2, offset){
    if(columnType == "DATE"){
      try{
        var parts_1 = val1.split(' ')[0].split('-').concat(val1.split(' ')[1].split(':'));
        var parts_2 = val2.split(' ')[0].split('-').concat(val1.split(' ')[1].split(':'));
      }
      catch(e){
        var part_1 = val1.split('Z')
        var part_2 = val2.split('Z')
        var parts_1 = part_1[0].split('T')[0].split('-').concat(part_1[0].split('T')[1].split(':'));
        var parts_2 = part_2[0].split('T')[0].split('-').concat(part_2[0].split('T')[1].split(':'));
      }

      for(var i=0; i < parts_1.length; i++){
        if(parts_1[i] != parts_2[i]){
          if(i == 0){
            return date_query_levels[i];
          }
          else{
            return date_query_levels[i - offset];
          }
        }
      }
      return "DAY";
    }
    else if(columnType == "NUMERIC"){
      if(offset == 1){
        if(val1 == val2){
          if(Number.isInteger(parseFloat(val1))){
            return 0;
          }
          else{
            return -1;
          }
        }
        return 0;
      }
      else if(offset == 0){
        let possibleRes;
        possibleRes = Math.floor(Math.log10(Math.abs(val2 - val1)));
        while(possibleRes >= 0){
          if(Number.isInteger(val1 / (10 ** possibleRes))){
            break;
          }
          else{
            possibleRes -= 1;
          }
        }
        return possibleRes;
      }
    }
  }
}

export function handleReduceResolutionRequest(ctype, column_display_name, level, _setGranularityCb, _raiseToastWarningCb, reinitCb){
  let msg;
  var date_query_levels = ExploreConsts.dateGranularityOrder;
  let oldLevel = level;
  if (ctype == 'NUMERIC') {
    msg = 'Too many values to plot as ' + Math.pow(10, level) + ' .Plotting "' + column_display_name + '" at lower granularity';
    level += 1;
  }
  else if (ctype == 'DATE') {
    let levelIndex = date_query_levels.indexOf(level);
    if (levelIndex != 0) {
      msg = 'Too many values to plot as ' + level + ' .Plotting "' + column_display_name + '" at lower granularity';
      level = date_query_levels[levelIndex - 1];
    }
  }
  if(msg){
    _setGranularityCb(level, true);
    _raiseToastWarningCb(msg);
    reinitCb();
    return;
  }
}

export function _filterVerticalWhitespaces(text) {
  let verticalWhitespaceRegex = /[\r\n\f]+/g
  return text.replace(verticalWhitespaceRegex, ' ')
}


export function reconsileAggParams(metadata, selectedAggregation, changeAggOptionsCb){
  if(selectedAggregation.aggregation != 'COUNT'){
    let intNames = metadata.map(x => x.internal_name);
    if(intNames.indexOf(selectedAggregation.target) == -1){
      selectedAggregation.aggregation = 'COUNT';
      selectedAggregation.target = null;
      changeAggOptionsCb();
      return false;
    }
  }
  return true;
}


export function getHtmlFormattedBlankValueOrSpaces(value){
  /*
  For blank values return [blank] in italic
  For spaces:
    - if one space, return [whitespace] in italic
    - if multiple spaces, return [whitespace x n], where n is the number of spaces
  Otherwise return value as it is
   */
  if(value === '' || value === undefined || value === null){
    return "<i>[blank]</i>";
  }
  else{
    if(typeof value === 'string'){
      if(value.trim()){
        return value;
      }
      else{
        if(value.length == 1) {
          return "<i>[whitespace]</i>";
        }
        else{
          return "<i>[whitespace x " + value.length + "]" + "</i>";
        }
      }
    }else {
      return value;
    }

  }
}
