<template>
  <div>
    <restrictions-wrapper validate-storage-limit>
      <mm-button :label="$t('data_library.data_addition.add_data')" icon="plus" @click="isSelectionModalOpen = true" />
    </restrictions-wrapper>

    <!-- SELECTION MODAL -->
    <mm-modal v-model="isSelectionModalOpen" width="450" closable :title="$t('data_library.data_addition.modal_title')">
      <restrictions-wrapper>
        <ul name="modal-list" class="p-l-0 m-t-6 m-b-5" style="list-style: none">
          <li v-for="(item, idx) in items" :key="item.title">
            <div class="d-flex">
              <div class="d-flex-col flex-grow-1 m-r-4">
                <span class="mm-text--h300" v-text="item.title" />
                <span class="m-t-2" v-text="item.description" />
              </div>
              <mm-button
                class="m-y-auto flex-shrink-0"
                :label="$t('global.dictionary.select')"
                objective="secondary"
                @click="select(item)"
              />
            </div>
            <mm-divider v-if="idx < items.length - 1" class="m-y-5" />
          </li>
        </ul>
      </restrictions-wrapper>
    </mm-modal>

    <!-- ADD DATA MODAL -->
    <mm-modal
      v-model="isAddDataModalOpen"
      :key="`data_addition_modal_${modalKey}_${defaultConnectorKey}`"
      :title="$t('data_library.data_addition.add_data')"
      v-bind="selectedItem.props"
      :content-class="`mm-data-library-data-addition-modal ${selectedItem.contentClass}`"
    >
      <component
        :is="selectedItem.component"
        :folder-resource-id="folderResourceId"
        :default-connector-key="defaultConnectorKey"
        :is-back-button-visible="!(defaultType || defaultConnectorKey)"
        @submit="isAddDataModalOpen = false"
        @cancel="isAddDataModalOpen = false"
        @back="returnToSelectionModal"
      />
    </mm-modal>
  </div>
</template>

<script>
import DataLibraryDataAdditionThirdParty from './data-library-data-addition-third-party/data-library-data-addition-third-party'
import DataLibraryDataAdditionFetchFromUrl from './data-library-data-addition-fetch-from-url/data-library-data-addition-fetch-from-url'
import DataLibraryDataAdditionUploadFiles from './data-library-data-addition-upload-files/data-library-data-addition-upload-files'
import DataLibraryDataAdditionWebhook from './data-library-data-addition-webhook/data-library-data-addition-webhook'
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

// Constants
import { USER_EVENTS } from '@/constants'

export default {
  name: 'data-library-data-addition',
  components: { RestrictionsWrapper },
  props: {
    folderResourceId: [String, Number],
    defaultConnectorKey: String,
    defaultType: String // in cases like the empty landing where there are 4 separate buttons, defaultType allows to open the specific data addition type modal
  },
  data: () => ({
    isAddDataModalOpen: false,
    isSelectionModalOpen: false,
    selectedItem: {
      /*
        key: string,
        component: component,
        title: string,
        description: string,
        contentClass: string,
        props: {}
      */
    },
    modalKey: 0
  }),
  created() {
    this.items = [
      {
        key: 'FILE_UPLOAD',
        component: DataLibraryDataAdditionUploadFiles,
        title: this.$t('data_library.data_addition.upload_files.my_computer'),
        description: this.$t('data_library.data_addition.upload_files.description'),
        props: { width: '460' }
      },
      {
        key: 'WEBHOOK',
        component: DataLibraryDataAdditionWebhook,
        title: this.$t('data_library.data_addition.webhook.title'),
        description: this.$t('data_library.data_addition.webhook.description'),
        featureToValidate: 'isWebhookEnabled'
      },
      {
        key: 'THIRD_PARTY',
        component: DataLibraryDataAdditionThirdParty,
        title: this.$t('data_library.data_addition.third_party.title'),
        description: this.$t('data_library.data_addition.third_party.description'),
        contentClass: 'height-100',
        props: { maxWidth: '1100', width: '95%', closable: true }
      },
      {
        key: 'FETCH_FROM_URL',
        component: DataLibraryDataAdditionFetchFromUrl,
        title: this.$t('data_library.data_addition.fetch_from_url.title'),
        description: this.$t('data_library.data_addition.fetch_from_url.description')
      }
    ]
  },
  watch: {
    defaultConnectorKey(val) {
      // If a default connector was defined, open the third-party modal with given connector selected
      if (val) {
        this.selectedItem = Object.values(this.items).find((i) => i.key == 'THIRD_PARTY')
        this.isAddDataModalOpen = true
      }
    },
    defaultType(val) {
      // If a default data addition type was defined, open the modal in that type
      if (val) {
        this.modalKey++
        this.selectedItem = Object.values(this.items).find((i) => i.key == val)
        this.isAddDataModalOpen = true
      }
    },
    isAddDataModalOpen(val) {
      if (!val) this.$emit('addDataModalClose')
    }
  },
  methods: {
    select(item) {
      this.modalKey++
      this.selectedItem = item
      this.isSelectionModalOpen = false
      this.isAddDataModalOpen = true
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION[item.key].OPEN)
    },
    returnToSelectionModal() {
      this.isAddDataModalOpen = false
      this.isSelectionModalOpen = true
    }
  }
}
</script>

<style lang="scss">
.mm-data-library-data-addition-modal {
  max-height: 700px !important;
  flex-direction: column;
  display: flex;
}
</style>
