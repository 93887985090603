'use strict';
import * as angular from 'angular';
import {config} from "../common/app.config";

/**
 * @ngInject
 * Dataview service
 */

DataviewService.$inject = ['Dataview', '$q', 'eventCallbackManagerFactory', '$resource', 'FutureService', 'c'];
export function DataviewService(Dataview, $q, eventCallbackManagerFactory, $resource, FutureService, c) {
  var _ws_list = {};
  var _list_inited_promise = $q.defer();
  var ws_deletion = new eventCallbackManagerFactory('ws_deletion');
  var ws_updates = new eventCallbackManagerFactory('ws_updates');
  var ds_reprocessing = new eventCallbackManagerFactory('ds_reprocessing');
  var renameColEvent = new eventCallbackManagerFactory('renameColEvent');
  var updateTasksActionsDependencies = new eventCallbackManagerFactory('updateTasksActionsDependencies');
  var onApplyTemplate = new eventCallbackManagerFactory('onApplyTemplate');
  var updateMetadataHiddenColumns = new eventCallbackManagerFactory('updateMetadataHiddenColumns');

  var dataviews_service = {
    // public methods
    // - list methods
    is_list_inited: false,
    is_dataview_updated: false,
    is_list_inited_promise: _list_inited_promise.promise,
    get_by_id: get_by_id,
    getDependencyDictionery: getDependencyDictionery,
    list: _ws_list,  // tentative, to be replaced with a method
    add_to_list: add_to_list,
    // custom events
    on_delete: ws_deletion.add_callback,
    remove_on_delete: ws_deletion.remove_callback,
    on_list_updates: ws_updates.add_callback,
    remove_on_list_update: ws_updates.remove_callback,
    on_ds_reprocess: ds_reprocessing.add_callback,
    fire_on_ds_reprocess: ds_reprocessing.fire_event,
    on_rename_col: renameColEvent.add_callback,
    fire_on_rename_col: renameColEvent.fire_event,
    on_update_tasks_actions_dependencies: updateTasksActionsDependencies.add_callback,
    fire_on_update_tasks_actions_dependencies: updateTasksActionsDependencies.fire_event,
    on_apply_template: onApplyTemplate.add_callback,
    fire_on_apply_template:  onApplyTemplate.fire_event,
    getStepsCountMsg: getStepsCountMsg,
    on_update_metadata_hiddenColumns: updateMetadataHiddenColumns.add_callback,
    fire_on_update_metadata_hiddenColumns: updateMetadataHiddenColumns.fire_event,

  // - ws instance methods
    delete: del,
    rename: rename,
    // inter-service methods
    _set_updates: _set_updates
  };

  ////////


  function del(ws) {
    var deferred = $q.defer();
    var ws_id = ws.id;
    ws.delete().then(del_success);

    function del_success() {
      ws_deletion.fire_event(ws_id);
      deferred.resolve();
    }
    return deferred.promise;
  }


  function rename(ws, new_name, validation_only) {
    var deferred = $q.defer();
    ws.rename(new_name, validation_only).then(rename_success, rename_error);

    function rename_success() {
      deferred.resolve();
    }

    function rename_error() {
      deferred.reject.apply(this,arguments);
    }
    return deferred.promise;
  }

  function _set_updates(dataviews_data, ds, resource_update_callback, resource_delete_callback) {
    // This code needs a sherlock to understand.
    // Author: Sherlock is not a developer
    var return_ws_list = {};
    dataviews_service.is_dataview_updated = true;
    angular.forEach(dataviews_data, function (ws_data) {
      if (ws_data.objectStatus == c.coreListObjectStatus.deleted) {
        if (_ws_list[ws_data.id]) {
          _ws_list[ws_data.id].objectStatus = ws_data.objectStatus;
          return_ws_list[ws_data.id] = _ws_list[ws_data.id];
          delete _ws_list[ws_data.id];
          resource_delete_callback(_ws_list[ws_data.id]);
        }
      }
      else {
        if (_ws_list.hasOwnProperty(ws_data.id)) {
          _ws_list[ws_data.id].update(ws_data);
        } else {
          _ws_list[ws_data.id] = new Dataview(ws_data);
          _ws_list[ws_data.id].datasource = ds;
        }
        resource_update_callback(_ws_list[ws_data.id]);
        return_ws_list[ws_data.id] = _ws_list[ws_data.id];
      }
    });

    if (!dataviews_service.is_list_inited) {
      dataviews_service.is_list_inited = true;
      _list_inited_promise.resolve();
    }
    ws_updates.fire_event();
    return return_ws_list;
  }


  /**
   * Get dataview by ID. Assumes the ws list is already populated
   * @param id ID of the dataview
   * @returns {Dataview} Dataview instance or undefined if not present
   */
  function get_by_id(id) {
    return _ws_list[id];
  }

  function get_data_tracker(data) {
    var deferred = $q.defer();
    FutureService.track(data.future_id, data_tracker);

    function data_tracker(future) {
      let response = future.response;
      if (future.status == "processing") {
        return;
      }
      if (future.status == "success") {
        deferred.resolve(response);
      } else {
        deferred.reject(response);
      }
    }
    return deferred.promise;
  }

  function getDependencyDictionery(ws_id){
    /*
    get dependency dictionary of tasks for the dataview having dataviewd id as ws_id
     */
    var deferred = $q.defer();
    var reorder_res = $resource(config.api.alltasks, {ws_id: '@ws_id', get_dep_dict: '@get_dep_dict'});

    reorder_res.get({ws_id: ws_id, get_dep_dict: true}).$promise.then(successCallback, errorCallback);

    function successCallback(data) {
      get_data_tracker(data).then(function (response) {
        let dep_dict = response.dep_dict;

        /*
        dep_dict is of the form {task_sequence: [dependencies], task_sequence: [dependencies], ...}
        From dep_dict, we will extract the array of task sequences also.
        The example of dep_dictionary returned from the api is:
        {1: [2], 2: [], ...}
        For example it means task having sequence 1 is being used in task having sequences 2

        The below code will make modify this dictionary and make it like this:
        {1: [2], 2: [1], ...} -- it basically adds two way dependency

        */
        for(let key of Object.keys(dep_dict)){
          for(let item of dep_dict[key]){
            if(dep_dict[item].indexOf(key) == -1){
              dep_dict[item].push(key);
            }
          }
        }
        /*
        variable order is the array which holds the task sequences
        */
        let order = Object.keys(dep_dict).map(function (item) {
          return item;
        });
        deferred.resolve([order, dep_dict, response.count_of_pipeline_changes]);
      });
    }

    function errorCallback(resp) {
      deferred.reject(resp);
    }

    return deferred.promise
  }

  function getStepsCountMsg(ws_id){
    let wksp = _ws_list[ws_id];
    let taskCount = wksp.tasks_total_count;
    let stepsCount = taskCount;

    let msg = stepsCount + (stepsCount == 1 ? ' task' : ' tasks');
    if(stepsCount == 0){
      msg = "";
    }
    return msg;
  }

  function add_to_list(id, obj){
    _ws_list[id] = obj;
  }

  return dataviews_service;
}
