/*jslint node: true */
/**
 * @ngInject
 */
mathModalService.$inject = ['$uibModal', '$q', 'config', '$rootScope'];
export function mathModalService($uibModal, $q, config, $rootScope){
  return {
    open: openPanel
  }

  function openPanel(options){
    var controllerScope = $rootScope.$new();
    controllerScope.options = options;

    var modalReference = $uibModal.open({
      animation: true,
      templateUrl: config.templates.mathModal,
      controller: 'MathModalController',
      controllerAs: 'mathctrl',
      bindToController: true,
      scope: controllerScope,
      windowClass: 'modal-size-xlarge'
    });
    return modalReference.result;
  }
}

/**
 * @ngInject
 */
MathModalController.$inject = ['expressionManagerFactory', '$uibModalInstance'];
export function MathModalController(expressionManagerFactory, $uibModalInstance){
  this.$onInit = function() {
    var mathctrl = this;
    // TODO: SENTRYERROR:FRONTEND-91:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/413589078
    mathctrl.manager = expressionManagerFactory.get_manager(mathctrl.options);
    if (mathctrl.options.param) {
      mathctrl.manager.setParam(mathctrl.options.param);
    }
    mathctrl.close = close;

    function close() {
      $uibModalInstance.close(mathctrl.manager.getParam());
    }

  }
}
