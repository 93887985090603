import * as angular from 'angular';
import * as _ from 'lodash-es';

copyManagerFactory.$inject = ['destinationColumnManagerFactory', 'filterManagerFactory', 'utils', 'filterUI', 'FilterEditHelperFactory'];
export function copyManagerFactory(destinationColumnManagerFactory, filterManagerFactory, utils, filterUI, FilterEditHelperFactory) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new CopyManager(options);
  }


  function CopyManager(options) {
    var self = this;
    var metadata = options.metadata, taskUtils = options.taskUtils, dataview = options.context.dataview;
    self.getParam = getParam;
    self.validate = validate;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap;
    self.select_column = select_column;
    self.handlePasteParams = handlePasteParams;
    self.setParam = setParam;

    self.openConditionMenu = openConditionMenu;
    self.openConditionModal = openConditionModal;
    // self.conditionPopoverIsOpen = false;
    self.metadata = utils.sanitizeMetadataWithType(metadata);
    self.source_column = null;
    self.context = options.context
    self.filterEditHelper = new FilterEditHelperFactory();
    self.filterEditHelper.onMaximizeFilterPopoverToModal('maximizeFilterPopoverToModal',  self.openConditionModal)
    self.filterManager = filterManagerFactory.get_manager({
      metadata: metadata,
      dataviewId: options.context.dataview.id,
      context: options.context,
      originalSequenceNumber: options.originalSequenceNumber
    });
    self.destinationManager = destinationColumnManagerFactory.get_manager(
      {metadata: metadata, allowedTypes: undefined, taskUtils: options.taskUtils, isDestinationFormatterVisible: true}
    );

    self.destinationManager.disable();

    function select_column(){
      if (self.source_column){
        taskUtils.highlight.sources(self.source_column);
        var allowed_destination_types;
        var default_type = null;

        if(self.source_column.type == "TEXT"){
          allowed_destination_types = ["TEXT"];
        }
        else{
          allowed_destination_types = [self.source_column.type, "TEXT"];
          default_type = self.source_column.type;
        }
        if(self.destinationManager.destination_column && allowed_destination_types.indexOf(self.destinationManager.destination_column.type) == -1){
          allowed_destination_types.push(self.destinationManager.destination_column.type)
          self.destinationManager.destination_column = undefined
        }
        let excludedColumn = new Set([self.source_column.internal_name]);
        self.destinationManager.setAllowedTypesAndColumns(allowed_destination_types, default_type, excludedColumn);
        var source_column_format = dataview.display_properties.FORMAT_INFO[self.source_column.internal_name];
        if (!source_column_format) {
          let column = _.find(self.metadata, function (c) {
            return c.internal_name == self.source_column.internal_name;
          });
          source_column_format = column['format'];
        }
        self.destinationManager.setDestinationFormat(source_column_format);
        self.destinationManager.enable();
      } else {
        self.destinationManager.disable();
      }

    }

    function openConditionMenu(){
      self.filterEditHelper.open(self.filterManager);
      
    }

    function openConditionModal(param=null){
      filterUI.open(self.filterManager, param);
    }

    function validate() {
      var is_valid = true;
      var dst_validation = self.destinationManager.validate();

      is_valid = is_valid &&  dst_validation
      if (self.source_column?.type!==self.destinationManager.destination_type){
        if (self.destinationManager.destination_type != 'TEXT'){
          is_valid = false
        }
      }

      if (self.source_column) {
        if (
          self.source_column.type !== self.destinationManager.destination_type &&
          self.destinationManager.destination_type != 'TEXT'
        ) {
          // Source type should be same as destination type
          // If they are not same, then destination should be TEXT type to be considered valid
          // In all other cases, report bulk copy as invalid
          is_valid =  false
        } else if (self.source_column.hasOwnProperty('error')) {
          // If any selected source columns are reporting an error
          // For example, column being deleted after reordering delete column rule
          // Report bulk copy as invalid
          is_valid =  false
        }
      }

      if (self.filterManager.condition){
        return is_valid  && self.filterManager.condition.validate();
      }
      return is_valid;
    }

    function handlePasteParams(taskInfo){
      /** Update source, destination and condition params with suitable replacement columns, based on display name*/
      var params = taskInfo.params
      if (params.COPY.hasOwnProperty('DESTINATION')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.COPY, 'DESTINATION', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        //Update destination manager metadata
        self.destinationManager.metadata = self.metadata
        self.destinationManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(self.metadata)

      }

      //Source column
      utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.COPY, 'SOURCE', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);

      //Handle columns used in condition
      var columnUsedInCondition = utils.get_input_columns(_.cloneDeep(params.CONDITION))
      if (columnUsedInCondition) {
        utils.metadata.findReplaceColumnsInCondition(self.metadata, self.displayNameAndTypeToColumnMap, params, columnUsedInCondition, taskInfo)
      }
      self.filterManager.metadata = self.metadata
      return params
    }

    function getParam() {
      var copy_param = {SOURCE: self.source_column.internal_name};

      var destination_param = self.destinationManager.getParam();
      if (destination_param.hasOwnProperty('AS') && self.context.inEditMode==true  &&  self.context.task){
        utils.sanatizeParamForDuplicateCols(destination_param['AS'], 'INTERNAL_NAME', self.context.task)
      }
      angular.extend(copy_param, destination_param);

      var param: any = {
        COPY: copy_param
      };

      if(self.filterManager.condition){
        param.CONDITION = self.filterManager.getParam();
      }
      if (self.context.hasOwnProperty('sequence')){
        param['SEQUENCE_NUMBER'] = self.context['sequence']
      }
      return param;
    }


    function setParam(param) {
      self.param = param.COPY;
      self.source_column = utils.metadata.get_column_by_internal_name(metadata, param.COPY.SOURCE);
      taskUtils.highlight.sources(self.source_column);
      select_column();
      self.destinationManager.setParam(param.COPY);

      if (param.hasOwnProperty('CONDITION')) {
        self.filterManager.setParam(param.CONDITION, param?.EXECUTION_TIMESTAMP);
      }
    }
  }
}


export function valCopyCol() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valCopyCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valCopyCol = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue && modelValue.hasOwnProperty('error')){
          is_valid =  false
        }
        return is_valid
      }
    }
  }
}


