/**
 * Manages currently logged in user and currently selected workspace, also has information of other users relevant to the
 * current user in the context of permissions, sharing.
 * */
"use strict";
import * as _ from 'lodash-es';
import * as Raven from 'raven-js';
import * as dateformat from "dateformat";

/**
 * @ngInject
 * User Workspace service
 */

ClientAppsService.$inject = ['$http', '$q', '$resource', 'config', '$timeout', '$rootScope', 'toastNotification', 'Notification'];

export function ClientAppsService($http, $q, $resource, config, $timeout, $rootScope, toastNotification, Notification) {

  var clientApps = $resource(config.api.clientApps);
  var clientApp = $resource(config.api.clientApp);

  


  var service = {
    apps_data: [],
    appDetails: undefined,
    appValues: {},

    //methods
    create_app: create_app,
    get_apps: get_apps,
    get_app_details: get_app_details,
    delete_app: delete_app,
    edit_details: edit_details
  };

  add_event_listeners();
  return service;

  function get_apps() { 
    clientApps.get().$promise.then(function successCallback(response){
      service.apps_data = response
      for(var i=0; i<response.result.length; i++){
        if (service.apps_data['result'][i].last_usage){
          var date = new Date(service.apps_data['result'][i].last_usage+'+00:00');
          service.apps_data['result'][i].last_usage = date.toLocaleString()
        }
        else{
          service.apps_data['result'][i].last_usage = "Never"
        }
      }
    });
  }


  function create_app(request_params, keyPair) {

    var postData = {
      "app_name": request_params.app_name,
      "description": request_params.app_description,
      "api_key": keyPair.key,
      "api_secret": keyPair.secret,
      "encrypted_key": keyPair.encryptedKey,
      "encrypted_secret": keyPair.encryptedSecret
    }

    clientApps.save({}, postData).$promise.then(function successCallback(response) {
      get_apps()
      if (response.STATUS == 'FAILURE') {
        Notification.error(response.error);
      }
    },
      function errorCallback(response) {
        Notification.error(response.data.ERROR_MESSAGE);
      });
  }

  function get_app_details(app_key) {
    clientApp.get({ app_key: app_key }).$promise.then(function successCallback(response) {
      service.appValues = response.result
    },
      function errorCallback(response) {
        Notification.error(response.data.ERROR_MESSAGE);
      });
  }

  function delete_app(app_key) {
    clientApp.delete({ app_key : app_key }).$promise.then(function successCallback(response) {
      get_apps()
      $timeout(function () {
        toastNotification.success('Deleted the selected app successfully');

      }, 500);
    },
      function errorCallback(response) {
        Notification.error(response.data.ERROR_MESSAGE);
      });
  }


  function add_event_listeners() {
    $rootScope.$on(config.events.auth.reselectWorkspace, function () {
      reset();
      $rootScope.$broadcast(config.events.auth.checkTokenEvent);
    });
  }

  function edit_details(details, generatedSecret, encryptedSecret) {
    var app_key = details.app_key
    var patch = [];
    patch.push({"op": "replace", "path": "app_name", "value": details.app_name});
    patch.push({"op": "replace", "path": "description", "value": details.description});
    if(generatedSecret){
      patch.push({"op": "replace", "path": "api_secret", "value": [generatedSecret, encryptedSecret]});
    }

    clientApp.patch({app_key}, {"patch": patch}).$promise.then(function successCallback(response) {
      get_apps()
      get_app_details(details.app_key)
      if (response.STATUS == 'FAILURE') {
        Notification.error(response.error);
      }
    },
      function errorCallback(response) {
        Notification.error(response.data.ERROR_MESSAGE);
      });
  }

  function reset() {
    service.apps_data = null;
  }

}