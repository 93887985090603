/**
 * @ngInject
 */
import {analyticsService} from '../common/analytics.service';

HistoryDataViewPanelFactory.$inject = ['eventCallbackManagerFactory', 'DataviewService', 'utils', 'c', 'historicDataviewData',
                                     '$timeout', 'config', 'TaskServiceFactory', 'taskDescriber', 'analyticsService'];
export function HistoryDataViewPanelFactory(eventCallbackManagerFactory, DataviewService, utils, c, historicDataviewData,
                                     $timeout, config, TaskServiceFactory, taskDescriber, analyticsService) {
  var registry = {};
  return {
    getByDataviewId: getByDataviewId
  };

  function getByDataviewId(dataviewId) {
    if (!registry.hasOwnProperty(dataviewId)) {
      registry[dataviewId] = new HistoryDataViewPanel(dataviewId);
    }
    return registry[dataviewId];
  }

  function HistoryDataViewPanel(dataviewId) {
    var hd = this;
    hd.dataview = DataviewService.get_by_id(dataviewId);
    hd.toggle = toggle;

    function toggle(sequence_number) {
      let eventName;
      if (hd.dataview.stepPreview.sequence == sequence_number) {
        hd.dataview.stepPreview.exitPreview();
        eventName = c.userEvents.dataviewEvents.taskPanelEvents.exitingStepPreviewMode;
      } else {
        hd.dataview.stepPreview.setSequence(sequence_number);
        eventName = c.userEvents.dataviewEvents.taskPanelEvents.enteringStepPreviewMode;
      }
      analyticsService.userEventTrack(eventName, {
        menuType: "taskPanel",
        eventOrigin: "dataview"
      });
    }

  }
}
