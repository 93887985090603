<template>
  <div class="mm-data-addition-third-party-metrics-spreadsheet height-100">
    <div v-if="isLoading" class="loading height-100">
      <mm-loader class="m-x-auto" size="large" />
    </div>

    <div v-else class="content">
      <div class="content-wrapper m-x-2">
        <mm-text-input
          v-model="fileUrl"
          :title="$t('data_library.data_addition.third_party.enter_file_url')"
          :placeholder="$t('global.dictionary.example_url')"
          hide-details
        />

        <div class="d-flex m-t-2">
          <mm-icon name="information" />
          <span class="m-l-2 mm-text--caption-regular">
            {{ $t('data_library.data_addition.third_party.spreadsheet_info') }}
          </span>
        </div>

        <mm-text-input
          v-model="sheetName"
          class="m-t-4"
          :title="$t('data_library.data_addition.third_party.sheet_name')"
        />

        <mm-checkbox
          v-model="isTreatColumnAsTextActive"
          :label="$t('data_library.data_addition.third_party.treat_cols_as_text')"
        />
      </div>

      <div class="d-flex p-a-2">
        <mm-button :label="$t('global.dictionary.back')" objective="tertiary" @click="$emit('back')" />
        <mm-button
          class="m-l-auto"
          :label="$t('global.dictionary.next')"
          :disabled="!computedIsSubmitEnabled"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Utils
import { isValidURL } from '@/utils/validations'

// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

export default {
  name: 'data-library-data-addition-third-party-metrics-spreadsheet',
  props: {
    connector: {
      type: Object,
      required: true
      /*{
        key: string,
        name: string,
        icon: string,
        category: string,
        connection: {...},
        metrics: {...}
      }*/
    },
    connection: {
      type: Object,
      required: true
      /*{
        value: string,
      }*/
    }
  },
  data: () => ({
    fileUrl: '',
    sheetName: '',
    isTreatColumnAsTextActive: false,
    isLoading: true
  }),
  computed: {
    computedIsSubmitEnabled() {
      return isValidURL(this.fileUrl) && this.sheetName.trim()
    }
  },
  async created() {
    this.metricsInfo = await dataLibraryApi.getMetricsInfo(
      this.connector.hash,
      this.connection.value,
      this.$store.state.projectId
    )

    if (!this.metricsInfo.isValid) {
      this.$toast.show({ content: this.metricsInfo.message, status: 'error' })
      this.$emit('back')
    }

    this.isLoading = false
  },
  methods: {
    submit() {
      this.$emit('submit', {
        fileUrl: this.fileUrl,
        sheetName: this.sheetName,
        table: { name: this.sheetName.charAt(0).toUpperCase() + this.sheetName.slice(1) }, // Default dataset name
        isTreatColumnAsTextActive: this.isTreatColumnAsTextActive
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mm-data-addition-third-party-metrics-spreadsheet {
  flex-direction: column;
  display: flex;

  .loading {
    align-items: center;
    display: flex;
  }

  .content {
    flex-direction: column;
    display: flex;
    height: 100%;

    .content-wrapper {
      flex-direction: column;
      display: flex;
      flex-grow: 1;
    }
  }
}
</style>
