// VUE area
import * as admin from "app/components/admin/admin.tpl.html";
// VUE area END

import * as topNav from "app/components/topNav/topNav.tpl.html";
import * as landing from "app/components/landing/landing.tpl.html";
import * as expired from "app/components/expired/expired.tpl.html";
import * as oldadmin from "app/components/admin/oldadmin.tpl.html";
import * as dataviewLayout from "app/components/dataview/dataviewLayout.tpl.html";
import * as addData from "app/components/landing/addData.tpl.html";
import * as modalWindow from "app/components/common/templates/modal.windowTemplate.tpl.html";
import * as error404 from "app/components/common/404.tpl.html";
import * as tabLayout from "app/components/tab/tabLayout.tpl.html";
import * as dsPreview from "app/components/landing/preview.tpl.html";
import * as dataview from "app/components/dataview/dataview.tpl.html";
import * as deleteUserConfirmation from "app/components/common/templates/delete.user.tpl.html";
import * as deleteConfirmation from "app/components/common/templates/delete.confirmation.tpl.html";
import * as pipelineConfirmation from "app/components/common/templates/pipeline.confirmation.tpl.html";
import * as joinNonUniqueValues from "app/components/common/templates/joinNonUniqueValue.tpl.html";
import * as pendingUpdatesConfirmation from "app/components/common/templates/pipelineUpdates.confirmation.tpl.html";
import * as showCredential from "app/components/dataview/actions/showPublishCredentials.tpl.html";
import * as regeneratePublishPwd from "app/components/common/templates/regeneratePublishPwd.tpl.html";
import * as publishDB from "app/components/dataview/actions/publishDB.tpl.html";
import * as publishReportPrompt from "app/components/dataview/actions/publishReportPrompt.tpl.html";
import * as publish from "app/components/publish/publish.tpl.html";
import * as dateFilterInput from "app/components/common/filter/date.filter.input.tpl.html";
import * as currentDateTimeInput from "app/components/common/date/currentDateTime.tpl.html";
import * as refreshCloudDataConfirmation from "app/components/common/templates/refreshCloudData.confirmation.tpl.html";
import * as paymentErrorWarning from "app/components/common/templates/payment.error.tpl.html";
import * as resetWsConfirmation from "app/components/common/templates/resetView.confirm.tpl.html";
import * as rawData from "app/components/raw/raw.data.tpl.html";
import * as editDatapullConfig from "app/components/landing/datapull/editDatapullConfig.tpl.html";
import * as editMetrics from "app/components/landing/datapull/editMetrics.tpl.html";
import * as renameConfirmation from "app/components/common/templates/rename.modal.tpl.html";
import * as cloneDsConfirmation from "app/components/common/templates/cloneDs.tpl.html";
import * as templateConfigModal from "app/components/common/templates/templateConfig.tpl.html";
import * as templateConfirmationModal from "app/components/common/templates/template.confirm.tpl.html";
import * as combineDs from "app/components/landing/combineDs.tpl.html";
import * as multiDeleteConfirmation from "app/components/common/templates/multiDelete.confirmation.tpl.html";
import * as stepsPanel from "app/components/dataview/viewUtils/steps.panel.tpl.html";
import * as columnExplorer from "app/components/dataview/column.explorer.tpl.html";
import * as joinSourceGrids from "app/components/dataview/tasks/join.sourceGrids.tpl.html";
import * as addRuleMenu from "app/components/dataview/rule.menu.tpl.html";
import * as wkspGridColumnMenu from "app/components/dataview/col.menu.tpl.html";
import * as wkspGridCellMenu from "app/components/dataview/cell.menu.tpl.html";
import * as wkspColumnSummaryMenu from "app/components/dataview/summary.menu.tpl.html";
import * as wkspModificationAlert from "app/components/common/templates/wksp.modification.alert.tpl.html";
import * as exportDialog from "app/components/common/templates/exportDialog.tpl.html";
import * as mathModal from "app/components/common/math/math.modal.tpl.html";
import * as numericFormatter from "app/components/common/formatters/numeric.formatter.tpl.html";
import * as dateFormatter from "app/components/common/formatters/date.formatter.tpl.html";
import * as fileUpload from "app/components/landing/file.tpl.html";
import * as sampleWarningModal from "app/components/dataview/sample.alert.tpl.html";
import * as manageUsers from "app/components/common/templates/manageUsers.tpl.html";
import * as explorePanel from "app/components/explore/explore.panel.tpl.html";
import * as explorePanelButton from "app/components/explore/explore.button.tpl.html";
import * as dateInputFormat from "app/components/common/date/dateInputFormatter.tpl.html";
import * as thirdPartyAPI from "app/components/landing/datapull/apiv2.tpl.html";
import * as compatibilityWarnings from "app/components/common/templates/compatibility.warnings.tpl.html";
import * as elementModal from "app/components/element/element.modal.tpl.html";
import * as elementDisplay from "app/components/dataview/elements.display.panel.tpl.html";
import * as formlyCheckboxList from "app/components/common/templates/checkbox_list.tpl.html";
import * as exploreMenu from "app/components/dataview/explore.menu.tpl.html";
import * as scheduler from "app/components/landing/datapull/scheduler.tpl.html";
import * as webhook from "app/components/landing/datapull/webhook.tpl.html";
import * as URL from "app/components/landing/datapull/url.tpl.html";
import * as stepRename from "app/components/common/templates/stepRename.tpl.html";
import * as stepAddNote from "app/components/common/templates/stepAddNote.tpl.html";
import * as displayMenuHandle from "app/components/element/common/display.menu.tpl.html";
import * as switcherInBuilderMenu from "app/components/element/common/element.switcher.builder.menu.tpl.html";
import * as metricElementContentTemplate from "app/components/element/metric/metric.displayContent.tpl.html";
import * as filterElementContentTemplate from "app/components/element/filter/filter.displayContent.tpl.html";
import * as chartElementContentTemplate from "app/components/element/chart/chart.displayContent.tpl.html";
import * as tableElementContentTemplate from "app/components/element/table/table.displayContent.tpl.html";
import * as tableElementTemplate from "app/components/element/table/table.display.tpl.html";
import * as filter from "app/components/common/filter/filter.modal.tpl.html";
import * as filterSection from "app/components/common/filter/filter.section.tpl.html";
import * as chartTypeMenu from "app/components/element/chart/chartTypeMenu.tpl.html";
import * as deleteBatchConfirmation from "app/components/landing/deleteBatchConfirmation.tpl.html";
import * as suspendBatchConfirmation from "app/components/landing/suspendBatchConfirmation.tpl.html";
import * as builder from "app/components/common/math/builder.tpl.html";
import * as notification from "app/components/common/templates/notification.tpl.html";
import * as select from "app/components/dataview/tasks/select.tpl.html";
import * as addColumn from "app/components/dataview/tasks/addColumn.tpl.html";
import * as columnSplit from "app/components/dataview/tasks/column.split.tpl.html";
import * as combine from "app/components/dataview/tasks/combine.tpl.html";
import * as convert from "app/components/dataview/tasks/convert.tpl.html";
import * as copy from "app/components/dataview/tasks/copy.tpl.html";
import * as bulkCopy from "app/components/dataview/tasks/bulkCopy.tpl.html";
import * as fillValues from "app/components/dataview/tasks/fillValues.tpl.html";
import * as deleteColumn from "app/components/dataview/tasks/deleteColumn.tpl.html";
import * as destination from "app/components/dataview/tasks/destination.tpl.html";
import * as multiDestination from "app/components/dataview/tasks/multiDestination.tpl.html";
import * as extractDate from "app/components/dataview/tasks/extractDate.tpl.html";
import * as unnestData from "app/components/dataview/tasks/unnest.tpl.html";
import * as formatText from "app/components/dataview/tasks/formatText.tpl.html";
import * as incrementDate from "app/components/dataview/tasks/incrementDate.tpl.html";
import * as dateDiffTemplate from "app/components/dataview/tasks/date.diff.tpl.html";
import * as insert from "app/components/dataview/tasks/insert.tpl.html";
import * as join from "app/components/dataview/tasks/join.tpl.html";
import * as limit from "app/components/dataview/tasks/limit.tpl.html";
import * as lookup from "app/components/dataview/tasks/lookup.tpl.html";
import * as math from "app/components/dataview/tasks/math.tpl.html";
import * as pivot from "app/components/dataview/tasks/pivot.tpl.html";
import * as removeDupliactes from "app/components/dataview/tasks/removeDuplicates.tpl.html";
import * as replace from "app/components/dataview/tasks/replace.tpl.html";
import * as rulebottom from "app/components/dataview/tasks/rulebottom.tpl.html";
import * as runningTotal from "app/components/dataview/tasks/runningTotal.tpl.html";
import * as windowFunction from "app/components/dataview/tasks/windowFunction.tpl.html";
import * as smallOrLarge from "app/components/dataview/tasks/smallOrLarge.tpl.html";
import * as sort from "app/components/dataview/tasks/sort.tpl.html";
import * as singleSort from "app/components/dataview/tasks/singleSort.tpl.html";
import * as filterForm from "app/components/common/filter/filter.tpl.html";
import * as filterAtomic from "app/components/common/filter/filter.atomic.tpl.html";
import * as extractText from "app/components/dataview/tasks/extractText.tpl.html";
import * as collapseRows from "app/components/dataview/tasks/collapseRows.tpl.html";
import * as mysql from "app/components/dataview/actions/mysql.tpl.html";
import * as redshift from "app/components/dataview/actions/redshift.tpl.html";
import * as bigquery from "app/components/dataview/actions/bigquery.tpl.html";
import * as saveasds from "app/components/dataview/actions/saveasdataset.tpl.html";
import * as export_to_project from "app/components/dataview/actions/exporttoproject.tpl.html";
import * as actionSequenceSelect from "app/components/dataview/actions/action.sequence.select.tpl.html";
import * as elasticsearch from "app/components/dataview/actions/elasticsearch.tpl.html";
import * as email from "app/components/dataview/actions/email.tpl.html";
import * as powerbi from "app/components/dataview/actions/powerbi.tpl.html";
import * as postgres from "app/components/dataview/actions/postgresql.tpl.html";
import * as mssql from "app/components/dataview/actions/mssql.tpl.html";
import * as s3upload from "app/components/dataview/actions/s3upload.tpl.html";
import * as sftp from "app/components/dataview/actions/sftp.tpl.html";
import * as actionBottom from "app/components/dataview/actions/actionBottom.tpl.html";
import * as pipelineAction from "app/components/dataview/actions/pipelineAction.tpl.html";
import * as toolTipTemplates from "app/components/help/templates.html";
import * as chartCreate from "app/components/element/chart/chart.create.menu.tpl.html";
import * as chartDisplay from "app/components/element/chart/chart.display.tpl.html";
import * as pivotChartMenu from "app/components/element/chart/pivotChart.create.menu.tpl.html";
import * as funnelChartMenu from "app/components/element/chart/funnelChart.create.menu.tpl.html";
import * as metricCreate from "app/components/element/metric/metric.create.menu.tpl.html";
import * as filterCreate from "app/components/element/filter/filter.create.menu.tpl.html";
import * as plotCreate from "app/components/element/chart/plotChart.create.menu.tpl.html";
import * as tableCreate from "app/components/element/table/table.create.menu.tpl.html";
import * as filterLogical from "app/components/common/filter/filter.logical.tpl.html";
import * as spinnerLoader from "app/components/common/templates/spinner.tpl.html";
import * as elementSwitcher from "app/components/element/common/element.switcher.menu.tpl.html";
import * as filterDisplay from "app/components/element/filter/filter.display.tpl.html";
import * as metricDisplay from "app/components/element/metric/metric.display.tpl.html";
import * as backup from "app/components/element/backup.html";
import * as unstructureData from "app/components/unstructured/unstructure.data.tpl.html";
import * as explorePanelButtonWithMenu from "app/components/explore/explore.button.with.menu.tpl.html";
import * as exploreAddItemsMenu from "app/components/explore/explore.menu.tpl.html";
import * as templateFixer from "app/components/common/templates/template.fix.tpl.html";
import * as json from "app/components/dataview/tasks/json-handle.tpl.html";
import * as createLabelModal from "app/components/common/templates/createLabel.modal.tpl.html";
import * as folderPicker from "app/components/common/templates/folderPicker.tpl.html";
import * as crossTab from "app/components/dataview/viewUtils/crosstab.tpl.html";
import * as targetDsTpl from "app/components/dataview/viewUtils/target.dataset.tpl.html";
import * as inplaceRename from 'app/components/landing/inplaceRenaming.tpl.html';
import * as alertsDialog from 'app/components/dataview/alerts.dialog.tpl.html';
import * as duplicateWs from 'app/components/common/templates/duplicateWs.error.tpl.html';
import * as wkspPipelineErrorAlert from 'app/components/common/templates/wksp.pipelineInError.alert.tpl.html';
import * as multiLevelDropdown from 'app/components/dataview/multi-level-dropdown-menu.tpl.html';
import * as appendBatchConfirmation from 'app/components/landing/appendBatchConfirmation.tpl.html';
import * as confirmQueryUpdate from 'app/components/landing/datapull/components/confirmQueryUpdate.tpl.html';
import * as appCreationModal from "app/components/common/templates/app.creation.modal.tpl.html";
import * as appCreationEditModal from "app/components/common/templates/app.creation.edit.modal.tpl.html";

populateTemplateCache.$inject = ['templateCacheService', 'config'];

export function populateTemplateCache(templateCacheService, config) {
  // VUE area
  templateCacheService.put(config.templates.admin, admin);
  // VUE area END

  templateCacheService.put(config.templates.wkspPipelineErrorAlert, wkspPipelineErrorAlert);
  templateCacheService.put(config.templates.appendBatchConfirmation, appendBatchConfirmation);
  templateCacheService.put(config.templates.duplicateWs, duplicateWs);
  templateCacheService.put(config.templates.json, json);
  templateCacheService.put(config.templates.templateFixer, templateFixer);
  templateCacheService.put(config.templates.exploreAddItemsMenu, exploreAddItemsMenu);
  templateCacheService.put(config.templates.unstructureData, unstructureData);
  templateCacheService.put(config.templates.explorePanelButtonWithMenu, explorePanelButtonWithMenu);
  templateCacheService.put(config.templates.backup, backup);
  templateCacheService.put(config.templates.metricDisplay, metricDisplay);
  templateCacheService.put(config.templates.filterDisplay, filterDisplay);
  templateCacheService.put(config.templates.elementSwitcher, elementSwitcher);
  templateCacheService.put(config.templates.spinnerLoader, spinnerLoader);
  templateCacheService.put(config.templates.filterLogical, filterLogical);
  templateCacheService.put(config.templates.tableCreate, tableCreate);
  templateCacheService.put(config.templates.plotCreate, plotCreate);
  templateCacheService.put(config.templates.filterCreate, filterCreate);
  templateCacheService.put(config.templates.metricCreate, metricCreate);
  templateCacheService.put(config.templates.pivotChartMenu, pivotChartMenu);
  templateCacheService.put(config.templates.funnelChartMenu, funnelChartMenu);
  templateCacheService.put(config.templates.chartDisplay, chartDisplay);
  templateCacheService.put(config.templates.chartCreate, chartCreate);
  templateCacheService.put(config.templates.toolTipTemplates, toolTipTemplates);
  templateCacheService.put(config.templates.actionBottom, actionBottom);
  templateCacheService.put(config.templates.pipelineAction, pipelineAction);
  templateCacheService.put(config.templates.s3upload, s3upload);
  templateCacheService.put(config.templates.mysql, mysql);
  templateCacheService.put(config.templates.redshift, redshift);
  templateCacheService.put(config.templates.bigquery, bigquery);
  templateCacheService.put(config.templates.saveasdataset, saveasds);
  templateCacheService.put(config.templates.export_to_project, export_to_project);
  templateCacheService.put(config.templates.elasticsearch, elasticsearch);
  templateCacheService.put(config.templates.email, email);
  templateCacheService.put(config.templates.powerbi, powerbi);
  templateCacheService.put(config.templates.postgres, postgres);
  templateCacheService.put(config.templates.mssql, mssql);
  templateCacheService.put(config.templates.sftp, sftp);
  templateCacheService.put(config.templates.extractText, extractText);
  templateCacheService.put(config.templates.filterAtomic, filterAtomic);
  templateCacheService.put(config.templates.filterForm, filterForm);
  templateCacheService.put(config.templates.sort, sort);
  templateCacheService.put(config.templates.singleSort, singleSort);
  templateCacheService.put(config.templates.smallOrLarge, smallOrLarge);
  templateCacheService.put(config.templates.runningTotal, runningTotal);
  templateCacheService.put(config.templates.windowFunction, windowFunction);
  templateCacheService.put(config.templates.rulebottom, rulebottom);
  templateCacheService.put(config.templates.replace, replace);
  templateCacheService.put(config.templates.pivot, pivot);
  templateCacheService.put(config.templates.removeDupliactes, removeDupliactes);
  templateCacheService.put(config.templates.limit, limit);
  templateCacheService.put(config.templates.math, math);
  templateCacheService.put(config.templates.lookup, lookup);
  templateCacheService.put(config.templates.join, join);
  templateCacheService.put(config.templates.insert, insert);
  templateCacheService.put(config.templates.incrementDate, incrementDate);
  templateCacheService.put(config.templates.dateDiffRule, dateDiffTemplate);
  templateCacheService.put(config.templates.formatText, formatText);
  templateCacheService.put(config.templates.extractDate, extractDate);
  templateCacheService.put(config.templates.unnest, unnestData);
  templateCacheService.put(config.templates.destination, destination);
  templateCacheService.put(config.templates.multiDestination, multiDestination);
  templateCacheService.put(config.templates.deleteColumn, deleteColumn);
  templateCacheService.put(config.templates.copy, copy);
  templateCacheService.put(config.templates.bulkCopy, bulkCopy);
  templateCacheService.put(config.templates.fillValues, fillValues);
  templateCacheService.put(config.templates.convert, convert);
  templateCacheService.put(config.templates.combine, combine);
  templateCacheService.put(config.templates.columnSplit, columnSplit);
  templateCacheService.put(config.templates.addColumn, addColumn);
  templateCacheService.put(config.templates.collapseRows, collapseRows);
  templateCacheService.put(config.templates.select, select);
  templateCacheService.put(config.templates.topNav, topNav);
  templateCacheService.put(config.templates.landing, landing);
  templateCacheService.put(config.templates.expired, expired);
  templateCacheService.put(config.templates.oldadmin, oldadmin);
  templateCacheService.put(config.templates.dataviewLayout, dataviewLayout);

  templateCacheService.put(config.templates.addData, addData);
  templateCacheService.put(config.templates.modalWindow, modalWindow);
  templateCacheService.put(config.templates['404'], error404);
  templateCacheService.put(config.templates.tabLayout, tabLayout);
  templateCacheService.put(config.templates.dsPreview, dsPreview);
  templateCacheService.put(config.templates.dataview, dataview);
  templateCacheService.put(config.templates.deleteUserConfirmation, deleteUserConfirmation);
  templateCacheService.put(config.templates.deleteConfirmation, deleteConfirmation);
  templateCacheService.put(config.templates.pipelineConfirmation, pipelineConfirmation);
  templateCacheService.put(config.templates.joinNonUniqueValues, joinNonUniqueValues);
  templateCacheService.put(config.templates.pendingUpdatesConfirmation, pendingUpdatesConfirmation);
  templateCacheService.put(config.templates.regeneratePublishPwd, regeneratePublishPwd);
  templateCacheService.put(config.templates.showCredential, showCredential);
  templateCacheService.put(config.templates.publishDB, publishDB);
  templateCacheService.put(config.templates.publish, publish);
  templateCacheService.put(config.templates.publishReportPrompt, publishReportPrompt);
  templateCacheService.put(config.templates.dateFilterInput, dateFilterInput);
  templateCacheService.put(config.templates.currentDateTimeInput, currentDateTimeInput);
  templateCacheService.put(config.templates.refreshCloudDataConfirmation, refreshCloudDataConfirmation);
  templateCacheService.put(config.templates.resetWsConfirmation, resetWsConfirmation);
  templateCacheService.put(config.templates.rawData, rawData);
  templateCacheService.put(config.templates.editDatapullConfig, editDatapullConfig);
  templateCacheService.put(config.templates.editMetrics, editMetrics);
  templateCacheService.put(config.templates.renameConfirmation, renameConfirmation);
  templateCacheService.put(config.templates.cloneDsConfirmation, cloneDsConfirmation);
  templateCacheService.put(config.templates.templateConfigModal, templateConfigModal);
  templateCacheService.put(config.templates.templateConfirmationModal, templateConfirmationModal);
  templateCacheService.put(config.templates.combineDs, combineDs);
  templateCacheService.put(config.templates.multiDeleteConfirmation, multiDeleteConfirmation);
  templateCacheService.put(config.templates.stepsPanel, stepsPanel);
  templateCacheService.put(config.templates.columnExplorer, columnExplorer);
  templateCacheService.put(config.templates.joinSourceGrids, joinSourceGrids);
  templateCacheService.put(config.templates.addRuleMenu, addRuleMenu);
  templateCacheService.put(config.templates.wkspGridColumnMenu, wkspGridColumnMenu);
  templateCacheService.put(config.templates.wkspGridCellMenu, wkspGridCellMenu);
  templateCacheService.put(config.templates.wkspColumnSummaryMenu, wkspColumnSummaryMenu);
  templateCacheService.put(config.templates.wkspModificationAlert, wkspModificationAlert);
  templateCacheService.put(config.templates.exportDialog, exportDialog);
  templateCacheService.put(config.templates.mathModal, mathModal);
  templateCacheService.put(config.templates.numericFormatter, numericFormatter);
  templateCacheService.put(config.templates.dateFormatter, dateFormatter);
  templateCacheService.put(config.templates.fileUpload, fileUpload);
  templateCacheService.put(config.templates.sampleWarningModal, sampleWarningModal);
  templateCacheService.put(config.templates.manageUsers, manageUsers);
  templateCacheService.put(config.templates.explorePanel, explorePanel);
  templateCacheService.put(config.templates.explorePanelButton, explorePanelButton);
  templateCacheService.put(config.templates.dateInputFormat, dateInputFormat);
  templateCacheService.put(config.templates.thirdPartyAPI, thirdPartyAPI);
  templateCacheService.put(config.templates.compatibilityWarnings, compatibilityWarnings);
  templateCacheService.put(config.templates.element.modal, elementModal);
  templateCacheService.put(config.templates.element.display, elementDisplay);
  templateCacheService.put(config.templates.formly.checkbox_list, formlyCheckboxList);
  templateCacheService.put(config.templates.exploreMenu, exploreMenu);
  templateCacheService.put(config.templates.scheduler, scheduler);
  templateCacheService.put(config.templates.webhook, webhook);
  templateCacheService.put(config.templates.URL, URL);
  templateCacheService.put(config.templates.stepRename, stepRename);
  templateCacheService.put(config.templates.stepAddNote, stepAddNote);
  templateCacheService.put(config.templates.displayMenuHandle, displayMenuHandle);
  templateCacheService.put(config.templates.switcherInBuilderMenu, switcherInBuilderMenu);
  templateCacheService.put(config.templates.metricElementContentTemplate, metricElementContentTemplate);
  templateCacheService.put(config.templates.filterElementContentTemplate, filterElementContentTemplate);
  templateCacheService.put(config.templates.chartElementContentTemplate, chartElementContentTemplate);
  templateCacheService.put(config.templates.tableElementContentTemplate, tableElementContentTemplate);
  templateCacheService.put(config.templates.tableElementTemplate, tableElementTemplate);
  templateCacheService.put(config.templates.filter, filter);
  templateCacheService.put(config.templates.filterSection, filterSection);
  templateCacheService.put(config.templates.chartTypeMenu, chartTypeMenu);
  templateCacheService.put(config.templates.deleteBatchConfirmation, deleteBatchConfirmation);
  templateCacheService.put(config.templates.suspendBatchConfirmation, suspendBatchConfirmation);
  templateCacheService.put(config.templates.builder, builder);
  templateCacheService.put(config.templates.notification, notification);
  templateCacheService.put(config.templates.createLabel, createLabelModal);
  templateCacheService.put(config.templates.folderPicker, folderPicker);
  templateCacheService.put(config.templates.crosstab, crossTab);
  templateCacheService.put(config.templates.targetDs, targetDsTpl);
  templateCacheService.put(config.templates.actionSequenceSelect, actionSequenceSelect);
  templateCacheService.put(config.templates.inplaceRename, inplaceRename);
  templateCacheService.put(config.templates.paymentErrorWarning, paymentErrorWarning);
  templateCacheService.put(config.templates.alertsDialog, alertsDialog);
  templateCacheService.put(config.templates.multiLevelDropdown, multiLevelDropdown);
  templateCacheService.put(config.templates.confirmQueryUpdate, confirmQueryUpdate);
  templateCacheService.put(config.templates.appCreationModal, appCreationModal);
  templateCacheService.put(config.templates.appCreationEditModal, appCreationEditModal);
}
