import API from '@/api'

const patch = (id, payload) => API.patch(`notifications/${id}`, { patch: [payload] })

const remove = (id) => patch(id, { op: 'replace', path: 'isDismissed', value: true })

const read = (id) => patch(id, { op: 'replace', path: 'isRead', value: true })

const unread = (id) => patch(id, { op: 'replace', path: 'isRead', value: false })

export default {
  remove,
  read,
  unread
}
