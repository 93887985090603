<template>
  <mm-menu
    v-model="isMenuOpen"
    shadowClass="mm-shadow--heavy-high"
    menuClass="column-browser-content"
    :closeOnContentClick="false"
  >
    <template #activator="{ on }">
      <action-bar-button
        :label="$t('action_bar.column_browser.button_label')"
        :activated="isMenuOpen"
        :tooltipLabel="computedTooltipLabel"
        :disabled="!!computedTooltipLabel.length"
        icon="explore"
        v-on="on"
        @click="onColumnBrowserToggle"
      />
    </template>
    <template #content>
      <section class="d-flex-col">
        <column-browser-header
          :showSelectAll="!(isRenameEnabled || isReorderEnabled)"
          :columns="computedColumns"
          :filteredColumns="computedFilteredColumns"
          :search.sync="search"
        />
        <column-browser-list
          :columns="computedColumns"
          :filteredColumns="computedFilteredColumns"
          :isRenameEnabled="isRenameEnabled"
          :isReorderEnabled="isReorderEnabled"
          :reorderedColumnIds.sync="reorderedColumnIds"
          :lastReorderedColumnId.sync="lastReorderedColumnId"
          :renameColumnNames.sync="renameColumnNames"
        />
      </section>
      <section>
        <column-browser-actions
          :columns="computedColumns"
          :isRenameEnabled.sync="isRenameEnabled"
          :isReorderEnabled.sync="isReorderEnabled"
          :isApplyDisabled="computedIsApplyDisabled"
          @action:show="showColumns"
          @action:hide="hideColumns"
          @action:showOnlyThese="showOnlyTheseColumns"
          @action:reorder="enableReorder"
          @action:rename="enableRename"
          @action:remove="removeColumns"
          @action:findAndReplace="findAndReplaceColumnData"
          @action:convertColumnType="convertColumnType"
          @action:openInExploreColumns="openInExploreColumns"
          @action:apply="apply"
          @action:discard="discard"
        />
      </section>
    </template>
  </mm-menu>
</template>

<script>
import ActionBarButton from '../action-bar-button/action-bar-button.vue'
import ColumnBrowserHeader from './action-bar-column-browser-header/action-bar-column-browser-header.vue'
import ColumnBrowserList from './action-bar-column-browser-list/action-bar-column-browser-list.vue'
import ColumnBrowserActions from './action-bar-column-browser-actions/action-bar-column-browser-actions.vue'
import dataEditorApi from '@/modules/data-editor/api/data-editor.api'
import { USER_EVENTS } from '@/constants'

export default {
  name: 'action-bar-column-browser',
  components: {
    ActionBarButton,
    ColumnBrowserHeader,
    ColumnBrowserList,
    ColumnBrowserActions
  },
  data: () => ({
    isMenuOpen: false,
    search: '',
    isReorderEnabled: false,
    reorderedColumnIds: [],
    lastReorderedColumnId: '',
    isRenameEnabled: false,
    renameColumnNames: {}
  }),
  computed: {
    computedColumns() {
      return this.$store.getters['dataEditor/columns']
    },
    computedFilteredColumns() {
      let columns = this.computedColumns
      if (this.reorderedColumnIds.length)
        columns = this.reorderedColumnIds.map((id) => columns.find((c) => c.id === id))
      return columns.filter((column) => column.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    computedIsApplyDisabled() {
      if (this.isReorderEnabled)
        return (
          this.reorderedColumnIds.length == 0 ||
          this.reorderedColumnIds.every((columnId, index) => columnId == this.computedColumns[index].id)
        )
      if (this.isRenameEnabled)
        return this.computedColumns.every((column) => this.renameColumnNames[column.id] === column.name)
      return false
    },
    computedSelectedColumnIds() {
      return this.computedColumns.filter((column) => column.selected).map((column) => column.id)
    },
    computedHiddenColumnIds() {
      return this.computedColumns.filter((column) => !column.show).map((column) => column.id)
    },
    computedTooltipLabel() {
      if (this.$store.getters['pipeline/isError']) return this.$t(`action_bar.pipeline_error_tooltip`)
      if (this.$store.getters['pipeline/isDraft']) return this.$t(`action_bar.pipeline_draft_tooltip`)
      if (this.$store.state.dataEditor.isPreview) return this.$t(`action_bar.column_browser.preview_tooltip`)
      return ''
    }
  },
  watch: {
    isRenameEnabled(isRenameEnabled) {
      if (isRenameEnabled) {
        this.renameColumnNames = this.computedColumns.reduce((map, column) => {
          map[column.id] = column.name
          return map
        }, {})
      }
    }
  },
  methods: {
    onColumnBrowserToggle() {
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.TOGGLE, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    async saveDisplayProperties(displayProperties) {
      this.isMenuOpen = false
      await dataEditorApi.setDisplayProperties({
        viewId: this.$store.getters['dataEditor/view'].viewId,
        displayProperties
      })
    },
    showColumns() {
      const hiddenColumns = this.computedHiddenColumnIds.filter(
        (columnId) => !this.computedSelectedColumnIds.includes(columnId)
      )
      this.saveDisplayProperties({ HIDDEN_COLUMNS: hiddenColumns })
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.SHOW, {
        viewId: this.$store.state.dataEditor.viewId,
        hiddenColumns
      })
    },
    hideColumns() {
      const hiddenColumns = [...this.computedHiddenColumnIds, ...this.computedSelectedColumnIds]
      this.saveDisplayProperties({ HIDDEN_COLUMNS: hiddenColumns })
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.HIDE, {
        viewId: this.$store.state.dataEditor.viewId,
        hiddenColumns
      })
    },
    showOnlyTheseColumns() {
      const hiddenColumns = this.computedColumns
        .map((column) => column.id)
        .filter((columnId) => !this.computedSelectedColumnIds.includes(columnId))
      this.saveDisplayProperties({ HIDDEN_COLUMNS: hiddenColumns })
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.SHOW_ONLY_THESE, {
        viewId: this.$store.state.dataEditor.viewId,
        hiddenColumns
      })
    },
    enableReorder() {
      this.isReorderEnabled = true
      this.isRenameEnabled = false
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.REORDER, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    enableRename() {
      this.isReorderEnabled = false
      this.isRenameEnabled = true
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.RENAME, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    apply() {
      if (this.isReorderEnabled) this.saveReorderedColumns()
      else this.saveRenamedColumns()
      this.discard({ sendUserEvent: false })
    },
    async saveReorderedColumns() {
      const columnOrder = this.reorderedColumnIds.reduce((columnOrder, columnId, index) => {
        columnOrder[index] = columnId
        return columnOrder
      }, {})
      this.$store.commit('dataEditor/setDisabled', true)
      await this.saveDisplayProperties({ COLUMN_ORDER: columnOrder })
      this.$store.commit('dataEditor/setDisabled', false)
      this.$root.legacyHandlers.updateDataviewData(true)
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.APPLY, {
        viewId: this.$store.state.dataEditor.viewId,
        applyFor: 'reorder',
        columnOrder
      })
    },
    async saveRenamedColumns() {
      this.isMenuOpen = false
      const newColumnNames = this.computedColumns
        .filter((column) => column.name != this.renameColumnNames[column.id])
        .reduce((renamedColumns, column) => {
          renamedColumns[column.id] = this.renameColumnNames[column.id]
          return renamedColumns
        }, {})
      const payload = {
        COLUMN_NAMES: newColumnNames,
        COLUMN_ORDER: this.computedColumns.reduce((columnOrder, column, index) => {
          columnOrder[index] = column.id
          return columnOrder
        }, {}),
        HIDDEN_COLUMNS: this.computedHiddenColumnIds
      }
      await this.saveDisplayProperties(payload)
      this.$root.legacyHandlers.updateDataviewData(true)
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.APPLY, {
        viewId: this.$store.state.dataEditor.viewId,
        applyFor: 'rename',
        ...payload
      })
    },
    discard({ sendUserEvent = true } = {}) {
      this.search = ''
      this.isReorderEnabled = false
      this.reorderedColumnIds = []
      this.lastReorderedColumnId = ''
      this.isRenameEnabled = false

      if (sendUserEvent) {
        this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.DISCARD, {
          viewId: this.$store.state.dataEditor.viewId
        })
      }
    },
    removeColumns() {
      this.isMenuOpen = false
      const columnIds = this.computedSelectedColumnIds
      this.$root.legacyHandlers.openTaskPanelWithParams('delete', { columnIds })
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.REMOVE, {
        viewId: this.$store.state.dataEditor.viewId,
        columnIds
      })
    },
    findAndReplaceColumnData() {
      this.isMenuOpen = false
      const columnIds = this.computedColumns
        .filter((column) => column.selected && column.type === 'text')
        .map((column) => column.id)
      this.$root.legacyHandlers.openTaskPanelWithParams('replace', { columnIds })
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.REPLACE, {
        viewId: this.$store.state.dataEditor.viewId,
        columnIds
      })
    },
    convertColumnType() {
      this.isMenuOpen = false
      const columnIds = this.computedSelectedColumnIds
      this.$root.legacyHandlers.openTaskPanelWithParams('convert', { columnIds })
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.CONVERT_COLUMN_TYPE, {
        viewId: this.$store.state.dataEditor.viewId,
        columnIds
      })
    },
    openInExploreColumns() {
      this.isMenuOpen = false
      const existingExploreCardColumnIds = this.$store.getters['exploreSection/exploreCardColumnIds']
      const columnIds = this.computedSelectedColumnIds.filter((colId) => !existingExploreCardColumnIds.includes(colId))
      const allExploreCardColumnIds = [...columnIds, ...existingExploreCardColumnIds]
      columnIds.forEach(this.$root.legacyHandlers.openExploreColumn)
      this.$store.commit('dataEditor/setExploreCardColumns', {
        columnIds: allExploreCardColumnIds
      })
      this.$store.commit('dataEditor/setColumnSelection', {
        selected: false,
        columnIds: this.computedSelectedColumnIds
      })
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.OPEN_IN_EXPLORE_COLUMNS, {
        viewId: this.$store.state.dataEditor.viewId,
        columnIds
      })
    }
  }
}
</script>

<style lang="scss">
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';

.column-browser-content {
  .mm-menu--container {
    display: flex;
    width: 565px;
    height: 400px;
    border: 1px solid var(--mm-color--n60);
    @extend .mm-text--body-regular;

    section:first-child {
      width: 324px;
    }
    section:last-child {
      border-left: 1px solid var(--mm-color--n70);
      width: 240px;
    }
  }
}
</style>
