/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
import * as RRule from 'rrule-alt';
import {
  getRange, generateFrequencyOption, monthDays, minutes, minutely, hours,
  days, isInstance, SERVER_FORMAT, momentFormat, exampleFormat, startTimeEndTimeMinimumPadding
} from "./scheduler.utils";


/**
 * @ngInject
 */
schedulerController.$inject = ['$rootScope', 'moment', 'config', '$timeout', 'c', 'utils', 'analyticsService'];
export function schedulerController($rootScope, moment, config, $timeout, c, utils, analyticsService) {


  /*
  * reset function restore default values for scheduler -> move stuff from init method to reset method whenever required
  * function to get minimum start value for period apis along with test cases
  *
  * */

  var svm = this;
  let _reportDsNameChange = utils.debounce(() => {
    analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.changeDsName,
      {
        eventOrigin: "thirdPartyModal",
        step: "schedule"
      });
  }, 2000, false);
  this.$onInit = init;

  function init() {
    // variables
    svm.appendReplaceTooltip = '';
    svm.DSNameChangedManually = false;
    svm.atTime = '00:00';  // for daily, weekly, monthly
    svm.atMinute = 0;  // for hourly
    svm.examples = [];
    svm.exampleEnd = null;
    svm.moment_type = moment().constructor.name;
    svm.startDate = getStartDate().format(momentFormat);
    svm.defaultDate = getStartDate().format(momentFormat);
    svm.periodDataStartDate = getStartDate().subtract(15, 'days').format(momentFormat);
    svm.periodDataStartDateMax = getStartDate().subtract(15, 'minutes').format(momentFormat);
    svm.datePickerFormat = 'Y-m-d h:i K';
    svm.byweekday = null;
    svm.bymonthday = null;
    svm.endDateDirty = false;
    svm.startDateDirty = false;
    svm.requireEndDate = "";

    //functions
    svm.uniqueSeqColChange = uniqueSeqColChange;
    svm.normalizeTime = normalizeTime;
    svm.toggleWeekday = toggleWeekday;
    svm.setFrequency = setFrequency;
    svm.appendReplaceChange = appendReplaceChange;
    svm.updateDsName = updateDsName;
    svm.manuallyChangedDSName = manuallyChangedDSName;
    svm.recomputeRecurRule = recomputeRecurRule;
    svm.getStartDate = getStartDate;
    svm.getEndDate = getEndDate;
    svm.changeScheduleOptions = changeScheduleOptions;

    svm.deferredRecomputeRecurRule = function () {

      $timeout(recomputeRecurRule, 200);
    };
    appendReplaceChange('REPLACE');

    svm.scheduleRelatedParams.isValid = true;
    svm.deferredRecomputeRecurRule();
    svm.startDateMin = getStartDate().format(momentFormat);
    svm.scheduleRelatedParams.firstPullAt = 'NOW';


    /** Min Frequency Map  for reference as minimum frequency is computed through this
     YEARLY: 6
     MONTHLY: 5
     WEEKLY: 4
     DAILY: 3
     HOURLY: 2
     MINUTELY: 1
     JUST_ONCE: 0
     */
    svm.originalScheduleOptions = [];
    svm.default_option = generateFrequencyOption('Just once', null);
    svm.originalScheduleOptions.push(svm.default_option);

    if ($rootScope.isFeatureEnabled($rootScope.appFeatures.minutelyDataPull)) {
      svm.originalScheduleOptions.push(generateFrequencyOption('Every few minutes', 'MINUTELY'));
    }
    if ($rootScope.isFeatureEnabled($rootScope.appFeatures.hourlyDataPull)) {
      svm.originalScheduleOptions.push(generateFrequencyOption('Hourly', 'HOURLY'));
    }

    svm.originalScheduleOptions.push(
      generateFrequencyOption('Daily', 'DAILY'),
      generateFrequencyOption('Weekly', 'WEEKLY'),
      generateFrequencyOption('Monthly', 'MONTHLY')
    );

    svm.scheduleOptions = _.cloneDeep(svm.originalScheduleOptions);
    if (!svm.schedule) {
      svm.schedule = {
        FREQUENCY: svm.scheduleOptions[0].internal,
        INTERVAL: 1,
        BYWEEKDAY: null,
        BYMONTHDAY: null
      };
    }

    svm.frequencyIntervals = {
      'WEEKLY': c.weekdays,
      'MONTHLY': monthDays,
      'MINUTES': minutes,
      'MINUTELY': minutely,
      'HOURLY': hours,
      'DAILY': days
    };

    reset();

    if (!svm.callBackFns || !svm.callBackFns.length) {
      svm.callBackFns = [];
    }

    if (svm.callBackFns.indexOf(svm.deferredRecomputeRecurRule) === -1) {
      svm.callBackFns.push(svm.deferredRecomputeRecurRule);
    }

    if (!svm.start) {
      var start = moment(new Date());
      start.subtract(2, 'weeks');
      start.subtract(1, 'days');
      svm.start = start.utc().format(SERVER_FORMAT);
    }
  }

  function reset() {

  }

  function updateDsName() {
    if (svm.origDsName && !svm.DSNameChangedManually) {
      if (svm.schedule.FREQUENCY) {
        var time_str = svm.schedule.FREQUENCY.toLowerCase();
        if (svm.schedule.FREQUENCY == 'MINUTELY') {
          time_str = 'minute';
        }
        svm.dsName = svm.origDsName + ' ' + time_str;
      } else {
        svm.dsName = svm.origDsName;
      }
    }
  }

  function changeScheduleOptions(option) {
    analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.schedulingOptions.changeScheduleOptions,
      {
        eventOrigin: "thirdPartyModal",
        propertyChanged: option
      });
    svm.deferredRecomputeRecurRule()
  }

  svm.literal_text = function () {
    return svm.schedule.FREQUENCY === 'WEEKLY' || svm.schedule.FREQUENCY === 'MONTHLY' ? 'and again on every : ' : 'and again after every : ';
  };

  function getStartDate(current_date = moment().seconds(0)) {
    if (!isInstance(current_date, [svm.moment_type, 'Date'])) {
      console.error("Expected Moment or Date object");
      return;
    }
    return normalizeTime(current_date);
  }

  function getEndDate(current_date = moment().seconds(0)) {

    if (!isInstance(current_date, [svm.moment_type, 'Date'])) {
      console.error("Expected Moment or Date object");
      return;
    }
    return current_date.second(0);
  }

  function normalizeTime(date_obj) {
    if (isInstance(date_obj, 'Date')) {
      date_obj = moment(date_obj);
    }
    if (!isInstance(date_obj, ['Date', svm.moment_type])) {
      console.error("Expected Date or Moment object");
      return
    }
    date_obj.add(config.MIN_TIME_FROM_NOW_OCCURRENCE + config.SCHEDULER_INTERVAL, 'minutes');
    let minutes_part = date_obj.minutes();
    return date_obj.minutes(minutes_part - minutes_part % 5 + 5).seconds(0);
  }

  function appendReplaceChange(val, eventOrigin?) {
    if (eventOrigin === 'userEvent') {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.schedulingOptions.changeOnRefreshOption,
        {
          eventOrigin: 'thirdPartyModal',
          step: "schedule"
        });
    }
    if (val == 'APPEND') {
      svm.appendReplaceTooltip = 'This option will *add* to existing data in the dataset and replace it with the new results';
    } else if (val == 'REPLACE') {
      svm.appendReplaceTooltip = 'This option will *remove* all data from the dataset and replace it with the new results. This may take a while if you have a large dataset';
    } else if (val == 'REPLACELATEST') {
      svm.appendReplaceTooltip = 'This option will *remove* all data from the dataset and keep only the latest data';
    } else {
      svm.appendReplaceTooltip = '';
    }
  }

  function setFrequency(option) {
    analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.schedulingOptions.changeScheduleFrequency,
      {
        eventOrigin: "thirdPartyModal",
        step: "schedule"
      });

    svm.schedule.FREQUENCY = option.internal;
    svm.bymonthday = null;
    svm.byweekday = null;
    svm.schedule.INTERVAL = 1;

    switch (svm.schedule.FREQUENCY) {
      case null: {
        svm.requireEndDate = '';
        recomputeRecurRule();
        break;
      }
      case 'WEEKLY': {
        svm.byweekday = svm.frequencyIntervals[svm.schedule.FREQUENCY][0].key;
        break;
      }
      case 'MONTHLY': {
        svm.bymonthday = svm.frequencyIntervals[svm.schedule.FREQUENCY][0].key;
        break;
      }
    }

    if (svm.schedule.FREQUENCY !== 'MONTHLY' && svm.schedule.FREQUENCY !== 'WEEKLY' && svm.schedule.FREQUENCY !== null) {
      svm.byweekday = null;
      svm.bymonthday = null;
      svm.schedule.INTERVAL = svm.frequencyIntervals[svm.schedule.FREQUENCY][0].key;
    }
    if (!svm.startDateDirty) {
      if (!svm.schedule.FREQUENCY) {
        svm.startDate = getStartDate().subtract(1, 'month').format(momentFormat);
      } else {
        svm.defaultDate = getStartDate().format(momentFormat);
        svm.startDate = svm.defaultDate;
      }
    }
    svm.updateDsName();
    svm.recomputeRecurRule();
  }

  function manuallyChangedDSName() {
    _reportDsNameChange();
    svm.DSNameChangedManually = true;
  }

  function toggleWeekday(weekday) {

    var index = svm.schedule.BYWEEKDAY.indexOf(weekday.key);

    if (index > -1) {
      svm.schedule.BYWEEKDAY.splice(index, 1);
    } else {
      svm.schedule.BYWEEKDAY.push(weekday.key);
    }
  }


  function resetStartDateMinMax(minFromNow: any) {
    var diff = Math.abs((minFromNow.valueOf() - svm.startDateMin.valueOf()) / 1000);
    if (diff >= 30) {
      svm.startDateMin = minFromNow;
    }
  }

  function computeExamples(rule: any, minFromNow: any, endDate: any) {
    svm.examples = getNextOccurrences(rule, minFromNow, 4);
    if (svm.scheduleRelatedParams.firstPullAt === 'NOW') {
      svm.examples.unshift();
    }
    if (svm.requireEndDate && endDate) {
      svm.exampleEnd = moment(rule.before(endDate, true)).format(exampleFormat);
    }
  }

  function setupWeekDayMonthDayInSchedule(rule: any, ruleConfig: any) {
    var firstOccurrence = rule.after(ruleConfig.dtstart, true);
    if (svm.schedule.FREQUENCY === 'WEEKLY') {
      var weekdayno = moment(firstOccurrence).utc().day();
      svm.schedule.BYWEEKDAY = weekdayno !== weekdayno ? [] : [c.weekdays[c.PY_WEEKDAYS[weekdayno]].key];
    } else if (svm.schedule.FREQUENCY === 'MONTHLY') {
      var monthdayno = moment(firstOccurrence).utc().date();
      svm.schedule.BYMONTHDAY = monthdayno !== monthdayno ? [] : [monthdayno];
    } else {
      svm.schedule.BYWEEKDAY = [];
      svm.schedule.BYMONTHDAY = [];
    }
  }

  function setScheduleStart(internalStart: any) {
    svm.schedule.START = moment(internalStart, momentFormat).utc().format(momentFormat);
  }

  function prepareRuleConfig(internalStart: any) {
    let ruleConfig: any = {
      freq: c.frequencyRRuleMap[svm.schedule.FREQUENCY],
      interval: svm.schedule.INTERVAL,
      dtstart: moment(internalStart, momentFormat).toDate(),

    };
    if (svm.schedule.FREQUENCY === 'WEEKLY') {
      ruleConfig.byweekday = c.weekdayRRuleMap[svm.byweekday];
    } else if (svm.schedule.FREQUENCY === 'MONTHLY') {
      ruleConfig.bymonthday = svm.bymonthday;
    } else {
      ruleConfig.byweekday = [];
      ruleConfig.bymonthday = [];
    }
    return ruleConfig;
  }

  function setEndDateMinMax() {
    var _endDateMin = moment(svm.startDate).add(startTimeEndTimeMinimumPadding, 'minutes');
    if (svm.schedule.FREQUENCY === null && svm.scheduleType === 'PERIOD') {
      _endDateMin = moment(svm.periodDataStartDate).add(startTimeEndTimeMinimumPadding, 'minutes');
      svm.endDateMax = moment().seconds(0).toDate()
    }
    var diff = Math.abs((_endDateMin.valueOf() - moment(svm.endDateMin).valueOf()) / 1000);
    if (diff >= 30 || !svm.endDateMin) {
      svm.endDateMin = _endDateMin.format(momentFormat);
    }
  }

  function recomputeRecurRule() {
    svm.startDateMax = null;
    svm.endDateMax = null;
    var _minFromNow = getStartDate();
    var minFromNow = _minFromNow.toDate();

    if (svm.scheduleRelatedParams.firstPullAt === 'NOW') {
      svm.startDate = moment().format(momentFormat);
    } else if (moment(svm.startDate, momentFormat).diff(_minFromNow) < 0) {
      svm.startDate = _minFromNow.format(momentFormat);
    }
    var internalStart = svm.startDate;
    if (internalStart == undefined) {
      return
    }
    var endDate = null;

    if (!svm.requireEndDate) {
      delete svm.schedule.UNTIL;
    }
    svm.defaultDate = _minFromNow.format(momentFormat);
    resetStartDateMinMax(minFromNow);
    var ruleConfig = prepareRuleConfig(internalStart);

    setScheduleStart(internalStart);
    //
    // var _endDateMin = moment(svm.startDate).add(startTimeEndTimeMinimumPadding, 'minutes');
    // var endDateMin = _endDateMin.toDate();
    // var diff = Math.abs((endDateMin.valueOf() - svm.endDateMin.valueOf()) / 1000);
    // if (diff >= 30) {
    //   svm.endDateMin = endDateMin
    // }
    setEndDateMinMax();

    if (svm.requireEndDate || (svm.scheduleType === 'PERIOD' && !svm.schedule.FREQUENCY && svm.scheduleRelatedParams.firstPullAt === 'NOW')) {
      endDate = moment(svm.endDate, momentFormat).toDate();
      if (svm.endDate == undefined) {
        return
      }
      ruleConfig.until = endDate;
      svm.schedule.UNTIL = moment(svm.endDate, momentFormat).utc().format(momentFormat);
    }

    if (!svm.schedule || !svm.schedule.FREQUENCY) {
      svm.scheduleRelatedParams.is_valid = true;
      return;
    }

    var rule = new RRule(ruleConfig);
    setupWeekDayMonthDayInSchedule(rule, ruleConfig);
    var now = moment();
    computeExamples(rule, now.toDate(), endDate);

    svm.scheduleRelatedParams.is_valid = !(!svm.examples.length && svm.schedule.FREQUENCY !== null);
    if (svm.scheduleRelatedParams.is_valid) {
      let latestStart = rule.after(now.toDate());
      setScheduleStart(moment(latestStart).format(momentFormat));
    }
  }

  function getNextOccurrences(rule, after, count) {
    var occ = [];
    var latest = after;
    for (var i = 0; i < count; i++) {
      if (latest === null) {
        break;
      }
      latest = rule.after(latest);
      if (latest) {
        occ.push(moment(latest).format(exampleFormat));
      }
    }
    return occ;
  }

  function uniqueSeqColChange() {
    analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.schedulingOptions.changeUniqueSeqCol,
      {
        eventOrigin: "thirdPartyModel",
      });
  }

}

dateHandler.$inject = ['moment'];
export function dateHandler(moment) {
  return {
    parse: function (str) {
      if (!str) {
        return null;
      }
      return new Date(Date.parse(str + ' UTC'));

    },
    format: function (dt) {
      if (!dt) {
        return null;
      }
      return moment(dt).utc().format(SERVER_FORMAT);
    }
  };
}
