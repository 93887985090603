/**
 * Created by ranjith on 20/11/18.
 */
import * as angular from "angular";
import * as _ from 'lodash-es';


/**
 * Splits a given text
 *
 * @param text
 * @returns {Array}
 */
function split(text) {
  let delim = '[' + ['\\\\+', '\\-', '\\\\(', '\\\\)', '\\\\*', '/'].join('|') + ']';
  var delimRegex = new RegExp(delim, 'g');
  let quotedString = /"(?:[^"\\]|\\.)*"/g;

  let regexToUse = delimRegex;
  var token, index, result = [];
  while (text !== '') {
    if(text.startsWith('"')){
      regexToUse = quotedString;
    }
    else{
      regexToUse = delimRegex;
    }
    regexToUse.lastIndex = 0;
    token = regexToUse.exec(text);
    if (token === null) {
      break;
    }
    index = token.index;
    if (token[0].length === 0) {
      index = 1;
    }
    result.push(text.substr(0, index));
    result.push(token[0]);
    index = index + token[0].length;
    text = text.slice(index);
    text = text.trim();
  }
  result.push(text);
  return result;
}


// basic tokenising function that splits by the operators and brackets

export function tokenise(str) {
  var result = split(str);
  var clean = [];
  result.forEach(function (r) {
    r = r.trim();
    if(r.length){
      clean.push(r);
    }
  });
  return clean;
}
