<template>
  <mm-modal v-model="computedIsModalOpen" :title="$t('navbar.tab.menu.reset.title')" :width="350">
    <template #default>
      <div class="d-flex">
        <mm-icon class="m-r-3" name="refresh_24px" />
        <span class="mm-text--body-regular">{{ $t('navbar.tab.menu.reset.description') }}</span>
      </div>
    </template>
    <template #actions>
      <mm-button :label="$t('global.dictionary.cancel')" objective="tertiary" @click="computedIsModalOpen = false" />
      <mm-button class="m-l-3" :label="$t('global.dictionary.reset')" @click="submit" />
    </template>
  </mm-modal>
</template>

<script>
export default {
  name: 'view-options-modal-reset',
  props: {
    value: Boolean
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    submit() {
      this.$emit('submit')
      this.computedIsModalOpen = false
    }
  }
}
</script>
