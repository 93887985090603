/**
 * Manages currently logged in user and currently selected workspace, also has information of other users relevant to the
 * current user in the context of permissions, sharing.
 * */
"use strict";
import * as _ from 'lodash-es';
import * as Raven from 'raven-js';
import * as dateformat from "dateformat";

// Transform
import { transformWorkspaces, transformUsageInfo } from "../../../vueApp/src/mm-frontend/src/api/global.transform.js"
import axios from '../../../vueApp/src/vueCode/plugins/axios.js'
import {forEach} from "lodash";

/**
 * @ngInject
 * User Workspace service
 */
UserWorkspaceService.$inject = ['VuexStore', '$http', '$q', '$resource', '$window', 'config', 'eventCallbackManagerFactory', '$timeout', '$location',
                            '$rootScope', 'c', 'analyticsService', 'featureAvailability', 'localService', 'utils', 'toastNotification', 'keyCloak', 'Notification', 'CacheFactory'];
export function UserWorkspaceService($store, $http, $q, $resource, $window, config, eventCallbackManagerFactory, $timeout, $location,
                            $rootScope, c, analyticsService, featureAvailability, localService, utils, toastNotification, keyCloak, Notification, CacheFactory) {
  var workspaceSelectedEvent = new eventCallbackManagerFactory('workspaceSelected');
  var uiPreferencesUpdatedEvent = new eventCallbackManagerFactory('uiPreferencesUpdatedEvent');
  var selfDetailsLoadedEvent = new eventCallbackManagerFactory('selfDetailsLoadedEvent');
  var smsDetailsLoadedEvent = new eventCallbackManagerFactory('workspaceSmsDetailsLoadedEvent');
  var UIPreferences = $resource(config.api.uiPreferences);
  var SelfResource = $resource(config.api.self);
  var _2FAResource = $resource(config.api.twoFactorAuth);
  var keycloak2FA = $resource(config.api.keycloak2FA)
  var modifyPassword = $resource(config.api.modifyPassword)
  let smsData: any = {};
  let usageData: any = {};

  var service = {
    loggedInEmail: $store.state.user.email,
    workspace: null,
    logged_in_user: undefined, // gets populated later, do not use if needed at init time
    selfDetails: undefined,
    ui_preferences: {}, // gets populated later, do not use if needed at init time
    workspaces: [],
    projects: [],
    users: [],
    smsData: smsData,
    usageData: usageData,
    smsHostedPage: null,
    paymentMethodPage: null,
    is_super_admin: false,
    isOwner: false,
    isAdmin: false,
    can_manage_users: false,
    owners_count: 0,
    _workspace_list_inited: false,
    allowed_to_add_user: true,
    // methods
    get_ui_preferences: get_ui_preferences,
    set_ui_preferences: set_ui_preferences,
    get_self_details: get_self_details,
    set_self_details: set_self_details,
    get_workspaces: get_workspaces,
    get_users: get_users,
    select_workspace: select_workspace,
    add_user_to_workspace: add_user_to_workspace,
    remove_user_from_workspace: remove_user_from_workspace,
    change_resources_ownership: change_resources_ownership,
    change_user_role: change_user_role,
    auto_select_workspace: auto_select_workspace,
    reset: reset,
    getSmsData: getSmsData,
    checkoutSmsPlan: checkoutSmsPlan,
    updatePaymentMethod: updatePaymentMethod,
    loadDiscountDetails: loadDiscountDetails,
    loadInvoices: loadInvoices,
    downloadInvoice: downloadInvoice,
    loadWorkspace: loadWorkspace,
    patchWorkspace: patchWorkspace,
    setup2FA: setup2FA,
    is2FAEnabled : is2FAEnabled,
    getCurrentUsage: getCurrentUsage,
    fetch_2FA_uri: fetch_2FA_uri,
    validate_2FA_OTP: validate_2FA_OTP,
    isWorkspaceAccessible: isWorkspaceAccessible,
    modify_password: modify_password,
    // events
    on_workspace_select: workspaceSelectedEvent.add_callback,
    on_ui_preferences_update: uiPreferencesUpdatedEvent.add_callback,
    onSelfDetailsLoaded: selfDetailsLoadedEvent.add_callback,
    onSmsDetailsLoaded: smsDetailsLoadedEvent.add_callback,
    clearCache: clearCache
  };
  add_event_listeners();

  var Workspaces = $resource(config.api.workspaces);
  let debouncedLoad = utils.debounce(loadDetailsAsync, 2000);
  var WorkspaceUsers;

  if (window.name == "2FA"){
    window.close()
    localService.storage.is2FAchange = utils.getRandomString(10)
  }

  const get_used_percent_indicator = (val) => {
    if (val > 50 && val <= 75){
      return 'warning';
    }
    else if (val > 75){
      return 'danger';
    }
    else{
      return  '';
    }
  }
  return service;

  function add_event_listeners() {
    $rootScope.$on(config.events.auth.reselectWorkspace, function () {
      reset();
      $rootScope.$broadcast(config.events.auth.checkTokenEvent);
    });
  }

  function get_workspaces() {
    if(service.is_super_admin){
      return;
    }
    var deferred = $q.defer();


    fetchResource(Workspaces, `/${service.loggedInEmail}/`, 'workspaces').then(get_workspaces_success);
    function get_workspaces_success(data) {
      $store.dispatch('saveWorkspaces', transformWorkspaces({ data }))
      service.workspaces = data.workspaces;
      service._workspace_list_inited = true;
      deferred.resolve();
    }
    return deferred.promise;
  }
  async function fetchResource(resource, cache_bucket: string, cache_key: string, cache_options: any = {}, cache_condition_checker: any = undefined){
    /*
    * Fetches a resource from the server and caches it
    * @param resource: $resource object
    * @param cache_bucket: name of the cache bucket
    * @param cache_key: key to store the resource in the cache bucket
    * @param cache_options: options to pass to the cache factory
    * @param cache_condition_checker: function to check if the resource should be cached or not. response from the server is passed to this function and it should return true or false
    * @returns {Promise<*>}
    *
    * */
    // default checker function which returns always true
    
    if (!cache_condition_checker) {
        cache_condition_checker = (data) => true;
    }
    let _cache = CacheFactory.get(cache_bucket);
    if (!_cache) {
        _cache = CacheFactory(cache_bucket, cache_options);
    }
    let data = _cache.get(cache_key);
    if (data) {
        return data;
    }
    data = await resource.get().$promise;
    if(cache_condition_checker(data)) {
      _cache.put(cache_key, data);
    }
    return data;
  }

  function getSmsData(){
    var deferred = $q.defer();

    // If workspace list is not initialized, exit.
    if (service._workspace_list_inited == false) {

      // This function is used as callback in
      // $window.onFocus of topNav controller.
      // If user is logging out and onFocus is triggered
      // then at that moment, workspace list is empty.
      return;
    }
    function process_sms_details (data) {
      service.smsData = data;
      service.smsData['_json'] = JSON.stringify(data, undefined, 2);
      if (!data.sms_details) {
        data.sms_details = {};
      }
      try {
        if (data.sms_details.card) {
          if (data.sms_details.card.status != 'valid') {
            service.smsData.showWarning = true;
            service.smsData.warningReason = 'invalid_card';
          }
        }
        if (data.sms_details.subscription.status == 'in_trial') {
          let lastDate = new Date(data.sms_details.subscription.trial_end * 1000);
          let today = new Date();
          let timeDiff = lastDate.getTime() - today.getTime();
          let diff = Math.ceil((timeDiff) / (24 * 3600 * 1000));
          data.sms_details.subscription.daysLeftInTrial = diff;
        }
        // If subscription is active and there are 5 days or less remaining for current term to end show warning
        if (data.sms_details.subscription.status == 'active') {
          let lastDate = new Date(data.sms_details.subscription.current_term_end * 1000);
          let today = new Date();
          let timeDiff = lastDate.getTime() - today.getTime();
          let diff = Math.ceil((timeDiff) / (24 * 3600 * 1000));
          data.sms_details.subscription.daysLeftInTrial = diff;
          if (diff <= 5) {
            service.smsData.showWarning = true;
            service.smsData.warningReason = 'term_near_end';
          }
        }
        data.sms_details.subscription.nextBillingAt = dateformat(new Date(data.sms_details.subscription.next_billing_at * 1000), "dddd, mmmm dS, yyyy");
        data.sms_details.subscription.updatedAt = dateformat(new Date(data.sms_details.subscription.updated_at * 1000), "dddd, mmmm dS, yyyy");
        let plans = data.available_plans;

        if (data.current_plan) {
          let my_plan = data.current_plan
          service.smsData['currentPlan'] = my_plan
          isUserFull(my_plan)
        }
        let addonMatrix = {};
        _.forEach(data.available_addons, function (addon) {
          addon.perPlanPrice = {};
          if (addon.meta_data && addon.meta_data.plan_based_discounts) {
            let pdiscount = addon.meta_data.plan_based_discounts;
            _.forEach(pdiscount, function (d, p) {
              addon.perPlanPrice[p] = addon.price * (1 - d);
            });
          }
          addonMatrix[addon.id] = addon;
        });
        service.smsData['addonMatrix'] = addonMatrix;
      } catch (e) {
        // console.error(e);
      }
      smsDetailsLoadedEvent.fire_event();
      deferred.resolve(service.smsData);
    }
    let res = new $resource(config.api.smsDetails, {workspace_id: service.workspace.id});
    fetchResource(res, '/sms_details/', service.workspace.id).then(process_sms_details, deferred.reject);
    return deferred.promise;
  }

  function isUserFull(my_plan){
    service.allowed_to_add_user = false;
    if(my_plan.cf_are_new_users_allowed && my_plan.cf_are_new_users_allowed == 'Yes'){
      if (my_plan.cf_number_of_users){
        if(service.users.length < my_plan.cf_number_of_users){
            service.allowed_to_add_user = true;
        }
      }else{
        service.allowed_to_add_user = true;
      }
    }
  }

  function checkoutSmsPlan(plan_name){
    var deferred = $q.defer();
    let postData = {
      plan_name: plan_name,
      redirect_to: $location.absUrl()
    };
    let res = new $resource(config.api.smsDetails, {workspace_id: service.workspace.id});
    clearCache('/sms_details/', service.workspace.id);
    res.save(postData).$promise.then(_resolve, deferred.reject);
    function _resolve(data){
      deferred.resolve(data.page);
      service.smsHostedPage = data.page;
    }
    return deferred.promise;
  }

  function updatePaymentMethod(){
    var deferred = $q.defer();
    let postData = {
      update_payment_method: true,
      redirect_to: $location.absUrl()
    };
    let res = new $resource(config.api.smsDetails, {workspace_id: service.workspace.id});
    clearCache('/sms_details/', service.workspace.id);
    res.save(postData).$promise.then(_resolve, deferred.reject);
    function _resolve(data){
      deferred.resolve(data.page);
      service.paymentMethodPage = data.page;
    }
    return deferred.promise;
  }

  function loadDiscountDetails(){
    service.smsData.discountDetailsLoaded = false;
    _.forEach(service.smsData.sms_details.subscription.coupons, function(c, idx){
      var res = new $resource(config.api.coupons, {workspace_id: service.workspace.id, coupon_id: c.coupon_id});
      res.get().$promise.then(_resolve);
      function _resolve(data){
        _.merge(c, data.coupon);
      }
      service.smsData.discountDetailsLoaded = true;
    });
  }

  function loadInvoices(){
    service.smsData.invoicesLoaded = false;
    var res = new $resource(config.api.invoices, {workspace_id: service.workspace.id});
    return res.get().$promise.then(_resolve);
    function _resolve(data){
      service.smsData.invoices = data.invoices;
      service.smsData.invoicesLoaded = true;
      _.forEach(data.invoices, function(invoice){
        invoice.dueDate = dateformat(new Date(invoice.due_date * 1000), "dddd, mmmm dS, yyyy");
        invoice.formattedStatus = utils.string.capitalize(invoice.status).replace('_', ' ');
      });
    }
  }

  function downloadInvoice(invoiceId){
    service.smsData.invoicesLoaded = false;
    var res = new $resource(config.api.invoices, {workspace_id: service.workspace.id});
    return res.save({'invoice_id': invoiceId}).$promise;
  }

  function get_users() {
    var deferred = $q.defer();
    WorkspaceUsers.get().$promise.then(getWorkspaceUsersSuccess);
    function getWorkspaceUsersSuccess(data) {
      service.users = data.users;
      if(service.smsData.current_plan){
        isUserFull(service.smsData.current_plan);
      }
      service.owners_count = 0;

      _.forEach(service.users, function (user) {
        let user_workspaces = user.user_roles.workspaces;
        let current_wksp_role = user_workspaces[service.workspace.id];
        user.primary_user_role = current_wksp_role;

        user.display_name = user.name ? user.name + " - " + user.email : user.email;
        if (user.email.toLowerCase() == service.loggedInEmail.toLowerCase()) {
          service.logged_in_user = user;
          $timeout(function () {
            Raven.setUserContext({
              email: user.email,
              id: user.id
            })
          });
        }
      });

      const currentUser = service.users.find(user => user.id === service.logged_in_user.id)
      const currentWorkspaceRole = currentUser.user_roles.workspaces[service.workspace.id]
      if (currentWorkspaceRole == c.ROLES.WORKSPACE_OWNER) {
        service.isOwner = true;
      }
      else if (currentWorkspaceRole == c.ROLES.WORKSPACE_ADMIN) {
        service.isAdmin = true;
      }
      service.can_manage_users = service.isAdmin || service.isOwner;

      deferred.resolve();
    }
    return deferred.promise;
  }

  function fetch_2FA_uri(renew?){
    var deferred = $q.defer();
    if (renew) {
      var res = $resource(config.api.twoFactorAuth, {renew: '@renew'});
    }
    else{
      res = _2FAResource;
    }

    res.get({renew: renew}).$promise.then(function(data){
      deferred.resolve(data)
    }, deferred.reject);
    return deferred.promise;
  }

  function validate_2FA_OTP(otp, secret){
    var deferred = $q.defer();
    _2FAResource.save({'otp': otp, 'secret': secret}).$promise.then(function(data){
      deferred.resolve(data)
    }, deferred.reject);
    return deferred.promise;

  }

  function modify_password(pwd){
    var deferred = $q.defer();
    var payload =  {'old_password': pwd.oldPassword,
                    'new_password': pwd.newPassword,
                    'confirm_password':pwd.confirmPassword}
    if (pwd.totp){
      payload['totp'] = pwd.totp
    }
    modifyPassword.save(payload).$promise.then(function successCallback(response) {
      deferred.resolve(response)
    },
      function errorCallback(response) {
        deferred.resolve(response)
      });
    return deferred.promise;
  }

  function set_self_details(details){
    var deferred = $q.defer();
    service.selfDetails.saving = true;
    let patches = [];
    if(details.first_name){
      patches.push({op: "replace", path: 'first_name', value: details.first_name});
      _.set(service.selfDetails, 'first_name', details.first_name);
    }
    if(details.last_name){
      patches.push({op: "replace", path: 'last_name', value: details.last_name});
      _.set(service.selfDetails, 'last_name', details.last_name);
    }
    if(details.hasOwnProperty('multi_fact_auth_enabled')){
      patches.push({op: "replace", path: 'multi_fact_auth_enabled', value: details.multi_fact_auth_enabled});
      _.set(service.selfDetails, 'multi_fact_auth_enabled', details.multi_fact_auth_enabled);
    }
    // clear cache
    let cache_key = service.loggedInEmail;
    let cache_bucket = '/self_details/';
    clearCache(cache_bucket, cache_key);
    SelfResource.patch({}, {patch: patches}).$promise.then(function(){
      get_self_details().then(function(data){
        deferred.resolve(data)
      })
    }, deferred.reject);
    return deferred.promise;
  }

  function get_self_details(){
    // use email/user_id for cache key
    if (!service.loggedInEmail){
      console.error("loggedInEmail not set");
      return;
    }
    var deferred = $q.defer();
    let cache_key = service.loggedInEmail;
    let cache_bucket = '/self_details/';
    fetchResource(SelfResource, cache_bucket, cache_key).then(_get_ui_preferences, error_callback);
    function _get_ui_preferences(data) {
      service.selfDetails = data.self;
      selfDetailsLoadedEvent.fire_event();
      deferred.resolve(data)
    }
    function error_callback(data){
      if(data.data.ERROR_CODE == 39 ){
        Notification.error("You don't have a Mammoth Analytics workspace linked to this Google ID / Mail. Please register user first on register page!")
        setTimeout(function(){ keyCloak.logout() }, 3000);
      }
    }
    return deferred.promise;
  }

  function patchWorkspace(details){
    var deferred = $q.defer();
    service.workspace.saving = true;
    let AccRes = $resource(config.api.workspaceApi, {workspace_id: service.workspace.id});
    let patches = [];
    if(details.name){
      patches.push({op: "replace", path: 'name', value: details.name});
      _.set(service.workspace, 'name', details.name);
    }
    if (details.hasOwnProperty('update_2fa_status')){
      patches.push({op: "replace",path: 'update_2fa_status', value: details.update_2fa_status});
    }
    AccRes.patch({}, {patch: patches}).$promise.then(function(patchResponse){
      loadWorkspace().then(function(data){
        deferred.resolve(data)
      })
    });
    return deferred.promise;
  }

  async function setup2FA(){
    await keyCloak.updateToken(300)
    keycloak2FA.save().$promise.then(function successCallback(response) {
      if(response.STATUS == "SUCCESS"){
        window.open(window.location.href, "2FA", "width=1080,height=700");
      }
      else if(response.STATUS == "FAILURE"){
        $window.location.reload()
      }
    },
      function errorCallback(response) {
        $window.location.reload()
      });
    }

  function is2FAEnabled(){
    var deferred = $q.defer();
    keycloak2FA.get().$promise.then(function(response) {
      deferred.resolve(response)
    });
      return deferred.promise
    }


  function loadWorkspace(){
    var deferred = $q.defer();
    let AccRes = $resource(config.api.workspaceApi, {workspace_id: service.workspace.id});
    AccRes.get().$promise.then(_cb);
    function _cb(data) {
      service.workspace = data.workspace;
      deferred.resolve(data.workspace)
    }
    return deferred.promise;
  }

  function get_ui_preferences() {
    let cache_key = service.loggedInEmail;
    let cache_bucket = '/ui_preferences/';
    fetchResource(UIPreferences, cache_bucket, cache_key).then(_get_ui_preferences);
    function _get_ui_preferences(data) {
      service.ui_preferences = data;
      service.ui_preferences['inited'] = true;
      uiPreferencesUpdatedEvent.fire_event();
    }
  }

  function clearCache(cache_bucket: string, cache_key: string) {
    let cache = CacheFactory.get(cache_bucket);
    if (cache) {
      cache.remove(cache_key);
    }
  }

  function set_ui_preferences(path, value, retryCount) {
    $timeout(function () {
      _.set(service.ui_preferences, path, value);
    });
    // clear cache
    let cache_key = service.loggedInEmail;
    let cache_bucket = '/ui_preferences/';
    clearCache(cache_bucket, cache_key);
    UIPreferences.patch({}, {patch: [{op: "replace", path: path, value: value}]}).$promise.then(
        get_ui_preferences, handleServerError);
    if(retryCount === undefined){
      retryCount = 2;
    }
    else{
      retryCount = retryCount - 1;
    }
    function handleServerError(e, data){
      if(retryCount > 0 && e.status == 403){
        $timeout(_retry, 2000);
      }

      function _retry(){
        set_ui_preferences(path, value, retryCount);
      }
    }
  }

  /**
   * This method resets the service
   */
  function reset(){
    service._workspace_list_inited = false;
    service.workspaces = [];
    service.workspace = null;
    service.is_super_admin = false;
  }
  /**
  * The following function determines whether the access to given workspace is permissible to given user or not
  * @param user_details
  * @param workspace_details
  * @returns true/false
  */
  function isWorkspaceAccessible(user_details, workspace_details){
    if(!workspace_details.two_factor_auth_enabled){
      return true
    }
    else if(workspace_details.two_factor_auth_enabled){
      if (user_details.has_two_factor_auth_secret){
        return true
      }else{
        // Convert user created, two_factor_auth_last_date from UTC to local time
        var user_creation_datetime = new Date(Date.parse(user_details.created_at.replace(' ', 'T')+'Z'));
        var last_date_time_string = _.clone(workspace_details.two_factor_auth_last_date)
        var last_allowed_datetime = new Date(Date.parse(last_date_time_string.replace(' ', 'T')+'Z'))
        //  Get current date in local time
        var currentDate = new Date()
        // Newly created user can access workspace for 2 days from creation even if he has not setup 2FA
        user_creation_datetime.setDate(user_creation_datetime.getDate()+2)
        if(currentDate < last_allowed_datetime){
          return true
        }
        else if(currentDate > last_allowed_datetime && currentDate < user_creation_datetime){
          return true
        }
      }
    }

  }

  /**
   * This method will select the workspace and reload the page.
   *
   * @param workspace
   * @param reload
     */
  function select_workspace(workspace) {
    if(service.workspace != workspace){
      var isAccAccessible = isWorkspaceAccessible(service.selfDetails, workspace)
      if (!isAccAccessible){
        toastNotification.error('You cannot access the workspace - '+ workspace.name + ' unless two-factor authentication is set up');
        return 'not_accessible'
      }
      service.workspace = workspace;
      featureAvailability.init(workspace.subscription, c.splitFeatureFlags);
      $http.defaults.headers.common[config.headers.WORKSPACE_ID] = service.workspace.id;
      axios.defaults.headers[config.headers.WORKSPACE_ID] = service.workspace.id;
      $store.commit('setWorkspaceId', service.workspace.id)
      localService.storage.workspace_id = service.workspace.id;

      analyticsService.setSuperProperties({
        workspaceName: service.workspace.name,
        userRole: service.workspace.role
      });
      analyticsService.userEventTrack(c.userEvents.workspaceSelected);

      WorkspaceUsers = $resource(config.api.workspaceUsers, {'workspace_id': service.workspace.id});
      debouncedLoad();
    }
  }

  function loadDetailsAsync(){
    $timeout(getSmsData);
    $timeout(get_self_details);
    $timeout(getCurrentUsage);
    $timeout(function () {
      Raven.setExtraContext({
      workspaceId: service.workspace.id
      });
    })
  }

  /**
   * Auto select mechanism: Not very thought out because this is not that
   * important in the short term.
   *
   * 1. After log in, the workspace is selected by selecting the first workspace in
   * a list of workspaces where the user is a member.
   * 2. If the user was already logged in at page load and had an workspace selected,
   * then the workspace selected is the one whose ID is stored int the session storage.
   *
   *
   * @returns {*}
   */

  function auto_select_workspace(force_refetch) {
    var auto_sel_defr = $q.defer();
    if(service._workspace_list_inited && !force_refetch){
       auto_select_cb()
    }
    else{
      get_workspaces().then(auto_select_cb);
    }
    return auto_sel_defr.promise;

    /**
     * This method will go through list of workspaces and select an workspace from
     * the list. It will look at localService.storage to see if an workspace was
     * previously selected. If so, it will choose that workspace.
     * If that is not the case, the first workspace in
     * the list of workspaces will be selected.
     */
    function auto_select_cb () {
      let workspaceId = $store.state.workspaceId;
      
      // check for workspaceId in url
      // $stateParams here does not contain the params so reading from window.location
      const urlHash = window.location.hash;
      if (urlHash.includes("workspaces") && urlHash.split("/").length > 2) {
          let urlWorkspaceId = urlHash.split("/")[2];
          if(Number(urlWorkspaceId) > 0) workspaceId = urlWorkspaceId
      }

      var deferred = $q.defer();
      var workspace;
      var resp;
      if (service.workspaces.length == 0) {
        auto_sel_defr.reject({"no_workspace": true});
      }
      service.workspaces.forEach(function (a) {
        if (a.id == workspaceId) {
          workspace = a;
          resp = select_workspace(workspace);
          if (resp == 'not_accessible'){
            workspace = undefined
          }
        }
      });
      var cnt=0;
      while (!workspace && cnt<service.workspaces.length) {
        workspace = service.workspaces[cnt]

        resp = select_workspace(workspace);
        if (resp == 'not_accessible'){
          workspace = undefined
        }
        cnt+=1;
      }
      get_users().then(() => {
        workspaceSelectedEvent.fire_event()
        auto_sel_defr.resolve();
      });
      deferred.resolve();
      return deferred.promise;
    }
  }

  function add_user_to_workspace(email, role_name, first_name, last_name) {
    var req = WorkspaceUsers.save({'email': email, 'role_name': role_name, first_name: first_name, last_name: last_name});
    return req.$promise;
  }

  function remove_user_from_workspace(user_id) {
    var user = new WorkspaceUsers();
    var req = user.$delete({'user_id': user_id});
    return req;
  }

  function change_resources_ownership(user_id, new_owner_user_id) {
    var req = WorkspaceUsers.patch({'user_id': user_id, 'prevent_last_owner': true}, {patch: [{op: "replace", path: "owner_id", value: new_owner_user_id}]});
    return req.$promise;
  }

  function change_user_role(user_id, new_role) {
    var req = WorkspaceUsers.patch({'user_id': user_id}, {patch: [{op: "replace", path: "user_roles",
      value: new_role}]});
    return req.$promise;
  }

  function extractUsageInfo(data: any) {
    let usage_info = {
      storageUnits: {
        limit:null,
        used: 0
      },
      computeUnits: {
        limit:null,
        used: 0
      },
      storageInBytes:
      {
        limit: null,
        used: 0,
        used_percent: 0,
        used_percent_indicator: ''
      }
    };
    let cellSizeEstimateInBytes = 16;
    let millisecondsInMonth = 3600 * 24 * 31 * 1000;
    let d1 = new Date(data?.start_date_su);
    let d2 = new Date();
    let ddiff = d2.getTime() - d1.getTime();
    if (ddiff > millisecondsInMonth) {
      ddiff = millisecondsInMonth;
    }
    let daysSinceBilling = (ddiff) / (24 * 3600 * 1000);
    let cellWeightSU = cellSizeEstimateInBytes * daysSinceBilling / 31;
    let cellWeightCU = cellSizeEstimateInBytes;
    if (data?.hasOwnProperty("STORAGE_UNIT" && data.STORAGE_UNIT > 0)){
      usage_info.storageUnits.used = getInt(data.STORAGE_UNIT / 1000 * cellWeightSU);
    }
    if (data?.hasOwnProperty("COMPUTE_UNIT" && data.COMPUTE_UNIT > 0)){
      usage_info.computeUnits.used = getInt(data.COMPUTE_UNIT * cellWeightCU);
    }

    usage_info.storageInBytes.limit = data?.PLAN_STORAGE_VALUE;
    usage_info.storageInBytes.used = data?.STORAGE_USED ?  data.STORAGE_USED :  0;
    usage_info.storageInBytes.used_percent = data?.PLAN_STORAGE_VALUE ? (100 * usage_info.storageInBytes.used)/usage_info.storageInBytes.limit : 0;
    usage_info.storageInBytes.used_percent_indicator = get_used_percent_indicator(usage_info.storageInBytes.used_percent);
    return usage_info
  }

  function getInt(arg: any){
      return Math.round(arg * 100)/100;
    }

  function getCurrentUsage() {
    if(service.workspace.id === -1 && service.logged_in_user.id === -1) return
    var deferred = $q.defer();
    var res = new $resource(config.api.appUsage, {workspace_id: service.workspace.id, user_id: service.logged_in_user.id});
    res.get(
      function (data: any) {
        const usage = transformUsageInfo({data})
        if (usage.workspace.allowedUsage != null) $store.commit('setAllowedWorkspaceUsage', usage.workspace.allowedUsage)
        if (usage.workspace.currentUsage != null) $store.commit('setCurrentWorkspaceUsage', usage.workspace.currentUsage)

        service.usageData.self = extractUsageInfo(data.self);
        service.usageData.workspace = extractUsageInfo(data.workspace);
        deferred.resolve(service.usageData);
      },
      deferred.reject);
    return deferred.promise;
  }

}
