import * as $ from 'jquery';

/**
 * @ngInject
 *
 */
summaryMenu.$inject = ['clickEvents', '$timeout', 'utils', 'config'];
export function summaryMenu(clickEvents, $timeout, utils, config) {
  return {
    controller: config.controllers.columnMenu,
    controllerAs: 'sm',
    bindToController: {
      gridUnit: "=",
      taskPanel: "=",
      dataview: "=",
      elementPanel: "=",
      menuType: "=",
      summaryService: "="
    },
    link: function cellMenuViewLink(scope, element, attrs) {
      var randomId = utils.string.random(10);
      scope.sm.summaryService.summaryMenu.onSelect('repositionMenuLogic', _repositionMenu);
      var unsubscribeClickEvents = clickEvents.onClick('deselectCellMenuOnOutsideClick' + randomId , deselectMenuOnOutsideClick);

      scope.$on('$destroy', destroy);

      function _repositionMenu(target, colEle: any) {
        var colEle: any = $(colEle);
        var colSummaryPanel = colEle.closest('.col-summary-panel');
        var gridPanelWidth = colSummaryPanel.width();
        var menuWidth = $(element).width();
        var top = colEle.find('.summary-wrap').first().position().top + colSummaryPanel.position().top + 27;
        var left = colEle.position().left + colEle.width() - 100;
        if (left + menuWidth > gridPanelWidth) {
          left = left - menuWidth + 40;
          $(element).addClass('left-col-menu')
        } else {
          $(element).removeClass('left-col-menu')
        }
        $(element).css('right', '').css('top', top).css('left', left);

      }

      function deselectMenuOnOutsideClick(e) {
        if (scope.sm.summaryService.summaryMenu.visible) {
          var target = $(e.target);
          if (!target.closest('.summary-menu-container').length) {
            scope.sm.summaryService.summaryMenu.hide();
          }
        }
      }

      function destroy() {
        unsubscribeClickEvents();
      }
    }
  }
}

