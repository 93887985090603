import * as _ from 'lodash-es';
import { ILabelService } from './types/core';

export class folderPickerController {
  public initialLabelView;
  public mode;
  public modeInfo;
  public currentLabelView;
  public currentSelection;
  public selectedResource;
  public isMenuOpen;
  public items;
  private isSelectLocked = false;

  private modes = {
    'move': {'submitLabel': 'MOVE HERE', 'origin': 'landingPage.previewPanel', submitMethod: () => this.move()},
    'select-folder': {'submitLabel': 'CHOOSE', 'origin': 'addDSModal', submitMethod: () => this.selectFolder()}
  };

  static $inject = ['landingList', 'c', 'modalService', 'labelService', 'landingSelection', '$timeout', 'analyticsService'];
  public $onInit: () => void;

  public constructor(public landingList, public c, private modalService, private labelService: ILabelService,
                     private landingSelection, private $timeout, public analyticsService) {
    this.$onInit =function(){
      if (!this.initialLabelView) {
        this.initialLabelView = this.landingList.labelViewMap[this.c.HOME];
      }
      this.mode = this.mode || 'move';
      this.modeInfo = this.modes[this.mode];
      this.currentLabelView = this.initialLabelView;
      if (this.mode != 'move' && this.selectedResource && this.selectedResource.type == 'label') {
        this.currentSelection = this.landingList.labelViewMap[this.selectedResource.resource_id];
      }
    }
  }

  public goTo(labelView) {
    this.isSelectLocked = true;
    this.currentLabelView = labelView;
    this.currentSelection = undefined;
    this.$timeout(() => { this.isSelectLocked = false }, 200);
  }

  public selectDestination(labelView) {
    if (this.isSelectLocked) {
      return;
    }
    this.currentSelection = labelView;
  }

  public toggleMenu(setToOpen) {
    this.isMenuOpen = setToOpen === undefined ? !this.isMenuOpen : !!setToOpen;
  };

  public createNewFolder() {
    this.analyticsService.userEventTrack(this.c.userEvents.openNewFolderModal, { eventOrigin: "folderPicker"});
    const parentRID = this.currentLabelView.label ? this.currentLabelView.label.resource_id : undefined;
    this.modalService.createLabel(parentRID)
  }

  public submit(origin?) {
    this.analyticsService.userEventTrack(this.c.userEvents.moveToFolder, { eventOrigin: origin+".folderPicker"});
    this.modeInfo.submitMethod();
  }

  public move() {
    if (!this.items || !this.items.length) {
      return;
    }
    if (this.items.indexOf(this.currentSelection.label) !== -1) {
      return;
    }
    const resourceRIDs: number[] = _.map(this.items, 'resource_id');
    const labelRID = this.currentSelection.label ? this.currentSelection.label.resource_id : undefined;
    this.labelService.move(labelRID, resourceRIDs);
    this.landingSelection.clearSelection();
  }

  public selectFolder() {
    this.selectedResource = this.currentSelection.label ? this.currentSelection.label : undefined;
    this.isMenuOpen = false;
  }
}
