/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
/**
 * @ngInject
 */
chartRenderController.$inject = ['utils', 'c', '$timeout', 'elementConfig'];
export function chartRenderController(utils, c, $timeout, elementConfig){
  this.$onInit = function () {
    var crvm = this,
      unsubscribeOnUpdate,
      unsubscribeOnRootUpdate,
      unsubscribeOnDisplayUpdate,
      controllerId = utils.getRandomString(10);

    crvm.renderer = null;
    crvm.registerRenderer = registerRenderer;
    crvm.dateTruncateExtractOptions = c.dateTruncateExtractOptions;
    crvm.truncateExtractInfo = null;
    crvm.query = _query;
    initTruncateInfo();

    function initTruncateInfo() {
      if (crvm.element.display_properties.truncateExtractInfo) {
        $.each(crvm.dateTruncateExtractOptions, function (i, t) {
          if (angular.equals(t.display, crvm.element.display_properties.truncateExtractInfo.display) &&
            angular.equals(t.param, crvm.element.display_properties.truncateExtractInfo.param) &&
            angular.equals(t.value, crvm.element.display_properties.truncateExtractInfo.value)) {
            crvm.truncateExtractInfo = t;
          }
        })
      }
    }

    function _query(displayOnly?) {
      if (displayOnly && crvm.element.display_properties.chartType == c.chartTypes.xy && !crvm.element.display_properties.yAxisBreakDown) {
        crvm.renderer({
          display_properties: _.cloneDeep(crvm.element.display_properties),
          displayOnly: true
        });
      } else {
        crvm.processing = true;

        crvm.element.row_limit = elementConfig.rowLimits.chart;
        var condition = crvm.condition;
        if (crvm.element.display_properties.isDrilldown && crvm.drilldownManager) {
          if (!crvm.drilldownManager.checkIfValuesSelected(crvm.element.id)) {
            $timeout(crvm.query, 500);
            crvm.processing = false;
            return;
          }
          condition = crvm.drilldownManager.addDrilldownConditionToExisting(crvm.element, condition);
        }
        var extra_group_by = null;
        var queryDisplayProperties = null;
        if (crvm.element.display_properties.truncateExtractInfo) {
          extra_group_by = {COLUMN: crvm.element.display_properties.labelColumn};
          // TODO: SENTRYERROR:FRONTEND-FJ:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/449886408
          angular.merge(extra_group_by, crvm.truncateExtractInfo.param);
          queryDisplayProperties = {
            FORMAT_INFO: _.cloneDeep(crvm.element.display_properties.FORMAT_INFO),
            SORT: _.cloneDeep(crvm.element.display_properties.SORT)
          };
          queryDisplayProperties.FORMAT_INFO[crvm.element.display_properties.labelColumn] = crvm.truncateExtractInfo.value;
          if (crvm.truncateExtractInfo.param.hasOwnProperty('COMPONENT')) {
            if (queryDisplayProperties.SORT[0][0] == crvm.element.display_properties.labelColumn) {
              queryDisplayProperties.SORT[0][2] = crvm.truncateExtractInfo.param.COMPONENT;
            }
          } else {
            if (queryDisplayProperties.SORT[0].length > 2) {
              queryDisplayProperties.SORT[0].pop();
            }

          }
        }
        crvm.element.query(condition, extra_group_by, queryDisplayProperties).then(_data_cb);
      }
    }

    function _prepare_data_metadata_display_properties(data, metadata, display_properties) {
      if (display_properties.chartType === c.chartTypes.xy && display_properties.yAxisBreakDown) {
        var seriesInfoScaffold = display_properties.series[0];
        var newSeriesInfo = [];

        $.each(metadata, function (i: any, c) {
          if (i > 0) {
            var newInfo = _.cloneDeep(seriesInfoScaffold);
            newInfo.internal_name = c.internal_name;
            newInfo.name = c.display_name;
            newSeriesInfo.push(newInfo);
          }
        });

        display_properties.series = newSeriesInfo;
        display_properties.yAxisTitle = seriesInfoScaffold.name;
        return {
          metadata: metadata,
          data: data,
          display_properties: display_properties
        }
      } else if (display_properties.hasOwnProperty('simpleChart') && display_properties.simpleChart) {
        var newData = [];
        for (var i = 0; i < metadata.length; i++) {
          newData.push({
            'x_axis': metadata[i]['display_name'],
            'y_axis': data[0][metadata[i]['internal_name']]
          })
        }
        return {
          metadata: display_properties.metadata,
          data: newData,
          display_properties: display_properties
        };
      } else {
        return {
          metadata: metadata,
          data: data,
          display_properties: display_properties
        }
      }

    }

    function _data_cb(data) {
      if (data.STATUS == 'READY') {
        var chartOptions = _prepare_data_metadata_display_properties(
          data.data, data.metadata,
          _.cloneDeep(crvm.element.display_properties));
        crvm.renderer(chartOptions);
      }
      crvm.processing = false;
    }

    function registerRenderer(renderer) {
      crvm.renderer = renderer;
    }

    crvm.handleDisplayUpdated = function (displayProperties) {
      crvm.element.display_properties = displayProperties;
      crvm.element.edit(undefined, displayProperties);
      crvm.element.displayUpdated();
    }

    if (crvm.element.onUpdate) {
      unsubscribeOnUpdate = crvm.element.onUpdate("elementDirectiveUpdate" + controllerId, function (paramUnchanged) {
        initTruncateInfo();
        crvm.query(paramUnchanged && crvm.element.status == 'ready');
      });
    }
    if (crvm.element.onDisplayUpdate) {
      unsubscribeOnDisplayUpdate = crvm.element.onDisplayUpdate("elementDirectiveDisplayUpdate" + controllerId, function () {
        // TODO: SENTRYERROR:FRONTEND-FJ:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/449886408
        crvm.query(true);
      });
    }
    if (crvm.element.display_properties.isDrilldown && crvm.drilldownManager) {
      unsubscribeOnRootUpdate = crvm.drilldownManager.rootElement.onDisplayUpdate("updatedrilldown" + controllerId, function () {
        crvm.renderer();
      });

      crvm.drilldownManager.onValueUpdated("updateDrilldown" + controllerId, function () {
        _query();
      })
    }
    _query();

    crvm.destroy = function () {
      typeof unsubscribeOnRootUpdate === "function" && unsubscribeOnRootUpdate();
      typeof unsubscribeOnUpdate === "function" && unsubscribeOnUpdate();
      typeof unsubscribeOnDisplayUpdate === "function" && unsubscribeOnDisplayUpdate();
    };
  }
}

