import * as $ from 'jquery';
import * as angular from 'angular';
import * as _ from 'lodash-es';


/**
 * @ngInject
 *
 * Generic column menu implementation, requires gridUnit instance
 */
colMenu.$inject = ['clickEvents', '$timeout', 'utils', 'resources', 'TaskServiceFactory', 'ActionServiceFactory', 'oldElementServiceFactory', 'c'];
export function colMenu(clickEvents, $timeout, utils, resources, TaskServiceFactory, ActionServiceFactory, oldElementServiceFactory, c) {
  return {
    link: function colMenuViewLink(scope, element, attrs, ctrl) {
      var gridUnit = scope.$eval(attrs.colMenu), grid, lastKnownScrollLeft;
      if (gridUnit.gridInstance && gridUnit.gridInstance.grid) {
        subscribeListeners();
      } else {
        gridUnit.onGridInit('subscribeColumnMenuListeners', subscribeListeners);
      }
      gridUnit.columnMenu.onSelectColumn('columnSelectionViewLogic', handleColumnSelect);
      clickEvents.onClick('deselectColMenuOnOutsideClick' + gridUnit.dataview.id, deselectColMenuOnOutsideClick);
      clickEvents.onRightClick('deselectColMenuOnOutsideRightClick' + gridUnit.dataview.id, deselectColMenuOnOutsideClick);

      var TaskService = TaskServiceFactory.get_by_dataview_id(gridUnit.dataview.id);
      var actionService = ActionServiceFactory.get_by_dataview_id(gridUnit.dataview.id);
      var elementService = oldElementServiceFactory.getByDataviewId(gridUnit.dataview.id);


      //  close menu on escape key press
      $(document).on("keydown", this, function (e) {
          var keycode = ((typeof e.keyCode !='undefined' && e.keyCode) ? e.keyCode : e.which);
          if (keycode === 27) {
            deselectColMenuOnOutsideClick(e);
          };
      });

      // select column for Column Menu logic
      function handleColumnSelect(columnId, noDelay, menuType?) {
        var _wasVisible = gridUnit.columnMenu.visible;
        menuType = menuType || 'tasks';
        if (gridUnit.columnMenu.visible) {
          var oldColumnId = gridUnit.columnMenu.columnId;
          var oldMenuType = gridUnit.columnMenu.menuType || 'tasks';
          gridUnit.columnMenu.hide();
          if (_wasVisible && columnId && oldColumnId && (oldColumnId == columnId) && (menuType == oldMenuType)) {
            return;
          }
        }
        if (columnId) {
          gridUnit.columnMenu.columnId = columnId;
        }
        gridUnit.columnMenu.menuType = menuType || 'tasks';
        $timeout(function () {
          if (_repositionMenu(columnId)) {
            gridUnit.columnMenu._fireOnColumnSelected(columnId);
            gridUnit.columnMenu.show();
          }
        }, (_wasVisible && !noDelay ? 150 : 0));

      }

      // Repositioning Logic
      function _repositionMenu(columnId, preventAutoScroll = false) {
        var cell: any = {}, viewPort;
        var lastKnownColumnId = columnId || gridUnit.columnMenu.columnId;
        if (!lastKnownColumnId) {
          return false;
        }
        grid = gridUnit.gridInstance.grid;
        viewPort = grid.getViewport();
        cell.row = viewPort.top;
        cell.column = grid.getColumnIndex(lastKnownColumnId);
        cell.node = $(grid.getCellNode(cell.row, cell.column));
        if (!cell.column) {
          return false;
        }
        if (cell.column && !cell.node.length) {
          if (!preventAutoScroll) {
            grid.scrollCellIntoView(cell.row, cell.column);
            $timeout(function () {
              handleColumnSelect(columnId, true);
            }, 500);
          }
          return false;
        }
        cell.position = cell.node.position();
        cell.width = cell.node.width();

        // if cell is not in visible range do not show the menu
        if (((cell.position.left + cell.width) < viewPort.leftPx) || cell.position.left > viewPort.rightPx) {
          if (!preventAutoScroll) {
            grid.scrollCellIntoView(cell.row, cell.column);
            $timeout(function () {
              handleColumnSelect(columnId, true);
            }, 200);
          }
          return false;
        }
        if (_checkIfMenuFitsOnTheRight(cell, viewPort)) {
          element.css('right', '');
          element.css('left', (cell.position.left - viewPort.leftPx) + cell.width + 25);
        } else {
          element.css('left', '');
          element.css('right', (viewPort.rightPx - cell.position.left));
        }
        lastKnownScrollLeft = viewPort.leftPx;
        return true;
      }

      function _checkIfMenuFitsOnTheRight(_cell, _viewPort) {
        // 60px is an empirical offset because there were some issues here
        return (_cell.position.left + _cell.width + 110 + element.outerWidth()) < _viewPort.rightPx;
      }


      // subscribe listeners
      function subscribeListeners() {
        gridUnit.gridInstance.grid.onScroll.subscribe(scrollHandler);
        gridUnit.gridInstance.grid.onColumnsResized.subscribe(scrollHandler);
        gridUnit.gridInstance.grid.onColumnsReordered.subscribe(scrollHandler);
      }

      // scroll handler
      var _debouncedRepositionOnScroll = utils.debounce(_repositionIfAlreadyVisible, 300);
      var _debouncedRepositionOnScrollLeadingEdge = utils.debounce(_repositionIfAlreadyVisible, 500, true);

      function scrollHandler(e, data) {
        if (gridUnit.columnMenu.visible) {
          if (data.scrollLeft !== lastKnownScrollLeft) {
            $timeout(function () {
              gridUnit.columnMenu._inViewPort = false;
            });
            _debouncedRepositionOnScrollLeadingEdge();
            _debouncedRepositionOnScroll();
          }
        }
      }

      function _repositionIfAlreadyVisible() {
        $timeout(function () {
          gridUnit.columnMenu._inViewPort = _repositionMenu(undefined, true);
        })
      }

      // deselect col menu on outside click
      function deselectColMenuOnOutsideClick(e) {
        if (gridUnit.columnMenu.visible) {
          var target = $(e.target);
          if (!target.closest('.col-menu-container').length && !target.closest('.slick-header-column-menubutton').length
            && !gridUnit.gridInstance.columnHeaderLock() && !target.closest('.flatpickr-day').length
            && !target.closest('.flatpickr-calendar').length && !target.closest('.date-format-menu').length) {
            gridUnit.columnMenu.hide();
          }
        }
      }

      function _unhighlightClasses() {
        gridUnit.gridInstance.plugins.columnHighlighter.unhighlightByClasses(['menu-highlight',
          'tasks-menu-highlight',
          'metrics-menu-highlight',
          'text-end',
          'text-monospace']);
      }

      // This recursion is quite tricky since there are a few possibilities we consider here.
      // Existing column name should not be considered while renaming with incremental integer
      function renameIfNameAlreadyExists(updated_column_header, existing_col_name, i=1){
        var temp_header = updated_column_header + ' ' + i;
        let metadata = _.cloneDeep(gridUnit.dataview.metadata);

        if(existing_col_name){
          for(var j = 0; j < metadata.length; j++) {
            if(metadata[j].display_name == existing_col_name) {
              metadata.splice(j, 1);
              break;
            }
          }
        }

        var nameExists = utils.isKeyValuePairPresentInObject('display_name', temp_header, metadata);
        if(!nameExists){
          return temp_header;
        }
        else {
          i++;
          // Passing undefined for existing column name here because the check needs to be done only once
          return renameIfNameAlreadyExists(updated_column_header, undefined, i);
        }
      }

      function _postRenameAction(id, col_header_input, existing_col_name) {
        // The syntax below: HTML element cannot have the attribute value, typescript is type specific.
        var updated_column_header = utils.sanitizeName((<HTMLInputElement>document.getElementById(id)).value);

        var header_id = col_header_input.dataset.colHeaderId;

        if(updated_column_header==""){
          updated_column_header = existing_col_name;
        }

        // check if the updated column header uses any reserved col names
        let reservedColumnNameUsed = c.RESERVED_BATCH_COLUMN_NAMES.findIndex(item => 
          updated_column_header.toLowerCase() === item.toLowerCase()) != -1;

        // rename updated_column_header if it is using any reserved column names
        // TIP: rename only if column_name is changing
        if(reservedColumnNameUsed && (updated_column_header != existing_col_name)) {
          updated_column_header = updated_column_header + ' ' + 2;
        }

        const nameAlreadyExists = utils.isKeyValuePairPresentInObject('display_name', updated_column_header, gridUnit.dataview.metadata);

        if(nameAlreadyExists && updated_column_header != existing_col_name) {
          updated_column_header = renameIfNameAlreadyExists(updated_column_header, existing_col_name);
        }

        // if an attempt to renam a batch column is made, just dont allow that, retain the old display_name
        if(c.RESERVED_BATCH_COLUMN_NAMES.findIndex(item => existing_col_name.toLowerCase() === item.toLowerCase()) != -1) {
          updated_column_header = existing_col_name;
        }

        // Update column header with the value and rerender grid
        grid.updateColumnHeader(header_id, updated_column_header);
        // Firing the column menu hide event overrides the focus on input,
        // hence manually hiding the column menu and resetting classes in colmenu controller, and unhighlighting columns here
        _unhighlightClasses();
        var rerender = gridUnit.gridInstance.plugins.columnHighlighter; //To access the rerender function
        rerender.renderAll();
        _updateHeaderData(header_id, updated_column_header);
        // Refer MVP-3177
        updateHeaderInGrid(gridUnit.dataview.display_properties.COLUMN_NAMES[header_id], header_id);
      }

      function _updateHeaderData(header_id, updated_column_header) {
        let patch_updated_col = gridUnit.dataview.display_properties.COLUMN_NAMES || {};
        patch_updated_col[header_id] = updated_column_header;
        gridUnit.dataview.display_properties.COLUMN_NAMES = patch_updated_col;
        gridUnit.dataview.setDisplayProperties(gridUnit.dataview.display_properties).then(function () {
          resources.update();
          // Update Col names in task pipeline
          TaskService.updateTasksDescription();
          // Update Col names as used in actions
          actionService.update_list();
        });
      }

      function updateHeaderInGrid(new_col_title, header_id){
        let updated_columns = gridUnit.gridInstance.grid.getColumns();
        for (const col_index in updated_columns) {
          if (updated_columns.hasOwnProperty(col_index)) {
            let column = updated_columns[col_index];
            if (column.internal_name == header_id){
              column.name = new_col_title;
            }
          }
        }
        gridUnit.gridInstance.grid.setColumns(updated_columns);
      }

      // TODO: Move the listener below and associated methods to a more approptiate location- Dataview directives
      // Listens for column header rename event to be fired, and applies the new column name to grid and sends a patch request
      gridUnit.columnMenu.onColHeaderRenameEvent("column_header_rename", function (id, existing_col_name) {
        // grid is not defined until column menu is opened at least once
        if (!grid) {
          grid = gridUnit.gridInstance.grid;
        }

        var col_header_input = document.getElementById(id);

        col_header_input.addEventListener('keypress', function (e) {
          if (e.key === 'Enter') {
            _postRenameAction(id, col_header_input, existing_col_name);
          }
        });

        col_header_input.addEventListener('blur', () => {
          _postRenameAction(id, col_header_input, existing_col_name);
        });
      });
    }
  };
}




