import * as angular from 'angular';
import {config} from "../common/app.config";
import _ = require('lodash');
/**
 * @ngInject
 */
CombineDsCtrl.$inject = ['$scope', 'DatasourceService', '$http', 'DSBatchService', '$uibModalInstance', 'analyticsService',
                          'c', '$uibModal','$rootScope', 'toastNotification', '$q', 'FutureService'];
export function CombineDsCtrl($scope, DatasourceService, $http, DSBatchService, $uibModalInstance, analyticsService,
                              c, $uibModal, $rootScope, toastNotification, $q, FutureService) {
  this.$onInit = function () {
    var mvm = this;
    mvm.step = 1;
    mvm.ds1 = $scope.ds;
    mvm.ds2 = null;
    mvm.destinationDsList = [$scope.ds, null];
    mvm.destinationDs = mvm.ds1;
    mvm.dsList = dsList();
    mvm.sourceToDestActionMap = {};
    mvm.actionMaps = [];
    mvm.sourceToDestMap = {};
    mvm.getColumnType = getColumnType;
    mvm.isTypeMismatch = isTypeMismatch;
    mvm.isMappingValid = false;
    mvm.serverSideValidationDone = false;
    mvm.mappingaction = [
      {'val': 'retain_col_type', 'display': 'Retain column type to'},
      {'val': 'change_col_type', 'display': 'Change column type to'}];
    mvm.selectDatasource = selectDatasource;
    mvm.getMetadata = getMetadata;
    mvm.compareMetadata = _compareMetadata;
    mvm.onNext = onNext;
    mvm.filterMappings = filterMappings;
    mvm.columnMappingSearch;
    mvm.onPrev = onPrev;
    mvm.onSubmit = onSubmit;
    mvm.selectAndValidate = selectAndValidate;
    mvm.delete_source_ds = true;
    mvm.replace = false;
    mvm.areOnlyUnmappedColumnsVisible = false;
    mvm.toggleUnMappedColumnFilter = toggleUnMappedColumnFilter;
    mvm.newDsParams = {
      name: ""
    };
    let batch_col_display_names = c.batch_table_colums_list.map(function (i) {
      return i.display_name;
    });
    let batch_col_internal_names_ds1 = mvm.ds1.metadata.reduce(function (acc, curr) {
      if (batch_col_display_names.includes(curr.display_name)) {
        acc.push(curr["internal_name"]);
      }
      return acc;
    }, []);

    mvm.ds1.ds_original_columns = mvm.ds1.metadata.filter(function (column_info) {
      return batch_col_internal_names_ds1.indexOf(column_info.internal_name) == -1
    })
    sortColumnsByDisplayName()
    mvm.batch_grid_loader = {
      show: true,
      hideOverlay: true
    };

    mvm.toggleBatchGridLoader = function (is_true) {
      toggleGridLoader(mvm.batch_grid_loader, is_true);
    };

    function toggleGridLoader(obj, is_true) {
      if (is_true !== undefined) {
        obj.show = !!is_true;
        obj.hideOverlay = !!is_true;
      } else {
        obj.show = !obj.show;
        obj.hideOverlay = !obj.hideOverlay;
      }
    }

    function selectDatasource(ds) {
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.combineData.combineDSSelect, {eventOrigin: "landingPage.previewPanel"});
      mvm.ds2 = ds;
      
      mvm.ds2.dataUrl = config.api.datasourcesRoot + '/' + mvm.ds2.id;
    }

    function getMetadata(ds) {
      var deferred = $q.defer();
      var dataUrl = config.api.datasourcesRoot + '/' + ds.id;
      $http.get(dataUrl, {
        "params": {
          "offset": 1,
          "limit": 2
        }
      }).then(function (resp) {
        let response = resp.data;
        get_data_tracker(response).then(function (data) {
          ds.metadata = data.metadata;
          _initializeSourceToDestMap(mvm.ds1.metadata);
          _extractColumnsByType(mvm.ds2.metadata);
          _compareMetadata(mvm.ds1.metadata, mvm.ds2.metadata);
          deferred.resolve(data);
        });
      }, deferred.reject);
      return deferred.promise;
    }

    function get_data_tracker(data) {
      var deferred = $q.defer();
      FutureService.track(data.future_id, data_tracker);

      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }

      return deferred.promise;
    }

    function _compareMetadata(meta1, meta2) {
      //meta1 destination and meta2 source
      if (!(meta1 && meta2))
        return;

      angular.forEach(meta1, function (colMetadata1) {
        angular.forEach(meta2, function (colMetadata2) {
          if (!mvm.sourceToDestMap[colMetadata1.internal_name]) {
            //For which display names match and source has not been mapped to destination yet
            if (colMetadata1.type === colMetadata2.type && colMetadata1.display_name === colMetadata2.display_name) {
              mvm.sourceToDestMap[colMetadata1.internal_name] = colMetadata2.internal_name;
              mvm.sourceToDestActionMap[colMetadata1.internal_name]['action'] = 'retain_col_type';
            } else {
              mvm.sourceToDestMap[colMetadata1.internal_name] = null;
              mvm.sourceToDestActionMap[colMetadata1.internal_name]['action'] = 'retain_col_type';
            }
          }
        });
      });
      checkIfAllColumnsMapped()
      _generateMapping();
    }
    function checkIfAllColumnsMapped(){
      mvm.areAllColumnsMapped = Object.values(mvm.sourceToDestMap).every(v => v)
      if (mvm.areAllColumnsMapped) toggleUnMappedColumnFilter(false)
    }
    function filterMappings(value){
      mvm.columnMappingSearch = value
      if (!mvm.areOnlyUnmappedColumnsVisible){
        mvm.ds1.ds_original_columns = mvm.ds1.metadata.filter(
          c => (c.display_name.toLowerCase().includes(value.toLowerCase()) ||
                  ( mvm.sourceToDestMap[c.internal_name] && 
                    mvm.ds2_internal_to_display_name_map[mvm.sourceToDestMap[c.internal_name]].toLowerCase().includes(value.toLowerCase())
                  )
               ) && !batch_col_internal_names_ds1.includes(c.internal_name))
        if (!value) mvm.ds1.ds_original_columns = mvm.ds1.metadata.filter((column_info) => !batch_col_internal_names_ds1.includes(column_info.internal_name))     
      }else {
        mvm.ds1.ds_original_columns = mvm.ds1.metadata.filter(
          c => (c.display_name.toLowerCase().includes(value.toLowerCase()) ||
                  (mvm.sourceToDestMap[c.internal_name] &&
                    mvm.ds2_internal_to_display_name_map[mvm.sourceToDestMap[c.internal_name]].toLowerCase().includes(value.toLowerCase())
                  )
               ) && !mvm.sourceToDestMap[c.internal_name]  
                 && !batch_col_internal_names_ds1.includes(c.internal_name))
        if (!value) mvm.ds1.ds_original_columns = mvm.ds1.metadata.filter((column_info) => !mvm.sourceToDestMap[column_info.internal_name] && !batch_col_internal_names_ds1.includes(column_info.internal_name))     
      }
      sortColumnsByDisplayName()
    }
    function sortColumnsByDisplayName() {
      mvm.ds1.ds_original_columns.sort((a, b) => {
        const nameA = a.display_name.toUpperCase();
        const nameB = b.display_name.toUpperCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }
    function toggleUnMappedColumnFilter(value){
      mvm.areOnlyUnmappedColumnsVisible = value
      if (value) mvm.ds1.ds_original_columns = mvm.ds1.ds_original_columns.filter(c => !mvm.sourceToDestMap[c.internal_name])
      else mvm.ds1.ds_original_columns = mvm.ds1.metadata.filter(column_info => !batch_col_internal_names_ds1.includes(column_info.internal_name))
      sortColumnsByDisplayName()
    }

    function _extractColumnsByType(metaData) {
      if (!metaData)
        return;
      mvm.columnsByType = {
        TEXT: [],
        NUMERIC: [],
        DATE: []
      };
      angular.forEach(metaData, function (column) {
        mvm.columnsByType[column.type].push(column);
        // push date and numeric columns as well into text type, MVP-2983
        if (column.type != 'TEXT') {
          mvm.columnsByType.TEXT.push(column)
        }
      })
    }

    function _initializeSourceToDestMap(metaData) {
      if (!metaData) {
        return;
      }
      angular.forEach(metaData, function (column) {
        let column_map = {'action': null};
        mvm.sourceToDestActionMap[column.internal_name] = column_map;
        mvm.sourceToDestMap[column.internal_name] = null;
      })
    }

    function onNext() {
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.combineData.combineDSNext, {eventOrigin: "landingPage.previewPanel"});
      mvm.step = 2;
      mvm.getMetadata(mvm.ds1);
      mvm.getMetadata(mvm.ds2);
      initialiseDs2InternalNameToDisplayNameMap();
    }

    function initialiseDs2InternalNameToDisplayNameMap(){
      mvm.ds2_internal_to_display_name_map = mvm.ds2.metadata.reduce((accumulator, currentValue) => {
        accumulator[currentValue.internal_name] = currentValue.display_name;
        return accumulator;
      }, {});
    }

    function onPrev() {
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.combineData.combineDSPrevious, {eventOrigin: "landingPage.previewPanel"});
      mvm.step = 1;
    }

    function onSubmit(isValidationRequired = true) {
      if (mvm.step == 1) {
        mvm.onNext();
        return
      }
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.combineData.combineDSConfirm, {eventOrigin: "landingPage.previewPanel"});
      _generateMapping();
      _generateActionMapping();
      if (!mvm.destinationDs) {
        DSBatchService.addBatch(mvm.ds1.id, mvm.ds2.id, mvm.mapping, mvm.actionMaps, mvm.newDsParams, false, mvm.delete_source_ds);
        $uibModalInstance.close();
      } else {
        var sourceDsId = mvm.ds2.id;
        if (parseInt(mvm.destinationDs.id, 10) === sourceDsId) {
          sourceDsId = mvm.ds1.id;
        }
        if (mvm.serverSideValidationDone == true) {
          isValidationRequired = false;
          $uibModalInstance.close();
        }
        DSBatchService.addBatch(parseInt(mvm.destinationDs.id, 10), sourceDsId, mvm.mapping, mvm.actionMaps,
          undefined, isValidationRequired, mvm.delete_source_ds, mvm.replace).then(successCallback, failureCallback);
      }
    }

    function successCallback(data) {
      if (data.hasOwnProperty('result') && data['result']['is_valid'] == false) {
        var scp = $rootScope.$new();
        angular.extend(scp, data);
        angular.extend(scp, mvm.destinationDs);
        var modal = $uibModal.open({
          templateUrl: config.templates.appendBatchConfirmation,
          windowClass: 'modal-size-medium append-batch-confirmation',
          controller: config.controllers.dsModificationAlertCtrl,
          controllerAs: 'uvm',
          bindToController: true,
          scope: scp
        });
        modal.result.then(modalCallback);
      } else {
        mvm.serverSideValidationDone = true;
      }
      /*
        Show toast notification after successfull deletion,
        This method gets called two times:
        1) When validation is successfull.
        2) When whole action of combination is done.
        Display this notification only in case #2.
        For success toast, delete_source_ds flag should be true and data should contain
        something so that we can differentiate function call if it is from case #1.
      */
      if (data.hasOwnProperty('result') && data['result']['is_valid'] == true) {
        // data.result has only key value pair after validation is done.
        if (mvm.delete_source_ds) {
          toastNotification.success('Source dataset is queued up for deletion');
        }
      }
    }

    function modalCallback(data) {
      if (data) {
        mvm.serverSideValidationDone = true;
      } else {
        mvm.serverSideValidationDone = false;
      }
    }

    function failureCallback(data) {
    }

    function _generateActionMapping() {
      mvm.actionMaps = [];
      for (var key in mvm.mapping) {
        if (mvm.mapping.hasOwnProperty(key)) {
          mvm.actionMaps.push({
            'destination': key,
            'source': mvm.mapping[key],
            'action': mvm.sourceToDestActionMap[key]['action']
          })
        }
      }
    }

    function _generateMapping() {
      mvm.mapping = _.cloneDeep(mvm.sourceToDestMap);

      var reverseMap = {};

      for (var key in mvm.mapping) {
        if (mvm.mapping.hasOwnProperty(key)) {
          if (mvm.mapping[key] == null) {
            delete mvm.mapping[key];
            continue;
          }
          if (mvm.destinationDs && mvm.destinationDs.id === mvm.ds2.id) {
            reverseMap[mvm.mapping[key]] = key;
          }
        }
      }
      if (Object.keys(reverseMap).length != 0) {
        mvm.mapping = reverseMap;
      }
      _validateColumnMap();
    }

    function _validateColumnMap() {
      if (Object.keys(mvm.mapping).length == 0) {
        mvm.isMappingValid = false;
      } else {
        angular.forEach(mvm.mapping, function (mapItem) {
          if (mapItem != undefined) {
            mvm.isMappingValid = true;
          }
        });
      }
    }

    function selectAndValidate(SourceColumn) {
      mvm.serverSideValidationDone = false;
      var columnList = Object.keys(mvm.sourceToDestMap);
      angular.forEach(columnList, function (item) {
        if (item != SourceColumn && mvm.sourceToDestMap[item] != null && mvm.sourceToDestMap[item] === mvm.sourceToDestMap[SourceColumn]) {
          mvm.sourceToDestMap[item] = undefined;
        }
      });
      checkIfAllColumnsMapped()
      _generateMapping();
    }

    function dsList() {
      var originalList = DatasourceService.list;
      var filteredList = [];
      angular.forEach(originalList, function (ds) {
        if (ds.id != mvm.ds1.id && ds.status === 'ready') {
          filteredList.push(ds);
        }
      });
      return filteredList;
    }

    function getColumnType(internal_name) {
      if (internal_name) {
        let col_info = mvm.ds2.metadata.filter(function (col_info) {
          return col_info['internal_name'] == internal_name
        })
        return col_info[0]['type']
      } else {
        return 'empty'
      }
    }

    function isTypeMismatch(internal_name, type) {
      if (internal_name) {
        let col_info = mvm.ds2.metadata.filter(function (col_info) {
          return col_info['internal_name'] == internal_name
        });
        if (col_info[0]['type'] == type ? false : true) {
          mvm.mappingaction[0]['display'] = 'Retain as ' + type;
          mvm.mappingaction[1]['display'] = 'Change to ' + col_info[0]['type'];
          return true
        }
      }
      return false
    }
  }
}
