<template>
  <mm-modal
    v-model="computedIsModalOpen"
    content-class="mm-combine-with-dataset-review"
    :title="`${$t('data_library.combine.review.title')}:`"
  >
    <div class="table-wrapper overflow-kit">
      <table :aria-describedby="$t('data_library.combine.review.title')">
        <tr class="table-header mm-text--h200">
          <th>{{ $tc('global.dictionary.column') }}</th>
          <th>{{ $t('data_library.combine.review.views') }}</th>
          <th>{{ $t('data_library.combine.review.tasks_metrics') }}</th>
        </tr>

        <template v-for="(col, index) in reviewPayload">
          <tr v-for="(info, viewId, idx) in col.info" :key="`${viewId}_${idx}_${index}`" class="table-row">
            <td v-if="idx == 0" :rowspan="Object.keys(col.info).length">
              <mm-tooltip :label="col.colName" wrapper-class="overflow-ellipsis max-width-100">
                <span>{{ col.colName }}</span>
              </mm-tooltip>
            </td>
            <td>
              <mm-tooltip
                :label="getResourcePath($store.getters['resources/getViewByViewId'](viewId).resourceId).join(' / ')"
                wrapper-class="width-auto d-flex-align-center"
              >
                <span class="m-r-1 overflow-ellipsis">
                  {{ $store.getters['resources/getDatasetByViewId'](viewId).properties.name }} -
                </span>
                <mm-link
                  class="overflow-ellipsis"
                  :label="$store.getters['resources/getViewByViewId'](viewId).properties.name"
                  underline
                  @click="openViewInDataEditor(viewId)"
                />
              </mm-tooltip>
            </td>
            <td>
              <p v-for="view in info.same_dataview_affected_rules" :key="view.sequence" class="m-b-1">
                {{ $t('data_editor.pipeline.changes.task_number') }} {{ view.sequence }}
              </p>
              <p v-if="checkIfMetricsPresent(info)" class="m-b-1">
                {{ $t('data_library.combine.review.metrics') }}: {{ info.dependencies.derivatives.length }}
              </p>
            </td>
          </tr>
        </template>
      </table>
    </div>

    <template #actions>
      <mm-checkbox v-model="isProceedEnabled" class="m-r-auto" :label="$t('data_library.combine.review.understand')" />
      <mm-button
        class="m-r-3"
        :label="$t('global.dictionary.cancel')"
        objective="secondary"
        @click="computedIsModalOpen = false"
      />
      <mm-button
        :disabled="!isProceedEnabled"
        :label="$t('global.dictionary.proceed')"
        @click="
          $emit('submit')
          computedIsModalOpen = false
        "
      />
    </template>
  </mm-modal>
</template>

<script>
import { getResourcePath } from '@/modules/resources/utils'
import { getOldAppUrl } from '@/constants'
export default {
  name: 'combine-with-dataset-review-modal',
  props: {
    value: Boolean,
    reviewPayload: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    isProceedEnabled: false
  }),
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        if (!value) this.isProceedEnabled = false
        this.$emit('input', value)
      }
    }
  },
  methods: {
    getResourcePath,
    checkIfMetricsPresent(info) {
      return info.dependencies?.derivatives?.length
    },
    openViewInDataEditor(viewId) {
      // TODO: remove when angular code is gone
      const redirectUrl = `${getOldAppUrl()}/#/workspaces/${this.$store.state.workspaceId}/projects/${
        this.$store.state.projectId
      }/dataviews/${viewId}`
      window.open(redirectUrl, `_mammouth_combine_ds_review_${viewId}`)
    }
  }
}
</script>

<style lang="scss">
@import '@mammoth_developer/mm-storybook/src/styles/spacing.scss';
$border-color: var(--mm-color--n80);

.mm-combine-with-dataset-review {
  .table-wrapper {
    overflow-x: hidden;
    max-height: 400px;
    padding: 1px;

    table {
      outline: 1px solid $border-color;
      border-collapse: collapse;
      table-layout: fixed;
      width: 750px;

      .table-header {
        background: var(--mm-color--n30);
      }

      th {
        @extend .p-y-3;
        @extend .p-l-5;
      }

      td {
        @extend .p-y-2;
        @extend .p-l-3;
      }

      th,
      td {
        outline: 1px solid $border-color;
        width: 220px;
      }
    }
  }

  .mm-row {
    align-items: center;

    .mm-spacer {
      display: none;
    }
  }
}
</style>
