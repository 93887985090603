import { i18n } from '@/plugins/i18n'

export const randomString = (length) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  return Array(length)
    .fill('')
    .map(() => Math.floor(Math.random() * chars.length))
    .map((r) => chars.charAt(r))
    .join('')
}

export const getExportTooltip = (dependency) => {
  let text = ''

  if (dependency.host)
    text += `${i18n.t('data_library.data_addition.third_party.new_connection.host_url')}: ${dependency.host}<br>`
  if (dependency.database)
    text += `${i18n.t('data_library.data_addition.third_party.new_connection.database')}: ${dependency.database}<br>`
  if (dependency.table) text += `${i18n.t('global.dictionary.table')}: ${dependency.table}<br>`
  if (dependency.indexName) text += `${i18n.t('global.dictionary.index_name')}: ${dependency.indexName}<br>`
  if (dependency.directory) text += `${i18n.t('global.dictionary.directory')}: ${dependency.directory}<br>`

  return text
}

export const flattenObject = (obj, parent = '', flattened = {}) => {
  for (let key in obj) {
    const propName = parent ? `${parent}.${key}` : key
    if (typeof obj[key] === 'object') flattenObject(obj[key], propName, flattened)
    else flattened[propName] = obj[key]
  }
  return flattened
}
