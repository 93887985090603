/*jslint node: true */
import * as angular from 'angular';
import * as $ from 'jquery';

let placeHolders = {
  normalMode: 'Enter a math function here: E.g. Column1 + Column2 OR AVG(Column2) / Column1 ',
  functionsOnlyMode: 'E.g. SUM("Col2") / AVG("Col1")'
};

/**
 * @ngInject
 * This directive is for assigning of math editors
 *
 * Attributes
 *
 * 1. ngModel: The model on which expression is be assigned
 * 2. columns: The numeric columns that can be used to construct the array
 * 3. metrics: The metrics that are available
 * 7. disallowColumnsAsAtoms: defaults to true.
 *    This is to be set to false in case the columns are allowed as low level
 *    building blocks of the expression. Use case is that metrics do not allow
 *    this.
 * 4. setInsertTextFunction: A function to set callback so that text can
 *      be inserted at cursor position.
 */

mamMathEditor.$inject = ['mammothExpressionHelperFactory', '$timeout', '$log', 'codeMirrorEditorDefiner'];
export function mamMathEditor(mammothExpressionHelperFactory, $timeout, $log, codeMirrorEditorDefiner) {
  return {
    restrict: 'A',
    scope: {
      ngModel: '=ngModel',
      columns: '=columns',
      metrics: '=metrics',
      dirty: "=dirty",
      modelUpdateCallback: "&",
      disallowColumnsAsAtoms: '=disallowColumnsAsAtoms',
      setTextInsert: '&setTextInsert',
      onTextAreaBlur: "&onTextAreaBlur",
      openHintsIfEmpty: '='
    },
    require: 'ngModel',
    link: function (scope, element, attrs, ngModelCtrl) {
      var modeName = 'mammath' + Math.floor(Math.random()*1000);

      var mimeName = 'text/' + modeName;

      var expression_helper = mammothExpressionHelperFactory.get_helper();

      codeMirrorEditorDefiner.defineModeMime(modeName, mimeName, expression_helper);
      codeMirrorEditorDefiner.registerHintHelper(modeName, expression_helper);
      let placeHolder;

      if(scope.disallowColumnsAsAtoms){
        placeHolder = placeHolders.functionsOnlyMode;
      }
      else{
        placeHolder = placeHolders.normalMode;
      }
      var editor = codeMirrorEditorDefiner.create($(element)[0], modeName, placeHolder);
      var show_mammoth_hints = codeMirrorEditorDefiner.getShowHintFunction(editor, modeName);
      var textInserter = codeMirrorEditorDefiner.getTextInserter(editor);
      var movedDownStatus = false;

      editor.on("keyup", keyUpHandler);
      editor.on("mousedown", mouseDownHandler);
      editor.on("change", changeHandler);
      editor.on("beforeChange", beforeChangeHandler);
      editor.on("cursorActivity", cursorActivityHandler);

      scope.$watch('columns', initialise);
      scope.$watch('metrics', initialise);
      scope.$watch('setTextInsert()', bindSetInsert);

      editor.on("blur", scope.onTextAreaBlur);

      function keyUpHandler(cm, keyevent) {
        if ([38, 40, 27].indexOf(keyevent.which) == -1) {
          // to avoid flicker on keyboard navigation etc
          show_mammoth_hints(cm)
        }
      }

      function mouseDownHandler(cm) {
        /*
        During mouse click event, both mouseDownHandler and
        cursorActivityHandler are called (in same order). 
        cursorActivity is fired whenever cursor or selection moves and
        mousedown is fired when CodeMirror is handling a mouse down DOM event.
        Setting mouse down flag true here so that when cursorActivity handler is called,
        it knows changes are brought due to mouse down event.
        */
        movedDownStatus = true;
      }

      function cursorActivityHandler(cm){
        if (movedDownStatus) {
          show_mammoth_hints(cm);
        }
      }

      function changeHandler(cm, change) {
        if (change.origin == 'complete') {
         show_mammoth_hints(cm)
        }
        var text = cm.getValue();
        try {
          expression_helper.validate(text);
          var expression = expression_helper.getParam(text);
          ngModelCtrl.$setViewValue(expression);
          scope.modelUpdateCallback();
        } catch (e) {
          ngModelCtrl.$setViewValue(null);
          scope.modelUpdateCallback();
        }
        scope.dirty = true;
      }


      function beforeChangeHandler(instance, change) {
        var newtext = change.text.join("").replace(/\n/g, ""); // remove ALL \n !
        change.update(change.from, change.to, [newtext]);
        return true;
      }

      /**
       * This will set a function on controller so that it is possible to insert text at the cursor position
       *
       * */
      function bindSetInsert(setTextInsert) {
        if (setTextInsert) {
          setTextInsert(textInserter)
        }
      }

      function initialise() {
        $timeout(function(){
          expression_helper.init(scope.columns, scope.metrics, scope.disallowColumnsAsAtoms);
          if (scope.ngModel) {
            var expression_string = expression_helper.getExpressionString(scope.ngModel,
              scope.columns, scope.metrics);
            editor.setValue(expression_string);
            editor.setCursor({line: 1, pos: expression_string.length})
          }
          editor.focus();
          var emptyModel = !scope.ngModel || (angular.isArray(scope.ngModel) && scope.ngModel.length == 0);
          if(scope.openHintsIfEmpty && emptyModel){
            show_mammoth_hints(editor);
          }
        }, 500)
      }
    }
  }
}
