<template>
  <mm-modal v-model="computedIsModalOpen" title="Delete Workspace" width="480" closable>
    <div class="d-flex">
      <mm-icon class="m-r-2" name="information" style="min-width: 16px"></mm-icon>
      <p class="mm-text--caption-regular">
        Deleting this Workspace is irreversible and will delete both the Workspace and its contents for all users with
        access
      </p>
    </div>

    <p class="mm-text--body-regular">
      Type <strong>{{ details.name }}</strong> to confirm
    </p>

    <mm-text-input
      v-model="confirmationText"
      style="min-width: 400px"
      ref="deleteConfirmationInput"
      placeholder="Enter workspace name"
      hide-details
      @keyup.enter="deleteWorkspace"
    ></mm-text-input>

    <template #actions>
      <mm-button label="Cancel" objective="secondary" @click="computedIsModalOpen = false" />
      <mm-button
        class="m-l-3"
        label="Apply"
        objective="destructive"
        :disabled="confirmationText !== details.name"
        @click="deleteWorkspace"
      />
    </template>
  </mm-modal>
</template>

<script>
export default {
  name: 'workspace-deletion-confirmation',
  props: {
    value: Boolean,
    details: Object,
  },
  data() {
    return {
      confirmationText: '',
    }
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async deleteWorkspace() {
      if (this.confirmationText !== this.details.name) return

      const response = await this.$axios.delete(`/admin/workspaces/${this.details.id}`)
      if (response) {
        this.$toast.show({ content: `Workspace ${this.details.id} deleted successfully`, status: 'success' })
      } else this.$toast.show({ content: 'Could not delete Workspace', status: 'error' })
      this.computedIsModalOpen = false
    },
  },
  watch: {
    computedIsModalOpen: {
      handler(val) {
        if (val) setTimeout(() => this.$refs.deleteConfirmationInput.focusInputField(), 10)
        else this.confirmationText = ''
      },
      immediate: true,
    },
  },
}
</script>
