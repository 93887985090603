
/**
 * @ngInject
 */
TabController.$inject = ['UserWorkspace', 'Auth'];
export function TabController(UserWorkspace, Auth) {
  // ViewModel
  var tvm = this;
  tvm.UserWorkspace = UserWorkspace;
  tvm.Auth = Auth;


  // Testing angular ui bootstrap tabs...
  // tvm.ViewTabs = [
  //   { title: 'View 1', content: 'Content 1' },
  //   { title: 'View 2', content: 'Content 2' }
  // ];
  // Usage:
  // <uib-tabset>
  //   <uib-tab ng-repeat="tab in tvm.tabs" heading="{{tab.title}}" active="tab.active">
  //     {{tab.content}}
  //   </uib-tab>
  // </uib-tabset>



  tvm.Breadcrumbs = [];

  tvm.Notifications = ["notif 1", "notif 2", "notif 3"];
  tvm.Workspace = [
    { label: 'Workspace' },
    { label: 'Users' },
    { label: 'Settings' },
    { label: 'Logout' }
  ];

}

