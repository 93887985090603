import * as angular from 'angular';
import * as _ from 'lodash-es';

/**
 * @ngInject
 */
ElementModalController.$inject =['c', 'elementConfig', '$injector', 'derivatives', 'oldElementServiceFactory', 'utils', 'modalService',
                                       'elementModalService', '$timeout', 'eventCallbackManagerFactory',
                                       'DrilldownManagerFactory', 'analyticsService'];
export function ElementModalController(c, elementConfig, $injector, derivatives, oldElementServiceFactory, utils, modalService,
                                       elementModalService, $timeout, eventCallbackManagerFactory,
                                       DrilldownManagerFactory, analyticsService) {
  this.$onInit = function () {

    var epvm = this,
      unsubscribeSaveElement,
      cache: any = {};
    let _reportMetricTitleChanged = utils.debounce(() => {
      analyticsService.userEventTrack(c.userEvents.elements.editor.metricTitleChanged,
        {
          eventOrigin: "metricCreatorModal"
        });
    }, 2000, false);
    let _reportMetricSubtitleChanged = utils.debounce(() => {
      analyticsService.userEventTrack(c.userEvents.elements.editor.metricSubtitleChanged,
        {
          eventOrigin: "metricCreatorModal"
        });
    }, 2000, false);
    let _reportMetricNoteChanged = utils.debounce(() => {
      analyticsService.userEventTrack(c.userEvents.elements.editor.metricSubtitleChanged,
        {
          eventOrigin: "metricCreatorModal"
        });
    }, 2000, false);
    var elementSelectedEvent = new eventCallbackManagerFactory("elementModalOnElementSelected");
    epvm.onElementSelected = elementSelectedEvent.add_callback;
    epvm.addElementInProgress = false;
    epvm.selectElementInProgress = false;
    epvm.modalReady = false;
    epvm.inFilterMode = false;
    epvm.elementTypes = c.elementTypes;
    epvm.elementService = oldElementServiceFactory.getByDataviewId(epvm.options.dataview.id);
    epvm.destroy = destroy;
    epvm.saveElement = saveElement;
    epvm.immediateSaveElement = _saveElement;
    epvm.addElement = addElement;
    epvm.deleteElement = deleteElement;
    epvm.selectElement = selectElement;
    epvm.updateElementType = updateElementType;
    epvm.deselectElement = deselectElement;
    epvm.selectElementById = selectElementById;
    epvm.deleteDrilldown = deleteDrilldown;
    epvm.toggleScientificFormatOption = toggleScientificFormatOption;
    epvm.metricTitleChanged = metricTitleChanged;
    epvm.metricSubtitleChanged = metricSubtitleChanged;
    epvm.metricNoteChanged = metricNoteChanged;
    epvm.toggleTab = toggleTab;
    epvm.closeModal = closeModal;
    epvm.drilldownManagers = {};
    epvm.datastores = {};
    initDrilldownMangersAndDatastores();
    epvm.tabSelected = tabSelected;

    function tabSelected(index) {
      analyticsService.userEventTrack(c.userEvents.elements.editor.tabSelected, {
        index: index
      });
    }

    epvm.elementService.onElementsUpdated("eleModalUpdateDrilldowns", initDrilldownMangersAndDatastores);

    var modalController = {
      selectElement: selectElement,
      saveElement: saveElement,
      selectElementById: selectElementById
    };

    var elementMenuManager;

    epvm.options.metadata = utils.metadata.add_type_to_display_name(epvm.options.dataview.metadata);
    if (epvm.options.element.hasOwnProperty('reference_errors')) {
      epvm.options.metadata = utils.metadata.add_error_columns_to_metadata(epvm.options.metadata, epvm.options.element.reference_errors.reference_errors, epvm.options.dataview.id);
    }
    $timeout(function () {
      selectElement(epvm.options.element, true);
      if (epvm.options.element.type === "FILTER") {
        epvm.inFilterMode = true;
      }
    }, 500);  // time for the modal to animate open and start rendering


    $timeout(function () {
      epvm.modalReady = true;
      $timeout(elementSelectedEvent.fire_event, 600);
    }, 600);

    function selectElementById(elementId, initing?) {
      var ele = epvm.elementService.mainList[elementId];
      if (!ele) {
        return
      }
      selectElement(ele, initing);
    }

    function selectElement(element, initing?, reset?, additionalDisplayProperties?) {
      cache.element = element;
      cache.reset = reset;
      cache.additionalDisplayProperties = additionalDisplayProperties;

      if (element && element.isDeleting || (!initing && (epvm.options.element == element))) {
        return;
      }
      $timeout(function () {
        epvm.selectElementInProgress = true;
      });
      epvm.options.drilldownManager = undefined;
      epvm.options.additionalDisplayProperties = additionalDisplayProperties;
      $timeout(function () {
        if (unsubscribeSaveElement) {
          unsubscribeSaveElement();
        }
        elementMenuManager = $injector.get(elementConfig[element.type].menuFactory);
        epvm.options.element = element;
        epvm.options.modalController = modalController;
        if (epvm.drilldownManagers.hasOwnProperty(element.id)) {
          epvm.options.drilldownManager = epvm.drilldownManagers[element.id];
          epvm.options.rootElement = element;
        } else if (element.display_properties && element.display_properties.isDrilldown &&
          epvm.drilldownManagers.hasOwnProperty(element.display_properties.rootElementId)) {
          epvm.options.drilldownManager = epvm.drilldownManagers[element.display_properties.rootElementId];
          epvm.options.rootElement = epvm.elementService.mainList[element.display_properties.rootElementId];
        } else if (additionalDisplayProperties && additionalDisplayProperties.isDrilldown &&
          epvm.drilldownManagers.hasOwnProperty(additionalDisplayProperties.rootElementId)) {
          epvm.options.drilldownManager = epvm.drilldownManagers[additionalDisplayProperties.rootElementId];
          epvm.options.rootElement = epvm.elementService.mainList[additionalDisplayProperties.rootElementId];
        }
        if (epvm.options.drilldownManager) {
          if (reset) {
            epvm.options.drilldownManager.reset();
          }
          drilldownChangeListener();
        }
        $timeout(function () {
          epvm.manager = elementMenuManager.get(epvm.options);
          unsubscribeSaveElement = epvm.manager.on_valid_update("controllerSaveElement", function () {
            saveElement();
          });
        }, 10);
      }, epvm.options.element ? 50 : 0);
      $timeout(function () {
        elementSelectedEvent.fire_event();
        epvm.selectElementInProgress = false;
      }, 200);
    }

    function deleteDrilldown() {
      var deleteAlertPromise = modalService.deleteDrilldown({type: "drilldown"});
      deleteAlertPromise.then(function () {
        epvm.manager.drilldownManager.deleteAllDrillDownElements(epvm.options.element.id);
        delete epvm.options.element.display_properties.drilldown.default.elementId;
        epvm.options.element.display_properties.drilldown.enabled = false;
        epvm.options.element.display_properties.drilldown.default = {};
        epvm.options.element.displayUpdated();
        // saveElement();
      });
    }

    function updateElementType() {
      $timeout(function () {
        selectElement(cache.element, true, cache.reset, cache.additionalDisplayProperties);
      }, 300);
    }

    function drilldownChangeListener() {
      epvm.options.drilldownManager.onChange('menuManager', function () {
        if (epvm.options.drilldownManager) {
          var currEle = epvm.options.drilldownManager.getCurrentElement();
          if (currEle && (currEle.id != epvm.options.element.id)) {
            selectElementById(currEle.id);
          }
        }
      });
    }


    function initDrilldownMangersAndDatastores() {
      angular.forEach(epvm.elementService.mainList, function (ele) {
        getDatastore(ele.id); // init
      });
      angular.forEach(epvm.elementService.elements, function (ele) {
        getDrillDownManager(ele); // init
      });
    }

    function getDrillDownManager(currElem) {
      if (epvm.drilldownManagers.hasOwnProperty(currElem.id)) {
        return epvm.drilldownManagers[currElem.id]
      } else {
        var ddm = new DrilldownManagerFactory(currElem, epvm.elementService, epvm.datastores);
        epvm.drilldownManagers[currElem.id] = ddm;
        return ddm;
      }
    }

    function getDatastore(elementId) {
      if (!epvm.datastores.hasOwnProperty(elementId)) {
        epvm.datastores[elementId] = {};
      }
      return epvm.datastores[elementId];
    }

    function addElement(type) {
      epvm.options.isEdit = false;
      selectElement({type: type});
      $timeout(elementSelectedEvent.fire_event, 400);
    }

    function deleteElement(element) {
      var item = _.cloneDeep(element);
      modalService.deleteDerivative(epvm.options.dataview, element).then(_closeCallback);

      function _closeCallback() {
        element.isDeleting = true;
        epvm.elementService.pollForUpdatesUntilReady();
        deselectElement();
      }
    }

    function deselectElement() {
      $timeout(function () {
        epvm.options.element = undefined;
      });
    }

    var addElementDebounced = utils.debounce(_saveElement, 1200);
    var saveElementDebounced = utils.debounce(_saveElement, 600);

    function saveElement(closeModal?) {
      if (epvm.options.element.id) {
        if (epvm.manager) {
          var displayProperties;
          try {
            displayProperties = epvm.manager.getDisplayProperties();
          } catch (e) {
            return;
          }
          epvm.options.element.display_properties = displayProperties;
          epvm.options.element.displayUpdated();
          saveElementDebounced(closeModal);
        }
      } else {
        addElementDebounced(closeModal);
      }
    }

    function _saveElement(closeModal?) {
      if (epvm.addElementInProgress) {
        return;  // epvm.addElementInProgress implies menu is getting created, block until creation is complete to
        // prevent multiple creations
      }
      try {
        var param = epvm.manager.getParam();
        var displayProperties = epvm.manager.getDisplayProperties();
        if (!param) {
          return;
        }
      } catch (e) {
        return;
      }
      param.IS_PERMANENT = true;

      if (!epvm.options.element.id) {
        $timeout(function () {
          epvm.addElementInProgress = true;
        });
        analyticsService.userEventTrack(c.userEvents.elements.add, {
          elementType: displayProperties['type'],
          eventOrigin: "elementPanel"
        });

        return derivatives.add(epvm.options.dataview.id, param, displayProperties).then(addCallback);
      } else {
        var _existing_params = _.cloneDeep(epvm.options.element.param);
        var _existing_display_properties;
        if (epvm.options.element.getServerSideDisplayProps) {
          _existing_display_properties = epvm.options.element.getServerSideDisplayProps();
        } else {
          _existing_display_properties = undefined;
        }
        delete _existing_params.DERIVATIVE_ID;
        delete _existing_params.DATAVIEW_ID;
        var _param = angular.equals(param, _existing_params) ? undefined : param;
        var _displayProps = angular.equals(displayProperties, _existing_display_properties) ? undefined : displayProperties;
        if (_param || _displayProps) {
          if (_param) {
            epvm.options.element.status = 'processing';
          }
          return derivatives.edit(epvm.options.dataview.id, epvm.options.element.id,
            _param, _displayProps).then(editCallback);
        } else {
          return;
        }

      }

      function addCallback(elementId) {
        $timeout(function () {
          // epvm.options.element.id = elementId;
        });
        epvm.elementService.pollForUpdatesUntilReady().then(function () {
          initDrilldownMangersAndDatastores();
          var addedElement = epvm.elementService.mainList[elementId];
          $timeout(function () {
            epvm.options.element = addedElement;
          });
          if (addedElement.display_properties.isDrilldown && addedElement.display_properties.drillUp.elementId) {
            var parentElement = epvm.elementService.mainList[addedElement.display_properties.drillUp.elementId];
            if (!parentElement.display_properties.hasOwnProperty('drilldown')) {
              parentElement.display_properties.drilldown = {};
            }
            if (!parentElement.display_properties.drilldown.hasOwnProperty(addedElement.display_properties.drillUp.key)) {
              parentElement.display_properties.drilldown[addedElement.display_properties.drillUp.key] = {};
            }
            parentElement.display_properties.drilldown.enabled = true;
            parentElement.display_properties.drilldown[addedElement.display_properties.drillUp.key].elementId = addedElement.id;
            derivatives.edit(epvm.options.dataview.id, parentElement.id,
              parentElement.param, parentElement.display_properties);
          }
          $timeout(function () {
            addedElement = epvm.elementService.mainList[elementId];
            epvm.addElementInProgress = false;
            if (epvm.options.drilldownManager) {
              epvm.options.drilldownManager.openDrilldown(addedElement.id);
            }
            selectElement(addedElement, true);
          }, 300);

        });
        if (closeModal) {
          elementModalService.close();
        }
      }

      function editCallback() {
        epvm.elementService.pollForUpdatesUntilReady();
        epvm.addElementInProgress = false;
        if (closeModal) {
          elementModalService.close();
        }
      }
    }

    function destroy() {
      if (unsubscribeSaveElement) {
        unsubscribeSaveElement();
      }
    }

    function toggleScientificFormatOption() {
      analyticsService.userEventTrack(c.userEvents.elements.editor.toggleScientificFormatOption,
        {
          eventOrigin: "metricCreatorModal",
          value: epvm.manager.displayProperties.USE_SCIENTIFIC_FORMAT
        });
    }

    function metricTitleChanged() {
      _reportMetricTitleChanged();
    }

    function metricSubtitleChanged() {
      _reportMetricSubtitleChanged();
    }

    function metricNoteChanged() {
      _reportMetricNoteChanged();
    }

    function toggleTab(value) {
      analyticsService.userEventTrack(c.userEvents.elements.editor.toggleTab,
        {
          eventOrigin: "metricCreatorModal",
          currentTab: value
        });
    }

    function closeModal(value) {
      analyticsService.userEventTrack(c.userEvents.elements.editor.closeModal,
        {
          eventOrigin: "metricCreatorModal." + value,
        });
    }

  }
}
