import * as $ from 'jquery';
import * as _ from 'lodash-es';

/**
 * @ngInject
 */
deleteColumnManagerFactory.$inject = ['eventCallbackManagerFactory', 'utils'];
export function deleteColumnManagerFactory(eventCallbackManagerFactory, utils) {
  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new DeleteColumnManager(options);
  }


  function DeleteColumnManager(options) {
    var metadata = options.metadata, taskUtils = options.taskUtils;
    var onColumnAdded = new eventCallbackManagerFactory('deleteManagerColumnAdded');
    var self = this;
    self.metadata = utils.sanitizeMetadataWithType(metadata);
    self.context = options.context
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.validate = validate;
    self.getParam = getParam;
    self.sanitizeParam = sanitizeParam;
    self.setParam = setParam;
    self.handlePasteParams = handlePasteParams;
    self.addColumn = addColumn;
    self.removeColumn = removeColumn;
    self.columnChanged = columnChanged;
    self.deleteColumnInputArray = [undefined];
    self.onColumnAdded = onColumnAdded.add_callback;
    self.internal_name_to_column_map = {}
    self.columns = (function(){
      var columns = [];
      self.metadata.forEach(function(col){
          self.internal_name_to_column_map[col.internal_name] = col;
      });
      return columns;
    })();


    self.param = {
      "DELETE": []
    };

    function addColumn() {
      self.deleteColumnInputArray.push(undefined);
      onColumnAdded.fire_event();
    }

    function removeColumn(i) {
      self.deleteColumnInputArray.splice(i, 1);
      if (!self.deleteColumnInputArray.length) {
        addColumn();
      }
    }

    function getNewSrcColumns(replacementColumnMaps) {
      var newSourceColumns = []
      for (const internalName in replacementColumnMaps) {
        newSourceColumns.push(replacementColumnMaps[internalName]['internal_name']);
      }
      return newSourceColumns
    }

    function handlePasteParams(taskInfo){
      /** Update  params with suitable replacement columns, based on display name*/
      var param = taskInfo.params;
      for(const index in param.DELETE){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(param.DELETE, index, taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }
      return param
    }

    function getParam() {
      self.param.DELETE = self.deleteColumnInputArray;
      if(self.context.hasOwnProperty('sequence')){
        self.param['SEQUENCE_NUMBER'] = self.context.sequence
      }
      return self.param;
    }

    function sanitizeParam(param){
      let indicesOfColNotAvailable = [];
      _.forEach(param.DELETE, function(col){
        var col_info = utils.metadata.get_column_by_internal_name(metadata, col);
        if (!col_info) {
          indicesOfColNotAvailable.push(param.DELETE.indexOf(col))
        }
      });
      param.DELETE = param.DELETE.filter(function(value,index){
        return indicesOfColNotAvailable.indexOf(index) == -1;
      })
    }

    function setParam(param) {
      // self.sanitizeParam(param)
      self.deleteColumnInputArray = param.DELETE || [];
      if (!self.deleteColumnInputArray.length) {
        addColumn();
      }
      columnChanged();
    }

    function columnChanged() {
      taskUtils.highlight.deletions(self.deleteColumnInputArray);
    }

    function validate() {
      return true;
    }


  }
}


/**
 * @ngInject
 * */
valColDeletion.$inject = ['utils']
export function valColDeletion(utils) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valColDeletion(scope, elem, attrs, ctrl) {
      ctrl.$validators.valColDeletion = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue){
          var col_info = utils.metadata.get_column_by_internal_name(scope.tvm.manager.metadata, modelValue);
          if (col_info && col_info.hasOwnProperty('error')){
            is_valid =  false
          }
        }
        return is_valid
      };
    }
  };
}


export function deleteColumnDuplicateFilter() {
  return function (cols, selectedColumns, currentIndex) {
    return $.grep(cols, function (col: any) {
      return [-1, currentIndex].indexOf(selectedColumns.indexOf(col.internal_name)) !== -1;
    });
  };
}
