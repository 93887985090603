<template>
  <div>
    <strong>{{ details.id }}. {{ details.name }}</strong>
    <span>
      <input
        type="checkbox"
        :checked="details.enabled"
        @change="enableDisableIntegration(details)"
        :disabled="disabled"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: "AdminInfoIntegrations",
  props: ["details"],
  data: function () {
    return {
      disabled: false,
    };
  },
  methods: {
    enableDisableIntegration: function (payload) {
      this.disabled = true;

      this.$axios
        .post("admin/integrations", {
          integration_id: this.details.id,
          enabled: !this.details.enabled,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              this.$store.dispatch("setIntegrations", null);
              this.$axios.get("admin/integrations").then((response) => {
                payload = {
                  ...payload,
                  enabled: !payload.enabled,
                };

                this.$store.dispatch(
                  "setIntegrations",
                  response.data.integrations
                );
                this.$store.dispatch("setInfo", {
                  page: "admin-info-integrations",
                  payload: payload,
                });
                this.disabled = false;
              });
            }
          },
          (error) => {
            console.error(error);
            this.disabled = false;
          }
        );
    },
  },
};
</script>
<style scoped></style>
