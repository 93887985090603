/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
import {SERVER_FORMAT} from "./scheduler.utils";
import {config} from "../../common/app.config";


/**
 * @ngInject
 */
export function includeScheduler() {
  return {
    templateUrl: config.templates.scheduler,
    scope: {
      schedule: "=ngModel",
      start: "=start",
      dsName: "=dsName",
      origDsName: "=origDsName",
      disableCombineMode: "=disableCombineMode",
      scheduleType: "=scheduleType",
      metadata: "=metadata",
      scheduleRelatedParams : "=scheduleRelatedParams",
      minimumScheduleFrequency : "=minimumScheduleFrequency",
      enableUscReplace : "=enableUscReplace",
      callBackFns: "=callBackFns",
      periodDataStartDate: "=periodDataStartDate"
    },
    restrict: 'AE',
    controller: 'schedulerController',
    controllerAs: 'svm',
    bindToController: true,
    link: function(scope, e, attr){
      scope.$watch('svm.origDsName', function (newValue) {
        scope.svm.updateDsName();
      });
    }
  }
}


/**
 *@ngInject
 */
uibDatepickerWrapper.$inject = ['dateHandler', '$timeout'];
export function uibDatepickerWrapper(dateHandler, $timeout){
  return {
    scope: {
      start:"=start",
      ngModel: "=",
      datepickerOptions: "=",
      onChangeCallback: '&'
    },
    template: '<input class = "calendar" ng-model="dateVal"  datepicker-options="datepickerOptions" />',
    link: function(scope, element, attributes){
      $timeout(function() {
        // flatpickr('.calendar');
        scope.$watch('dateVal', function (value) {
          if (value) {
            scope.ngModel = dateHandler.format(value);
            scope.onChangeCallback();
          }

        });
      },500);
      $timeout(function () {
        scope.$watch('ngModel', function (value) {
          if (value) {
            // if start > value reset start
            scope.dateVal = dateHandler.parse(value);
          }
          else{
            scope.dateVal= new Date();
          }
        });
      },300);

      scope.$watch('end',function(value){

      })
    }
  }
}
uibDatepickerPopupWrapper.$inject = ['dateHandler'];
export function uibDatepickerPopupWrapper(dateHandler) {
  return {
    scope: {
      start: "=start",
      ngModel: "=",
      isOpen : "=",
      datepickerOptions: "="
    },
    template: '<input type="text" ng-model="dateVal" uib-datepicker-popup="yyyy-M-d H:m:s" show-button-bar="false" is-open="calendarPopup" ng-click = "calendarPopup = true" >',
    link: function (scope, element, attributes) {

      scope.$watch('dateVal', function (value) {
        if (value) {
          scope.ngModel = dateHandler.format(value);
        }

      });
      scope.$watch('ngModel', function (value) {
        if (value) {
          // if start> value reset start

          scope.dateVal = dateHandler.parse(value);
        }
      });
      scope.$watch('end', function (value) {

      })
    }
  }
}
function dateParser(value){
  // toDo check for type of value input
  return new Date(value);
}

/**
 * @ngInject
 * @param value
 * @param moment
 * @returns {*}
 */
function dateFormatter(value,moment){
  // check for type of value output
  return moment(value).utc().format(SERVER_FORMAT)
}


