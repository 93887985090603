import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';

/**
 * @ngInject
 */
ExplorePanelFactory.$inject = ['c', 'analyticsService','DataviewLayoutService',
                                'DatasourceService', 'toastNotification'];
export function ExplorePanelFactory(c, analyticsService, DataviewLayoutService,
                                    DatasourceService, toastNotification){
  return ExplorePanel;

  function ExplorePanel(dataview){
    let _wsSwitchEventListener = DataviewLayoutService.dataviewSwitchListeners[dataview.id];
    let ep = this;
    let exploreConfig;
    let defaultCfg = {
      open: false,
      items: []
    };
    ep.config = defaultCfg
    ep.onSwitch = onSwitch;
    ep.init = init;
    ep.setTaskPanel = setTaskPanel;
    ep.handleExploreSecondaryAction = handleExploreSecondaryAction;
    ep.saveFilterCallback = saveFilterCallback;

    ep.toggleExplore = toggleExplore;
    dataview.on_update('from_within_explore_panel_handler' + dataview.id, function(changes){
      _syncCfg()
    });
    dataview.stepPreviewSequenceUpdateEvent.add_callback('sequence_updated_event_explr_pnl_cb', function () {
      if(!dataview?.stepPreview?.inPreviewMode){
        _syncCfg();
      }
    });
    init();


    function setTaskPanel(taskPanel){
      ep.taskPanel = taskPanel;
    }

    function onSwitch(cb){
      _wsSwitchEventListener.add_callback('wsControllerSwitchCbExploreCard' + dataview.id, cb);
    }

    function _syncCfg(){
      if (!angular.equals(exploreConfig, dataview?.user_display_properties?.EXPLORE_PANEL)){
        init();
      }
    }

    function _migrateOldSpec(metadata, items){
      let typeMap = {};
      _.each(metadata, function(c){
        typeMap[c.internal_name] = c.type;
      });
      let newItems = [];
      _.forEach(items, function(item){
        if(typeof item == 'string'){
          if(item == '__custom__'){
            newItems.push({
              isCustomCard: true
            });
          }
          else{
            if(typeMap[item] == c.text){
              newItems.push({
                isCustomCard: false,
                column: item,
                renderAs: ''
              });
            }
            else{
              newItems.push({
                isCustomCard: false,
                column: item,
                renderAs: ''
              });
            }
          }
        }
        else{
          newItems.push(item)
        }
      });
      return newItems;
    }

    function init(){
      exploreConfig = _.cloneDeep(dataview?.user_display_properties?.EXPLORE_PANEL);
      let sys_cols = [];
      if(dataview.sys_cols){
        sys_cols = dataview.sys_cols;
      }
      let _metadata = [];
      if (dataview.metadata){
        _metadata = _.cloneDeep(dataview.metadata);
      }
      let newExploreConfig =  exploreConfig|| defaultCfg;
      if(!angular.equals(newExploreConfig, ep.config)){
        ep.config = newExploreConfig;
      }
      let allIntNames = [];
      angular.forEach((_metadata).concat(sys_cols), function(col){
        allIntNames.push(col.internal_name)
      });
      let items = _migrateOldSpec((_metadata).concat(sys_cols), ep.config.items);
      let newItems = [];
      angular.forEach(ep.config.items, function(c){
        if(c.isCustomCard || allIntNames.indexOf(c.column) !=-1){
          newItems.push(c);
        }
      });
      if(newItems.length == 0){
        ep.config.open = false;
      }
      if(!angular.equals(newItems, ep.config.items)){
        ep.config.items = newItems;
        _save();
      }
    }

    function _save(){
      if(ep.config.items.length == 0){
        ep.config.open = false;
      }
      if(!dataview?.stepPreview?.inPreviewMode && !angular.equals(dataview?.user_display_properties?.EXPLORE_PANEL, ep.config)){
        var patch = {EXPLORE_PANEL: ep.config};
        dataview.setDisplayProperties(patch, true);
      }
    }

    ep.saveExploreConfig = function(items){
      if(!ep.config.item && items){
        ep.config.open = true;
      }
      ep.config.items = items;
      _save();
    };

    function toggleExplore(val){
      if(val === true || val === false){
        ep.config.open = val;
      }
      else{
        ep.config.open = !ep.config.open;
      }
      _save();
      analyticsService.userEventTrack(c.userEvents.exploreCards.exploreToggled,
        {
          eventOrigin: 'dataview.exploreButton',
          state: ep.config.open
        });
    }

    function saveFilterCallback(optype, param?){
      if(optype == 'rule'){
        _submitConditionParams(param.CONDITION);
      }
      else if(optype == 'view'){
        createCustomFilterAsView(param.CONDITION, param.NAME);
      }
      else if(optype == 'dataset'){

      }
    }

    function createCustomFilterAsView(condition, name) {
      DatasourceService.add_dataview(dataview.datasource, dataview.id,
        undefined, condition, name).then(function () {
        toastNotification.success('New View created');
      });
    }

    function handleExploreSecondaryAction(optype, card){
      if(optype == 'replace'){
        submitReplace(card);
        analyticsService.userEventTrack(c.userEvents.exploreCards.replaceAllValuesLikeThis, {eventOrigin: 'dataview.exploreMenu' });
      }
      else if(optype == 'keep'){
        let condition = card.getSelectionCondition();
        _submitConditionParams(condition)
        analyticsService.userEventTrack(c.userEvents.exploreCards.keepAllValuesLikeThis, {eventOrigin: 'dataview.exploreMenu' });
      }
      else if(optype == 'remove'){
        let condition = card.getSelectionCondition();
        condition['FILTER_TYPE'] = 'REMOVE';
        _submitConditionParams(condition)
        analyticsService.userEventTrack(c.userEvents.exploreCards.removeAllValuesLikeThis, {eventOrigin: 'dataview.exploreMenu' });
      }
    }

    function _submitConditionParams(condition) {
      var param = {
        SELECT: "ALL",
        CONDITION: condition,
        DATAVIEW_ID: dataview.id
      };
      ep.taskPanel.open('filter', param);
    }

    function submitReplace(card) {
      if (card.column.type == c.text) {
        var param = {
          REPLACE: {
            SOURCE: [card.column.internal_name],
            MAPPING: [
              {
                SEARCH_VALUE: card.getSelected(),
                REPLACE_VALUE: ''
              }
            ],
            MATCH_CASE: true,
            MATCH_WORDS: true
          },
          DATAVIEW_ID: dataview.id,
          _UI_CUSTOM: 'searchReplace'
        };
        ep.taskPanel.open('bulkReplace', param);
      }
      else if (card.column.type == c.numeric || card.column.type == c.date) {
        let param = {
          SET: {
            VALUES: [{
              VALUE: null,
              CONDITION: card.getSelectionCondition()
            }],
            DESTINATION: card.column.internal_name
          }
        };
        ep.taskPanel.open('insert', param);
      }

      analyticsService.userEventTrack(c.userEvents.exploreCards.replaceTextFromExploreMenu,
        {eventOrigin: 'dataview.exploreMenu'});
    }
  }
}

/**
 * @ngInject
 */
export function preventClickEventPropagationExplore(){
    return {
      link: function(s, e, a){
        $('.explore-wksp-menu').click(function(event){
          event.stopPropagation();
        });
      }
    }
}
