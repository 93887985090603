const getInitialState = () => ({
  users: [
    // {
    //     email: 'user@mammoth.io',
    // }
  ]
})

const getters = {}

const mutations = {
  setUsers: (state, users) => (state.users = users)
}

const actions = {}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
