<template>
  <div class="mm-dataflow-status" :key="`dataflow_status_${dataflowStatusKey}`">
    <mm-tabs v-model="currentTab" :tabs="computedTabs" @input="onTabSelect">
      <template v-for="tab in computedTabs" #[tab.slotName]>
        <component
          :is="tab.component"
          :key="tab.slotName"
          class="p-a-4"
          :actionRequiredQuantity.sync="actionRequiredQuantity"
          :activePipelinesQuantity.sync="activePipelinesQuantity"
          :nonSyncTasksQuantity.sync="nonSyncTasksQuantity"
          :highlighted-view-id="highlightedViewId"
          @submit="$emit('submit')"
        />
      </template>
    </mm-tabs>
  </div>
</template>

<script>
// Components
import DataflowStatusActionRequired from './dataflow-status-action-required/dataflow-status-action-required'
import DataflowStatusActivePipelines from './dataflow-status-active-pipelines/dataflow-status-active-pipelines'
import DataflowStatusNonSyncTasks from './dataflow-status-non-sync-tasks/dataflow-status-non-sync-tasks'

// API
import resourcesApi from '@/modules/resources/api/resources.api'

// Constants
import { SPLITIO_FEATURE_FLAGS, USER_EVENTS } from '@/constants'

// Plugins
import { featuresVisibility } from '@/plugins/splitio'

export default {
  name: 'dataflow-status',
  props: {
    isVisible: Boolean,
    highlightedViewId: [String, Number]
  },
  data: () => ({
    currentTab: 0,
    actionRequiredQuantity: 0,
    activePipelinesQuantity: 0,
    nonSyncTasksQuantity: 0,
    dataflowStatusKey: 0
  }),
  computed: {
    computedTabs() {
      return [
        {
          slotName: 'action_required',
          label: `${this.$t('dataflow_status.action_required.title')} (${this.actionRequiredQuantity})`,
          component: DataflowStatusActionRequired,
          eager: true
        },
        {
          slotName: 'active_pipelines',
          label: `${this.$t('dataflow_status.active_pipelines.title')} (${this.activePipelinesQuantity})`,
          component: DataflowStatusActivePipelines,
          eager: true
        },
        {
          slotName: 'non_sync_tasks',
          label: `${this.$t('dataflow_status.non_sync_tasks.title')} (${this.nonSyncTasksQuantity})`,
          component: DataflowStatusNonSyncTasks,
          eager: true,
          render: () => featuresVisibility[SPLITIO_FEATURE_FLAGS.DATA_SYNC]
        }
      ].filter((tab) => (tab.render ? tab.render() : true))
    }
  },
  watch: {
    isVisible: {
      async handler(val) {
        if (val) {
          this.getPendingPipelineChanges()
          this.dataflowStatusKey++
        }
      },
      immediate: true
    },
    actionRequiredQuantity(val) {
      // Update navbar dataflow status icon badge visibility status
      this.$store.commit('resources/setAreActionsRequiredAvailable', val > 0)
    }
  },
  methods: {
    async getPendingPipelineChanges() {
      const pendingPipelineChanges = await resourcesApi.getPendingPipelineChanges(this.$store.state.projectId)
      this.$store.commit('resources/setPendingPipelineChanges', pendingPipelineChanges)

      if (this.isVisible) setTimeout(() => this.getPendingPipelineChanges(), 3000)
    },
    onTabSelect(tabIndex) {
      this.$userEvents.save(USER_EVENTS.DATAFLOW_STATUS.TAB_SELECT, { tabIndex })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';
@import '@mammoth_developer/mm-storybook/src/styles/spacing.scss';

.mm-dataflow-status {
  .mm-tabs {
    height: inherit;

    ::v-deep .mm-tabs--content-wrapper {
      width: 630px;
      height: 400px;

      .accordions-wrapper {
        @extend .p-a-2;
        flex-direction: column;
        overflow: auto;
        display: flex;
        height: 100%;

        .mm-accordion {
          .mm-accordion--panel {
            flex-direction: column;
            max-height: 100%;
            display: flex;

            .v-expansion-panel-header {
              min-height: 40px;
            }

            .v-expansion-panel-content {
              max-height: 100%;

              .mm-accordion--content {
                overflow: auto;

                .table-wrapper {
                  flex-direction: column;
                  overflow: auto;
                  display: flex;

                  .title-row {
                    ::v-deep .mm-checkbox--label {
                      @extend .mm-text--caption-bold;
                    }
                  }

                  .list-content {
                    overflow: auto;
                  }
                }
              }

              &.expand-transition-enter-active {
                .mm-accordion--content {
                  overflow: hidden;

                  .table-wrapper {
                    overflow: hidden;

                    .list-content {
                      overflow: hidden;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .no-data {
        color: var(--mm-color--n300);
        justify-content: center;
        flex-direction: column;
        place-items: center;
        display: flex;
        height: 100%;
      }
    }

    ::v-deep .mm-accordion--content {
      @extend .p-a-0;
      flex-direction: column;
      display: flex;
      height: 100%;

      .function-row {
        border: solid var(--mm-color--n80);
        background: var(--mm-color--n40);
        border-width: 1px 0px;
      }
    }
  }
}
</style>
