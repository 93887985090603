<template>
  <mm-menu
    v-model="isMenuOpen"
    shadowClass="mm-shadow--heavy-low"
    menuClass="action-bar-transform-menu-content"
    :closeOnContentClick="false"
  >
    <template #activator="{ on }">
      <restrictions-wrapper validate-storage-limit>
        <action-bar-button
          class="action-bar-button--transform"
          :activated="isMenuOpen"
          :label="$t('global.dictionary.transform')"
          :tooltipLabel="computedTooltipLabel"
          :disabled="!!computedTooltipLabel.length"
          icon="transform"
          v-on="on"
        />
      </restrictions-wrapper>
    </template>

    <template #content>
      <div class="transform-menu-search p-a-3">
        <mm-text-input v-model="search" :inputAttrs="{ tabindex: 0 }" type="search" ref="search" hideDetails />
      </div>
      <p
        v-if="search && !computedFilteredItems.length"
        class="p-a-3 m-b-0"
        style="color: var(--mm-color--n300)"
        v-text="$t('action_bar.empty_search')"
      />
      <action-bar-transform-list v-else :items="computedFilteredItems" @action="onAction" />
    </template>
  </mm-menu>
</template>

<script>
// Components
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'
import ActionBarButton from '../action-bar-button/action-bar-button.vue'
import ActionBarTransformList from './action-bar-transform-list.vue'

// Constants
import { USER_EVENTS } from '@/constants'
import { featuresVisibility } from '@/plugins/splitio'

export const TRANSFORM_ITEMS = [
  {
    label: 'label_filter_replace',
    children: ['apply_filter', 'find_and_replace', 'bulk_replace', 'label_insert_values', 'show_top_bottom_rows']
  },
  {
    label: 'reshape_group_aggregate',
    children: [
      'group_and_aggregate',
      'columns_to_rows',
      'remove_duplicate_rows',
      'extract_json',
      'crosstab',
      'concatenate_values'
    ]
  },
  {
    label: 'column_functions',
    children: [
      'create_column',
      'duplicate_column',
      'bulk_duplicate_columns',
      'fill_missing_values',
      'combine_multiple_columns',
      'convert_column_type',
      'remove_column',
      'split_multiple_columns'
    ]
  },
  {
    label: 'number_functions',
    children: ['perform_math_functions', 'obtain_large_or_small_values', 'window_function']
  },
  {
    label: 'text_functions',
    children: ['extract_text', 'text_formatting']
  },
  {
    label: 'date_functions',
    children: ['increment_decrement_date_values', 'extract_date_part', 'calculate_date_difference']
  }
]

export default {
  name: 'action-bar-transform',
  components: {
    ActionBarButton,
    ActionBarTransformList,
    RestrictionsWrapper
  },
  data: () => ({
    featuresVisibility,
    isMenuOpen: false,
    search: ''
  }),
  computed: {
    computedTransformItems() {
      const t = (param) => this.$t(`action_bar.transform_menu.${param}`)

      return TRANSFORM_ITEMS.map(({ label, children }) => ({
        label: t(label),
        children: children
          .map((childLabel) => ({
            label: t(childLabel),
            action: childLabel,
            tags: [t(childLabel).toLowerCase(), ...Object.values(t(`${childLabel}_tags`))],
            children: []
          }))
          .filter((item) => (featuresVisibility[item.action] != undefined ? featuresVisibility[item.action] : true))
      }))
    },
    computedFilteredItems() {
      return this.search
        ? this.computedTransformItems
            .map((item) => item.children)
            .flat()
            .filter(({ tags }) => tags.some((tag) => tag.includes(this.search.toLowerCase())))
        : this.computedTransformItems
    },
    computedTooltipLabel() {
      if (this.$store.state.dataEditor.isPreview) return this.$t(`action_bar.transform.preview_tooltip`)
      return ''
    }
  },
  watch: {
    isMenuOpen(isMenuOpen) {
      if (isMenuOpen) {
        // setimeout required for focus
        // Ref: https://stackoverflow.com/questions/1096436/document-getelementbyidid-focus-is-not-working-for-firefox-or-chrome
        setTimeout(() => this.$refs.search?.$refs.inputField?.focus(), 100)
      } else {
        this.search = ''
      }
    }
  },
  methods: {
    onAction(action) {
      this.$root.legacyHandlers.openTaskOrActionPanel(action)
      this.isMenuOpen = false
      this.search = ''
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.TRANSFORM_ITEM_CLICK, {
        viewId: this.$store.state.dataEditor.viewId
      })
    }
  }
}
</script>

<style lang="scss">
$menu-width: 312px;

.action-bar-transform-menu-content {
  min-width: $menu-width;
  width: $menu-width;
  max-width: $menu-width;

  .transform-menu-search {
    border-bottom: 1px solid var(--mm-color--n50);

    .mm-text-input--field {
      border-color: white;

      &:hover {
        border-color: var(--mm-color--n40);
      }
    }
  }

  .mm-list {
    max-height: 210px;
  }
}
</style>
