import * as angular from "angular";
import * as _ from "lodash-es";

/**
 * @ngInject
 */
removeDuplicatesManagerFactory.$inject = ['utils', 'c'];

export function removeDuplicatesManagerFactory(utils, c) {
    var service = {
        get_manager: get_manager
    };
    return service;

    function get_manager(options) {
        return new removeDuplicatesManager(options);
    }


    function removeDuplicatesManager(options) {
        var self = this;
        var metadata = _.cloneDeep(options.metadata), taskUtils = options.taskUtils;
        self.metadata = utils.sanitizeMetadataName(metadata);
        self.validate = validate;
        self.getParam = getParam;
        self.handlePasteParams = handlePasteParams;
        self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap;
        self.setParam = setParam;
        self.selected_columns = [];

        self.addColumn = addColumn;
        self.removeColumn = removeColumn;
        self.metadata = metadata;
        if (!_.get(options, 'context.inEditMode', false)) {
            let batch_col_display_names = c.batch_table_colums_list.map(function (i) {
                return i.display_name;
            });
            let batch_cols = self.metadata.reduce(function (acc, curr) {
                if (batch_col_display_names.includes(curr.display_name)) {
                    acc.push(curr)
                }
                return acc;
            }, []);
            angular.forEach(batch_cols, function (col) {
                let col_in_wksp = utils.metadata.get_column_by_internal_name(metadata, col["internal_name"]);
                if (col_in_wksp){
                    self.selected_columns.push(col_in_wksp)
                }
            });
        }

        function getParam() {
            var param: any = {
                DISCARD_DUPLICATES: true,
                IGNORE_COLUMNS: self.selected_columns.map(c => c.internal_name)
            };
            if(options.context.hasOwnProperty('sequence')){
              param['SEQUENCE_NUMBER'] = options.context.sequence;
            }
            return param;
        }

        function handlePasteParams(taskInfo){
          /** Update params with suitable replacement columns, based on display name*/
          var params = taskInfo.params;
          for(const index in params.IGNORE_COLUMNS){
            utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.IGNORE_COLUMNS, index , taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
          }
          return params;
        }

        function setParam(param) {
            angular.forEach(param.IGNORE_COLUMNS, function (col) {
                addColumn(utils.metadata.get_column_by_internal_name(metadata, col));
            });
        }


        function addColumn(column) {
            self.selected_columns.push(column);
            _setHighlights();
        }

        function removeColumn(column) {
            var index = self.selected_columns.indexOf(column);
            if (index > -1) {
                self.selected_columns.splice(index, 1);
            }
            _setHighlights();
        }

        function validate() {
            let is_valid = true;
            _.forEach(self.selected_columns,function(select_col){
              if(select_col.hasOwnProperty('error')){
                is_valid = false;
              }
            })
          return is_valid
        }

        function _setHighlights() {
            taskUtils.highlight.sources(self.selected_columns);
        }
    }
}

