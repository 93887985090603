import * as angular from 'angular';
import * as _ from 'lodash-es';

/**
 * @ngInject
 * Action service
 * @returns {}
 */
ActionServiceFactory.$inject = ['$resource', '$q', 'config', 'dataviewConfig', 'Action', 'eventCallbackManagerFactory', 'utils',
                                     'modificationRequestTracker', 'DataviewService', 'DatasourceService', 'toastNotification','c', 'FutureService',
                                     'taskDescriber', 'analyticsService', 'VuexStore'];
export function ActionServiceFactory($resource, $q, config, dataviewConfig, Action, eventCallbackManagerFactory, utils,
                                     modificationRequestTracker, DataviewService, DatasourceService, toastNotification,c, FutureService, 
                                     taskDescriber, analyticsService, $store) {
  var registry = {};
  var factory: any = {};
  factory.get_by_dataview_id = get_by_dataview_id;
  factory.get_unregistered_instance = get_unregistered_instance;

  return factory;

  function get_unregistered_instance(dataview_id) {
    return new ActionService(dataview_id);
  }

  function get_by_dataview_id(dataview_id) {
    if (!registry.hasOwnProperty(dataview_id)) {
      registry[dataview_id] = new ActionService(dataview_id);
    }
    return registry[dataview_id];
  }

  function getColumnMappings(list){
    let columnMapping = `<div class="mt-2">`
    columnMapping += "<b>Branch out columns:</b>";
    for (let source of Object.keys(list)) {
      columnMapping += `<p>${source}: <span class="text-success">${list[source]}</span></p>`
    }
    columnMapping += "</div>"
    return columnMapping;
  }

  function ActionService(dataview_id): void {
    var actionUpdateEvent = new eventCallbackManagerFactory('actionUpdateEvent_' + dataview_id);
    var actionSubmitEvent = new eventCallbackManagerFactory('actionSubmitEvent_' + dataview_id);
    var actionProgressEvent = new eventCallbackManagerFactory('actionProgressEvent_' + dataview_id);
    var publishReportUpdateEvent = new eventCallbackManagerFactory('publishReportUpdateEvent_' + dataview_id);

    var _action_list = {}, _prev_action_data, self = this;
    var actions_resource = $resource(config.api.actions, {ws_id: dataview_id});
    var action_triggers_resource = $resource(config.api.actionTriggers, {ws_id: dataview_id});
    var publish_resource = $resource(config.api.publish, { ws_id: dataview_id });
    var dataview = DataviewService.get_by_id(dataview_id);
    var action_service = this;
    // public methods
    // - list methods
    action_service.update_list = update_list;
    action_service.add_action = add_action;
    action_service.add_publish = add_publish;
    action_service.serverside_validate = serverside_validate;
    action_service.edit_action = edit_action;
    action_service.editDisplayInfo = editDisplayInfo;
    action_service.delete_action = delete_action;
    action_service.delete_publish = delete_publish;
    action_service.onUpdate = actionUpdateEvent.add_callback;
    action_service.onSubmit = actionSubmitEvent.add_callback;
    action_service.actionProgressEvent = actionProgressEvent;
    action_service.onActionProgress = actionProgressEvent.add_callback;
    action_service.publishReportUpdateEvent = publishReportUpdateEvent;
    action_service.onPublishReportUpdate = publishReportUpdateEvent.add_callback;
    action_service.list = _action_list;
    action_service.getSummaryOfActions = actionSummary;
    action_service.linkedActions = [];
    action_service.categorized_lists = {
      pipeline_end: []
    };
    self.desc_resolver = {};
    action_service.copyUrl = copyUrl;
    action_service.url1 = "";
    action_service.firstEmail = "";
    action_service.otherEmails = "";
    action_service.allEmails = "";
    action_service.liveCsvLink = null;
    action_service.getActionObject = getActionObject;
    action_service.allActionsInvisible = allActionsInvisible;
    action_service.getAction = getAction;
    action_service.isSftpEnabled = isSftpEnabled;
    action_service.copyToClipboard = copyToClipboard;
    return action_service;

    function isSftpEnabled() {
      return localStorage.getItem('sftpEnabled') == 'true';
    }

    function copyToClipboard(content) {
      utils.copyToClipboard(content);
      toastNotification.success('Copied to clipboard');
    }

    ////////
    function actionSummary() {
      var saveAsDatasetCount=0;
      var pivotTableCount=0;
      var exportActionCount=0;
      var csvLiveLink = false;
      _.forEach(action_service.list,(action)=>{
        if(action.handler_type=="s3"){
            csvLiveLink = true
        }else if(action.handler_type=="internal_dataset" && action.target_properties.TRANSFORM==null){
            saveAsDatasetCount = saveAsDatasetCount + 1
        }else if(action.handler_type=="internal_dataset" && action.target_properties.TRANSFORM!=null){
          pivotTableCount = pivotTableCount + 1
        }else {
          exportActionCount = exportActionCount+1
        }

      })
      return {pvtCount: pivotTableCount, saveDsCount: saveAsDatasetCount, exportCount: exportActionCount, csvLiveLink: csvLiveLink}
    }
    function _set_updates(actions_data) {
      var existing_action_ids = [];
      action_service.linkedActions = [];
      action_service.categorized_lists.published_actions = [];
      angular.forEach(actions_data, function (action) {
        action.ws_id = dataview_id;
        if (_action_list.hasOwnProperty(action.id)) {
          _action_list[action.id].update(action);
        } else {
          _action_list[action.id] = new Action(action);
           if(action.handler_type=="internal_dataset"){
             DatasourceService.on_post_ds_deletion('refresh_action_description', function(){
               describe(_action_list[action.id]);
             });
           }
        }
        describe(_action_list[action.id]);
        if(!isNaN(_action_list[action.id].targetDatasetId)){
          action_service.linkedActions.push(_action_list[action.id]);
        }
        existing_action_ids.push(action.id);

        if (action.handler_type == 'publishdb'){
          action_service.categorized_lists.published_actions.push(action);
        }
      });

      action_service.categorized_lists.pipeline_end.splice(0);
      angular.forEach(_action_list, function (action: any) {
        if (existing_action_ids.indexOf(action.id) == -1) {
          delete _action_list[action.id];
        } else if (action.sequence === null && action.trigger_type == "pipeline") {
          action_service.categorized_lists.pipeline_end.push(action);
        }     
      });
    }

    function checkForLiveLink(){
      action_service.liveCsvLink = null;
      for(var key in action_service.list){
        var action_info = action_service.list[key]
        if(action_info['handler_type'] == dataviewConfig.actions.s3){
          action_service.liveCsvLinkStatus = action_info['status']
          
          if (action_info.last_run_result){
            action_service.liveCsvLink = action_service.list[key]['last_run_result']['url'];
          }
          else{
            action_service.liveCsvLink = 'Pending'
          }
        }
      }
    }

    function update_list() {
      var deferred = action_triggers_resource.get()
      deferred.$promise.then(action_triggers_resource_success_callback, deferred.reject);

      function action_triggers_resource_success_callback(data){
        if (data.STATUS == "SUCCESS") {
          get_list_success(data);
        }
      }
      function get_list_success(data) {
        var actions_data = data['triggers'];
        _prev_action_data = _.cloneDeep(actions_data);
        _set_updates(actions_data);
        checkForLiveLink();
        actionUpdateEvent.fire_event();
      }

      return deferred.$promise;
    }

    /**
     * Add action method
     */
    function add_action(param) {
      param = _.cloneDeep(param);
      return _add_action(param)
    }

    function add_publish(param, action_panel) {
      var deferred = $q.defer();

      // Once Publish is done, credentials should be shown to user.
      // Success response of Publish contains corresponding publish trigger id.
      // call Open Publish credentials modal with action params
      // corresponding to publish trigger id. 

      publish_resource.save({ 'param': param }).$promise.then(publish_success);

      function publish_success(data) {

        function future_tracker(future) {
          if (future.status == "processing") {
            return;
          }
          if (future.status == "success") {
            action_service.update_list();
            if (future && future.hasOwnProperty('response') &&  future.response && future.response.hasOwnProperty('publish_id')){
              var publish_id = future.response.publish_id;
              // get publish action object, this is required for 
              // rendering content in modal.
              action_service.getAction(publish_id).then(function (publish) {

                action_panel.showCredential(publish);
                action_panel.submit_in_progress = false;
                action_panel.close();
                action_service.actionProgressEvent.fire_event('complete');
                analyticsService.userEventTrack(c.userEvents.publishEvents.addPublish, {
                  eventOrigin: "action_service",
                  status: "success"
                });
                deferred.resolve(future.response);
              });
            }else{
              action_panel.submit_in_progress = false;
              action_panel.close();
              analyticsService.userEventTrack(c.userEvents.publishEvents.addPublish, {
                eventOrigin: "action_service",
                status: "success"
              });
              deferred.resolve(future.response);
            }
          }
          else if (future.status == "error") {
            action_service.update_list();
            action_service.actionProgressEvent.fire_event('error');
            var failure_msg = 'Publish Failed';
            if (future?.response?.message) {
              failure_msg = future?.response?.message;
            }
            toastNotification.error(failure_msg);
            analyticsService.userEventTrack(c.userEvents.publishEvents.addPublish, {
              eventOrigin: "action_service",
              status: "failure"
            });
            deferred.reject(future.response);
          }
          
        }

        if (data.STATUS == 'PROCESSING') {
          if (data.future_id) {
            FutureService.track(data.future_id, future_tracker)
          }
        }
      }

      return deferred.promise;
    }

    function serverside_validate(param) {
      param = _.cloneDeep(param);
      param['validate_only'] = true;
      return _add_action(param);
    }

    function _add_action(param) {
      actionProgressEvent.fire_event('in_progress');
      var deferred = $q.defer();
      actions_resource.save({'param': param}).$promise.then(action_success_callback(param, deferred), function (data) {
        actionProgressEvent.fire_event('error');
        deferred.reject(data);
      });
      return deferred.promise;
    }

    function action_success_callback(param, deferred) {
      function future_tracker(future) {
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(future.response);
          actionProgressEvent.fire_event('complete');
        }
        else if (future.status == "error") {
          actionProgressEvent.fire_event('error');
          deferred.reject(future.response);
        }
        
      }
      return function(data){
        if (data.STATUS == 'PROCESSING') {
          if (data.future_id) {
            FutureService.track(data.future_id, future_tracker)
          }
        }else{
          actionProgressEvent.fire_event('error');
          deferred.reject(data);
        }

      }
    }

    function edit_action(action, param) {
      actionProgressEvent.fire_event('in_progress');
      var deferred = $q.defer();
      action.edit(param).then(action_success_callback(param, deferred), function (data) {
        actionProgressEvent.fire_event('error');
        deferred.reject(data);
      });
      return deferred.promise;
    }

    function editDisplayInfo(action, display_info) {
      return action.editDisplayInfo(display_info);
    }

    function delete_action(action) {
      actionProgressEvent.fire_event('in_progress');
      var deferred = $q.defer();
      action.delete().then(function (data) {
        if (data.STATUS == 'SUCCESS') {
          update_list();
          actionProgressEvent.fire_event('complete');
          deferred.resolve(data);
        }

        else {
          toastNotification.error('Unable to delete the export action');
          actionProgressEvent.fire_event('error');
          deferred.reject(data);
        }
      }, function (data) {
        toastNotification.error('Unable to delete the export action');
        actionProgressEvent.fire_event('error');
        deferred.reject(data);
      });
      return deferred.promise;

    }
    
    function delete_publish(publish) {
      actionProgressEvent.fire_event('in_progress');
      var deferred = $q.defer();
      publish.delete().then(function (data) {
        if (data.STATUS == 'SUCCESS') {
          actionProgressEvent.fire_event('complete');
          deferred.resolve(data);
          update_list();
        }

        // this is specific to publish, action delete is synchronous
        else if (data.STATUS == 'PROCESSING') {
          if (data.future_id){
            FutureService.track(data.future_id, function(future){
              if (future.status == "processing") {
                return;
              }
              else if (future.status == "success") {
                actionProgressEvent.fire_event('complete');
                toastNotification.success('Table deleted from Database');
                deferred.resolve(data);
                update_list();
              }
              else if (future.status == "error") {
                actionProgressEvent.fire_event('error');
                toastNotification.error('Unpublished failed');
                deferred.reject(data);
              }
            });
          }
          analyticsService.userEventTrack(c.userEvents.publishEvents.deletePublish, {
            eventOrigin: "action_service",
            status: data.STATUS
          });
        };
      });
      return deferred.promise;
    }

    function _get_action_properties_object(opname, desc) {
      return {
        "opname": opname,
        "desc": desc,
        "title": dataviewConfig.actionConfig[opname]["list_title"]
      };
    }

    function getActionObject(actionName){
      var actions = _action_list;
      for(var key in actions){
        if(actions[key]["handler_type"] == actionName){
          return actions[key];
        }
      }
    }

    function allActionsInvisible(){
      var actions = _action_list;
      var allActionsInvisible = true;
      for(var key in actions){
        let action = actions[key]
        if (!action?.additional_properties?.invisible){
          allActionsInvisible = false;
        }

        if (action.handler_type == 's3'){
          // Do not consider live link as invisible even if its invisible
          allActionsInvisible = false;
        }
      }
      return allActionsInvisible;
    }

    function getAction(trigger_id){
      var action_trigger_resource = $resource(config.api.actionTriggers, {ws_id: dataview_id, trigger_id: trigger_id});
      var deferred = $q.defer();
      action_trigger_resource.get().$promise.then(function (trigger) {
        trigger['ws_id'] = trigger['dataview_id'];
        deferred.resolve(new Action(trigger));
      }, error_cb);
      function error_cb(trigger) {
        deferred.reject(trigger);
      }
      return deferred.promise;
    }



    function describe(action) {
      if (action.handler_type == "mysql") {
        if (action.target_properties.table == null) {
          action.desc = "Exported data to MySQL" + " (" + action.target_properties.host + "/<br>" + action.target_properties.database + ")";
        } else {
          action.desc = "Exported data to MySQL <b>" + action.target_properties.table + "</b> (" + action.target_properties.host + "/</br>" + action.target_properties.database + ")";
          }

      }
      if (action.handler_type == "mssql") {
        if (action.target_properties.table == null) {
          action.desc = "Exported data to Sql Server" + " (" + action.target_properties.host + "/<br>" + action.target_properties.database + ")";
        } else {
          action.desc = "Exported data to Sql Server <b>" + action.target_properties.table + "</b> (" + action.target_properties.host + "/</br>" + action.target_properties.database + ")";
          }

      }
      if (action.handler_type == "redshift") {
        if (action.target_properties.table == null) {
          action.desc = "Exported data to AWS Redshift" + " (" + action.target_properties.host + "/<br>" + action.target_properties.database + ")";
        } else {
          action.desc = "Exported data to AWS Redshift <b>" + action.target_properties.table + "</b> (" + action.target_properties.host + "/</br>" + action.target_properties.database + ")";
          }

      }
      if (action.handler_type == "postgres") {
        if (action.target_properties.table == null) {
          action.desc = "Exported data to PostgreSQL" + " (" + action.target_properties.host + "/</br>" + action.target_properties.database + ")";
        } else {
          action.desc = "Exported data to PostgreSQL <b>" + action.target_properties.table + "</b> (" + action.target_properties.host + "/</br>" + action.target_properties.database + ")";
          }
      }
      if (action.handler_type == "elasticsearch") {
        action.desc = "Exported data to Elasticsearch index <b>" + action.target_properties.index + "</b> </br> (" + action.target_properties.host + "/</br>" + action.target_properties.username + ")";

      }

      if (action.handler_type == "publishdb") {
        var odbc_display_name = config.odbcTypeToNameMap[action.target_properties.odbc_type];
        action.desc = "Type: " + odbc_display_name + "</br>" +
        "Name: <b>" + action.target_properties.database + "</b></br> " + 
        "Table: <b>"+ action.target_properties.table  + "</b> </br>";
      }
      if (action.handler_type == "powerbi") {
        action.desc = "Exported data to Power Bi <br> dataset <b>" + action.target_properties.dataset + "</b> </br> table <b>" + action.target_properties.table+" </b>" ;
      }
      if (action.handler_type == "bigquery") {
        if (action.target_properties.table == null) {
          action.desc = "Exported data to Google Bigquery" + " (" + action.target_properties.host + "/</br>" + action.target_properties.database + ")";
        }
        else if (action.error_info != undefined  && action.error_info.message != undefined) {
          action.desc = "Exported data to Google Bigquery <b>" + action.target_properties.table.replace(/\s/g, "_") +
            "</b> (" + action.target_properties.host + "/</br>" +
            action.target_properties.database + ")" + "<br>";
        }
        else {
          action.runnable = true;
          let exportDisplay = {
            "COMBINE": "Combine",
            "REPLACE": "Replace",
            "UPSERT": "Merge"
          }
          action.desc = "Exported data to Google Bigquery <b>" + action.target_properties.table + "</b> (" + action.target_properties.host + "/</br>" + action.target_properties.database + ")";
          action.desc += "</br><b>Type:</b> " + (exportDisplay[action.target_properties.exportType] || "Replace");
          if (action.target_properties.exportType == "UPSERT" && action.target_properties.upsertKeys) {
            let keyList = [];
            for (let keyObject of action.target_properties.upsertKeys) {
              keyList.push(keyObject.column.display_name_w_type);
            }
            action.desc += "</br><b>Keys: </b>" + keyList.join(", ");
          }
          if (action.target_properties.partition) {
            action.desc += "</br><b>Partition: </b>" + action.target_properties.partition.FIELD + " (" + action.target_properties.partition.GRANULARITY + ")";
          }
        }
      }
      if (action.handler_type == "email") {
        var allEmails = action.target_properties.emails.split(",");
        var firstEmail = allEmails[0];
        var otherEmails = allEmails.slice(1).join(",\n");
        if(allEmails.length == 1) {
          action.desc = "Sent email to <b> " + firstEmail + "</b> </br>";
        }
        else if(allEmails.length == 2){
          let templateString = `Sent email to <b> ${firstEmail} </b> </br> and <b> ${allEmails.length-1}
            <span uib-tooltip=${otherEmails} tooltip-append-to-body='true' tooltip-placement='right'
            tooltip-class='emailTooltip'> other </span>`;
          action.desc = _.template(templateString)(null);
        }
        else {
          let templateString = `Sent email to <b> ${firstEmail} </b> </br> and <b> ${allEmails.length-1}
            <span uib-tooltip=${otherEmails} tooltip-append-to-body='true' tooltip-placement='right'
            tooltip-class='emailTooltip'> others </span>`;
          action.desc = _.template(templateString)(null);
        }

      }
      if (action.handler_type == "s3") {
        action_service.url1 = action.last_run_result?.url;
        let templateString = ''
        if (action_service.url1){
          templateString = "<span>Link generated: <a class='copy-link' ng-click=\"sp.actionService.copyUrl(sp.actionService.url1)\">Copy</a></span>";
        }else{
          templateString = "<span>Link yet to be generated</span>";

        }
        let templateData = {};
        action.desc = _.template(templateString)(templateData);
      }
      if (action.handler_type == "sftp") {
        let exported_file_path = action.target_properties?.exported_file_path; 
        if (!exported_file_path){
          exported_file_path = action.target_properties.directory
        }
        
        // let templateString = "<span>Exported data to SFTP server at " + exported_file_path + "<b>"
        //   "<a class='copy-link' ng-click=\"sp.actionService.copyIt(exported_file_path)\">Copy</a> </b> </span>";
        let templateData = {};
        let templateString = `<span>Exported data to SFTP server at <a class='copy-link' ng-click=sp.actionService.copyToClipboard('${exported_file_path}')>${exported_file_path}</a></span>`;
        action.desc = _.template(templateString)(templateData);
        
      }
      if ([c.actions.sftp, c.actions.mysql, c.actions.postgres, c.actions.redshift, c.actions.elasticsearch, c.actions.mssql, c.actions.bigquery].indexOf(action.handler_type)!=-1){
       let error_info = "";
        let message = _.get(action, 'error_info.message'); 
        let error = _.get(action, 'error_info.error'); 
        if (action.handler_type == c.actions.bigquery && !message && !error) {
          message = _.get(action, 'error_info.message')
          error = _.get(action, 'error_info.error')
        }
        if (message) {
          error_info = message + ": ";
        }
        if (error && error != message) {
          error_info += error;
        }
        
        let templateStringErr = "<span>" + error_info + "</span>"; 
        let templateData = {};
        action.err_desc = _.template(templateStringErr)(templateData);
        
        if (error_info) {
          action.error_info = action.err_desc;
        }
      }

      if(action.handler_type == 'internal_dataset'){
        let templateData = {};
        let templateDataErr = {};
        let templateString = "Saved data in ";
        let templateStringErr = "";
        let templateCondition = "";
        let export_project = action.target_properties?.export_project;


        var wk_id = action.ws_id;
        if(action.hasOwnProperty('condition') && Object.keys(action.condition).length > 0){
          var wksp = DataviewService.get_by_id(wk_id);
          var pMetadata = utils.metadata.applyDisplayChangesReturnMetadata(wksp.metadata, wksp.display_properties, [], {}, false);
          pMetadata = utils.metadata.add_type_to_display_name(pMetadata);
          if(_.get(action, 'error_info.additional_info.reference_errors')){
            var reference_errors = _.get(action, 'error_info.additional_info.reference_errors');
            pMetadata = utils.metadata.add_error_columns_to_metadata(pMetadata, reference_errors, wksp.id);
          }
          templateCondition = '<br>Condition: ' + taskDescriber.conditionDescriber.describeCondition(pMetadata, action.condition, false, action.execution_start_time);
        }
        if (action.target_properties.TRANSFORM && action.target_properties.TRANSFORM.CROSSTAB) {
          action.nameOverride = 'Crosstab';
          templateString = "Cross tabulated data is saved to a new Dataset ";
        }
        if (action.target_properties.hasOwnProperty('TARGET_DS_ID')) {
          let target_ds_id = action.target_properties.TARGET_DS_ID; 
          if (export_project) {
            action.nameOverride = 'Branch out to Project';

            action.targetDatasetId = target_ds_id;
            action.targetDatasetName = action.target_properties.DS_NAME;
            const currWorkspace = $store.state.workspaces.filter((workspace) => workspace.id == $store.state.workspaceId)[0];
            const target_project = currWorkspace.projects.filter(project => project.id == action.target_properties.project_id)[0];
            let project_name = 'Project (access denied)';
            if (target_project)
            {
              project_name = utils.string.addCentreEllipsis(target_project.name, 20);
            }
            templateData['ds_name'] = utils.string.addCentreEllipsis(action.target_properties.DS_NAME, 20);
            templateData['project_name'] = project_name
            templateString = "Exported data to <b> ${project_name} </b> <br> dataset <b> ${ds_name} </b>";
            templateString += templateCondition;

          }
          else {
            if (Number.isInteger(target_ds_id)) {
              let ds = DatasourceService.get_by_id(target_ds_id);
              if (ds) {
                action.targetDatasetId = target_ds_id;
                action.targetDatasetName = ds.name;
                templateData['ds_name'] = utils.string.addCentreEllipsis(ds.name, 20);
                templateData['ds'] = ds;
                ds.sanitizedName = escape(unescape(ds.name));
                templateString += (': ' + "<a class=\"target-ds\" open-first-dataview-by-ds-id ds-id=\"${ds.id}\" ds-name=\"'${ds.sanitizedName}'\"></a>");
                templateString += templateCondition;
                if (action.target_properties.COLUMN_MAPPING) {
                  templateString += getColumnMappings(action.target_properties.COLUMN_MAPPING);
                }

              }
              else {
                templateString = 'The result dataset has been deleted.';
                if (action.target_properties.COLUMN_MAPPING) {
                  templateString += getColumnMappings(action.target_properties.COLUMN_MAPPING);
                }
                action.runnable = false;

              }

            }
            else {
              templateString += action.target_properties.DS_NAME;
              if (action.target_properties.COLUMN_MAPPING) {
                templateString += getColumnMappings(action.target_properties.COLUMN_MAPPING);
              }
            }
          }
          if (action.runnable == false && action.error_info?.error_info?.ERROR_CODE) {
            if (action.error_info.error_info.ERROR_CODE == 153) {
              if (action.error_info.additional_info.hasOwnProperty('error_code')){
                var err_msg = {
                  description: "",
                  row_count : 0,
                  error_descriptions: {}
                };
                var wksp = DataviewService.get_by_id(action.ws_id);
                err_msg = taskDescriber.describeErrors(err_msg, action.error_info.additional_info, wksp.display_properties);
                var error_description = "";
                if (_.get(err_msg,'error_descriptions.type_mismatch')){
                  error_description += _.get(err_msg,'error_descriptions.type_mismatch') + '<br>';
                }
                if (_.get(err_msg,'error_descriptions.column_missing')){
                  error_description += _.get(err_msg,'error_descriptions.column_missing') + '<br>';
                }
                if (_.get(err_msg,'error_descriptions.view_error')){
                  error_description += _.get(err_msg,'error_descriptions.view_error');
                }
              }
                error_description = error_description || "";
                templateDataErr['err_msg'] = error_description;
                templateDataErr['err_msg'] += c.errorCodetoMessageMap[153];
                templateStringErr = ("<span>${err_msg}</span>");
            }
            if (action.error_info.error_info.ERROR_CODE == 154) {
              templateDataErr['err_msg'] = c.errorCodetoMessageMap[154];
              templateStringErr = ("<span>${err_msg}</span>");
            }
            if (action.error_info.error_info.ERROR_CODE == 1613) {
              templateDataErr['err_msg'] = c.errorCodetoMessageMap[1613];
              templateStringErr = ("<span>${err_msg}</span>");
            }
            if (action.error_info.error_info.ERROR_CODE == 7011) {
              templateDataErr['err_msg'] = c.errorCodetoMessageMap[7011];
              templateStringErr = ("<span>${err_msg}</span>");
            }
            if (action.error_info.error_info.ERROR_CODE == 159) {
              templateDataErr['err_msg'] = c.errorCodetoMessageMap[159];
              templateStringErr = ("<span>${err_msg}</span>");
            }
            if (action.error_info.error_info.ERROR_CODE == 139) {
              templateDataErr['err_msg'] = c.errorCodetoMessageMap[139];
              templateStringErr = ("<span>${err_msg}</span>");
            }
            action.err_desc = _.template(templateStringErr)(templateDataErr);
          }

          if (action.runnable && action.error_info){
            templateDataErr['err_msg'] = c.errorCodetoMessageMap[999]
            templateStringErr = ("<span>${err_msg}</span>");
            action.err_desc = _.template(templateStringErr)(templateDataErr);
          }

          action.desc = _.template(templateString)(templateData);
        }
      }
      action.desc = "<span>" + action.desc + "</span>";

    }

  function copyUrl(url){
      utils.copyToClipboard(url);
      toastNotification.success('Copied to clipboard');
    }


  }

}