import * as $ from 'jquery';
import * as angular from 'angular';
import * as _ from 'lodash-es';


incrementDateManagerFactory.$inject = ['destinationColumnManagerFactory', 'filterManagerFactory', 'utils', 'filterUI', 'c', 'FilterEditHelperFactory'];
export function incrementDateManagerFactory(destinationColumnManagerFactory, filterManagerFactory, utils, filterUI, c, FilterEditHelperFactory) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new IncrementDateManager(options);
  }

  function IncrementDateManager(options) {
    var self = this;
    var metadata = options.metadata, taskUtils = options.taskUtils, dataview = options.context.dataview;
    self.getParam = getParam;
    self.validate = validate;
    self.context = options.context;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap;
    self.setParam = setParam;
    self.handlePasteParams = handlePasteParams;
    self.sanitizeParam = sanitizeParam;
    self.openConditionMenu = openConditionMenu;
    self.openConditionModal = openConditionModal;
    self.sourceColumnSelected = sourceColumnSelected;

    
    self.metadata = utils.sanitizeMetadataWithType(metadata);
    self.filterEditHelper = new FilterEditHelperFactory();
    self.filterEditHelper.onMaximizeFilterPopoverToModal('maximizeFilterPopoverToModal',  self.openConditionModal)
    self.filterManager = filterManagerFactory.get_manager({
      metadata: metadata,
      dataviewId: options.context.dataview.id,
      context: options.context,
      originalSequenceNumber: options.originalSequenceNumber
    });
    self.destinationManager = destinationColumnManagerFactory.get_manager({
      metadata: metadata,
      allowedTypes: ['DATE'],
      taskUtils: options.taskUtils,
      isDestinationFormatterVisible: true
    });
    self.add_sub_option = "add";
    self.source_columns = $.grep(self.metadata, function (col: any) {
      return col.type === c.date;
    });
    self.param = {
      'INCREMENT_DATE': {
        'DELTA': {}
      }
    };

    function openConditionMenu() {
      // filterUI.open(self.filterManager);
      self.filterEditHelper.open(self.filterManager);
    }

    function openConditionModal(param=null){
      filterUI.open(self.filterManager, param);
    }

    function validate() {
      let is_valid = true;
      if (self.filterManager.condition){
        is_valid = self.filterManager.condition.validate()
      }
      if(self.destinationManager){
        is_valid = is_valid && self.destinationManager.validate();
      }
      return is_valid;
    }

    function getParam() {
      var param = _.cloneDeep(self.param);
      _get_negative_values(param);
      var destination_param = self.destinationManager.getParam();
      delete param.INCREMENT_DATE.DESTINATION;
      delete param.INCREMENT_DATE.AS;
      if (destination_param.hasOwnProperty('AS') && self.context.inEditMode==true  &&  self.context.task){
        utils.sanatizeParamForDuplicateCols(destination_param['AS'], 'INTERNAL_NAME', self.context.task)
      }
      angular.extend(param.INCREMENT_DATE, destination_param);

      if (self.filterManager.condition) {
        param.CONDITION = self.filterManager.getParam();
      } else {
        delete self.param.CONDITION;
      }
      if(options.context.hasOwnProperty('sequence')){
        param['SEQUENCE_NUMBER'] = options.context.sequence
      }
      return param;
    }

    function handlePasteParams(taskInfo){
      /** Update params with suitable replacement columns, based on display name*/

      var params = taskInfo.params;
      //Condition params
      var columnUsedInCondition = utils.get_input_columns(_.cloneDeep(params.CONDITION))
      if (columnUsedInCondition) {
        utils.metadata.findReplaceColumnsInCondition(self.metadata, self.displayNameAndTypeToColumnMap, params, columnUsedInCondition, taskInfo)
      }
      //Destination params
      if (params.INCREMENT_DATE.hasOwnProperty('DESTINATION')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.INCREMENT_DATE, 'DESTINATION', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }
      //Source params
      utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.INCREMENT_DATE, 'SOURCE', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);

      //Update destination and filter manager metadata
      self.destinationManager.metadata = self.metadata
      self.destinationManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(metadata)
      self.filterManager.metadata = self.metadata
      return params;
    }

    function _get_negative_values(param) {
      if (self.add_sub_option === "subtract") {
        angular.forEach(param.INCREMENT_DATE.DELTA, function (val, key) {
          param.INCREMENT_DATE.DELTA[key] = (val * -1);
          if (val === 0 || val == null) {
            delete param.INCREMENT_DATE.DELTA[key];
          }
        });
      } else {
        angular.forEach(param.INCREMENT_DATE.DELTA, function (val, key) {
          if (val === 0 || val == null) {
            delete param.INCREMENT_DATE.DELTA[key];
          }
        });
      }

    }

    function sourceColumnSelected() {
      taskUtils.highlight.sources(self.param.INCREMENT_DATE.SOURCE);
      var source_column_format = dataview.display_properties.FORMAT_INFO[self.param.INCREMENT_DATE.SOURCE];
      if (!source_column_format) {
        let column = _.find(self.metadata, function (c) {
          return c.internal_name == self.param.INCREMENT_DATE.SOURCE;
        });
        source_column_format = column['format'];
      }
      self.destinationManager.setDestinationFormat(source_column_format);
    }
    function sanitizeParam(param){
      let col_info = utils.metadata.get_column_by_internal_name(self.metadata, param.INCREMENT_DATE.SOURCE);
      if(!col_info){
        param.INCREMENT_DATE.SOURCE = undefined;
      }else if(col_info['type']!=='DATE'){
        param.INCREMENT_DATE.SOURCE = undefined;
      }
    }
    function setParam(param) {
      // self.sanitizeParam(param);
      self.param.INCREMENT_DATE.SOURCE = param.INCREMENT_DATE.SOURCE;
      var sourceColumnInternalNames = _.map(self.source_columns,'internal_name')
      if (sourceColumnInternalNames.indexOf(self.param.INCREMENT_DATE.SOURCE) == -1){
        var src_col = utils.metadata.get_column_by_internal_name(metadata, self.param.INCREMENT_DATE.SOURCE)
        self.source_columns.push(src_col)
      }
      if (param.INCREMENT_DATE.DELTA) {
        _set_negative_values(param);
        self.param.INCREMENT_DATE.DELTA = param.INCREMENT_DATE.DELTA;
      }
      self.destinationManager.setParam(param.INCREMENT_DATE);

      if (param.hasOwnProperty('CONDITION')) {
        self.filterManager.setParam(param.CONDITION, param?.EXECUTION_TIMESTAMP);
      }
      sourceColumnSelected();
    }

    function _set_negative_values(param) {
      var atleast_one_negative = false;
      var neg_delta = {};
      angular.forEach(param.INCREMENT_DATE.DELTA, function (val, key) {
        neg_delta[key] = val * -1;
        if (val < 0) {
          atleast_one_negative = true;
        }
      });
      if (atleast_one_negative) {
        param.INCREMENT_DATE.DELTA = neg_delta;
        self.add_sub_option = "subtract";
      }
    }
  }
}


valIncrDecrCol.$inject = ['utils'];
export function valIncrDecrCol(utils) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valIncrDecrCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valIncrDecrCol = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue){
          var col_info = utils.metadata.get_column_by_internal_name(scope.tvm.manager.metadata, modelValue)
          if( col_info.hasOwnProperty('error')){
            is_valid =  false
          }
          else if( col_info.type != 'DATE'){
            is_valid =  false
          }
        }
        return is_valid
      };
    }
  };
}
