<template>
  <mm-modal
    v-model="computedIsModalOpen"
    :title="$t('view.dataflow_settings.data_sync_options.title')"
    content-class="mm-data-sync-options"
    width="685"
    closable
  >
    <mm-tooltip wrapper-class="m-b-7" :label="getResourcePath(computedResource.resourceId).join(' / ')">
      <span>{{ $store.getters['resources/getDatasetByViewId'](viewId).properties.name }}</span> -
      <span>{{ computedResource.properties.name }}</span>
    </mm-tooltip>

    <mm-tabs v-model="currentTab" :tabs="tabs" @input="onTabSelect">
      <template #[computedSelectedTab]>
        <div class="p-a-5 width-100">
          <span> {{ $t(`view.dataflow_settings.data_sync_options.${computedSelectedTab}.description`) }} </span>

          <div class="options-table mm-rounded-borders m-t-6">
            <div class="p-x-5 p-y-2 d-flex">
              <span class="mm-text--h300">
                {{ $t(`view.dataflow_settings.data_sync_options.${computedSelectedTab}.title`) }}
              </span>
              <mm-spacer />
              <restrictions-wrapper validate-storage-limit>
                <mm-button
                  :label="$t('data_library.views.set_sync_on')"
                  icon="sync"
                  objective="tertiary"
                  size="small"
                  :disabled="!computedIsAnyDependencySelected"
                  @click="toggleDataSync(true)"
                />
              </restrictions-wrapper>
              <restrictions-wrapper validate-storage-limit>
                <mm-button
                  class="m-l-4"
                  :label="$t('data_library.views.set_sync_off')"
                  icon="sync_disabled"
                  objective="tertiary"
                  size="small"
                  :disabled="!computedIsAnyDependencySelected"
                  @click="toggleDataSync(false)"
                />
              </restrictions-wrapper>
            </div>

            <mm-divider />

            <mm-row no-gutters class="title-row mm-text--caption-bold p-y-3 p-l-5 p-r-6">
              <mm-col cols="5">
                <mm-checkbox
                  v-model="computedAreAllDependenciesSelected"
                  class="m-l-1"
                  :indeterminate="computedIsAnyDependencySelected"
                  :disabled="!computedDependencies[computedSelectedTab].length"
                  :label="$t(`global.dictionary.${computedSelectedTab == 'in' ? 'source' : 'destination'}`)"
                />
              </mm-col>
              <mm-col cols="2"> {{ $t('view.dataflow_settings.data_sync_options.type') }} </mm-col>
              <mm-col cols="3"> {{ $t('data_library.views.last_update') }} </mm-col>
              <mm-col cols="2" class="d-flex" style="justify-content: center">
                <mm-tooltip :label="$t('data_library.views.ds_tooltip')">
                  <span class="mm-text--caption-bold"> {{ $t('data_library.views.data_sync') }} </span>
                </mm-tooltip>
              </mm-col>
            </mm-row>

            <mm-divider />

            <div class="dependencies-list overflow-auto">
              <span
                v-if="!computedDependencies[computedSelectedTab].length"
                class="mm-text--caption-regular d-flex m-y-3"
                style="justify-content: center"
              >
                {{ $t(`view.dataflow_settings.data_sync_options.${computedSelectedTab}.no_data`) }}
              </span>

              <div
                v-for="(dependency, idx) in computedDependencies[computedSelectedTab]"
                :key="`options_${dependency.operationId}_${dependencyRowKey}_${idx}`"
              >
                <mm-divider v-if="idx != 0" />

                <mm-row no-gutters class="p-y-4 p-l-5 p-r-6 align-items-center" @click="toggleRow(dependency)">
                  <mm-col cols="5" class="col-overflow">
                    <div class="d-flex align-items-center">
                      <mm-checkbox
                        v-model="
                          dependenciesSelection[computedSelectedTab][dependency.operationId + dependency.operationName]
                        "
                        class="m-l-1"
                        @click="toggleRow(dependency)"
                      />
                      <mm-tooltip
                        v-if="$store.state.resources.resourcesMap[dependency.resourceId]"
                        :label="getResourcePath(dependency.resourceId).join(' / ')"
                        wrapper-class="p-y-2 p-r-2"
                      >
                        <div class="d-flex">
                          <div
                            v-if="$store.state.resources.resourcesMap[dependency.resourceId].viewId"
                            class="resource-path"
                          >
                            <span class="mm-text--caption-regular">
                              {{
                                $store.getters['resources/getDatasetByViewResourceId'](dependency.resourceId).properties
                                  .name
                              }}
                              -
                            </span>
                          </div>
                          <div v-if="dependency.operationName == RESOURCE_DEPENDENCIES.VIEW" class="d-flex">
                            <span class="resource-path mm-text--caption-regular">
                              {{ $store.state.resources.resourcesMap[dependency.resourceId].properties.name }}
                            </span>
                            <span class="secondary-text mm-text--caption-regular">
                              ({{ $t('global.dictionary.this_dataset') }})
                            </span>
                          </div>
                          <mm-link
                            v-else
                            class="m-l-1"
                            :label="$store.state.resources.resourcesMap[dependency.resourceId].properties.name"
                            small
                            @click.stop="openResource(dependency)"
                          />
                        </div>
                      </mm-tooltip>
                      <mm-tooltip
                        v-else-if="dependency.operationName === RESOURCE_DEPENDENCIES.BRANCH_OUT_TO_PROJECT"
                        :label="getDependencyProjectName(dependency)"
                        wrapper-class="p-y-2 p-r-2"
                      >
                        <div class="d-flex">
                          <div class="resource-path">
                            <span class="mm-text--caption-regular">
                              {{ getDependencyProjectName(dependency) }}
                            </span>
                          </div>
                        </div>
                      </mm-tooltip>
                      <mm-link
                        v-else-if="dependency.operationName === RESOURCE_DEPENDENCIES.LIVE_LINK"
                        class="m-r-3"
                        :label="dependency.url"
                        :href="dependency.url"
                        small
                        @click.stop=""
                      />
                      <mm-tooltip v-else :label="getExportTooltip(dependency)" wrapper-class="p-r-2">
                        <div class="d-flex">
                          <mm-icon class="m-r-2" :name="getConnector(dependency.dbType).smallIcon" />
                          <span class="mm-text--caption-regular"> {{ getConnector(dependency.dbType).name }} </span>
                        </div>
                      </mm-tooltip>
                    </div>
                  </mm-col>
                  <mm-col cols="2">
                    <span class="mm-text--caption-regular">
                      {{
                        $tc(
                          `global.dictionary.${
                            RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP[dependency.operationName] ||
                            dependency.operationName.toLowerCase()
                          }`
                        )
                      }}
                    </span>
                  </mm-col>
                  <mm-col cols="3" class="col-overflow">
                    <mm-tooltip :label="moment.utc(getDependencyUpdateDate(dependency)).local().format('lll')">
                      <span class="mm-text--caption-regular">
                        {{ getFromNowDate(getDependencyUpdateDate(dependency)) }}
                      </span>
                    </mm-tooltip>
                  </mm-col>
                  <mm-col cols="2" style="text-align: center">
                    <mm-icon
                      :name="
                        (typeof dependency.dataSyncTemp == 'boolean' ? dependency.dataSyncTemp : dependency.dataSync)
                          ? 'check'
                          : 'sync_disabled'
                      "
                    />
                  </mm-col>
                </mm-row>
              </div>
            </div>
          </div>
        </div>
      </template>
    </mm-tabs>

    <template #actions>
      <mm-button :label="$t('global.dictionary.cancel')" objective="tertiary" @click="computedIsModalOpen = false" />
      <mm-button
        class="m-l-3"
        :label="$t('global.dictionary.apply_changes')"
        :disabled="isSubmitDisabled"
        :loading="isSubmitLoading"
        @click="submit"
      />
    </template>
  </mm-modal>
</template>

<script>
// Constants
import {
  RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP,
  USER_EVENTS,
  EXPORT_CONNECTORS,
  RESOURCE_DEPENDENCIES,
  getOldAppUrl
} from '@/constants'

// Dependencies
import moment from 'moment'
import Vue from 'vue'

// Filters
import { getFromNowDate } from '@/utils/filters'

// API
import dataEditorApi from '@/modules/data-editor/api/data-editor.api'

//Utils
import { getDependencyProjectName, getResourcePath } from '@/modules/resources/utils'
import { getExportTooltip } from '@/utils/string'

// Components
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

export default {
  name: 'data-sync-options',
  components: { RestrictionsWrapper },
  props: {
    value: Boolean,
    viewId: {
      type: [String, Number],
      required: true
    }
  },
  data: () => ({
    currentTab: 0,
    dependenciesSelection: { in: {}, out: {} },
    dependencyRowKey: 1,
    isSubmitDisabled: true,
    isSubmitLoading: false,
    moment
  }),
  created() {
    this.tabs = [
      {
        label: this.$t('view.dataflow_settings.data_sync_options.in.title'),
        slotName: 'in'
      },
      {
        label: this.$t('view.dataflow_settings.data_sync_options.out.title'),
        slotName: 'out'
      }
    ]

    this.RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP = RESOURCE_DEPENDENCIES_TRANSLATIONS_MAP
    this.RESOURCE_DEPENDENCIES = RESOURCE_DEPENDENCIES
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    computedSelectedTab() {
      return this.tabs[this.currentTab].slotName
    },
    computedResource() {
      return this.$store.getters['resources/getViewByViewId'](this.viewId)
    },
    computedDependencies() {
      return this.computedResource.dependencies
    },
    computedIsAnyDependencySelected() {
      // Stating 'dependencyRowKey' here so that this computed property updates every time this variable changes.
      // https://stackoverflow.com/questions/48700142/vue-js-force-computed-properties-to-recompute
      return (
        this.dependencyRowKey &&
        Object.values(this.dependenciesSelection[this.computedSelectedTab]).some((value) => value)
      )
    },
    computedAreAllDependenciesSelected: {
      get() {
        // Stating 'dependencyRowKey' here so that this computed property updates every time this variable changes.
        // https://stackoverflow.com/questions/48700142/vue-js-force-computed-properties-to-recompute

        const dependenciesSelectionValues = Object.values(this.dependenciesSelection[this.computedSelectedTab])

        return (
          this.dependencyRowKey &&
          dependenciesSelectionValues.length > 0 &&
          dependenciesSelectionValues.length == this.computedDependencies[this.computedSelectedTab].length &&
          dependenciesSelectionValues.every((value) => value)
        )
      },
      set(isSelected) {
        this.dependenciesSelection[this.computedSelectedTab] = this.computedDependencies[this.computedSelectedTab]
          .map((d) => d.operationId + d.operationName)
          .reduce((o, k) => ({ ...o, [k]: isSelected }), {})
      }
    }
  },
  watch: {
    computedIsModalOpen(val) {
      if (!val) {
        this.currentTab = 0
        this.isSubmitDisabled = true
        this.computedAreAllDependenciesSelected = false
        this.dependenciesSelection = { in: {}, out: {} }
        for (let direction of ['in', 'out']) this.computedDependencies[direction].forEach((d) => delete d.dataSyncTemp)
      }
    }
  },
  methods: {
    getConnector(key) {
      return EXPORT_CONNECTORS.find((c) => c.key == key)
    },
    toggleRow(dependency) {
      Vue.set(
        this.dependenciesSelection[this.computedSelectedTab],
        dependency.operationId + dependency.operationName,
        !this.dependenciesSelection[this.computedSelectedTab][dependency.operationId + dependency.operationName]
      )

      this.dependencyRowKey++
    },
    getDependencyUpdateDate(dependency) {
      let resource
      if (dependency.resourceId && this.$store.state.resources.resourcesMap[dependency.resourceId])
        resource = this.$store.state.resources.resourcesMap[dependency.resourceId]
      else resource = this.computedResource
      return resource?.properties.updatedAt
    },
    getDependencyProjectName,
    openResource(dependency) {
      // TODO: remove when angular code is gone
      const redirectUrl = `${getOldAppUrl()}/#/workspaces/${this.$store.state.workspaceId}/projects/${
        this.$store.state.projectId
      }/dataviews/${dependency.linkId}`
      window.open(redirectUrl, `_mammoth_ds_${dependency.resourceDatasetId}`)

      if (window.name == `_mammoth_ds_${dependency.resourceDatasetId}`) this.computedIsModalOpen = false
    },
    toggleDataSync(dataSyncStatus) {
      this.$userEvents.save(USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.DATA_SYNC_OPTIONS.TOGGLE_DATA_SYNC, {
        dataSyncStatus
      })

      this.computedDependencies[this.computedSelectedTab].forEach((d) => {
        const selectedIds = Object.keys(this.dependenciesSelection[this.computedSelectedTab]).filter(
          (k) => this.dependenciesSelection[this.computedSelectedTab][k]
        )

        if (selectedIds.includes(d.operationId + d.operationName)) d.dataSyncTemp = dataSyncStatus
      })

      this.dependencyRowKey++
      this.isSubmitDisabled = false
    },
    async submit() {
      this.isSubmitLoading = true

      let toggledDataSyncDependencies = []
      for (let direction of ['in', 'out'])
        toggledDataSyncDependencies.push(
          ...this.computedDependencies[direction].filter(
            (d) => typeof d.dataSyncTemp == 'boolean' && d.dataSyncTemp != d.dataSync
          )
        )

      if (toggledDataSyncDependencies.length) {
        const dependenciesPayload = toggledDataSyncDependencies.map((d) => ({
          id: d.operationId,
          type: d.operationType,
          status: d.dataSyncTemp
        }))
        await dataEditorApi.toggleStepsDataSyncStatus(dependenciesPayload, null, 'status')
        toggledDataSyncDependencies.forEach((d) => (d.dataSync = d.dataSyncTemp))
      }

      this.$toast.show({ content: this.$t('view.dataflow_settings.data_sync_options.success'), status: 'success' })
      this.computedIsModalOpen = false

      this.$userEvents.save(USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.DATA_SYNC_OPTIONS.SUBMIT, {
        toggledDataSyncDependencies
      })

      this.isSubmitLoading = false
      this.$emit('submit')
    },
    onTabSelect(tabIndex) {
      this.$userEvents.save(USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.DATA_SYNC_OPTIONS.TAB_SELECT, { tabIndex })
    },
    getFromNowDate,
    getResourcePath,
    getExportTooltip
  }
}
</script>

<style lang="scss" scoped>
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';
@import '@mammoth_developer/mm-storybook/src/styles/spacing.scss';

.mm-data-sync-options {
  .options-table {
    border: 1px solid var(--mm-color--n80);

    .title-row {
      align-items: center;

      ::v-deep .mm-checkbox--label {
        @extend .mm-text--caption-bold;
      }
    }

    .dependencies-list {
      max-height: 250px;

      .mm-row {
        min-height: 47px;
        cursor: pointer;

        &:hover {
          background: var(--mm-color--n30);
        }

        .secondary-text {
          color: var(--mm-color--n500);
        }

        .resource-path,
        .mm-link {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .resource-path {
          max-width: 100px;
        }

        .col-overflow {
          ::v-deep .mm-tooltip--slot-wrapper {
            @extend .m-r-3;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            cursor: default;
          }
        }
      }
    }
  }
}
</style>
