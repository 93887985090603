import * as angular from 'angular';
import { AuthService, HttpRequestInterceptor } from './auth.service';
import { UserWorkspaceService } from './userWorkspace.service';
import { ClientAppsService } from './clientApps.service';
import { featureAvailability, onRun } from './feature.availibility';
import { DatasourceService } from './datasource.service';
import { resources } from './resource.service';
import { DataviewService } from './dataview.service';
import { FutureService } from './future.service';
import { FileService } from './file.service';
import { NotificationService } from './notifications.service';
import { webhooks } from './webhooks.service';
import { derivativeBackend } from './derivatives.resources';
import { labelService } from './label.service';
import { modelsModule } from "../models/index";
import {localService} from './local.service';
import 'angular-cookies';
import { AlertServiceFactory } from './alertService.factory';
import { UserEventsService } from "./userEvents.service";
import {ClipboardService} from './clipboard.service';

export let coreModule = angular.module('app.core', [modelsModule.name, 'ui-notification', 'ui.router', 'ngCookies']);
coreModule.run(onRun);
coreModule.service('Auth', AuthService);
coreModule.factory('HttpRequestInterceptor', HttpRequestInterceptor);
coreModule.service('UserWorkspace', UserWorkspaceService);
coreModule.service('ClientApps', ClientAppsService);
coreModule.service('UserEvents', UserEventsService);
coreModule.service('featureAvailability', featureAvailability);
coreModule.service('DataviewService', DataviewService);
coreModule.service('DatasourceService', DatasourceService);
coreModule.service('resources', resources);
coreModule.service('FutureService', FutureService);
coreModule.service('FileService', FileService);
coreModule.service('NotificationService', NotificationService);
coreModule.service('webhooks', webhooks);
coreModule.service('derivatives', derivativeBackend);
coreModule.service('localService', localService);
coreModule.service('labelService', labelService);
coreModule.service('AlertServiceFactory', AlertServiceFactory);
coreModule.service('ClipboardService', ClipboardService);
