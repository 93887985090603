import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';


export function filterSelectedSearchTerms(){
    return function(values, selected){
      return values;
    }
}

const SUGGESTIONS_LIMIT = 200;


replaceManagerFactory.$inject = ['c', '$timeout', 'derivatives', 'FilterHintsFactory', 'utils', '$q', 'filterManagerFactory', 'filterUI',
  'config', '$resource', 'FilterEditHelperFactory', 'eventCallbackManagerFactory', 'analyticsService', 'FutureService'];
export function replaceManagerFactory(c, $timeout, derivatives, FilterHintsFactory, utils, $q, filterManagerFactory, filterUI, config,
                                      $resource, FilterEditHelperFactory, eventCallbackManagerFactory, analyticsService, FutureService) {
  return {
    get_manager: get_manager
  };
  function get_manager(options) {
    return new ReplaceManager(options);
  }

  function ReplaceManager(options){
    let SuggestionResource = new $resource(config.api.suggestions);
    var self = this;
    self.onAddingRG = new eventCallbackManagerFactory('onAddingRG');
    self.options = options;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.context = options.context;
    self.filteredColumns = [];
    self.search = '';
    var metadata = options.metadata;

    self.columns = $.grep(metadata, function(col: any){
      return col.type == c.text;
    });

    self.tabs = {
      searchReplace: 'SEARCH AND REPLACE',
      groupReplace: 'GROUP REPLACE'
    };
    self.tabsAsArray = ['searchReplace', 'groupReplace'];

    var helperRegistry = {
      searchReplace: null
    };

    var helperBase = {
      searchReplace: SimpleReplaceHelper,
      groupReplace: GroupReplaceHelper
    };

    self.filterEditHelper = new FilterEditHelperFactory();
    self.filterManager = filterManagerFactory.get_manager({
      metadata: metadata,
      dataviewId: options.context.dataview.id,
      context: options.context,
      originalSequenceNumber: options.originalSequenceNumber
    });

    // toggleAllSelect();

    self.validate = validate;
    self.getParam = getParam;
    self.setParam = setParam;
    self.handlePasteParams = handlePasteParams;
    self.sanitizeParam = sanitizeParam;
    self.openConditionMenu = openConditionMenu;
    self.openConditionModal = openConditionModal;
    self.filterEditHelper.onMaximizeFilterPopoverToModal('maximizeFilterPopoverToModal',  self.openConditionModal)


    self.selectTab = selectTab;
    self.selectedTab = null;
    self.selectTab('searchReplace');

    function  openConditionMenu(){
      // filterUI.open(self.filterManager);
      self.filterEditHelper.open(self.filterManager);

    }
    
    function openConditionModal(param=null){
      filterUI.open(self.filterManager, param);
    }

    function selectTab(tab_name, handleSwitch) {
      if(self.selectedTab == tab_name){
        return;
      }
      self.selectedTab = tab_name;

      if(helperRegistry.hasOwnProperty(tab_name) && helperRegistry[tab_name]){
        self.helper = helperRegistry[tab_name];
      }
      else{
        self.helper = new helperBase[tab_name]();
        helperRegistry[tab_name] = self.helper;
      }
      if(handleSwitch){
        self.helper.handleSwitch()
      }
    }
    function getNewSrcColumns(replacementColumnMaps) {
      var newSourceColumns = []
      for (const internalName in replacementColumnMaps) {
        newSourceColumns.push(replacementColumnMaps[internalName]['internal_name']);
      }
      return newSourceColumns
    }

    function handlePasteParams(taskInfo){
      /** Update source and condition params with suitable replacement columns, based on display name*/
      var param = taskInfo.params
      for(const index in param.REPLACE.SOURCE){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(param.REPLACE.SOURCE, index, taskInfo, metadata, self.displayNameAndTypeToColumnMap);
      }
      var columnUsedInCondition = utils.get_input_columns(_.cloneDeep(param.CONDITION))
      if (columnUsedInCondition) {
        utils.metadata.findReplaceColumnsInCondition(metadata, self.displayNameAndTypeToColumnMap, param, columnUsedInCondition, taskInfo)
      }
      self.filterManager.metadata = metadata
      return param
    }

    function setParam(param){
      // self.sanitizeParam(param);
      if(param.REPLACE.MAPPING){
        self.selectTab('groupReplace');
      }
      else{
          self.selectTab('searchReplace');
      }
      var all_param = _.cloneDeep(param);
      all_param.CONDITION = undefined;
      self.helper.setParam(all_param);
      if (param.hasOwnProperty('CONDITION')) {
        self.filterManager.setParam(param.CONDITION, param?.EXECUTION_TIMESTAMP);
      }
      self.validate()
    }

    function getParam(){
      var param = self.helper.getParam();
      param.CONDITION = undefined;
      if(options.context.simpleMode){
        param._UI_CUSTOM = 'searchReplace';
      }
      else{
        param._UI_CUSTOM = self.selectedTab;
      }
      if(self.filterManager.condition){
        param.CONDITION = self.filterManager.getParam();
      }
      if (self.options.context.hasOwnProperty('sequence')){
        param['SEQUENCE_NUMBER'] = self.options.context['sequence']
      }
      return param;
    }

    function validate(){
      var valid = true
      if (self.filterManager.condition){
        valid = valid && self.filterManager.condition.validate();
      }
      valid = valid && self.helper.validate()
      return valid;
    }

    function sanitizeParam(param){
      let indicesOfColNotAvailable = [];
      let indicesOfColWithTypeMismatch = [];
      _.forEach(param.REPLACE.SOURCE,function(source_col){
        let col_info = _.filter(metadata, {'internal_name': source_col});
        if (col_info.length==0){
          indicesOfColNotAvailable.push(param.REPLACE.SOURCE.indexOf(source_col));
        }else if(col_info.length==1 && col_info[0]['type'] !== "TEXT"){
          indicesOfColWithTypeMismatch.push(param.REPLACE.SOURCE.indexOf(source_col));
        }
      });
      param.REPLACE.SOURCE = param.REPLACE.SOURCE.filter(function(value,index){
        return indicesOfColNotAvailable.indexOf(index) == -1;
      });
      param.REPLACE.SOURCE = param.REPLACE.SOURCE.filter(function(value,index){
        return indicesOfColWithTypeMismatch.indexOf(index) == -1;
      });

    }
    function SimpleReplaceHelper(){
      var sr = this;
      sr.param = {
        REPLACE: {
          SOURCE: [],
          VALUE_PAIR: [],
          MATCH_CASE: false,
          MATCH_WORDS: false
        }
      };
      sr.getParam = getParam;
      sr.setParam = setParam;
      sr.toggleSelection = toggleSelection;
      sr.addValuePair = addValuePair;
      sr.removeValuePair = removeValuePair;
      sr.toggleAllSelect = toggleAllSelect;
      sr.handleSwtich = handleSwitch;
      sr.validate = validate;
      addValuePair();

      function getParam(){
        return sr.param;
      }

      function setParam(param){
        /**Extend soucrColumns list with columns which are present in params but are no longer of type text*/
        var sourceColumnInternalNames = _.map(self.columns,'internal_name')
        _.forEach(param.REPLACE.SOURCE, function(selected_column){
          if(sourceColumnInternalNames.indexOf(selected_column) == -1){
            var column = _.find(metadata,function (c) {
              return c.internal_name == selected_column
            })
            self.columns.push(column)
          }
        }.bind(self))
        self.columns = utils.sanitizeMetadataWithType(self.columns);
        sr.param = _.cloneDeep(param);
      }

      function toggleAllSelect() {
        if(sr.param.REPLACE.SOURCE.length === self.filteredColumns.length){
          sr.param.REPLACE.SOURCE = [];
        }
        else{
          sr.param.REPLACE.SOURCE = [];
          self.filteredColumns.forEach(function(col){
            sr.param.REPLACE.SOURCE.push(col.internal_name);
          })
        }
        $timeout(function () {});
      }

      function addValuePair(search = undefined, replace = undefined){
        if(search == undefined){
          search = '';
        }
        if(!replace){
          replace = '';
        }
        sr.param.REPLACE.VALUE_PAIR.push({SEARCH_VALUE: search, REPLACE_VALUE: replace});
      }

      function toggleSelection(internal_name){
        if(sr.param.REPLACE.SOURCE.indexOf(internal_name) == -1){
          sr.param.REPLACE.SOURCE.push(internal_name);
        }
        else{
          var index = sr.param.REPLACE.SOURCE.indexOf(internal_name);
          if (index > -1) {
            sr.param.REPLACE.SOURCE.splice(index, 1);
          }
        }
        // following is done because the model was not working right on the view
        sr.param.REPLACE.SOURCE = _.cloneDeep(sr.param.REPLACE.SOURCE);
      }

      function removeValuePair(valuePair){
        sr.param.REPLACE.VALUE_PAIR = $.grep(sr.param.REPLACE.VALUE_PAIR, function(vp){
          return vp != valuePair;
        });
      }

      function handleSwitch(){

      }
      function validate(){
        var valid = true
        _.forEach(sr.param.REPLACE.SOURCE,function(source_col){
          let col_info = _.filter(metadata, {'internal_name': source_col});
          if (col_info[0]['type'] !='TEXT') {
              valid = false
          }
          if (col_info[0].hasOwnProperty('error')){
            valid = false
          }
        });
      return valid
      }
    }

    function GroupReplaceHelper(){
      var br = this;
      br.getParam = getParam;
      br.setParam = setParam;
      br.selectColumn = selectColumn;
      br.addReplaceGroup = addReplaceGroup;
      br.removeReplaceGroup = removeReplaceGroup;
      br.markUniqueValueInBucket = markUniqueValueInBucket;
      br.selectCallBackGenerator = selectCallBackGenerator;
      br.clearSelectionCallback = clearSelectionCallback;
      br.toggleSelection = toggleSelection;
      br.handleSwitch = handleSwitch;
      br.checkForSourceValues = checkForSourceValues;
      br.toggleWarning = toggleWarning;
      br.getUniqueValueCount = getUniqueValueCount;
      br.reset = reset;
      br.triggerBack = triggerBack;
      br.triggerFwd = triggerFwd;
      br.wizardMode = false;
      br.validate = validate;
      br.filterWhitespaces = utils.filterVerticalWhitespaces;
      br.updateUniqueValues = utils.debounce(updateUniqueValues, 500);
      reset();
      br.dragRg = null;

      function triggerBack() {
        br.wizardMode = true;
      }
      function triggerFwd() {
        br.wizardMode = false;
      }

      function reset(){
        br.column = null;
        br.unique_values = [];
        br.replaceGroups = [];
        br.queryString = '';
        br.selected = [];
        br.suggestions = [];
        br.derivative_id = null;
        br.loading = false;
        br.column_selected = false;
        br.search_visible = true;
        br.source_values_exist = false;
        br.autogrouped = false;
        br.showWarningMessage = false;
        br.countCache = {};
        br.autoGroupHelper = new AutoGroupHelper();
        br.addReplaceGroup();
        br.filterHints = new FilterHintsFactory(options.context.dataview.id);
      }

      function checkForSourceValues() {
        br.source_values_exist = false;
        for (var i = 0; i < br.replaceGroups.length; i++) {
          if (br.replaceGroups[i].source_values.length > 0 && br.column_selected){
            br.source_values_exist = true;
            break;
          }
        }
      }

      function toggleWarning() {
        if (br.source_values_exist)
          br.showWarningMessage = true;
        else
          br.showWarningMessage = false;
      }

      function getUniqueValueCount(value){
        let val_key = value;
        if(!value){
          val_key = '$___blank___$';
        }
        if(!br.countCache.hasOwnProperty(val_key)){
          if(br.unique_values.length == 0){
            return null;
          }
          let filtered: any = _.filter(br.unique_values, {value: value});
          if(filtered.length){
            br.countCache[val_key] = filtered[0].count;
          }
        }
        return br.countCache[val_key];
      }
      function validate(){
        var valid = true;
        if (br.column && (br.column['type'] != 'TEXT' || br.column.hasOwnProperty('error'))) {
          valid = false;
          br.wizardMode = true;
        }
        return valid;
      }
      function AutoGroupHelper(){
        let ag = this;
        ag.done = false;
        ag.inited = false;
        ag.confirm = false;
        ag.suggestions = [];
        ag.sorted_uniq_vals = [];
        ag.threshold = null;
        ag.loading = false;
        ag.init = init;
        ag.apply = apply;
        ag.prepareToApply = prepareToApply;
        ag.tryAgain = tryAgain;

        function prepareToApply(){
          if(ag.suggestions.length == 0){
            return;
          }
          if(br.replaceGroups.length > 1){
            ag.confirm = true;
          }
          if(br.replaceGroups.length  == 1){
            let rg = br.replaceGroups[0];
            if(rg.source_values && rg.source_values.length){
              ag.confirm = true;
            }
            if(rg.replace_value){
              ag.confirm = true;
            }
          }
          if(!ag.confirm){
            ag.apply();
          }
        }

        function tryAgain(){
          ag.done = false;
          ag.inited = false;
          ag.confirm = false;
          ag.suggestions = [];
          ag.loading = false;
          ag.error = false;
          ag.done = false;
          init();
        }



        function apply(){
          br.replaceGroups = [];
          ag.suggestions.forEach(function(suggestions){
            let rg = br.addReplaceGroup();
            suggestions.sort(function(a, b){
              return _getCount(a) - _getCount(b);
            });
            suggestions.forEach(function(str){
              rg.addSourceValue(str);
            });
            suggestions.reverse();
            rg.replace_value = _getBest(suggestions);
          });
          ag.confirm = false;
          ag.done = true;
          checkForSourceValues();
        }

        function _getCount(val){
          let count = 0;
          let filtered: any = _.filter(ag.sorted_uniq_vals, {value: val});
          if(filtered.length){
            count = filtered[0].count;
          }
          return count;
        }

        function _getBest(vals){
          // return first non null like value. If none found return first value
          for(let i=0; i< vals.length; i++){
            if(vals[i]){
              return vals[i];
            }
          }
          return vals[0];
        }

        function init(){
          ag.loading = true;
          ag.done = false;
          ag.confirm = false;
          let postData = {
            dataview_id: options.context.dataview.id,
            param: br.filterHints.param,
            column: br.filterHints.groubByIntName,
            type: "derivative-fuzzy-bucket",
            threshold: br.autoGroupHelper.threshold
          };
          var deferred = $q.defer();
          SuggestionResource.save({}, postData).$promise.then(function (data) {
            future_request_tracker(data.future_id).then(deferred.resolve, deferred.reject);

          });
          function future_request_tracker(future_id) {
            var deferred = $q.defer();
            FutureService.track(future_id, data_tracker);

            function data_tracker(future) {
              let response = future.response;
              if (future.status == "processing") {
                return;
              }
              if (future.status == "success") {
                try {

                  ag.suggestions = [];
                  response.suggestions.forEach(function (s) {
                    if (s.length > 1) {
                      ag.suggestions.push(s);
                    }
                  });
                  ag.inited = true;
                  ag.loading = false;
                  deferred.resolve(response);
                }
                catch (e) {
                  ag.error = true;
                  ag.loading = false;
                }
              }
              else {
                ag.error = true;
                // ag.done = true;
                //In case there is error fetching suggestions dont mark auto-grouping as done.This fixes the issue of back button not showing
                // when suggestion request fails
                ag.loading = false;
                deferred.reject(response);
              }
            }
            return deferred.promise;
          }
        }
      }

      function setParam(param){
        var sourceColumnInternalNames = _.map(self.columns,'internal_name')
        _.forEach(param.REPLACE.SOURCE, function(selected_column){
          if(sourceColumnInternalNames.indexOf(selected_column) == -1){
            var column = _.find(metadata,function (c) {
              return c.internal_name == selected_column
            })
            self.columns.push(column)
          }
        }.bind(self))
        self.columns = utils.sanitizeMetadataWithType(self.columns);
        if(param.REPLACE.SOURCE.length){
          br.column = utils.metadata.get_column_by_internal_name(self.columns, param.REPLACE.SOURCE[0]);
          br.selectColumn();
          br.column_selected = true;
        }
        br.replaceGroups = [];
        if(param.REPLACE.MAPPING.length){
          param.REPLACE.MAPPING.forEach(function (rgParam) {
            var newRg = br.addReplaceGroup();
            newRg.setParam(rgParam);
          });
        }
        checkForSourceValues();
      }

      function getParam(){
        var param = {
          REPLACE: {
            SOURCE: [br.column.internal_name],
            MAPPING: [],
            MATCH_CASE: true,
            MATCH_WORDS: true
          }
        };
        $.each(br.replaceGroups, function(i, rg){
          param.REPLACE.MAPPING.push(rg.getParam());
        });
        return param;
      }

      function handleSwitch(){
        if(br.column){
          br.selectColumn();
        }
      }

      function markUniqueValueInBucket(value, inBucket){
        $.each(br.unique_values, function(i, uniqueValue){
          if(!value){
            value = null;
          }
          if (uniqueValue.value === value) {
            uniqueValue.isInBucket = inBucket;
            return false;
          }
        })
      }

      function clearSelectionCallback(columnInternalName) {
        return function () {
          br.selected = [];
        }
      }

      function toggleSelection(column_internal_name, value, clearAll) {
        if (!angular.isArray(value)) {
          value = [value];
        }
        if (clearAll && !(br.selected.length == 1 && value.length == 1 && br.selected[0] == value[0])) {
          br.selected = [];
        }
        angular.forEach(value, function (val) {
          if(br.selected.indexOf(val) == -1){
            br.selected.push(val);
          }
          else{
            br.selected.splice(br.selected.indexOf(val), 1);
          }
        });
      }

      function selectCallBackGenerator(columnInternalName, clearAll) {
        return function (values) {
          br.toggleSelection(columnInternalName, values, clearAll);
        }
      }

      function _hideBucketedValues(){
        $.each(br.replaceGroups, function(i, rg){
          $.each(rg.source_values, function(j, sourceValue){
            br.markUniqueValueInBucket(sourceValue, true);
          })
        })
      }


      function updateUniqueValues(queryString?, cb?, append?){
        let skip=0;
        if(append){
          skip = br.unique_values.length;
          br.loading = true;
        }
        br.filterHints.getHints(queryString, SUGGESTIONS_LIMIT, skip).then(_hintsQueryCb, failure_cb);
        function _hintsQueryCb(data){
          if (data) {
            if (append) {
              _.forEach(data, function (d) {
                br.unique_values.push(d);
              });
            } else {
              br.unique_values = data;
            }
          }

          br.unique_values.forEach(function(val){
            val['isInBucket'] = false;
          });
          _hideBucketedValues();
          br.loading = false;
          br.wizardMode = false;
          br.column_selected = true;
          if(cb){
            cb();
          }
        }
        function failure_cb(){
          br.loading = false;
          br.column_selected = true;
        }
      }



      function selectColumn() {
        var deferred = $q.defer();
        br.loading = true;
        checkForSourceValues();

        var sequence_number = null;
        if(options.context.hasOwnProperty('task') && options.context.task && options.context.task.hasOwnProperty('sequence')){
          //If existing task
          sequence_number = options.context.task.sequence;
          br.autoGroupHelper.done = true;
        }
        br.filterHints.init(br.column.internal_name, sequence_number);
        updateUniqueValues(undefined, _uniqueValueCb);
        return deferred.promise;

        function _uniqueValueCb(){
          if(!(options.context.hasOwnProperty('task') && options.context.task && options.context.task.hasOwnProperty('sequence'))){
            //If not an existing task
            br.autoGroupHelper = new AutoGroupHelper();
            br.autoGroupHelper.init();
          }
          deferred.resolve();
        }
      }

      function ReplaceGroup(){
        var rg = this;
        rg.replace_value = null;
        rg.source_values = [];
        rg.applyHighlight = false;
        rg.active = false;

        rg.addSourceValue = addSourceValue;
        rg.removeSourceValue = removeSourceValue;
        rg.removeSourceValues = removeSourceValues;
        rg.setParam = setParam;
        rg.getParam = getParam;
        rg.dropCallback = dropCallback;
        rg.dragCallback = dragCallback;
        rg.highlightBucket = highlightBucket;

        function addSourceValue(val){
          if(rg.source_values.indexOf(val) !== -1){
            return;
          }
          var svalues = _.cloneDeep(rg.source_values);
          if(!val){
            val = '';
          }
          svalues.unshift(val);
          rg.source_values = svalues;
          br.markUniqueValueInBucket(val, true);
        }

        function removeSourceValue(index){
          br.markUniqueValueInBucket(rg.source_values[index], false);
          rg.source_values.splice(index, 1);
          checkForSourceValues();
          if (rg.source_values.length === 0){
            rg.source_values = [];
          }
        }

        function removeSourceValues() {
          $.each(rg.source_values, function(i, sourceValue){
            br.markUniqueValueInBucket(sourceValue, false);
          });
          rg.source_values = [];
          rg.replace_value = null;
        }
        function sanitize_source_values(source_values){
          let sanitized_source_values = []
          $.each(source_values, function(i, sourceValue){
            if(typeof(sourceValue)!=='string'){
              sanitized_source_values.push(String(sourceValue))
            }else{
              sanitized_source_values.push(sourceValue)
            }
          });
          return sanitized_source_values;
        }
        function getParam(){
          /**
           * For MVP-5714: when user tries to do bulk replace on a numeric column which is pending to be converted to text
           * The values in it are still numeric in  order to apply bulk replace in this situation the values must be string so sanitize them before use.
           */
          let sanitized_source_values = sanitize_source_values(rg.source_values);
          return {REPLACE_VALUE: rg.replace_value, SEARCH_VALUE: sanitized_source_values}
        }

        function setParam(rgParam){
          rg.replace_value = rgParam.REPLACE_VALUE;
          rg.source_values = rgParam.SEARCH_VALUE;
        }

        function highlightBucket() {
          $timeout(function () {
            rg.applyHighlight = !rg.applyHighlight;
          })
        }

        function dragCallback(){
          br.dragRg = this;
        }

        function dropCallback(event, ui) {
          if(br.dragRg == rg){
            br.dragRg = null;
            return;
          }
          for (var i = 0; i < br.replaceGroups.length; i++) {
            br.replaceGroups[i].active = false;
          }
          var val = ui.draggable.scope().unique_val;
          if(!val) {
            val = ui.draggable.scope().item.value;
          }
          if (br.selected.indexOf(val) == -1) {
            addSourceValue(val);
          }
          else {
            $.each(br.selected, function(i, selectedValue){
              addSourceValue(selectedValue);
            })
          }
          br.selected = [];
          rg.active = true;
          if(br.dragRg){
            var index = br.dragRg.source_values.indexOf(val);
            if (index > -1) {
              br.dragRg.source_values.splice(index, 1);
            }
            br.dragRg = null;
          }
          checkForSourceValues();
          rg.highlightBucket();
          return false;
        }
      }

      function addReplaceGroup(){
        br.queryString = '';
        var newRg = new ReplaceGroup();
        br.replaceGroups.push(newRg);
        self.onAddingRG.fire_event();
        return newRg;
      }

      function removeReplaceGroup(rg){
        $.each(rg.source_values, function(i, sourceValue){
          br.markUniqueValueInBucket(sourceValue, false);
        })
        br.replaceGroups = $.grep(br.replaceGroups, function(rGroup){
          return rGroup != rg;
        })
        br.checkForSourceValues();
      }
    }
  }
}



/**
 * @ngInject
 * For validating if the build replace is right
 */

shouldBeValidBulkReplaceParam.$inject = ['utils'];
export function shouldBeValidBulkReplaceParam(utils){
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (s, e, a, ctrl){
      ctrl.$validators.shouldBeValidBulkReplaceParam = function(mV, vV){
        if (!angular.isArray(vV)){
          return false;
        }
        return vV.length != 0;
      }
    }
  }
}

/**
 * @ngInject
 * For validating if the build replace is right
 */

export function shouldBeValidReplacePairs(){
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, element, attributes, ctrl){
      let lastKnownVal;
      ctrl.$validators.shouldBeValidReplacePairs = function(mV, vV){
        let otherValue = scope.$eval(attributes.otherValue);
        return otherValue != vV;
      };
      scope.$watch(attributes.otherValue, function(v){
        if(v != lastKnownVal){
         lastKnownVal = v;
          ctrl.$validate();
        }
      });
    }
  }
}



/**
 * @ngInject
 */
export function bucketListHeaderAutoWidth(){
    return {
      restrict: 'A',
      link: function(scope, element, attribute){
        scope.$watch('tvm.manager.helper.replaceGroups.length', render);
        function render(v){
          setTimeout(function(){
            if(!v){
              return;
            }
            let wMax = $('.bucket-list-container').outerWidth() - 30;
            let w = $('.bucket-container').outerWidth() || $('.bucket-list-container').outerWidth();
            let widthToSet = Math.min(w * v, wMax);
            if(widthToSet < w){
              widthToSet = w;
            }
            $('.bucket-list-header').width(widthToSet);
          }, 50);
        }
      }
    }
}

export function valReplaceCol() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valReplaceCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valReplaceCol = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue){
          if (modelValue.hasOwnProperty('error')){
            is_valid=false
          }
          if (modelValue.type != 'TEXT'){
            is_valid=false
          }
        }
        return is_valid
      }
    }
  }
}
