<template>
  <mm-menu
    v-model="isMenuOpen"
    menu-class="linked-in-out-menu"
    width="240px"
    max-width="240px"
    open-on-hover
    :close-on-content-click="false"
    :key="`linked_in_out_menu_${menuKey}`"
  >
    <template #activator="{ on }">
      <div
        class="cursor-pointer linked-in-out-icon-container"
        :class="{ 'linked-in-out-icon-container-menu-open': isMenuOpen }"
        v-on="on"
      >
        <mm-icon class="m-r-1" name="branch_out_view" />
      </div>
    </template>

    <template #content>
      <div class="linked-in-out-menu-wrapper p-y-5 p-x-2">
        <linked-in-out-list :resource="computedResource" :key="`in_out_list_${inOutListKey}`" />
      </div>
    </template>
  </mm-menu>
</template>

<script>
// Components
import LinkedInOutList from './linked-in-out-list/linked-in-out-list.vue'

export default {
  name: 'linked-in-out',
  components: { LinkedInOutList },
  props: {
    resourceId: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    isMenuOpen: false,
    inOutListKey: 0,
    menuKey: 0
  }),
  watch: {
    computedResource() {
      if (!this.isMenuOpen) this.menuKey++
      this.inOutListKey++
    },
    isMenuOpen(val) {
      if (!val) this.menuKey++
    }
  },
  computed: {
    computedResource() {
      return this.$store.state.resources.resourcesMap[this.resourceId]
    }
  }
}
</script>

<style lang="scss">
.linked-in-out-menu {
  background: var(--mm-color--n10);
}

.linked-in-out-menu-wrapper {
  line-height: 1;

  .mm-linked-in-out-list {
    max-height: 90vh;
  }
}

.linked-in-out-icon-container {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 24px;
  width: 24px;

  &.linked-in-out-icon-container-menu-open {
    background: var(--mm-color--n60);
    border-radius: 50%;
  }
}
</style>
