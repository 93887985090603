import * as angular from "angular";
import * as _ from 'lodash-es';
/**
 * @ngInject
 */
publishDBManagerFactory.$inject = ['$resource', 'config','toastNotification'];
export function publishDBManagerFactory($resource, config, toastNotification) {
    var service = {
        get_manager: get_manager
    };
    return service;

    function get_manager(options) {
        return new publishDBManager(options);
    }

    function publishDBManager(options) {
        var self = this;

        self.datasource = options.context.dataview.datasource;
        self.dataview = options.context.dataview;
        self.odbcTypeToNameMap = config.odbcTypeToNameMap;
        if (options.context.dataview) {
            self.dataviewId = options.context.dataview.id;
        }
        self.serversideValidationRequired = false;
        if (_.isUndefined(options.triggerType)) {
            options.triggerType = "pipeline";
        }
        self.publish_resource = $resource(config.api.publish, { ws_id: self.dataviewId });

        self.getParam = getParam;
        self.setParam = setParam;
        self.validate = validate;

        // setting parameter vars

        self.odbc_types = [
            {
                internal: 'postgres',
                display: 'PostgreSQL'
            },
            {
                internal: 'mssql',
                display: 'MSSQL'
            },
            {
                internal: 'mysql',
                display: 'MySQL'
            }
        ];
        self.odbc = 'postgres'; // default database for publish
        self.param = {
            "odbc_type": self.odbc,
        };
        self.is_valid_table = false;
        self.is_table_already_exists = false;
        self.validate_table_name = validate_table_name;
        self.published_tables = [];
        self.default_table_name = '';
        self.generate_default_table_name = generate_default_table_name;
        self.table_name_inited = false;

        init();

        function init() {
            self.publish_resource.get().$promise.then(function (data) {
                /**We expect the response will have status SUCCESS, but in any case if it fails it means we  */
                if (data.STATUS == 'SUCCESS'){

                // Following things are done here: 
                // 1. Set allowed odbc types.
                // 2. Initialize list of published tables.
                var allowed_types;

                if (data && data.valid_odbc_types) {
                    allowed_types = data.valid_odbc_types;
                }
                if (Array.isArray(allowed_types)) {
                    var new_odbc_types = [];
                    self.odbc_types.forEach(function (o) {
                        if (allowed_types.indexOf(o.internal) != -1) {
                            new_odbc_types.push(o);
                        }
                    });
                    // if none of odbc types are allowed, show none
                    if (new_odbc_types.length == 0) {
                        self.odbc = 'none';
                        self.odbc_types = [{
                            internal: 'none',
                            display: 'None'
                        }];
                    } else {
                        self.odbc_types = new_odbc_types;
                        // set first one from the list as default
                        self.odbc = self.odbc_types[0].internal;
                    }
                }

                if (data && data.published_tables) {
                    self.published_tables = data.published_tables;
                    if (self.param.target_properties.table == '') {
                        self.param.target_properties.table = self.generate_default_table_name();
                        self.table_name_inited = true;
                    }
                }
            } else{
                   self.table_name_inited=true;
                   toastNotification.error("Unable to initialize publish feature. Contact support for help.");
                   

            }
            }, function(){
                   self.table_name_inited=true;
                   toastNotification.error("Unable to initialize publish feature. Contact support for help.");
                   
            });
        }

        function generate_default_table_name() {
            /*
            Default name is defined as:
            ds_name + _ + wksp_name 
            1. Only alphanumeric characters and _ are allowed
            2. Remove vowels from ds and view name if length is more than 30.
            3. Add postfix if repeation of name happens
            */
            var ds_name = self.dataview.datasource.name;
            ds_name = minify_name(ds_name);
            var wksp_name = self.dataview.name;
            wksp_name = minify_name(wksp_name);
            var table_name = `${ds_name}_${wksp_name}`;
            table_name = table_name.toLowerCase();

            var postfix = 1;

            while (self.published_tables.indexOf(table_name) != -1) {
                var last = table_name.slice(table_name.length - 3);
                if (last.slice(0, 2) == '__' && typeof parseInt(last[2]) == 'number') {
                    postfix = parseInt(last[2]) + 1;
                    table_name = table_name.slice(0, table_name.length - 3);
                }
                table_name = `${table_name}__${postfix}`;
                table_name.toLowerCase();
            }
            return table_name;

            function minify_name(name) {
                name = name.replace(/[^a-z0-9]/gi, '_');
                if (name.length > 30) {
                    name = name.replace(/[aeiou]/gi, '');
                    var upper_limit = name.length > 30 ? 30 : name.length;
                    name = name.slice(0, upper_limit);
                }
                // remove leading special char
                if (name[0] == '_') {
                    name = name.slice(1);
                }
                // remove trailinng special char
                if (name[name.length - 1] == '_') {
                    name = name.slice(0, name.length - 2);
                }
                return name;
            }

        }


        function validate() {
            return true;
        }
        function getParam() {
            return self.param;
        }

        function validate_table_name() {

            self.is_valid_table = self.param.target_properties.table.match(/[^a-z0-9_]/gi);
            self.is_table_already_exists = self.published_tables.indexOf(self.param.target_properties.table.toLowerCase()) != -1;
        }

        function setParam(param) {
            angular.extend(self.param, param);
            if (!self.param.target_properties) {
                self.param.target_properties = {};
                self.param.target_properties.table = '';
                self.param.target_properties.odbc_type = self.odbc;
            }

        }
    }
}

