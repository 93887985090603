import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';

/**
 * @ngInject
 * @returns {{get_manager: get_manager}}
 */

combineManagerFactory.$inject = ['destinationColumnManagerFactory', 'filterManagerFactory', 'utils', 'filterUI', 'FilterEditHelperFactory'];
export function combineManagerFactory(destinationColumnManagerFactory, filterManagerFactory, utils, filterUI, FilterEditHelperFactory) {
  return {
    get_manager: get_manager
  };

  function get_manager (options) {
    return new CombineManager(options);
  }

  function CombineManager (options) {
    var self = this;
    var metadata = options.metadata, taskUtils = options.taskUtils;
    self.getParam = getParam;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.handlePasteParams = handlePasteParams;
    self.setParam = setParam;
    self.openConditionMenu = openConditionMenu;
    self.openConditionModal = openConditionModal;
    self.validate = validate;
    self.param = {
      COMBINE:{
        SOURCE:[]
      }
    }
    self.sanitizeParam = sanitizeParam;
    self.addColumn = addColumn;
    self.addString = addString;
    self.removeItem = removeItem;
    self.metadata = metadata;
    self.context = options.context;

    self.destinationManager = destinationColumnManagerFactory.get_manager(
      {metadata: metadata, allowedTypes: ['TEXT'], taskUtils: options.taskUtils}
    );

    self.sources = [];
    self.filterEditHelper = new FilterEditHelperFactory();
    self.filterEditHelper.onMaximizeFilterPopoverToModal('maximizeFilterPopoverToModal',  self.openConditionModal)
    self.filterManager = filterManagerFactory.get_manager({metadata: metadata,
      dataviewId: options.context.dataview.id,
      context: options.context,
      originalSequenceNumber: options.originalSequenceNumber
    });


    function addColumn(column) {
      self.sources.push({COLUMN: column});
      _setHighlights();
    }

    function addString(str) {
      self.sources.push({STRING: str});
    }

    function removeItem(item) {
      var index = self.sources.indexOf(item);
      if (index > -1) {
        self.sources.splice(index, 1);
      }
      _setHighlights();
    }

    function openConditionMenu(){
      self.filterEditHelper.open(self.filterManager);
    }
    function openConditionModal(param=null){
      filterUI.open(self.filterManager, param);
    }

    function validate() {
      let is_valid = true
      is_valid = self.destinationManager.validate();
      if (self.destinationManager.destination_type != 'TEXT'){
        is_valid = false
      }
      if (self.filterManager.condition){
        is_valid = is_valid && self.filterManager.condition.validate()
      }
      if (self.sources.length < 2) {
        self.error = "Select at least 2 source columns";
        is_valid = false;
      }
      self.param = self.getParam()
      _.forEach(self.param.COMBINE.SOURCE, function(src){
        if (src.hasOwnProperty('COLUMN')){
          let col_info = _.filter(metadata, {'internal_name': src.COLUMN});
          if (col_info[0].hasOwnProperty('error')){
            is_valid = false
          }
        }
      })
      return is_valid;

    }

    function _setHighlights() {
      var cols = [];
      $.each(self.sources, function (i, item) {
        if (item.COLUMN) {
          cols.push(item.COLUMN);
        }
      });
      taskUtils.highlight.sources(cols);
    }

    function handlePasteParams(taskInfo){
      var params = taskInfo.params
      /** Update source, destination and condition params with suitable replacement columns, based on display name*/
      if (params.COMBINE.hasOwnProperty('DESTINATION')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.COMBINE, 'DESTINATION', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        //Update destination manager metadata
        self.destinationManager.metadata = self.metadata
        self.destinationManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(self.metadata)

      }
      for(const index in params.COMBINE.SOURCE){
        if (Object.keys(params.COMBINE.SOURCE[index]).indexOf('COLUMN')!=-1){
          //Source Column
          utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.COMBINE.SOURCE[index], 'COLUMN', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        }
      }
      // Handle columns used in condition
      var columnUsedInCondition = utils.get_input_columns(_.cloneDeep(params.CONDITION))
      if (columnUsedInCondition) {
        utils.metadata.findReplaceColumnsInCondition(self.metadata, self.displayNameAndTypeToColumnMap, params, columnUsedInCondition, taskInfo)
      }
      self.filterManager.metadata = self.metadata
      return params

    }
    function getParam(extra_condition) {
      var sources = [];
      angular.forEach(self.sources, function (source) {
        if (source.hasOwnProperty("COLUMN")) {
          sources.push({COLUMN: source.COLUMN.internal_name});
        } else {
          sources.push(source);
        }
      });
      var combine_param = {
        SOURCE: sources
      };

      var destination_param = self.destinationManager.getParam();
      if (destination_param.hasOwnProperty('AS') && self.context.inEditMode==true  &&  self.context.task){
        utils.sanatizeParamForDuplicateCols(destination_param['AS'],'INTERNAL_NAME', self.context.task)
      }
      angular.extend(combine_param, destination_param);

      var param: any = {
        COMBINE: combine_param
      };

      if (self.filterManager.condition) {
        param.CONDITION = self.filterManager.getParam();
      }
      if (options.context.hasOwnProperty('sequence')){
        param['SEQUENCE_NUMBER'] = options.context['sequence']
      }
      return param;
    }

    function sanitizeParam(param){
      let indicesOfColNotAvailable = [];
      _.forEach(param.COMBINE.SOURCE, function (source) {
        if (source.hasOwnProperty('COLUMN')){
          var col_info = utils.metadata.get_column_by_internal_name(metadata, source.COLUMN)
          if (!col_info){
            indicesOfColNotAvailable.push(param.COMBINE.SOURCE.indexOf(source))
          }
        }
      });
      param.COMBINE.SOURCE = param.COMBINE.SOURCE.filter(function(value,index){
        return indicesOfColNotAvailable.indexOf(index) == -1;
      });
    }
    function setParam(param) {
      // self.sanitizeParam(param)
      self.param = param
      angular.forEach(param.COMBINE.SOURCE, function (col_param) {
        if (col_param.hasOwnProperty("COLUMN")) {
          var col_info=utils.metadata.get_column_by_internal_name(metadata, col_param.COLUMN)
          addColumn(col_info);
        } else {
          addString(col_param.STRING);
        }
      });
      self.destinationManager.setParam(param.COMBINE);
      if (param.hasOwnProperty('CONDITION')) {
        self.filterManager.setParam(param.CONDITION, param?.EXECUTION_TIMESTAMP);
      }
    }
  }
}
