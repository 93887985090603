/*jslint node: true */

import * as angular from 'angular';
import * as papaParse from 'papaparse';
import _ = require('lodash');
unstructureDataController.$inject = ['csvData', '$uibModalInstance', 'unstructuredDataService', 'rawData', 'collections'];
export function unstructureDataController(csvData, $uibModalInstance, unstructuredDataService, rawData, collections){
  this.$onInit = function () {
    var uvm = this;
    uvm.csv = null;
    uvm.show_loader = false;
    uvm.delimiters = {
      'Pipe': '|',
      'Comma': ',',
      'Tab': '\t'
    };

    uvm.editMode = false;
    uvm.isDirty = false;
    uvm.previewMode = false;
    uvm.datesAllowed = ['US', 'UK'];

    uvm.raw_grid_loader = {
      show: true,
      hideOverlay: true
    };
    uvm.cancel = cancel;
    uvm.reComputeColumns = reComputeColumns;
    uvm.initiated = false;
    uvm.isUnprocessed = false;
    uvm.rowStatus = {
      unmerged: "unmerged",
      processing: "processing",
      deleted: "deleted",
      merged: "merged"

    };

    uvm.unstructuredRequestsQueue = new collections.Queue();

    const unstructuredRowProperties = {
      isCompatible: "_isCompatible",
      unmergedRowCount: "_unmergedRowCount",
      columnCount: "_columnCount"
    };

    uvm.openDataSettingsModal = function () {
      $uibModalInstance.dismiss();
      rawData.openByDsId(uvm.datasource_id, uvm.datasource_status);
    };

    function toggleGridLoader(obj, is_true) {
      if (is_true !== undefined) {
        obj.show = !!is_true;
        obj.hideOverlay = !!is_true;
      } else {
        obj.show = !obj.show;
        obj.hideOverlay = !obj.hideOverlay;
      }
    }

    uvm.toggleRawGridLoader = function (is_true) {
      toggleGridLoader(uvm.raw_grid_loader, is_true);
    };

    uvm.delete_unstructured_row = function (ds_id, row) {
      row.status = uvm.rowStatus.processing;
      unstructuredDataService.discard_row(ds_id, row.line_num, row.batch_id).then(function (data) {
        row.status = uvm.rowStatus.deleted;
        if (uvm.csv._unmergedRowCount === 0) {
          $uibModalInstance.dismiss();
        }
      });

    };

    uvm.delete_all_unstructured_rows = function (ds_id) {

      angular.forEach(uvm.csv.unstructured_rows, function (row) {
        row.edit_mode = false;
        if (row.status === uvm.rowStatus.unmerged) {
          row.status = uvm.rowStatus.processing;
        }

      });
      unstructuredDataService.discard_all(ds_id).then(function (data) {
        uvm.is_all_discarded = true;
        angular.forEach(uvm.csv.unstructured_rows, function (row) {
          row.status = uvm.rowStatus.deleted;
        });
        $uibModalInstance.dismiss();
      });

    };


    // uvm.validate_unstructured_row = function (ds_id, row) {
    //   row.status = uvm.rowStatus.processing;
    //   unstructuredDataService.validate_row(ds_id, row.line_num, row.batch_id, row.line).then(function (data) {
    //     row.isCompatible = data.is_compatible_row;
    //     row.reason = data.reason;
    //     row.status = uvm.rowStatus.unmerged;
    //   });
    //
    // };

    uvm.merge_unstructured_row = function (ds_id, row) {
      row.status = uvm.rowStatus.processing;
      let data = [{
          "line_num": row.line_num,
          "line": row.line
        }] 
      unstructuredDataService.merge_row(ds_id, row.batch_id, data).then(function (data) {
        row.status = uvm.rowStatus.merged;
        if (uvm.csv._unmergedRowCount === 0) {
          $uibModalInstance.dismiss();
        }
      });

    };


    function initializeData() {

      uvm.show_loader = true;
      csvData.getByDsId(uvm.datasource_id).then(function (csv) {
        delete uvm.csv;

        uvm.csv = csv;
        uvm.csv.date_format = uvm.csv.date_format || 'UK';
        $.each(csv.data_rows, function (i: number, r) {
          r.unshift(csv.first_data_row + i);
        });

        csv.metadata.unshift({'display_name': '#', internal_name: "column_serial"});
        csv.display_properties = {
          COLUMN_WIDTHS: {
            column_serial: 40
          }
        };
        csv.total_rows_in_result = csv.skipped_rows.length + csv.data_rows.length;
        var new_params = {
          initial_skip_count: csv.initial_skip_count,
          data_rows: csv.data_rows,
          has_header: csv.has_header,
          date_format: csv.date_format,
          delimiter: csv.delimiter
        };
        uvm.prev_params = _.cloneDeep(new_params);
        if (!uvm.initiated) {
          uvm.initial_params = _.cloneDeep(new_params);
          uvm.initiated = true;
        }
        get_unstructured_rows();

        uvm.show_loader = false;

      });

    }

    function get_unstructured_rows() {
      uvm.csv.unstructuredDataLoader = true;
      unstructuredDataService.get_unstructured_rows(uvm.datasource_id).then(function (data) {
        uvm.csv.unstructured_rows = data.unstructured_rows;

        angular.forEach(uvm.csv.unstructured_rows, function (row) {
          row.edit_mode = false;
          row.status = uvm.rowStatus.unmerged;

          Object.defineProperty(row, unstructuredRowProperties.isCompatible, {
            get: function () {
              return row.columnCount === (uvm.csv.metadata.length - 1)
            }
          });

          Object.defineProperty(row, unstructuredRowProperties.columnCount, {
            get: function () {
              return papaParse.parse(row.line, {
                delimiter: uvm.csv.delimiter,
                quoteChar: uvm.csv.quotechar
              }).data[0].length;
            }
          });
          row.columnCount = row._columnCount;
          row.isCompatible = row._isCompatible;

        });

        Object.defineProperty(uvm.csv, unstructuredRowProperties.unmergedRowCount, {
          get: function () {
            var count = uvm.csv.unstructured_rows.reduce(function (total_count, row) {
              if ([uvm.rowStatus.processing, uvm.rowStatus.unmerged].indexOf(row.status) !== -1) {
                total_count++;
              }
              return total_count;
            }, 0);
            return count;
          }
        });
        uvm.csv.unstructuredDataLoader = false;
      });
    }

    function reComputeColumns(row) {
      row.isDirty = true;
      row.columnCount = row._columnCount;
      row.isCompatible = row._isCompatible;
    }

    function cancel() {
      $uibModalInstance.dismiss()
    }

    initializeData();

  }
}
