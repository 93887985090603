/*jslint node: true */
import * as angular from "angular";
import * as _ from "lodash-es";
import * as $ from "jquery";
import {
  digestSelection,
  combineCondition,
  isNullCondition,
  getPivotSelectParam,
  getRangeUpperBound,
  genExploreFilterDescription,
  getPivotGroupByParam,
  getGranularityOption,
  processExploreRangeData,
  handleReduceResolutionRequest,
  reconsileAggParams,
  _filterVerticalWhitespaces
} from "./utils";
import {ExploreConsts} from "./explore.constants";
/**
 * @ngInject
 */
RangeExploreCard.$inject = ['utils', 'eventCallbackManagerFactory', 'derivatives', 'c', 'CardSummaryDataFactory', 'analyticsService', '$resource', '$q', 'FutureService', 'toastNotification', 'exploreCardService', 'globalFilterService'];
export function RangeExploreCard(utils, eventCallbackManagerFactory, derivatives, c, CardSummaryDataFactory, analyticsService, $resource, $q, FutureService, toastNotification, exploreCardService, globalFilterService){
  return Card;
  function Card(sourceId, sourceType, metadata, column, formatInfo = {}, sourceUpdateEvent, getSourceData, exportFileNamePrepend) {

    var date_query_levels = ExploreConsts.dateGranularityOrder;
    var card = this;
    if(column.type == c.numeric){
      card.type = 'numeric';
    }
    else if(column.type == c.date){
      card.type = 'date';
    }

    card.at = 99999;
    card.column = column;
    card.sourceId = sourceId;
    card.sourceType = sourceType;
    // changing it to unique id makes storing order and _conditionMap not possible.
    // Unique and persistant card id is required for order and _conditionMap
    // card.id = utils.getRandomString(10);
    card.id = column.internal_name;
    card.dirty = false;
    card.querying = false;
    card.latestQuery = null;
    card.filterDescription = null;
    card.isActionPossible = false;
    card.initial_condition = null;
    card.unsupportedGranularityError = false;
    card.deselect = deselect;
    card.setActive = setActive;
    card.metadata = metadata;

    card.filter = filter;
    card.query = query;
    card.setCondition = setCondition;
    card.getSelectionCondition = getSelectionCondition;
    card.getSelectionConditionInverse = getSelectionConditionInverse;
    card.describe = describe;
    card.textDescribe = textDescribe;
    card.deactivate = deactivate;
    card.changeAggOptionsCb = changeAggOptionsCb;
    card.fireMentricCreateEvent = fireMentricCreateEvent;
    card.lastNotifiedAt = null;
    card.copyText = copyText;
    card.exportData = exportData;
    card.formatInfo = formatInfo;
    card.granularity = null;
    card.sequence = null;
    card.selectedAggregation = {
      aggregation: 'COUNT',
      target: null,
      format: {scientific: true}
    };

    card.summary =  new CardSummaryDataFactory();
    card.changeRenderCb = (renderType) => {
    };

    var cardUpdateEvent = new eventCallbackManagerFactory('CardUpdateEvent-' + card.id);
    card.onSelectionChanged = cardUpdateEvent.add_callback;

    var metricSelectedEvent = new eventCallbackManagerFactory('CardUpdateEvent-' + card.id);
    card.onMetricSelected = metricSelectedEvent.add_callback;

    sourceUpdateEvent.add_callback('fromExploreCard_' + card.column.internal_name, function (changedProps, prevData, newData) {
      card.metadata = _.get(newData, 'metadata', card.metadata);
      if (changedProps.display_properties && _.includes(changedProps.display_properties, "COLUMN_NAMES")) {
          card.column = _.find(card.metadata, {internal_name: card.column.internal_name}) || card.column;
      }
      if (changedProps.display_properties) {
          _querySummaryData();
      }
    });
      function copyText() {
          let data = [];
          let latestQuery = card.queries[card.queries.length - 1],
              selectedVlaues = _.get(latestQuery, 'selectedValues', []);

          if (selectedVlaues && selectedVlaues.length > 0) {
              data = latestQuery.data.filter(function (item) {
                  return latestQuery.selectedValues.indexOf(item.formatted) !== -1
              });
          } else {
              data = latestQuery.data.slice(0, 200);
          }
          exploreCardService.copyToClipboard(card, data);
      }

      async function exportData() {
        let latestQuery = card.queries[card.queries.length - 1];
        let selectedValues = _.get(latestQuery, 'selectedValues', []);
        let data = []
        if(selectedValues && selectedValues.length){
          data = latestQuery.data.filter(item => selectedValues.includes(item.formatted))
        } else {
          data = _.get(latestQuery, 'data') || [];
        }
        exploreCardService.exportAsCSV(card, data, exportFileNamePrepend);
      }


    function query(){
      let cardState = card.globalFilter.getCardState(card.id);
      if (_.isEmpty(cardState)) {
        card.queries = [new GraphData(card.initial_condition)];
      } else {
        if (card.column.type === c.numeric) {
          // card level starts from 1 and goes on decrementing. To get the order sort function is used here
          const sorted = Object.keys(cardState)
          sorted.sort(function (a: string, b: string) {
            return parseInt(b) - parseInt(a);
          })
          card.queries = Array.from(sorted, x => {
            const { activeValues, initialCondition, filterDescription } = cardState[x];
            // added callback function
            const data = new GraphData(initialCondition, parseInt(x), activeValues)
            if (filterDescription) {
              card.filterDescription = filterDescription;
            }
            return data;
          });
        } else if (card.column.type === c.date) {
          const levels = Object.keys(cardState);
          card.queries = Array.from(levels, x => {
            const { activeValues, initialCondition, filterDescription } = cardState[x];
            const data = new GraphData(initialCondition, x, activeValues)
            if (filterDescription) {
              card.filterDescription = filterDescription;
            }
            return data;
          })
        };
        // latest filterDescription will be applied to the card
        if (card.filterDescription) {
          // add filter to the card
          card.isActive = true;
        }
      }
      _querySummaryData();
    }

    function changeAggOptionsCb(removeCondition=true){
      analyticsService.userEventTrack(c.userEvents.exploreCards.changeAggregation,
        {
          eventOrigin: 'dataview.exploreCard',
          cardType: 'column',
          dataType: card.type
        });
      if(removeCondition){
        card.removeCondition(card);
      }
      card.query();
      card.saveConfig();
    }

    function _raiseToastWarning(msg){
      card.unsupportedGranularityError = msg;
    }

    function getSelectionCondition(){
      return card.latestQuery.getCondition();
    }

    function _setGranularity(level, save=false){
      let granularity = getGranularityOption(card.column.type, level);
      if(!angular.equals(granularity, card.granularity)){
        card.granularity = granularity;
        if(save){
          card.saveConfig();
        }
      }
    }

    function getSelectionConditionInverse(){
      return card.latestQuery.getInverseCondition();
    }

    function _querySummaryData(){
      card.summary.query(sourceId, sourceType, card.column, card.initial_condition, card.formatInfo, card.sequence, getSourceData);
    }


    function setCondition(condition){
      if(!angular.equals(card.initial_condition, condition)){
        card.granularity = null;
        card.initial_condition = condition;
        card.query();
      }
    }

    function storeCardState(){
      const cardIndex = card.queries.length - 1;
      let level, activeValues, initial_condition, query = null;
      query = card.latestQuery;
      level = query?.level
      activeValues = query?.activeValues;
      initial_condition = query?.initial_condition;
      const cardState = card.globalFilter.getCardState(card.id);
      cardState[level] = {activeValues, initialCondition: initial_condition }
      // latestQuery has activeValues and upcoming GraphData object has the condition
      // this is after subQuery is executed. it adds a graphdata object to card.queries
      query = card.queries[cardIndex];
      level = query?.level;
      activeValues = query?.activeValues;
      initial_condition = query?.initial_condition;
      cardState[level] = {activeValues, initialCondition: initial_condition, filterDescription: card.filterDescription };
      card.globalFilter.setCardState(card.id, cardState);
    }

    function filter(){
      var condition = card.latestQuery.getCondition();
      cardUpdateEvent.fire_event(condition);
      card.latestQuery.setActive();
      card.filterDescription = card.latestQuery.describe(true);
      card.latestQuery.removeSubQuery();
      if(card.latestQuery.activeValues.length == 1){
        card.latestQuery.subQuery(condition);
      }
      card.isActionPossible = false;
      storeCardState();
    }

    function deselect(){
      card.filterDescription = null;
      card.queries = [new GraphData(card.initial_condition)];
    }

    function describe(){
      return card.filterDescription;
    }

    function textDescribe() {
      if (angular.isArray(card.filterDescription)) {
        let valKey = 'value'
        if (card.column.type != c.text) {
          valKey = 'formatted'
        }
        let blankHtml = '<span class="font-weight-bold ">blank</span>'
        let describedText = card.filterDescription.map((x) => x[valKey] || blankHtml).join(', ')
        return _filterVerticalWhitespaces(describedText)
      }
      return _filterVerticalWhitespaces(card.filterDescription)
    }

    function setActive(value){
      card.isActive = value;
    }

    function deactivate(){
      card.isActionPossible = false;
      if(!card.queries){
        return;
      }
      card.queries.forEach(function(q){
        if(!q.inited){
          return;
        }
        q.deselect();
      });
    }

    function fireMentricCreateEvent(agg){
      // metricSelectedEvent.fire_event(agg);
    }

    function GraphData(initial_condition = null, query_level?, activeValues = null){
      var gd = this;
      gd.init = init;
      gd.select = select;
      gd.deselect = deselect;
      gd.subQuery = subQuery;
      gd.removeSubQuery = removeSubQuery;
      gd.getCondition = getCondition;
      gd.getInverseCondition = getInverseCondition;
      gd.describe = describe;
      gd.getServerVal = getServerVal;
      gd.setActive = setActive;
      gd.selectedValues = null;
      gd.activeValues = activeValues;

      gd.init();

      function getServerVal(value) {
        var serverVal=null;
        $.each(gd.data, function(i, r){
          if(r.formatted == value){
            serverVal = r.value
          }
        });
        return serverVal;
      }

      function getBoundaries(selected){
        let s1 = getServerVal(selected[0]);
        if(card.column.type == c.numeric){
          s1 = parseFloat(s1);
        }
        let s2 = getServerVal(selected[selected.length - 1]);
        let upperBound = getRangeUpperBound(card.column.type, s2, gd.level);
        return {
          lower: s1,
          upper: upperBound
        }
      }

      function describe(active=false){
        let digested;
        if(active){
          digested = digestSelection(gd.data, gd.activeValues);
        }
        else{
          digested = digestSelection(gd.data, gd.selectedValues);
        }

        let selectedValues = digested.selected;
        let nullSelected = digested.nullSelected;
        let filterDescription = [];

        if(selectedValues.length){
          let vals = [getServerVal(selectedValues[0])];
          if(selectedValues.length > 1){
            vals.push(getServerVal(selectedValues[selectedValues.length - 1]));
          }
          filterDescription.push(genExploreFilterDescription(card.column.type, vals, gd.level));
        }
        if(nullSelected){
          filterDescription.push('blank');
        }
        return filterDescription.join(' or ');
      }

      function getCondition(){
        let digested = digestSelection(gd.data, gd.selectedValues);
        let selectedValues = digested.selected;
        let nullSelected = digested.nullSelected;
        let nullCondition = null;
        let rangeCondition = null;

        if(selectedValues.length){
          let boundaries = getBoundaries(selectedValues);
          var identifier = card.column.internal_name;
          let c1 = {};
          let c2 = {};
          c1[identifier] = {
            GTE: boundaries.lower
          };
          c2[identifier] = {
            LT: boundaries.upper
          };

          rangeCondition = {
            AND: [c1, c2]
          };
        }

        if(nullSelected){
          nullCondition = {};
          nullCondition[card.column.internal_name] = {IS_EMPTY: true};
        }
        let final =  combineCondition('OR', nullCondition, rangeCondition);
        return final;
      }

      function getInverseCondition(){
        let digested = digestSelection(gd.data, gd.selectedValues);
        let selectedValues = digested.selected;
        let nullSelected = digested.nullSelected;
        let nullCondition = null;
        let rangeCondition = null;

        if(selectedValues.length){
          let boundaries = getBoundaries(selectedValues);
          var identifier = card.column.internal_name;
          let c1 = {};
          let c2 = {};
          c1[identifier] = {
            LT: boundaries.lower
          };
          c2[identifier] = {
            GTE: boundaries.upper
          };
          rangeCondition = {
            OR: [c1, c2]
          };
        }

        if(nullSelected){
          nullCondition = {};
          nullCondition[card.column.internal_name] = {IS_NOT_EMPTY: true};
        }
        return combineCondition('AND', nullCondition, rangeCondition);
      }

      function setActive(){
        gd.activeValues = gd.selectedValues;
        gd.selectedValues = null;
      }

      function init(){
        gd.inited = false;
        gd.initial_condition = initial_condition;
        gd.selected = null;
        gd.hasVariety = true;

        if (card.column.type == c.numeric && !isNaN(query_level)){
          gd.level = query_level
        }
        else if (card.column.type == c.date && date_query_levels.indexOf(query_level) != -1){
          gd.level = query_level
        }
        else if(card.granularity){
          gd.level = card.granularity.internal;
        }
        else{
          gd.level = 'AUTO';
        }
        let valid = reconsileAggParams(card.metadata, card.selectedAggregation, changeAggOptionsCb);
        if(!valid){
          return;
        }

        let selectParam = getPivotSelectParam(
          card.selectedAggregation.aggregation, card.selectedAggregation.target, card.column.internal_name);
        let groupByParam = getPivotGroupByParam(card.column.internal_name, card.column.type, gd.level);
        var param =  {
          PIVOT: {
            SELECT: selectParam,
            GROUP_BY: groupByParam
          },
          CONDITION : undefined,
        };
        if(initial_condition){
          param.CONDITION = initial_condition;
        }
        if (Number.isInteger(card.sequence)){
            param[c.sequenceNumber] = card.sequence
        }
        var display = {SORT: [['value', 'ASC']], FORMAT_INFO: {}};
        if(card.column.type == c.date){
          display.FORMAT_INFO = {value: {date_format: '%Y-%m-%d %H:%M:%S', tz: 'UTC'}};
        }
        var deferred = $q.defer();
        let requestInitiatedAt = Date.now()
        let identifier = exploreCardService.cardIdentifier(card);
        getSourceData({args: {param: param, display_properties: display}}).then(function (data) {
          utils.futureDataTracker(data, false, identifier, requestInitiatedAt).then(function (response) {
            if(response?.data && response?.data.length){
              process(response.data);
            } else {
              _.remove(card.queries, (query: any) => query.level === gd.level);
            }
            deferred.resolve(response);
          });
          }, function() {
            process([]);
            deferred.reject});
        return deferred.promise;
      }

      /**
       * A callback function that processes the query data for range explore cards(Date, Numeric)
       * @param {object} data
       * @returns void
       */
      function process(data){
        card.unsupportedGranularityError = null;
        let result = processExploreRangeData(card.column.type, card.type, data, gd.level, card.queries.indexOf(gd), ExploreConsts.plotThreshold);
        if(result.reduceResolution){
          handleReduceResolutionRequest(card.column.type, card.column.display_name, result.level, _setGranularity,
            _raiseToastWarning, init);
          return;
        }

        if(gd.level == 'AUTO'){
          gd.level = result.level;
          _setGranularity(result.level);
        }

        gd.hasVariety = result.hasVariety;
        if(!angular.equals(gd.data, result.data)){
          gd.data = result.data;
        }
        gd.inited = true;
      }

      function deselect(){
        gd.selectedValues = null;
        if(card.latestQuery == gd){
          card.isActionPossible = false;
        }
      }

      function select(values){
        gd.selectedValues = values;
        card.latestQuery = gd;
        card.isActionPossible = true;
        gd.selected = true;
      }

      function subQuery(subCondition){
        removeSubQuery(true);

        if(isNullCondition(subCondition, card.column.internal_name)){
          return;
        }
        if(initial_condition){
          subCondition = {"AND": [initial_condition, subCondition]}
        }
        if(card.column.type == c.numeric){
          card.queries.push(new GraphData(subCondition, gd.level - 1));

        }
        else if (card.column.type == c.date){
          var selfLevelIndex = date_query_levels.indexOf(gd.level);
          if(selfLevelIndex < date_query_levels.length - 1){
            var nextLevel = date_query_levels[selfLevelIndex + 1];

            card.queries.push(new GraphData(subCondition, nextLevel));
          }
        }
      }

      function  removeSubQuery(keepVal){
        card.queries.splice(card.queries.indexOf(gd) + 1);
      }

    }
  }
}
