LabelCreateController.$inject = ['labelService', '$uibModalInstance', 'landingList', 'toastNotification', 'resources',
  '$timeout', 'c', 'analyticsService'] as ReadonlyArray<string>;
export function LabelCreateController(labelService, $uibModalInstance, landingList, toastNotification, resources,
                                      $timeout, c, analyticsService) {
  this.$onInit = function () {
    const mvm = this;
    mvm.createInProgress = false;
    mvm.label_name = undefined;
    mvm.create = create;

    function create(parentRID) {
      if (mvm.createInProgress || !mvm.label_name) {
        return;
      }
      mvm.createInProgress = true;
      parentRID = parentRID || landingList.currentLabelResourceId;
      labelService.create(mvm.label_name, parentRID).then(
        function () {
          analyticsService.userEventTrack(c.userEvents.createNewFolder, {eventOrigin: "landingPage"})
          $uibModalInstance.close();
          resources.update();
          setNotInProgress();
        }, function (data) {
          let errorMessage = data.ERROR_MESSAGE;
          if (data.ERROR_CODE == 3003) {
            errorMessage = "Invalid folder name, must be a string between 1 and 100 characters long"
          }
          toastNotification.error(errorMessage);
          setNotInProgress();
        });
    }

    function setNotInProgress() {
      $timeout(() => {
        mvm.createInProgress = false;
      }, 500);
    }
  }
}

