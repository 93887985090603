import keycloak from '@/plugins/keycloak'

const getInitialState = () => ({})

const clearKeysFromLocalStorage = (keys_selector) => {
  Object.keys(localStorage)
    .filter(keys_selector)
    .forEach((key) => localStorage.removeItem(key))
}

const keysToClear = ['storageClass', 'account_id', 'workspace_id', 'authenticated', 'card_filter']

const appKeysFilter = (key) => {
  return keysToClear.includes(key)
}

const mmCacheFilter = (key) => key.startsWith('mmCache_')

export const clearLocalStorage = () => {
  clearKeysFromLocalStorage(appKeysFilter)
  // clear the mmCache_ entries from local storage. This is being filled by the angular code. but logout logic is here, so cleaning it here.
  clearKeysFromLocalStorage(mmCacheFilter)
}

const clearIndexedDB = () => {
  // TODO: remove when angular code is gone. This is to clear the resources stored in indexdb.
  indexedDB.deleteDatabase('localforage')
}

const getters = {}

const mutations = {}

const actions = {
  logout() {
    clearLocalStorage()
    clearIndexedDB()
    keycloak.logout()
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
