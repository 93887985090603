
import * as _ from 'lodash-es';
addColumnManagerFactory.$inject = ['eventCallbackManagerFactory', 'utils'];
export function addColumnManagerFactory(eventCallbackManagerFactory, utils) {
  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new addColumnManager(options);
  }

  function addColumnManager(options) {
    var metadata = options.metadata;
    var onColumnAdded = new eventCallbackManagerFactory('addColumnManagerColumnAdded');
    var self = this;
    self.context = options.context;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.metadata = metadata;
    self.validate = validate;
    self.getParam = getParam;
    self.setParam = setParam;
    self.addColumn = addColumn;
    self.removeColumn = removeColumn;
    self.addColumnInputArray = [{"COLUMN": undefined, "TYPE": "TEXT"}];
    self.onColumnAdded = onColumnAdded.add_callback;
    self.handlePasteParams = handlePasteParams;


    self.param = {
      "ADD_COLUMN": []
    };

    function addColumn() {
      self.addColumnInputArray.push({"COLUMN": undefined, "TYPE": "TEXT"});
      onColumnAdded.fire_event();
    }

    function removeColumn(i) {
      self.addColumnInputArray.splice(i, 1);
      if (!self.addColumnInputArray.length) {
        addColumn();
      }
    }

    function getParam() {
      self.param.ADD_COLUMN = self.addColumnInputArray;
      if(self.context.inEditMode==true &&  self.context.task){
        _.forEach(self.param.ADD_COLUMN, function(item){
        utils.sanatizeParamForDuplicateCols(item,'INTERNAL_NAME', self.context.task)
        })
      } 
      if (self.context.hasOwnProperty('sequence')){
        self.param['SEQUENCE_NUMBER'] = self.context['sequence']
      }
      return self.param;
    }

    function handlePasteParams(taskInfo){
      return taskInfo.params
    }

    function setParam(param) {
      self.addColumnInputArray = param.ADD_COLUMN || [];
      if (param.hasOwnProperty('ORDER')) {
        self.param.ORDER = param.ORDER;
      }

      if (!self.addColumnInputArray.length) {
        addColumn();
      }
    }

    function validate() {
      return true;
    }


  }
}
