<template>
  <mm-modal v-model="computedIsModalOpen" :title="$t('navbar.tab.menu.rename.title')" :width="480">
    <template #default>
      <mm-form v-model="isFormValid" ref="modalForm">
        <mm-text-input
          v-model="newName"
          class="width-100 m-t-7"
          :placeholder="$t('navbar.tab.menu.rename.type_name')"
          :validations="[$validators.required, validations.differentName, validations.duplicateName]"
          @enterKeyPressed="submit"
        />
      </mm-form>
    </template>
    <template #actions>
      <mm-button :label="$t('global.dictionary.cancel')" objective="secondary" @click="computedIsModalOpen = false" />
      <mm-button class="m-l-3" :label="$t('global.dictionary.done')" :disabled="!isFormValid" @click="submit" />
    </template>
  </mm-modal>
</template>

<script>
export default {
  name: 'view-options-modal-rename',
  props: {
    value: Boolean,
    view: {
      type: Object,
      required: true
      /*
      {
        viewId: -1,
        properties: { 
          name: '',
          dataset: { id: -1 }
        }
      }
      */
    }
  },
  data: (vm) => ({
    newName: '',
    isFormValid: false,
    validations: {
      differentName: ({ value }) => value !== vm.view.properties.name || vm.$t('navbar.tab.menu.rename.different_name'),
      duplicateName: ({ value }) => vm.validateDuplicateName(value) || vm.$t('navbar.tab.menu.rename.duplicate_name')
    }
  }),
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    this.newName = this.view.properties.name
  },
  mounted() {
    this.$nextTick(() => this.$refs.modalForm?.clearValidations())
  },
  methods: {
    submit() {
      if (this.$refs.modalForm) {
        const formErrors = this.$refs.modalForm.getFormErrors()
        if (formErrors.length) return
      }

      this.$emit('submit', { newName: this.newName })
      this.computedIsModalOpen = false
    },
    validateDuplicateName(newName) {
      const dataset = this.$store.getters['resources/getDatasetByDatasetId'](this.view.properties.dataset.id)
      // Validate if any view from this dataset has the same name
      return !dataset.properties.viewsIds.some(
        (viewId) => this.$store.getters['resources/getViewByViewId'](viewId).properties.name == newName
      )
    }
  }
}
</script>
