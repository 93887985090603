/**
 * @ngInject
 */
CompatibilityWarnings.$inject = ['$rootScope', 'windowEvents', '$window', '$timeout', 'localService', 'eventCallbackManagerFactory'];
export function CompatibilityWarnings($rootScope, windowEvents, $window, $timeout, localService, eventCallbackManagerFactory) {

  let compatibility_service = {
    displayInstanceSnoozeWarning: displayInstanceSnoozeWarning

  }

  return {
    init: init,
    compatibility_service: compatibility_service
  };


  function init() {
    let areWarningsDisabled = isBrowserWarningDisabled();
    $rootScope.showBrowserSizeWarning = false;
    $rootScope.showincompatibleBrowserWarning = false;
    $rootScope.showNetworkStateOfflineWarning = false;
    $rootScope.browserWarningMacOS = false;
    $rootScope.showDeviceWarning = false;
    $rootScope.showInstanceSnoozeWarning = false;

    if (!areWarningsDisabled) {
      // Code commented out because all warnings except offline warning is removed for MVP-2451
      // displayDeviceWarning();
      // displayResolutionWarning();
      // incompatibleBrowserWarning();
      displayOfflineWarning();
      windowEvents.onNetworkStateChange('app_run_listener', displayOfflineWarning);
      // windowEvents.onResize('app_run_listener', displayResolutionWarning);
    }
  }

  function displayInstanceSnoozeWarning(workspaceStatus) {
    if (workspaceStatus == 'snoozed') {
      $rootScope.showInstanceSnoozeWarning = true;
    }
    else {
      $rootScope.showInstanceSnoozeWarning = false;
    }
  }

  function displayOfflineWarning() {
    var online = window.navigator.onLine;
    if (!$window.bowser.mobile) {
      if (($window.bowser.tablet && $window.bowser.os == 'ios') || ($window.bowser.name == 'Chrome' || $window.bowser.name == 'Safari' || $window.bowser.name == 'Firefox')) {
          if (online) {
            $rootScope.showNetworkStateOfflineWarning = false;
          }
          else {
            $rootScope.showNetworkStateOfflineWarning = true;
          }
      }
    }
  }

  function incompatibleBrowserWarning() {
    var currentBrowser = $window.bowser.name;
    if (!$window.bowser.mobile) {
      if (currentBrowser == 'Chrome' || currentBrowser == 'Safari' || currentBrowser == 'Firefox') {
        $rootScope.showincompatibleBrowserWarning = false;
        $rootScope.showBrowserSizeWarning = false;
      }
      else {
        $rootScope.showincompatibleBrowserWarning = true;
        if ($window.bowser.osname == 'macOS' || $window.bowser.osname == 'iOS') {
          $rootScope.browserWarningMacOS = true;
        }
        else {
          $rootScope.browserWarningMacOS = false;
        }
      }
    }
  }

  function displayResolutionWarning() {
    $timeout(function () {
      var browserWidth = $window.innerWidth;
      var browserHeight = $window.innerHeight;
      if (!$window.bowser.mobile) {
        if ($window.bowser.name == 'Chrome' || $window.bowser.name == 'Safari' || $window.bowser.name == 'Firefox') {
          if ($window.bowser.tablet && (browserWidth < 400 || browserHeight < 500)) {
            $rootScope.showBrowserSizeWarning = true;
          } else if (!$window.bowser.tablet && (browserHeight < 500 || browserWidth < 1100)) {
            $rootScope.showBrowserSizeWarning = true; //Set this to true to turn on warnings
          } else {
            $rootScope.showBrowserSizeWarning = false;
          }
        }
      }
    });
  }

  function isBrowserWarningDisabled() {
    let isDisabledThroughCookie = localService.storage.disableBrowserWarnings == 'true';
    return (isDisabledThroughCookie);
  }

  function displayDeviceWarning() {
    $timeout(function () {
      if ($window.bowser.mobile) {
          $rootScope.showDeviceWarning = true;
      }
    });
  }

}
