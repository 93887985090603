import * as _ from 'lodash-es';

export function collapseRowsColumns() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function collapseRowsColumns(scope, elem, attrs, ctrl) {
      ctrl.$validators.collapseRowsColumns = function (modelValue, viewValue) {
        let isValid = true;
        let col = _.filter(scope.tvm.manager.metadata, {'internal_name': modelValue});
        if (col.length && (col[0].hasOwnProperty('error') || col[0]['type'] != 'TEXT')){
          isValid=false;
        }
        return isValid;
      };
    }
  };
}

export function collapseColumnNameValidator() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function collapseColumnNameValidator(scope, elem, attrs, ctrl) {
      var manager = scope.$eval(attrs.manager);
      ctrl.$validators.collapseColumnNameValidator = function (modelValue, viewValue) {
        let groupByColumnsInfo = _.filter(scope.tvm.manager.metadata, item => scope.tvm.manager.groupByColumns.includes(item.internal_name));
        let groupByDisplayNames = _.map(groupByColumnsInfo, 'display_name')
        return !groupByDisplayNames.includes(viewValue);
      };

      manager.validateCollapsedColumnName.add_callback("validateColumnName", ctrl.$validate);
      ctrl.$viewChangeListeners.push(manager.validateCollapsedColumnName.fire_event);

      scope.$on('$destroy', function () {
        manager.validateCollapsedColumnName.remove_callback("validateColumnName");
      });
    }
  };
}
