import * as $ from 'jquery';

const messages = {
  unknown: "Something went wrong",
  noSteps: "There are no rules in the data view",
  empty: "This view has nothing to save",
  nonEmpty: "This view has rules. Reset the view before applying the template",
  error: "Error while applying template",
  incompatible: "The template is incompatible with this dataset",
  joinLookupIssue: "Templates with lookup and join rules can not be applied.",
  invalidTemplateFile: "Invalid Template file"
};

/**
 * @ngInject
 */
TemplateService.$inject = ['$resource', 'config', '$timeout', '$q', 'resources', 'Notification', 'utils',
'TemplateDependencyChooserService', 'oldElementServiceFactory', 'DataviewService', 'TaskServiceFactory'];
export function TemplateService($resource, config, $timeout, $q, resources, Notification, utils, TemplateDependencyChooserService,
  oldElementServiceFactory, DataviewService, TaskServiceFactory){


  return {
    saveConfig: saveConfig,
    applyConfigToWs: applyConfigToWs,
    prepareWsForExport: prepareWsForExport,
    cancelWsTemplateApply: cancelWsTemplateApply
  };

  function _getCfgFromWs(dataview_id){
    var deferred = $q.defer();
    var dataviewExportConfigResource = new $resource(config.api.dataviewsExportConfig, {'ws_id': dataview_id});
    dataviewExportConfigResource.get(_successCb);
    return deferred.promise;

    function _successCb(data){
      var async_request_id = data.request_id;
      $timeout(_query, 1000);

      function _query(){
        var asyncResponse = new $resource(config.api.asyncRequest, {'request_id': async_request_id});
        asyncResponse.get(_successQryCb);

        function _successQryCb(data){
          if(data.STATUS == 'PROCESSING'){
            $timeout(_query, 1000);
          }
          else if(data.STATUS == 'SUCCESS' && data.response){
            deferred.resolve(data.response);
          }
          else{
            Notification.error(messages.unknown);
          }
        }
      }
    }
  }

  function saveConfig(ws){
    _getCfgFromWs(ws.id).then(function(response){
      let fileName = ws.datasource.name;
      if(fileName.length > 10){
        fileName = fileName.substr(0, 10);
      }
      fileName = fileName + ' '  + ws.name;
      fileName = fileName.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g,'_') + '.mammoth';
      utils.saveJSON(fileName , response);
    })
  }



  function checkAndGetErrorMessage(ws, templateCfg){
    try {
      if (ws.tasks_total_count != 0) {
        return messages.nonEmpty;
      }

      // if (!isMetadataMatching(templateCfg.metadata, ws.metadata)) {
      //   return messages.incompatible;
      // }
      // for (let i = 0; i < templateCfg.tasks.length; i++) {
      //   let taskParam = templateCfg.tasks[i].params;
      //   if (taskParam.hasOwnProperty("LOOKUP") || taskParam.hasOwnProperty("JOIN")) {
      //     return messages.joinLookupIssue;
      //   }
      // }
    } catch (e) {
      console.error(e);
      return messages.invalidTemplateFile;
    }
  }

  function applyConfigToWs(ws, templateCfg){
    let errorMsg = checkAndGetErrorMessage(ws, templateCfg);
    if(errorMsg){
      Notification.error(errorMsg);
      cancelWsTemplateApply(ws);
      return;
    }
    // No need to fix template while applying it. User can fix this manually after the rules shows up in the pipeline.
    _applyConfigToWs(ws, templateCfg);
  }

  function _applyConfigToWs(ws, templateCfg){
    ws.status = 'processing';
    var TaskService = TaskServiceFactory.get_by_dataview_id(ws.id);
    TaskService.taskProgressEvent.fire_event('in_progress')
    var dataviewExportConfigResource = new $resource(config.api.dataviewsExportConfig, {'ws_id': ws.id});
    let savePromise = dataviewExportConfigResource.save({}, {config: templateCfg}).$promise;
    savePromise.then(function(data){
      if (data.STATUS == 'FAILURE'){
        Notification.error(data.data.ERROR_MESSAGE);
        cancelWsTemplateApply(ws);
        DataviewService.fire_on_apply_template();
      }else{
        _applySuccess(data.request_id, ws.id);
      }
    }, function(data){
      let error_message = messages.error;
      if(data.data && data.data.ERROR_MESSAGE) {
        error_message += ": " + data.data.ERROR_MESSAGE;
      }
      Notification.error(error_message);
      cancelWsTemplateApply(ws);
    })
  }

  function prepareWsForExport(ws) {

  }

  function cancelWsTemplateApply(ws, error = undefined){
    if(error){
      Notification.error(messages.invalidTemplateFile)
    }
    ws.status = 'ready';
  }

  function _applySuccess(async_request_id, dataview_id){
    $timeout(_query, 1000);

    function _query(){
      var asyncResponse = new $resource(config.api.asyncRequest, {'request_id': async_request_id});
      asyncResponse.get(_successQryCb);

      function _successQryCb(data){
        if(data.STATUS == 'PROCESSING'){
          $timeout(_query, 1000);
        }
        else if(data.STATUS == 'SUCCESS'){
          resources.update();
          // upon completion of 'apply template' operation, fire even to update tasks dependencies
          DataviewService.fire_on_update_tasks_actions_dependencies(true, dataview_id);
          DataviewService.fire_on_apply_template();

        }
        else{
          DataviewService.fire_on_apply_template();
          Notification.error(messages.unknown);
        }
      }
    }
  }
}

TemplateDependencyChooserService.$inject = ['$uibModal', '$rootScope', '$q', 'config', '$timeout'];
export function TemplateDependencyChooserService($uibModal, $rootScope, $q, config, $timeout){
  return {
    fix: fix
  };

  function fix(currentWs, templateCfg){
    let def = $q.defer();

    let dependentTasks = {};
    let dependencyTypes = {};
    let taskIndeces = [];
    let indexToDependencyKey = {};

    for (let i = 0; i < templateCfg.tasks.length; i++) {
      let taskParam = templateCfg.tasks[i].params;
      if (taskParam.hasOwnProperty("LOOKUP")) {
        dependentTasks[i] = taskParam;
        dependencyTypes[i] = 'LOOKUP';
        templateCfg.dependencies[taskParam.LOOKUP.DATAVIEW_ID] = null;
        indexToDependencyKey[i] = taskParam.LOOKUP.DATAVIEW_ID;
        taskIndeces.push(i);
      }
      else if(taskParam.hasOwnProperty("JOIN")){
        dependentTasks[i] = taskParam;
        dependencyTypes[i] = 'JOIN';
        templateCfg.dependencies[taskParam.JOIN.DATAVIEW_ID] = null;
        indexToDependencyKey[i] = taskParam.JOIN.DATAVIEW_ID;
        taskIndeces.push(i);
      }
    }

    if(taskIndeces.length == 0){
      $timeout(function(){
        def.resolve(templateCfg);
      }, 200);
    }
    else{
      let scope = $rootScope.$new();
      scope.taskIndeces = taskIndeces;
      scope.dependencyTypes = dependencyTypes;
      scope.indexToDependencyKey = indexToDependencyKey;
      scope.currentWs = currentWs;
      scope.dependencies = templateCfg.dependencies;
      scope.dependency_metadata = templateCfg.dependency_metadata;

      $uibModal.open({
        templateUrl: config.templates.templateFixer,
        controller: 'TemplateDependencyChooserResolverCtrl',
        controllerAs: "tvm",
        scope: scope,
        bindToController: true,
        windowClass: 'modal-size-medium'
      }).result.then(function(result){
        templateCfg.dependencies = result;
        def.resolve(templateCfg);
      }, def.reject);
    }


    return def.promise;
  }
}

TemplateDependencyChooserResolverCtrl.$inject = ['DatasourceService', '$uibModalInstance', 'DataviewService',
  'Notification'];
export function TemplateDependencyChooserResolverCtrl(DatasourceService, $uibModalInstance, DataviewService, Notification){
  this.$onInit = function() {
    let tvm = this;
    tvm.dataviews = [];
    tvm.submit = submit;
    tvm.result = {};
    DatasourceService.on_list_update('ForTemplatesUI', _updateWksplist);
    _updateWksplist();

    function submit() {
      let allValid = true;
      for (let i = 0; i < tvm.taskIndeces.length; i++) {
        let valid = true;
        let tindex = tvm.taskIndeces[i];
        let dependencyVar = tvm.indexToDependencyKey[tindex];
        let dependency = tvm.dependencies[dependencyVar];
        if (!dependency) {
          valid = false;
        } else {
          let dependencyWs = DataviewService.get_by_id(dependency);
          if (!dependencyWs) {
            valid = false;
          } else {
            valid = isMetadataMatching(dependencyWs.metadata, tvm.dependency_metadata[dependencyVar]);
          }
        }
        if (!valid) {
          Notification.error("View(s) chosen are not matching expectation");
        }

        allValid = allValid && valid;
      }

      if (allValid) {
        $uibModalInstance.close(tvm.dependencies);
      }
    }

    function _updateWksplist() {
      let dataviews = [];
      $.each(Object.keys(DatasourceService.list), function (i, object_id) {
        var item = DatasourceService.list[object_id];
        if (item.type == 'datasource' && item != tvm.currentWs.datasource) {
          $.each(Object.keys(item.dataviews), function (j, ws_id) {
            var ws = item.dataviews[ws_id];
            ws.display_name_for_join = item.name + ' > ' + ws.name;
            dataviews.push(ws);
          });
        }
      });
      tvm.dataviews = dataviews;
    }
  }
}

export function isMetadataMatching(m1, m2){
  if(m1.length != m2.length){
    return false;
  }
  /*
   * for every column in metadata m1, check if there is some column in m2 that has same internal_name and type.
   * Return true if every column in m1 satisfies this condition
   */
  let is_metadata_same_ignoring_order = m1.every(column1 => m2.some(column2 => {
   return  (column1.internal_name == column2.internal_name) && (column1.type == column2.type);
  })
  );
  return is_metadata_same_ignoring_order;
}
