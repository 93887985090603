/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';



/**
 * @ngInject
 */
tableMenuManagerFactory.$inject = ['pivotManagerFactory', 'eventCallbackManagerFactory', 'utils', 'filterManagerFactory',
                                 'filterUI', 'sortManagerFactory', 'c', 'analyticsService'];
export function tableMenuManagerFactory(pivotManagerFactory, eventCallbackManagerFactory, utils, filterManagerFactory,
                                 filterUI, sortManagerFactory, c, analyticsService){
  return {
    get: getMenu
  }

  function getMenu(options){
    return new TableMenu(options);
  }

  function TableMenu(options){
    var self = this;
    self.getParam = getParam;
    self.setParam = setParam;
    self.getDisplayProperties = getDisplayProperties;
    self.onColumnChange = onColumnChange;
    self.toggleColumnVisibility = toggleColumnVisibility;
    self.toggleAggregation = toggleAggregation;
    self.containsChecked = containsChecked;
    self.random_name = 'table_' + utils.getRandomString();
    var tableEvent = new eventCallbackManagerFactory(self.random_name);
    self.on_valid_update = tableEvent.add_callback;
    self.applyMenuChanges = applyMenuChanges;
    self.containsTextColumns = containsTextColumns;
    self.resetMenuAttributes = resetMenuAttributes;

    self.new_metadata = [];
    self.toggleDrilldown = toggleDrilldown;
    self.addDrilldown = addDrilldown;
    self.editDrilldown = editDrilldown;
    self.changeDrilldownColumn = changeDrilldownColumn;
    self.saveFormat = saveFormat;
    self.haveAttributesChanged = haveAttributesChanged;
    self.closeMenu = closeMenu;
    self.eventTrack = {
      columnChanged: function (params) {
        analyticsService.userEventTrack(c.userEvents.elements.editor.columnChanged, params)
      },
      columnAdded: function (params) {
        analyticsService.userEventTrack(c.userEvents.elements.editor.columnAdded, params)
      },
      aggregationAdded: function (params) {
        analyticsService.userEventTrack(c.userEvents.elements.editor.aggregationAdded, params)
      },
    };

    self.openConditionMenu = openConditionMenu;

    self.allowedAggregations = ["SUM", "AVG", "MIN", "MAX"];
    self.decimalPlacesOptions = [
      {display: 0, value: 0},
      {display: 0.1, value: 1},
      {display: 0.12, value: 2},
      {display:0.123, value:  3},
      {display:0.1234, value: 4},
      {display:0.12345, value: 5}
    ];

    self.currencyOptions = ["€", "$", "¥", "£", "₹"];

    //-1 is used to indicate no decimal places selected
    self.defaultMenuAttributes = {
      visible: null,
      decimal_spec: -1,
      comma_separated: false,
      is_percentage: false,
      currency_symbol: null,
    };

    self.menuAttributes = _.cloneDeep(self.defaultMenuAttributes);

    self.visibilityOptions = [
      {value: false, display: 'Hide'},
      {value: true, display: 'Show'},
    ];

    self.menuAttributesSelected = false;

    self.isMenuOpen = false;


    function applyMenuChanges() {
      self.pivotManager.selected_items.forEach(function(item) {
        if(item.is_checked) {
          item.FORMAT.decimal_spec = self.menuAttributes.decimal_spec;
          item.FORMAT.comma_separated = self.menuAttributes.comma_separated;
          item.FORMAT.is_percentage = self.menuAttributes.is_percentage;
          item.FORMAT.currency_symbol = self.menuAttributes.currency_symbol;
          item.is_checked = false;
        }
    });
      self.pivotManager.derivedColumns.forEach(function(item) {
        if(item.is_checked) {
          item.FORMAT.decimal_spec = self.menuAttributes.decimal_spec;
          item.FORMAT.comma_separated = self.menuAttributes.comma_separated;
          item.FORMAT.is_percentage = self.menuAttributes.is_percentage;
          item.FORMAT.currency_symbol = self.menuAttributes.currency_symbol;
          item.is_checked = false;
        }
      });
    self.resetMenuAttributes();
}

    //Check if there are checked rows between the selected ones
    function containsChecked() {
      var is_checked = false;
      self.pivotManager.selected_items.forEach(function (item) {
        if(item.is_checked){
          is_checked = true;
        }
      });

      self.pivotManager.derivedColumns.forEach(function (item) {
        if(item.is_checked){
          is_checked = true;
        }
      });
      self.isMenuOpen =  is_checked;
    }


    //Check if there are text rows between the checked ones
    function containsTextColumns() {
      var is_text = false;
      self.pivotManager.selected_items.forEach(function (item) {
        // TODO: SENTRYERROR:FRONTEND-82:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/413129195
          // TODO: SENTRYERROR:FRONTEND-83:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/413129602
      if(item.column.type === 'TEXT' && item.is_checked){
          is_text = true;
        }
      });
      return is_text;
    }

    function resetMenuAttributes() {
      self.menuAttributes = _.cloneDeep(self.defaultMenuAttributes);
    }

    function haveAttributesChanged() {
      return angular.equals(self.menuAttributes, self.defaultMenuAttributes)
    }

    function closeMenu() {
      self.pivotManager.selected_items.forEach(function(item) {
          item.is_checked = false;
      });
      self.pivotManager.derivedColumns.forEach(function(item) {
          item.is_checked = false;
      });
      self.isMenuOpen = false;
      self.resetMenuAttributes();
    }

    self.sortManager = sortManagerFactory.get_manager({
      metadata: _.cloneDeep(self.new_metadata)
    });
    self.sortManager.sortChangeCallback = tableEvent.fire_event;

    self.uiSortableOptions = {
      handle: '> .dragger',
      stop: tableEvent.fire_event
    }

    var defaultDisplayProperties = {type: 'table', title: null, HIDDEN: {}, aggregations: {}};

    init();
    update(options);

    function saveFormat() {
      options.modalController.saveElement()
    }
    function openConditionMenu(){
      filterUI.open(self.filterManager).then(tableEvent.fire_event);
    }

    function init() {
      self.metadata = options.metadata;
      self.displayProperties = _.cloneDeep(defaultDisplayProperties);
      self.pivotManager = pivotManagerFactory.get_manager({
        metadata: options.dataview.metadata,
        dataview: options.dataview,
        generateInternalNames: true
      });
      self.pivotManager.onItemAdded('fromInsideTableMenuManager', tableEvent.fire_event);
    }

    function toggleDrilldown() {
      if (!self.element.display_properties.hasOwnProperty('drilldown')) {
        self.element.display_properties.drilldown = {
          enabled: false,
        }
      }
      if (!self.element.display_properties.drilldown.hasOwnProperty('default')) {
        self.element.display_properties.drilldown.default = {};
      }
      if (self.element.display_properties.drilldown && self.element.display_properties.drilldown.enabled
        && self.element.display_properties.drilldown.default.elementId) {
        self.element.display_properties.drilldown.enabled = false;
        tableEvent.fire_event();
      }
    }

    function changeDrilldownColumn() {
      var dispProperties = getDisplayProperties();
      var newProps = angular.merge(dispProperties, self.element.display_properties);
      setDisplayProperties(newProps);
      tableEvent.fire_event();
      if (!self.element.display_properties.drilldown.default.elementId) {
        addDrilldown();
      }
    }

    function addDrilldown() {
      var rootElementId = self.element.display_properties.isDrilldown ?
        self.element.display_properties.rootElementId : self.element.id;
      var _newElement = {type: "CHART"};
      var _additionalDispProps = {
        isDrilldown: true,
        rootElementId: rootElementId,
        drillUp: {
          elementId: self.element.id,
          key: 'default'
        }
      };
      self.modalController.selectElement(_newElement, undefined, undefined,
        _additionalDispProps);
      if (self.drilldownManager) {
        self.drilldownManager.openEmptyDrilldown(_newElement, _additionalDispProps);
      }
    }

    function editDrilldown(drilldownElementId) {
      self.drilldownManager.openDrilldown(drilldownElementId);
      // self.modalController.selectElementById(drilldownElementId);
    }

    function update(options) {
      self.modalController = options.modalController;
      self.element = options.element;
      self.metadata = options.metadata;
      self.drilldownManager = options.drilldownManager;
      if (self.element.id) {
        self.filterManager = filterManagerFactory.get_manager({
          metadata: options.metadata,
          teardownOptions: {
            teardown_callback: tableEvent.fire_event
          },
          dataviewId: options.dataview.id,
          context: options.context
        });
        updateSortMetadata();
      }
      if (self.drilldownManager) {
        if (self.element.id) {
          self.currentDrillItem = self.drilldownManager.getDrillItemById(self.element.id);
        } else {
          self.currentDrillItem = self.drilldownManager.getLatestDrillItem();
        }
      }
      if (options.element){
        if(options.element.param){
          self.setParam(options.element.param, options.element.display_properties);
        }

        if (options.element.display_properties){
          setDisplayProperties(options.element.display_properties);
        }
      }
      if (options.additionalDisplayProperties != undefined) {
        angular.merge(self.displayProperties, options.additionalDisplayProperties);
      }
    }

    function _seperateParamDisplayProperties(param){
      var cpParam = _.cloneDeep(param);
      var pivotParam = _.cloneDeep(param.PIVOT);
      var internal_name_format_map = {};

      pivotParam.SELECT.forEach(function(item){
        if(item.FORMAT){
          internal_name_format_map[item.INTERNAL_NAME] = item.FORMAT;
          delete item.FORMAT;
        }
      });

      pivotParam.DERIVED_SELECT.forEach(function(item){
        var internal_name = item.MATH.AS.INTERNAL_NAME;
        if(item.FORMAT){
          internal_name_format_map[internal_name] = item.FORMAT;
          delete item.FORMAT;
        }

      });

      pivotParam.GROUP_BY.forEach(function(item){
        if(item.FORMAT){
          internal_name_format_map[item.COLUMN] = item.FORMAT;
          delete item.FORMAT;
        }

      });

      cpParam.PIVOT = pivotParam;
      return {
        param: cpParam,
        displayProperties: {
          FORMAT_INFO: internal_name_format_map
        }
      };
    }

    function _combineParamDisplayProperties(param, displayProperties){
      if(!displayProperties){
        displayProperties = {};
      }
      var internal_name_format_map = displayProperties.FORMAT_INFO;
      if(!internal_name_format_map){
        internal_name_format_map = {};
      }
      var pivotParam = param.PIVOT;
      pivotParam.SELECT.forEach(function(item){
        if(internal_name_format_map.hasOwnProperty(item.INTERNAL_NAME)){
          item.FORMAT = internal_name_format_map[item.INTERNAL_NAME];
        }
      });

      pivotParam.DERIVED_SELECT.forEach(function(item){
        var internal_name = item.MATH.AS.INTERNAL_NAME;
        if(internal_name_format_map.hasOwnProperty(internal_name)){
          item.FORMAT = internal_name_format_map[internal_name];
        }
      });

      pivotParam.GROUP_BY.forEach(function(item){
        if(internal_name_format_map.hasOwnProperty(item.COLUMN)){
          item.FORMAT = internal_name_format_map[item.COLUMN];
        }
      });
    }

    function _updateNewMetadata() {
      var param = self.pivotManager.getParam();
      var new_metadata = [];
      angular.forEach(param.PIVOT.GROUP_BY, function (col) {
        new_metadata.push(utils.metadata.get_column_by_internal_name(self.metadata, col.COLUMN));
      });
      angular.forEach(param.PIVOT.SELECT, function (col) {
        new_metadata.push({'internal_name': col.INTERNAL_NAME, 'display_name': col.AS, 'type': 'NUMERIC'});
      });
      angular.forEach(param.PIVOT.DERIVED_SELECT, function (col) {
        new_metadata.push({'internal_name': col.MATH.AS.INTERNAL_NAME, 'display_name': col.MATH.AS.COLUMN, 'type': 'NUMERIC'});
      });
      self.new_metadata.splice(0);
      angular.extend(self.new_metadata, utils.metadata.add_type_to_display_name(new_metadata));
    }

    function updateSortMetadata() {
      _updateNewMetadata();
      self.sortManager.updateMetadata(_.cloneDeep(self.new_metadata));
    }

    function _isNonEmptyArray(v){
      return (v && angular.isArray(v) && v.length != 0)
    }

    function getParam() {
      updateSortMetadata();
      var param = self.pivotManager.getParam();
      var ret = _seperateParamDisplayProperties(param).param;
      if(self.filterManager && self.filterManager.condition) {
        ret.CONDITION = self.filterManager.getParam();
      }
      if(!_isNonEmptyArray(param.PIVOT.SELECT) && !_isNonEmptyArray(param.PIVOT.GROUP_BY)){
        return null;
      }
      return ret;
    }

    function setParam(param, displayProperties) {
      var newParam = _.cloneDeep(param);
      _combineParamDisplayProperties(
        newParam, _.cloneDeep(displayProperties));

      if(displayProperties && displayProperties.FORMAT_INFO){
        self.pivotManager.setFormatInfo(_.cloneDeep(displayProperties).FORMAT_INFO);
      }
      self.pivotManager.setParam(newParam);
      if (param.hasOwnProperty('CONDITION') && self.filterManager) {
        self.filterManager.setParam(param.CONDITION, param?.EXECUTION_TIMESTAMP);
      }
      updateSortMetadata();
    }

    function getDisplayProperties() {
      var param = self.pivotManager.getParam();
      var sortParam = self.sortManager.getParam();
      if (sortParam) {
        self.displayProperties.SORT = sortParam;
      }
      var cpDisplayProperties = _.cloneDeep(self.displayProperties);
      angular.merge(cpDisplayProperties,
        _seperateParamDisplayProperties(param).displayProperties);
      return cpDisplayProperties;
    }

    function setDisplayProperties(displayProperties) {
      self.displayProperties = _.cloneDeep(defaultDisplayProperties);
      angular.merge(self.displayProperties, displayProperties);
      if (self.displayProperties.hasOwnProperty('SORT')) {
        self.sortManager.setParam(self.displayProperties.SORT);
      }
    }

    function onColumnChange(){
      self.displayProperties.title = self.column.display_name;
    }

    function toggleColumnVisibility(column_name){
      if(self.displayProperties.HIDDEN.hasOwnProperty(column_name)){
        delete self.displayProperties.HIDDEN[column_name];
      }
      else{
        self.displayProperties.HIDDEN[column_name] = true;
      }
      self.displayProperties.HIDDEN_COLUMNS = Object.keys(self.displayProperties.HIDDEN);
      tableEvent.fire_event();
    }

    function toggleAggregation(column_name){
      if(self.displayProperties.aggregations.hasOwnProperty(column_name)){
        delete self.displayProperties.aggregations[column_name];
      }
      else{
        self.displayProperties.aggregations[column_name] = 'SUM';
      }
      tableEvent.fire_event();
    }

  }
}
