'use strict';

import * as angular from 'angular';
import { dataviewConfig } from './../dataview.config';
import { addColumnManagerFactory } from './addColumn.manager';
import { valAllAddColumnOnChange, valAddColumnDuplicateName } from './addColumn.directives';
import { combineManagerFactory } from './combine.manager';
import {valConvertColDuplicateCols, columnDuplicateFilter, convertManagerFactory, valColConversion, valToType} from './convert.manager';
import {copyManagerFactory, valCopyCol} from './copy.manager';
import {bulkCopyManagerFactory, valNewColumnDuplicateName} from './bulkCopy.manager';
import {deleteColumnDuplicateFilter, deleteColumnManagerFactory, valColDeletion} from './deleteColumn.manager';
import {destinationColumnManagerFactory, valDestinationCol} from './destination.manager';
import {extractDateManagerFactory, valDateExtractCol} from './extractDate.manager';
import {extractTextManagerFactory, valExtractCol} from './extractText.manager';
import {formatTextManagerFactory, valTextTransformCol} from './formatText.manager';
import { valAtLeastOneDateComponent } from './incrementDate.directives';
import {incrementDateManagerFactory, valIncrDecrCol} from './incrementDate.manager';
import {dateDiffManagerFactory, filterDateOptionsForDiff, valDateDiffCol} from './date.diff.manager';
import { insertManagerFactory } from './insert.manager';
import {fillValuesManagerFactory, valFillCol} from './fillValues.manager';
import {JoinManagerFactory, valJoinMap} from './join.manager';
import { joinSourceGridsController } from './join.sourceGrids.controller';
import { limitManagerFactory } from './limit.manager';
import {lookupManagerFactory, valKeyCol} from './lookup.manager';
import { mathManagerFactory } from './math.manager';
import { removeDuplicatesManagerFactory } from './removeDuplicates.manager';
import {
  filterSelectedSearchTerms, shouldBeValidBulkReplaceParam, replaceManagerFactory,
  shouldBeValidReplacePairs, bucketListHeaderAutoWidth, valReplaceCol
} from './replace.manager';
import { runningTotalManagerFactory } from './runningTotal.manager';
import {valPartitionCol, valWindowArgCol, windowFunctionManagerFactory, windowGroupsDuplicateFilter} from './windowFunction.manager';
import { selectManagerFactory } from './select.manager';
import {smallOrLargeManagerFactory, shouldBeValidSmallLargeSelection, valSmallLargeSelection} from './smallOrLarge.manager';
import {unnestManagerFactory, shouldBeValidUnnestSelection, valUnnestCol} from './unnest.manager';
import {valDuplicateSortCol, metadataSort, WorksapceMetadataSortController, dataviewMetadataSort, valSortCol} from './sort.directive';
import { sortManagerFactory } from './sort.manager';
import {valDuplicateNewColName, valSplitCol} from './split.directives';
import {splitManagerFactory} from './split.manager';
import { taskController } from './task.controller';
import { taskDescriber } from './taskDescriber.service';
import { TaskServiceFactory } from './taskService.factory';
import {JsonExtractManagerFactory, shouldBeValidJsonKeyExtraction, valJsonCol} from './json-handle.manager';
import {collapseRowsManagerFactory, filterColumnsForCollapse} from './collapseRows.manager';
import {reorderDirective} from './reorder.directive';
import {reorderFactory} from './reorder.factory';
import {collapseRowsColumns, collapseColumnNameValidator} from './collapseRows.directives';

export let taskModule = angular.module('app.dataview.task', [
  'app.models', 'ui.bootstrap', 'app.common'
]);

taskModule.controller('joinSourceGridsController', joinSourceGridsController);
taskModule.controller(dataviewConfig.taskController, taskController);
// directives
taskModule.directive('metadataSort', metadataSort);
taskModule.directive('valSortCol', valSortCol);
taskModule.directive('valFillCol', valFillCol);
taskModule.directive('valExtractCol', valExtractCol);
taskModule.directive('dataviewMetadataSort', dataviewMetadataSort);
taskModule.controller('WorksapceMetadataSortController', WorksapceMetadataSortController);
taskModule.directive('shouldBeValidBulkReplaceParam', shouldBeValidBulkReplaceParam);
taskModule.directive('valAddColumnDuplicateName', valAddColumnDuplicateName);
taskModule.directive('valNewColumnDuplicateName', valNewColumnDuplicateName);
taskModule.directive('valAllAddColumnOnChange', valAllAddColumnOnChange);
taskModule.directive('valSmallLargeSelection', valSmallLargeSelection);
taskModule.directive('valAtLeastOneDateComponent', valAtLeastOneDateComponent);
taskModule.directive('valConvertColDuplicateCols', valConvertColDuplicateCols);
taskModule.directive('valColConversion', valColConversion);
taskModule.directive('valToType', valToType);
taskModule.directive('valDestinationCol', valDestinationCol);
taskModule.directive('valCopyCol', valCopyCol);
taskModule.directive('valDuplicateNewColName', valDuplicateNewColName);
taskModule.directive('shouldBeValidSmallLargeSelection', shouldBeValidSmallLargeSelection);
taskModule.directive('shouldBeValidUnnestSelection', shouldBeValidUnnestSelection);
taskModule.directive('shouldBeValidJsonKeyExtraction', shouldBeValidJsonKeyExtraction);
taskModule.directive('valDuplicateSortCol', valDuplicateSortCol);
taskModule.directive('reorderDirective', reorderDirective);
taskModule.directive('valColDeletion', valColDeletion);
taskModule.directive('valWindowArgCol', valWindowArgCol);
taskModule.directive('valPartitionCol', valPartitionCol);
taskModule.directive('valIncrDecrCol', valIncrDecrCol);
taskModule.directive('valDateExtractCol', valDateExtractCol);
taskModule.directive('valJsonCol', valJsonCol);
taskModule.directive('valDateDiffCol', valDateDiffCol);
taskModule.directive('valUnnestCol', valUnnestCol);
taskModule.directive('valJoinMap', valJoinMap);
taskModule.directive('valKeyCol', valKeyCol);
taskModule.directive('valReplaceCol', valReplaceCol);

//filters
taskModule.filter("deleteColumnDuplicateFilter", deleteColumnDuplicateFilter);
taskModule.filter("windowGroupsDuplicateFilter", windowGroupsDuplicateFilter);
taskModule.filter('filterSelectedSearchTerms', filterSelectedSearchTerms);
taskModule.filter('filterDateOptionsForDiff', filterDateOptionsForDiff);
taskModule.filter('columnDuplicateFilter', columnDuplicateFilter);
taskModule.filter('filterColumnsForCollapse', filterColumnsForCollapse);
//services
taskModule.service('destinationColumnManagerFactory', destinationColumnManagerFactory);
taskModule.service('mathManagerFactory', mathManagerFactory);
taskModule.service('sortManagerFactory', sortManagerFactory);
taskModule.service('taskDescriber', taskDescriber);
taskModule.service('TaskServiceFactory', TaskServiceFactory);
// task managers
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.addColumn].manager, addColumnManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.combine].manager, combineManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.convert].manager, convertManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.copy].manager, copyManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.bulkCopy].manager, bulkCopyManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.delete].manager, deleteColumnManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.duplicates].manager, removeDuplicatesManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.extract_date].manager, extractDateManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.extract_text].manager, extractTextManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.filter].manager, selectManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.format_text].manager, formatTextManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.increment_date].manager, incrementDateManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.date_diff].manager, dateDiffManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.insert].manager, insertManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.fillValues].manager, fillValuesManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.join].manager, JoinManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.limit].manager, limitManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.lookup].manager, lookupManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.replace].manager, replaceManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.running_total].manager, runningTotalManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.window_function].manager, windowFunctionManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.smallOrLarge].manager, smallOrLargeManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.unnest].manager, unnestManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.split].manager, splitManagerFactory);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.collapseRows].manager, collapseRowsManagerFactory);
taskModule.directive('shouldBeValidReplacePairs', shouldBeValidReplacePairs);
taskModule.directive('bucketListHeaderAutoWidth', bucketListHeaderAutoWidth);
taskModule.service(dataviewConfig.taskConfig[dataviewConfig.tasks.json_handle].manager, JsonExtractManagerFactory);
taskModule.factory('reorderFactory', reorderFactory);
taskModule.directive('valTextTransformCol', valTextTransformCol);
taskModule.directive('valSplitCol', valSplitCol);
taskModule.directive('collapseRowsColumns', collapseRowsColumns)
taskModule.directive('collapseColumnNameValidator', collapseColumnNameValidator)

