export const WORKSPACE_PERMISSIONS = {
  ADMIN: 'workspace_admin',
  MEMBER: 'workspace_member',
  OWNER: 'workspace_owner'
}

export const PROJECT_PERMISSIONS = {
  ADMIN: 'project_admin',
  ANALYST: 'project_analyst'
}
