import {constants} from "../../common/app.constants";
import * as _ from "lodash-es";
import * as angular from "angular";
import {config} from "../../common/app.config";


export class CrossTabController implements ng.IController {
  public alllowedAggregations = constants.aggregationsArray;
  public metadata: any[any];
  public columns: any[] = [];
  public rows: any[]= [];
  public select: any = {FUNCTION: "COUNT", COLUMN: null, FORMAT: null};
  public selectOptions: any[];
  public context: any;
  public ngModel: any;
  static $inject = ['$timeout', 'utils'];
  public constructor(public $timeout, public utils){
    // @ts-ignore
    this.$onInit = function (){
      if (!this.ngModel) {
        this.ngModel = this.getParam();
      } else {
        this.syncFromNgModel();
      }
      this.utils = utils
    }

  }

  public validate(){
    return true;
  }

  public getParam(){
    let rows = [];
    let cols = [];
    _.forEach(this.rows, function(r){
      rows.push(r.getParam());
    });
    _.forEach(this.columns, function(c){
      cols.push(c.getParam());
    });

    let cp: any = {
      ROWS: rows,
      COLUMNS: cols,
      SELECT: this.select
    };
    return {
      CROSSTAB: cp
    };
  }

  public sanitizeParams(params){
    _.forEach(params.CROSSTAB.ROWS, function (item) {
      var col_info = this.utils.metadata.get_column_by_internal_name(this.metadata, item.COLUMN)
       if(col_info && col_info.type != item.TYPE && !item.hasOwnProperty('COMPONENT')){
        item.TYPE = col_info.type
      }
    }.bind(this))
  }

  public setParam(param){
    this.sanitizeParams(param)
    let cp = param.CROSSTAB;
    let mdata = this.utils.sanitizeMetadataName(this.metadata);
    let rows = this.rows;
    let columns = this.columns;
    this.selectOptions = _.filter(this.metadata, {'type': 'NUMERIC'})
    if(cp.SELECT){
      this.select = cp.SELECT;
      let selected_col = _.filter(this.metadata, {'internal_name': this.select.COLUMN})
      if (selected_col.length) {
        if(!this.utils.metadata.get_column_by_internal_name(this.selectOptions, this.select.COLUMN)){
          this.selectOptions.push(selected_col[0]);
        }
      }else if(this.select.COLUMN !== null){
        this.selectOptions.push({'internal_name': this.select.COLUMN, 'display_name': 'Column Missing'});
      }
    }
    let setModel = this.getBoundSetModelCb();

    if(angular.isArray(cp.ROWS)){
      _.forEach(cp.ROWS, function(r){
        let rspec = new RowSpec(setModel);
        rspec.setMetadata(mdata);
        rspec.setParam(r);
        rows.push(rspec);
      });
    }
    if(angular.isArray(cp.COLUMNS)){
      _.forEach(cp.COLUMNS, function(c){
        let cspec = new ColSpec(setModel);
        cspec.setMetadata(mdata);
        cspec.setParam(c);
        columns.push(cspec);
      });

    }
  }


  public addToRows(column){
    let setModel = this.getBoundSetModelCb();
    let rspec = new RowSpec(setModel);
    rspec.setMetadata(this.metadata);
    rspec.setParam({COLUMN: column.internal_name, type: constants.text});
    this.rows.push(rspec);
    this.reEvalSelections();
    this.setModel();
  }

  public addToColumns(column){
    let setModel = this.getBoundSetModelCb();
    let cspec = new ColSpec(setModel);
    cspec.setMetadata(this.metadata);
    cspec.setParam({COLUMN: column.internal_name, type: constants.text});
    this.columns.push(cspec);
    this.reEvalSelections();
    this.setModel();
  }

  public removeFromRows(item){
    let idx = this.rows.indexOf(item);
    if(idx != -1){
      this.rows.splice(idx, 1);
      this.reEvalSelections();
    }
    this.setModel();
  }

  public removeFromCols(item){
    let idx = this.columns.indexOf(item);
    if (idx != -1) {
      this.columns.splice(idx, 1);
      this.reEvalSelections();
    }
    this.setModel();
  }

  public getBoundSetModelCb() {
    var boundFunction = (function () {
      this.setModel();
    }).bind(this);
    return boundFunction;
  }

  public setModel(){
    this.ngModel = this.getParam();
  }

  public syncFromNgModel(){
    if(this.ngModel){
      this.setParam(this.ngModel);
      this.reEvalSelections();
    }
  }

  public reEvalSelections(){
    let selectedCols = [];
    _.forEach(this.rows, function (spec) {
      if(spec.column && spec.column.internal_name){
        selectedCols.push(spec.column.internal_name);
      }
    });
    _.forEach(this.columns, function (spec) {
      if(spec.column && spec.column.internal_name){
        selectedCols.push(spec.column.internal_name);
      }
    });
    _.forEach(this.metadata, function(c){
      c.selectedForCrosstab = selectedCols.indexOf(c.internal_name) != -1;
    });
  }
}


const defaultRowDateExtract = {
    type: 'DATE',
    display: 'Day, Month, Year (date)',
    format: {date_format: "%d-%b-%Y"},
    value: {TRUNCATE: 'DAY'}
};

const RowDateOptions = [
  {type: 'DATE', display: 'Second (date)', format: {date_format: "%d %b, %Y %H:%M"}, value: {TRUNCATE: 'SECOND'}},
  {type: 'DATE', display: 'Minute (date)', format: {date_format: "%d %b, %Y %H:%M"}, value: {TRUNCATE: 'MINUTE'}},
  {type: 'DATE', display: 'Hour (date)', format: {date_format: "%d %b, %Y %H:%M"}, value: {TRUNCATE: 'HOUR'}},
  defaultRowDateExtract,
  {type: 'TEXT', display: 'Month, Year (text)', value: {COMPONENT: 'year_month'}},
  {type: 'NUMERIC', display: 'Year (num)', value: {COMPONENT: 'year'}},
  {type: 'TEXT', display: 'Month (text)', value: {COMPONENT: 'month_text'}},
  {type: 'TEXT', display: 'Year (text)', value: {COMPONENT: 'year'}},
  {type: 'TEXT', display: 'Weekday (text)', value: {COMPONENT: 'weekday_text'}},
  {type: 'TEXT', display: 'Datetime (text)', value: {COMPONENT: 'month_day_year_hour_minute_second'}}
];

const ColDateOptions = [
  {type: 'TEXT', display: 'Month, Day, Year (text)', value: {COMPONENT: 'month_day_year'}},
  {type: 'TEXT', display: 'Month, Year (text)', value: {COMPONENT: 'month_year'}},
  {type: 'TEXT', display: 'Month (text)', value: {COMPONENT: 'month_text'}},
  {type: 'TEXT', display: 'Year (text)', value: {COMPONENT: 'year'}},
  {type: 'TEXT', display: 'Weekday (text)', value: {COMPONENT: 'weekday_text'}}
];

class RowSpec{
  public column: any;
  public metadata:any[];
  public granularityOption: any = defaultRowDateExtract;
  public granularityOptions = RowDateOptions;
  static $inject = [];
  public constructor(public handleChangeCb: Function){

  }
  public setMetadata(m: any){
    this.metadata = m;
  }

  public validate(){
    return true;
  }

  public handleGranularityChange(){
    this.handleChangeCb();
  }

  public getParam(){

    let p: any = {
      COLUMN: this.column.internal_name,
      TYPE: this.column.type,
    };
    if(p.TYPE == constants.date){
      if (this.granularityOption) {
        p.TYPE = this.granularityOption.type;
        _.merge(p, this.granularityOption.value);
        p.FORMAT = this.granularityOption.format;
      }
    }
    return p;
  }

  public setParam(param: any){
    if(param.hasOwnProperty('COLUMN')){
      let filtered = _.filter(this.metadata, {internal_name: param.COLUMN});
      if(filtered.length){
        this.column = filtered[0];
      }
      else{
        this.column = {'display_name': 'Column Missing', 'type': param.TYPE, 'internal_name':param.COLUMN}
      }
    }
    if(this.column && this.column.type == constants.date){
      let filteredGranularity = _.filter(this.granularityOptions, {type: param.TYPE});
      if(param.hasOwnProperty('COMPONENT')){
        filteredGranularity = _.filter(filteredGranularity, {value: {COMPONENT: param.COMPONENT}});
      }
      else if (param.hasOwnProperty('TRUNCATE')){
        filteredGranularity = _.filter(filteredGranularity, {value: {TRUNCATE: param.TRUNCATE}});
      }
      if(filteredGranularity.length){
        this.granularityOption = filteredGranularity[0];
      }
    }
  }


}

class ColSpec extends RowSpec{
  public granularityOption = ColDateOptions[0];
  public granularityOptions = ColDateOptions;
}

/**
 * @ngInject
 */
crossTabParams.$inject = [];
export function crossTabParams(){
  return {
    require: 'ngModel',
    scope: {
      ngModel: '=',
      metadata: '=',
      context: '=',
    },
    controller: 'CrossTabController',
    controllerAs: 'ct',
    bindToController: true,
    templateUrl: config.templates.crosstab,
    link: function(scope, element, attrs){
      scope['rowsDropCallback'] = function(event, ui){
        var col = ui.draggable.scope().c;
        scope.ct.addToRows(col);
        scope['rowsOnOutCallback']();
      };

      scope['columnsDropCallback'] = function (event, ui) {
        var col = ui.draggable.scope().c;
        scope.ct.addToColumns(col);
        scope['colsOnOutCallback']()
      };

      scope['rowsOnHoverCallback'] = function(){
        scope['showRowsHighlight'] = true;
      };

      scope['colsOnHoverCallback'] = function(){
        scope['showColsHighlight'] = true;
      };

      scope['rowsOnOutCallback'] = function(){
        scope['showRowsHighlight'] = false;
      };

      scope['colsOnOutCallback'] = function(){
        scope['showColsHighlight'] = false;
      };

    }
  }
}

crossTabGenSpecCanNotBeEmpty.$inject = [];
export function crossTabGenSpecCanNotBeEmpty(){
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (s, e, a, ctrl){

      ctrl.$validators.crossTabGenSpecCanNotBeEmpty = function(mV, vV){
        let isValid = true;
        if(_.isPlainObject(mV)){
          if(mV.CROSSTAB.ROWS.length && mV.CROSSTAB.COLUMNS.length){
            _.forEach(s.ct.columns,function (colSpec) {
              let index = _.findIndex(s.ct.metadata, {internal_name: colSpec.column.internal_name})
              if(index == -1){
                isValid = false;
              }
            })
            _.forEach(s.ct.rows,function (rowSpec) {
              let index = _.findIndex(s.ct.metadata, {internal_name: rowSpec.column.internal_name})
              if(index == -1){
                isValid = false;
              }
            })
            if (s.ct.select.FUNCTION !='COUNT' && s.ct.select.COLUMN){
              let selectColIndex = _.findIndex(s.ct.metadata, {internal_name: s.ct.select.COLUMN})
              if(selectColIndex == -1){
                isValid = false;
              }
            }
          }else{
            isValid = false;
          }
        }else{
          isValid = false;
        }
        return isValid;
      }
    }
  }
}

valColType.$inject = [];
export function valColType() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valColType(scope, elem, attrs, ctrl) {
      ctrl.$validators.valColType = function (modelValue, viewValue) {
        let isValid = true;
        let col = _.filter(scope.$parent.ct.metadata, {'internal_name': modelValue});
        if (col.length && col[0]['type'] != 'NUMERIC'){
          isValid = false;
        }
        return isValid;
      };
    }
  };
}
