import * as angular from 'angular';
import * as _ from 'lodash-es';

/**
 * @ngInject
 */
ColumnRenamePanelFactory.$inject = ['Notification', '$q', '$timeout', 'analyticsService', 'c', 'utils', 'resources', 'DataviewService', 'MainGridUnitFactory'];
export function ColumnRenamePanelFactory(Notification, $q, $timeout, analyticsService, c, utils, resources, DataviewService, MainGridUnitFactory) {
  let _reportColumnSearchChanged = utils.debounce((value) => {

    if(value === 'findColumnPanel'){
    analyticsService.userEventTrack(c.userEvents.dataviewEvents.findColumnPanelSearchChange,
        {
          eventOrigin: "dataview.findColumnPanel"
        });
    } else {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.modifyColumnsPanelSearchChange,
        {
          eventOrigin: "dataview.modifyColumnsPanel"
        });
    }
  }, 2000, false);

  return {
    get: function (dataview) {
      return new ColumnRenamePanel(dataview);
    }
  };

  function ColumnRenamePanel(dataview) {
    // instead of self.columns use the dataview object itself so the grid does not have to refresh
    var self = this;
    self.isOpen = false;
    self.saveInProgress = false;
    self.populateInProgress = true;
    self.columns = [];
    self.filteredColumns = [];
    self.selectedColumns = [];
    self.search = "";
    self.inEditMode = false;
    self.isToggled = false;
    self._existingHiddenColumns = undefined;
    self.hasChanges = undefined;
    self.selectMenuOpen = false;
    self.new_names={};
    self.longCols = [];
    self.selectedCols = [];
    self.dragInProcess = false;
    self.populateColumns = populateColumns;
    self.toggled = toggled;
    self.save = save;
    self.resetNames = resetNames;
    self.resetName = resetName;
    self.checkForChanges = checkForChanges;
    self.columnNameChanges = columnNameChanges;
    self.toggleSelectMenu = toggleSelectMenu;
    self.onSearchChange = onSearchChange;
    self.filterLongCols = filterLongCols;
    self.longColsList = longColsList;
    self.handleColumnsRename = handleColumnsRename;
    self.longCols = [];
    self.disableApply = disableApply;
    self.noLongCols = noLongCols;
    self.toggleColumnVisibility = toggleColumnVisibility;
    self.hideOrShowSelectedCols = hideOrShowSelectedCols;
    self.setTaskPanel = setTaskPanel;
    self.setParamForTaskPanel = setParamForTaskPanel;
    self.startDragger = startDragger;
    self.stopDragger = stopDragger;
    self.setGridUnitInstance = setGridUnitInstance;
    self.mainGridUnit = undefined;
    self.colsToHighlight = [];

    // Variables and methods for group formatting

    self.colTypeSelection = {NUMERIC: false, TEXT: false, DATE: false};
    self.selectAllColsCheckbox = undefined;
    self.colSelectCount = 0;
    self.selectAllOrNone = selectAllOrNone;
    self.selectAllColTypes = selectAllColTypes;
    self.selectOrUnselectColumn = selectOrUnselectColumn;
    self.selectOrUnselectColumnFast = selectOrUnselectColumnFast;
    self.selectColumn = selectColumn;
    self.unselectColumn = unselectColumn;
    self.incrementColSelectCount = incrementColSelectCount;
    self.decrementColSelectCount = decrementColSelectCount;
    self.selectAllColumns = selectAllColumns;
    self.unselectAllColumns = unselectAllColumns;
    self.selectColsByType = selectColsByType;
    self.openTaskPanel = openTaskPanel;
    self.highlightColInGrid = highlightColInGrid;
    self.handleColumnSelectionFromGrid = handleColumnSelectionFromGrid;
    self.updateColSelectCount = updateColSelectCount;
    self.checkIfOnlyTextColsAreSelected = checkIfOnlyTextColsAreSelected;
    self.nonSelectionChangesPresent = false;
    self.disableHide = false;
    self.disableShow = false;
    // When columns are selected, this is toggled to true
    self.columnSelectionState = false;
    self.draftState = false;
    self.editState = false;
    self.sortableOptions = {
      'handle':'> .col-dragger',
      'start': startDragger,
      'stop': stopDragger,
      'axis': 'y'
    };

    self.resetColBrowserState = resetColBrowserState;
    self.handleEdit = handleEdit;
    self.prevCol = undefined;
    self.editModeInfo = {};
    self.columnExplorerEdit = columnExplorerEdit;
    self.setClassHighlightPostDrop = setClassHighlightPostDrop;
    self.dropColumnHighlight = {};
    self.resetGridState = resetGridState;
    self.updateReorderedItems = updateReorderedItems;
    self.reorderedItems = {};
    self.resetMetadata = resetMetadata;

    self.handleActionFromGrid = handleActionFromGrid;

    // Moved this from dataview controller
    function columnExplorerEdit(col_internal_name, editMode){
      if (editMode==false){
        self.editModeInfo[col_internal_name+'editMode'] = false;
      }
      else{
        self.editModeInfo[col_internal_name+'editMode'] = true;
      }
    }

    function setClassHighlightPostDrop(col_name) {
        self.dropColumnHighlight[col_name] = true;
        $timeout(function () {
        self.dropColumnHighlight[col_name] = false;
        }, 2000);
    }

    // This method is to identify which column was edited before
    // Clicking on consecutive edit buttons should collapse the textbox of the previous edits
    // This is not possible via on-outside-click-exclude-selector directive
    // since the edit button class is common across all columns
    function handleEdit(currentCol) {
      if(self.prevCol && self.prevCol != currentCol){
        columnExplorerEdit(self.prevCol, false);
      }
      self.prevCol = currentCol;
    };

    function resetGridState(){
      self.colsToHighlight = [];
      highlightColInGrid(false);
    }

    function resetMetadata(display_properties){
      var new_columns = utils.metadata.applyDisplayChangesReturnMetadata(dataview.metadata, display_properties, [], {}, false);
      resetColBrowserState(new_columns);
    }

    function resetColBrowserState(new_columns ?){
      self.columnSelectionState = false;
      self.draftState = false;
      self.editState = false;
      self.colTypeSelection = {NUMERIC: false, TEXT: false, DATE: false};
      self.selectAllColsCheckbox = false;
      self.colSelectCount = 0;
      self.sortableOptions.disabled = false;
      if(new_columns){
        self.columns = new_columns;
      }
      else{
        self.columns = _.cloneDeep(dataview.metadata);
      }
      self.newColNames = {};
      self.reorderedItems = {};
      self.columnChangeCount = 0;
      self.columnReorderCount = 0;
      $timeout(function () {
        self.populateColumns('resetCols', self.columns);
      }, 10);
    }

    function setGridUnitInstance(gridUnit) {
      self.mainGridUnit = gridUnit;
    }

    function onSearchChange(value) {
     _reportColumnSearchChanged(value);
    }
    function toggleSelectMenu (isOpen) {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.toggleSelectMenu,
        {
          eventOrigin: 'dataView.modifyColumnsPanel'
        });
      if (isOpen !== self.selectMenuOpen) {
        isOpen = true;
      }
      else {
        isOpen = false;
      }
      self.selectMenuOpen = isOpen;
    }

    function extend(obj, src) {
      for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
      }
      return obj;
    }

    var hasChanges = false;
    self.newColNames = {};

    function columnNameChanges(col) {
      var idx = col.idx;
      $timeout(function () {
        var dispProps = dataview.display_properties || {};
        var hiddenColumns = dispProps.HIDDEN_COLUMNS || [];
        if(col!=undefined && idx!=undefined){
          var was_visible = hiddenColumns.indexOf(col.internal_name) === -1;
          // If visibility of column is changed or if the name is changed add to dictionary/if not changed, remove from dictionary
          if(dataview.metadata[idx-1] && dataview.metadata[idx-1].internal_name != col.internal_name){
            self.newColNames[idx] = col;
            self.nonSelectionChangesPresent = true;
          }
          else if (col.is_visible != was_visible || dataview.metadata[idx-1] && dataview.metadata[idx-1].display_name != col.display_name) {
            self.newColNames[idx] = col;
            self.new_names[idx] = col.display_name.length;
            self.nonSelectionChangesPresent = true;
          }
          else if (dataview.metadata[idx-1] && self.newColNames[idx] && self.newColNames[idx].display_name == dataview.metadata[idx-1].display_name && col.is_visible == was_visible) {
            delete self.newColNames[idx];
            self.nonSelectionChangesPresent = false;
          }
        }
        self.columnChangeCount = Object.keys(self.newColNames).length;
        // If length of newColNames is zero then there are no changes
        hasChanges = Object.keys(self.newColNames).length != 0;
        self.hasChanges = hasChanges;
        self.draftState = hasChanges;
        self.editState = hasChanges;
      }, 100)
    }

    function selectAllOrNone(select_all){
      if(self.colSelectCount > 0){
        unselectAllColumns();
      }
      else if(self.colSelectCount == 0){
        selectAllColumns();
      }
    }

    function selectAllColTypes(checked) {
      self.selectAllColsCheckbox = checked;
      self.colTypeSelection = _.mapValues(self.colTypeSelection, () => checked);
    }

    function setTaskPanel(taskPanel){
      self.taskPanel = taskPanel;
    }

    function setParamForTaskPanel(task_name) {
      // self.colSelectCount = 0;
      var selectedCols = [];
      var createParams = {};
      self.sortableOptions.disabled = false;

      angular.forEach(self.columns, function (col) {
        if(col.is_selected == true) {
          selectedCols.push(col)
        }
      });

      if (task_name == 'convert') {
        var val = [];
        angular.forEach(selectedCols, function (col) {
          val.push({'SOURCE': col.internal_name})
        });
        createParams = {'CONVERT': val};
      }
      // TODO: Needs fixing on function panel. This is a template.
      else if (task_name == 'copy') {
        angular.forEach(selectedCols, function (col) {
          val.push({'SOURCE': col.internal_name});
          createParams = {
            'COPY': {
              'SOURCE': col.internal_name,
              AS: {
                'COLUMN': 'Copy of ' + col.display_name,
                'TYPE': col.type,
                'FORMAT': {}
              }
            }
          }
        });
      }
      else if (task_name == 'bulkCopy') {
        angular.forEach(selectedCols, function (col) {
          val.push({'SOURCE': col.internal_name});
          createParams = {
            'COPY': [{
              'SOURCE': col.internal_name,
              AS: {
                'COLUMN': 'Copy of ' + col.display_name,
                'TYPE': col.type,
                'FORMAT': {}
              }
            }]
          }
        });
      }
      else if (task_name == 'delete') {
        var colsToDelete = [];
        angular.forEach(selectedCols, function (col) {
          colsToDelete.push(col.internal_name)
        });
        createParams = {"DELETE": colsToDelete};
      }

      //TODO: Add condition only for text columns

      else if(task_name == 'replace') {
        var replaceCols = [];
        angular.forEach(selectedCols, function (col) {
          if(col.type=='TEXT'){
            replaceCols.push(col.internal_name)
          }
        });

        createParams = {
          REPLACE: {
            SOURCE: replaceCols,
            VALUE_PAIR: [{SEARCH_VALUE: '', REPLACE_VALUE: ''}],
                MATCH_CASE: true,
                MATCH_WORDS: true
          },
          CONDITION: null
        };
      }
      resetGridState();
      resetColBrowserState();
      return createParams
    }

    function startDragger(){
      self.dragInProcess = true;
    }

    function stopDragger(e, ui) {
      self.dragInProcess = false;
      setClassHighlightPostDrop(ui.item.scope().col.internal_name);
      updateReorderedItems(ui.item);
      checkForChanges();
    }

    function updateReorderedItems(uiItem){
      var colObject = uiItem.scope().col;
      // adding a +1 in condition because the dropindex count starts from 0 and col.idx starts from 1
      if(!isNaN(uiItem.sortable.dropindex) && colObject.idx != uiItem.sortable.dropindex+1){
        self.reorderedItems[colObject.idx] = colObject.internal_name
      }
      else if(!isNaN(uiItem.sortable.dropindex) && colObject.idx == uiItem.sortable.dropindex+1) {
        delete self.reorderedItems[colObject.idx];
      }

      Object.keys(self.reorderedItems).forEach(function(key) {
        if(self.reorderedItems[key] == self.filteredColumns[(key as any)-1].internal_name){
          delete self.reorderedItems[key];
        }
      });
      self.columnReorderCount = Object.keys(self.reorderedItems).length;
    }

    // TODO: Remove this method. Currently changes in column order is dependant on this method.

    function checkForChanges() {
      $timeout(function () {
        var hasChanges = false;
        angular.forEach(self.columns, function (col, idx) {
          var dispProps = dataview.display_properties || {};
          var hiddenColumns = dispProps.HIDDEN_COLUMNS || [];
          var was_visible = hiddenColumns.indexOf(col.internal_name) === -1;
          if (col.is_visible != was_visible || dataview.metadata[idx].display_name != col.display_name) {
            hasChanges = true;
            return false;
          }
        });
        self.hasChanges = hasChanges;
        self.draftState = hasChanges;
        self.editState = hasChanges;
      }, 100)
    }

    function resetNames() {
      $timeout(function () {
        angular.forEach(self.columns, function (col, idx) {
          col.display_name = col.old_display_name;
        });
      });
    }


    function toggleColumnVisibility(col) {
      col.is_visible = !col.is_visible;
      checkForChanges();
    }

    function highlightColInGrid(addMenuButton){
      if(!addMenuButton){
        addMenuButton = false;
      }
    }

    function selectOrUnselectColumn(col, idx) {
      if (col.is_selected){
        // TODO: WHY?
        col.is_selected = true;
        incrementColSelectCount();
        $timeout(function () {
          selectColumn(col, idx);
        }, 20);
      }
      else {
        // TODO: WHY?
        col.is_selected = false;
        decrementColSelectCount();
        $timeout(function () {
          unselectColumn(col, idx);
        }, 20);
      }
    }

    // Fix MVP-8879 Column Browser selection lagging
    // same as selectOrUnselectColumn but without timeout
    function selectOrUnselectColumnFast(col, idx, isHighlightColumnInGrid = true) {
      if (col.is_selected){
        col.is_selected = true;
        incrementColSelectCount();
        selectColumn(col, idx, isHighlightColumnInGrid);
      }
      else {
        col.is_selected = false;
        decrementColSelectCount();
        unselectColumn(col, idx, isHighlightColumnInGrid);
      }
    }
    
    function updateColSelectCount(){
      self.colSelectCount = self.filteredColumns.filter(c => c.is_selected == true).length;
    }

    function handleActionFromGrid(task_name) {
      var task_to_function_map = {
        'hide': hideOrShowSelectedCols,
        'show': hideOrShowSelectedCols,
        'showOnly': hideOrShowSelectedCols,
        'hideOnly': hideOrShowSelectedCols,
        'replace': openTaskPanel,
        'delete': openTaskPanel
      };
      task_to_function_map[task_name](task_name);
    }

    function handleColumnSelectionFromGrid(columnObj, multiselect){
      /**
       *
       * if no key is clicked at all, unselect everything but the one being clicked on, mark as primary
       * if cmd or ctrl is clicked, add to selection or remove from selection
       * if shift is clicked,
       * if selection is empty, mark as primary and add to array
       * else if col is in selection, do nothing
       * else if col is not in selection, select from primary's index until current index.
       *
       */
      if(self.columns.length === 0){
        self.columns = _.cloneDeep(dataview.metadata);
      }

      if(multiselect.shift == false && multiselect.ctrl == false){
        self.colsToHighlight = [];
        self.colsToHighlight.push(columnObj.column.internal_name);
        highlightColInGrid(false);

        $timeout(function () {
          //  get column internal name and highlight
          //  use internal name to find object in self.columns and mark as selected
          var colIdx = self.columns.findIndex(o => o.internal_name === columnObj.column.internal_name);
          var col = self.columns[colIdx];
          col.is_selected = true;
          self.colSelectCount = 1;
          self.columnSelectionState = true;
          self.sortableOptions.disabled = true;
          self.draftState = true;
          _.map(self.columns, function (col) {
            if ((col as any).internal_name != columnObj.column.internal_name) {
              // typescript type check below
              (col as any).is_selected = false;
            }
          });
          checkIfOnlyTextColsAreSelected();
        }, 10);

    }
      else if(multiselect.ctrl == true && multiselect.shift == false){
        var colIdx = self.columns.findIndex(o => o.internal_name === columnObj.column.internal_name);
        var col = self.columns[colIdx];
        var addMenuButton = false;
        if (utils.array.elementInArray(self.colsToHighlight, col.internal_name)) {
          self.colsToHighlight = utils.array.removeElement(self.colsToHighlight, columnObj.column.internal_name);
          addMenuButton = self.colsToHighlight.length > 1;
          highlightColInGrid(addMenuButton);
          $timeout(function () {
            col.is_selected = false;
            decrementColSelectCount();
            checkIfOnlyTextColsAreSelected();
          }, 10);
        }
        else {
          self.colsToHighlight.push(columnObj.column.internal_name);
          addMenuButton = self.colsToHighlight.length > 1;
          highlightColInGrid(addMenuButton);
          $timeout(function () {
            col.is_selected = true;
            incrementColSelectCount();
            self.columnSelectionState = true;
            self.draftState = true;
            checkIfOnlyTextColsAreSelected();
          }, 10);
        }
      }
      else if(multiselect.shift == true){
        // Reset the selection count from first click
        self.colSelectCount = 0;
        // TODO: Have names for selection states (discreet/continuous etc) This way previous selection state is known
        // Shift select after command select should take last selected as the primary column
        // Shift + arrow for selection
        var colIdx = self.columns.findIndex(o => o.internal_name === self.colsToHighlight[0]);
        if(colIdx == -1){
          // If user selects shift+click without selecting any column previously, the start index should be set to zero
          colIdx = 0;
        }
        var currentColIdx = self.columns.findIndex(o => o.internal_name === columnObj.column.internal_name);
        var currentCol = self.columns[currentColIdx];
        if(self.colsToHighlight.indexOf(currentCol.internal_name) === -1 ){
          self.colsToHighlight = [self.colsToHighlight[0]]; // to reset selection to primary column
          if(currentColIdx>colIdx){
            self.mainGridUnit.gridInstance.plugins.columnHighlighter.highlightColumnsByRange(colIdx, currentColIdx, dataview.display_properties.COLUMN_ORDER, true);
            _.map(self.columns, function(col) {
              // typescript type check below
              (col as any).is_selected = false;
            });
            for(let i=colIdx; i<=currentColIdx; i++){
              self.colsToHighlight.push(self.columns[i].internal_name);
              self.columns[i].is_selected = true;
              incrementColSelectCount();
              self.columnSelectionState = true;
              self.draftState = true;
            }
          }
          else{
            self.mainGridUnit.gridInstance.plugins.columnHighlighter.highlightColumnsByRange(currentColIdx, colIdx, dataview.display_properties.COLUMN_ORDER, true);
            _.map(self.columns, function(col) {
                // typescript type check below
                (col as any).is_selected = false;
            });
            for(let j=currentColIdx; j<=colIdx; j++){
              self.colsToHighlight.push(self.columns[j].internal_name);
              self.columns[j].is_selected = true;
              incrementColSelectCount();
              self.columnSelectionState = true;
              self.draftState = true;
            }
          }
          checkIfOnlyTextColsAreSelected();
        }
      }
    }

    function selectColumn(col, idx, isHighlightColumnInGrid = true){
        if(self.colsToHighlight.indexOf(col.internal_name) === -1 ){
            self.colsToHighlight.push(col.internal_name);
        }
      isHighlightColumnInGrid && highlightColInGrid(self.colsToHighlight.length > 1);
      checkIfOnlyTextColsAreSelected();
      col.is_selected = true;
      // Column selection should be a part of draft mode
      self.newColNames[idx] = col;
      // This one is for col type selection menu
      if(checkIfAllColsOfTypeIsSelected(col.type)){
        self.colTypeSelection[col.type] = true;
      }
      if(self.colSelectCount === self.filteredColumns.length){
        self.selectAllColsCheckbox = true;
      }
      self.columnSelectionState = true;
      // disable dragger when column browser is in selection state
      self.sortableOptions.disabled = true;
      self.draftState = true;
    }

    function unselectColumn(col, idx, isHighlightColumnInGrid = true){
      col.is_selected = false;
      // This one is for col type selection menu
      self.colTypeSelection[col.type] = false;
      if(self.colsToHighlight.indexOf(col.internal_name) != -1 ){
        self.colsToHighlight = utils.array.removeElement(self.colsToHighlight, col.internal_name);
      }
      isHighlightColumnInGrid && highlightColInGrid(self.colsToHighlight.length > 1);
      delete self.newColNames[idx];
      checkIfOnlyTextColsAreSelected();
      //  Check if column type is selected and toggle to false
      //  Uncheck this col type from type menu toggle
      //  Column selection should be a part of draft mode
      if(self.colSelectCount==0){
        self.columnSelectionState = false;
        self.sortableOptions.disabled = false;
        self.draftState = false;
      }

    }

    function incrementColSelectCount() {
      self.colSelectCount+=1;
    }

    function decrementColSelectCount() {
      self.colSelectCount-=1;
    }

    function selectAllColumns(){
      //  set is_selected values for all cols in filteredColumns to true
      _.map(self.filteredColumns, function(col) {
        // typescript type check below
        (col as any).is_selected = true;
      });
      self.colSelectCount = self.filteredColumns.length;
      selectAllColTypes(true);
      self.colsToHighlight = _.map(self.filteredColumns, 'internal_name');
      highlightColInGrid(self.colsToHighlight.length > 1);
      //  Column selection should be a part of draft mode
      self.columnSelectionState = true;
      self.draftState = true;
      checkIfOnlyTextColsAreSelected();
    }

    function hideOrShowSelectedCols(option){
      var deferred = $q.defer();
      var _this = this;
      var colOrder = dataview.display_properties.COLUMN_ORDER || {};
      var colNames = dataview.display_properties.COLUMN_NAMES || {};
      var hiddenColumns = dataview.display_properties.HIDDEN_COLUMNS || [];

      if(option == 'hide'){
        hiddenColumns = hiddenColumns.concat(self.colsToHighlight);
      }
      else if(option == 'show'){
        hiddenColumns = _.difference(hiddenColumns, self.colsToHighlight);
      }
      else if(option == 'showOnly'){
        if(Object.keys(self.filteredColumns).length>0){
          var colsNotInSelection = _.filter(self.filteredColumns, function (col){
            return col.is_selected==false;
          });
          hiddenColumns = colsNotInSelection.map(function (col) {
            return (col as any).internal_name;
          });
        }
        else{
          hiddenColumns = _.difference(Object.values(dataview.display_properties.COLUMN_ORDER), self.colsToHighlight);
        }
      }
      else if(option == 'hideOnly'){
          hiddenColumns = self.colsToHighlight;
      }

      var patch = {
        'COLUMN_ORDER': colOrder,
        'COLUMN_NAMES': colNames,
        'HIDDEN_COLUMNS': hiddenColumns
      };
      dataview.setDisplayProperties(patch).then(saveSuccess, saveError);
      resetGridState();
      resetColBrowserState();
      return deferred.promise;

      function saveSuccess() {
        if(_this){
          _this.isOpen = false;
        }
        self.newColNames = {};
        self.colsToHighlight = [];

        // While accessing this via grid, 'this' isn't defined. More elegant solution required.
        if(typeof _this != 'undefined'){
          deferred.resolve(angular.equals(_this._existingHiddenColumns, hiddenColumns));
        }
        else{
          deferred.resolve(angular.equals({}, hiddenColumns));
        }
      }

      function saveError(data) {
        if(_this) {
          _this.saveInProgress = false;
        }
        Notification.error(data.data.ERROR_MESSAGE);
      }
    }

    function unselectAllColumns(){
      _.map(self.filteredColumns, function(col) {
        // typescript type check below
        (col as any).is_selected = false;
      });
      self.colSelectCount = 0;
      selectAllColTypes(false);
      self.colsToHighlight = [];
      highlightColInGrid(false);
      //    Column selection should be a part of draft mode
      self.columnSelectionState = false;
      self.sortableOptions.disabled = false;
      self.draftState = false;
      self.newColNames = {};
    }

    // To enable and disable find & Replace (Only for text columns)

    function checkIfOnlyTextColsAreSelected() {
      var selected_cols = self.filteredColumns.filter((col) => { return col.is_selected == true; });
      var col_types = [];
      // TODO: rename method to enable or disable options
      // This method checks column types and visibility state and enables/disables options accordingly
      var display_states = [];
      angular.forEach(selected_cols, function(col){
        if(col.hasOwnProperty('display_name')){
          col_types.push(col['type']);
          display_states.push(col['is_visible']);
        }
      });
      var uniqueTypes = Array.from(new Set(col_types));
      if(uniqueTypes.length == 1 && uniqueTypes[0] == 'TEXT'){
        self.onlyTextColsAreSelected = true;
      }
      else {
        self.onlyTextColsAreSelected = false;
      }
      var selectedColStates = Array.from(new Set(display_states))
      if(selectedColStates.length == 1 && selectedColStates[0] == true){
        self.disableShow = true;
        self.disableHide = false;
      }
      else if (selectedColStates.length == 1 && selectedColStates[0] == false) {
        self.disableHide = true;
        self.disableShow = false;
      }
      else {
        self.disableHide = false;
        self.disableShow = false;
      }
    }

    // function to check if all columns of type in argument is selected
    function checkIfAllColsOfTypeIsSelected(col_type){
      // returns true or false after checking if all columns of this type are selected
      var cols_by_type = self.filteredColumns.filter((col) => { return col.type == col_type; });
      var cols_by_selection = cols_by_type.filter((col) => { return col.is_selected == true; });
      return cols_by_type.length == cols_by_selection.length;
    }

    function selectColsByType(col_type){
      // highlight cols in grid
      var cols_to_be_selected_by_type = self.filteredColumns.filter((col) => {
        return col.type == col_type;
      });
      // when col type is given, check overall selection status in object and then toggle either to true or false
      var setColumnSelectionTo = !self.colTypeSelection[col_type];
      self.colTypeSelection[col_type] = setColumnSelectionTo;
      var setSelectedStateForColumns = cols_to_be_selected_by_type.map(function (col) {
        col.is_selected = setColumnSelectionTo;
        if (setColumnSelectionTo == true) {
          self.colsToHighlight.push(col.internal_name);
        }
        else {
          if (self.colsToHighlight.indexOf(col.internal_name) != -1) {
            self.colsToHighlight = utils.array.removeElement(self.colsToHighlight, col.internal_name);
          }
        }
        return col;
      });
      highlightColInGrid(self.colsToHighlight.length > 1);
      updateColSelectCount();
      checkIfOnlyTextColsAreSelected();

      // Replacing columns that belong to the current selection type in filteredColumns object to reflect on the col browser
      self.filteredColumns.map(obj => setSelectedStateForColumns.find(o => o.internal_name === obj.internal_name) || obj);
      if (self.colSelectCount === self.filteredColumns.length) {
        self.selectAllColsCheckbox = true;
      }
      else if (self.colSelectCount == 0) {
        self.selectAllColsCheckbox = false;
      }
    }

    function openTaskPanel(task_name){
      self.taskPanel.open(task_name, setParamForTaskPanel(task_name));
      resetGridState();
      resetColBrowserState();
      if(self.isOpen == true){
        toggled(false, true, 'cancelButton');
      }
      unselectAllColumns();
      self.colSelectCount=0;
    }

    function populateColumns(source, new_columns ?) {
      $timeout(function () { self.populateInProgress = true; });
      var _this = this;

      if (source == 'resetCols'){
        var columns = _.cloneDeep(dataview.metadata);
        if (new_columns){
          columns = new_columns;
        }
        var dispProps = dataview.display_properties || {};
        var hiddenColumns = dispProps.HIDDEN_COLUMNS || [];
        angular.forEach(columns, function (col, idx) {
          col.idx = idx + 1;
          if (Object.keys(self.newColNames).length == 0 || !col.old_display_name) {
            col.old_display_name = col.display_name;
            col.is_visible = hiddenColumns.indexOf(col.internal_name) === -1;
            col.is_selected = false;
          }
          _this._existingHiddenColumns = hiddenColumns;
          });
      }
      else{
        var columns = self.columns;
          if(self.editState == true) {
              self.hasChanges = true;
          }
      }
        _this.columns = columns;
        $timeout(function () { self.populateInProgress = false; }, 10);
    }

    function toggled(isOpen, isInEditMode, source?) {
      if (isOpen === self.isOpen && isInEditMode === self.inEditMode) {
        isOpen = false;
        self.hasChanges=false;
      }

      if (isOpen === self.isOpen && isInEditMode !== self.inEditMode) {
        isOpen = true;
      }

      self.inEditMode = isInEditMode;

      if(isInEditMode){
        self.populateInProgress = true;
      }
      else{
        self.populateInProgress = false;
      }

      if (isInEditMode) {
        self.search = '';
      }

      // If source is cancel button, clear all changes
      if (source=='cancelButton'){
        selectAllOrNone(false);
        self.newColNames = {};
        self.nonSelectionChangesPresent = false;
        self.selectAllColsCheckbox = false;
        self.hasChanges = false;
        resetGridState();
        resetColBrowserState();
      }

      self.populateColumns();

      self.isOpen = isOpen;

      if (source === 'findColumnPanel') {
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.toggleFindColumnPanel,
        {
          eventOrigin: "dataview."+source,
          isOpen: isOpen
        });
      } else {
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.toggleModifyColumnsPanel,
        {
          eventOrigin: "dataview."+source,
          isOpen: isOpen
        });
      }

      if(!self.isOpen){
        self.inEditMode = false;
      }
    }

    function save() {
      self.hasChanges = false;
      self.nonSelectionChangesPresent = false;
      var deferred = $q.defer();
      var _this = this, newOrder = {}, newNames = {}, hiddenColumns = [];
      _this.saveInProgress = true;
      let existingNames = [];
      angular.forEach(_this.columns, function (col, i) {
        newOrder[i] = col.internal_name;
        // rename columns if there are name issues
        let rename_idx = 2;
        let new_name = col.display_name;
        if(new_name.length > 63){
          self.longCols.push(col.internal_name);
        }

        // check if the rename column name is any of the reserved column names
        let isColumnUsingReservedName = c.RESERVED_BATCH_COLUMN_NAMES.findIndex(item => col.display_name.toLowerCase() === item.toLowerCase()) != -1;

        // if renamed column name is any of the reserved column names, then add a suffix
        if(isColumnUsingReservedName){
          col.display_name = new_name + ' ' + rename_idx;
          rename_idx ++;
        }
        while(true){
          if(existingNames.indexOf(col.display_name) == -1){
            break;
          }
          col.display_name = new_name + ' ' + rename_idx;
          rename_idx++;
        }

        // if an attempt to renam a batch column is made, just dont allow that, retain the old display_name
        if(c.RESERVED_BATCH_COLUMN_NAMES.findIndex(item => col.old_display_name.toLowerCase() === item.toLowerCase()) != -1) {
          col.display_name = col.old_display_name;
        }

        if (col.old_display_name !== col.display_name) {
          newNames[col.internal_name] = utils.sanitizeName(col.display_name);
        }
        if (!col.is_visible) {
          hiddenColumns.push(col.internal_name);
        }
        existingNames.push(col.display_name);
      });
      resetGridState();
      resetColBrowserState();
        var patch = {
        'COLUMN_ORDER': newOrder,
        'COLUMN_NAMES': newNames,
        'HIDDEN_COLUMNS': hiddenColumns
      };
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.columnsModified,
        {
          eventOrigin: "dataview.modifyColumnsPanel",
          numberOfNameChnages: Object.keys(newNames).length,
          numberOfColumnsHidden: hiddenColumns.length
        });
      dataview.setDisplayProperties(patch).then(saveSuccess, saveError);
      return deferred.promise;

      function saveSuccess() {
        if(_this){
          _this.saveInProgress = false;
          _this.isOpen = false;
        }
        self.newColNames = {};
        deferred.resolve(angular.equals(_this._existingHiddenColumns, hiddenColumns));
      }

      function saveError(data) {
        _this.saveInProgress = false;
        Notification.error(data.data.ERROR_MESSAGE);
      }
    }

    // ----------Methods to handle long columns------------

    function longColsList(){
      self.columns_to_display = _.cloneDeep(dataview.metadata);
      // push all long cols to the array 'longCols' - used for some condition in UI
      self.longCols = [];
      angular.forEach(self.columns_to_display, function (col) {
        col.old_display_name = col.display_name;
        if(col.display_name.length > 63) {
          self.longCols.push(col.internal_name);
        }
      });
    }

    function filterLongCols(col){
      // returns cols which are long
      if(self.longCols.includes(col.internal_name)){
        return col;
      }
    }

    function noLongCols(){
      // return true if there no long cols otherwise false
      var flag = true;
      var metadata = dataview.metadata;
      for(let i in metadata){
        if(metadata[i].display_name.length > 63){
          flag = false;
          break;
        }
      }
      return flag;
    }

    function resetName(column) {
      // undo - resets column name to old_display_name
      $timeout(function () {
        angular.forEach(self.columns_to_display, function (col) {
          if(col.internal_name == column.internal_name) {
            col.display_name = col.old_display_name;
          }
        });
      });
    }

    function disableApply(){
      // display apply button(util long cols are renamed) while adding an action
      var service = DataviewService
      var flag = true;
      var columns = self.columns_to_display;
      var nolongColumn = true;
      for(let i in columns){
        if(columns[i].display_name.length > 63){
          nolongColumn = false;
          break;
        }
      }
      for(let i in columns){
        if((columns[i].display_name != columns[i].old_display_name) && columns[i].display_name.length && nolongColumn) {
          flag = false;
          break;
        }
      }
      return flag;
    }

    function handleColumnsRename(columns) {
      let patch = dataview.display_properties || {};
      var new_names = {};
      self.saveInProgress = true;

      angular.forEach(columns, function (col, i) {
        if (col.old_display_name !== col.display_name) {
          var columnName = utils.sanitizeName(col.display_name);
          new_names[col.internal_name] = columnName;
          if(!patch.hasOwnProperty("COLUMN_NAMES")){
            patch['COLUMN_NAMES'] = {};
          }
          patch['COLUMN_NAMES'][col.internal_name] = columnName;
        }
      });
      dataview.setDisplayProperties(patch).then(function () {
        resources.update();
        //  Do we need the dataview to refresh and display the new name while the action panel is still open?
        // If yes, uncomment below line of code
        DataviewService.fire_on_rename_col();
        //    TODO: CSS properties post save needs fixing
      });
      self.saveInProgress = false;
    }


  }
}
