// Store
import store from '@/plugins/store'

// Returns an array of strings containing the specified resource path
export function getResourcePath(resourceId, { excludeResourcePath, excludeFolders, includeProjectName } = {}) {
  const resource = store.state?.resources.resourcesMap[resourceId]
  if (!resource) return []

  let resourcePath = []

  if (!excludeResourcePath) resourcePath.push(resource.properties.name)

  //Check if dependency resource is contained in a folder
  // If dependency resource is a view, find its dataset (which contains the folder resource id)
  let folderResourceId
  if (resource.viewId) {
    const dataset = Object.values(store.state.resources.resourcesMap).find(
      (r) => r.datasetId === resource.properties.dataset.id
    )
    folderResourceId = dataset.folderResourceId
    resourcePath.push(dataset.properties.name)
  } else folderResourceId = resource.folderResourceId

  if (!excludeFolders && folderResourceId)
    // Get folders path (loop through existing folders)
    do {
      const folderResource = store.state.resources.resourcesMap[folderResourceId]
      if (folderResource) {
        resourcePath.push(folderResource.properties.name)
        folderResourceId = folderResource.folderResourceId
      } else folderResourceId = null
    } while (folderResourceId)

  if (includeProjectName) {
    const project = store.getters.getProjectById(resource.properties.projectId)
    if (project) resourcePath.push(project.name)
  }

  return resourcePath.reverse()
}

export function getDependencyProjectName(dependency) {
  const exportProjectId = dependency.direction === 'out' ? dependency.projectId : dependency.sourceProjectId
  const currWorkspace = store.getters.getCurrentWorkspace
  const project = currWorkspace.projects.find((project) => project.id == exportProjectId)
  return project?.name || 'Project(access denied)'
}
