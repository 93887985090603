import * as angular from 'angular';
import * as _ from 'lodash-es';
/**
 * @ngInject
 *
 * @returns {
    getIntegrations: getIntegrations,
    getIdentities: getIdentities,
    getAddIdentityConfig: getAddIdentityConfig,
    deleteIdentity: deleteIdentity,
    saveIdentity: saveIdentity,
    getIdentityConfig: getIdentityConfig,
    uiMap: ui_map,
    getDsConfigs: getDsConfigs,
    getAddDsConfig: getAddDsConfig,
    deleteDsConfig: deleteDsConfig,
    saveDsConfig: saveDsConfig,
    getDsConfig: getDsConfig,
    integrations: {},
    identities: {},
    ds_configs: {}
  }

 * @constructor
 */
/**
 * @param  {} integration_key - integration key to be encrypted
 * @returns base 64 encrypted string 
 */
function encodeIntegrationkey(integration_key){
  return btoa(integration_key)
}

SingularityService.$inject = ['$q', '$resource', 'config', 'CloudService', 'eventCallbackManagerFactory', '$timeout', '$rootScope',
  '$location', 'toastNotification', 'c', 'analyticsService', 'VuexStore', '$stateParams', 'FutureService'];
export function SingularityService($q, $resource, config, CloudService, eventCallbackManagerFactory, $timeout, $rootScope, $location,
                                   toastNotification, c, analyticsService, $store, $stateParams, FutureService) {
  var addDsConfigSpec = {};
  var pendingRequests = {};
  var on_augment_ds_config_spec_params = new eventCallbackManagerFactory('on_augment_ds_config_spec');
  var on_augment_ds_complete = new eventCallbackManagerFactory('on_augment_ds_complete');
  var on_validate_ds_config = new eventCallbackManagerFactory('on_validate_ds_config');
  var reInitialize_ds_config_form = new eventCallbackManagerFactory('on_ds_config_form_reintialize');
  var openDataPullDialog = new eventCallbackManagerFactory('openDataPullDialog');
  var openDataPushDialog = new eventCallbackManagerFactory('openDataPushDialog');
  var newIdentitySaved = new eventCallbackManagerFactory('newIdentitySaved');

  var service = {
    selected_integration_key: undefined,
    data: undefined,
    dsConfigs: undefined,
    preselect_integration_from_oauth2_flow: false,
    pendingRequests: pendingRequests,
    getIntegrations: getIntegrations,
    getIdentities: getIdentities,
    getAddIdentityConfig: getAddIdentityConfig,
    deleteIdentity: deleteIdentity,
    saveIdentity: saveIdentity,
    getIdentityConfig: getIdentityConfig,
    getIdentityDsConfig: getIdentityDsConfig,
    getDsConfigs: getDsConfigs,
    getAddDsConfig: getAddDsConfig,
    deleteDsConfig: deleteDsConfig,
    saveDsConfig: saveDsConfig,
    editDsConfig: editDsConfig,
    getDsConfig: getDsConfig,
    validateDsConfig: validateDsConfig,
    getDataSample: getDataSample,
    checkUrlParamsForAuthRedirect: checkUrlParamsForAuthRedirect,
    augmentDsConfigSpecParams: augmentDsConfigSpecParams,
    onAugmentDsConfigSpecParams: on_augment_ds_config_spec_params.add_callback,
    augmentDsComplete: on_augment_ds_complete.fire_event,
    onValidateDsConfig: on_validate_ds_config.add_callback,
    onReInitializeDsConfigForm: reInitialize_ds_config_form.add_callback,
    getProfiles: getProfiles,
    setProfiles: setProfiles,
    triggerDsConfigFormReInitialization: triggerDsConfigFormReInitialization,
    updateIdentity: updateIdentity,
    updateMetrics: updateMetrics,
    openDataPullDialogRequest: openDataPullDialog.fire_event,
    onDataPullDialogOpenRequest: openDataPullDialog.add_callback,
    onDataPushDialogOpenRequest: openDataPushDialog.add_callback,
    onNewIdentitySaved: newIdentitySaved.add_callback
  };


  var Integrations = $resource(config.api.integrations);
  var Identities = $resource(config.api.identities, null, {
    'update': { method: 'PUT'}
  });

  var dsConfigs = $resource(config.api.dsConfigs, null, {
    'update': { method: 'PUT'}
  });
  var selected_integration_key = "";
  service.selected_integration_key = "";
  var selected_identity_key = "";
  var selected_ds_config_key = "";
  var selected_profile: any = {};

  $rootScope.$watch(function(){ return $location.search()['datapush'] }, function(){
    openDataPushDialog.fire_event();
  });

  service.data = {
    integrations: {},
    identities:[],
    profiles: [],
    selected_integration_key:"",
    selected_identity:{},
    selected_profile:{}
  };

  return service;

  function getIntegrations() {
    var deferred = $q.defer();
    Integrations.get().$promise.then(get_integrations_success, deferred.reject);
    function get_integrations_success(data) {
      service.data.integrations = data.integrations;
      deferred.resolve(data.integrations);
    }
    return deferred.promise;
  }

  function setProfiles(profiles){
    service.data.profiles = profiles;
  }

  function getIdentities(integration_key, projectId,  isExport?) {
    selected_integration_key = integration_key;
    var deferred = $q.defer();
    Identities.get({integration_key: encodeIntegrationkey(integration_key), is_export: (!!isExport), project_id: projectId })
      .$promise.then(get_identities_success, deferred.reject);
    function get_identities_success(data) {
      service.data.identities = data.identities;
      var identitiesWithoutProfile = []
      data.identities.forEach(identity => {
        if(identity.profiles && identity.profiles.length==0 && isExport){
          identitiesWithoutProfile.push(identity['identity_config']['client_email']);
        }
        if(identity.profiles && identity.profiles.length==0){
          identitiesWithoutProfile.push(identity.name);
        }
      });
      if(identitiesWithoutProfile.length > 0) {
        toastNotification.error("No profiles could be found for these workspaces: " + identitiesWithoutProfile.join(" ,"));
      }
      deferred.resolve(data.identities);
    }

    return deferred.promise;
  }

  function getAddIdentityConfig(integration_key, export_key?) {
    selected_integration_key = integration_key;
    var deferred = $q.defer();
    // fetching identity spec
    Integrations.get({
      integration_key: encodeIntegrationkey(integration_key),
      is_export: (!!export_key)
    }).$promise.then(add_identity_success, deferred.reject);

    function add_identity_success(data) {
      deferred.resolve(data.spec);
    }
    return deferred.promise;
  }

  function saveIdentity(integration_key, wizardData, isExport?) {
    var deferred = $q.defer();
    // add project id as well

    if(integration_key=="parquet"){
      wizardData['identity_type'] = "datalake"
    }
    Identities.save({
      integration_key: encodeIntegrationkey(integration_key),
      is_export: (!!isExport)
    }, wizardData).$promise.then((data) => save_identity_success(data));

    function save_identity_success(data) {
      FutureService.track(data.future_id, function(future){
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      });
    }
    return deferred.promise;
  }

  function updateMetrics(integration_key, identity_key, profile_key,  ds_config_key, datasource_id, formData?) {
    var deferred = $q.defer();
    var patch = [];
    formData["profile"] = profile_key;
    formData["datasource_id"] = datasource_id;
    patch.push({"op": "replace", "path": "ds_config", "value": formData});
    dsConfigs.patch({integration_key: encodeIntegrationkey(integration_key), identity_key: identity_key, dsConfig_key: ds_config_key},{"patch": patch}).$promise.then(update_ds_success, deferred.reject);

    function update_ds_success(data){
      deferred.resolve(data.response);
    }
    return deferred.promise;
  }

  function updateIdentity(integration_key, identity_key, formData?) {
    var deferred = $q.defer();
    formData["identity_key"] = identity_key;
    var patch = [];
    patch.push({"op": "replace", "path": "identity", "value": formData});

    Identities.patch({integration_key: encodeIntegrationkey(integration_key), identity_key: identity_key}, {"patch": patch}).$promise.then(update_identity_success, deferred.reject);

    function update_identity_success(data) {
      FutureService.track(data.future_id, function(future){
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      });
    }
    return deferred.promise;
  }

  function deleteIdentity(integration_key, identity_key, isExport?) {
    analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.removeConnection, {eventOrigin: "thirdPartyModal", integration: integration_key})
    selected_identity_key = "";

    var deferred = $q.defer();
    Identities.delete({
      integration_key: encodeIntegrationkey(integration_key),
      identity_key: identity_key,
      is_export: (!!isExport)
    }).$promise.then(delete_identity_success, deferred.reject);
    function delete_identity_success(data) {
      deferred.resolve(data);
    }

    return deferred.promise;

  }

  function getIdentityDsConfig(integration_key, identity_key, dsConfig_key, projectId) {
    selected_identity_key = identity_key;
    var deferred = $q.defer();

    dsConfigs.get({
      integration_key: encodeIntegrationkey(integration_key),
      identity_key: identity_key,
      dsConfig_key: dsConfig_key,
      project_id: projectId
    }).$promise.then(get_identity_dsconfig_success, deferred.reject);

    function get_identity_dsconfig_success(data){
      deferred.resolve(data)
    }

    return deferred.promise;
  }

  function getIdentityConfig(integration_key, identity_key) {
    selected_identity_key = identity_key;
    var deferred = $q.defer();
    Identities.get({
      integration_key: encodeIntegrationkey(integration_key),
      identity_key: identity_key
    }).$promise.then(get_identity_config_success, deferred.reject);

    function get_identity_config_success(data) {

      var async_request_id = data.request_id;
      $timeout(_query, 1000);

      function _query() {
        var asyncResponse = new $resource(config.api.asyncRequest, {'request_id': async_request_id});
        asyncResponse.get(_successQryCb);

        function _successQryCb(data) {
          if (data.STATUS === 'PROCESSING') {
            $timeout(_query, 1000);
          }
          else {
            deferred.resolve(data.response.identity_config);
          }
        }
      }
    }

    return deferred.promise;
  }

  function getDsConfigs(integration_key, identity_key, projectId) {
    selected_identity_key = identity_key;

    var deferred = $q.defer();

    dsConfigs.get({
      integration_key: encodeIntegrationkey(integration_key),
      identity_key: identity_key,
      project_id: projectId
    })
      .$promise.then(get_dsConfigs_success, deferred.reject);
    function get_dsConfigs_success(data) {
      // service.dsConfigs = ui_map(data.DsConfigList);
      deferred.resolve(service.dsConfigs);
    }

    return deferred.promise;

  }

  function getAddDsConfig(integration_key, identity_key, profile?) {
    selected_integration_key = integration_key;
    selected_identity_key = identity_key;
    selected_profile = profile;
    var deferred = $q.defer();

    Identities.get({
      integration_key: encodeIntegrationkey(integration_key),
      identity_key: identity_key,
      profile: (profile ? profile.value : null),
      concern: 'ds_config_spec'
    }).$promise.then(add_dsConfig_success, deferred.reject);

    function add_dsConfig_success(data) {

        var async_request_id = data.request_id;
        $timeout(_query, 1000);

        function _query() {
          var asyncResponse = new $resource(config.api.asyncRequest, {'request_id': async_request_id});
          asyncResponse.get(_successQryCb);

          function _successQryCb(data) {
            if (data.STATUS === 'PROCESSING') {
              $timeout(_query, 1000);
            }
            else if (data.STATUS === 'FAILURE') {
              deferred.reject(data.response);
            }
            else {
              addDsConfigSpec = data.response.ds_config_spec;
              if (addDsConfigSpec) {
                deferred.resolve(addDsConfigSpec);
              }
              else {
                deferred.reject(data.response);
              }
            }
          }
        }

    }

    return deferred.promise;
  }

  function validateDsConfig(dsConfig, scope, callback_fn) {
    dsConfig["validate"] = true;
    if (selected_profile && selected_profile.hasOwnProperty('value')){
      dsConfig["profile"] = selected_profile.value;
    }
    var deferred = $q.defer();
    dsConfigs.save({integration_key: encodeIntegrationkey(selected_integration_key), identity_key: selected_identity_key}, dsConfig)
      .$promise.then(validate_ds_config_success, deferred.reject);

  function validate_ds_config_success(data) {
    var async_request_id = data.request_id;
    $timeout(_query, 1000);

    function _query() {
      var asyncResponse = new $resource(config.api.asyncRequest, {'request_id': async_request_id});
      asyncResponse.get(_successQryCb);

      function _successQryCb(data) {
        if (data.STATUS === 'PROCESSING') {
          $timeout(_query, 1000);
        }
        if (data.STATUS === 'SUCCESS'){
          on_validate_ds_config.fire_event(data.response);
      if (callback_fn !== undefined) {
        callback_fn();
      }
      deferred.resolve(data.response);
    }
        }
      }
    }
    return deferred.promise;
  }

  function getDataSample(integrationKey, identityKey, dsConfig) {
    delete dsConfig.validate;
    dsConfig["data_sample"] = true;
    var deferred = $q.defer();
    dsConfigs.save({
      integration_key: encodeIntegrationkey(integrationKey),
      identity_key: identityKey
    }, dsConfig)
      .$promise.then(save_ds_config_success, error_cb);


    function save_ds_config_success(data) {
      var async_request_id = data.request_id;
      $timeout(_query, 1000);

      function _query() {
        var asyncResponse = new $resource(config.api.asyncRequest, {'request_id': async_request_id});
        asyncResponse.get(_successQryCb);

        function _successQryCb(data) {
          if (data.STATUS === 'PROCESSING') {
            $timeout(_query, 1000);
          }
          else {
            if (data.response.STATUS == 'SUCCESS') {
              deferred.resolve(data.response);
            }
            else {
              deferred.reject(data.response);
            }
          }
        }
      }
    }
    function error_cb(data) {
      deferred.reject(data.response);
    }
    return deferred.promise;
  }


  function saveDsConfig(integration_key, identity_key, ds_config, schedule_related_params, recurrence_info, label_resource_id) {
    var deferred = $q.defer();
    CloudService.save_ds_config(
      integration_key, identity_key, ds_config, schedule_related_params, recurrence_info, label_resource_id).then(function (data) {
      deferred.resolve(data);
    },error_cb);
    function error_cb(data) {
      deferred.reject(data);
    }
    return deferred.promise;
  }

  function editDsConfig(integration_key, identity_key, ds_id, schedule_related_params, recurrence_info) {
    var deferred = $q.defer();
    CloudService.edit_ds_config(
      integration_key, identity_key, ds_id, schedule_related_params, recurrence_info).then(function (data) {
      deferred.resolve(data);
    },error_cb);
    function error_cb(data) {
      deferred.reject(data);
    }
    return deferred.promise;
  }

  function deleteDsConfig(integration_key, identity_key, dsConfig_key) {

    var deferred = $q.defer();
    dsConfigs.delete({
      integration_key: integration_key,
      identity_key: identity_key,
      dsConfig_key: dsConfig_key
    }).$promise.then(delete_dsConfig_success, deferred.reject);

    function delete_dsConfig_success(data) {
      deferred.resolve(data);
    }

    return deferred.promise;

  }

  function getDsConfig(integration_key, identity_key, dsConfig_key, projectId) {

    var deferred = $q.defer();
    dsConfigs.query({
      integration_key: encodeIntegrationkey(integration_key),
      identity_key: identity_key,
      dsConfig_key: dsConfig_key,
      project_id: projectId
    }).$promise.then(get_ds_config_success, deferred.reject);
    function get_ds_config_success(data) {
      deferred.resolve(data);
    }

    return deferred.promise;
  }

  function augmentDsConfigSpecParams(params, scope, callback_fn?) {
    //fire event from here
    if (selected_profile) {
      //params['profile'] = selected_profile.value;
    }
    var deferred = $q.defer();
    Identities.save({
      integration_key: encodeIntegrationkey(selected_integration_key),
      identity_key: selected_identity_key
    }, params)
      .$promise.then(augment_param_success, deferred.reject);

    function augment_param_success(data) {

      var async_request_id = data.request_id;
      $timeout(_query, 1000);

      function _query() {
        var asyncResponse = new $resource(config.api.asyncRequest, {'request_id': async_request_id});
        asyncResponse.get(_successQryCb);

        function _successQryCb(data) {
          if (data.STATUS === 'PROCESSING') {
            $timeout(_query, 1000);
          }
          else {
            on_augment_ds_complete.add_callback('augment_success_cb', function () {
              if (callback_fn !== undefined) {
                callback_fn();
              }
              deferred.resolve(data.response);
            });
            on_augment_ds_config_spec_params.fire_event(data.response.augmented_params);
          }
        }
      }
    }

    return deferred.promise;

  }


  function checkUrlParamsForAuthRedirect(query_params) {
    var deferred = $q.defer();
    var integration_key = query_params['integration_key'];
    var isExport = !!query_params['is_export'];
    let params: any;
    if (!isExport){
      // After keycloak code parameter from third party will return codes via redirect.mammoth
      var code = query_params['codes'];
      params = {'code': code};
    }

    const integration = {'key': integration_key, 'isExport': isExport};
    if (query_params['editId']){
      integration['editId'] = query_params['editId'];
    }
    if(query_params.hasOwnProperty('shop')){
      params = {'code': code, 'shop': query_params['shop']}
    }
    if (query_params.hasOwnProperty('identity_key') && query_params.identity_key){
      params.identity_key = query_params.identity_key;
    }
    if(query_params.hasOwnProperty('server')){
      params = {'code': code, 'server': query_params['server']}
    }

    params['project_id'] = parseInt(query_params["project_id"])
    if (integration_key && isExport) {
      if (query_params['connection_data']){
        params = {};
        params['connection_data'] = query_params['connection_data'];
      }
      saveIdentity(integration_key, params, true).then(function () {
        newIdentitySaved.fire_event();
        deferred.resolve(integration);
      }, function () {
        deferred.reject(integration);
      });
    }
    else if (integration_key && code) {
      saveIdentity(integration_key, params).then(function () {
        newIdentitySaved.fire_event();
        deferred.resolve(integration);
      }, function (response) {
        if (response?.message) toastNotification.error(response.message);
        deferred.reject(integration);
      });
      // deferred.resolve(integration_key);
    } else {
      deferred.reject(integration);
    }
    return deferred.promise;
  }

  function getProfiles(integration_key, identity_key, isExport?) {
    selected_identity_key = identity_key;
    var deferred = $q.defer();

    Identities.get({
      integration_key: encodeIntegrationkey(integration_key),
      identity_key: identity_key,
      concern: 'profiles',
      is_export: (!!isExport)
    })
      .$promise.then(get_profiles_success, get_profiles_error);

    function get_profiles_success(data) {

        var async_request_id = data.request_id;
        $timeout(_query, 1000);

        function _query() {
          var asyncResponse = new $resource(config.api.asyncRequest, {'request_id': async_request_id});
          asyncResponse.get(_successQryCb);

          function _successQryCb(data) {
            if (data.STATUS === 'PROCESSING') {
              $timeout(_query, 1000);
            }
            else if(data.STATUS === 'FAILURE'){
              deferred.reject(data.response);
            }
            else {
              service.data.profiles = data.response.profiles;
              deferred.resolve(data.response);
            }
          }
        }
    }

    function get_profiles_error(data) {
      service.data.profiles = [];
      deferred.reject(data);
    }
    return deferred.promise;
  }

  function getProfiles2() {
    return service.data.profiles;
  }

  function triggerDsConfigFormReInitialization() {
    reInitialize_ds_config_form.fire_event();
  }

}

