import * as angular from 'angular';

import {DatasourceFactory} from './Datasource.factory';
import {FileObjectFactory} from './FileObject.factory';
import { DataviewFactory, SimpleDataviewFactory } from './Dataview.factory';
import { NotificationsFactory } from './notifications.factory';
import { TaskFactory } from './Task.factory';
import { LabelFactory } from './Label.factory';
import { AlertFactory } from "./Alerts.factory";

export let modelsModule = angular.module('app.models', []);

modelsModule.factory('Dataview', DataviewFactory);
modelsModule.factory('SimpleDataviewFactory', SimpleDataviewFactory);
modelsModule.factory('Datasource', DatasourceFactory);
modelsModule.factory('FileObject', FileObjectFactory);
modelsModule.factory('NotificationsFactory', NotificationsFactory);
modelsModule.factory('Task', TaskFactory);
modelsModule.factory('Label', LabelFactory);
modelsModule.service('AlertFactory', AlertFactory);
