'use strict';

export function deploymentConsts() {

  let MAMMOTH_OUTBOUND_IP = "52.87.76.204";
  let RECAPTCHA_KEY = "6Ldr570ZAAAAAF9d3aoLsMV0J8LDWorsicgFXhZu";
  let RECAPTCHA_ENABLED = false;
  let CANNY_APP_ID = "5eac24134ee08e29ed70df25";
  let SPLIT_API_KEY = "8s5mbru9mvet0stcu32v58hltam5qsc5c26c";
  let API_TOKEN_ENC_KEY = "gSn355GhjveEizBt";
  let KEYCLOAK_HOST = "https://auth.mammoth.io";
  let KEYCLOAK_REALM = "qa";
  let KEYCLOAK_CLIENT = "qa";


  Object.defineProperty(this, "MAMMOTH_OUTBOUND_IP", { // public readonly getter
    get: function () {
      return MAMMOTH_OUTBOUND_IP;
    },
    set: function (){}
  });
  Object.defineProperty(this, "RECAPTCHA_KEY", { // public readonly getter
    get: function () {
      return RECAPTCHA_KEY;
    },
    set: function (){}
  });
  Object.defineProperty(this, "RECAPTCHA_ENABLED", { // public readonly getter
    get: function () {
      return RECAPTCHA_ENABLED;
    },
    set: function (){}
  });
  Object.defineProperty(this, "CANNY_APP_ID", { // public readonly getter
    get: function () {
      return CANNY_APP_ID;
    },
    set: function (){}
  });
  Object.defineProperty(this, "SPLIT_API_KEY", { // public readonly getter
    get: function () {
      return SPLIT_API_KEY;
    },
    set: function (){}
  });
  Object.defineProperty(this, "API_TOKEN_ENC_KEY", { // public readonly getter
    get: function () {
      return API_TOKEN_ENC_KEY;
    },
    set: function (){}
  });
  Object.defineProperty(this, "KEYCLOAK_HOST", { // public readonly getter
    get: function () {
      return KEYCLOAK_HOST;
    },
    set: function (){}
  });
  Object.defineProperty(this, "KEYCLOAK_REALM", { // public readonly getter
    get: function () {
      return KEYCLOAK_REALM;
    },
    set: function (){}
  });
  Object.defineProperty(this, "KEYCLOAK_CLIENT", { // public readonly getter
    get: function () {
      return KEYCLOAK_CLIENT;
    },
    set: function (){}
  });
  return this
};