/*jslint node: true */
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * @ngInject
 */
renderTableElement.$inject = ['$timeout', 'gridFactory', 'utils', 'config'];
export function renderTableElement($timeout, gridFactory, utils, config){
  function sum(){
    var s = 0;
    angular.forEach(arguments, function(v){
      s += v;
    });
    return s
  }

  function avg(){
    return sum.apply(null, arguments)/arguments.length;
  }


  function AggregationFramework(){
    this.execute = function(data, aggregations, format_info){
      var values = {}, results = {};
      data.forEach(function(r){
        Object.keys(aggregations).forEach(function(internal_name){
          if(r[internal_name]){
            var value = parseFloat(r[internal_name].replace(/[^0-9-.]/g, ''));
            if(isNaN(value)){
              value = 0;
            }
            if(values.hasOwnProperty(internal_name)){
              values[internal_name].push(value);
            }
            else{
              values[internal_name] = [value];
            }
          }
        })
      });

      var mapped = {
        SUM: sum,
        AVG: avg,
        MIN: Math.min,
        MAX: Math.max
      };

      Object.keys(values).forEach(function(name){
        var format = format_info[name] || {};
        var functionName = aggregations[name];
        var valueSet = values[name];
        var result = mapped[functionName].apply(null, valueSet);
        results[name] = utils.string.capitalize(functionName, true) + ' : ' + utils.number.format(result, format) ;
      });

      return results
    }
  }


  return {
    controller: 'tableRenderController',
    bindToController: true,
    controllerAs:'tevm',
    scope: {
      element: "=",
      condition: "=",
      big:"=",
      drilldownManager: "=",
      datastore: "="
    },
    templateUrl: config.templates.tableElementContentTemplate,
    link: function(scope, element, attributes){
      $(element).css('height', 'inherit');
      scope.tevm.processing = true;
      var cache: any = {}, unsubSortListener, queryDispProps = {"SORT": []};
      $timeout(function(){
        scope.tevm.query(queryDispProps);
        var firstTimeWatchCollection = true, firstTimeBigVarWatch = true;
        scope.$watchCollection('tevm.condition', function(){
          if(firstTimeWatchCollection){
            firstTimeWatchCollection = false;
          }
          else{
            scope.tevm.query(queryDispProps);
          }
        });

        scope.$watch('tevm.big', function () {
          if(firstTimeBigVarWatch){
            firstTimeBigVarWatch = false;
          }
          else{
            rerender();
          }
        });

      }, 200);

      scope.$on("$destroy", function () {
        scope.tevm.destroy && scope.tevm.destroy();
      });

      var options = {
        showColumnTypeIcons: true,
        rowHeight: 35,
        defaultColumnWidth: 100,
        enableViewerSort: true
      };

      var colOptions = {
        resizable: true
      };

      var gridInstance;
      scope.tevm.registerRenderer(render);

      var aggFramework = new AggregationFramework();

      function render(metadata, data){
        cache.data = _.cloneDeep(data);
        cache.metadata = _.cloneDeep(metadata);
             // TODO: SENTRYERROR:FRONTEND-E4:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/436213346
        rerender();
      }

      function rerender(){
        var data = _.cloneDeep(cache.data);
        var metadata = cache.metadata;
        var displayProperties = _.cloneDeep(scope.tevm.element.display_properties);
        if (scope.tevm.datastore) {
          scope.tevm.datastore.metadata = metadata;
          scope.tevm.datastore.data = data;
          scope.tevm.datastore.display_properties = displayProperties;
          if (metadata && metadata.length && displayProperties.drilldown && displayProperties.drilldown.enabled && displayProperties.drilldown.default.column) {
            var dCol = utils.metadata.get_column_by_internal_name(metadata, displayProperties.drilldown.default.column);
            var dColValues = [];
            angular.forEach(data, function (dataItem) {
              dColValues.push(dataItem[displayProperties.drilldown.default.column]);
            });
            if (dCol && dColValues) {
              scope.tevm.datastore.valueSets = [{column: dCol, values: dColValues}];
            }
          }
        }
        if(displayProperties.aggregations && Object.keys(displayProperties.aggregations).length){
          var result = aggFramework.execute(data, displayProperties.aggregations || {},
            displayProperties.FORMAT_INFO);
          if(Object.keys(result).length){
            data.push(result);
          }
        }

        var columns = [];

             // TODO: SENTRYERROR:FRONTEND-E4:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/436213346
        utils.metadata.applyDisplayChanges(metadata, displayProperties, columns, colOptions);

        if (gridInstance) {
          gridInstance.destroy();
          var childDiv = $(element).find('.table')[0];
          $(childDiv).empty();
        }

        if (scope.tevm.big) {
          var styleString = 'style="margin-left:auto; margin-right:auto;"';
        }
        else {
          var styleString = 'style=""';
        }

        scope.styleString = styleString;

        var childDiv = $(element).find('.table')[0];
        var drillColumn, drillColumnIdx;
        if (displayProperties.drilldown && displayProperties.drilldown.enabled && displayProperties.drilldown.default.column) {
          drillColumn = $.grep(columns, function (col) {
            return col.id == displayProperties.drilldown.default.column;
          });
          if (drillColumn.length) { drillColumn = drillColumn[0]; }
          if (drillColumn) {
            drillColumn.cssClass = "drillcolumn";
          }
        }

        gridInstance = new gridFactory(childDiv, undefined, columns, options, data);
        gridInstance.init();
        if (unsubSortListener) {
          unsubSortListener();
        }
        unsubSortListener = gridInstance.onSort("sortListener", function (sortSpec) {
          queryDispProps['SORT'] = sortSpec;
          scope.tevm.query(queryDispProps);
        });

        if (displayProperties.SORT && !queryDispProps.SORT.length) {
          queryDispProps['SORT'] = _.cloneDeep(displayProperties.SORT);
        }
        if (queryDispProps.SORT.length) {
          gridInstance.setSort(queryDispProps.SORT);
        }

        gridInstance.grid.onColumnsResized.subscribe(_gridResizeCb);
        if (drillColumn) {
          drillColumnIdx = gridInstance.grid.getColumnIndex(drillColumn.id);
          gridInstance.grid.onClick.unsubscribe(_gridClickHandler);
          gridInstance.grid.onClick.subscribe(_gridClickHandler);
        }

        function _gridClickHandler(e, clickData) {
          if (clickData.cell == drillColumnIdx) {
            var cell = gridInstance.grid.getCellNode(clickData.row, clickData.cell);
            var drilledVal = $(cell).text();
            scope.tevm.drilldownManager.openDrilldown(displayProperties.drilldown.default.elementId, [{
              value: drilledVal, column: utils.metadata.get_column_by_internal_name(metadata, drillColumn.id)
            }]);
            $timeout(function () {});
          }
        }

        function _gridResizeCb(){
          var cols = gridInstance.grid.getColumns();
          var newWidths = displayProperties.COLUMN_WIDTHS || {};
          angular.forEach(cols, function (col, i) {
            if (col.width != col.previousWidth) {
              newWidths[col.id] = col.width;
            }
          });
          displayProperties.COLUMN_WIDTHS = newWidths;
          scope.tevm.handleDisplayUpdated(displayProperties);
        }

        gridInstance.grid.onColumnsReordered.subscribe(_gridReorderCb);
        scope.tevm.gridInstance = gridInstance;

        function _gridReorderCb(){
          var cols = gridInstance.grid.getColumns();
          var newOrder = {};
          cols = $.grep(cols, function (col: any) {
            return col.id !== "index";
          });
          angular.forEach(cols, function (col, i) {
            newOrder[i] = col.id;
          });
          displayProperties.COLUMN_ORDER = newOrder;
          scope.tevm.handleDisplayUpdated(displayProperties);
        }
      }

    }
  }
}
