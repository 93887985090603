<template>
  <div class="action-bar d-flex justify-space-between">
    <section class="actions-bar-container d-inline-flex py-2 px-3">
      <!-- EXPLORE COLUMNS -->
      <div v-if="showExploreColumns" class="action-option-column">
        <div class="button-group">
          <!-- this button is a div because in this version we are not using our DSM buttons,
        but rather a copy of the existing button in the dataview. so we use div to style it like that button -->
          <div class="explore-columns-button" @click="$emit('explore-columns-clicked')">
            <mm-icon name="explore" color="n600"></mm-icon>
            <span v-if="hasExploreCards" class="green-dot" />
          </div>
        </div>
        <span class="option-name">Explore Columns</span>
      </div>
    </section>
    <section class="actions-bar-container d-inline-flex py-2 px-3">
      <action-bar-button
        class="action-bar-button--export"
        icon="actionbar_share"
        :label="$t('action_bar.export.button_label')"
        @click="isExportListModalOpen = !isExportListModalOpen"
      />
      <action-bar-export-list-modal
        v-model="isExportListModalOpen"
        :title="$t('action_bar.export.modal_title')"
        :items="computedExportAndShareItems"
        @itemClick="onListModalItemClick"
      />
    </section>
  </div>
</template>

<script>
import ActionBarButton from '@vue/mm-frontend/src/modules/data-editor/action-bar/components/action-bar-button/action-bar-button.vue'
import ActionBarExportListModal from '@vue/mm-frontend/src/modules/data-editor/action-bar/components/action-bar-export/list-modal/action-bar-export-list-modal.vue'
import { USER_EVENTS, SPLITIO_FEATURE_FLAGS, XLSX_DOWNLOAD_MAX_ROW_LIMIT } from '@/constants'
import { featuresVisibility } from '@/plugins/splitio'
import { mapState } from 'vuex'
import { PublishApi_downloadGridData } from '@vueSrc/modules/publish/viewer/api/publish.api.js'

export default {
  components: {
    ActionBarButton,
    ActionBarExportListModal,
  },
  data() {
    return {
      showExploreColumns: true,
      isExportListModalOpen: false,
      featuresVisibility,
    }
  },
  computed: {
    ...mapState({
      computedPublishExploreConfig: (state) => state.publish.exploreConfig,
      computedPublishReport: (state) => state.publish.reportMetadata,
      computedPublishDisplayProps: (state) => state.publish.reportDisplayProps,
      computedPublishFormatInfo: (state) => state.publish.reportFormatInfo,
      computedReportId: (state) => state.publish.reportId,
      computedReportRowCount: (state) => state.publish.rowCount,
    }),
    hasExploreCards() {
      return this.$store.state.publish.exploreConfig?.length > 0
    },
    computedExportAndShareItems() {
      return [
        {
          id: 'download-csv',
          title: this.$t(`action_bar.export.download_data.title`, { fileType: 'CSV' }),
          description: this.$t(`action_bar.export.download_data.csv_sub_title`),
          userEventKey: 'DOWNLOAD_CSV_CLICK',
          render: () => this.featuresVisibility[SPLITIO_FEATURE_FLAGS.EXPORT.DOWNLOAD_CSV],
        },
        {
          id: 'download-xlsx',
          title: this.$t(`action_bar.export.download_data.title`, { fileType: 'XLSX' }),
          description: this.$t(`action_bar.export.download_data.xlsx_sub_title`),
          userEventKey: 'DOWNLOAD_XLSX_CLICK',
          disabled: this.computedReportRowCount > XLSX_DOWNLOAD_MAX_ROW_LIMIT,
          tooltip:
            this.computedReportRowCount > XLSX_DOWNLOAD_MAX_ROW_LIMIT
              ? this.$t(`action_bar.export.download_data.download_xlsx_tooltip`, {
                  maxLimitRowCount: XLSX_DOWNLOAD_MAX_ROW_LIMIT.toLocaleString('en-US'),
                })
              : '',
          render: () => this.featuresVisibility[SPLITIO_FEATURE_FLAGS.EXPORT.DOWNLOAD_XLSX],
        },
      ].filter((option) => (option.render ? option.render() : true))
    },
  },
  methods: {
    onListModalItemClick({ id, userEventKey }) {
      switch (id) {
        case 'download-csv':
          this.downloadData('csv')
          this.isExportListModalOpen = false
          break

        case 'download-xlsx':
          this.downloadData('xlsx')
          this.isExportListModalOpen = false
          break
      }
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT[userEventKey], {
        reportId: this.computedReportId,
      })
    },
    async downloadData(fileType) {
      this.isExportListModalOpen = false
      try {
        const response = await PublishApi_downloadGridData(
          this.computedReportId,
          this.$store.state.publish.dataset.condition,
          this.reportDisplayProps,
          fileType
        )
        window.open(response.url, '_self')
        this.$userEvents.save(USER_EVENTS.REPORTS.ACTION_BAR.DOWNLOAD_DATA, {
          reportId: this.computedReportId,
          fileType,
        })
      } catch (error) {
        this.$toast.show({ content: error.message, status: 'error' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$background-color: #e3e3e3;
$text-color: #4c5055;

.actions-bar-container {
  .action-option-column {
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
      margin-right: 5em;
    }

    .button-group {
      display: inherit;

      & button:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .explore-columns-button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 23px;
        width: 45px;
        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(143, 145, 149);
        background-color: white;
        cursor: pointer;

        &:hover {
          background-color: rgb(235, 235, 235);
        }

        .green-dot {
          display: block;
          position: absolute;
          top: 3px;
          right: 6px;
          width: 5px;
          height: 5px;
          background-color: #06a66b;
          border-radius: 50%;
        }
      }

      .option-name {
        margin-top: 4px;
        font-size: 0.85em;
        color: $text-color;
      }
    }
  }
}
</style>
