import * as angular from 'angular';
import 'angular-formly';
import 'angular-formly-templates-bootstrap-edgemetric';
import { thirdPartyAPIController } from './api.controller';
import { SingularityService } from './singularity.service';
import { CloudService } from './cloud.service';
import { apiUtils } from './api.utils';
import { apiHelpers } from './api.helper';
import { editDataPullConfigController } from './editDatapullConfig.controller';
import { editMetricsController } from "./editMetrics.controller";
import { MappedFieldFactory } from './MappedField.factory';
import { MappedFormFactory } from './MappedForm.factory';
import { MappedFormHelperService } from './MappedFormHelper.service';
import { schedulerController, dateHandler } from './scheduler.controller';
import { uibDatepickerWrapper, includeScheduler, uibDatepickerPopupWrapper } from './scheduler.directive';
import { datapullByUrlController } from './url.controller';
import { inputDiscoveryUtil } from './userInputDiscovery.service';
import { webhookConfigScreenController, copyWebhookUrl } from './webhook.controller';
import { ftpHelpers } from './helpers/ftp.helper';
import { mixpanelHelpers } from './helpers/mixpanel.helper';
import { commonModule } from "../../common/index";
import { elementCommonModule } from "../../element/common/index";
import { config } from "../../common/app.config";
import { googleAnalyticsHelpers } from './helpers/googleAnalytics.helpers';
import { salesforceHelpers } from './helpers/salesforce.helpers';
import { googleDriveHelpers } from './helpers/googleDrive.helpers';
import { dropboxHelpers } from './helpers/dropbox.helpers';
import { googleAdsHelpers } from './helpers/googleAds.helper';

export let datapullModule = angular.module('app.datapull', [
  'ui.bootstrap', 'angularFileUpload', 'formly', 'angularMoment', 'formlyBootstrap', 'ngResource', 'ui.router',
  elementCommonModule.name, commonModule.name
]);

datapullModule.service('Singularity', SingularityService);
datapullModule.service('CloudService', CloudService);
datapullModule.controller('thirdPartyAPIController', thirdPartyAPIController);
datapullModule.service('apiUtils', apiUtils);
datapullModule.service('apiHelpers', apiHelpers);
datapullModule.controller(config.controllers.editDatapullConfig, editDataPullConfigController);
datapullModule.controller(config.controllers.editMetrics, editMetricsController);
datapullModule.factory('MappedField', MappedFieldFactory);
datapullModule.factory('MappedForm', MappedFormFactory);
datapullModule.service('MappedFormHelper', MappedFormHelperService);
datapullModule.controller('schedulerController', schedulerController);
datapullModule.directive('includeScheduler', includeScheduler);
datapullModule.directive('uibDatepickerWrapper', uibDatepickerWrapper);
datapullModule.directive('uibDatepickerPopupWrapper', uibDatepickerPopupWrapper);
datapullModule.controller('datapullByUrlController', datapullByUrlController);
datapullModule.service('inputDiscoveryUtil', inputDiscoveryUtil);
datapullModule.controller('webhookConfigScreenController', webhookConfigScreenController);
datapullModule.service('ftpHelpers', ftpHelpers);
datapullModule.service('mixpanelHelpers', mixpanelHelpers);
datapullModule.service('googleDriveHelpers', googleDriveHelpers);
datapullModule.service('dropboxHelpers', dropboxHelpers);
datapullModule.service('googleAnalyticsHelpers', googleAnalyticsHelpers);
datapullModule.service('salesforceHelpers', salesforceHelpers);
datapullModule.service('dateHandler', dateHandler);
datapullModule.directive("copyWebhookUrl", copyWebhookUrl);
datapullModule.service('googleAdsHelpers', googleAdsHelpers);
