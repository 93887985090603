/**
 * Created by mammoth2 on 04/09/18.
 */

export let max_sample_run_count = 10;
export let ExploreConsts = {
  renderTypes: {
    list: 'list',
    chart: 'chart'
  },
  dataAggregationType: {
    range: 'range',
    discreet: 'discreet'
  },
  aggregationOptions: [
    'COUNT', 'SUM', 'AVG', 'MIN', 'MAX', 'STDDEV'
  ],
  dateGranularityOrder: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'],
  dateGranularityOptions: [
    {
      'internal': 'DAY',
      'display': 'Day',
      'type': 'TRUNCATE',
      "format": '%d-%B-%Y',
      "listFormat": 'dd-mmm-yyyy',
      "graphFormat": 'd'
    },
    {
      'internal': 'MONTH',
      'display': 'Month',
      'type': 'TRUNCATE',
      "format": '%B, %Y',
      "listFormat": 'mmm \'yy',
      "graphFormat": 'mmm-yyyy'
    },
    {
      'internal': 'YEAR',
      'display': 'Year',
      'type': 'TRUNCATE',
      "format": '%Y',
      "listFormat": 'yyyy',
      "graphFormat": 'yyyy'
    },
    {
      'internal': 'HOUR',
      'display': 'Hour',
      'type': 'TRUNCATE',
      "format": '%d-%B-%Y %H:%M',
      "listFormat": 'dd-mmm-yyyy hh:00',
      "graphFormat": 'h tt'
    },
    {
      'internal': 'MINUTE',
      'display': 'Minute',
      'type': 'TRUNCATE',
      "format": '%d-%B-%Y %H:%M',
      "listFormat": 'dd-mmm-yyyy hh:MM',
      "graphFormat": 'h:MM tt'
    },
    {
      'internal': 'SECOND',
      'display': 'Second',
      'type': 'TRUNCATE',
      "format": '%d-%B-%Y %H:%M:%S',
      "listFormat": 'dd-mmm-yyyy hh:MM:ss',
      "graphFormat": 'h:MM:ss tt'
    }
  ],
  numGranularityOptions: getGranularityOptions(),
  textCardDefaultWidth: 300,
  plotThreshold: 60,
  listThreshold: 10000
};



function getGranularityOptions(maxLevel=10, minLevel=-3){
  let options = [];
  for(let level = maxLevel; level >= minLevel; level--){
    options.push({
        internal: level,
        display: Math.pow(10, level).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        type: undefined,
        format: undefined,
        listFormat: undefined,
        graphFormat: undefined
    });
  }
  return options;
}
