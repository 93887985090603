/**
 * @ngInject
 */
import * as _ from "lodash-es";
import * as RRule from "rrule-alt";
import {
  monthDays,
  minutes,
  minutely,
  hours,
  days,
  generateFrequencyOption,
  isInstance,
  momentFormat,
  exampleFormat
} from "./scheduler.utils";

editDataPullConfigController.$inject = [
  "$rootScope",
  "moment",
  "config",
  "$uibModalInstance",
  "c",
  "DatasourceService",
  "Singularity",
  'NotificationService',
  'analyticsService'
];
export function editDataPullConfigController(
  $rootScope,
  moment,
  config,
  $uibModalInstance,
  c,
  DatasourceService,
  Singularity,
  NotificationService,
  analyticsService
) {
  var vm = this;
  this.$onInit = init


  function init() {
    analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.editScheduleConfig,
      {
        eventOrigin: "editThirdPartyScheduleModal"
      });
    // switch to enable or disable editing schedule
    vm.datasource_info = _.cloneDeep(vm.datasource);
    vm.enabled = _.cloneDeep(vm.isScheduleRunning);
    vm.showLoader = false;
    vm.modal_instance = $uibModalInstance;
    vm.atTime = "00:00"; // for daily, weekly, monthly
    vm.atMinute = 0; // for hourly
    vm.examples = [];
    vm.exampleEnd = null;
    vm.moment_type = moment().constructor.name;
    vm.startDate = getStartDate().format(momentFormat);
    vm.defaultDate = getStartDate().format(momentFormat);
    vm.periodDataStartDate = getStartDate().subtract(15, "days").format(momentFormat);
    vm.periodDataStartDateMax = getStartDate().subtract(15, "minutes").format(momentFormat);
    vm.datePickerFormat = "Y-m-d h:i K";
    vm.byweekday = null;
    vm.bymonthday = null;
    vm.startDateDirty = false;
    vm.requireEndDate = "now";
    vm.startDateMin = getStartDate().format(momentFormat);

    // functions
    vm.normalizeTime = normalizeTime;
    vm.setFrequency = setFrequency;
    vm.recomputeRecurRule = recomputeRecurRule;
    vm.getStartDate = getStartDate;
    vm.cancelButtonHandler = cancelButtonHandler;
    vm.submitScheduleConfig = submitScheduleConfig;
    vm.deferredRecomputeRecurRule = function() {
      recomputeRecurRule();
    };

    vm.schedule = _.cloneDeep(vm.datasource_info.display_info.rrule);

    // `just once` dataset which is scheduled to pull data later will have frequency to `YEARLY`
    if (!vm.schedule.FREQUENCY || vm.schedule['FREQUENCY'] === "YEARLY") {
      vm.schedule["FREQUENCY"] = "Just once";
    }

    var refresh_type_upper = vm.datasource_info.display_info.refresh_type.value.toUpperCase();
    if (refresh_type_upper == "COMBINE"){
      refresh_type_upper = "APPEND"
    }
    vm.scheduleRelatedParams = {
      schedule_type: "MOMENT",
      on_refresh_action: refresh_type_upper
    };

    vm.originalScheduleOptions = [];
    vm.originalScheduleOptions.push(
      generateFrequencyOption("Just once", "Just once")
    );

    if ($rootScope.isFeatureEnabled($rootScope.appFeatures.minutelyDataPull)) {
      vm.originalScheduleOptions.push(
        generateFrequencyOption("Every few minutes", "MINUTELY")
      );
    }
    if ($rootScope.isFeatureEnabled($rootScope.appFeatures.hourlyDataPull)) {
      vm.originalScheduleOptions.push(
        generateFrequencyOption("Hourly", "HOURLY")
      );
    }

    vm.originalScheduleOptions.push(
      generateFrequencyOption("Daily", "DAILY"),
      generateFrequencyOption("Weekly", "WEEKLY"),
      generateFrequencyOption("Monthly", "MONTHLY")
    )

    vm.scheduleOptions = _.cloneDeep(vm.originalScheduleOptions);
    if (!vm.schedule) {
      vm.schedule = {
        FREQUENCY: vm.scheduleOptions[0].internal,
        INTERVAL: 1,
        BYWEEKDAY: null,
        BYMONTHDAY: null
      };
    }

    vm.frequencyIntervals = {
      WEEKLY: c.weekdays,
      MONTHLY: monthDays,
      MINUTES: minutes,
      MINUTELY: minutely,
      HOURLY: hours,
      DAILY: days
    };

    vm.scheduleRelatedParams.firstPullAt = "NOW";
    vm.scheduleRelatedParams.isValid = true;
    vm.setFrequency();
    updateValues();
  }

  function updateValues() {
    if (
      vm.datasource_info.display_info.rrule.FREQUENCY === "WEEKLY" &&
      vm.datasource_info.display_info.rrule.BYWEEKDAY[0]
    ) {
      vm.byweekday = vm.datasource_info.display_info.rrule.BYWEEKDAY[0];
    } else if (
      vm.datasource_info.display_info.rrule.FREQUENCY === "MONTHLY" &&
      vm.datasource_info.display_info.rrule.BYMONTHDAY[0]
    ) {
      vm.bymonthday = vm.datasource_info.display_info.rrule.BYMONTHDAY[0];
    } else if (
      vm.datasource_info.display_info.rrule.FREQUENCY === "MINUTELY" ||
      vm.datasource_info.display_info.rrule.FREQUENCY === "HOURLY" ||
      vm.datasource_info.display_info.rrule.FREQUENCY === "DAILY"
    ) {
      vm.schedule.INTERVAL = vm.datasource_info.display_info.rrule.INTERVAL;
    }
    vm.recomputeRecurRule();
  }

  function getNotificationId(ds_id, matchingCriteria: any) {
    var list = NotificationService.list;
    var matchingNoification: any = _.filter(list, function(o) { return o.details.type == matchingCriteria.type && o.details.op == matchingCriteria.operation && o.details.data.ds_id == ds_id;});
    if (matchingNoification.length > 0) {
      return matchingNoification[0]['id']
    }
  }

  function submitScheduleConfig(scheduleJob: string) {
    // when there is a change in schedule running state
    if(vm.enabled !== vm.isScheduleRunning){
      vm.showLoader = true;
      var id = getNotificationId(vm.datasource_info.id, {type: 'singularity', operation: 'pull_data_as_csv'})
      if(vm.enabled==false) {
          DatasourceService.updateSchedule(vm.datasource_info.id, 'pause', scheduleJob, id).then(final_cb, error_cb)
      } else {
          DatasourceService.updateSchedule(vm.datasource_info.id, 'resume', scheduleJob, id).then(final_cb, error_cb)
      }
    }
    if(vm.enabled) {
      if (vm.requireEndDate === "now") {
        // When current time is given as start time for now config datasets, The dataset pull will be failed because of time lag. We need to add some delay to the start time.
        delete vm.schedule.UNTIL;
      } else {
        vm.scheduleRelatedParams.firstPullAt = "LATER";
      }

      if (vm.schedule.FREQUENCY === "Just once") {
        vm.schedule.FREQUENCY = null;
        vm.schedule.BYMONTHDAY = null;
        vm.schedule.BYWEEKDAY = null;
        vm.scheduleRelatedParams.firstPullAt = "NOW";
        vm.schedule.START = moment().utc().format(momentFormat);
        delete vm.schedule.UNTIL;
      }

      Singularity.editDsConfig(
        vm.datasource_info.config_keys.integration_key,
        vm.datasource_info.config_keys.identity_key,
        vm.datasource_info.id,
        vm.scheduleRelatedParams,
        vm.schedule
      ).then(
        vm.close ||
          function() {
            vm.submitInProcess = false;
          },
        error_cb,
        final_cb
      );

    }

    function error_cb(data) {
      vm.showLoader = false;
    }

    function final_cb() {
      vm.showLoader = false;
    }
    vm.updateScheduleState(vm.enabled);
    vm.cancelButtonHandler();
  }

  function getStartDate(current_date = moment().seconds(0)) {
    if (!isInstance(current_date, [vm.moment_type, "Date"])) {
      console.error("Expected Moment or Date object");
      return;
    }
    return normalizeTime(current_date);
  }

  function normalizeTime(date_obj) {
    if (isInstance(date_obj, "Date")) {
      date_obj = moment(date_obj);
    }
    if (!isInstance(date_obj, ["Date", vm.moment_type])) {
      console.error("Expected Date or Moment object");
      return;
    }
    date_obj.add(config.MIN_TIME_FROM_NOW_OCCURRENCE + config.SCHEDULER_INTERVAL,"minutes");
    let minutes_part = date_obj.minutes();
    return date_obj.minutes(minutes_part - (minutes_part % 5) + 5).seconds(0);
  }

  function resetStartDateMinMax(minFromNow: any) {
    var diff = Math.abs((minFromNow.valueOf() - vm.startDateMin.valueOf()) / 1000);
    if (diff >= 30) {
      vm.startDateMin = minFromNow;
    }
  }

  function setScheduleStart(internalStart: any) {
    vm.schedule.START = moment(internalStart, momentFormat).utc().format(momentFormat);
  }

  function prepareRuleConfig(internalStart: any) {
    let ruleConfig: any = {
      freq: c.frequencyRRuleMap[vm.schedule.FREQUENCY],
      interval: vm.schedule.INTERVAL,
      dtstart: moment(internalStart, momentFormat).toDate()
    };
    if (vm.schedule.FREQUENCY === "WEEKLY") {
      ruleConfig.byweekday = c.weekdayRRuleMap[vm.byweekday];
    } else if (vm.schedule.FREQUENCY === "MONTHLY") {
      ruleConfig.bymonthday = vm.bymonthday;
    } else {
      ruleConfig.byweekday = [];
      ruleConfig.bymonthday = [];
    }
    return ruleConfig;
  }


  function setupWeekDayMonthDayInSchedule(rule: any, ruleConfig: any) {
    var firstOccurrence = rule.after(ruleConfig.dtstart, true);
    if (vm.schedule.FREQUENCY === "WEEKLY") {
      var weekdayno = moment(firstOccurrence).utc().day();
      vm.schedule.BYWEEKDAY =
        weekdayno !== weekdayno ? [] : [c.weekdays[c.PY_WEEKDAYS[weekdayno]].key];
      vm.schedule.BYMONTHDAY = [];
    } else if (vm.schedule.FREQUENCY === "MONTHLY") {
      var monthdayno = moment(firstOccurrence).utc().date();
      vm.schedule.BYMONTHDAY = monthdayno !== monthdayno ? [] : [monthdayno];
      vm.schedule.BYWEEKDAY = []
    } else {
      vm.schedule.BYWEEKDAY = [];
      vm.schedule.BYMONTHDAY = [];
    }
  }

  function getNextOccurrences(rule, after, count) {
    var occ = [];
    var latest = after;
    for (var i = 0; i < count; i++) {
      if (latest === null) {
        break;
      }
      latest = rule.after(latest);
      if (latest) {
        occ.push(moment(latest).format(exampleFormat));
      }
    }
    return occ;
  }

  function computeExamples(rule: any, minFromNow: any) {
    vm.examples = getNextOccurrences(rule, minFromNow, 4);
    if (vm.scheduleRelatedParams.firstPullAt === "NOW") {
      vm.examples.unshift();
    }
  }

  function setFrequency() {
    vm.bymonthday = null;
    vm.byweekday = null;
    vm.schedule.INTERVAL = 1;
    switch (vm.schedule.FREQUENCY) {
      case "Just once": {
        vm.requireEndDate = null;
        break;
      }

      // for minutely, hourly and daily we use intervals
      // for weekly and monthly frequency is used
      case "MINUTELY": {
        if (vm.requireEndDate === null) {
          vm.requireEndDate = "now";
        }
        vm.schedule.INTERVAL = vm.frequencyIntervals[vm.schedule.FREQUENCY][0].key;
        recomputeRecurRule();
        break;
      }
      case "HOURLY": {
        if (vm.requireEndDate === null) {
          vm.requireEndDate = "now";
        }
        vm.schedule.INTERVAL = vm.frequencyIntervals[vm.schedule.FREQUENCY][0].key;
        recomputeRecurRule();
        break;
      }
      case "DAILY": {
        if (vm.requireEndDate === null) {
          vm.requireEndDate = "now";
        }
        vm.schedule.INTERVAL = vm.frequencyIntervals[vm.schedule.FREQUENCY][0].key;
        recomputeRecurRule();
        break;
      }
      case "WEEKLY": {
        vm.byweekday = vm.frequencyIntervals[vm.schedule.FREQUENCY][0].key;
        if (vm.requireEndDate === null) {
          vm.requireEndDate = "now";
        }
        recomputeRecurRule();
        break;
      }
      case "MONTHLY": {
        vm.bymonthday = vm.frequencyIntervals[vm.schedule.FREQUENCY][0].key;
        if (vm.requireEndDate === null) {
          vm.requireEndDate = "now";
        }
        recomputeRecurRule();
        break;
      }
    }

    if (!vm.startDateDirty) {
      if (!vm.schedule.FREQUENCY) {
        vm.startDate = getStartDate()
          .subtract(1, "month")
          .format(momentFormat);
      } else {
        vm.defaultDate = getStartDate().format(momentFormat);
        vm.startDate = vm.defaultDate;
      }
    }
  }

  function recomputeRecurRule() {
    vm.startDateMax = null;
    var _minFromNow = getStartDate();
    var minFromNow = _minFromNow.toDate();

    if (vm.requireEndDate === "now") {
      vm.startDate = moment().format(momentFormat);
    } else if (moment(vm.startDate, momentFormat).diff(_minFromNow) < 0) {
      vm.startDate = _minFromNow.format(momentFormat);
    }
    var internalStart = vm.startDate;
    if (internalStart == undefined){
      return
    }
    vm.defaultDate = _minFromNow.format(momentFormat);
    resetStartDateMinMax(minFromNow);
    var ruleConfig = prepareRuleConfig(internalStart);

    setScheduleStart(internalStart);

    if (!vm.schedule || vm.schedule.FREQUENCY === "Just once") {
      vm.scheduleRelatedParams.is_valid = true;
      return;
    }
    var rule = new RRule(ruleConfig);
    setupWeekDayMonthDayInSchedule(rule, ruleConfig);
    var now = moment();
    computeExamples(rule, now.toDate());

    vm.scheduleRelatedParams.is_valid = !(
      !vm.examples.length && vm.schedule.FREQUENCY !== "Just once"
    );
    if (vm.scheduleRelatedParams.is_valid) {
      let latestStart = rule.after(now.toDate());
      setScheduleStart(moment(latestStart).format(momentFormat));
    }
  }

  function cancelButtonHandler() {
    vm.close();
  }
}
