import * as angular from 'angular';
import { eventCallbackManagerFactory } from './events';
import { utils } from './utils';
import { dependencyChecker } from './dependencyChecker';
import {collections} from './collections';

/**
 * Module containing math editor
 */

export let utilsModule = angular.module('app.common.utils', ['angularMoment']);
utilsModule.factory('eventCallbackManagerFactory', eventCallbackManagerFactory);
utilsModule.service('utils', utils);
utilsModule.service('dependencyChecker', dependencyChecker);
utilsModule.service('collections', collections);
