export class addDataController {
  public modal_instance;
  public ds = undefined;
  public appending = false;
  public uploader;
  public closeOnAdd;
  public activeOption;
  public fileUploadDestinationResource;
  static $inject = ['$uibModalInstance', '$timeout', 'DatasourceService', 'analyticsService', 'c', 'landingList'];
  public $onInit: () => void;

  public constructor(protected $uibModalInstance, protected $timeout, protected DatasourceService,
                     protected analyticsService, protected c, public landingList) {
    this.$onInit = function () {
      this.modal_instance = $uibModalInstance;
      this.uploader.runtimeOptions.fileUploadDestinationResource = this.landingList.currentLabelView.label;
      if (this.uploader.formData && this.uploader.formData[0].append_to_ds_id) {
        this.appending = true;
        this.ds = DatasourceService.list[this.uploader.formData[0].append_to_ds_id];
      }
      let self = this;
      this.uploader.onAfterAddingAll = function () {
        if (self.closeOnAdd) {
          $timeout(self.modal_instance.close);
        }
      }
    }
  }

  public onClose(source?) {
    this.analyticsService.userEventTrack(
      this.c.userEvents.landingPage.addNewDS.closeModal,
      {
        eventOrigin: source==='cancelButton'?"addNewDsModal.fileUpload.cancelButton":"addNewDsModal.crossButton"
      }
    );
    this.modal_instance.close()
  }

  public setActiveOption(activeOption) {
    this.analyticsService.userEventTrack(
      this.c.userEvents.landingPage.addNewDS.addnewDSActiveOption,
      {
        ActiveOption: activeOption,
        eventOrigin: "landingPage"
      }
    );
    //Active option in the 'Add New' dialog
    this.activeOption = activeOption;
  }
}
