import * as _ from 'lodash-es';
import { IAlertServiceFactory, IAlertService, IAlert, IAlertFactory } from '../common/types/core';

export class AlertService implements IAlertService {
  public alertsAPI: ng.resource.IResourceClass<ng.resource.IResource<any>>;
  public alertsRegister: any = {};

  constructor(private dataviewId: string | number,
              private $resource: ng.resource.IResourceService,
              private config: any,
              private alertFactory: IAlertFactory,
              private $q: any) {
    this.alertsAPI = $resource(config.api.alert);
  }

  public fetchUpdates() {
    var deferred = this.$q.defer();
    this.alertsAPI.get({
      dataviewId: this.dataviewId
    }).$promise.then((data) => {
      this.setUpdates(data.alerts);
      deferred.resolve();
    }, deferred.reject);
    return deferred.promise;
  }

  private setUpdates(alertsFromBackend: any) {
    this.alertsRegister = {}
    if (alertsFromBackend.length > 0) {
      alertsFromBackend.forEach((alertData) => {
        let alert = _.get(this.alertsRegister, alertData.id);
        if (!alert) {
          alert = this.alertFactory.createAlert(this.dataviewId);
        }
        alert.setUpdates(alertData);
        this.alertsRegister[alert.id] = alert;
      });
    } else {
      this.alertsRegister = {};
    }
  }

  public getAlertById(alertId: string | number): IAlert {
    return _.get(this.alertsRegister, alertId);
  }

  public createAlert(): IAlert {
    return this.alertFactory.createAlert(this.dataviewId);
  }
}

export class AlertServiceFactory implements IAlertServiceFactory {
  private registry: any = {};
  public static $inject: string[] = ['$resource', 'config', 'AlertFactory', '$q'];

  constructor(private $resource: ng.resource.IResourceService,
              private config: any,
              private AlertFactory: IAlertFactory,
              private $q: any) {

  }

  public getByDataviewId(dataviewId: number) {

    let alertService = _.get(this.registry, dataviewId);
    if (!alertService) {
      alertService = new AlertService(dataviewId, this.$resource, this.config, this.AlertFactory, this.$q);
      this.registry[dataviewId] = alertService;
    }
    return alertService;
  }

}
