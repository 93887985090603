<template>
  <div v-show="!computedIsEmpty" class="explore-section-toggle" :class="computedClasses">
    <mm-button
      :icon="computedIsOpen ? 'chevron_up' : 'chevron_down'"
      objective="tertiary"
      size="small"
      noFocusOnClick
      @click="onToggleClick"
    />
  </div>
</template>

<script>
export default {
  name: 'explore-section-toggle',
  computed: {
    computedIsOpen() {
      return this.$store.state.exploreSection.isOpen
    },
    computedIsEmpty() {
      return this.$store.getters['exploreSection/isEmpty']
    },
    computedClasses() {
      const filterCondition =
        this.$store.state.dataEditor.viewsMap[this.$store.state.dataEditor.viewId]?.filterCondition || {}

      return {
        'explore-section-toggle-open': this.computedIsOpen,
        'explore-section-toggle-with-filter': !!Object.keys(filterCondition).length
      }
    }
  },
  methods: {
    onToggleClick() {
      this.$emit('toggleExplore')
    }
  }
}
</script>

<style lang="scss">
.explore-section-toggle {
  width: 18px;
  height: 14px;
  z-index: 2;
  position: absolute;
  top: -1px;
  left: 50%;
  overflow: hidden;

  .mm-button {
    position: absolute;
    top: -4px;
    border: 1px solid var(--mm-color--n70);
    padding: 0px !important;
    width: 18px !important;
    height: 18px !important;
    min-height: 18px !important;
    background: white;

    .mm-icon {
      margin-top: 1px !important;
      --mm-icon--color: var(--mm-color--n300) !important;
      --mm-icon--color-hover: var(--mm-color--n300) !important;
    }

    &:hover {
      background: var(--mm-color--n70) !important;
    }
  }

  &.explore-section-toggle-open {
    top: 184px;

    .mm-button {
      top: 0px;

      .mm-icon {
        margin-top: -2px !important;
      }
    }
  }

  &.explore-section-toggle-with-filter {
    top: 44px;

    &.explore-section-toggle-open {
      top: 216px;
    }
  }
}
</style>
