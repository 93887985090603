@@ -1,51 +0,0 @@
<template>
  <div class="action-bar d-flex justify-space-between p-x-6 p-y-3">
    <section>
      <action-bar-button
        class="action-bar-button--pipeline"
        icon="pipeline"
        :label="$t('action_bar.pipeline_tasks.label')"
        :disabled="$store.getters['pipeline/isDraft']"
        :tooltipLabel="computedPipelineTooltipLabel"
        position="right"
        :activated="$store.state.pipeline.isOpen"
        :enabled="!$store.state.pipeline.isOpen && !$store.getters['pipeline/isEmpty']"
        @click="onTogglePipelineClick"
      />
    </section>

    <section>
      <action-bar-transform />
      <action-bar-combine />
      <action-bar-export ref="export" />
      <mm-divider class="m-y-auto" style="height: 24px" vertical />
      <action-bar-column-browser />
    </section>

    <section>
      <restrictions-wrapper feature-to-validate="areAlertsEnabled">
        <action-bar-button
          icon="alerts"
          :label="$t('global.dictionary.alerts')"
          :tooltipLabel="computedAlertTooltipLabel"
          :disabled="$store.getters['pipeline/isDraft'] || $store.getters['pipeline/isError']"
          tooltipArrowMargin="78%"
          @click="$root.legacyHandlers.openAlertsDialog"
        />
      </restrictions-wrapper>
    </section>
  </div>
</template>

<script>
// Components
import ActionBarButton from './components/action-bar-button/action-bar-button.vue'
import ActionBarTransform from './components/action-bar-transform/action-bar-transform.vue'
import ActionBarCombine from './components/action-bar-combine/action-bar-combine.vue'
import ActionBarExport from './components/action-bar-export/action-bar-export.vue'
import ActionBarColumnBrowser from './components/action-bar-column-browser/action-bar-column-browser.vue'
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

// Constants
import { USER_EVENTS } from '@/constants'

export default {
  name: 'action-bar',
  components: {
    ActionBarButton,
    ActionBarTransform,
    ActionBarCombine,
    ActionBarExport,
    ActionBarColumnBrowser,
    RestrictionsWrapper
  },
  computed: {
    computedPipelineTooltipLabel() {
      if (this.$store.getters['pipeline/isError']) return this.$t(`action_bar.pipeline_error_tooltip`)
      if (this.$store.getters['pipeline/isDraft']) return this.$t(`action_bar.pipeline_draft_tooltip`)
      return this.$t(`action_bar.pipeline_tasks.label`)
    },
    computedAlertTooltipLabel() {
      if (this.$store.getters['pipeline/isError']) return this.$t(`action_bar.pipeline_error_tooltip`)
      if (this.$store.getters['pipeline/isDraft']) return this.$t(`action_bar.pipeline_draft_tooltip`)
      return this.$t(`action_bar.alerts.label`)
    }
  },
  methods: {
    onTogglePipelineClick() {
      this.$root.legacyHandlers.togglePipeline()
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.TOGGLE_PIPELINE, { viewId: this.$store.state.dataEditor.viewId })
    },
    onExplorePanelClick() {
      this.$root.legacyHandlers.toggleExplore()
    }
  }
}
</script>

<style lang="scss">
.action-bar {
  background: var(--mm-color--n10);
  position: relative;
  z-index: 2; // bring action-bar on top of explore-toggle
  border-bottom: 2px solid #e5e6e5; // TODO: change color from legecy code.

  section {
    display: flex;
    gap: 24px;
  }
}
</style>
