/**
 * @ngInject
 */
validationService.$inject = ['validationConstants'];
export function validationService(validationConstants) {
  var service = {
    getErrorMessage: getErrorMessage
  };
  return service;

  function getErrorMessage(validatorKey) {
    return validationConstants.messages[validatorKey];
  }
}
