import * as angular from 'angular';
import _ = require('lodash');


modalService.$inject = ['$uibModal', 'DatasourceService', 'FileService', 'DataviewService', '$timeout',
  'dataviewSafeDeleteResetService', 'oldElementServiceFactory', 'config',
  '$q', '$rootScope', 'derivatives', 'DrilldownManagerFactory',  'analyticsService', 'c',
  'FutureService', 'resources', 'utils', 'labelService', 'toastNotification','$resource', 'VuexStore', 'Notification', 'modificationRequestTracker'] as ReadonlyArray<string>;
export function modalService($uibModal, DatasourceService, FileService, DataviewService, $timeout,
                             dataviewSafeDeleteResetService, oldElementServiceFactory,
                             config, $q, $rootScope, derivatives, DrilldownManagerFactory, analyticsService, c,
                             FutureService, resources, utils, labelService, toastNotification, $resource, $store, Notification, modificationRequestTracker) {
  return {
    // delete modals
    deleteDs: deleteDs,
    deleteWs: deleteWs,
    deleteFile: deleteFile,
    multiDelete: multiDelete,
    deleteTask: deleteTask,
    suspendTask: suspendTask,
    deleteDerivative: deleteDerivative,
    deleteDrilldown: deleteDrilldown,
    deleteIdentity: deleteIdentity,
    deleteOrSuspendAction: deleteOrSuspendAction,
    deleteLabel: deleteLabel,
    deleteAlert:deleteAlert,

    // rename
    renameDs: renameDs,
    cloneDs: cloneDs,
    renameWs: renameWs,
    duplicateWs: duplicateWs,
    renameLabel: renameLabel,
    combineDS: combineDS,

    openRenameTaskModal: openRenameTaskModal,
    openEditTaskNoteModal: openEditTaskNoteModal,
    saveTemplateConfig: saveTemplateConfig,
    templateConfirmationDialog: templateConfirmationDialog,
    //  other
    refreshCloudData: refreshCloudData,
    resetWs: resetWs,
    manageUsers: manageUsers,
    // Publish
    showCredential: showCredential,
    openRegeneratePasswordModal: openRegeneratePasswordModal,
    //exportCSV
    exportDialog: exportDialog,
    //alertsDialog
    alertsDialog: alertsDialog,
    // labels
    createLabel: createLabel,
    transferOwnerShip: transferOwnerShip,
    //Pipeline
    applyPipelineChanges: applyPipelineChanges,
    discardPipelineChanges: discardPipelineChanges,
    viewPendingUpdates: viewPendingUpdates,
    // app creation modals
    createApiToken: createApiToken,
    editApiToken: editApiToken,
    deleteApiToken: deleteApiToken,
    // data flow modal
    fetchPendingChanges: fetchPendingChanges,
    applyPendingChanges: applyPendingChanges,
    showNonUniqueValues: showNonUniqueValues
  };
  function showNonUniqueValues(task_info){
    var data = task_info.display_info
    let rightView = DataviewService.get_by_id(task_info.params.JOIN.DATAVIEW_ID);
    var foreignView = 'foreign view'
    if (rightView) {
      foreignView = rightView.datasource.name + ' > ' + rightView.name

    }
    var rightViewlocation = "/#/dataviews/"+ rightView.id
    var  nonUniqueValueModal = $uibModal.open({
      templateUrl: config.templates.joinNonUniqueValues,
      windowClass: 'modal-size-large pipeline-confirmation',
      controller: function () {
        var mvm = this;
        mvm.c = c;
        mvm.rightViewName = foreignView;
        mvm.rightViewURL = rightViewlocation;
        mvm.data_of_right_keys = data?.analysis?.data_of_right_keys
        mvm.data_of_left_keys = data?.analysis?.data_of_left_keys
        mvm.columns_used = data?.analysis?.columns_used
        mvm.foreign_columns_used = data?.analysis?.foreign_columns_used
        mvm.left_summary_table_columns = data?.analysis?.left_summary_table_columns
        mvm.right_summary_table_columns = data?.analysis?.right_summary_table_columns
        mvm.right_physical_column_name_to_internal_name = data?.analysis?.key_columns_physical_to_internal_name?.right_key_column
        mvm.left_physical_column_name_to_internal_name = data?.analysis?.key_columns_physical_to_internal_name?.left_key_column
        mvm.left_key = ''
        
        for(let i=0; i < mvm.left_summary_table_columns.length - 1; i++ ){
          // Physical columns are a core backend concept and should not be send to or used in the frontend. In duckdb,
          // summary_table_columns contain the internal names (and not the physical names) so there is no need for the
          // physical column name to internal name mapping. For postgres, backend still sends the physical column namws
          // so we try to fetch it from the summary_table_columns first, if it is not present then we use the
          // physical_column_name_to_internal_name mapping to keep to backward compatable. Should be removed once the
          // backend is completely migrated to duckdb.
          let display_name =
            mvm.columns_used?.[mvm.left_summary_table_columns?.[i]]?.['display_name'] ??
            mvm.columns_used?.[mvm.left_physical_column_name_to_internal_name?.[mvm.left_summary_table_columns?.[i]]]?.['display_name'];
          if (i< mvm.left_summary_table_columns.length - 2){
            mvm.left_key =  mvm.left_key + display_name + ' , '
          }else{
            mvm.left_key =  mvm.left_key + display_name
          }
        }
        mvm.ldata = []
        
        
        for(let p=0; p < mvm.data_of_left_keys.length; p++){
          let l_replaced = mvm.data_of_left_keys[p].map(function(item) {
            return item === null ? '(blank)' : item;
          });
          let l_value = l_replaced.slice(0,-1).join(', ')
          if (!l_value){
            l_value = '(blank)'
          }
          mvm.ldata.push({'value': l_value, 'count': mvm.data_of_left_keys[p][mvm.data_of_left_keys[p].length -1]})

        }
        mvm.right_key = ''
        for(let j=0; j < mvm.right_summary_table_columns.length - 1; j++ ){
          let display_name =
            mvm.foreign_columns_used?.[mvm.right_summary_table_columns?.[j]]?.['display_name'] ??
            mvm.foreign_columns_used?.[mvm.right_physical_column_name_to_internal_name?.[mvm.right_summary_table_columns?.[j]]]?.['display_name'];
          if (j< mvm.right_summary_table_columns.length - 2){
            mvm.right_key =  mvm.right_key + display_name + ' , '
          }else{
            mvm.right_key =  mvm.right_key + display_name
          }
        }
        mvm.rdata = []
        
        for(let q=0; q < mvm.data_of_right_keys.length; q++){
          let r_replaced = mvm.data_of_right_keys[q].map(function(item) {
            return item === null ? '(blank)' : item;
          });
          let r_value = r_replaced.slice(0,-1).join(', ')
          if (!r_value){
            r_value = '(blank)'
          }
          let count =  mvm.data_of_right_keys[q][mvm.data_of_right_keys[q].length -1]
          if (count && count > 1){
            mvm.rdata.push({'value': r_value, 'count': count})
          }
        }        
        
      },

      controllerAs: 'mvm'
      })
      return nonUniqueValueModal.result;


  }
  function _openSetupMeetingModal() {
    var quoteMeetingAlert = $uibModal.open({
      templateUrl: config.templates.requestQuoteMeeting,
      windowClass: 'modal-size-medium',
      controller: function () {
        var mvm = this;
      },
      controllerAs: 'mvm'
    });
    return quoteMeetingAlert.result;
  }


  function deleteDs(ds, bypass_confirmation) {
    if (bypass_confirmation === true || bypass_confirmation === "true") {
      var deleteSuccessPromise = $q.defer();
      confirmationAccepted();
      $timeout(function () {
        deleteSuccessPromise.resolve();
      }, 200);
      return deleteSuccessPromise.promise;
    }
    var deleteAlertPromise = _openDeleteModal(ds);
    deleteAlertPromise.then(confirmationAccepted);
    return deleteAlertPromise;

    function confirmationAccepted() {
      DatasourceService.delete(ds).then(delete_success);

      function delete_success() {
        analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.deleteDS, {eventOrigin: "landingPage.previewPanel"});
        toastNotification.success('One item queued for deletion');
      }
    }
  }


  function deleteWs(ws, eventOrigin) {
    if (eventOrigin == undefined) {
      eventOrigin = "landingPage";
    }
    var deleteAlertPromise = _openDeleteModal(ws);
    deleteAlertPromise.then(confirmationAccepted);
    return deleteAlertPromise;

    function confirmationAccepted() {
      dataviewSafeDeleteResetService.delete(ws.id).then(delete_success);

      function delete_success() {
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.viewOptions.deleteView, {eventOrigin: eventOrigin});
        $timeout(function () {
          toastNotification.success(ws.name + ' deleted');
        }, 500);
      }
    }
  }

  function applyPendingChanges(dataview_list){
    var pendingChangesResource = $resource(config.api.pendingChanges);
    pendingChangesResource.save({'dataview_ids': dataview_list}).$promise.then(successCallback, errorCallback);

    function successCallback(data){
      FutureService.track(data.future_id, future_tracker)
    }

    function errorCallback(data) {
      console.log("Request to /pending-changes failed with data", data)
    }

    function future_tracker(future) {
      if (future.status == "processing") {
        console.log("Processing for future id ", future.id)
        return;
      }
      if (future.status == "success") {
        console.log("Success for future id ", future.id)
        console.log(future)
        
      }
      else if (future.status == "error") {
        console.log("Error for future id ", future.id)
      }
      
    }
    
  }
  function fetchPendingChanges(){
    var pendingChangesResource = $resource(config.api.pendingChanges);
    pendingChangesResource.get({ project_id: $store.state.projectId }).$promise.then(successCallback, errorCallback);

    function successCallback(data){
      FutureService.track(data.future_id, future_tracker)
    }

    function errorCallback(data) {
      console.log("Request to /pending-changes failed with data", data)
    }

    function future_tracker(future) {
      if (future.status == "processing") {
        console.log("Processing for future id ", future.id)
        return;
      }
      if (future.status == "success") {
        console.log("Success for future id ", future.id)
        console.log(future)
        
      }
      else if (future.status == "error") {
        console.log("Error for future id ", future.id)
      }
      
    }
    
  }

  function deleteFile(file, bypass_confirmation) {
    if (bypass_confirmation === true || bypass_confirmation === "true") {
      confirmationAccepted();
      return;
    }
    var deleteAlertPromise = _openDeleteModal(file);
    deleteAlertPromise.then(confirmationAccepted);
    return deleteAlertPromise;

    function confirmationAccepted() {
      FileService.delete(file).then(delete_success);

      function delete_success() {
        $timeout(function () {
          toastNotification.success('Deleted file <strong>' + file.name + '</strong>');
        }, 500);
      }
    }
  }

  function deleteTask(task) {
    var item = _.cloneDeep(task);
    item.type = "task";
    var deleteAlertPromise = _openDeleteModal(item);
    var deleteSuccessPromise = $q.defer();
    deleteAlertPromise.then(confirmationAccepted);
    return deleteSuccessPromise.promise;

    function confirmationAccepted() {
      deleteSuccessPromise.resolve();
    }
  }

  function applyPipelineChanges(dataview, stepsList){
    var pipelineModificationsAlert = openPipelineChangeConfirmation(stepsList, 'apply');
    var pipelineModificationSuccessPromise = $q.defer();
    pipelineModificationsAlert.then(confirmationAccepted, callbackForCancel);
    return pipelineModificationSuccessPromise.promise;

    function callbackForCancel(){
      pipelineModificationSuccessPromise.reject();
    }

    function confirmationAccepted(data) {
      dataview.pipeline_status = 'submitted'
      var draft_mode_res = $resource(config.api.draftMode, {ws_id: '@ws_id'});
      draft_mode_res.save({ws_id: dataview.id},{'draft_operation': 'submit'}).$promise.then(pipelineModificationSuccessCallback, pipelineModificationErrorCallback);

      function pipelineModificationSuccessCallback() {
        pipelineModificationSuccessPromise.resolve();
      }

      function pipelineModificationErrorCallback(data) {
        dataview.pipeline_status = 'ready'
        let error_message = "Failed";
        if(data.data && data.data.ERROR_MESSAGE) {
          error_message += ": " + data.data.ERROR_MESSAGE;
        }
        toastNotification.error(error_message);

        pipelineModificationSuccessPromise.reject();
      }
    }
  }

  function discardPipelineChanges(dataview, stepsList){
    var pipelineModificationsAlert = openPipelineChangeConfirmation(stepsList, 'discard');
    var pipelineModificationSuccessPromise = $q.defer();
    pipelineModificationsAlert.then(confirmationAccepted, callbackForCancel);
    return pipelineModificationSuccessPromise.promise;

    function callbackForCancel(){
      pipelineModificationSuccessPromise.reject();
    }

    function confirmationAccepted() {
      dataview.pipeline_status = 'submitted'
      var draft_mode_res = $resource(config.api.draftMode, {ws_id: '@ws_id'});
       draft_mode_res.save({ws_id: dataview.id},{'draft_operation': 'discard'}).$promise.then(pipelineModificationSuccessCallback);

      function pipelineModificationSuccessCallback() {
        pipelineModificationSuccessPromise.resolve();
      }
    }
  }

  function openPedningUpdateConfirmation(dataview){
      var pipelineUpdatesAlert = $uibModal.open({
      templateUrl: config.templates.pendingUpdatesConfirmation,
      windowClass: 'modal-size-large pipeline-confirmation',
      controller: function () {
        var mvm = this;
        mvm.c = c;
        dataview.inbound_updates_contexts.forEach(function (item, index) {
          item['message'] = c.updateTypeToMessage[item?.context]
          if (item?.context == 'dependency_update'){
              let foreign_res = resources.resourcesMap[item?.info?.changed_resource_id];
                 item['message']  += ' '+foreign_res?.datasource?.name + ' > ' + foreign_res?.name
              }
          else{
              item['message']  += ' '+dataview.datasource?.name
          }
        });
        mvm.inbound_updates_contexts = dataview.inbound_updates_contexts;
      },

      controllerAs: 'mvm'
      })
      return pipelineUpdatesAlert.result;
  }

  function viewPendingUpdates(dataview){
    var pipelineUpdatesAlert = openPedningUpdateConfirmation(dataview);
    var pipelineModificationSuccessPromise = $q.defer();
    pipelineUpdatesAlert.then(confirmationAccepted, callbackForCancel);
    return pipelineModificationSuccessPromise.promise;    
    function callbackForCancel(){
      pipelineModificationSuccessPromise.reject();
    }

    function confirmationAccepted() {
      pipelineModificationSuccessPromise.resolve();
    }
  }
  function openPipelineChangeConfirmation(stepsList, context){

    var pipelineModificationsAlert = $uibModal.open({
      templateUrl: config.templates.pipelineConfirmation,
      windowClass: 'modal-size-medium pipeline-confirmation',
      controller: function () {
        var mvm = this;
        mvm.c = c;
        mvm.context = context;
        mvm.deleted = 0        ,
        mvm.edited = 0
        mvm.suspended = 0
        mvm.reordered = 0
        mvm.added = 0
        mvm.stepsList = stepsList;
        _.forEach(stepsList, function(step){
          var status = ''
          if (step.reordered){
            mvm.reordered += 1
          }
          status = step.status
          if (status == c.stepStatus.deleted){
            mvm.deleted += 1
          }else if (status == c.stepStatus.edited){
            mvm.edited += 1
          }
          else if (status == c.stepStatus.suspending){
            mvm.suspended += 1
          }
          else if (status == c.stepStatus.added){
            mvm.added += 1
          }

        })
        mvm.stepStatusMap = {
          'deleted': 'Deleted',
          'edited' : 'Edited',
          'added' : 'Added',
          'suspending' : 'Suspended'
        }
        mvm.actionHandlerToTitleMap = {
          'mysql': 'MySQL',
          'postgres': 'PostgreSQL',
          'bigquery': 'Google BigQuery',
          'mssql': 'SQL Server',
          'elasticsearch': 'Elasticsearch',
          'redshift': 'AWS Redshift',
          's3': 'CSV Live Link',
          'publishdb': 'Publish as a Database',
          'sftp':  'SFTP'
        }
      },
      controllerAs: 'mvm'
    });
    return pipelineModificationsAlert.result;
  }

  function suspendTask(task) {
    var item = _.cloneDeep(task);
    item.type = "task";
    item.displayOverride = {
      header: 'Suspend Confirmation',
      effect: 'suspended',
      buttonText: 'Suspend',
      changeContent: true
    }
    var deleteAlertPromise = _openDeleteModal(item);
    var deleteSuccessPromise = $q.defer();
    deleteAlertPromise.then(confirmationAccepted);
    return deleteSuccessPromise.promise;

    function confirmationAccepted() {
      deleteSuccessPromise.resolve();
    }
  }

  function deleteLabel(label) {
    var item = _.cloneDeep(label);
    var deleteAlertPromise = _openDeleteModal(item);
    var deleteSuccessPromise = $q.defer();
    deleteAlertPromise.then(confirmationAccepted);
    return deleteSuccessPromise.promise;

    function confirmationAccepted() {
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.deleteFolder, { eventOrigin: "landingPage" });
      labelService.deleteLabel(label, true).then(function (data) {
        future_request_tracker(data.future_id)
      }, deleteSuccessPromise.reject);


      function future_request_tracker(future_id) {
        FutureService.track(future_id, data_tracker);

        function data_tracker(future) {
          let response = future.response;
          if (future.status == "processing") {
            return;
          }
          if (future.status == "success") {
            toastNotification.success('Deleted folder <em>' + label.name + '</em>');
            deleteSuccessPromise.resolve();
          } else {
            let scp = $rootScope.$new();
            // response has multiple views whose info we need to show to user
            angular.forEach(response.information, function (information) {
              scp.information = information;
              scp.dataviewId = scp.information.DATAVIEW_ID;

              // handleFailedRequest can't handle dataview id
              delete scp.information.DATAVIEW_ID;

              // scp.usages has info added for current view
              modificationRequestTracker.handleFailedRequest(scp);
            });

            // legacy code
            const openConfirmationModal = scp => {
              let confirmation = $uibModal.open({
                templateUrl: config.templates.wkspModificationAlert,
                windowClass: 'modal-size-medium discard-step',
                scope: scp
              })
              return confirmation.result
            };
            let confirmationPromise = openConfirmationModal(scp);
            // if user press accept, do not validate 
            confirmationPromise.then(function () {
              labelService.deleteLabel(label, false).then(deleteSuccessPromise.resolve(), deleteSuccessPromise.reject());
            });
          }
        }
      }
    }
  }

  function openShowCredentialModal(item, action_panel) {
    $uibModal.open({
      templateUrl: config.templates.showCredential,
      windowClass: 'modal-size-medium show-credential-modal',
      controller: function () {
        var mvm = this;
        mvm.item = item;
        mvm.action_panel = action_panel;
      },
      controllerAs: 'mvm'
    });
  }
  function showCredential(action, action_panel) {
    var item = _.cloneDeep(action);
    item.type = "action";
    openShowCredentialModal(item, action_panel);
  }

  function deleteOrSuspendAction(action, opName, callback) {
    var item = _.cloneDeep(action);
    item.type = "action";
    item.displayOverride = {
      header: 'Confirmation?',
      label: `Are you sure you want to ${opName} this link?`,
      content: 'This action does not delete data at the target',
      buttonText: `${opName}`,
      changeContent: true
    }
    if(item.handler_type == 'internal_dataset'){
      if (item.target_properties.SAVE_AS_DS_MODE == 'REPLACE_IN_DS') {
        item.displayOverride.content = 'This action deletes the linked data in the target dataset.';
      } 
      else{
        item.displayOverride.content = 'This action does not delete the linked data in the target dataset. You will need to do that manually.';
      }
    }

    else if(item.handler_type == 'publishdb'){
      item.displayOverride.content = `The data from this view will be removed from the database and the table <b uib-tooltip="${item.target_properties.database}">${utils.string.addCentreEllipsis(item.target_properties.table, 17)}</b> deleted.`;

      item.displayOverride.tooltip = item.target_properties.table;
    }


    var deleteAlertPromise = _openDeleteModal(item);
    var deleteSuccessPromise = $q.defer();
    deleteAlertPromise.then(confirmationAccepted);
    return deleteSuccessPromise.promise;

    function confirmationAccepted() {
      callback(action).then(delete_success)
      function delete_success() {
        deleteSuccessPromise.resolve();
      }
    }
  }

  function deleteDerivative(dataview, derivative) {
    var item = _.cloneDeep(derivative);
    var deleteAlertPromise = _openDeleteModal(item);
    var deleteSuccessPromise = $q.defer();
    deleteAlertPromise.then(confirmationAccepted);
    analyticsService.userEventTrack(c.userEvents.elements.deleteTriggered, {
      elementType: derivative.type, elementId: derivative.id, dataviewId: derivative.dataviewId
    });
    return deleteSuccessPromise.promise;

    function confirmationAccepted() {
      derivative.deleting = true;
      var elementService = oldElementServiceFactory.getByDataviewId(dataview.id);
      var ddm = new DrilldownManagerFactory(derivative, elementService);
      ddm.deleteAllDrillDownElements(derivative.id);
      derivatives.discard(dataview.id, derivative.id).then(delete_success);

      function delete_success(data) {
        function future_tracker(future) {
          if (future.status == "processing") {
            return;
          }
          if (future.status == "success") {
            deleteSuccessPromise.resolve()
          }
          else if (future.status == "error") {
            deleteSuccessPromise.reject()
          }
          
        }
        FutureService.track(data.future_id, future_tracker)
     
        analyticsService.userEventTrack(c.userEvents.elements.delete, {
          elementType: derivative.type, elementId: derivative.id, dataviewId: derivative.dataviewId
        });
      }
    }
  }

  function deleteDrilldown(item) {
    var deleteAlertPromise = _openDeleteModal(item);
    return deleteAlertPromise;
  }
  function deleteAlert(item){
    var deleteAlertPromise=_openDeleteModal(item);
    return deleteAlertPromise;
  }

  function deleteIdentity(item) {
    var deleteAlertPromise = _openDeleteModal(item);
    return deleteAlertPromise;
  }

  function deleteApiToken(item) {
    var deleteDeletePromise = _openDeleteModal(item);
    return deleteDeletePromise;
  }

  function _openDeleteModal(item) {
    var deleteAlert = $uibModal.open({
      templateUrl: config.templates.deleteConfirmation,
      windowClass: 'modal-size-medium delete-dataset',
      controller: function () {
        var mvm = this;
        mvm.item = item;
      },
      controllerAs: 'mvm'
    });
    return deleteAlert.result;
  }

  function createApiToken(item) {
    var createAppToken = $uibModal.open({
      templateUrl: config.templates.appCreationModal,
      windowClass: 'modal-size-medium app-creation',
      controller: function () {
        var mvm = this;
        mvm.item = item;
      },
      controllerAs: 'mvm'
    });
    return createAppToken.result;
  }

  function editApiToken(item) {
    var editAppToken = $uibModal.open({
      templateUrl: config.templates.appCreationEditModal,
      windowClass: 'modal-size-medium app-creation',
      controller: function () {
        var mvm = this;
        mvm.item = item;
      },
      controllerAs: 'mvm'
    });
    return editAppToken.result;
  }

  function openRegeneratePasswordModal() {
    // text to display during confirmation
    var item = {
      title: 'Regenerate Password?',
      content: ''
    }
    item.content = 'All views are published to the same database. ';
    item.content += 'You will need to update the password on any third party applications that use this data.';

    var regeneratePasswordAlert = $uibModal.open({
      templateUrl: config.templates.regeneratePublishPwd,
      windowClass: 'modal-window modal-size-medium',
      controller: function () {
        var mvm = this;
        mvm.item = item;
      },
      controllerAs: 'mvm'
    });
    return regeneratePasswordAlert.result;
  }

  function renameDs(ds) {
    var renamePromise = _openRenameModal(ds);
    analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.renameDS, {eventOrigin: "landingPage.previewPanel"});
    renamePromise.then(confirmationAccepted);
    return renamePromise;

    function confirmationAccepted(new_name) {
      DatasourceService.rename(ds, new_name).then(rename_success);
      function rename_success() {
      }
    }
  }
  function cloneDs(ds) {
    var cloneDsPromise = _openCloneDsModal(ds);
    analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.createDuplicateDS, {eventOrigin: "landingPage.previewPanel"});
    
    cloneDsPromise.then(confirmationAccepted);
    return cloneDsPromise;

    function confirmationAccepted(modalResult) {
      ds.clone(modalResult).then(clone_ds_success);
      function clone_ds_success() {
      }
    }
  }

  function renameWs(ws) {
    var renamePromise = _openRenameModal(ws);
    var renameSuccessPromise = $q.defer();
    renamePromise.then(confirmationAccepted);
    return renameSuccessPromise.promise;

    function confirmationAccepted(new_name) {
      DataviewService.rename(ws, new_name).then(rename_success, rename_failure);
      function rename_success() {
        renameSuccessPromise.resolve(new_name);
      }

      function rename_failure(data) {
        renameSuccessPromise.reject(data);
      }
    }
  }

  function duplicateWs() {
    var deleteAlert = $uibModal.open({
      templateUrl: config.templates.duplicateWs,
      windowClass: 'modal-size-medium',
      controller: function () {
        var mvm = this;
        },
      controllerAs: 'mvm'
    });
    return deleteAlert.result;
  }

  function renameLabel(label) {
    var renamePromise = _openRenameModal(label);
    var renameSuccessPromise = $q.defer();
    renamePromise.then(confirmationAccepted);
    return renameSuccessPromise.promise;

    function confirmationAccepted(newName) {
      labelService.rename(label, newName).then(rename_success, rename_failure);
      function rename_success() {
        renameSuccessPromise.resolve(newName);
      }

      function rename_failure(data) {
        renameSuccessPromise.reject(data);
      }
    }
  }

  function saveTemplateConfig() {
    var renameAlert = $uibModal.open({
      templateUrl: config.templates.templateConfigModal,
      windowClass: 'modal-size-large'
    });
    return renameAlert.result;
  }

  function templateConfirmationDialog() {
    var renameAlert = $uibModal.open({
      templateUrl: config.templates.templateConfirmationModal,
      windowClass: 'modal-size-large'
    });
    return renameAlert.result;
  }

  function _openRenameModal(item) {
    var renameAlert = $uibModal.open({
      templateUrl: config.templates.renameConfirmation,
      windowClass: 'modal-size-medium renameModal',
      controller: function () {
        var mvm = this;
        mvm.item = item;
        mvm.new_name = utils.decodeSanitizedName(item.name);
      },
      controllerAs: 'mvm'
    });
    return renameAlert.result;
  }
  function _openCloneDsModal(item) {
    var cloneDsAlert = $uibModal.open({
      templateUrl: config.templates.cloneDsConfirmation,
      windowClass: 'modal-size-medium renameModal',
      controller: function () {
        var mvm = this;
        mvm.item = item;
        mvm.cloneDataview = false;
      },
      controllerAs: 'mvm'
    });
    return cloneDsAlert.result;
  }

 

  function transferOwnerShip(UserWorkspace, userToBeDeleted){
    var windowClass = 'modal-size-medium modal-transfer-owner';
    return $uibModal.open({
      templateUrl: config.templates.deleteUserConfirmation,
      windowClass: windowClass,
      controller: function () {
        var mvm = this;
        mvm.user = userToBeDeleted;
        mvm.UserWorkspace = UserWorkspace;
        mvm.new_owner_user = null;
      },
      controllerAs: 'mvm'
    }).result;
  }


  function combineDS(ds) {
    analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.combineData.combineDSClicked, {eventOrigin: "landingPage.previewPanel"});
    var scope = $rootScope.$new();
    scope.ds = ds;
    var combineModal = $uibModal.open({
      templateUrl: config.templates.combineDs,
      scope: scope,
      windowClass: 'modal-size-large',
      controller: config.controllers.combineDs,
      controllerAs: 'mvm'
    });

    return combineModal.result;
  }

  function multiDelete(items) {
    var multiDeletePromise = _openMultiDeleteModal(items);
    multiDeletePromise.then(confirmationAccepted);
    return multiDeletePromise;

    function confirmationAccepted() {
      let ds_items = [];
      let file_items = [];
      let wksps = [];
      let labels = [];
      angular.forEach(items, function (item) {
        if (item.type === 'datasource') {
          ds_items.push(item);
        }
        else if (item.type === 'file_object') {
          file_items.push(item)
        }
        else if (item.type === 'dataview') {
          wksps.push(item);
        }
        else if (item.type === 'label') {
          labels.push(item);
        }
      });
      var allPromises = [];

      if (ds_items.length > 0) {
        allPromises.push(DatasourceService.multiDelete(ds_items));

      }

      if (file_items.length > 0) {
        allPromises.push(FileService.multi_delete(file_items));
      }

      if (labels.length > 0) {
        allPromises.push(labelService.multiDeleteLabels(labels));
      }

      if (wksps.length > 0) {
        angular.forEach(wksps, function (wksp) {
          allPromises.push(dataviewSafeDeleteResetService.delete(wksp.id));

        });
      }
      $q.all(allPromises).then(delete_success);

      function delete_success() {
        toastNotification.success(utils.string.capitalize(
            utils.number.toWords(wksps.length + file_items.length + ds_items.length + labels.length), true
          ) + ' items queued for deletion');
      }
    }
  }

  function _openMultiDeleteModal(items) {

    var deleteMultiModal = $uibModal.open({
      templateUrl: config.templates.multiDeleteConfirmation,
      windowClass: 'modal-size-medium delete-dataset',
      controller: function () {
        var mvm = this;
        mvm.items = items;
      },
      controllerAs: 'mvm'
    });

    return deleteMultiModal.result;
  }

  function openRenameTaskModal(task) {
    var renameTaskModal = $uibModal.open({
      templateUrl: config.templates.stepRename,
      windowClass: 'modal-size-medium renameModal',
      controller: function () {
        var tdvm = this;
        tdvm.task = task;
        tdvm.display_info = {};
        tdvm.display_info = _.cloneDeep(task.display_info);
        tdvm.save = function () {
          renameTaskModal.close(tdvm.display_info);
        };
      },
      controllerAs: 'tdvm'
    });

    return renameTaskModal.result;
  }


  function openEditTaskNoteModal(task) {
    var editTaskNoteModal = $uibModal.open({
      templateUrl: config.templates.stepAddNote,
      windowClass: 'modal-size-medium',
      controller: function () {
        var tdvm = this;
        tdvm.display_info = {};
        tdvm.task = task;
        tdvm.display_info = _.cloneDeep(task.display_info);
        tdvm.save = function () {
          editTaskNoteModal.close(tdvm.display_info);
        };
        if (tdvm.display_info && 'note' in tdvm.display_info){
          tdvm.display_info['note'] = utils.sanitizeData(tdvm.display_info['note'])
        }
        tdvm.hasChanges = false;
        tdvm.checkForChanges = function () {
          $timeout(function () {
            if ('note' in tdvm.display_info) {
              if(task.display_info){
                tdvm.hasChanges = task.display_info['note'] != tdvm.display_info['note'];
              }
              else if(!task.display_info && !tdvm.display_info['note']){
                tdvm.hasChanges = false
              }
              else{
                tdvm.hasChanges = true
              }
            }
          }, 500)
        };
      },
      controllerAs: 'tdvm'
    });
    return editTaskNoteModal.result;
  }

  function refreshCloudData(item) {
    var refreshAlertPromise = _openRefreshCloudDataModal(item);
    return refreshAlertPromise;
  }

  function _openRefreshCloudDataModal(item) {
    var refreshAlert = $uibModal.open({
      templateUrl: config.templates.refreshCloudDataConfirmation,
      windowClass: 'modal-size-medium refresh-cloud-data-modal',
      controller: function () {
        var mvm = this;
        mvm.item = item;
      },
      controllerAs: 'mvm'
    });
    return refreshAlert.result;
  }

  function resetWs() {
    var refreshAlert = $uibModal.open({
      templateUrl: config.templates.resetWsConfirmation,
      windowClass: 'modal-size-medium',
      controller: function () {
      },
      controllerAs: 'mvm'
    });
    return refreshAlert.result;
  }

  function exportDialog(ws, condition) {
    var scope = $rootScope.$new();
    let fileterdData: boolean = false;
    if (condition){
      fileterdData = true;
    }
    scope.dataview = ws;
    scope.condition = condition;

    analyticsService.userEventTrack(c.userEvents.exportEvents.exportDailogOpened, {eventOrigin: 'dataview.statusBar', filteredData: fileterdData  })
    var exportDialog = $uibModal.open({
      templateUrl: config.templates.exportDialog,
      scope: scope,
      windowClass: 'modal-size-large exportDialogModal',
      controller: config.controllers.exportDialog,
      controllerAs: 'mvm'
    });
    return exportDialog.result;
  }

  function alertsDialog(ws, condition) {
    var scope = $rootScope.$new();
    scope.dataview = ws;
    scope.condition = condition;
    var exportDialog = $uibModal.open({
      templateUrl: config.templates.alertsDialog,
      scope: scope,
      windowClass: 'modal-size-large alertsDialogModal',
      controller: config.controllers.alertsDialog,
      controllerAs: 'mvm'
    });
    return exportDialog.result;
  }

  function manageUsers() {
    var manageUsers = $uibModal.open({
      templateUrl: config.templates.manageUsers,
      windowClass: 'modal-size-xlarge user-management-modal',
      controller: config.controllers.manageUsers,
      controllerAs: 'mvm'
    });
    return manageUsers.result;
  }

  function createLabel(parentRID) {
    var scope = $rootScope.$new();
    scope.parentRID = parentRID;
    var createLabel = $uibModal.open({
      templateUrl: config.templates.createLabel,
      scope: scope,
      windowClass: 'modal-size-medium createLabelModal',
      controller: config.controllers.createLabel,
      controllerAs: 'mvm'
    });
    return createLabel.result;
  }
}
