<template>
  <mm-modal
    v-model="computedShowModal"
    contentClass="action-bar-export-managed-credentials"
    :title="$t('action_bar.export.export_to_database.mammoth_managed_database.credentials.modal_title')"
    :width="442"
    closable
  >
    <div class="d-flex-center">
      <mm-icon style="width: 42px" name="logo_postgresql" />
      <span>PostgreSQL</span>
    </div>

    <div
      v-for="(credentialKey, credentialIndex) in credentialKeys"
      class="database-credential d-flex m-b-3 mm-rounded-borders"
      :class="{ 'database-credential-copied': copiedKeys.includes(credentialKey) }"
      :key="credentialIndex"
    >
      <div class="credential-text d-flex">
        <span class="m-r-3">{{ credentialKey.charAt(0).toUpperCase() + credentialKey.slice(1) }}</span>
        <input
          class="mm-text--h200 m-r-3 flex-grow-1"
          :type="credentialKey == 'password' && hidePassword ? 'password' : 'text'"
          :value="credentials[credentialKey]"
          readonly
        />
      </div>
      <mm-button
        v-if="credentialKey === 'password'"
        class="credentials-btn credentials-btn-password m-r-3"
        objective="tertiary"
        :icon="hidePassword ? 'show' : 'hide'"
        size="medium"
        noFocusOnClick
        @click="hidePassword = !hidePassword"
      />
      <mm-button
        class="credentials-btn"
        :class="lastCopiedKey == credentialKey ? 'credential-btn-check' : 'credential-btn-copy'"
        objective="tertiary"
        :icon="lastCopiedKey == credentialKey ? 'check' : 'copy'"
        size="medium"
        noFocusOnClick
        @click="onCopyClick(credentialKey)"
      />
    </div>
    <template #actions>
      <mm-button
        class="database-credential-copy-all-btn w-100"
        :label="$t('action_bar.export.export_to_database.mammoth_managed_database.credentials.copy_all')"
        objective="primary"
        size="large"
        block
        @click="onCopyAllClick"
      />
    </template>
  </mm-modal>
</template>

<script>
export default {
  name: 'action-bar-export-managed-credentials',
  props: {
    value: Boolean,
    credentials: {
      type: Object,
      default: () => ({
        // host: String,
        // port: String,
        // database: String,
        // username: String,
        // password: String,
        // table: String
      })
    }
  },
  data: () => ({
    hidePassword: true,
    copiedKeys: [],
    lastCopiedKey: ''
  }),
  computed: {
    computedShowModal: {
      get() {
        return this.value
      },
      set(showModal) {
        this.$emit('input', showModal)
      }
    }
  },
  created() {
    this.credentialKeys = ['host', 'port', 'username', 'password', 'database', 'table']
  },
  methods: {
    async onCopyClick(key) {
      this.lastCopiedKey = key
      this.copiedKeys.push(key)
      await navigator.clipboard.writeText(this.credentials[key])

      if (this.copyTimeoutHandle) clearTimeout(this.copyTimeoutHandle)
      this.copyTimeoutHandle = setTimeout(() => (this.lastCopiedKey = ''), 4000)
    },
    async onCopyAllClick() {
      this.copiedKeys.splice(0)
      const creds = this.credentialKeys
        .reduce((creds, credentialKey) => {
          this.copiedKeys.push(credentialKey)
          return `${creds}\n${credentialKey}: ${this.credentials[credentialKey]}`
        }, '')
        .substr(1)
      await navigator.clipboard.writeText(creds)
      this.$toast.show(
        this.$t('action_bar.export.export_to_database.mammoth_managed_database.credentials.copy_all_toast')
      )
    }
  }
}
</script>

<style lang="scss">
.action-bar-export-managed-credentials {
  .database-credential {
    border: 1px solid var(--mm-color--n40);
    padding: 8px 8px 8px 16px;
    align-items: center;

    .credential-text {
      flex: 1;
      flex-wrap: wrap;
      align-items: center;
      height: 24px;

      input {
        color: var(--mm-color--p800);
        outline: none;
        border: none;
        box-shadow: none;
      }
    }

    .credentials-btn {
      background: var(--mm-color--n40);

      &:hover {
        background: var(--mm-color--n20) !important;
      }

      .mm-icon {
        --mm-icon--color: var(--mm-color--n300) !important;
        --mm-icon--color-hover: var(--mm-color--n300) !important;
      }

      &.credential-btn-check {
        background: var(--mm-color--s100) !important;

        .mm-icon {
          --mm-icon--color: var(--mm-color--s600) !important;
          --mm-icon--color-hover: var(--mm-color--s600) !important;
        }
      }

      &.credential-btn-copy {
        padding: 6px 10px 6px 10px !important;

        .mm-icon {
          width: 12px !important;
          height: 12px !important;
        }
      }
    }

    &.database-credential-copied {
      border: 1px solid var(--mm-color--s100);
    }
  }

  .database-credential-copy-all-btn {
    background: var(--mm-color--s900) !important;

    &:hover {
      background: var(--mm-color--s800) !important;
    }
  }
}
</style>
