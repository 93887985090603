
dateFormatter.$inject = ['config', '$uibModal'];
export function dateFormatter(config, $uibModal){
  return {
    templateUrl: config.templates.dateFormatter,
    controller: 'dateFormatterController',
    controllerAs: 'nf',
    scope: {
      format: "=",
      displayText: "=",
      appendToBody: '=',
      sampleValues: '=',
      onSubmitCb: '&'
    },
    bindToController: true,
    link: function(scope, element, attrs, ctrl){
      scope.isModal = attrs.isModal;
      var modal;
      if (scope.isModal) {
        scope.toggleClicked = function () {
          modal = $uibModal.open({
            templateUrl: config.templates.dateFormatter,
            scope: scope,
            windowClass: 'modal-size-small modal-formatter'
          });
        };
      } else {
        scope.$close = function () {
          // does nothing
        };
      }
    }
  };
}


/**
 * @ngInject
 */
numericFormatter.$inject = ['config'];
export function numericFormatter(config) {
  return {
    templateUrl: config.templates.numericFormatter,
    controller: 'numericFormatterController',
    controllerAs: 'nf',
    bindToController: true,
    scope: {
      format: "=",
      onSubmitCb: '&'
    },
    link: function linkFn(scope, element, attrs) {
      scope.$watchCollection('nf.format', function (newVal, oldVal) {
        if(oldVal.scientific && !newVal.scientific)
        {
          scope.$parent.cm.humanized = false;
        }
        scope.nf.init();
      }, true);
    }
  };
}
//Color a specified number of zeros corresponding to the selected number of decimal places
/**
 * @ngInject
 */
export function colorChars() {
  var value = '0.00000';
  return {
    restrict: 'E',
    template: '<span>' +
    '<span class="active-letter" ng-bind="firstPart"></span>' +
    '<span class="inactive-letter" ng-bind="secondPart"></span>' +
    '</span>',
    link: function(scope, element, attrs) {
      scope.$watch(attrs.decimalSpec, _render);

      function _render(decimalSpec) {
        let firstPart, secondPart;
        if(decimalSpec === undefined || decimalSpec === null || isNaN(decimalSpec)){
          firstPart = '';
          secondPart = value;
        }
        else{
          decimalSpec = Math.floor(decimalSpec);
          decimalSpec += 1;
          if(decimalSpec > 1){
            decimalSpec += 1;
          }
          firstPart = value.substring(0, decimalSpec);
          secondPart = value.substring(decimalSpec, value.length);
        }
        scope.firstPart = firstPart;
        scope.secondPart = secondPart;
      }
    }
  }
}
