import * as angular from 'angular';
import { mammothExpressionHelperFactory } from './expression.helper';
import {codeMirrorEditorDefiner} from './code.mirror.service';
import {mamMathEditor} from './mam.math.editor.directive';
import {MetricNameDestinationManagerFactory} from './expression.manager';
import {expressionManagerFactory} from './expression.manager';
import {MathModalController, mathModalService} from './math.service';
import { formulaBuilderInterface } from './formula.builder';
/**
 * Module containing math editor
 */
export let mathModule = angular.module('app.common.math', ['app.common.filter', 'app.common.utils'])
mathModule.service('mammothExpressionHelperFactory', mammothExpressionHelperFactory);
mathModule.service('codeMirrorEditorDefiner', codeMirrorEditorDefiner);
mathModule.directive('mamMathEditor', mamMathEditor);
mathModule.service('MetricNameDestinationManagerFactory', MetricNameDestinationManagerFactory);
mathModule.service('expressionManagerFactory', expressionManagerFactory);
mathModule.service('mathModalService', mathModalService);
mathModule.controller('MathModalController', MathModalController);
mathModule.directive('formulaBuilderInterface', formulaBuilderInterface);
