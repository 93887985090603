import * as angular from 'angular';
import {DataviewService} from '../core/dataview.service';
import {TaskServiceFactory} from '../dataview/tasks/taskService.factory';

dsModificationAlertCtrl.$inject = ['$scope', '$uibModalInstance','DataviewService', 'TaskServiceFactory'];

export function dsModificationAlertCtrl($scope, $uibModalInstance, DataviewService,TaskServiceFactory) {
  this.$onInit = function () {
    var uvm = this;

    uvm.columns_affected = Object.keys(uvm.result.info);

    uvm.col_internal_to_display_name = {};
    angular.forEach(uvm.columns_affected, function (column) {
      let column_info = uvm.metadata.filter(function (col) {
        return col['internal_name'] == column;
      });
      uvm.col_internal_to_display_name[column] = (column_info[0]['display_name'])

    });

    uvm.wksp_affected_per_column = {};
    uvm.wksp_names_map = {};
    uvm.userConfirmation = false;
    uvm.column_wise_wksp_wise_derivative_depedencies = {};
    uvm.list_of_column_wise_wksp_wise_affected_task_sequences = {};

    angular.forEach(uvm.columns_affected, function (column) {
      uvm.wksp_names_map[column] = {};
      let wksps = Object.keys(uvm.result.info[column]);
      uvm.list_of_column_wise_wksp_wise_affected_task_sequences[column] = {};
      uvm.column_wise_wksp_wise_derivative_depedencies[column] = {};
      angular.forEach(wksps, function (wksp) {
        uvm.list_of_column_wise_wksp_wise_affected_task_sequences[column][wksp] = [];
        uvm.column_wise_wksp_wise_derivative_depedencies[column][wksp] = [];

        //affected derivatives of a wksp
        if (uvm.result.info[column][wksp]['dependencies'].hasOwnProperty('derivatives')) {
          let list_of_derivative_ids = [];
          angular.forEach(uvm.result.info[column][wksp]['dependencies']['derivatives'], function (derivative) {
            list_of_derivative_ids.push(derivative['id']);
          });
          uvm.column_wise_wksp_wise_derivative_depedencies[column][wksp] = list_of_derivative_ids;
        }

        //affceted tasks of the dataview itself
        angular.forEach(uvm.result.info[column][wksp]['same_dataview_affected_rules'], function (task) {
          uvm.list_of_column_wise_wksp_wise_affected_task_sequences[column][wksp].push(task['sequence']);
        });

        //affceted tasks of a dependent dataview
        if (uvm.result.info[column][wksp]['dependencies'].hasOwnProperty('tasks')) {
          angular.forEach(uvm.result.info[column][wksp]['dependencies']['tasks'], function (task) {
            uvm.list_of_column_wise_wksp_wise_affected_task_sequences[column][task['dataview_id']] = [];
            uvm.list_of_column_wise_wksp_wise_affected_task_sequences[column][task['dataview_id']].push(task['sequence']);
          });
        }
      });
      uvm.wksp_affected_per_column[column] = Object.keys(uvm.list_of_column_wise_wksp_wise_affected_task_sequences[column]);
      angular.forEach(uvm.wksp_affected_per_column[column], function (w_id) {
        let wksp = DataviewService.get_by_id(w_id);
        uvm.wksp_names_map[column][w_id] = wksp.name + ' of ' + wksp.datasource.name;
      });

    });


    uvm.onSubmit = function (info) {
      $uibModalInstance.close(info);
    };
  }
}
