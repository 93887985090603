<template>
  <main>
    <sidebar class="admin-sidebar"></sidebar>
    <section class="admin-main-container">
      <router-view class="admin-router-view-component"></router-view>
      <admin-info class="admin-info"></admin-info>
    </section>
  </main>
</template>

<script>
import Sidebar from "../components/AdminSidebar.vue";
import AdminInfo from "../components/info/AdminInfo.vue";

export default {
  components: { Sidebar, AdminInfo }
};
</script>

<style lang="scss">
$admin-sidebar-length: 160px;

.admin-sidebar {
  height: 100%;
  width: $admin-sidebar-length;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  padding-top: 20px;
  border-right: 1px solid #babfc7;
  padding-left: 20px;
}

.admin-main-container {
  position: relative;
  margin-left: $admin-sidebar-length;
  width: calc(100% - #{$admin-sidebar-length});
  height: 100vh;
  min-height: 750px;
}

.admin-router-view-component {
  height: 55vh;
  min-height: 400px;
  overflow: auto;
}

.admin-info {
  position: absolute;
  bottom: 0;
  padding: 2em;
  border-top: 1px solid #babfc7;
  width: 100%;
  height: 45vh;
  min-height: 350px;
  overflow: auto;
  background: #fcfcfc;
}
</style>
