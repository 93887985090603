googleDriveHelpers.$inject = [];

export function googleDriveHelpers() {
    return {
        on_file_url_change: on_file_url_change
    }

    function on_file_url_change(params, ele, scope, callback_fn) {
        scope.options.data.parentController.isDsConfigValid = true;
        scope.options.data.parentController.dsConfigValidationMessage = { error_message: "" };
    }
}