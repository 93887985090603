/*jslint node: true */
import * as angular from "angular";
import _ = require('lodash');

/**
 * @ngInject
 */
exploreButtonWithMenu.$inject = ['config'];
export function exploreButtonWithMenu(config){
  return {
    scope: {
      config: '=',
      dataview: '=',
      isOpen: '=',
      toggleExplore: '&',
      toggleCustom: '&',
      toggleColumn: '&',
    },
    controller: 'exploreButtonMenuController',
    controllerAs: 'em',
    replace: true,
    bindToController: true,
    templateUrl: config.templates.explorePanelButtonWithMenu,
    link: function(scope, elem, attrs){
      scope.menuTemplateUrl = config.templates.exploreAddItemsMenu;
      scope.$watch('em.isOpen', scope.em.reset);

    }
  }
}

/**
 * @ngInject
 */
exploreButtonMenuController.$inject = ['$timeout'];
export function exploreButtonMenuController($timeout){
  let em = this;
  em.addToExploreMenu = new AddToExploreMenu();

  function AddToExploreMenu(){
    let self = this;
    self.isOpen = false;
    self.state = {};
    self.origState = {};
    self.isDirty = false;
    self.reset = reset;
    self.open = open;
    self.delayedToggle = delayedToggle;
    self.close = close;
    self.apply = apply;
    self.checkIfDirty = checkIfDirty;
    self.sys_cols = [];

    function open(){
      self.isOpen = true;
      self.reset();
    }

    function delayedToggle(setToOpen){
      $timeout(function(){
        self.isOpen = setToOpen == undefined ? !self.isOpen : setToOpen;
        self.reset();
      }, 100);
    }

    function close(){
      self.isOpen = false;
    }

    function checkIfDirty(){
      self.isDirty = !angular.equals(self.state, self.origState);
    }

    function apply(){
      let atLeastOneCard = false;
      if(isStateDifferent('__custom__')){
        if(self.state['__custom__']){
          atLeastOneCard = true;
        }
        em.toggleCustom();
      }
      var allMetadata = (em.dataview.metadata).concat(em.dataview.sys_cols);
      for(let i=0; i< allMetadata.length; i++){
        let column = allMetadata[i];
        if(isStateDifferent(column.internal_name)){
          em.dataview.exploreButtonClickEvent.fire_event(column.internal_name, 'exploreColumnMenu');
        }
        if(self.state[column.internal_name]){
          atLeastOneCard = true;
        }
      }
      if(atLeastOneCard){
        em.toggleExplore({toggleVal: true});
      }
      close();
    }

    function isStateDifferent(key){
      return self.state[key] != self.origState[key];
    }

    function reset(){
      let exploreCols = [];
      let customFound = false;
      angular.forEach(em.config, function(cfg){
        if(cfg.isCustomCard){
          self.state['__custom__'] = true;
          customFound = true;
        }
        else{
          exploreCols.push(cfg.column);
        }
      });
      if(!customFound && self.state.hasOwnProperty('__custom__')){
        delete self.state['__custom__'];
      }
      for(let i=0; i< ((em.dataview.metadata).concat(em.dataview.sys_cols)).length; i++){
        let column = (em.dataview.metadata).concat(em.dataview.sys_cols)[i];
        self.state[column.internal_name] = exploreCols.indexOf(column.internal_name) != -1
      }
      self.origState = _.cloneDeep(self.state);
      self.isDirty = false;
    }

  }
}
