<template>
  <div class="mm-dataflow-status-action-required d-flex flex-column width-100">
    <div v-show="!computedShowNoDataInfo" class="accordions-wrapper">
      <div class="m-b-4 m-x-2">
        <span>{{ $t('dataflow_status.action_required.description') }}</span>
      </div>

      <!-- DATA UPDATES -->
      <mm-accordion
        v-if="computedIsDataSyncFeatureEnabled"
        v-model="isPendingDataUpdatesOpen"
        class="m-b-5"
        @toggle="handleAccordionToggle($event, 'data_updates')"
      >
        <template v-slot:header>
          <div class="d-flex align-items-center">
            <span :class="{ 'mm-text--body-bold': isPendingDataUpdatesOpen }">
              {{ $t('dataflow_status.action_required.pending_data_updates') }}
              ({{ pendingDataUpdatesQuantity }})
            </span>
          </div>
        </template>

        <template v-slot:content>
          <dataflow-status-action-required-data-updates
            :pendingDataUpdatesQuantity.sync="pendingDataUpdatesQuantity"
            :highlighted-view-id="highlightedViewId"
            @submit="$emit('submit')"
          />
        </template>
      </mm-accordion>

      <!-- PIPELINE CHANGES -->
      <mm-accordion
        v-model="isPendingPipelineChangesOpen"
        :collapsible="computedIsDataSyncFeatureEnabled"
        @toggle="handleAccordionToggle($event, 'pipeline_changes')"
      >
        <template v-slot:header>
          <div class="d-flex align-items-center">
            <span :class="{ 'mm-text--body-bold': isPendingPipelineChangesOpen }">
              {{ $t('dataflow_status.action_required.pending_pipelines') }}
              ({{ pendingPipelineChangesQuantity }})
            </span>
          </div>
        </template>

        <template v-slot:content>
          <dataflow-status-action-required-pipeline-changes @submit="$emit('submit')" />
        </template>
      </mm-accordion>
    </div>

    <div v-if="computedShowNoDataInfo" class="no-data">
      <p class="mm-text--h400">{{ $t('dataflow_status.action_required.empty_info') }}</p>
      <span>{{ $t('dataflow_status.action_required.empty_description') }}</span>
    </div>
  </div>
</template>

<script>
// Components
import DataflowStatusActionRequiredDataUpdates from './dataflow-status-action-required-data-updates/dataflow-status-action-required-data-updates'
import DataflowStatusActionRequiredPipelineChanges from './dataflow-status-action-required-pipeline-changes/dataflow-status-action-required-pipeline-changes'

// Constants
import { SPLITIO_FEATURE_FLAGS } from '@/constants'

// Plugins
import { featuresVisibility } from '@/plugins/splitio'

// Dependencies
import { mapGetters } from 'vuex'

export default {
  name: 'dataflow-status-action-required',
  components: { DataflowStatusActionRequiredPipelineChanges, DataflowStatusActionRequiredDataUpdates },
  props: {
    highlightedViewId: [String, Number]
  },
  data: () => ({
    isPendingDataUpdatesOpen: true,
    pendingDataUpdatesQuantity: 0,
    isPendingPipelineChangesOpen: false
  }),
  computed: {
    ...mapGetters({ pendingPipelineChangesQuantity: 'resources/getPendingPipelineChangesQuantity' }),
    computedShowNoDataInfo() {
      return !this.pendingDataUpdatesQuantity && !this.pendingPipelineChangesQuantity
    },
    computedIsDataSyncFeatureEnabled() {
      return featuresVisibility[SPLITIO_FEATURE_FLAGS.DATA_SYNC]
    }
  },
  watch: {
    pendingDataUpdatesQuantity: {
      handler(val) {
        this.$emit('update:actionRequiredQuantity', val + this.pendingPipelineChangesQuantity)
      },
      immediate: true
    },
    pendingPipelineChangesQuantity: {
      handler(val) {
        this.$emit('update:actionRequiredQuantity', this.pendingDataUpdatesQuantity + val)
      },
      immediate: true
    },
    computedIsDataSyncFeatureEnabled: {
      handler(val) {
        this.$nextTick(() => {
          if (val) {
            this.isPendingDataUpdatesOpen = !!this.pendingDataUpdatesQuantity
            this.isPendingPipelineChangesOpen = !this.isPendingDataUpdatesOpen
          } else this.isPendingPipelineChangesOpen = true
        })
      },
      immediate: true
    }
  },
  methods: {
    handleAccordionToggle(toggleStatus, type) {
      if (type == 'pipeline_changes') this.isPendingDataUpdatesOpen = !toggleStatus
      else this.isPendingPipelineChangesOpen = !toggleStatus
    }
  }
}
</script>
