// API
import API from '@/api'
import globalApi from '@/api/global.api'

// Transforms
import {
  transformAllResources,
  transformNewView,
  transformResources,
  transformPipelineChanges,
  transformAppliedPipelineChanges
} from './resources.transform.js'

const apiGenerator = (path) => async () => {
  const { data } = await API.get(path)
  return { data }
}

export default {
  getResources: async (lastCheckedUntil, projectId) =>
    transformAllResources(
      await apiGenerator(
        `/resources?last_checked_until=${lastCheckedUntil}${projectId ? `&project_id=${projectId}` : ''}`
      )()
    ),
  refreshWebhook: (webhookId) => API.post(`webhooks/${webhookId}`),
  addNewView: async (datasetId) =>
    transformNewView(
      await API.post(`datasets/${datasetId}/dataviews`, null, { customErrorHandling: true }).catch((error) => error) //TODO remove catch when angular code is gone, default api error handling should be used
    ),
  duplicateView: async (viewId, datasetId) =>
    transformNewView(
      await API.post(
        `datasets/${datasetId}/dataviews`,
        { id: datasetId, clone_config_from: viewId },
        { customErrorHandling: true }
      ).catch((error) => error) //TODO remove catch when angular code is gone, default api error handling should be used
    ),
  getReportResources: async () => transformResources(await apiGenerator(`/reports`)()),
  getPendingPipelineChanges: async (projectId) => {
    const { data } = await API.get(`pipeline_changes?project_id=${projectId}`)
    return transformPipelineChanges(await globalApi.getFutureRequest(data.future_id))
  },
  applyPendingPipelineChanges: async (viewsIds) => {
    const { data } = await API.post('pipeline_changes', { dataview_ids: viewsIds.map((viewId) => Number(viewId)) })
    return transformAppliedPipelineChanges(await globalApi.getFutureRequest(data.future_id))
  }
}
