import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
/**
 * @ngInject
 * MappedField Factory
 */
MappedFieldFactory.$inject = ['Singularity', 'utils'];
export function MappedFieldFactory(Singularity, utils) {

  var _fieldTypeMap = {
    NUMERIC_TEXT_BOX: 'input',
    TEXT_BOX: 'input',
    EMAIL: 'input',
    PASSWORD: 'input',
    URL: 'input',
    PARAGRAPH_TEXT: 'textarea',
    CHECKBOX: 'checkbox',
    SEARCHABLE_LIST: 'ui_select',
    SINGLE_SELECT_LIST: 'select',
    MULTI_SELECT_LIST: 'checkbox_list',
    DISPLAY_LIST: 'select',
    GRID: 'grid',
    BUTTON: 'button',
    LABEL: 'label',
    HIDDEN_FIELD: 'label',
    SERVICE_FILE: 'service_file'
  };

  return MappedField;

  function MappedField(fieldSpec, fieldAttributes, moduleFunctions, extraData) {

    var self = this;
    self.templateOptions = {};
    self.data = extraData;


    self.key = fieldSpec.key;
    self.defaultValue = fieldSpec.default_value;

    self.templateOptions.placeholder = utils.sanitizeData(fieldSpec.placeholder) || "";
    self.templateOptions.required = fieldSpec.required;
    // self.templateOptions.description =fieldSpec.short_desc;
    self.templateOptions.label = utils.sanitizeData(fieldSpec.label);

    // if (fieldSpec.dependent_on !== null) {
    //   dependencies_map[fieldSpec.key] = fieldSpec.dependent_on;
    // }

    self.data.server_validate = fieldSpec.server_validate;
    self.data.long_desc = fieldSpec.long_desc;
    self.data.server_auto_suggest = fieldSpec.server_auto_suggest;
    self.data.server_validate = fieldSpec.server_validate;
    self.hideExpression = fieldAttributes.hidden_field || "";

    self.type = _fieldTypeMap[fieldSpec.type];
    switch (fieldSpec.type) {

      case 'TEXT_BOX':
        {
          self.templateOptions.type = 'text';
          if (fieldAttributes.hasOwnProperty('onChange') && fieldAttributes.onChange) {
            self.templateOptions.onChange = _getCustomMethod(fieldAttributes.onChange);
          }
          break;
        }
      case 'EMAIL':
        {
          self.templateOptions.type = 'email';
          break;
        } case 'NUMERIC_TEXT_BOX':
        {
          self.templateOptions.type = 'number';
          if (fieldAttributes.hasOwnProperty('onChange') && fieldAttributes.onChange) {
            self.templateOptions.onChange = _getCustomMethod(fieldAttributes.onChange);
          }
          break;
        }
      case 'PASSWORD':
        {
          self.templateOptions.type = 'password';
          break;
        }
      case 'SERVICE_FILE':
        {
          break;
        }
      case 'PARAGRAPH_TEXT':
        {  // ToDo :  if row, columns required? if it should be non expandable?
          if (fieldAttributes.hasOwnProperty('onChange') && fieldAttributes.onChange) {
            self.templateOptions.onChange = _getCustomMethod(fieldAttributes.onChange);
          }
          self.templateOptions.rows = fieldAttributes.rows || 8;
          self.templateOptions.classes = fieldAttributes.classes || "";
          // self.templateOptions.cols = 75;
          self.templateOptions['disabled'] = fieldSpec?.disabled || false;
          break;
        }
      case 'URL':
        {
          self.templateOptions.type = 'url';
          self.templateOptions.description = fieldSpec.short_desc;
          if (fieldAttributes.hasOwnProperty('onChange') && fieldAttributes.onChange) {
            self.templateOptions.onChange = _getCustomMethod(fieldAttributes.onChange);
          }
          break;
        }
      case 'CHECKBOX':
        { // Or Bool
          break;
        }
      case 'SEARCHABLE_LIST':
        {
          self.type = fieldAttributes.ui_type || _fieldTypeMap[fieldSpec.type];
          self.ngModelAttrs = {
            size: {
              attribute: 'size'
            }
          };
          self.templateOptions.options = fieldAttributes.items;  // backend validation :  required key
          self.templateOptions.text = fieldSpec.long_desc; // description text
          if (fieldAttributes.hasOwnProperty('onChange') && fieldAttributes.onChange) {
            self.templateOptions.onChange = _getCustomMethod(fieldAttributes.onChange);
          }

          break;
        }
      case 'SINGLE_SELECT_LIST':
        {
          self.type = fieldAttributes.ui_type || _fieldTypeMap[fieldSpec.type];
          self.ngModelAttrs = {
            size: {
              attribute: 'size'
            }
          };

          self.templateOptions.size = fieldAttributes.size || 0;
          self.templateOptions.options = fieldAttributes.items;  // backend validation :  required key
          if (fieldAttributes.hasOwnProperty('onChange') && fieldAttributes.onChange) {
            self.templateOptions.onChange = _getCustomMethod(fieldAttributes.onChange);
          }

          self.templateOptions.allowNullSelection = true;
          if (fieldAttributes.hasOwnProperty('allowNullSelection') && typeof fieldAttributes.allowNullSelection === "boolean") {
            self.templateOptions.allowNullSelection = fieldAttributes.allowNullSelection;
          }
          self.templateOptions.nullOpt = false;
          if (fieldAttributes.hasOwnProperty('nullOpt') && typeof fieldAttributes.nullOpt === "boolean") {
            self.templateOptions.nullOpt = fieldAttributes.nullOpt;
          }
          if (self.templateOptions.nullOpt) {
            self.templateOptions.nullDisabled = false;
            if (fieldAttributes.hasOwnProperty('nullDisabled') && typeof fieldAttributes.nullDisabled === "boolean") {
              self.templateOptions.nullDisabled = fieldAttributes.nullDisabled;
            }

            for (var i = 0; i < fieldAttributes.items.length; i++) {
              if (fieldAttributes.items[i].value === "") {
                self.templateOptions.nullDisplay = fieldAttributes.items[i].name;
                fieldAttributes.items.splice(i, 1);
                break;
              }
            }

          }


          /*function (value,fieldSpecent, scope) {
           //toOD ; put if check for existence of fieldSpecent in depedency map
           var params = {};
           params[fieldSpec.key] = value;
           Singularity.augmentDsConfigSpecParams(params, scope,fieldSpecent.onChangeCallback);
           };*/
          break;
        }
      case 'MULTI_SELECT_LIST':
        {
          self.type = fieldAttributes.ui_type || _fieldTypeMap[fieldSpec.type];
          self.ngModelAttrs = {
            size: {
              attribute: 'size'
            }
          };

          self.templateOptions.size = fieldAttributes.size || 0;
          self.templateOptions.options = fieldAttributes.items;  // backend validation :  required key
          if (fieldAttributes.hasOwnProperty('onChange') && fieldAttributes.onChange) {
            self.templateOptions.onChange = _getCustomMethod(fieldAttributes.onChange);
          }
          /*function (value,fieldSpecent, scope) {
           //toOD ; put if check for existence of fieldSpecent in depedency map
           var params = {};
           params[fieldSpec.key] = value;
           Singularity.augmentDsConfigSpecParams(params, scope,fieldSpecent.onChangeCallback);
           };*/
          break;
        }
      case 'DISPLAY_LIST':
        {
          self.ngModelAttrs = {
            size: {
              attribute: 'size'
            },
            disabled: {}
          };

          self.controller = /*@ngInject*/ function ($scope) {

          };
          self.templateOptions.size = fieldAttributes.size;
          self.templateOptions.options = fieldSpec.items;  // backend validation :  required key
          break;
        }
      case 'GRID':
        {
          self.templateOptions.rows = fieldAttributes.rows;
          self.templateOptions.metadata = fieldAttributes.metadata;

          break;
        }
      case 'BUTTON':
        {
          self.templateOptions.text = fieldSpec.placeholder;
          self.templateOptions.onClickParams = fieldAttributes.onClickParams;
          self.templateOptions.onClick = _getCustomMethod(fieldAttributes.onClick);
          self.templateOptions.enabled = fieldAttributes.enabled;
          break;
        }
      case 'LABEL':
        {
          self.templateOptions.label = fieldSpec.label;
          self.templateOptions.enabled = (fieldAttributes.enabled === undefined || fieldAttributes.enabled === null) ? true : fieldAttributes.enabled;
          self.templateOptions.classes = fieldAttributes.classes;
          break;
        }
      case 'HIDDEN_FIELD':
        {
          self.templateOptions.label = fieldSpec.label;
          self.templateOptions.type = "hidden";
          break;
        }

    }

    function _getCustomMethod(methodName) {
      if (moduleFunctions && moduleFunctions.hasOwnProperty(methodName)) {
        return moduleFunctions[methodName];
      }
      else {
        // statements as string, not a function
        console.warn("Not recommended: unknown method name received ", methodName);
        try {
          console.warn("Not recommended: attempting to validate input as js code", methodName);
          return new Function(methodName);
        }
        catch (e) {
          console.error(e.name);
          console.error(e.message);
        }
      }
    }
  }
}