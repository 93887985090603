import * as angular from 'angular';
import * as $ from 'jquery';
/**
 * @ngInject
 * */
export function valDuplicateAggColName() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valDuplicateAggColName(scope, elem, attrs, ctrl) {
      var manager = scope.$eval(attrs.valDuplicateAggColName);
      ctrl.$validators.valDuplicateAggColName = function (modelValue, viewValue) {
        var found = false;
        $.each(manager.selected_items, function (i, item) {
          if (item !== scope.item && item.new_column_name ===  viewValue) {
            found = true;
            return false;
          }
        });

        if(angular.isArray(manager.derivedColumns)){
          $.each(manager.derivedColumns, function (i, item) {
            if (item !== scope.item && item.MATH.AS.COLUMN ===  viewValue) {
              found = true;
              return false;
            }
          });
        }

        return !found;
      };
      var run_validator_key = Math.random().toString();
      manager.run_validators.add_callback(run_validator_key, ctrl.$validate);
      ctrl.$viewChangeListeners.push(manager.run_validators.fire_event);

      scope.$on('$destroy', function () {
        manager.run_validators.remove_callback(run_validator_key);
      });
    }
  };
}
