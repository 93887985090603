import { dataviewConfig } from './../dataview.config';
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
/**
 * @ngInject
 */
emailActionManagerFactory.$inject = ['eventCallbackManagerFactory', 'utils'];
export function emailActionManagerFactory(eventCallbackManagerFactory, utils) {
  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new emailActionManager(options);
  }

  function emailActionManager(options) {
    var self = this;
    self.serversideValidationRequired = false;
    if (_.isUndefined(options.triggerType)) {
      options.triggerType = "pipeline";
    }
    self.dataviewId = options.context.dataview.id;
    self.param = {
      "handler_type": "email",
      "trigger_type": options.triggerType,
      "run_immediately": true,
      sequence: options.context.currentSequenceNumber
    };

    self.param.target_properties = {};
    self.param.target_properties.emails = null;
    self.param.target_properties.subject = null;
    self.param.target_properties.message = null;

    self.getParam = getParam;
    self.setParam = setParam;
    self.validate = validate;

    function validate() {
      return true;
    }
    function getParam() {
      if (self.param.target_properties.index) {
        self.param.target_properties.index = self.param.target_properties.index.trim();
      }
      return self.param;
    }

    function setParam(param) {
      angular.extend(self.param, param);
    }

  }
}
