import * as angular from 'angular';
import * as _ from 'lodash-es';
/**
 * @ngInject
 * Datasource service
 */
DataviewFactory.$inject = ['$resource', 'config', '$q', 'eventCallbackManagerFactory',
                          'utils', '$timeout', 'FutureService', 'c','Notification'];
export function DataviewFactory($resource, config, $q, eventCallbackManagerFactory,
                          utils, $timeout, FutureService, c, Notification) {
  var DataviewsResource = $resource(config.api.dataviews,{id: '@id'});
  var DataviewDataResource = $resource(config.api.dataviewData, {ws_id: '@id'});

  return Dataview;

  function Dataview(ws_data) {
    var self = this;
    self.rename = rename;
    self.type = 'dataview';
    self.update = update;
    self.pipeline_status = '';
    self.get_data = get_data;
    self.get_100_rows = get_100_rows;
    self.setDisplayProperties = setDisplayProperties;
    self.getLongName = getLongName;
    var _prev_data, _prevColumns;
    self.exploreButtonClickEvent = new eventCallbackManagerFactory('exploreButtonClickEvent');
    self.exploreCardCloseEvent = new eventCallbackManagerFactory('exploreCardCloseEvent');
    self.stepPreview = {
        sequence: null,
        get inPreviewMode(){
            return Number.isInteger(this.sequence)
        },
        sequenceUpdateEvent: new eventCallbackManagerFactory('step_preview_sequence_updated'),
        setSequence: function(sequence_number){
            this.sequence = sequence_number;
            this.sequenceUpdateEvent.fire_event(this.sequence);
        },
        exitPreview: function () {
            this.setSequence(null)
        }
    };
    var ws_updated = new eventCallbackManagerFactory('ws_updated');
    var ws_data_fetched = new eventCallbackManagerFactory('ws_data_fetched');
    var ws_reset_event = new eventCallbackManagerFactory('ws_data_fetched');
    self.stepPreviewSequenceUpdateEvent = new eventCallbackManagerFactory('ws-step-preview-sequence-update-event');

    self.update_event = ws_updated;
    self.on_update = ws_updated.add_callback;
    self.on_data_fetch = ws_data_fetched.add_callback;
    self.fire_on_update = ws_updated.fire_event;
    self.on_reset = ws_reset_event.add_callback;
    self.fire_reset = ws_reset_event.fire_event;
    self.remove_on_update = ws_updated.remove_callback;
    self.dataRefreshedAt = null;

    var ws_columns_added = new eventCallbackManagerFactory('ws_updated');
    self.onColumnsAdded = ws_columns_added.add_callback;

    var ws_res = new DataviewsResource();
    var ws_data_res = new DataviewDataResource();
    ws_res.id = self.id;
    ws_data_res.id = self.id;

    var _updateDiffKeys = ["status", "pipeline_status", "derivative_count", "tasks_total_count", "name", "display_properties",
      "updated_at", "metrics", "row_count", "column_count", "draft_mode", "inbound_updates_pending","inbound_updates_enabled",
      "inbound_updates_contexts", "data_updated_at", 'in_preview_mode'];
    ////

    update(ws_data);

    function update_column_properties(_COLUMN_NAMES, _HIDDEN_COLUMNS) {
      let _visibleCount = 0;
      angular.forEach(self.metadata, function (col) {
        /*
         *The value of display_name property in col info in the metadata gets overriden by the renamed display name and original display name is lost.
          so we need an extra property to hold the value of original display name.
         */
        if(!col.hasOwnProperty('old_display_name')) {
          col.old_display_name = col.display_name;
        }
        col.display_name = _COLUMN_NAMES[col.internal_name] || col.display_name;
        if (_HIDDEN_COLUMNS.indexOf(col.internal_name) === -1) {
          _visibleCount += 1;
        }
      });
      self.visible_columns_count = _visibleCount;
    }

    function update(ws_data, preventUpdateEvent = false) {
      if(angular.equals(_prev_data, ws_data) || self.stepPreview.inPreviewMode){
        return;
      }
      // logic to ignore changes in some display properties
      var _changesOnlyInIgnoredProperties = false;
      var _changes = {};
      if (_prev_data) {
        _changes = _getChangedProperties(_prev_data, ws_data);
        // TODO: make the following code better
        var _prev_data_w_panel_props = _.cloneDeep(_prev_data);
        var _data_w_panel_props = _.cloneDeep(ws_data);
        delete _prev_data_w_panel_props.display_properties.ELEMENTS_PANEL;
        delete _data_w_panel_props.display_properties.ELEMENTS_PANEL;
        delete _prev_data_w_panel_props.display_properties.COLUMN_WIDTHS;
        delete _data_w_panel_props.display_properties.COLUMN_WIDTHS;
        _changesOnlyInIgnoredProperties = angular.equals(_prev_data_w_panel_props, _data_w_panel_props);
      }
      angular.extend(self, ws_data);
      self.unique_id = self.type + '_' + self.id;
      if (ws_data.metadata) {
        self.metadata = utils.metadata.sortMetadataByOrderSpec(ws_data.metadata, ws_data.display_properties.COLUMN_ORDER || {});
        // TODO: Why are we doing this? Not doing this calls this method as many times as the number of views. Needs investigating.
        self.metadata = utils.metadata.add_type_to_display_name(self.metadata);
        let _COLUMN_NAMES = ws_data.display_properties.COLUMN_NAMES || {};
        let _HIDDEN_COLUMNS = ws_data.display_properties.HIDDEN_COLUMNS || [];
        update_column_properties(_COLUMN_NAMES, _HIDDEN_COLUMNS);

      }
      // TODO: This is for updating the display name with type since the previous step does not do it.
      self.metadata = utils.metadata.add_type_to_display_name(self.metadata);
      if (ws_res) {
        ws_res.id = self.id;
      }
      if((!preventUpdateEvent && !_changesOnlyInIgnoredProperties) || ws_data.pipeline_status == 'running'){
        ws_updated.fire_event(_changes, _.cloneDeep(_prev_data), _.cloneDeep(self));
      }
      _prev_data = _.cloneDeep(ws_data);
      ws_res.id = self.id;
      ws_data_res.id = self.id;
    }

    function updateData(data) {

      angular.extend(self, data);
      if (data.metadata) {
        self.metadata = utils.metadata.applyDisplayChangesReturnMetadata(data.metadata, data.display_properties, undefined, undefined, false);
        self.metadata = utils.metadata.add_type_to_display_name(self.metadata);
        let _HIDDEN_COLUMNS = data.display_properties.HIDDEN_COLUMNS || [];
        let _COLUMN_NAMES = data.display_properties.COLUMN_NAMES || {};
        update_column_properties(_COLUMN_NAMES, _HIDDEN_COLUMNS);
      }
    }

    function _getChangedProperties(prev, now) {
      let _changed = {};
      angular.forEach(_updateDiffKeys, function (key) {
        if (!angular.equals(prev[key], now[key])) {
          _changed[key] = true;
        }
      });
      if (_changed.hasOwnProperty('display_properties')) {
        var changedProperties = [];
        if (prev) {
          changedProperties = utils.objectShallowDiff(now.display_properties, prev.display_properties)
        } else {
          changedProperties = Object.keys(now.display_properties)
        }
        _changed['display_properties'] = changedProperties;
      }
      return _changed;
    }

    /**
     * Rename WS
     */
    function rename(new_name, validation_only) {
      if (new_name == self.name) {
        return $q.resolve();
      }
      var ret = DataviewsResource.patch({id: self.id, validation_only: validation_only}, {patch: [{op: "replace", path: "name", value: new_name}]});
      return ret.$promise;
    }

    function get_100_rows(sequence_number) {
      var deferred = $q.defer();
      let wsDataRes =  $resource(config.api.dataviewData, {ws_id: '@id'});
      wsDataRes.get({ws_id: self.id, sequence: sequence_number}).$promise.then(function(d){
        get_data_tracker(d).then(function (response) {
          deferred.resolve(response.data);
        });
      }, deferred.reject);
      return deferred.promise;
    }

    function get_data_tracker(d) {
      // d contains future_id not the actual data
      var deferred = $q.defer();
      FutureService.track(d.future_id, data_tracker);
      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        }
        else {
          deferred.reject(response);
        }
      }
      return deferred.promise;
    }

    function get_data(forceDataUpdate=false, includeRowCount=false) {
      var deferred = $q.defer();
      if(self.dataRefreshedAt != self.data_updated_at || forceDataUpdate){
        ws_res.id = self.id;
        let params = {
          [c.sequenceNumber]: self.stepPreview.sequence
        }
        if(includeRowCount){
          params[c.includeRowCount]= includeRowCount
        }
        ws_res.$get(params).then(function(data){
          get_data_tracker(data).then(function (response) {
            updateData(response);
            var allColumns = [];
            angular.forEach(self.metadata, function (c) {
              allColumns.push(c.internal_name);
            });

            if(_prevColumns !== undefined){
              var columnChanged = [];
              angular.forEach(allColumns, function (c) {
                if (_prevColumns.indexOf(c) == -1) {
                  columnChanged.push(c);
                }
              });

              if(columnChanged.length){
                ws_columns_added.fire_event(columnChanged);
              }
            }
            _prevColumns = _.cloneDeep(allColumns);
            ws_data_fetched.fire_event();
            self.dataRefreshedAt = self.data_updated_at;
            deferred.resolve(response);
          });
        }, deferred.reject);
      }
      else {
        $timeout(deferred.resolve, 50);
      }
      return deferred.promise;
    }

    function setDisplayProperties(patch, userSpecific=false){
      let _key = userSpecific ? 'user_display_properties' : 'display_properties'
      var deferred = $q.defer();
      var patch_keys = Object.keys(patch);
      var changed = false;
      if (_prev_data?.hasOwnProperty(_key)) {
        angular.forEach(patch_keys, function(pk){
          if(!angular.equals(patch[pk], _prev_data[_key][pk])){
            changed = true;
          }
        });
      }
      else{
        changed = true;
      }

      if(!changed || self.isReadOnly){
        $timeout(deferred.resolve, 10);
      }
      else{
        DataviewsResource.save({id: self.id}, {[_key]: patch}).$promise.then(function (data) {
          get_data_tracker(data).then(function (response) {
            updateData(response);
            deferred.resolve(response);
          });
        }, function(){
          Notification.error("Unable to update view properties")
          deferred.reject();
        });
      }
      return deferred.promise;
    }

    function getLongName(){
      var name = '';
      if (self.datasource){
        name = self.datasource.name;
        // if (self.datasource.dataview_count > 1) {
          name +=  " > " + self.name;
        // }
      } else {
        name += self.name;
      }
      return utils.sanitizeData(name);
    }
  }
}

/**
 * @ngInject
 */
SimpleDataviewFactory.$inject = ['$q', '$resource', 'config', 'utils', 'eventCallbackManagerFactory'];
export function SimpleDataviewFactory($q, $resource, config, utils, eventCallbackManagerFactory){
  return Dataview;

  function Dataview(dataviewData){
    var ws = this;
    var DataviewDataResource = $resource(config.api.dataviewData);
    var DataviewResource = $resource(config.api.dataviews);

    var ws_data_resource = new DataviewDataResource();
    ws_data_resource.id = dataviewData.id;
    var ws_updated = new eventCallbackManagerFactory('ws_updated');
    ws.on_update = ws_updated.add_callback;

    ws.get_data = get_data;
    ws.update = update;
    ws.update(dataviewData)

    function update(data){
      angular.merge(ws, data);
    }

    function get_data(){
      var def = $q.defer();
      DataviewResource.get({id: ws.id}, {}).$promise.then(_getDataCb, def.reject);
      return def.promise;

      function _getDataCb(data){
        var mdata = utils.metadata.add_type_to_display_name(data.metadata);
        angular.merge(ws, {metadata: mdata, dataUrl: config.apiUrl + data.data_url});
        def.resolve();
      }
    }

  }
}
