import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * @ngInject
 * MappedForm Factory
 */
MappedFormFactory.$inject = ['apiUtils', 'MappedField', 'MappedFormHelper'];

export function MappedFormFactory(apiUtils, MappedField, MappedFormHelper) {

  return MappedForm;

  function MappedForm(userInputDiscoverySpec, parentController) {
    var self = this;


    self._keyWiseFieldsMap = {};
    self.userInputDiscoverySpec = userInputDiscoverySpec;
    self.mappedFields = [];
    self.moduleCode = parentController.selectedIntegration.key;
    self.moduleType = self.userInputDiscoverySpec.module_type;
    self.moduleFunctions = apiUtils.get_module_functions(self.moduleCode);
    self.extraData = {
      parentController: parentController,
      keyWiseFieldsMap: self._keyWiseFieldsMap
    };
    self.modelData = {};
    self.update = updateForm;
    _createForm();

    if (self.moduleFunctions && self.moduleFunctions.hasOwnProperty('on_load') && typeof self.moduleFunctions.on_load === 'function') {
      self.moduleFunctions.on_load.call(self);
    }

    function _createForm() {
      if (!!self.userInputDiscoverySpec.ux.layout && self.userInputDiscoverySpec.ux.layout.length > 0) {
        angular.forEach(self.userInputDiscoverySpec.ux.layout, function (layoutObject) {
            self.mappedFields = self.mappedFields.concat(_generateFieldsWithLayout(layoutObject));
        });
      } else {
        //  if layout info is missing
        angular.forEach(self.userInputDiscoverySpec.fields, function (field_spec, field_key) {
          var fieldAttributes = self.userInputDiscoverySpec.ux.attributes[field_key];
          var mappedField = new MappedField(field_spec,
            fieldAttributes,
            self.moduleFunctions,
            self.extraData);
            self.mappedFields.push(mappedField);

          self._keyWiseFieldsMap[field_key] = _createFieldMapObject(
            field_spec, {attributes: fieldAttributes}, mappedField
          );
        });


        // for (var i = 0; i < self.userInputDiscoverySpec.fields.length; i++) {
        //   var fieldAttributes = self.userInputDiscoverySpec.ux.attributes[self.userInputDiscoverySpec.fields[i].key];
        //   var mappedField = new MappedField(self.userInputDiscoverySpec.fields[i],
        //     fieldAttributes,
        //     self.moduleFunctions,
        //     self.extraData);
        //   self.mappedFields.push(mappedField);
        //
        //   self._keyWiseFieldsMap[self.userInputDiscoverySpec.fields[i].key] = _createFieldMapObject(
        //     self.userInputDiscoverySpec.fields[i], {attributes: fieldAttributes}, mappedField
        //   );
        // }


      }

    }

    function _createFieldMapObject(spec, ux, mappedField) {
      return {
        jsonSpec: spec,
        ux: ux,
        mappedField: mappedField
      }
    }

    // function _getFieldByKey(key) {
    //   var matchedField = undefined;
    //   angular.forEach(self.userInputDiscoverySpec.fields, function (field) {
    //     if (field.key === key) {
    //       matchedField = field;
    //       return false;
    //     }
    //   });
    //   return matchedField;
    // }

    function _generateFieldsWithLayout(layoutObject) {
      var mappedFields = [];
      switch (layoutObject.type) {

        case 'field': {
          var fieldSpec = self.userInputDiscoverySpec.fields[layoutObject.key];
          var fieldAttributes = self.userInputDiscoverySpec.ux.attributes[layoutObject.key];
          var field = new MappedField(fieldSpec,
            fieldAttributes,
            self.moduleFunctions,
            self.extraData);
          field.data.mappedFields = self.mappedFields;
          MappedFormHelper.applyLayoutToField(field, layoutObject);
          self._keyWiseFieldsMap[layoutObject.key] = _createFieldMapObject(
            fieldSpec, {
              attributes: fieldAttributes,
              layout: layoutObject
            }, field
          );
          mappedFields.push(field);
          break;
        }

        case 'row': {
          var row = {};
          var fields = [];
          angular.forEach(layoutObject.items, function (childLayoutObject) {
            childLayoutObject.parentLength = layoutObject.items.length;
            fields = fields.concat(_generateFieldsWithLayout(childLayoutObject));
          });
          row['className'] = 'inputs ' + layoutObject.classes;
          row['fieldGroup'] = fields;
          mappedFields.push(row);
          break;
        }

        case 'block': {
          angular.forEach(layoutObject.items, function (childLayoutObject) {
            mappedFields = mappedFields.concat(_generateFieldsWithLayout(childLayoutObject));
          });
          break;
        }

        default:
          console.error(
            'Unknown layout object type found!');
      }
      return mappedFields;
    }


    function _updateFieldsWithLayout(layoutObject) {
      //Assuming only fields layout might need to be updated
      switch (layoutObject.type) {

        case 'field': {
          var fieldSpec = self.userInputDiscoverySpec.fields[layoutObject.key];
          var fieldAttributes = self.userInputDiscoverySpec.ux.attributes[layoutObject.key];

          self._keyWiseFieldsMap[layoutObject.key].jsonSpec = angular.extend({}, self._keyWiseFieldsMap[layoutObject.key].jsonSpec,
            fieldSpec);
          self._keyWiseFieldsMap[layoutObject.key].ux.attributes = angular.extend({}, self._keyWiseFieldsMap[layoutObject.key].ux.attributes, fieldAttributes);
          self._keyWiseFieldsMap[layoutObject.key].ux.layout = angular.extend({}, self._keyWiseFieldsMap[layoutObject.key].ux.layout, layoutObject);
          MappedFormHelper.updateField(self._keyWiseFieldsMap[layoutObject.key]);
          MappedFormHelper.applyLayoutToField(self._keyWiseFieldsMap[layoutObject.key].mappedField, layoutObject);
          break;
        }

        case 'row': {
          angular.forEach(layoutObject.items, function (childLayoutObject) {
            childLayoutObject.parentLength = layoutObject.items.length;
            _updateFieldsWithLayout(childLayoutObject);
          });
          break;
        }

        case 'block': {
          angular.forEach(layoutObject.items, function (childLayoutObject) {
            _updateFieldsWithLayout(childLayoutObject);
          });
          break;
        }

        default:
          console.error(
            'Unknown layout object type found!');
      }
    }

    function updateForm(userInputDiscoverySpec) {
      //ToDO ; accordingly backend needs to be changes to so that augment method in controller
      // will receive full structured userInputDiscoverySpec
      if (userInputDiscoverySpec) {
        self.userInputDiscoverySpec = userInputDiscoverySpec;
      }
      // angular.merge(self.userInputDiscoverySpec, userInputDiscoverySpec);
      if (!!self.userInputDiscoverySpec.ux.layout && self.userInputDiscoverySpec.ux.layout.length > 0) {
        angular.forEach(self.userInputDiscoverySpec.ux.layout, function (layoutObject) {
          _updateFieldsWithLayout(layoutObject);
        });
      } else {
        //  if layout info is missing

        angular.forEach(self.userInputDiscoverySpec.fields, function (fieldSpec, fieldKey) {
          var fieldAttributes = self.userInputDiscoverySpec.ux.attributes[fieldKey];

          self._keyWiseFieldsMap[fieldKey].jsonSpec = angular.extend({}, self._keyWiseFieldsMap[fieldKey].jsonSpec,
            self.userInputDiscoverySpec.fields[fieldKey]);
          self._keyWiseFieldsMap[fieldKey].ux.attributes = angular.extend({}, self._keyWiseFieldsMap[fieldKey].ux.attributes, fieldAttributes);
          MappedFormHelper.updateField(self._keyWiseFieldsMap[fieldKey]);


        });


        // for (var i = 0; i < self.userInputDiscoverySpec.fields.length; i++) {
        //   var fieldKey = self.userInputDiscoverySpec.fields[i].key;
        //   var fieldAttributes = self.userInputDiscoverySpec.ux.attributes[fieldKey];
        //   self._keyWiseFieldsMap[fieldKey].jsonSpec = angular.extend({}, self._keyWiseFieldsMap[fieldKey].jsonSpec,
        //     self.userInputDiscoverySpec.fields[i]);
        //   self._keyWiseFieldsMap[fieldKey].ux.attributes = angular.extend({}, self._keyWiseFieldsMap[fieldKey].ux.attributes, fieldAttributes);
        //   MappedFormHelper.updateField(self._keyWiseFieldsMap[fieldKey]);
        // }

      }
    }

    function _addField() {

    }

    function _removeField() {

    }


  }
}
