/*jslint node: true */
"use strict";

let notificationHint = {
  element: ".nav-global .notifications",
  id: 'notification',
  customClass: 'notification-tip'
};

export let hintsCfg = {
  'landing': [
    {
      element: ".upload-items",
      id: 'upload',
      hintPosition: 'middle-middle'
    },
    {
      element: ".is-first-landing-item",
      id: 'dataset',
      hintPosition: 'bottom-left'
    },
    {
      element: ".preview-panel .tooltip-helper-class-open-data-view-button",
      id: 'openDataViewButton',
      hintPosition: 'bottom-middle'
    },
    {
      element: ".app-helper-class-empty-landing",
      id: 'emptyLanding'
    },
    // notificationHint,
    // {
    //   element: ".app-helper-class-landing-sort",
    //   id: 'landingDsSort'
    // },
  ],
  'dataview': [
    {
      id: 'exportButton',
      element: '.active-dataview .export-view-data .explore-btn'
    },
    {
      id: 'addTaskButton',
      element: '.active-dataview .steps-btn-group .btn.task-button'
    },
    {
      id: 'exploreButton',
      hintPosition: 'left',
      element: '.active-dataview .steps-btn-group .btn.explore-button',
    },
    // {
    //   id: 'colSummary',
    //   element: '.explore-card.first-card .content'
    // },
    {
      id: 'pipeline',
      element: '.active-dataview .steps-btn-group .toggle-steps'
    },
    {
      element: '.app-helper-class-add-view',
      id: 'addView'
    },
    {
      element: ".elements-open a.add-element",
      id: 'elementsPlus'
    },
    {
      element: '.active-dataview .non-metric-panel.toggle-element-panel',
      id: 'elementsPanelSizeAdjust'
    },
    {
      element: '.active-dataview .metric-only-panel.toggle-element-panel',
      id: 'metricsPaneltoggle'
    },
    {
      element: '.active-dataview .column-find',
      id: 'columnBrowse'
    },
    {
      element: '.active-dataview .dv-dataview .ds-grid .slick-header-column:nth-child(2) .slick-header-column-menubutton',
      id: 'columnHeader'
    },
    {
      element: '.dv-dataview.elements-panel-contracted .grid-panel li.sorted-by .edit-option',
      id: 'multiColumnSort'
    }
    // {
    //   element:'.app-helper-class-steps-panel',
    //   id: 'stepsPanel'
    // },
    // {
    //   element: '.active-dataview .highcharts-series-group',
    //   id: 'columnDrilldown'
    // },
  ]
};
