import * as Vue from "vue";
import * as Router from "vue-router";
import adminRoutes from "../admin/admin.routes.js";

const RouterConstructor = Router.default || Router
Vue.use(RouterConstructor);

const router = new RouterConstructor({
  routes: [adminRoutes].flat(),
});
export default router;
