import * as angular from 'angular';

/**
 * @ngInject
 * FileObject Factory
 */

FileObjectFactory.$inject = ['$resource', 'config', 'c', 'utils'];
export function FileObjectFactory($resource, config, c, utils) {
  var FileObjectResource = $resource(config.api.file, {id: '@id'});
  return FileObject;
  function FileObject(file_data) {
    var self = this, _prev_file_data;
    self.update = update;
    self.delete = del;

    update(file_data);
    var file_res = new FileObjectResource();
    file_res.id = self.id;

    ////////

    /**
     * Updates the local UDS instance with values from ds_data
     * @param file_data JSON representation of the FileObject ds
     */
    function update(file_data) {
      if (angular.equals(_prev_file_data, file_data)) {
        return false;
      }
      angular.extend(self, file_data);
      self.unique_id = self.type + '_' + self.id;
      self.shortName = utils.string.addCentreEllipsis(self.name);
      return true;
    }

    /**
     * Delete a FileObject ds
     */
    function del(success) {
      var ret = file_res.$delete({}, success);
      self.status = c.fileStatus.deleting;
      return ret;
    }

  }
}

