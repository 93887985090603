<template>
  <nav class="admin-sidebar-container">
    <h4>Admin</h4>
    <router-link to="/admin/workspaces">Workspaces</router-link>
    <router-link to="/admin/integrations">Integrations</router-link>
    <button @click="logout()">Sign Out</button>
  </nav>
</template>

<script>
import keycloak from '../../../mm-frontend/src/plugins/keycloak';

export default {
  methods:{
      logout(){
        this.$store.dispatch('auth/logout')
        this.$store.commit('reset')
        localStorage.setItem('authenticated', false)
        this.$axios.post('/blacklist_token', {token: keycloak.token})
        keycloak.logout()
      }
    },
};
</script>

<style scoped lang="scss">
.admin-sidebar-container {
  & > * {
    display: block;
    padding: 6px;
    margin-left: 10px;
    border: none;
    background: transparent;
  }

  a,
  button {
    color: #00866d;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}
</style>
