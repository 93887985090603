'use strict';
import * as angular from 'angular';
import * as _ from 'lodash-es';
import {config} from "../common/app.config";

var taskConfig = {},
  actionConfig = {},
  taskMenu,
  newTaskMenu,
  alertMenu,
  actionMenu,
  disableEdit,
  actionsNoLongCols,
  actionsWithoutForm,
  dbActions;

var tasks = {
  // table tasks
  filter: "filter",
  transpose: "transpose",
  sort: "sort",
  duplicates: "duplicates",
  limit: "limit",
  join: "join",
  pivot: "pivot",

  // column tasks
  addColumn: 'addColumn',
  delete: 'delete',
  clone: 'clone',
  combine: 'combine',
  split: 'split',
  convert: 'convert',
  insert: 'insert',
  copy: 'copy',
  bulkCopy: 'bulkCopy',
  lookup: 'lookup',
  fillValues: 'fillValues',
  // numeric
  math: 'math',
  running_total: 'running_total', //legacy
  rTotal: 'rTotal',
  rank: 'rank',
  window_function: 'window_function',
  json_handle: 'json_handle',
  smallOrLarge: 'small_or_large',
  // text
  extract_text: 'extract_text',
  format_text: 'format_text',
  // date
  increment_date: 'increment_date',
  date_diff: 'date_diff',
  extract_date: 'extract_date',
  replace: 'replace',
  bulkReplace: 'bulkReplace',
  unnest: 'unnest',
  collapseRows: 'collapseRows'
};
let disabledActionInfo = "Coming soon!";

var actions: any = {
  mysql: 'mysql',
  elasticsearch: 'elasticsearch',
  powerbi: 'powerbi',
  postgres: 'postgres',
  s3: 's3',
  mssql: 'mssql',
  redshift: 'redshift',
  marketo: 'marketo',
  tableau: 'tableau',
  salesforce: 'salesforce',
  dropbox: 'dropbox',
  chrome: 'chrome',
  box: 'box',
  saveasdataset: 'saveasdataset',
  internal_dataset: 'internal_dataset',
  branchouttoview: 'branchouttoview',
  pivot_table: 'pivot_table',
  email: 'email',
  bigquery: 'bigquery',
  sftp: 'sftp',
  publishdb: 'publishdb',
  publish_report_prompt: 'publish_report_prompt',
  export_to_project: 'export_to_project'
};

var alerts = {
  dataset: 'dataset',
  alert: 'alert',
}


// table tasks
taskConfig[tasks.filter] = {
  name: tasks.filter,
  template: config.templates.select,
  label: 'Apply Filter',
  state: '.Filter',
  manager: 'selectManagerFactory',
  list_title: 'Apply Filter',
  info: {
    content: 'Filter the data by applying a condition', //'Filters rows by the given condition',
    tags: ['apply', 'filter', 'condition' ,'filter condition','is equal to','is not equal to','less than','less than or equal to','greater than',
    'greater than or equal to','is the maximum value','is not the maximum value','is the minimum value','is not the minimum value',
    'has empty value','does not have empty value','in between','is equal to','is in list','is not equal to','is not in list','contains','does not contain',
     'starts with','ends with','does not start with','does not end with','has empty values','does not have empty values']
  }
};

taskConfig[tasks.transpose] = {
  name: tasks.transpose,
  label: 'Transpose',
  info: {
    content: "Coming soon! Flip the axis and convert the first column values into column headers",
    tags: ['transpose', 'pivot', 'transpose pivot']
  }
};
taskConfig[tasks.sort] = {name: tasks.sort, label: 'Sort'};
taskConfig[tasks.duplicates] = {
  name: tasks.duplicates,
  template: config.templates.removeDupliactes,
  label: 'Remove Duplicate Rows',
  state: '.RemoveDuplicates',
  manager: 'removeDuplicatesManager',
  list_title: 'Remove Duplicate Rows',
  info: {
    content: 'For visible columns, any duplicate rows found are removed',
    tags: ['remove','duplicates', 'deduplicate', 'discard duplicates', 'discard', 'redundant', 'recurring', 'rows', 'delete', 'consolidat','consolidate','remove duplicate rows']
  }
};
taskConfig[tasks.limit] = {
  name: tasks.limit,
  template: config.templates.limit,
  label: 'Show Top/Bottom Rows',
  state: '.Limit',
  manager: 'limitManagerFactory',
  list_title: 'Show Top/Bottom Rows',
  info: {
    content: 'Show the Top or Bottom N number of rows displayed in the grid',
    tags: ['show', 'top', 'bottom','five', 'ten', 'twenty', 'hundred', '5' ,'10', '20', '100', 'consolidat','consolidate','show top/bottom rows']
  }
};

taskConfig[tasks.replace] = {
  name: tasks.replace,
  label: 'Find And Replace',
  manager: 'replaceManagerFactory',
  template: config.templates.replace,
  list_title: 'Find and Replace',
  info: {
    content: 'Find and Replace any text value across the grid',
    tags: ['search', 'find', 'replace', 'simple','find and replace']
  }
};

taskConfig[tasks.bulkReplace] = {
  name: tasks.bulkReplace,
  label: 'Bulk Replace',
  manager: 'replaceManagerFactory',
  template: config.templates.replace,
  list_title: 'Bulk Replace',
  info: {
    content: 'Replace multiple text values with a single value',
    tags: ['search', 'replace', 'fuzzy',' bulk', 'find',' group','cluster','find and replace']
  },
  defaultParams: {
    REPLACE: {
      SOURCE: [],
      MAPPING: [{
        REPLACE_VALUE: null,
        SEARCH_VALUE: []
      }],
      MATCH_CASE: true,
      MATCH_WORDS: true
    },
    _UI_CUSTOM: 'groupReplace'
  }
};

taskConfig[tasks.join] = {
  name: tasks.join,
  label: 'Join',
  manager: 'joinManagerFactory',
  template: config.templates.join,
  list_title: 'Join',
  info: {
    content: 'Merge other columns from another table into this table.',
    tags: ['join','left','right','inner','outer','lookup','merge','merge and branch out','merge & branch out']
  }
};

taskConfig[tasks.pivot] = {
  name: tasks.pivot,
  template: config.templates.pivot,
  label: 'Group & Aggregate',
  state: '.Pivot',
  manager: 'pivotManagerFactory',
  list_title: 'Group & Aggregate',
  info: {
    content: 'Consolidate duplicate occurrences into aggregated single entries',
    tags: ['group','table','aggregate','sum','avg','summary','average','grouping','consolidated','pivot',
      'reshape, group & aggregate','reshape, group and aggregate','group and aggregate']
  }
};


// column tasks
taskConfig[tasks.insert] = {
  name: tasks.insert,
  label: 'Label & Insert Value',
  template: config.templates.insert,
  state: '.Insert',
  manager: 'insertManagerFactory',
  list_title: 'Label & Insert Value',
  info: {
    content: 'Create custom labels for each row based on defined conditions',
    tags: ['insert','create','custom','value','annotate','annotation','label','label & insert text']
  }
};
taskConfig[tasks.addColumn] = {
  name: tasks.addColumn,
  template: config.templates.addColumn,
  label: 'New Column',
  state: '.AddColumn',
  manager: 'addColumnManagerFactory',
  list_title: 'New column',
  info: {
    content: 'Create a new column',
    tags: ['add','column','functions','column function','new column', 'add column']
  }
};
taskConfig[tasks.convert] = {
  name: tasks.convert,
  template: config.templates.convert,
  label: 'Convert Column Type',
  state: '.ConvertColumn',
  manager: 'convertManagerFactory',
  list_title: 'Convert column type',
  info: {
    content: 'Change the type of column into text, numeric or date',
    tags: ['convert','column','functions','column function','change type','convert column type']
  }
};

taskConfig[tasks.lookup] = {
  name: tasks.lookup,
  label: 'Lookup',
  template: config.templates.lookup,
  manager: 'lookupManagerFactory',
  list_title: 'Lookup',
  info: {
    content: 'Add a new column to this grid from another Dataset. Similar to a VLookup in MS Excel',
    tags: ['lookup','link','foreign keys','vlookup','join','branchout','merge','merge and branch out','merge & branch out']
  }
};

taskConfig[tasks.delete] = {
  name: tasks.delete,
  template: config.templates.deleteColumn,
  label: 'Remove Column',
  state: '.DeleteColumn',
  manager: 'deleteColumnManagerFactory',
  list_title: 'Remove Column',
  info: {
    content: 'Delete one or more columns. Unlike hiding columns, this action tranforms the table and adds a task in the pipeline.',
    tags: ['delete','remove','column','functions','column function']
  }
};

taskConfig[tasks.split] = {
  name: tasks.split,
  template: config.templates.columnSplit,
  label: 'Split Into Multiple Columns',
  state: '.Split',
  manager: 'splitManagerFactory',
  list_title: 'Split into multiple columns',
  info: {
    content: "Split the values in a single column into multiple columns by defining a delimiter. Similar to MS Excel's text to column feature.",
    tags: ['split','divide','multiple','column','functions','texttocolumns','column function','split into multiple columns']
  }
};

taskConfig[tasks.copy] = {
  name: tasks.copy,
  template: config.templates.copy,
  label: 'Duplicate Column / Copy Column',
  state: '.Copy',
  manager: 'copyManagerFactory',
  list_title: 'Duplicate Column / Copy Column',
  info: {
    content: 'Copy an existing column',
    tags: ['copy','duplicate','column','functions','clone','column function','copy values','copy column values','copy column']
  }
};

taskConfig[tasks.bulkCopy] = {
  name: tasks.bulkCopy,
  template: config.templates.bulkCopy,
  label: 'Copy Multiple Columns',
  state: '.BulkCopy',
  manager: 'bulkCopyManagerFactory',
  list_title: 'Copy Multiple Columns',
  info: {
    content: 'Copy existing columns',
    tags: ['copy','duplicate','column','functions','clone','column function','copy values','copy column values','copy column','bulk']
  }
};

taskConfig[tasks.combine] = {
  name: tasks.combine,
  template: config.templates.combine,
  state: '.Combine',
  manager: 'combineManagerFactory',
  label: 'Combine multiple columns',
  list_title: 'Combine',
  info: {
    content: 'Merge multiple columns into a single column. The resulting column will be of type text',
    tags: ['combine','merge','join','column','functions','column function','multiple','combine multiple columns']
  }
};

taskConfig[tasks.fillValues] = {
  name: tasks.fillValues,
  label: 'Fill Missing Values',
  template: config.templates.fillValues,
  state: '.FillValues',
  manager: 'fillValuesManagerFactory',
  list_title: 'Fill Missing Values',
  info: {
    content: 'Fill null or empty values of a column with value above or below the missing value',
    tags: ['fill','missing','values','column','functions','column function','empty values','fill empty','fill missing values','fill missing']
  }
};

// numeric
taskConfig[tasks.math] = {
  name: tasks.math,
  label: 'Perform Math Function',
  manager: 'mathManagerFactory',
  template: config.templates.math,
  list_title: 'Perform math function',
  info: {
    content: 'Perform basic mathematical functions',
    tags: ['math', 'addition', 'add', 'plus', 'subtraction','subtract', 'minus', 'multiple', 'times','divide','numeric','functions','number','average','avg','max','min','numeric function','perform math function']
  }
};


taskConfig[tasks.running_total] = {
  name: tasks.running_total,
  label: 'Running Total',
  manager: 'runningTotalManagerFactory',
  template: config.templates.runningTotal,
  list_title: 'Running Total',
  info: {
    content: 'Calculate the running total of a column',
    tags: ['calculate','running total', 'sum']
  }
};


taskConfig[tasks.window_function] = {
  name: tasks.window_function,
  label: 'Window function',
  manager: 'windowFunctionManagerFactory',
  template: config.templates.windowFunction,
  list_title: 'Window function',
  info: {
    content: 'Calculate various mathematical and statistical functions over the entire data or over partitions',
    tags: ['calculate','compute', 'running', 'total' ,'sum', 'window', 'avg', 'min',
    'max','lead', 'lag', 'dense rank', 'cumulative' ,'std dev','standard deviation','count','row number','rank','dense rank','percent rank','cumulative percent rank',
      'first value','nth value','dense',
    'ntile' ,'row number percentile', 'first', 'last value', 'nth', 'stddev' ,'variance' ,'numeric' ,'functions' ,'math', 'plus' ,'minus', 'divide' ,'average','numeric function']
  }
};

taskConfig[tasks.rTotal] = {
  name: tasks.rTotal,
  label: 'Running Total',
  manager: 'windowFunctionManagerFactory',
  template: config.templates.windowFunction,
  list_title: 'Running Total',
  info: {
    content: 'Create a running total of a specific column for the entire table or by a partition',
    tags: ['calculate', 'running total' ,'sum']
  },
  defaultParams: {
    WINDOW: {
      EVALUATE: {
        FUNCTION: "SUM",
        ARGUMENTS: [null]
      }
    }
  }
};
taskConfig[tasks.rank] = {
  name: tasks.rank,
  label: 'Rank',
  manager: 'windowFunctionManagerFactory',
  template: config.templates.windowFunction,
  list_title: 'Rank',
  info: {
    content: 'Create a ranking of a specific column for the entire table or by a partition',
    tags: ['rank']
  },
  defaultParams: {
    WINDOW: {
      EVALUATE: {
        FUNCTION: "SUM",
        ARGUMENTS: [null]
      }
    }
  }
};

// Text
taskConfig[tasks.extract_text] = {
  name: tasks.extract_text,
  label: 'Extract Text',
  manager: 'extractTextManagerFactory',
  template: config.templates.extractText,
  list_title: 'Extract text',
  info: {
    content: 'Extract a substring of the cell value',
    tags: ['extract', 'wildcard', 'substring', 'text', 'txt', 'functions', 'text function', 'extract text', 'regex', 'regular', 'expression', 'pattern']
  }
};
taskConfig[tasks.format_text] = {
  name: tasks.format_text,
  label: 'Text Formatting',
  manager: 'formatTextManagerFactory',
  template: config.templates.formatText,
  list_title: 'Text formatting',
  info: {
    content: 'Remove extra whitespace, change text to UPPERCASE, lowercase or Capitalized.',
    tags: ['format','text', 'uppercase','lowercase', 'capitalized', 'capital', 'camel', 'txt', 'functions','text function','text formatting']
  }
};

// date
taskConfig[tasks.increment_date] = {
  name: tasks.increment_date,
  label: 'Increment/Decrement Date Values',
  manager: 'incrementDateManagerFactory',
  template: config.templates.incrementDate,
  list_title: 'Increment/decrement date values',
  info: {
    content: 'Add or subtract date values from an existing date column',
    tags: ['addition', 'subtraction', 'plus', 'minus', 'date', 'increment', 'decrement', 'functions','date function','increment/decrement date values']
  }
};

taskConfig[tasks.date_diff] = {
  name: tasks.date_diff,
  label: 'Calculate Date Difference',
  manager: 'dateDiffManagerFactory',
  template: config.templates.dateDiffRule,
  list_title: 'Calculate Date Difference',
  info: {
    content: 'Determine the difference between two date columns in days, minutes, seconds, etc.',
    tags: ['difference', 'subtraction', 'plus', 'minus','date', 'functions', 'datedif','date function','calculate date difference']
  }
};

taskConfig[tasks.extract_date] = {
  name: tasks.extract_date,
  label: 'Extract Date Part',
  manager: 'extractDateManagerFactory',
  template: config.templates.extractDate,
  list_title: 'Extract date part',
  info: {
    content: 'Extract a specific part of a date',
    tags: ['extract', 'date', 'convert', 'functions','date function','extract date part']
  }
};


taskConfig[tasks.unnest] = {
  name: tasks.unnest,
  label: 'Columns To Rows',
  manager: 'unnestDataManagerFactory',
  template: config.templates.unnest,
  list_title: 'Columns to rows',
  info: {
    content: 'Reshape your data by converting the value of columns into rows',
    tags: ['reshape','unnest', 'data', 'columns', 'rows', 'data', 'aggregate', 'pivot','reshape, group & aggregate','reshape, group and aggregate']
  }
};

taskConfig[tasks.smallOrLarge] = {
  name: tasks.smallOrLarge,
  label: 'Obtain Large Or Small Values',
  manager: 'smallOrLargeManagerFactory',
  template: config.templates.smallOrLarge,
  list_title: 'Obtain large or small values',
  info: {
    content: 'Find the Nth Smallest or Largest value across multiple columns',
    tags: ['small', 'large', 'values', 'numeric', 'functions','numeric function','smallest or largest','smallest','largest','obtain small or large values']
  }
};


taskConfig[tasks.json_handle] = {
  name: tasks.json_handle,
  label: 'Extract JSON',
  manager: 'JsonExtractManagerFactory',
  template: config.templates.json,
  list_title: 'Extract JSON',
  info: {
    content: 'Convert the key-value pairs from a JSON structure into a two dimensional table ',
    tags: ['json', 'key', 'extract', 'flatten','flat', 'list', 'array', 'value','extract json']
  }
};

taskConfig[tasks.collapseRows] = {
  name: tasks.collapseRows,
  label: 'Concatenate Values',
  manager: 'collapseRowsManagerFactory',
  template: config.templates.collapseRows,
  list_title: 'Concatenate Values',
  info: {
    content: 'Concatenate rows of a column based on group by columns',
    tags: ['collapse', 'aggregate', 'collapse text', 'concatenate']
  }
};

actionConfig[actions.email] = {
  type: actions.email,
  name: 'Send Via Email',
  label: 'Via Email',
  template: config.templates.email,
  manager: 'emailActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Send data from this view via an email',
    tags: ['send via email' ,'mail']
  }
};


actionConfig[actions.s3] = {
  type: actions.s3,
  name: 'Live Link',
  label: 'Live link (CSV)',
  template: null,
  manager: 's3ActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Export data from this view into a CSV file and generate a link',
    tags: ['CSV','download', 'export', 'email']
  }
};

actionConfig[actions.alert] = {
  type: actions.alert,
  name: 'Send Alert',
  label: 'Alert',
  template: 'app/components/dataview/actions/alert.tpl.html',
  manager: 'alertActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Send an alert regarding data from this view',
    tags: ['send alert','alert']
  }
};
actionConfig[actions.mysql] = {
  type: actions.mysql,
  name: 'MySQL',
  label: 'MySQL',
  template: config.templates.mysql,
  manager: 'mysqlActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Export data from this view into a MySQL database',
    tags: ['export', 'database', 'mysql', 'db', 'sql']
  }
};
actionConfig[actions.elasticsearch] = {
  type: actions.elasticsearch,
  name: 'Elasticsearch',
  label: 'Elasticsearch',
  template: config.templates.elasticsearch,
  manager: 'elasticsearchActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Export data from this view into a Elasticsearch database',
    tags: ['export', 'database', 'elasticsearch', 'db', 'sql']
  }
};
actionConfig[actions.powerbi] = {
  type: actions.powerbi,
  name: 'Power Bi',
  label: 'PowerBI',
  template: config.templates.powerbi,
  manager: 'powerbiActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Export data from this view into a Power Bi cloud service',
    tags: ['export', 'cloud', 'powerbi', 'db', 'sql']
  }
};

actionConfig[actions.postgres] = {
  type: actions.postgres,
  name: 'PostgreSQL',
  label: 'PostgreSQL',
  template: config.templates.postgres,
  manager: 'postgresqlActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Export data from this view into a PostgreSQL database',
    tags: ['export', 'database', 'postgres', 'db', 'sql']
  }
};

actionConfig[actions.publishdb] = {
  type: actions.publishdb,
  name: 'Export to database',
  label: 'Export to database',
  template: config.templates.publishDB,
  manager: 'publishDBManagerFactory',
  is_auth: false,
  info: {
    content: 'Export data from this view into a PostgreSQL, MySQL and MS SQL databases',
    tags: ['export', 'database', 'postgres', 'db', 'sql']
  }
};

actionConfig[actions.publish_report_prompt] = {
  type: actions.publish_report_prompt,
  name: 'Publish as a Report',
  label: 'Publish as a Report',
  template: config.templates.publishReportPrompt,
  manager: 'publishDBManagerFactory',
};


actionConfig[actions.mssql] = {
  type: actions.mssql,
  name: 'Sql Server',
  label: 'Sql Server',
  template: config.templates.mssql,
  manager: 'mssqlActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Export data from this view into a Sqlserver database',
    tags: ['export', 'database', 'sqlserver' ,'db', 'sql','sql server']
  }
};

actionConfig[actions.redshift] = {
  type: actions.redshift,
  name: 'AWS Redshift',
  label: 'AWS Redshift',
  template: 'app/components/dataview/actions/redshift.tpl.html',
  manager: 'redshiftActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Export data from this view into a AWS Redshift database',
    tags: ['export', 'database', 'redshift', 'db', 'sql','aws redshift','aws']
  }
};

actionConfig[actions.bigquery] = {
  type: actions.bigquery,
  name: 'Google BigQuery',
  label: 'Google BigQuery',
  key: 'bigquery',
  template: config.templates.bigquery,
  manager: 'bigqueryActionManagerFactory',
  is_auth: true,
  info: {
    content: 'Export data from this view into a Google bigquery database',
    tags: ['export', 'database', 'bigquery', 'db', 'BigQuery', 'google bigquery','google']
  }
};

actionConfig[actions.dropbox] = {
  type: actions.dropbox,
  name: 'Dropbox',
  label: 'Dropbox',
  template: 'app/components/dataview/actions/dropbox.tpl.html',
  manager: 'dropboxActionManagerFactory',
  is_auth: false,
  info: {
    content: disabledActionInfo,
    tags: 'export database dropbox cloud'
  }
};


actionConfig[actions.internal_dataset] = {
  type: actions.internal_dataset,
  name: 'Branch Out To Dataset',
  label: 'Branch Out To Dataset',
  template: config.templates.saveasdataset,
  manager: 'internalDataSetFactory',
  is_auth: false,
  info: {
    content: 'Save this table into another Dataset',
    tags: ['branch', 'export', 'save to dataset', 'append' ,'blend', 'master','merge and branch out','merge & branch out','branch out to dataset']
  },
  defaultParams: {
    target_properties: {
      DS_NAME: 'Result Dataset'
    }
  }
};

actionConfig[actions.branchouttoview] = {
  type: actions.branchouttoview,
  name: 'branchtoview',
  label: 'Branch out to View',
  template: config.templates.saveasdataset,
  manager: 'internalDataSetFactory',
  is_auth: false,
  info: {
    content: 'Create another View in the same Dataset from this point onward. This requires at least one task in the pipeline of current View.',
    tags: ['branch export save to dataset append blend master']
  },
};
actionConfig[actions.export_to_project] = {
  type: actions.internal_dataset,
  name: 'Branch Out To Project',
  label: 'Branch Out To Project',
  template: config.templates.export_to_project,
  manager: 'internalDataSetFactory',
  is_auth: false,
  info: {
    content: 'Save this table into another Dataset in different Project',
    tags: ['branch', 'export', 'save to dataset', 'append' ,'blend', 'master','merge and branch out','merge & branch out','branch out to dataset']
  },
  defaultParams: {
    target_properties: {
      DS_NAME: 'Result Dataset',
      export_project: true
    }
  }
};

actionConfig[actions.pivot_table] = {
  type: actions.pivot_table,
  name: 'Pivot Table',
  label: 'Crosstab',
  template: config.templates.saveasdataset,
  manager: 'internalDataSetFactory',
  is_auth: false,
  info: {
    content: 'Compute a simple cross tabulation. Take cell values and place them into columns. The resulting cells are aggregate values of the new column',
    tags: ['pivot', 'table', 'summary', 'summarise', 'group', 'table', 'aggregate', 'sum', 'avg', 'summary', 'average', 'grouping', 'consolidated', 'crosstab','reshape, group & aggregate','reshape, group and aggregate']
  },
  defaultParams: {
    target_properties: {
      DS_NAME: 'Crosstab',
      TRANSFORM: {
        CROSSTAB: {
          ROWS: [],
          COLUMNS: [],
          SELECT: {
            FUNCTION: "COUNT",
            COLUMN: null
          }
        }
      }
    }
  }
};


actionConfig[actions.chrome] = {
  type: actions.chrome,
  name: 'Chrome',
  label: 'Chrome',
  template: 'app/components/dataview/actions/chrome.tpl.html',
  manager: 'chromeActionManagerFactory',
  is_auth: false,
  info: {
    content: disabledActionInfo,
    tags: ['export', 'database', 'chrome', 'cloud']
  }
};

actionConfig[actions.box] = {
  type: actions.box,
  name: 'Box',
  label: 'Box',
  template: 'app/components/dataview/actions/box.tpl.html',
  manager: 'boxActionManagerFactory',
  is_auth: false,
  info: {
    content: disabledActionInfo,
    tags: ['export', 'database', 'box', 'cloud']
  }
};

actionConfig[actions.marketo] = {
  type: actions.marketo,
  name: 'Marketo',
  label: 'Marketo',
  template: 'app/components/dataview/actions/marketo.tpl.html',
  manager: 'marketoActionManagerFactory',
  is_auth: false,
  info: {
    content: disabledActionInfo,
    tags: ['export', 'service', 'marketo']
  }
};

actionConfig[actions.tableau] = {
  type: actions.tableau,
  name: 'Tableau',
  label: 'Tableau',
  template: 'app/components/dataview/actions/tableau.tpl.html',
  manager: 'tableauActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Coming soon!',
    tags: ['export', 'service', 'tableau', 'db' ,'sql']
  }
};

actionConfig[actions.salesforce] = {
  type: actions.salesforce,
  name: 'Salesforce',
  label: 'Salesforce',
  template: 'app/components/dataview/actions/salesforce.tpl.html',
  manager: 'salesforceActionManagerFactory',
  is_auth: false,
  info: {
    content: disabledActionInfo,
    tags: ['export', 'service', 'salesforce']
  }
};

actionConfig[alerts.dataset] = {
  type: alerts.dataset,
  name: 'Save as a new dataset',
  label: 'Dataset',
  template: 'app/components/dataview/actions/dataset.tpl.html',
  manager: 'datasetActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Save the current dataset as Raw or Seen data',
    tags: ['save new dataset']
  }
};

actionConfig[alerts.alert] = {
  type: alerts.alert,
  name: 'Send Alert',
  label: 'Alert',
  template: 'app/components/dataview/actions/alert.tpl.html',
  manager: 'alertActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Send an alert regarding data from this view',
    tags: ['send alert']
  }
};

actionConfig[actions.sftp] = {
  type: actions.sftp,
  name: 'Export to SFTP',
  label: 'SFTP',
  template: config.templates.sftp,
  manager: 'sftpActionManagerFactory',
  is_auth: false,
  info: {
    content: 'Export data from this view to sftp',
    tags: ['export to sftp' ,'sftp']
  }
};

alertMenu = [
  {
    'label': 'Alerts',
    'items': [
      actionConfig[alerts.dataset],
      actionConfig[alerts.alert]
    ]
  }
];
actionMenu = [
  // {
    // 'label': 'Export',
    // 'subSections': [
      // {
      //   'label': 'Mammoth',
      //   'items': [
      //     actionConfig[actions.internal_dataset],
      //     actionConfig[actions.branchouttoview],
      //   ]
      // },
      {
        'label': 'Database',
        'items': [
          actionConfig[actions.mysql],
          actionConfig[actions.postgres],
          actionConfig[actions.elasticsearch],
          actionConfig[actions.redshift],
          // actionConfig[actions.powerbi],
          actionConfig[actions.mssql],
          actionConfig[actions.bigquery],
          actionConfig[actions.sftp]
        ]
      }
      // {
      //   'label': 'Cloud Drive',
      //   'items': [
      //     actionConfig[actions.dropbox],
      //     actionConfig[actions.chrome],
      //     actionConfig[actions.box],
      //   ]
      // }
      // {
      //   'label': 'Services',
      //   'items': [
      //     actionConfig[actions.marketo],
      //     actionConfig[actions.tableau],
      //     actionConfig[actions.salesforce]
      //   ]
      // }
    // ]
  // }
];

var tasksThatCanBeAppliedToFilteredRows = [
  tasks.replace, tasks.insert, tasks.copy, tasks.bulkCopy, tasks.combine,
  tasks.math, tasks.extract_text,
  tasks.format_text, tasks.increment_date
];

tasksThatCanBeAppliedToFilteredRows.forEach(function (task_name) {
  taskConfig[task_name].applicableToFilteredRows = true;
});

// THERE IS ALSO A NEW TASK MENU DEFINED BELOW
// THIS COULD BE OBSOLETE
// PLEASE CHECK
taskMenu = [
  {
    'label': 'Table',
    'items': [
      taskConfig[tasks.filter],
      taskConfig[tasks.replace],
      taskConfig[tasks.bulkReplace],
      // taskConfig[tasks.transpose],
      taskConfig[tasks.duplicates],
      taskConfig[tasks.limit],
      taskConfig[tasks.lookup],
      taskConfig[tasks.join],
      taskConfig[tasks.pivot],
      taskConfig[tasks.unnest],
      taskConfig[tasks.collapseRows],
      actionConfig[actions.pivot_table],
    ]
  },
  {
    'label': 'Column',
    'items': [
      taskConfig[tasks.addColumn],
      taskConfig[tasks.delete],
      taskConfig[tasks.insert],
      taskConfig[tasks.combine],
      taskConfig[tasks.split],
      taskConfig[tasks.copy],
      taskConfig[tasks.convert],
      taskConfig[tasks.fillValues]
    ]
  },
  {
    // 'label': 'Functions',
    'subSections': [
      {
        'label': 'NUMERIC COLUMN ',
        'items': [
          taskConfig[tasks.math],
          taskConfig[tasks.smallOrLarge],
          taskConfig[tasks.window_function]
        ]
      },
      {
        'label': 'TEXT COLUMN',
        'items': [
          taskConfig[tasks.extract_text],
          taskConfig[tasks.format_text],
          taskConfig[tasks.json_handle]
        ]
      },
      {
        'label': 'DATE COLUMN',
        'items': [
          taskConfig[tasks.increment_date],
          taskConfig[tasks.extract_date],
          taskConfig[tasks.date_diff]
        ]
      }
    ]
  }
];

// Spec
// newtask = {
//   "id": unique ID,
//   "op": operation name or template,
//   "opType": Task or action,
//   "subOps": Sub tasks or Sub actions
// }

newTaskMenu = [
  {
    "id": "filter",
    "op": taskConfig[tasks.filter],
    "opType": 'task',
    "subOps": []
  },
  {
    "id": "insert",
    "op": taskConfig[tasks.insert],
    "opType": 'task',
    "subOps": []
  },
  {
    "id": "json",
    "op": taskConfig[tasks.json_handle],
    "opType": 'task',
    "subOps": []
  },
  {
    "id": "FR",
    "op": "Replace",
    "opType": 'task',
    "subOps": [
      {
        "id": "replace",
        "op": taskConfig[tasks.replace],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "bulkReplace",
        "op": taskConfig[tasks.bulkReplace],
        "opType": 'task',
        "subOps": []
      }
    ]
  },
  {
    "id": "Column",
    "op": "Column Functions",
    "opType": 'task',
    "subOps": [
      {
        "id": "addColumn",
        "op": taskConfig[tasks.addColumn],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "copy",
        "op": taskConfig[tasks.copy],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "combine",
        "op": taskConfig[tasks.combine],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "split",
        "op": taskConfig[tasks.split],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "convert",
        "op": taskConfig[tasks.convert],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "delete",
        "op": taskConfig[tasks.delete],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "missingval",
        "op": taskConfig[tasks.fillValues],
        "opType": 'task',
        "subOps": []
      }
    ]
  },
  {
    "id": "NF",
    "op": "Numeric Functions",
    "opType": 'task',
    "subOps": [
      {
        "id": "math",
        "op": taskConfig[tasks.math],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "smallOrLarge",
        "op": taskConfig[tasks.smallOrLarge],
        "opType": 'task',
        "subOps": []
      },
      // {
      //   "id": "rank",
      //   "op": taskConfig[tasks.rank],
      //   "opType": 'task',
      //   "subOps": []
      // },
      // {
      //   "id": "runningtotal",
      //   "op": taskConfig[tasks.rTotal],
      //   "opType": 'task',
      //   "subOps": []
      // },
      {
        "id": "windowfunction",
        "op": taskConfig[tasks.window_function],
        "opType": 'task',
        "subOps": []
      }
    ]
  },
  {
    "id": "TF",
    "op": "Text Functions",
    "opType": 'task',
    "subOps": [
      {
        "id": "extract_text",
        "op": taskConfig[tasks.extract_text],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "textformat",
        "op": taskConfig[tasks.format_text],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "collapseRows",
        "op": taskConfig[tasks.collapseRows],
        "opType": 'task',
        "subOps": []
      }
    ]
  },
  {
    "id": "date",
    "op": "Date Functions",
    "opType": 'task',
    "subOps": [
      {
        "id": "increment_date",
        "op": taskConfig[tasks.increment_date],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "extract_date",
        "op": taskConfig[tasks.extract_date],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "date_diff",
        "op": taskConfig[tasks.date_diff],
        "opType": 'task',
        "subOps": []
      }
    ]
  },
  {
    "id": "rpa",
    "op": "Reshape, Group & Aggregate",
    "opType": 'task',
    "subOps": [
      {
        "id": "group",
        "op": taskConfig[tasks.pivot],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "unnest",
        "op": taskConfig[tasks.unnest],
        "opType": 'task',
        "subOps": []
      },

      // {
      //   "id": "transpose",
      //   "op": taskConfig[tasks.transpose],
      //   "opType": 'task',
      //   "subOps": []
      // },
      {
        "id": "crosstab",
        "op": actionConfig[actions.pivot_table],
        "opType": 'action',
        "subOps": []
      }
    ]
  },
  {
    "id": "consolidate",
    "op": "consolidate",
    "opType": 'task',
    "subOps": [
      {
        "id": "duplicate",
        "op": taskConfig[tasks.duplicates],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "limit",
        "op": taskConfig[tasks.limit],
        "opType": 'task',
        "subOps": []
      },
    ]
  },
  {
    "id": 'tools',
    "op": 'Merge & Branch out',
    "opType": 'task',
    "subOps": [
      {
        "id": "lookup",
        "op": taskConfig[tasks.lookup],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "join",
        "op": taskConfig[tasks.join],
        "opType": 'task',
        "subOps": []
      },
      {
        "id": "Savetodataset",
        "op": actionConfig[actions.internal_dataset],
        "opType": 'action',
        "subOps": []
      }
      // {
      //   "id": "branchtoview",
      //   "op": actionConfig[actions.branchouttoview],
      //   "opType": 'action',
      //   "subOps": []
      // }
    ]
  },
  {
    "id": "export",
    "op": "Export",
    "opType": 'action',
    "subOps": [
      // {
      //   "id": "email",
      //   "op": actionConfig[actions.email],
      //   "opType": 'action',
      //   "subOps": []
      // },
      // {
      //   "id": "external",
      //   "op": "Database",
      //   "opType": 'action',
      //   "subOps": [
          {
            "id": "MySQL",
            "op": actionConfig[actions.mysql],
            "opType": 'action',
            "subOps": []
          },
          {
            "id": "PostgreSQL",
            "op": actionConfig[actions.postgres],
            "opType": 'action',
            "subOps": []
          },
          {
            "id": "Elasticsearch",
            "op": actionConfig[actions.elasticsearch],
            "opType": 'action',
            "subOps": []
          },
          {
            "id": "Redshift",
            "op": actionConfig[actions.redshift],
            "opType": 'action',
            "subOps": []
          },
          {
            "id": "Sqlserver",
            "op": actionConfig[actions.mssql],
            "opType": 'action',
            "subOps": []
          },
          {
            "id": "Bigquery",
            "op": actionConfig[actions.bigquery],
            "opType": 'action',
            "subOps": []
          },
          {
            "id": "SFTP",
            "op": actionConfig[actions.sftp],
            "opType": 'action',
            "subOps": []
          }
          // {
          //   "id": "cloud",
          //   "op": actionConfig[actions.s3],
          //   "opType": 'action',
          //   "subOps": []
          // }
        // ]
      // }
      // {
      //   "id": "Visualisation",
      //   "op": "Visualisation",
      //   "opType": 'action',
      //   "subOps": [
      //     {
      //       "id": "PowerBI",
      //       "op": actionConfig[actions.powerbi],
      //       "opType": 'action',
      //       "subOps": []
      //     },
      //     {
      //       "id": "Tableau",
      //       "op": actionConfig[actions.tableau],
      //       "opType": 'action',
      //       "subOps": []
      //     }
      //   ]
      // }
      // {
      //   "id": "cloud",
      //   "op": "Cloud Drive",
      //   "opType": 'action',
      //   "subOps": [
      //     {
      //       "id": "Dropbox",
      //       "op": actionConfig[actions.dropbox],
      //       "opType": 'action',
      //       "subOps": []
      //     },
      //     {
      //       "id": "Chrome",
      //       "op": actionConfig[actions.chrome],
      //       "opType": 'action',
      //       "subOps": []
      //     },
      //     {
      //       "id": "Box",
      //       "op": actionConfig[actions.box],
      //       "opType": 'action',
      //       "subOps": []
      //     }
      //   ]
      // }
    ]
  }
];

var colMenuSeparator = { isSeparator: true };
// THIS CODE ALSO SEEMS OBSOLETE WITH NEW ACTION BAR AND GRID WORK
// PLEASE CHECK
var columnMenu = {
  'columnTypeTasks': {
    'COMMON': [
      angular.extend({}, taskConfig[tasks.filter], {'label': 'Apply Filter with this Column'}),
      angular.extend({}, taskConfig[tasks.copy], {'label': 'Duplicate Column / Copy Column'}),
      angular.extend({}, taskConfig[tasks.convert], {'label': 'Convert Column type'})
    ],
    'TEXT': [
      angular.extend({}, taskConfig[tasks.insert], {}),
      angular.extend({}, taskConfig[tasks.split], {'label': 'Split this Column'}),
      angular.extend({}, taskConfig[tasks.bulkReplace], {'label': 'Bulk Replace'}),
      taskConfig[tasks.extract_text],
      angular.extend({}, taskConfig[tasks.combine], {'label': 'Combine with another Column'})
    ],
    'NUMERIC': [
      angular.extend({}, taskConfig[tasks.insert], {}),
      angular.extend({}, taskConfig[tasks.math], {'label': 'Perform Math Function'}),
      angular.extend({}, taskConfig[tasks.rTotal], {'label': 'Calculate Running Total'}),
      angular.extend({}, taskConfig[tasks.rank], {'label': 'Ranking'})
    ],
    'DATE': [
      angular.extend({}, taskConfig[tasks.insert], {}),
      taskConfig[tasks.extract_date],
      angular.extend({}, taskConfig[tasks.increment_date], {'label': 'Increment / Decrement Date'})
    ]
  }
};

_.forEach(taskConfig, function(cfg: any, name){
  cfg.stepType = 'task';
  cfg.type = cfg.name;
});

_.forEach(actionConfig, function(cfg: any, name){
  cfg.stepType = 'action';
});

var sortConfig = {
  'template': config.templates.sort
};

var singleSortConfig = {
  'template': config.templates.singleSort
};

disableEdit = [actions.s3, actions.publishdb];
actionsWithoutForm = [actions.s3];
actionsNoLongCols = [actions.mysql, actions.postgres, actions.mssql, actions.elasticsearch, actions.redshift, actions.bigquery];
dbActions = [actions.mysql, actions.postgres, actions.mssql, actions.redshift, actions.bigquery];

export let dataviewConfig = {
  tasks: tasks,
  taskMenu: taskMenu,
  newTaskMenu: newTaskMenu,
  columnMenu: columnMenu,
  taskController: 'taskController',
  actionController: 'actionController',
  taskConfig: taskConfig,
  actions: actions,
  actionConfig: actionConfig,
  actionMenu: actionMenu,
  alertMenu: alertMenu,
  sortConfig: sortConfig,
  singleSortConfig: singleSortConfig,
  rowLimitForSampleWarning: 10* 100 * 1000,
  rowLimitForSample: 10000,
  disableEdit: disableEdit,
  actionsWithoutForm: actionsWithoutForm,
  actionsNoLongCols: actionsNoLongCols,
  dbActions: dbActions,

  get_manager_factory_from_task_name: function get_manager_factory_from_task_name (task_name) {
    return taskConfig[task_name].manager;
  },
  get_manager_factory_from_action_name: function get_manager_factory_from_action_name (action_name) {
    return actionConfig[action_name].manager;
  }
};

export default dataviewConfig;
