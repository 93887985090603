import * as angular from 'angular';

dataviewPreviewGrid.$inject = ['utils', 'gridFactory'];
export function dataviewPreviewGrid(utils, gridFactory) {
  return {
    link: function (scope, element, attr) {
      var options = {
        showColumnTypeIcons: true,
        rowHeight: 20,
        defaultColumnWidth: 100,
        enableColumnReorder: false
      };
      var colOptions = {
        resizable: false
      };
      var indexColumn = {"name": "#", "id": "index", "field": "index", "width": 30,  cssClass: "sequence-cell",
        resizable: false};
      var gridInstance;

      scope.$watch(attr['dataviewPreviewGrid'], function (newVal) {
        if (!newVal.data) {
          return;
        }
        var ws_preview = scope.pvm.ws_preview;
        var columns = [];
        utils.metadata.applyDisplayChanges(ws_preview.metadata, ws_preview.display_properties, columns, colOptions);
        angular.forEach(columns, function (col, i) {
          if (col.width) {
            columns[i].width = Math.floor(col.width * 0.67);
          }
        });
        columns.splice(0, 0, indexColumn);
        if (gridInstance) {
          gridInstance.destroy();
          element.empty();
        }
        gridInstance = new gridFactory(element, undefined, columns, options, ws_preview.data.data);
        gridInstance.init();
      }, true);
    }
  };
}

datasetInplaceRename.$inject = ['config'];

export function datasetInplaceRename(config) {
  return {
    templateUrl: config.templates.inplaceRename,
    controller: config.controllers.renameController,
    controllerAs: 'rvm',
    bindToController: true,
    scope: {
      datasource: '='
    }
  };
}