import * as angular from 'angular';
import * as $ from 'jquery';
import * as humanize from 'humanize';
import * as _ from 'lodash-es';


smallOrLargeManagerFactory.$inject = ['destinationColumnManagerFactory', 'c', 'utils', '$timeout'];
export function smallOrLargeManagerFactory(destinationColumnManagerFactory, c, utils, $timeout) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new SmallOrLargeManager(options);
  }

  function SmallOrLargeManager(options) {
    var self = this;
    var metadata = _.cloneDeep(options.metadata), taskUtils = options.taskUtils, dataview = options.context.dataview;
    self.getParam = getParam;
    self.handlePasteParams = handlePasteParams;
    self.setParam = setParam;
    self.validate = validate;
    self.sanitizeParam = sanitizeParam;
    self.toggleSource = toggleSource;
    self.selectAll = selectAll;
    self.findResults = findResults;
    self._postSourceChangeActions = _postSourceChangeActions;
    self.handleControlValueChange = handleControlValueChange;
    self.getSortedValues = getSortedValues;
    self._postSourceValuesToPreview = _postSourceValuesToPreview;
    self.recalculateValidity = recalculateValidity;
    self.isValid = false;
    self.metadata = metadata;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.internal_name_to_column_map = {};
    self.sources = [];
    self.index = 1;
    self.previewIndex = null;
    self.smallOrLarge = 'SMALL';
    self.availableIndeces = [];
    self.previewData = new Array(6);
    self.searchColumns = '';
    self.filteredColumns = [];
    self.allSelected = false;
    self.controlValue = 0;
    self.isEnabled = false;
    self.dataviewData = [];
    self.useControlValue = false;
    self.sequence_number = undefined;
    self.previewData = new Array(6);

    self.smallOrLargeOptions = [
      {internal: 'SMALL', display: 'smallest'},
      {internal: 'LARGE', display: 'largest'}
    ];

    self.columns = (function(){
      var columns = [];
      metadata.forEach(function(col){
        if(col.type == c.numeric){
          col.selected = false;
          columns.push(col);
          self.internal_name_to_column_map[col.internal_name] = col;
        }
      });
      return columns;
    })();

    self.destinationManager = destinationColumnManagerFactory.get_manager({
      metadata: metadata,
      allowedTypes: ['NUMERIC'],
      taskUtils: options.taskUtils,
      isDestinationFormatterVisible: true});

    if(options.context.hasOwnProperty('task') && options.context.task && options.context.task.hasOwnProperty('sequence')){
      self.sequence_number = options.context.task.sequence;
    }
    getWsData();


    function getWsData(){
      dataview.get_100_rows(self.sequence_number).then(function (data) {
        self.dataviewData = data;
        _postSourceValuesToPreview();
      });
    }

    function recalculateValidity(){
      self.isValid = _validateSources();
    }

    function findResults() {
      var results = [];
      results.push("Result");
      for (var j = 1; j < 4; j++) {
        var rowValues = [];
        for (var i = 0; i < self.previewData.length - 1; i++) {
          if (self.previewData[i] === undefined || self.previewData[i][0] === '...') {
            continue
          }
          // Do not add null values for evaludate nth smallest/largest
          if (self.previewData[i][j]){
            rowValues.push(self.previewData[i][j]);
          }
          getSortedValues(rowValues);
        }
        // Find nth smallest/largest among unique values
        const rowValueSet = new Set(rowValues)
        rowValues =  Array.from(rowValueSet.values())
        var result = null;
        if (self.index === undefined && self.sources.length > 1)
          self.index = self.sources.length;
        var index = self.index;

        if (index > 3) {
          index = 3;
        }
        if (self.smallOrLarge === 'SMALL')
          result = rowValues[index - 1];
        if (self.smallOrLarge === 'LARGE')
          result = rowValues[rowValues.length - index];
        results.push(result);
      }
      if (self.index > 3){
        self.previewIndex = humanize.ordinal(3);
      }
      else{
        self.previewIndex = humanize.ordinal(self.index);
      }
      self.previewData[5] = results;
    }

    function handleControlValueChange() {
      _postSourceValuesToPreview();
      recalculateValidity();
    }

    function getSortedValues(dataToSort) {
      dataToSort.sort(function (a, b) {
        return a - b
      });
    }

    function _postSourceValuesToPreview() {
      self.previewData = new Array(6);
      self.isEnabled = true;
      if(!self.dataviewData.length){
        return;
      }
      if (self.sources.length < 2 && self.useControlValue === false || self.sources.length < 1 && self.useControlValue === true) {
        self.index = 1;
        self.isEnabled = false;
        self.previewData = [["","","",""],["","","",""], undefined, undefined, undefined,["Result","","",""]];
        return;
      }

      for (var i = 0; i < self.sources.length && i < 3; i++) {
        var j = 0;
        var columnValues = [];
        let column = utils.metadata.get_column_by_internal_name(self.columns, self.sources[i].VALUE);
        columnValues.push(column.display_name);
        do {
          var rowValue = self.dataviewData[j][self.sources[i].VALUE];
          columnValues.push(rowValue);
          j++;
        }
        while (j < 3);
        self.previewData[i] = columnValues;
      }
      if (self.sources.length > 3){
        self.previewData[3] = ['...', , , , ];
      }
      if (self.useControlValue) {
        self.previewData[4] = ['Control value', self.controlValue, self.controlValue, self.controlValue];
      }
      else {
        self.controlValue = 0;
        self.previewData[4] = undefined;
      }
      findResults();
    }

    function _postSourceChangeActions() {
      var cols = [];
      self.availableIndeces = [];
      $.each(self.sources, function (i: number, item) {
        if (item.COLUMN) {
          cols.push(item.COLUMN);
        }
        self.availableIndeces.push({internal: i + 1, display: humanize.ordinal(i + 1)});
      });
      if (self.useControlValue)
        self.availableIndeces.push({internal: self.sources.length + 1, display: humanize.ordinal(self.sources.length + 1)});
      taskUtils.highlight.sources(cols);
      recalculateValidity();
    }

    function _validateSources(){
      return (self.sources.length > 1) || (self.sources.length === 1 && self.useControlValue && self.controlValue!==null)
    }

    function validate() {
      let isValid = _validateSources();
      if (self.destinationManager) {
        isValid = isValid && self.destinationManager.validate();
      }
      return isValid;
    }

    function toggleSource(item_type, item_value, isSelected){
      var item = {TYPE: item_type, VALUE: item_value};
      var column = utils.metadata.get_column_by_internal_name(metadata, item_value);
      var isInSources = false;
      var indexInSources;

      if(isSelected === true || isSelected === false){
        column.isSelected = isSelected;
      }
      else{
        isSelected = column.isSelected;
      }

      for (var i = 0; i < self.sources.length; i++) {
        if (self.sources[i].VALUE === item_value) {
          isInSources = true;
          indexInSources = i;
        }
      }
      if (isSelected === true && !isInSources) {
        self.sources.push(item);
      }
      if(isSelected === false && isInSources){
        self.sources.splice(indexInSources, 1);
      }

      self.sources = _.cloneDeep(self.sources);
      _postSourceChangeActions();
      _postSourceValuesToPreview();
    }

    function selectAll() {
      self.allSelected = !self.allSelected;
      $timeout(function () {
        if (self.allSelected) {
          self.filteredColumns.forEach(function (col) {
            col.isSelected = true;
            toggleSource("COLUMN", col.internal_name, col.display_name);
          });
        }
        else {
          self.filteredColumns.forEach(function (col) {
            col.isSelected = false;
            toggleSource("COLUMN", col.internal_name, col.display_name);
          });
        }
      });
    }

    function handlePasteParams(taskInfo){
      /** Update source and destination params with suitable replacement columns, based on display name*/
      var params = taskInfo.params;
      //Determine the rule is to find smallest or largest value
      if (params.hasOwnProperty('SMALL')){
        self.smallOrLarge = 'SMALL';
      }
      else if(params.hasOwnProperty('LARGE')){
        self.smallOrLarge = 'LARGE';
      }

      //Destination column
      if (params[self.smallOrLarge].hasOwnProperty('DESTINATION')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params[self.smallOrLarge], 'DESTINATION', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        //Update destination manager metadata
        self.destinationManager.metadata = self.metadata
        self.destinationManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(self.metadata)
      }

      //Selected columns
      for(const index in params[self.smallOrLarge]['VALUES']){
        if(params[self.smallOrLarge]['VALUES'][index]['TYPE'] == 'COLUMN'){
          utils.metadata.replaceMatchingColumnAndUpdateMetadata(params[self.smallOrLarge]['VALUES'][index], 'VALUE', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        }
      }
      return params;
    }

    function getParam() {
      var smallOrBigParam = {
        VALUES: self.sources,
        INDEX: self.index
      };
      if(self.useControlValue){
        smallOrBigParam.VALUES.push({TYPE: 'NUMBER', VALUE: self.controlValue})
      }
      var destination_param = self.destinationManager.getParam();
      if (destination_param.hasOwnProperty('AS') && options.context.inEditMode==true && options.context.task){
        utils.sanatizeParamForDuplicateCols(destination_param['AS'], 'INTERNAL_NAME', options.context.task)
      }
      angular.extend(smallOrBigParam, destination_param);

      var ret = {};
      ret[self.smallOrLarge] = smallOrBigParam;
      if (options.context.hasOwnProperty('sequence')){
        ret['SEQUENCE_NUMBER'] = options.context.sequence
      }
      return ret;
    }

    function sanitizeParam(param) {
      let indicesOfColNotAvailable = [];
      let indicesOfColWithTypeMismatch = [];
      _.forEach(param.VALUES, function (source_col) {
        if (source_col.TYPE == 'COLUMN') {
          let source_col_index = param.VALUES.indexOf(source_col);
          let col_info = _.filter(self.metadata, {'internal_name': source_col.VALUE});
          if (col_info.length == 0) {
            indicesOfColNotAvailable.push(source_col_index);
          } else if (col_info[0]['type'] != 'NUMERIC') {
            indicesOfColWithTypeMismatch.push(source_col_index);
          }
        }
      });
      param.VALUES = param.VALUES.filter(function(value,index){
        return indicesOfColNotAvailable.indexOf(index) == -1 && indicesOfColWithTypeMismatch.indexOf(index) == -1
      });
    }

    function setParam(param) {
      if (param.hasOwnProperty('SMALL')){
        self.smallOrLarge = 'SMALL';
      }
      else if(param.hasOwnProperty('LARGE')){
        self.smallOrLarge = 'LARGE';
      }
      else{
        throw 'Invalid Param';
      }
      self.sources = [];
      var largeOrSmallParam = param[self.smallOrLarge];
      // self.sanitizeParam(largeOrSmallParam);
      largeOrSmallParam.VALUES.forEach(function(v){
        if(v.TYPE == 'COLUMN'){
          var col_info = utils.metadata.get_column_by_internal_name(self.columns, v.VALUE)
          if (!col_info){
            col_info = utils.metadata.get_column_by_internal_name(self.metadata, v.VALUE)
            self.columns.push(col_info)
            self.internal_name_to_column_map[col_info.internal_name] = col_info
          }
          toggleSource(v.TYPE, v.VALUE, true);
        }
        else if(v.TYPE == 'NUMBER'){
          self.useControlValue = true;
          self.controlValue = v.VALUE;
          handleControlValueChange();
        }
      });
      self.index = largeOrSmallParam.INDEX;
      self.destinationManager.setParam(largeOrSmallParam);
      recalculateValidity()
    }
  }
}

export function valSmallLargeSelection() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, element, attributes, ctrl) {
      let lastKnownVal;
      ctrl.$validators.shouldBeValidReplacePairs = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue && scope.column.type != 'NUMERIC'){
         is_valid = false
        }
        if (modelValue && scope.column.hasOwnProperty('error')){
          is_valid = false
        }
        return is_valid
      };
    }
  };
}

/**
 * @ngInject
 * For validating if the small or large param is right
 */

shouldBeValidSmallLargeSelection.$inject = ['utils'];
export function shouldBeValidSmallLargeSelection(utils){
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, e, a, ctrl){
      ctrl.$validators.shouldBeValidSmallLargeSelection = function(mV, vV){
        return mV === true;
      }
    }
  }
}
