salesforceHelpers.$inject = ['Singularity', 'c', 'analyticsService', 'filterFilter'];

export function salesforceHelpers(Singularity, c, analyticsService, filterFilter) {
  return {
    get_sf_object_schema: get_sf_object_schema,
    onSearchChange: onSearchChange,
  };

  function onSearchChange(params, ele, scope, callback_fn){

    var sfObjects = scope.options.data.keyWiseFieldsMap.sf_objects.ux.attributes.items;
    scope.options.data.keyWiseFieldsMap.sf_objects.mappedField.templateOptions.options = sfObjects.filter(function (el) {
      return el.name.toLowerCase().includes(params.toLowerCase());
    });
  }

  function get_sf_object_schema(params, ele, scope, callback_fn) {
    analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.salesForce.selectObject,
      {
        eventOrigin: "thirdPartyModal.salesForce"
      });
    scope.options.data.keyWiseFieldsMap.sf_object_schema.mappedField.templateOptions.showLoader = true;
    scope.options.data.keyWiseFieldsMap.sf_object_schema.mappedField.templateOptions.options = [];
    delete scope.options.data.parentController.addDsConfigSpec.ux.attributes.sf_object_schema;

    Singularity.augmentDsConfigSpecParams(params, scope).then(success_callback);
    function success_callback() {
      if (callback_fn !== undefined && typeof callback_fn === "function") {
        callback_fn();
      }
      var sfObjects = scope.options.data.keyWiseFieldsMap.sf_objects.ux.attributes.items;
      scope.options.data.keyWiseFieldsMap.sf_objects.mappedField.templateOptions.options = sfObjects.filter(function (el) {
        return el.name.toLowerCase().includes(scope.model["search"].toLowerCase());
      });
      scope.options.data.keyWiseFieldsMap.sf_object_schema.mappedField.templateOptions.showLoader = false;
      scope.model.sf_object_schema = filterFilter(scope.options.data.keyWiseFieldsMap.sf_object_schema.mappedField.templateOptions.options, {selected: true}).map(function (opt) {
        return opt.value;
      });
    }
  }
}
