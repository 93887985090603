<template>
  <div v-if="computedViewId.properties.dataUpdatePending">
    <mm-tooltip :label="$t('data_library.views.check_dataflow')" :visible="computedShowDataflowStatus">
      <div
        class="mm-inconsistent-data-warning mm-rounded-borders p-x-3 d-flex"
        :class="{ 'cursor-pointer': computedShowDataflowStatus }"
        @click="toggleDataflowStatusModal"
      >
        <mm-icon style="min-width: 16px" class="m-r-2" name="warning_dark" color="warn800" />
        <span class="m-t-1">{{ $t('data_library.views.inconsistent_data') }}</span>
      </div>
    </mm-tooltip>

    <mm-modal v-model="showDataflowStatusModal" :title="$t('dataflow_status.title')" closable>
      <dataflow-status :is-visible="showDataflowStatusModal" :highlighted-view-id="highlightedViewId" />
    </mm-modal>
  </div>
</template>

<script>
// Components
import DataflowStatus from '@/components/dataflow-status/dataflow-status'

// Plugins
import { featuresVisibility } from '@/plugins/splitio'

//Constants
import { SPLITIO_FEATURE_FLAGS, USER_EVENTS } from '@/constants'

export default {
  name: 'inconsistent-data-warning',
  components: { DataflowStatus },
  props: {
    viewId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    showDataflowStatusModal: false,
    highlightedViewId: null
  }),
  computed: {
    computedViewId() {
      return this.$store.getters['resources/getViewByViewId'](this.viewId)
    },
    computedShowDataflowStatus() {
      return featuresVisibility[SPLITIO_FEATURE_FLAGS.DATAFLOW_STATUS]
    }
  },
  methods: {
    toggleDataflowStatusModal() {
      this.$userEvents.save(USER_EVENTS.DATA_EDITOR.FOOTER.WARNING_CLICK, { viewId: this.viewId })
      this.highlightedViewId = this.viewId
      if (this.computedShowDataflowStatus) this.showDataflowStatusModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
.mm-inconsistent-data-warning {
  background: var(--mm-color--a75);
  align-items: center;
  width: fit-content;
  height: 24px;
}
</style>
