import * as _ from 'lodash-es';

/**
 * @ngInject
 */
webhooks.$inject = ['$q', '$resource', 'config', 'DatasourceService'];
export function webhooks($q, $resource, config, DatasourceService){
  var WebHookResource = $resource(config.api.webhooks);
  var getUrl = window.location;
  var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
  baseUrl = baseUrl.substring(0, baseUrl.length - 1);
  var webhook_mode = undefined;

  return {
    create: create,
    getMode: getMode,
    getAbsUrl: getAbsUrl,
    list: list,
    editOrigins: editOrigins,
    editMode: editMode,
    toggleSecurityHeader: toggleSecurityHeader,
    remove: remove,
    processList: processList,
    markWebhookDatasetsAsDirty: markWebhookDatasetsAsDirty,
    updateChildData: updateChildData
  };

  function create(name, origins, isSecure, mode, destinationLabelResourceId?){
    let options = {
      name: name,
      origins: cleanOrigins(origins),
      is_secure: isSecure,
      mode: mode
    };
    if (destinationLabelResourceId) {
      options['label_resource_id'] = destinationLabelResourceId;
    }
    webhook_mode = mode;
    return WebHookResource.save({}, options).$promise;
  }

  //Get the current mode of the webhook dataset to display in preview panel
  function getMode(ds){
    return ds.additional_info.webhook.mode
  }

  function getAbsUrl(url){
    return baseUrl + config.apiUrl + url;
  }

  function list(){
    var deferred = $q.defer();

    WebHookResource.get().$promise.then(function(data){
      data.webhooks.forEach(function(w){
        w.absUrl = getAbsUrl(w.url);
      });
      deferred.resolve(data.webhooks);
    }, deferred.reject);

    return deferred.promise;
  }

  function updateChildData(ds){
    if (ds) {
      var webhookInfo = ds.webhookInfo;
      webhookInfo.unprocessed_count = 0;
      ds.webhookInfo.unprocessed_count = 0;
      return WebHookResource.save({webhookId: webhookInfo.id}, {}).$promise;
    }
  }

  function processList(webhookList){
    _.forEach(webhookList, function (webhookInfo) {
      markWebhookDatasetsAsDirty(webhookInfo);
    });
  }

  function markWebhookDatasetsAsDirty(webhookInfo) {
    var ds = DatasourceService.list[webhookInfo.ds_id];
    webhookInfo.ds = ds;
    if (ds) {
      ds.webhookInfo = webhookInfo;
    }
  }

  function editOrigins(id, origins){
    let patches = [{"op": "replace", "path": "origins", "value": cleanOrigins(origins)}];
    return WebHookResource.patch({webhookId: id}, {'patch': patches}).$promise;
  }

  function editMode(id, mode){
    let patches = [{"op": "replace", "path": "mode", "value": mode}];
    return WebHookResource.patch({webhookId: id}, {'patch': patches}).$promise;
  }

  function toggleSecurityHeader(id, value){
    let patches = [{"op": "replace", "path": "is_secure", "value": value}];
    return WebHookResource.patch({webhookId: id}, {'patch': patches}).$promise;
  }


  function remove(id){
    return WebHookResource.delete({webhookId: id}).$promise;
  }

}


function cleanOrigins(originsString){
  let origins  = originsString.trim().split(',');
  let allowed = [];
  origins.forEach(function(origin){
    origin = origin.trim(); // extra stuff remove
    if(origin.endsWith('/')){
      origin = origin.substring(0, origin.length - 1);
    }
    if(isValidURL(origin)){
      allowed.push(origin);
    }
  });
  if(allowed.length == 0){
    allowed = ['*']
  }

  return allowed.join(',');
}


function isValidURL(str) {
  let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  if(!pattern.test(str)) {
    return false;
  } else {
    return true;
  }
}
