/**
 * Created by ranjith on 17/11/18.
 */
import * as angular from "angular";
import * as _ from "lodash-es";
import {config} from "../../common/app.config";
import {constants} from "../../common/app.constants";


export class TargetDatasetChoices implements ng.IDirective {
  public templateUrl = config.templates.targetDs;
  public replace = true;
  public bindToController = true;
  public controller = 'TargetDatasetDestinationCtrl';
  public controllerAs = 'tm';
  public scope = {
    srcToDestinationColumnMapping: '=',
    targetDatasetId: '=',
    validationInfo: '=',
    metadata: '=',
    appendToExisting: '=',
    disableDsId: '=',
    dsName: '=',
    inEditMode: '=',
    exportToProject: '=',
  };

  public constructor() {}

  link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) => {};

  static factory(): ng.IDirectiveFactory {
    const directive = () => new TargetDatasetChoices();
    directive.$inject = [];
    return directive;
  }
}


export class TargetDatasetDestinationCtrl{
  public srcToDestinationColumnMapping;
  public targetDataset: any;
  public targetDatasetId: number;
  public metadata: any[];
  public targetMetadata: any[];
  public targetOptions: any[];
  public dsList = [];
  public search;
  public filteredColumns = [];
  public waitLoader=true;
  public srcIntNameToTypeMap = {};
  public targetDisplayNameToObj = {};
  public createColIdentifier = constants.saveAsDSCreateColIdentifier;
  public disableDsId: number;
  public runId: number = Math.random();
  public dsName: string;
  public inEditMode: any;
  public exportToProject: boolean;
  public $onInit: () => void;



  static $inject = ['DatasourceService', 'DataviewService'];
  public constructor(public DatasourceService, public DataviewService){
    this.$onInit = function (){
      this.init();
    }

  }

  public allowBranchOutToAnyDs() {
    return localStorage.getItem('enableBranchoutToAnyDs') == 'true';
  }

  public isEmptyObject(targetDatasetId){
    return !Number.isInteger(targetDatasetId)
  }
  public searchColumn(){
    //Filter column mappings based on search string
    if (this.search.length == 0){
      this.filteredColumns = _.cloneDeep(this.metadata);
      return;
    }
    this.filteredColumns = [];
    for (let i = 0; i<this.metadata.length; i++){
      if(this.metadata[i].display_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.filteredColumns.indexOf(this.metadata[i]) ==-1 ){
        this.filteredColumns.push(this.metadata[i]);
      }
    }
  }

  public selectAll(){
    /*
     * 1: Enable column mappings of all columns except those columns that are in error(e.g missing columns)
     * 2: Interpret the column mapping - this basically analyses the mapping and modifies it if required
     */
    let self = this;
    let createColIdentifier = this.createColIdentifier;
    self.metadata.forEach(function(column){
      let col_display_name = _.get(column,'old_display_name');
      if (!column.hasOwnProperty("error_reason") && !self.srcToDestinationColumnMapping.hasOwnProperty(col_display_name)){
        self.srcToDestinationColumnMapping[col_display_name] = _.get(column, 'display_name');
      }
    });
    this.interpretTargetColumnMapping();
  }

  public deselectAll(){
    /*
     * Disable the column mapping of all columns
     */
    this.srcToDestinationColumnMapping = {};
    this.interpretTargetColumnMapping();
  }

  private init(){
    let dsList = [];
    let targetDs = null;
    let targetDsId = this.targetDatasetId;
    let disableDsId = this.disableDsId;
    var self = this;
    this.DataviewService.on_update_metadata_hiddenColumns('updateMetadataHiddenColumnsInMapping',  function(hidden){
      self.handleDsChange(false);
    });
    _.forEach(this.DatasourceService.list, function(ds){
      // Allow any type dataset for branchout. This applies when allowBranchOutToAnyDs is true
      if(ds && (ds.additional_info && ds.additional_info.DATAVIEW_ID && ds.additional_info.TRIGGER_ID) || self.allowBranchOutToAnyDs()){
        if(ds.id != disableDsId){
          dsList.push(ds);
        }
      }
      if(ds.id == targetDsId){
        targetDs = ds;
      }
    });

    this.dsList = dsList;
    this.targetDataset = targetDs;
    let metadata = _.cloneDeep(this.metadata);
    this.srcIntNameToTypeMap = (() => {
      let srcIntNameToTypeMap = {}
      _.forEach(metadata, function (col) {
        srcIntNameToTypeMap[col.internal_name] = col.type;
      });
      return srcIntNameToTypeMap;
    })();

    if(!_.isPlainObject(this.srcToDestinationColumnMapping)){
      this.srcToDestinationColumnMapping = {};
    }
    try{
      this.handleDsChange(true);
    }
    catch (e){
      console.error(e);
    }
  }

  public sortColumns(){
    let sortedMapped=[];
    let sortedUnMapped=[];
    let missingColumns = [];
    let typemismatchColumns = [];
    let sorted=[];
    let colMap=this.srcToDestinationColumnMapping;
    let srcColumnsMapped = _.keys(colMap)
    _.forEach(colMap, function (destination, source) {
        let srcColInMetadata = _.filter(this.metadata, function(col) { return col['old_display_name']==source; })[0];
        let targetCol = this.targetDisplayNameToObj[destination]
        if (srcColInMetadata){
          //mapped column is available in metadata
          if(targetCol && srcColInMetadata['type']!==targetCol['type'] && targetCol['type']!= 'TEXT'){
            srcColInMetadata['has_error'] = true
            srcColInMetadata['error_reason'] = 'type mismatch'
            typemismatchColumns.push(srcColInMetadata)
          }
          else if((srcColInMetadata['error'] == 'not available' || srcColInMetadata['error_reason'] == 'Column missing')){
            // Column are marked as 'not available' if it is pulled from join/lookup and such rule is deleted.
            // These columns should be added to missing column list.
            srcColInMetadata['has_error'] = true
            missingColumns.push({
              'display_name': source, 'internal_name': source, 'old_display_name': source, 'has_error': true, 'error_reason': 'Column missing'
            });
          }
          else {
            srcColInMetadata['has_error'] = false
            sortedMapped.push(srcColInMetadata);
          }
        }
        else {
          if(!srcColInMetadata) {
            missingColumns.push({
              'display_name': source, 'internal_name': source, 'old_display_name': source, 'has_error': true, 'error_reason': 'Column missing'
            });
          }
        }
    }.bind(this))

    _.forEach(this.metadata, function (col) {
        if (srcColumnsMapped.indexOf(col['old_display_name']) == -1){
            col['has_error'] = false;
            sortedUnMapped.push(col);
        }
    })

    sortedMapped.sort(function (a,b) {
      return(a.old_display_name?.toLowerCase().charCodeAt(0) - b.old_display_name?.toLowerCase().charCodeAt(0));
    });

    sortedUnMapped.sort(function (a,b) {
      return(a.old_display_name?.toLowerCase().charCodeAt(0) - b.old_display_name?.toLowerCase().charCodeAt(0));
    });

    typemismatchColumns.sort(function (a,b) {
      return(a.old_display_name.toLowerCase().charCodeAt(0) - b.old_display_name.toLowerCase().charCodeAt(0));
    });

    for(let i=0;i<missingColumns.length;i++){
      sorted.push(missingColumns[i]);
    }

    for(let i=0;i<typemismatchColumns.length;i++){
      sorted.push(typemismatchColumns[i]);
    }

    for(let i=0;i<sortedMapped.length;i++){
      sorted.push(sortedMapped[i]);
    }
    for(let i=0;i<sortedUnMapped.length;i++){
      sorted.push(sortedUnMapped[i]);
    }

    this.filteredColumns = sorted;
    this.metadata = sorted;
    this.runId = Math.random();
    this.waitLoader=false;
  }

  public handleDsChange(editMode=false){
    if(!this.targetDataset){
      this.filteredColumns = _.cloneDeep(this.metadata);
      return;
    }
    this.targetDatasetId = this.targetDataset.id;
    if(this.targetDatasetId != this.disableDsId){
    this.dsName = this.targetDataset.name;
    }
    let self = this;
    // No need to make call to get target ds properties as its no data is passed in dataCallback
    // this.targetDataset.get().then(_dataCallback);
    _dataCallback()
    function _dataCallback(){
      self.targetMetadata = _.cloneDeep(self.targetDataset.metadata);
      let batch_cols_internal_names = Object.values(_.get(self.targetDataset,'batch_cols', {})).map(col => col['internal_name']);
      self.targetMetadata = self.targetMetadata.filter(col => batch_cols_internal_names.indexOf(col['internal_name']) == -1);
        self.targetOptions = [
        {
          'internal_name': '$CREATE$',
          'display_name': self.createColIdentifier,
          'allowedTypes': [constants.text, constants.numeric, constants.date]
        }
      ];
      /*
        'targetOptions' will contain only 'Create a new column' when new dataset is being created.
        To know if new dataset is being created, 'targetDatasetId == disableDSId'.
      */
      if (self.targetDatasetId && self.disableDsId && (self.targetDatasetId != self.disableDsId)){
        self.targetMetadata.forEach(function(column){
          self.targetOptions.push(column);
        });
      } else {
        /*
          In case of fresh branchout, targetMetadata is same as
          current metadata.
         */
        self.targetMetadata = _.cloneDeep(self.metadata);
      }


      let targetMetadata = self.targetMetadata;

      self.targetDisplayNameToObj = (() => {
        let targetDisplayNameToObj = {}
        _.forEach(targetMetadata, function (col) {
          targetDisplayNameToObj[col.display_name] = col;
        });
        return targetDisplayNameToObj;
      })();

      _.forEach(targetMetadata, function (col) {
        col.allowedTypes = [col.type]
      });

      if(!editMode && (self.targetDatasetId != self.disableDsId) ) {
        self.autoGenerateMapping();
      }
      else{
        self.interpretTargetColumnMapping();
      }
    }
   }


  public interpretTargetColumnMapping(){
    /*
     * Loops through srcToDestinationColumnMapping and does the following:
     * 1: If destination column is not found, maps it to `Create a new column``
     * 2: Calls sort function that sorts the column mapping
     */
    let self = this;
    let mapping = {};
    let createColIdentifier = this.createColIdentifier;
    let targetMetadata = this.targetMetadata;
    _.forEach(this.srcToDestinationColumnMapping, function (val, key) {
      let index = _.findIndex(targetMetadata, function(item) {
        return item['display_name'] == val;
      });
      if(index != -1){
        mapping[key] = val;
      }
      else{
        mapping[key] = createColIdentifier;
      }
    });
    this.srcToDestinationColumnMapping = mapping;
    this.sortColumns();
    // runId is set to a random number. This is required to trigger the directive columnMappingShouldBeValid
    this.runId = Math.random();
  }

  public toggleIgnore(column){
    /*
     * :param column: a column object
     *
     * The functions toggles the mapping for the given column
     *    - if mapping for the given column is found, delete that mapping
     *    - else if mapping was not found, then enables the mapping in the following manner
     *      - if a column is found in the target dataset that has simlimar display name and type, then add the mapping
     *      - otherwise map the column to `Create a new column`
     */
    let display_name = column.old_display_name;
    if(this.srcToDestinationColumnMapping.hasOwnProperty(display_name)){
      delete this.srcToDestinationColumnMapping[display_name];
    }
    else{
      let targetColumn = this.targetDisplayNameToObj[column.display_name];
      if(targetColumn && targetColumn.type == column.type){
        this.deleteMappingByValue(this.srcToDestinationColumnMapping, targetColumn.display_name);
        this.srcToDestinationColumnMapping[display_name] = targetColumn.display_name;
      }
      else {
        this.srcToDestinationColumnMapping[display_name] = this.createColIdentifier;
      }
    }
    this.runId = Math.random();
    // resets errors for the given column. Basically sets has_error to false
    this.resetErrors(display_name);
  }

  public resetErrors(src_display_name){
    let index = _.findIndex(this.filteredColumns, {old_display_name: src_display_name})
    if (index!=-1){
      this.filteredColumns[index]['has_error']=false;
    }

  }

  public deleteMappingByValue(mapping, value) {
    /*
     * :param mapping: the src to destination column mapping
     * :param value: the destination display_name that needs to be deleted from the mapping
     *
     * Disables the mapping in srcToDestinationColumnMapping based on the destination display_name
     */
    if(value != this.createColIdentifier) {
      _.forEach(mapping, function(val, key){
        if(mapping.hasOwnProperty(key) && mapping[key] == value) {
          delete mapping[key];
        }
      });
    }
  }

  public handleColumnMappingUpdate(src_display_name, dest_display_name){
    /*
     * This is used when a source column is mapping to different destination column i.e. user choses a different
     * column in the destination dropdown column list
     *
     * This fn does the following:
     * - deletes column mapping of dest_display_name so that it can be now mapped to new src_display_name
     * - adds mapping from src_display_name to dest_display_name
     *
     */
    let columnMapping = this.srcToDestinationColumnMapping;
    this.deleteMappingByValue(columnMapping, dest_display_name);
    columnMapping[src_display_name] = dest_display_name;
    this.runId = Math.random();
    this.resetErrors(src_display_name)
  }

  public autoGenerateMapping(){
    /*
     * this is triggered when destination dataset is changed
     * - This fn auto generates the column mapping for the changed dataset
     */
    let mapping = {};
    let targetDisplayNameToObj = this.targetDisplayNameToObj;
    _.forEach(this.metadata, function(srcColumn){
      let targetColumn = targetDisplayNameToObj[srcColumn.display_name];
      if(targetColumn){
        if(targetColumn.type == srcColumn.type){
          mapping[srcColumn.old_display_name] = targetColumn.display_name;
        }
      }
    });
    this.srcToDestinationColumnMapping = mapping;
    this.sortColumns();
    this.runId = Math.random();
  }
}

export function filterColumnsInTargetDs(){
  return function(columns, ctype){
    // here we should return any column with either of same as ctype or on TEXT type. we need this because user should be able to add data from
    // any type of column into text type column in target dataset. MVP-3270
    return columns.filter(function(x) {
      if (x. hasOwnProperty('allowedTypes') && (x.allowedTypes.indexOf(ctype) != -1 || x.type == constants.text)){
        return x;
      }
      else {
        // if type of column matches given ctype, return it too
        if (x.type == ctype)
          return x;
      }
    });
  }
}

columnMappingShouldBeValid.$inject = ['$timeout','$q'];
export function columnMappingShouldBeValid($timeout, $q){
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (s, e, a, ctrl){
      ctrl.$asyncValidators.columnMappingShouldBeValid = function(mV, vV){
        var deferred = $q.defer();

        $timeout(function () {
          let isValid = true;
          if (s.tm.targetDataset == null){
            isValid = false;
            deferred.reject();
            return
          }
          let targetMetadata;
          if ((s.tm.disableDsId && s.tm.targetDatasetId)
            && (s.tm.disableDsId == s.tm.targetDatasetId)) {
            /* if it is new branchout then target metadata
               is same as metadata of current ds.
            */
            targetMetadata = s.tm.metadata;
          } else {
            targetMetadata = s.tm.targetDataset.metadata;
          }

          let srcToDestinationColumnMapping = s.$eval(a.targetMapping);
          let metadata = s.$eval(a.metadata);

            // Mappings can't be valid if there is no mapping
          if (Object.keys(srcToDestinationColumnMapping).length == 0) {
            isValid = false;
            deferred.reject();
            return
          }

          if (_.isPlainObject(srcToDestinationColumnMapping) && targetMetadata !== undefined) {
            _.forEach(srcToDestinationColumnMapping, function (v, k) {
              if (v && k) {
                let target_col = _.filter(targetMetadata, function (col) {
                  return col['display_name'] == v;
                })[0];
                let src_col = _.filter(metadata, function (col) {
                  return col['old_display_name'] == k;
                })[0];
                if (!src_col || src_col['has_error'] && src_col['error_reason'] == 'Column missing') {
                  isValid = false;
                  // if target col is not available, we wont make the action invalid
                } else if (target_col && target_col['type'] && target_col['type'] != 'TEXT' && src_col['type'] != target_col['type']) {
                  isValid = false;
                }
              }
            });
          }

          if (isValid) {
            deferred.resolve();
          } else {
            deferred.reject();
          }
        }, 0);

        return deferred.promise;

      }
    }
  }
}
