import * as angular from 'angular';

/**
 * @ngInject
 * @returns {{get_manager: get_manager}}
 */
fillValuesManagerFactory.$inject = ['utils', 'filterUI', 'sortManagerFactory'];
export function fillValuesManagerFactory(utils, filterUI, sortManagerFactory) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new FillValuesManager(options);
  }

  function FillValuesManager(options) {
    var self = this;
    var metadata = options.metadata, taskUtils = options.taskUtils, dataview = options.context.dataview;
    self.getParam = getParam;
    self.validate = validate;
    self.handlePasteParams = handlePasteParams;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.context = options.context;
    self.select_column = select_column;
    self.highLightColumns = [];
    self.setParam = setParam;
    self.metadata = utils.sanitizeMetadataWithType(metadata);
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.column = null;
    self.orderBy = null;
    self.partition_by_col = null;
    self.show=false;
    self.functions = [
      {
        "name": "FIRST_VALUE",
        "value": "Above"
      },
      {
        "name": "LAST_VALUE",
        "value": "Below"
      },
    ];
    self.funName = self.functions[0]['name'];
    options.mandatory_sort = false;

    self.sortManager = sortManagerFactory.get_manager(options);

    function select_column(){
      if (self.column){
        taskUtils.highlight.sources(self.column);
      }
    }

    function validate() {
      var is_valid = true
      var sort_validation = self.sortManager.validate()
      is_valid = is_valid && sort_validation

      return is_valid;
    }
    function handlePasteParams(taskInfo){
      /** Update params with suitable replacement columns, based on display name*/

      var params = taskInfo.params;
      //Source Column
      utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.FILL, 'COLUMN', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      // SORT COLUMNS
      if (params.FILL.hasOwnProperty('ORDER_BY')){
        for(const index in params.FILL.ORDER_BY){
          if (params.FILL.ORDER_BY[index].length = 2){
            utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.FILL.ORDER_BY[index], 0, taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
            }
          }
      }
      //PARTITION COLUMN
      if (params.FILL.hasOwnProperty('PARTITION_BY')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.FILL, 'PARTITION_BY', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }
      return params
    }


    function getParam() {
      var fillValues_param = {
        COLUMN: self.column.internal_name,
        WITH: self.funName
      };
      var sortParams = self.sortManager.getParam();
      if(sortParams){
          fillValues_param['ORDER_BY'] = sortParams;
      }
      if (self.partition_by_col) {
        fillValues_param['PARTITION_BY'] = self.partition_by_col.internal_name;
      }

      var param: any = {
        FILL: fillValues_param
      };
      if (self.context.hasOwnProperty('sequence')){
        param['SEQUENCE_NUMBER'] = self.context.sequence
      }
      return param;
    }

    function setParam(param) {
      self.column = utils.metadata.get_column_by_internal_name(metadata, param.FILL.COLUMN);
      self.orderBy = param.FILL.ORDER_BY;
      self.sortManager.setParam(param.FILL.ORDER_BY);
      self.funName = param.FILL.WITH;
      self.partition_by_col = utils.metadata.get_column_by_internal_name(metadata, param.FILL.PARTITION_BY);
      taskUtils.highlight.sources(self.column);
    }
  }
}

export function valFillCol() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valFillCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valFillCol = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue && modelValue.hasOwnProperty('error')){
          is_valid =  false
        }
        return is_valid
      }
    }
  }
}


