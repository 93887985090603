// API
import globalApi from '@/api/global.api'

// store
import store from '@/plugins/store'

export async function initializeOnboarding() {
  const flags = await globalApi.getOnboardingFlags()
  store.commit('setOnboardingFlags', flags)
}

export async function toggleOnboardingFlag(flag, status = true) {
  store.commit('setOnboardingFlag', { flag, status })
  await globalApi.toggleOnboardingFlag(flag, status)
  localStorage.removeItem(`mmCache_user_${store.state.user.email}_/preferences`)
}
