import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';

taskController.$inject = ['$stateParams', 'DataviewService', 'TaskServiceFactory', 'dataviewConfig', 'TaskHelperServiceFactory',
                        'DataviewGlobalFilterService', '$injector','config',
                        '$timeout', 'resources', 'Notification', '$log', 'utils', '$q', '$window', 'c', '$resource'];
export function taskController($stateParams, DataviewService, TaskServiceFactory, dataviewConfig, TaskHelperServiceFactory,
                        DataviewGlobalFilterService, $injector, config,
                        $timeout, resources, Notification, $log, utils, $q, $window, c, $resource) {
  this.$onInit = function () {
    var tvm = this;
    tvm.config = config;
    tvm.create = create;
    tvm.paste = paste;
    tvm.edit = edit;
    tvm.submit = submit;
    tvm.destroy = destroy;
    var dataview_id = $stateParams.dataviewId;
    var TaskService;
    tvm.dataview = DataviewService.get_by_id(dataview_id);
    tvm.taskHelperService = TaskHelperServiceFactory.getByDataviewId(dataview_id);
    tvm.initController = _initController;
    tvm.isGlobalFilterApplied = false;
    tvm.openDocumentationInNewTab = openDocumentationInNewTab;
    tvm.taskExistsInAppHelp = false;
    tvm.task_display_name = undefined;
    tvm.load_pipeline_info = load_pipeline_info;
    tvm.load_pipeline_info();

    var initPromise = $q.defer();
    // _initController();
    var unsubscribeTaskPanelCloseEvent = tvm.task_panel.taskPanelCloseEvent.add_callback('closeTaskServiceHelper', function () {
      tvm.taskHelperService.destroyHelper();
      unsubscribeTaskPanelCloseEvent();
    });

    var globalFilter = DataviewGlobalFilterService.getByDataviewId(tvm.dataview.id);

    function load_pipeline_info() {
      let pipelineResource = $resource(config.api.pipelineInfo);
      pipelineResource.get({ws_id: tvm.dataview.id}).$promise.then(function (response) {
        tvm.dataview.taskwise_info = response['taskwise_info']
      });
    }
    function set_global_filter(task_name) {
      tvm.isGlobalFilterApplied = false;
      var taskCfg = dataviewConfig.taskConfig[task_name];
      if (taskCfg.applicableToFilteredRows) {
        if (globalFilter.getCondition()) {
          tvm.isGlobalFilterApplied = true;
        }
      }
    }

    function create(task_name, createParams) {
      tvm.dataview.get_data().then(_getDataCb);

      // ViewModel
      function _getDataCb() {
        set_global_filter(task_name);
        var context = {
          dataview: tvm.dataview,
          inEditMode: false
        }
        initTaskManager(task_name, context, undefined, undefined).then(function () {
          if (createParams !== undefined) {
            tvm.manager.setParam(createParams);
          }
          initPromise.resolve(tvm.manager);
        });

      }
    }

    function paste(task_name, taskParams) {
      set_global_filter(task_name)
      // Todo: add reason why setting inEditmode: true when a task pasted into pipeline and its manager inited
      var context = {
        dataview: tvm.dataview,
        inEditMode: true,
        sequence: taskParams['sequence']
      }
      initTaskManager(task_name, context, taskParams['sequence'], undefined).then(function () {
        var sanitizedParam = tvm.manager.handlePasteParams(taskParams);
        sanitizedParam['SEQUENCE_NUMBER'] = taskParams['sequence']
        tvm.manager.setParam(sanitizedParam);
        initPromise.resolve(tvm.manager);
      })

    }

    function _checkIfTaskExistsInAppHelp(taskName) {
      return !!(taskName in c.appHelp.tasks);
    }

    function openDocumentationInNewTab(taskName) {
      $window.open(c.appHelpRoot + c.appHelp.tasks[taskName], '_blank');
    }

    function edit(editing_task) {
      tvm.dataview = DataviewService.get_by_id(dataview_id);
      var task_name = editing_task.opname;
      var context = {
        dataview: tvm.dataview,
        task: editing_task,
        inEditMode: true,
        sequence: editing_task?.sequence
      }
      initTaskManager(task_name, context, editing_task.sequence, editing_task.reference_errors).then(function () {
        tvm.manager.setParam(editing_task.params);
        if (tvm.manager.addColumnInputArray) {
          for (var i = 0; i < tvm.manager.addColumnInputArray.length; i++) {
            tvm.manager.addColumnInputArray[i].COLUMN = utils.sanitizeData(tvm.manager.addColumnInputArray[i].COLUMN);
          }
        }
        if (tvm.manager.destinationManager) {
          tvm.manager.destinationManager.new_column_name = utils.sanitizeData(tvm.manager.destinationManager.new_column_name);
        }
        initPromise.resolve(tvm.manager);
      })
    }

    function initTaskManager(task_name, context, sequence?, reference_errors?) {
      var deferred = $q.defer();
      tvm.task_display_name = dataviewConfig.taskConfig[task_name]["label"];
      TaskService = TaskServiceFactory.get_by_dataview_id(dataview_id);
      //Enable 'Learn More' button during modification of any task
      var taskExists = _checkIfTaskExistsInAppHelp(task_name);
      if (taskExists == true) {
        tvm.taskExistsInAppHelp = true;
      }
      // get task manager factory
      var manager_factory = $injector.get(dataviewConfig.get_manager_factory_from_task_name(task_name));
      // Ensure taskwise info is always updated
      $timeout(function () {
        var task_sequences = Object.keys(tvm.dataview.taskwise_info)
        if (sequence) {
          var metadata = _.cloneDeep(utils.metadata.getMetadata(tvm.dataview.taskwise_info, sequence - 1))
        } else {
          metadata = _.cloneDeep(utils.metadata.getMetadata(tvm.dataview.taskwise_info, task_sequences.length - 1));
        }
        metadata = utils.metadata.applyDisplayChangesReturnMetadata(metadata, tvm.dataview.display_properties, [], {}, false);

        /**Add error columns to the previous metadata so that they can be shown in the function panel*/
        if (reference_errors && reference_errors.hasOwnProperty('reference_errors')) {
          metadata = utils.metadata.add_error_columns_to_metadata(metadata, reference_errors.reference_errors, tvm.dataview.id);
        }
        var displayNameAndTypeToColumnMap = utils.metadata.getDisplayNameAndTypeToColumnMap(metadata);
        metadata = utils.metadata.add_type_to_display_name(metadata);
        tvm.manager = manager_factory.get_manager({
          metadata: metadata,
          internal_name_to_col_map: utils.metadata.get_internal_name_to_col_map(metadata),
          taskUtils: taskUtils(),
          taskHelperService: tvm.taskHelperService,
          context: context,
          originalSequenceNumber: sequence,
          displayNameAndTypeToColumnMap: displayNameAndTypeToColumnMap
        });
        if (tvm.manager?.destinationManager) {
          tvm.manager.destinationManager.onAllowedColumnChangeEvent('allowedColumnChangeEvent', handleAllowedColumnChangeEvent)
        }
        deferred.resolve()
      },0)
      return deferred.promise;
    }

    function handleAllowedColumnChangeEvent() {
      tvm.functionsPanel.fire_revalidate()
    }

    function submit(withCondition) {
      if (!tvm.manager.validate()) {
        Notification.error("Rule params are invalid");
        return;
      }
      tvm.task_panel.submit_in_progress = true;
      var param = tvm.manager.getParam();

      if (withCondition && tvm.isGlobalFilterApplied) {
        mergeParamWithGlobalCondition(param, tvm.task_panel.task_name);
      }

      param['DATAVIEW_ID'] = $stateParams.dataviewId;
      if (!tvm.task_panel.editing_task) {
        var d = document.getElementsByClassName('dummy')
        if (d.length > 0) {
          let dummyCard: any = d[0].parentNode.parentNode
          let allCards = dummyCard.parentNode.children
          let arrayOfPipelineCards = Array.from(allCards)
          var taskCards = []
          for (let i of arrayOfPipelineCards) {
            let card: any = i
            if (card.attributes['steptype'].nodeValue == "task") {
              taskCards.push(i)
            }
          }
          let indexOfDummyElement = taskCards.indexOf(dummyCard)
          let sequence = indexOfDummyElement + 1
          if (sequence <= tvm.dataview.tasks_total_count) {
            param['SEQUENCE_NUMBER'] = sequence
          } else {
            param['SEQUENCE_NUMBER'] = tvm.dataview.tasks_total_count + 1
          }
        }
      }
      if (!tvm.task_panel.editing_task) {
        TaskService.add_task(param).then(submission_success, submission_failure);
      } else {
        TaskService.edit_task(tvm.task_panel.editing_task, param).then(submission_success, submission_failure);
      }

      function submission_success(data) {
        if (tvm.dataview.pipeline_autorun_enabled) {
          resources.update();
        }
        tvm.task_panel.submit_in_progress = false;
        tvm.task_panel.latest_params = param;
        // temp hack below. beware!
        $timeout(function () {
          //hack
          if (param.RUNNING_TOTAL && param.RUNNING_TOTAL.SORT) {
            var current_dataview = DataviewService.get_by_id(dataview_id);
            current_dataview.setDisplayProperties({"SORT": param.RUNNING_TOTAL.SORT});
          }
        }, 500);
        $timeout(function () {
          tvm.task_panel.waiting_for_completion = true;
        }, 0);
        tvm.task_panel.close();
        if (!tvm.task_panel.editing_task) {
          tvm.task_panel.taskAddedEvent.fire_event();
        }
        tvm.load_pipeline_info();
      }

      function submission_failure(data) {
        if (data && data.hasOwnProperty('proceed') && data.proceed == true) {
          var param = data.information['param']
          param['skip_validation'] = true
          if (data.information['type_of_modification'] == 'edit_rule') {
            TaskService.edit_task(tvm.task_panel.editing_task, param).then(submission_success, submission_failure);
          } else if (data.information['type_of_modification'] == 'add_rule') {
            TaskService.add_task(param).then(submission_success, submission_failure);
          }
        } else {
          tvm.task_panel.submit_in_progress = false;
          var message = 'The task could not be submitted, please try again or contact support for more information';
          if (data.data && data.data.ERROR_CODE && c.errorCodetoMessageMap.hasOwnProperty(data.data.ERROR_CODE)) {
            message = utils.prepare_message(data.data.ERROR_CODE, data.data);
            Notification.error(message);
          } else if (data.data && data.data.ERROR_MESSAGE) {
            message = data.data.ERROR_MESSAGE;
            Notification.error(message);
          }
        }
        tvm.load_pipeline_info();
      }
    }

    function destroy() {
      $timeout(function () {
        var tUtils = taskUtils();
        tUtils.highlight.sources(undefined, true);
        tUtils.highlight.destinations(undefined, true);
        tUtils.highlight.deletions(undefined, true);
        tUtils.highlight.renderAll();
      }, 0);
    }

    tvm.dataview.on_update('dataviewUpdateTaskDescriptionListener', function () {
      TaskService.update_list();
      TaskService.updateTasksDescription();
    });

    function taskUtils() {
      return {
        highlight: {
          sources: function (sourceColumns, doNotRender) {
            highlightItems('source-highlight', sourceColumns, doNotRender)
          },
          destinations: function (destinationColumns, doNotRender) {
            highlightItems('destination-highlight', destinationColumns, doNotRender)
          },
          deletions: function (destinationColumns, doNotRender) {
            highlightItems('deletions-highlight', destinationColumns, doNotRender)
          },
          columns: function (columnsToHighlight){
            highlightItems(null, columnsToHighlight, null);
          },
          renderAll: function () {
            // tvm.mainGridUnit.gridInstance.plugins.columnHighlighter.renderAll();
          }
        },
      }

      function highlightItems(itemClass, colDefs, doNotRender) {
        var internalNames = utils.metadata.getListOfIntNamesFromListOfCols(colDefs);
        // dataeditorGrid
        tvm.mainGridUnit.vueGridInstance.selectColumns(internalNames);
        // tvm.mainGridUnit.gridInstance.plugins.columnHighlighter.highlightMultipleColumns(itemClass, internalNames, doNotRender);
        if (internalNames && internalNames.length) {
          tvm.mainGridUnit.vueGridInstance.scrollToColumn(internalNames.pop());
        }
      }
    }

    function _initController() {
      if (tvm.task_panel.pasting_task) {
        tvm.paste(tvm.task_panel.task_name, tvm.task_panel.createParams)
      } else if (tvm.task_panel.editing_task) {
        tvm.edit(tvm.task_panel.editing_task);
      } else {
        tvm.create(tvm.task_panel.task_name, tvm.task_panel.createParams);
      }
      // Note: Here we defining the submit method for task_panel otherwise its undefined in the task panel factory
      tvm.task_panel.submit = tvm.submit;
      return initPromise.promise;
    }

    function mergeParamWithGlobalCondition(param, task_name) {
      if (task_name != dataviewConfig.tasks.insert) {
        param.CONDITION = _combineWithGlobalCondition(param.CONDITION);
      } else {
        $.each(param.SET.VALUES, function (i, v) {
          v.CONDITION = _combineWithGlobalCondition(v.CONDITION);
        });
      }
    }

    function _combineWithGlobalCondition(origCondition) {
      var origConditionIsNonNull = origCondition === null || origCondition === undefined || angular.equals(origCondition, {});
      var globalCondition = globalFilter.getCondition();
      if (!origConditionIsNonNull) {
        return {AND: [origCondition, globalCondition]}
      } else {
        return globalCondition;
      }
    }

  }
}
