import * as _ from 'lodash-es';
import * as angular from 'angular';

/**
 * @ngInject
 */

DrilldownManagerFactory.$inject = ['eventCallbackManagerFactory', 'derivatives', 'utils', '$timeout', 'queryHelper'];
export function DrilldownManagerFactory(eventCallbackManagerFactory, derivatives, utils, $timeout, queryHelper) {
  return DrilldownManager;

  function DrillItem(element, elementService) {
    var ddi = this;
    ddi.element = element;
    ddi.up = {
      element: undefined,
      drillItem: undefined
    };
    ddi.isEmpty = false;
    ddi.selectedValues = [];  // contains {value: , column: } maps
    if (element.display_properties && element.display_properties.drillUp) {
      ddi.up.element = elementService.mainList[element.display_properties.drillUp.elementId]
    }

    ddi.selectedValuesGetterSetter = function (column) {
      var selVal = _.filter(ddi.selectedValues, function (_selVal) {
        return _selVal && _selVal.column && column && _selVal.column.internal_name == column.internal_name;
      });
      selVal = selVal.length ? selVal [0] : selVal;
      return selVal;
    }
  }

  /**
   * Manages drilldown, should be initialized on the base (a.k.a root) element.
   * The base element is the zeroth level element, every drilldown increases the level.
   * The information about drilldown is contained within an element's `display_properties` under the
   * attributes `drilldown` and `drillup`.
   *
   * @param rootElement
   * @param elementService
   * @param datastores
   * @constructor
   */
  function DrilldownManager(rootElement, elementService, datastores) {
    var ddm: any = this;
    var drilldownChangeEvent = new eventCallbackManagerFactory('drilldownChangeEvent');
    var drilldownValueUpdateEvent = new eventCallbackManagerFactory('drilldownValueUpdateEvent');
    ddm._randomId = utils.string.random(5);
    ddm.rootElement = rootElement;
    ddm.datastores = datastores || {};
    ddm.visibleDrilldowns = [];
    ddm._drilldownItemMap = {};
    ddm.onChange = drilldownChangeEvent.add_callback;
    ddm.fireChanged = drilldownChangeEvent.fire_event;
    ddm.onValueUpdated = drilldownValueUpdateEvent.add_callback;
    ddm.fireValueUpdated = drilldownValueUpdateEvent.fire_event;

    ddm.openDrilldown = openDrilldown;
    ddm.updateDrilldown = updateDrilldown;
    ddm.openEmptyDrilldown = openEmptyDrilldown;
    ddm.getDrillItemById = getDrillItemById;
    ddm.getLatestDrillItem = getLatestDrillItem;
    ddm.addDrilldownConditionToExisting = addDrilldownConditionToExisting;
    ddm.getCurrentElement = getCurrentElement;
    ddm.deleteAllDrillDownElements = deleteAllDrillDownElements;
    ddm.autoSelectValues = autoSelectValues;
    ddm.checkIfValuesSelected = checkIfValuesSelected;
    elementService.onElementsUpdated("reinitDrilldownManager" + ddm._randomId , reinit);
    reinit();

    function reinit() {
      var drillElements = [ddm.rootElement];
      for (var i=0; i < drillElements.length; i++) {
        var element = drillElements[i];
        if (element && element.display_properties.drilldown) {
          angular.forEach(element.display_properties.drilldown, function (drillSpec, key) {
            var _elem = elementService.mainList[drillSpec.elementId];
            if (_elem) {
              drillElements.push(_elem);
            }
          });
        }
      }
      angular.forEach(drillElements, function (element) {
        if (!ddm._drilldownItemMap.hasOwnProperty(element.id)) {
          var drillItem = new DrillItem(element, elementService);
          ddm._drilldownItemMap[element.id] = drillItem;
          if (drillItem.up.element) {
            drillItem.up.drillItem = ddm._drilldownItemMap[drillItem.up.element.id];
          }
        }
      });
    }


    function openDrilldown(elementId, selectedValues) {
      var elem = elementService.mainList[elementId];
      var dItem;
      if (!elem) {
        return;
      } else {
        var _lastItem = ddm.visibleDrilldowns[ddm.visibleDrilldowns.length - 1];
        if (_lastItem && !_lastItem.element.id && !selectedValues) {
          selectedValues = _.cloneDeep(_lastItem.selectedValues);
        }
        var currDItem = ddm._drilldownItemMap[elementId];
        if (selectedValues != undefined) {
          currDItem.selectedValues.splice(0);
          angular.extend(currDItem.selectedValues, selectedValues);
        }
        var newStack = [currDItem];
        for (var i=0; i < newStack.length; i++) {
          dItem = newStack[i];
          var nextDItem = ddm._drilldownItemMap[dItem.up.element.id];
          if (nextDItem && nextDItem.element.id != ddm.rootElement.id) {
            newStack.push(nextDItem);
          }
        }
        ddm.visibleDrilldowns.splice(newStack.length);
        for (i=0; i < newStack.length; i++) {
          dItem = newStack[newStack.length - i - 1];
          if (!dItem.selectedValues || !dItem.selectedValues.length) {
            autoSelectValues(dItem.element.id);
          }
          ddm.visibleDrilldowns[i] = dItem;
        }
        drilldownChangeEvent.fire_event();
      }
    }

    function autoSelectValues(elementId, drillItem = undefined) {
      var dItem, dColName, dData, xAxis ;
      var maxValXAxisVal;
      if (elementId && !drillItem) {
        dItem = ddm._drilldownItemMap[elementId];
      } else {
        dItem = drillItem;
      }
      if (!dItem) { return }
      var dStore = datastores[dItem.up.element.id];
      if (!dStore) { return }
      if (dItem.up.element.type == "CHART") {
        xAxis = dStore.metadata[0];
        dColName = xAxis.internal_name;
        dData = dStore.data;
      } else if (dItem.up.element.type == "TABLE") {
        dData = dStore.data;
        dColName = dItem.up.element.display_properties.drilldown.default.column;
        xAxis = utils.metadata.get_column_by_internal_name(dStore.metadata, dColName);
      }
      maxValXAxisVal = _findMaxVal(dData, dColName);
      dItem.selectedValues.splice(0);
      dItem.selectedValues.push({value: maxValXAxisVal, column: xAxis});

    }

    function _findMaxVal(data, dColName) {
      var maxVal = undefined, maxValXAxisVal = undefined;
      angular.forEach(data, function (dataItem) {
        // manager.previousXAxisData.push(dataItem[xAxis.internal_name]);
        var colLen = Object.keys(dataItem).length
        angular.forEach(dataItem, function (val, key) {
          if (colLen === 1 || key != dColName) {
            val = parseFloat(val);
            if (maxVal == undefined || val > maxVal) {
              maxVal = val;
              maxValXAxisVal = dataItem[dColName];
            }
          }
        });
      });
      return maxValXAxisVal;
    }

    function checkIfValuesSelected(elementId) {
      var dItem = ddm._drilldownItemMap[elementId];
      return (dItem && dItem.selectedValues && dItem.selectedValues.length);
    }

    // old
    function updateDrilldown(elementId, drilledValue, drilledColumn) {
      var dItem;
      dItem = _.filter(ddm.visibleDrilldowns, function (dI) {
        return dI.element.id == elementId;
      });
      if (dItem.length) {
        dItem = dItem[0];
        if (!(dItem.drilledValue == drilledValue && dItem.drilledColumn == drilledColumn)) {
          dItem.drilledValue = drilledValue;
          dItem.drilledColumn = drilledColumn;
          drilldownChangeEvent.fire_event();
        }
      }
    }


    function openEmptyDrilldown(element, additionalDisplayProperties) {
      var drillItem = new DrillItem(element, elementService);
      drillItem.up.element = elementService.mainList[additionalDisplayProperties.drillUp.elementId];
      ddm.visibleDrilldowns.push(drillItem);
      autoSelectValues(undefined, drillItem);
      drilldownChangeEvent.fire_event();
    }

    function getDrillItemById(elementId) {
      return ddm._drilldownItemMap[elementId];
    }

    function getLatestDrillItem() {
      if (ddm.visibleDrilldowns.length) {
        return ddm.visibleDrilldowns[ddm.visibleDrilldowns.length - 1];
      }
    }

    ddm.pop = function () {
      ddm.visibleDrilldowns.pop();
      drilldownChangeEvent.fire_event();
    };

    ddm.reset = function () {
      ddm.visibleDrilldowns.splice(0);
      drilldownChangeEvent.fire_event();
    };

    function addDrilldownConditionToExisting(element, condition) {
      var drilldownCondition = {AND: []};
      for (var i = 0; i < ddm.visibleDrilldowns.length; i++) {
        var ditem = ddm.visibleDrilldowns[i];
        angular.forEach(ditem.selectedValues, function (sValue) {
          var cond = {};
          if (sValue.column.type == 'DATE') {
            var groupParam = _.filter(ditem.up.element.param.PIVOT.GROUP_BY, function (group) {
              return group.COLUMN == sValue.column.internal_name;
            });
            if (!groupParam.length) {
              return;
            } else {
              groupParam = groupParam[0];
            }
            cond = queryHelper.getDateQuery(groupParam, sValue.column.internal_name, sValue.value);
          } else {
            cond[sValue.column.internal_name] = {EQ: sValue.value};
          }
          drilldownCondition.AND.push(cond);
        });

        if (ditem.element.id == element.id) {
          break;
        }
      }

      if (condition) {
        if (condition.hasOwnProperty("AND")) {
          drilldownCondition.AND.concat(condition.AND);
        } else {
          drilldownCondition.AND.push(condition);
        }
      }
      return drilldownCondition;
    };

    function getCurrentElement() {
      if (ddm.visibleDrilldowns.length) {
        return ddm.visibleDrilldowns[ddm.visibleDrilldowns.length - 1].element
      } else {
        return ddm.rootElement;
      }
    }

    /**
     * Deletes all the children drilldowns of the given element.
     * @param elementId
     */
    function deleteAllDrillDownElements(elementId) {
      var elementsToDelete = [elementId];
      for (var i=0; i < elementsToDelete.length; i++) {
        var ele = elementService.mainList[elementsToDelete[i]];
        if (ele && (ele.type == "CHART" || ele.type == "TABLE")) {
            if (ele.display_properties.drilldown && ele.display_properties.drilldown.default) {
              elementsToDelete.push(ele.display_properties.drilldown.default.elementId)
            }
        }
      }
      var ws_id = ddm.rootElement.dataviewId;
      elementsToDelete = elementsToDelete.splice(1);
      angular.forEach(elementsToDelete, function obj(eleId) {
        derivatives.discard(ws_id, eleId);
      });
    }
  }

}
