import * as angular from 'angular';

import { oldElementServiceFactory, baseElementServiceFactory } from './old.element.service.factory';
import { elementConfig } from './element.config';
import { DrilldownManagerFactory } from './drilldown.manager.factory';
import {
  bindButtonToElementForm, elementSwitcherOnBuilderMenu, showElementBuilderMenu,
  elementDisplayContent, chartTypeMenu, elementDisplayMenuHandle, copyMetricValueToClipboard
} from './element.directives';
import { ElementFactory } from './Element.factory';
import { queryHelperService } from './query.helper.service';


export let elementCommonModule = angular.module('app.element.common', ['app.core', 'ngResource']);
elementCommonModule.constant('elementConfig', elementConfig);
elementCommonModule.factory('baseElementServiceFactory', baseElementServiceFactory);
elementCommonModule.factory('oldElementServiceFactory', oldElementServiceFactory);
elementCommonModule.factory('DrilldownManagerFactory', DrilldownManagerFactory);
elementCommonModule.directive('elementDisplayMenuHandle', elementDisplayMenuHandle);
elementCommonModule.directive('chartTypeMenu', chartTypeMenu);
elementCommonModule.directive('elementDisplayContent', elementDisplayContent);
elementCommonModule.directive('showElementBuilderMenu', showElementBuilderMenu);
elementCommonModule.directive('elementSwitcherOnBuilderMenu', elementSwitcherOnBuilderMenu);
elementCommonModule.directive('bindButtonToElementForm', bindButtonToElementForm);
elementCommonModule.factory('Element', ElementFactory);
elementCommonModule.service('queryHelper', queryHelperService);
elementCommonModule.directive('copyMetricValueToClipboard', copyMetricValueToClipboard);
