<template>
  <div class="navbar-notifications">
    <mm-row align="center" class="m-x-3 m-b-3 p-t-5">
      <label class="mm-text--body-bold m-y-3">{{ $t('navbar.notifications.title') }}</label>
      <mm-pill
        v-if="unreadNotificationsQuantity > 0"
        :label="unreadNotificationsQuantity.toString()"
        small
        no-focus-on-click
        class="m-l-2"
      />

      <mm-spacer />
      <mm-button
        v-if="unreadNotificationsQuantity > 0"
        :label="$t('navbar.notifications.mark_all_read')"
        objective="tertiary"
        size="small"
        @click="markAllRead"
      />
      <mm-button
        v-if="readNotificationsQuantity > 0"
        :label="$t('navbar.notifications.delete_reads')"
        objective="tertiary"
        size="small"
        class="m-l-3"
        @click="deleteAllReads"
      />
    </mm-row>

    <mm-divider />

    <div class="navbar-notifications-wrapper">
      <div v-if="notifications.length === 0" class="navbar-notifications-empty navbar-notifications-info">
        <div class="justify-align-center">
          <p class="mm-text--caption-bold m-a-0">{{ $t('navbar.notifications.all_caught_up') }}</p>
        </div>
        <div class="justify-align-center">
          <p class="mm-text--caption-regular m-a-0">{{ $t('navbar.notifications.no_notifications') }}</p>
        </div>
      </div>

      <div class="navbar-notifications-panel overflow-kit">
        <transition-group tag="div" name="navbar-notifications-list">
          <div v-for="notification in notifications" :key="`notification_${notification.id}`">
            <navbar-notifications-item :notification="notification" />
            <mm-divider />
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import NavbarNotificationsItem from './navbar-notifications-item/navbar-notifications-item'

//Constants
import { USER_EVENTS } from '@/constants'

export default {
  name: 'navbar-notifications',
  components: { NavbarNotificationsItem },
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    unreadNotificationsQuantity() {
      return this.notifications.filter((n) => !n.isRead).length
    },
    readNotificationsQuantity() {
      return this.notifications.filter((n) => n.isRead).length
    }
  },
  methods: {
    deleteAllReads() {
      const readNotificationsIds = this.notifications.filter((n) => n.isRead).map((n) => n.id)
      readNotificationsIds.forEach((id) => this.$store.dispatch('notifications/remove', id))
      this.$userEvents.save(USER_EVENTS.NAVBAR.NOTIFICATIONS.DELETE_READS, { ids: readNotificationsIds })
    },
    markAllRead() {
      const unreadNotificationsIds = this.notifications.filter((n) => !n.isRead).map((n) => n.id)
      unreadNotificationsIds.forEach((id) => this.$store.dispatch('notifications/read', id))
      this.$userEvents.save(USER_EVENTS.NAVBAR.NOTIFICATIONS.MARK_ALL_READ, { ids: unreadNotificationsIds })
    }
  }
}
</script>

<style lang="scss" scoped>
$navbar-notifications-info-font-color: var(--mm-color--n400);
$navbar-notifications-width: 360px;
$navbar-notifications-panel-height: 440px;

.navbar-notifications {
  width: $navbar-notifications-width;

  .navbar-notifications-wrapper {
    height: $navbar-notifications-panel-height;
    position: relative;

    .navbar-notifications-panel {
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .navbar-notifications-info {
      color: $navbar-notifications-info-font-color;
    }

    .navbar-notifications-empty {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.navbar-notifications-list-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.navbar-notifications-list-leave-active {
  transition: all 0.2s ease-in;
  position: absolute;
}

.navbar-notifications-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.navbar-notifications-list-move {
  transition: transform 0.5s ease;
}
</style>
