import * as angular from 'angular';
import * as _ from 'lodash-es';
/**
 * @ngInject
 * File service
 */

FileService.$inject = ['FileObject', 'eventCallbackManagerFactory', '$q', '$resource', 'config', 'c', 'FutureService'];
export function FileService(FileObject, eventCallbackManagerFactory, $q, $resource, config, c, FutureService) {
  let fileMap = {}, fileMapActive = {}, _prevFileData;
  const ACTIVE_FILE_TYPES = ['action_needed', 'extracting', 'processing', 'extracted', 'error'];
  var list_updated = new eventCallbackManagerFactory('file_list_updated');
  var file_deletion = new eventCallbackManagerFactory('file_deletion');
  var FilesResource = $resource(config.api.files);
  var FileResource = $resource(config.api.file, {id: '@id'});

  var files_service = {
    _set_updates: _set_updates,
    list: fileMap,  // tentative, to be replaced with a method
    list_active: fileMapActive,
    delete: del,
    extractSheets: extractSheets,
    passwordValidationForProtectedFile: passwordValidationForProtectedFile,
    multi_delete: multi_delete,
    listInited: false,
    reset: reset,
    // custom events
    on_list_update: list_updated.add_callback,
    on_delete: file_deletion.add_callback,
    remove_on_delete: file_deletion.remove_callback,
  };
  return files_service;

  ////
  function reset() {
    files_service.listInited = false;
    _prevFileData = undefined;
    //noinspection TypeScriptValidateTypes
    _.forOwn(fileMap, function(v,k,o){_.unset(o,k)})
    //noinspection TypeScriptValidateTypes
    _.forOwn(fileMapActive, function(v,k,o){_.unset(o,k)})
  }

  function del(file) {
    var deferred = $q.defer();
    file.delete().then(del_success);

    function del_success() {
      delete fileMap[file.id];
      file_deletion.fire_event();
      deferred.resolve();
    }
    return deferred.promise;
  }

  function extractSheets(fileId, sheets, combine, deleteFile) {
    var patch;
    if(!combine){
      patch = {patch: [{op: "replace", path: "extract_sheets", value: sheets, deleteFile : deleteFile}]};
    }
    else{
      patch = {patch: [{op: "replace", path: "extract_sheets_and_combine", value: sheets, deleteFile : deleteFile}]}
    }
    var ret = FileResource.patch({id: fileId}, patch);
    return ret.$promise;
  }

  function passwordValidationForProtectedFile(fileId, filePassword) {
    let patch;
    let deferred = $q.defer();
    patch = {patch: [{op: "replace", path: "validate_password", value: filePassword}]}
    
    FileResource.patch({id: fileId}, patch).$promise.then((data) => track_future_id(data));

    function track_future_id(data){
      let error_message = ""
      if (data.future_id){
        FutureService.track(data.future_id, function(future){
          if (future.status == "error") {
            error_message = future.response.error_message;
          }
          deferred.resolve(error_message);
        });
      }
    }
    return deferred.promise;
  }

  function multi_delete(file_items) {
    var deferred = $q.defer();


    var file_ids = file_items.map(function (item) {
      if (item.type === 'file_object')
        return item.id;
    });

    FilesResource.patch({
      patch: [{
        "op": "remove",
        "path": "file_objects",
        "value": file_ids
      }]
    }).$promise.then(del_success());


    function del_success() {
      angular.forEach(file_ids, function (file_id) {
        delete fileMap[file_id];
        file_deletion.fire_event();
      });
      deferred.resolve();
    }
    return deferred.promise;
  }


  function _set_updates(allFilesData, resource_update_callback, resource_delete_callback) {
    if (angular.equals(allFilesData, _prevFileData)) {
      return false;
    }
    _prevFileData = _.cloneDeep(allFilesData);
    let  changedFileIds = [];
    angular.forEach(allFilesData, function (fileData) {
      if (fileData.objectStatus == c.coreListObjectStatus.deleted) {
        delete fileMap[fileData.id];
        resource_delete_callback(fileMapActive[fileData.id]);
        delete fileMapActive[fileData.id];
      }
      else {
        if (fileMap.hasOwnProperty(fileData.id)) {
          if (fileMap[fileData.id].update(fileData)) {
            changedFileIds.push(fileData.id);
          }
        }
        else {
          fileMap[fileData.id] = new FileObject(fileData);
        }
        var _file = fileMap[fileData.id];
      // add active items
        if (ACTIVE_FILE_TYPES.indexOf(_file.status) !== -1) {
          resource_update_callback(_file);
          fileMapActive[fileData.id] = _file;
        }
        else {
          resource_delete_callback(fileMapActive[fileData.id]);
          delete fileMapActive[fileData.id];
        }
      }
    });
    files_service.listInited = true;
    list_updated.fire_event(changedFileIds);
    return true;
  }
}
