import * as angular from 'angular';
import * as $ from 'jquery';
import _ = require('lodash');

var listOfColors = [
  {
    "name":"All Green",
    "colors":["#77B885","#1E5359","#45ADA8","#99D7C2","#C0D860","#619080","#8CC652","#AECCB6","#9DE0AD","#539F33","#547980","#9FBDB2","#DEF2C4","#C0D860","#AECCB6"]
  },
  {
    "name":"All Blue",
    "colors":["#7ACBEE","#005B82","#00BCD4","#547980","#45ADA8","#4FC3F7","#B9D7D9","#7CBBFF","#006666","#33FFFF","#403D99","#0CB4E8","#08E0E8","#0B576B","#3D8599"]
  },
  {
    "name":"Haberdasher",
    "colors" : ["#7ACBEE", "#FDD761", "#FF7857", "#674E9E", "#A3C86D", "#3CB7CC", "#E65818","#B8E48D","#7798BF", "#665446","#E5683F","#EBE3AA","#859987","#77B885","#619080"]
  },
  {
    "name":"Tramshed",
    "colors":["#005B82", "#F27D00", "#0798C0", "#FFB702", "#405ACF", "#8CC652", "#BE0008", "#3CB7CC", "#E65818","#B8E48D","#7798BF","#5D4157","#F6AA93","#9A8194","#619080"]
  },
  {
    "name":"Nobu",
    "colors":["#AED581", "#00BCD4", "#F06292", "#FFC107", "#8BAB67", "#4FC3F7", "#7986CB","#47639E","#FD6F6C", "#F3D1DC", "#CA824F","#DEF2C4","#E5FCC2","#F6AA93","#9FBDB2"]
  },
  {
    "name":"Chihuahua",
    "colors":["#9A8194","#547980","#45ADA8","#5D4157","#859987","#665446","#619080","#E99944","#4F2716","#E5683F","#809994","#1E5359","#F6AA93","#E5FCC2","#B9D7D9"]
  },
  {
    "name":"Romeo",
    "colors":["#1E5359","#45ADA8","#665446","#E5683F","#619080","#5D4157","#E99944","#809994","#4F2716","#859987","#547980","#9A8194","#DEF2C4","#E5FCC2","#F6AA93"]
  },
  {
    "name":"Senegal",
    "colors":["#619080","#E99944","#1E5359","#5D4157","#9A8194","#809994","#547980","#4F2716","#45ADA8","#E5683F","#859987","#665446","#99D7C2","#B9D7D9","#AECCB6"]
  },
  {
    "name":"Sidekick",
    "colors":["#4F2716","#77B885","#619080","#AECCB6","#1E5359","#B9D7D9","#E99944","#B5FFC5","#665446","#9FBDB2","#859987","#C0D860","#9A8194","#F6AA93","#E5683F"]
  },
  {
    "name":"Rambo",
    "colors":["#547980","#9FBDB2","#1E5359","#B9D7D9","#859987","#77B885","#45ADA8","#9DE0AD","#9A8194","#99D7C2","#4F2716","#AECCB6","#619080","#C0D860","#E99944"]
  },
  {
    "name":"Benefactor",
    "colors":["#619080","#EBE3AA","#665446","#AECCB6","#E5683F","#B9D7D9","#547980","#99D7C2","#9A8194","#9FBDB2","#809994","#F6AA93","#4F2716","#B5FFC5","#E99944"]
  },
  {
    "name":"Pride",
    "colors":["#1E5359","#F6AA93","#E5683F","#C0D860","#4F2716","#B9D7D9","#E99944","#DEF2C4","#619080","#EBE3AA","#665446","#9FBDB2","#859987","#99D7C2","#547980"]
  },
  {
    "name":"Long Hair",
    "colors":["#E5683F","#EBE3AA","#859987","#77B885","#619080","#B9D7D9","#665446","#AECCB6","#809994","#F6AA93","#45ADA8","#9FBDB2","#547980","#99D7C2","#9A8194"]
  },
  {
    "name":"Bom Dia",
    "colors":["#665446","#B9D7D9","#859987","#9DE0AD","#1E5359","#9FBDB2","#E5683F","#99D7C2","#4F2716","#77B885","#9A8194","#DEF2C4","#5D4157","#AECCB6","#619080"]
  }
];

var defaultColors = ["#7ACBEE", "#FDD761", "#FF7857", "#674E9E", "#A3C86D", "#3CB7CC", "#E65818","#B8E48D","#7798BF", "#665446","#E5683F","#EBE3AA","#859987","#77B885","#619080"];
listOfColors.forEach(function(t){
  t.colors = t.colors.concat(defaultColors);
})

/**
 * @ngInject
 */
export function chartThemes(){

    return {
      getThemes: getThemes,
      getThemeByName: getThemeByName
    }


    function getThemeByName(name){
      var matching: any = $.grep(listOfColors, function(color){
        return color.name == name;
      });
      if(matching.length){
        matching = matching[0];
      }
      else{
        matching = listOfColors[0];
      }
      return matching;
    }

    function getThemes(){
      return _.cloneDeep(listOfColors);
    }
}
