import moment from 'moment'

//Returns a common way of displaying time handled by moment#fromNow. This is sometimes called timeago or relative time.
export function getFromNowDate(date) {
  return moment.utc(date).local().fromNow()
}

export function compactNumberFormat(number) {
  return Intl.NumberFormat('en', { notation: 'compact' }).format(number)
}
