<template>
  <div class="mm-linked-in-out-list">
    <div
      v-if="resource.dependencies.in.filter((d) => IN_OUT_RESOURCE_DEPENDENCIES.includes(d.operationName)).length"
      :class="{ 'operation-list m-l-5 m-b-4 p-l-5': !hideDetails }"
    >
      <span v-if="!hideDetails" class="mm-text--body-bold">{{ $t('data_library.data_in') }}</span>
      <div v-for="(operationName, idx) in IN_OUT_RESOURCE_DEPENDENCIES" :key="`op_name_in_${idx}`">
        <component
          :is="OP_COMPONENTS[operationName]"
          v-if="computedSortedDependencies.in[operationName].length"
          class="m-y-2"
          :resource="resource"
          :operations="computedSortedDependencies.in[operationName]"
        />
      </div>
    </div>

    <div v-if="!hideDetails" class="current-data d-flex justify-align-center">
      <div class="icon-container">
        <mm-icon :name="resource.viewId ? 'view' : 'dataset'" />
      </div>
      <mm-tooltip :label="resource.properties.name">
        <span class="mm-text--caption-regular resource-name">{{ resource.properties.name }}</span>
      </mm-tooltip>
    </div>

    <div
      v-if="resource.dependencies.out.filter((d) => IN_OUT_RESOURCE_DEPENDENCIES.includes(d.operationName)).length"
      :class="{ 'operation-list m-l-5 m-t-4 p-l-5': !hideDetails }"
    >
      <span v-if="!hideDetails" class="mm-text--body-bold">{{ $t('data_library.data_out') }}</span>
      <div v-for="(operationName, idx) in IN_OUT_RESOURCE_DEPENDENCIES" :key="`op_name_out_${idx}`">
        <component
          :is="OP_COMPONENTS[operationName]"
          v-if="computedSortedDependencies.out[operationName].length"
          class="m-y-2"
          :operations="computedSortedDependencies.out[operationName]"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import LinkedInOutListOperations from './linked-in-out-list-operations/linked-in-out-list-operations'
import LinkedInOutListLiveLinkOperation from './linked-in-out-list-operations/linked-in-out-list-live-link-operation'
import LinkedInOutListExportOperation from './linked-in-out-list-operations/linked-in-out-list-export-operation'

//Constants
import { RESOURCE_DEPENDENCIES, IN_OUT_RESOURCE_DEPENDENCIES } from '@/constants'

export default {
  name: 'linked-in-out-list',
  props: {
    resource: {
      type: Object,
      /*
      {
        resourceId: id,
        properties:{
          name: string,
          dataset: {}
        },
        dependencies: {
          in: [],
          out: []
        }
      }
      */
      required: true
    },
    hideDetails: Boolean
  },
  computed: {
    computedSortedDependencies() {
      let inDependencies = {}
      let outDependencies = {}

      IN_OUT_RESOURCE_DEPENDENCIES.forEach((t) => {
        inDependencies[t] = []
        outDependencies[t] = []
      })

      this.resource.dependencies.in.forEach((d) => inDependencies[d.operationName]?.push(d))
      this.resource.dependencies.out.forEach((d) => outDependencies[d.operationName]?.push(d))
      return { in: inDependencies, out: outDependencies }
    }
  },
  created() {
    this.IN_OUT_RESOURCE_DEPENDENCIES = IN_OUT_RESOURCE_DEPENDENCIES
    this.OP_COMPONENTS = {
      [RESOURCE_DEPENDENCIES.BRANCH_OUT]: LinkedInOutListOperations,
      [RESOURCE_DEPENDENCIES.BRANCH_OUT_TO_PROJECT]: LinkedInOutListOperations,
      [RESOURCE_DEPENDENCIES.CROSSTAB]: LinkedInOutListOperations,
      [RESOURCE_DEPENDENCIES.EXPORT]: LinkedInOutListExportOperation,
      [RESOURCE_DEPENDENCIES.JOIN]: LinkedInOutListOperations,
      [RESOURCE_DEPENDENCIES.LIVE_LINK]: LinkedInOutListLiveLinkOperation,
      [RESOURCE_DEPENDENCIES.LOOKUP]: LinkedInOutListOperations,
      [RESOURCE_DEPENDENCIES.PUBLISH]: LinkedInOutListExportOperation
    }
  }
}
</script>

<style lang="scss" scoped>
.current-data {
  background: var(--mm-color--s75);
  justify-content: left;
  border-radius: 4px;
  height: 32px;
  padding: 4px;
  width: 100%;

  /deep/.mm-tooltip--slot-wrapper {
    display: grid;
  }

  .icon-container {
    display: inline-block;
    height: 100%;
    width: 25px;
    background: var(--mm-color--s200);
    border-radius: 4px;
    padding: 4px;
    margin-right: 6px; // inline-block already has a little margin separating, I suppose 2px
    vertical-align: sub;
  }

  .resource-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.operation-list {
  position: relative;
  border-left: 1px dashed var(--mm-color--s500);

  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: -3px;
    border: solid var(--mm-color--s500);
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
  }
}
</style>
