<template>
  <mm-modal
    v-model="computedShowModal"
    :title="$t('action_bar.export.publish_as_a_report.modal_title')"
    :width="520"
    closable
  >
    <div class="action-bar-export-publish-view m-t-6">
      <mm-text-input
        v-model="reportName"
        :title="$t('action_bar.export.publish_as_a_report.view_name')"
        :placeholder="$t('dictionary.name')"
        hide-details
      />
      <label class="m-t-5">{{ $t('action_bar.export.publish_as_a_report.invite_message') }}</label>
      <mm-email-input
        v-model="emails"
        class="m-t-2"
        :validEmailIds="$store.state.workspace.users.map((user) => user.email)"
        :error.sync="emailInputError"
        :placeholder="$t('dictionary.name')"
      />
      <mm-text-input
        v-model="message"
        class="m-t-5"
        :title="$t('dictionary.message')"
        :placeholder="$t('dictionary.message')"
        hide-details
      />
      <mm-toggle
        v-model="autoSync"
        class="m-t-5"
        :label="$t('action_bar.export.publish_as_a_report.auto_sync_message')"
        small
      />

      <template actions>
        <div class="d-flex m-t-6" style="gap: 8px">
          <mm-spacer />
          <mm-button
            style="width: 137px"
            :label="$t('dictionary.cancel')"
            objective="tertiary"
            @click="computedShowModal = false"
          />
          <mm-button
            style="width: 137px"
            :label="$t('dictionary.apply')"
            :loading="applyLoading"
            :disabled="!reportName || emailInputError"
            @click="onApplyClick"
          />
        </div>
      </template>
    </div>
  </mm-modal>
</template>

<script>
import actionBarApi from '@/modules/data-editor/action-bar/action-bar.api'

export default {
  name: 'action-bar-export-publish-as-a-report',
  props: {
    value: Boolean
  },
  data: () => ({
    reportName: '',
    emails: [],
    message: '',
    autoSync: false,
    applyLoading: false,
    emailInputError: false
  }),
  computed: {
    computedShowModal: {
      get() {
        return this.value
      },
      set(showModal) {
        this.$emit('input', showModal)
      }
    }
  },
  watch: {
    computedShowModal: {
      handler(showModal) {
        if (showModal) {
          const dataset = this.$store.getters['dataEditor/dataset']
          const view = this.$store.getters['dataEditor/view']

          this.reportName = this.$t('action_bar.export.publish_as_a_report.report_name', {
            datasetName: dataset.properties.name,
            viewName: view.properties.name
          })
          this.emails = []
          this.message = ''
          this.autoSync = false
          this.applyLoading = false
          this.emailInputError = false
        }
      },
      immediate: true
    }
  },
  methods: {
    async onApplyClick() {
      this.applyLoading = true
      try {
        await actionBarApi.publishReport({
          projectId: this.$store.state.projectId,
          viewId: this.$store.getters['dataEditor/view'].viewId,
          name: this.reportName,
          emails: this.emails,
          message: this.message,
          autoSync: this.autoSync
        })
        this.$toast.show(this.$t('action_bar.export.publish_as_a_report.publish_success_message'))
        this.$root.legacyHandlers.openPipeline()
        this.computedShowModal = false
      } catch (error) {
        this.$toast.show({ content: error.data.ERROR_MESSAGE, status: 'error' })
      }
      this.applyLoading = false
    }
  }
}
</script>

<style lang="scss">
.mm-publish-view-modal-content {
  > * {
    margin-top: 16px;
  }
}
</style>
