<template>
  <div class="action-bar-column-browser-actions p-a-3 d-flex-col height-100">
    <mm-list
      class="m-t-5 flex-grow-1"
      :items="computedActionItems"
      :dividerAtIndex="[5, 8]"
      :labelAtIndex="{ 5: $t('action_bar.column_browser.quick_actions') }"
      @itemClick="({ item }) => $emit(`action:${item.action}`)"
    />
    <div class="p-b-2 p-l-3">
      <div v-if="isReorderEnabled || isRenameEnabled" class="d-flex m-t-4">
        <mm-button
          class="m-x-5"
          objective="tertiary"
          size="medium"
          noFocusOnClick
          :label="$t('dictionary.discard')"
          @click="$emit('action:discard')"
        />
        <mm-button
          size="medium"
          noFocusOnClick
          :disabled="isApplyDisabled"
          :label="$t('dictionary.apply')"
          @click="$emit('action:apply')"
        />
      </div>
      <span v-else class="mm-column-browser--status" v-text="computedStatus" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'action-bar-column-browser-actions',
  props: {
    columns: Array,
    isReorderEnabled: Boolean,
    isRenameEnabled: Boolean,
    isApplyDisabled: Boolean
  },
  computed: {
    computedActionItems() {
      const selectedColumns = this.columns.filter((c) => c.selected)
      return [
        {
          text: this.$t('dictionary.hide'),
          icon: 'hide',
          action: 'hide',
          disabled: selectedColumns.every((c) => !c.show)
        },
        {
          text: this.$t('dictionary.show'),
          icon: 'show',
          action: 'show',
          disabled: selectedColumns.every((c) => c.show)
        },
        {
          text: this.$t('action_bar.column_browser.show_only_these'),
          icon: 'show',
          action: 'showOnlyThese',
          disabled: !selectedColumns.length
        },
        {
          text: this.$t('dictionary.reorder'),
          icon: 'reorder',
          action: 'reorder',
          class: { 'mm-list-item--selected': this.isReorderEnabled },
          disabled: this.isRenameEnabled || selectedColumns.length || !this.columns.length,
          handler: () => this.$emit('reorderClick')
        },
        {
          text: this.$t('dictionary.rename'),
          icon: 'rename',
          action: 'rename',
          class: { 'mm-list-item--selected': this.isRenameEnabled },
          disabled: this.isReorderEnabled || selectedColumns.length || !this.columns.length
        },
        {
          text: this.$t('dictionary.remove'),
          icon: 'delete',
          action: 'remove',
          disabled: !selectedColumns.length
        },
        {
          text: this.$t('action_bar.column_browser.find_and_replace'),
          icon: 'search',
          action: 'findAndReplace',
          disabled: !selectedColumns.filter((c) => c.type === 'text').length
        },
        {
          text: this.$t('action_bar.column_browser.convert_column_type'),
          icon: 'convert',
          action: 'convertColumnType',
          disabled: !selectedColumns.length
        },
        {
          text: this.$t('action_bar.column_browser.open_in_explore_columns'),
          icon: 'explore',
          action: 'openInExploreColumns',
          addDivider: true,
          disabled: selectedColumns.every((c) => c.exploreCard)
        }
      ]
    },
    computedStatus() {
      const columnLength = this.columns.length
      const selectedColumnLength = this.columns.filter((c) => c.selected).length
      const showColumnLength = this.columns.filter((column) => column.show).length

      let statusId = 'show_some_columns'
      if (selectedColumnLength) statusId = 'selected_columns'
      else if (showColumnLength === this.columns.length) statusId = 'show_all_columns'

      return this.$t(`action_bar.column_browser.status.${statusId}`, {
        columnLength,
        selectedColumnLength,
        showColumnLength
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@mammoth_developer/mm-storybook/src/styles/typography.scss';

.action-bar-column-browser-actions {
  .mm-list {
    ::v-deep .mm-list-label {
      @extend .mm-text--body-regular;
      color: var(--mm-color--n300);
    }
  }
}
</style>
