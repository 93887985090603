import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';

let colRange = [];
for(let i=2; i< 51; i++){
  colRange.push(i);
}


splitManagerFactory.$inject = ['c', 'utils'];
export function splitManagerFactory(c, utils) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new SplitManager(options);
  }


  function SplitManager(options) {
    var self = this;
    var metadata = options.metadata, taskUtils = options.taskUtils;
    let knownDisplayNames = [];

    metadata.forEach(function(c){
      c.display_name_w_type = utils.sanitizeData(c.display_name_w_type)
      knownDisplayNames.push(c.display_name);
    });

    self.getParam = getParam;
    self.validate = validate;
    self.setParam = setParam;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.handlePasteParams = handlePasteParams;

    self.metadata = metadata;
    self.filterManager = null;
    self.source_column_options = [];
    self.highlightColumns = highlightColumns;

    self.context = options.context

    function _get_new_column_param(val: any = undefined){
      let allNames =  [];
      allNames.concat(knownDisplayNames);

      if(self.param && self.param.SPLIT && self.param.SPLIT.AS){
        $.each(self.param.SPLIT.AS, function(i, s){
          if(s.COLUMN){
            allNames.push(s.COLUMN);
          }
        });
      }

      if(!val){
        val = 1;
      }
      let cName;
      while (true){
        cName = "New Column " + (val);
        if(allNames.indexOf(cName) == -1){
          break;
        }
        val++;
      }
      return {
        COLUMN: cName
      }
    }

    self.param = {
      SPLIT: {
        SOURCE: undefined,
        DELIMITER: "",
        AS: [_get_new_column_param(1), _get_new_column_param(2)]
      }
    };

    self.result_column = {
      reset: reset_result_columns_count,
      count: 2,
      range: colRange
    };

    angular.forEach(metadata, function (col) {
      if (col.type === c.text) {
        self.source_column_options.push(col);
      }
    });

    function reset_result_columns_count() {
      if (self.result_column.count > self.param.SPLIT.AS.length) {
        var diff = self.result_column.count - self.param.SPLIT.AS.length;
        for(var i = 0; i < diff; i++){
          self.param.SPLIT.AS.push(_get_new_column_param());
        }
      }

      if (self.result_column.count < self.param.SPLIT.AS.length) {
        var i: number = self.result_column.count;
        self.param.SPLIT.AS.splice(i, self.param.SPLIT.AS.length);
      }
    }

    function validate() {
      if (self.param.SPLIT.SOURCE){
        var col_info = utils.metadata.get_column_by_internal_name(metadata, self.param.SPLIT.SOURCE);
        if (!col_info){
          self.error = 'select the column';
          return false;
        }else if(col_info['type']!='TEXT'){
          self.error = 'select column of type TEXT';
          return false;
        }
      }
      return true;
    }

    function handlePasteParams(taskInfo){
      /** Update source params with suitable replacement column, based on display name*/
      var params = taskInfo.params
      utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.SPLIT, 'SOURCE', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      return params
    }
    function getParam() {
      if (self.context.inEditMode && self.context.task){
        _.forEach(self.param.SPLIT.AS, function(item){
          utils.sanatizeParamForDuplicateCols(item,'INTERNAL_NAME', self.context.task)
          })
      }
      if(self.context.hasOwnProperty('sequence')){
        self.param['SEQUENCE_NUMBER'] = self.context.sequence
      }
      return self.param;
    }

    function highlightColumns() {
      taskUtils.highlight.sources(self.param.SPLIT.SOURCE);
    }

    function setParam(param) {
      self.param.SPLIT.SOURCE = param.SPLIT.SOURCE;
      self.source_column = utils.metadata.get_column_by_internal_name(metadata, param.SPLIT.SOURCE);
      var src_col_in_options = utils.metadata.get_column_by_internal_name(self.source_column_options, param.SPLIT.SOURCE);
      if (!src_col_in_options){
        self.source_column_options.push(self.source_column)
      }
      highlightColumns();
      if (param.SPLIT.DELIMITER) {
        self.param.SPLIT.DELIMITER = param.SPLIT.DELIMITER;
      }
      if (param.SPLIT.AS) {
        self.param.SPLIT.AS = param.SPLIT.AS;
        self.result_column.count = self.param.SPLIT.AS.length;
      }
    }
  }
}

