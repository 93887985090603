export const LOGIN_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success'
}

export const LOGIN_STATUS_REASONS = {
  OTP_REQUIRED: 'otp_required'
}

export const LANDING_PAGE_PATH = '#/landing'

// ToDO: remove after complete removal of angular code
export function getOldAppUrl() {
  return `//${window.location.host}`
}
