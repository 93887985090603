import * as $ from 'jquery';
import * as angular from 'angular';
import * as _ from 'lodash-es';

/**
 * @ngInject
 */
JoinManagerFactory.$inject = ['DatasourceService', 'utils', 'DataviewService', '$timeout', 'dataviewConfig','$sce', 'c', '$resource', 'config', '$q', 'FutureService'];
export function JoinManagerFactory(DatasourceService, utils, DataviewService, $timeout, dataviewConfig,  $sce, c, $resource, config, $q, FutureService) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new JoinManager(options);
  }

  function JoinManager(options){
    var self = this;
    self.metadata = utils.sanitizeMetadata(options.metadata);
    self.dataview = options.context.dataview;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap;
    self.validate = validate;
    self.getParam = getParam;
    self.setParam = setParam;
    self.handlePasteParams = handlePasteParams;
    self.addJoinMap = addJoinMap;
    self.foreignViewMissing = false;
    self.removeJoinMap = removeJoinMap;
    self.reEvalJoinMaps = reEvalJoinMaps;
    self.updateRightKeyCandidates = updateRightKeyCandidates;
    self.joinId = utils.string.random(5).toLowerCase();
    self._isSourceForeignColumnCombinationInvalid = _isSourceForeignColumnCombinationInvalid;
    self.isNewJoinMapAllowed = false;

    self.joinMaps = [new JoinMap()];
    self.rightDataview = _rightDataviewGettterSetter;
    self.delayedRefreshGrid = delayedRefreshGrid;
    self._rightDataviewUpdated = _rightDataviewUpdated;
    self._rightDataview = null;
    self.originalSelectParam = null; //used while edits are done.
    self.joinType = 'LEFT';
    self.allowedJoinTypes = [
      'LEFT', 'RIGHT', 'INNER', 'OUTER'
    ];
    self.resultColumns = new ResultColumns();
    self.selectMenuOpen = false;
    self.toggleSelectMenu = toggleSelectMenu;
    self.c = c;
    self.dependentDataviews = [];
    let updated_left_wksp = null;

    self.capitalizer = utils.string.capitalize;

    var helperOptions: any = {
      sourceDataview: {
        dataviewId: self.dataview.id
      },
      rightDataview: {
        dataviewId: undefined
      },
      joinMaps: self.joinMaps
    };
    if (options?.context?.inEditMode && options?.context?.sequence){
      helperOptions.sourceDataview['sequence_number'] = options.context.sequence - 1
    }
    self.taskHelper = options.taskHelperService.initHelper(dataviewConfig.tasks.join, helperOptions);

    let wsResource =  $resource(config.api.dataviews);
    var deferred = $q.defer();
    var ws_request_param = {id: self.dataview.id}  
    if (options?.context?.inEditMode && options?.context?.sequence){
      var ws_request_sequence = options.context.sequence
      if (options.context.sequence > 0){
        // Adjust the sequence so that data is fetched for the step before 
        ws_request_sequence -=1
      }
      ws_request_param[c.sequenceNumber] = ws_request_sequence
    }
    wsResource.get(ws_request_param).$promise.then(function (response) {
      get_data_tracker(response).then(function (data) {
        updated_left_wksp = data;
        _updateWksplist();
        DatasourceService.on_list_update('ForJoinUI', _updateWksplist);
        deferred.resolve(data);
      }, function (){
        _updateWksplist();
      });
    }, deferred.reject);

    function get_data_tracker(data) {
      var deferred = $q.defer();
      FutureService.track(data.future_id, data_tracker);
  
      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }
      return deferred.promise;
    }

    function toggleSelectMenu(isOpen) {

      if (isOpen !== self.selectMenuOpen) {
        isOpen = true;
      }
      else {
        isOpen = false;
      }
      self.selectMenuOpen = isOpen;
    }

    function _updateWksplist(){
      self.dataviews = [];
      self.dependentDataviews = [];
      var currentWkspExists = false;
      $.each(Object.keys(DatasourceService.list), function(i, object_id) {
        var item = DatasourceService.list[object_id];

        if (item.type == 'datasource') {
          _.forEach(item.dataviews, function (ws:any, ws_id) {
              if (ws_id != options.context.dataview.id) {
                if(updated_left_wksp && updated_left_wksp.all_dependent_dataview_ids.includes(parseInt(ws_id))){
                    self.dependentDataviews.push(parseInt(ws_id));
                }
                ws.display_name_for_join = utils.sanitizeData(item.name + ' → ' + ws.name);
                if(self._rightDataview && (ws.id == self._rightDataview.id)){
                  currentWkspExists = true;
                  self.dataviews.push(self._rightDataview);
                }
                else{
                  self.dataviews.push(ws);
                }
              }
          });
        }
      });
      
      if(!currentWkspExists && !self.foreignViewMissing){
        self.rightOnColumn = null;
        self._rightDataview = null;
        self.originalSelectParam = null; //used while edits are done.
      }
      else{
        if(self._rightDataview.metadata.indexOf(self.rightOnColumn) == -1){
          self.rightOnColumn = null;
        }
      }
    }

    function _isSourceForeignColumnCombinationInvalid(left, right) {
      let is_invalid = false;
      // Error case is XOR of left drop down having date column and right drop down having date column selected
      // This can be derived by making the following allowed cases and inverting them:
      // (TEXT, TEXT), (TEXT, NUM), (NUM, TEXT), (NUM, NUM), (DATE, DATE)
      if (left?.type != c.date && right?.type == c.date || left?.type == c.date && right?.type != c.date) {
        is_invalid = true;
      }
      return is_invalid;
    }

    function validate(){
      var is_valid = true
      _.forEach(self.joinMaps, function (jm){
        // sentry error fixed: accessing left and right join even when not initiated 
        if (jm.left && jm.left.hasOwnProperty('error')){
          is_valid=false
        }
        else if(jm.right && jm.right.hasOwnProperty('error')){
          is_valid=false
        }
        else if (jm.left && jm.right && _isSourceForeignColumnCombinationInvalid(jm.left, jm.right)) {
          is_valid = false
        }
      })
      _.forEach(self.resultColumns.rightMetadata, function(col_info){
        if (col_info.hasOwnProperty('error') && col_info.selected){
          is_valid = false
        }
      })
      if (self._rightDataview && self._rightDataview.pipeline_status == 'error'){
        is_valid = false
      }
      return is_valid
    }

    function getParam(){
      var joinMapsParams = [];
      angular.forEach(self.joinMaps, function(jMap){
        joinMapsParams.push(jMap.getParam())
      });

      var param: any = {
        JOIN: {
          DATAVIEW_ID: self._rightDataview.id,
          ON: joinMapsParams,
          TYPE: self.joinType,
          JOIN_ID: self.joinId,
          COLUMN_PREFIX : self.resultColumns.columnPrefix
        }
      };
      if(!self.resultColumns.allSelected){
        param.JOIN.SELECT = self.resultColumns.getParam();
      }

      if(options.context.hasOwnProperty('sequence')){
        param['SEQUENCE_NUMBER'] = options.context.sequence;
      }
      return param;
    }

    function handlePasteParams(taskInfo){
      /** Update key columns suitable replacement columns, based on display name*/
      var params = taskInfo.params;
      for(const index in params.JOIN.ON){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.JOIN.ON[index], 'LEFT', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }

      // Remove SELECT param ALIASES from being carried over (O(n) operation)
      // Each copy/paste operation is a new rule, so it should reflect new fetched column names everytime
      for (let selectParam of params.JOIN.SELECT) {
        if (selectParam.hasOwnProperty('ALIAS')) {
          delete selectParam.ALIAS;
        }
      }
      params.JOIN.JOIN_ID = utils.string.random(5).toLowerCase()
      params.DATAVIEW_ID = self.dataview.id;
      return params
    }

    function setParam(param) {
      var rightDataview = _.cloneDeep(DataviewService.get_by_id(param.JOIN.DATAVIEW_ID))
      // To ensure the taskwise info is available to the right wksp make a get data call and proceed with rest of the work
      // This needs to be done because we removed taskwise info from ws core list object hence taskwise info is not always avilable in ws object sitting in wsSerice's list
      if (rightDataview){
        let pipelineResource =  $resource(config.api.pipelineInfo);
        pipelineResource.get({ws_id: rightDataview.id}).$promise.then(function (response) {
          rightDataview['taskwise_info'] = response['taskwise_info']
          setParamCallback()
        })
      }else{
        setParamCallback()
      }
      
      function setParamCallback() {
        var task = options.context.task
        if (rightDataview){
          rightDataview.display_name_for_join = utils.sanitizeData(rightDataview.datasource.name + ' → ' + rightDataview.name);

          // We fetch latest tasks's metadata from right dataview since it considers pending changes like draft mode too
          let rightDataviewTaskwiseInfoMetadata = rightDataview.taskwise_info[rightDataview.tasks_total_count].metadata

          // Iterate taskwise info array of dictionaries and overwrite the taskwise info metadata with the metadata from ws info
          for (let col of rightDataviewTaskwiseInfoMetadata) { 
            let existing_col = utils.metadata.get_column_by_internal_name(rightDataview.metadata, col.internal_name)
            if (existing_col){
              _.merge(col, existing_col)
            }
          }
          
          rightDataview.metadata = rightDataviewTaskwiseInfoMetadata
          rightDataview.metadata = utils.metadata.add_type_to_display_name(rightDataview.metadata)
          self.foreignViewMissing = false;
        }else{
          if (task && task.hasOwnProperty('has_referror') && task.has_referror && task.reference_errors.type == 'referror') {
            _.forEach(task.reference_errors.reference_errors, function (referror_info) {
              if (referror_info.error_code ==  7007){
                rightDataview = {
                  id: param.JOIN.DATAVIEW_ID,
                  name: referror_info.dependency_info.name,
                  display_name_for_join: referror_info.dependency_info.name,
                  metadata:  utils.metadata.add_type_to_display_name(referror_info.dependency_info.metadata),
                  pipeline_status: 'error'
                  
                }
                self.foreignViewMissing = true;
              }
            })
          }
        }
        var sequence_number = null;
        if(options.context.hasOwnProperty('task') && options.context.task && options.context.task.hasOwnProperty('sequence')){
          sequence_number = options.context.task.sequence;
          
          if (task && task.hasOwnProperty('has_referror') && task.has_referror && task.reference_errors.type == 'referror') {
            var reference_errors = task.reference_errors.reference_errors;
            _.forEach(reference_errors, function (referror_info) {

              if (referror_info.reason == "not available" && referror_info.dataview == param.JOIN.DATAVIEW_ID) {
                var col = referror_info.column;
                col =  utils.metadata.add_type_to_display_name_in_column(col)
                col['error'] = referror_info.reason;
                let existing_col = utils.metadata.get_column_by_internal_name(rightDataview.metadata, col.internal_name)
                if (!existing_col){
                  rightDataview.metadata.push(col);
                }
              }
            });
          }
        }
        if (sequence_number){
          helperOptions.sourceDataview.sequence_number = sequence_number - 1;
        }
        helperOptions.rightDataview.dataviewId =  param.JOIN.DATAVIEW_ID

        _rightDataviewGettterSetter(rightDataview, function(){
          _setJoinMap(param.JOIN.ON);
          self.resultColumns.setParam(param.JOIN.SELECT);
          self.resultColumns.columnPrefix =utils.sanitizeData(param.JOIN.COLUMN_PREFIX);
          self.resultColumns.applyChangesInSourceMaps(self.joinMaps);
          self.resultColumns.checkForColumnTypeSelected();
          updateRightKeyCandidates();
        });
        if(param.JOIN.JOIN_ID){
          self.joinId = param.JOIN.JOIN_ID;
        }
        self.joinType = param.JOIN.TYPE;
        self._rightDataviewUpdated();
        }
    }

    function _setJoinMap(onParams){
      self.joinMaps = [];

      angular.forEach(onParams, function(onParam){
        var joinMap = new JoinMap()
        var rightCol = $.grep(self._rightDataview.metadata, function (col: any) {
          return col.internal_name == onParam.RIGHT;
        });
        var leftCol = $.grep(self.metadata, function (col: any) {
          return col.internal_name == onParam.LEFT;
        });

        if(rightCol.length){
          joinMap.right = rightCol[0];
        }
        if(leftCol.length){
          joinMap.left = leftCol[0];
        }

        self.joinMaps.push(joinMap);
      })
    }

    function addJoinMap(){
      reEvalJoinMaps();
      if(self.isNewJoinMapAllowed){
        self.joinMaps.push(new JoinMap());
        _updateJoinMaps();
      }
    }

    function removeJoinMap(joinMap){
      if(self.joinMaps.length == 1){
        return;
      }
      restoreKeysColumns(joinMap); //make removed key column importable
      self.joinMaps = $.grep(self.joinMaps, function (jm) {
        return jm != joinMap;
      });
      _updateJoinMaps();
      self.resultColumns.selectedColumnList();
    }

    function restoreKeysColumns(joinMap){
      /*
      This function makes removed key column available as
      importable column by un-hiding itself.
       */
      angular.forEach(self.joinMaps, function(jm) {
        if(!joinMap.right){ //if join map is empty then quit!
          return;
        }
        if (_.get(joinMap,'right.internal_name') == _.get(jm, 'right.internal_name')) {
          angular.forEach(self.resultColumns.rightMetadata, function (col) {
            if (col.internal_name == joinMap.right.internal_name) {
              col.hidden = false;
            }
          });
        }
      });
    }

    function reEvalJoinMaps(){
      let allowNew = true;
      angular.forEach(self.joinMaps, function(jm, i){
        allowNew = allowNew && jm.left && jm.right;
      });
      self.isNewJoinMapAllowed = allowNew;

    }


    function _rightDataviewGettterSetter(value, getDataCb){
      if(value === self._rightDataview){
        return;
      }
      else if(value){
        self._rightDataview = value;
        self.joinMaps.forEach(function(jm){
          // When rightdataview is changed try if column mapping can be retained
          if(jm.right!=null){          
            var maching_column = null;
            for (var col of value.metadata){
              if (col.display_name_w_type == jm.right.display_name_w_type){
                  maching_column = col
                  break
              }
            }
            if (maching_column){
              jm.right = maching_column;
            }else{
              jm.right = null;
            }
          }
        });
        //Why making get data  request when not used or required. Making request overwrites the changes done to metadata of the dataview for missing columns
        // value.get_data().then(fullCb);
        if(!getDataCb){
            getDataCb = function(){};
        }
        self.resultColumns.handleRightWsChange();
        getDataCb();
      }
      else{
        return self._rightDataview;
      }
    }

    function _rightDataviewUpdated() {
      $timeout(function () {
        helperOptions.rightDataview.dataviewId = self._rightDataview.id;
        _updateJoinMaps();
      }, 300);
    }

    function _updateJoinMaps() {
      self.taskHelper.options.joinMaps = self.joinMaps;
      self.taskHelper.refreshGrids();
      delayedRefreshGrid();
      reEvalJoinMaps();
      updateRightKeyCandidates();
    }
    function updateRightKeyCandidates(){
      _.forEach(self.joinMaps, function (jm) {
        if (jm.left) {
          // Reset right drop-down if date column was selected in right drop down but left drop down was changed to a non-date column
          if (jm.right && _isSourceForeignColumnCombinationInvalid(jm.left, jm.right)) {
            jm.right = null;
          }

          // Allow same datatypes to exist, with an addition of text along with numerics
          jm.right_key_candidates = utils.sanitizeMetadata(_.filter(self._rightDataview.metadata, function (right_key) {
            return !_isSourceForeignColumnCombinationInvalid(jm.left, right_key);
          }))
          
          var right_candidate_internal_names = _.map(jm.right_key_candidates, 'internal_name');
          //If a column mentioned in jm but not in the candidates its possible a case of error. Add the missing column
          //To be able to display the user's choice
          if (jm.right && right_candidate_internal_names.indexOf(jm.right.internal_name) == -1) {
            jm.right_key_candidates.push(jm.right);

          }
        }
      });
    }

    function delayedRefreshGrid() {
      $timeout(self.taskHelper.refreshGrids, 200);
    }

    function JoinMap(){
      var jm = this;
      jm.right = null;
      jm.left = null;
      jm.getParam = function(){
        return {LEFT: jm.left.internal_name, RIGHT: jm.right.internal_name};
      }
      jm.right_key_candidates = []
    }

    function ResultColumns(){
      var rc = this;
      rc.allSelected = false;
      rc.selected = [];
      rc.toggleAll = toggleAll;
      rc.selectAll = selectAll;
      rc.deselectAll = deselectAll;
      rc.colTypeSelection = {NUMERIC: false, TEXT: false, DATE: false};
      rc.getParam = getParam;
      rc.setParam = setParam;
      rc.isAllSelected = isAllSelected;
      rc.applyChangesInSourceMaps = applyChangesInSourceMaps;
      rc.handleRightWsChange = handleRightWsChange;
      rc.selectAllColsCheckbox = false;
      rc.columnPrefix = "";
      rc.originalColumns = [];
      rc.selectedColumnList = selectedColumnList;
      rc.selectedColumnNames = "";
      rc.colListMessage ="0 column selected"; //default message when modal opens for first time
      rc.checkForColumnTypeSelected = checkForColumnTypeSelected;
      rc.internalNameToAliasMapping = {};

      function checkForColumnTypeSelected(){
        var selectedCount = {
          "TEXT" : {
            selected : 0,
            count : 0
          },
          "NUMERIC" : {
            selected : 0,
            count : 0
          },
          "DATE" : {
            selected : 0,
            count : 0
          }
        }
        rc.rightMetadata.forEach(function (col) {
          if(!col.hidden) {
            selectedCount[col.type].count++;
          }
          if (col.selected && !col.hidden){
            /* Don't count key column and as key
            columns are assumed to hidden, check for
            hidden flag.
             */
            selectedCount[col.type].selected++;
          }
        });
        _.forEach([self.c.text,self.c.numeric,self.c.date], function(type){
          if (selectedCount[type].selected == selectedCount[type].count && selectedCount[type].count != 0) {
            rc.colTypeSelection[type] = true;
          } else {
            rc.colTypeSelection[type] = false;
          }
        });
        
        if (rc.colTypeSelection["TEXT"] && rc.colTypeSelection["NUMERIC"] && rc.colTypeSelection["DATE"]){
          rc.selectAllColsCheckbox = true
        }
      }
      function selectedColumnList(){
        /* this function returns list of all selected columns names
        and message to be displayed in tooltip containing same column
        names.
         */
        let result = [];
        var names = "";
        angular.forEach(rc.rightMetadata, function(col){
          if(col.selected && !col.hidden){
            result.push(col.display_name);
            names=  names.concat(col.display_name + '<br>');
          }
        });
        rc.selectedColumnNames = $sce.trustAsHtml(names);
        let listLen = result.length;
        if (listLen == 0){
          rc.colListMessage = "No column is selected";
          rc.selectAllColsCheckbox = false;
        }
        else {
          rc.colListMessage = listLen + (listLen > 1 ? ' columns' : ' column') + ' selected';
        }
        rc.checkForColumnTypeSelected();
      }

      function getParam() {
        if(rc.allSelected){
          return "ALL";
        }

        let result = [];
        angular.forEach(rc.rightMetadata, function(col){
          if (col.selected && !col.hidden) {
            let resultParam = { COLUMN: col.internal_name };
            if (rc.internalNameToAliasMapping[col.internal_name]) {
              resultParam['ALIAS'] = rc.internalNameToAliasMapping[col.internal_name];
            }
            else {
              let rightDataviewColumns = self._rightDataview?.display_properties?.COLUMN_NAMES;
              if (rightDataviewColumns && col.internal_name in rightDataviewColumns) {
                resultParam['ALIAS'] = rightDataviewColumns[col.internal_name];
              } else {
                resultParam['ALIAS'] = col.display_name;
              }
            }
            result.push(resultParam);
          }
        });
        return result;
      }

      function toggleAll(flag){
        angular.forEach(rc.rightMetadata, function(col) {
          if (!col.hidden) {
          col.selected = flag;
        } else {
            col.selected = false;
          }
        });
        rc.selectedColumnList();
      }

      function isAllSelected(){
        if (!rc.getParam() || !rc.rightMetadata){
          return false;
        }
        if ( (rc.getParam().length == rc.rightMetadata.length) ||
          rc.getParam() == 'ALL'){
          return true;
        }
        else {
          return false;
        }
      }

      function selectAll(colType){
        if (!colType){
          toggleAll(true);
          rc.colTypeSelection = _.mapValues(rc.colTypeSelection, () => true);
          rc.selectAllColsCheckbox = true;
          return;
        }
        rc.colTypeSelection[colType] = !rc.colTypeSelection[colType];
        angular.forEach(rc.rightMetadata, function(col){
          if ((colType == col.type)){
            /* MAP all type of columns (as specified by colType)
            into selected/de-selected, rest of types become deselected.
            */
              col.selected = rc.colTypeSelection[colType];
          } else if(rc.colTypeSelection[col.type]){
            /*
            Make sure other column types which are chosen
            should remain selected too.
            */
            col.selected = true;
          }
          else {
            col.selected = false;
          }
          for (var i=0; i<rc.colTypeSelection.length; i++){
            if(rc.colTypeSelection[i] == colType)
              continue;
            rc.colTypeSelection[i] = true;
          }
          });
        rc.selectedColumnList();
      }
      function deselectAll(){
        toggleAll(false);
        rc.colTypeSelection = _.mapValues(rc.colTypeSelection, () => false);
        rc.selectAllColsCheckbox = false;
      }
      function setParam(param: any){
        if(param == "ALL" || !angular.isArray(param)){
          rc.allSelected = true;
          rc.toggleAll(true);
        }
        else{
          rc.allSelected = false;
          let selected = new Set();
          angular.forEach(param, function (p) {
            selected.add(p.COLUMN);
          });
          angular.forEach(rc.rightMetadata, function(col){
            col.selected = selected.has(col.internal_name);
          });
          rc.selectedColumnList();
        }

        for (let currentColumn of param) {
          if (currentColumn.hasOwnProperty('ALIAS')) {
            rc.internalNameToAliasMapping[currentColumn.COLUMN] = currentColumn.ALIAS;
          }
        }

        let result = [];
        angular.forEach(rc.rightMetadata, function(col){
          if (col.selected) {
            let resultParam = { COLUMN: col.internal_name };
            if (rc.internalNameToAliasMapping[col.internal_name]) {
              resultParam['ALIAS'] = rc.internalNameToAliasMapping[col.internal_name];
            }
            result.push(resultParam);
          }
        });
        return result;
      }

      function handleRightWsChange(){
        // Keep a copy of current metadata
        var rightMetadataCopy = _.cloneDeep(rc.rightMetadata)
        // Update resultsColumn's right metadata
        rc.rightMetadata = _.cloneDeep(self._rightDataview.metadata);
       
        // upon right ws change try to retain the column selections
        if (rightMetadataCopy){
          for(var c of rightMetadataCopy){
            if (c.hasOwnProperty('selected') && c.selected){
              for(var cc of rc.rightMetadata){
                if (cc.display_name_w_type == c.display_name_w_type){
                  cc.selected = true
                }
              }
            }
          }
        }
        if (!options?.context?.inEditMode){
          rc.columnPrefix = $("<p/>").html(_.get(self._rightDataview, 'datasource.name', '')).text();;
        }
        rc.originalColumns = _.cloneDeep(self._rightDataview.metadata);
        // The following will update the message that summarizes column selections. Otherwise the message remains stale.
        rc.selectedColumnList();
      }

      function applyChangesInSourceMaps(joinMaps){
        let selectedRightCols = [];

        angular.forEach(joinMaps, function(jm){
          if (jm.right) {
            selectedRightCols.push(_.get(jm,'right.internal_name'));
          }
        });
        angular.forEach(rc.rightMetadata, function(col){
          col.hidden = selectedRightCols.indexOf(col.internal_name) != -1;
          if (_.get(col,'internal_name') == _.get(joinMaps[0],'right.internal_name')) {
            col.selected = false;
          }
          if(col.hidden) {
            col.selected = false;
          }
        });
        selectedColumnList();
      }
    }
  }
}

export function valJoinMap() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valJoinMap(scope, elem, attrs, ctrl) {
      ctrl.$validators.valJoinMap = function (modelValue, viewValue) {
        var col_side = scope.$eval(attrs.colSide)
        var is_valid = true;
        if (modelValue && scope.joinMap.left && scope.joinMap.right) {

          if (col_side == 'left'&& modelValue.type != scope.joinMap.right.type) {
            is_valid = false;
          }else  if (col_side == 'right' && modelValue.type != scope.joinMap.left.type) {
            is_valid = false;
          }
          else if(modelValue.hasOwnProperty('error')){
            is_valid = false
          }
        }
        return is_valid
      };
    }
  };
}
