<template>
  <div class="mm-data-addition-third-party-metrics-checkbox-list height-100">
    <div v-if="isLoading" class="loading height-100">
      <mm-loader class="m-x-auto" size="large" />
    </div>

    <mm-row v-else class="height-100" no-gutters>
      <mm-col cols="6" md="3" class="height-100 options-col">
        <span class="mm-text--body-bold">{{ $t('data_library.data_addition.third_party.list_of_options') }}</span>
        <mm-text-input v-model="search" class="m-b-2" type="search" hide-details />

        <div class="option-list">
          <ul class="basic-list">
            <li
              v-for="option in computedOptions"
              :key="option.value"
              :active="option.value == selectedOption.value"
              @click="selectOption(option)"
            >
              {{ option.name }}
            </li>
          </ul>
        </div>
      </mm-col>
      <mm-col class="option p-t-4 p-x-4">
        <div class="option-items">
          <span class="mm-text--h300">{{ $t('data_library.data_addition.third_party.steps.3') }}</span>
          <mm-divider class="m-b-3" />
          <span v-if="!selectedOption.items">{{ $t('data_library.data_addition.third_party.select_option') }}</span>

          <mm-row no-gutters>
            <mm-col v-for="item in selectedOption.items" :key="item.value" cols="4">
              <mm-checkbox v-model="item.selected" class="m-a-2" :label="item.name" :disabled="!item.selectable" />
            </mm-col>
          </mm-row>
        </div>

        <div class="actions d-flex m-t-4 m-b-2">
          <mm-button :label="$t('global.dictionary.back')" objective="tertiary" @click="$emit('back')" />
          <mm-button
            class="m-l-auto"
            :label="$t('global.dictionary.next')"
            :disabled="!selectedOption.value"
            @click="submit"
          />
        </div>
      </mm-col>
    </mm-row>
  </div>
</template>

<script>
// Constants
import { USER_EVENTS } from '@/constants'

// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

export default {
  name: 'data-library-data-addition-third-party-metrics-checkbox-list',
  props: {
    connector: {
      type: Object,
      required: true
      /*{
        key: string,
        name: string,
        icon: string,
        category: string,
        connection: {...},
        metrics: {...}
      }*/
    },
    connection: {
      type: Object,
      required: true
      /*{
        value: string,
      }*/
    }
  },
  data: () => ({
    isLoading: true,
    metricsInfo: {},
    search: '',
    selectedOption: {}
  }),
  computed: {
    computedOptions() {
      return this.metricsInfo?.options
        .filter((o) => o.name.toLowerCase().includes(this.search.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  async created() {
    this.metricsInfo = await dataLibraryApi.getMetricsInfo(
      this.connector.hash,
      this.connection.value,
      this.$store.state.projectId
    )

    if (!this.metricsInfo.options) {
      if (this.metricsInfo.message) this.$toast.show({ content: this.metricsInfo.message, status: 'error' })
      this.$emit('back')
    }

    this.isLoading = false
  },
  methods: {
    submit() {
      this.$emit('submit', {
        table: {
          ...this.selectedOption,
          selectedMetrics: {
            [this.selectedOption.key]: this.selectedOption.items.filter((i) => i.selected).map((i) => i.value)
          },
          scheduleType: this.selectedOption.value == 'user_profiles' ? 'MOMENT' : 'PERIOD'
        }
      })
    },
    selectOption(option) {
      this.selectedOption = option
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.OPEN_TABLE, { option })
    }
  }
}
</script>

<style lang="scss" scoped>
.mm-data-addition-third-party-metrics-checkbox-list {
  max-height: -webkit-fill-available;
  overflow: auto;

  .loading {
    align-items: center;
    display: flex;
  }

  .options-col {
    flex-direction: column;
    display: flex;

    .option-list {
      overflow: auto;

      li {
        white-space: nowrap;
      }
    }
  }

  .option {
    flex-direction: column;
    display: flex;
    height: 100%;

    .option-items {
      flex-direction: column;
      display: flex;
      min-height: 0;

      .mm-row {
        overflow: auto;
        min-height: 0;
        flex-grow: 1;

        ::v-deep .mm-checkbox--label {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .actions {
    align-items: end;
    flex-grow: 1;
  }
}
</style>
