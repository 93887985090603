import * as $ from 'jquery';
import * as _ from 'lodash-es';
import {config} from "../common/app.config";

/**
 * @ngInject
 */
datasourcePreviewPanel.$inject =['$compile'];
export function datasourcePreviewPanel($compile){
  return {
    controller: 'dsPreviewController',
    controllerAs: 'pvm',
    bindToController: true,
    link: function (scope, element, attr) {
      scope.pvm.init();
      scope.$watch('pvm.datasource.total_rows', function (count) {
        if (count) {
          scope.pvm.refreshBatchInfo();
        }
      });


      var templateUrl = config.templates.dsPreview;
      var template = '<ng-include src="\'' + templateUrl + '\'"></ng-include>';
      var compiled = $compile(template)(scope);
      element.html(compiled);
    }
  };
}
/**
 * @ngInject
 **/
lineItem.$inject = ['utils', 'moment', '$timeout', 'c', 'DatasourceService', 'fileUploadService', '$animate',
  'landingSelection'];
export function lineItem (utils, moment, $timeout, c, DatasourceService, fileUploadService, $animate, landingSelection) {
    return {
      link: function (scope, element, attrs) {
        $animate.enabled(element, false);
        var highlight_class = 'highlight';
        var item = scope.item, prev_status,
          prev_list_upated_at = DatasourceService.list_last_updated_at;

        if (item.type == c.resourceTypes.ds) {
          DatasourceService.on_list_update('set_highlight_' + item.unique_id, check_new_and_set_highlight);
          check_new_and_set_highlight();
        }

        scope.$on('$destroy', function () {
          DatasourceService.remove_on_list_update('set_highlight' + item.unique_id);
        });
        /////
        function check_new_and_set_highlight() {
          if (prev_status != undefined && prev_status != c.dsStatus.ready && item.status == c.dsStatus.ready) {
            var updated_at = utils.parseDateMoment(item.updated_at);
            if (updated_at > prev_list_upated_at || item.updated_at == item.created_at) {
              set_highlight();
            }
          }
          prev_status = item.status;
          prev_list_upated_at = DatasourceService.list_last_updated_at;
        }

        function set_highlight() {
          if (item.source_type == 'file' && fileUploadService.lastFileUploadsCount == 0){
            fileUploadService.lastFileUploadsCount++
          }

          element.addClass(highlight_class);
          if ((fileUploadService.lastFileUploadsCount == 1 || item.source_type == "cloud") &&
            landingSelection.selection.items.length == 0) {
            landingSelection.selectResourceDebounced(item);
          }
          $timeout(function () {
            element.removeClass(highlight_class);
            if (landingSelection.selection.items.length == 0) {
              // scope.vm.landingSelection.selectResourceDebounced(item);
            }
          }, 2000);
        }
      }
    };
}


selectResource.$inject = ['$timeout'];
export function selectResource($timeout) {
  return {
    link: function (scope, element, attrs) {
      let registered = false;
      let firstClickTimedOut = true;
      let clickPromise = undefined;
      register_listener();

      function register_listener() {
        if (registered) { return; }
        // element.click(on_click);
        scope.$on('$destroy', deregister);
        element.on("dragstart", onDragstart);
        element.on("dragstop", onDragstop);
        element.on("mouseup", onMouseup);
      }

      function onDragstart(event, ui) {

      }

      function onDragstop(event, ui) {

      }

      function onMouseup(event) {
        on_click();
      }

      function deregister() {
        element.off("click");
      }

      function on_click() {
        $timeout.cancel(clickPromise);
        if (!firstClickTimedOut) {
          return;
        }
        firstClickTimedOut = false;
        clickPromise = $timeout(function () {
          firstClickTimedOut = true;
          scope.$evalAsync(attrs["selectResource"]);
        }, 200);
        $timeout(() => firstClickTimedOut = true, 200)
      }
    }
  };
}

export function showHideAsideSection(){
  return {
    link: function(s, e, a){
      var upButton, downButton, section;


      upButton = $(e).find('i.hide-button');
      downButton = $(e).find('i.show-button');

      function show(){
        section = $(e).find('.hide-able-content');
        downButton.hide();
        upButton.show();
        section.show();
      }

      function hide(){
        section = $(e).find('.hide-able-content');
        downButton.show();
        upButton.hide();
        section.hide();
      }


      $(upButton).click(hide);
      $(downButton).click(show);

      show();
    }
  }
}


export function listenForFileDragsIntoLanding(){
  return {
    link: function(scope, element, attrs){
      $(element).on('dragenter', function(e: any) {
        var dt = e.originalEvent.dataTransfer;
        if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files'))) {
          scope.vm.toggleDataPullDialog()
        }
      });
    }
  }
}


lineItemDraggable.$inject = ['$timeout', 'landingSelection', 'c', '$q'];
export function lineItemDraggable($timeout, landingSelection, c, $q) {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {
      const item = scope.item;
      let dragStartTimeoutPromise;
      var jqueryUIOptions = scope.$eval(attr.draggableOptions) || {};
      jqueryUIOptions['cursor'] = "-webkit-grab";
      jqueryUIOptions['cursorAt'] = { top: 15, left: -2 };
      jqueryUIOptions['containment'] = ".ds-wrapper .assets";
      jqueryUIOptions['delay'] = 200;
      jqueryUIOptions['distance'] = 15;

      // jqueryUIOptions['start'] = dragStart;
      // jqueryUIOptions['stop'] = dragStop;
      const isEnabled = scope.$eval(attr.lineItemDroppable);
      if(isEnabled){
        var directiveConfig = scope.$eval(attr.draggable) || {};
        elem.draggable(jqueryUIOptions);
        setDraggableHelper();
        elem.on("mousedown", onMouseDown);
        elem.on("mouseup", onMouseUp);
        elem.on("drag", drag);
        elem.on("dragstop", dragStop);
        scope.$on('$destroy', destroy);
      }

      function onMouseDown() {
        let draggingItems;
        if (landingSelection.selection.items.length > 1 && landingSelection.selection.items.indexOf(item) != -1) {
          draggingItems = _.clone(landingSelection.selection.items);
        } else {
          draggingItems = [item];
        }
        landingSelection.dragging.items = draggingItems;
      }

      function onMouseUp() {
        $timeout.cancel(dragStartTimeoutPromise);
        dragStop();
        $timeout(dragStop, 250);
      }

      function drag(e, data) {
        if (elem.data('ui-draggable').options.disabled) {
          return;
        }
        if ((Math.abs(data.deltaX) > 15 || Math.abs(data.deltaY) > 15) && !landingSelection.dragging.inProcess) {
          dragStart(e, data);
        }
      }
      function dragStart(e, data) {
        dragStartTimeoutPromise = $timeout(() => {
          landingSelection.lock();
          // landingSelection.dragging.items = draggingItems;
          landingSelection.dragging.inProcess = true;
          landingSelection.dragging.fireDragStart();
          // setDraggableHelper();
        }, 200);
      }

      function setDraggableHelper() {
        elem.draggable("option", "helper", function () {
          const container = $('<div class="line-item-drag-helper"></div>');
          _.forEach(landingSelection.dragging.items, function (_item) {
            const title = $('<div class="ds-title"></div>');
            if (_item.type == c.resourceTypes.file) {
              title.addClass('file-title');
            } else if (_item.type == c.resourceTypes.label) {
              title.addClass('folder');
            }
            title.append('<h5><a><span>' + _item.shortName + '</span></a></h5>');
            container.append($('<div class="ds-box"></div>').append(title))
          });
          return container;
        });
      }

      function dragStop() {
        if (landingSelection.dragging.inProcess) {
          landingSelection.dragging.fireDragStop();
        }
        $timeout(() => {
          landingSelection.dragging.items = [];
          landingSelection.dragging.inProcess = false;
        });
        $timeout(() => landingSelection.unlock(), 200);
      }

      function destroy() {
        elem.off("drag");
        elem.off("dragstart");
        elem.off("dragstop");
        if (elem.data('ui-draggable')) {
          elem.draggable( "destroy" );
        }
      }
    }
  }
}


lineItemDroppable.$inject = ['c', 'landingSelection', 'labelService', '$timeout', 'resources'];
export function lineItemDroppable(c, landingSelection, labelService, $timeout, resources) {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {
      const isEnabled = scope.$eval(attr.lineItemDroppable);
      const item = scope.item;
      let cancelDragStart, cancelDragStop;
      if (isEnabled) {
        elem.droppable({
          drop: onDrop,
          hoverClass: "droppable-hover",
          tolerance: "pointer"
        });
        scope.$watch(landingSelection.dragging.inProcess, checkAndDisable);
        cancelDragStart = landingSelection.dragging.onDragStart('lineItemDroppable' + item.resource_id, checkAndDisable);
        cancelDragStop = landingSelection.dragging.onDragStop('lineItemDroppable' + item.resource_id, checkAndDisable);
      }
      scope.$on('$destroy', destroy);

      function onDrop(event, ui) {
        if (item.type == c.resourceTypes.label) {
          const itemsToMove = landingSelection.dragging.items;
          if (itemsToMove.indexOf(item) !== -1) {
            return;
          }
          const resourceRIDs = _.map(itemsToMove, 'resource_id');
          labelService.move(item.resource_id, resourceRIDs).then((data) => {
            _.forEach(itemsToMove, (item) => {
              item.status = 'moving';
            });

            $timeout(() => {
              let res = resources.resourcesMap[item.resource_id];
              // landingSelection.selectResource(res, false);
            }, 1000);
          });
          landingSelection.dragging.fireDrop();
        }
      }

      function checkAndDisable() {
        if (!elem.data('ui-droppable')) {
          return;
        }
        if (landingSelection.dragging.inProcess) {
          if (landingSelection.dragging.items.indexOf(item) != -1) {
            elem.droppable("disable");
          } else {
            elem.droppable("enable");
          }
        } else {
          elem.droppable("enable");
        }
      }

      function destroy() {
        if (elem.data('ui-droppable')) {
          elem.droppable("destroy");
        }
        if (cancelDragStart) {
          cancelDragStart();
        }
        if (cancelDragStop) {
          cancelDragStop();
        }
      }
    }
  }
}
