import * as angular from 'angular';
/**
 * @ngInject
 * Task model
 */

TaskFactory.$inject =['$resource', 'config', '$q'];
export function TaskFactory($resource, config, $q) {
  var TasksResource = $resource(config.api.tasks,
    {task_id: '@id', ws_id: '@ws_id'}
  );
  return Task;

  function Task(task_data) {
    var self = this;
    self.update = _update_self;
    self.edit = _edit_task;
    self.suspend = _suspend_task;
    self.restore = restore;
    self.editDisplayInfo = _editTaskDisplayInfo;
    self.delete = deleteTask;
    _update_self(task_data);

    ////
    function _update_self(task_data) {
      angular.extend(self, task_data);
    }

    function _edit_task(new_param) {
      return TasksResource.patch({task_id: self.id, ws_id: self.ws_id}, {"patch": [{"op": "replace", "path": "params", "value": new_param}]}).$promise;
    }
    function _suspend_task(param) {
      return TasksResource.patch({task_id: self.id, ws_id: self.ws_id}, {"patch": [{"op": "suspend", "path": "status", "value": param}]}).$promise;
    }
    function restore(param) {
      return TasksResource.patch({task_id: self.id, ws_id: self.ws_id}, {"patch": [{"op": "restore", "path": "status", "value": param}]}).$promise;
    }

    function _editTaskDisplayInfo(new_display_info) {
      return TasksResource.patch({
        task_id: self.id,
        ws_id: self.ws_id
      }, {"patch": [{"op": "replace", "path": "display_info", "value": new_display_info}]}).$promise;
    }

    function deleteTask(skip_validation) {
      var task_resource_instance = new TasksResource();
      task_resource_instance.id = self.id;
      task_resource_instance.ws_id = self.ws_id;
      return task_resource_instance.$delete({'skip_validation': skip_validation});
    }
  }
}
