/*jslint node: true */
import './pivotChart.menu.manager'
import './funnelChart.menu.manager'

/**
 * @ngInject
 */
chartMenuManagerFactory.$inject = ['pivotChartMenuManagerFactory', 'funnelMenuManagerFactory', 'eventCallbackManagerFactory',
        'utils', 'chartThemes', 'c', 'analyticsService'];
export function chartMenuManagerFactory(pivotChartMenuManagerFactory, funnelMenuManagerFactory, eventCallbackManagerFactory,
        utils, chartThemes, c, analyticsService) {
  return {
    get: getMenu
  };

  function getMenu(options){
    return new ChartMenu(options)
  }

  function ChartMenu(options){
    var manager = this,
      unsubEvent;
    manager.random_name = 'chart_' + utils.getRandomString();
    var chartEvent = new eventCallbackManagerFactory(manager.random_name);

    // manager.isPivot = true;
    manager.getParam = wrap('getParam');
    manager.setParam = setParam;
    manager.getDisplayProperties = getDisplayProperties;
    manager.on_valid_update = chartEvent.add_callback;
    manager.openConditionMenu = wrap('openConditionMenu');

    manager.changeChartType = changeChartType;
    manager.addDrilldown = wrap('addDrilldown');
    manager.editDrilldown = wrap('editDrilldown');
    manager.deleteDrilldown = wrap('deleteDrilldown');
    manager.toggleDrilldown = wrap('toggleDrilldown');
    manager.pivotOptions = [
      {
        internal: true,
        display: 'values in a column'
      },
      {
        internal: false,
        display: 'multiple columns'
      }
    ];


    var chartToManagerTypeMap = {
      "pie": "AGGREGATION",
      "xy": "AGGREGATION",
      "funnel": "FUNNEL"
    };
    var chartToFactoryMap = {
      "pie": pivotChartMenuManagerFactory,
      "xy": pivotChartMenuManagerFactory,
      "funnel": funnelMenuManagerFactory
    };
    var ctype = undefined;
    if (options.element.display_properties && options.element.display_properties.chartType ) {
      ctype = options.element.display_properties.chartType;
    }

    initManager(ctype);

    Object.defineProperty(manager, 'currentDrillItem', {
      get: function(){
        if (manager.provider) {
          return manager.provider.currentDrillItem;
        }
      },
      set: function(value){
        if (manager.provider) {
          manager.provider.currentDrillItem = value;
        }
      }
    });

    Object.defineProperty(manager, 'drilldownManager', {
      get: function(){
        if (manager.provider) {
          return manager.provider.drilldownManager;
        }
      },
      set: function(value){
        if (manager.provider) {
          manager.provider.drilldownManager = value;
        }
      }
    });

    Object.defineProperty(manager, 'element', {
      get: function(){
        if (manager.provider) {
          return manager.provider.element;
        }
      }
    });

    function getDisplayProperties(){
      var dislayProperties = wrap('getDisplayProperties')();
      dislayProperties.theme = manager.selectedTheme.name;
      return dislayProperties
    }

    function subscribeManagerEvents() {
      if (unsubEvent) {
        unsubEvent();
      }
      unsubEvent = manager.provider.on_valid_update(manager.random_name + 'listener', function () {
        chartEvent.fire_event.apply(this, arguments);
      })
    }


    if(options.element.id){
      setParam(options.element.param);
    }


    function wrap(key){
      return function(parameter?){
        var fn = manager.provider[key];
       // TODO: SENTRYERROR:FRONTEND-KF:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/480137038
      return fn.apply(this, arguments);
      }
    }



    function initManager(type) {
      if (!manager.provider && type) {
        manager.provider = chartToFactoryMap[type].get(options);
        subscribeManagerEvents();
      }
      manager.themesAvailable = chartThemes.getThemes();
      manager.selectedTheme = manager.themesAvailable[0];
      if(options.element && options.element.display_properties && options.element.display_properties.theme){
        manager.selectedTheme = chartThemes.getThemeByName(options.element.display_properties.theme);
      }
    }

    function changeChartType(type) {
      if (!manager.provider || (manager.provider.managerType != chartToManagerTypeMap[type])) {
        manager.provider = chartToFactoryMap[type].get(options);
        subscribeManagerEvents();
      }
      var oldType = manager.provider.chart.display_properties.chartType;
      manager.provider.chart.display_properties.chartType = type;
      manager.provider.chart.handleTypeChange();
      analyticsService.userEventTrack(c.userEvents.elements.editor.changeChartType, {
        elementType: type, previousElementType: oldType
      });
    }

    function setParam(param){
      // manager.isPivot = false;
      if(param){
        // manager.isPivot = param.PIVOT.GROUP_BY.length > 0;
        wrap('setParam')(param);
      }
    }
  }
}
