import * as angular from 'angular';
import * as $ from 'jquery';
import { isEqual } from 'lodash';
import _ = require('lodash');

/**
 * @ngInject
 */
DataviewGlobalFilterService.$inject = ['eventCallbackManagerFactory', 'DataviewService', 'utils', 'c', 'VuexStore'];
export function DataviewGlobalFilterService(eventCallbackManagerFactory, DataviewService, utils, c, $store) {
  var registry = {};
  return {
    getByDataviewId: getByDataviewId
  };

  function getByDataviewId(dataviewId) {
    if (!registry.hasOwnProperty(dataviewId)) {
      registry[dataviewId] = new DataviewGlobalFilter(dataviewId);
    }
    return registry[dataviewId];
  }

  function DataviewGlobalFilter(dataviewId){
    var gf = this;
    gf.dataview = DataviewService.get_by_id(dataviewId);
    var changeEvent = new eventCallbackManagerFactory("DataviewGlobalFilter." + dataviewId);
    gf.isActive = false;
    gf.condition = undefined;
    gf.onChange = changeEvent.add_callback;

    gf.getCondition = getCondition;
    gf.setCondition = setCondition;
    gf.getOrderedColumnList = function () {
      return [];
    };
    gf.getContextualCondition = function () {
      return null;
    };
    gf.reconsile = function () {
      return null;
    };

    function getCondition(){
      return gf.condition;
    }

    function setCondition(condition){
      if(!isEqual(gf.condition, condition)) {
        gf.condition = condition;
        gf.isActive = !!condition;
        $store.commit('dataEditor/setFilterCondition', {viewId: dataviewId, filterCondition: condition});
        changeEvent.fire_event();
      }
    }

  }

  function DataviewGlobalFilter2(dataviewId){
    var gf = this;
    gf.dataview = DataviewService.get_by_id(dataviewId);
    var changeEvent = new eventCallbackManagerFactory("DataviewGlobalFilter." + dataviewId);
    gf._selected = {};
    gf._cache = {};
    var _known_type_map = {};
    gf._order = [];
    gf.isActive = false;

    gf.select = select;
    gf.deselect = deselect;
    gf.removeFromSelectedArray = removeFromSelectedArray;
    // gf.toggleSelection = toggleSelection;
    gf.getCondition = getCondition;
    gf.getContextualCondition = getContextualCondition;
    gf.deselectColumn = deselectColumn;
    gf.getSelectedByColumn = getSelectedByColumn;
    gf.getSelected = getSelected;
    gf.getOrderedColumnList = getOrderedColumnList;
    gf.deselectAll = deselectAll;
    gf.reconcile = reconcile;
    gf.saveConditions = saveConditions;
    // gf.refresh();

    gf.onChange = changeEvent.add_callback;

    function getSelectedByColumn(column_internal_name){
      return _.cloneDeep(gf._selected[column_internal_name] || []);
    }

    function getSelected(){
      return _.cloneDeep(gf._selected);
    }

    function getOrderedColumnList(){
      return _.cloneDeep(gf._order);
    }

    function deselectColumn(column_name){
      if(gf._selected.hasOwnProperty(column_name)){
        delete gf._selected[column_name];
        if(gf._order.indexOf(column_name) != -1){
          gf._order.splice(gf._order.indexOf(column_name), 1);
        }
        if(Object.keys(gf._selected).length == 0){
          gf.deselectAll();
        }
        else{
          _save(gf.getCondition());
        }
      }
    }

    function deselectAll(){
      gf._selected = {};
      gf._order = [];
      gf.isActive = false;
      _save(null);
    }

    function _save(condition){
      gf.isActive = !!condition;
      gf.dataview.setDisplayProperties({
        "GLOBAL_DISPLAY_CONDITION": condition
      }).then(function(){
        changeEvent.fire_event();
      });
    }

    function select(identifier, identifierType, value){
      gf._selected[identifier] = _.cloneDeep(value);
      if(gf._order.indexOf(identifier) == -1){
        gf._order.push(identifier);
      }
      _known_type_map[identifier] = identifierType;
      _save(gf.getCondition())
    }

    function deselect(identifier){
      if(gf._selected.hasOwnProperty(identifier)){
        delete gf._selected[identifier];
      }

      if(gf._order.indexOf(identifier) != -1){
        gf._order.splice(gf._order.indexOf(identifier), 1);
      }
      if(gf._order.length == 0){
        deselectAll();
      }
      else{
        _save(gf.getCondition())
      }
    }

    /**
     * Used by the text column summaries since the selected values are an array
     */
    function removeFromSelectedArray(columnInternalName, value){
      if(gf._selected.hasOwnProperty(columnInternalName)){
        var selected = gf._selected[columnInternalName];
        if(selected.indexOf(value) != -1){
          selected.splice(selected.indexOf(value), 1);
        }
        if(selected.length == 0){
          deselect(columnInternalName);
        }
        else{
          _save(gf.getCondition());
        }
      }
    }

    function saveConditions() {
      _save(gf.getCondition());
    }

    function getCondition(){
      return _getConditionForSelected(gf._selected);
    }

    function _getConditionForSelected(selected){
      var ands = [];
      $.each(Object.keys(selected), function(i, identifier){
        var identifierType = _known_type_map[identifier];
        if(identifierType == 'custom'){
          ands.push(selected[identifier]);
        }
        else {
          var column = utils.metadata.get_column_by_internal_name(gf.dataview.metadata, identifier);
          if(!column){
            var column_type = _known_type_map[identifier];
          }
          else{
            column_type = column.type;
          }
          var subconditions = [];
          if(column_type == c.text){
            $.each(selected[identifier], function(i, val){
              var subcondition = {};
              subcondition[identifier] = {};
              if(val === null){
                subcondition[identifier].IS_EMPTY = true;
              }
              else{
                subcondition[identifier].EQ = val;
              }
              subconditions.push(subcondition);
            });
          }
          else if(column_type == c.numeric){
            var subcondition: any = {};
            subcondition[identifier] = {IN_RANGE: selected[identifier]};
            subconditions.push(subcondition);
          }
          else if(column_type == c.date){
            var conditions = [];
            $.each(selected[identifier], function(i, selected){
              conditions.push({
                VALUE: selected[0],
                TRUNCATE: selected[1]
              })
            })

            var subcondition: any = {};
            if(conditions.length == 1){
              subcondition[identifier] = {EQ: conditions[0]};
            }
            else{
              var sc1 = {};
              var sc2 = {};
              sc1[identifier] =  {GTE: conditions[0]};
              sc2[identifier] =  {LTE: conditions[1]};
              subcondition.AND = [sc1, sc2];
            }
            subconditions.push(subcondition);
          }
          if(subconditions.length == 1){
            ands.push(subconditions[0]);
          }
          else{
            ands.push({OR: subconditions});
          }
        }

      });
      if(ands.length == 0){
        return null;
      }
      else if(ands.length == 1){
        return ands[0];
      }
      else{
        return {AND: ands}
      }
    }


    /**
     * Logic is if the column is one of the columns used in global filter, the condition for it
     * comprises of all the columns selected before the column summary was calculated.
     *If not so, the column summary condition is the whole global condition
     */
    function getContextualCondition(column, ignore_numeric_logic = undefined){
      var orderArray = _.cloneDeep(gf._order);

      if(orderArray.indexOf(column) != -1){
        if(_known_type_map[column] == c.numeric && !ignore_numeric_logic){
          orderArray.splice(orderArray.indexOf(column) + 1);
        }
        else{
          orderArray.splice(orderArray.indexOf(column));
        }
      }
      var newSelected = {};
      orderArray.forEach(function(c){
        newSelected[c] = gf._selected[c];
      });
      return _getConditionForSelected(newSelected);
    }

    function _reconsileWithRuleChanges(column, selectedValues){
      if(!selectedValues || !angular.isArray(selectedValues) || selectedValues.length == 0){
        return selectedValues;
      }
      if(gf.dataview.logs && angular.isArray(gf.dataview.logs) && gf.dataview.logs.length){
        var latestLog = gf.dataview.logs[0];
        if(latestLog.event == 'add_task'){
          var latestTaskParam = latestLog.param;
          if(latestTaskParam.hasOwnProperty('SET')){
            var setParam = latestTaskParam.SET;
            var destination = setParam.DESTINATION;
            if (destination == column){
              var values = setParam.VALUES;
              var newValue = undefined;
              values.forEach(function(value){
                if(angular.equals(getCondition(), (value.CONDITION || null))){
                  newValue = value.VALUE;
                }
              })
              if(newValue !== undefined){
                return [newValue];
              }
            }
          }
          else if(latestTaskParam.hasOwnProperty('REPLACE')){
            var replaceParam = latestTaskParam.REPLACE;
            var destinations = replaceParam.SOURCE;
            if(destinations.indexOf(column) != -1){
              if(angular.equals(latestTaskParam.CONDITION || null, getContextualCondition(column) || null)){
                if(replaceParam.hasOwnProperty('VALUE_PAIR')){
                  replaceParam.VALUE_PAIR.forEach(function(vp){
                    var val = vp.SEARCH_VALUE;
                    if(!vp.SEARCH_VALUE){
                      val = null;
                    }
                    var foundAt = selectedValues.indexOf(val);
                    if(foundAt != -1){
                      selectedValues[foundAt] = vp.REPLACE_VALUE;
                    }
                  });
                }
              }
            }
          }
        }
      }
      return selectedValues;
    }

    function reconcile(column, values){
      if(angular.equals(gf._cache[column], values)){
        return;
      }
      gf._cache[column] = values;

      var originalSelectedList = _.cloneDeep(gf._selected[column]);
      var selectedValues =  _reconsileWithRuleChanges(column, gf._selected[column]);
      if(!angular.isArray(selectedValues)){
        return;
      }

      var newSelectedList = [];
      selectedValues.forEach(function(v){
        if(values.indexOf(v) != -1){
          newSelectedList.push(v);
        }
      });

      if(!angular.equals(newSelectedList, originalSelectedList)){
        if(newSelectedList.length == 0){
          delete gf._selected[column];
          if(gf._order.indexOf(column) != -1){
            gf._order.splice(gf._order.indexOf(column), 1);
          }
        }
        else{
          gf._selected[column] = newSelectedList;
        }
        _save(gf.getCondition());
      }
    }
  }
}
