import * as angular from 'angular';
import * as _ from 'lodash-es';


/**
 * @ngInject
 */
modificationRequestTracker.$inject = ['$resource', 'config', '$timeout', '$uibModal', '$rootScope', 'DataviewService', 'utils', '$q'];
export function modificationRequestTracker($resource, config, $timeout, $uibModal, $rootScope, DataviewService, utils, $q){
  return {
    trackWkspRequest: trackWkspRequest,
    handleFailedRequest: _handleFailedRequest
  };

  function trackWkspRequest(dataviewId, requestId, handleFailedRequest, progressCb, metadata){
    var requestCompletePromise = $q.defer();
    handleFailedRequest = handleFailedRequest === undefined ? true : handleFailedRequest;
    var res = $resource(config.api.dataviewModificationRequest);
    function track(){
      res.get({dataviewId: dataviewId, requestId: requestId}).$promise.then(function(data){
        if(data.status == 'submitted'){
          $timeout(track, 1000);
          if (progressCb && _.isFunction(progressCb)) {
            progressCb(data);
          }
        }
        else if(data.status == 'referror'){
          if (handleFailedRequest) {
            var scp = $rootScope.$new();
            scp.dataviewId = dataviewId;
            scp.task_result_metadata = metadata;
            angular.extend(scp, data);
            _handleFailedRequest(scp);

            const openConfirmationModal = scp => {
              var confirmation = $uibModal.open({
                templateUrl: config.templates.wkspModificationAlert,
                windowClass: 'modal-size-medium discard-step',
                scope: scp
              })
              return confirmation.result
            };

            var confirmationPromise = openConfirmationModal(scp);
            confirmationPromise.then(function (result) {
              angular.extend(data, result);
              requestCompletePromise.reject(data);
            }, function(){
              requestCompletePromise.reject(data);
            });


          } else {
            requestCompletePromise.reject(data);
          }

        }
        else if(data.status == 'error'){
          if (handleFailedRequest) {
            var scp = $rootScope.$new();
            scp.dataviewId = dataviewId;
            angular.extend(scp, data);
            _handleFailedRequestOnError(scp);

            $uibModal.open({
              templateUrl: config.templates.wkspPipelineErrorAlert,
              windowClass: 'modal-size-medium discard-step',
              scope: scp
            })
          }
        }
        else{
          requestCompletePromise.resolve(data);
        }
      }, requestCompletePromise.reject)
    }
    $timeout(track, 1000);
    return requestCompletePromise.promise;
  }

  function _get_asset_display_name_type(scope, dataview, asset_internal_name){
    var column;
    if(scope.task_result_metadata){
     column = utils.metadata.get_column_by_internal_name(scope.task_result_metadata, asset_internal_name);
    }
    else {
      column = utils.metadata.get_column_by_internal_name(dataview.metadata, asset_internal_name);
    }
    var metric = _.filter(dataview.metrics, function(m){
          return m.internal_name == asset_internal_name
      });

    if (column){
      var display_name = column.display_name
      if (dataview.display_properties.hasOwnProperty('COLUMN_NAMES') && Object.keys(dataview.display_properties.COLUMN_NAMES).indexOf(column.internal_name) != -1){
        display_name = dataview.display_properties.COLUMN_NAMES[column.internal_name]
      }
      return [display_name, 'Column'];
    }
    else if (metric.length){
      return [metric[0].display_name, 'Metric']
    }
    else {
        return ['unknown', 'asset']
    }
  }

  /**
   * This is a private method that would look at the scope and enrich it with a few properties so that \
   * it would be easy to show things on the config.templates.wkspModificationAlert view.
   * It should have been a controller that did it, but the scope of the controller would be so low that I
   * decided to short cut it by just one method and some justifying documentation. :P
   * Also no need to have this following thing as an instantiated class through controller mechanism.
     */

  function _handleFailedRequest(scope){

    scope.title = "Dependencies detected";
    scope.usages = scope.usages || [];

    if (scope.information) {
      scope.affected = {
        num: Object.keys(scope.information).length,
        txt: utils.number.toWords(Object.keys(scope.information).length)
      };
    }

    var usages = [];
    var wksp = DataviewService.get_by_id(scope.dataviewId);

    if(!wksp.metadata){
      wksp.get_data().then(function(){
        _handleFailedRequest(scope);
      });
      return;
    }

    if (scope.information) {
        angular.forEach(Object.keys(scope.information), function (internalName) {
          var ret = _get_asset_display_name_type(scope, wksp, internalName);
          var asset = ret[0];
          var assetType = ret[1];

          var tasks = scope.information[internalName].tasks || [];
          var derivatives = scope.information[internalName].derivatives || [];
          tasks.forEach(function (t) {
            var taskWs = wksp;
            var foreign = false;
            if (t.dataview_id !=undefined && t.dataview_id != wksp.id) {
              taskWs = DataviewService.get_by_id(t.dataview_id);
              foreign = true;
            }
            usages.push({
              asset: asset,
              assetType: assetType,
              type: 'task',
              task: t,
              dataview: taskWs,
              foreign: foreign
            });
          });

          derivatives.forEach(function (d) {
            var derivativeWs = wksp;
            var foreign = false;

            if(d.dataview_id !=undefined && d.dataview_id != wksp.id){
              derivativeWs = DataviewService.get_by_id(d.dataview_id);
              foreign = true;
            }
            usages.push({
              asset: asset,
              assetType: assetType,
              type: 'derivative',
              derivative: d,
              foreign: foreign,
              dataview: derivativeWs
            });
          });
        });
    }

    scope.usages = scope.usages.concat(usages);
  }

  function _handleFailedRequestOnError(scope) {
    scope.title = 'Error in Pipeline';
  }
}
