<template>
  <div class="mm-dataflow-status-active-pipelines d-flex flex-column width-100">
    <div v-if="computedTotalViewsQuantity" class="accordions-wrapper">
      <div class="m-b-4 m-x-2">
        <span>{{ $t('dataflow_status.active_pipelines.description') }}</span>
      </div>

      <mm-accordion
        v-for="(status, statusKey, idx) in TASK_STATUS"
        :key="statusKey"
        v-model="accordionsStatus[status]"
        :class="{ 'm-b-6': idx === 0 }"
        @toggle="handleAccordionToggle($event, status)"
      >
        <template v-slot:header>
          <div class="d-flex align-items-center">
            <span :class="{ 'mm-text--body-bold': accordionsStatus[status] }">
              {{ $t(`dataflow_status.active_pipelines.${status}`) }}
              ({{ status === TASK_STATUS.PROCESSING ? computedProcessingViewsQuantity : computedQueuedViewsQuantity }})
            </span>
          </div>
        </template>

        <template v-slot:content>
          <div class="table-wrapper">
            <div v-if="!computedResources[status].length" class="m-y-3 d-flex justify-content-center">
              <span class="mm-text--caption-regular">{{ $t('global.dictionary.no_data') }}</span>
            </div>

            <div
              v-else
              v-for="(resource, idx) in computedResources[status]"
              :key="`${resource.viewId}_${idx}`"
              class="resource-row p-x-5 p-y-3"
              :class="{ 'd-flex': status === TASK_STATUS.QUEUED }"
            >
              <mm-tooltip :label="getResourcePath(resource.viewResourceId).join(' / ')" wrapper-class="d-flex m-r-auto">
                <span class="mm-text--caption-regular m-r-1">{{ resource.datasetName }} -</span>
                <mm-link :label="resource.viewName" underline small @click="openView(resource)" />
              </mm-tooltip>

              <div class="d-flex align-items-center">
                <div v-if="status === TASK_STATUS.PROCESSING" class="d-flex width-100 align-items-center">
                  <mm-progress-bar :value="getProgressPercentage(resource)" class="width-100" color="p700" inline />
                  <span
                    v-if="resource.concludedTasks != null"
                    class="mm-text--caption-bold tasks-quantity m-r-2 m-l-auto"
                  >
                    {{ resource.concludedTasks.toLocaleString() }} |
                  </span>
                </div>

                <span
                  v-if="resource.totalTasks != null"
                  class="tasks-quantity tasks-alt-color mm-text--caption-regular"
                >
                  {{
                    $tc('global.dictionary.tasks', resource.totalTasks, {
                      tasksQuantity: resource.totalTasks.toLocaleString()
                    })
                  }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </mm-accordion>
    </div>

    <div v-else class="no-data">
      <p class="mm-text--h400">{{ $t('dataflow_status.active_pipelines.empty_info') }}</p>
      <span>{{ $t('dataflow_status.active_pipelines.empty_description') }}</span>
    </div>
  </div>
</template>

<script>
//Constants
import { USER_EVENTS, getOldAppUrl } from '@/constants'
const TASK_STATUS = { PROCESSING: 'processing', QUEUED: 'queued' }

//Utils
import { getResourcePath } from '@/modules/resources/utils'

export default {
  name: 'dataflow-status-active-pipelines',
  data: () => ({
    accordionsStatus: { [TASK_STATUS.PROCESSING]: true, [TASK_STATUS.QUEUED]: false }
  }),
  computed: {
    computedResources() {
      let resources = {}

      const mapper = (r) => {
        const view = this.$store.state.resources.resourcesMap[r[0]]
        if (!view) return
        const dataset = this.$store.getters['resources/getDatasetByDatasetId'](view.properties.dataset.id)
        return {
          viewName: view.properties.name,
          viewId: view.viewId,
          datasetName: dataset.properties.name,
          concludedTasks: r[1],
          totalTasks: r[2] || r[1],
          viewResourceId: view.resourceId
        }
      }
      resources.processing = this.$store.state.resources.pendingResources?.processing
        .map((r) => mapper(r))
        .filter((r) => r)
      resources.queued = this.$store.state.resources.pendingResources?.queued.map((r) => mapper(r)).filter((r) => r)
      return resources
    },
    computedTotalViewsQuantity() {
      const totalQuantity = this.computedProcessingViewsQuantity + this.computedQueuedViewsQuantity
      this.$emit('update:activePipelinesQuantity', totalQuantity)
      return totalQuantity
    },
    computedProcessingViewsQuantity() {
      return this.computedResources?.processing?.length
    },
    computedQueuedViewsQuantity() {
      return this.computedResources?.queued?.length
    }
  },
  created() {
    this.TASK_STATUS = TASK_STATUS
  },
  methods: {
    openView(view) {
      this.$userEvents.save(USER_EVENTS.DATAFLOW_STATUS.ACTIVE_PIPELINES.OPEN_RESOURCE, { id: view.viewId })

      // TODO: remove when angular code is gone
      const redirectUrl = `${getOldAppUrl()}/#/workspaces/${this.$store.state.workspaceId}/projects/${
        this.$store.state.projectId
      }/dataviews/${view.viewId}`
      window.open(redirectUrl, `_mammoth_ds_${view.datasetId}`)
    },
    handleAccordionToggle(toggleStatus, taskStatus) {
      if (taskStatus == TASK_STATUS.PROCESSING) this.accordionsStatus[TASK_STATUS.QUEUED] = !toggleStatus
      else this.accordionsStatus[TASK_STATUS.PROCESSING] = !toggleStatus
    },
    getProgressPercentage(resource) {
      if (!resource.concludedTasks && !resource.totalTasks) return 100
      return (resource.concludedTasks * 100) / resource.totalTasks
    },
    getResourcePath
  }
}
</script>

<style lang="scss" scoped>
.mm-dataflow-status-active-pipelines {
  ::v-deep .v-expansion-panel-content {
    .resource-row {
      border-bottom: 1px solid var(--mm-color--n40);
      min-height: 36px;

      .mm-progress-bar {
        max-width: 450px;
      }

      .tasks-quantity {
        white-space: nowrap;

        &.tasks-alt-color {
          color: var(--mm-color--n300);
        }
      }
    }
  }

  ::v-deep .mm-tooltip--slot-wrapper {
    max-width: 80%;

    span,
    .mm-link {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
