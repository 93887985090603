// API
import API from '@/api'
import globalApi from '@/api/global.api'

const combineWithDataset = async (initialDatasetId, payload) => {
  const { data } = await API.post(`datasets/${initialDatasetId}/batches`, payload)
  if (data?.result?.future_id) return await globalApi.getFutureRequest(data.result.future_id)
  return data?.result
}

const getDataset = async (datasetId, includeRowCount) => {
  const { data } = await API.get(`datasets/${datasetId}?includeRowCount=${!!includeRowCount}`)
  return await globalApi.getFutureRequest(data.future_id)
}

export default { 
  combineWithDataset,
  getDataset
}
