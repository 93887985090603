import * as angular from 'angular';
import {config} from "../app.config";
import * as _ from 'lodash-es';


/**
 * @ngInject
 */
FilterHintsFactory.$inject = ['derivatives', '$q', '$timeout', '$resource', 'FutureService'];
export function FilterHintsFactory(derivatives, $q, $timeout, $resource, FutureService){
  return FilterHints;

  function FilterHints(dataviewId){
    var fh = this;
    fh.init = init;
    fh.getHints = getHints;
    fh.column = null;
    fh.groubByIntName = 'value';
    fh.derivativeId = null;
    fh.queryId = null;

    function init(column, sequenceNumber=0){
      fh.column = column;
      fh.derivativeId = null;

      var param = {
        PIVOT: {
          SELECT: [{FUNCTION: 'COUNT', 'AS': 'count', 'INTERNAL_NAME': 'count'}],
          GROUP_BY: [{COLUMN: column, INTERNAL_NAME: fh.groubByIntName}]
        }
      };
      if (sequenceNumber) {
        param['SEQUENCE_NUMBER'] = sequenceNumber
      }
      if(!dataviewId){
        return;
      }
      fh.param = param;
    }


    function get_data_tracker(data) {
      var deferred = $q.defer();
      FutureService.track(data.future_id, data_tracker);
  
      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }
      return deferred.promise;
    }

    function getHints(text, limit, skip=0, deferred){
        if(!deferred){
          deferred = $q.defer();
        }
        var condition;
        let queryDisplayProps = {SORT: [
          [
            "value",
            "ASC"
          ]
        ]};
        var condition;
        let param = _.cloneDeep(fh.param);
        if (text) {
          condition = {};
          condition[fh.groubByIntName] = {ICONTAINS: text};
          queryDisplayProps['CONDITION'] = condition;
        }
        if(limit){
          queryDisplayProps['LIMIT'] = limit;
        }
        if(skip){
          queryDisplayProps['SKIP'] = skip;
        }
        if(dataviewId){
          let query_res = $resource(config.api.volatile_query);
          query_res.save(
              {dataviewId: dataviewId},
              {param: param, display_properties: queryDisplayProps}
          ).$promise.then(function (data) {
            get_data_tracker(data).then(function (response) {
              deferred.resolve(response.data);
            }, deferred.reject);
          }, deferred.reject);
        }
        else{
          $timeout(function(){
            deferred.resolve([]);
          });
        }

      return deferred.promise;
    }
  }
}
