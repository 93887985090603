<template>
  <mm-modal v-model="computedIsModalOpen" :title="$t('project.manage.leave.title')" width="480">
    <p style="white-space: break-spaces">{{ $t('project.manage.leave.info', { name: computedProjectName }) }}</p>

    <template #actions>
      <mm-button :label="$t('global.dictionary.cancel')" objective="secondary" @click="computedIsModalOpen = false" />
      <mm-button
        class="m-l-3"
        :label="$t('project.manage.leave.title')"
        objective="destructive"
        @click="leaveProject"
      />
    </template>
  </mm-modal>
</template>

<script>
import projectApi from '@/modules/project/api/project.api.js'

export default {
  name: 'project-modal-leave',
  props: {
    value: Boolean,
    projectId: Number
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    computedProjectName() {
      return this.$store.getters.getProjectById(this.projectId).name
    }
  },
  methods: {
    async leaveProject() {
      const response = await projectApi.leaveProject(this.$store.state.user.id, this.projectId)
      if (!response) this.$toast.show({ content: this.$t('global.api.generic_error'), status: 'error' })
      this.$store.dispatch('getWorkspaces', { invalidateCache: true })
      this.$store.dispatch('getCurrentUser', { invalidateCache: true })
      this.computedIsModalOpen = false
      this.$emit('submit')
    }
  }
}
</script>
