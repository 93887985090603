<template>
  <div class="mm-restrictions-wrapper">
    <slot />

    <restrictions-wrapper-modal-upgrade-plan
      v-model="isUpgradePlanModalVisible"
      :restricted-feature="restrictedItem"
      :restricted-feature-name="restrictedFeatureName"
    />
    <restrictions-wrapper-modal-contact-sales
      v-model="isContactSalesModalVisible"
      :restricted-feature="restrictedItem"
      :restricted-feature-name="restrictedFeatureName"
    />
    <restrictions-wrapper-modal-buy-storage v-model="isBuyStorageModalVisible" />
  </div>
</template>

<script>
// This component mutates the behavior of the slot, in case a restriction occurs
// (unhallowed feature or max allowed storage reached)

// Constants
import { WORKSPACE_PERMISSIONS, getOldAppUrl } from '@/constants'

// Dependencies
import { find } from 'lodash'

// Components
import RestrictionsWrapperModalUpgradePlan from './restrictions-wrapper-modals/upgrade-plan/restrictions-wrapper-modal-upgrade-plan'
import RestrictionsWrapperModalContactSales from './restrictions-wrapper-modals/contact-sales/restrictions-wrapper-modal-contact-sales'
import RestrictionsWrapperModalBuyStorage from './restrictions-wrapper-modals/buy-storage/restrictions-wrapper-modal-buy-storage'

export default {
  name: 'restrictions-wrapper',
  components: {
    RestrictionsWrapperModalUpgradePlan,
    RestrictionsWrapperModalContactSales,
    RestrictionsWrapperModalBuyStorage
  },
  props: {
    featureToValidate: String,
    validateStorageLimit: Boolean
  },
  data: () => ({
    defaultFn: null,
    isMounted: false,
    isUpgradePlanModalVisible: false, // Currently not being used
    isContactSalesModalVisible: false,
    isBuyStorageModalVisible: false,
    restrictedItem: '',
    restrictedFeatureName: ''
  }),
  computed: {
    computedSlotInstance() {
      // Get slot instance that is meant to be restricted, given that the passed slot may different structures depending on where this component is used
      if (this.$slots.default[0].componentInstance) return this.$slots.default[0].componentInstance
      if (this.$slots.default[0]?.data?.attrs?.name) return this.$slots.default[0]
      return this.$slots.default[0].context.$children[0].$el.children[0]
    },
    computedSlotName() {
      // Name of what is passed as a slot
      return (
        this.computedSlotInstance?.$options?.name ||
        this.computedSlotInstance?.data?.attrs?.name ||
        this.computedSlotInstance?.tagName
      )
    },
    computedRestrictedFeatures() {
      // Restricted features depend on the current plan
      const currentPlan = this.$store.state.smsDetails.currentPlan
      if (!this.isMounted || !currentPlan) return []

      const featuresToValidate = this.getRestrictionsToValidate('featureToValidate')
      return featuresToValidate?.filter((f) => !currentPlan[f]) || []
    },
    computedLimitReachedRestrictedItems() {
      if (!this.isMounted || !this.$store.getters.isWorkspaceUsageLimitReached) return []

      return this.getRestrictionsToValidate('validateStorageLimit')
    },
    computedIsAnyRestrictionActive() {
      return !!(
        this.computedRestrictedFeatures.filter((r) => r).length ||
        this.computedLimitReachedRestrictedItems.filter((r) => r).length
      )
    },
    computedIsUserAnalyst() {
      return this.$store.getters.getCurrentWorkspacePermissions == WORKSPACE_PERMISSIONS.MEMBER
    }
  },
  watch: {
    computedRestrictedFeatures(val) {
      this.decideClickFunctionToBeSet(this.clickFnForRestrictedFeature, val, 'featureToValidate')
    },
    computedLimitReachedRestrictedItems(val) {
      this.decideClickFunctionToBeSet(this.clickFnForReachedLimit, val, 'validateStorageLimit')
    }
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    decideClickFunctionToBeSet(fn, restrictedItems, restriction) {
      // If there are restricted items override default function, else set default function back
      if (restrictedItems.length) this.setClickFunction(fn, restrictedItems, restriction)
      else if (this.defaultFn && !this.computedIsAnyRestrictionActive) this.setClickFunction(null, null, restriction)
    },
    getRestrictionsToValidate(validation) {
      // Returns the type of restriction that needs to be checked on the element
      switch (this.computedSlotName) {
        case 'mm-button':
        case 'mm-link':
        case 'action-bar-button':
        case 'DIV': //TODO remove DIV, LI, A and SPAN after angular migration
        case 'LI':
        case 'A':
        case 'SPAN':
          return [this[validation]].filter((i) => i)
        case 'mm-list':
        case 'mm-segmented-control':
          return this.computedSlotInstance.$props.items.filter((i) => i[validation]).map((i) => i[validation])
        case 'modal-list':
          return this.computedSlotInstance.context.items.filter((i) => i[validation]).map((i) => i[validation])
      }
    },
    setClickFunction(customFn, restrictedItems, condition) {
      // Override the default click function of the restricted element with a custom one, in case an item is restricted for the specified condition
      // Or revert back the initial default function in case the restrictions have been lifted
      switch (this.computedSlotName) {
        case 'mm-button':
        case 'mm-link':
        case 'action-bar-button':
          this.defaultFn ||= this.computedSlotInstance._events.click?.[0]
          this.computedSlotInstance._events.click ||= []
          this.computedSlotInstance._events.click[0] = restrictedItems
            ? () => customFn(restrictedItems[0])
            : this.defaultFn
          break
        case 'mm-list': {
          this.computedSlotInstance._events.itemClick ||= []
          this.defaultFn ||= this.computedSlotInstance._events.itemClick[0]
          this.computedSlotInstance._events.itemClick[0] = (event) =>
            restrictedItems?.includes(event.item[condition]) ? customFn(event.item[condition]) : this.defaultFn(event)
          break
        }
        case 'mm-segmented-control': {
          this.computedSlotInstance._events.input ||= []
          this.defaultFn ||= this.computedSlotInstance._events.input[0]
          this.computedSlotInstance._events.input[0] = (itemValue) => {
            const initialValue = this.computedSlotInstance.value
            const item = this.computedSlotInstance.$props.items.find((i) => i.value == itemValue)
            if (restrictedItems?.includes(item[condition])) {
              customFn(item[condition])
              this.$nextTick(() => this.defaultFn(initialValue))
            }
            this.defaultFn(itemValue)
          }
          break
        }
        case 'modal-list': {
          const items = this.computedSlotInstance.context.items
          this.defaultFn ||= {}
          this.computedSlotInstance.children.forEach((c, i) => {
            if (items[i][condition]) {
              const button = c.elm.querySelector('.mm-button').__vue__

              if (restrictedItems?.includes(items[i][condition])) {
                this.defaultFn[i] ||= button._events.click?.[0]
                button._events.click ||= []
                button._events.click[0] = () => customFn(items[i][condition])
              } else if (this.defaultFn[i]) button._events.click[0] = this.defaultFn[i]
            }
          })
          break
        }
        case 'DIV': //TODO remove DIV, LI, A and SPAN after angular migration
        case 'LI':
        case 'A':
        case 'SPAN': {
          const defaultFn =
            this.computedSlotInstance[
              Object.keys(this.computedSlotInstance)?.find((key) => this.computedSlotInstance[key].events)
            ]?.events ||
            this.computedSlotInstance.children?.[0]?.[
              Object.keys(this.computedSlotInstance.children?.[0] || {})?.find(
                (key) => this.computedSlotInstance.children?.[0]?.[key].events
              )
            ]?.events
          this.defaultFn ||= () => defaultFn?.click?.forEach((clickEvent) => clickEvent?.handler?.())
          Object.keys(this.computedSlotInstance).forEach((k) => delete this.computedSlotInstance[k])
          Array.from(this.computedSlotInstance.children)?.forEach((c) => Object.keys(c).forEach((k) => delete c[k]))
          this.computedSlotInstance.onclick = restrictedItems ? () => customFn(restrictedItems[0]) : this.defaultFn
          break
        }
      }
    },
    clickFnForRestrictedFeature(restrictedItem) {
      // This functions runs when a restricted feature is clicked

      this.restrictedItem = restrictedItem

      const featureTranslations = find(this.$t('settings.workspace.plan.compare'), restrictedItem)[restrictedItem]
      const featureName = featureTranslations.alias || featureTranslations.title
      this.restrictedFeatureName = featureName

      const isSelfServe = this.$store.state.smsDetails.currentPlan.isSelfServe
      if (this.computedIsUserAnalyst) {
        const toastConfig = isSelfServe
          ? {
              title: this.$t('settings.workspace.plan.restricted.request_upgrade'),
              content: this.$t('settings.workspace.plan.restricted.contact_admin_feature_self_serve', { featureName }),
              status: 'warning'
            }
          : {
              content: this.$t('settings.workspace.plan.restricted.request_feature_access', { featureName }),
              status: 'warning'
            }

        this.$toast.show(toastConfig)
      } else this.isContactSalesModalVisible = true
    },
    clickFnForReachedLimit() {
      // This functions runs when an action that needs storage is clicked, but storage limit is reached

      this.restrictedItem = ''
      this.restrictedFeatureName = ''

      const { isSelfServe, maxStorage, isAdditionalStorageAllowed } = this.$store.state.smsDetails.currentPlan

      if (this.computedIsUserAnalyst) {
        const toastConfig = {
          content: this.$t('global.data_usage.max_reached'),
          status: 'error',
          actionLabel: this.$t('settings.workspace.storage.title'),
          actionFn: () => {
            //TODO remove when angular code is gone
            window.open(
              `${getOldAppUrl()}/n/#/settings/workspace/${this.$store.state.workspaceId}?dataStorage=true`,
              'Settings'
            )
          }
        }
        this.$toast.show(toastConfig)
      } else if (isSelfServe && this.$store.state.usageInfo.workspace.allowed >= maxStorage)
        this.isContactSalesModalVisible = true
      else if (isAdditionalStorageAllowed) this.isBuyStorageModalVisible = true
      else this.isContactSalesModalVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.mm-restrictions-wrapper {
  display: initial;
}
</style>
