/* 
    This file contains directives as required in action module.
    */
updateConditionSelection.$inject = ['$timeout', '$q'];
export function updateConditionSelection($timeout, $q) {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      applyToAll: "=",
      param: '=',
      filterCondition: '='
    },
    link: function (scope, elm, attrs, ctrl) {

      scope.$watch('filterCondition', function (filterCondition) {
        if (!filterCondition) {
          scope.param = {};
          scope.applyToAll = true;
        }
      });
    }
  };
}

copyPublishCredentials.$inject = ['$timeout', '$q', 'toastNotification', 'utils'];
export function copyPublishCredentials($timeout, $q, toastNotification, utils) {
  return {

    restrict: 'A',
    scope: {
      copyValue: "=",
    },
    link: function (scope, elm, attrs, ctrl) {
      $(elm).on('click', function () {
        var copytoast = 'Credential copied to clipboard';
        var value = scope.copyValue;
        // copy all credentials is passed as JSON, 
        // convert to string before copying to clipboard.
        if (typeof value === "object") {
          var clipboardString = "";
          for (var key in value){
            clipboardString += `${key}: ${value[key]}\n`;
          }
          value = clipboardString; 
          copytoast = 'Credentials copied to clipboard';         
        }
        if (value && (value.length || Number.isInteger(value))) {
          utils.copyToClipboard(value);
          toastNotification.success(copytoast);
        }
      });
    }
  }
}