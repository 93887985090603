/*jslint node: true */
/**
 * @ngInject
 */

filterElementFactory.$inject = ['c', 'derivatives', 'utils', 'filterMenuFactory'];
export function filterElementFactory(c, derivatives, utils, filterMenuFactory){
  // todo: Abstract-ify as we define more elements

  var registry = {};
  return {
    get: get
  }

  function get(initDict){
    if ((initDict.hasOwnProperty('id') && registry.hasOwnProperty(initDict.id))){
      return registry['id'];
    }
    else{
      return new FilterElement(initDict);
    }
  }


  function FilterElement(options){
    var self = this;
    self.type = c.elementTypes.FILTER;
    self.init = init;
    self.save = save;

    self.init()

    function init(){
      self.manager = filterMenuFactory.get({
        metadata: utils.metadata.add_type_to_display_name(options.dataview.metadata)
      });
      if (options.derivative.param){
        self.manager.setParam(options.derivative.param);
      }
    }

    function save(){
      var param = self.manager.getParam();
      var displayProperties = self.manager.getDisplayProperties();
      if (!options.derivative.id){
        return derivatives.add(options.dataview.id, param, displayProperties);
      }
      else{
        return derivatives.edit(options.dataview.id, options.derivative.id, param);
      }
    }
  }
}
