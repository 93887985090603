/**
 * @ngInject
 */

import * as _ from 'lodash-es';

UserEventsService.$inject = ['config', '$resource', '$window', '$location', '$http', 'moment', 'utils', 'VuexStore'];
export function UserEventsService(config, $resource, $window, $location, $http, moment, utils, $store) {
    var service = {
        createUserEventEntry: createUserEventEntry,
        setUserEvent: setUserEvent
    };
    return service;

    function setUserEvent(data){
        var userEventResource = $resource(config.api.userEvents);
        return userEventResource.save({data: data}).$promise.then(function() {
        });
    }

    function createUserEventEntry(event_name, event_properties, additional_info) {
        var UTCseconds = utils.fetchCurrentTime() / 1000;
        if (!event_properties) {
            event_properties = {};
        }
        if (!additional_info) {
            additional_info = {};
        }

        var timestamp = new Date(); //UTC
        if (!UTCseconds) {
            UTCseconds = (timestamp.getTime()) / 1000; //UTC ms
            // UTCseconds = (timestamp.getTime() + timestamp.getTimezoneOffset() * 60 * 1000) / 1000; //Not required

        }
        var email_id = $store.state.user.email;
        var workspace_id = $store.state.workspaceId;
        var user_name = $store.state.user.name;
        var browser_name = $window.bowser.name;
        var browser_version = $window.bowser.version;
        var os_name = $window.bowser.osname;
        var device_info = $window.navigator.userAgent;
        var browserWidth = $window.innerWidth;
        var browserHeight = $window.innerHeight;
        var browser_size = {height: browserHeight, width: browserWidth};
        var domain = $location.host();

        var data = {
            timestamp: UTCseconds,
            workspace_id: workspace_id,
            email_id: email_id,
            user_name: user_name,
            domain: domain,
            os_name: os_name,
            device_info: device_info,
            browser_name: browser_name,
            browser_version: browser_version,
            browser_size: browser_size,
            event_name: event_name,
            event_context: event_properties,
            event_origin: _.get(event_properties, 'eventOrigin', ''),
            additional_info: additional_info
        };
        var data_string = JSON.stringify(data);

        var encodedData = window.btoa(unescape(encodeURIComponent(data_string))); // encode a string
        if (event_name) {
            setUserEvent(encodedData)
        }
    }
}

