import { i18n } from '@/plugins/i18n'

export function isValidURL(url) {
  const res = url.match(
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  )
  return res !== null
}

// File pattern validation functions have been copied from 'frontend\src\app\components\landing\datapull\helpers\ftp.helper.ts'
export function isFilePatternValid(selectedFile, datasetName, namePattern) {
  if (!selectedFile)
    return { errorMessage: i18n.t('data_library.data_addition.third_party.file_pattern_error.select_file') }

  const DATE_FORMATS = {
    dd: '(0?[1-9]|[12][0-9]|3[01])',
    mm: '(0?[1-9]|1[012])',
    yy: '[0-9]{2}',
    yyyy: '[0-9]{4}',
    mon: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
    month: [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december'
    ],
    w: '[0-6]{1}',
    dy: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
    day: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    hh: '[0-1]{1}[0-2]{1}',
    hh12: '[0-1]{1}[0-2]{1}',
    hh24: '(0[1-9]|1[0-9]|2[0-4])',
    mi: '[0-5]{1}[0-9]{1}',
    ss: '[0-5]{1}[0-9]{1}',
    am: 'am',
    pm: 'pm'
  }

  let datePatterns = []
  if (namePattern.length > 0) {
    datePatterns = getDatePattern(namePattern, DATE_FORMATS)
    if (datePatterns.length > 0) {
      let prefix = namePattern.substring(0, namePattern.indexOf(datePatterns[0]))
      let lastIndex = namePattern.split(datePatterns.slice(-1)[0])
      let suffix = lastIndex.slice(-1)[0]

      let dateSeparators = []
      if (datePatterns.length > 1)
        for (let i = 0; i < datePatterns.length - 1; i++) {
          let sep = getStringBetween(namePattern, datePatterns[i], datePatterns[i + 1])
          dateSeparators.push(sep)
        }

      let dateRegex = getRegexForFindingDatePattern(
        datasetName,
        prefix,
        suffix,
        DATE_FORMATS,
        datePatterns,
        dateSeparators
      )
      dateRegex = new RegExp(dateRegex)

      if (dateRegex.test(datasetName)) return { isValid: true }
      return { errorMessage: i18n.t('data_library.data_addition.third_party.file_pattern_error.no_match') }
    } else
      return { errorMessage: i18n.t('data_library.data_addition.third_party.file_pattern_error.at_least_one_date') }
  }
}

function getStringBetween(str, start, end) {
  const result = str.match(new RegExp(start + '(.*)' + end))
  return result[1]
}

function getDatePattern(namePattern, date_formats) {
  // eslint-disable-next-line no-useless-escape
  const extractingDateFormat = /\<[^<>]+\>/g
  const datePatterns = []
  let match

  do {
    match = extractingDateFormat.exec(namePattern)
    if (match) {
      const givenPattern = match[0]
      const pattern = givenPattern.replace('<', '').replace('>', '').toLowerCase()
      const format = Object.keys(date_formats).includes(pattern)
      if (format) datePatterns.push(givenPattern)
    }
  } while (match)

  return datePatterns
}

function getRegexForFindingDatePattern(fileName, prefix, suffix, dateFormats, datePatterns, dateSeparators) {
  const datePartOfFileName = fileName.replace(prefix, '').replace(suffix, '')
  let dateRegex = '^' + prefix

  for (let i = 0; i < datePatterns.length; i++) {
    const pattern = datePatterns[i].replace('<', '').replace('>', '').toLowerCase()
    const compPattern = dateFormats[pattern]

    if (Array.isArray(compPattern)) {
      for (let x of compPattern) {
        let startIndex = -1
        let stringLength = x.length
        if (datePartOfFileName.includes(x.toLowerCase())) startIndex = datePartOfFileName.indexOf(x.toLowerCase())
        if (datePartOfFileName.includes(x.toUpperCase())) startIndex = datePartOfFileName.indexOf(x.toUpperCase())
        if (datePartOfFileName.includes(x[0].toUpperCase() + x.slice(1)))
          startIndex = datePartOfFileName.indexOf(x[0].toUpperCase() + x.slice(1))
        if (startIndex > -1) {
          const patternValue = datePartOfFileName.substring(startIndex, startIndex + stringLength)
          dateRegex += patternValue
          break
        }
      }
    } else dateRegex += compPattern

    if (i < datePatterns.length - 1) dateRegex += dateSeparators[i]
  }

  return dateRegex + suffix + '$'
}
