<template>
  <div class="mm-data-addition-upload-files mm-rounded-borders">
    <mm-file-uploader
      v-model="files"
      :title="$t('data_library.data_addition.upload_files.title')"
      drag-and-drop
      multiple
      :allowed-extensions="DATA_ADDITION_EXTENSIONS"
    />

    <mm-row no-gutters class="m-t-7">
      <mm-button
        v-if="isBackButtonVisible"
        :label="$t('global.dictionary.back')"
        objective="tertiary"
        @click="redirectToMainMenu"
      />
      <mm-spacer />
      <mm-button :label="$t('global.dictionary.cancel')" objective="tertiary" @click="$emit('cancel')" />
      <restrictions-wrapper validate-storage-limit>
        <mm-button
          class="m-l-3"
          :label="$t('global.dictionary.submit')"
          :disabled="files.length == 0"
          @click="submit"
        />
      </restrictions-wrapper>
    </mm-row>
  </div>
</template>

<script>
// Constants
import { DATA_ADDITION_EXTENSIONS, USER_EVENTS } from '@/constants'

// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

// Components
import RestrictionsWrapper from '@/components/restrictions-wrapper/restrictions-wrapper'

export default {
  name: 'data-library-data-addition-upload-files',
  components: { RestrictionsWrapper },
  props: {
    folderResourceId: [String, Number],
    isBackButtonVisible: Boolean
  },
  data: () => ({
    files: []
  }),
  created() {
    this.DATA_ADDITION_EXTENSIONS = DATA_ADDITION_EXTENSIONS
  },
  methods: {
    redirectToMainMenu() {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.BACK_TO_MAIN, { type: 'uploadFiles' })
      this.$emit('back')
    },
    async submit() {
      this.$emit('submit')

      this.$toast.show(
        this.$tc('data_library.data_addition.upload_files.start', this.files.length, { fileName: this.files[0].name })
      )

      const promiseResponses = await Promise.all(
        this.files.map((file) => {
          let formData = new FormData()
          formData.append('project_id', this.$store.state.projectId)
          formData.append('file', file)
          formData.append('label_resource_id', this.folderResourceId)
          return dataLibraryApi.addDataFromFile(formData)
        })
      )

      promiseResponses.forEach((response, idx) => {
        if (response.data.ERROR_CODE) {
          const errorMessage = this.$te(`global.api.${response.data.ERROR_CODE}`)
            ? this.$t(`global.api.${response.data.ERROR_CODE}`)
            : this.$t('data_library.data_addition.upload_files.error', { fileName: this.files[idx].name })
          this.$toast.show({ content: errorMessage, status: 'error' })
        }

        this.$userEvents.save(USER_EVENTS.DATA_ADDITION.FILE_UPLOAD.SUBMIT, {
          fileName: this.files[idx].name,
          fileSize: this.files[idx].size
        })
      })
    }
  }
}
</script>
