import { dataviewConfig } from './../dataview.config';
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
/**
 * @ngInject
 */
elasticsearchActionManagerFactory.$inject = ['eventCallbackManagerFactory', 'utils'];
export function elasticsearchActionManagerFactory(eventCallbackManagerFactory, utils) {
  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new elasticsearchActionManager(options);
  }

  function elasticsearchActionManager(options) {
    var self = this;
    self.serversideValidationRequired = true;
    if (_.isUndefined(options.triggerType)) {
      options.triggerType = "pipeline";
    }
    self.dataviewId = options.context.dataview.id;
    self.param = {
      "handler_type": "elasticsearch",
      "trigger_type": options.triggerType,
      "run_immediately": true,
      sequence: options.context.currentSequenceNumber
    };

    self.param.target_properties = {};
    self.param.target_properties.host = null;
    self.param.target_properties.port = 9243;
    self.param.target_properties.username = null;
    self.param.target_properties.password = null;
    self.param.target_properties.index = null;
    self.param.target_properties.chunksize = 2000;
    self.param.target_properties.connection = "https";

    self.getParam = getParam;
    self.setParam = setParam;
    self.validate = validate;

    function validate() {
      return true;
    }
    function getParam() {
      if (self.param.target_properties.index) {
        self.param.target_properties.index = self.param.target_properties.index.trim();
      }
      return self.param;
    }

    function setParam(param) {
      angular.extend(self.param, param);
    }

  }
}
