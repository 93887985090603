import { dataviewConfig } from './../dataview.config';
import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
/**
 * @ngInject
 */
powerbiActionManagerFactory.$inject = ['eventCallbackManagerFactory', 'utils'];
export function powerbiActionManagerFactory(eventCallbackManagerFactory, utils) {
  var service = {
    get_manager: get_manager
  };
  return service;

  function get_manager(options) {
    return new powerbiActionManager(options);
  }

  function powerbiActionManager(options) {
    var self = this;
    self.dataviewId = options.context.dataview.id;
    self.serversideValidationRequired = true;
    if (_.isUndefined(options.triggerType)) {
      options.triggerType = "pipeline";
    }

    self.param = {
      "handler_type": "powerbi",
      "trigger_type": options.triggerType,
      "run_immediately": true,
      sequence: options.context.currentSequenceNumber
    };

    self.param.target_properties = {};
    self.param.target_properties.username = null;
    self.param.target_properties.password = null;
    self.param.target_properties.dataset = null;
    self.param.target_properties.table = null;
    self.param.target_properties.clientId = null;

    self.getParam = getParam;
    self.setParam = setParam;
    self.validate = validate;

    function validate() {
      return true;
    }
    function getParam() {
      if (self.param.target_properties.dataset) {
        self.param.target_properties.dataset = self.param.target_properties.dataset.trim();
      }
      return self.param;
    }

    function setParam(param) {
      angular.extend(self.param, param);
    }

  }
}
