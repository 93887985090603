import * as angular from 'angular';
/**
 * @ngInject
 * Action model
 */

ActionFactory.$inject = ['$resource', 'config', '$q', 'FutureService',
              'toastNotification'];
export function ActionFactory($resource, config, $q, FutureService,
              toastNotification) {
  var ActionTriggerResource = $resource(config.api.actionTriggers,
    {trigger_id: '@id', ws_id: '@ws_id'}
  );

  var PublishResource = $resource(config.api.publishTrigger,
    {trigger_id: '@id', ws_id: '@ws_id'});

  return Action;

  function Action(action_data) {
    var self = this;
    self.targetDataset = null;
    self.update = _update_self;
    self.edit = _edit_action;
    self.editDisplayInfo = _editActionDisplayInfo;
    self.executeAction = executeAction;
    self.toggleSuspend = toggleSuspend;
    self.delete = deleteAction;
    self.regenerate_password = regenerate_password;
    _update_self(action_data);

    ////
    function _update_self(action_data) {

      // Reset validation info
      if (self.hasOwnProperty('error_info')){
        self.error_info = {};
      }

      angular.extend(self, action_data);
    }
    function _edit_action(new_param) {
      return ActionTriggerResource.patch(
          {
            trigger_id: self.id,
            ws_id: self.ws_id
          },
          {'patch': [
                      {'op': 'replace',
                       'path': 'params',
                       'value': new_param
                      }]
          }).$promise;
    }

    function regenerate_password(){
      if (self.target_properties.handler_type === 'publishdb'){
        return;
      }
      var publish_resource = $resource(config.api.publish, { ws_id: self.ws_id });
      return publish_resource.patch(
        {
          "patch": [{
            "op": "replace", "path": "reset_password",
            "value": {
              "odbc_type": self.target_properties.odbc_type
          }
        }]
        }).$promise;
    }

    function _editActionDisplayInfo(new_display_info) {
      return ActionTriggerResource.patch({
        action_id: self.id,
        ws_id: self.ws_id
      }, {"patch": [{"op": "replace", "path": "display_info", "value": new_display_info}]}).$promise;
    }

    function deleteAction() {
      var action_resource_instance = new ActionTriggerResource();

      if (self.handler_type == "publishdb"){
        action_resource_instance = new PublishResource();
      }

      action_resource_instance.id = self.id;
      action_resource_instance.ws_id = self.ws_id;

      return action_resource_instance.$delete();
    }

    function executeAction() {
      var action_resource_instance = new ActionTriggerResource();
      action_resource_instance.id = self.id;
      action_resource_instance.ws_id = self.ws_id;
      return action_resource_instance.$save();
    }

    function toggleSuspend(newState) {
      /*
       * Toggle suspend state of the action. i.e. if the current state of the action is `suspended` then this function will
       * `restore/unsuspend` the action
       * newState would be opposite of current state
       */
      
      return ActionTriggerResource.patch(
        {
          trigger_id: self.id,
          ws_id: self.ws_id
        },
        {'patch': [
          {'op': 'replace',
            'path': 'updateState',
            'value': newState 
          }]
        }).$promise;
    }
  }
}
