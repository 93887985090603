// Constants
import { DATA_TYPES_MAP, REQUESTS_STATUS } from '@/constants'

export function transformIntegrationInfo({ data }) {
  return {
    authUrl: data.spec.AUTH_URL,
    scope: data.spec.scope,
    fields: data.spec.fields,
    type: data.spec.type
  }
}

export function transformConnectionMetricsInfo(data) {
  const attributes = data.ds_config_spec?.ux.attributes

  return {
    options: attributes?.datapull_options?.items.map((option) => ({
      ...option,
      items: Object.values(attributes).find((a) => a?.hidden_field.includes(option.value))?.items,
      key: Object.keys(attributes).find((key) => attributes[key]?.hidden_field.includes(option.value))
    })),
    tables: attributes?.tables?.items,
    fileList: attributes?.file_list?.items || attributes?.documents?.items,
    folderList: attributes?.folder_list?.items,
    message: data.message,
    isValid: data.is_valid
  }
}

export function transformTableData(data) {
  const tableData = data?.data_sample || data
  if (data.status == REQUESTS_STATUS.ERROR) {
    return { columnDefs: [], rows: [] }
  }
  return {
    columnDefs: tableData.metadata.map((c) => ({
      field: c.internal_name,
      headerName: c.display_name,
      datatype: DATA_TYPES_MAP[c.type]
    })),
    rows:
      tableData.data ||
      tableData.rows.map((r) => {
        let row = {}
        tableData.metadata.forEach((c, index) => (row[c.internal_name] = r[index]))
        return row
      })
  }
}
