<template>
  <div class="mm-folder-breadcrumb">
    <mm-breadcrumbs
      v-if="computedFoldersBreadcrumb.length"
      :items="computedFoldersBreadcrumb"
      class="m-b-6"
      @click="saveUserEvent"
    />

    <label class="mm-text--h400">
      <!-- TODO: updated when data library migration -->
      {{ computedCurrentFolder ? computedCurrentFolder.properties.name : $store.state.dataLibrary.activeSubItem.title }}
    </label>
  </div>
</template>

<script>
//Constants
import { USER_EVENTS } from '@/constants'

export default {
  name: 'folder-breadcrumb',
  props: {
    folderResourceId: [String, Number]
  },
  computed: {
    computedFoldersBreadcrumb() {
      let folderResourceId = this.folderResourceId
      let folders = []

      do {
        const folder = this.$store.state.resources.resourcesMap[folderResourceId]
        if (!folder) break

        // TODO: revisit this after migration for applying router
        folders.unshift({
          text: folder.properties.name,
          href: `#/workspaces/${this.$store.getters.getCurrentWorkspace.id}/projects/${this.$store.state.projectId}/folders/${folder.resourceId}`,
          folderResourceId
        })
        folderResourceId = folder.folderResourceId
      } while (folderResourceId)

      // TODO: revisit this after migration for applying router
      folders.unshift({
        text: this.$store.state.dataLibrary.activeSubItem.title,
        href: `#/workspaces/${this.$store.getters.getCurrentWorkspace.id}/projects/${this.$store.state.projectId}`
      })

      // remove the current folder from breadcrumbs
      folders.splice(-1)
      return folders
    },
    computedCurrentFolder() {
      if (!this.folderResourceId) return
      return this.$store.state.resources.resourcesMap[this.folderResourceId]
    }
  },
  methods: {
    saveUserEvent(item) {
      this.$userEvents.save(USER_EVENTS.FOLDER_BREADCRUMB.OPEN, item)
    }
  }
}
</script>
