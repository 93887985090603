'use strict'
import * as angular from 'angular'
import { values, cloneDeep, isEmpty } from 'lodash-es'
import { transformResources } from '../../../vueApp/src/mm-frontend/src/modules/resources/api/resources.transform.js'
import { IN_OUT_RESOURCE_DEPENDENCIES } from '../../../vueApp/src/mm-frontend/src/constants'

LandingCtrl.$inject = [
  'VuexStore',
  'Auth',
  'resources',
  'DatasourceService',
  'FileService',
  'config',
  '$window',
  'analyticsService',
  'DSBatchService',
  'navigationService',
  'modalService',
  'c',
  'fileUploadService',
  'keyboardEvents',
  'rawData',
  '$location',
  'appMetaInfo',
  '$timeout',
  'UserWorkspace',
  'Singularity',
  'unstructureDataModal',
  'labelService',
  'landingList',
  'landingSelection',
  'CompatibilityWarnings',
  '$resource',
  'moment',
  'DataviewService',
  'appHelperService',
  '$scope',
] as ReadonlyArray<string>

export function LandingCtrl(
  $store,
  Auth,
  resources,
  DatasourceService,
  FileService,
  config,
  $window,
  analyticsService,
  DSBatchService,
  navigationService,
  modalService,
  c,
  fileUploadService,
  keyboardEvents,
  rawData,
  $location,
  appMetaInfo,
  $timeout,
  UserWorkspace,
  Singularity,
  unstructureDataModal,
  labelService,
  landingList,
  landingSelection,
  CompatibilityWarnings,
  $resource,
  moment,
  DataviewService,
  appHelperService,
  $scope
) {
  this.$onInit = function () {
    $store.commit('cache/setLoader', true)
    CompatibilityWarnings.init()
    // ViewModel
    var vm = this
    // locals
    // make available service properties directly on the controller, override when needed

    vm.$store = $store

    vm.toggleDataPullDialog = toggleDataPullDialog
    vm.landingList = landingList
    vm.showAddMoreDataModal = false
    vm.addMoreDataMode = ''
    vm.addMoreDataDatasetName = ''
    vm.addMoreDataDatasetId = ''

    vm.landingSelection = landingSelection
    vm.dataPullOptions = [
      {
        internal: 'file',
        key: 'FILE_UPLOAD',
        display: 'My Desktop',
        show: true,
      },
      {
        internal: 'api',
        key: 'THIRD_PARTY',
        display: 'APIs & Databases',
        show: true,
      },
    ]
    vm.vueDefaultConnector = null
    vm.vueDataAdditionType = null

    vm.dataPullOptions.push({
      internal: 'webhook',
      key: 'WEBHOOK',
      display: 'Webhooks',
      show: $store.state.smsDetails?.currentPlan?.isWebhookEnabled,
    })

    vm.dataPullOptions.push({
      internal: 'url',
      key: 'FETCH_FROM_URL',
      display: 'Fetch from URL',
      show: true,
    })

    vm.delete_datasource = _getWrappedWithCallbacks(modalService.deleteDs, () => landingSelection.clearSelection()) //override delete
    vm.delete_dataview = modalService.deleteWs //override delete
    vm.delete_file_object = modalService.deleteFile
    vm.dsAddData = dsAddData
    vm.toggleAddMoreDataModal = toggleAddMoreDataModal
    vm.fileUploadService = fileUploadService

    vm.rename_datasource = modalService.renameDs
    vm.clone_datasource = modalService.cloneDs
    vm.rename_label = modalService.renameLabel
    vm.rename_dataview = modalService.renameWs
    vm.duplicateDataview = duplicateDataview
    vm.openDSCombineModal = modalService.combineDS
    vm.openUnprocessedResolver = getUnprocessedActionResolverModal
    vm.selectWorkbookSheets = selectWorkbookSheets
    vm.goToLabel = goToLabel
    vm.onSearchBarSelect = onSearchBarSelect
    vm.onFilterResources = onFilterResources
    vm.vueAddDataModalClose = vueAddDataModalClose

    vm.isKeyDown = keyboardEvents.isKeyDown
    vm.keyDownList = keyboardEvents.keyDownList
    vm.navigationService = navigationService
    vm.uploader = null
    vm.searchQuery = null
    vm.deleteMultiple = deleteMultiple
    vm.dataPullLastAutoOpened = null
    vm.openLabelCreatorModal = openLabelCreatorModal
    vm.resources = resources
    vm.resourceList = []

    /************** Publish Reports ***********************/
    vm.isPublishedReportRoute = window.location.hash.includes('shared-reports')

    vm.fetchPendingChanges = modalService.fetchPendingChanges
    vm.applyPendingChanges = modalService.applyPendingChanges

    let dependenciesCheck = false

    $window.name = config.windowNames.landing

    vm.openPublishedReport = function (report) {
      $window.open('/#/publish/' + report.id, config.windowNames.report + report.id)
    }

    const Reports = $resource(config.api.publish_reports, {}, { get: { isArray: true } })

    landingList.onUpdate('landingListUpdate', () => {
      /* on change in resources
     1. If searchQuery then update the search results
     2. else update the resource list
     3. Updated options in the search bar
    */
      if (vm.searchQuery) {
        vm.onFilterResources(vm.searchQuery)
      } else {
        vm.resourceList = vm.landingList.currentLabelView?.contents.map(item => ({ ...item, isCollapsed: true }))
      }
      getSearchList()
      $store.commit('cache/setLoader', false)
    })

    function formatReport(report) {
      const user = UserWorkspace.users.find((ua) => ua.id === report.owner_user_id)
      const currReport = cloneDeep(report)
      currReport.updated_at = currReport.last_synced_at
      currReport.owner_name = user?.email === UserWorkspace.loggedInEmail ? 'me' : user.name
      return currReport
    }

    UserWorkspace.on_workspace_select('update_reports', function () {
      setPageTitle()
    })

    UserWorkspace.onSmsDetailsLoaded('fromDataLibrary', () => {
      vm.dataPullOptions.find((option) => option.key === 'WEBHOOK').show = $store.state.smsDetails?.currentPlan?.isWebhookEnabled
    })

    vm.hasLinkedDependencies = function (resource_id) {
      if (!dependenciesCheck) dependenciesCheck = true

      return (
        $store.state.resources.resourcesMap?.[resource_id]?.dependencies?.in?.filter((d) =>
          IN_OUT_RESOURCE_DEPENDENCIES.includes(d.operationName)
        ).length ||
        $store.state.resources.resourcesMap?.[resource_id]?.dependencies?.out?.filter((d) =>
          IN_OUT_RESOURCE_DEPENDENCIES.includes(d.operationName)
        ).length
      )
    }

    landingList.onLabelViewChange('labelViewChange', () => {
      landingSelection.clearSelection()
      setPageTitle()
      getSearchList()
      vm.isPublishedReportRoute = window.location.hash.includes('shared-reports')
      vm.resourceList = vm.landingList.currentLabelView?.contents.map(item=> ({...item, isCollapsed: true}))
      $store.commit('cache/setLoader', false)
    })

    /**
     * The function is to get the absolute path of the resource
     * @param resource resource item from the resource list
     * @returns path of the resource
     */
    function getResourcePath(resource) {
      let resourceId = null
      let path = ''
      if (resource.type === 'label') {
        resourceId = resource.parent_resource_id
      } else {
        resourceId = resource.datasource?.label_resource_ids[0] || resource?.label_resource_ids[0]
      }
      while (resourceId) {
        let folder = labelService.labelsDict[resourceId]
        if (folder) {
          path = folder?.name + '/' + path
        }
        resourceId = folder?.parent_resource_id
      }
      return path.slice(0, -1)
    }

    /**
     * The function is to format the resource item into search bar compactable list
     * @param item Resource Item
     * @returns id, label, icon and action text for button
     */
    function getResourceItems(item) {
      let icon = 'folder'
      let actionText = ''
      let label = item.name
      let id = item.id
      switch (item.type) {
        case 'datasource':
          icon = 'dataset'
          actionText = 'Open Dataset'
          break
        case 'file_object':
          icon = 'dataset'
          actionText = 'Open Dataset'
          break
        case 'dataview':
          icon = 'view'
          actionText = 'Open View'
          label = item.getLongName()
          break
        case 'label':
          id = item.resource_id
        default:
          break
      }
      return { id, label, icon, actionText }
    }

    /**
     * The function is to get the list of items for search bar in the top nav bar.
     */
    function getSearchList() {
      const resources = values(vm.resources?.resourcesMap).filter((item) =>
        item.type !== 'dataview' ? item.status === c.dsStatus.ready : item?.datasource?.status === c.dsStatus.ready
      )
      vm.searchList = resources.map((item) => {
        const path = getResourcePath(item)
        const { id, label, icon, actionText } = getResourceItems(item)
        return {
          id,
          label,
          icon,
          actionText,
          type: item.type,
          createdAt: moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a'),
          updatedAt: moment(item.updated_at).fromNow(),
          url: item.data_url,
          fullPath: path,
        }
      })
    }

    async function fetchReports() {
      const allReportsResponse = await Reports.get().$promise
      const allReports = allReportsResponse.map(formatReport)
      $store.dispatch('resources/handleReportResources', transformResources(allReports))
      landingList.publishedReports = allReports
      landingList.labelViewMap[c.PUBLISHED_REPORTS].contents = allReports
      landingList.inited = true
      return allReports
    }


    // add a listener to file list changes and remove upload items accordingly
    FileService.on_list_update('remove_upload_items', _remove_upload_items)
    DSBatchService.onBatchAdded('landing_ctrl', _select_target_ds)
    Singularity.onDataPullDialogOpenRequest('landing_ctrl', checkAndOpenIfSingularityDialog)

    $timeout(checkAndOpenIfSingularityDialog, 2000)

    init()

    async function init() {
      vm.landingSelection.clearSelection()
      if (UserWorkspace._workspace_list_inited) {
        if (appMetaInfo.isPageLoading) appMetaInfo.pageLoaded()
        if (window.location.hash.includes('landing') || isEmpty($store.getters.getCurrentWorkspace)) {
          navigationService.openFirstProjectView()
        } else if (vm.isPublishedReportRoute) {
          const allReports = await fetchReports()
          vm.resourceList = allReports
          landingList.sortList(allReports)
          landingList.setCurrentLabelView(c.PUBLISHED_REPORTS)
          getSearchList()
          $store.commit('cache/setLoader', false)
        } else if (!$store.getters.getSubscribedProjects.length) {
          fetchReports()
          vm.showProjectLoader = false
          $store.commit('cache/setLoader', false)
        } else {
          const currentWorkspace = $store.getters.getCurrentWorkspace
          const activeProject = currentWorkspace.projects.find(
            (item) => item.id == $store.state.projectId
          )

          if (isEmpty(activeProject)) {
            navigationService.openFirstProjectView()
            return
          }
          $store.commit('dataLibrary/setActiveSubItem', {
            icon: 'data_library_20px',
            path: `#/workspaces/${$store.state.workspaceId}/projects/${$store.state.projectId}`,
            title: activeProject.name,
          })
          fetchReports()
          if (window.location.hash.includes('?onboarding=true')) {
            setTimeout(() => {
              appHelperService.startOnBoarding()
              $location.search('onboarding', null);
            }, 1000)
          }
        }
      }
    }

    DatasourceService.onNaviagteToDataset('openDataset', (ds_id) => {
      vm.onSearchBarSelect({ id: ds_id, type: 'datasource'})
    })
    /**
     * The function is the dispatch the action based on the selected search bar item.
     * @param value Selected value from search bar
     * @returns nothing
     */
    function onSearchBarSelect(value) {
      vm.landingSelection.clearSelection()
      if (!value) {
        return
      }
      if (value.type === 'label') {
        vm.goToLabel(value.id)
      } else if (value.type === 'file_object') {
        const fileId = value.id
        var fileObject = FileService.list[fileId]
        if (fileObject?.label_resource_ids?.length) {
          const folderId = fileObject.label_resource_ids[0]
          vm.goToLabel(folderId)
        } else {
          vm.goToLabel()
        }
        setTimeout(() => {
          vm.landingSelection.selectResource(fileObject)
        }, 300)
      } else if (value.type === 'datasource') {
        const ds_id = value.id
        var ds = DatasourceService.list[ds_id]
        if (ds?.label_resource_ids?.length) {
          const folderId = ds.label_resource_ids[0]
          if (parseInt(vm.landingList.currentLabelResourceId) !== parseInt(folderId)) {
            vm.goToLabel(folderId)
          }
        } else if (vm.landingList.currentLabelResourceId) {
          vm.goToLabel()
        }
        setTimeout(() => {
          vm.landingSelection.selectResource(ds)
        }, 300)
      } else {
        const ws = DataviewService.get_by_id(value.id)
        navigationService.open_dataview(ws)
      }
      analyticsService.userEventTrack(c.userEvents.landingPage.searchDS, {
        eventOrigin: 'landingPage.searchBar',
      })
      vm.onFilterResources('')
    }

    /**
     * The function is to do the search of all the items in landing page.
     * @param filter String to flter the resources in landing page
     */
    function onFilterResources(filter) {
      vm.searchQuery = filter
      const searchFilter = filter.toLowerCase().trim()
      if (!filter) {
        vm.resourceList = vm.landingList.currentLabelView.contents
      } else {
        const resourceList = cloneDeep(vm.resources.resourcesMap)
        vm.resourceList = values(resourceList)
          .map((item) => {
            if (item.type === 'datasource') {
              const path = getResourcePath(item)
              if (item?.shortName?.toLowerCase()?.includes(searchFilter)) {
                const highlightedName = highlightMatches(item.shortName, filter)
                const name = path
                  ? `${highlightedName} <strong class="mm-text--caption-regular text-muted">(in ${path})</strong>`
                  : highlightedName
                return { ...item, shortName: name }
              } else if (item.dataviews_list?.some((w) => w.name.toLowerCase().includes(searchFilter))) {
                item.dataviews_list.forEach((dataview) => {
                  if (dataview.name?.toLowerCase()?.includes(searchFilter)) {
                    dataview.name = highlightMatches(dataview.name, filter)
                  }
                  return dataview
                })
                return item
              }
            } else if (item.type === 'label') {
              if (item.name?.toLowerCase().includes(searchFilter)) {
                const path = getResourcePath(item)
                if (item?.name?.toLowerCase()?.includes(filter)) {
                  const highlightedName = highlightMatches(item.name, filter)
                  const name = path
                    ? `${highlightedName} <strong class="mm-text--caption-regular text-muted">(in ${path})</strong>`
                    : highlightedName
                  return { ...item, name }
                }
              }
            }
          })
          .filter(Boolean)
      }
    }

    function goToLabel(resource_id) {
      $store.commit('cache/setLoader', true)
      let url = `${window.location.origin}/#/workspaces/${$store.getters.getCurrentWorkspace.id}/projects/${$store.state.projectId}`
      if (resource_id) {
        url += `/folders/${resource_id}`
      }
      window.location.href = url
    }

    function highlightMatches(text, compareText) {
      const matchExists = text.toLowerCase().includes(compareText?.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(compareText, 'ig')
      return text.replace(re, (matchedText) => `<strong class="bg-warning">${matchedText}</strong>`)
    }

    function toggleDataPullDialog(section, params) {
      analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.addnewDSClicked, {
        eventOrigin: 'landingPage',
        section: section,
      })
      vm.vueDataAdditionType = section || 'FILE_UPLOAD'
    }

    function dsAddData(ds, replace) {
      vm.showAddMoreDataModal = true
      vm.addMoreDataMode = replace == 'true' ? 'replace' : 'combine'
      vm.addMoreDataDatasetName = ds.name
      vm.addMoreDataDatasetId = ds.id
    }

    function toggleAddMoreDataModal(showModal) {
      vm.showAddMoreDataModal = showModal
    }

    function _remove_upload_items() {
      if (vm.uploader && vm.uploader.queue.length) {
        const items_to_remove = []
        angular.forEach(vm.uploader.queue, function (upload_item) {
          if (FileService.list.hasOwnProperty(upload_item.file_id)) {
            items_to_remove.push(upload_item)
          }
        })
        if (items_to_remove.length) {
          angular.forEach(items_to_remove, function (item) {
            item.remove()
          })
        }
      }
    }

    function getUnprocessedActionResolverModal(dsStatus) {
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.resolveButtonClicked, {
        eventOrigin: 'landingPage',
        dsStatus: dsStatus,
      })
      let f = undefined
      switch (dsStatus) {
        case c.dsStatus.hasUnstructuredData:
          f = unstructureDataModal.openByDsId
          break

        case c.dsStatus.actionNeeded:
        default:
          f = rawData.openByDsId
      }
      return _getWrappedWithCallbacks(f, () => landingSelection.clearSelection())
    }

    /**
     * The following generate a function will call argument function fn.
     * It would be assumed that the fn would return a promise and depending on the promise, success callback
     * and failure callbacks would be executed.
     *
     * This is to generate wrapper methods for modalService functions.
     *
     * Note that this returns a function.
     * @param fn
     * @param scb
     * @param fcb
     * @private
     */
    function _getWrappedWithCallbacks(fn, scb, fcb?) {
      scb = scb || function () {}
      fcb = fcb || function () {}
      return function () {
        fn.apply(this, arguments).then(scb, fcb)
      }
    }

    function deleteMultiple() {
      if (vm.landingSelection.selection.items.length < 2) {
        return
      }
      analyticsService.userEventTrack(c.userEvents.landingPage.multiDeleteDatasets, { eventOrigin: 'landingPage' })
      modalService.multiDelete(vm.landingSelection.selection.items).then(() => {
        vm.landingSelection.clearSelection()
      })
    }

    function duplicateDataview(dataview) {
      dataview.datasource.add_dataview(undefined, dataview.id)
      landingSelection.clearSelection(null)
    }

    function checkAndOpenIfSingularityDialog() {
      if ($location.search()['datapull']) {
        var params = $location.search()
        params.activeOption = 'api'
        Singularity.selected_integration_key = params['datapull']
        Singularity.preselect_integration_from_oauth2_flow = true
        let now: any = new Date()
        if (!vm.dataPullLastAutoOpened || now - vm.dataPullLastAutoOpened > 3000) {
          vm.dataPullLastAutoOpened = now
          vm.vueDefaultConnector = Singularity.selected_integration_key
          //vm.toggleDataPullDialog('api', params);
          $location.search('datapull', null)
          $location.search('activeOption', null)
        }
      }
    }

    function setPageTitle() {
      var title = 'Home | ' + $store.state.user.email + ' | ' + UserWorkspace.workspace.name
      appMetaInfo.setPageTitle(title)
    }

    function selectWorkbookSheets(fileId, sheetsInfo, combine, deleteFile) {
      var selectedSheets = []
      angular.forEach(sheetsInfo, function (sheet) {
        if (sheet.value) {
          selectedSheets.push(sheet.sheet_name)
        }
        sheet.value = false
      })
      if (selectedSheets.length) {
        FileService.extractSheets(fileId, selectedSheets, combine, deleteFile).then(vm.landingSelection.clearSelection())
      }
    }

    function _select_target_ds(ds_id) {
      var ds = DatasourceService.list[ds_id]
      vm.landingSelection.selectResource(ds)
      var old_row_count = ds.row_count
      var unsub = ds.onUpdate('waiting-for-append', function () {
        if (ds.status == 'ready' && ds.row_count > old_row_count) {
          unsub()
          ds.justCompletedDataAppend = true
          $timeout(function () {
            ds.justCompletedDataAppend = false
          }, 2000)
        }
      })
    }

    function openLabelCreatorModal() {
      analyticsService.userEventTrack(c.userEvents.openNewFolderModal, { eventOrigin: 'addDataMenu' })
      modalService.createLabel()
    }

    function vueAddDataModalClose() {
      vm.vueDataAdditionType = null
      vm.vueDefaultConnector = null
    }
  }
}
