<template>
  <div data-canny-changelog class="justify-align-center">
    <mm-icon
      class="navbar-releases-icon cursor-pointer"
      :color="isReleasesMenuOpen ? 'p300' : 'n10'"
      hover-color="p300"
      name="releases_24px"
      @click="onClick"
      @blur="isReleasesMenuOpen = false"
    />
  </div>
</template>

<script>
//Constants
import { USER_EVENTS } from '@/constants'

export default {
  name: 'navbar-releases',
  data: () => ({
    isReleasesMenuOpen: false
  }),
  mounted() {
    // The following code has been generated by Canny Docs
    // https://developers.canny.io/install/changelog
    !(function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          let f = d.getElementsByTagName(s)[0]
          let e = d.createElement(s)
          e.type = 'text/javascript'
          e.async = !0
          e.src = 'https://canny.io/sdk.js'
          f?.parentNode.insertBefore(e, f)
        }
      }
      if ('function' != typeof w.Canny) {
        let c = function () {
          c.q.push(arguments)
        }
        c.q = []
        w.Canny = c
        if ('complete' === d.readyState) l()
        else if (w.attachEvent) w.attachEvent('onload', l)
        else w.addEventListener('load', l, !1)
      }
    })(window, document, 'canny-jssdk', 'script')
    window.Canny('initChangelog', {
      appID: process.env.VUE_APP_CANNY_ID,
      position: 'bottom',
      align: 'right'
    })
  },
  methods: {
    onClick() {
      this.isReleasesMenuOpen = !this.isReleasesMenuOpen
      this.$userEvents.save(USER_EVENTS.NAVBAR.RELEASES.OPEN)
    }
  }
}
</script>

<style lang="scss" scoped>
$navbar-releases-badge-color: var(--mm-color--s200);
$navbar-background-color: var(--mm-color--p700);

::v-deep .Canny_Badge {
  background: $navbar-releases-badge-color;
  border: 1px solid $navbar-background-color !important;
  padding: 3px;
  right: -1px;
  top: -2px;
}

.navbar-releases-icon {
  margin-top: -2px;
  outline: none;
}
</style>
