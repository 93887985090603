import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

windowFunctionManagerFactory.$inject = ['destinationColumnManagerFactory', 'c', 'sortManagerFactory', 'utils', '$window'];
export function windowFunctionManagerFactory(destinationColumnManagerFactory, c, sortManagerFactory, utils, $window) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new WindowFunctionManager(options);
  }
  function WindowFunctionManager(options) {
    var self = this;
    var metadata = options.metadata, taskUtils = options.taskUtils, dataview = options.context.dataview;
    self.metadata = options.metadata;
    self.getParam = getParam;
    self.setParam = setParam;
    self.handlePasteParams = handlePasteParams;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.context = options.context;
    self.validate = validate;
    self.sourceColumnSelected = sourceColumnSelected;
    self.highlightColumns = highlightColumns;
    self.handleFunNameChange = handleFunNameChange;
    self.openWindowHelp = openWindowHelp;
    self.checkIfFunctionExistsInAppHelp = checkIfFunctionExistsInAppHelp;
    self.resetSortForAggregateFunctions = resetSortForAggregateFunctions;
    self.functionExistsInAppHelp = false; //If task does not exist in app help map, the Learn more option does not show up
    self.helpOptions = {
      SUM: 'window.sum',
      AVG: 'window.avg',
      MIN: 'window.min',
      MAX: 'window.max',
      STDDEV: 'window.stddev',
      VARIANCE: 'window.variance',
      COUNT: 'window.count',
      ROW_NUMBER: 'window.rownum',
      RANK: 'window.rank',
      DENSE_RANK: 'window.dense_rank',
      PERCENT_RANK: 'window.percent_rank',
      CUMULATIVE_PERCENT_RANK: 'window.cum_percent_rank',
      NTILE: 'window.ntile',
      LEAD: 'window.lead',
      LAG: 'window.lag',
      FIRST_VALUE: 'window.first_val',
      LAST_VALUE: 'window.last_val',
      NTH_VALUE: 'window.nth_val'
    };

    self.columns = metadata;

    self.destinationManager = destinationColumnManagerFactory.get_manager(
      {metadata: metadata, allowedTypes: [c.numeric], taskUtils: options.taskUtils, isDestinationFormatterVisible: true}
    );
    options.mandatory_sort = false;

    self.sortManager = sortManagerFactory.get_manager(options);

    class GroupByManager{
      public applyPartitioning: boolean;
      public partitionBy: string[];

      public constructor() {
        this.applyPartitioning = false;
        this.partitionBy = [];
      }

      getParam(){
        if (this.applyPartitioning) {
          let groups = [];
          angular.forEach(this.partitionBy, function (group) {
            groups.push({ COLUMN: group });
          })
          return groups;
        }
      }
      sanitizeParam(param){
        let indicesOfColNotAvailable = [];
        _.forEach(param, function (colObj) {
          let colObjIndex = param.indexOf(colObj);
          let colInfo = utils.metadata.get_column_by_internal_name(colObj.COLUMN);
          if (!colInfo) {
            indicesOfColNotAvailable.push(colObjIndex);
          }
        });
        param = param.filter(function(value,index){
          return indicesOfColNotAvailable.indexOf(index) == -1
        });
      }

      setParam(param) {
        let self = this;
        if(angular.isArray(param) && param.length){
          // this.sanitizeParam(param);
          angular.forEach(param, function (currentParam) {
            self.partitionBy.push(currentParam.COLUMN);
          })
          this.applyPartitioning = true;
        }
        else {
          this.partitionBy = [];
          this.applyPartitioning = false;
        }
      }

      togglePartitioning() {
        this.applyPartitioning = !this.applyPartitioning;
        if (this.applyPartitioning) {
          if (this.partitionBy.length === 0) {
              this.addPartition();
          }
        }
      }

      removePartition(groupIndexToRemove) {
        this.partitionBy = $.grep(this.partitionBy, function(group, index){
          return index != groupIndexToRemove;
        });
        if (this.partitionBy.length == 0) {
          this.applyPartitioning = false;
        }
        highlightColumns();
      }

      addPartition() {
        this.partitionBy.push(undefined);
      }
    }

    class RangeManager{
      public rangeOps = [
        {
          internal: 'UNBOUNDED',
          display: 'Aggregate'
        },
        {
          internal: 'RUNNING',
          display: 'Accumulate'
        },
      ];
      rangeDisabledFor = ['ROW_NUMBER', 'RANK', 'DENSE_RANK',
        'PERCENT_RANK', 'CUMULATIVE_PERCENT_RANK', 'LEAD', 'LAG'];
      entireWindowFuncs = ['FIRST_VALUE', 'LAST_VALUE', 'NTH_VALUE', 'STDDEV',
          'VARIANCE', 'MIN', 'MAX', 'NTILE'];
      range: string = 'RUNNING';

      handleFuncChange(funcName){
        self.functionExistsInAppHelp = self.checkIfFunctionExistsInAppHelp(funcName) == true;
        if(this.entireWindowFuncs.indexOf(funcName) != -1){
          this.range = 'UNBOUNDED';
        }
        else {
          this.range = 'RUNNING';
        }
        if(self.evalManager.singleArgNumFuncs.indexOf(funcName) != -1 ){
          self.evalManager.offset = 100;
        }
      }

      getParam(func){
        if(this.rangeDisabledFor.indexOf(func) != -1){
          return null;
        }
        return this.range
      }

      setParam(p){
        if(p){
          this.range = p;
        }
      }
    }

    class EvalManager{
      public column: string = null;
      public funName: string = null;
      public offset: number = 1;
      public singleArgNumColFuncs = ['SUM', 'AVG', 'MIN', 'MAX', 'STDDEV', 'VARIANCE'];
      public zeroArgFuncs = ['COUNT', 'ROW_NUMBER', 'RANK', 'DENSE_RANK',
        'PERCENT_RANK', 'CUMULATIVE_PERCENT_RANK'];
      public rankFunctions = ['ROW_NUMBER', 'RANK', 'DENSE_RANK'];
      public singleArgColFuncs = ['FIRST_VALUE', 'LAST_VALUE'];
      public doubleArgColFuncs = ['NTH_VALUE', 'LEAD', 'LAG'];
      public singleArgNumFuncs = ['NTILE'];
      public dateColAllowedFuncs = ['MIN', 'MAX'].concat(this.singleArgColFuncs).concat(this.doubleArgColFuncs);
      public allowedFuncs: string[];
      public allowed_columns;

      public constructor(public metadata){
        this.allowed_columns =  $.grep(metadata, function (col: any) {
          return col.type == c.numeric;
        });
        this.allowedFuncs = this.singleArgNumColFuncs.concat(
          this.zeroArgFuncs, this.singleArgNumFuncs,
          this.singleArgColFuncs, this.doubleArgColFuncs);
      }

      isRankFunction(){
        return this.rankFunctions.indexOf(this.funName) != -1;
      }

      sanitizeParam(param){
        if(param.ARGUMENTS){
          let indicesOfColNotAvailable = [];
          let indicesOfNonNumericCol = [];
          _.forEach(param.ARGUMENTS, function (col) {
              let colIndex = param.ARGUMENTS.indexOf(col);
              let col_info = utils.metadata.get_column_by_internal_name(metadata, col);
              if(!col_info){
                indicesOfColNotAvailable.push(colIndex)
              }else if(col_info['type']!== 'NUMERIC'){
                indicesOfNonNumericCol.push(colIndex)
              }
          });
          param.ARGUMENTS = param.ARGUMENTS.filter(function(value,index){
            return indicesOfColNotAvailable.indexOf(index) == -1 && indicesOfNonNumericCol.indexOf(index) == -1
          });
        }
      }
      setParam(param){
        // this.sanitizeParam(param);
        this.funName = param.FUNCTION;
        if(!param.ARGUMENTS){
          param.ARGUMENTS = [];
        }
        if(this.singleArgNumFuncs.indexOf(this.funName) != -1){
          this.offset = param.ARGUMENTS[0];
        }
        else{
          if(param.ARGUMENTS.length > 0){
            this.column = param.ARGUMENTS[0];
            var col_info = utils.metadata.get_column_by_internal_name(this.allowed_columns, this.column)
            if (!col_info){
              col_info = utils.metadata.get_column_by_internal_name(this.metadata, this.column)
              this.allowed_columns.push(col_info)
            }
          }
          if(param.ARGUMENTS.length > 1){
            this.offset = param.ARGUMENTS[1];
          }
        }
      }

      getParam(){
        let p: any = {
          FUNCTION: this.funName,
          SOURCES: this.column
        };
        // in case of zero argument funcs like Count, we dont need sources
        if(this.zeroArgFuncs.indexOf(this.funName) != -1){
          p.SOURCES = null;
        }
        if(this.singleArgColFuncs.indexOf(this.funName) != -1){
          p.ARGUMENTS = [this.column];
        }
        else if(this.singleArgNumColFuncs.indexOf(this.funName) != -1){
          p.ARGUMENTS = [this.column];
        }
        else if(this.doubleArgColFuncs.indexOf(this.funName) != -1){
          p.ARGUMENTS = [this.column, this.offset];
        }
        else if(this.singleArgNumFuncs.indexOf(this.funName) != -1){
          p.ARGUMENTS = [this.offset];
        }
        return p;
      }

      validate(){
        function isNotNull(v){
          return v !== null;
        }
        if(this.zeroArgFuncs.indexOf(this.funName) != -1){
          return isNotNull(this.funName);
        }
        else if(this.singleArgColFuncs.indexOf(this.funName) != -1){
          return isNotNull(this.column) && isNotNull(this.funName);
        }
        else if(this.singleArgNumColFuncs.indexOf(this.funName) != -1){
          return isNotNull(this.column) && isNotNull(this.funName);
        }
        else if(this.singleArgNumFuncs.indexOf(this.funName) != -1){
          return isNotNull(this.offset) && isNotNull(this.funName);
        }
        else if(this.doubleArgColFuncs.indexOf(this.funName) != -1){
          return isNotNull(this.offset) && isNotNull(this.column) && isNotNull(this.funName);
        }
      }
      public initTask(task_name,col){
        /* Set initial task function on basis of task which calls it */
        if(col.column) {
          this.column = col.column.internal_name;
          sourceColumnSelected();
          self.destinationManager.setIfNewColumn(true);
        }
        if (task_name == 'rank'){
          this.funName=this.allowedFuncs[this.allowedFuncs.indexOf('RANK')];
          self.destinationManager.new_column_name="Ranking of "+col.column.display_name;
          self.sortManager.setParam([[col.column.internal_name,"ASC"]]);
        }
        else if(task_name == 'rTotal'){
          this.funName=this.allowedFuncs[this.allowedFuncs.indexOf('SUM')];
          self.destinationManager.new_column_name="Running total of "+col.column.display_name;
        }
      }
    }

    class PostConditionManager{
      public applyLimits:boolean = false;
      public limit: number = 1;

      setParam(p){
        if(p && p.LIMIT){
          this.applyLimits = true;
          this.limit = p.LIMIT;
        }
        else{
          this.applyLimits = false;
          this.limit = 1;
        }
      }

      getParam(){
        if(this.applyLimits && this.limit >= 1){
          return {LIMIT: this.limit};
        }
        else{
          return true;
        }
      }
    }

    self.evalManager = new EvalManager(metadata);
    self.groupByManager = new GroupByManager();
    self.postConditionManager = new PostConditionManager();
    self.rangeManager = new RangeManager();

    function validate() {
      var is_valid=true
      if (!self.evalManager.validate()) {
        self.error = "Eval param error";
        is_valid=false;
      }

      if (self.destinationManager) {
        var is_dst_valid = self.destinationManager.validate();
        is_valid = is_valid && is_dst_valid
      }
      if (self.sortManager) {
        var is_sort_valid = self.sortManager.validate();
        is_valid = is_valid && is_sort_valid
      }
      return is_valid;
    }

    function sourceColumnSelected() {
      highlightColumns();
      let currentColumnName = self.evalManager.column;
      if (currentColumnName) {
        let currentColumn = utils.metadata.get_column_by_internal_name(self.evalManager.metadata, currentColumnName);
        if (currentColumn.type === c.numeric) {
          self.destinationManager.setAllowedTypesAndColumns([c.numeric]);
        }
        else if (currentColumn.type === c.date) {
          self.destinationManager.setAllowedTypesAndColumns([c.date]);
        }
        var source_column_format = dataview.display_properties.FORMAT_INFO[currentColumnName];
        if (!source_column_format) {
          let column = _.find(self.evalManager.metadata, function (c) {
            return c.internal_name == currentColumnName;
          });
          source_column_format = column['format'];
        }
        self.destinationManager.setDestinationFormat(source_column_format);
      }
    }

    function handleColumnTypesWithinDropdown() {
      if (self.evalManager.dateColAllowedFuncs.indexOf(self.evalManager.funName) != -1) {
        self.evalManager.allowed_columns = $.grep(self.evalManager.metadata, function (col: any) {
          return col.type == c.numeric || col.type == c.date
        })
      } else {
        self.evalManager.allowed_columns = $.grep(self.evalManager.metadata, function (col: any) {
          return col.type == c.numeric
        })
      }

      // If dropdown is changed to a function which doesn't have any column arguments
      // Such as RANK, or DESNSE RANK, then set destination to NUMERIC column
      if (self.evalManager.zeroArgFuncs.indexOf(self.evalManager.funName) != -1) {
        self.destinationManager.setAllowedTypesAndColumns([c.numeric]);
      }
    }

    function highlightColumns() {
      let columnsToBeHighlighted = [];
      if (self.evalManager.column) {
        columnsToBeHighlighted.push(self.evalManager.column)
      }
      if (self.groupByManager.applyPartitioning) {
        columnsToBeHighlighted = columnsToBeHighlighted.concat(self.groupByManager.partitionBy);
      }
      if (columnsToBeHighlighted.length > 0) {
        taskUtils.highlight.sources(columnsToBeHighlighted);
      }
    }

    function resetSortForAggregateFunctions() {
      if (self.rangeManager.range === 'UNBOUNDED'){
        self.sortManager.setParam([]);
      }
      else {
        self.sortManager.addOrRemoveSort();
      }
    }

    function handleFunNameChange(){
      self.rangeManager.handleFuncChange(self.evalManager.funName);
      handleColumnTypesWithinDropdown();
    }

    function getParam() {
      var evalParam: any = self.evalManager.getParam();
      let windowParam:any = {EVALUATE: evalParam};


      var destination_param = self.destinationManager.getParam();
      if (destination_param.hasOwnProperty('AS') && self.context.inEditMode==true && self.context.task){
        utils.sanatizeParamForDuplicateCols(destination_param['AS'], 'INTERNAL_NAME', self.context.task)
      }
      angular.extend(windowParam, destination_param);

      var sortParams = self.sortManager.getParam();

      let groupByParam: any = self.groupByManager.getParam();
      if(groupByParam && groupByParam.length){
        windowParam.GROUP_BY = groupByParam;
      }

      let rangeParam = self.rangeManager.getParam();
      if(rangeParam){
        windowParam.RANGE = rangeParam;
      }

      if(self.evalManager.isRankFunction()){
        let limitParam = self.postConditionManager.getParam();
        if(limitParam){
          windowParam.LIMIT = limitParam.LIMIT;
        }
      }

      if (sortParams){
        windowParam.ORDER_BY = sortParams;
      } else {
        delete windowParam.ORDER_BY;
      }

      var params = {WINDOW: windowParam}

      if(self.context.hasOwnProperty('sequence')){
        params['SEQUENCE_NUMBER'] = self.context.sequence
      }
      return params;

    }
    function handlePasteParams(taskInfo){
      /** Update params with suitable replacement columns, based on display name*/

      var params = taskInfo.params;
      //Destination column
      if (params.WINDOW.hasOwnProperty('DESTINATION')){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.WINDOW, 'DESTINATION', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        //Update destination manager metadata
        self.destinationManager.metadata = self.metadata
        self.destinationManager.internal_name_to_col_map = utils.metadata.get_internal_name_to_col_map(self.metadata)

      }
      //sort params
      if (params.WINDOW.hasOwnProperty('ORDER_BY')){
        for(const index in params.WINDOW.ORDER_BY){
          if (params.WINDOW.ORDER_BY[index].length = 2){
            utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.WINDOW.ORDER_BY[index], 0 , taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
          }
        }
      }
      // grouping params
      if (params.WINDOW.hasOwnProperty('GROUP_BY')){
        for(const index in params.WINDOW.GROUP_BY){
          utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.WINDOW.GROUP_BY[index], 'COLUMN' , taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        }
      }
      //Columns used in functions like SUM, AVG, MIN
      if(self.evalManager.singleArgNumColFuncs.indexOf(params.WINDOW.EVALUATE.FUNCTION)!=-1 || self.evalManager.singleArgColFuncs.indexOf(params.WINDOW.EVALUATE.FUNCTION)!=-1){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.WINDOW.EVALUATE.ARGUMENTS,0, taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }
      //Columns used in functions like Nth Value
      if(self.evalManager.doubleArgColFuncs.indexOf(params.WINDOW.EVALUATE.FUNCTION)!=-1){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.WINDOW.EVALUATE.ARGUMENTS, 0, taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }
      return params
    }

    function setParam(param) {
      let windowParam = param.WINDOW;
      self.evalManager.setParam(windowParam.EVALUATE);
      self.groupByManager.setParam(windowParam.GROUP_BY);
      sourceColumnSelected();
      handleColumnTypesWithinDropdown();
      if (windowParam.hasOwnProperty('ORDER_BY')){
        self.sortManager.setParam(windowParam.ORDER_BY);
      }
      if(windowParam.hasOwnProperty('LIMIT')){
        self.postConditionManager.setParam({LIMIT: windowParam.LIMIT});
      }
      if(windowParam.hasOwnProperty('RANGE')){
        self.rangeManager.setParam(windowParam.RANGE);
      }
      self.destinationManager.setParam(windowParam);
    }

    function openWindowHelp(funcName) {
      $window.open(c.appHelpRoot + c.appHelp.tasks[self.helpOptions[funcName]], '_blank');
    }

    function checkIfFunctionExistsInAppHelp(funcName){
      return !!(funcName in self.helpOptions);
    }
  }
}

valWindowArgCol.$inject = ['utils','c']
export function valWindowArgCol(utils,c) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valWindowArgCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valWindowArgCol = function (modelValue, viewValue) {
        var columns = scope.$eval(attrs.valWindowArgCol)
        var is_valid = true
        if (modelValue){
          var col_info = utils.metadata.get_column_by_internal_name(columns, modelValue)
          if( col_info.hasOwnProperty('error')){
            is_valid =  false
          }
          if (!(col_info.type == c.numeric || col_info.type == c.date)){
            is_valid = false
          }
        }
        return is_valid
      }
    }
  }
}
valPartitionCol.$inject = ['utils']
export function valPartitionCol(utils) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valPartitionCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valPartitionCol = function (modelValue, viewValue) {
        var columns = scope.$eval(attrs.valPartitionCol)
        var is_valid = true
        if (modelValue){
          var col_info = utils.metadata.get_column_by_internal_name(columns, modelValue)
          if( col_info.hasOwnProperty('error')){
            is_valid =  false
          }
        }
        return is_valid

      }
    }
  }
}

export function windowGroupsDuplicateFilter() {
  return function (cols, columnIntNames, currentIndex) {
    return _.filter(cols, function (col) {
      return [-1, currentIndex].indexOf(columnIntNames.indexOf(col.internal_name)) !== -1;
    });
  };
}
