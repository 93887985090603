import * as angular from 'angular';
import * as _ from 'lodash-es';

ElementPanelFactory.$inject = ['eventCallbackManagerFactory', 'utils', '$timeout', 'clickEvents', 'elementModalService',
                                    'modalService', 'c', 'toastNotification', 'config', 'resources', 'analyticsService',
                                    'DrilldownManagerFactory', 'DataviewGlobalFilterService', 'derivatives',
                                    'featureAvailability','taskDescriber'];
export function ElementPanelFactory(eventCallbackManagerFactory, utils, $timeout, clickEvents, elementModalService,
                             modalService, c, toastNotification, config, resources, analyticsService,
                             DrilldownManagerFactory, DataviewGlobalFilterService, derivatives,
                             featureAvailability, taskDescriber) {
  return {
    get: function (dataview, elementService) {
      return new ElementPanel(dataview, elementService);
    }
  };

  function ElementPanel(dataview, elementService) {
    var self = this;
    var _prevFilterCount;
    var ELEMENTS_PANEL =  dataview.display_properties.ELEMENTS_PANEL || {};
    var globalFilter = DataviewGlobalFilterService.getByDataviewId(dataview.id);

    initState();
    self.reportUrl = null;
    self.isLoading = true;
    self.isAddElementMenuOpen = false;
    self.sharedURLBox = {
      isOpen: false,
      highlightLast: false
    };
    self.saveDisplayPropertiesInProgress = false;

    self.loading = loading;
    self.loaded = loaded;

    var renderComplete = new eventCallbackManagerFactory('elementPanelRenderComplete' + dataview.id);
    self.onRenderComplete = renderComplete.add_callback;
    self.fireRenderComplete = utils.debounce(renderComplete.fire_event, 500);

    var resizeComplete = new eventCallbackManagerFactory('elementPanelResizeComplete' + dataview.id);
    self.onResizeComplete = resizeComplete.add_callback;
    self.fireResizeComplete = utils.debounce(resizeComplete.fire_event, 500);

    var onToggleEvent = new eventCallbackManagerFactory('elementPanelToggle' + dataview.id);
    self.onToggle = onToggleEvent.add_callback;
    self.fireToggle = onToggleEvent.fire_event;
    self.elementModalService = elementModalService;

    self.toggleFilter = toggleFilter;
    globalFilter.onChange('fromInsideElementPanel', function(){
      $timeout(applyFilters, 100);
    });
    $timeout(applyFilters, 100);

    elementService.onElementsUpdated('elementPanelListener', elementPanelListener);
    clickEvents.onClick('closeElementAddPanel', closeElementAddPanel);
    self.renderElements = false;
    self.applyFilters = applyFilters;
    self.resetFilters = resetFilters;
    self.close = close;
    self.open = open;
    self.openAddElementMenu = openAddElementMenu;
    self.toggle = toggle;
    self.toggleFullScreen = toggleFullScreen;
    self.setExpandedState = setExpandedState;
    self.setElementsDisplayOrder = setElementsDisplayOrder;
    self.toggleMetricGridViewState = toggleMetricGridViewState;

    self.addElement = addElement;
    self.editElement = editElement;
    self.editMetric = editMetric;
    self.deleteElement = deleteElement;
    self.duplicateElement = duplicateElement;
    self.initState = initState;
    self.drilldownManagers = {};
    self.datastores = {};
    self.viewProperties = {};
    self.toggleViewSourceTable = toggleViewSourceTable;
    self.describeCondition = describeCondition;
    if(!featureAvailability.isFeatureEnabled(c.appFeatures.elements)){
      self.close();
    }

    $timeout(function () {
      self.renderElements = true;
    }, 3000);

    function loading() {
      self.isLoading = true;
    }

    function loaded() {
      self.isLoading = false;
    }

    function initState(){
      ELEMENTS_PANEL = dataview.display_properties.ELEMENTS_PANEL || {};
      self.isOpen = (ELEMENTS_PANEL.SHOW === undefined) ? false: ELEMENTS_PANEL.SHOW;
      self.fullScreen = (ELEMENTS_PANEL.EXPANDED === undefined) ? false: ELEMENTS_PANEL.EXPANDED;
      self.isFilterOpen = (ELEMENTS_PANEL.SHOW_FILTER === undefined) ? false : ELEMENTS_PANEL.SHOW_FILTER;
      self.metricGridView = (ELEMENTS_PANEL.METRIC_GRID_VIEW === undefined) ? true : ELEMENTS_PANEL.METRIC_GRID_VIEW;
      self.expandedState = (ELEMENTS_PANEL.EXPANDED_STATE === undefined) ? 1: ELEMENTS_PANEL.EXPANDED_STATE;
    }

    function initDrilldownManagers() {
      angular.forEach(elementService.elements, function (ele) {
        if (!self.drilldownManagers.hasOwnProperty(ele.id)) {
          self.drilldownManagers[ele.id] = new DrilldownManagerFactory(ele, elementService);
        }
      });
      angular.forEach(elementService.mainList, function (ele) {
        if (!self.datastores.hasOwnProperty(ele.id)) {
          self.datastores[ele.id] = {};
        }
      });
    }

    function describeCondition(condition){
      return taskDescriber.describeCondition(dataview.metadata, condition);
    }

    function toggleViewSourceTable(datastore) {
      if (!datastore) { return; }
      if (!datastore.hasOwnProperty('sourceTableView')) {
        datastore.sourceTableView = {
          enabled: true,
          visible: false
        };
      }
      datastore.sourceTableView.visible = !datastore.sourceTableView.visible;
    }

    function setElementsDisplayOrder(orderArray) {
      ELEMENTS_PANEL = dataview.display_properties.ELEMENTS_PANEL || {};
      var EXISTING_ELEMENTS_PANEL = _.cloneDeep(ELEMENTS_PANEL);
      var _listKey = self.fullScreen ? 'EXPANDED' : 'NORMAL';
      if (!ELEMENTS_PANEL.ELEMENTS_ORDER) {
        ELEMENTS_PANEL.ELEMENTS_ORDER = {NORMAL: [], EXPANDED: []};
      }
      ELEMENTS_PANEL.ELEMENTS_ORDER[_listKey] = orderArray;
      if (!angular.equals(ELEMENTS_PANEL, EXISTING_ELEMENTS_PANEL)) {
        self.saveDisplayPropertiesInProgress = true;
        dataview.setDisplayProperties({'ELEMENTS_PANEL': ELEMENTS_PANEL});
      }
    }


    function toggleFullScreen(setTo) {
      if(!featureAvailability.isFeatureEnabled(c.appFeatures.nonMetricElements)){
        setTo = false;
      }
      $timeout(function () {
        self.fullScreen = setTo !== undefined ? !!setTo : !self.fullScreen;
        self.fireResizeComplete();
        $timeout(function () {
          _save_display_properties('EXPANDED', self.fullScreen);
        }, 0);
        analyticsService.userEventTrack(c.userEvents.elements.panel.fullscreenToggled, {
          dataviewId: dataview.id, isFullscreen:self.fullScreen
        });
      }, 0);
    }

    function setExpandedState(setTo) {
      if(!featureAvailability.isFeatureEnabled(c.appFeatures.nonMetricElements)){
        setTo = setTo ? 1 : 0;
      }
      $timeout(function () {
        self.expandedState = setTo;
        self.fireResizeComplete();
        $timeout(function () {
          _save_display_properties('EXPANDED_STATE', self.expandedState);
        }, 0);
        analyticsService.userEventTrack(c.userEvents.elements.panel.expandedState, {
          dataviewId: dataview.id, expandedState: self.expandedState
        });
      }, 0);
    }

    function toggleMetricGridViewState(setTo){
      $timeout(function () {
        self.metricGridView = setTo !== undefined ? !!setTo : !self.metricGridView;
        $timeout(function () {
          _save_display_properties('METRIC_GRID_VIEW', self.metricGridView);
        }, 0);
        analyticsService.userEventTrack(c.userEvents.elements.panel.fullscreenToggled, {
          dataviewId: dataview.id, metricGridView: self.metricGridView
        });
      }, 0);
    }

    function toggle(setTo) {
      self.isOpen = setTo !== undefined ? !!setTo : !self.isOpen;
      if (self.isOpen) {
        self.fireResizeComplete();
      }
      self.fireToggle();
      _save_display_properties('SHOW', self.isOpen);
      analyticsService.userEventTrack(c.userEvents.elements.panel.toggled, {
        dataviewId: dataview.id, isOpen:self.isOpen
      });
    }

    function toggleFilter(isOpen) {
      var _prev = self.isFilterOpen;
      self.isFilterOpen = (isOpen !== undefined) ? !!isOpen : !self.isFilterOpen;
      if (_prev !== self.isFilterOpen) {
        _save_display_properties('SHOW_FILTER', self.isFilterOpen);
      }
    }

    function openAddElementMenu() {
      self.isAddElementMenuOpen = true;
    }

    function open() {
      self.isOpen = true;
      self.fireResizeComplete();
      _save_display_properties('SHOW', true);
    }

    function close() {
      self.isOpen = false;
      _save_display_properties('SHOW', true);
    }

    function _save_display_properties(key, value){
      self.saveDisplayPropertiesInProgress = true;
      ELEMENTS_PANEL[key] = value;
      dataview.setDisplayProperties({'ELEMENTS_PANEL': ELEMENTS_PANEL});
    }

    function applyFilters() {
      var conditions = [];
      $.each(elementService.filters, function (i, filter) {
        if (filter.current_query) {
          conditions.push(filter.current_query);
        }
      });
      var final_condition;
      if (conditions.length == 0) {
        final_condition = null;
      }
      else if (conditions.length == 1) {
        final_condition = conditions[0];
      }
      else {
        final_condition = {"AND": conditions};
      }

      if(globalFilter.isActive){
        if(final_condition){
          final_condition = {"AND": [globalFilter.getCondition(), _.cloneDeep(final_condition)]};
        }
        else{
          final_condition = globalFilter.getCondition();
        }
      }

      if (!angular.equals(self.condition, final_condition)) {
        self.condition = _.cloneDeep(final_condition);
      }
    };

    function resetFilters() {
      self.condition = null;
      Object.keys(elementService.filters).forEach(function(fid){
        elementService.filters[fid].fireFilterReset();
      })
    }

    function closeElementAddPanel() {
      $timeout(function () {
        if (self.isAddElementMenuOpen) {
          self.isAddElementMenuOpen = false;
        }
      }, 0);
    }

    function elementPanelListener() {
      if (_prevFilterCount !== undefined) {
        if (_prevFilterCount === 0 && elementService.filtersLength === 1) {
          self.toggleFilter(true);
        }
      }
      _prevFilterCount = elementService.filtersLength;
      initDrilldownManagers();
    }

    function _openElementModal(element, isEdit?){
      elementModalService.open({
        element:  element,
        dataview: dataview,
        isEdit: !!isEdit
      }).then(_closeSuccessCallback, _closeFailureCallback);
      function _closeSuccessCallback(){
        if(!self.isOpen){
          self.open();
        }
        self.applyFilters();
      }
      function _closeFailureCallback(){
      }
    }

    function addElement(eType){
      _openElementModal({type: eType});
      analyticsService.userEventTrack(c.userEvents.elements.editor.addOpened, {elementType: eType});
    }

    function editElement(element){
      _openElementModal(element, true);
      analyticsService.userEventTrack(c.userEvents.elements.editor.editOpened, {
        elementType: element.type, elementId: element.id, dataviewId: element.dataviewId
      });
    }

    function editMetric(metric){
      _openElementModal(metric.derivative);
      analyticsService.userEventTrack(c.userEvents.elements.editor.editOpened, {
        elementType: metric.type, elementId: metric.id, dataviewId: metric.dataviewId
      });
    }

    function duplicateElement(element){
      derivatives.add(element.dataviewId, element.param, element.display_properties).then(elementService.updateList);
      analyticsService.userEventTrack(c.userEvents.elements.duplicated, {
        elementType: element.type, elementId: element.id, dataviewId: element.dataviewId
      });
      toastNotification.success('Duplicated Metric');
    }

    function deleteElement(element){
      var item = _.cloneDeep(element);
      modalService.deleteDerivative(dataview, element).then(_closeCallback);
      function _closeCallback(data){
        elementService.pollForUpdatesUntilReady().then(function(){
          if(Object.keys(elementService.elements).length === 0){
            self.close();
          }
          else{
            applyFilters();
          }
        });
        $timeout(function () {
          toastNotification.success('Deleted ' + config.resourceTypeToNameMap[item.type]);
          resources.update().then();
        }, 500);
      }
    }
  }
}

