import * as $ from 'jquery';
import * as _ from 'lodash-es';

formatTextManagerFactory.$inject = ['filterManagerFactory', 'c', 'FilterEditHelperFactory','utils','filterUI'];
export function formatTextManagerFactory(filterManagerFactory, c, FilterEditHelperFactory, utils, filterUI) {
  return {
    get_manager: get_manager
  };

  function get_manager(options) {
    return new formatTextManager(options);
  }

  function formatTextManager(options) {
    var metadata = options.metadata, taskUtils = options.taskUtils;
    var self = this;
    self.metadata = metadata;
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    self.getParam = getParam;
    self.validate = validate;
    self.setParam = setParam;
    self.sanitizeParam = sanitizeParam;
    self.openConditionMenu = openConditionMenu;
    self.openConditionModal = openConditionModal;
    self.handlePasteParams = handlePasteParams;
    self.toggleSelection = toggleSelection;
    self.toggleCaseChange = toggleCaseChange;
    self.handleCaseChange = handleCaseChange;
    self.validate_params = validate_params;
    self.highlightColumns = highlightColumns;
    self.filterEditHelper = new FilterEditHelperFactory();
    self.filterEditHelper.onMaximizeFilterPopoverToModal('maximizeFilterPopoverToModal',  self.openConditionModal)
    self.filterManager = filterManagerFactory.get_manager({
      metadata: metadata,
      dataviewId: options.context.dataview.id,
      context: options.context,
      originalSequenceNumber: options.originalSequenceNumber
    });

    self.sourceColumns = $.grep(self.metadata, function (col: any) {
      return col.type === c.text;
    });


    self.param = {
      TEXT_TRANSFORM: {
        SOURCE: []
      }
    };

    var exampleText = "Jim Smith";

    self.configOptions = {
      allowChangeCase: false
    };
    self.runId = Math.random();

    function openConditionMenu() {
      // filterUI.open(self.filterManager);
      self.filterEditHelper.open(self.filterManager);
    }

    function openConditionModal(param=null){
      filterUI.open(self.filterManager, param);
    }

    function sanitizeParam(param){
      let indicesOfColNotAvailable = [];
      let indicesOfColWithTypeMismatch = [];
      _.forEach(param.TEXT_TRANSFORM.SOURCE,function(source_col){
        let source_col_index = param.TEXT_TRANSFORM.SOURCE.indexOf(source_col);
        let col_info = _.filter(self.metadata, {'internal_name': source_col});
        if (col_info.length==0){
           indicesOfColNotAvailable.push(source_col_index)
        }
        else if (col_info[0]['type']!= 'TEXT'){
          indicesOfColWithTypeMismatch.push(source_col_index)
        }
      });
      param.TEXT_TRANSFORM.SOURCE = param.TEXT_TRANSFORM.SOURCE.filter(function(value,index){
        return indicesOfColNotAvailable.indexOf(index) == -1 && indicesOfColWithTypeMismatch.indexOf(index) == -1
      });
    }

    function getNewSrcColumns(replacementColumnMaps) {
      var newSourceColumns = []
      for (const internalName in replacementColumnMaps) {
        newSourceColumns.push(replacementColumnMaps[internalName]['internal_name']);
      }
      return newSourceColumns
    }

    function handlePasteParams(taskInfo){
      var param = taskInfo.params
      /** Update source and condition params with suitable replacement columns, based on display name*/
      for(const index in param.TEXT_TRANSFORM.SOURCE){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(param.TEXT_TRANSFORM.SOURCE, index, taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }
      var columnUsedInCondition = utils.get_input_columns(_.cloneDeep(param.CONDITION))
      if (columnUsedInCondition) {
        utils.metadata.findReplaceColumnsInCondition(self.metadata, self.displayNameAndTypeToColumnMap, param, columnUsedInCondition, taskInfo)
      }
      self.filterManager.metadata = self.metadata
      return param
    }


    function getParam() {
      if (self.filterManager.condition) {
        self.param.CONDITION = self.filterManager.getParam();
      }
      else {
        delete self.param.CONDITION;
      }
      if (options.context.hasOwnProperty('sequence')){
        self.param['SEQUENCE_NUMBER'] = options.context['sequence']
      }
      return self.param;
    }
    function setParam(param) {
      // self.sanitizeParam(param);
      if (param.TEXT_TRANSFORM.CASE) {
        self.configOptions.allowChangeCase = true;
        self.param.TEXT_TRANSFORM.CASE = param.TEXT_TRANSFORM.CASE;
      }

      if (param.hasOwnProperty('CONDITION')) {
        self.filterManager.setParam(param.CONDITION, param?.EXECUTION_TIMESTAMP);
      }

      /**Extend soucrColumns list with columns which are present in params but are no longer of type text*/
      var sourceColumnInternalNames = _.map(self.sourceColumns,'internal_name')
      _.forEach(param.TEXT_TRANSFORM.SOURCE, function(selected_column_internal_name){
        if(sourceColumnInternalNames.indexOf(selected_column_internal_name) == -1){
          var column = _.find(self.metadata,function (c) {
               return c.internal_name == selected_column_internal_name
          })
          self.sourceColumns.push(column)
        }
      }.bind(self))
      self.param = _.cloneDeep(param);

      handleCaseChange();
      // highlightColumns();
    }

    function validate() {
      var isValid = true
      if (self.filterManager.condition){
        isValid = self.filterManager.condition.validate()
      }
      return isValid;
    }

    function toggleSelection(internal_name) {

      if (self.param.TEXT_TRANSFORM.SOURCE.indexOf(internal_name) == -1) {
        self.param.TEXT_TRANSFORM.SOURCE.push(internal_name);
      }
      else {
        var index = self.param.TEXT_TRANSFORM.SOURCE.indexOf(internal_name);
        if (index > -1) {
          self.param.TEXT_TRANSFORM.SOURCE.splice(index, 1);
        }
      }
      // following is done because the model was not working right on the view
      self.param.TEXT_TRANSFORM.SOURCE = _.cloneDeep(self.param.TEXT_TRANSFORM.SOURCE);
      //Update runId to trigger model changes so that valColTypeTextTransform validates the params
      self.runId = Math.random()
      highlightColumns();
    }

    function toggleCaseChange() {
      if (!self.configOptions.allowChangeCase) {
        delete self.param.TEXT_TRANSFORM.CASE
      }
      else {
        self.param.TEXT_TRANSFORM.CASE = "UPPER";
        self.exampleText = exampleText;
        self.exampleTextResult = self.exampleText.toUpperCase();
      }

    }


    function handleCaseChange() {
      switch (self.param.TEXT_TRANSFORM.CASE) {
        case 'UPPER':
          self.exampleText = exampleText;
          self.exampleTextResult = self.exampleText.toUpperCase();
          break;

        case 'LOWER':
          self.exampleText = exampleText;
          self.exampleTextResult = self.exampleText.toLowerCase();
          break;

        case 'TITLE':
          self.exampleText = exampleText.toLowerCase();
          self.exampleTextResult = self.exampleText.split(' ').map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }).join(' ');

          break;
      }
    }

    function validate_params(val) {
      return val.hasOwnProperty('CASE') || (val.hasOwnProperty('TRIM') && val.TRIM === true);
    }

    function highlightColumns() {
      taskUtils.highlight.sources(self.param.TEXT_TRANSFORM.SOURCE);
    }
  }
}

/**Validate that columns selected for formatting are of type 'TEXT'*/
valTextTransformCol.$inject = [];
export function valTextTransformCol() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valTextTransformCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valTextTransformCol = function (modelValue, viewValue) {
        let isValid = true;
        var selectedColumns = scope.$eval(attrs.selections)

        if (scope.column.type !='TEXT' && selectedColumns.indexOf(scope.column.internal_name)!=-1){
          isValid = false;
        }else if(scope.column.hasOwnProperty('error') && selectedColumns.indexOf(scope.column.internal_name)!=-1){
          isValid = false;
        }
        return isValid;
      };
    }
  };
}
