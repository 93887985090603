<template>
  <div class="mm-action-bar-export-and-share">
    <action-bar-button
      class="action-bar-button--export"
      icon="actionbar_share"
      :label="$t('action_bar.export.button_label')"
      @click="isExportListModalOpen = !isExportListModalOpen"
    />
    <action-bar-export-list-modal
      v-model="isExportListModalOpen"
      :title="$t('action_bar.export.modal_title')"
      :items="computedExportAndShareItems"
      @itemClick="onListModalItemClick"
    />
    <action-bar-export-publish-as-a-report v-model="isPublishAsAReportModalOpen" />
    <action-bar-export-to-database ref="exportToDatabase" v-model="isExportToDatabaseModalOpen" />
    <action-bar-export-sftp ref="sftp" v-model="isSftpModalOpen" />
    <action-bar-export-save-as-dataset ref="save-as-dataset" v-model="isSaveAsDatasetModalOpen" />
  </div>
</template>

<script>
import ActionBarButton from '../action-bar-button/action-bar-button.vue'
import ActionBarExportListModal from './list-modal/action-bar-export-list-modal.vue'
import ActionBarExportPublishAsAReport from './publish-as-a-report/action-bar-export-publish-as-a-report.vue'
import ActionBarExportToDatabase from './database/action-bar-export-to-database.vue'
import ActionBarExportSftp from './sftp/action-bar-export-sftp.vue'
import ActionBarExportSaveAsDataset from './save-as-dataset/action-bar-export-save-as-dataset.vue'
import actionBarApi from '@/modules/data-editor/action-bar/action-bar.api'
import { randomString } from '@/utils/string'
import { USER_EVENTS, SPLITIO_FEATURE_FLAGS, XLSX_DOWNLOAD_MAX_ROW_LIMIT } from '@/constants'
import { featuresVisibility } from '@/plugins/splitio'
import { mapState } from 'vuex'

export default {
  name: 'action-bar-export',
  components: {
    ActionBarButton,
    ActionBarExportListModal,
    ActionBarExportPublishAsAReport,
    ActionBarExportToDatabase,
    ActionBarExportSftp,
    ActionBarExportSaveAsDataset
  },
  data: () => ({
    isExportListModalOpen: false,
    isPublishAsAReportModalOpen: false,
    isLiveLinkLoading: false,
    isExportToDatabaseModalOpen: false,
    isSftpModalOpen: false,
    isSaveAsDatasetModalOpen: false,
    featuresVisibility
  }),
  computed: {
    ...mapState({
      computedDataEditorSequence: (state) => state.dataEditor.sequence,
      computedDataEditorIsPreview: (state) => state.dataEditor.isPreview,
      computedDataEditorRowCount: (state) => state.dataEditor.rowCount,
      computedataEditorFilterCondition: (state) => state.dataEditor.viewsMap[state.dataEditor.viewId]?.filterCondition,
      computedDataEditorVisibleRowCount: (state) => state.dataEditor.viewsMap[state.dataEditor.viewId]?.visibleRowCount
    }),
    computedExportAndShareItems() {
      const t = (id, params) => this.$t(`action_bar.export.${id}`, params)
      const isReportPublished = this.$store.getters['pipeline/isReportPublished']
      const isLiveLinkGenerated = this.$store.getters['pipeline/isLiveLinkGenerated']

      return [
        {
          id: 'publish-as-a-report',
          title: t('publish_as_a_report.title'),
          description: t('publish_as_a_report.sub_title'),
          disabled: this.computedDataEditorIsPreview || isReportPublished,
          userEventKey: 'PUBLISH_AS_REPORT_CLICK',
          tooltip: this.computedDataEditorIsPreview
            ? t(`preview_tooltip`)
            : isReportPublished
            ? t('publish_as_a_report.tooltip.disabled')
            : '',
          featureToValidate: 'isPrivateViewEnabled'
        },
        {
          id: 'live-link',
          title: t('live_link.title'),
          description: t('live_link.sub_title'),
          disabled: this.$store.state.dataEditor.isPreview || isLiveLinkGenerated,
          loading: this.isLiveLinkLoading,
          userEventKey: 'LIVE_LINK_CLICK',
          tooltip: this.computedDataEditorIsPreview
            ? t(`preview_tooltip`)
            : isLiveLinkGenerated
            ? t('live_link.tooltip.disabled')
            : '',
          render: () => this.featuresVisibility[SPLITIO_FEATURE_FLAGS.EXPORT.LIVE_LINK],
          featureToValidate: 'isLiveLinkEnabled'
        },
        {
          id: 'download-csv',
          title: t(`download_data.title`, { fileType: 'CSV' }),
          description: t('download_data.csv_sub_title'),
          userEventKey: 'DOWNLOAD_CSV_CLICK',
          render: () => this.featuresVisibility[SPLITIO_FEATURE_FLAGS.EXPORT.DOWNLOAD_CSV],
          featureToValidate: 'isCSVExcelExportEnabled'
        },
        {
          id: 'download-xlsx',
          title: t(`download_data.title`, { fileType: 'XLSX' }),
          description: t('download_data.xlsx_sub_title'),
          disabled:
            (!this.computedataEditorFilterCondition
              ? this.computedDataEditorRowCount
              : this.computedDataEditorVisibleRowCount) > XLSX_DOWNLOAD_MAX_ROW_LIMIT,
          tooltip:
            (!this.computedataEditorFilterCondition
              ? this.computedDataEditorRowCount
              : this.computedDataEditorVisibleRowCount) > XLSX_DOWNLOAD_MAX_ROW_LIMIT
              ? t(`download_data.download_xlsx_tooltip`, {
                  maxLimitRowCount: XLSX_DOWNLOAD_MAX_ROW_LIMIT.toLocaleString()
                })
              : '',
          userEventKey: 'DOWNLOAD_XLSX_CLICK',
          render: () => this.featuresVisibility[SPLITIO_FEATURE_FLAGS.EXPORT.DOWNLOAD_XLSX],
          featureToValidate: 'isCSVExcelExportEnabled'
        },
        {
          id: 'export-to-database',
          title: t('export_to_database.title'),
          description: t('export_to_database.sub_title'),
          userEventKey: 'EXPORT_TO_DATABASE_CLICK',
          disabled: this.computedDataEditorIsPreview,
          tooltip: this.computedDataEditorIsPreview ? t(`preview_tooltip`) : ''
        },
        {
          id: 'sftp',
          title: t('sftp.title'),
          description: t('sftp.sub_title'),
          userEventKey: 'EXPORT_VIA_SFTP_CLICK',
          disabled: this.computedDataEditorIsPreview,
          render: () => this.featuresVisibility[SPLITIO_FEATURE_FLAGS.EXPORT.sftp],
          tooltip: this.computedDataEditorIsPreview ? t(`preview_tooltip`) : ''
        },
        {
          id: 'save-as-dataset',
          title: t('save_as_dataset.title'),
          description: t('save_as_dataset.sub_title'),
          userEventKey: 'SAVE_AS_DATASET_CLICK',
          render: () => this.featuresVisibility[SPLITIO_FEATURE_FLAGS.EXPORT.SAVE_AS_DATASET],
          tooltip: this.computedDataEditorIsPreview ? t(`preview_tooltip`) : '',
          validateStorageLimit: true
        }
      ].filter((option) => (option.render ? option.render() : true))
    }
  },
  methods: {
    onListModalItemClick({ id, userEventKey, title }) {
      switch (id) {
        case 'publish-as-a-report':
          this.isExportListModalOpen = false
          this.isPublishAsAReportModalOpen = true
          break

        case 'live-link':
          this.generateLiveLink()
          break

        case 'download-csv':
          this.downloadData('csv')
          this.isExportListModalOpen = false
          break

        case 'download-xlsx':
          this.downloadData('xlsx')
          this.isExportListModalOpen = false
          break

        case 'export-to-database':
          this.isExportListModalOpen = false
          this.isExportToDatabaseModalOpen = true
          break

        case 'sftp':
          this.isExportListModalOpen = false
          this.isSftpModalOpen = true
          break

        case 'save-as-dataset':
          this.isExportListModalOpen = false
          this.isSaveAsDatasetModalOpen = true
          break
      }
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT[userEventKey], {
        viewId: this.$store.state.dataEditor.viewId,
        actionName: title
      })
    },
    async generateLiveLink() {
      this.isLiveLinkLoading = true

      const response = await actionBarApi.generateLiveLink({
        viewId: this.$store.getters['dataEditor/view'].viewId,
        fileName: this.getFileName('csv'),
        message: this.$t('action_bar.export.live_link.message')
      })

      if (response.status == 'error') this.$toast.show({ content: response.message, status: 'error' })
      else {
        await this.$root.legacyHandlers.actionServiceUpdateList()
        this.isExportListModalOpen = false
        this.$root.legacyHandlers.openPipeline()
        this.$toast.show(this.$t('action_bar.export.live_link.message'))
      }

      this.isLiveLinkLoading = false
    },
    async downloadData(fileType) {
      this.isExportListModalOpen = false
      const data = await actionBarApi.downloadCsv({
        viewId: this.$store.getters['dataEditor/view'].viewId,
        fileName: this.getFileName(fileType),
        condition: this.$store.state.dataEditor.viewsMap[this.$store.getters['dataEditor/view'].viewId].filterCondition,
        sequence: this.computedDataEditorSequence,
        fileType
      })

      // TODO: Add transient toast which can show that csv download is getting ready
      if (data.error) this.$toast.show({ content: data.message, status: 'error' })
      else window.open(data.url, '_self')
    },
    getFileName(fileType) {
      const datasetName = this.$store.getters['dataEditor/dataset'].properties.name.substring(0, 10)
      const viewName = this.$store.getters['dataEditor/view'].properties.name.substring(0, 10)
      const fileName = `${datasetName} ${viewName} ${randomString(16)}`

      return fileName.toLowerCase().replace(/\s+/g, '_') + '.' + fileType
    }
  }
}
</script>
