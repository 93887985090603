import * as angular from 'angular';
import { compact, map, forEach } from 'lodash';
import { Dictionary } from '../common/types/datatypes';
import { ILabelService } from '../common/types/core';
import _ = require('lodash-es');
export class labelService implements ILabelService {
  private labelsAPI;
  private labelResourcesAPI;
  private labelsResourcesAPI;
  public listUpdated;
  public labelChanged;
  private _prevList;
  private _labels_map = {};
  public labelsDict = this._labels_map;
  private resourcesMapPointer: Dictionary<any>;

  static $inject = ['$resource', 'config', 'eventCallbackManagerFactory', 'Label', '$q',
    'toastNotification', 'FutureService', 'c', 'VuexStore'];

  constructor(private $resource, private config, private eventCallbackManagerFactory,
              private Label, private $q, private toastNotification, private FutureService, private c, private $store) {
    this.labelsAPI = $resource(config.api.labels);
    this.labelResourcesAPI = $resource(config.api.labelResources);
    this.labelsResourcesAPI = $resource(config.api.labelsResources);
    this.listUpdated = new eventCallbackManagerFactory('label_list_updated');
    this.labelChanged = new eventCallbackManagerFactory('label_changed');
  }

  public initResourcesMap(resourcesMap: Dictionary<any>) {
    this.resourcesMapPointer = resourcesMap;
  }

  public reset() {
    _.forOwn(this._labels_map, function(v,k,o){_.unset(o,k)})
  }



  public create(labelName, parentRID) {
    let self = this;
    const deferred = this.$q.defer();
    this.labelsAPI.save({
      'name': labelName,
      'parent_resource_id': parseInt(parentRID),
      'project_id': this.$store.state.projectId
    }).$promise.then((data) => createLabelSuccess(data), (data) => {deferred.reject(data.data)});
    return deferred.promise;
    function createLabelSuccess(data) {
      self.labelChanged.fire_event();
      self.toastNotification.success('Created new folder <em>' + data.name + '</em>');
      deferred.resolve(data);
      return true;
    }
  }

  public move(destinationLabelRID, resourceRIDs: string[]|number[]) {
    let self = this;
    const deferred = this.$q.defer();

    _.forEach(resourceRIDs, (resourceId: number | string) => {
      self.resourcesMapPointer[resourceId].isMoving = true;
    })

    if (destinationLabelRID !== undefined) {
      this.labelResourcesAPI.save({labelRId: destinationLabelRID},
        {
          'resource_RIDs': resourceRIDs
        }).$promise.then((data) => moveSucess(data), (data) => moveFailure(data.data));
    } else {
      this.labelsResourcesAPI.patch({}, {
        "patch": [{
          "op": "remove",
          "path": "folder",
          "value": { resource_ids: resourceRIDs, project_id: this.$store.state.projectId}
        }]
      }).$promise.then((data) => moveSucess(data), (data) => moveFailure(data.data));
    }
    return deferred.promise;

    function moveSucess(data) {
      if (data.future_id) {
        self.FutureService.track(data.future_id, get_tracker(data));
      } else {
        _markSuccess(data);
      }
    }

    function get_tracker(data) {
      return function move_request_tracker(future) {
        if (future.status == "processing") { return; }
        if (future.status == "success") {
          _markSuccess(data);
        }
        else {
          moveFailure(data);
        }
      }
    }


    function _markSuccess(data) {
      self.labelChanged.fire_event();
      let message;
      if (resourceRIDs.length < 3) {
        const resNameList = compact(map(resourceRIDs as number[],
          (rid) => self.resourcesMapPointer[rid].shortName || self.resourcesMapPointer[rid].name
        ));
        message = 'Moved <em>' + resNameList.join("</em>, <em>") + '</em>';
      } else {
        message = 'Moved ' + resourceRIDs.length + ' items';
      }
      if (destinationLabelRID !== undefined) {
        const label = self.resourcesMapPointer[destinationLabelRID];
        const labelName = self.resourcesMapPointer[destinationLabelRID].shortName;
        message +=
          ` to folder <a href="#/workspaces/${self.$store.state.workspaceId}/projects/${self.$store.state.projectId}/folders/` +
          label.resource_id +
          '">' +
          labelName +
          '</a>';
      } else {
        message += ` to <a href="#/workspaces/${self.$store.state.workspaceId}/projects/${self.$store.state.projectId}">Data Library</a>`;
      }
      _.forEach(resourceRIDs, (resourceId: number | string) => {
        self.resourcesMapPointer[resourceId].isMoving = false;
      })
      self.toastNotification.success(message);
      deferred.resolve(data);
    }

    function moveFailure(data) {
      let errorMessage = data.ERROR_MESSAGE;
      if (data.ERROR_CODE == 3009) {
        errorMessage = "Cannot move a folder into one of its descendant folders"
      }
      _.forEach(resourceRIDs, (resourceId: string | number) => {
        self.resourcesMapPointer[resourceId].isMoving = false;
      })
      self.toastNotification.error(errorMessage);
      deferred.reject(data);
    }
  }

  public deleteLabel(label, check_for_dependency) {
    let deleteLabelPromise = null;
    if (label) {
      deleteLabelPromise = label.del({'check_for_dependency': check_for_dependency});
    } else {
      deleteLabelPromise = this.$q.defer().reject();
    }

    return deleteLabelPromise;
  }

  public multiDeleteLabels(labels) {
    let deferred = this.$q.defer();
    let label_resource_ids = labels.map(function (item) {
      if (item.type === 'label') {
        return item.resource_id;
      }
    });

    this.labelsAPI.patch({
      patch: [{
        "op": "remove",
        "path": "labels",
        "value": label_resource_ids
      }]
    }).$promise.then(del_success());


    function del_success() {
      forEach(labels, (label) => {
        label.status = "deleting";
      });
      deferred.resolve();
    }

    return deferred.promise;
  }


  public rename(label, newName) {
    if (!label) {
      return this.$q.defer().reject();
    }
    return label.rename(newName);
  }

  public updateList(labelList, resource_update_callback, resource_delete_callback) {
    if (angular.equals(this._prevList, labelList)) {
      return false;
    }
    this._prevList = _.cloneDeep(labelList);
    forEach(labelList, (label) => {
      if (label.objectStatus == this.c.coreListObjectStatus.deleted) {
        resource_delete_callback(this._labels_map[label.resource_id]);
        delete this._labels_map[label.resource_id];
      }
      else {
        if (this._labels_map.hasOwnProperty(label.resource_id)) {
          this._labels_map[label.resource_id].update(label);
        } 
        else {
          this._labels_map[label.resource_id] = new this.Label(label);
        }
        resource_update_callback(this._labels_map[label.resource_id]);
      }
    });
    this.listUpdated.fire_event();
    return true;
  }
}
