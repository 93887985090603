'use strict';
import { StateProvider, UrlService, Ng1StateDeclaration } from '@uirouter/angularjs';
import * as angular from 'angular';

OnConfig.$inject = [
  '$stateProvider',
  '$locationProvider',
  '$urlServiceProvider',
  '$httpProvider',
  'config',
  '$resourceProvider',
  '$uibModalProvider',
  '$analyticsProvider',
  '$animateProvider',
  'NotificationProvider',
  'CacheFactoryProvider',
];
export function OnConfig(
  $stateProvider: StateProvider,
  $locationProvider,
  $urlServiceProvider: UrlService,
  $httpProvider,
  config,
  $resourceProvider,
  $uibModalProvider,
  $analyticsProvider,
  $animateProvider,
  NotificationProvider,
  CacheFactoryProvider
) {
  delete $httpProvider.defaults.headers.common['X-Requested-With'];
  $httpProvider.interceptors.push('HttpRequestInterceptor');
  $httpProvider.defaults.withCredentials = true;
  $locationProvider.html5Mode(false);
  $locationProvider.hashPrefix(''); // by default '!' is used since angular 1.6
  $analyticsProvider.firstPageview(false);
  $analyticsProvider.virtualPageviews(false);
  // set default behavior for angular-cache
  let default_max_age = 5 * 60 * 1000; // 5 minutes
  angular.extend(CacheFactoryProvider.defaults, {
    maxAge: default_max_age,
    deleteOnExpire: 'aggressive',
    storageMode: 'localStorage',
    storagePrefix: 'mmCache_', // prefix for all cache keys
  });
  let allowed_animation_events = ['enter', 'leave'];
  $animateProvider.customFilter(function (node, event, options) {
    // Example: Only animate `enter` and `leave` operations.
    return allowed_animation_events.includes(event);
  });

  $stateProvider
    .state(config.states.register, {
      url: config.url.register,
      title: 'Sign up',
      views: {
        baseLayout: {
          templateProvider: [
            '$templateCache',
            function ($templateCache) {
              return $templateCache.get(config.templates.registrationForm);
            },
          ],
          controllerAs: 'vm',
          controller: config.controllers.registerForm,
        },
      },
    } as Ng1StateDeclaration)

    // expired workspace
    .state(config.states.expired, {
      url: config.url.expired,
      title: 'Workspace Expired',
      views: {
        baseLayout: {
          controller: config.controllers.expired,
          controllerAs: 'vm',
          templateProvider: [
            '$templateCache',
            function ($templateCache) {
              return $templateCache.get(config.templates.expired);
            },
          ],
        },
      },
    } as Ng1StateDeclaration)
    // ---------------------------------------------------------
    // ----------------------- VUE admin -----------------------
    /**
     * @Code reference 4
     * -> Creating route for a Vue component
     * @Comment state config for the route in angular
     * @link: https://mammoth.atlassian.net/wiki/spaces/PD/pages/1363247150/Vue+Porting#Vue-component-with-routing
     */

    .state(config.states.admin, {
      url: config.url.admin,
      title: 'Old | Admin',
      views: {
        baseLayout: {
          controller: 'adminController',
          controllerAs: 'vm',
          templateProvider: [
            '$templateCache',
            function ($templateCache) {
              return $templateCache.get(config.templates.oldadmin);
            },
          ],
        },
      },
    } as Ng1StateDeclaration)
    .state(config.states.newadmin, {
      url: config.url.newadmin,
      title: 'Admin',
      views: {
        baseLayout: {
          controller: 'adminController',
          controllerAs: 'vm',
          templateProvider: [
            '$templateCache',
            function ($templateCache) {
              return $templateCache.get(config.templates.admin);
            },
          ],
        },
      },
    } as Ng1StateDeclaration)
    .state(config.states.adminWorkspaces, {
      url: config.url.adminWorkspaces,
      title: 'Admin',
      views: {
        baseLayout: {
          controller: 'adminController',
          controllerAs: 'vm',
          templateProvider: [
            '$templateCache',
            function ($templateCache) {
              return $templateCache.get(config.templates.admin);
            },
          ],
        },
      },
    } as Ng1StateDeclaration)
    .state(config.states.adminDomains, {
      url: config.url.adminDomains,
      title: 'Domains | Admin',
      views: {
        baseLayout: {
          controller: 'adminController',
          controllerAs: 'vm',
          templateProvider: [
            '$templateCache',
            function ($templateCache) {
              return $templateCache.get(config.templates.admin);
            },
          ],
        },
      },
    } as Ng1StateDeclaration)
    .state(config.states.adminIntegrations, {
      url: config.url.adminIntegrations,
      title: 'Integrations | Admin',
      views: {
        baseLayout: {
          controller: 'adminController',
          controllerAs: 'vm',
          templateProvider: [
            '$templateCache',
            function ($templateCache) {
              return $templateCache.get(config.templates.admin);
            },
          ],
        },
      },
    } as Ng1StateDeclaration)

    .state(config.states.publish, {
      url: config.url.publish,
      title: 'Publish',
      views: {
        baseLayout: {
          controller: 'publishController',
          controllerAs: 'vm',
          templateProvider: [
            '$templateCache',
            function ($templateCache) {
              return $templateCache.get(config.templates.publish);
            },
          ],
        },
      },
    } as Ng1StateDeclaration)

    // ----------------------- VUE admin END -----------------------
    // ---------------------------------------------------------

    
 
    .state(config.states.dataviewsAbstract, {
      url: config.url.dataviewsAbstract,
      title: 'Dataview',
      abstract: true,
      views: {
        baseLayout: {
          templateProvider: [
            '$templateCache',
            function ($templateCache) {
              return $templateCache.get(config.templates.dataviewLayout);
            },
          ],
          controller: config.controllers.dataviewLayout,
          controllerAs: 'wlvm',
        },
      },
    } as Ng1StateDeclaration)
    .state(config.states.dataview, {
      url: config.url.dataview,
      views: {
        dataviewFalseView: {
          // required to cause dataview to open
          controller: [
            '$stateParams',
            'DataviewLayoutService',
            'Auth',
            'VuexStore',
            function dataviewInitController($stateParams, DataviewLayoutService, Auth, $store) {
              Auth.check_token().then(open_ws);
              $store.commit('setProjectId', +$stateParams.projectId);
              function open_ws() {
                DataviewLayoutService.open_dataview($stateParams.dataviewId);
              }
            },
          ],
        },
      },
    })
    .state(config.states.landing, {
      url: config.url.landing,
      title: 'Landing',
      resolve: {
        redirect: [
          'Auth',
          'navigationService',
          function (Auth, navigationService) {
            Auth.check_token().then(() => {
              navigationService.openFirstProjectView();
            });
          },
        ],
      },
    } as Ng1StateDeclaration)
    .state(config.states.workspaceView, {
      url: config.url.workspaceView,
      title: 'WorkspaceView',
      resolve: {
        authenticated: [
          'Auth',
          function (Auth) {
            return Auth.check_token();
          },
        ],
      },
      views: {
        baseLayout: {
          controller: config.controllers.landing,
          controllerAs: 'vm',
          templateProvider: [
            '$templateCache',
            'VuexStore',
            '$stateParams',
            function ($templateCache, $store, $stateParams) {
              $store.commit('setWorkspaceId', +$stateParams.workspaceId);
              $store.commit('setProjectId', 0);
              return $templateCache.get(config.templates.landing);
            },
          ],
        },
      },
    } as Ng1StateDeclaration)
    .state(config.states.reportView, {
      url: config.url.reportView,
      title: 'ReportView',
      views: {
        baseLayout: {
          controller: config.controllers.landing,
          controllerAs: 'vm',
          templateProvider: [
            '$templateCache',
            'VuexStore',
            '$stateParams',
            'landingList',
            function ($templateCache, $store, $stateParams, landingList) {
              $store.commit('setWorkspaceId', +$stateParams.workspaceId);
              $store.commit('setProjectId', 0);
              if(landingList.inited) {
                landingList.setCurrentLabelView("published-reports");
                $store.commit('cache/setLoader', false);
              }
              return $templateCache.get(config.templates.landing);
            },
          ],
        },
      },
    } as Ng1StateDeclaration)
    .state(config.states.projectView, {
      url: config.url.projectView,
      title: 'ProjectView',
      views: {
        baseLayout: {
          controller: config.controllers.landing,
          controllerAs: 'vm',
          templateProvider: [
            '$templateCache',
            'VuexStore',
            '$stateParams',
            'appHelperService',
            'landingList',
            'resources',
            function ($templateCache, $store, $stateParams,appHelperService, landingList, resources) {
              if($store.getters.isOnboardingDataLibraryActive){
                window.location.href = `${window.location.origin}/n/#/onboarding`;
                return
              } else {
                if($store.state.projectId && $store.state.projectId !== parseInt($stateParams.projectId)) {
                  resources.reset();
                  landingList.reset();
                }
                $store.commit('cache/setLoader', true);
                $store.commit('setWorkspaceId', +$stateParams.workspaceId);
                $store.commit('setProjectId', +$stateParams.projectId);
                landingList.initPromise.promise.then(() => {
                  landingList.setCurrentLabelView('home');
                  if(window.location.href.includes('?ds=')){
                    appHelperService.navigateToDataset(window.location.href.split('?ds=')[1])
                  }
                  $store.commit('cache/setLoader', false);
                });
                return $templateCache.get(config.templates.landing);
              }
            },
          ],
        },
      },
    } as Ng1StateDeclaration)
    .state(config.states.projectLabelView, {
      url: config.url.projectLabelView,
      title: 'ProjectViewLabel',
      views: {
        baseLayout: {
          controller: config.controllers.landing,
          controllerAs: 'vm',
          templateProvider: [
            '$templateCache',
            'VuexStore',
            '$stateParams',
            'appHelperService',
            'landingList',
            function ($templateCache, $store, $stateParams, appHelperService, landingList) {
              $store.commit('setWorkspaceId', +$stateParams.workspaceId);
              $store.commit('setProjectId', +$stateParams.projectId);
              landingList.initPromise.promise.then(() => {
                landingList.setCurrentLabelView($stateParams.labelId);
                if (window.location.href.includes('?ds=')) {
                  appHelperService.navigateToDataset(window.location.href.split('?ds=')[1])
                }
                $store.commit('cache/setLoader', false);
              });
              return $templateCache.get(config.templates.landing);
            },
          ],
        },
      },
    } as Ng1StateDeclaration);

  $urlServiceProvider.rules.otherwise(config.url.landing);


  $resourceProvider.defaults.actions.patch = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
  };

  $uibModalProvider.options.windowTemplateUrl = config.templates.modalWindow;
}
// end of config block
