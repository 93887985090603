// This is an aux store created to prevent reactivity issues on the navbar view tabs
// A parallel on mm-frontend is not needed

import Vue from "vue"

export default {
  namespaced: true,
  state: {
    resources: {
      /*
        projectId: {
          last_checked_until,
          core_list_items
        }
        */
    },
    isLoading: false,
  },
  getters: {
    getResourcesByProjectId: (state) => (id) => state.resources[id] || {},
  },
  actions: {},
  mutations: {
    setProjectResources: (state, { projectId, coreListLastCheckedUntil, newResources = {} }) => {
      let resources = newResources
      if (coreListLastCheckedUntil) {
        let currentState = state.resources[projectId]?.core_list_items?.items || {}
        Object.keys(newResources).forEach((itemKey) => {
          const item = newResources[itemKey]
          if (item.object_status == 'deleted') {
            delete currentState[itemKey]
          } else {
            currentState[itemKey] = item
          }
        })
        resources = currentState
      }
      Vue.set(state.resources, projectId, {
        core_list_items: {
          items: resources,
          changed: true,
        },
        core_list_last_checked_until: coreListLastCheckedUntil,
      })
    },
    setLoader: (state, payload) => {
      state.isLoading = payload
    }
  },
}
