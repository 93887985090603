// Dependencies
import Vue from 'vue'

// Constants
import { RESOURCE_DEPENDENCIES, RESOURCE_STATUS, RESOURCE_DRAFT_STATUS, RESOURCE_PIPELINE_STATUS } from '@/constants'

const getInitialState = () => ({
  resourcesMap: {
    /*
    123: {
      resourceId: 123,
      viewId / datasetId: 456,
      folderResourceId: 789,
      type: string,
      dependencies: {},
      dependenciesUnfiltered: {},
      properties: {
        name: string,
        tasksQuantity: 0,
        pipelineStatus: string,
        viewsIds: [],
        dataset: { id: 123 },
      }
    }
    */
  },
  pendingResources: {
    processing: [
      /*
      [123, 1, 2],
      [456, 1, 3],
      [789, 2, 6],
      [*resourceId*, *concludedTasksQuantity*, *totalTasksQuantity*],
      */
    ],
    queued: [
      /*
      [123, 2],
      [456, 3],
      [789, 0],
      [*resourceId*, *tasksQuantity*],
      */
    ]
  },
  areActionsRequiredAvailable: false, // Used to display a badge on the dataflow status icon on the navbar
  pendingPipelineChanges: [
    /*
    {
      viewId: 123,
      pendingStepsCount: 2,
      viewName: string,
      datasetName: string,
      isPipelineInError: boolean,
    }
    */
  ]
})

const getters = {
  getDatasetByDatasetId: (state) => (id) =>
    Object.values(state.resourcesMap).find((r) => r.datasetId && r.datasetId == id),
  getDatasetByViewId: (state, getters) => (viewId) => {
    const datasetId = getters.getViewByViewId(viewId)?.properties.dataset?.id
    return getters.getDatasetByDatasetId(datasetId)
  },
  getDatasetByViewResourceId: (state, getters) => (viewResourceId) => {
    const datasetId = state.resourcesMap[viewResourceId]?.properties.dataset?.id
    return getters.getDatasetByDatasetId(datasetId)
  },
  getViewByViewId: (state) => (id) => Object.values(state.resourcesMap).find((r) => r.viewId && r.viewId == id),
  getWebhookByWebhookId: (state) => (id) =>
    Object.values(state.resourcesMap).find((r) => r.webhookId && r.webhookId == id),
  getReports: (state) => Object.values(state.resourcesMap).filter((r) => r.reportId),
  areResourcesRunning: (state) => !!state.pendingResources.processing.length,
  getPendingPipelineChangesQuantity: (state, getters, rootState) =>
    // This getter is needed so that we can know the quantity of pending pipeline changes before opening the dataflow status modal
    // This quantity is needed to show/hide the badge on the navbar icon
    Object.values(state.resourcesMap).filter(
      (r) =>
        r.viewId &&
        r.status != RESOURCE_STATUS.DELETED &&
        ![RESOURCE_PIPELINE_STATUS.RUNNING, RESOURCE_PIPELINE_STATUS.SUBMITTED].includes(r.properties.pipelineStatus) &&
        (r.properties.pipelineStatus === RESOURCE_PIPELINE_STATUS.ERROR ||
          r.properties.draftModeStatus === RESOURCE_DRAFT_STATUS.DIRTY) &&
        (rootState.projectId ? rootState.projectId == r.properties.projectId : true)
    ).length,
  getAllPendingResources: (state) => [...state.pendingResources.processing, ...state.pendingResources.queued],
  getDatasetViews: (state, getters) => (datasetId) => {
    const dataset = getters.getDatasetByDatasetId(datasetId)
    return dataset.properties.viewsIds.map((viewId) => getters.getViewByViewId(viewId))
  },
  getResourcesByProject: (state, getters, rootState) => (projectId = rootState.projectId) =>
    Object.values(state.resourcesMap).filter((r) => r.properties.projectId == projectId)
}

const mutations = {
  setResources(state, newResources) {
    // Convert to map
    let newResourcesMap = {}
    newResources.forEach((r) => {
      r.workspaceId = this.state.workspaceId
      newResourcesMap[r.resourceId] = r
    })
    state.resourcesMap = { ...state.resourcesMap, ...newResourcesMap }

    const resources = Object.values(state.resourcesMap)
    resources.forEach((resource) => {
      // Set each resource dependencies. This needs to be done because 'newResources' only contains deltas
      // Dependencies need to take into account all the resources, not only the delta ones
      Vue.set(state.resourcesMap[resource.resourceId], 'dependencies', getFilteredDependencies(resource, state))

      // Set each dataset's views ids. This needs to be done because 'newResources' only contains deltas
      // The 'viewsIds' array needs to take into account all the views, not only the delta ones
      if (resource.datasetId)
        resource.properties.viewsIds = resources
          .filter((r) => r.viewId && r.properties.dataset?.id === resource.datasetId)
          .map((r) => r.viewId)
    })
  },
  setPendingResources(state, pendingResources) {
    state.pendingResources = pendingResources
  },
  setAreActionsRequiredAvailable(state, status) {
    state.areActionsRequiredAvailable = status
  },
  setPendingPipelineChanges(state, pendingPipelineChanges) {
    state.pendingPipelineChanges = pendingPipelineChanges
  }
}

const actions = {
  handleReportResources({ state, commit }, reportResources) {
    // Remove existing reports from resourcesMap before adding new reports payload
    const allResources = Object.values(state.resourcesMap)
    const reportsResourceIds = allResources.filter((r) => r.reportId).map((r) => r.resourceId)
    reportsResourceIds.forEach((reportResourceId) => delete state.resourcesMap[reportResourceId])
    commit('setResources', reportResources)
  }
}

function getFilteredDependencies(resource, state) {
  resource.dependencies ||= {}
  let filteredDependencies = {}

  for (let direction of ['in', 'out'])
    filteredDependencies[direction] =
      resource.dependenciesUnfiltered?.[direction]
        .filter((d) => Object.values(RESOURCE_DEPENDENCIES).includes(d.operationName))
        .map((d) => ({
          ...d,
          linkId:
            state.resourcesMap[d.resourceId]?.viewId ||
            Object.values(state.resourcesMap).find(
              (r) => r.properties.dataset?.id === state.resourcesMap[d.resourceId]?.datasetId
            )?.viewId,
          resourceDatasetId:
            state.resourcesMap[d.resourceId]?.datasetId || state.resourcesMap[d.resourceId]?.properties.dataset.id
        })) || []

  return filteredDependencies
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
