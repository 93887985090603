// Dependencies
import { SplitFactory } from '@splitsoftware/splitio'
import Vue from 'vue'

//Store
import store from '@/plugins/store'

// Constants
import { SPLITIO_FEATURE_FLAGS } from '@/constants'

// Initialize all features properties, making it reactive
export let featuresVisibility = getSplitioFeatureFlags().reduce((obj, key) => ({ ...obj, [key]: false }), {})

// Enable all features on storybook and unit tests
if (window.STORYBOOK_ENV || process.env.JEST_WORKER_ID)
  Object.keys(featuresVisibility).forEach((k) => (featuresVisibility[k] = true))

let _splitState
let _splitClient

export async function initializeSplitIo(callback) {
  const server = window.location.hostname.split('.')[0]
  const workspaceId = localStorage.getItem('workspace_id')

  // Instantiate the SDK
  const attributes = { server, email: localStorage.getItem('email') }

  const chargebeePlan = store.state.smsDetails?.currentPlan?.id
  if (chargebeePlan) attributes.chargebee_plan = chargebeePlan

  if (!_splitClient) {
    const factory = SplitFactory({
      core: {
        authorizationKey: process.env.VUE_APP_SPLITIO_KEY,
        key: `${server}_${workspaceId}`
      }
    })
    _splitClient = factory.client()
  }

  // Initialize once, get all the flags
  if (_splitState === _splitClient.Event.SDK_READY) populateSplitFeatureFlags(_splitClient, attributes, callback)
  else {
    _splitClient.once(_splitClient.Event.SDK_READY, () => {
      _splitState = _splitClient.Event.SDK_READY
      populateSplitFeatureFlags(_splitClient, attributes, callback)
    })
  }
  //Look for changes made within split and apply updates the same client
  _splitClient.on(_splitClient.Event.SDK_UPDATE, () => {
    populateSplitFeatureFlags(_splitClient, attributes, callback)
  })
}

function populateSplitFeatureFlags(client, attributes, callback) {
  // Get treatments back for all splits defined
  const treatments = client.getTreatments(getSplitioFeatureFlags(), attributes)
  Object.keys(treatments).forEach((feature) => Vue.set(featuresVisibility, feature, treatments[feature] === 'on'))
  callback?.(client, attributes)
}

function getSplitioFeatureFlags() {
  let flagsList = []

  // Validate if it is a string. If it is add to flagsList array, else iterate through object properties
  const validate = (flag) => {
    if (typeof flag === 'string') flagsList.push(flag)
    else Object.values(flag).forEach((f) => validate(f))
  }

  validate(SPLITIO_FEATURE_FLAGS)
  return flagsList
}
