'use strict';

import { dsPreviewController } from '../landing/preview.controller';

var api_url = '/api/v1';

export let config = {
  appTitle: 'Mammoth Analytics',
  apiUrl: api_url,
  api: {  // api endpoints
    pendingChanges: api_url + '/pipeline_changes',
    login: api_url + '/login',
    token: api_url + '/token',
    set_sec: api_url + '/set_sec',
    twoFactorAuth: api_url + '/two-factor-auth',
    keycloak2FA: api_url + '/keycloak-2fa',
    totp: api_url + '/totp',
    admin: api_url + '/admin',
    resetPassword: api_url + '/reset-password',
    registrations: api_url + '/user-registrations',
    modifyPassword: api_url + '/modify-password',
    resources: api_url + '/resources',
    resourceDependencies: api_url + '/resource_dependencies',
    hashJSON: api_url + '/hash_json',
    workspaces: api_url + '/workspaces',
    workspaceApi: api_url + '/workspace/:workspace_id',
    smsDetails: api_url + '/workspaces/:workspace_id/sms-details',
    coupons: api_url + '/workspaces/:workspace_id/sms-details/coupons/:coupon_id',
    invoices: api_url + '/workspaces/:workspace_id/sms-details/invoices',
    uiPreferences: api_url + '/preferences',
    clientApps: api_url + '/clientapps',
    clientApp: api_url + '/clientapp',
    self: api_url + '/self',
    workspaceUsers: api_url + '/workspaces/:workspace_id/users/:user_id',
    appUsage: api_url + '/workspaces/:workspace_id/app-usage/:user_id',
    files: api_url + '/files',
    file: api_url + '/files/:id',
    weburls: api_url + '/weburls',
    datasource: api_url + '/datasets',
    datasources: api_url + '/datasets/:id',
    notifications: api_url + '/notifications/:id',
    dsRawData: api_url + '/datasets/:dataset_id/csvfile',
    dsUnstructuredData: api_url + '/datasets/:dataset_id/unstructured_data',
    datasourceBatches: api_url + '/datasets/:ds_id/batches/:batch_id',
    datasourceBatchesFormultiDelete: api_url + '/datasets/:ds_id/batches',
    datasourceApplyData: api_url + '/datasets/:ds_id/apply-data',
    datasourcesDataviews: api_url + '/datasets/:id/dataviews/:ws_id',
    dataviews: api_url + '/dataviews/:id',
    clipboard: api_url + '/clipboard',
    tasks: api_url + '/dataviews/:ws_id/tasks/:task_id',
    alltasks: api_url + '/dataviews/:ws_id/tasks',
    draftMode: api_url + '/dataviews/:ws_id/draft-mode',
    pipelineInfo: api_url + '/dataviews/:ws_id/pipeline-info',
    inboundUpdates: api_url + '/dataviews/:ws_id/inbound-updates',
    dataviewData: api_url + '/dataviews/:ws_id/data',
    dataviewReset: api_url + '/dataviews/:wsId/reset',
    dataviewRerun: api_url + '/dataviews/:wsId/rerun',
    dataviewModificationRequest: api_url + '/dataviews/:dataviewId/requests/:requestId',
    dataviewSafeDeleteRequest: api_url + '/dataviews/:dataviewId/safe-delete-request',
    dataviewPreviewMode: api_url + '/dataviews/:dataviewId/preview-mode',
    dataviewTaskStatuses: api_url + '/dataviews/:dataviewId/task-statuses',
    dataviewPreview: api_url + '/dataviews/previews',
    metrics: api_url + '/dataviews/:ws_id/metrics',
    dataviewsExportConfig: api_url + '/dataviews/:ws_id/exportable-config',
    //alerts
    dataviewsAlerts: api_url + '/dataviews/:ws_id/alerts',
    // user events
    userEvents: api_url + '/mm-ue',
    // time sync
    timeSync: api_url + '/timesync',
    // chargebee plan
    chargebeePlan: api_url + '/chargebee-plan',
    //singularity endpoints starts
    integrations: api_url + '/integrations/:integration_key',
    identities: api_url + '/integrations/:integration_key/identities/:identity_key',
    dsConfigs: api_url + '/integrations/:integration_key/identities/:identity_key/dsConfigs/:dsConfig_key',
    datasourcesRoot: api_url + '/datasets',
    editDatasource: api_url + '/schedule',
    derivatives: api_url + '/dataviews/:dataviewId/derivatives/:derivativeId',
    volatile_query: api_url + '/dataviews/:dataviewId/data/query',
    publish_reports: api_url + '/reports',
    publish_report: api_url + '/report/:reportId',
    asyncRequest: api_url + '/async/:request_id',
    futureRequests: api_url + '/futures',
    futureRequest: api_url + '/future/:request_id',
    //admin related
    // actions
    actions: api_url + '/dataviews/:ws_id/actions',
    publish: api_url + '/dataviews/:ws_id/publish',
    publishTrigger: api_url + '/dataviews/:ws_id/publish/:trigger_id',
    publishCredentials: api_url + '/publish_credentials',
    actionTriggers: api_url + '/dataviews/:ws_id/actions/:trigger_id',
    webhooks: api_url + '/webhooks/:webhookId',
    captchVerify: api_url + '/captcha-verify',
    suggestions: api_url + '/suggestions',
    // labels
    labels: api_url + '/labels/:labelRId',
    labelsResources: api_url + '/label/resources',
    labelResources: api_url + '/labels/:labelRId/resources/:resourceRId',
    // elements
    elements: api_url + '/dataviews/:dataviewId/elements/:elementId',
    elementQuery: api_url + '/dataviews/:dataviewId/elements/:elementId/query/:queryId',
    // alerts
    alert: api_url + '/dataviews/:dataviewId/alerts/:alertId',
    // date-convert
    dateConvert: api_url + '/date-convert'
  },
  // adding new URLS for routes handled via mm-frontend
  newUrls: {
    login: '#/login'
  },
  url: {  // SPA routes (not to be confused with API endpoints)
    oldadmin: '/oldadmin',
    newadmin: '/newadmin',

    // ---------------------------------------------------------
    // ----------------------- VUE -----------------------
    /**
     * @Code reference 4
     * -> Creating route for a Vue component
     * @Comment angular config for the route
     * @link: https://mammoth.atlassian.net/wiki/spaces/PD/pages/1363247150/Vue+Porting#Vue-component-with-routing
     */
    admin: '/admin',
    adminWorkspaces: '/admin/workspaces',
    adminDomains: '/admin/domains',
    adminIntegrations: '/admin/integrations',

    publish: '/publish/:reportId',
    // ----------------------- VUE END -----------------------
    // ---------------------------------------------------------

    register: '/register',
    landing: '/landing',
    workspaceView: '/workspaces/:workspaceId',
    reportView: '/shared-reports',
    projectView: '/projects/:projectId',
    projectLabelView: '/projects/:projectId/folders/:labelId',
    expired: '/expired',
    dataviewsAbstract: '/workspaces/:workspaceId/projects/:projectId/dataviews',
    dataview: '/:dataviewId',
    settings: '/settings?section',
  },
  headers: {
    TOKEN: 'X-API-KEY',
    WORKSPACE_ID: 'X-WORKSPACE-ID'
  },
  states: {
    abstract: {
      tabs: 'Tabs'
    },
    oldadmin: 'oldadmin',

    // ---------------------------------------------------------
    // ----------------------- VUE -----------------------

    /**
     * @Code reference 4
     * -> Creating route for a Vue component
     * @Comment angular config for the route
     * @link: https://mammoth.atlassian.net/wiki/spaces/PD/pages/1363247150/Vue+Porting#Vue-component-with-routing
     */
    admin: 'admin',
    newadmin: 'newadmin',
    adminDomains: 'adminDomains',
    adminIntegrations: 'adminIntegrations',
    adminWorkspaces: 'adminWorkspaces',

    publish: 'publish',

    // ----------------------- VUE END -----------------------
    // ---------------------------------------------------------

    register: 'Register',
    settings: 'Settings',
    landing: 'Landing',
    workspaceView: 'Workspace',
    reportView: 'Workspace.Report',
    projectView: 'Workspace.Project',
    projectLabelView: 'Workspace.ProjectLabelView',
    expired: 'Expired',
    dataviewsAbstract: 'Dataviews',
    dataview: 'Dataviews.Dataview',
    integrations: 'Landing.Integrations',
    integrationsIdentity: 'Landing.Integrations.Identity',
    integrationsIdentityDsConfig: 'Landing.Integrations.Identity.DsConfig',
    passwordReset: 'passwordReset'
  },
  controllers: {
    dataview: 'DataviewController',
    columnMenu: 'ColumnMenuController',
    dataviewLayout: 'DataviewLayoutController',
    landing: 'LandingController',
    expired: 'expiredController',
    tab: 'TabController',
    addData: 'addDataController',
    editDatapullConfig: 'EditDataPullConfigController',
    editMetrics: 'editMetricsController',
    registerForm: 'RegistrationController',
    topNav: 'TopNavController',
    combineDs: 'CombineDsController',
    dsModificationAlertCtrl: 'dsModificationAlertCtrl',

    // ---------------------- VUE ------------------------------
    admin: 'AdminController',
    publish: 'publishController',
    // ---------------------- VUE End ------------------------------
    elementModal: 'ElementModalController',
    manageUsers: 'manageUsersController',
    exportDialog: 'exportDialogController',
    dateInputFormat: 'dateInputFormatController',
    thirdPartyAPI: 'thirdPartyAPIController',
    integration: 'IntegrationController',
    identity: 'IdentityController',
    createLabel: 'LabelCreateController',
    alertsDialog: 'alertsDialogController',
    previewController: 'dsPreviewController',
    renameController: 'renameController'
  },
  templates: {
    '404': 'app/components/common/404.tpl.html',

    // --------------------------------- VUE area ---------------------------------
    admin: 'app/components/admin/admin.tpl.html',
    publish: 'app/components/publish/publish.tpl.html',
    // --------------------------------- VUE area End ---------------------------------

    oldadmin: 'oldadmin',
    inplaceRename: 'app/components/landing/inplaceRenaming.tpl.html',
    pivotChartMenu: 'app/components/element/chart/pivotChart.create.menu.tpl.html',
    funnelChartMenu: 'app/components/element/chart/funnelChart.create.menu.tpl.html',
    metricCreate: 'app/components/element/metric/metric.create.menu.tpl.html',
    filterCreate: 'app/components/element/filter/filter.create.menu.tpl.html',
    chartCreate: 'app/components/element/chart/chart.create.menu.tpl.html',
    chartDisplay: 'app/components/element/chart/chart.display.tpl.html',
    toolTipTemplates: 'app/components/help/templates.html',
    plotCreate: 'app/components/element/chart/plotChart.create.menu.tpl.html',
    tableCreate: 'app/components/element/table/table.create.menu.tpl.html',
    filterForm: 'app/components/common/filter/filter.tpl.html',
    filterAtomic: 'app/components/common/filter/filter.atomic.tpl.html',
    filterLogical: 'app/components/common/filter/filter.logical.tpl.html',
    convert: 'app/components/dataview/tasks/convert.tpl.html',
    destination: 'app/components/dataview/tasks/destination.tpl.html',
    multiDestination: 'app/components/dataview/tasks/multiDestination.tpl.html',
    extractDate: 'app/components/dataview/tasks/extractDate.tpl.html',
    formatText: 'app/components/dataview/tasks/formatText.tpl.html',
    incrementDate: 'app/components/dataview/tasks/incrementDate.tpl.html',
    dateDiffRule: 'app/components/dataview/tasks/date.diff.tpl.html',
    insert: 'app/components/dataview/tasks/insert.tpl.html',
    fillValues: 'app/components/dataview/tasks/fillValues.tpl.html',
    join: 'app/components/dataview/tasks/join.tpl.html',
    limit: 'app/components/dataview/tasks/limit.tpl.html',
    lookup: 'app/components/dataview/tasks/lookup.tpl.html',
    unnest: 'app/components/dataview/tasks/unnest.tpl.html',
    duplicateWs: 'app/components/common/templates/duplicateWs.error.tpl.html',
    math: 'app/components/dataview/tasks/math.tpl.html',
    pivot: 'app/components/dataview/tasks/pivot.tpl.html',
    removeDupliactes: 'app/components/dataview/tasks/removeDuplicates.tpl.html',
    replace: 'app/components/dataview/tasks/replace.tpl.html',
    rulebottom: 'app/components/dataview/tasks/rulebottom.tpl.html',
    runningTotal: 'app/components/dataview/tasks/runningTotal.tpl.html',
    windowFunction: 'app/components/dataview/tasks/windowFunction.tpl.html',
    smallOrLarge: 'app/components/dataview/tasks/smallOrLarge.tpl.html',
    sort: 'app/components/dataview/tasks/sort.tpl.html',
    singleSort: 'app/components/dataview/tasks/singleSort.tpl.html',
    copy: 'app/components/dataview/tasks/copy.tpl.html',
    bulkCopy: 'app/components/dataview/tasks/bulkCopy.tpl.html',
    deleteColumn: 'app/components/dataview/tasks/deleteColumn.tpl.html',
    combine: 'app/components/dataview/tasks/combine.tpl.html',
    select: 'app/components/dataview/tasks/select.tpl.html',
    addColumn: 'app/components/dataview/tasks/addColumn.tpl.html',
    columnSplit: 'app/components/dataview/tasks/column.split.tpl.html',
    collapseRows: 'app/components/dataview/tasks/collapseRows.tpl.html',
    scheduler: 'app/components/landing/datapull/scheduler.tpl.html',
    webhook: 'app/components/landing/datapull/webhook.tpl.html',
    stepRename: 'app/components/common/templates/stepRename.tpl.html',
    stepAddNote: 'app/components/common/templates/stepAddNote.tpl.html',
    URL: 'app/components/landing/datapull/url.tpl.html',
    tabLayout: 'app/components/tab/tabLayout.tpl.html',
    topNav: 'app/components/topNav/topNav.tpl.html',
    registrationForm: 'app/components/login/register.tpl.html',
    landing: 'app/components/landing/landing.tpl.html',
    expired: 'app/components/expired/expired.tpl.html',
    dsPreview: 'app/components/landing/preview.tpl.html',
    dataview: 'app/components/dataview/dataview.tpl.html',
    dataviewLayout: 'app/components/dataview/dataviewLayout.tpl.html',
    pipelineConfirmation: 'app/components/common/templates/pipeline.confirmation.tpl.html',
    joinNonUniqueValues: 'app/components/common/templates/joinNonUniqueValue.tpl.html',
    pendingUpdatesConfirmation: 'app/components/common/templates/pipelineUpdates.confirmation.tpl.html',
    deleteConfirmation: 'app/components/common/templates/delete.confirmation.tpl.html',
    showCredential: 'app/components/dataview/actions/showPublishCredentials.tpl.html',
    regeneratePublishPwd: 'app/components/common/templates/regeneratePublishPwd.tpl.html',
    publishDB: 'app/components/dataview/actions/publishDB.tpl.html',
    publishReportPrompt: 'app/components/dataview/actions/publishReportPrompt.tpl.html',
    deleteUserConfirmation: 'app/components/common/templates/delete.user.tpl.html',
    extractText: 'app/components/dataview/tasks/extractText.tpl.html',
    mysql: 'app/components/dataview/actions/mysql.tpl.html',
    redshift: 'app/components/dataview/actions/redshift.tpl.html',
    bigquery: 'app/components/dataview/actions/bigquery.tpl.html',
    elasticsearch: 'app/components/dataview/actions/elasticsearch.tpl.html',
    email: 'app/components/dataview/actions/email.tpl.html',
    powerbi: 'app/components/dataview/actions/powerbi.tpl.html',
    saveasdataset: 'app/components/dataview/actions/saveasdataset.tpl.html',
    export_to_project: 'app/components/dataview/actions/exporttoproject.tpl.html',
    actionSequenceSelect: 'app/components/dataview/actions/action.sequence.select.tpl.html',
    postgres: 'app/components/dataview/actions/postgresql.tpl.html',
    mssql: 'app/components/dataview/actions/mssql.tpl.html',
    s3upload: 'app/components/dataview/actions/s3upload.tpl.html',
    actionBottom: 'app/components/dataview/actions/actionBottom.tpl.html',
    pipelineAction: 'app/components/dataview/actions/pipelineAction.tpl.html',
    dateFilterInput: 'app/components/common/filter/date.filter.input.tpl.html',
    currentDateTimeInput: 'app/components/common/date/currentDateTime.tpl.html',
    refreshCloudDataConfirmation: 'app/components/common/templates/refreshCloudData.confirmation.tpl.html',
    createLabel: 'app/components/common/templates/createLabel.modal.tpl.html',
    folderPicker: 'app/components/common/templates/folderPicker.tpl.html',
    resetWsConfirmation: 'app/components/common/templates/resetView.confirm.tpl.html',
    spinnerLoader: 'app/components/common/templates/spinner.tpl.html',
    rawData: 'app/components/raw/raw.data.tpl.html',
    unstructureData: 'app/components/unstructured/unstructure.data.tpl.html',
    addData: 'app/components/landing/addData.tpl.html',
    editDatapullConfig: 'app/components/landing/editDatapullConfig.tpl.html',
    editMetrics: 'app/components/landing/datapull/editMetrics.tpl.html',
    renameConfirmation: 'app/components/common/templates/rename.modal.tpl.html',
    cloneDsConfirmation: 'app/components/common/templates/cloneDs.tpl.html',
    smsCheckout: 'app/components/common/templates/checkout.sms.tpl.html',
    templateConfigModal: 'app/components/common/templates/templateConfig.tpl.html',
    templateConfirmationModal: 'app/components/common/templates/template.confirm.tpl.html',
    combineDs: 'app/components/landing/combineDs.tpl.html',
    modalWindow: 'app/components/common/templates/modal.windowTemplate.tpl.html',
    multiDeleteConfirmation: 'app/components/common/templates/multiDelete.confirmation.tpl.html',
    stepsPanel: 'app/components/dataview/viewUtils/steps.panel.tpl.html',
    columnExplorer: 'app/components/dataview/column.explorer.tpl.html',
    joinSourceGrids: 'app/components/dataview/tasks/join.sourceGrids.tpl.html',
    addRuleMenu: 'app/components/dataview/rule.menu.tpl.html',
    wkspGridColumnMenu: 'app/components/dataview/col.menu.tpl.html',
    wkspGridCellMenu: 'app/components/dataview/cell.menu.tpl.html',
    wkspColumnSummaryMenu: 'app/components/dataview/summary.menu.tpl.html',
    wkspModificationAlert: 'app/components/common/templates/wksp.modification.alert.tpl.html',
    wkspPipelineErrorAlert: 'app/components/common/templates/wksp.pipelineInError.alert.tpl.html',
    exportDialog: 'app/components/common/templates/exportDialog.tpl.html',
    displayMenuHandle: 'app/components/element/common/display.menu.tpl.html',
    switcherInBuilderMenu: 'app/components/element/common/element.switcher.builder.menu.tpl.html',
    elementSwitcher: 'app/components/element/common/element.switcher.menu.tpl.html',
    filterDisplay: 'app/components/element/filter/filter.display.tpl.html',
    metricElementContentTemplate: 'app/components/element/metric/metric.displayContent.tpl.html',
    metricDisplay: 'app/components/element/metric/metric.display.tpl.html',
    filterElementContentTemplate: 'app/components/element/filter/filter.displayContent.tpl.html',
    chartElementContentTemplate: 'app/components/element/chart/chart.displayContent.tpl.html',
    tableElementContentTemplate: 'app/components/element/table/table.displayContent.tpl.html',
    tableElementTemplate: 'app/components/element/table/table.display.tpl.html',
    filter: 'app/components/common/filter/filter.modal.tpl.html',
    filterSection: 'app/components/common/filter/filter.section.tpl.html',
    chartTypeMenu: 'app/components/element/chart/chartTypeMenu.tpl.html',
    deleteBatchConfirmation: 'app/components/landing/deleteBatchConfirmation.tpl.html',
    suspendBatchConfirmation: 'app/components/landing/suspendBatchConfirmation.tpl.html',
    appendBatchConfirmation: 'app/components/landing/appendBatchConfirmation.tpl.html',
    json: 'app/components/dataview/tasks/json-handle.tpl.html',
    backup: 'app/components/element/backup.html',
    builder: 'app/components/common/math/builder.tpl.html',
    appCreationModal: 'app/components/common/templates/app.creation.modal.tpl.html',
    appCreationEditModal: 'app/components/common/templates/app.creation.edit.modal.tpl.html',
    element: {
      modal: 'app/components/element/element.modal.tpl.html',
      display: 'app/components/dataview/elements.display.panel.tpl.html',
    },
    mathModal: 'app/components/common/math/math.modal.tpl.html',
    numericFormatter: 'app/components/common/formatters/numeric.formatter.tpl.html',
    dateFormatter: 'app/components/common/formatters/date.formatter.tpl.html',
    fileUpload: 'app/components/landing/file.tpl.html',
    sampleWarningModal: 'app/components/dataview/sample.alert.tpl.html',
    selectWorkbookSheets: 'selectWorkbookSheets.html',
    formly: {
      checkbox_list: 'app/components/common/templates/checkbox_list.tpl.html'
    },
    manageUsers: 'app/components/common/templates/manageUsers.tpl.html',
    explorePanel: 'app/components/explore/explore.panel.tpl.html',
    exploreFilterDescription: 'exploreFilterDescription.tpl.html',
    explorePanelButton: 'app/components/explore/explore.button.tpl.html',
    explorePanelButtonWithMenu: 'app/components/explore/explore.button.with.menu.tpl.html',
    exploreAddItemsMenu: 'app/components/explore/explore.menu.tpl.html',
    exploreDrillDown: 'app/components/explore/drilldown.tpl.html',
    dateInputFormat: 'app/components/common/date/dateInputFormatter.tpl.html',
    dateInputFormatDoc: 'assets/documents/date-format-doc.html',
    thirdPartyAPI: 'app/components/landing/datapull/apiv2.tpl.html',

    compatibilityWarnings: 'app/components/common/templates/compatibility.warnings.tpl.html',
    paymentErrorWarning: 'app/components/common/templates/payment.error.tpl.html',
    browserWarnings: 'app/components/common/templates/browser.warnings.tpl.html',
    templateFixer: 'app/components/common/templates/template.fix.tpl.html',

    onBoarding: {
      main: 'app/components/help/onboarding.tpl.html'
    },
    notification: 'app/components/common/templates/notification.tpl.html',
    elementEditor: 'app/components/elementEditor/elementEditor.component.tpl.html',
    exploreMenu: 'app/components/dataview/explore.menu.tpl.html',
    alertsDialog: 'app/components/dataview/alerts.dialog.tpl.html',
    crosstab: 'app/components/dataview/viewUtils/crosstab.tpl.html',
    targetDs: 'app/components/dataview/viewUtils/target.dataset.tpl.html',
    multiLevelDropdown: 'app/components/dataview/multi-level-dropdown-menu.tpl.html',
    requestQuoteMeeting: 'app/components/common/templates/setupQuoteMeeting.modal.tpl.html',
    confirmQueryUpdate: 'app/components/landing/datapull/components/confirmQueryUpdate.tpl.html',
    sftp: 'app/components/dataview/actions/sftp.tpl.html',
    dataFlowModal: 'app/components/landing/datapull/components/dataFlowModal.tpl.html'
  },
  events: {
    auth: {
      logout: 'Auth.logout',
      checkTokenEvent: 'Auth.checkTokenEvent',
      reselectWorkspace: 'UserWorkspace.reselectWorkspace'
    }
  },
  windowNames: {
    landing: '_mammoth_landing_',
    datasource: '_mammoth_ds_',
    report: '_mammoth_report_'
  },
  resourceTypeToNameMap: {
    datasource: "dataset",
    dataview: "view",
    file_object: "file",
    report: "report",
    task: "task",
    action: "action",
    METRIC: "Metric",
    CHART: "Chart",
    TABLE: "Table",
    FILTER: "Filter",
    drilldown: "Drilldown",
    identity: "Connection",
    label: "Folder"
  },
  odbcTypeToNameMap: {
    postgres: "PostgreSQL",
    mysql: "MySQL",
    mssql: "MSSQL",
  },
  PAGESIZE: 200,
  PAGEWIDTH: 50,
  POLLING_INTERVAL: 2000,
  MIN_TIME_FROM_NOW_OCCURRENCE: 3,
  SCHEDULER_INTERVAL: 1
};
