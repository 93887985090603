'use strict';

export let highchartsGrayTheme = {
  colors: ["#7ACBEE", "#FDD761", "#FF7857", "#674E9E", "#A3C86D", "#3CB7CC", "#E65818",
    "#B8E48D","#7798BF", "#539F33", "#FC8F2A"],
  chart: {
    backgroundColor: 'transparent',
    borderRadius: 2,
    plotBackgroundColor: null,
    plotShadow: false,
    plotBorderWidth: 0
  },
  title: {
    style: {
      color: '#000',
      font: '11px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
      letterSpacing:'.5',
      paddingBottom:'3px',
      lineHeight:'15'
    }
  },
  subtitle: {
    style: {
      color: '#999',
      font: '9px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif'
    }
  },
  xAxis: {
    gridLineWidth: 0,
    lineColor: '#ccc',
    tickColor: '#eee',
    labels: {
      style: {
        color: '#999',
        fontWeight: 'bold',
        fontSize:'9px'
      }
    },
    title: {
      style: {
        color: '#AAA',
        font: '9px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif'
      }
    }

  },
  yAxis: {
    alternateGridColor: null,
    minorTickInterval: null,
    gridLineColor: 'rgba(255, 255, 255, .1)',
    minorGridLineColor: 'rgba(255,255,255,0.07)',
    lineWidth: 0,
    tickWidth: 0,
    labels: {
      style: {
        color: '#999',
        fontWeight: 'bold',
        fontSize:'9px'
      }
    },
    title: {
      style: {
        color: '#AAA',
        font: '9px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif'
      }
    }
  },
  legend: {
    itemStyle: {
      fontSize:'10px',
      color: '#aaa'
    },
    itemHoverStyle: {
      color: '#666'
    },
    itemHiddenStyle: {
      color: '#333'
    }
  },
  labels: {
    style: {
      color: '#CCC'
    }
  },
  tooltip: {
    shared: true,
    useHTML: true,
    headerFormat: '<span style="font-size:11px;padding-left:3px;">{point.key}</span><table>',
    pointFormat: '<tr><td style="color: {series.color};">{series.name}: </td>' +
    '<td style=""><b>{point.y}</b></td></tr>',
    footerFormat: '</table>',
    shadow: false,
    backgroundColor: {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: [
        [1, 'rgba(250, 250, 251, .9)']
      ]
    },
    borderWidth: 1,
    borderColor:'#ccc',
    borderRadius: 3,
    style: {
      color: '#666',
      fontSize:'11px',
      textShadow:'1px 1px 1px #fff'
    }
  },


  plotOptions: {
    series: {
      nullColor: '#444444'
    },
    line: {
      dataLabels: {
        color: '#CCC'
      },
      marker: {
        lineColor: '#333',
        radius:'0',
        states: { hover: { radius: 1 }}

      }
    },
    spline: {
      marker: {
        lineColor: '#333'
      }
    },
    scatter: {
      marker: {
        lineColor: '#333'
      }
    },
    candlestick: {
      lineColor: 'white'
    },
    pie: {
      dataLabels: {
        distance:5,
        connectorColor: '#eee',
        style: { fontSize:'8px', color:'#888',textShadow:'none', letterSpacing:'.5' }
      },
      showInLegend: false,
      innerSize: 50
    }
  },
  toolbar: {
    itemStyle: {
      color: '#CCC'
    }
  },

  navigation: {
    buttonOptions: {
      symbolStroke: '#DDDDDD',
      hoverSymbolStroke: '#FFFFFF',
      theme: {
        fill: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0.4, '#606060'],
            [0.6, '#333333']
          ]
        },
        stroke: '#000000'
      }
    }
  },

  // scroll charts
  rangeSelector: {
    buttonTheme: {
      fill: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0.4, '#888'],
          [0.6, '#555']
        ]
      },
      stroke: '#000000',
      style: {
        color: '#CCC',
        fontWeight: 'bold'
      },
      states: {
        hover: {
          fill: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0.4, '#BBB'],
              [0.6, '#888']
            ]
          },
          stroke: '#000000',
          style: {
            color: 'white'
          }
        },
        select: {
          fill: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0.1, '#000'],
              [0.3, '#333']
            ]
          },
          stroke: '#000000',
          style: {
            color: 'yellow'
          }
        }
      }
    },
    inputStyle: {
      backgroundColor: '#333',
      color: 'silver'
    },
    labelStyle: {
      color: 'silver'
    }
  },

  navigator: {
    handles: {
      backgroundColor: '#666',
      borderColor: '#AAA'
    },
    outlineColor: '#CCC',
    maskFill: 'rgba(16, 16, 16, 0.5)',
    series: {
      color: '#7798BF',
      lineColor: '#A6C7ED'
    }
  },

  scrollbar: {
    barBackgroundColor: {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: [
        [0.4, '#888'],
        [0.6, '#555']
      ]
    },
    barBorderColor: '#CCC',
    buttonArrowColor: '#CCC',
    buttonBackgroundColor: {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: [
        [0.4, '#888'],
        [0.6, '#555']
      ]
    },
    buttonBorderColor: '#CCC',
    rifleColor: '#FFF',
    trackBackgroundColor: {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: [
        [0, '#000'],
        [1, '#333']
      ]
    },
    trackBorderColor: '#666'
  },
};
