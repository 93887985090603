import * as angular from 'angular';
import * as _ from 'lodash-es';
/**
 * @ngInject
 */
columnMenuCtrl.$inject = ['utils', 'dataviewConfig', 'toastNotification', 'resources', 'derivatives', 'oldElementServiceFactory',
                        '$timeout', 'TaskServiceFactory', '$injector', 'elementConfig', 'elementModalService',
                        '$log', 'analyticsService', 'DataviewGlobalFilterService', 'c', 'eventCallbackManagerFactory'];
export function columnMenuCtrl(utils, dataviewConfig, toastNotification, resources, derivatives, oldElementServiceFactory,
                        $timeout, TaskServiceFactory, $injector, elementConfig, elementModalService,
                        $log, analyticsService, DataviewGlobalFilterService, c, eventCallbackManagerFactory) {
  this.$onInit = function () {
    var cm = this;
    var gridUnit = cm.gridUnit, // initialized via directive
      taskPanel = cm.taskPanel, // initialized via directive
      dataview = cm.dataview, // initialized via directive
      elementPanel = cm.elementPanel, // initialized via directive
      menuType = cm.menuType; // initialized via directive
    cm.hasColFormatInfoChanged = false;


    var paramGeneratorMap = _initParamGenerator();
    var globalFilter = DataviewGlobalFilterService.getByDataviewId(cm.dataview.id);

    cm.eventTrack = {
      columnChanged: function (params) {
        params['eventSource'] = 'columnMenu';
        analyticsService.userEventTrack(c.userEvents.elements.editor.columnChanged, params)
      },
      changeChartType: function (params) {
        params['eventSource'] = 'columnMenu';
        analyticsService.userEventTrack(c.userEvents.elements.editor.changeChartType, params)
      },
      elementTypeSelected: function (params) {
        params['eventSource'] = 'columnMenu';
        analyticsService.userEventTrack(c.userEvents.elements.editor.elementTypeSelected, params)
      },
    };


    if (menuType == "column") {
      gridUnit.columnMenu.onColumnSelected('loadColumnMenuData', function () {
        $timeout(loadColumnMenuData);
      });
    } else if (menuType == "cell") {
      gridUnit.cellMenu.onCellSelected('loadCellData', loadCellData);
    }

    if (gridUnit) {
      gridUnit.columnMenu.onHideColumn('hideColumnMenuResetController', onHideColumnMenu);
    }
    cm.open = openTask;
    cm.submit = submitTaskInBackground;
    cm.hideMenu = hideMenu;
    cm.setSort = setSort;
    cm.colFormatInfo = undefined;
    cm.setFormatInfo = setFormatInfo;
    cm.renameColumn = renameColumn;
    cm.sortProcessing = false;
    cm.defaultSortDirection = "undefined";
    cm.sortDirection = _.cloneDeep(cm.defaultSortDirection);
    cm.createMetric = createMetric;
    cm.handleMetricInputChange = handleMetricInputChange;
    cm.limitSelect = limitSelect;
    cm.setColFormatInfoDirty = setColFormatInfoDirty;

    function setColFormatInfoDirty() {
      cm.hasColFormatInfoChanged = true;
    }

    function limitSelect(word, size) {
      if (word.length <= size) {
        return word;
      } else {
        return word.substr(0, size - 3) + '...';
      }
    }

    cm.chart = {
      element: undefined,
      manager: undefined,
      isMenuOpen: false,
      create: createChart,
      openCreateModal: openCreateChartModal
    };
    cm.selectedColTypeTasks = [];
    cm.hideColumn = hideColumn;
    cm.columnHideInProgress = false;
    cm.cellFilter = {
      type: "keep",
      op: {
        "TEXT": "IN_LIST",
        "NUMERIC": "EQ",
        "DATE": "EQ"
      }
    };
    cm.currentMenu = undefined;

    cm.metric = {
      element: undefined,
      manager: undefined,
      aggregation: undefined,
      isMenuOpen: false,
      create: createMetric,
      openCreateModal: openCreateMetricModal,
      toggle: toggleMetricMenu,
      title: 'metric',
      dirtyTitle: false,
      recomputeTitle: function () {
        if (cm.metric.dirtyTitle) {
          return;
        }
        cm.metric.title = cm.metric.aggregation[0] + cm.metric.aggregation.slice(1).toLowerCase() + " of " + cm.currentMenu.column.display_name;
      },
      reset: function () {
        cm.metric.title = 'metric';
        cm.metric.dirtyTitle = false;
        cm.metric.aggregation = "SUM";
        cm.metric.recomputeTitle();
      }
    };
    cm.metricAggregationOptions = [
      c.aggregations.sum, c.aggregations.avg, c.aggregations.stddev,
      c.aggregations.min, c.aggregations.max
    ];

    function toggleMetricMenu(isOpen) {
      var setTo = isOpen === undefined ? !cm.metric.isMenuOpen : isOpen;
      $timeout(function () {
        cm.metric.isMenuOpen = setTo;
      });
    }

    cm.newLeftColumn = {
      value: '',
      isMenuOpen: false
    };

    cm.newRightColumn = {
      value: '',
      isMenuOpen: false
    };

    cm.insert = {
      value: '',
      create: createCellMetric,
      isMenuOpen: false,
      hide: hideInsertMenu
    };

    function hideInsertMenu() {
      $timeout(function () {
        cm.insert.isMenuOpen = false;
      });
    }

    cm.cellMetric = {
      name: '',
      create: createCellMetric,
      isMenuOpen: false,
      hide: hideCellMetricMenu
    };

    cm.cellReplace = {
      value: '',
      isMenuOpen: false,
      hide: hideCellReplace
    };

    function hideCellReplace() {
      $timeout(function () {
        cm.cellReplace.isMenuOpen = false;
      });
    }

    cm.elementsMenu = {
      isMenuOpen: false,
      toggle: toggleElementsMenu
    };

    cm.formatMenu = {
      isMenuOpen: false,
      toggle: toggleFormatMenu,
      allowChangeCase: false,
      param_case: "UPPER",
      validate_params: validateFormatTextParams,
      reset: resetFormatMenu
    };

    function resetFormatMenu() {
      cm.formatMenu.allowChangeCase = false;
      cm.formatMenu.param_case = "UPPER";
      cm.formatMenu.param_trim = false;
    }

    function validateFormatTextParams(val) {
      return (val.allowChangeCase && ['UPPER', 'LOWER', 'TITLE'].indexOf(val.param_case) !== -1) || (val.hasOwnProperty('param_trim') && val.param_trim === true);
    }

    cm.selectedElement = undefined;

    function toggleFormatMenu(isOpen) {
      var setTo = isOpen === undefined ? !cm.formatMenu.isMenuOpen : isOpen;
      $timeout(function () {
        cm.formatMenu.isMenuOpen = setTo;
      });
    }

    function toggleElementsMenu(isOpen) {
      var setTo = isOpen === undefined ? !cm.elementsMenu.isMenuOpen : isOpen;
      $timeout(function () {
        cm.elementsMenu.isMenuOpen = setTo;
      });
    }

    function hideCellMetricMenu() {
      $timeout(function () {
        cm.cellMetric.isMenuOpen = false;
      });
    }

    cm.numericColumns = [];
    // plot options
    cm.plot = {
      type: 'xy',
      xAxis: undefined,
      yAxis: undefined,
      yFunc: 'SUM',
      isMenuOpen: false
    };

    var OP_INVERSE_MAP = {
      CONTAINS: "NOT_CONTAINS",
      EQ: "NE",
      GTE: "LT",
      LTE: "GT",
      GT: "LTE",
      LT: "GTE",
      IS_EMPTY: "IS_NOT_EMPTY"
    };


    function loadCellData() {
      cm.cellFilter = {
        type: "keep",
        op: {
          "TEXT": "IN_LIST",
          "NUMERIC": "EQ",
          "DATE": "EQ"
        }
      };
      cm.cellMetric.name = '';
      cm.cellMetric.isMenuOpen = false;
      cm.elementsMenu.isMenuOpen = false;
      cm.currentMenu = gridUnit.cellMenu;
      cm.cellReplace.value = "";
      cm.cellReplace.isMenuOpen = false;
    }

    /*
      cm.haveFormatOptionsChanged = haveFormatOptionsChanged;
      function haveFormatOptionsChanged() {
        return angular.equals(cm.formatInfo, cm.previousFormatInfo);
      }
    */

    function loadColumnMenuData() {
      cm.hasColFormatInfoChanged = false;
      cm.sortDirection = undefined;
      cm.sortProcessing = false;
      cm.plot.type = 'xy';
      cm.selectedElement = undefined;
      cm.metric.aggregation = 'SUM';
      cm.metric.title =
        cm.plot.xAxis = undefined;
      cm.plot.yAxis = undefined;
      cm.plot.yFunc = 'SUM';
      cm.formatMenu.toggle(false);
      cm.formatMenu.reset();
      cm.elementsMenu.toggle(false);
      cm.columnHideInProgress = false;
      cm.currentMenu = gridUnit.columnMenu;
      cm.insert.value = null;
      cm.insert.isMenuOpen = false;
      var columnId = gridUnit.columnMenu.columnId;
      var _column = utils.metadata.get_column_by_internal_name(gridUnit.dataview.metadata, columnId);

      if (!_column) {
        gridUnit.columnMenu.hide();
        return;
      }
      gridUnit.columnMenu.column = _column;
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.columnMenuEvents.selectColumnMenu, {
        menuType: "Column Menu",
        eventOrigin: "dataview"
      });
      var colType = gridUnit.columnMenu.column.type;
      $timeout(function () {
        cm.selectedColTypeTasks = dataviewConfig.columnMenu.columnTypeTasks[colType];
      });

      if (_column.type == 'TEXT') {
        if (dataview.display_properties.FORMAT_INFO && dataview.display_properties.FORMAT_INFO[columnId]) {
          cm.colFormatInfo = dataview.display_properties.FORMAT_INFO[columnId];
        } else {
          cm.colFormatInfo = undefined;
        }
      }

      if (colType === "DATE") {
        if (dataview.display_properties.FORMAT_INFO && dataview.display_properties.FORMAT_INFO[columnId]) {
          let fInfo = dataview.display_properties.FORMAT_INFO[columnId];
          if (typeof fInfo == 'string') {
            //backward compatibility code
            fInfo = {
              'date_format': fInfo,
              'timezone': 'UTC'
            }
          }
          cm.colFormatInfo = _.cloneDeep(fInfo);
        } else {
          cm.colFormatInfo = {};
        }
      }
      if (colType === "NUMERIC") {
        if (dataview.display_properties.FORMAT_INFO && dataview.display_properties.FORMAT_INFO[columnId]) {
          cm.colFormatInfo = _.cloneDeep(dataview.display_properties.FORMAT_INFO[columnId]);
        } else {
          cm.colFormatInfo = {};
        }
      }
      if (colType === "NUMERIC") {
        cm.plot.yAxis = gridUnit.columnMenu.column;
      } else {
        cm.plot.xAxis = gridUnit.columnMenu.column;
      }
      $timeout(function () {
        cm.numericColumns = [];
        angular.forEach(gridUnit.metadata, function (col) {
          if (col.type == "NUMERIC") {
            cm.numericColumns.push(col);
          }
        });
      });
      // plot options
      $timeout(prepareChart);
      if (colType == "NUMERIC") {
        $timeout(prepareMetric);
      }
    }

    function renameColumn() {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.columnMenuEvents.renameColumn, {eventOrigin: "Column Menu"});
      // Event is fired. Listener is present on dataview directives
      gridUnit.columnMenu._fireOnRenameClick(cm.currentMenu.columnId);
      // Closing the column menu below, since calling hidemenu method
      // fires another event that overrides the focus on input element in this method
      hideColMenuOnRename();
    }

    function hideColMenuOnRename() {
      cm.currentMenu.visible = false;
      cm.currentMenu.columnId = undefined;
      onHideColumnMenu();
    }

    function onHideColumnMenu() {
      $timeout(function () {
        cm.chart.isMenuOpen = false;
      }, 20);
      cm.formatMenu.toggle(false);
      cm.formatMenu.reset();
      cm.elementsMenu.toggle(false);
    }

    function setFormatInfo(format) {
      cm.currentMenu.hide();
      var new_format_info = {};
      new_format_info[cm.currentMenu.column.internal_name] = format;
      dataview.setDisplayProperties({'FORMAT_INFO': new_format_info});
      cm.hasColFormatInfoChanged = false;
    }

    function openTask(taskName) {
      var param;
      var col = cm.currentMenu.column;
      if (paramGeneratorMap.hasOwnProperty(taskName)) {
        param = paramGeneratorMap[taskName](col);
      }
      taskPanel.open(taskName, param);
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.taskPanelEvents.taskInvoked, {
        menuType: menuType,
        taskName: taskName,
        eventOrigin: "dataview"
      });
      $timeout(function () {
        if (taskName == "math" && taskPanel.manager) {
          taskPanel.manager.setFunctionsDefaultSelectedColumn(col.internal_name);
        }
      }, 300);
    }

    function hideColumn() {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.columnMenuEvents.hideColumn, {
        menuType: menuType,
        eventOrigin: "dataview"
      });
      $timeout(function () {
        cm.columnHideInProgress = true;
      });
      var hidden_columns = _.cloneDeep(gridUnit.dataview.display_properties.HIDDEN_COLUMNS) || [];
      hidden_columns.push(cm.currentMenu.columnId);
      gridUnit.dataview.setDisplayProperties({'HIDDEN_COLUMNS': hidden_columns}).then(cm.currentMenu.hide, cm.currentMenu.hide);
    }

    function submitTaskInBackground(taskName) {
      var param;
      var col = cm.currentMenu.column;
      if (paramGeneratorMap.hasOwnProperty(taskName)) {
        param = paramGeneratorMap[taskName](col);
      }
      var manager_factory = $injector.get(dataviewConfig.get_manager_factory_from_task_name(taskName));
      var manager = manager_factory.get_manager(
        {
          metadata: utils.metadata.add_type_to_display_name(gridUnit.dataview.metadata),
          context: {
            dataview: gridUnit.dataview,
            inEditMode: false,
            simpleMode: true // useful in search replace. Else ignored
          }
        }
      );
      manager.setParam(param);
      var TaskService = TaskServiceFactory.get_by_dataview_id(gridUnit.dataview.id);
      var finalParam = manager.getParam();
      finalParam['DATAVIEW_ID'] = parseInt(gridUnit.dataview.id);
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.taskPanelEvents.taskInvoked, {
        menuType: menuType,
        taskName: taskName,
        eventOrigin: "dataview"
      });

      TaskService.add_task(finalParam).then(submission_success, submission_failure);
      resources.update();

      function submission_success() {
        resources.update();
      }

      function submission_failure(data) {
        $log.error(arguments);
        var message = 'The task could not be submitted, please try again or contact support for more information';
        if (data.data && data.data.ERROR_MESSAGE) {
          message = data.data.ERROR_MESSAGE;
        }
        toastNotification.error(message);
      }
    }

    function hideMenu() {
      cm.currentMenu.hide();
    }

    function setSort() {
      cm.sortProcessing = true;
      var patch = {'SORT': [[cm.currentMenu.column.internal_name, cm.sortDirection]]}
      dataview.setDisplayProperties(patch).then(setSortSuccess, setSortFailure);

      function setSortSuccess() {
        cm.sortProcessing = false;
        resources.update();
        gridUnit.reloadGrid();
        cm.currentMenu.hide();
      }

      function setSortFailure() {
        cm.sortProcessing = false;
        toastNotification.error("Could not set Sort, please try again.");
      }
    }

    function handleMetricInputChange() {
      cm.metric.recomputeTitle();
      cm.metric.manager.setParam(getMetricParam());
      cm.metric.manager.expressionUpdateCb();
      cm.eventTrack.columnChanged({
        'index': 0,
        column: cm.currentMenu.column.internal_name,
        elementType: 'metric',
        elementId: undefined,
        agg: cm.metric.aggregation
      });
    }

    function getMetricParam() {
      return {
        "METRIC": {
          "EXPRESSION": [{
            "TYPE": "FUNCTION",
            "VALUE": {"FUNCTION": cm.metric.aggregation, "ARGUMENT": cm.currentMenu.column.internal_name}
          }], "AS": cm.metric.title
        }, "CONDITION": null,
        "IS_PERMANENT": true
      };
      // commented out because its unreachable code
      // let fInfo = cm.metric.manager.displayProperties.FORMAT_INFO;
      // if(fInfo && fInfo.RESULT && fInfo.RESULT.is_percentage){
      //   cm.metric.manager.displayProperties.USE_SCIENTIFIC_FORMAT = c.useScientificFormatByDefaultInMetrics;
      // }
    }

    function createMetric() {
      var mParam = getMetricParam();
      cm.metric.manager.setParam(mParam);
      var finalParam = cm.metric.manager.getParam();
      var displayProperties = cm.metric.manager.getDisplayProperties();
      displayProperties.info.title = mParam.METRIC.AS;
      _addDerivative(finalParam, displayProperties);
      $timeout(function () {
        cm.metric.reset();
      }, 1000);
    }

    function createCellMetric() {
      prepareMetric();

      var val = cm.currentMenu.selection.length ? cm.currentMenu.selection : cm.currentMenu.function;
      var param = {
        "IS_PERMANENT": true,
        "METRIC": {
          "EXPRESSION": [{
            "TYPE": "FUNCTION",
            "VALUE": {
              FUNCTION: val,
              ARGUMENT: cm.currentMenu.column.internal_name
            }
          }], "AS": cm.cellMetric.name
        }, "CONDITION": {}
      };
      cm.metric.manager.setParam(param);
      var finalParam = cm.metric.manager.getParam();
      var displayProperties = cm.metric.manager.getDisplayProperties();
      var columnDecimalSpec;
      if (cm.dataview.display_properties.FORMAT_INFO.hasOwnProperty(cm.currentMenu.column.internal_name) &&
        cm.dataview.display_properties.FORMAT_INFO[cm.currentMenu.column.internal_name] &&
        cm.dataview.display_properties.FORMAT_INFO[cm.currentMenu.column.internal_name].decimal_spec) {
        columnDecimalSpec = cm.dataview.display_properties.FORMAT_INFO[cm.currentMenu.column.internal_name].decimal_spec;
      } else {
        columnDecimalSpec = 2;
      }

      if (displayProperties.FORMAT_INFO && displayProperties.FORMAT_INFO.RESULT) {
        if ((val === 'AVG' || val === 'STDDEV') && (!columnDecimalSpec || columnDecimalSpec < 2)) {
          displayProperties.FORMAT_INFO.RESULT.decimal_spec = 2;
        } else {
          displayProperties.FORMAT_INFO.RESULT.decimal_spec = columnDecimalSpec;
        }
      }

      _addDerivative(finalParam, displayProperties);
      cm.cellMetric.isMenuOpen = false;
      cm.cellMetric.name = null;
      cm.currentMenu.hide();
    }

    function openCreateMetricModal() {
      let displayProps = cm.metric.manager.getDisplayProperties();
      let metricParam = getMetricParam();
      displayProps.info.title = metricParam.METRIC.AS;
      var element = {
        type: "METRIC",
        param: metricParam,
        display_properties: displayProps
      };
      $timeout(function () {
        elementModalService.open({
          element: _.cloneDeep(element),
          dataview: dataview,
          functionsDefaultSelectedColumn: cm.currentMenu.column.internal_name
        });
      });
    }

    function _getManagerInstanceAndElement(type) {
      var menuFactory = $injector.get(elementConfig[type].menuFactory);
      var element = {type: type};
      var manager = menuFactory.get({
        element: element,
        dataview: gridUnit.dataview,
        metadata: gridUnit.dataview.metadata
      });
      return {
        element: element,
        manager: manager
      };
    }

    function prepareMetric() {
      var managerAndElement = _getManagerInstanceAndElement("METRIC");
      cm.metric.element = managerAndElement.element;
      cm.metric.manager = managerAndElement.manager;
      cm.metric.aggregation = "SUM";
      cm.metric.recomputeTitle();
      cm.handleMetricInputChange();
    }

    function prepareChart() {
      var managerAndElement = _getManagerInstanceAndElement("CHART");
      cm.chart.element = managerAndElement.element;
      cm.chart.manager = managerAndElement.manager;
      cm.chart.manager.changeChartType('xy');
      cm.chart.manager.isPivot = true;
      // cm.chart.manager.handlePivotChanges();
      cm.chart.manager.provider.chart.display_properties.chartType = 'xy';

      var colType = gridUnit.columnMenu.column.type;
      if (colType === "NUMERIC") {
        cm.chart.manager.provider.chart.aggregations[0].column = gridUnit.columnMenu.column;
        cm.chart.manager.provider.chart.aggregations[0].onColumnChange();
        cm.chart.manager.provider.chart.aggregations[0].function = 'SUM';
        cm.chart.manager.provider.chart.aggregations[0].onFunctionChange();
      } else {
        cm.chart.manager.provider.chart.label.column = gridUnit.columnMenu.column;
        cm.chart.manager.provider.chart.label.onColumnChange();
      }
    }

    function createChart() {
      cm.chart.manager.provider.chart.setDefaultSort();
      if (gridUnit.columnMenu.column.type == 'DATE') {
        cm.chart.manager.provider.chart.label.checkTypeSetTruncateInfo();
      }
      // TODO: SENTRYERROR:FRONTEND-KF:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/480137038
      // TODO: SENTRYERROR:FRONTEND-KG:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/480137334
      var displayProperties = cm.chart.manager.getDisplayProperties();
      var param = cm.chart.manager.getParam();
      param.IS_PERMANENT = true;
      _addDerivative(param, displayProperties);
    }

    function openCreateChartModal() {
      var element: any = {type: "CHART", display_properties: {chartType: 'xy'}};
      try {
        var param = cm.chart.manager.getParam(true);
        if (param) {
          element.param = param;
        }
        var displayProperties = cm.chart.manager.getDisplayProperties();
        if (displayProperties) {
          element.display_properties = displayProperties;
        }
      } catch (e) {
        throw(e);
      }
      $timeout(function () {
        elementModalService.open({
          element: element,
          dataview: dataview
        });
      });
    }

    function _addDerivative(param, displayProperties) {
      analyticsService.userEventTrack(c.userEvents.exploreCards.metricAdded,
        {eventOrigin: 'dataview.columnMenu'});
      param.IS_PERMANENT = true;
      var elementService = oldElementServiceFactory.getByDataviewId(cm.dataview.id);
      var type = displayProperties.type[0].toUpperCase() + displayProperties.type.slice(1);
      derivatives.add(cm.dataview.id, param, displayProperties).then(addCallback, addFailure);

      function addCallback() {
        elementPanel.open();
        cm.currentMenu.hide();
        cm.chart.isMenuOpen = false;
        elementService.pollForUpdatesUntilReady().then(function () {
          toastNotification.success(type + " added");
        });
      }

      function addFailure() {
        toastNotification.error("The " + type + " could not be created, please try again");
      }
    }

    function _initParamGenerator() {
      var map = {};
      map[dataviewConfig.tasks.combine] = function (col) {
        return {"COMBINE": {"SOURCE": [{"COLUMN": col.internal_name}]}};
      };
      map[dataviewConfig.tasks.copy] = function (col) {
        return {
          'COPY': {
            'SOURCE': col.internal_name,
            AS: {
              'COLUMN': 'Copy of ' + col.display_name,
              'TYPE': col.type,
              'FORMAT': {},
              'INTERNAL_NAME': 'gcf_' + utils.string.random(10).toLowerCase()
            }
          }
        }
      };
      map[dataviewConfig.tasks.convert] = function (col) {
        return {"CONVERT": [{"SOURCE": col.internal_name}]};
      };
      map[dataviewConfig.tasks.split] = function (col) {
        return {"SPLIT": {"SOURCE": col.internal_name}};
      };
      map[dataviewConfig.tasks.insert] = function (col) {
        var val = null;
        if (cm.insert.isMenuOpen) {
          val = cm.insert.value;
        }
        return {
          "SET": {
            DESTINATION: col.internal_name,
            VALUES: [{VALUE: val, CONDITION: globalFilter.getCondition()}]
          }
        };
      };
      map[dataviewConfig.tasks.extract_text] = function (col) {
        var substr: any = {"SOURCE": col.internal_name};
        if (menuType != "column" && cm.currentMenu.selection.length) {
          substr.CHAR_POSITION = cm.currentMenu.selectionStart;
          substr.NUM_CHAR = cm.currentMenu.selectionEnd - cm.currentMenu.selectionStart + 1;
          substr.DIRECTION = "RIGHT";
        }
        return {"SUBSTRING": substr};
      };
      map[dataviewConfig.tasks.math] = function (col) {
        return {"MATH": {"EXPRESSION": [{"TYPE": "COLUMN", "VALUE": col.internal_name}]}};
      };
      map[dataviewConfig.tasks.window_function] = function (col) {
        return {
          'WINDOW': {
            EVALUATE: {
              FUNCTION: 'SUM',
              'ARGUMENTS': [col.internal_name],
            },
            AS: {
              'COLUMN': 'Sum of ' + col.display_name,
              'TYPE': 'NUMERIC',
              'FORMAT': {},
              'INTERNAL_NAME': 'gcf_' + utils.string.random(10).toLowerCase()
            },
            RANGE: "RUNNING"
          }
        }
      };
      map[dataviewConfig.tasks.increment_date] = function (col) {
        return {"INCREMENT_DATE": {"SOURCE": col.internal_name}};
      };
      map[dataviewConfig.tasks.extract_date] = function (col) {
        return {"EXTRACT_DATE": {"SOURCE": col.internal_name}};
      };
      map[dataviewConfig.tasks.addColumn] = function (col) {
        var colOrder = cm.dataview.display_properties.COLUMN_ORDER
        return {"ORDER": parseInt(utils.getKeyByValue(colOrder, col.internal_name))};
      };
      map[dataviewConfig.tasks.delete] = function (col) {
        return {"DELETE": [col.internal_name]};
      };
      map[dataviewConfig.tasks.bulkReplace] = (col) => {
        if (menuType == 'column') {
          return {
            REPLACE: {
              SOURCE: [col.internal_name],
              MAPPING: [{SEARCH_VALUE: [], REPLACE_VALUE: null}],
              MATCH_CASE: true,
              MATCH_WORDS: true
            },
            CONDITION: globalFilter.getContextualCondition(col.internal_name)
          };
        }
      }
      map[dataviewConfig.tasks.replace] = function (col) {
        if (col.type != "TEXT") {
          return;
        }

        if (menuType == 'column') {
          return {
            REPLACE: {
              SOURCE: [col.internal_name],
              MAPPING: [{SEARCH_VALUE: [], REPLACE_VALUE: null}],
              MATCH_CASE: true,
              MATCH_WORDS: true
            },
            CONDITION: globalFilter.getContextualCondition(col.internal_name)
          };
        } else {
          var r = cm.cellReplace.isMenuOpen && cm.cellReplace.value.length ? cm.cellReplace.value : null;
          if (cm.currentMenu.selection.length) {
            return {
              REPLACE: {
                SOURCE: [col.internal_name],
                VALUE_PAIR: [{SEARCH_VALUE: cm.currentMenu.selection, REPLACE_VALUE: r}],
                MATCH_CASE: true,
                MATCH_WORDS: false
              },
              CONDITION: globalFilter.getContextualCondition(col.internal_name)
            };
          } else {
            var val = cm.currentMenu.value;
            if (!_.isArray(val)) {
              val = [val];
            }
            return {
              REPLACE: {
                SOURCE: [col.internal_name],
                MAPPING: [{SEARCH_VALUE: val, REPLACE_VALUE: r}],
                MATCH_CASE: true,
                MATCH_WORDS: true
              },
              CONDITION: globalFilter.getContextualCondition(col.internal_name)
            };
          }
        }
      };

      function getConditionItems(col, op, val, isPartial, cellFilterType) {
        if (menuType != "column") {
          if (col.type == "TEXT") {
            if (isPartial) {
              op = "CONTAINS";
            } else {
            }
          } else if (col.type == "NUMERIC") {
            val = window['numeral'](val).value();
          } else if (col.type == "DATE") {
          } else {
            return;
          }
          if (val === null || val == undefined || (col.type == "NUMERIC" && isNaN(val)) || val === '') {
            val = true;
            op = "IS_EMPTY";
          }
        }
        op = cellFilterType == "keep" ? op : OP_INVERSE_MAP[op];
        return [op, val]
      }

      map[dataviewConfig.tasks.filter] = function (col) {
        var condition = {}, op, val;
        if (_.isArray(cm.currentMenu.value)) {
          if (col.type == 'TEXT' || (col.type == 'NUMERIC' && cm.currentMenu.value.length != 2)) {
            var _logical = [], _logicalOp = 'OR';
            angular.forEach(cm.currentMenu.value, function (v) {
              [op, val] = getConditionItems(col, cm.cellFilter.op[col.type], v, false, cm.cellFilter.type);
              var _col = {};
              _col[col.internal_name] = {};
              _col[col.internal_name][op] = val;
              _logical.push(_col)
            });
            if (op == 'NE') {
              _logicalOp = 'AND';
            }
            condition[_logicalOp] = _logical;
          } else if (col.type == 'NUMERIC' && cm.currentMenu.value.length == 2) {
            if (cm.cellFilter.type == "keep") {
              condition[col.internal_name] = {IN_RANGE: cm.currentMenu.value}
            } else {
              cm.currentMenu.value = _.sortBy(cm.currentMenu.value);
              var lt = {}, gt = {};
              lt[col.internal_name] = {'LT': cm.currentMenu.value[0]};
              gt[col.internal_name] = {'GT': cm.currentMenu.value[1]};
              condition["OR"] = [lt, gt]
            }
          }
        } else {
          if (cm.currentMenu.selection && cm.currentMenu.selection.length) {
            val = cm.currentMenu.selection;
          } else {
            val = cm.currentMenu.value;
          }
          [op, val] = getConditionItems(col, cm.cellFilter.op[col.type], val, cm.currentMenu.selection && cm.currentMenu.selection.length, cm.cellFilter.type);
          condition[col.internal_name] = {};
          condition[col.internal_name][op] = val;
        }
        return {CONDITION: condition, SELECT: "ALL"};
      };
      map[dataviewConfig.tasks.format_text] = function (col) {
        var param = {
          TEXT_TRANSFORM: {
            SOURCE: [col.internal_name]
          }
        };
        if (cm.formatMenu.allowChangeCase) {
          param.TEXT_TRANSFORM["CASE"] = cm.formatMenu.param_case;
        }
        if (cm.formatMenu.hasOwnProperty("param_trim")) {
          param.TEXT_TRANSFORM["TRIM"] = cm.formatMenu.param_trim;
        }

        return param;
      };
      return map;
    }
  }
}
