import * as angular from 'angular';
import * as dateformat from 'dateformat';
import * as $ from "jquery";
import * as _ from 'lodash-es';
import {functionsIn} from "lodash-es";
const HINTS_LIMIT = 50;
let conditionOperators = [
  {
    value: 'EQ',
    argTypes: ['NUMERIC', 'DATE'],
    description: 'is',
    operandType: 'input'
  },
  {
    value: 'IN_LIST',
    argTypes: ['TEXT','NUMERIC'],
    description: 'is one of',
    operandType: 'multiple'
  },
  {
    value: 'NE',
    argTypes: ['NUMERIC', 'DATE'],
    description: 'is NOT',
    operandType: 'input'
  },
  {
    value: 'NOT_IN_LIST',
    argTypes: ['TEXT', 'NUMERIC'],
    description: 'is NOT one of',
    operandType: 'multiple'
  },
  {
    value: 'CONTAINS',
    argTypes: ['TEXT'],
    description: 'contains',
    operandType: 'input'
  },
  {
    value: 'NOT_CONTAINS',
    argTypes: ['TEXT'],
    description: 'does NOT contain',
    operandType: 'input'
  },
  {
    value: 'STARTS_WITH',
    argTypes: ['TEXT'],
    description: 'starts with',
    operandType: 'input'
  },
  {
    value: 'ENDS_WITH',
    argTypes: ['TEXT'],
    description: 'ends with',
    operandType: 'input'
  },
  {
    value: 'NOT_STARTS_WITH',
    argTypes: ['TEXT'],
    description: 'does NOT start with',
    operandType: 'input'
  },
  {
    value: 'NOT_ENDS_WITH',
    argTypes: ['TEXT'],
    description: 'does NOT end with',
    operandType: 'input'
  },
  {
    value: 'LT',
    argTypes: ['NUMERIC'],
    description: 'is less than',
    operandType: 'input'
  },
  {
    value: 'LT',
    argTypes: ['DATE'],
    description: 'is earlier than',
    operandType: 'input'
  },
  {
    value: 'LTE',
    argTypes: ['NUMERIC'],
    description: 'is less than or equal to',
    operandType: 'input'
  },
  {
    value: 'LTE',
    argTypes: ['DATE'],
    description: 'is on or earlier than',
    operandType: 'input'
  },
  {
    value: 'GT',
    argTypes: ['NUMERIC'],
    description: 'is greater than',
    operandType: 'input'
  },
  {
    value: 'GT',
    argTypes: ['DATE'],
    description: 'is later than',
    operandType: 'input'
  },
  {
    value: 'GTE',
    argTypes: ['NUMERIC'],
    description: 'is greater than or equal to',
    operandType: 'input'
  },
  {
    value: 'GTE',
    argTypes: ['DATE'],
    description: 'is on or later than',
    operandType: 'input'
  },
  {
    value: 'IS_MAXVAL',
    argTypes: ['NUMERIC'],
    description: 'is the maximum value',
    operandType: 'none'
  },
  {
    value: 'IS_NOT_MAXVAL',
    argTypes: ['NUMERIC'],
    description: 'is NOT the maximum value',
    operandType: 'none'
  },
  {
    value: 'IS_MINVAL',
    argTypes: ['NUMERIC'],
    description: 'is the minimum value',
    operandType: 'none'
  },
  {
    value: 'IS_NOT_MINVAL',
    argTypes: ['NUMERIC'],
    description: 'is NOT the minimum value',
    operandType: 'none'
  },
  {
    value: 'IS_EMPTY',
    argTypes: ['TEXT', 'NUMERIC', 'DATE'],
    description: 'is Empty',
    operandType: 'none'
  },
  {
    value: 'IS_NOT_EMPTY',
    argTypes: ['TEXT', 'NUMERIC', 'DATE'],
    description: 'is NOT Empty',
    operandType: 'none'
  },
  {
    value: 'IN_RANGE',
    argTypes: ['NUMERIC', 'DATE'],
    description: 'in between',
    operandType: ['input', 'input']
  }
];

var globalMetadata = null;
var global_internal_name_to_col_map = null;

// only these operators are valid when selecing CASE
let validOperatorsForCase = ['EQ', 'IN_LIST', 'NE', 'NOT_IN_LIST', 'CONTAINS', 'NOT_CONTAINS',
  'STARTS_WITH', 'ENDS_WITH', 'NOT_STARTS_WITH', 'NOT_ENDS_WITH'];

var validOpsForSuggestions = ['EQ', 'IN_LIST', 'NE', 'NOT_IN_LIST'];

var listOperators = ['IN_LIST', 'NOT_IN_LIST', 'CONTAINS', 'NOT_CONTAINS'];

var numericListOperators = ['IN_LIST', 'NOT_IN_LIST']

// a dictionary which represents properties of strings. e.g CASE, whitespaces etc
let stringProp = {
  case:{
    caseSensitive: 'CASE-SENSITIVE',
    caseInSensitive: 'CASE-INSENSITIVE'
  }
};

let unaryOperators = ['IS_NOT_EMPTY', 'IS_EMPTY', 'IS_NOT_MINVAL', 'IS_MINVAL', 'IS_NOT_MAXVAL', 'IS_MAXVAL'];

// a dict which represents filter type: SHOW and REMOVE
// SHOW - show mr values where some condition is met
// REMOVE - opposite of show
let filterTypes = {
  SHOW: "Keep",
  REMOVE: "Remove"
};

/**
 * @ngInject
 * @returns {{get_manager: get_manager}}
 */


filterManagerFactory.$inject = ['utils', '$timeout', 'FilterHintsFactory', 'moment', 'c', 'analyticsService', '$q', '$sce', 'taskDescriber'];

export function filterManagerFactory(utils, $timeout, FilterHintsFactory, moment, c, analyticsService, $q, $sce, taskDescriber) {

  var service = {
    get_manager: get_manager
  };
  return service;

  ////
  function get_manager(options) {
    return new filterManager(options);
  }

  /**
   *
   * @param metadata
   * @param teardown_options
   *
   * Teardown options is an object with two keys:
   *  allow_teardown (default: false): If the filter can be removed from the function panel.
   *    This will happen when there is a single condition and user removes it.
   *  teardown_callback: If the above option happens, what method to call to
   *    notify? This used by the rule manager that uses this class.
   *    By passing a callback, the manager can get notified and take actions.
   */
  function filterManager(options) {
    var self = this;
    var parent = this;
    self.filterTypes = filterTypes;
    // set default value of filterType
    self.filterType = Object.keys(filterTypes)[0];
    var metadata = utils.sanitizeMetadata(_.cloneDeep(options.metadata)),
      teardown_options = options.teardownOptions;
    globalMetadata = _.cloneDeep(options.metadata);
    var originalSequenceNumber = options.originalSequenceNumber;
    self.dataviewId = options.dataviewId;
    var filterHintsMap = {};
    self.metadata =metadata;
    self.internal_name_to_col_map = options.internal_name_to_col_map || utils.metadata.get_internal_name_to_col_map(metadata);
    global_internal_name_to_col_map = _.cloneDeep(self.internal_name_to_col_map);
    self.context = options.context
    self.param = {};
    self.condition = null;
    // default case is caseInSensitive
    self.case = stringProp.case.caseInSensitive;
    // count - tracks whether case sensitive checkbox should be shown or not, count > 0 means show the checkbox
    self.caseTracker = 0;
    // this variable is true whenever there are more than one conditions added
    self.secondConditionAdded = false;
    self.isActive = false;
    self.isValidCombination = isValidCombination;
    self.addToCaseTracker = addToCaseTracker;
    self.removeFromCaseTracker = removeFromCaseTracker;
    self.get_referenced = get_referenced;
    self.set_referenced = set_referenced;
    self.getParam = getParam;
    self.setParam = setParam;
    self.teardown = teardown;
    self.cleanup = cleanup;
    self.validate = validate;
    self.closeFilterModal = closeFilterModal;
    self.addFilterCondition = addFilterCondition;
    self.updatePointerString = updatePointerString;
    self.getFilterHints = getFilterHints;
    self.selectedColumn = null;
    self.typeSelection = 'Value';
    self.recursiveGetCountOfConditions = recursiveGetCountOfConditions;
    self.toggleCaseSensitive = toggleCaseSensitive;
    self.condition_tooltip = null;
    self.teardown_options = {
      allow_teardown: true,
      teardown_callback: function () {
      }
    };

    if (teardown_options) {
      angular.extend(self.teardown_options, teardown_options);
    }

    /**
     *
     * @param pointer/ pointerString: This is a string of the form .<NUM>.<NUM>...<NUM>. For example '.2.2.2.'
     * The number indicates the index of the element in the args array of logical
     * condition. If value is . for pointer, it is assumed that you are referring to the root.
     * Every logicalCondition has a pointerString associated with it
     * Let's consider the below condition
     * (A & B & (C & D & (E & F)))
     * A, B, C ... are all atomic conditions, while as (C & D & (E & F)) is an example of a logical Condition
     * The whole condition i.e. (A & B & (C & D & (E & F))) is at the root, so pointerString associated with it will be '.'
     * PointerString associated with (C & D & (E & F)) will be '.2' because within root condition, it is at an index of 2 ...
     * the condition A being at index 0 and B at index 1
     * Similarly pointerString associated with (E & F) will be .2.2 because (E & F) is at index 2 within (C & D & (E & F))
     * @private
     */

    function addFilterCondition(){
      analyticsService.userEventTrack(c.userEvents.elements.editor.addFilterCondition,
      {
        eventOrigin: "metricCreator.filterConditionModal"
      });
    }
    function closeFilterModal(value){
    analyticsService.userEventTrack(c.userEvents.elements.editor.closeFilterConditionBuilderModal,
      {
        eventOrigin: "metricCreator.filterConditionModal."+value
      });
    }

    function computeConditionTooltip(executionTimestamp?: string){
      var txt = taskDescriber.conditionDescriber.describeCondition(self.metadata, self.getParam(), false, executionTimestamp);
      self.condition_tooltip = $sce.trustAsHtml(txt);
    }

    function get_referenced(pointer) {
      if (pointer == '.') {
        return self.condition;
      }
      else {
        var pointers_array = pointer.split('.');
        var node = self.condition;
        var i = 0;

        while (pointers_array.length > i) {
          if (node.type == 'logical') {
            node = node.args[parseInt(pointers_array[i])];
            i++;
          }
          else {
            throw 'Can not traverse through an atomic condition';
          }
        }

        return node;
      }
    }

    function recursiveGetCountOfConditions(condition){
      /*
      given the condition object, it recursively checks the count of conditions.
       */
      let count = 0;
      if(!condition){
        condition = self.condition;
      }
      if(condition.type == 'atomic'){
        return 1;
      }
      else if(condition.type == 'logical'){
        for(let item of condition.args){
          if(item.type == 'atomic'){
            count += 1;
          }
          else{
            let c = recursiveGetCountOfConditions(item);
            count += c;
          }
        }
      }
      return count;
    }

    function getFilterHints(column) {
      if (!filterHintsMap.hasOwnProperty(column.internal_name)) {
        filterHintsMap[column.internal_name] = new FilterHintsFactory(options.dataviewId);
      }
      return filterHintsMap[column.internal_name];
    }

    self.get_parent_pointer = function(pointer) {
      if (pointer == '.') {
        return null;
      }

      var pointers_array = pointer.split('.');
      pointers_array.pop();
      if (pointers_array.length == 0) {
        return '.';
      }
      else {
        return pointers_array.join('.');
      }
    }

    function set_referenced(pointer, condition) {
      if (pointer == '.') {
        self.condition = condition;
      }
      else {
        var pointers_array = pointer.split('.');
        var node = self.condition;

        var i = 1;

        while (pointers_array.length - 2 > i) {
          if (node.type == 'logical') {
            node = node.args[parseInt(pointers_array[i])];
            i++;
          }
          else {
            throw 'Can not traverse through an atomic condition';
          }
        }

        node.args[parseInt(pointers_array[i])] = condition;
      }
    }

    self.add_root_condition = function () {
      self.condition = new AtomicCondition();
    };

    self.remove_root_condition = function () {
      analyticsService.userEventTrack(c.userEvents.dataviewEvents.removeFilterCondition,
        {
        eventorigin: "dataview.filterCondition"
      });
      self.condition = null;
      teardown();
    };

    /*initially when user creates a condition, it will be an atomic condition
    after that if user adds more conditions, the condition as a whole will no more remain atomic rather it will become a
    logical condition or we can say conditions will be wrapped inside a logical condition
    */
    self.convert_root_condition_to_logical = function (second_condition_type) {
      var second_condition;
      if (second_condition_type == 'logical') {
        second_condition = new LogicalCondition(undefined, '.', '1');
      }
      else {
        second_condition = new AtomicCondition();
      }

      set_referenced('.', new LogicalCondition([
        get_referenced('.'),
        second_condition
      ], '.'));
      self.secondConditionAdded = true;
    };

    self.convert_root_condition_to_atomic = function () {
      set_referenced('.', get_referenced('.').args[0]);
    };


    // initially when there is only one condition, it is an atomic condition, after that if user adds more conditions, the whole condition
    // is wrapped inside a logical condition
    self.convert_root_condition_to_top_level_condition = function () {
      let condition = get_referenced('.');
      set_referenced('.', new LogicalCondition([
        new LogicalCondition([condition, new AtomicCondition()], '.', '0')
      ], '.'));
      self.secondConditionAdded = true;
    };

    // converts an atomic condition to a logical condition: basically creates a logical condition and puts 2 atomic conditions inside that
    // 1: this atomic condition and 2: a new empty atommic condition
    self.convert_atomic_condition_to_logical_condition = function (pointerString, index) {
      if (pointerString == '.') {
        self.condition.args[index] = new LogicalCondition([self.condition.args[index], new AtomicCondition()], '.', index);
      }
      else {
        pointerString = pointerString.slice(1);
        var pointers_array = pointerString.split('.').map(Number);
        var node = self.condition;
        var i = 0;

        while (pointers_array.length > i) {
          if (node.type == 'logical') {
            node = node.args[pointers_array[i]];
            i++;
          }
          else {
            throw 'Can not traverse through an atomic condition';
          }
        }

        node.args[index] = new LogicalCondition([node.args[index], new AtomicCondition()], node.pointerString, index);
      }
    };

    // basically puts an atomic condition in the parent logical condition
    self.move_condition_to_parent_condition = function (pointerString, index) {
      pointerString = pointerString.slice(1);
      let pointers_array = pointerString.split('.').map(Number);
      let node = self.condition;
      if(pointers_array.length > 0){
        var i = 0;

        while (pointers_array.length > i) {
          if (node.type == 'logical') {
            node = node.args[pointers_array[i]];
            i++;
          }
          else {
            throw 'Can not traverse through an atomic condition';
          }
        }
      }
      let group = get_referenced(pointerString);
      let parentPointer = self.get_parent_pointer(pointerString);
      let parentGroup = get_referenced(parentPointer);
      let groupIdx = _.findKey(parentGroup.args, group);
      let removedCondition = node.args.pop();
      // parentGroup.args.push(removedCondition);
      parentGroup.args.splice(parseInt(groupIdx) + 1, 0, removedCondition);
      if(!node.args.length){
        let idx = _.findKey(parentGroup.args, node);
        parentGroup.args.splice(parseInt(idx), 1);
      }
      if(parentPointer == '.' && parentGroup.args.length == 1){
        self.condition = parentGroup.args[0];
        self.secondConditionAdded = false;
      }
    };

    function updatePointerString(){
      // this function updates pointerString of all logical Conditions - update is required whenever a conditions is added or removed
      if(self.condition.type == 'logical') {
        // self.condition is the root condition, so pointerString will be '.'
        self.condition.pointerString = '.';
        updatePointerStringOfLogicalCondition(self.condition);
      }
    }

    function updatePointerStringOfLogicalCondition(logicalCondition){
      // loops through args and updates pointerString of all logical conditions
      logicalCondition.args.forEach(function (condition, index) {
        if(condition.type == 'logical'){
          let parentPointerString = logicalCondition.pointerString;
          if(parentPointerString == '.'){
            condition.pointerString = parentPointerString + index;
          }
          else {
            condition.pointerString = parentPointerString + '.' + index;
          }
          updatePointerStringOfLogicalCondition(condition);
        }
      })
    }

    function teardown() {
      if (self.teardown_options.allow_teardown) {
        if (typeof self.teardown_options.teardown_callback == 'function') {
          self.teardown_options.teardown_callback();
        }
      }
    }

    function _cleanup_logical_nodes(parent_node, node_index) {
      var node = parent_node.args[node_index];
      if (node.type == 'logical') {
        if (node.args.length == 1) {
          parent_node.args[node_index] = node.args[0];
        }
        // if a logical condition becomes empty - meaning that it contains no atomic conditions then remove that logical condition altogether
        if (node.args.length == 0) {
          parent_node.args[node_index] = node.args[0];
          parent_node.args.splice(node_index, 1);
        }

        for (var index = 0; index < node.args.length; index++) {
          _cleanup_logical_nodes(node, index);
        }
      }

    }

    function cleanup(pointerString) {
      if (self.condition.type == 'logical') {

        for (var index = 0; index < self.condition.args.length; index++) {
          _cleanup_logical_nodes(self.condition, index);
        }

        if (self.condition.args.length == 1 && self.condition.args[0].type == 'atomic') {
          self.condition = self.condition.args[0];
          self.secondConditionAdded = false;
        }
      }
      updatePointerString();
    }

    function getParam() {
      let param = {};
      if (self.condition) {
        // this case to condition variable - metric watches on condition variable only, but filteer, annotate, math etc
        // watch on the entire filtermanager
        self.condition.case = self.case;
        param = {
          // the spec of condition now includes STRING_PROP also
          STRING_PROP: {
          }
        };
        // if caseTracker is non zero, that means case sensitive checkbox is ON and user has seleted valid column and operator for CASE
        if(self.caseTracker) {
          param['STRING_PROP']['CASE'] = self.case;
        }
        let filterTypeDict = {FILTER_TYPE: self.filterType};
         angular.merge(param, self.condition.getParam(), filterTypeDict);
      }
      return param
    }

    function setParam(param, executionTimestamp?: string) {
      if (param === null) {
        param = {};
      }
      self.filterType = param['FILTER_TYPE'];
      if(!self.filterType){
        self.filterType = Object.keys(filterTypes)[0];
      }
      // when modifying a condition, check whether STRING_PROP is present, if yes then set that param
      if(Object.keys(param).includes('STRING_PROP')) {
        self.case = param['STRING_PROP']['CASE'];
        // Remove STRING_PROP(as it is already set above) from param - to set condition params other than STRING_PROP (),
        param = Object.keys(param).filter(key => key != 'STRING_PROP').reduce((obj, key) => {
          obj[key] = param[key];
          return obj;
        }, {});
      }
      // if STRING_PROP Not found, set to mammoth default, caseSensitive
      else{
        self.case = stringProp.case.caseSensitive;
      }
      var keys = Object.keys(param);
      if (keys.length < 1) {
        self.condition = null;
        return;
      }
      if (['AND', 'OR'].indexOf(keys[0]) !== -1) {
        self.condition = new LogicalCondition([], '.');
      } else {
        self.condition = new AtomicCondition();
      }
      self.condition.setParam(param, self.metadata);
      self.caseTracker = 0;
      // when condition menu is opened/edited, retrack valid columns and operators for case
      if(self.condition.args) {
        self.addToCaseTracker(self.condition.args);
      }
      else{
        self.addToCaseTracker(self.condition);
      }
      self.secondConditionAdded = self.condition.type == 'logical';
      computeConditionTooltip(executionTimestamp);
    }


    // args contains the tree of atomic and logical conditions, traverse through args
    // add do an increment to caseTracker if valid combinaton of columns and operators for CASE is found
    function addToCaseTracker(args){
      if(!Array.isArray(args)){
        args = [args];
      }
      args.forEach(function(item){
        if(item.type == 'atomic'){
          if(self.isValidCombination(item.column, item.operator)){
            self.caseTracker += 1
          }
          item.preValue = self.isValidCombination(item.column, item.operator);
        }
        else if(item.type == 'logical'){
          self.addToCaseTracker(item.args);
        }
      });
    }

    // used when a condition is removed - traverses through args and  decrements caseTracker for every valid combination of column and operator for CASE
    function removeFromCaseTracker(args){
      if(!Array.isArray(args)){
        args = [args];
      }
      args.forEach(function(item){
        if(item.type == 'atomic'){
          if(item.preValue){
            parent.caseTracker -= 1
          }
        }
        else if(item.type == 'logical'){
          self.removeFromCaseTracker(item.args);
        }
      });
    }

    // checks if the combination of column and operator is valid for CASE or not
    function isValidCombination(column, op){
      return(column && column.type=='TEXT' && op && validOperatorsForCase.includes(op.value));
    }
  //First declare
  self.toggleCaseSensitive = toggleCaseSensitive;
  // Then define
      function toggleCaseSensitive(value){
        if (value == 'CASE-SENSITIVE'){
          self.case = 'CASE-SENSITIVE'
        }else{
          self.case = 'CASE-INSENSITIVE'
        }

        analyticsService.userEventTrack(c.userEvents.elements.editor.caseSensitive,
          {
            eventOrigin: "functionPanel.caseSensitive",
            value: value
          });
      }

    function validate() {
      var ret = !!self.condition && !!self.condition.validate && self.condition.validate();
      if (self.teardown_options.allow_teardown) {
        return (self.condition === null) || ret;
      }
      return ret;
    }

    /**
     * All conditions are of type logical condition
     *
     * @constructor
     */

    function LogicalCondition(conditions = undefined, parentPointerString = undefined, index = ''): void {
      /*
        Logical Condition: It is condition consisting of atomic conditions ANDed or ORed together.
        There can be logical conditions inside a logical condition if the logical condition is not the inner most logical condition.
        Logical Condition can also be termed as a nested condition

        conditions: this parameter is an array of atomic conditions that this logicalCondition will have inside itself

        parentPointerString: every logical condition has a pointerString associated with it to identify its position/ address
        within the whole condition. parentPointerString is the pointerString of the parent Condition of this condition

        index: index of this logical condition within its parent condition
      */
      if (!conditions) {
        conditions = [new AtomicCondition(), new AtomicCondition()];
      }

      var self = this;
      if(parentPointerString == '.'){
        self.pointerString = parentPointerString + index;
      }
      else {
        self.pointerString = parentPointerString + '.' + index;
      }
      self.type = 'logical';
      self.value = 'AND';
      self.args = conditions;

      self.toggle = toggle;
      self.add = add;
      self.remove = remove;
      self.validate = validate;
      self.getParam = getParam;
      self.setParam = setLogicalParam;
      self.updatePointerString = updatePointerString;

      function toggle() {
        if (self.value == 'AND') {
          self.value = 'OR';
        }
        else {
          self.value = 'AND';
        }
      }

      function add(type, parentPointerString = undefined, index = undefined) {
        if (!type) {
          type = 'atomic';
        }
        var condition;
        if (type == 'atomic') {
          condition = new AtomicCondition();
        }
        else {
          condition = new LogicalCondition(undefined, parentPointerString, index);
        }

        self.args.push(condition);
      }

      function remove(index) {
        // when an atomic or logical condition is removed, remove its count from caseTracker
        parent.removeFromCaseTracker(self.args[index]);
        var new_args = [];
        for (var i = 0; i < self.args.length; i++) {
          if (i != index) {
            new_args.push(self.args[i]);
          }
        }
        self.args = new_args;
      }

      function validate() {
        var is_valid = true;
        for (var i = 0; i < self.args.length; i++) {
          is_valid = is_valid && self.args[i].validate();
        }
        return is_valid;
      }

      function getParam() {
        var ret = {};
        var argParams = [];
        for (var i = 0; i < self.args.length; i++) {
          argParams.push(self.args[i].getParam());
        }
        ret[self.value] = argParams;
        return ret;
      }

      function setLogicalParam(param, metadata) {
        self.value = "";
        if (param.hasOwnProperty('AND')){
          self.value='AND';
        }
        else if (param.hasOwnProperty('OR')){
          self.value='OR';
        }
        angular.forEach(param[self.value], function (sub_condition_param, index) {
          var op, sub_condition;
           if (sub_condition_param.hasOwnProperty('AND')){
            op='AND';
           }
           else if (sub_condition_param.hasOwnProperty('OR')){
            op='OR';
           }
          if (['AND', 'OR'].indexOf(op) !== -1) {
            sub_condition = new LogicalCondition([], self.pointerString, String(index));
          } else {
            sub_condition = new AtomicCondition();
          }
          self.args.push(sub_condition);
          sub_condition.setParam(sub_condition_param, metadata);
        });
      }
    }


    function AtomicCondition(): void {

      var self = this;
      self.metadata = globalMetadata;
      self.internal_name_to_col_map = global_internal_name_to_col_map;
      self.type = 'atomic';
      self.column = null;
      self.operator = null;
      self.operand = null;
      self.allowed_operators = [];
      self.uniqueValuesList = [];
      self.error = null;
      self.reset = reset;
      self.soft_reset = soft_reset;
      self.deselectColumn = deselectColumn;
      self.select_column = select_column;
      self.select_operator = select_operator;
      self.validate = validate;
      self.getParam = getParam;
      self.setParam = setAtomicParam;
      self.getColumnUniqueValues = getColumnUniqueValues;
      self.computeColumnUniqueValuesList = computeColumnUniqueValuesList;
      self.uniqueColumnsLoading = false;
      self.onSelect = onSelect;
      self.trackCase = trackCase;
      self.preValue = undefined;
      self.unaryOperators = unaryOperators;
      self.operandTypes = [{"display_name" : "Value", "internal_name" : "Value" },
                            {"display_name" : "Column Value", "internal_name" : "Column" }]
      self.allowedColumns = [];
      self.setAllowedColumns = setAllowedColumns;

      function onSelect(item) {
        if (item == undefined) {
          self.operand.splice(self.operand.indexOf(undefined));
        }
      }
      function setAllowedColumns(col_to_exclude){
      self.allowedColumns = [];
      angular.forEach(self.metadata, function (col){
        if (col.internal_name != col_to_exclude.internal_name){
          /*
           * Allowed columns in the dropdown are those which are type compatible
           */
          if (col.type==self.column.type){
            self.allowedColumns.push(col);
          }
        }
      });
    }

      function select_column() {
        var column = self.column;
        self.allowed_operators = [];
        for (var i = 0; i < conditionOperators.length; i++) {
          var op = conditionOperators[i];
          if (op.argTypes.indexOf(column.type) != -1) {
            self.allowed_operators.push(op);
          }

        }
        if (self.allowed_operators.indexOf(self.operator) == -1) {
          self.operand = null;
        }
        if (self.column.internal_name == self.selectedColumn){
          self.selectedColumn = null;
        }

        // when column is selected, set operator to previously selected operator if comptaible otherwise set it to
        // default i.e. "is"
        if(!self.operator || self.allowed_operators.indexOf(self.operator) == -1){
          if(self.allowed_operators.indexOf(conditionOperators[0]) != -1) {
            self.operator = conditionOperators[0];
          }
          else{
            self.operator = conditionOperators[1];
          }
        }

        if (self.column.type == 'TEXT') {
          let filterHints = getFilterHints(self.column);
          filterHints.init(self.column.internal_name, originalSequenceNumber);
        }
        // if typeSelection is already selected, keep that otherwise set it to default i.e. value
        if(!self.typeSelection) {
          self.typeSelection = 'Value';
        }
      }

      function select_operator() {
        var op = self.operator;
        if (self.allowed_operators.indexOf(op) != -1) {
          if (self.operator) {
            var old_operand_type = self.operator.operandType;
            self.operator = op;
            if (['IN_LIST', 'NOT_IN_LIST', 'CONTAINS', 'NOT_CONTAINS'].includes(self.operator.value) && typeof(self.operand) == 'string') {
              self.operand = [self.operand];

            }
            if (!['IN_LIST', 'NOT_IN_LIST', 'IN_RANGE', 'CONTAINS', 'NOT_CONTAINS'].includes(self.operator.value) && typeof(self.operand) == 'object') {
              if (angular.isArray(self.operand)) {
                self.operand = self.operand[0];
              }
            }
            if ((op.value == 'EQ' || op.value == 'NE') && (_.get(self.operand, 'FUNCTION') == 'SYSTEM_TIME')) {
              self.operand = '';
            }
            // This is only mentioned inside the next condition, but operand needs resetting even when the condition is not satisfied.
            // value will blank out only when the value is not convertible to text
            if (self.column.type == 'TEXT' && (self.operand && typeof self.operand == "object" &&
              (!Array.isArray(self.operand) && self.operand.length && self.operand[0]!="" ))) {
              self.operand = '';
            }
            if (op.value == 'IN_RANGE') {
                self.typeSelection = [];
                self.selectedColumn = [];
                for (let i = 0 ; i < 2 ; i++) {
                  self.typeSelection[i] = 'Value';
                  self.selectedColumn[i] = null;
                }
              }
            // Including this condition to make sure the value in output bar below is not undefined.
            if (old_operand_type != op.operandType) {
              if (op.value == 'IN_RANGE') {
                self.operand = [null, null];
              }
              if (op.value == 'IN_LIST') {
                self.operand = [];
              }
              if (self.column.type == 'TEXT') {
                self.operand = '';
              }
              else {
                self.operand = null;
              }
            }
            // A text column supports only single operands, so setting typeSelection to Value(defaule),
            // if operand type is Array i.e. typeSelection is Array
            if (self.column.type == 'TEXT' && Array.isArray(self.typeSelection)) {
              self.typeSelection = 'Value';
            }
          }

        }
      }

      function reset() {
        self.column = null;
        self.operator = null;
        self.operand = null;
        self.allowed_operators = [];
        self.allowedColumns = [];
        self.selectedColumn = null;
        self.typeSelection = "Value";
      }

      function soft_reset(index = "") {
        if (index == "") {
          self.operand = null;
          self.selectedColumn = null;
        } else {
          var index = index[0];
          if (typeof self.operand[index] != 'undefined') {
            self.operand[index] = null;
          }
          if (typeof self.selectedColumn[index] != 'undefined') {
            self.selectedColumn[index] = null;
          }
        }
      }

      /*
       * Function to deslelect one column in filter rule (in between) drop-down menu
       * when the other drop-down menu has selected the same column
       * index here is received as either [0], [1] (i,e a single element array with 0/1), or undefined
       * We first parse the number out of the array which denote the current drop-down's index
       * 0 index corresponds to the first drop-down menu and 1 index to second drop-down menu
       * To obtain the other dropdown menu index from given index x we can use y = (1-x)
       * If dropDown[x] is same as dropDown[y] then we toggle the y drop-down to be cleared out
       */
      function deselectColumn(index) {
        if (index && index.length > 0) {
          let passedIndex = Number(index[0]);
          let requiredIndex = 1 - passedIndex;
          if(self.selectedColumn[passedIndex] == self.selectedColumn[requiredIndex]) {
            self.selectedColumn[requiredIndex] = null;
          }
        }
      }

      function getColumnUniqueValues(operator) {
        // suggestions in condition will not show up for all
        if (self.column.type == 'TEXT' && validOpsForSuggestions.includes(operator.value)) {
          $timeout(function () {
            self.uniqueColumnsLoading = true;
          });
          var val;
          if (angular.isString(self.operand)) {
            val = self.operand;
          }
          let filterHints = getFilterHints(self.column);
          return filterHints.getHints(val, HINTS_LIMIT).then(function (data) {
            $timeout(function () {
              self.uniqueColumnsLoading = false;
            });
            return data;
          }, function () {
            $timeout(function () {
              self.uniqueColumnsLoading = false;
            });
          });
        }
        else {
          $timeout(function () {
            self.uniqueColumnsLoading = false;
          });
          return [];
        }
      }

      function computeColumnUniqueValuesList(filterByVal = null) {
        self.uniquevaluesLoading = true;
        self.uniqueValuesList = [];
        var deferred = $q.defer();
        let filterHints = getFilterHints(self.column);
        filterHints.getHints(filterByVal, HINTS_LIMIT).then((data) => {
          self.uniqueValuesList = data.map(x => Object({id: $("<p/>").html(x.value).text(), name: $("<p/>").html(x.value).text()}));
          self.uniquevaluesLoading = false;
          deferred.resolve(self.uniqueValuesList);
        },  function failurecallback(){
          self.uniquevaluesLoading = false;
          deferred.reject});
        return deferred.promise;
      }

      function _is_date(value) {
        var timestamp = moment(value);
        self.error = 'Invalid date';
        return !isNaN(timestamp);
      }

      function validate() {
        /**Validate the following
         *  Column is selected
         *  Oerator is chosen
         *  Operator is compatible with chosen column's type
         *  Operand is compatible with chosen colum's type and operation
         * */
        self.error = null;
        if (!self.column) {
          self.error = 'select column';
          return;
        }
        else if(self.column.hasOwnProperty('error')){
          self.error = 'column not present';
          return;
        }
        else if (!self.operator) {
          self.error = 'select operator';
          return;
        }
        else if(self.operator && self.operator.argTypes.indexOf(self.column.type) == -1){
          self.error = 'select operator';
          self.setAllowedColumns(self.column)
          return;
        }
        function validateOperandType(operand) {
          if(self.typeSelection == 'Column') {
            //validate for column type
            var operandColumn = $.grep(self.metadata, function(col){
              return _.get(col,'internal_name') == self.selectedColumn;
            })[0];
            if (_.get(operandColumn, 'type') == self.column.type){
              return true;
            } else {
              self.setAllowedColumns(self.column)
              return false;
            }

          }
          if (self.column.type == 'DATE') {
            if (operand && operand.hasOwnProperty('TRUNCATE')) {
              operand = operand.VALUE;
            }
            if (operand && operand.hasOwnProperty('FUNCTION')) {
              return true;
            }
            return _is_date(operand);
          }
          else if (self.column.type == 'NUMERIC') {
            if(Array.isArray(operand)){
              for(const element of operand){
                if (isNaN(element)) {
                  self.error = 'invalid number';
                  return false;
                }
              }
            }
            else if (isNaN(operand)) {
              self.error = 'invalid number';
              return false;
            }
          }
          return true;
        }

        if (self.operator.operandType != 'none') {
          if (self.operator.value == 'IN_RANGE') {
            var is_valid = true;
            for(var i = 0 ; i < 2 ; i++) {
              if (self.typeSelection[i] == 'Column') {
                var operandColumn = $.grep(self.metadata, function (col) {
                  return _.get(col, 'internal_name') == self.selectedColumn[i];
                })[0];
                if (_.get(operandColumn, 'type') == self.column.type) {
                  continue;
                } else {
                  self.setAllowedColumns(self.column)
                  return false;
                }
              } else {
                // Operand is a value
                is_valid = is_valid && validateOperandType(self.operand[i])
              }
            }
            return is_valid;
          }
          else {
            return validateOperandType(self.operand);
          }
        }

        return self.error === null;
      }

      function _sanitizeDate(val) {
        if (val === undefined || val === null) {
          return null;
        }
        if (typeof val === 'string') {
          var isValidDate = moment(val).isValid();
          // if the value is a valid date, only then sanitize
          if(isValidDate) {
            return dateformat(moment(val), 'yyyy-mm-dd HH:MM:ss');
          }
          else{
            return null;
          }
        }
        return val;
      }

      function getParam() {
        var ret = {};
        var rhs = {};

        if (!self.column) {
          return ret;
        }
        ret[self.column.internal_name] = rhs;
        if (!self.operator) {
          return ret;
        }
        if (self.operator.operandType == 'none') {
          rhs[self.operator.value] = true;
        }
        else {
          if (self.operator.value == 'IN_RANGE') {
            if (self.column.type == 'DATE') {
              $.each(self.operand, function (i, o) {
                self.operand[i] = _sanitizeDate(o);
              });
            }
            rhs[self.operator.value] = [];
              for (var i = 0 ; i < 2; i++) {
                if (self.selectedColumn[i]) {
                  rhs[self.operator.value][i] = {'COLUMN': self.selectedColumn[i]};
                } else {
                  if (self.operand && Array.isArray(self.operand) && self.operand[i] != null) {
                    rhs[self.operator.value][i] = {'VALUE': self.operand[i]};
                  }
                }
              }
              return  ret;
          }
          else {
            if (self.column.type == 'DATE') {
              self.operand = _sanitizeDate(self.operand);
            }
          }

         /*
          RHS of operator (in case of binary operations) will be always be either
          key as COLUMN or VALUE and value as column's internal name or actual raw value.
          */
        if (self.selectedColumn) {
            rhs[self.operator.value] = {'COLUMN' : self.selectedColumn};
          } else {
            if(self.column.type == 'NUMERIC' && Array.isArray(self.operand)){
              rhs[self.operator.value] = {'VALUE': self.operand.map(val => parseFloat(val))}
            } else{
              rhs[self.operator.value] = {'VALUE': self.operand};
            }
          }
        }

        return ret;
      }

      function setAtomicParam(param, metadata) {
        // not using metadata passed as argument, as we already have metadata in self.metadata as well as global metadata
        metadata = self.metadata;
        var col_int_name = "";
        for (let col in metadata){
          if (param.hasOwnProperty(metadata[col].internal_name)){
            col_int_name=metadata[col].internal_name;
            break;
          }
        }
        self.column = self.internal_name_to_col_map[col_int_name];
        // self.column = utils.metadata.get_column_by_internal_name(metadata, col_int_name);
        if (!self.column){
          return
        }
        select_column();
        var operator_val = Object.keys(param[col_int_name])[0];
        self.operator = $.grep(conditionOperators, function (item) {
          if (item.value == operator_val){
            //check types
            if(Array.isArray(item.argTypes)){
              for (let i = 0; i < item.argTypes.length; i++){
                if (self.column.type == item.argTypes[i])
                  return true;
              }
            } else {
              return item.argTypes == self.column.type;
            }
          }
        })[0];
        //While setting params add operator to the list of allowed operators
        //this is usefull in maintaing the condition, this needs to be done for showing task panel when task in error
        if (self.allowed_operators.indexOf(self.operator) == -1){
          self.allowed_operators.push(self.operator)
        }
        if (self.operator && self.operator.operandType != 'none') {
          self.operand = param[col_int_name][operator_val];
          // convert a string operand into an array(only for list operators) - required for old templates when contains and not contains operators
          // didnt support array of operands
          //This is to support legacy spec
          if(!self.operand){
            return;
          }
          var isLegacy = !(self.operand.hasOwnProperty('COLUMN') || self.operand.hasOwnProperty('VALUE'));
          if (Array.isArray(self.operand) && self.operator.value == 'IN_RANGE'){
            isLegacy = !(self.operand[0].hasOwnProperty('COLUMN') || self.operand[0].hasOwnProperty('VALUE'));
          }
          if (isLegacy && !Array.isArray(self.operand)){
            self.operand = { 'VALUE': self.operand };
          }
          else if (isLegacy && Array.isArray(self.operand) && self.operator.value == 'IN_RANGE'){
            self.operand = [{'VALUE': self.operand[0]}, {'VALUE': self.operand[1]}];
          }
          if(param[col_int_name][operator_val].hasOwnProperty('COLUMN')){
            self.typeSelection = 'Column';
            self.setAllowedColumns(self.column);
            self.selectedColumn = param[col_int_name][operator_val]['COLUMN'];
          } else if (self.operand.hasOwnProperty('VALUE')) {
            self.typeSelection = 'Value';
            self.operand = self.operand.VALUE;
          }
          if (Array.isArray(self.operand) && self.operator.value == 'IN_RANGE'){
            self.typeSelection = [];
            self.selectedColumn = [];
            self.setAllowedColumns(self.column);
            for (var i = 0; i < 2; i++){
              if ('VALUE' in self.operand[i]){
                self.operand[i] = self.operand[i].VALUE;
                self.typeSelection[i] = 'Value';
              }
              else {
                self.selectedColumn[i] = self.operand[i].COLUMN;
                self.typeSelection[i] = 'Column';
              }
            }
          }
          // convert a string operand into an array(only for list operators) - required for old templates when contains and not contains operators
          // didnt support array of operands
          if(typeof self.operand === 'string' && listOperators.indexOf(self.operator.value) != -1){
            self.operand =  [self.operand];
          }
          if(Array.isArray(self.operand) && numericListOperators.indexOf(self.operator.value) != -1){
            self.operand =  self.operand.map(val => val.toString())
          }
        }
      }

      // called every time a column/operator is selected/ changed - increments/decrements caseTracker based on it the
      // combinatio of selected column and operator is valid for CASE or not
      function trackCase(column, op){
        if(!self.preValue && column && column.type=='TEXT' && parent.isValidCombination(column, op)){
          parent.caseTracker += 1;
        }
        else if(self.preValue && !parent.isValidCombination(column, op)){
          parent.caseTracker -= 1;
        }
        self.preValue = parent.isValidCombination(column, op);
      }

    }
  }
}

/**If operator is not compatible with chosen column's type, then invalidate*/
valOperator.$inject = [];
export function valOperator() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valOperator(scope, elem, attrs, ctrl) {
      ctrl.$validators.valOperator = function (modelValue, viewValue) {
        var column = scope.$eval(attrs.column)
        let isValid = true;
        if(modelValue && modelValue.argTypes.indexOf(column.type) == -1){
          isValid = false;
        }
        return isValid;
      };
    }
  };
}

/** If column has an 'error' property in its definition, then invalidate*/
valColumn.$inject = [];
export function valColumn() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valColumn(scope, elem, attrs, ctrl) {
      ctrl.$validators.valColumn = function (modelValue, viewValue) {
        let isValid = true;
        if(modelValue && modelValue.hasOwnProperty('error')){
          isValid = false;
        }
        return isValid;
      };
    }
  };
}


valColumnSelected.$inject = ['utils'];
export function valColumnSelected(utils) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valColumnSelected(scope, elem, attrs, ctrl) {
      ctrl.$validators.valColumnSelected = function (modelValue, viewValue) {
        var is_valid = true
        if (modelValue){
          /*
           *Passing metadata of filterManager. The metadata of filterManager is same as metadata of tvm/ avm
           */
          var selected_col_info = scope.filterManager.internal_name_to_col_map[modelValue];
          if (selected_col_info && selected_col_info.hasOwnProperty('error')){
            is_valid =  false
          }
          else{
            var column_info = scope.$eval(attrs.atomicConditionCol)
            if (selected_col_info['type'] != column_info['type']){
              is_valid = false
            }
          }
        }
        return is_valid
      };
    }
  };
}
