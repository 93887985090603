const adminStore = {
  state: {
    workspaces: null,
    domains: null,
    integrations: null,
    info: {
      page: "admin-info-workspaces",
      payload: null
    },
    deploymentEnv: {
      hostname: null,
      env: null
    }
  },

  actions: {
    setWorkspaces: (store, workspaces) => {
      store.commit("setWorkspaces", workspaces);
    },
    setDomains: (store, domains) => {
      store.commit("setDomains", domains);
    },
    setIntegrations: (store, integrations) => {
      store.commit("setIntegrations", integrations);
    },
    setInfo: (store, info) => {
      store.commit("setInfo", info);
    },
    setDeploymentEnv: (store, deploymentEnv) => {
      store.commit("setDeploymentEnv", deploymentEnv);
    }
  },

  mutations: {
    setWorkspaces: (state, workspaces) => {
      state.workspaces = workspaces;
    },
    setDomains: (state, domains) => {
      state.domains = domains;
    },
    setIntegrations: (state, integrations) => {
      state.integrations = integrations;
    },
    setInfo: (state, info) => {
      state.info.page = info.page;
      state.info.payload = info.payload;
    },
    setDeploymentEnv: (state, deploymentEnv) => {
      state.deploymentEnv = deploymentEnv;
    }
  },

  getters: {
    workspaces: state => state.workspaces,
    domains: state => state.domains,
    integrations: state => state.integrations,
    info: state => state.info,
    deploymentEnv: state => state.deploymentEnv
  }
};

export default adminStore;
