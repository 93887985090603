/*jslint node: true */

/**
 * @ngInject
 */
renderFilterElement.$inject = ['config'];
export function renderFilterElement(config){
  return {
    scope: {
      filter: "=",
      applyCallback: "&"
    },
    templateUrl: config.templates.filterElementContentTemplate,
    controller: 'filterRenderController',
    controllerAs: 'fevm',
    bindToController: true,
    link: function (scope, ele, attrs) {
      if (!attrs.applyCallback) {
        scope.fevm.isSelectable = false;
      } else {
        scope.fevm.isSelectable = true;
      }
    }
  };
}
