'use strict';


/**
 * @ngInject
 */

analyticsService.$inject = ['$analytics', '$location', '$timeout', 'UserEvents', 'VuexStore'];
export function analyticsService($analytics, $location, $timeout, UserEvents, $store){


  var service = {
    userEventTrack: userEventTrack,
    init:init,
    isInited: false,
    setSuperProperties: setSuperProperties
  };

  return service;

  function setSuperProperties(superProperties) {
    if (!service.isInited) {
      init();
    }
    $analytics.setSuperProperties(superProperties);
  }

  function userEventTrack(eventName, eventProperties, additionalInfo = {}){
    $timeout(function () {
      if (!service.isInited){
            init();
      }
      if (eventProperties) {
          UserEvents.createUserEventEntry(eventName, eventProperties, additionalInfo);
          $analytics.eventTrack(eventName, eventProperties);
      }
      else {
          UserEvents.createUserEventEntry(eventName);
          $analytics.eventTrack(eventName);
      }
    });
  }

  function init(){
    var userEmail = $store.state.user.email;
    var name = $store.state.user.name;
    if (userEmail) {
      $analytics.setUsername(userEmail);
      $analytics.setUserPropertiesOnce(
        {
          $email: userEmail,
          $name: name
        }
      );
      $analytics.setSuperProperties({
        domain: $location.host()
      });
      service.isInited = true;
    }
  }
}
