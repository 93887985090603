// Dependencies
import { decode } from 'html-entities'

// Transforms
import { transformMetadata } from '@/api/global.transform.js'
import { transformNotifications } from '@/components/navbar/navbar-notifications/api/notifications.transform.js'

// Constants
import { RESOURCE_TYPES, RESOURCE_DEPENDENCIES, EXPORT_CONNECTORS, RESOURCE_STATUS } from '@/constants'

const RESOURCE_TYPES_MAP = {
  datasource: { name: RESOURCE_TYPES.DATASET, idType: 'datasetId' },
  dataview: { name: RESOURCE_TYPES.VIEW, idType: 'viewId' },
  file_object: { name: RESOURCE_TYPES.FILE, idType: 'fileId' },
  label: { name: RESOURCE_TYPES.FOLDER, idType: 'folderId' },
  report: { name: RESOURCE_TYPES.REPORT, idType: 'reportId' },
  webhook: { name: RESOURCE_TYPES.WEBHOOK, idType: 'webhookId' }
}

export function transformResources(data) {
  const resources = data.data || data
  return resources
    .filter((r) =>
      RESOURCE_TYPES_MAP[r.resource_type]?.name == RESOURCE_TYPES.FILE
        ? [
            RESOURCE_STATUS.ACTION_NEEDED,
            RESOURCE_STATUS.NEED_ACTION,
            RESOURCE_STATUS.HAS_UNSTRUCTURED_DATA,
            RESOURCE_STATUS.ERROR
          ].includes(r.object_properties?.status)
        : RESOURCE_TYPES_MAP[r.resource_type]
    )
    .map((r) => ({
      resourceId: r.resource_id,
      [RESOURCE_TYPES_MAP[r.resource_type].idType]: r.object_properties?.id || r.id,
      type: RESOURCE_TYPES_MAP[r.resource_type].name,
      status: r.object_status,
      dependenciesUnfiltered: (() => {
        // Dependencies need to be filtered together with all of the existing dependencies
        // These ones only contain delta. Store them as 'dependenciesUnfiltered', so they will get filtered together with the rest
        if (![RESOURCE_TYPES.DATASET, RESOURCE_TYPES.VIEW].includes(RESOURCE_TYPES_MAP[r.resource_type].name)) return

        let dependenciesUnfiltered = {}
        for (let direction of ['in', 'out'])
          dependenciesUnfiltered[direction] =
            r.object_properties?.dependencies?.[direction].map((d) => ({
              operationId: d.op_id,
              operationName: (() => {
                if (EXPORT_CONNECTORS.map((c) => c.key).includes(d.op_name)) return 'EXPORT'
                if (d.op_name === RESOURCE_DEPENDENCIES.BRANCH_OUT && d.additional_info?.export_project)
                  return RESOURCE_DEPENDENCIES.BRANCH_OUT_TO_PROJECT
                return d.op_name
              })(),
              operationType: d.op_type,
              resourceId: d.resource_id,
              dataSync: d.data_pass_through,
              projectId: d.additional_info?.project_id,
              sourceProjectId: d.additional_info?.source_project_id,
              dataUpdatePending: d.data_update_pending,
              url: d.additional_info?.url,
              table: d.additional_info?.table,
              host: d.additional_info?.host,
              indexName: d.additional_info?.index,
              database: d.additional_info?.database,
              directory: d.additional_info?.directory,
              dbType: d.additional_info?.odbc_type || d.op_name,
              direction
            })) || []
        return dependenciesUnfiltered
      })(),
      folderResourceId: r.object_properties?.label_resource_ids?.[0] || r.object_properties?.parent_resource_id,
      properties: {
        status: r.object_properties?.status,
        name: decode(r.object_properties?.name),
        dataset: r.object_properties?.ds_id && {
          id: r.object_properties?.ds_id
        },
        webhook: r.object_properties?.additional_info?.webhook?.id && {
          id: r.object_properties?.additional_info.webhook.id
        },
        pipelineStatus: r.object_properties?.pipeline_status,
        pipelineAutoRunEnabled: r.object_properties?.pipeline_autorun_enabled,
        draftModeStatus: r.object_properties?.draft_mode,
        tasksQuantity: r.object_properties?.tasks_total_count,
        updatedAt: r.object_properties?.updated_at,
        columnCount: r.object_properties?.column_count,
        rowCount: r.object_properties?.row_count,
        webhookHasUpdates: !!r.object_properties?.unprocessed_count || undefined,
        dataSync: r.object_properties?.dependencies?.in?.find((d) => d.op_type == RESOURCE_DEPENDENCIES.VIEW)
          ?.data_pass_through,
        dataUpdatePending: r.object_properties?.dependencies?.in?.find((d) => d.op_type == RESOURCE_DEPENDENCIES.VIEW)
          ?.data_update_pending,
        sourceType: r.object_properties?.config_keys?.integration_key || undefined,
        projectId: r.object_properties?.project_id
      },
      metadata: transformMetadata(r.object_properties?.metadata)
    }))
}

export function transformAllResources({ data }) {
  // Transforms ALL resources needed: 'notifications', 'resources' and 'pendingResources'
  let transformedResources = {}
  if (data.notifications.changed) transformedResources = transformNotifications(data.notifications) // includes notifications and toasts
  if (data.core_list_items?.changed)
    transformedResources.resources = transformResources(Object.values(data.core_list_items.items))
  if (data.global_pending_items?.changed) transformedResources.pendingResources = data.global_pending_items.items
  if (data.projects.changed) transformedResources.isProjectChanged = true
  if (data.disk_usage_stats?.changed && data.disk_usage_stats.disk_usage?.workspace?.ROW_COUNT_UTILIZED != null)
    transformedResources.workspaceUsage = data.disk_usage_stats.disk_usage.workspace.ROW_COUNT_UTILIZED

  return { ...transformedResources, lastCheckedUntil: data.last_checked_until }
}

export function transformNewView({ data }) {
  if (data.ERROR_CODE) return { error: { code: data.ERROR_CODE } }
  return { newView: { viewId: data.dataview_id } }
}

export function transformPipelineChanges(data) {
  return Object.keys(data)
    .filter((k) => !isNaN(k))
    .map((k) => data[k])
    .map((change) => ({
      viewId: change.dataview_id,
      pendingStepsCount: change.pending_steps_count,
      viewName: decode(change.dataview_name),
      datasetName: decode(change.datasource_name),
      isPipelineInError: change.is_pipeline_in_error,
      tasksInError: Object.values(change.tasks_in_error || {}).map((a) => ({
        type: a.task_type,
        sequence: a.sequence
      })),
      actionsInError: Object.values(change.actions_in_error || {}).map((t) => ({ type: t.handler_type }))
    }))
}

export function transformAppliedPipelineChanges(data) {
  return {
    skippedViews: data.dataviews_skipped.map((skipped) => ({
      errorCode: skipped.error_code,
      reason: skipped.reason,
      viewId: skipped.dataview_id
    })),
    submittedViews: data.dataviews_submitted
  }
}
