<template>
  <button
    class="action-bar-button d-flex-col mm-rounded-borders p-x-3 p-y-2"
    :class="computedButtonClasses"
    @click="!disabled && $emit('click', $event)"
  >
    <mm-tooltip
      contentClass="action-bar-button-tooltip"
      :label="tooltipLabel"
      :arrowMargin="tooltipArrowMargin"
      :disabled="!tooltipLabel.length"
    >
      <div class="icon-wrapper mm-rounded-borders p-a-1">
        <mm-icon :name="icon" :color="enabled ? 'n10' : 'n600'" :hoverColor="enabled ? 'n10' : 'n800'" />
      </div>
      <span class="mm-text--caption-regular m-t-1" style="font-size: 13px">{{ label }}</span>
    </mm-tooltip>
  </button>
</template>

<script>
export default {
  name: 'action-bar-button',
  props: {
    icon: String,
    label: String,
    enabled: Boolean,
    activated: Boolean,
    disabled: Boolean,
    tooltipLabel: {
      type: String,
      default: ''
    },
    tooltipArrowMargin: String
  },
  computed: {
    computedButtonClasses() {
      return {
        'action-bar-button--enabled': this.enabled,
        'action-bar-button--activated': this.activated,
        'action-bar-button--disabled': this.disabled
      }
    }
  }
}
</script>

<style lang="scss">
@import '@mammoth_developer/mm-storybook/src/styles/shadows.scss';

$text-color-enabled: var(--mm-color--n800);

.action-bar-button {
  background: var(--mm-color--n10);

  .mm-tooltip--slot-wrapper {
    display: flex;
    flex-direction: column;

    .icon-wrapper {
      margin: 0px auto;
      height: 28px;

      .mm-icon {
        width: 24px;
        height: 24px;
      }
    }

    span {
      color: var(--mm-color--n600);
      white-space: nowrap;
    }
  }

  &.action-bar-button--activated,
  &:hover {
    .icon-wrapper {
      background: var(--mm-color--n50);
    }

    span {
      color: $text-color-enabled;
    }
  }

  &:focus,
  &:focus-visible {
    @extend .mm-shadow--focus;
    outline: none;

    span {
      color: $text-color-enabled;
    }
  }

  &.action-bar-button--enabled {
    .icon-wrapper {
      background: var(--mm-color--p600);
    }

    span {
      color: $text-color-enabled;
    }
  }

  &.action-bar-button--disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.action-bar-button-tooltip {
  label {
    text-align: initial !important;
  }
}
</style>
