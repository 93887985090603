/**
 * Created by mammoth2 on 14/09/18.
 */
import * as angular from "angular";
import {config} from "../common/app.config";

CardSummaryDataFactory.$inject = ["derivatives", 'utils', 'c', '$resource', '$q', 'exploreCardService'];
export function CardSummaryDataFactory(derivatives, utils, c, $resource, $q, exploreCardService) {
  class CardSummaryData {
    public summaryData: any;
    private column: any;
    private prevParams: any;

    constructor() {
    }

    public query(source_id, source_type, column, condition, formatInfo, sequence, getSourceData) {
      let self = this;
      this.column = column;
      if (column.type == c.text) {
        return;
      }
      let colFormat = this.getColFormat(formatInfo, column);

      let fnMap = {
        NUMERIC: generateQueryParamsNumeric,
        DATE: generateQueryParamsDate,
      };

      let queryParams: any = fnMap[column.type](column.internal_name, colFormat, condition);
      // queryParams['data_updated_at'] = dataview.data_updated_at;
      if (angular.equals(this.prevParams, queryParams)) {
        return;
      }
      this.prevParams = queryParams;
      this.summaryData = null;
      if (Number.isInteger(sequence)) {
        queryParams['param'][c.sequenceNumber] = sequence;
      }

      var deferred = $q.defer();
      let requestInitiatedAt = Date.now()
      let cardLikeObject = {
        sourceId: source_id,
        sourceType: source_type,
        column: column,
      }
      let identifier = exploreCardService.cardIdentifier(cardLikeObject, 'summary');
      getSourceData({args: {param: queryParams.param, display_properties: queryParams.displayProperties}}).then(function (response) {
        utils.futureDataTracker(response, false, identifier, requestInitiatedAt).then(function (data) {
          if (angular.isArray(data.data) && data.data.length) {
            self.summaryData = data.data[0];
            if (self.summaryData.hasOwnProperty('stddev') && self.summaryData.stddev) {
              self.summaryData.stddev = utils.number.compact(self.summaryData.stddev);
            }
            if (self.summaryData.hasOwnProperty('avg') && self.summaryData.avg) {
              self.summaryData.avg = utils.number.compact(self.summaryData.avg);
            }
          } else {
            self.summaryData = null;
          }
          deferred.resolve(response);
        });
      }, deferred.reject);
      return deferred.promise;
    }

    getColFormat(formatInfo, column) {
      if (column.type == c.numeric) {
        var colFormat = formatInfo[column.internal_name] || column.format || {decimal_spec: 2};
        colFormat.comma_separated = true;
        return colFormat;
      } else if (column.type == c.date) {
        return {
          date_format: c.datetimeServerSideFormat
        };
      }
    }
  }

  return CardSummaryData;
}

function generateQueryParamsDate(internalName, colFormat, initialCondition){
  var selParam = {
    SELECT: [
      {
        FUNCTION: 'MIN',
        COLUMN: internalName,
        INTERNAL_NAME: 'min',
        AS: 'min'
      },
      {
        FUNCTION: 'MAX',
        COLUMN: internalName,
        INTERNAL_NAME: 'max',
        AS: 'max'
      }
    ]
  };
  var param: any = {PIVOT: selParam, CONDITION: initialCondition};
  var displayProperties = {
    FORMAT_INFO: {
      max: colFormat,
      min: colFormat
    }
  };

  return {
    'displayProperties': displayProperties,
    'param': param,
    "colFormat": colFormat
  }
}


function generateQueryParamsNumeric(internalName, colFormat, initialCondition){
  var selParam = {
    SELECT: [
      {
        FUNCTION: 'SUM',
        COLUMN: internalName,
        INTERNAL_NAME: 'sum',
        AS: 'sum'
      },
      {
        FUNCTION: 'AVG',
        COLUMN: internalName,
        INTERNAL_NAME: 'avg',
        AS: 'avg'
      },
      {
        FUNCTION: 'MIN',
        COLUMN: internalName,
        INTERNAL_NAME: 'min',
        AS: 'min'
      },
      {
        FUNCTION: 'MAX',
        COLUMN: internalName,
        INTERNAL_NAME: 'max',
        AS: 'max'
      },{
        FUNCTION: 'STDDEV',
        COLUMN: internalName,
        INTERNAL_NAME: 'stddev',
        AS: 'stddev'
      }
    ]
  };
  var param: any = {PIVOT: selParam, CONDITION: initialCondition};
  var displayProperties = {FORMAT_INFO: {
    min: colFormat,
    max: colFormat
  }};

  return {
    'displayProperties': displayProperties,
    'param': param,
    "colFormat": colFormat
  }
}
