<template>
  <div class="action-bar-column-browser-header d-flex p-a-3 mm-shadow--soft-low">
    <div v-if="showSelectAll" class="column-browser-select-all d-flex m-r-3">
      <mm-checkbox
        :value="columns.every((c) => c.selected)"
        :indeterminate="columns.some((c) => c.selected)"
        :disabled="!filteredColumns.length"
        noFocusOnClick
        @click="onSelectAllClick"
      />

      <mm-menu
        v-model="isSelectAllMenuOpen"
        menuClass="column-browser-select-all-menu"
        shadowClass="mm-shadow--heavy-high"
        :closeOnContentClick="false"
      >
        <template #activator="{ on }">
          <mm-button
            icon="chevron_down"
            :activated="isSelectAllMenuOpen"
            size="medium"
            objective="tertiary"
            noFocusOnClick
            v-on="on"
          />
        </template>
        <template #content>
          <mm-list
            class="m-a-2"
            :items="computedItems"
            :selectedItems="computedItems.filter((item) => item.selected)"
            :dividerAtIndex="[3]"
            showCheckbox
            @itemClick="onItemClick"
          />
        </template>
      </mm-menu>
    </div>

    <mm-text-input :value="search" type="search" hideDetails small @input="$emit('update:search', $event)" />
  </div>
</template>

<script>
export default {
  name: 'action-bar-column-browser-header',
  props: {
    showSelectAll: Boolean,
    columns: Array,
    filteredColumns: Array,
    search: String
  },
  data: () => ({
    isSelectAllMenuOpen: false
  }),
  computed: {
    computedItems() {
      return this.selectionItems.map((selectionItem) => {
        const columns = this.filteredColumns.filter(selectionItem.selectionFilter)
        return {
          ...selectionItem,
          selected: columns.length && columns.every((c) => c.selected),
          disabled: !columns.length
        }
      })
    }
  },
  created() {
    this.selectionItems = [
      {
        text: this.$t('dictionary.text'),
        icon: 'text',
        selectionFilter: (column) => 'text' === column.type
      },
      {
        text: this.$t('dictionary.numeric'),
        icon: 'number',
        selectionFilter: (column) => 'numeric' === column.type
      },
      {
        text: this.$t('dictionary.date'),
        icon: 'date',
        selectionFilter: (column) => 'date' === column.type
      },
      {
        text: this.$t('action_bar.column_browser.visible_columns'),
        icon: 'show',
        selectionFilter: (column) => column.show
      },
      {
        text: this.$t('action_bar.column_browser.hidden_columns'),
        icon: 'hide',
        selectionFilter: (column) => !column.show
      }
    ]
  },
  methods: {
    onSelectAllClick() {
      this.$store.commit('dataEditor/setColumnSelection', {
        selected: !this.filteredColumns.every((c) => c.selected),
        columnIds: this.filteredColumns.map((c) => c.id)
      })
    },
    onItemClick({ item }) {
      if (!item.disabled) {
        this.$store.commit('dataEditor/setColumnSelection', {
          selected: !item.selected,
          columnIds: this.filteredColumns.filter(item.selectionFilter).map((c) => c.id)
        })
        this.isSelectAllMenuOpen = false
      }
    }
  }
}
</script>

<style lang="scss">
.action-bar-column-browser-header {
  .column-browser-select-all {
    align-items: center;

    .mm-checkbox--checkbox {
      margin-right: 2px;
    }
  }

  .mm-text-input {
    flex: 1;

    .mm-text-input--field {
      border-color: var(--mm-color--n40);
      background-color: var(--mm-color--n40);
    }
  }
}

.column-browser-select-all-menu {
  background: var(--mm-color--n10);
}
</style>
