const getInitialState = () => ({
  isOpen: false
})

const getters = {
  isEmpty: (state, getters, rootState, rootGetters) =>
    rootGetters['dataEditor/columns'].every((column) => !column.exploreCard),
  exploreCardColumnIds: (state, getters, rootState, rootGetters) =>
    rootGetters['dataEditor/columns'].filter((column) => column.exploreCard).map((col) => col.id),
  selectedColumnIds: (state, getters, rootState, rootGetters) =>
    rootGetters['dataEditor/columns'].filter((column) => column.selected).map((col) => col.id)
}

const mutations = {
  setIsOpen: (state, isOpen) => (state.isOpen = isOpen)
}

const actions = {}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
