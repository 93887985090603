import * as angular from 'angular';

var typeDiscriptionMap = {
  file: 'File Upload',
  sketch: 'System Generated',
  abstract: 'System Generated',
  append: 'System Generated',
  cloud: 'Third party API'
}

/**
 * @ngInject
 * Datasource Factory
 */
DatasourceFactory.$inject = ['$resource', 'config', 'c', '$q', '$http', 'eventCallbackManagerFactory', 'utils',
                                  'analyticsService', '$timeout', '$interval', 'moment', 'FutureService', 'toastNotification'];
export function DatasourceFactory($resource, config, c, $q, $http, eventCallbackManagerFactory, utils,
                                  analyticsService, $timeout, $interval, moment, FutureService, toastNotification) {
  var DatasourcesResource = $resource(config.api.datasources, {id: '@id'});
  var DatasourcesDataviewsResource = $resource(config.api.datasourcesDataviews, {id: '@id'});
  return Datasource;
  function Datasource(ds_data) {
    var self = this, _previous_ds_data;
    self.update = update;
    self.get = get;
    self.delete = del;
    self.rename = rename;
    self.updateBatchColumns = updateBatchColumns;
    self.updateSchedule = updateSchedule;
    self.clone = clone;
    self.toggleAutoSync = toggleAutoSync;
    self.changeSyncDelay = changeSyncDelay;
    self.add_dataview = add_dataview;
    self.refresh = refresh;
    self.syncState = new SyncState();
    self.dataviews = {};
    var dsUpdated = new eventCallbackManagerFactory('ws_updated');
    self.onUpdate = dsUpdated.add_callback;
    self.fireOnUpdate = dsUpdated.fire_event;
    self.dataviews_list = [];
    update(ds_data);
    var ds_res = new DatasourcesResource();
    ds_res.id = self.id;

    ////////

    /**
     * Updates the local DS instance with values from ds_data
     * @param ds_data JSON representation of the datasource
     */
    function update(ds_data) {
      if (angular.equals(_previous_ds_data, ds_data)) {
        return;
      }
      _previous_ds_data = ds_data;
      ds_data._dataviews = ds_data.dataviews;
      delete ds_data.dataviews;
      angular.extend(self, ds_data);
      self.unique_id = self.type + '_' + self.id;
      addDisplayProperties(self);
      try {
        self.dsSourceType = typeDiscriptionMap[self.source_type];
      } catch (e) {
        self.dsSourceType = 'System Generated';
      }
      self.fireOnUpdate();
    }

    function addDisplayProperties(ds_data) {
      self.shortName = utils.string.addCentreEllipsis(ds_data.name); //Adds centre ellipsis
      self.displayProperties = {
        scheduleFrequency: '-',
        typeName: ds_data.source_type
      };
      if (ds_data.display_info && ds_data.display_info.rrule) {
        self.displayProperties.scheduleFrequency = utils.schedule.getScheduleFrequency(ds_data.display_info.rrule);
      }
      if (ds_data.config_keys && ds_data.config_keys.integration_key) {
        self.displayProperties.typeName += ` ${ds_data.config_keys.integration_key}`;
      }
      if (ds_data.additional_info && ds_data.additional_info.webhook) {
        self.displayProperties.typeName += ` webhook`;
      }
      if (ds_data.additional_info && ds_data.additional_info.DATAVIEW_ID && ds_data.additional_info.TRIGGER_ID) {
        self.displayProperties.typeName += ` internal-ds`;
      }

    }


    function get(includeRowCount=false) {
      var deferred = $q.defer();
      let dsRes = $resource(config.api.datasources);
      dsRes.get({id: self.id, 'includeRowCount': includeRowCount}).$promise.then(function (data) {
        get_data_tracker(data).then(function (response) {
          self.update(response);
          deferred.resolve(self);
        });
      }, deferred.reject);
      return deferred.promise;
    }

    function get_data_tracker(data) {
      var deferred = $q.defer();
      FutureService.track(data.future_id, data_tracker);

      function data_tracker(future) {
        let response = future.response;
        if (future.status == "processing") {
          return;
        }
        if (future.status == "success") {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }
      return deferred.promise;
    }

    /**
     * Delete a datasource
     */
    function del() {
      ds_res.id = self.id;
      var ret = ds_res.$delete({});
      return ret;
    }

    /**
     * Rename DS
     */
    function rename(new_name, validation_only) {
      if (!new_name){
        return $q.reject();
      }
      if (new_name == self.name) {
        return $q.resolve();
      }
      var ret = DatasourcesResource.patch({id: self.id, validation_only: validation_only}, {
        patch: [{
          op: "replace",
          path: "name",
          value: new_name
        }]
      });
      return ret.$promise;
    }

      /**
       * Update batch columns in DS
       */
      function updateBatchColumns(add_columns, remove_columns) {
          if (!add_columns && !remove_columns) {
              return $q.reject();
          }
          var ret = DatasourcesResource.patch({id: self.id}, {
              patch: [{
                  op: "replace",
                  path: "batch_columns",
                  value: {
                      "add_columns": add_columns,
                      "remove_columns": remove_columns
                  }
              }]
          });
          return ret.$promise;
      }

      function toggleAutoSync(value){
      analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.toggleAutoSync, {eventOrigin: "landingPage.previewPanel", currentValue: value});

      self.autosync = value;
      var patchParams = {patch: [{op: "replace", path: "autosync", value: value}]};
      var ret = DatasourcesResource.patch({id: self.id}, patchParams);
      return ret.$promise;
    }

    function changeSyncDelay(delay_in_seconds){
      var now = moment.utc().add(delay_in_seconds, 's');
      var patchParams = {patch: [{op: "replace", path: "sync_eta", value: now.unix()}]};
      var ret = DatasourcesResource.patch({id: self.id}, patchParams);
      return ret.$promise;
    }

    /**
     * Refresh DS
     */
    function refresh() {
      var ret = DatasourcesResource.patch({id: self.id, validation_only: false}, {
        patch: [{
          op: "replace",
          path: "data",
          value: "refresh"
        }]
      });
      return ret.$promise;
    }

    function updateSchedule(scheduleState, scheduleJob, notification_id?) {
      if(!notification_id){
        notification_id = null
      }
      var ret = DatasourcesResource.patch({id: self.id, validation_only: false}, {
        patch: [{
          op: "replace",
          path: "schedule",
          value: scheduleJob,
          notification_id: notification_id,
          schedule_state: scheduleState
        }]
      });
      return ret.$promise;
    }


    /**
     * Adds a dataview
     */
    function add_dataview(name, copy_from_wksp_id, dataview_config, extra_condition) {
      var deferred = $q.defer();
      var param = {'name': name, 'id': self.id, dataview_config: dataview_config, extra_condition: extra_condition};

      if(copy_from_wksp_id){
        param['clone_config_from'] = copy_from_wksp_id;
      }
      DatasourcesDataviewsResource.save(param).$promise.then(add_wksp_success_cb, add_wksp_failure_cb);
      function add_wksp_success_cb(data) {
        deferred.resolve(data)
      }
      function add_wksp_failure_cb(data) {
        let error_message = "Failed to add dataview";
        if(data.data && data.data.ERROR_MESSAGE) {
          error_message += ": " + data.data.ERROR_MESSAGE;
        }
        toastNotification.error(error_message);
        deferred.reject()
      }
      return deferred.promise;
    }

    /**
     * clones the datasource
     */

    function clone(clone_dataviews) {
      var deferred = $q.defer();
      var post_object = $http.post(config.api.datasourcesRoot, {clone_existing: self.id, clone_dataviews: clone_dataviews});

      post_object.then(function () {
        deferred.resolve();
      });

      post_object.catch(function (response) {
        let data = response.data;
        let error_message = "Failed to clone the dataset";
        if(data && data.ERROR_MESSAGE) {
          error_message += ": " + data.ERROR_MESSAGE;
        }
        toastNotification.error(error_message);

        deferred.reject();
      });
      return deferred.promise;
    }



    function SyncState(){
      var ss = this;

      ss.showSyncPauseWarning = false;
      ss.sync = propagateLatestBatchToDataviews;
      ss.lastKnownEta = null;
      ss.secondsLeft = null;
      ss.setEta = setEta;
      ss.pause = pause;
      ss.syncNow = syncNow;
      ss.syncSubmitted = false;
      ss.isSyncing = isSyncSubmissionInProgress;

      ss.isUserActionNeeded = function isUserActionNeeded () {
        isSyncSubmissionInProgress();
        return !self.latest_batch_propagated && !self.autosync && !ss.syncSubmitted && self.latest_batch_rows>0;
      };

      function setEta(eta, secondsLeft){
        if(eta == ss.lastKnownEta){
          return
        }
        if(!self.autosync){
          return;
        }
        ss.lastKnownEta = eta;
        ss.secondsLeft = secondsLeft;

        if(ss.secondsLeft > 0){
          ss.showSyncPauseWarning = true;
          $interval(countDown, 1000, ss.secondsLeft);
        }
      }

      function pause(){
        ss.showSyncPauseWarning = false;
        self.toggleAutoSync(false);
      }

      function syncNow(){
        ss.showSyncPauseWarning = false;
        self.changeSyncDelay(0);
      }

      function countDown(){
        ss.secondsLeft--;
        if(ss.secondsLeft == 0){
          ss.showSyncPauseWarning = false;
        }
      }

      function propagateLatestBatchToDataviews(){
        ss.syncSubmitted = true;
        analyticsService.userEventTrack(c.userEvents.landingPage.previewPanel.syncData, {eventOrigin: "landingPage.previewPanel", eventType: "sync now clicked"});
        var deferred = $q.defer();
        $resource(config.api.datasourceApplyData,
          {ds_id: self.id}).save({}, {}
        ).$promise.then(deferred.resolve, errorCb);
        return deferred.promise;

        function errorCb(data) {
          ss.syncSubmitted = false;

          let error_message = "Failed";
          if(data.data && data.data.ERROR_MESSAGE) {
            error_message += ": " + data.data.ERROR_MESSAGE;
          }
          toastNotification.error(error_message);

          deferred.reject();
        }
      }

      function isSyncSubmissionInProgress(){
        if(ss.syncSubmitted && self.latest_batch_propagated){
          ss.syncSubmitted = false;
        }
        return ss.syncSubmitted;
      }
    }
  }
}
