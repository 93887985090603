import * as _ from 'lodash-es';
import { QRCode, ErrorCorrectLevel, QRNumber, QRAlphaNum, QR8BitByte, QRKanji } from 'qrcode-generator-ts/js';
/**
 * @ngInject
 */
manageUsersController.$inject = [
  'UserWorkspace',
  'c',
  '$timeout',
  'toastNotification',
  'Auth',
  'appMetaInfo',
  '$stateParams',
  'resources',
  '$location',
  '$rootScope',
  'modalService',
  'analyticsService',
  'moment',
  'ClientApps',
  'Notification',
  'utils',
  'deploymentConsts',
]
export function manageUsersController(
  UserWorkspace,
  c,
  $timeout,
  toastNotification,
  Auth,
  appMetaInfo,
  $stateParams,
  resources,
  $location,
  $rootScope,
  modalService,
  analyticsService,
  moment,
  ClientApps,
  Notification,
  utils,
  deploymentConsts
) {
  this.$onInit = function () {
    var mvm = this
    mvm.UserWorkspace = UserWorkspace
    mvm.ClientApps = ClientApps
    mvm.apps = {}
    mvm.keyPair = {}
    mvm.encryptedSecret = {}
    const apikeyLength = 28
    const secretLength = 34
    mvm.isInitialized = false

    mvm.new_user = {
      email: null,
      role_name: null,
      first_name: null,
      last_name: null,
    }
    // Make sure the order in which section options are shown here the index for uib-tab for these options in html are same.
    // Otherwise it causes wiered behaviour on UI like: clicking a tab selects a different tab
    // The first item in the list will be the default selection
    let sectionOptions = ['personal', 'org', 'security', 'users', 'uasage', 'billing', 'plan', 'apps']
    mvm.sections = {
      options: sectionOptions,
      currentSection: $stateParams.section || 'personal',
      change: changeSection,
      currentSectionIndex: $stateParams.section ? sectionOptions.indexOf($stateParams.section) : 0,
    }

    let memberPermOption = {
      name: c.ROLES_DISPLAY[c.ROLES.WORKSPACE_MEMBER],
      value: c.ROLES.WORKSPACE_MEMBER,
      desc: 'Import Data, create Pipelines and Reports',
      allowed: true,
    }
    let adminPermOption = {
      name: c.ROLES_DISPLAY[c.ROLES.WORKSPACE_ADMIN],
      value: c.ROLES.WORKSPACE_ADMIN,
      desc: 'Manage Users in addition to all Analyst privileges',
      allowed: true,
    }
    mvm.new_user_role_options = [memberPermOption, adminPermOption]

    mvm.adding_user = false
    mvm.show_change_ownership_modal = show_change_ownership_modal
    mvm.add_user = add_user
    mvm.twoFactorSecret = ''
    mvm.remove_user = remove_user
    mvm.UserWorkspace.isOwner = false
    mvm.UserWorkspace.isAdmin = false
    mvm.otp = ''
    mvm.otperror = undefined
    mvm.validateOTP = validateOTP
    mvm.get_2FA_QR = get_2FA_QR
    mvm.pwd = {}
    mvm.matchPassword = true
    mvm.isPasswordStrong = false
    mvm.showPasswordReset = false
    mvm.successText = false
    mvm.enableButton = false
    mvm.enableEditButton = false
    mvm.hideRegenerateButton = false
    mvm.modifyPassowrd = modifyPassowrd
    mvm.oldPwdInput = oldPwdInput
    mvm.PwdInput = PwdInput
    mvm.confirmPwdInput = confirmPwdInput
    mvm.totp = totp
    mvm.onPasswordChange = onPasswordChange
    mvm.comparePassword = comparePassword
    mvm.getPasswordReset = getPasswordReset
    mvm.initiate2FA = false
    mvm.toogleWorkspaceLevel2FA = toogleWorkspaceLevel2FA
    mvm.confirmWorkspaceLevel2FA = confirmWorkspaceLevel2FA
    mvm.keycloak2FA = keycloak2FA
    mvm.isKc2FAEnabled = 'loading'
    mvm.showConfirmation = false
    mvm.datetimeFor2FA = ''
    mvm.change_role = change_role
    mvm.checkoutSmsPlan = checkoutSmsPlan
    mvm.updatePaymentMethod = updatePaymentMethod
    mvm.downloadInvoice = downloadInvoice
    mvm.setOtp = setOtp
    mvm.regenerateSecret = regenerateSecret
    mvm.apps.showSecret = false
    mvm.apps.generatedSecret = ''
    mvm.apps.show_app_creation_modal = show_app_creation_modal
    mvm.apps.deleteApiToken = deleteApiToken
    mvm.apps.show_app_creation_edit_modal = show_app_creation_edit_modal
    mvm.apps.copyvalue = copyvalue
    mvm.apps.copyApiValue = copyApiValue
    mvm.apps.copyEditApiValue = copyEditApiValue
    mvm.hideRegenerateSecretButton = hideRegenerateSecretButton
    mvm.apps.get_details_of_app = get_details_of_app
    mvm.checkInvalidChars = checkInvalidChars
    mvm.validchars = true

    window.addEventListener('storage', (event) => {
      if (event.key === 'is2FAchange') {
        window.location.reload()
      }
    })

    Auth.check_token().then(reset)
    $rootScope.$on('queryStringUpdate', () => {
      syncFromState()
    })
    UserWorkspace.onSmsDetailsLoaded('from_settings_page', _loadExtraDetails)
    // When the workspace is selected update last date for enabling 2FA for user in that workspace
    UserWorkspace.on_workspace_select('set_2FA_status_and_date', function () {
      mvm.UserWorkspace.isOwner =
        mvm.UserWorkspace.users.find((user) => user.id === mvm.UserWorkspace.selfDetails.id).primary_user_role ===
        'workspace_owner'
      var last_date_time_string = _.clone(mvm.UserWorkspace.workspace.two_factor_auth_last_date)
      if (last_date_time_string) {
        var last_date_time_local = new Date(Date.parse(last_date_time_string.replace(' ', 'T') + 'Z'))
        var formatted_last_date_time_local = moment(last_date_time_local).format('YYYY-MM-DD HH:mm:ss')
        mvm.datetimeFor2FA = formatted_last_date_time_local
      }
    })
    function changeSection(section) {
      if (!mvm.isInitialized) {
        return
      }
      analyticsService.userEventTrack(c.userEvents.settingsPage.sectionChanged, {
        eventOrigin: 'settingsPage',
        section: section,
      })
      if (mvm.sections.options.indexOf(section) != -1) {
        mvm.sections.currentSection = section
        mvm.sections.currentSectionIndex = mvm.sections.options.indexOf(section)
        let query_params = $location.search()
        if (query_params.section != section) {
          $location.search('section', section)
        }
      }
      if (section === 'apps') {
        mvm.ClientApps.get_apps()
      }
      if (section === 'security') {
        mvm.UserWorkspace.is2FAEnabled().then(function (data) {
          if (data.response == true) {
            mvm.isKc2FAEnabled = 'enabled'
          } else if (data.response == false) {
            mvm.isKc2FAEnabled = 'disabled'
          }
        })
      }
    }
    /**
     * Following function handles toggling on the slider to enable/disable two-factor authentication
     */
    function toogleWorkspaceLevel2FA() {
      mvm.showConfirmation = true
      mvm.UserWorkspace.workspace.two_factor_auth_enabled = !mvm.UserWorkspace.workspace.two_factor_auth_enabled
      if (!mvm.UserWorkspace.workspace.two_factor_auth_enabled) {
        confirmWorkspaceLevel2FA()
      }
    }
    /**
     * Following function confirms two factor authentication settings for the workspace and saves the choices making API request on backend
     */
    function confirmWorkspaceLevel2FA() {
      var patch_details = { update_2fa_status: [mvm.UserWorkspace.workspace.two_factor_auth_enabled] }
      if (mvm.UserWorkspace.workspace.two_factor_auth_enabled) {
        var isoDate = moment(mvm.datetimeFor2FA).utc().format()
        patch_details['update_2fa_status'].push(isoDate)
      }
      mvm.UserWorkspace.patchWorkspace(patch_details).then(function (data) {
        mvm.showConfirmation = false
        var status = 'disabled'
        if (data.two_factor_auth_enabled) {
          status = 'enabled'
        }
        analyticsService.userEventTrack(c.userEvents.settingsPage.security.twoFactorAuthSettingsChanged, {
          eventOrigin: 'settingsPage',
          section: 'security',
        })
        // https://www.npmjs.com/package/angular-ui-notification
        toastNotification.success('Two factor authentication ' + status)
      })
    }

    function keycloak2FA() {
      mvm.UserWorkspace.setup2FA()
    }

    /**The following function fetches uri for authenctication secret and creates a QR using it,
     * to be used in Google Authenticator .
     * @param renew determines whether to generate a new authentication secret or not
     */
    function get_2FA_QR(renew?) {
      var renew = renew
      if (!renew) {
        renew = false
      }
      mvm.initiate2FA = true
      mvm.UserWorkspace.fetch_2FA_uri(renew).then(function (data) {
        var uri = data['auth_secret_uri']
        var regex = new RegExp('(secret=[A-Z|a-z|0-9])\\w+', 'g')
        var r = uri.match(regex)
        var t = r[0]
        var secret = t.slice(7)
        if (secret.length == 32) {
          mvm.twoFactorSecret = secret
          if (renew) {
            mvm.authrenewal = true
          }
        }
        create_QR_Code(uri, renew)
      })
    }

    /**The following function validates the OTP user submits while setting up 2FA
     * In post validation steps it empties the OTP value and auth secret as well as hides the QR
     */
    function validateOTP() {
      mvm.UserWorkspace.validate_2FA_OTP(mvm.otp, mvm.twoFactorSecret).then(function (data) {
        if (data['otp_validation_status'] == 'failure') {
          mvm.otperror = true
        } else if (data['otp_validation_status'] == 'success') {
          if (mvm.hasOwnProperty('authrenewal') && mvm.authrenewal) {
            toastNotification.success('Two factor authentication secret been updated successfully.')
            analyticsService.userEventTrack(c.userEvents.settingsPage.security.twoFactorAuthRenewed, {
              eventOrigin: 'settingsPage',
              section: 'security',
            })
          } else {
            analyticsService.userEventTrack(c.userEvents.settingsPage.security.twoFactorAuthSetup, {
              eventOrigin: 'settingsPage',
              section: 'security',
            })
            toastNotification.success('Two factor authentication has been setup successfully.')
          }
          mvm.twoFactorSecret = ''
          mvm.otperror = undefined
          mvm.otp = ''
          mvm.initiate2FA = false
        }
      })
    }

    /**
     * This function creates a QR for the two factor auth uri
     * @param uri - the text which needs to be converted to QR
     * @param renew - used to replace the existing QR with new QR
     * @returns
     */
    function create_QR_Code(uri, renew) {
      var qr = new QRCode()
      qr.setTypeNumber(10)
      qr.setErrorCorrectLevel(ErrorCorrectLevel.L)
      // qr.addData('otpauth://totp/dev:email%40something.io?secret=QOG26H7GRMEGJ6ILRV3DDQGU2CTCMPY6&issuer=dev')
      qr.addData(uri)
      qr.make()
      var qrElement = document.getElementById('authqr')
      if (qrElement.children.length > 0) {
        if (renew) {
          var childrenNodes = qrElement.children
          qrElement.removeChild(childrenNodes[0])
        } else {
          return
        }
      }
      var img = document.createElement('img')
      img.setAttribute('src', qr.toDataURL())
      document.body.appendChild(img)

      var cellSize = 2
      var margin = cellSize * 4
      var canvas = document.createElement('canvas')
      var size = qr.getModuleCount() * cellSize + margin * 2
      canvas.width = size
      canvas.height = size
      var ctx = canvas.getContext('2d')

      // fill background
      ctx.fillStyle = '#ffffff'
      ctx.fillRect(0, 0, size, size)

      // draw cells
      ctx.fillStyle = '#2ECC71'
      for (var row = 0; row < qr.getModuleCount(); row += 1) {
        for (var col = 0; col < qr.getModuleCount(); col += 1) {
          if (qr.isDark(row, col)) {
            ctx.fillRect(col * cellSize + margin, row * cellSize + margin, cellSize, cellSize)
          }
        }
      }
      qrElement.appendChild(img)
    }

    function syncFromState() {
      let section = $location.search().section || $stateParams.section || sectionOptions[0]
      changeSection(section)
    }

    function reset() {
      appMetaInfo.pageLoaded()
      _listWorkspaces()
      resources.stop_update_polling()

      function _listWorkspaces() {
        UserWorkspace.get_workspaces().then(_chooseWorkspace)
      }

      function _chooseWorkspace() {
        UserWorkspace.get_self_details().then(function () {
          UserWorkspace.auto_select_workspace().then(_setup)
        })
      }

      function _setup() {
        mvm.chosenPlan = null
        syncFromState()
      }

      mvm.isInitialized = true
    }

    function _loadExtraDetails() {
      try {
        UserWorkspace.loadInvoices()
        UserWorkspace.loadDiscountDetails()
      } catch (e) {
        console.error(e)
      }
      appMetaInfo.pageLoaded()
    }

    function checkoutSmsPlan(plan) {
      appMetaInfo.pageLoading()
      mvm.chosenPlan = plan
      UserWorkspace.checkoutSmsPlan(plan.id).then(function _cb(data) {
        location.href = data.url
      }, _errorCb)

      function _errorCb(data) {
        console.error(data.data.ADDITIONAL_INFO)
        mvm.chosenPlan = null
        appMetaInfo.pageLoaded()
        toastNotification.error(data.data.ADDITIONAL_INFO || 'something went wrong')
      }
    }

    function updatePaymentMethod() {
      appMetaInfo.pageLoading()
      UserWorkspace.updatePaymentMethod().then(function (data) {
        location.href = data.url
      })
    }

    function downloadInvoice(invoice) {
      invoice.downloading = true
      UserWorkspace.downloadInvoice(invoice.id).then(function (data) {
        invoice.download_url = data.download_url
        invoice.downloading = false
        $timeout(function () {
          invoice.download_url = null
        }, 1800000)
      })
    }

    function add_user() {
      if (!mvm.new_user.email || !mvm.new_user.role_name) {
        return
      }
      $timeout(function () {
        mvm.adding_user = true
      }, 0)
      UserWorkspace.add_user_to_workspace(
        mvm.new_user.email,
        mvm.new_user.role_name,
        mvm.new_user.first_name,
        mvm.new_user.last_name
      ).then(
        function () {
          UserWorkspace.get_users().then(function () {
            after_get_users()
            toastNotification.success(
              mvm.new_user.email +
                ' has been added as an ' +
                c.ROLES_DISPLAY[mvm.new_user.role_name] +
                ' to the workspace'
            )
            mvm.new_user = {
              email: null,
              role_name: null,
              first_name: null,
              last_name: null,
            }
          }, after_get_users)
        },
        function (data) {
          if (data.data && data.data.ERROR_CODE == 71) {
            toastNotification.error(mvm.new_user.email + ' already exists in this workspace')
            data = undefined
          }
          after_get_users(data)
        }
      )

      function after_get_users(data = undefined) {
        mvm.adding_user = false
        if (data) {
          _log_error(data)
        }
      }
    }

    function show_change_ownership_modal(user) {
      reset()
      modalService.transferOwnerShip(mvm.UserWorkspace, user).then(function () {
        analyticsService.userEventTrack(c.userEvents.settingsPage.userRemoved, {
          eventOrigin: 'settingsPage',
          delete_user_id: user.id,
          delete_user_email: user.email,
          workspace_id: mvm.UserWorkspace.workspace.id,
          workspace_name: mvm.UserWorkspace.workspace.name,
        })
        remove_user(user)
      })
    }

    async function show_app_creation_modal() {
      var apiKey = utils.getRandomString(apikeyLength)
      var apiSecret = utils.getRandomString(secretLength)

      mvm.keyPair.key = apiKey
      mvm.keyPair.secret = apiSecret
      mvm.keyPair.encryptedKey = await encrypt(apiKey)
      mvm.keyPair.encryptedSecret = await encrypt(apiSecret)
      modalService.createApiToken(mvm)
    }

    async function encrypt(data) {
      const textEncoder = new TextEncoder()
      const keyData = textEncoder.encode(deploymentConsts.API_TOKEN_ENC_KEY)
      const iv = window.crypto.getRandomValues(new Uint8Array(12))
      const algorithm = { name: 'AES-GCM', iv: iv }
      const encodedData = new TextEncoder().encode(data)
      const cryptoKey = await window.crypto.subtle.importKey('raw', keyData, algorithm, false, ['encrypt'])
      const encryptedData = await window.crypto.subtle.encrypt(algorithm, cryptoKey, encodedData)
      const ciphertext = new Uint8Array(iv.length + encryptedData.byteLength)
      ciphertext.set(iv)
      ciphertext.set(new Uint8Array(encryptedData), iv.length)
      const base64String = btoa(String.fromCharCode(...ciphertext))
      return base64String
    }

    function show_app_creation_edit_modal() {
      modalService.editApiToken(mvm)
    }

    function deleteApiToken(item) {
      item['type'] = 'thirdpartyapp'
      modalService.deleteApiToken(item).then(function () {
        deleteapp(item.app_key)
      })
    }

    function remove_user(user) {
      if (!user.id) {
        return
      }
      UserWorkspace.remove_user_from_workspace(user.id).then(function () {
        UserWorkspace.get_users()
        toastNotification.success((user.name || user.email) + ' has been removed from this workspace')
        if (mvm.UserWorkspace.logged_in_user.id == user.id) {
          Auth.logout()
        }
      }, _log_error)
    }

    function _log_error(data) {
      toastNotification.error(data.data.ERROR_MESSAGE)
    }

    function change_role(user, role) {
      var change_promise = UserWorkspace.change_user_role(user.id, role)
      change_promise.then(
        function successCallback() {
          mvm.UserWorkspace.users = []
          UserWorkspace.get_users().then(function () {
            toastNotification.success((user.name || user.email) + ' is now an ' + c.ROLES_DISPLAY[role])
          })
        },
        function errorCallback(response) {
          if (response.data.ERROR_MESSAGE) {
            Notification.error(response.data.ERROR_MESSAGE)
          }
        }
      )
    }

    function deleteapp(app_key) {
      mvm.ClientApps.delete_app(app_key)
    }

    function get_details_of_app(app_key) {
      appMetaInfo.pageLoading()
      mvm.ClientApps.get_app_details(app_key)
      appMetaInfo.pageLoaded()
    }

    function regenerateSecret() {
      var secret = utils.getRandomString(secretLength)
      encrypt(secret).then(function (encrypted) {
        mvm.encryptedSecret = encrypted
      })
      return secret
    }

    function hideRegenerateSecretButton() {
      mvm.hideRegenerateButton = true
    }

    function copyvalue(item) {
      navigator.clipboard.writeText(item)
      toastNotification.success('Copied to clipboard')
      mvm.successText = true
    }

    function copyApiValue(item) {
      navigator.clipboard.writeText(item)
      toastNotification.success('Copied to clipboard')
      mvm.enableButton = true
    }

    function copyEditApiValue(item) {
      navigator.clipboard.writeText(item)
      toastNotification.success('Copied to clipboard')
      mvm.enableEditButton = true
    }

    function getPasswordReset() {
      mvm.showPasswordReset = true
    }

    function onPasswordChange(value) {
      mvm.isPasswordStrong = value
    }

    function oldPwdInput(pwd) {
      mvm.pwd.oldPassword = pwd
    }

    function PwdInput(pwd) {
      mvm.checkInvalidChars(pwd)
      mvm.pwd.newPassword = pwd
      mvm.comparePassword()
    }

    function confirmPwdInput(pwd) {
      mvm.pwd.confirmPassword = pwd
      mvm.comparePassword()
    }

    function totp(otp) {
      mvm.pwd.totp = otp
    }

    function comparePassword() {
      mvm.matchPassword = false
      if (mvm.pwd.newPassword == mvm.pwd.confirmPassword) {
        mvm.matchPassword = true
      }
    }

    function checkInvalidChars(pwd) {
      mvm.validchars = true
      var invalidCharError1 = pwd.includes('\\')
      var invalidCharError2 = pwd.includes('"')
      if (invalidCharError1 || invalidCharError2) {
        mvm.validchars = false
        mvm.invalidchar = 'Sorry, the symbols \\ or " are not allowed. Please choose another one '
      }
    }

    function setOtp(otp) {
      mvm.otp = otp
    }

    function modifyPassowrd() {
      mvm.pwderror = null
      if (mvm.pwd.newPassword == mvm.pwd.oldPassword) {
        mvm.pwderror = 'New password is same as the old password'
      } else if (mvm.isPasswordStrong && mvm.matchPassword) {
        mvm.UserWorkspace.modify_password(mvm.pwd).then(function (response) {
          if (response.STATUS == 'SUCCESS') {
            toastNotification.success('Password changed successfully')
            mvm.pwd = {}
            mvm.matchPassword = null
            mvm.pwderror = null
          }
          if (response.STATUS == 'FAILURE') {
            if (response.response) {
              mvm.pwderror = response.response
            }
          }
        })
      } else {
        mvm.pwderror = 'Psassword is not strong or the passwords do not match'
      }
    }
  }
}
