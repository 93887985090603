/**
 * While elements are the concepts on the frontend,
 * derivatives are equivalent concepts on the backend.
 */



/**
 * @ngInject
 */
derivativeBackend.$inject = ['$resource', '$q', 'config', '$timeout', 'analyticsService', 'c'];
export function derivativeBackend($resource, $q, config, $timeout, analyticsService, c) {
  var DerivativeResource = $resource(config.api.derivatives);
  var counterTimeMap = [10, 10, 20, 50, 100, 100, 500, 500, 500, 500, 500, 500, 1000, 1000, 2000];

  return {
    add: add,
    edit: edit,
    discard: discard,
    list: list,
    get_derivative: get_derivative
  };


  function add(dataviewId, param, display_properties) {
    var deferred = $q.defer();
    if (display_properties == undefined) {
      display_properties = {};
    }
    DerivativeResource.save(
      {dataviewId: dataviewId},
      {param: param, display_properties: display_properties}
    ).$promise.then(function (data) {
        if (data.STATUS == 'FAILURE') {
          deferred.reject(data)
        } else {
          deferred.resolve(data.id);
        }
      }, deferred.reject
    );
    return deferred.promise;
  }

  function edit(dataviewId, derivativeId, param, display_properties) {
    var patch = [];
    if (param) {
      patch.push({"op": "replace", "path": "param", "value": param});
    }
    if (display_properties) {
      patch.push({"op": "replace", "path": "display_properties", "value": display_properties});
    }
    analyticsService.userEventTrack(c.userEvents.elements.edit, {eventOrigin: 'metric'});
    return DerivativeResource.patch({dataviewId: dataviewId, derivativeId: derivativeId}, {"patch": patch}).$promise;
  }

  /**
   * Create a query and resolve when query is complete
   */
  function get_derivative(dataviewId, derivativeId, condition, row_limit,
                 extra_group_by, queryDisplayProperties, deferred) {
    if (!condition) {
      condition = null;
    }
    if(!deferred){
      deferred = $q.defer();
    }
    DerivativeResource.save(
      {dataviewId: dataviewId,
       derivativeId: derivativeId},
      {display: JSON.stringify(queryDisplayProperties), CONDITION: condition, LIMIT: row_limit, GROUP_BY: extra_group_by}
    ).$promise.then(_successCb, _failureCb);

    function _successCb(data) {
      if(data.STATUS == 'PROCESSING' || data.STATUS == 'NEW'){
        $timeout(function(){
          get_derivative(dataviewId, derivativeId, condition, row_limit, extra_group_by, queryDisplayProperties, deferred)
        }, 5000);
        return;
      }
      if(data.STATUS == 'READY'){
        deferred.resolve(data);
      }
      if(data.STATUS == 'ERROR'){
        deferred.reject();
      }
    }

    function _failureCb(e){
      if(e.data.ERROR_CODE == 1503){
        $timeout(function(){
          get_derivative(dataviewId, derivativeId, condition, row_limit, extra_group_by, queryDisplayProperties, deferred)
        }, 5000);
      }

    }
    return deferred.promise;
  }

  function discard(dataviewId, derivativeId) {
    return DerivativeResource.delete({dataviewId: dataviewId, derivativeId: derivativeId}).$promise;
  }

  function list(dataviewId) {
    var deferred = $q.defer();
    DerivativeResource.get(
      {dataviewId: dataviewId}
    ).$promise.then(function (data) {
        data.derivatives.forEach(function(d){
          d.dataviewId = dataviewId;
        });
        deferred.resolve(data.derivatives);
      },
      deferred.reject
    );
    return deferred.promise;
  }
}
