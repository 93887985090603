<template>
  <mm-modal v-model="computedIsModalOpen" :title="$t('navbar.tab.menu.delete.title')" width="400">
    <template #default>
      <span class="mm-text--body-regular">{{ $t('navbar.tab.menu.delete.description') }}</span>

      <div class="modals-tab-delete-data m-t-3">
        <div class="resources-list">
          <mm-tooltip
            v-for="resource in resources"
            :key="resource.resourceId"
            :label="resource.properties.name"
            wrapper-class="text-ellipsis d-flex"
          >
            <mm-icon class="m-r-3" name="delete" style="min-width: 16px" />
            <p class="mm-text--body-bold text-ellipsis">{{ resource.properties.name }}</p>
          </mm-tooltip>

          <div v-if="resources[0].viewId">
            <span>{{ $t('navbar.tab.menu.delete.contained') }}</span>
            <mm-tooltip :label="computedDatasetName" wrapper-class="text-ellipsis">
              <span>{{ computedDatasetName }}</span>
            </mm-tooltip>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <mm-button :label="$t('global.dictionary.cancel')" objective="tertiary" @click="computedIsModalOpen = false" />
      <mm-button class="m-l-3" :label="$t('global.dictionary.delete')" objective="destructive" @click="submit" />
    </template>
  </mm-modal>
</template>

<script>
export default {
  name: 'resource-modal-delete',
  props: {
    value: Boolean,
    resources: {
      type: Array,
      required: true
    }
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    computedDatasetName() {
      return this.$store.getters['resources/getDatasetByViewId'](this.resources[0].viewId).properties.name
    }
  },
  methods: {
    submit() {
      this.$emit('submit')
      this.computedIsModalOpen = false
    }
  }
}
</script>

<style lang="scss">
.modals-tab-delete-data {
  .resources-list {
    overflow-x: hidden;
    max-height: 200px;
    overflow-y: auto;

    .text-ellipsis {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 260px;
      overflow: hidden;
    }
  }
}
</style>
