import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';


/**
 * @ngInject
 * */
export function valDuplicateNewColName() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valDuplicateNewColName(scope, elem, attrs, ctrl) {
      ctrl.$validators.valDuplicateNewColName = function (modelValue, viewValue) {
        var existing_names = [];
        $.each($(elem).siblings('input'), function (i, sibEle) {
          existing_names.push($(sibEle).val());
        });
        if (existing_names.length && viewValue) {
          return existing_names.indexOf(viewValue) === -1;
        }
        return true;
      };
    }
  };
}

valSplitCol.$inject = [];
export function valSplitCol() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valSplitCol(scope, elem, attrs, ctrl) {
      ctrl.$validators.valSplitCol = function (modelValue, viewValue) {
        let isValid = true;
        let col = _.filter(scope.tvm.manager.metadata, {'internal_name': modelValue});
        if (col.length && col[0].hasOwnProperty('error')){
          isValid=false
        }
        else if (col.length && col[0]['type'] != 'TEXT'){
          isValid = false;
        }
        return isValid;
      };
    }
  };
}
