/**
 * Created by ranjith on 14/11/18.
 */
import * as _ from "lodash-es";
import * as angular from 'angular';
import {config} from '../../common/app.config';
import * as $ from 'jquery';
/**
 *@ngInject
 */
includeStepsPanel.$inject = ['$compile', 'config'];
export function includeStepsPanel($compile, config) {
  return {
    replace: true,
    templateUrl: config.templates.stepsPanel,
    controller: 'StepsPanelController',
    controllerAs: 'sp',
    bindToController: true,
    scope: {
      historyDataViewPanel: '=',
      dataview: '=',
      dataviewCtrl: '=',
      taskPanel: '=',
      actionPanel: '=',
      actionTriggersLists: '=',
      dataviewConfig: '='
    }
  }
}

copyToClipboard.$inject = ['utils', 'toastNotification'];
export function copyToClipboard(utils, toastNotification){
  return {
    link: function(scope, element, attrs){
      $(element).click(function () {
        utils.copyToClipboard(attrs.copyToClipboard);
        toastNotification.success('Copied to clipboard');
      });
    }
  }
}

showMenuWithinViewport.$inject = ['$timeout'];
export function showMenuWithinViewport($timeout){
  return {
    link: function(scope, element, attributes){
      scope.$watch(attributes.onVisible, function(){
        $timeout(_check, 10);
      });
      function _check(){
        let e = $(element);
        let offset = e.offset();
        let width = e.outerWidth();
        let height = e.outerHeight()
        let docWidth =$(document.body).width();
        let docHeight =$(document.body).height();
        if(offset.left + width > docWidth){
          $(element).offset({left: docWidth - (width + 10), top: undefined});
        }
        if(offset.top + height > docHeight){
          $(element).offset({top: docHeight - (height + 10), left: undefined});
        }
      }
    }
  }
}

export class StepsPanelController{
  public dataview;
  public historyDataViewPanel;
  public taskService;
  public actionService;
  public taskPanel;
  public actionPanel;
  public actionTriggersLists;
  public itemsList: any[];
  public dataviewConfig;
  public hideDesc = false;
  public $timeout;
  public $scope;
  public rf;
  public ws_id;
  public toggleReorder;
  public $resource;
  public modificationRequestTracker;
  public toastNotification;
  public dataviewCtrl;
  public wkspTaskStatusFactory;
  public DataviewService;
  public modalService;
  public resource;
  public q;
  public autoRunValue;
  public autoRunLabel;
  public autoRunLoader;
  public statusMap;
  public dataviewRerunService;
  public resources;
  public appHelperService;
  public disablePipelineOptions = false;
  public localService;
  public enableCopyPaste;
  public reorderLoader = false;
  public FutureService;
  public pipelineChanges = 0;
  public clipboardService;
  public isPipelineMenuOpen;
  public isOriginalDsMenuOpen;
  public publishedReport = null;
  public isRePublishLoading = false;
  public $window;
  public $store;
  public vueToggleDataPreviewFn;
  public vueOriginalDataset = {};

  static $inject = ['ActionServiceFactory', 'TaskServiceFactory', 'c', 'analyticsService', '$timeout', '$scope',
    'reorderFactory', '$resource', 'modificationRequestTracker', 'toastNotification', 'DataviewService', '$q', 'dataviewRerunService',
    'resources', 'localService','appHelperService','wkspTaskStatusFactory', 'modalService', 'FutureService', 'ClipboardService', '$window', 'VuexStore'];
  public constructor(ActionServiceFactory, TaskServiceFactory, public c, public analyticsService, $timeout, $scope,
                     reorderFactory, $resource, modificationRequestTracker, toastNotification, DataviewService, $q,
                     dataviewRerunService, resources, localService, appHelperService, wkspTaskStatusFactory, modalService, FutureService, ClipboardService, $window, $store){
    // @ts-ignore
    this.$onInit = () => {
      this.actionService = ActionServiceFactory.get_by_dataview_id(this.dataview.id);
      this.taskService = TaskServiceFactory.get_by_dataview_id(this.dataview.id);
      this.wkspTaskStatusFactory = wkspTaskStatusFactory.get(this.dataview.id);
      this.init();
    }

    this.resource = $resource;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.modalService = modalService;
    this.DataviewService = DataviewService;
    this.dataviewRerunService = dataviewRerunService;
    this.localService = localService;
    this.rf = reorderFactory;
    this.$resource = $resource;
    this.q = $q;
    this.FutureService = FutureService;
    this.modificationRequestTracker = modificationRequestTracker;
    this.toastNotification = toastNotification;
    this.appHelperService = appHelperService;
    this.resources = resources;
    this.clipboardService = ClipboardService;

    this.publishedReport = null;
    this.modalService = modalService;
    this.$window = $window;
    this.$store = $store
    this.vueToggleDataPreviewFn = (event) => this.toggleDataPreview(event);
    this.vueOriginalDataset = {};

    // Catch publish report status change to show loader for republish icon
    const self = this;
    this.resources.publishedReportsUpdateEvent.add_callback('publishedReportsUpdateEvent', function(reports) {
      const currentDataviewPublishedReport = self.itemsList.find(item => item.stepType === 'report');
      const dataviewHasReport = !!currentDataviewPublishedReport;
      if (dataviewHasReport) {
        const isReportUpdated = reports.find(report => report.id === currentDataviewPublishedReport.id)
        if (isReportUpdated) self.updateList();
      } else if (reports.length) {
        self.updateList();
      }
    });
  }

  public init(){
    let self = this;
    // Instead of updating the itemsList only call postTaskServiceUpdates so you can call additional functions that are required
    self.taskService.onUpdate('from_steps_panel', postTaskServiceUpdates.bind(this));
    self.actionService.onUpdate('from_steps_panel',  postTaskServiceUpdates.bind(this));
    self.actionService.onPublishReportUpdate('from_steps_panel', self.updateList.bind(this));
    self.DataviewService.on_update_tasks_actions_dependencies("on_update_tasks_actions_dependencies", updateDepDictAndResetReorder);
    self.autoRunValue = self.dataview.pipeline_autorun_enabled;
    self.autoRunLabel = "Auto-run pipeline";

    function postTaskServiceUpdates(){
      /**
       * Post task service updates: Update the item list and then updating dependency dict and wksp.rf._order as itemsList is used there
       */
      self.updateList()
      updateDepDictAndResetReorder(true, this.dataview.id)
    }
    function updateDepDictAndResetReorder(updateDependencyDict=false, ws_id=null) {
      /*
      updateDependencyDict: update of dependency dictionary is required whenever dataview updates, for example when adding a new
      task, or deleting a task

      ws_id: the target dataview id
       */
      let wksp = self.DataviewService.list[ws_id];
      self.autoRunValue = wksp.pipeline_autorun_enabled;
      /*
      the rf object in the dataview object will be holding the information of reordering such as the dependency dictionary.
      It will also hold a boolean variable 'toggleReorder'. toggleReorder is use to toggle reordering from the UI
       */
      if(!wksp.rf){
        wksp.rf = {
          _order:[],
          toggleReorder: true,

        };
      }
      if(!updateDependencyDict){
        return;
      }

      /*
      get dependency dictionary and then store that information in the dataview object
      Every dataview will have its dependency dictionary aved in its own dataview object
       */
      self.DataviewService.getDependencyDictionery(ws_id).then(sucessCallback, errorCallback);

      function sucessCallback(d) {
        let data = d;
        // When sample mode is on do not consider the first item (which is the invisble limit rule) for reordering
        if(wksp.in_preview_mode){
          data[0].shift()
        }
        let order = data[0];

        /*
        this sorts the array 'order' according to the array 'itemsList' based on itemId
         */

        order.sort(function(a, b){
          let refObjL = self.itemsList[_.findKey(self.itemsList, {itemId: a})];
          let refObjR = self.itemsList[_.findKey(self.itemsList, {itemId: b})];
          return self.itemsList.indexOf(refObjL) - self.itemsList.indexOf(refObjR);
        });
        wksp.rf._order = data[0];
        wksp.rf._dep = data[1];

        /*
        In order to avoid reordering restrictions that are imposed by dependencies
        Set dependency for each sequence to empty array
        */
        if (self.dataview.pipeline_status == 'error' || self.dataview.draft_mode !='off'){
          _.forEach(wksp.rf._order, function(seq){
            wksp.rf._dep[seq] = []
          })
        }

        // Auto enable reordering when draft mode is on
        // wksp.rf.toggleReorder = wksp.draft_mode!='off'?true:false;
        // Auto enable reordering all the time
        wksp.rf.toggleReorder = true;

        if((self.rf.ws_id && self.rf.ws_id == wksp.id)){
          self.rf._order = wksp.rf._order;
          self.rf._dep = wksp.rf._dep;
          self.rf.ws_id = wksp.id;
        }
      }

      function errorCallback(resp) {
        self.cancelReorder(wksp);
        wksp.rf._dep = {}
        wksp.rf._order = []
        _.forEach(wksp.taskwise_info, function(info, seq){
          if (seq != "0"){
            wksp.rf._dep[seq] = []
            wksp.rf._order.push(seq)
          }
        })
        self.rf._order = wksp.rf._order;
        self.rf._dep = wksp.rf._dep;
        self.rf.ws_id = wksp.id;
      }

    };
    self.isPipelineMenuOpen = false;
    self.statusMap =  {
      suspended : "Suspended",
      suspending : "Suspended",
      edited : "Edited",
      deleted : "Removed",
      added: "New"

    }
    self.isOriginalDsMenuOpen = false;
    self.updateList();
    self.enableCopyPaste = self.localService.storage.copypaste || true;
    self.taskPanel.taskPastedEvent.add_callback("task_pasted", self.handleTaskPaste.bind(this));
    self.taskPanel.taskCreationEvent.add_callback("task_creation", self.handldeTaskCreate.bind(this));
    self.taskPanel.taskEditingEvent.add_callback("task_editing", self.handldeTaskEdit.bind(this));
    self.taskPanel.taskPanelCloseEvent.add_callback("task_pasted", self.handleTaskPanelClose.bind(this));
    self.actionPanel.actionPanelOpenEvent.add_callback("action_open", self.handldeActionOpen.bind(this));
    self.actionPanel.actionPanelCloseEvent.add_callback("action_close", self.handldeActionClose.bind(this));
    self.actionPanel.actionPastedEvent.add_callback("action_pasted", self.hanldeActionPaste.bind(this));

  }

  public handldeActionOpen(){
    this.disablePipelineOptions = true;
  }

  public handldeActionClose(){
    this.disablePipelineOptions = false;
    this.handleTaskPanelClose()
  }

  public handldeTaskEdit(){
    this.disablePipelineOptions = true;
  }

  public handleTaskPanelClose(){
    /**  When task panel is closed update the items list as well as cancel reorder.
     Note: Cancelling reorder prevents following issue - If user drags a dummy task
     in the middle of pipeline and cancels that task the pipeline following the dummy task vanishes
     and updating list on closing taskpanel ensures the dummy item is removed from the pipeline
     */

    var self = this
    this.updateList(false)
    let wksp = self.DataviewService.list[self.dataview.id];
    if (wksp.rf.orderChangedLocally){
      this.cancelReorder(wksp, true, true, true)
      wksp.rf.orderChangedLocally = false;
    }
    this.disablePipelineOptions = false;


  }
  public showNonUniqueValues(data){
    this.analyticsService.userEventTrack(this.c.userEvents.dataviewEvents.exploreJoinWarning,
      {
        eventOrigin: "dataview.pipeline"
      });
    this.modalService.showNonUniqueValues(data)
  }
  public handleTaskPaste(data){
    this.disablePipelineOptions = true;
    var self = this
    data['stepType']='task'
    data['title'] = self.dataviewConfig.taskConfig[data['opname']]['label']
    data['dummy'] = true;
    if(data['sequence']){
      /** data['sequence'] = 4 means paste below task 3
      Subtract 1 from 4 because index starts from 0 so the new step is added Idx0:(Task1),Idx1:(Task2),Idx2:(Task3),Idx3:(TaskNew)
      Add no. of actions in list before the new task's sequence
      Count all the actions attached to Original Ds, Task1, task2, Task3*/
      let lastTAskSequence = null
      let actionCount=0
      for(const q in self.itemsList){
        if (self.itemsList[q].stepType=='action'){
          if (lastTAskSequence!=null && lastTAskSequence>=data['sequence']){
            // Do not count actions from New Tasks sequence onwards
          }else{
            actionCount+=1
          }
        }else{
          lastTAskSequence = self.itemsList[q].sequence
        }
      }
      let item_id=self.itemsList.length + 1
      data['itemId'] = item_id.toString()
      self.itemsList.splice(data['sequence']-1+actionCount, 0, data)
    }else if(self.itemsList.length > 0){
      let item_id = self.itemsList.length + 1
      data['itemId'] = item_id.toString()
      self.itemsList.push(data)
    }
    this.$timeout(function(){
      this.resetPipelineSteps(self.dataview.id, self.itemsList)
    }.bind(self),500)
  }

  public hanldeActionPaste(data){
    this.disablePipelineOptions = true;
    var self = this
    data['stepType']='action'
    delete data['id']
    data['title'] = self.dataviewConfig.actionConfig[data['handler_type']]['label']
    if (data['handler_type'] == 'internal_dataset' && data?.target_properties?.TRANSFORM?.CROSSTAB){
      data['title'] = 'Crosstab'
      data['nameOverride'] = 'Crosstab'
    }
    if (data['handler_type'] == 'internal_dataset' && data?.target_properties?.export_project){
      data['nameOverride'] = 'Branch out to Project';
    }
    data['dummy'] = true;
    let lastTAskSequence = null
    let actionCount=0
     /** data['sequence'] = 3 means paste below task 3
      Subtract 1 from 4 because index starts from 0 so the new step is added Idx0:(Task1),Idx1:(Task2),Idx2:(Task3),Idx3:(TaskNew)
      Add no. of actions in list before the new task's sequence
      Count all the actions attached to Original Ds, Task1, task2, Task3*/
    if(data['sequence']){
      for(const q in self.itemsList){
        if (self.itemsList[q].stepType=='action'){
          if (lastTAskSequence!=null && lastTAskSequence>=data['sequence']){
            // Do not count actions from New Tasks sequence onwards
          }else{
            actionCount+=1
          }
        }else{
          lastTAskSequence = self.itemsList[q].sequence
        }
      }
      self.itemsList.splice(data['sequence']+actionCount, 0, data)
    }else if(self.itemsList.length > 0){
      self.itemsList.push(data)
    }
  }

  public handldeTaskCreate(data){
    this.disablePipelineOptions = true;
    var self = this
    data['stepType']='task'
    data['title'] = self.dataviewConfig.taskConfig[data['opname']]['label']
    data['dummy'] = true;
    let itemId = String(Object.keys(self.taskService.list).length + 1);
    data['itemId'] = itemId
    self.rf._order.push(itemId)
    self.rf._dep[itemId] = []
    self.itemsList.push(data)
    this.$timeout(function(){
      this.resetPipelineSteps(self.dataview.id, self.itemsList)
    }.bind(self),1500)
  }

  public updateList(fetch_reports=true){
    this.vueOriginalDataset = {...(this.dataview?.datasource || {})}

    let self = this;
    let finalList = [];

    if (self.taskService.list) {
      _.forEach(self.taskService.list, function (item) {
        item.stepType = 'task';
        item.firstTask = false;
        item.lastTask = false;
        // On every list update set reordered to false on the item so that any pending state of reordered true is cleared.
        item.latestDoneTask = false;
        finalList.push(item);
      });
    }
    function determineActionRerun(item) {
      if (item.error_info && !_.isEmpty(item.error_info)) {
        // Do not display re-run button if action is in error.
        item.enableRerun = false;
        return;
      }
      var wksp_data_updated_at = _.get(self.dataview, 'data_updated_at');
      wksp_data_updated_at = Date.parse(wksp_data_updated_at);
      var action_execution_time = _.get(item, 'last_modified_time');
      if (action_execution_time) {
        action_execution_time = Date.parse(action_execution_time);
        item.enableRerun = (wksp_data_updated_at > action_execution_time);
      } else {
        /*
         This is for backward compatibility.
         If last_run_time is not defined, show re-run button.
        */
        item.enableRerun = true;
      }
    }

    if (self.actionService.list) {
      _.forEach(self.actionService.list, function (item) {
        item.stepType = 'action';
        
        if (_.isEmpty(item.error_info)) {
          //convert {} to null 
          item.error_info = null;
        }
        // On every list update set reordered to false on the item so that any pending state of reordered true is cleared.
        determineActionRerun(item);
        finalList.push(item);
      });

    }

    finalList.sort(_sortStepItems);


    // mark first task: it will check which is the first item in the array, finalList, which is of type 'task'
    let firstIndexTask = finalList.map(item => item.stepType === 'task').indexOf(true);

    // mark last task: it will check which is the last item in the array, finalList, which is of type 'task'
    let lastIndexTask = finalList.map(item =>  item.stepType === 'task').lastIndexOf(true);

    if(firstIndexTask != -1) {
      finalList[firstIndexTask].firstTask = true;
    }
    if(firstIndexTask != -1) {
      finalList[lastIndexTask].lastTask = true;
    }

    //Mark latestDone task for the purpose of indicating the task whose data is reflected in the grid
    let tasks_with_status_done = finalList.map(item => item.stepType === 'task' && item.status == 'DONE');
    let indexOfLatestTaskInDoneState = tasks_with_status_done.lastIndexOf(true);
    if (indexOfLatestTaskInDoneState != -1) {
      finalList[indexOfLatestTaskInDoneState].latestDoneTask = true;
    }

    const Reports = this.resource(config.api.publish_reports);
    if (fetch_reports) {
      Reports.query({ dataview_id: this.dataview.id, project_id: this.$store?.state?.projectId }).$promise.then(function(reports) {
        // remove existing report, to avoid duplicate
        if (self.publishedReport) {
          finalList.splice(finalList.indexOf(self.publishedReport), 1)
        }

        if (reports.length != 0) {
          const report = reports[0]

          if (finalList.length > 0) {
            finalList[finalList.length - 1].lastTask = false;
          }

          const publishedReport = {
            stepType: 'report',
            firstTask: false,
            lastTask: true,
            latestDoneTask: false,
            opname: 'publish_report_prompt',
            handler_type: 'publish_report_prompt',
            target_properties: {},
            id: report.id,
            status: report.status,
            name: report.name,
            emailIds: report.users_emails,
            message: report.message,
            sync_type: report.sync_type
          };

          if (self.isRePublishLoading && publishedReport.status === 'ready') {
            self.isRePublishLoading = false;
            self.toastNotification.success('Republished successfully');
          }
          self.publishedReport = publishedReport;
          self.dataviewCtrl.isAlreadyPublished = !!publishedReport;
          finalList.push(publishedReport);
        } else {
          self.publishedReport = null;
          self.dataviewCtrl.isAlreadyPublished = false;
        }
      });
    }

    if (self.publishedReport) {
      if (finalList.length > 0) {
        finalList[finalList.length - 1].lastTask = false;
      }
      finalList.push(self.publishedReport);
    }
    let resetRequired = false
    if (self?.itemsList && !self.itemsList.find(a => a?.dummy) && self?.itemsList.length != finalList.length){
      resetRequired = true
    }
    self.itemsList =  finalList;
    // Fix for MVP-5754 by forcing angular to consider change in list being iterated in ng repeat
    // https://docs.angularjs.org/api/ng/directive/ngRepeat#tracking-and-duplicates
    self.itemsList = angular.copy(self.itemsList);
    self.pipelineChanges=0;
    // add a unique id to the items
    for(let item of self.itemsList){
      if(item.stepType == 'task'){
        item['itemId'] = String(item.sequence);
      }
      else if(item.stepType == 'action'){
        item['itemId'] = "action_" + item.id;
      }

      if(['added','deleted', 'edited', 'suspending'].indexOf(item.status) != -1){
        self.pipelineChanges += 1;
      }else{
        if(item.reordered){
          self.pipelineChanges += 1;
        }
      }
    }
    if(resetRequired){
      this.$timeout(function(){
        this.resetPipelineSteps(self.dataview.id, self.itemsList)
      }.bind(this), 500)

    }
    self.taskService.tasksActionsList = finalList;
    self.actionService.tasksActionsList = finalList;
    // Update the rf._order whenever the itemlist id updated.
    // Because when rf_.order is created in success callback of dependency update and it uses the itemList and sometimes itemList is stale
    // The reason why itemList is stale because tasks list updates and  action list updates come at different times
    let wksp = self.DataviewService.list[self.dataview.id];
    if (wksp.rf){
      wksp.rf._order.sort(function(a, b){
        let refObjL = self.itemsList[_.findKey(self.itemsList, {itemId: a})];
        let refObjR = self.itemsList[_.findKey(self.itemsList, {itemId: b})];
        return self.itemsList.indexOf(refObjL) - self.itemsList.indexOf(refObjR);
      });
      // Update whether reordering is pending or not in wksp when reordering is canceled or whenever itemlist is updated
      // Also re-evaluate auto run state so that its up to date
      wksp.rf.reorderPending = self.taskReorderPending()

    }

    // Remove dummy element from the dom upon list update
    var d = document.getElementsByClassName('dummy')
    if (d.length>0){
      d[0].remove()
    }
  }
  public closeAllPanles(){
    if (this.taskPanel.is_open){
      this.taskPanel.close();
    }
    if (this.actionPanel.is_open){
      this.actionPanel.close();
    }

  }

  public openReport(item) {
    this.$window.open('/#/publish/' + item.id, config.windowNames.report + item.id);
  }

  public publishContextMenu_onEdit(item) {
    item.showSharedEmails = false
    this.taskPanel.close();
    this.actionPanel.close();
    this.actionPanel.open('publish_report_prompt', { 'template': 'config.templates.publishReportPrompt' }, item);
  }

  public publishContextMenu_onRepublish(item) {
    const self = this;
    const Report = this.resource(config.api.publish_report);
    Report.save({ reportId: item.id }, null).$promise.then(function() {
      self.isRePublishLoading = true;
    });
  }

  public onRepublishIconClicked(item) {
    const self = this
    return function() {
      self.publishContextMenu_onRepublish(item)
    }
  }

  public publishContextMenu_onDelete(item) {
    this.taskPanel.close();
    this.actionPanel.close();
    const $q = this.q;
    const self = this;
    const TaskService = {
      delete_task: function() {
        var deleteSuccessPromise = $q.defer();
        deleteSuccessPromise.resolve();
        return deleteSuccessPromise.promise;
      }
    };
    this.modalService.deleteTask(item, TaskService).then(function() {
      const Report = self.resource(config.api.publish_report);
      Report.delete({ reportId: item.id }).$promise.then(function() {
        self.toastNotification.success("Report deleted");
        self.publishedReport = null
        self.itemsList = self.itemsList.filter(item => item.stepType != 'report')
        self.updateList()
      });
    });
  }

  public publishShowSharedEmails(item) {
    item.showSharedEmails = true
    this.taskPanel.close();
    this.actionPanel.close();
    this.actionPanel.open('publish_report_prompt', { 'template': 'config.templates.publishReportPrompt' }, item);
  }

  public togglePipelineDetails(){
    this.analyticsService.userEventTrack(this.c.userEvents.dataviewEvents.togglePipelineDetails,
      {
        eventOrigin: "dataview.pipeline"
      });
    this.hideDesc = !this.hideDesc
  }

  public runPipeline(ws_id, force_run=false){
   this.dataviewRerunService.rerun(ws_id, force_run);
  }

  public revertDraftChanges(){
    let self = this;
    self.autoRunLoader = true;
    // Set pipeline running property on dataview to true as soon as this function is called.
    // this will hide the apply/discard button from the UI
    self.dataview.is_pipeline_running = true;
    this.modalService.discardPipelineChanges(this.dataview, self.itemsList).then(function(data){
      self.dataviewCtrl.updateDataviewData(true, true).then(function (data) {
        // Reorder should always be enabled
        // self.dataview.rf.toggleReorder = data.draft_mode!='off'?true:false;
        self.dataview.rf.toggleReorder = true
        self.autoRunLoader=false;

      });
    }, function(){
      self.autoRunLoader=false;
      self.dataview.is_pipeline_running = false;
    })
  }

  public enableDraftMode(ws_id){
    let self = this
    var draft_mode_res = this.$resource(config.api.draftMode, {ws_id: '@ws_id'});
    draft_mode_res.save({ws_id: ws_id},{'draft_operation': 'enter'}).$promise.then(function(data){
    // self.toastNotification.success("Auto run disabled.");
    self.autoRunLoader = false;
    if (data.draft_mode){
      self.autoRunValue = false;
    }
    })
  }

  public exitDraftMode(ws_id){
    let self = this
    var draft_mode_res = this.$resource(config.api.draftMode, {ws_id: '@ws_id'});
    draft_mode_res.save({ws_id: ws_id},{'draft_operation': 'exit'}).$promise.then(function(data) {
      // self.toastNotification.success("Auto run enabled.");
      self.autoRunLoader = false;
      if (!data.draft_mode){
        self.autoRunValue == true
      }
    })
  }

  public applyDraftModeChanges(){
    let self = this;
    self.autoRunLoader = true;
    //Set pipeline running property on dataview to true as soon as this function is called.
    // this will hide the apply/discard button from the UI
    self.dataview.is_pipeline_running = true;
    this.modalService.applyPipelineChanges(this.dataview, self.itemsList).then(function(data){
      // Do not show toast notificationas (suggestion in UX-FB)
      // self.toastNotification.success("Running pipeline");
      self.autoRunLoader = false;
    }, function(){
      self.autoRunLoader = false;
      self.dataview.is_pipeline_running = false;
    })
  }
  public future_request_tracker(future_id) {
    var deferred = this.q.defer();
    this.FutureService.track(future_id, data_tracker);

    function data_tracker(future) {
      let response = future.response;
      if (future.status == "processing") {
        return;
      }
      if (future.status == "success") {
        deferred.resolve(response);
      } else {
        deferred.reject(response);
      }
    }
    return deferred.promise;
  }
  // this function does reordering - call reorder api with a new sequence list. Once done, closes reorder panel
  public reorder(ws_id, newOrder) {
    var requestCompletePromise = this.q.defer();
    let self = this;
    let wksp = self.DataviewService.list[ws_id]
    newOrder = self.rf._order;
    //When submitting tasks for reordering and sample mode is ON add the first item (which is the invisble limit rule) at the begining
    this.analyticsService.userEventTrack(this.c.userEvents.dataviewEvents.reorderTask,
      {
        eventOrigin: "dataview.pipeline"
      });
    if (wksp.in_preview_mode){
      newOrder = ["1"].concat(newOrder)
    }
    var steps_reordered = []
    _.forEach(self.itemsList, function(item){
      if(item.reordered == true){
        steps_reordered.push({'type': item.stepType, 'id': item.stepType=='task'?item.id:item.id})
      }
    })
    var patch = [{
      "op": "update",
      "path": "tasks",
      "value": {
        "reorder": {'param': {new_order: newOrder, steps_reordered: steps_reordered}}
      }
    }];

    var reorder_res = this.$resource(config.api.alltasks, {ws_id: '@ws_id'});
    self.reorderLoader = true;
    reorder_res.patch({ws_id: ws_id}, {'patch': patch}).$promise.then(function (data) {
      if(data.STATUS == 'SUCCESS'){
        if (data.information.status == 'done') {
          successReorder()
        }
        else if (data.information.status == 'error') {
          errorReorder(data)
        }
        else if (data.information.status == 'processing') {
          self.future_request_tracker(data.information.future_id).then(
            function () {
              successReorder()
            },
            function(){
              self.reorderLoader = false;
              let wksp = self.DataviewService.list[ws_id];
              self.cancelReorder(wksp);
            })
        }
      }
    }, function () {
      self.reorderLoader = false;
      let wksp = self.DataviewService.list[ws_id];
      self.cancelReorder(wksp);
    });

    function successReorder () {
      // Post steps reordering fetch and update task list and action list
      self.taskService.update_list(true);
      self.actionService.update_list();
      if (self.dataview.pipeline_autorun_enabled){
        self.toastNotification.success("Task reordering finished");
        // Toggle off the reordering when pipeline in auto run mode so that border warning can be removed post reordering success.
        self.postReorderChanges()
      }
      self.resetPipelineSteps(self.dataview.id, self.itemsList)
      requestCompletePromise.resolve()
    }

    function errorReorder(data) {
      self.toastNotification.error(data.information.error_info.error_message);
      let wksp = self.DataviewService.list[ws_id];
      // wksp.rf.toggleReorder = false;
      self.reorderLoader = false;
      self.cancelReorder(wksp,true, true, true);
      requestCompletePromise.resolve()
      self.postReorderChanges()
    }
    return requestCompletePromise.promise;
  };

  public postReorderChanges(){
    // Ensure following after reordering is done
    // hide the loader on reorder confirmation button,
    // remove the yellow border from the step
    this.reorderLoader = false;
    this.dataview.rf.reorderPending = false;
    _.forEach(this.itemsList, function(item){
      var el = document.getElementById(item.itemId)
      el.classList.remove('border-warning');
    })
  }
  /*
  cancel reordering provides options to:
  1: Reset reordering of but retain the Reorder Mode
  2: Reset reordering of tasks also exit the Reorder Mode

  retainReorderMode - if true, reorder panel will not close

  resetItemsList - if true, reordering of the tasks in the pipeline will be reset
   */
  public cancelReorder(ws, retainReorderMode=false, resetItemsList=true, force=false) {
    /* Do not unset the reorder status  when auto run is disabled
    Otherwise if two tasks are reordered in auto run off mode,
    on clicking apply changes and canceling at confirmation level will remove the reorder status of the tasks*/
    if(! ws.pipeline_autorun_enabled && !force){
      return
    }
    let self = this;
    self.reorderLoader = false;
    // Do nothing based on retainReorderMode for the new design as reorder mode will always be ON
    // if(retainReorderMode){
    //   ws.rf.toggleReorder = true;
    // }
    // else{
    //   ws.rf.toggleReorder = false;
    // }

    // order is the sequence of tasks. It is an array. For example [2,3,1,6,5,4]
    let order = ws.rf._order;
    if(order){
      order.sort(function(a, b){
        let refObjL = self.itemsList[_.findKey(self.itemsList, {itemId: a})];
        let refObjR = self.itemsList[_.findKey(self.itemsList, {itemId: b})];
        return self.itemsList.indexOf(refObjL) - self.itemsList.indexOf(refObjR);
      });
      ws.rf._order = order;
    }

    // Clear stale states of the reordered items marking all of them as reordered false when reordering is cancelled
    for(let item of self.itemsList){
      item.reordered=false;
    }
    // Reset pipeline steps to their actual positions
    self.resetPipelineSteps(self.dataview.id, self.itemsList)
    // Update whether reordering is pending or not in wksp when reordering is canceled or whenever itemlist is updated
    self.dataview.rf.reorderPending = self.taskReorderPending()
  };

  public resetPipelineSteps(ws_id, itemsList){
    // reset the pipeline steps based in ui based on their order in itemslist
    let className = 'step-of-'+ws_id;

    // Remove steps which dont have id
    let k: any = document.getElementsByClassName(className)
    let z = [...k].forEach(function(val){
      let refObjL = itemsList[_.findKey(itemsList, {itemId: val.attributes?.id?.value})];
      if (!refObjL){
        val.remove()
      }
    })

    // Remove duplicate cards
    let e: any = document.getElementsByClassName(className)
    let v= []
    let h = [...e].forEach((a) => {
      let b=-1
      b = v.indexOf(a.id)
      if(b!=-1){
        a.remove()
      }
      v.push(a.id)
    })

    // Show steps in order
    let d: any = document.getElementsByClassName(className)
    let p =  [...d].sort(function(a,b){
      let refObjL = itemsList[_.findKey(itemsList, {itemId: a.attributes?.id?.value})];
      let refObjR = itemsList[_.findKey(itemsList, {itemId: b.attributes?.id?.value})];
      return itemsList.indexOf(refObjL) - itemsList.indexOf(refObjR);
    }.bind(itemsList)).forEach( function(val){
      val.parentNode.appendChild(val)
    } )

    // remove border warning from the pipeline steps
    let x = [...d].forEach(function(val){
      let stepObj = itemsList[_.findKey(itemsList, {itemId: val.attributes?.id?.value})];
      // ensure that class for border warning is removed only when task is executed or suspended
      // in other scenarios its required to be present
      if (stepObj.status=='executed' || stepObj.status=='suspended'){
        val.classList.remove('border-warning');
      }
    }.bind(itemsList))


  }
  // checks if tasks are pending to get reordered
  public taskReorderPending() {
    let self = this;
    let flag = false;
    if(self.rf && self.rf._order) {
      let sortedItemsList = JSON.parse(JSON.stringify(self.rf._order));
      sortedItemsList.sort(function(a, b){
        let refObjL = self.itemsList[_.findKey(self.itemsList, {itemId: a})];
        let refObjR = self.itemsList[_.findKey(self.itemsList, {itemId: b})];
        return self.itemsList.indexOf(refObjL) - self.itemsList.indexOf(refObjR);
      });
      flag = JSON.stringify(self.rf._order) != JSON.stringify(sortedItemsList);
    }
    return flag;
  };

  public toggleAutoRun(wkspId){
    if (this.autoRunLoader){
      return
    }
    this.autoRunValue = !this.autoRunValue;
    this.autoRunLoader = true;
    if (this.autoRunValue == false){
      this.enableDraftMode(wkspId);

    }
    if(this.autoRunValue == true){
      this.exitDraftMode(wkspId);
    }

  }

  public openStepsMenu(event, item){
    if (event.which == this.c.eventCodeMap.rightClick){
      //On right click
      this.taskPanel.checkForPasteableItem()
      if (item.stepType == 'task'){
        item.isStepsMenuOpen = true;
      }else if(item.stepType=='action'){
        item.isActionMenuOpen = true;
      }
      item.clicked = !item.clicked
      var menuType = item.stepType == 'task'? 'taskMenu': 'actionMenu'
      var ele: any = document.getElementById(menuType+'-'+item.ws_id +'-'+item.itemId)
      this.$timeout(function(){
        ele.style.left = event.pageX.toString() + 'px'
        ele.style.top = event.pageY.toString() + 'px'
      }, 10)
      event.stopImmediatePropagation();
    }
  }

  public openPipelineMenu(event){
    if (event.which == this.c.eventCodeMap.rightClick){
    this.isPipelineMenuOpen = !this.isPipelineMenuOpen;
    var ele: any = document.getElementById('pipelineCustomMenu-'+ this.dataview.id.toString())
    this.$timeout(function(){
      ele.style.left = event.pageX.toString() + 'px'
      ele.style.top = event.pageY.toString() + 'px'
    }, 10)
    }
  }
  public openOriginalDsMenu(event){
    if (event.which==3){
      this.isOriginalDsMenuOpen = !this.isOriginalDsMenuOpen;
      var ele: any = document.getElementById('originalDsMenu-'+ this.dataview.id.toString())
      this.$timeout(function(){
        ele.style.left = event.pageX.toString() + 'px'
        ele.style.top = event.pageY.toString() + 'px'
      }, 10)
      event.stopImmediatePropagation();
    }
  }
  public toggleDataSync(task) {
    let self = this
    task.data_pass_through = !task.data_pass_through
    const dataSyncAPI = this.$resource(config.api.resourceDependencies);
    let stepType = task.stepType == 'action'? 'action': 'task'
    dataSyncAPI.patch([{ context_id: task.id, context_type: stepType, data_pass_through: task.data_pass_through }]).$promise.then(function (data) {
      self.future_request_tracker(data.future_id).then(function (data) {
        this.toastNotification.success('Data sync status has been successfully updated.')
        if (stepType == 'action'){
          this.actionService.update_list()
        }
      }.bind(this), function (data) {
        this.toastNotification.error("Failed to update Data sync setting")
        task.data_pass_through = !task.data_pass_through
      }.bind(this))
    }.bind(this), function (data) {
      task.data_pass_through = !task.data_pass_through
      this.toastNotification.error("Failed to update Data sync setting")
    }.bind(this))

  }
  public toggleDataPreview(sequence){
    if(sequence==0 && this.taskService.orderedList.length==0){
        return
    }
    const sequence_before_toggle  = this.dataview.stepPreview.sequence
    this.historyDataViewPanel.toggle(sequence);

    if (sequence == sequence_before_toggle){
      // Upon exiting preview mode turn on the reordering mode
      this.dataview.rf.toggleReorder=true;
    }else{
      // Upon entering preview mode turn off the reordering mode
      this.dataview.rf.toggleReorder=false;
    }
  }

  public paste(sequence?, position?){
    this.clipboardService.get().then(successcallback.bind(this))
    function successcallback(data){
      if (data?.stepType=='task'){
        this.taskPanel.paste(data, sequence, position)
      }
      else {
        if (data?.stepType=='action'){
          if (['postgres', 'mssql', 'bigquery', 'mysql', 'redshift', 'elasticsearch', 'sftp'].includes(data.handler_type)) {
            this.actionPanel.actionPanelPasteEvent.fire_event(data)
          } else {
            this.actionPanel.paste(data, sequence)
          }
        }
      }
    }
  }

  public clearClipboard(){
    this.clipboardService.clear().then(successCallback.bind(this))
    function successCallback(){
    this.toastNotification.success("Clipboard cleared")
    }
  }
}

function _sortStepItems(a, b){
  if (a.sequence === b.sequence) {
    if (a.stepType == 'task' && b.stepType == 'action') {
      return -1;
    }
    else if (b.stepType == 'task' && a.stepType == 'action') {
      return 1;
    }
    else{
      return a.id - b.id;
    }
  }
  else{
    if(a.sequence === null){
      return 1;
    }
    else if(b.sequence === null){
      return -1;
    }
    else if(a.stepType == b.stepType && a.stepType == 'action'){
      return a.sequence - b.sequence;
    }
    else if(a.stepType == b.stepType && a.stepType == 'task'){
      return a.sequence - b.sequence;
    }
    else{
      return a.sequence - b.sequence;
    }
  }
}
