import * as humanize from 'humanize';
import * as moment from 'moment';

export var SERVER_FORMAT = 'YYYY-M-D H:m:s';
export var momentFormat = 'YYYY-MM-DD HH:mm:ss';
export var exampleFormat = 'MMM Do (ddd) h:mm a';

export var startTimeEndTimeMinimumPadding = 5; //in minutes
export function getRange(count, start, step) {
  if (step === undefined || step < 1) {
    step = 1;
  }
  if (start === undefined) {
    start = 0;
  }
  var sum = start;
  var arrLength = count / step | 0;
  var range = new Array(arrLength);
  for (var idx = 0; idx < arrLength; idx++) {
    range[idx] = sum;
    sum += step;
  }
  return range;
}

export function generateFrequencyOption(display, internal, allowed?) {
  return {
    display: display,
    internal: internal,
    allowed: allowed
  };
}

export function isInstance(obj, types) {
  if (['String', 'Array'].indexOf(types.constructor.name) === -1) {
    console.error("types should either a string or an array of strings. Found: ", types.constructor.name);
    return false;
  }
  if (typeof types === 'string') {
    types = [types];
  }
  return types.indexOf(obj.constructor.name) !== -1
}


export var monthDays = getRange(31, 1, 1).map(function (day) {
  return {
    key: day,
    value: humanize.ordinal(day)
  };
});


export var minutes = getRange(60, 0, 1).map(function (minutes) {
  return {
    key: minutes,
    value: humanize.ordinal(minutes)
  };
});


export var minutely = [].concat(getRange(55, 5, 5)).map(function (minutes) {
  return {
    key: minutes,
    value: minutes + (minutes > 1 ? ' minutes' : ' minute')
  };
});

export var hours = getRange(23, 1, 1).map(function (hour) {
  return {
    key: hour,
    value: hour + (hour > 1 ? ' hrs' : ' hour')
  };
});

export var days = getRange(6, 1, 1).map(function (day) {
  return {
    key: day,
    value: day + (day > 1 ? ' days' : ' day')
  };
});


