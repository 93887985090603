'use strict'

import * as Vue from 'vue'
import axios from 'axios'
import { API_URL } from '../core/coreConstants.js' // should be process.env
import store from '../plugins/store'
import keyCloak from "../../mm-frontend/src/plugins/keycloak.js"
/**0
 * @description Creating axios for a Vue instance
 * @Comment vue config for axios
 * @source https://github.com/axios/axios#request-config
 */

const config = {
  baseURL: API_URL || process.env.baseURL || process.env.apiUrl || '',
  withCredentials: true,
}

const _axios = axios.create(config)

//Setting up headers for api calls
let workspaceId = store.state?.workspaceId
if (workspaceId == -1) workspaceId = 1
_axios.defaults.headers['X-WORKSPACE-ID'] = workspaceId

export function setCommonHeader(header_key, header_value) {
  _axios.defaults.headers[header_key] = header_value
}

// Add a REQUEST interceptor
_axios.interceptors.request.use(
  async function (config) {
    await keyCloak.updateToken(10)
    config.headers['Authorization'] = 'bearer ' + keyCloak.token
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// Add a RESPONSE interceptor
_axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

_axios.install = function (Vue, options) {
  Object.defineProperties(Vue.prototype, {
    $axios: {
      get() {
        return _axios
      },
    },
  })
}

Vue.use(_axios)

export default _axios
