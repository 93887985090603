/**
 * Created by mammoth2 on 16/12/15.
 */
import * as angular from 'angular';
import * as _ from 'lodash-es';
/**
 * @ngInject
 */
actionController.$inject = ['$stateParams', 'DataviewService', 'ActionServiceFactory', 'TaskServiceFactory', 'dataviewConfig','config',
                        '$injector', '$timeout', 'resources', 'toastNotification', '$log', 'utils', '$q', 'c', 'analyticsService',
                        '$window', '$sce', 'MappedForm', 'deploymentConsts','Notification',
                         'Singularity', 'modalService', 'ColumnRenamePanelFactory', 'localService', '$scope', '$resource', 'UserWorkspace', 'VuexStore'];
export function actionController($stateParams, DataviewService, ActionServiceFactory, TaskServiceFactory, dataviewConfig,
                                 config, $injector, $timeout, resources, toastNotification, $log, utils, $q, c,
                                 analyticsService, $window, $sce, MappedForm, deploymentConsts,
                                 Notification, Singularity, modalService, ColumnRenamePanelFactory, localService,
                                 $scope, $resource, UserWorkspace, $store) {
  this.$onInit = function () {

    var avm = this;
    avm.config = config;
    avm.create = create;
    avm.setIdentities = setIdentities;
    avm.validationSuccess = false;
    avm.edit = edit;
    avm.submit = submit;
    avm.submitPublish = submitPublish;
    avm.noLongCols = noLongCols;
    avm.destroy = destroy;
    avm.checkValidity = checkValidity;
    avm.resetValidate = resetValidate;
    avm.serversideValidate = serversideValidate;
    var dataview_id = $stateParams.dataviewId;
    var ActionService;
    avm.dataview = DataviewService.get_by_id(dataview_id);
    avm.initController = _initController;
    avm.warningMessage = "";
    avm.showIdentityForm = false;
    avm.useFormat = false;
    avm.includeHidden = false;
    avm.atEndOfPipeline = true;
    avm.actionExistsInAppHelp = false;
    avm.enableWarning = false;
    avm.checkIfActionExistsInAppHelp = _checkIfActionExistsInAppHelp;
    avm.openDocumentationInNewTab = openDocumentationInNewTab;
    avm.action_display_name = undefined;
    avm.isLoading = true;
    avm.selectIntegration = selectIntegration;
    avm.selectIdentity = selectIdentity;
    avm.selectProfile = selectProfile;
    avm.deleteIdentity = deleteIdentity;
    avm.onCancel = onCancel;
    avm.editIdentity = false;
    avm.toEditIdentity = new Object();
    avm.editIdentityHandler = editIdentityHandler;
    avm.isEdit = false;
    avm.editing_action = undefined;
    avm.columnRenamePanel = ColumnRenamePanelFactory.get(avm.workspace);
    avm.dataviewService = DataviewService;
    avm.columnRenamePanel = ColumnRenamePanelFactory.get(avm.dataview);
    avm.dataviewConfig = dataviewConfig;
    init();
    avm.hideHiddenColOption = avm.dataview.display_properties.HIDDEN_COLUMNS ?
      !avm.dataview.display_properties.HIDDEN_COLUMNS.length : true;
    avm.updateHiddenColumnStatus = updateHiddenColumnStatus;
    avm.getAndSetIdentity = getAndSetIdentity;
    avm.reason = null;
    avm.file = null;

    avm.currDataview = DataviewService.list[avm.dataview.id]
    avm.defaultReportName = "Report of " + avm.currDataview.datasource.name + " - " + avm.currDataview.name
    avm.publishPrompt = {
      config: {
        edit: false,
        reportName: avm.defaultReportName,
        showSharedEmails: false,
        sharedEmails: [],
        autoSyncOnChange: false
      },
      validEmailIds: UserWorkspace.users.map(e => e.email),
      onCancel: function () {
        avm.action_panel.close()
        avm.publishPrompt.clearFields()
      },
      onChange: function (data) {
        const publishConfig = avm.publishPrompt.config
        const ReportsResource = $resource(config.api.publish_reports);
        const ReportResource = $resource(config.api.publish_report);

        const successHandler = message => () => {
          ActionService.publishReportUpdateEvent.fire_event();
          toastNotification.success(message)
        }
        const errorHandler = (error) => toastNotification.error(error.data.ERROR_MESSAGE)

        const createReport = payload =>
          ReportsResource.save(payload)
            .$promise
            .then(successHandler('Published successfully'))
            .catch(errorHandler);

        const updateReport = patch =>
          ReportResource.patch({reportId: avm.editing_action.id}, {patch})
            .$promise
            .then(successHandler("Report updated successfully"))
            .catch(errorHandler);

      if (!publishConfig.edit) { // create report request
        createReport({
          name: data.reportName,
          dataview_id: avm.dataview.id,
          emails: data.newEmails,
          message: data.notifyPeople ? data.message : "",
          sync_type: data.autoSyncOnChange ? "AUTO" : "MANUAL",
          project_id: $store.state.projectId
        })
      } else {
        const patch = {
          name: data.reportName,
          emails: [...data.sharedEmails, ...data.newEmails],
          message: data.notifyPeople ? data.message : "",
          sync_type: data.autoSyncOnChange ? "AUTO" : "MANUAL"
        }
        updateReport(Object.keys(patch).map(path => ({ op: 'replace', path: path, value: patch[path] })))
      }
      
      avm.action_panel.close()
      avm.publishPrompt.clearFields()
    },
    clearFields() {
      const publishConfig = avm.publishPrompt.config
      publishConfig.edit = false
      publishConfig.reportName = ''
      publishConfig.showSharedEmails = false
      publishConfig.sharedEmails = []
    }
  }

    function updateHiddenColumnStatus(status) {
      DataviewService.fire_on_update_metadata_hiddenColumns(status);
    }

    avm.connectionVariant = [
      {"val": "http", "disp": "HTTP"},
      {"val": "https", "disp": "HTTPS"}
    ];

    avm.IncludeHiddenOptions = [
      {'val': true, 'disp': 'Include hidden columns'},
      {'val': false, 'disp': 'Exclude hidden columns'}
    ];

    var initPromise = $q.defer();

    function create(action_name, createParams) {
      avm.action_display_name = dataviewConfig.actionConfig[action_name]["label"]
      if (dataviewConfig.actionsWithoutForm.includes(action_name)) {
        avm.action_panel.submit_in_progress = true;
      }
      if (action_name == c.actions.bigquery) {
        avm.hideHiddenColOption = true;
        avm.includeHidden = true;
      }
      avm.action_panel.serversideValidationError = {};
      // ViewModel
      ActionService = ActionServiceFactory.get_by_dataview_id(dataview_id);
      let TaskService = TaskServiceFactory.get_by_dataview_id(dataview_id);
      avm.dataview.get_data().then(_getDataCb);

      var actionExists = this.checkIfActionExistsInAppHelp(action_name);
      if (actionExists == true) {
        avm.actionExistsInAppHelp = true;
      }
      avm.atEndOfPipeline = createParams?.sequence == null ? true : false
      let currentSequenceNumber = _.values(TaskService.list).length;

      // get the action service needed
      function _getDataCb() {
        let pipelineResource = $resource(config.api.pipelineInfo);
        pipelineResource.get({ws_id: dataview_id}).$promise.then(function (response) {
          avm.dataview.taskwise_info = response['taskwise_info']
          var manager_factory = $injector.get(dataviewConfig.get_manager_factory_from_action_name(action_name));
          var globalFilter = undefined;
          if (avm.dataview.display_properties.GLOBAL_DISPLAY_CONDITION) {
            globalFilter = _.cloneDeep(avm.dataview.display_properties.GLOBAL_DISPLAY_CONDITION);
          }
          var task_sequences = Object.keys(avm.dataview.taskwise_info)
          var metadata = _.cloneDeep(utils.metadata.getMetadata(avm.dataview.taskwise_info, task_sequences.length - 1));
          metadata = utils.metadata.applyDisplayChangesReturnMetadata(metadata, avm.dataview.display_properties, [], {}, false);
          var displayNameAndTypeToColumnMap = utils.metadata.getDisplayNameAndTypeToColumnMap(metadata);
          avm.manager = manager_factory.get_manager(
            {
              metadata: utils.metadata.add_type_to_display_name(metadata),
              actionUtils: actionUtils(),
              globalFilter: globalFilter,
              triggerType: "pipeline",
              context: {
                dataview: avm.dataview,
                inEditMode: false,
                currentSequenceNumber: currentSequenceNumber,
                defaultParams: dataviewConfig.actionConfig[avm.action_panel.action_name].defaultParams
              },
              displayNameAndTypeToColumnMap: displayNameAndTypeToColumnMap
            }
          );
          if (dataviewConfig.actionConfig[avm.action_panel.action_name]["is_auth"]) {
            avm.setIdentities(avm.action_panel.action_name);
          }
          if (createParams !== undefined) {
            if (action_name == 'internal_dataset' || action_name == 'pivot_table') {
              createParams = avm.manager.handlePasteParams(createParams);
            }
            avm.manager.setParam(createParams);
          }
          initPromise.resolve(avm.manager);
          if (dataviewConfig.actionsWithoutForm.includes(action_name)) {
            submit();
          }
        })

      }
    }

    function setIdentities(actionName) {
      avm.showLoader = true;
      let identitiesListPromise = Singularity.getIdentities(actionName, true).then(function (identities) {
        avm.manager.setIdentities(identities);
        avm.showLoader = false;
        avm.isLoading = false;
      }, error_cb);

      let identitySpecPromise = Singularity.getAddIdentityConfig(avm.action_panel.action_name, true).then(function (spec: any) {
        avm.showLoader = true;
        avm.addIdentitySpec = spec;
        if (
          spec.type === "API_KEY" ||
          spec.type === "PASSWORD" ||
          spec.type === "OAUTH2" ||
          spec.type === "CUSTOM_OAUTH2"
        ) {
          avm.showLoader = true;
          avm.addIdentityForm = new MappedForm(spec, avm);
        }
      }, error_cb);

      Promise.all([identitiesListPromise, identitySpecPromise]).then(function () {
        avm.showLoader = false;
      }, function () {
        avm.showIdentityForm = false;
        avm.showLoader = false;
      });

      function error_cb(data) {
        avm.showLoader = false;
        avm.isLoading = false
        avm.manager.connection_timeout = true;
      }

      initPromise.resolve(avm.manager);
    }

    function _checkIfActionExistsInAppHelp(actionName) {
      return !!(actionName in c.appHelp.tasks);
    }

    function openDocumentationInNewTab(actionName) {
      $window.open(c.appHelpRoot + c.appHelp.tasks[actionName], '_blank');
    }

    function edit(editing_action) {
      // ViewModel
      avm.editing_action = editing_action;
      avm.dataview = DataviewService.get_by_id(dataview_id);
      ActionService = ActionServiceFactory.get_by_dataview_id(dataview_id);
      let TaskService = TaskServiceFactory.get_by_dataview_id(dataview_id);
      // get the action service needed
      var action_name = editing_action.handler_type;
      avm.isEdit = true;

      //update "Learn More" button
      var actionExists = this.checkIfActionExistsInAppHelp(action_name);
      if (actionExists == true) {
        avm.actionExistsInAppHelp = true;
      }
      let pipelineResource = $resource(config.api.pipelineInfo);
      pipelineResource.get({ws_id: dataview_id}).$promise.then(function (response) {
        var manager_factory = $injector.get(dataviewConfig.get_manager_factory_from_action_name(action_name));
        avm.dataview.taskwise_info = response['taskwise_info']
        // let actionMetadata = TaskService.getResultMetadataBySequence(editing_action.sequence);
        var sequence = editing_action.sequence;
        var task_sequences = Object.keys(avm.dataview.taskwise_info)
        if (sequence || sequence == 0) {
          var actionMetadata = _.cloneDeep(utils.metadata.getMetadata(avm.dataview.taskwise_info, sequence))
        } else {
          var actionMetadata = _.cloneDeep(utils.metadata.getMetadata(avm.dataview.taskwise_info, task_sequences.length - 1));
        }
        actionMetadata = utils.metadata.add_type_to_display_name(actionMetadata);
        actionMetadata = utils.metadata.applyDisplayChangesReturnMetadata(actionMetadata, avm.dataview.display_properties, [], {}, false);
        //todo: get the actionMetadata at the right phase

        let pMetadata = utils.metadata.applyDisplayChangesReturnMetadata(actionMetadata, avm.dataview.display_properties, [], {}, false);
        pMetadata = utils.metadata.add_type_to_display_name(pMetadata);
        /**Add error columns to the previous metadata so that they can be shown in the function panel*/
        if (editing_action.error_info && _.get(editing_action.error_info, 'additional_info.reference_errors')) {
          pMetadata = utils.metadata.add_error_columns_to_metadata(pMetadata, editing_action.error_info.additional_info.reference_errors, avm.dataview.id);
        }

        avm.manager = manager_factory.get_manager(
          {
            metadata: utils.metadata.add_type_to_display_name(pMetadata),
            actionUtils: actionUtils(),
            context: {
              action: editing_action,
              dataview: avm.dataview,
              inEditMode: true
            }
          }
        );
        avm.useFormat = editing_action.target_properties.use_format;
        if (action_name == c.actions.bigquery) {
          avm.hideHiddenColOption = true;
          avm.includeHidden = true;
        } else {
          avm.includeHidden = editing_action.target_properties.include_hidden;
        }
        //action sequence must be null and not zero to consider action to be kept at end of pipeline
        avm.atEndOfPipeline = editing_action.sequence == null ? true : false
        avm.manager.setParam(editing_action);
        initPromise.resolve(avm.manager);
        if (dataviewConfig.actionConfig[avm.action_panel.action_name]["is_auth"]) {
          avm.setIdentities(action_name);
        }
        if (editing_action.opname === "publish_report_prompt") {
          const publishConfig = avm.publishPrompt.config
          publishConfig.edit = true
          publishConfig.reportName = editing_action.name
          publishConfig.showSharedEmails = editing_action.showSharedEmails
          publishConfig.sharedEmails = editing_action.emailIds
          publishConfig.autoSyncOnChange = editing_action.sync_type === "AUTO"
        }
      })

    }


    function checkValidity() {
      if (avm.actionPanelForm.$dirty && avm.validationSuccess) {
        avm.validationSuccess = false;
      }
    }

    function resetValidate() {
      avm.actionPanelForm.$setDirty();
      avm.checkValidity();
      avm.selectProfile(avm.manager.param.target_properties.selected_identity, avm.manager.param.target_properties.selected_profile)
    }

    function serversideValidate() {
      if (!avm.manager.validate() || avm.action_panel.validate_in_progress) {
        return;
      }
      avm.action_panel.validate_in_progress = true;
      avm.action_panel.serversideValidationError = {};
      var param = avm.manager.getParam();
      var actionsAllowedForEndOfPipeline = getListOfActionsForEndOfPipeline();
      let TaskService = TaskServiceFactory.get_by_dataview_id(dataview_id);
      // Setting sequence as null keeps action at end of the pipeline, this option is available for a selected list of actions
      if (avm.atEndOfPipeline && actionsAllowedForEndOfPipeline.indexOf(avm.manager.param.handler_type) != -1) {
        param.sequence = null;
      } else if (!avm.atEndOfPipeline && actionsAllowedForEndOfPipeline.indexOf(avm.manager.param.handler_type) != -1 && param.sequence == null) {
        if (!avm.action_panel.editing_action || param.sequence == null) {
          param.sequence = _.values(TaskService.list).length;
        }
      }
      param.target_properties.include_hidden = avm.includeHidden;
      param['DATAVIEW_ID'] = parseInt($stateParams.dataviewId);
      ActionService.serverside_validate(param).then(validation_success, validation_failure);

      function validation_success(data) {
        if (data.hasOwnProperty("warning")) {
          avm.warningMessage = data.warning;
          avm.enableWarning = true;
        } else {
          avm.warningMessage = "";
          avm.enableWarning = false;
        }
        avm.actionPanelForm.$setPristine();
        avm.action_panel.validate_in_progress = false;
        avm.validationSuccess = true;
        if (dataviewConfig.actionConfig[avm.action_panel.action_name]["is_auth"]) {
          avm.manager.serversideValidationRequired = false;
          avm.dataviewService['is_dataview_updated'] = false;
        }
      }

      function validation_failure(data) {
        avm.actionPanelForm.$setDirty();
        if (!data.hasOwnProperty("error") || !data.hasOwnProperty("message")) {
          avm.action_panel.serversideValidationError['message'] = "Could not validate, please retry after sometime";
          avm.action_panel.serversideValidationError['error'] = undefined;
        } else {
          avm.action_panel.serversideValidationError['message'] = data.message;
          var error_with_url = utils.linkify(data.error);
          $sce.trustAsHtml(error_with_url);
          avm.action_panel.serversideValidationError['error'] = error_with_url;
          if (!dataviewConfig.actionConfig[avm.action_panel.action_name]["is_auth"]) {
            let error = "";
            if (data.error) {
              error = data.error;
            }
            toastNotification.error(data.message + ": " + error);
          }
        }
        avm.action_panel.validate_in_progress = false;
      }
    }

    function noLongCols(actionName) {
      // returns true if there are no long cols otherwise false
      if (!dataviewConfig.actionsNoLongCols.includes(actionName)) {
        return true;
      }
      var flag = true;
      var metadata = avm.dataview.metadata;
      for (let i in metadata) {
        if (metadata[i].display_name.length > 63) {
          flag = false;
          break;
        }
      }
      return flag;
    }

    function getListOfActionsForEndOfPipeline() {
      return [c.actions.mysql, c.actions.postgres, c.actions.redshift, c.actions.elasticsearch, c.actions.mssql,
        c.actions.internal_ds, c.actions.bigquery, c.actions.sftp]
    }

    function submit() {
      if (!avm.manager.validate()) {
        return;
      }
      if (avm.warningMessage.length > 0) {
        avm.warningMessage = "";
      }
      avm.action_panel.submit_in_progress = true;
      var param = avm.manager.getParam();
      param['DATAVIEW_ID'] = parseInt($stateParams.dataviewId);
      if (!param.target_properties) {
        param.target_properties = {}
      }
      // Override the export format option for every action except s3, since s3 export is always as seen
      if (!param.target_properties.is_format_set) {
        // is_format_set is defined at s3upload.manager.ts
        // where it also sets use_format = true

        param.target_properties.use_format = avm.useFormat;
      }
      param.target_properties.include_hidden = avm.includeHidden;
      let TaskService = TaskServiceFactory.get_by_dataview_id(dataview_id);
      // Setting sequence as null keeps action at end of the pipeline, this option is available for a selected list of actions
      var actionsAllowedForEndOfPipeline = getListOfActionsForEndOfPipeline();
      if (avm.atEndOfPipeline && actionsAllowedForEndOfPipeline.indexOf(avm.manager.param.handler_type) != -1) {
        param.sequence = null;
      } else if (!avm.atEndOfPipeline && actionsAllowedForEndOfPipeline.indexOf(avm.manager.param.handler_type) != -1 && param.sequence == null) {
        if (!avm.action_panel.editing_action || param.sequence == null) {
          param.sequence = _.values(TaskService.list).length;
        }
      }
      let panelOpenId = avm.action_panel.openId;
      if (!avm.action_panel.editing_action) {
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.actionMenuEvents.addAction, {eventOrigin: 'dataview'}, {taskName: dataviewConfig.actionConfig[avm.action_panel.action_name]?.label});
        ActionService.add_action(param).then(submission_success, submission_failure);
      } else {
        analyticsService.userEventTrack(c.userEvents.dataviewEvents.actionMenuEvents.editAction, {eventOrigin: 'dataview'});
        ActionService.edit_action(avm.action_panel.editing_action, param).then(submission_success, submission_failure);
      }

      function submission_success(data) {
        if (param.additional_properties) {
          if (param.additional_properties.no_notification && avm.dataview.pipeline_autorun_enabled) {
            toastNotification.success(param.additional_properties.completionMessage);
          }
        }
        resources.update();
        if (dataviewConfig.actionConfig[avm.action_panel.action_name]["is_auth"]) {
          avm.manager.reset();
        }

        ActionService.update_list().then(successActionUpdateList);

        function successActionUpdateList() {
          if (avm.dataview.id) {
            DataviewService.fire_on_update_tasks_actions_dependencies(true, avm.dataview.id);
          }
        }

        avm.action_panel.submit_in_progress = false;
        if (data?.param?.additional_properties?.liveLink) {
          ActionService.liveCsvLink = 'Pending'
        }
        avm.action_panel.animateTaskToggle = true;
        $timeout(function () {
          avm.action_panel.animateTaskToggle = false;
          ActionService.update_list();
        }, 3000);
        $timeout(ActionService.update_list, 10000);
        $timeout(ActionService.update_list, 20000);
        $timeout(function () {
          avm.action_panel.waiting_for_completion = true;
        }, 0);
        if (panelOpenId == avm.action_panel.openId) {
          avm.action_panel.close();
        }
        if (!avm.action_panel.editing_action) {
          avm.action_panel.actionAddedEvent.fire_event();
        }
      }

      function submission_failure(data) {
        avm.action_panel.submit_in_progress = false;
        var message = 'The action could not be submitted. ';
        if (data.data && data.data.ERROR_MESSAGE) {
          message = data.data.ERROR_MESSAGE;
        } else if (data.information && data.information.error) {
          if (data.information.error.hasOwnProperty('message')) {
            avm.action_panel.serversideValidationError = data.information.error.message;
            message += data.information.error.message;
          } else {
            avm.action_panel.serversideValidationError = data.information.error;
            message += data.information.error;
          }
        } else if (data.ERROR_CODE && c.errorCodetoMessageMap.hasOwnProperty(data.ERROR_CODE)) {
          let additional_message = c.errorCodetoMessageMap[data.ERROR_CODE]
          message += additional_message;
        } else if (data.error_info?.ERROR_CODE) {
          let error_message = c.errorCodetoMessageMap[data.error_info.ERROR_CODE]
          if (data.additional_info?.error_code) {
            if (data.additional_info.error_code == c.pipeline_error_codes.reference_error && data.additional_info?.reference_errors) {
              let missing_column_names = [], type_mismatch_column_names = []
              data.additional_info?.reference_errors.map(colErrorInfo => {
                if (colErrorInfo.error_code == c.pipeline_error_codes.column_missing) missing_column_names.push(colErrorInfo.column.display_name)
                if (colErrorInfo.error_code == c.pipeline_error_codes.column_type_mismatch) type_mismatch_column_names.push(colErrorInfo.column_name)
              })
              if (missing_column_names.length) error_message = error_message + ' ' + c.column_missing_template(missing_column_names.join(", "), missing_column_names.length > 1)
              if (type_mismatch_column_names.length) error_message = error_message + ' ' + c.column_type_mismatch_template(type_mismatch_column_names.join(", "), type_mismatch_column_names.length > 1)
            }
            message = error_message
          }

        } else {
          message += ' Please try again or contact support for more information';
        }
        // Adding toast notification to display message for api level failure
        toastNotification.error(message);
        ActionService.update_list();
      }
    }

    function submitPublish() {
      if (!avm.manager.validate()) {
        return;
      }
      avm.action_panel.submit_in_progress = true;
      var param = avm.manager.getParam();
      ActionService.add_publish(param, avm.action_panel).then(submission_success, submission_failure);

      function submission_success() {
        // Fire event to open the steps panel
        avm.action_panel.actionAddedEvent.fire_event();
      }

      function submission_failure() {
      }

    }

    function destroy() {
      $timeout(function () {
        var tUtils = actionUtils();
        tUtils.highlight.sources(undefined, true);
        tUtils.highlight.destinations(undefined, true);
        tUtils.highlight.deletions(undefined, true);
        tUtils.highlight.renderAll();
      }, 0);
    }

    function actionUtils() {
      return {
        highlight: {
          sources: function (sourceColumns, doNotRender) {
            highlightItems('source-highlight', sourceColumns, doNotRender);
          },
          destinations: function (destinationColumns, doNotRender) {
            highlightItems('destination-highlight', destinationColumns, doNotRender);
          },
          deletions: function (destinationColumns, doNotRender) {
            highlightItems('deletions-highlight', destinationColumns, doNotRender);
          },
          renderAll: function () {
            avm.mainGridUnit.gridInstance.plugins.columnHighlighter.renderAll();
          }
        }
      };

      function highlightItems(itemClass, colDefs, doNotRender) {
        var internalNames = utils.metadata.getListOfIntNamesFromListOfCols(colDefs);
        avm.mainGridUnit.gridInstance.plugins.columnHighlighter.highlightMultipleColumns(itemClass, internalNames, doNotRender);
        if (internalNames && internalNames.length) {
          avm.mainGridUnit.gridInstance.plugins.scrollToColumn(internalNames.pop());
        }
      }
    }

    function selectIntegration(integration, identity_key) {
      avm.showLoader = true;
      avm.newConnectionInfo = '';
      reset();
      avm.selectedIntegration = integration;
      avm.showLoader = false;
    }

    function init() {
      avm.selectedIntegration = null;
      avm.newConnectionInfo = '';
      reset();
    }

    function reset() {
      avm.selectedExport = null;
      avm.warningMessage = "";
      avm.saveIdentityResponseFailureMsg = null;
      avm.saveIdentityResponseSuccessMsg = null;
    }

    function _initController() {
      if (!avm.action_panel.editing_action) {
        if (dataviewConfig.dbActions.includes(avm.action_panel.action_name))
          avm.newConnectionInfo = "Please make sure IP " + deploymentConsts.MAMMOTH_OUTBOUND_IP + " is whitelisted in your database settings.";
        else
          avm.newConnectionInfo = null
        avm.create(avm.action_panel.action_name, avm.action_panel.createParams);
      } else {
        avm.edit(avm.action_panel.editing_action);
      }
      // Note: Here we defining the submit method for action_panel otherwise its undefined in the action panel factory
      avm.action_panel.submit = avm.submit;
      avm.action_panel.serversideValidate = avm.serversideValidate;
      return initPromise.promise;
    }

    function getAndSetIdentity(connection_data?) {

      let params = {'integration_key': avm.action_panel.action_name, 'is_export': true, 'project_id': $store.state.projectId};
      if (connection_data) {
        params['connection_data'] = connection_data;
      }
      avm.manager.connection_timeout = false;
      avm.showLoader = true;
      avm.isLoading = true;
      avm.saveIdentityResponseFailureMsg = "";
      avm.saveIdentityResponseSuccessMsg = "Establishing connection...";

      if (avm.editIdentity) {
        if (avm.file) {
          let parsedFile = JSON.parse(avm.file);
          Object.assign(params, parsedFile);
        }

        Singularity.updateIdentity(avm.selectedIntegration, avm.toEditIdentity.value, params)
          .then(function (data) {
            if (data.success === 500) {
              avm.showIdentityForm = false;
              avm.showLoader = false;
              avm.isLoading = false;
            } else {
              if (data.identity_key !== null && !data.message) {
                // Make sure identity edited is updated with changes so that it reflects on the third party modal immediately
                avm.toEditIdentity.name = data.identity['name'];
                Object.keys(avm.toEditIdentity.identity_config).forEach((key) => {
                  avm.toEditIdentity.identity_config[key] = data.identity[key]
                });
                selectIdentity(avm.toEditIdentity);
                avm.showIdentityForm = false;
              } else {
                avm.saveIdentityResponseFailureMsg = data.message;
              }
            }
            avm.showLoader = false;
          }, function () {
            avm.showLoader = false;
          });
        avm.editIdentity = false;
      } else {
        Singularity.checkUrlParamsForAuthRedirect(params).then(function (integration) {
          avm.setIdentities(integration['key']);
        }, function () {
          avm.showLoader = false;
          avm.isLoading = false
          avm.manager.connection_timeout = true;
        });
      }
    }

    function selectIdentity(identity) {
      avm.selectedIdentity = avm.manager.param.target_properties.selected_identity = identity;
      avm.manager.param.target_properties.host = identity.name;
      avm.showLoader = true;
      avm.isLoading = true;
      avm.manager.serversideValidationRequired = true;
      avm.reason = null;
      Singularity.getProfiles(avm.action_panel.action_name, identity['identity_config']['config_key'], true).then(function (data) {
        identity.profiles = data.profiles;
        avm.showLoader = false;
        avm.isLoading = false;
      }, function (data) {
        avm.showLoader = false;
        avm.isLoading = false;
        var identitiesWithoutProfile = []
        identitiesWithoutProfile.push(identity['identity_config']['client_email']);
        if (data.hasOwnProperty("reason") && !avm.reason) {
          avm.reason = data.reason;
          toastNotification.error("No profiles could be found for these workspaces: " + identitiesWithoutProfile.join(" ,"));
        } else if (!avm.reason) {
          toastNotification.error('Some error occurred, please try again.');
        }
      });
    }

    function deleteIdentity(identity) {
      modalService.deleteIdentity({type: 'identity', name: identity.name}).then(deleteConfirmed);

      function deleteConfirmed() {
        identity.showLoader = true;
        Singularity.deleteIdentity(avm.manager.param.handler_type, identity.value, true).then(function () {
          var idx = -1;
          angular.forEach(avm.manager.identities, function (ident, i) {
            if (ident.value == identity.value) {
              idx = i;
            }
          });
          if (idx > -1) {
            avm.manager.identities.splice(idx, 1);
          }
          avm.manager.reset();
          identity.showLoader = false;
        });
      }
    }

    function selectProfile(identity, profile) {
      avm.showLoader = true;
      avm.isLoading = true;
      avm.manager.param.target_properties.host = identity['identity_config']['client_email'];
      avm.manager.param.target_properties.database = profile.name;
      avm.manager.serversideValidationRequired = true;
      var selectedProfile = avm.manager.param.target_properties.selected_identity;
      if (!!(selectedProfile) && selectedProfile.value === profile.value) {
        return;
      }
      avm.selectedIdentity = avm.manager.param.target_properties.selected_identity = identity;
      avm.selectedProfile = avm.manager.param.target_properties.selected_profile = profile;
      avm.isLoading = false;
      avm.showLoader = false;
    }

    function editIdentityHandler(identity?) {
      avm.newConnectionInfo = ''
      if (identity) {
        avm.showIdentityForm = !avm.showIdentityForm;
        avm.addIdentityForm.modelData = new Object()
        Object.assign(avm.addIdentityForm.modelData, identity["identity_config"]);
      }
    }

    function onCancel() {
      avm.showIdentityForm = false;
      avm.showLoader = false;
      avm.isLoading = false;
      avm.addIdentityForm.modelData = null;
      avm.saveIdentityResponseFailureMsg = null;
      avm.saveIdentityResponseSuccessMsg = null;
      delete Singularity.pendingRequests['save_identity']
    }

    $scope.onChange = function (element) {
      var dataFile = element.files[0];
      avm.newConnectionInfo = dataFile.name;
      var reader = new FileReader();
      reader.onload = function (e) {
        $scope.$apply(function () {
          avm.file = reader.result;
          try {
            JSON.parse(avm.file);
          } catch {
            avm.file = null;
            toastNotification.error("Please add a valid Json file.");
            return null;
          }
          if (element.id === 'getFile') {
            avm.getAndSetIdentity(avm.file);
          }
        });
      };
      reader.readAsText(dataFile);
    }
  }
}
