/**
 * .api.js files contain backend API requests
 */

import axios from '@vueSrc/plugins/axios'
import { transformGridColumns, transformMetrics } from './publish.transform.js'
import { mockPublishReportInfoData, mockRowsData, mockMetricsData } from './publish.mocks.js'

const mock = false

export {
  PublishApi_getPublishReportInfo,
  PublishApi_getGridRows,
  PublishApi_getExploreCardData,
  PublishApi_getMetrics,
  PublishApi_downloadGridData,
}

async function PublishApi_getPublishReportInfo(reportId) {
  if (mock) {
    return new Promise((resolve, reject) => {
      resolve(mockPublishReportInfoData)
    })
  }

  return axios.get(`/report/${reportId}`)
}

/**
 *
 * @param {string} reportId the dataview we want to request data for
 * @param {string} offset the first row we want to start the request from
 * @param {string} limit the number of rows we want to request
 * @param {string} filterCondition filters can be applied to the dataset, if there is any, it comes here
 */
function PublishApi_getGridRows(reportId, offset = 1, limit = 200, filterCondition, displayProperties) {
  if (mock) {
    return new Promise((resolve, reject) => {
      resolve(mockRowsData)
    })
  }

  const responseTypes = {
    ERROR: 'error',
    PROCESSING: 'processing',
    SUCCESS: 'succes',
  }

  let futureRequests = 0
  const maxRequests = 10
  const futureRequestDelay = 1000

  return axios
    .post(`/report_component`, {
      report_id: reportId,
      component_type: 'DATA_GRID',
      limit: limit,
      offset: offset,
      condition: filterCondition,
      display_properties: displayProperties,
    })
    .then((response) => future_request(response.data.future_id))

  function future_request(future_id) {
    futureRequests++

    if (futureRequests === maxRequests) {
      throw new Error('exceeded the amount of requests')
    }

    return axios.get(`/future/${future_id}`).then((futureResponse) => {
      // status could be success, processing (300ms) and failed
      // if processeing, future object should be just {status: 'processing', response: {}}, meaning response is empty
      if (futureResponse.data.future.status === responseTypes.ERROR)
        throw new Error(`POST /report_component -> future response status return was not success.`)

      if (futureResponse.data.future.status === responseTypes.PROCESSING) {
        return new Promise((resolve) => {
          setTimeout(() => resolve(future_request(future_id)), futureRequestDelay)
        })
      }

      const rowsInfo = futureResponse.data.future.response
      const columnDefs = transformGridColumns(rowsInfo.metadata)

      return { data: rowsInfo.data, columnDefs: columnDefs, paging: rowsInfo.paging, metadata: rowsInfo.metadata }
    })
  }
}

async function PublishApi_getExploreCardData(param, displayProperties, reportId, offset = 1, limit = 200) {
  const responseTypes = {
    ERROR: 'error',
    PROCESSING: 'processing',
    SUCCESS: 'succes',
  }

  let futureRequests = 0
  const maxRequests = 10
  const futureRequestDelay = 1000

  return axios
    .post(`/report_component`, {
      report_id: reportId,
      component_type: 'EXPLORE_CARD',
      limit: limit,
      offset: offset,
      param: param,
      display_properties: displayProperties,
    })
    .then((response) => {
      return response.data
    })
}

async function PublishApi_getMetrics(metricsArray) {
  const metricsPromisesArray = metricsArray.map((metric) =>
    axios.post(`/report_component`, {
      report_id: metric.report_id,
      component_type: 'METRIC',
      param: metric.params,
      display_properties: metric.display_props,
    })
  )

  return transformMetrics(metricsArray, await Promise.all(metricsPromisesArray))
}

function PublishApi_downloadGridData(reportId, filterCondition, displayProperties, fileType) {
  if (mock) {
    return new Promise((resolve, reject) => {
      resolve(mockRowsData)
    })
  }

  const responseTypes = {
    ERROR: 'error',
    PROCESSING: 'processing',
    SUCCESS: 'succes',
  }

  let futureRequests = 0
  const maxRequests = 100
  const futureRequestDelay = 1000

  return axios
    .post(`/report_component`, {
      report_id: reportId,
      component_type: 'DATA_GRID',
      download_file_type: fileType,
      condition: filterCondition,
      display_properties: displayProperties,
    })
    .then((response) => future_request(response.data.future_id))

  function future_request(future_id) {
    futureRequests++

    if (futureRequests === maxRequests) {
      throw new Error('exceeded the amount of requests')
    }

    return axios.get(`/future/${future_id}`).then((futureResponse) => {
      // status could be success, processing (300ms) and failed
      // if processeing, future object should be just {status: 'processing', response: {}}, meaning response is empty
      if (futureResponse.data.future.status === responseTypes.ERROR)
        throw new Error(`POST /report_component -> future response status return was not success.`)

      if (futureResponse.data.future.status === responseTypes.PROCESSING) {
        return new Promise((resolve) => {
          setTimeout(() => resolve(future_request(future_id)), futureRequestDelay)
        })
      }
      return futureResponse.data.future.response
    })
  }
}
