'use strict';
import _ = require('lodash-es');
// Vue app
import { transformResources } from '../../../vueApp/src/mm-frontend/src/modules/resources/api/resources.transform.js';
import { transformNotifications } from '../../../vueApp/src/mm-frontend/src/components/navbar/navbar-notifications/api/notifications.transform.js';
import { VueInstance } from '../../../vueApp/src/ngVueBridgeCode/ngVueComponentsModule.js';
import { NOTIFICATION_STATUS } from '../../../vueApp/src/mm-frontend/src/constants/notifications.js';
import VueNotificationsApi from '../../../vueApp/src/mm-frontend/src/components/navbar/navbar-notifications/api/notifications.api.js';
import { STORAGE_WARNING_PERCENTAGE } from '../../../vueApp/src/mm-frontend/src/constants/sms.js'

resources.$inject = [
  'VuexStore',
  '$q',
  '$resource',
  'Auth',
  'config',
  'DatasourceService',
  'DataviewService',
  'FileService',
  'webhooks',
  '$interval',
  'FutureService',
  'NotificationService',
  'UserWorkspace',
  'labelService',
  'eventCallbackManagerFactory',
  'ActionServiceFactory',
  'c',
  'analyticsService',
  '$rootScope',
  'toastNotification',
  'keyCloak',
  'navigationService',
  '$stateParams',
  'landingList',
  'CacheFactory',
  'CompatibilityWarnings'
];

/**
 * Fetches and updates all the resources
 */
export function resources(
  $store,
  $q,
  $resource,
  Auth,
  config,
  DatasourceService,
  DataviewService,
  FileService,
  webhooks,
  $interval,
  FutureService,
  NotificationService,
  UserWorkspace,
  labelService,
  eventCallbackManagerFactory,
  ActionServiceFactory,
  c,
  analyticsService,
  $rootScope,
  toastNotification,
  keyCloak,
  navigationService,
  $stateParams,
  landingList,
  CacheFactory,
  CompatibilityWarnings
) {
  let polling_modes = {
    get active() {
      return { update_interval: 1000 };
    },
    get lazy() {
      return { update_interval: 3000 };
    },
    // super lazy interval is for polling on expired workspaces.
    get superLazy() {
      return { update_interval: 10000 };
    },
  };

  // keeping initial polling mode active as we need it on page load. on page load there are bunch of future requests generated.
  let polling_mode = polling_modes.active;
  let oldCurrentUsage = -1;
  // Cache validation interval 5 minutes or 300000 ms
  let cacheValidationInterval = 300000;
  let resourcesMap = {};
  let resourcesUpdateEvent = new eventCallbackManagerFactory('resourcesUpdated');
  let globalPendingItemsUpdateEvent = new eventCallbackManagerFactory('dataFlowUpdated');
  let publishedReportsUpdateEvent = new eventCallbackManagerFactory('publishedReportsUpdated');
  let resourcesDeniedEvent = new eventCallbackManagerFactory('resourcesUpdated');
  let res_service = {
    update: update,
    reset: reset,
    start_update_polling: start_update_polling,
    stop_update_polling: stop_update_polling,
    is_polling_on: false,
    force_refresh: force_refresh,
    resourcesMap: resourcesMap,
    globalPendingItemsUpdateEvent: globalPendingItemsUpdateEvent,
    publishedReportsUpdateEvent: publishedReportsUpdateEvent,
    globalPendingItems: [],
    // events
    onUpdate: resourcesUpdateEvent.add_callback,
    onDenied: resourcesDeniedEvent.add_callback,
  };
  var pending_requests = false;
  var all_deferred_requests = [];
  let isValidationInProgress = false;
  let resourceApiCallCount = 0;

  var Resources = $resource(config.api.resources);
  let compatibility_service = CompatibilityWarnings.compatibility_service;
  var HashJson = $resource(config.api.hashJSON);
  // interval promise for resource polling
  var interval_promise = null;

  // interval promise for validation polling
  var validationIntervalPromise = null;
  let last_checked_until = 0;
  let core_list_last_checked_until = 0;
  let currentProjectId = $stateParams.projectId;
  let need_core_list_hash = false;
  let notifications_to_be_removed = [];
  let workspaceStatus = null;
  let workspaceStatusChangedTime = null;


  DatasourceService.on_delete('update_resources', update);
  DataviewService.on_delete('update_resources', update);
  FileService.on_delete('update_resources', update);
  DatasourceService.on_add_dataview('update_resources', update);
  labelService.labelChanged.add_callback('update_resources', update);
  FutureService.on_track_request('enable_active_polling', enable_active_polling);
  FutureService.on_zero_pending_requests('disable_active_polling', disable_active_polling);
  labelService.initResourcesMap(res_service.resourcesMap);
  /**
   * Common reducer function to transform an iterable based on a value getter.
   * @param iterable - The iterable to reduce.
   * @param value_getter - A function that returns the value for a given key.
   * @returns {Object} The reduced result.
   * */
  const common_reducer = (iterable: string[], value_getter: (prop) => any): object => {
    return iterable.reduce((result, key) => {
      result[key] = value_getter(key);
      return result;
    }, {});
  };
  /**
   * Extracts the required props from a resource object.
   * @param props - The required props.
   * @param resource - The resource object.
   * @returns {Object} Object with required properties only.
   * */
  const extractProps = (props: string[], resource: any) => {
    return common_reducer(props, (prop) => resource[prop]);
  }

  /**
   * Reduces the core list items to a simple object with resource_id as key and value as object with required props of resource object.
   * @param resources_data - The core list items.
   * @param requiredProps - The required props.
   * @returns {Object} The reduced result.
   * */
  const reduceCoreListItems = (resources_data: Object, requiredProps: string[]) => {
    const get_resource_prop = (resource_id) => extractProps(requiredProps, resources_data[resource_id]);
    return common_reducer(Object.keys(resources_data), get_resource_prop);
  };
  const propsForHash = ["resource_id", "resource_type", "last_updated_at", "object_status"];


  async function validate(data) {
    if (data.core_list_items.hasOwnProperty('existing_items_hash')) {
      const { existing_items_hash } = data.core_list_items;
      const resources = $store.getters['cache/getResourcesByProjectId'](currentProjectId) || {};
      const core_list_items = JSON.parse(JSON.stringify(resources?.core_list_items?.items)) || {};
      const json_structure = reduceCoreListItems(core_list_items, propsForHash);
      let response_data = HashJson.save({json_structure });
      let { hash } = await response_data.$promise;
      if (hash !== existing_items_hash) {
        analyticsService.userEventTrack(c.userEvents.invalidResourceCache, { eventOrigin: 'resource.service' });
        clean_resources();
        return false;
      }
      analyticsService.userEventTrack(c.userEvents.validResourceCache, { eventOrigin: 'resource.service' });
      return true;
    }
  }

  function reset() {
    if (currentProjectId == $stateParams.projectId) {
      return
    }
    if (currentProjectId) {
      res_service.resourcesMap = {};
      FileService.reset();
      DatasourceService.reset();
      labelService.reset();
      core_list_last_checked_until = 0;
      last_checked_until = 0;
    }
    currentProjectId = $stateParams.projectId;
    restoreCache();
  }

  /**
   * procedure that makes an api call to /resource endpoint
   * @param params the flags that we pass to resource endpoint.
   *    last_checked_until: number = last checked time / 0 is it is a fresh request.
   *    need_core_list_hash: boolean = given true response will contain hash generated by backend resources
   */
  async function getData(params) {
    /*
    getData:
    *     call api
    *       feed cache
    *       process data
    *       update cache
    *       if validation needed
    *         validate data
    *           if not valid
    *               isValid = false
    *               last checked until = 0
    *       if valid
    *         process_resources_data
    *         last checked until
    * */
    let resource_data = Resources.get(params);
    try {
      const response = await resource_data.$promise;
      $store.commit('setLastCheckedUntil',response?.last_checked_until || 0)
      if (params['project_id'] && response?.core_list_items.changed && response?.core_list_items?.items) {
        $store.commit('cache/setProjectResources', {
          projectId: response.project_id,
          coreListLastCheckedUntil: response?.core_list_last_checked_until,
          newResources: _.cloneDeep(response?.core_list_items.items),
        });
      }
      if(response.project_id == currentProjectId) {
        last_checked_until = response.last_checked_until;
        core_list_last_checked_until = response.core_list_last_checked_until;
        process_resources_data(response);
        if (params['need_core_list_hash']) {
          isValidationInProgress = true;
          let isCacheValid = await validate(response);
          need_core_list_hash = false;
          if (!isCacheValid) {
            clean_resources();
          }
          isValidationInProgress = false;
        }
        if (!resourceApiCallCount) {
          ++resourceApiCallCount;
        }
      }
      pending_requests = false;
    } catch (error) {
      process_resources_failure(error);
      need_core_list_hash = false;
    }
  }

  Auth.on_logout('clean_resources', onLogout);
  return res_service;

  function onLogout() {
    stop_update_polling();
    $store.commit('cache/setProjectResources', {
      projectId: currentProjectId,
      coreListLastCheckedUntil: 0,
      newResources: null,
    });
    clean_resources();
  }

  function _resolve() {
    while (all_deferred_requests.length) {
      let deferred = all_deferred_requests.pop();
      deferred.resolve();
    }
    if (polling_mode === polling_modes.superLazy) {
      switchPollingMode(polling_modes.active);
    }
    if ($rootScope.showPaymentError) {
      $rootScope.showPaymentError = false;
    }
    pending_requests = false;
  }

  function update() {
    const isReportsTab = window.location.hash.includes('shared-reports')
    const isPublishTab = window.location.hash.includes('publish')
    if (
      UserWorkspace.is_super_admin ||
      !UserWorkspace.workspace ||
      isReportsTab || 
      (!$stateParams.projectId && !isPublishTab)
    ) {
      return
    }
    reset();

    let deferred = $q.defer();
    all_deferred_requests.push(deferred);
    if (!pending_requests && !isValidationInProgress) {
      pending_requests = true;
      let params = {};

      params['last_checked_until'] = last_checked_until;
      params['core_list_last_checked_until'] = core_list_last_checked_until
      if (need_core_list_hash && resourceApiCallCount) {
        params['need_core_list_hash'] = need_core_list_hash;
      }
      // we need to make getData api call everytime. present / absense of cache just modifies the params
      params['project_id'] = currentProjectId;
      getData(params);
    }

    return deferred.promise;
  }

  function reconstruct_core_list_item(cli) {
    cli.object_properties.type = cli.resource_type;
    cli.object_properties.resource_id = cli.resource_id;
    cli.object_properties.objectStatus = cli.object_status;
    _.merge(cli.object_properties, cli.user_properties);
    return cli.object_properties;
  }

  function process_resources_failure(e) {
    if (e.status == 403) {
      if (e.data && e.data.ERROR_CODE == 5000) {
        if (!$rootScope.showPaymentError) {
          $rootScope.showPaymentError = true;
        }
        clean_resources();
        resourcesDeniedEvent.fire_event();
        if (polling_mode !== polling_modes.superLazy) {
          switchPollingMode(polling_modes.superLazy);
        }
      } else if (e.data?.ERROR_CODE === 70) {
        landingList.reset()
        navigationService.openFirstProjectView(true);
      }
    } else if (e.data?.ERROR_CODE === 6600) {
      navigationService.openFirstProjectView();
    } else {
      console.error(e);
    }
    pending_requests = false;
  }

  function process_resources_data(data) {
    if (data.core_list_items.hasOwnProperty('changed')) {
      if (data.core_list_items.changed && data.core_list_items?.items){
        var core_list_items: any = _.map(data.core_list_items.items, reconstruct_core_list_item);
        var datasources = _.filter(core_list_items, { type: 'datasource' });
        var files = _.filter(core_list_items, { type: 'file_object' });
        var webhookList = _.filter(core_list_items, { type: 'webhook' });
        var wsList = _.filter(core_list_items, { type: 'dataview' });
        var labelsList = _.filter(core_list_items, { type: 'label' });
        var wsByDsList = {};
        _.forEach(wsList, function (ws: any) {
          if (!_.has(wsByDsList, ws.ds_id)) {
            wsByDsList[ws.ds_id] = [];
          }
          wsByDsList[ws.ds_id].push(ws);
        });

        DatasourceService._set_updates(datasources, wsByDsList, addToResMap, removeFromResMap);
        FileService._set_updates(files, addToResMap, removeFromResMap);
        labelService.updateList(labelsList, addToResMap, removeFromResMap);

        webhooks.processList(webhookList);
        labelService.initResourcesMap(res_service.resourcesMap);
        landingList.update(res_service.resourcesMap)
        resourcesUpdateEvent.fire_event();
        try {
          $store.commit('resources/setResources', transformResources(Object.values(data.core_list_items.items)));
        } catch (error) {
          console.error(error);
        }
      }
    }

    if (data.hasOwnProperty('future_request') && data.future_request.changed) {
      FutureService.set_updates(data.future_request.items);
    }

    if (data.hasOwnProperty('notifications') && data.notifications.changed && data.notifications.items) {
      try {
        const transformedNotifications = transformNotifications(data.notifications);
        if (transformedNotifications.notifications?.length)
          $store.dispatch('notifications/save', transformedNotifications.notifications);
        if (transformedNotifications.toasts?.length) {
          //TODO - until angular code is gone, any change made in this block must be copied into its parallel on the 'mm-frontend' repo on 'resources/index.js' file
          transformedNotifications.toasts.forEach((t) => {
            const toastMessage = VueInstance.$t(
              `global.toast.${t.details.type}.${t.details.subType}.${t.status}`,
              t.details
            );
            // @ts-ignore:next-line
            VueInstance.$toast.show({content:toastMessage, timeout:1500})
            if (t.status === NOTIFICATION_STATUS.COMPLETED) {
              if (!notifications_to_be_removed.includes(t.id)) {
                notifications_to_be_removed.push(t.id);

                VueNotificationsApi.remove(t.id).then(() => {
                  delete notifications_to_be_removed[t.id];
                });
              }
            } else if (t.status === NOTIFICATION_STATUS.PROCESSING) VueNotificationsApi.read(t.id);
          });
        }
      } catch (error) {
        console.error(error);
      }
      NotificationService.updateList(data.notifications.items);
    }

    if (data.projects?.changed) {
      $store.dispatch('getWorkspaces', { invalidateCache: true });
      $store.dispatch('getCurrentUser',  { invalidateCache: true });
      let email = $store.state.user.email
      UserWorkspace.clearCache(`/${email}/`, 'workspaces')
      UserWorkspace.clearCache(`/${email}/`, 'self')
    }
    // Keep tracking request data for any change in action and update action list
    // Inital request starts with epoch as 0.
    // This causes resource tracker to return all resources as modified
    // (wrt 0 epoch).
    // Such resources need not to be updated.
    // After 2nd api call onwards, actual epoch is ascertained from backend.
    // Then update resource
    if (data.hasOwnProperty('actions') && data.actions.changed && last_checked_until != 0) {
      var triggers = data.actions.triggers;
      let refreshed_dataviews = {};
      if (triggers) {
        var ActionService;
        triggers.forEach(function (trigger) {
          // Do not repeat refresh action_list for dataview if already done so.
          if (trigger.dataview_id in refreshed_dataviews) {
            // already refreshed, skip!
            return;
          }
          ActionService = ActionServiceFactory.get_by_dataview_id(trigger.dataview_id);
          ActionService.update_list();
          refreshed_dataviews[trigger.dataview_id] = '';
        });
      }
    }
    if (data.hasOwnProperty('global_pending_items') && data.global_pending_items.changed) {
      res_service.globalPendingItems = data.global_pending_items.items;
      globalPendingItemsUpdateEvent.fire_event();
      $store.commit('resources/setPendingResources', data.global_pending_items.items);
    }
    if (data.hasOwnProperty('reports') && data.reports.changed && data.reports.data) {
      publishedReportsUpdateEvent.fire_event(data.reports.data);
    }
    if (data.hasOwnProperty('disk_usage_stats') && data.disk_usage_stats.changed && data.disk_usage_stats.disk_usage?.workspace) {
      let workspaceCurrentUsage = data.disk_usage_stats.disk_usage.workspace.ROW_COUNT_UTILIZED
      $store.commit('setCurrentWorkspaceUsage', workspaceCurrentUsage)
      verifyDataUsageToast(workspaceCurrentUsage)
    } else if ($store.state.usageInfo.workspace.current  != -1) oldCurrentUsage = $store.state.usageInfo.workspace.current 

    verifyDataUsageBanner()

    if (data.hasOwnProperty('disk_usage_stats') && data.disk_usage_stats.changed) {
      $store.commit(
        'setCurrentWorkspaceUsage',
        data.disk_usage_stats.disk_usage.workspace.ROW_COUNT_UTILIZED
      );
    }
    
    // if the workspace status changes from active to snoozed within 15 seconds, ignore the updates
    // set the previous workspace status and changed time
    const currentTime = new Date();
    const currentWorkspaceStatus = data.workspace_state;
    const isInitialStateNull = !workspaceStatusChangedTime && !workspaceStatus;
    const hasChangedToActive = workspaceStatus === 'snoozed' && currentWorkspaceStatus === 'active';
    const hasChangedToSnoozed = workspaceStatus === 'active' && currentWorkspaceStatus === 'snoozed';
    const timeDiffBetweenActiveToSnoozed =  workspaceStatusChangedTime && ((currentTime.getTime() - workspaceStatusChangedTime.getTime()) / 1000);

    if (currentWorkspaceStatus && (isInitialStateNull || hasChangedToActive || (hasChangedToSnoozed && timeDiffBetweenActiveToSnoozed > 15))) {
      workspaceStatus = data.workspace_state;
      workspaceStatusChangedTime = new Date();
      compatibility_service.displayInstanceSnoozeWarning(workspaceStatus);
    }
    _resolve();
  }
  function _poll() {
    if (res_service.is_polling_on) {
      return update();
    }
  }

  function addToResMap(resource) {
    if (resource && resource.resource_id) {
      res_service.resourcesMap[resource.resource_id] = resource;
    }
  }

  function removeFromResMap(resource) {
    if (resource && resource.resource_id && res_service.resourcesMap[resource.resource_id]) {
      delete res_service.resourcesMap[resource.resource_id];
    }
  }
  function restoreCache() {
    let data = _.cloneDeep($store.getters['cache/getResourcesByProjectId']($stateParams.projectId));
    if (_.isEmpty(data)) {
      // validate data after rendering page
      return;
    }
    try {
      data.project_id = $stateParams.projectId
      process_resources_data(data);
      last_checked_until = 0;
      resourceApiCallCount = 0;
      core_list_last_checked_until = data?.core_list_last_checked_until || last_checked_until
      need_core_list_hash = !window.location.hash.includes('dataviews');
    } catch (error) {
      console.error(error);
    }
  }

  function switchPollingMode(mode = polling_modes.active) {
    stop_update_polling();
    polling_mode = mode;
    start_update_polling();
  }

  function enable_active_polling() {
    switchPollingMode(polling_modes.active);
  }

  function disable_active_polling() {
    switchPollingMode(polling_modes.lazy);
  }

  function start_update_polling() {
    if (interval_promise != null) {
      stop_update_polling();
    }
    res_service.is_polling_on = true;
    interval_promise = $interval(_poll, polling_mode.update_interval);
    // validate cache
    validationIntervalPromise = $interval(() => {
      need_core_list_hash = true;
    }, cacheValidationInterval);
    return update();
  }

  function stop_update_polling() {
    if (interval_promise != null) {
      $interval.cancel(interval_promise);
    }
    if (validationIntervalPromise != null) {
      $interval.cancel(validationIntervalPromise);
    }
    res_service.is_polling_on = false;
    interval_promise = null;
    validationIntervalPromise = null;
  }

  function clean_resources() {
    $store.commit('cache/setProjectResources', {
      projectId: currentProjectId,
      coreListLastCheckedUntil: 0,
      newResources: null,
    });
    last_checked_until = 0;
    core_list_last_checked_until = 0;
  }

  function force_refresh() {
    clean_resources();
    return update();
  }


  function verifyDataUsageToast(currentUsage){
    // Warn user that has reached X percentage of usage
    const allowedUsage = $store.state.usageInfo.workspace.allowed
    if (allowedUsage <= 0 || oldCurrentUsage == -1){
      oldCurrentUsage = currentUsage
      return
    } 

    const oldUsedPercentage = (oldCurrentUsage * 100) / allowedUsage
    const newUsedPercentage = (currentUsage * 100) / allowedUsage

    const tempWarningPercentage = newUsedPercentage >= 100 ? 100 : STORAGE_WARNING_PERCENTAGE

    if (oldUsedPercentage < tempWarningPercentage && tempWarningPercentage <= newUsedPercentage)
      toastNotification.warning({
        content:  $store.getters['isWorkspaceUsageLimitReached']
        ? 'You have exceeded the storage limit on your plan.'
        : `You're at ${Math.floor(newUsedPercentage)}% of your storage limit. Consider upgrading to a better plan for more space`,
        status: $store.getters['isWorkspaceUsageLimitReached'] ? 'error' : 'warning',
        actionLabel: "Manage storage",
        actionFn: () => {
          window.open(
            `${window.location.origin}/n/#/settings/workspace/${$store.state.workspaceId}?dataStorage=true`,
            'Settings'
          )
        }
        }
       );

    oldCurrentUsage = currentUsage
  }

  function verifyDataUsageBanner(){
    const allowedUsage = $store.state.usageInfo.workspace.allowed
    const currentUsage = $store.state.usageInfo.workspace.current 
    if (allowedUsage <= 0 || currentUsage == -1) return

    const dataUsedPercentage = (currentUsage * 100) / allowedUsage

    if (STORAGE_WARNING_PERCENTAGE <= dataUsedPercentage && keyCloak.sessionId != localStorage.getItem('keycloakSessionId')){
      const isMaxStorageReached = dataUsedPercentage >= 100
      VueInstance.$banner.show({
        content: isMaxStorageReached ? "You have exceeded the row limit on your plan." : `You have used ${Math.floor(dataUsedPercentage)}% of your storage.`,
        status: isMaxStorageReached ? 'error' : 'warning',
        actionLabel: "Manage storage",
        actionFn: () => {
          window.open(
            `${window.location.origin}/n/#/settings/workspace/${$store.state.workspaceId}?dataStorage=true`,
            'Settings'
          )
        }
      })
    }
      
    localStorage.setItem('keycloakSessionId', keyCloak.sessionId)
  }

}
