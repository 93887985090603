import * as angular from 'angular';
import _ = require('lodash');

/**
 * @ngInject
 */


elementDisplayMenuHandle.$inject = ['elementConfig','$templateCache'];
export function elementDisplayMenuHandle(elementConfig,$templateCache) {
  return {
    templateUrl: elementConfig.templates.displayMenuHandle
  }
}
/**
 * @ngInject
 */
chartTypeMenu.$inject = ['config'];
export function chartTypeMenu(config) {
  return {
    templateUrl: config.templates.chartTypeMenu
  }
}
/**
 * @ngInject
 */
elementDisplayContent.$inject = ['elementConfig', '$templateCache', '$compile'];
export function elementDisplayContent(elementConfig, $templateCache, $compile) {
  return {
    link: function (scope, ele, attrs) {
      ele.css('height', 'inherit');
      var element = scope.$eval(attrs.element);
      var newScope = scope.$new();
      if (attrs.drilldownManager) {
        scope.$watch(attrs.drilldownManager, function (drilldownManager) {
          if (newScope.elementDisplayContent) {
            newScope.elementDisplayContent.drilldownManager = drilldownManager;
          }
        })
      }
      if (attrs.datastore) {
        scope.$watch(attrs.datastore, function (datastore) {
          if (newScope.elementDisplayContent) {
            newScope.elementDisplayContent.datastore = datastore;
          }
        });
      }
      if (!element.id) {
        scope.$watch(attrs.element, init, true);
      }

      init();

      function init() {
        element = scope.$eval(attrs.element);
        newScope.elementDisplayContent = {};
        newScope.elementDisplayContent.big = attrs.big != 'false';
        newScope.elementDisplayContent.element = element;
        var content_to_append = '<!--element display content directive-->' + $templateCache.get(elementConfig[element.type].displayTemplate);
        ele.html($compile(content_to_append)(newScope));
      }

    }
  }
}
/**
 * @ngInject
 */

showElementBuilderMenu.$inject = ['elementConfig', '$templateCache', '$compile'];
export function showElementBuilderMenu(elementConfig, $templateCache, $compile) {
  return {
    link: function (scope, element, attrs) {
      var previousElementWODispProps;
      scope.$watch(attrs.element, init, true);
      var oldElement;

      function init(newElem) {
        if (oldElement != newElem) {
          oldElement = newElem;
          var _newElem = _.cloneDeep(newElem);
          if (_newElem.display_properties) {
            delete _newElem.display_properties;
          }
          if (angular.equals(_newElem, previousElementWODispProps)) {
            return;
          }
          previousElementWODispProps = _newElem;
          element.empty();
          if (scope.epvm.options.element) {
            var content_to_append = $templateCache.get(elementConfig[scope.epvm.options.element.type].createTemplate);
            element.html($compile(content_to_append)(scope));
          }
        }
      }
    }

  }
}
/**
 * @ngInject
 */

elementSwitcherOnBuilderMenu.$inject = ['config'];
export function elementSwitcherOnBuilderMenu(config) {
  return {
    restrict: 'E',
    templateUrl: config.templates.switcherInBuilderMenu
  }
}
/**
 * @ngInject
 */
export function bindButtonToElementForm() {
  return {
    link: function (scope, ele, attrs) {
      var form = scope.$eval(attrs.bindButtonToElementForm);

    }
  }
}

/**
 * @ngInject
 */
copyMetricValueToClipboard.$inject = ['toastNotification','utils', 'c', 'analyticsService'];
export function copyMetricValueToClipboard(toastNotification, utils, c, analyticsService){
    return {
      link: function(scope, element, attrs){
        $(element).on('click', function(){
          analyticsService.userEventTrack(c.userEvents.elements.copyMetricValueToClipboard,
            {
              eventOrigin: "elementsPanel.metric.metricOptions"
            });
          let value: any = $($(element).parents('.elem-wrap.card')[0]).find('.value').text();
          let title = $($(element).parents('.elem-wrap.card')[0]).find('.header').text();
          if(value.length){
            value = title + ': ' + value;
            utils.copyToClipboard(value);
            toastNotification.success('Copied to clipboard');
          }
          else{
            toastNotification.success('Please wait ...');
          }
        })
      }
    }
}
