<template>
  <div class="mm-project-manage-menu">
    <div class="p-a-2">
      <mm-list
        class="p-a-3"
        :items="computedSelectionItems"
        :divider-at-index="computedItemsDividerIndexes"
        @itemClick="$event.item.clickHandler()"
      />
    </div>

    <component
      v-if="modalComponent"
      :is="modalComponent"
      v-model="isActionModalOpen"
      :projectId="projectId"
      @input="!$event && $emit('modalClosed', modalType)"
      @submit="$emit('optionExecuted', modalType)"
    />
  </div>
</template>

<script>
import ModalName from '../modals/project-modal-name'
import ModalLeave from '../modals/project-modal-leave'
import ModalJoin from '../modals/project-modal-join'
import ModalDelete from '../modals/project-modal-delete'
import ModalManage from '../modals/project-modal-manage'

import { USER_EVENTS, WORKSPACE_PERMISSIONS, PROJECT_PERMISSIONS } from '@/constants'

export default {
  name: 'project-manage-menu',
  props: {
    projectId: {
      type: [Number, String],
      required: true
    },
    tooltipsPosition: {
      type: String,
      default: 'left'
    }
  },
  data: () => ({
    isActionModalOpen: false,
    modalComponent: null,
    modalType: ''
  }),
  computed: {
    computedSelectionItems() {
      const isUserInProject = !!this.$store.state.user.roles.projects[this.projectId]
      const isUserProjectAdmin = this.$store.state.user.roles.projects[this.projectId] === PROJECT_PERMISSIONS.ADMIN
      const isUserWorkspaceAdmin = [WORKSPACE_PERMISSIONS.ADMIN, WORKSPACE_PERMISSIONS.OWNER].includes(
        this.$store.getters.getCurrentWorkspacePermissions
      )

      return [
        {
          text: this.$t('project.manage.manage_users.title'),
          clickHandler: () => this.openModal(ModalManage, 'MANAGE'),
          divider: true,
          render: () => isUserProjectAdmin || (isUserInProject ? false : isUserWorkspaceAdmin)
        },
        {
          text: this.$t('project.manage.rename.title'),
          clickHandler: () => this.openModal(ModalName, 'RENAME'),
          render: () => isUserProjectAdmin || (isUserInProject ? false : isUserWorkspaceAdmin)
        },
        {
          text: this.$t('project.manage.leave.title'),
          clickHandler: () => this.openModal(ModalLeave, 'LEAVE'),
          render: () => isUserInProject,
          divider: true
        },
        {
          text: this.$t('project.manage.join.title'),
          clickHandler: () => this.openModal(ModalJoin, 'JOIN'),
          render: () => !isUserInProject && isUserWorkspaceAdmin,
          divider: true
        },
        {
          text: this.$t('project.manage.delete.title'),
          clickHandler: () => this.openModal(ModalDelete, 'DELETE'),
          disabled: !isUserProjectAdmin,
          class: { 'delete-option': isUserProjectAdmin },
          tooltipProps: !isUserProjectAdmin && {
            label: this.$t('project.manage.delete.need_to_project_admin'),
            position: this.tooltipsPosition
          },
          render: () => isUserInProject || isUserWorkspaceAdmin
        }
      ].filter((item) => item.render())
    },
    computedItemsDividerIndexes() {
      return this.computedSelectionItems.map((item, idx) => item.divider && idx + 1).filter((item) => item)
    }
  },
  methods: {
    openModal(modalComponent, modalType) {
      this.$userEvents.save(USER_EVENTS.PROJECT_OPTIONS.SELECT, modalType)
      this.modalComponent = modalComponent
      this.modalType = modalType
      this.isActionModalOpen = true
    }
  }
}
</script>

<style lang="scss">
.mm-project-manage-menu {
  .delete-option {
    label {
      color: var(--mm-color--dest800) !important;
    }
  }
}
</style>
