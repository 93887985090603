export const REQUESTS_STATUS = {
  DONE: 'done',
  ERROR: 'error',
  FAILURE: 'FAILURE',
  PROCESSING: 'PROCESSING',
  PROCESSING_1: 'processing',
  SUBMITTED: 'submitted',
  SUCCESS: 'success',
  SUCCESS_1: 'SUCCESS'
}
