<template>
  <div>
    <mm-button icon="dataflow" objective="tertiary" size="medium" @click="showDataFlowModal = true" />
    <mm-modal
      v-model="showDataFlowModal"
      :title="'Dataflow Graph for ' + computedActiveResource.properties.name"
      closable
      :maxWidth="maximize ? '100%' : 1000"
      :width="maximize ? '100%' : 1000"
      v-if="showDataFlowModal"
    >
      <p class="m-b-0">Double Click on a node to make it node of focus</p>
      <dependency-graph :resourceId="resourceId" :maximize="maximize" @resize="resizeModal" />
    </mm-modal>
  </div>
</template>

<script>
import DependencyGraph from './DependencyGraph.vue'
export default {
  name: 'DataFlowMap',
  components: { DependencyGraph },
  data() {
    return {
      showDataFlowModal: false,
      maximize: false,
      loader: false,
    }
  },
  props: {
    resourceId: Number,
  },
  computed: {
    computedActiveResource() {
      return this.$store.state.resources.resourcesMap[this.resourceId]
    },
  },
  methods: {
    resizeModal() {
      this.maximize = !this.maximize
    },
  },
}
</script>
