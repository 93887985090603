/*jslint node: true */
import * as angular from 'angular';
import * as $ from 'jquery';
import _ = require('lodash');
/**
 * @ngInject
 */
plotMenuManagerFactory.$inject = ['c', 'eventCallbackManagerFactory', 'utils'];
export function plotMenuManagerFactory(c, eventCallbackManagerFactory, utils){
  var defaultDisplayProperties = {
    type: 'chart',
    title: "",
    chartType: null,
    xAxes: {
      1: {
        name: 'X Axis'
      }
    },
    metadata: [{
      display_name: 'X Axis',
      internal_name: 'x_axis',
      type: 'TEXT'
    }, {
      display_name: 'Y Axis',
      internal_name: 'y_axis',
      type: 'NUMERIC'
    }],
    series: [
      {
        "axisIndex":0,
        "name":"Y Axis",
        "stacking":"normal",
        "axisPosition":"left",
        "internal_name":"y_axis",
        "autoComputeLabel":true,
        "type":"column"
      }
    ],
    simpleChart: true,
    invertAxis: false,
    enableZoomAndPan: false,
    legend: {
      enabled: false,
      verticalAlign: 'bottom'
    },
    showAxisLabels: false,
    FORMAT_INFO: {}
  };

  var chartTypesMenu = [
    {type: c.chartTypes.xy, title: 'Line/Column'},
    {type: c.chartTypes.pie, title: 'Pie'}
  ];

  return {
    get: getMenu
  }

  function getMenu(options){
    var plotMenu = new PlotMenu(options);
    return plotMenu;
  }

  function PlotMenu(options){
    var self = this;
    self.getParam = getParam;
    self.setParam = setParam;
    self.getDisplayProperties = getDisplayProperties;
    self.toggleColumn = toggleColumn;
    self.chartTypesMenu = chartTypesMenu;

    self.random_name = 'table_' + utils.getRandomString();
    var plotMenuEvent = new eventCallbackManagerFactory(self.random_name);
    self.on_valid_update = plotMenuEvent.add_callback;
    self.selectedColumns = [];
    self.allowedAggregations = ['SUM', 'AVG', 'MIN', 'MAX', 'STDDEV'];
    self.selectedAggregation = null;
    self.displayProperties = defaultDisplayProperties;

    update(options);

    function update(options) {
      self.metadata = _.cloneDeep(options.metadata);

      self.numericColumns = $.grep(self.metadata, function(col: any){
        return col.type == c.numeric;
      })

      if (options.element){
        self.element = options.element;
        if(options.element.param){
          self.setParam(options.element.param)
        }
        if (options.element.display_properties){
          self.displayProperties = options.element.display_properties;
        }
      }

      if (options.additionalDisplayProperties) {
        angular.merge(self.displayProperties, options.additionalDisplayProperties);
      }
    }

    function getParam() {
      var selected = [];
      if(self.selectedColumns.length == 0){
        throw 'select at least one';
      }
      for(var i = 0; i < self.selectedColumns.length; i++){
        var column = self.selectedColumns[i];
        selected.push({
          FUNCTION: self.selectedAggregation,
          COLUMN: column.internal_name,
          AS: column.title,
          INTERNAL_NAME: column.result_internal_name
        });
      }

      return {
        PIVOT: {
          GROUP_BY: [],
          SELECT: selected
        }
      };
    }

    function setParam(param) {
      self.selectedColumns = [];
      angular.forEach(param.PIVOT.SELECT, function(selectParam){
        var column: any = $.grep(self.numericColumns, function(col: any){
          return col.internal_name == selectParam.COLUMN;
        })
        if(column.length == 1){
          column = column[0];
          column.title = selectParam.AS;
          column.result_internal_name = selectParam.INTERNAL_NAME;
          self.selectedColumns.push(column);
          self.selectedAggregation = selectParam.FUNCTION;
        }

      })
    }

    function getDisplayProperties() {
      return self.displayProperties;
    }

    function toggleColumn(column){
      var index = self.selectedColumns.indexOf(column);
      if(index== -1){
        column.title = column.display_name;

        if(!column.result_internal_name){
          column.result_internal_name = 'plot_val_' + utils.string.random(5);
        }

        self.selectedColumns.push(column);
      }
      else{
        self.selectedColumns.splice(index, 1);
      }

      var selectedColumns = [];
      $.each(self.selectedColumns, function(i, col){
        selectedColumns.push(col);
      })

      self.selectedColumns = selectedColumns;
    }

  }
}
