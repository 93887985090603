import * as angular from 'angular';
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * @ngInject
 * Scope: The purpose of this is to verify if a given metadata matches the dependency requirements
 * Also to avoid cases of circular dependencies
 *
 * This is more necessary during edit of rules/metrics.
 *
 *
 *
 *
 */
export function dependencyChecker() {
  return {
    filterMetrics: filterMetrics
  };

  function filterMetrics(metrics, metadata, context) {
    //trying to build a data structure that will be optimal to go through.
    var all_columns = [];
    $.each(metadata, function (i, c) {
      all_columns.push(c.internal_name);
    });

    /**
     * check if the metric is allowed for the given context to be used in math.
     */
    function _check(metric) {
      if (metric.dataview_id != context.dataview.id) {
        return true;
      }
      var all_dependencies_met = true;
      var dependencies = _.cloneDeep(metric.dependencies);
      $.each(Object.keys(dependencies), function (i, dataview_id) {
        if (dataview_id == metric.dataview_id) {
          var column_dependencies = dependencies[dataview_id].COLUMN;
          var metric_dependencies = dependencies[dataview_id].METRIC;
          column_dependencies = $.grep(column_dependencies, function (c) {
            return c != 'COUNT';
          });

          var columns_not_found_in_metadata = $.grep(column_dependencies, function (c) {
            return all_columns.indexOf(c) == -1;
          });

          // now check if the given  context implies it is a metric edit.
          // If so make sure that the metric being edited is not a dependency for this metric.
          var is_metric_a_dependency = false;
          if (context.hasOwnProperty('metric_internal_name')) {
            if (context.metric_internal_name) {
              if (metric_dependencies.indexOf(context.metric_internal_name) != -1) {
                // TODO: SENTRYERROR:FRONTEND-HE:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/465455341
                // TODO: SENTRYERROR:FRONTEND-HW:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/469039670
                is_metric_a_dependency = true;
              }
            }
          }

          all_dependencies_met = columns_not_found_in_metadata.length == 0 && !is_metric_a_dependency;
          if (!all_dependencies_met) {
            return true;
          }
        }
      });
      return all_dependencies_met;
    }

    return $.grep(metrics, function (metric, i) {
      return _check(metric);
    });
  }


}
