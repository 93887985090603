///<reference path="../../../../node_modules/@types/jqueryui/index.d.ts"/>
import * as _ from 'lodash-es';
import * as angular from 'angular';
import * as dateformat from 'dateformat';
import * as $ from 'jquery';
import * as moment from 'moment';
import * as Flatpickr from 'flatpickr';
import * as confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import * as Tether from 'tether';
import {numberWithCommas} from '../explore/utils';
import { isArray } from 'angular';


var common_validators = {
  notStartsWithAlpha: function (modelValue, viewValue) {
    return (!viewValue) || !(/[^a-zA-Z]/.test(viewValue[0]));
  },
  onlyAlphaNum: function (modelValue, viewValue) {
    return (!viewValue) || !!viewValue.match(/^[\w\d\s]*$/);
  }
};


/**
 * @ngInject
 */
export function selectAllOnFocus() {
  return {
    link: function (s, e, a) {
      //select on focus
      $(e).focus(function () {
        $(this).select();
      });
      //select if focused right now
      if ($(e).is(":focus")) {
        $(this).select();
      }
    }
  }
}

bindScopeDisplayContent.$inject = ['$compile']
export function bindScopeDisplayContent($compile){
  return {
    link: function (scope, element, attrs) {
      function _render() {
        let htmlContent = scope.$eval(attrs.bindScopeDisplayContent);
        let compiled_and_bound: any = $compile(htmlContent)(scope);
        $(element).html(compiled_and_bound);
      }
      scope.$watch(attrs.bindScopeDisplayContent, _render)
    }
  }
}
/**
 * @ngInject
 */
onScrolledToEnd.$inject = ['utils'];
export function onScrolledToEnd(utils) {
  return {
    link: function (scope, element, attrs) {
      //select on focus
      let threshold = parseFloat(attrs.onScrolledToEndOffsetPercentage);
      $(element).on('scroll', _scrollCb);
      let dcb = utils.debounce(callback, 2000, true);
      function _scrollCb(){
        let domEle = $(element)[0];
        let scrollPercentage = 100* domEle.scrollTop/domEle.scrollHeight;
        if(scrollPercentage > threshold){
          dcb();
        }
      }

      function callback(){
        scope.$eval(attrs.onScrolledToEnd);
      }

    }
  }
}


/**
 * Following directive needs a rewrite!
 *
 * @ngInject
 *
 * */
colTypeInput.$inject = ['$compile', 'c', 'utils'];
export function colTypeInput($compile, c, utils) {
  return {
    restrict: 'E',
    require: 'ngModel',
    link: function (scope, element, attrs, ctrl) {
      var isolated_scope, operand_type = 'input', column_type, options, _prev_isolated_scope;
      var _default_options = {
        'required': false,
        forceTextType: false // when true use input type 'text' instead of 'number'
      };
      var specialDateInput = false;
      let currentDateTimeInput = false;
      _read_options();
      init_isolated_scope();

      function init_isolated_scope() {
        // This method runs every time col type is changed
        isolated_scope = scope.$new();
        let val = scope.$eval(attrs.ngModel);
        let modelValue = (val || val === 0) ? val : null;
        if (column_type === c.text) {
          modelValue = _toText(scope.directiveModel);
        }
        if (column_type === c.numeric && options.forceTextType) {
          modelValue = _toNumIfValid(scope.directiveModel);
        }
        isolated_scope.directiveModel = modelValue;
        isolated_scope.$watch('directiveModel', function (value) {
          ctrl.$setViewValue(value);
        }, true);
      }
      let context = attrs.context;

      // watch on operator and column change to make sure that the input box of operand is focussed
      scope.$watchGroup([attrs.operatorValue, attrs.column], function (nv, ov) {
        if(nv != ov){
          fillHTML();
        }
      },true);

      scope.$watch(attrs.operandType, function (operandType) {
        if (operandType !== undefined && operandType !== operand_type) {
          operand_type = operandType;
          fillHTML();
        }
      });

      scope.$watch(attrs.columnType, function (cType) {
        if (cType != column_type) {
          column_type = cType;
          fillHTML();
        }
      });

      scope.$watch(attrs.specialDateCase, function (sdi) {
        if (sdi != specialDateInput) {
          specialDateInput = sdi;
          fillHTML();
        }
      });
      scope.$watch(attrs.currentDateTimeInput, function (cdi) {
        if (cdi != currentDateTimeInput) {
          currentDateTimeInput = cdi;
          fillHTML();
        }
      });

      scope.$watch(attrs.ngModel, function (value) {
        isolated_scope.directiveModel = value;
        scope.directiveModel = value;
      });

      scope.$watch(attrs.selectedType , function (selectedType){
        fillHTML();
      }, true);

      function getElementToAppend(ctype, model_name, autofocus=true) {
        let itemId = 'c' + utils.string.random(2);
        if(autofocus){
          scope[itemId] = true;
        }
        else{
          scope[itemId] = false;
        }
        var element_to_append;
        var required = "";
        if (options.hasOwnProperty('required') && options.required) {
          required = typeof options.required !== 'string' ? "true" : options.required;
          required = ' ng-required="' + required + '" ';
        }
        // whether a null value is allowed in the input?
        let nullAllowed = false;
        if(options.hasOwnProperty('null_allowed')){
          nullAllowed = options.null_allowed;
        }
        var _common_string = 'ng-model="' + model_name + '"' +
                             ' ma-validate ' +
                             'set-focus-on-error ' +
                             'null-allowed="'+ nullAllowed +'"' +
                             'context="'+ context +'"'
                               required +
                             ' set-focus-on-true="'+itemId+'" ' +
                             ' ng-model-options="{debounce:{ \'default\': 500, \'blur\': 0}, \'allowInvalid\': true }" ';
        var _common_string_date = 'ng-model="' + model_name + '"' +
                                  ' ma-validate ' +
                                  ' set-focus-on-error ' +
                                  'context="'+ context +'"'
                                    required +
                                  ' ng-model-options="{debounce:{ \'default\': 500, \'blur\': 0}, \'allowInvalid\': true }" ';

        if (options.typeAheadOptions && ctype == "TEXT") {
          var _t = "";
          angular.forEach(options.typeAheadOptions, function (v, k) {
            _t += " " + k + '="' + v + '" ';
          });
          _common_string += _t;
        }
        if (ctype == "DATE") {
          if (specialDateInput) {
            element_to_append = '<date-input-for-condition ng-model="' + model_name + '" comparison-op="' + attrs.comparisonOp + '">' +
                                '</date-input-for-condition>' +
                                '<input type="text" ng-hide="true" ' + _common_string + '>';
          }
          else if (currentDateTimeInput){
            element_to_append = '<current-date-time-input ng-model="' + model_name + '"></current-date-time-input>'
          }
          else {
            var defaultVal = dateformat(new Date(), 'yyyy-mm-dd') + ' 00:00:00';
            if (isolated_scope.$eval(model_name)) {
              defaultVal = isolated_scope.$eval(model_name);
            }

            element_to_append = '<input placeholder="Choose date value"' +
                                       'class="dateinput me-2" type="text" '
                                       + _common_string_date +
                                       'val-date title="{{parsedDateVal}}" datepicker default-date="\'' + defaultVal + '\'">';
          }
        }
        else if (ctype == "NUMERIC") {
          element_to_append = '<input type="' + (options.forceTextType ? "text" : "number") + '" placeholder="Enter Numeric value" ' +
              'class="me-2" ' + _common_string +
            ' val-number '+ '>';
        }
        else {
          // whitespaces
          var ws = "";
          if (options.trim_whitespaces) {
              ws = 'ng-trim=' + options.trim_whitespaces + ' ';
          }
          element_to_append = '<input type="text" placeholder="Type value e.g. red" ' + ws + _common_string + '>';
        }
        return element_to_append;
      }

      function getOperandSelectionElement(model_type){
        /*
        It expects model type (in form of [1],[2] etc) in case when multiple
        operand selection box are needed otherwise it can be null (for one selection box).
         */
        if (model_type == null ){
          model_type = "";
        }
        var operatorSelectionBody = "<div class=\"styled-select\" ng-if = \"atomicCondition.unaryOperators.indexOf(atomicCondition.operator.value) == -1 \">\n" +
          "  <select ng-disabled=\"!atomicCondition.column\"\n" +
          "          ng-if=\"(['IS_NOT_EMPTY', 'IS_EMPTY', 'IS_NOT_MINVAL', 'IS_MINVAL', 'IS_NOT_MAXVAL', 'IS_MAXVAL' ].indexOf(atomicCondition.operator.value) == -1)\"\n" +
          "          ng-class=\"{'option-selected': atomicCondition.operator}\"\n" +
          "          ng-model=\"atomicCondition.typeSelection" + model_type + "\"\n" +
          "          ng-change = \"atomicCondition.soft_reset(" + model_type +"); atomicCondition.setAllowedColumns(atomicCondition.column)\"\n" +
          "          ng-options=\"operand_type.internal_name as operand_type.display_name for operand_type in atomicCondition.operandTypes\">\n" +
          "    <option value=\"\" disabled=\"disabled\">Select an operand type</option>\n" +
          "  </select>\n" +
          "</div>";
        return operatorSelectionBody;
      }
      function  getColumnSelectionElement(model_type){
        /*
        It expects model type (in form of [1],[2] etc) in case when multiple
        column selection box are needed otherwise it can be null (for one selection box).
         */
        if (model_type == null ){
          model_type = "";
        }
        var columnSelectionBody =
          "    <div class=\"styled-select\"  ng-if = \" atomicCondition.typeSelection" + model_type + " == 'Column' \">\n" +
          "      <select ng-model=\"atomicCondition.selectedColumn" + model_type + "\"\n" +
          "              ng-show=\"atomicCondition.operator\"\n" +
          "              ng-class=\"{'option-selected': atomicCondition.column}\"\n" +
          "              ng-options=\"column.internal_name as column.display_name_w_type for column in atomicCondition.allowedColumns | filterColumnsInTargetDs: atomicCondition.column.type  | orderBy:'display_name_w_type'\"\n" +
          "              ng-change = \"atomicCondition.deselectColumn(" + model_type +");\"\n" +
          "              ma-validate\n" +
          "              required>\n" +
          "    <option value=\"\" disabled=\"disabled\">Select a column</option>\n" +
          "  </select>\n" +
          "  </div>";
        return columnSelectionBody;
      }

      function fillHTML() {
        // backup prev isolated scope
        if (isolated_scope) {
          _prev_isolated_scope = isolated_scope;
        }
        init_isolated_scope();
        if (operand_type !== 'none') {
          var dom2append;

          let val = scope.$eval(attrs.ngModel);
          let modelValue = (val || val === 0) ? val : null;
          scope.directiveModel = modelValue;

          if (column_type === c.text) {
            scope.directiveModel = _toText(scope.directiveModel);
            ctrl.$setViewValue(scope.directiveModel);
          }

          if (column_type === c.numeric && !options.forceTextType) {
            scope.directiveModel = _toNum(scope.directiveModel);
            ctrl.$setViewValue(scope.directiveModel);
          }
          if (column_type === c.numeric && options.forceTextType) {
            scope.directiveModel = _toNumIfValid(scope.directiveModel);
            ctrl.$setViewValue(scope.directiveModel);
          }

          if (!angular.isArray(operand_type)) {
            dom2append = [];
            /*
            In case of single operand types (GT,EQ etc.), push one Value/Column Value selection box
            into DOM.
             */
            var operandSelectionElement = getOperandSelectionElement(null);
            var colSelectionDom = $compile($(operandSelectionElement))(isolated_scope);
            dom2append.push(colSelectionDom);

            let typeSelection = scope.$eval(attrs.selectedType);
            /*
            If user selects operand type as column type, push column selection box
            into DOM.
             */
            if (typeSelection == 'Column'){
              var columnElementToAppend = getColumnSelectionElement(null);
              dom2append.push($compile($(columnElementToAppend))(isolated_scope));
            }
            else {
              /*
            If user selects operand type as Value type, push Value input box
            into DOM.
             */
              var element2append = getElementToAppend(column_type, 'directiveModel');
              dom2append.push($compile($(element2append))(isolated_scope));
            }
          }
          else {
            if (!angular.isArray(modelValue) || (angular.isArray(modelValue) && modelValue.length !== operand_type.length)) {
              scope.directiveModel = [];
              operand_type.forEach(function () {
                scope.directiveModel.push(null);
              });
              ctrl.$setViewValue(scope.directiveModel)
            }

            dom2append = [];
            var typeSelection =  scope.$eval(attrs.selectedType);
            $.each(operand_type, function (i, ot) {
              /*
              Push n numbers of operator selection box in case operation with n number of operands.
              Eg, for in between operator, push two operator selection box
               */
              var operandSelectionElement = getOperandSelectionElement("[" + i + "]");
              var operandSelectionDom = $compile($(operandSelectionElement))(isolated_scope);
              dom2append.push(operandSelectionDom);

             if (typeSelection[i] == 'Column') {
               /*
            If user selects ith operand type as column type, push ith column selection box
            into DOM.
             */
               var columnElementToAppend = getColumnSelectionElement("[" + i + "]");
               dom2append.push($compile($(columnElementToAppend))(isolated_scope));
             } else {
               /*
            If user selects ith operand type as Value type, push ith Value input box
            into DOM.
             */
               var element2append = getElementToAppend(column_type, "directiveModel[" + i + "]", i == 0);
               dom2append.push($compile($(element2append))(isolated_scope));
             }
            });
          }
          _remove_prev_element();
          $(element).append(dom2append);
        } else {
          _remove_prev_element();
        }
      }

      function _toText(modelValue) {
        if (!angular.isArray(modelValue)) {
          return (modelValue === null || modelValue === undefined) ? modelValue : modelValue + '';
        } else {
          angular.forEach(modelValue, function (val, i) {
            modelValue[i] = (val === null || val === undefined) ? val : val + '';
          });
          return modelValue;
        }
      }

      function _toNum(modelValue) {
        if (!angular.isArray(modelValue)) {
          if (typeof modelValue === "string") {
            modelValue = parseFloat(modelValue.replace(/[^\d\.]/g, ""));
          }
          return (modelValue === null || modelValue === undefined) ? modelValue : (isNaN(modelValue) ? null : modelValue);
        } else {
          angular.forEach(modelValue, function (val, i) {
            if (typeof val === "string") {
              val = parseFloat(modelValue.replace(/[^\d\.]/g, ""));
            }
            modelValue[i] = (val === null || val === undefined) ? val : (isNaN(val) ? null : val);
          });
          return modelValue;
        }
      }

      function _toNumIfValid(modelValue) {
        if (!angular.isArray(modelValue)) {
            modelValue = (modelValue || modelValue === 0) ? modelValue : null;
            // convert to numeric type if the value is a valid number else return as it is
            if ((modelValue + "").trim() === "") {
                return null
            } else if (modelValue !== null && (modelValue + "").trim() !== "" && isFinite(Number((modelValue + "").replace(/,/g, "")))) {
                // if the modelValue is a valid number, parse it to numeric type
                return parseFloat((modelValue + "").replace(/,/g, ""));
            } else return modelValue
        } else {
            angular.forEach(modelValue, function (val, i) {
                // convert to numeric type if the value is a valid number else return as it is
                if ((modelValue + "").trim() === "") {
                    return null
                } else if (modelValue !== null && (modelValue + "").trim() !== "" && isFinite(Number((val + "").replace(/,/g, "")))) {
                    // if the modelValue is a valid number, parse it to numeric type
                    modelValue[i] = parseFloat((val + "").replace(/,/g, ""));
                } else {
                    modelValue[i] = val;
                }
            });
            return modelValue;
        }
      }

      function _remove_prev_element() {
        // delete prev isolate scope if any
        if (_prev_isolated_scope) {
          _prev_isolated_scope.$destroy();
          _prev_isolated_scope = undefined;
        }
        $(element).empty();
      }

      function _read_options() {
        // init options
        if (options === undefined) {
          options = angular.extend({}, _default_options);
        }
        // read options if available
        if (attrs.colTypeInputOptions) {
          options = angular.extend({}, _default_options, scope.$eval(attrs.colTypeInputOptions));
        }
        if (attrs.typeAheadOptions) {
          options.typeAheadOptions = scope.$eval(attrs.typeAheadOptions);
        }
      }
    }
  };
}

/**
 * @ngInject
 *
 * */
export function pauseChildrenWatchersIf() {
  return {
    link: function (scope, element, attrs) {
      scope.$watch(attrs.pauseChildrenWatchersIf, function (newVal) {

        if (newVal === undefined) {
          return;
        }
        if (newVal) {
          toggleChildrenWatchers(element, true);
        } else {
          toggleChildrenWatchers(element, false);
        }
      });
      function toggleChildrenWatchers(element, pause) {
        angular.forEach(element.children(), function (childElement) {
          toggleAllWatchers(angular.element(childElement), pause);
        });
      }

      function toggleAllWatchers(element, pause) {
        var data = element.data();
        if (data.hasOwnProperty('$scope') && data.$scope.hasOwnProperty('$$watchers') && data.$scope.$$watchers) {
          if (pause) {
            data._bk_$$watchers = [];
            $.each(data.$scope.$$watchers, function (i, watcher) {
              data._bk_$$watchers.push($.extend(true, {}, watcher));
            });

            data.$scope.$$watchers = [];
          } else {
            if (data.hasOwnProperty('_bk_$$watchers')) {
              $.each(data._bk_$$watchers, function (i, watcher) {
                data.$scope.$$watchers.push($.extend(true, {}, watcher));
              });
            }
          }

        }
        toggleChildrenWatchers(element, pause);
      }
    }
  };
}

/**
 * @ngInject
 *
 * */
export function includeReplace() {
  return {
    require: 'ngInclude',
    restrict: 'A', /* optional */
    link: function (scope, el, attrs) {
      el.replaceWith(el.children());
    }
  };
}


/**
 * @ngInject
 *
 * */
valDate.$inject = ['moment'];
export function valDate(moment) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, elm, attrs, ctrl) {
      ctrl.$validators.valDate = function (modelValue, viewValue) {
        if (ctrl.$isEmpty(viewValue)) {
          // empty models is valid
          return true;
        }
        return /^\d{4}\-\d{1,2}\-\d{1,2}(\s\d{1,2}:\d{1,2}:\d{1,2})?$/.test(viewValue) &&
          moment.utc(viewValue, "YYYY-MM-DD HH:mm:ss").isValid();
      };

      function parse_date_val(val) {
        var momentVal = moment.utc(val + " +0000", "YYYY-MM-DD HH:mm:ss Z");
        if (/^\d{4}\-\d{1,2}\-\d{1,2}(\s\d{1,2}:\d{1,2}:\d{1,2})?$/.test(val) && momentVal.isValid()) {
          scope.parsedDateVal = momentVal.format('dddd, MMMM Do YYYY, h:mm:ss a');
          return momentVal.format('YYYY-MM-DD HH:mm:ss');
        } else {
          return val;
        }

      }

      ctrl.$parsers.push(parse_date_val);
    }
  };
}


valDuplicateCol.$inject = ['utils']
export function valDuplicateCol(utils) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valDuplicateCol(scope, elem, attrs, ctrl) {
      var duplicateCount = attrs.duplicateCount || 0;
      duplicateCount = parseInt(duplicateCount);
      ctrl.$validators.valDuplicateCol = function (modelValue, viewValue) {
        var taskwise_info = scope.$eval(attrs.valDuplicateCol);
        var display_properties = scope.$eval(attrs.wkspDisplayProperties);

        var task_info = scope.$eval(attrs.taskInfo);
        var display_names = []
        var current_step_output_col_internal_names = []
        var current_step_output_col_display_names = []
        if (taskwise_info) {
          _.forEach(taskwise_info, function (io_metadata) {
            if (_.toInteger(io_metadata['task_sequence']) == 0) {
              var display_names_1 = _.map(io_metadata['metadata'], 'display_name')
              display_names = _.concat(display_names, display_names_1)
            }
            else if (task_info && task_info.sequence == io_metadata['task_sequence']) {
              current_step_output_col_internal_names = _.map(io_metadata['outputs'], 'dependency')
              for (var i in current_step_output_col_internal_names) {
                var column = utils.metadata.get_column_by_internal_name(io_metadata['metadata'], current_step_output_col_internal_names[i])
                if (column) {
                  current_step_output_col_display_names.push(column['display_name'])
                  if (display_properties.hasOwnProperty('COLUMN_NAMES') && display_properties['COLUMN_NAMES'].hasOwnProperty(column['internal_name'])){
                    current_step_output_col_display_names.push(display_properties['COLUMN_NAMES'][column['internal_name']])
                  }

                }
              }
            }
            else {
              var internal_names = _.map(io_metadata['outputs'], 'dependency')
              var disp_names = []
              for (var i in internal_names) {
                var column = utils.metadata.get_column_by_internal_name(io_metadata['metadata'], internal_names[i])
                if (column) {
                  disp_names.push(column['display_name'])
                }
              }
              display_names = _.concat(display_names, disp_names)
            }
          })
          // To fix MVP-4768: Ignore renamed values from display property for the columns whose new names are same as original
          // To ensure this verify the renamed values against display_names used in the whole pipeline
          if( display_properties.hasOwnProperty('COLUMN_NAMES')){
            _.forEach(display_properties.COLUMN_NAMES, function(renamed_value, internal_name){
              if(display_names.indexOf(renamed_value)== -1 && current_step_output_col_display_names.indexOf(renamed_value)==-1){
                display_names.push(renamed_value)
              }
            })
          }

          return display_names.indexOf(viewValue) == -1;

        } else {
          return true;
        }
      };
    }
  };
}


/*
 * Checks if the column name uses any of the reserved column names - case-insensitive
 */
valReservedColName.$inject = ['c'];
export function valReservedColName(c) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valReservedColNameLink(scope, elem, attrs, ctrl) {
      ctrl.$validators.valReservedColName = function (modelValue, viewValue) {
        if(viewValue){
          if(c.RESERVED_BATCH_COLUMN_NAMES.findIndex(item => viewValue.toLowerCase() === item.toLowerCase()) != -1){
            return false;
          }
        }
        return true;
      };
    }
  };
}
/**
 * @ngInject
 * */
export function validateMetricName() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valDuplicateMetricName(scope, elem, attrs, ctrl) {
      ctrl.$validators.validateMetricName = function (modelValue, viewValue) {
        if (!viewValue) {
          return false;
        }
        var args = scope.$eval(attrs.validateMetricName);
        var context = args.context;
        var element = args.element;
        var other_metrics_in_dataview;
        if (element.id) {
          other_metrics_in_dataview = $.grep(context.dataview.metrics, function (m: any) {
            return (m.id != element.id);
          })
        }
        else {
          other_metrics_in_dataview = $.grep(context.dataview.metrics, function (m: any) {
            return (m.internal_name != context.metric_internal_name);
          })
        }
        var metrics_with_same_display_name = $.grep(other_metrics_in_dataview, function(m: any){
        return m.display_name.trim().toLowerCase() == viewValue.trim().toLowerCase();
        })
        var is_valid = viewValue && (metrics_with_same_display_name.length == 0)
        return is_valid;
      };
      ctrl.$validators.notStartsWithAlpha = common_validators.notStartsWithAlpha;
      ctrl.$validators.onlyAlphaNum = common_validators.onlyAlphaNum;
    }
  };
}


/**
 * @ngInject
 */
datepicker.$inject = ['c', 'utils', '$timeout'];
export function datepicker(c, utils, $timeout) {
  return {
    require: 'ngModel',
    link: function datepicker(scope, elem, attrs, ctrl) {
      var picker,
        pickerId = utils.getRandomString(10),
        format;
      var defaultDate;
      var enableSeconds;
      var enableMinutes;
      var enableTime = true;
      var time_24hr = false;

      if (attrs.enableSeconds) {
        enableSeconds = scope.$eval(attrs.enableSeconds);
      }
      if (attrs.enableMinutes) {
        enableMinutes = scope.$eval(attrs.enableMinutes);
      }
      if (attrs.enableTime) {
        enableTime = scope.$eval(attrs.enableTime);
      }
      if (attrs.railwayTime) {
        time_24hr = scope.$eval(attrs.railwayTime);
      }
      if (enableSeconds === undefined) {
        enableSeconds = true;
      }
      if (enableMinutes === undefined) {
        enableMinutes = true;
      }
      let defaultDateFormat = c.defaultDateTimePickerFormat;
      if(!enableTime){
        defaultDateFormat = c.defaultDatePickerFormat;
      }
      format = evalAttributeValue(attrs.format, defaultDateFormat);

      if (attrs.enableSeconds) {
        enableSeconds = scope.$eval(attrs.enableSeconds);
      }

      function evalAttributeValue(attribute, defaultValue){
        let val = scope.$eval(attribute);
        return val ? val : defaultValue;
      }
      if(attrs.hasOwnProperty('defaultDate')){
        defaultDate = evalAttributeValue(attrs.defaultDate, dateformat(new Date(), 'yyyy-mm-dd') + ' 00:00:00');
      }

      defaultDate = scope.ngModel || defaultDate;
      var defaultOptions = {
        dateFormat: format,
        enableTime: enableTime,
        enableSeconds: !!enableSeconds,
        plugins: [new confirmDatePlugin({})],
        defaultDate: defaultDate,
        time_24hr: time_24hr,
        onChange: function (dateobj, dateStr) {
          $timeout(function () {
            if(!dateStr){
                ctrl.$setViewValue(null, "blur");
            } else if (dateStr != ctrl.$viewValue) {
              ctrl.$setViewValue(dateStr, "blur");
            }
          }, 200);
        },
        onClose: function (dateObj, dateStr) {
            if (!dateStr) {
                ctrl.$setViewValue(null, "blur");
            } else if (dateStr != ctrl.$viewValue) {
            ctrl.$setViewValue(dateStr, "blur");
          }
        }
      };
      if (elem[0]) {
        picker = new Flatpickr(elem[0], defaultOptions);
      }
      scope["picker" + pickerId] = picker;
      if (attrs.minDate) {
        scope.$watch(attrs.minDate, function (val) {
          picker.config.minDate = val;
        });
      }
      if (attrs.maxDate) {
        scope.$watch(attrs.maxDate, function (val) {
          picker.config.maxDate = val;
        });
      }
      scope.$on('$destroy', function () {
        if (picker) {
          picker.destroy();
        }
      });
    }
  };
}
/**
 * @ngInject
 */
timepicker.$inject = ['c', 'utils'];
export function timepicker(c, utils) {
  return {
    require: 'ngModel',
    link: function datepicker(scope, elem, attrs, ctrl) {
      var picker,
        pickerId = utils.getRandomString(10),
        format = attrs.format || c.defaultDatePickerFormat;
      var defaultOptions = {
        dateFormat: 'H:i:S',
        enableTime: true,
        enableSeconds: true,
        timeFormat: 'H:i:S',
        time_24hr: true,
        plugins: [new confirmDatePlugin({})],
        noCalendar: true,
        defaultDate: moment().hours(0).minutes(0).seconds(0).toDate(),
        onChange: function (dateobj, datestr) {
          ctrl.$setViewValue($.trim(datestr), "blur");
        }
      };
      if (elem[0]) {
        picker = new Flatpickr(elem[0], defaultOptions);
      }
      scope.$on('$destroy', function () {
        if (picker) {
          picker.destroy();
        }
      });
    }
  };
}

/**
 * @ngInject
 * For validating if the expression is a valid one.
 */
hasToBeValidExpression.$inject = ['utils'];
export function hasToBeValidExpression(utils) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (s, e, a, ctrl) {
      ctrl.$validators.hasToBeValidExpression = function (mV, vV) {
        return utils.math.isMathExpressionValid(vV);
      }
    }
  }
}
/**
 * @ngInject
 */
accordion.$inject = ['utils'];
export function accordion(utils) {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {
      $(elem).accordion({
        collapsible: true,
        heightStyle: "content",
        icons: false,
        animate: 150
      });
    }
  }
}
/**
 * @ngInject
 */
draggable.$inject = ['$timeout'];
export function draggable($timeout) {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {
      var jqueryUIOptions = scope.$eval(attr.draggableOptions) || {};
      var directiveConfig = scope.$eval(attr.draggable) || {};
      if (directiveConfig.dragModel && directiveConfig.dragValue) {
        $(elem).on("dragstart", dragStart);
        $(elem).on("dragstop", dragStop);
      }
      $(elem).draggable(jqueryUIOptions);
      if (directiveConfig.enabled) {
        scope.$watch(directiveConfig.enabled, function (enable) {
          if ($(elem).data("ui-draggable")) {
            if (enable) {
              $(elem).draggable("enable");
            } else {
              $(elem).draggable("disable");
            }
          }
        });
      }

      function dragStart() {
        if ($(elem).data('ui-draggable').options.disabled) {
          return;
        }
        $timeout(function () {
          scope.$eval(directiveConfig.dragModel + ' = ' + directiveConfig.dragValue);
        });
      }

      function dragStop() {
        $timeout(function () {
          scope.$eval(directiveConfig.dragModel + ' = undefined');
        }, 200);
      }
    }
  }
}
/**
 * @ngInject
 */
onEnterKey.$inject = ['keyboardEvents', 'utils'];
export function onEnterKey(keyboardEvents, utils) {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {
      var func = attr.onEnterKey,
        unsub,
        randId = utils.string.random(10);
      if (func) {
        $(elem).focus(subscribeKeylistener);
        $(elem).blur(unsubscribe);
      }

      function subscribeKeylistener() {
        unsub = keyboardEvents.onKeyUp('keyPressListener' + randId, function (event) {
          if (elem.is(":focus") && event.which == 13) {
            scope.$eval(func);
          }
        });
      }

      function unsubscribe() {
        if (unsub) {
          unsub();
          unsub = undefined;
        }
      }
    }
  }
}

/**
 * @ngInject
 */
droppable.$inject = ['$timeout'];
export function droppable($timeout) {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {
      var jqueryUIOptionsDefaults = {
        greedy: true,
        hoverClass: "hover",
        classes: {
          "ui-droppable-hover": "hover"
        }
      };
      var directiveConfig = scope.$eval(attr.droppable) || {};
      var jqueryUIOptions = angular.merge(jqueryUIOptionsDefaults, scope.$eval(attr.droppableOptions) || {});
      var existingDrop = jqueryUIOptions.drop || function () {
        };
      jqueryUIOptions.drop = function (event, ui) {
        if ($(event.target.closest('.ui-droppable')).is(elem)) {
          if (directiveConfig.onDrop) {
            onDrop(event, ui);
          }
          existingDrop(event, ui);
          $(elem).addClass("ui-droppable-dropped");
          $timeout(function () {
            $(elem).removeClass("ui-droppable-dropped");
          }, 700)
        }
        return false;
      };
      $(elem).droppable(jqueryUIOptions);
      if (directiveConfig.enabled) {
        scope.$watch(directiveConfig.enabled, function (enable) {
          if ($(elem).data("ui-droppable")) {
            if (enable) {
              $(elem).droppable("enable");
            } else {
              $(elem).droppable("disable");
            }
          }
        });
      }

      function onDrop(event, ui) {
        scope.$eval(directiveConfig.onDrop);
        return false;
      }
    }
  }
}
/**
 * @ngInject
 */
export function hasToBeNonEmptyArray() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (s, e, a, ctrl) {
      ctrl.$validators.hasToBeNonEmptyArray = function (mV, vV) {
        return angular.isArray(vV) && vV.length != 0;
      }
    }
  }
}

/**
 * @ngInject
 */
dateFormatHasToBeNonNull.$inject = ['c'];
export function dateFormatHasToBeNonNull(c) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (s, e, a, ctrl) {
      ctrl.$validators.hasToBeNonNull = function (mV, vV) {
        if (vV !== null && vV !== undefined) {
          if (vV.date_format) {
            return true;
          }
          if(vV.hasOwnProperty(c.POSTGRES_DATE_FORMAT)){
            return true;
          }
        }
        return false;
      }
    }
  }
}

/**
 * @ngInject
 */
export function valInteger() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valIntegerLink(scope, ele, attr, ctrl) {
      ctrl.$validators.valInteger = function integerValidator(modelValue, viewValue) {
        return (viewValue % 1) === 0;
      };
    }
  };
}

/**
 * @ngInject
 */
export function validateViaFunction() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function valViaFunctionLink(scope, ele, attr, ctrl) {
      var valFn = scope.$eval(attr["validateViaFunction"]);

      ctrl.$validators.validateViaFunction = function (val) {
        return !!(valFn(val));
      };
    }
  };
}

/**
 * @ngInject
 */
export function valNumber() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valIntegerLink(scope, ele, attr, ctrl) {

      // For info on $parsers and $validators, refer to https://stackoverflow.com/questions/31029756/angularjs-parsers-vs-validators
      ctrl.$parsers.push(function (viewValue) {
        // parsers will run every time the ngModelController is updated with a new $viewValue
        // from the DOM, usually via user input
        if (viewValue === "") {
          // if the viewValue is empty string set it to null so that the validation passes for numeric col.
            return null;
        }
        // Parse the viewValue to numeric type only if it is a valid number.
        // isFinite returns true for null and empty string or spaces so we'll have to check for it separately
        else if (viewValue !== null && (viewValue + "").trim() !== "" && isFinite(Number((viewValue + "").replace(/,/g, "")))){
          // if the viewValue is a valid number, parse it to numeric type
          return parseFloat((viewValue + "").replace(/,/g, ""));
        }
        else {
            return viewValue;
        }
      });
      ctrl.$validators.valNumber = function numberValidator(modelValue, viewValue) {
        // if nulls are allowed, then consider a null value, a valid input
        if(attr.nullAllowed == 'true' && !viewValue){
            return true;
        }
        else if (viewValue+"".trim() === ""){
            return false;
        }
        else{
            let val: any = (viewValue + "").replace(/,/g, "");
            return !isNaN(val);
        }

      };
    }
  };
}

/**
 * @ngInject
 */
export function valEmail() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function valEmailLink(scope, ele, attr, ctrl) {
      ctrl.$validators.valEmail = function emailValidator(modelValue, viewValue) {
        var valValue;
        if (!_.isArray(modelValue)) {
          valValue = [modelValue];
        } else {
          valValue = modelValue;
        }
        var isValid = _.every(valValue, function (val) {
          return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(val + "");
        });
        return isValid;

      };
    }
  };
}


/**
 * @ngInject
 *
 * */
valRenameResource.$inject = ['DatasourceService', 'DataviewService', 'c', '$q', 'utils'];
export function valRenameResource(DatasourceService, DataviewService, c, $q, utils) {
  return {
    require: 'ngModel',
    scope: {
      valRenameResource: '=',
    },
    restrict: 'A',
    link: function ($scope, elm, attrs, ctrl) {
      $scope.$watch('valRenameResource',uponItemChange)

      function uponItemChange(v) {
        var item = v;
        var val_fn;

        if (item.type === c.resourceTypes.ds) {
          val_fn = DatasourceService.rename;
        } else if (item.type === c.resourceTypes.ws) {
          val_fn = DataviewService.rename;
        } else {
          return;
        }

        ctrl.$asyncValidators.valRenameResource = function (modelValue, viewValue) {
          var deferred = $q.defer();
          val_fn(item, viewValue, true).then(val_success, val_failure);

          function val_success() {
            utils.asyncErrors.reset(ctrl, "valRenameResource");
            deferred.resolve();
          }

          function val_failure(resp) {
            var data = resp.data;
            utils.asyncErrors.set(data, ctrl, "valRenameResource");
            deferred.reject();
          }

          return deferred.promise;
        };

      }

    }
  };
}

/**
 * @ngInject
 *
 * */
export function valColumnName() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, elm, attrs, ctrl) {
    }
  };
}

export function allowAlphaNum() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, elm, attrs, ctrl) {
      ctrl.$validators.notStartsWithAlpha = common_validators.notStartsWithAlpha;
      ctrl.$validators.onlyAlphaNum = common_validators.onlyAlphaNum;
    }
  };
}


/**
 * @ngInject
 * @ngdoc directive
 * @memberof app.common
 * */
maValidateForm.$inject = ['eventCallbackManagerFactory'];
export function maValidateForm(eventCallbackManagerFactory) {
  return {
    require: "?form",
    priority: 2,
    link: {
      pre: function (scope, element, attrs, form) {
        var on_submit_event = new eventCallbackManagerFactory('formSubmit');
        var revalidate_event = new eventCallbackManagerFactory('revalidateForm');
        form.on_submit = on_submit_event.add_callback;
        form.fire_submit = on_submit_event.fire_event;
        form.remove_on_submit = on_submit_event.remove_callback;

        form.on_revalidate = revalidate_event.add_callback;
        form.fire_revalidate = revalidate_event.fire_event;
        form.remove_on_revalidate = revalidate_event.remove_callback;
        element.on("submit", onsubmit);

        function onsubmit(event) {  // required to prevent actual HTML form submission
          event.stopImmediatePropagation();
          event.preventDefault();
          form.$setSubmitted();
          on_submit_event.fire_event();
          return false;
        }

      }
    }
  };
}

/**
 * @ngInject
 * @ngdoc directive
 * @memberof app.common
 * */
maValidateSubmit.$inject = ['$log'];
export function maValidateSubmit($log) {
  return {
    priority: 1,
    link: {
      pre: function (scope, elem, attrs) {
        var form, form_element;

        if (attrs.form) {
          form = scope.$eval(attrs.form);
        }
        else {
          form_element = $(elem).parents('form').first();
          form = angular.element(form_element).scope()[form_element.attr('name')];
        }

        if (!form) {
          $log.error('Form not found for maValidateSubmit. ' +
            'It should either be enclosed in a form or should have the form attribute', elem);
          return;
        }

        elem.on("click", onsubmitclicked);

        if (form_element) {
          form_element.on("keypress", onkeypress);
        }

        function onsubmitclicked(event) {
          form.$setSubmitted();
          form.fire_submit();
          if (!form.$valid) {
            event.stopImmediatePropagation();
            event.preventDefault();
          }
        }

        function onkeypress(event) {
          if (event.keyCode === 13) {
            elem.click();
            event.stopImmediatePropagation();
            event.preventDefault();
          }
        }
      }
    }
  };
}
/**
 * @ngInject
 * @ngdoc directive
 * @memberof app.common
 * */
export function maRevalidateOnModelChange() {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, elem, attrs, ctrl) {
      scope.$watchCollection(attrs.ngModel, formatter);
      function formatter() {
        ctrl.$validate();
      }
    }
  };
}


/**
 * @ngInject
 * @ngdoc directive
 * @memberof app.common
 * */
maValidate.$inject = ['$timeout', '$q', 'utils', 'validationService'];
export function maValidate($timeout, $q, utils, validationService) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, elem, attrs, ctrl) {
      var form, form_element, dereg, tooltip, tooltip_show_promise, _form_inited = false, _input_id = utils.getRandomString();
      _get_form();
      _add_listeners();
      _add_tooltip();
      ctrl.$validate();
      $timeout(function () {
        scope.$evalAsync(function () {
          _get_form();
          _add_listeners();
          _add_tooltip();
          ctrl.$validate();
        });
      }, 200);

      // set invalid class if invalid
      function check_and_set_invalid_class() {
        if (ctrl.$invalid) {
          elem.addClass('invalid');
          tooltip && tooltip.set('content.text', "");
          var error_messages = get_error_messages();
          if (tooltip && error_messages.length) {
            tooltip.set('content.text', error_messages.join("<br/>"));
            tooltip_show_promise = $timeout(function () {
              tooltip.enable();
              if (elem.is(':focus')) {
                tooltip.show();
              }
            }, 200);
          }
        }
      }

      function get_error_messages() {
        var error_messages = [];
        var error_attr = scope.$eval(attrs.maValidate);
        angular.forEach(ctrl.$error, function (is_true, err: any) {
          err = err + "";
          if (is_true && ((error_attr && error_attr.hasOwnProperty(err)) ||
            (ctrl.hasOwnProperty('maValAsyncErrors') && ctrl.maValAsyncErrors.hasOwnProperty(err))) ||
            (validationService.getErrorMessage(err))
          ) {
            var error_message = (ctrl.maValAsyncErrors && ctrl.maValAsyncErrors[err]) ?
              ctrl.maValAsyncErrors[err] : (error_attr ? error_attr[err] : undefined);
            error_message = error_message ? error_message : validationService.getErrorMessage(err);
            error_message && error_messages.push(error_message);
          }
        });
        return error_messages;
      }

      function unset_invalid_class() {
        ctrl.$setUntouched();
        form.$setPristine();
        elem.removeClass('invalid');
        if (tooltip) {
          $timeout.cancel(tooltip_show_promise);
          tooltip.hide();
          tooltip.disable();
        }
      }

      function _add_listeners() {
        elem.on('blur change', function () {
          scope.$evalAsync(check_and_set_invalid_class);
        });

        elem.on('keydown', function (event) {
          if (event.type === "keydown" && (event.keyCode === 13 || event.keyCode === 9)) {
            return;
          }
          scope.$evalAsync(unset_invalid_class);
        });
        elem.on('$destroy', _cleanup);
        ctrl.$asyncValidators.alwaysTrue = function () {
          if (!ctrl.$invalid && form) {
            scope.$evalAsync(unset_invalid_class);
          }
          return $q.resolve();
        };
        dereg = scope.$on('$destroy', _cleanup);

        // special case for radio buttons
        if (elem.attr('type') === 'radio') {
          // if radio button get all other radio elements within the form and add listener for each
          var _radio_selector = elem.attr('name');
          if (_radio_selector) {
            _radio_selector = 'input[name=' + _radio_selector + ']';
            angular.forEach(form_element.find(_radio_selector), function (_radio_elem) {
              $(_radio_elem).on('focus keydown', function () {
                scope.$evalAsync(unset_invalid_class);
              });
            });
          }
        }
      }

      function revalidate_input() {
        ctrl.$validate();
      }

      function _get_form() {
        if (_form_inited) {
          return
        }
        if (form === undefined && $(elem).parents('form').length) {
          _form_inited = true;
          form_element = $(elem).parents('form').first();
          form = angular.element(form_element).scope().$eval(form_element.attr('name'));
          form.$addControl(ctrl);
          form.on_submit(_input_id, check_and_set_invalid_class);
          form.on_revalidate(_input_id, revalidate_input);
        }
      }

      function _add_tooltip() {
        try {
          if (typeof elem.qtip === 'function') {
            elem.qtip({
              content: {
                text: "Invalid"
              },
              style: {
                classes: 'qtip-tipsy'
              },
              position: {
                my: 'top left',
                at: 'bottom left',
                adjust: {
                  x: -5
                }
              },
              show: {
                event: 'focus mouseenter'
              },
              hide: {
                event: 'blur mouseleave',
                inactive: 5000
              }
            });
            tooltip = elem.qtip('api');
            tooltip.disable();
          }
        } catch (e){
          console.error(e);
        }
      }

      function _cleanup() {
        tooltip && tooltip.destroy(true);
        if (elem.attr('type') === 'radio') {
          // if radio button get all other radio elements within the form and add listener for each
          var _radio_selector = elem.attr('name');
          if (_radio_selector) {
            _radio_selector = 'input[name=' + _radio_selector + ']';
            angular.forEach(form_element.find(_radio_selector), function (_radio_elem) {
              $(_radio_elem).off();
            });
          }
        }
        if (form) {
          form.remove_on_revalidate(_input_id);
          form.remove_on_submit(_input_id);
          form.$removeControl(ctrl);
          form_element.off();
          elem.off();
          dereg();
        }
      }
    }
  };
}
/**
 * @ngInject
 * */
mainKeypressListener.$inject = ['$window', 'keyboardEvents'];
export function mainKeypressListener($window, keyboardEvents) {
  return {
    link: function () {
      if (!keyboardEvents._isRegistered) {
        angular.element($window).keyup(keyUpHandler);
        angular.element($window).keydown(keyDownHandler);
        angular.element($window).blur(unbindAll);
        keyboardEvents._isRegistered = true;
      }

      function keyUpHandler(event) {
        keyboardEvents.keyUp(event.which);
        keyboardEvents.fireOnKeyUp(event);
      }

      function keyDownHandler(event) {
        keyboardEvents.keyDown(event.which);
      }

      function unbindAll(event) {
        keyboardEvents.unbindAll();
      }
    }
  };
}
/**
 * @ngInject
 * */
mainWindowListener.$inject = ['$window', '$document', 'windowEvents', '$timeout'];
export function mainWindowListener($window, $document, windowEvents, $timeout) {
  return {
    link: function () {
      var win = angular.element($window);
      win.bind('resize', windowEvents.resize);
      $window.addEventListener('offline', function (e) {
        windowEvents.setNetworkStateChange(false);
      });
      $window.addEventListener('online', function (e) {
        windowEvents.setNetworkStateChange(true);
      });

    }
  }
}
/**
 * @ngInject
 * */
mainClicksListener.$inject = ['$window', '$document', 'clickEvents', 'keyboardEvents', '$timeout'];
export function mainClicksListener($window, $document, clickEvents, keyboardEvents, $timeout) {
  return {
    link: function () {
      var win = angular.element($window);
      win.click(clickHandler);
      win.bind("contextmenu", rightClickHandler);
      var isTextSelected = false;
      var selection = undefined;
      var cachedEvent = undefined;

      keyboardEvents.onKeyUp("monitorChangesInSelect", function () {
        if (isTextSelected) {
          selectHandler();
        }
      });

      function clickHandler(event) {
        clickEvents.click(event);
        $timeout(function () {
          selectHandler(event, true);
        }, 100);
      }

      function rightClickHandler(event) {
        clickEvents.rightClick(event);
        keyboardEvents.keyUp(17); // ctrl should be down on right click
      }

      function selectHandler(event?, onClick?) {
        var newSelection = getSelectedText();
        if (newSelection == selection && !onClick) {
          return;
        }
        if (onClick) {
          cachedEvent = event;
        }
        selection = newSelection;
        if (selection) {
          clickEvents.select(selection, cachedEvent, window.getSelection());
          isTextSelected = true;
        } else {
          if (isTextSelected) {
            clickEvents.select(selection, cachedEvent, window.getSelection());
          }
          isTextSelected = false;
        }
      }

      function getSelectedText() {
        if ($window.getSelection) {
          return $window.getSelection().toString();
        } else if ($document.selection) {
          return $document.selection.createRange().text;
        }
        return undefined;
      };

    }
  };
}
/**
 * @ngInject
 * */
showSpinnerLoader.$inject = ['$compile', 'config', 'appMetaInfo', '$templateCache'];
export function showSpinnerLoader($compile, config, appMetaInfo, $templateCache) {
  return {
    restrict: 'A',
    link: function (s, e, a) {
      var loaderType;
      if (!s.loaderType && !a.hasOwnProperty("loaderType")) {
        loaderType = 'covercontent';
      } else {
        loaderType = s.loaderType ? s.loaderType : a.loaderType;
      }
      var new_scope = s.$new();
      new_scope.loaderType = loaderType;
      new_scope.hideOverlay = a.hideOverlay;
      var showSpinnerLoader = a.showSpinnerLoader;

      // special case
      if (a.hasOwnProperty('showOnAppLoading')) {
        new_scope.appMetaInfo = appMetaInfo;
        if (!showSpinnerLoader) {
          showSpinnerLoader = "false";
        }
        showSpinnerLoader += " || appMetaInfo.isPageLoading";
      }
      var template = $($templateCache.get(config.templates.spinnerLoader));
      template.attr('ng-show', showSpinnerLoader);
      var compile_template = $compile(template)(new_scope);
      e.prepend(compile_template);
    }
  };
}

smallInlineLoader.$inject = ['$compile'];
export function smallInlineLoader($compile) {
  return {
    template: '<i class="fa fa-spinner fa-pulse"></i>',
    link: function (scope, element, attrs) {
      element.addClass('small-inline-loader');
    }
  }
}

/**
 * @ngInject
 * */
windowUnloadListener.$inject = ['fileUploadService'];
export function windowUnloadListener(fileUploadService) {
  return {
    restrict: 'A',
    link: function () {

      $(window).bind('beforeunload', function () {
        var count = fileUploadService.fileUploadsInProgress;
        if (count) {
          return "File uploads are in progress.";
        }
      });
    }
  }

}

/**
 *@ngInject
 */
executeWindowFocusChecks.$inject = ['resources', 'UserWorkspace', '$window', 'Auth'];
export function executeWindowFocusChecks(resources, UserWorkspace, $window, Auth) {
  return {
    restrict: 'A',
    link: function () {
      Auth.on_check_token_success('start_polling', _start);
      Auth.on_logout('stop_polling', _stop);
      $window.document.addEventListener('visibilitychange', _visibilityListener);

      function _start() {
        const isSettingsTab = window.location.hash.includes('settings')
        if (!$window.document.hidden && !UserWorkspace.is_super_admin && !isSettingsTab) {
          resources.start_update_polling();
        } else {
          resources.stop_update_polling();
        }
      }

      function _stop() {
        resources.stop_update_polling();
      }

      function _visibilityListener() {
        !$window.document.hidden ? _start() : _stop()
      }
    }
  }
}

/**
 * @ngInject
 *
 */
scrollIntoViewCb.$inject = ['utils'];
export function scrollIntoViewCb(utils) {
  // Directive to scroll something into view with respect to its parent. Works on eventCallbackManagerFactory's events,
  // give it the add callback function. And it'll register a method that should be called when scrolling required.
  // Takes an optional html attribute 'scroll-into-view-on-enabled' that can be used to toggle this on or off
  return {
    link: function (scope, elem, attr) {
      var cb = scope.$eval(attr.scrollIntoViewCb);
      var _instanceId = "_scrollIntoViewCb" + utils.getRandomString(10);
      var _unsubscribeFn;
      var enabled = true;
      var direction = attr.scrollDirection || "vertical";
      var defaultViewPadding = {x: elem.context.clientWidth, y: elem.context.clientHeight};
      if (direction == "horizontal") {
        delete defaultViewPadding['y'];
      } else if (direction == "vertical") {
        delete defaultViewPadding['x'];
      }
      var viewPadding = attr.scrollIntoViewPadding ? scope.$eval(attr.scrollIntoViewPadding) : defaultViewPadding;
      if (typeof cb == "function") {
        _unsubscribeFn = cb(_instanceId, function () {
          if (attr.scrollIntoViewCbEnabled != undefined) {
            enabled = !!scope.$eval(attr.scrollIntoViewCbEnabled);
          }
          if (enabled) {
            setTimeout(function () {
               $(elem).scrollintoview({
                duration: 100,
                direction: direction,
                viewPadding: viewPadding,
              })
            },  _.toNumber(attr.scrollDelay) || 200);
          }
        })
      }
      scope.$on("$destroy", function () {
        if (_unsubscribeFn) {
          _unsubscribeFn();
        }
      })
    }
  }
}

/**
 * @ngInject
 *
 */
addBorderReplace.$inject = ['windowEvents'];
export function addBorderReplace(windowEvents) {
  return {
    scope: {
      bucketCount: '='
    },

    link: function (scope, elem, attr) {
      function addBorder(){
            if (scope.bucketCount * elem.context.clientWidth > $(elem).parent()[0].clientWidth) {
              $('.group-replace .content, .group-replace .add-bucket, .group-replace .bucket-list-container').addClass('add-border');
            }
            else {
                $('.group-replace .content, .group-replace .add-bucket, .group-replace .bucket-list-container').removeClass('add-border');
            }
      }
      scope.$watch('bucketCount', function () {
        addBorder()
      });
      windowEvents.onResize('_addBorder', addBorder);
    }
  }
}

// adds or removes a class to the html based on some condition
/*
Adds or removes a class(e.g. border) to a scrollable html element. Border can be added at the top or at the bottom.
Required attrs:
- scrollRequiredForTopBorder: how much scroll is needed to add the top border
- scrollRequiredForBottomBorder: how much scroll is needed to add the bottom border
 */
export function addAndRemoveClass() {
  return {
    link: function (scope, elem, attr) {
      elem[0].addEventListener('scroll', function(e) {
        // current scroll value
        var scrollValue = $(elem).scrollTop();
        var scrollHeight = $(elem)[0].scrollHeight - $(elem)[0].offsetHeight;
        // scrollRequiredForTopBorder: how much scroll is needed to add the top border
        if(attr.hasOwnProperty("scrollRequiredForTopBorder")){
          let scrollRequiredForTopBorder = parseInt(attr.scrollRequiredForTopBorder);
          if(scrollValue > scrollRequiredForTopBorder){
            $(elem).parent().addClass(attr.topClass);
          }
          else{
            $(elem).parent().removeClass(attr.topClass);
          }
        }
        // scrollRequiredForBottomBorder: how much scroll is needed to add the bottom border
        if(attr.hasOwnProperty("scrollRequiredForBottomBorder")){
          let scrollRequiredForBottomBorder = parseInt(attr.scrollRequiredForBottomBorder);
          if((scrollHeight - scrollValue) > scrollRequiredForBottomBorder){
            $(elem).parent().addClass(attr.bottomClass);
          }
          else{
            $(elem).parent().removeClass(attr.bottomClass);
          }
        }
      });
    }
  }
}

/**
 * @ngInject
 *
 */
multiSelect.$inject = ['keyboardEvents', 'clickEvents', 'utils', '$timeout'];
export function multiSelect(keyboardEvents, clickEvents, utils, $timeout) {
  return {
    link: function (scope, elem, attr) {
      var collection = scope.$eval(attr.multiSelect),
        rangeSelectCb = scope.$eval(attr.rangeSelectCallback),
        multiSelectCb = scope.$eval(attr.multiSelectCallback),
        singleSelectCb = scope.$eval(attr.singleSelectCallback),
        propKey = scope.$eval(attr.selectKey),
        startIndexOffset = attr.hasOwnProperty('multiSelectIndexOffset') ? attr.multiSelectIndexOffset : '0',
        prevIndex, clickLock;

      elem.on('click', onClick);
      scope.$on('$destroy', destroy);

      function onClick(e) {
        $timeout(function () {
          clickHandler(e);
        }, 200);
      }

      function destroy() {
        elem.off('click');
      }

      function clickHandler(e) {
        if (clickLock) {
          return;
        }
        if (!elem.is(e.target)) {
          collection = scope.$eval(attr.multiSelect);
          var _scope = angular.element(e.target).scope();
          var currIndex = _scope['$index'] + _scope.$eval(startIndexOffset);
          if (currIndex != undefined && currIndex > -1) {
            if (keyboardEvents.isKeyDown("shift") && prevIndex > -1) {
              handleRangeSelect(prevIndex, currIndex);
            } else if (keyboardEvents.isKeyDown("cmd") || keyboardEvents.isKeyDown("ctrl")) {
              handleMultiSelect(currIndex);
            } else {
              handleSingleSelect(currIndex);
            }
            prevIndex = currIndex;
          }
        }
      }

      function handleMultiSelect(currIndex) {
        var item = collection[currIndex];
        if (propKey && item.hasOwnProperty(propKey)) {
          item = item[propKey];
        }
        if (typeof multiSelectCb == "function") {
          multiSelectCb(item);
        }
      }

      function handleSingleSelect(currIndex) {
        var item = collection[currIndex];
        if (propKey && item.hasOwnProperty(propKey)) {
          item = item[propKey];
        }
        if (typeof singleSelectCb == "function") {
          singleSelectCb(item);
        }
      }

      function handleRangeSelect(startIndex, endIndex) {
        var _reverse = false;
        if (startIndex > endIndex) {
          _reverse = true;
        }
        [startIndex, endIndex] = _.sortBy([startIndex, endIndex]);
        if (!_reverse) {
          startIndex += 1;
          endIndex += 1;
        }
        var _subArray = collection.slice(startIndex, endIndex),
          subArray;
        if (propKey) {
          subArray = _.map(_subArray, function (item) {
            return item[propKey];
          })
        } else {
          subArray = _subArray;
        }
        if (typeof rangeSelectCb == "function") {
          rangeSelectCb(subArray);
        }
        clickEvents.clearSelection();
      }

    }
  }
}
/**
 * @ngInject
 *
 */
scrollIntoViewIf.$inject = ['$timeout'];
export function scrollIntoViewIf($timeout) {
  // Directive to scroll something into view with respect to its parent. Does it only one time, if the attr evaluates to true
  return {
    link: function (scope, elem, attr) {
      var cb = scope.$eval(attr.scrollIntoViewIf);

      if (cb) {
        setTimeout(function () {
          $(elem).scrollintoview({
            duration: 200,
            viewPadding: {y: 40},
          });
          if (attr.highlightAfterScroll && scope.$eval(attr.highlightAfterScroll)) {
            $timeout(function () {
              $(elem).addClass('highlight');
              $timeout(function () {
                $(elem).removeClass('highlight');
              }, 1000);
            }, 300);
          }
        }, _.toNumber(attr.scrollDelay) || 200);
      }
    }
  }
}

/**
 * @ngInject
 */
syncScroll.$inject = ['$timeout'];
export function syncScroll($timeout) {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {

      var scrollPosition = scope.$eval(attrs.syncScroll);
      scope.$watch(attrs.syncScroll, function (value) {
        $(element).scrollTop(value.top);
      }, true);

      $(element).scroll(_scrollCb);

      function _scrollCb() {
        $timeout(function () {
          scrollPosition.top = $(element).scrollTop();
        }, 0);
      }
    }
  }
}

/**
 * @ngInject
 */
setFocusOnInit.$inject = ['$timeout'];
export function setFocusOnInit($timeout) {
  return {
    link: function (scope, element, attrs) {
      $timeout(function () {
        if (!attrs.setFocusOnInit || scope.$eval(attrs.setFocusOnInit)) {
          element[0].focus();
        }
      }, 200);
    }
  };
}
/**
 * @ngInject
 */
setFocusOnError.$inject = ['$timeout'];
export function setFocusOnError($timeout) {
  return {
    link: function (scope, element, attrs, ctrl) {
      var context = scope.$eval(attrs.context);
      $timeout(function () {
        if (context && context.inEditMode) {
          if (element.context.classList.contains("ng-invalid") && !element.context.disabled) {
            element[0].focus();
            element.addClass('invalid');
            element[0].blur();
          }}
      }, 200);
    }
  };
}
/**
 * @ngInject
 */
setFocusOnTrue.$inject = ['$timeout'];
export function setFocusOnTrue($timeout) {
  return {
    link: function (scope, element, attrs) {
      var _prevVal = false;
      let delay = 200;

      if (attrs.focusDelay && isNormalInteger(attrs.focusDelay)) {
        delay = parseInt(attrs.focusDelay);
      }
      scope.$watch(attrs.setFocusOnTrue, function (val) {
        if (!_prevVal && val) {
          focus();
        }
        _prevVal = !!val;
      });

      function focus() {
        $timeout(function () {
          // TODO: SENTRYERROR:FRONTEND-7E:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/412117146
          element[0].focus();
        }, delay);
      }

      function isNormalInteger(str) {
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
      }
    }
  };
}
/**
 * @ngInject
 */
export function scrollToTopOnChange() {
  return {
    link: function (scope, element, attrs) {
      scope.$watch(attrs.scrollToTopOnChange, function (val) {
        if (val) {
          element.scrollTop(0);
        }
      });

    }
  };
}


/**
 * @ngInject
 */
onOutsideClick.$inject = ['$timeout', 'clickEvents', 'utils'];
export function onOutsideClick($timeout, clickEvents, utils) {
  return {
    link: function (scope, ele, attrs) {
      var uniqueId = utils.getRandomString(10);
      var unsubscribe = clickEvents.onClick("onOutsideClick" + uniqueId, function (e) {
        var check = true, exclude;
        if (attrs.hasOwnProperty("onOutsideClickIf")) {
          check = scope.$eval(attrs.onOutsideClickIf);
        }
        if (attrs.hasOwnProperty('onOutsideClickExcludeSelectors')) {
          exclude = attrs.onOutsideClickExcludeSelectors;
        }
        if (check) {
          var target = e.target;
          var element = ele[0];
          if (!$.contains(element, target) && !$(element).is(target)) {
            if (exclude && $(target).closest(exclude).length) {
              return;
            }
            $timeout(function () {
              var exp = scope.$eval(attrs.onOutsideClick);
              if (angular.isFunction(exp)) {
                exp();
              }
            }, 0);
          }
        }
      });
      scope.$on('$destroy', destroy);
      ele.on('$destroy', destroy);

      function destroy() {
        if (angular.isFunction(unsubscribe)) {
          unsubscribe();
        }
      }
    }
  };
}

/**
 * @ngInject
 */
export function observeScopeVariable() {
  return {
    link: function (scope, element, attrs) {
      scope.$watchCollection(attrs.observeScopeVariable, function (ov, nv) {
        console.trace(ov, nv)
      })
    }
  }
}


/**
 * @ngInject
 */
export function tetherMenu() {
  return {
    link: function (scope, element, attrs) {
      var menuElement = $(scope.$eval(attrs.tetherMenuElement));
      var tetherInstance;
      scope.$watch(attrs.tetherMenuIsOpen, function (newValue, ov) {
        var condition = scope.$eval(attrs.tetherMenuIf);
        if (!_.isUndefined(condition) && !condition) {
          return;
        }
        if (!ov && newValue) {
          showMenu();
        } else if (ov && !newValue) {
          hideMenu();
        }
      });

      function showMenu() {
        hideMenu();
        var target = $(scope.$eval(attrs.tetherMenuTarget));
        tetherInstance = new Tether({
          element: $(menuElement)[0],
          target: $(target)[0],
          attachment: 'top left',
          targetAttachment: 'bottom right',
          offset: '0px 15px',
          constraints: [
            {
              to: 'scrollParent'
            }
          ]
        });
        menuElement.show();
      }

      function hideMenu() {
        if (tetherInstance && tetherInstance.destroy) {
          tetherInstance.destroy();
        }
        menuElement.hide();
      }
    }
  }
}


/**
 * @ngInject
 */
showHorizontalScrollableControls.$inject = ['$timeout','windowEvents', 'c', 'analyticsService'];
export function showHorizontalScrollableControls($timeout, windowEvents, c, analyticsService) {
  return {
    link: function (scope, element, attrs) {
      let container = scope.$eval(attrs.scrollableContainer);
      if(attrs.delayedInit){
          $timeout(init, 1000);
      }
      else{
          init();
      }

      function init(){
        var ele = $(element);
        if(container){
          ele = $(element).find(container);
        }
        var eleDom = ele[0];
        var isOverflowing = false;
        var scrollRightEle = $(element).find('[scroll-right]').first();
        var scrollLeftEle = $(element).find('[scroll-left]').first();
        var scrollListenerDebounced = _.debounce(scrollListener, 200);
        var scrollThreshold = 10;
        scope.$watch(attrs.monitorChanges, function (newValue, oldValue) {
          if(attrs.delayedInit){
            $timeout(checkIfOverflowing, 200);
          }
          else{
            checkIfOverflowing();
          }
        });
        $timeout(checkIfOverflowing, 1000);
        windowEvents.onResize("horizontalScrollBar" + _.random(99999999), checkIfOverflowing);
        scrollRightEle.click(scrollRight);
        scrollLeftEle.click(scrollLeft);

        ele.on('scroll', scrollListenerDebounced);
        function scrollListener(event?) {
          if ((eleDom.scrollLeft + scrollThreshold) > eleDom.scrollWidth - eleDom.offsetWidth) {
            scrollRightEle.addClass('disabled');
            scrollLeftEle.removeClass('disabled');
          } else if (eleDom.scrollLeft < scrollThreshold) {
            scrollRightEle.removeClass('disabled');
            scrollLeftEle.addClass('disabled');
          } else {
            scrollRightEle.removeClass('disabled');
            scrollLeftEle.removeClass('disabled');
          }
        }

        function checkIfOverflowing() {
          isOverflowing = eleDom.scrollWidth > eleDom.offsetWidth;
          scope.$eval(attrs.isOverflowing + " = " + isOverflowing);
          scrollListener();
        }

        function scrollRight() {
          analyticsService.userEventTrack(c.userEvents.dataviewEvents.viewOptions.scrollRight,{
            eventOrigin: "dataview.viewPanel"
          });
          ele.animate({scrollLeft: "+=300"}, 500);
        }

        function scrollLeft() {
          analyticsService.userEventTrack(c.userEvents.dataviewEvents.viewOptions.scrollLeft,{
            eventOrigin: "dataview.viewPanel"
          });
          ele.animate({scrollLeft: "-=300"}, 500);
        }
      }
    }
  }
}

/**
 * @ngInject
 */
export function textTruncate() {
  return function (val, len) {
    var x = val;
    if (typeof val == 'string') {
      if (x.length > len) {
        x.splice(len);
      }
      x += '...';
    }
    return x;
  }
}


trustHtmlForBinding.$inject = ['$sce']
export function trustHtmlForBinding($sce){
  return function (text) {
    return $sce.trustAsHtml(text);
  };
}

/**
 * @ngInject
 */
bindNumberAsHumanisedHtml.$inject = ['utils'];
export function bindNumberAsHumanisedHtml(utils) {
  return {
    scope: {
      val: "=",
      limit: "="
    },
    link: function (scope, element, attrs) {
      scope.$watch('val', function (val) {
        // remove comma thousand separater in the value.
        // When value is greater than 4 and less than 8, it will be comma thousand separated value. parseFloat will return 1 when we pass comma separated vavlue.
        if(typeof val == 'string'){
          val = val.replace(/,/g, '')
        }
        val = parseFloat(val);

        if (isNaN(val)) {
          $(element).prop('title', '');
          $(element).empty();
          return;
        }

        if(!isNaN(scope.limit) && Math.abs(val) < Number(scope.limit)){
          let txt = 'less than ' + scope.limit;
          $(element).prop('title', txt);
          $(element).html(txt);
        }
        else{
          let title = val;
          if (Math.abs(val) > 1) {
            title = Math.round(parseFloat(val) * 100) / 100;
          }
          $(element).prop('title', numberWithCommas(title));
          $(element).html(utils.number.humanizeNumberAsHtml(val, {prefix: attrs.prefix}));
        }

      })
    }
  }
}

/**
 * @ngInject
 */
bindCopyKeys.$inject = ['$window'];

export function bindCopyKeys($window) {
    return {
        link: function (scope, element, attrs) {
            // KeyCodes -- 17-- ctrl, 91-- Left cmd(mac), 93-- Right cmd(mac), 224-- left/right cmd (mac-firefox), 67-- c(key)
            let ctrlDown = false, ctrlOrCmdKey = [17, 91, 93, 224], copyKey = 67, callback = scope.$eval(attrs.callback) || _.noop;
            element.bind("keyup", function ($event) {
                if (ctrlOrCmdKey.includes($event.keyCode)){
                    ctrlDown = false;
                }
                scope.$apply();
            });

            element.bind("keydown", function ($event) {
                if (ctrlOrCmdKey.includes($event.keyCode)) {
                        ctrlDown = true;
                }
                else if (ctrlDown && $event.keyCode == copyKey) {
                    $event.stopPropagation();
                    callback();
                }
                scope.$apply();
            });
            element.focus();
        }
    }
}


folderPicker.$inject = ['config'];
export function folderPicker(config) {
  return {
    controller: 'folderPickerController',
    controllerAs: 'mvm',
    bindToController: true,
    templateUrl: config.templates.folderPicker,
    scope: {
      items: '=',
      initialLabelView: '=?',
      isMenuOpen: '=?',
      toggleClasses: '=?',
      mode: '@?',
      selectedResource: '=?'
    },
    link: function (scope, element, attr) {
    }
  }
}


export function getWindowSize() {
    var d = document, root = d.documentElement, body = d.body;
    var wid = window.innerWidth || root.clientWidth || body.clientWidth,
      hi = window.innerHeight || root.clientHeight || body.clientHeight;
    return [wid, hi];
}


showPaymentError.$inject = ['config']
export function showPaymentError(config){
  return {
    restrict: 'E',
    scope: {},
    templateUrl: config.templates.paymentErrorWarning,
  }
}

mmTitle.$inject = ['$sce']
export function mmTitle($sce) {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope : {
      name:'=',
      type:'='
    },
    template: '<blockType ng-attr-title="{{decodedName}}"><ng-transclude></ng-transclude></blockType>',
    link: function(scope, element, attrs){
      scope.$watch('name', function (val) {
        let value = angular.element('<div />').html(val).text();
        scope.decodedName = $sce.trustAsHtml(value);
        scope.blockType = scope.type || 'div';
      });
    }
  }
}


mmSelect.$inject = ['$sce']
export function mmSelect($sce) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      data: '=',
      value: '@',
      isdisabled: '@'
    },
    template:'<select ng-options="item.decodedName for item in items">'+
    '<option value="" ng-disabled={{isdisabled}}>{{value}}</option>' +
    '<option ng-repeat = "item in items">{{item}}</option>'+'</select>',
    link: function(scope, element, attrs) {
      scope.items = scope.data;
      if (!isArray(scope.data)) {
        scope.items = [scope.data]
      }
      scope.items.forEach(function (item) {
        let value = angular.element('<div />').html(item.name).text();
        item.decodedName = $sce.trustAsHtml(value);
      });
    }
  };
}

export function mmLimit() {
  return function(text, limit) {
    if (text){
      return text.length > limit ? text.substr(0, limit) + '...': text;
    }
  }
}
