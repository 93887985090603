<template>
  <div class="action-bar-export-to-database">
    <action-bar-export-list-modal
      v-model="computedShowModal"
      :title="$t('action_bar.export.export_to_database.modal_title')"
      :items="computedExportToDatabaseItems"
      @itemClick="onListModalItemClick"
    />
    <action-bar-export-database-selection
      v-model="isDatabaseSelectionModalOpen"
      @select="onDatabaseSelect"
      @back="computedShowModal = true"
    />
    <action-bar-export-existing-credentials
      v-model="isExistingCredentialsModelOpen"
      :selectedDatabase="selectedDatabase"
      ref="existingCredentials"
      @back="isDatabaseSelectionModalOpen = true"
    />
    <action-bar-export-big-query
      v-model="isBigQueryModalOpen"
      ref="bigQuery"
      @back="isDatabaseSelectionModalOpen = true"
    />
    <action-bar-export-table-name
      v-model="isTableNameModalOpen"
      @apply="onManagedTableNameApply"
      @back="computedShowModal = true"
    />
    <action-bar-export-managed-credentials
      v-model="isManagedCredentialsModalOpen"
      :credentials="managedCredentials"
      @back="isTableNameModalOpen = true"
    />
  </div>
</template>

<script>
import ActionBarExportListModal from '../list-modal/action-bar-export-list-modal.vue'
import ActionBarExportDatabaseSelection from './existing-database/database-selection/action-bar-export-database-selection.vue'
import ActionBarExportExistingCredentials from './existing-database/existing-credentials/action-bar-export-existing-credentials.vue'
import ActionBarExportBigQuery from './existing-database/big-query/action-bar-export-big-query.vue'
import ActionBarExportTableName from './managed-database/table-name/action-bar-export-table-name.vue'
import ActionBarExportManagedCredentials from './managed-database/managed-credentials/action-bar-export-managed-credentials.vue'
import { EXPORT_CONNECTORS_MAP, EXPORT_CONNECTORS, USER_EVENTS, SPLITIO_FEATURE_FLAGS } from '@/constants'
import { featuresVisibility } from '@/plugins/splitio'

export default {
  name: 'action-bar-export-to-database',
  components: {
    ActionBarExportListModal,
    ActionBarExportDatabaseSelection,
    ActionBarExportExistingCredentials,
    ActionBarExportTableName,
    ActionBarExportManagedCredentials,
    ActionBarExportBigQuery
  },
  props: {
    value: Boolean
  },
  data: () => ({
    isDatabaseSelectionModalOpen: false,
    isExistingCredentialsModelOpen: false,
    isBigQueryModalOpen: false,
    isTableNameModalOpen: false,
    isManagedCredentialsModalOpen: false,
    managedCredentials: {
      host: '',
      port: '',
      database: '',
      username: '',
      password: '',
      table: ''
    },
    selectedDatabase: {
      name: '',
      handler: '',
      defaultPort: ''
    },
    featuresVisibility
  }),
  computed: {
    computedShowModal: {
      get() {
        return this.value
      },
      set(showModal) {
        this.$emit('input', showModal)
      }
    },
    computedExportToDatabaseItems() {
      const t = (id) => this.$t(`action_bar.export.export_to_database.${id}`)
      const { isMammothManagedExport } = this.$store.state.pipeline
      return [
        {
          id: 'existing-database',
          title: t('existing_database.title'),
          description: t('existing_database.sub_title'),
          userEventKey: 'ALREADY_EXISTING_DATABASE_CLICK'
        },
        {
          id: 'managed-database',
          title: t('mammoth_managed_database.title'),
          description: t('mammoth_managed_database.sub_title'),
          disabled: isMammothManagedExport,
          tooltip: isMammothManagedExport ? t('mammoth_managed_database.disabled_tooltip') : '',
          userEventKey: 'MAMMOTH_MANAGED_DATABASE_CLICK',
          render: () => this.featuresVisibility[SPLITIO_FEATURE_FLAGS.EXPORT.PUBLISH_TO_DB],
          featureToValidate: 'isCreateDbEnabled'
        }
      ].filter((option) => (option.render ? option.render() : true))
    }
  },
  methods: {
    onListModalItemClick({ id, userEventKey }) {
      this.computedShowModal = false
      if (id === 'existing-database') {
        this.selectedDatabase = { name: '', defaultPort: '', handler: '' }
        this.isDatabaseSelectionModalOpen = true
      } else if (id === 'managed-database') {
        this.isTableNameModalOpen = true
      }
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT[userEventKey], {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    onDatabaseSelect(database) {
      this.isDatabaseSelectionModalOpen = false
      if (database.handler === EXPORT_CONNECTORS_MAP.BIGQUERY.key) {
        this.isBigQueryModalOpen = true
        this.$refs.bigQuery.actionId = ''
      } else {
        this.isExistingCredentialsModelOpen = true
        this.$refs.existingCredentials.actionId = ''
        this.selectedDatabase = database
      }
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.DATABASE_SELECT, {
        viewId: this.$store.state.dataEditor.viewId,
        selectedDatabase: database.name
      })
    },
    onManagedTableNameApply(credentials) {
      this.isTableNameModalOpen = false
      this.managedCredentials = credentials
      this.isManagedCredentialsModalOpen = true
      this.$root.legacyHandlers.openPipeline()
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.DATABASE_SELECT, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    showMammothManagedCredentials(credentials) {
      this.managedCredentials = credentials
      this.isManagedCredentialsModalOpen = true
      this.$userEvents.save(USER_EVENTS.ACTION_BAR.EXPORT.SHOW_MAMMOTH_MANAGED_CREDENTIALS, {
        viewId: this.$store.state.dataEditor.viewId
      })
    },
    editDatabaseCredntials({ actionId, databaseHandler, targetProperties, keepAtPipelineEnd }) {
      if (databaseHandler === EXPORT_CONNECTORS_MAP.BIGQUERY.key) {
        this.isBigQueryModalOpen = true
        this.$nextTick(() => {
          this.$refs.bigQuery.handleEdit({ actionId, targetProperties, keepAtPipelineEnd })
        })
      } else {
        this.isExistingCredentialsModelOpen = true
        this.$nextTick(() => {
          const existingCredentials = this.$refs.existingCredentials
          const credentials = existingCredentials.credentials

          const connector = EXPORT_CONNECTORS.find((c) => c.key == databaseHandler)
          this.selectedDatabase = {
            name: connector.name,
            handler: connector.key,
            logo: connector.mediumIcon,
            defaultPort: connector.connection.exportDefaultPort || connector.connection.fields.port.props.value
          }

          Object.keys(credentials).forEach((key) => (credentials[key] = targetProperties[key]))
          existingCredentials.actionId = actionId
          existingCredentials.keepAtPipelineEnd = keepAtPipelineEnd
          existingCredentials.includeHiddenColumns = targetProperties.include_hidden
          this.$nextTick(existingCredentials.$refs.form.getFormErrors)
        })
      }
    }
  }
}
</script>
