import * as angular from 'angular';

/**
 * @ngInject
 */
elementModalService.$inject = ['$uibModal', '$q', 'config', '$rootScope'];
export function elementModalService($uibModal, $q, config, $rootScope){
  var modalReference;
   var service = {
    isOpen: false,
    open: openPanel,
    close: closePanel
  };
  /**
   * options Specs
   *
   * It is expected to have the following keys
   *
   * 1. dataview. The instance of the dataview.
   * 2. derivative:
   *    If in create mode, this should be an object of the form:
   *      {param: {TYPE: <eType>}}
   *      where <eType> is the element type. It could be one of 'METRIC',... (TODO: fill this documentation)
   *    If in edit mode, it should be an instance of metric
   *
   *
   */

  function openPanel(options){
    service.isOpen = true;
    var controllerScope = $rootScope.$new();
    controllerScope.options = options;
    modalReference = $uibModal.open({
      animation: false,
      templateUrl: config.templates.element.modal,
      controller: config.controllers.elementModal,
      controllerAs: 'epvm',
      bindToController: true,
      scope: controllerScope,
      windowClass: 'modal-size-full elements-modal'
    });

    modalReference.closed.then(function () {
      service.isOpen = false;
    });
    return modalReference.result;
  }

  function closePanel() {
    if (modalReference.close) {
      modalReference.close();
    }
  }
  return service;
}
