import * as angular from 'angular';
import * as $ from 'jquery';
import {tokenise} from "./math.bnf";

var zeroArgFunctions = [
  'COUNT'
];

var singleColArgFunctions = [
  'SUM', 'AVG', 'MIN', 'MAX', 'STDDEV'
];

var expressionArgFunctions = [
  'INT', 'ABS'
  //'SQRT'
];

var allFunctions = [].concat(zeroArgFunctions, singleColArgFunctions, expressionArgFunctions);

var operators = [
  '+', '-', '*', '/', '%'
];

function isMember(array, element){
  return array.indexOf(element) != -1;
}


function getFunctionCallInServerFormat(function_name, argument){
  var value = {
    FUNCTION: function_name,
    ARGUMENT: argument
  }

  return {
    TYPE: "FUNCTION",
    VALUE: value
  }
}

function getNumericInServerFormat(numString){
  var number = parseInt(numString);

  if(number != parseFloat(numString)){
    number  = parseFloat(numString);
  }

  return {
    TYPE: "NUMBER",
    VALUE: number
  }
}

function getColumnInServerFormat(column_internal_name){
  return {
    TYPE: "COLUMN",
    VALUE: column_internal_name
  }
}

function getOperatorInServerFormat(operator){
  return {
    TYPE: "OPERATOR",
    VALUE: operator
  }
}

function getMetricInServerFormat(metric_info){
  return {
    TYPE: "METRIC",
    VALUE: {
      METRIC: metric_info.internal_name,
      DATAVIEW_ID: metric_info.dataview_id
    }
  }
}


/**
 * tells if a text is numeric or not
 *
 * @param text: the text to be checked

 * @returns {boolean}
 */

function isNumeric(text) {
  return !isNaN(parseFloat(text)) && isFinite(text);
}

var funcArgTypes = {
  'zero': 'zero',
  'columnSingle': 'columnSingle',
  'expression': 'expression'
}

function quoteColumnName(column, suffix?){
  if (suffix){
    column.quotedName = '"' + (column.hasOwnProperty('display_name_w_type') ?column.display_name_w_type.replaceAll('"', '\\"') : column.display_name.replaceAll('"', '\\"')) + suffix + '"';
  }else{
    column.quotedName = '"' +  (column.hasOwnProperty('display_name_w_type') ? column.display_name_w_type.replaceAll('"', '\\"') : column.display_name.replaceAll('"', '\\"')) + '"';
  }
}


/**
 * @ngInject
 */

mammothExpressionHelperFactory.$inject = ['utils', 'c'];
export function mammothExpressionHelperFactory(utils, c) {
  return {
    get_helper: get_helper,
    operators: operators,
    brackets: ['(', ')'],
    funcArgTypes: funcArgTypes,
    functions: {
      zero: zeroArgFunctions,
      columnSingle: singleColArgFunctions,
      expression: expressionArgFunctions
    }
  };

  function get_helper(){
    return new ExpressionHelper();
  }


  function ExpressionHelper(){

    var self = this;
    self.init = init;
    self.validate = validate;
    self.getParam = getParam;
    self.getExpressionString = getExpressionString;
    self.get_last_token_type = get_last_token_type;
    self.get_hints = get_hints;
    self.get_function_type = get_func_type;
    self.disallowColumnsAsAtoms = false;
    self.error_column = -1

    var metric_display_name_to_internal_info_map;
    var metric_names;
    var columns_with_function_names;
    var column_display_name_to_internal_name_map;
    var column_names;

    var types = {
      numeral: 'numeral',
      column: 'column',
      expression: 'expression',
      metric: 'metric',
      function: "function",
      operator: "operator"
    };

    /**
     * @param columns: An array of numeric columns.
     *  Each object in array should have display_name and internal_name
     * @param metrics: An array of metrics.
     *  Each object in array should have dataview_id, display_name and internal_name
     * @param disallowColumnsAsAtoms: true or false
     * @constructor
     */

    function init(columns, metrics, disallowColumnsAsAtoms){
      // reset variables
      column_names = [];
      self.columns = columns;
      if (disallowColumnsAsAtoms === undefined) {
        disallowColumnsAsAtoms = false;
      }

      self.disallowColumnsAsAtoms = disallowColumnsAsAtoms;

      column_display_name_to_internal_name_map = {};
      columns_with_function_names = [];
      metric_names = [];
      metric_display_name_to_internal_info_map = {};

      if (columns === undefined) {
        columns = [];
      }
      if (metrics === undefined) {
        metrics = [];
      }


      columns.forEach(function (column) {
        quoteColumnName(column);
        if(column.type == c.numeric && !column.hasOwnProperty('error')) {
          column_names.push(column.quotedName);
        }
        column_display_name_to_internal_name_map[column.quotedName] = column.internal_name;
      })


      metrics.forEach(function (metric) {
        metric_names.push(metric.display_name);
        metric_display_name_to_internal_info_map[metric.display_name] = {
          internal_name: metric.internal_name,
          dataview_id: metric.dataview_id
        };
      })
    }

    function get_last_token_type(token, nextchar){
      var token_type;
      token  = token.trim();
      if (token[0] == '"' && token[token.length - 1]) {
        if (isMember(column_names, token)) {
          token_type = types.column;
        }
      }
      else if (isMember(columns_with_function_names, token)) {
        // figure out if the next token is an opening bracket.
        // If so, it should be a function
        if (nextchar == '(') {
          token_type = types.function;
        }
        else {
          token_type = types.column;
        }
      }
      else if (isMember(column_names, token)) {
        token_type = types.column;
      }
      else if (isMember(metric_names, token)) {
        token_type = types.metric;
      }
      else if (isMember(allFunctions, token)) {
        if (nextchar == '(') {
          token_type = types.function;
        }

      }
      else if (token == '(') {
        token_type = types.expression;
      }
      else if (isMember(operators, token)) {
        token_type = types.operator;
      }
      else if (isNumeric(token)) {
        token_type = types.numeral;
      }

      return token_type;
    }

    function get_func_type(funcName){
      if(isMember(zeroArgFunctions, funcName)){
        return funcArgTypes.zero;
      }
      else if(isMember(singleColArgFunctions, funcName)){
        return funcArgTypes.columnSingle;
      }
      else if (isMember(expressionArgFunctions, funcName)){
        return funcArgTypes.expression
      }
    }

    function _collectSubExpressionTokens(tokens, starting_position){
      // lets start the counter at starting_position + 1, that is we start checking
      // after the first bracket
      // Lets init a open_bracket_counter at 1. When this becomes zero,
      // we can say that the argument_tokens have been collected

      //Assumption is that the starting position will be a bracket.
      if(tokens[starting_position] != '('){
        throw "invalid subexpression starting position";
      }

      var subExpression = [];
      var open_brackets = 1;
      var counter = starting_position + 1;

      for (; counter < tokens.length; counter++) {
        var t = tokens[counter];
        if (t == '(') {
          open_brackets += 1;
        }
        else if(t == ')') {
          open_brackets -= 1;
        }

        if (open_brackets == 0) {
          break;
        }
        else {
          subExpression.push(t);
        }
      }

      if (open_brackets != 0) {
        throw "Syntax error";
      }

      return subExpression;
    }

    function _tokensToExpression(tokens) {
      var expression = [];
      var i = 0, j = -1; // to track current and previous element
      while (i < tokens.length) {
        // BTW jetbrains is complaining that this while statement doesn't loop.
        var token = tokens[i];

        if (j == i) {
          throw "Error in while loop"
        }
        else {
          j = i;
        }

        var token_type = get_last_token_type(token, tokens[i + 1]);

        if(!token_type){
          throw "Syntax error";
        }

        if(token_type == types.function){
          // if it is a function. figure out what are its arguments.

          var argument_tokens = _collectSubExpressionTokens(tokens, i + 1);
          if(isMember(zeroArgFunctions, token)){
            if (argument_tokens.length != 0){
              throw "Function " + token + " takes no arguments";
            }
            else{
              expression.push(getFunctionCallInServerFormat(token, null));
            }
          }
          else if (isMember(singleColArgFunctions, token)){
            if(argument_tokens.length != 1){
              throw "Function "+  token + "takes exactly 1 argument";
            }
            else{
              var arg_token = argument_tokens[0];
              if (isMember(column_names, arg_token)){
                expression.push(
                  getFunctionCallInServerFormat(
                    token,
                    column_display_name_to_internal_name_map[arg_token]
                  )
                );
              } else {
                throw "Unexpected token, " + token;
              }
            }
          }
          else{
            // Recursion. Yay!!!
            var argument_expression = _tokensToExpression(argument_tokens);
            expression.push(getFunctionCallInServerFormat(token, argument_expression));
          }
          // lets advance the counter.
          i = i + 3 + argument_tokens.length;
        }

        else if(token_type == types.column){
          let t = token;
          expression.push(getColumnInServerFormat(column_display_name_to_internal_name_map[t]));
          // lets advance the counter.
          i = i + 1;
        }

        else if(token_type == types.numeral){
          expression.push(getNumericInServerFormat(token));
          i = i + 1;
        }

        else if(token_type == types.operator){
          expression.push(getOperatorInServerFormat(token));
          i = i + 1;
        }
        else if(token_type == types.metric){
          expression.push(getMetricInServerFormat(
            metric_display_name_to_internal_info_map[token]
          ));
          i = i + 1;
        }
        else if(token_type == types.expression){
          var sub_expression_tokens = _collectSubExpressionTokens(tokens, i);
          expression.push(_tokensToExpression(sub_expression_tokens));
          i = i + 2 + sub_expression_tokens.length;
        }
        else {
          throw "Unexpected token, " + token;
        }
      }
      return expression;
    }

    function _validateExpression(expression){
      /**
       * Rules
       * 1. Expression should not start/end in an operator. + or - can be first chars however
       * 2. Two operators can not be in sequence.
       * 3. Two non-operators should never be in sequence
       * 4. If disallowColumnsAsAtoms is true, columns are not allowed in the expression.
       * 5. expression should not be empty
       * 6. If a function can take expression as argument, validate the expression
       */
      //rule 5
      if(expression.length == 0){
        console.trace();
        throw "Empty expression: " + expression;
      }

      var prev;

      for (var i = 0; i < expression.length; i++) {
        prev = null;

        var element = expression[i];
        if(angular.isArray(element)){
          prev = expression[i - 1];
          // rule 4
          if (prev && prev.TYPE != "OPERATOR") {
            throw "Syntax error.";
          }
          // This is a subexpression. Let's recursively validate it
          _validateExpression(element);
        }
        else{
          if(element.TYPE == "OPERATOR"){
            if(i == 0){
              // Rule 1.1
              if (!isMember(['+', '-'], element.VALUE)) {
                throw "Expression can not begin with * or /";
              }
            }
            else if(i  == expression.length -1){
              // rule 1.2
              throw "Expression can not end with any of " + operators.join(',');
            }
            else{
              prev = expression[i - 1];

              if (prev.TYPE == "OPERATOR") {
                throw "Operators (" + operators.join(',') + ") can not be followed by other operators.";
              }
            }
          }
          else{
            prev = expression[i - 1];
            if (prev && prev.TYPE != "OPERATOR") {
              throw "Syntax error";
            }
            if(element.TYPE == "FUNCTION"){
              // rule 5
              var fcall = element.VALUE;
              if (isMember(expressionArgFunctions, fcall.FUNCTION)){
                _validateExpression(fcall.ARGUMENT);
              }
              else{
                var column = utils.metadata.get_column_by_internal_name(self.columns, fcall.ARGUMENT);
                if (column && column.type !='NUMERIC'){
                  throw "Only Numeric columns allowed"
                }
                else if (column && column.hasOwnProperty('error')){
                  throw "Column Missing"
                }
              }
            }
            else if(element.TYPE == "COLUMN"){
              //rule 4
              if(self.disallowColumnsAsAtoms) {
                throw "Columns are not allowed in the expression";
              }
              var column = utils.metadata.get_column_by_internal_name(self.columns, element.VALUE);
              if (column && column.type !='NUMERIC'){
                throw "Only Numeric columns allowed"
              }
              else if (column && column.hasOwnProperty('error')){
                throw "Column Missing"
              }
            }
          }
        }

      }
    }

    /**
     * This function will return a dictionary with following keys
     * 1. partial match: if this is a partial match or a full match
     * 2. options: a list of dicts with the following keys
     *      1. text
     *      3. type
     * 3. last_token: the last token
     * @param text
     */

    function get_hints(text){
      var options = [];
      var col_options = [];
      var metric_options = [];
      var function_options = [];

      allFunctions.forEach(function (name) {
        function_options.push({
          text: name,
          type: types.function
        });
      })

         // TODO: SENTRYERROR:FRONTEND-KS:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/491045197
      column_names.forEach(function(name){
        col_options.push({
          text: name,
          displayText: name,
          type: types.column
        })
      });

      metric_names.forEach(function(name){
        metric_options.push({
          text: name,
          type: types.metric
        })
      });

      var general_options;
      if(self.disallowColumnsAsAtoms){
        general_options = options.concat(function_options, metric_options);
      }
      else{
        general_options = options.concat(col_options, function_options, metric_options);
      }

      var lpar = {
        text: "(",
        type: types.operator
      }

      var rpar = {
        text: ")",
        type: types.operator
      }

      var tokens = tokenise(text);
      var last_token = tokens[tokens.length - 1];
      if (last_token){
        last_token = last_token.trim();
      }
      else{
        last_token = '';
      }

      var penultimate = tokens[tokens.length - 2];
      var antepenultimate = tokens[tokens.length - 3];

      var last_token_full_match = true;
      if(isMember(operators, last_token)){
        // if last token was an operator all options are available

        options = general_options;
      }
      else if (last_token == "(") {
        if (penultimate !== undefined) {
          if (isMember(allFunctions, penultimate)) {
            if (isMember(zeroArgFunctions, penultimate)) {
              options = [];
            }
            else if (isMember(singleColArgFunctions, penultimate)) {
              options = col_options;
            }
            else {
              options = general_options;
            }
          }
          else {
            options = general_options;
          }
        } else {
          options = general_options;
        }
      }
      else if (last_token == ')') {
        //nothing
      }
      else if(isMember(column_names, last_token)){
        if(penultimate === '(' && isMember(allFunctions, antepenultimate)){
          options = [rpar];
        }
      }
      else if(isMember(metric_names, last_token)){
        //nothing
      }
      else if(isMember(allFunctions, last_token)){
        options = [lpar];
      }
      else{
        last_token_full_match = false;
      }

      if(!last_token_full_match){
        if (last_token) {
          last_token = last_token.toLowerCase()
        }
        // Limit the length of token used for creating regexp to 10 to avoid performance issue
        // Large regexp can cause webpage to crash
        if(last_token.length> 10){
          last_token = last_token.slice(0, 10)
        }
        var regexString = last_token.split('').join(".*")
        regexString = regexString.replace(/[\(\)\+\[\]\\]/g, '\\$&');
        regexString = regexString.replace(/\*{2}/g, '*\\*');

        var last_token_re = new RegExp(regexString, "gi");

        if(penultimate == '(' && isMember(singleColArgFunctions, antepenultimate)){
          options = col_options;
        }
        else {
          options = general_options;
        }

        options = $.grep(options, function(o){
          return o.text.match(last_token_re)
        });
        //options.sort(function (a, b) {
        //  return a.text.toLowerCase().search(last_token_re) - b.text.toLowerCase().search(last_token_re)
        //})
      }

      return {
        last_token: last_token,
        options: options,
        partial_match: !last_token_full_match
      }

    }

    function _validate_tokens(tokens){
      // first lets construct an an array of operators and atoms
      var expression = _tokensToExpression(tokens);
      _validateExpression(expression);
    }

    function validate(string) {
      var tokens = tokenise(string);
      return _validate_tokens(tokens);
    }

    function getParam(string) {
      return _tokensToExpression(tokenise(string));
    }

    function getExpressionString(expression, columns, metrics){
      var string = '';
      expression.forEach(function(atom){
        var string_to_add = 'UNKNOWN';
        if(angular.isArray(atom)){
          string_to_add = "("+ getExpressionString(atom, columns, metrics)+")";
        }
        else{
          if (atom.TYPE == 'COLUMN') {
            var column = utils.metadata.get_column_by_internal_name(columns, atom.VALUE);
            var suffix = ""
            if (column) {
              if (column.hasOwnProperty('error')) {
                self.error_column = 1;
                suffix += ' - missing ';
              }
              else if (column.type != 'NUMERIC') {
                self.error_column = 1;
                suffix += ' - type mismatch ';
              }
              quoteColumnName(column, suffix);
              string_to_add = column.quotedName;
            }
          }
          else if (atom.TYPE == 'METRIC') {
            var metric;
            angular.forEach(metrics, function (m) {
              if (m.internal_name == atom.VALUE.METRIC) {
                metric = m;
              }
            });

            if (metric) {
              string_to_add = metric.display_name;
            }
          }
          else if (atom.TYPE == 'OPERATOR' || atom.TYPE == 'NUMBER') {
            string_to_add = ' ' + atom.VALUE + ' ';
          }
          else if(atom.TYPE == 'FUNCTION'){
            var function_name = atom.VALUE.FUNCTION;
            var argument = atom.VALUE.ARGUMENT;
            var arg_as_string = 'UNKNOWN';
            if (!argument) {
              arg_as_string = ''
            }
            else if(angular.isArray(argument)){
              arg_as_string = getExpressionString(argument, columns, metrics);
            }
            else if(argument){
              var column = utils.metadata.get_column_by_internal_name(columns, argument);
              var suffix=""
              if (column) {
                if (column.hasOwnProperty('error')) {
                  self.error_column = 1;
                  suffix += ' - missing ';
                }
                else if (column.type != 'NUMERIC') {
                  self.error_column = 1;
                  suffix += ' - type mismatch ';
                }
                quoteColumnName(column, suffix);
                arg_as_string = column.quotedName;
              }
            }
            string_to_add = function_name + '(' + arg_as_string + ')';
          }
        }

        string += string_to_add;
      });
      return string;
    }


  }
}
