<template>
  <div>
    <span class="mm-text--caption-bold"> {{ $t('global.dictionary.generate_live_link') }} </span>
    <ul class="operation-list m-b-3">
      <li v-for="(operation, idx) in operations" :key="`${operation.operationId}_${idx}`">
        <div class="operation-wrapper">
          <mm-link class="m-r-2" :label="operation.url" :href="operation.url" small underline />
          <mm-tooltip :label="$t('global.dictionary.copy_link')" position="right" wrapper-class="m-l-auto m-r-2">
            <mm-button objective="tertiary" size="small" icon="copy" @click="copy(operation.url)" />
          </mm-tooltip>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'linked-in-out-list-live-link-operation',
  props: {
    operations: {
      type: Array,
      required: true
    }
  },
  methods: {
    async copy(url) {
      await navigator.clipboard.writeText(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.operation-list {
  padding-left: 21px;
  display: grid;

  li {
    .operation-wrapper {
      overflow-wrap: anywhere;
      align-items: center;
      display: flex;

      .mm-link {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 145px;
      }
    }

    &::marker {
      font-size: 13px;
    }
  }
}
</style>
