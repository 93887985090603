/*jslint node: true */

import * as angular from 'angular';
import * as _ from 'lodash-es';

collapseRowsManagerFactory.$inject = ['utils', 'c', 'eventCallbackManagerFactory'];
export function collapseRowsManagerFactory(utils, c, eventCallbackManagerFactory) {
  return {get_manager: get_manager};

  function get_manager(options) {
    return new CollapseRowsManager(options);
  }

  function CollapseRowsManager(options) {
    var self = this;
    var metadata = _.cloneDeep(options.metadata), taskUtils = options.taskUtils, dataview;

    if(options.context){
      dataview = options.context.dataview;
    }
    else{
      dataview = options.dataview;
    }

    self.context = options.context;
    self.source_column_options = [];
    self.collapseColumn = undefined;
    self.collapsedColumnInternalName = undefined;
    self.groupByColumns = [];
    self.delimiter = ',';
    self.collapsedColumnName = "";
    self.updateCollapseColumnName = updateCollapseColumnName;
    self.toggleAllSelect = toggleAllSelect;
    self.toggleSelection = toggleSelection;
    self.unSelectCollapseFromGroupBy = unSelectCollapseFromGroupBy;

    self.validateCollapsedColumnName = new eventCallbackManagerFactory('collapse-column_name-validators');

    if(!dataview){
      throw "Dataview should be supplied in the options dictionary in one of the two ways. Read code above for more information";
    }

    metadata = utils.sanitizeMetadata(metadata)
    self.metadata = _.cloneDeep(metadata);
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    // attributes
    self.selected_items = [];
    self.functions = [
      c.aggregations.concat,
    ];

    //methods
    self.getParam = getParam;
    self.handlePasteParams = handlePasteParams;
    self.setParam = setParam;
    self.validate = validate;
    self.highlightColumns = highlightColumns;

    angular.forEach(metadata, function (col) {
      if (col.type === c.text) {
        self.source_column_options.push(col);
      }
    });

    function toggleAllSelect() {
      // condition for deselecting all groupByColumns
      if ((self.groupByColumns.length == (self.metadata.length - (self.collapseColumn
        ? 1 : 0)))){
        self.groupByColumns = [];
      }
      // condition for selecting all groupByColumns
      else {
        self.groupByColumns = [];
        angular.forEach(self.metadata, function(column) {
          if (column.internal_name != self.collapseColumn) {
            self.groupByColumns.push(column.internal_name);
          }
        })
      }
      self.validateCollapsedColumnName.fire_event();
    }

    function toggleSelection(internal_name) {
      if(self.groupByColumns.includes(internal_name)) {
        self.groupByColumns.splice(self.groupByColumns.indexOf(internal_name), 1);
      }
      else {
        self.groupByColumns.push(internal_name)
      }
      // TODO: need a better fix. Validation is not triggering when item is pushed to array `self.groupByColumns` but it works when
      // array is assigned a value;
      self.groupByColumns = _.cloneDeep(self.groupByColumns);
      self.validateCollapsedColumnName.fire_event();
    }

    function unSelectCollapseFromGroupBy() {
      // whenever user changes collapse column, remove/unselect that column from groupByColumns array
      let idx = self.groupByColumns.indexOf(self.collapseColumn);
      if (idx != -1) {
        self.groupByColumns.splice(idx, 1);
      }
    }

    function validate(){
      let groupByColumnsInfo = _.filter(self.metadata, function(item){
        return self.groupByColumns.includes(item.internal_name);
      });
      return !(groupByColumnsInfo.some(function (item) {
        return item.hasOwnProperty("error");
      }))
    }

    function handlePasteParams(taskInfo){
      /** Update params with suitable replacement columns, based on display name*/

      var params = taskInfo.params;
      //Group by columns
      for(const index in params.PIVOT.GROUP_BY){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.PIVOT.GROUP_BY[index], 'COLUMN', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }
      //columns used in functions
      for(const index in params.PIVOT.SELECT){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.PIVOT.SELECT[index], 'COLUMN', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }
      return params;
    }

    function getParam(){
      var pivot_param = {
        GROUP_BY: undefined, 
        SELECT: undefined, 
        DERIVED_SELECT: undefined,
      };
      var group_by = [];
      var aggregations = [];
      let aggregation = c.aggregations.concat

      let collapseColumnInfo = utils.metadata.get_column_by_internal_name(self.metadata, self.collapseColumn)

      if (!self.collapsedColumnInternalName) {
        self.collapsedColumnInternalName = utils.string.format("{0}_{1}_{2}", [aggregation.toLowerCase(),
          collapseColumnInfo.internal_name, utils.string.random(10, {lowercase: true})]);
      }

      angular.forEach(self.groupByColumns, function (groupByColumn, i) {
        let groupSpec = {
          COLUMN: groupByColumn, 
          ORDER: i,
        }
        group_by.push(groupSpec);
      });

      var agg = {
        FUNCTION: aggregation,
        AS: self.collapsedColumnName,
        COLUMN: self.collapseColumn,
        INTERNAL_NAME: self.collapsedColumnInternalName,
        ORDER: self.groupByColumns.length,
        DELIMITER: self.delimiter
      };
      aggregations.push(agg);

      pivot_param.GROUP_BY = group_by;
      pivot_param.SELECT = aggregations;
      pivot_param.DERIVED_SELECT = [];

      var pivotSpec = {
        PIVOT: pivot_param,
        _UI_CUSTOM: 'collapseRows' 
      }

      if(options.context.hasOwnProperty('sequence')){
        pivotSpec['SEQUENCE_NUMBER'] = options.context.sequence;
      }
      return pivotSpec;
    }

    function setParam(param) {
      self.collapseColumn = param.PIVOT.SELECT[0].COLUMN;
      self.collapsedColumnName = param.PIVOT.SELECT[0].AS;
      self.delimiter = param.PIVOT.SELECT[0].DELIMITER;
      self.collapsedColumnInternalName = param.PIVOT.SELECT[0].INTERNAL_NAME;

      self.groupByColumns = [];
      angular.forEach(param.PIVOT.GROUP_BY, function (group) {
        self.groupByColumns.push(group.COLUMN);
      });
    }

    function highlightColumns() {
      taskUtils.highlight.sources([self.collapseColumn].concat(self.groupByColumns));
    }
    
    function updateCollapseColumnName() {
      let columnInfo = utils.metadata.get_column_by_internal_name(self.metadata, self.collapseColumn);
      self.collapsedColumnName = "Concatenation of " + (columnInfo ? columnInfo.display_name: "");
      // Required to trigger validation on array `self.groupByColumns`. Need a better fix
      self.groupByColumns = _.cloneDeep(self.groupByColumns);
    }
  }
}

export function filterColumnsForCollapse(){
  return function(values, avoid){
    let a = _.filter(values, function(v){
      if (v) {
        return !avoid.includes(v.internal_name);
      }
    });
    return a;
  }
}


