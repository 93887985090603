import Vue from 'vue'
import { PublishApi_getGridRows } from './api/publish.api.js'

const publishStore = {
  namespaced: true,

  state: {
    isExploreSectionOpen: false,
    exploreConfig: null,
    reportMetadata: null,
    reportDisplayProps: null,
    reportFormatInfo: null,
    getSourceDataFunc: () => {},
    reportId: null,
    columnOpenEvent: null,
    gridRemoveColumn: () => {},
    rowCount: null,
    dataset: {
      condition: null,
    },
  },

  mutations: {
    setReport: (state, report) => {
      state.report = report
    },
    setExploreConfig: (state, props) => {
      state.exploreConfig = props
    },
    setReportMetadata: (state, metadata) => {
      state.reportMetadata = metadata
    },
    setDisplayProps: (state, displayProps) => {
      state.reportDisplayProps = displayProps
    },
    setFormatInfo: (state, info) => {
      state.reportFormatInfo = info
    },
    setGetSourceDataFunc: (state, func) => {
      state.getSourceDataFunc = func
    },
    setReportId: (state, id) => {
      state.reportId = id
    },
    setColumnOpenEvent: (state, event) => {
      state.columnOpenEvent = event
    },
    setDatasetCondition: (state, condition) => {
      state.dataset.condition = condition
    },
    setGridRemoveColumnFunc: (state, func) => {
      state.gridRemoveColumn = func
    },
    setIsExploreSectionOpen: (state, value) => {
      state.isExploreSectionOpen = value
    },
    setRowCount: (state, value) => {
      state.rowCount = value
    },
  },

  getters: {
    hasOpenCards: (state) => state.setIsExploreSectionOpen,
    rowCount: (state) => state.setRowCount,
  },
}

export default publishStore
