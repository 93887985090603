import * as angular from 'angular';
import {config} from "../../common/app.config";
export let elementConfig: any = {};
function get_config_by_type(etype) {
  var type_in_lower_case = etype.toLowerCase();
  return {
    menuFactory: type_in_lower_case + 'MenuManagerFactory',
    createTemplate: 'app/components/element/' + type_in_lower_case + '/' + type_in_lower_case + '.create.menu.tpl.html',
    displayTemplate: 'app/components/element/' + type_in_lower_case + '/' + type_in_lower_case + '.display.tpl.html'
  }
}

angular.extend(elementConfig, <any>{
  METRIC: get_config_by_type('METRIC'),
  TABLE: get_config_by_type('TABLE'),
  CHART: get_config_by_type('CHART'),
  FILTER: get_config_by_type('FILTER'),
  FUNNEL: {
    menuFactory: 'chartMenuManagerFactory',
    createTemplate: config.templates.chartCreate,
    displayTemplate: config.templates.chartDisplay
  },
  templates: {
    displayMenuHandle: config.templates.displayMenuHandle,
    switcherInBuilderMenu: config.templates.switcherInBuilderMenu,
    metricElementContentTemplate: config.templates.metricElementContentTemplate,
    filterElementContentTemplate: config.templates.filterElementContentTemplate,
    chartElementContentTemplate: config.templates.chartElementContentTemplate,
    tableElementContentTemplate: config.templates.tableElementContentTemplate,
    tableElementTemplate: config.templates.tableElementTemplate
  }
});

elementConfig.rowLimits = {
  chart: 100000,
  filter: 250
};


