import { render, staticRenderFns } from "./navbar-releases.vue?vue&type=template&id=2690cbe6&scoped=true&"
import script from "./navbar-releases.vue?vue&type=script&lang=js&"
export * from "./navbar-releases.vue?vue&type=script&lang=js&"
import style0 from "./navbar-releases.vue?vue&type=style&index=0&id=2690cbe6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2690cbe6",
  null
  
)

export default component.exports