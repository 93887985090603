/*jslint node: true */
import * as _ from 'lodash-es';
import * as $ from 'jquery';

/**
 * @ngInject
 */
apiHelpers.$inject = ['Singularity', '$timeout', 'utils', 'c', 'analyticsService', 'deploymentConsts'];
export function apiHelpers(Singularity, $timeout, utils, c, analyticsService, deploymentConsts) {

    return {
        on_query_change: on_query_change,
        validate_query_change: validate_query_change,
        rdbms_on_load: rdbms_on_load,
        onSearchChange: onSearchChange,
        get_preview: get_preview,
        force_validate_ds_config: force_validate_ds_config,
        augment_ds_config_spec: augment_ds_config_spec,
        validate_ds_config: validate_ds_config,
        re_initialize_ds_config_form: re_initialize_ds_config_form,
        get_table_description_for_postgresql: get_table_description_for_postgresql,
        get_table_description_for_mysql: get_table_description_for_mysql,
        get_table_description: get_table_description
    };


    function augment_ds_config_spec(params, ele, scope, callback_fn) {
        Singularity.augmentDsConfigSpecParams(scope.model, scope).then(success_callback);

        function success_callback() {
            if (callback_fn !== undefined && typeof callback_fn === "function") {
                callback_fn();
            }
        }
    }

    function validate_ds_config(params, ele, scope, callback_fn) {
        Singularity.validateDsConfig(params, scope, callback_fn).then(function (data) {
            scope.options.data.parentController.isDsConfigValid = !!data.is_valid;
        })
    }

    function re_initialize_ds_config_form() {
        Singularity.triggerDsConfigFormReInitialization();
    }

    // web_analytics related functions
    function get_preview(params, scope, callback_fn) {
        if (callback_fn !== undefined && typeof callback_fn === 'function') {
            callback_fn();
        }
    }

    function force_validate_ds_config(params, elem, scope) {
        scope.options.data.parentController.isDsConfigValid = true;
    }


    // RDBMS Apis related functions
    function validate_query_change(params, ele, scope, callback_fn) {
        analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.rdbms.runTestQuery,
            {
                eventOrigin: 'thirdPartyModal',
                integration: scope.options.data.parentController.selectedIntegration.name
            });
        scope.options.data.parentController.dataSampleVisible = true;
        scope.options.data.parentController.preview_grid_loader.show = true;
        scope.options.data.parentController.previewLabelInfo = params.query_string;
        scope.options.data.parentController.dsConfigValidationMessage = undefined;
        Singularity.validateDsConfig(params, scope, callback_fn).then(function (data) {
            if (data.is_valid) {
                scope.options.data.parentController.dataSampleVisible = true;
                scope.model.hidden_query = params.query_string;
                scope.options.data.parentController.origDsName = scope.model.tables;
                scope.options.data.keyWiseFieldsMap.validate_query_string.mappedField.templateOptions.enabled = false;
                if (data.hasOwnProperty('is_select_query') && data.is_select_query == true) {
                    scope.options.data.parentController.disableCombineMode = false;
                } else if (data.hasOwnProperty('is_select_query') && data.is_select_query == false) {
                    scope.options.data.parentController.disableCombineMode = true;
                }
                if (data.hasOwnProperty("schedule_type")) {
                    var ctrl = scope.options.data.parentController;
                    ctrl.scheduleRelatedParams.schedule_type = data.schedule_type;
                }
            }
            else {
                scope.options.data.parentController.dataSampleVisible = false;
                scope.options.data.parentController.togglePreviewGridLoader(false);
            }
            _queryStringUpdatePostProcess(scope, scope.options.data.keyWiseFieldsMap.query_string.mappedField.templateOptions);
        });
    }


    function rdbms_on_load() {
        let parentController = this.extraData.parentController;
        parentController.dataSampleVisible = true;
        parentController.preview_grid_loader.show = false;
        parentController.newConnectionInfo = "Please make sure IP " + deploymentConsts.MAMMOTH_OUTBOUND_IP + " is whitelisted in your database settings.";
        if(this.userInputDiscoverySpec.hasOwnProperty('need_whitelist') && !this.userInputDiscoverySpec.need_whitelist){
            parentController.newConnectionInfo = null;
        }
        $timeout(function () {
            $(".right-preview-section").parent().addClass('right-preview-pane');
        }, 200);

        if (this.extraData.keyWiseFieldsMap.tables && !this.extraData.keyWiseFieldsMap.tables.ux.attributes.items.length) {
            // parentController.dsConfigDataNotAvailable = true;
            // parentController.dsConfigDataNotAvailableMessage = "Make sure you have access to the tables";
            // parentController.isDsConfigValid = false;
            //
            this.extraData.keyWiseFieldsMap.tables.ux.attributes.items.push({
                name: "No tables found for this database.",
                value: null
            });
            this.extraData.keyWiseFieldsMap.tables.ux.layout.classes += ' no-tables ';
            this.update();
        }
        else {
            parentController.dsConfigDataNotAvailable = false;
            parentController.dsConfigDataNotAvailableMessage = "";
        }
    }

    function _queryStringUpdatePostProcess(scope, query_string_to) {
        if (scope.model.query_string.length == 0) {
            if (query_string_to.classes.indexOf(" non-empty-state") != -1) {
                query_string_to.classes = query_string_to.classes.replace(" non-empty-state", "");
            }
        }
        else {
            if (query_string_to.classes.indexOf(" non-empty-state") === -1) {
                query_string_to.classes += " non-empty-state";
            }
        }
    }


    function get_table_description_for_postgresql(params, ele, scope, callback_fn) {
        get_table_description(params, ele, scope, callback_fn, "\"");
    }

    function get_table_description_for_mysql(params, ele, scope, callback_fn) {
        get_table_description(params, ele, scope, callback_fn, "`");
    }

    function get_table_description(params, ele, scope, callback_fn, quote_char) {
        analyticsService.userEventTrack(c.userEvents.landingPage.addNewDS.thirdParty.rdbms.selectTable,
            {
                eventOrigin: "thirdPartyModal",
                integration: scope.options.data.parentController.selectedIntegration.name
            });
        var table_data_query = "SELECT * FROM " + quote_char + scope.model.tables + quote_char;
        scope.options.data.parentController.dataSampleVisible = true;
        scope.options.data.parentController.preview_grid_loader.show = true;
        scope.options.data.parentController.isDsConfigValid = false;
        scope.options.data.parentController.previewLabelInfo = table_data_query;
        scope.options.data.parentController.dsConfigValidationMessage = undefined;
        params.query_string = table_data_query
        scope.model.query_string = params.query_string;
        Singularity.validateDsConfig(params, scope, callback_fn).then(function (data) {
            if (data.is_valid) {
                scope.options.data.parentController.dataSampleVisible = true;
                scope.model.hidden_query = params.query_string;
                scope.options.data.parentController.origDsName = scope.model.tables;
                scope.options.data.keyWiseFieldsMap.validate_query_string.mappedField.templateOptions.enabled = false;
                if (data.hasOwnProperty('is_select_query') && data.is_select_query == true) {
                    scope.options.data.parentController.disableCombineMode = false;
                } else if (data.hasOwnProperty('is_select_query') && data.is_select_query == false) {
                    scope.options.data.parentController.disableCombineMode = true;
                }
            } else {
                scope.options.data.parentController.dataSampleVisible = false;
                scope.options.data.parentController.togglePreviewGridLoader(false);
            }
            _queryStringUpdatePostProcess(scope, scope.options.data.keyWiseFieldsMap.query_string.mappedField.templateOptions);
        });

    }

    function on_query_change(value, element, scope) {
        scope.options.data.keyWiseFieldsMap.query_string.mappedField.data.queryStringManuallyChanged = true;
        scope.options.data.keyWiseFieldsMap.validate_query_string.mappedField.templateOptions.enabled = !!scope.model.query_string.length;
        function invalidateDsConfig() {
            scope.options.data.parentController.isDsConfigValid = false;
        }
        _queryStringUpdatePostProcess(scope, element.templateOptions);
        utils.debounce(invalidateDsConfig, 300, false)();

    }

    function onSearchChange(params, ele, scope, callback_fn) {
        var tablesList = scope.options.data.keyWiseFieldsMap.tables.ux.attributes.items;
        scope.options.data.keyWiseFieldsMap.tables.mappedField.templateOptions.options = tablesList.filter(function (el) {
            return el.name.toLowerCase().includes(params.toLowerCase());
        });
    }

}
