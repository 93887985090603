/*jslint node: true */

import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash-es';

pivotManagerFactory.$inject = ['utils', 'c', 'eventCallbackManagerFactory', 'mammothExpressionHelperFactory', 'mathModalService'];
export function pivotManagerFactory(utils, c, eventCallbackManagerFactory, mammothExpressionHelperFactory, mathModalService) {
  return {get_manager: get_manager};

  function get_manager(options) {
    return new PivotManager(options);
  }

  function PivotManager(options) {
    var self = this;
    var metadata = _.cloneDeep(options.metadata),
      taskUtils = options.taskUtils, dataview;
    self.dateTruncateOptions = c.dateTruncateOptions;
    var numericDefaultFormat = c.numericDefaultFormat;

    if(options.context){
      dataview = options.context.dataview;
    }
    else{
      dataview = options.dataview;
    }

    $.each(metadata, function(i, col){
      if(col.type == c.date){
        col.truncateInfo = null;
      }
    })

    self.formatInfo = dataview.display_properties.FORMAT_INFO || {};

    self.derivedColumnsToExpressionStringMap = {};
    var expression_helper = mammothExpressionHelperFactory.get_helper();

    if(!dataview){
      throw "Dataview should be supplied in the options dictionary in one of the two ways. Read code above for more information";
    }

    var onItemAdded = new eventCallbackManagerFactory('pivotManagerItemAdded');
    metadata = utils.sanitizeMetadata(metadata)
    self.metadata = _.cloneDeep(metadata);
    self.displayNameAndTypeToColumnMap = options.displayNameAndTypeToColumnMap
    // attributes
    self.selected_items = [];
    self.derivedColumns = [];
    self.allowDerivedColumns = false;
    self.functions = [
      c.aggregations.sum,
      c.aggregations.avg,
      c.aggregations.min,
      c.aggregations.max,
      c.aggregations.stddev
    ];

    //methods
    self.getParam = getParam;
    self.handlePasteParams = handlePasteParams;
    self.sanitizeParam = sanitizeParam;
    self.setParam = setParam;
    self.validate = validate;
    self.addAggregation = addAggregation;
    self.addColumn = addColumn;
    self.removeItem = removeItem;
    self.removeColumn = removeColumn;
    self.addDerivedColumn = addDerivedColumn;
    self.editDerivedColumnValue = editDerivedColumnValue;
    self.removeDerivedColumnValue = removeDerivedColumnValue;
    self.setFormatInfo = setFormatInfo;
    self.updateDerivedColumnExpressionStrings = updateDerivedColumnExpressionStrings;

    self.run_validators = new eventCallbackManagerFactory('agg_validators');
    self.onItemAdded = onItemAdded.add_callback;

    /**
     * validation logic:
     *
     * Just make sure that  group by section has at least one element
     */

    function validate(){
      var is_valid = true
      if (self.selected_items.length > 0){
        _.forEach(self.selected_items, function (selected_item){
          if(selected_item.column && ((selected_item.hasOwnProperty('aggregation')  && selected_item.column.type !='NUMERIC') ||
              selected_item.column.hasOwnProperty('error'))){
            is_valid = false
          }
        })
      }else{
        is_valid = false
      }
      return is_valid;
    }

    function setFormatInfo(formatInfo){
      self.formatInfo = formatInfo;
    }

    function handlePasteParams(taskInfo){
      /** Update params with suitable replacement columns, based on display name*/

      var params = taskInfo.params;
      //Group by columns
      for(const index in params.PIVOT.GROUP_BY){
        utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.PIVOT.GROUP_BY[index], 'COLUMN', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
      }
      //columns used in functions
      for(const index in params.PIVOT.SELECT){
        if(params.PIVOT.SELECT[index]['FUNCTION'] != 'COUNT'){
          utils.metadata.replaceMatchingColumnAndUpdateMetadata(params.PIVOT.SELECT[index], 'COLUMN', taskInfo, self.metadata, self.displayNameAndTypeToColumnMap);
        }
      }
      return params;
    }

    function getParam(){
      var pivot_param = {GROUP_BY: undefined, SELECT: undefined, DERIVED_SELECT: undefined };
      var group_by = [];
      var aggregations = [];

      self.selected_items.forEach(function(item, i){
        if(item.aggregation){
          var agg = {
            FUNCTION: item.aggregation,
            AS: item.new_column_name,
            ORDER: i,
            COLUMN: undefined,
            FORMAT: undefined,
            INTERNAL_NAME: undefined
          };
          if (item.column){
            agg.COLUMN = item.column.internal_name;
          }
          if (item.FORMAT) {
            agg.FORMAT = item.FORMAT;
          }
          agg.INTERNAL_NAME = item.internal_name;
          aggregations.push(agg);
        }
        else{
          var spec = {COLUMN: item.column.internal_name, ORDER: i, FORMAT: undefined}
          if (item.column.type == c.numeric){
            var format = item.FORMAT || item.column.format;
            var _format = item.column && self.formatInfo[item.column.internal_name] ? self.formatInfo[item.column.internal_name] : format;
            spec.FORMAT = _format;
          }
          else if(item.column.type == c.date){
            if(item.truncateInfo){
              angular.merge(spec, item.truncateInfo.param);
              if(item.truncateInfo){
                spec.FORMAT = item.truncateInfo.value;
              }
            }
          }

          group_by.push(spec);
        }
      });
      pivot_param.GROUP_BY = group_by;
      pivot_param.SELECT = aggregations;
      pivot_param.DERIVED_SELECT = self.derivedColumns;
      var pivotSpec = {
        PIVOT: pivot_param
      }
      if(options.context.hasOwnProperty('sequence')){
        pivotSpec['SEQUENCE_NUMBER'] = options.context.sequence;
      }
      return pivotSpec;
    }

    function addItem(item, index){
      if(self.selected_items.indexOf(item) === -1){
        if (index === undefined) {
          self.selected_items.push(item);
        } else {
          self.selected_items.splice(index, 0, item);
        }
        self.selected_items = angular.extend([], self.selected_items);
      }
      self.run_validators.fire_event();
      onItemAdded.fire_event();
      _setHighlights();
      return item;
    }

    function addAggregation(agg, column, new_name, index, format){
      /**
       * Format argument will be ignored in case the column itself has a format info.
       */
      var new_internal_name;
      if (new_name === undefined) {
        if (agg === c.aggregations.count) {
          new_name = "Count";
          new_internal_name = utils.string.format("{0}_{1}", [agg.toLowerCase(), utils.string.random(10, {lowercase: true})]);
        } else {
          new_name = utils.string.format("{0} of {1}", [utils.string.capitalize(agg, true),
            column.display_name]);
          new_internal_name = utils.string.format("{0}_{1}_{2}", [agg.toLowerCase(),
            column.internal_name, utils.string.random(10, {lowercase: true})]);
        }
        new_name = getNewUniqueName(new_name);
      }

      var item = {aggregation: agg, column: column, new_column_name: new_name, internal_name: new_internal_name, FORMAT: undefined};

      if(format){
        item.FORMAT = _.cloneDeep(format);
      }
      
      else if (column) {
          item.FORMAT = column.format;
          if (self.formatInfo[column.internal_name]) {
            item.FORMAT = self.formatInfo[item.column.internal_name];
          }
      }

      self.allowDerivedColumns = true;
      return addItem(item, index);
    }

    function addColumn(column, index){
      column._is_grouped = true;
      var item = {column: column, new_column_name: column.display_name, FORMAT: undefined};
      if (column.type == "NUMERIC") {
        if (self.formatInfo[item.column.internal_name]) {
          item.FORMAT = self.formatInfo[item.column.internal_name];
        }
        else if (column.format) {
          item.FORMAT = column.format;
        }
      }
      addItem(item, index);
      return item;
    }

    function getNewUniqueName(name) {
      var new_name = name, i = 1;
      var unique_names = [];

      $.each(self.selected_items, function (i, item) {
        unique_names.push(item.new_column_name)
      });

      while (unique_names.indexOf(new_name) > -1) {
        i += 1;
        new_name = name + " " + i;
      }
      return new_name;
    }

    function removeItem(item){
      if (item.column && item.column._is_grouped) {
        item.column._is_grouped = false;
      }
      self.selected_items = $.grep(self.selected_items, function (sel_item) {
        return sel_item != item;
      });
      self.run_validators.fire_event();
      _setHighlights();
    }

    function removeColumn(column) {
      var item = $.grep(self.selected_items, function (item: any) {
        return item.column && (item.column.internal_name === column.internal_name) && !item.hasOwnProperty('aggregation');
      });
      if (item.length) {
        removeItem(item[0]);
      }

      if(!$.grep(self.selected_items, function(item: any){return item.aggregation;}).length){
        self.allowDerivedColumns = false;
      }
    }

    function _setHighlights() {
      var colList = [];
      $.each(self.selected_items, function (i, item) {
        if (item.column && colList.indexOf(item.column) === -1) {
          colList.push(item.column);
        }
      });
      if(taskUtils){
        taskUtils.highlight.sources(colList);
      }
    }

    function get_truncate_extract_info(grpSpec){
      var info = null;
      angular.forEach(self.dateTruncateOptions, function(dteo){
        if(dteo.param.hasOwnProperty('TRUNCATE') && grpSpec.hasOwnProperty('TRUNCATE') && dteo.param.TRUNCATE == grpSpec.TRUNCATE && dteo.value == grpSpec.FORMAT){
          info = dteo;
          return false;
        }
        else if(dteo.param.hasOwnProperty('COMPONENT') && grpSpec.hasOwnProperty('COMPONENT') && dteo.param.COMPONENT == grpSpec.COMPONENT && dteo.value == grpSpec.FORMAT){
          info = dteo;
          return false;
        }
      });
      if(!info){
        info = self.dateTruncateOptions[self.dateTruncateOptions.length - 1];
      }
      return info;
    }

    function sanitizeParam(param){
      let indicesOfGroupColumnNotAvailable = [];
      let indicesOfSelectColumnNotAvailable = [];
      _.forEach(param.PIVOT.GROUP_BY, function (col) {
        let col_info = utils.metadata.get_column_by_internal_name(self.metadata, col.COLUMN);
        if (!col_info){
          indicesOfGroupColumnNotAvailable.push(param.PIVOT.GROUP_BY.indexOf(col))
        }
      });
      param.PIVOT.GROUP_BY = param.PIVOT.GROUP_BY.filter(function(value,index){
        return indicesOfGroupColumnNotAvailable.indexOf(index) == -1;
      });
      let indicesOfSelectColumnWithTypeMismatch = [];

      _.forEach(param.PIVOT.SELECT, function (col) {
        if (col.hasOwnProperty('COLUMN')){
          let col_info = utils.metadata.get_column_by_internal_name(self.metadata, col.COLUMN);
          if (!col_info){
            indicesOfSelectColumnNotAvailable.push(param.PIVOT.SELECT.indexOf(col))
          }else if(col_info['type']!=='NUMERIC'){
            indicesOfSelectColumnWithTypeMismatch.push(param.PIVOT.SELECT.indexOf(col))
          }
        }
      });
      param.PIVOT.SELECT = param.PIVOT.SELECT.filter(function(value,index){
        return indicesOfSelectColumnNotAvailable.indexOf(index) == -1  && indicesOfSelectColumnWithTypeMismatch.indexOf(index) == -1;
      });
    }

    function setParam(param) {
      // self.sanitizeParam(param);
      self.selected_items = [];
      self.derivedColumns = [];
      self.allowDerivedColumns = false;
      angular.forEach(param.PIVOT.SELECT, function (agg) {
        var item = addAggregation(agg.FUNCTION, utils.metadata.get_column_by_internal_name(self.metadata, agg.COLUMN),
          agg.AS, agg.ORDER, agg.FORMAT);
        if(agg.INTERNAL_NAME){
          item.internal_name = agg.INTERNAL_NAME;
        }

      });
      angular.forEach(param.PIVOT.GROUP_BY, function (group) {
        if (typeof group === "string") { // for backward compatibility
          group = {'COLUMN': group};
        }
        var groupItem: any = addColumn(utils.metadata.get_column_by_internal_name(self.metadata, group.COLUMN), group.ORDER);

        if(group.hasOwnProperty('COMPONENT') || group.hasOwnProperty('TRUNCATE')){
          groupItem.truncateInfo = get_truncate_extract_info(group);
        }

      });
      if(angular.isArray(param.PIVOT.DERIVED_SELECT)){
        self.derivedColumns = param.PIVOT.DERIVED_SELECT;
        updateDerivedColumnExpressionStrings();
      }
    }


    function addDerivedColumn(){
      self.derivedColumns.push({
        MATH: {
          EXPRESSION: [],
          AS: {
            COLUMN: 'Column '+ (self.derivedColumns.length + self.selected_items.length + 1),
            TYPE: c.numeric,
            INTERNAL_NAME:  'column_' + utils.string.random(10, {lowercase: true}),
          }
        },
        FORMAT: _.cloneDeep(numericDefaultFormat)
      })
    }

    function _getDerivedMetadata($index?){
      var derivedMetadata = [];

      self.selected_items.forEach(function(item){
        if(item.aggregation) {
          derivedMetadata.push({
            type: c.numeric,
            internal_name: item.internal_name,
            display_name: item.new_column_name
          });
        }})

      $.each(self.derivedColumns, function($i: number, item){
        if(!isNaN($index) && $i >= $index){
          return false;
        }
        derivedMetadata.push({
          type: c.numeric,
          internal_name: item.MATH.AS.INTERNAL_NAME,
          display_name: item.MATH.AS.COLUMN
        })
      })
      return derivedMetadata;
    }

    function updateDerivedColumnExpressionStrings(){
      var derivedMetadata = _getDerivedMetadata();
      angular.forEach(self.derivedColumns, function(param){
        self.derivedColumnsToExpressionStringMap[param.MATH.AS.INTERNAL_NAME] = expression_helper.getExpressionString(
          param.MATH.EXPRESSION,
          derivedMetadata, []);
      })
    }

    function editDerivedColumnValue($index){
      var derivedMetadata = _getDerivedMetadata($index);
      mathModalService.open({
        metadata: derivedMetadata,
        mode: 'math',
        context: {},
        param: _.cloneDeep(self.derivedColumns[$index])
      }).then(_closeCb, _dismissCb);

      function _closeCb(param){
        param.FORMAT = self.derivedColumns[$index].FORMAT;
        self.derivedColumns[$index] = param;
        updateDerivedColumnExpressionStrings()
      }

      function _dismissCb(){
        self.derivedColumns[$index] = null;
        // TODO test this
      }
    }

    function removeDerivedColumnValue($index){
      self.derivedColumns = $.grep(self.derivedColumns, function (dc, index) {
        return index != $index;
      })
    }
  }
}



