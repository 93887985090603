// API
import API from '@/api'

// Constants
import { USER_EVENT_CONTENT } from '@/constants'

// Dependencies
import { detect } from 'detect-browser'

const userEvents = () => ({
  save(eventName, additionalInfo) {
    if (process.env.VUE_APP_DISABLE_USER_EVENTS === 'true') return

    const browser = detect()

    const event = {
      event_origin: USER_EVENT_CONTENT[eventName]?.event_context?.eventOrigin,
      ...USER_EVENT_CONTENT[eventName],
      workspace_id: localStorage.getItem('workspace_id'),
      additional_info: additionalInfo,
      browser_name: navigator.brave ? 'brave' : browser?.name,
      browser_size: { height: screen.height, width: screen.width },
      browser_version: browser?.version,
      device_info: navigator.userAgent,
      domain: window.location.host,
      email_id: localStorage.getItem('email'),
      os_name: browser?.os,
      timestamp: new Date().getTime() / 1000,
      user_name: localStorage.getItem('name')
    }

    const data = Buffer.from(JSON.stringify(event)).toString('base64')
    API.post('mm-ue', { data }, { customErrorHandling: true })
  }
})

const userEventsPlugin = {
  install(Vue) {
    Vue.prototype.$userEvents = userEvents()
  }
}

export default userEventsPlugin
