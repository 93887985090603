<template>
  <div>
    <span class="mm-text--caption-bold"> {{ computedOperationName }} </span>
    <ul class="operation-list m-b-3">
      <li v-for="(operation, idx) in operations" :key="`${operation.operationId}_${idx}`" class="m-b-2">
        <div class="operation-wrapper">
          <div class="d-flex">
            <mm-icon class="m-r-2" :name="getConnector(operation.dbType).smallIcon" />
            <span class="mm-text--caption-regular">{{ getConnector(operation.dbType).name }}</span>
          </div>

          <div class="operation-details m-l-5 p-l-2">
            <span v-if="operation.host" class="mm-text--caption-regular">
              {{ $t('data_library.data_addition.third_party.new_connection.host_url') }}: {{ operation.host }}
            </span>
            <span v-if="operation.database" class="mm-text--caption-regular">
              {{ $t('data_library.data_addition.third_party.new_connection.database') }}: {{ operation.database }}
            </span>
            <span v-if="operation.table" class="mm-text--caption-regular">
              {{ $t('global.dictionary.table') }}: {{ operation.table }}
            </span>
            <span v-if="operation.indexName" class="mm-text--caption-regular">
              {{ $t('global.dictionary.index_name') }}: {{ operation.indexName }}
            </span>
            <span v-if="operation.directory" class="mm-text--caption-regular">
              {{ $t('global.dictionary.directory') }}: {{ operation.directory }}
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { EXPORT_CONNECTORS, RESOURCE_DEPENDENCIES } from '@/constants'

export default {
  name: 'linked-in-out-list-export-operation',
  props: {
    operations: {
      type: Array,
      required: true
      /*
      [{
        operationId: -1,
        operationName: '',
        dbType: '',
        host: '',
        database: '',
        table: '',
        indexName: '',
        directory: ''
      }]
      */
    }
  },
  computed: {
    computedOperationName() {
      return this.operations[0].operationName == RESOURCE_DEPENDENCIES.EXPORT
        ? this.$t('global.dictionary.export')
        : this.$t('global.dictionary.publish_as_db')
    }
  },
  methods: {
    getConnector(key) {
      return EXPORT_CONNECTORS.find((c) => c.key == key)
    }
  }
}
</script>

<style lang="scss" scoped>
.operation-list {
  list-style: none;
  display: grid;
  padding: 0;

  li {
    &::marker {
      font-size: 13px;
    }

    .operation-details {
      color: var(--mm-color--n500);
      display: grid;
    }
  }
}
</style>
