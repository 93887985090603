import Keycloak from 'keycloak-js'

const KEYCLOAK_URL = process.env.VUE_APP_KEYCLOAK_URL
const KEYCLOAK_REALM = process.env.VUE_APP_KEYCLOAK_REALM
const KEYCLOAK_CLIENT = process.env.VUE_APP_KEYCLOAK_CLIENT_ID

const KEYCLOAK_CONFIG = {
  clientId: KEYCLOAK_CLIENT,
  realm: KEYCLOAK_REALM,
  url: KEYCLOAK_URL
}

export const KEYCLOAK_INIT_OPTIONS = {
  checkLoginIframe: false,
  onLoad: 'login-required'
}

const keycloak = new Keycloak(KEYCLOAK_CONFIG)

export default keycloak
