// TODO: SENTRYERROR:FRONTEND-JD:PENDING:https://sentry.io/mammoth-analytics-inc/frontend/issues/471147793

/**
 * @ngInject
 */
historicDataviewData.$inject = ['$resource', 'config', 'c'];
export function historicDataviewData($resource, config, c) {
  return {
    get: get
  };

  function get(id, sequence_number){
    var wsResource =  $resource(config.api.dataviews);
    return wsResource.get({id: id, [c.sequenceNumber]: sequence_number}).$promise;
  }
}
