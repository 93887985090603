<template>
  <div class="mm-data-addition-third-party height-100">
    <div v-if="step >= 1">
      <mm-stepper v-model="step" :steps="computedSteps" />

      <div class="top-info d-flex m-y-3">
        <span class="mm-text--body-bold" style="white-space: nowrap"> {{ connector.name }} </span>
        <div v-if="step >= 2" class="top-connection-info overflow-kit">
          <mm-tooltip :label="connection.name">
            <span class="m-x-2"> - {{ connection.name }}</span>
          </mm-tooltip>
          <mm-tooltip v-if="profile" :label="profile.name">
            <span class="mm-text--caption-regular profile-name m-x-2"> - {{ profile.name }}</span>
          </mm-tooltip>
        </div>
      </div>
    </div>

    <div v-if="isStartupLoading" class="startup-loader-wrapper"><mm-loader size="xlarge" /></div>

    <data-library-data-addition-third-party-selection
      v-else-if="step == 0"
      class="m-t-3"
      :connectors="processedConnectors"
      :is-back-button-visible="isBackButtonVisible"
      @removedConnector="processConnectorsList"
      @submit="selectConnector"
      @back="$emit('back')"
    />

    <data-library-data-addition-third-party-connection
      v-else-if="step == 1"
      :connector="connector"
      @back="step--"
      @submit="openMetrics"
    />

    <div v-else-if="step == 2" class="height-100 overflow-kit">
      <data-library-data-addition-third-party-metrics-query
        v-if="connector.metrics.query"
        :connector="connector"
        :connection="connection"
        :profile="profile"
        @back="step--"
        @submit="verifySchedulerRedirect"
      />
      <data-library-data-addition-third-party-metrics-checkbox-list
        v-else-if="connector.metrics.checkboxList"
        :connector="connector"
        :connection="connection"
        @back="step--"
        @submit="verifySchedulerRedirect"
      />
      <data-library-data-addition-third-party-metrics-spreadsheet
        v-else-if="connector.metrics.spreadsheet"
        :connector="connector"
        :connection="connection"
        :is-submit-loading="isSubmitLoading"
        @back="step--"
        @submit="verifySchedulerRedirect"
      />
      <data-library-data-addition-third-party-metrics-file-url
        v-else-if="connector.metrics.fileUrl"
        :connector="connector"
        :connection="connection"
        :is-submit-loading="isSubmitLoading"
        @back="step--"
        @submit="verifySchedulerRedirect"
      />
      <data-library-data-addition-third-party-metrics-file-list
        v-else-if="connector.metrics.fileList"
        :connector="connector"
        :connection="connection"
        :is-submit-loading="isSubmitLoading"
        @back="step--"
        @submit="verifySchedulerRedirect"
      />
    </div>

    <data-library-data-addition-third-party-scheduler
      v-else-if="step == 3"
      :connector="connector"
      :table="table"
      :loading="isSubmitLoading"
      :hide-first-retrieval="hideFirstRetrievalOnScheduler"
      @back="step--"
      @submit="submitDataAddition"
    />
  </div>
</template>

<script>
// Components
import DataLibraryDataAdditionThirdPartySelection from './data-library-data-addition-third-party-selection/data-library-data-addition-third-party-selection'
import DataLibraryDataAdditionThirdPartyConnection from './data-library-data-addition-third-party-connection/data-library-data-addition-third-party-connection'
import DataLibraryDataAdditionThirdPartyMetricsQuery from './data-library-data-addition-third-party-metrics/data-library-data-addition-third-party-metrics-query/data-library-data-addition-third-party-metrics-query'
import DataLibraryDataAdditionThirdPartyMetricsCheckboxList from './data-library-data-addition-third-party-metrics/data-library-data-addition-third-party-metrics-checkbox-list/data-library-data-addition-third-party-metrics-checkbox-list'
import DataLibraryDataAdditionThirdPartyMetricsFileUrl from './data-library-data-addition-third-party-metrics/data-library-data-addition-third-party-metrics-file-url/data-library-data-addition-third-party-metrics-file-url'
import DataLibraryDataAdditionThirdPartyMetricsFileList from './data-library-data-addition-third-party-metrics/data-library-data-addition-third-party-metrics-file-list/data-library-data-addition-third-party-metrics-file-list'
import DataLibraryDataAdditionThirdPartyMetricsSpreadsheet from './data-library-data-addition-third-party-metrics/data-library-data-addition-third-party-metrics-spreadsheet/data-library-data-addition-third-party-metrics-spreadsheet'
import DataLibraryDataAdditionThirdPartyScheduler from './data-library-data-addition-third-party-scheduler/data-library-data-addition-third-party-scheduler'

// API
import globalApi from '@/api/global.api'
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

// Constants
import { USER_EVENTS, CONNECTORS, WORKSPACE_PERMISSIONS } from '@/constants'

export default {
  name: 'data-library-data-addition-third-party',
  components: {
    DataLibraryDataAdditionThirdPartySelection,
    DataLibraryDataAdditionThirdPartyConnection,
    DataLibraryDataAdditionThirdPartyMetricsQuery,
    DataLibraryDataAdditionThirdPartyMetricsCheckboxList,
    DataLibraryDataAdditionThirdPartyMetricsFileUrl,
    DataLibraryDataAdditionThirdPartyMetricsFileList,
    DataLibraryDataAdditionThirdPartyMetricsSpreadsheet,
    DataLibraryDataAdditionThirdPartyScheduler
  },
  props: {
    folderResourceId: [String, Number],
    defaultConnectorKey: String,
    isBackButtonVisible: Boolean,
    hideFirstRetrievalOnScheduler: Boolean
  },
  data: () => ({
    step: 0,
    isStartupLoading: true,
    connector: null,
    connection: null,
    profile: null,
    table: null,
    isSubmitLoading: false,
    metricsPayload: {},
    processedConnectors: []
  }),
  computed: {
    computedSteps() {
      let steps = [1, 2]
      if (!this.connector.scheduler?.skip) steps.push(3)
      return steps.map((s) => ({ description: this.$t(`data_library.data_addition.third_party.steps.${s}`) }))
    }
  },
  async created() {
    this.isStartupLoading = true

    await this.processConnectorsList()
    // If a default connector was defined, open it
    if (this.defaultConnectorKey) this.selectDefaultConnector()
    else this.fetchIntervalId = setInterval(() => this.processConnectorsList(), 15000)

    this.isStartupLoading = false
  },
  watch: {
    step(step) {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.STEP_CHANGE, { step })
    }
  },
  methods: {
    async processConnectorsList() {
      // connectorsList contains info about premium/free and addition status
      const connectorsList = await globalApi.getConnectorsList()

      this.processedConnectors = CONNECTORS.map((connector) => {
        const conListItem = connectorsList.find((c) => c.key == connector.key)
        return {
          ...conListItem,
          ...connector,
          disabled:
            !conListItem ||
            (this.$store.getters.getCurrentWorkspacePermissions == WORKSPACE_PERMISSIONS.MEMBER &&
              conListItem.isPremium &&
              !conListItem.isAcquired)
        }
      })
    },
    selectDefaultConnector() {
      const defaultConnector = this.processedConnectors.find((c) => c.key === this.defaultConnectorKey)
      if (defaultConnector) this.selectConnector(defaultConnector)
    },
    selectConnector(connector) {
      this.connector = connector
      this.step++
    },
    openMetrics({ connection, profile }) {
      this.connection = connection
      this.profile = profile
      this.step++
    },
    verifySchedulerRedirect(payload) {
      this.connector.scheduler?.skip ? this.submitDataAddition(payload) : this.openScheduler(payload)
    },
    openScheduler(metricsPayload) {
      this.metricsPayload = metricsPayload
      this.table = metricsPayload.table
      this.step++
    },
    async submitDataAddition(submissionStepPayload) {
      this.isSubmitLoading = true

      const payload = {
        projectId: this.$store.state.projectId,
        connectorKey: this.connector.key,
        identityKey: this.connection.value,
        profile: this.profile?.arrayValue || this.profile?.value,
        folderResourceId: this.folderResourceId,
        ...this.metricsPayload,
        ...submissionStepPayload,
        table: this.table?.value,
        selectedMetrics: this.table?.selectedMetrics,
        scheduleType: this.table?.scheduleType
      }

      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.SUBMIT, payload)

      const { data } = await dataLibraryApi.addThirdPartyData(payload)

      if (data.ERROR_CODE || data.ERROR_MESSAGE) {
        const errorMessage = this.$te(`global.api.${data.ERROR_CODE}`)
          ? this.$t(`global.api.${data.ERROR_CODE}`)
          : this.$t('global.api.generic_error')
        this.$toast.show({ content: errorMessage, status: 'error' })
      } else this.$emit('submit')

      this.isSubmitLoading = false
    }
  },
  beforeDestroy() {
    clearInterval(this.fetchIntervalId)
  }
}
</script>

<style lang="scss" scoped>
.mm-data-addition-third-party {
  flex-direction: column;
  min-height: 0;
  display: flex;

  .startup-loader-wrapper {
    align-items: center;
    align-self: center;
    display: flex;
    height: 100%;
  }

  ::v-deep .mm-stepper .mm-stepper--step {
    max-width: fit-content;
    .mm-divider {
      width: 80px;
    }
  }

  .top-info {
    align-items: center;
    overflow: hidden;

    .top-connection-info {
      display: flex;
      width: 100%;

      ::v-deep .mm-tooltip--slot-wrapper {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .profile-name {
        color: var(--mm-color--n400);
      }
    }
  }
}
</style>
