/*
structure of the object stored in local storage
{
    sourceType_sourceId: {
        "conditions": {}
        "order": [],
        "cardState": {
            "card_id" : {
                "0": {
                    "activeValues": ['a','b','c'],
                    "initial_condition": {},
                    "filter_description": ['a','b','c']
                }
                ...
            }
            .....
        }
    }
    .....
}

Note:
 - For the text explore cards level is set to 0 as text explore card does not contain level
*/

import _ = require("lodash");

const filterStorageKey = "card_filter";
var storage = localStorage;

function filterStorageService(useSessionStorage = false) {
    storage = useSessionStorage ? sessionStorage : localStorage;
    // dataview controller
    this.getBySource = function(sourceId, sourceType) {
        return dataviewFilters(sourceId, sourceType)
    }
    // reset conditions from localstorage of all dataviews
    this.resetFilters = function() {
        storage.removeItem(filterStorageKey);
    }
}
function dataviewFilters(sourceId, sourceType) {
    var id = `${sourceId}_${sourceType}`;
    var storedFilters = {};
    var cardState = {};
    var conditions = {};
    var order = [];
    init();

    function init() {
        storedFilters = JSON.parse(storage.getItem(filterStorageKey)) || {};
        const dataviewFilters = storedFilters[id] 
        if (dataviewFilters) {
            conditions = dataviewFilters.conditions;
            order = dataviewFilters.order;
            cardState = dataviewFilters.cardState;
        }
    };
    function setAllFilters() {
        storedFilters[id] = { cardState, conditions, order}
        storage.setItem(filterStorageKey, JSON.stringify(storedFilters));
    };
    // get the stored filters (conditions and order) fromstorage 
    function getFilters() {
        return { conditions, order };
    };
    // store the dataview filters (condition and order) instorage 
    function setFilters(filterCondition, filterOrder) {
        conditions = filterCondition;
        order = filterOrder;
        Object.keys(cardState).forEach(cardId => {
            if(!order.includes(cardId)){
                delete cardState[cardId];
           }
        })
        setAllFilters();
    };
    // get card state of a card by  card id
    function getCardState(cardId) {
        return cardState[cardId] || {};
    };
    // store the card state of a card with dataview id and card id
    function setCardState(cardId, state) {
        if (!storedFilters[id]) {
            storedFilters[id] = {}
        }
        cardState[cardId] = state;
        setAllFilters();

    };
    // remove conditions based on dataview id and card id
    function removeCardStates(ids) {
        ids.forEach(id => {
            delete cardState[id];
        });
        setAllFilters()
    };    
    // remove conditions of a dataview
    function resetFilters() {
        cardState = {};
        conditions = {};
        order = [];
        delete storedFilters[id];
        storage.setItem(filterStorageKey, JSON.stringify(storedFilters))
    }

    return {
        getFilters,
        setFilters,
        getCardState,
        setCardState,
        removeCardStates,
        resetFilters,
    }
}

export default filterStorageService;