/**
 * Created by manu on 28/3/18.
 */

'use strict';

import * as angular from 'angular';
import {unstructuredDataService} from './unstructured.service';

export let unstructuredDataModule = angular.module('app.unstructuredData', ['ui.bootstrap']);

unstructuredDataModule.service('unstructuredDataService', unstructuredDataService);
